import { call, fork, takeLatest } from 'redux-saga/effects';
import fetchEntitySaga from '../old_to_refact/sagas/utils/fetchEntitySaga';
import apiPathFactory from '../../core/old_common/rooting/apiPathFactory';
import api from '../../core/helpers/api';
import { GET_STATISTICS } from './constants';


export const getStatistics = {
  base: () => ({
    type: GET_STATISTICS.BASE,
  }),
  request: () => ({ type: GET_STATISTICS.REQUEST }),
  success: (result) => ({ type: GET_STATISTICS.SUCCESS, result }),
  failure: (error) => ({ type: GET_STATISTICS.FAILURE, error }),
};

export function* getStatisticsSaga() {
  try {
    const url = apiPathFactory.getStatisticsAPI();
    const response = yield call(() => fetchEntitySaga(getStatistics, api.get, url));

    return 'poll';
  } catch (error) {
    return error;
  }
}

function* watchDashboard() {
  yield takeLatest(GET_STATISTICS.BASE, getStatisticsSaga);
}

export default function* watchers() {
  yield fork(watchDashboard);
}
