import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Box } from '@jcm-technologies/uikit/dist/atoms/Layout';
import Span from './Span';
import './ContentContainer.css';

export class ContentContainer extends Component {
  render() {
    const { children, title, tenants, isSecondDeletePopup } = this.props;
    return (
      <Box backgroundColor='white' borderRadius={3}>
        <Span className={isSecondDeletePopup ? 'titleRed' : ''} tenants={tenants}>
          {title?.replace('%23', '#').replace('%26', '&')}
        </Span>
        <Box padding={4}>{children}</Box>
      </Box>
    );
  }
}

const mapStateToProps = (state) => ({
  tenants: state.tenants,
});

export default connect(mapStateToProps, {})(ContentContainer);
