import moment from 'moment-timezone';

const convertStringDateToDatetime = (stringDate, format) => {
  return moment(stringDate, format).toDate();
};

const getTodayFormattedStringDate = (format) => {
  return moment().format(format);
};

export { convertStringDateToDatetime, getTodayFormattedStringDate };
