export default {
  resetPassword: {
    email: 'E-mail',
    newPassword: 'Nové heslo',
    backToLogin: 'Zpět na přihlšovací stránku',
    mailReseted: 'Vaše heslo bylo obnoveno',
    passwordReset: 'Vymazat heslo',
    send: 'Poslat',
  },
};
