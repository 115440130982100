import React, { Component } from 'react';
import { connect } from 'react-redux';
import StyledInput from './StyledInput';
import ErrorMessage from '../ErrorMessage';
import StyledSpan from './StyledSpan';
import StyledIcon from './StyledIcon';
import './Input.css';
import styled from 'styled-components';

const StyledSufix = styled.span({
  position: 'absolute',
  fontSize: '12px',
  color: (props) => (props.disabled ? '#f4f4f4' : 'grey'),
  right: '22px',
});

export class Input extends Component {
  render() {
    const {
      readonly,
      type,
      placeHolder,
      value,
      id,
      onChange,
      tenants,
      error,
      disable,
      required,
      icon,
      onFocus,
      onBlur,
      isFocused,
      min,
      maxlength,
      className,
      onIconClick,
      iconDisabled,
      max,
      componentRef,
      title,
      sufix,
    } = this.props;

    return placeHolder ? (
      <label className={`jcm-label ${className || ''} ${disable ? 'jcm-label-disabled' : ''}`}>
        <StyledInput
          tenants={tenants}
          icon={icon}
          error={error}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          isFocused={isFocused}
          value={value || ''}
          // defaultValue={value || ''}
          type={type}
          id={id}
          name={id}
          disabled={disable}
          required={required}
          autoComplete='off'
          min={min}
          title={title}
          max={max}
          maxLength={maxlength}
          readOnly={readonly}
          ref={componentRef}
        />
        {icon && (
          <StyledIcon
            tenants={tenants}
            disabled={iconDisabled}
            className={`input-icon ${icon}`}
            value={value}
            isFocused={isFocused}
            onClick={!iconDisabled ? onIconClick : null}
          />
        )}
        <StyledSpan tenants={tenants} icon={icon} value={value} isFocused={isFocused}>
          {placeHolder}
        </StyledSpan>
        {sufix && (
          <StyledSufix tenants={tenants} disabled={disable}>
            {sufix}
          </StyledSufix>
        )}
        {error ? <ErrorMessage message={error.errorMessage} /> : null}
      </label>
    ) : (
      <StyledInput
        tenants={tenants}
        className={className}
        icon={icon}
        error={error}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        isFocused={isFocused}
        value={value || ''}
        // defaultValue={value || ''}
        type={type}
        id={id}
        name={id}
        disabled={disable}
        required={required}
        autoComplete='off'
        min={min}
        max={max}
        readOnly={readonly}
        maxlength={maxlength}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  tenants: state.tenants,
});

export default connect(mapStateToProps, {})(Input);
