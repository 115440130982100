import { TabsMenu } from '@jcm-technologies/uikit/dist/molecules/TabsMenu';
import { Box } from '@jcm-technologies/uikit/dist/atoms/Layout';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getContrastColor } from '../../../../core/helpers/colorManager';
import { Edit as CustomizationUserFields } from './Edit';

export const View = () => {
  const { tab } = useParams();
  const { t } = useTranslation();
  const tenants = useSelector((state) => state.tenants);
  return (
    <>
      <Box backgroundColor='white' borderRadius={3} height='100%' paddingTop={2}>
        <TabsMenu
          id='administrationTab'
          colorActive={getContrastColor(tenants?.color1)}
          themeColor={tenants?.color1}
          defaultSelected={tab}
          components={{
            customization: <CustomizationUserFields code={3} />,
            equipments: <CustomizationUserFields code={4} />,
            fobs: <CustomizationUserFields code={5} />,
          }}
          items={[
            {
              id: 'customization',
              title: t('title.facilities'),
            },
            {
              id: 'equipments',
              title: t('title.equipments'),
            },
            {
              id: 'fobs',
              title: t('title.fobs'),
            },
          ]}
        />
      </Box>
    </>
  );
};
