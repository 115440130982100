export default {
  parameters: {
    title: 'Proxy Access 5k paraméter beállításai',
    pinCode: 'PIN kód',
    apn: 'APN',
    apnUser: 'APN felhasználó',
    apnPassword: 'APN jelszó',
    serverURL: 'URL szerver',
    editproxy: 'Acces 5K Proxy szerkesztése',
    titleRelays: 'Relék programozása',
    errorRelaysPopUp: 'Hiba: A relék maximális számát nem lehet túllépni.',
    CONFIGRELAYSNEW1_ICON: '1. relé szerkesztése',
    CONFIGRELAYSNEW2_ICON: '2. relé szerkesztése',
    CONFIGRELAYSNEW3_ICON: '3. relé szerkesztése',
    CONFIGRELAYSNEW4_ICON: '4. relé szerkesztése',
    ACTIVEEVENTSTOSD_ICON: 'Aktív események szerkesztése',
    A5KPROXY_ICON: 'A5K proxy szerkesztése',
  },
};
