export default {
  compareMemory: {
    nodata: 'In dieser Tabelle sind keine Daten verfügbar',
    filter: 'Filter',
    foundInBoth: 'Gefunden auf beiden Seiten',
    foundInBBDD: 'In der Datenbank gefunden',
    foundInMemory: 'Im Speichervergleich gefunden',
    refreshMemory: 'Speicher aktualisieren',
    deleteBBDD: 'Aus Datenbank löschen',
    addBBDD: 'Zur Datenbank hinzufügen',
    confirmDelete: 'Möchten Sie die emitter entfernen?',
    reserved: 'Reserviert',
  },
};
