import { call, fork, put, takeLatest } from 'redux-saga/effects';
import fetchEntitySaga from '../old_to_refact/sagas/utils/fetchEntitySaga';
import apiPathFactory from '../../core/old_common/rooting/apiPathFactory';
import api from '../../core/helpers/api';
import { getCustomerLicensesSaga } from '../customers/sagas';
import { showAlert } from '../state/alerts';
import i18n from '../../core/i18n';
import { DELETE_INVITATION, POST_INVITATION, POST_RESEND_INVITATIONS } from './constants';

export const postInvitation = {
  base: (id) => ({
    type: POST_INVITATION.BASE,
    id,
  }),
  request: () => ({ type: POST_INVITATION.REQUEST }),
  success: (result) => ({ type: POST_INVITATION.SUCCESS, result }),
  failure: (error) => ({ type: POST_INVITATION.FAILURE, error }),
};

export const deleteInvitation = {
  base: (data) => ({
    type: DELETE_INVITATION.BASE,
    data,
  }),
  request: () => ({ type: DELETE_INVITATION.REQUEST }),
  success: (result) => ({ type: DELETE_INVITATION.SUCCESS, result }),
  failure: (error) => ({ type: DELETE_INVITATION.FAILURE, error }),
};

export const postResendInvitations = {
  base: (id) => ({
    type: POST_RESEND_INVITATIONS.BASE,
    id,
  }),
  request: () => ({ type: POST_RESEND_INVITATIONS.REQUEST }),
  success: (result) => ({ type: POST_RESEND_INVITATIONS.SUCCESS, result }),
  failure: (error) => ({ type: POST_RESEND_INVITATIONS.FAILURE, error }),
};

// ----------------------------------------------------------------------

export function* postInvitationSaga({ data }) {
  try {
    const url = apiPathFactory.invitationsPost();
    const response = yield call(() => fetchEntitySaga(postInvitation, api.post, url, { data }));
    return response;
  } catch (error) {
    return error;
  }
}

export function* deleteInvitationSaga({ id }) {
  try {
    const url = apiPathFactory.invitationsDelete(id);
    const response = yield call(() => fetchEntitySaga(deleteInvitation, api.del, url));
    yield call(getCustomerLicensesSaga, {});
    return response;
  } catch (error) {
    return error;
  }
}

export function* postResendInvitationsSaga({ id }) {
  try {
    const url = apiPathFactory.postResendInvitations(id);
    const response = yield call(() =>
      fetchEntitySaga(postResendInvitations, api.post, url, { toastNotShow: true })
    );
    yield put(showAlert('green', 1, i18n.t('http.invitation.resend')));
    return response;
  } catch (error) {
    return error;
  }
}

// -------------------------------------------------------------------------------------

function* watchPostInvitationSaga() {
  yield takeLatest(POST_INVITATION.BASE, postInvitationSaga);
}

// -------------------------------------------------------------------------------------

function* watchDeleteInvitationSaga() {
  yield takeLatest(DELETE_INVITATION.BASE, deleteInvitationSaga);
}

// -------------------------------------------------------------------------------------

function* watchPostResendInvitations() {
  yield takeLatest(POST_RESEND_INVITATIONS.BASE, postResendInvitationsSaga);
}

export default function* badRuleWarningWatchers() {
  yield fork(watchPostInvitationSaga);
  yield fork(watchDeleteInvitationSaga);
  yield fork(watchPostResendInvitations);
}
