import styled, { css } from 'styled-components';

export default styled.span`
  transition: top 0.2s, font-size 0.2s, left 0.2s;
  position: relative;
  top: -4px;
  left: 10;

  ${(props) =>
    (props.value !== '' || props.isFocused) && css
      ? `
    font-size: 12px;
    ${props.icon ? `left: -5px;` : ``}
    top: -26px;
    `
      : ` ${props.icon ? `left: 25px;` : ``}`}

  ${(props) => (props.isFocused && css ? `color: ${props.tenants.color1}` : ``)}
`;
