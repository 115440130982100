export default {
  errors: {
    title: {
      honoa: 'HONOA Mostra stato porta:',
    },
    402: 'Pagamento richiesto',
    1000: 'Accesso fallito',
    name: 'Questo nome non è disponibile',
    generic: 'Identificatore univoco già utilizzato',
    selectTypeFirst: 'Seleziona prima un tipo',
    unexpectedError: 'Errore inaspettato',
    uniqueIdentity: 'Questo contenuto esiste già',
    10: "Avvio della sezione di configurazione dell'API CloudAssistant",
    11: "Configurazione eseguita. L'API è pronta per le chiamate in arrivo",
    12: 'Imposta la configurazione della versione di Api',
    13: 'Impostazioni API generali',
    14: 'Impostazioni SMTP',
    15: 'Configurazione delle politiche di cookie',
    16: 'Imposta ambiente di compatibilità:',
    17: 'Impostazione del contesto db',
    18: 'Servizi di autenticazione',
    19: 'Aggiungi documentazione spavalderia',
    100: 'Utente connesso',
    101: 'Utente disconnesso',
    102: 'Password modificata correttamente',
    103: 'Password dimenticata completata',
    104: 'Processo di reimpostazione della password completato',
    105: 'Invio e-mail a:',
    106: 'Utente non valido',
    107: 'Utente non aggiornato',
    108: 'Invito scaduto',
    109: 'Invito non cancellato',
    110: 'Utente non confermato e aggiornato in IS4',
    111: 'Utente non registrato correttamente',
    112: 'Mancata corrispondenza ID utente o cliente',
    113: "L'ID cliente non esiste",
    114: 'Invito non inviato',
    115: "L'invito è stato cancellato",
    116: 'Invito di rollback completato',
    117: 'Attributi del cliente non creati correttamente',
    118: 'Autorizzazioni insufficienti per mostrare questi elementi',
    119: 'Autorizzazioni insufficienti per mostrare o aggiornare questi elementi',
    120: 'Autorizzazioni insufficienti per la creazione di questi elementi',
    121: "Autorizzazioni insufficienti per l'eliminazione di questi elementi",
    122: "Utente non cancellato nell'identità",
    123: 'Utente non trovato',
    124: "Conflitto durante l'eliminazione dell'utente nell'identità",
    125: 'Fallimento della concorrenza',
    126: 'Errore predefinito',
    127: 'Email già esistente',
    128: 'Il nome del ruolo esiste già',
    129: 'Il nome utente esiste già',
    130: 'E-mail non valido',
    131: 'Nome ruolo non valido',
    132: 'Gettone non valido',
    133: 'Nome utente non valido',
    134: 'Login già associato',
    135: 'La password richiede lettere minuscole',
    136: 'La password richiede valori non alfanumerici',
    137: 'La password richiede caratteri univoci',
    138: 'La password richiede lettere maiuscole',
    139: 'Password troppo corta',
    140: 'Rimborso del codice di recupero non riuscito',
    141: "L'utente ha già una password",
    142: "L'utente ha già un ruolo",
    143: 'Utente senza ruolo',
    144: 'Blocco utente non abilitato',
    145: 'Autenticazione non riuscita, ID client non valido o nascosto',
    146: 'Richiesta non valida',
    147: 'E-mail o password non sono corretti',
    148: 'Ambito non valido',
    149: 'Questo cliente non è autorizzato a utilizzare il tipo di richiesta richiesta',
    150: 'Tipo di concessione non supportato',
    151: "Utente non cancellato nell'identità",
    152: "Conflitto durante l'eliminazione dell'utente nell'identità",
    153: 'Abbonamenti non aggiornati',
    154: 'Le iscrizioni sono state aggiornate',
    155: 'Errore durante il recupero degli abbonamenti',
    156: 'Il cliente ha gruppi o strutture universali con codice installatore',
    157: 'Errore sconosciuto',
    158: 'Autorizzazioni insufficienti',
    159: "L'e-mail dell'utente esiste già",
    160: 'Abbonamenti insufficienti',
    161: 'Ruoli utente non validi',
    162: 'Ruolo utente non aggiornato',
    163: 'Utente non registrato o senza abbonamenti',
    164: 'Ruoli utente o cliente non validi',
    165: "L'amministratore non può essere aggiornato",
    166: 'Codice installatore non aggiornato',
    167: 'Famiglie di prodotti non aggiornate',
    168: 'Prodotti non aggiornati',
    169: 'Nessun elemento trovato con questo ID',
    170: 'Aggiorna token',
    171: 'Abbonamenti insufficienti',
    172: 'Dispositivo non trovato',
    200: "Ottieni l'elenco di tutti gli utenti",
    300: 'Argomenti errati o numero di elementi superato per una singola richiesta',
    301: 'Aggiornamento completato con successo',
    302: 'Errore di convalida',
    400: 'Inquilino salvato con successo',
    401: 'Mancano alcuni dati degli inquilini! Salva senza successo',
    404: 'Dispositivo offline o errore di connessione.',
    9000: 'Accesso fallito',
    9001: 'Reimpostazione password non riuscita',
    9002: 'Password dimenticata fallita',
    9003: 'Modifica password non riuscita',
    9004: 'Mancata corrispondenza password',
    9005: 'La password richiede almeno una cifra',
    9006: 'Utente sconosciuto',
    90000: 'È stato richiesto il test di loopback:',
    90001: "È stata richiesta un'eccezione:",
    90002: 'È stato richiesto il codice di stato del reso:',
    90100: 'Sblocca eccezione SQL',
    90101: 'Creazione fallita.',
    90102: 'Il nome che hai inserito esiste già, devi cambiarlo',
    90103: 'Non trovato.',
    90104: 'Aggiornamento non riuscito',
    90105: 'Eliminazione non riuscita',
    90106: 'Questo contenuto esiste già',
    90107: 'Il contenuto non può essere nullo.',
    90108: 'Importazione non riuscita.',
    90109: 'Esportazione non riuscita.',
    90110:
      'Impossibile aggiornare il codice del programma di installazione nelle strutture a cui sono stati assegnati i dispositivi',
    90111: "L'ID o l'URL della struttura non è valido",
    90112: 'Gruppo entità struttura non creato',
    90113: 'Il gruppo di strutture deve avere un nome',
    90114: 'Il nome del gruppo di entità della struttura esiste già per questa struttura',
    90115: 'Gruppo entità struttura non aggiornato',
    90116: 'Gruppo entità struttura non cancellato',
    90117: "Il contatto dell'entità non può essere nullo",
    90118: 'Entità della struttura non aggiornata',
    90119: "La descrizione o il codice dell'entità struttura esiste già per questo gruppo",
    90120: 'Impossibile eliminare il contatto con la posizione 0',
    90121: 'Contatto non cancellato',
    90122: 'Contatto non creato',
    90123: "L'entità ha già 5 contatti",
    90124: 'Contatto entità non aggiornato',
    90125: 'GroupId non esiste per questa funzione',
    90126: 'FacilityId non valido',
    90127: 'Contatto entità struttura non valido',
    90128: 'Il codice installatore deve essere lo stesso per ogni struttura del gruppo universale',
    90129: 'Il codice installatore deve essere lo stesso per struttura e gruppo universale',
    91000: 'Mac VDP o ID non trovato',
    91001: 'Mac non valido',
    91002: 'Estensione e-mail non trovata',
    91003: "Errore durante l'aggiornamento del nome vdp",
    91004: 'Errore durante la creazione della relazione di contatto vdp',
    91005: 'Errore durante la creazione del contatto',
    91006: "Errore durante l'eliminazione dei contatti",
    91007: "Errore durante l'aggiornamento del nome del contatto",
    91008: 'Il nome del cliente esiste già',
    91009: 'Codice flat o porta o non valido',
    91010: 'Codice non introdotto',
    91011: 'Numero di canali danneggiati',
    91012: "L'ultima relazione di inoltro non può essere eliminata",
    91013: 'Impossibile disabilitare il relè perché ha gruppi assegnati',
    91014: 'Intervallo di codici non valido',
    91015: 'I codici devono essere dello stesso tipo',
    91016: 'Posizioni insufficienti nel gruppo',
    91017: 'Il codice esiste già nella struttura',
    91018: 'Il codice esiste già negli universali',
    91019: 'Il codice è una sostituzione di un universale cancellato',
    91020: 'Questa apparecchiatura della struttura non supporta i programmi',
    91021: 'Niente da copiare',
    91022: 'Niente da spostare',
    91023: 'Prodotto non valido',
    91024: 'Programma non trovato',
    91025: 'Impossibile eliminare la pianificazione con una funzione assegnata',
    91026: 'Il gruppo richiesto non è universale',
    91027: "L'apparecchiatura ha alcuni parametri non validi",
    91028: 'Impossibile eliminare un gruppo universale con funzioni assegnate',
    91029: 'Numero di serie o chiave univoca non registrata',
    91030: 'Il numero di serie o la chiave univoca non possono essere nulli',
    91031: 'Il codice client non è corretto',
    91032: 'Il codice installatore non è corretto',
    91033: 'La password non è corretta',
    91034: 'File corrotto',
    91065: 'link già assegnato',
    91066: 'Si è verificato un errore durante la disattivazione del collegamento',
    91067: 'Chiave di attivazione errata',
    91068: 'Richiesta API non riuscita',
    91069: 'Prodotto non abilitato',
    91070: 'Prodotto non trovato',
    91071: 'Nessun relè selezionato. Utilizzare il pulsante di configurazione',
    91072: 'Il nome del relè esiste già',
    91073: 'Numero massimo di installazioni raggiunto.',
    91074: 'Questa chiave di attivazione è utilizzata da un altro utente.',
    91100: 'Dispositivo disconnesso',
    91101: 'Gemelli dispositivo di lettura eccezioni non gestite',
    91102: 'Leggi eccezione dalla memoria',
    91103: 'Scrivi eccezione dalla memoria',
    91104: 'La memoria di scrittura non corrisponde',
    91105: 'Il dispositivo ha codice installatore',
    91106: 'Il dispositivo non ha codice installatore',
    91302: 'Le informazioni sulla SIM non sono disponibili',
    91303: 'Il contratto SIM non è disponibile',
    91307: 'Impossibile ottenere il numero di serie',
    91308: 'Chiave di attivazione zero o vuota',
    91309: 'Chiave di attivazione nulla, vuota o non valida',
    91201: "L'utente esiste già. Tutti i dati saranno ereditati",
    91452: 'Il dispositivo non supporta orari con vacanze',
    91527: 'Impossibile eliminare impianto con gruppo universale assegnato.',
    91528: 'Impossibile eliminare dispositivo con gruppo assegnato.',
    91529: 'Impossibile eliminare gruppo con emettitori.',
    91203: "L'utente esiste già in questa struttura",
    universalGroupsAssigned: 'UG assegnati: ',
    groupsAssigned: 'Gruppi assegnati: ',
    operationNotCompleted: "Impossibile completare l'operazione",
    redirectWarning: 'Sei stato reindirizzato alla pagina principale',
    noContent: 'Nessun contenuto disponibile per questo elenco',
    communicationError: 'Errore di comunicazione riprovare più tardi',
    91530:
      "Non è possibile deallocare questo apparecchio giacché l'apparecchio rimanente non consente l'uso di emettitori.",
    91531: "L'installazione non può essere riassegnata in quanto utilizza gruppi universali",
    91532: "L'installazione contiene apparecchi incompatibili",
    91534: 'Massimo numero di dispositivi Honoa raggiunti da questa installazione ',
    91310: "Questo seriale non è assegnato all'utente",
    91311: 'Errore di comunicazione con il servizio prod',
    91312: 'Errore nel generare la licenza',
    91400: 'Avviso di cancellazione dell’errore',
    91450: 'I seguenti orari non hanno un intervallo valido: ',
    91451: 'Deve essere selezionato almeno un giorno di vacanza',
    91452: 'Un dispositivo utilizzato non supporta gli orari con le vacanze',
    91203: "L'utente esiste già in questa struttura",
    91537: 'Non è possibile eliminare un gruppo con utenti Honoa',
    91453: "il nome dell'orario esiste già",
    91536: "il nome dell'installazione esiste già",
    91800: 'Formato numero di telefono non supportato',
    91801: 'Formato URL web non supportato',
    needsMinOnRelay: 'Deve avere almeno un relè assegnato',
    91802: 'Questo dispositivo non supporta emittenti',
    91803: 'Questo gruppo non supporta emittenti',
    nameRequired: 'Impossibile salvare. Nomi obbligatori nelle famiglie e nei prodotti.',
    deletedUser: 'Utente eliminato',
    91804: 'Il nome di uno o più orari assegnati a questa installazione è troppo lungo',
    91538: 'Errore durante il download del PDF',
    91853: 'Il campo della fascia oraria non può restare vuoto.',
    91539: 'I dispositivi in uso negli impianti non possono essere disattivati.',
    zeroCode: 'Attenzione! Si sta utilizzando un codice di installazione 0',
    91540:
      "Non è possibile annullare l'assegnazione del dispositivo poiché il gruppo contiene utenti HONOA",
    91854: 'Il gruppo non supporta utenti HONOA',
    92030: 'Il suo abbonamento non è compatibile non detta funzione.',
    92060: 'La data finale deve essere successiva a quella iniziale',
    92061: 'La data iniziale deve essere successiva a quella odierna',
    92031: 'Funzione non consentita senza finecorsa (sensori/Immettersi)',
    91075: 'Prenotazioni non abbastanza disponibili',
    304: 'Letterale non è valido (spazi vuoti, spazi iniziali o finali, ...)',
  },
};
