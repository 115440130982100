import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ContentContainer from '../../ContentContainer';
import TabMenu from '../../TabMenu';
import ChannelsCheckboxes from './ChannelsCheckboxes';

export class InstalationRelayContent extends Component {
  onChange = ({ relay, channelsMap }) => {
    const { secondaryId, principalId, principalParameter, secondaryParameter, putRelay } =
      this.props;
    const newRelay = { ...relay, channelsMap };
    putRelay({
      [principalParameter]: principalId,
      [secondaryParameter]: secondaryId,
      relay: newRelay,
    });
  };

  canUpdateRelay = (channelsMap) => {
    const { instalationsGroupRelays } = this.props;
    let activeCount = 0;
    instalationsGroupRelays.forEach((element) => {
      element.relays.forEach((relay) => {
        if (relay.channelsMap !== 0) {
          activeCount += 1;
        }
      });
    });
    return !(activeCount === 1 && channelsMap === 0);
  };

  getRelayTabs = (equipment) => {
    const { onChange } = this;
    const { canUpdateRelay } = this;
    const { secondaryItem, principalItem } = this.props;
    const tabs = equipment.relays.map((relay, i) => ({
      title: relay.relayName,
      bodyComponent: (props) => <ChannelsCheckboxes {...props} />,
      haveBorder: true,
      componentState: {
        relay: {
          ...relay,
          equipmentId: equipment.id,
          groupName: secondaryItem?.name,
          instalationName: principalItem?.name,
        },
        onChange,
        canUpdateRelay,
      },
      active: i === 0,
    }));
    return [...tabs];
  };

  render() {
    const { relay, className } = this.props;
    return (
      <div key={relay.name} className={`relays-divs ${className || ''}`}>
        <ContentContainer tenants={this.props.tenants} title={relay.name}>
          <TabMenu tabType='tabs' tabsData={this.getRelayTabs(relay)} />
        </ContentContainer>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const instalationRelayContent = withRouter(InstalationRelayContent);

export default connect(mapStateToProps, {})(instalationRelayContent);
