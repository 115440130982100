import { Flex } from '@jcm-technologies/uikit/dist/atoms/Layout';
import IconFilter from '@jcm-technologies/uikit/dist/atoms/Icons/IconFilter';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Text } from '@jcm-technologies/uikit/dist/atoms/Typography';
import { Field } from '@jcm-technologies/uikit/dist/atoms/Field';
import { Icon } from '@jcm-technologies/uikit/dist/atoms/Icon';
import { Input } from '@jcm-technologies/uikit/dist/atoms/Input';
import { SelectInput as Select } from '@jcm-technologies/uikit/dist/atoms/Select';
import { Card } from '@jcm-technologies/uikit/dist/molecules/Card';
import IconSearch from '@jcm-technologies/uikit/dist/atoms/Icons/IconSearch';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { getVisibleDescendants } from '../../../modules/old_to_refact/actions/universalGroups';
import { setSearchQuery } from '../../../modules/state/visibleDescendants/actions';

export function FiltersList({ searchAction, descendantsAction, listType, isExpanded = false }) {
  const { searchQuery, selectedDescendants } = useSelector((state) => state.visibleDescendants);
  const { color1 } = useSelector((state) => state.tenants);
  const { visibleDescendants } = useSelector((state) => state.universalGroups);
  const [isOpened, setIsOpened] = useState(isExpanded || false);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!visibleDescendants) {
      dispatch(getVisibleDescendants());
    }
  }, []);

  return (
    <>
      <Flex
        justifyContent='flex-end'
        alignItems='center'
        width='100%'
        style={{ cursor: 'pointer' }}
      >
        <Flex
          alignItems='center'
          onClick={() => {
            setIsOpened(!isOpened);
          }}
        >
          <Icon sizeIcon='display16' cursor='pointer' color='blackLight'>
            <IconFilter />
          </Icon>{' '}
          <Text marginLeft={1} color='blackLight'>
            {t('general.filters')}
          </Text>{' '}
        </Flex>
      </Flex>
      {isOpened && (
        <Card rounded='soft' marginTop={1} marginBottom={1}>
          {searchAction && (
            <Field label={t('general.search')} marginTop={0}>
              <Input
                id='searchForm'
                name='searchForm'
                sizeInput='small'
                value={searchQuery?.replace(/%23/g, '#').replace(/%26/g, '&')}
                placeholder={t('general.searchDotted')}
                iconRight={<IconSearch />}
                iconRightAction={() => searchAction(searchQuery)}
                onChange={(name, value) => {
                  dispatch(setSearchQuery(value || '', !!value));
                }}
              />
            </Field>
          )}

          {descendantsAction && (
            <Field label={t('form.descendants')}>
              <Select
                id='descendantsSelectFilter'
                isMulti
                themeColor={color1}
                defaultValue={selectedDescendants[listType]}
                value={selectedDescendants[listType]}
                placeholderMessage={t('general.selectEntityDotted', {
                  entity: t('form.descendants')?.toLowerCase(),
                })}
                options={visibleDescendants?.map((el) => ({
                  label: el.name,
                  value: el.id,
                }))}
                onChange={(name, selected) => descendantsAction(selected)}
              />
            </Field>
          )}
        </Card>
      )}
    </>
  );
}

FiltersList.propTypes = {
  searchAction: PropTypes.func,
  descendantsAction: PropTypes.func,
  listType: PropTypes.string,
  isExpanded: PropTypes.bool,
};
