export default {
  changePassword: {
    title: 'Cambiare la password',
    submitText: 'Cambia la password',
    currentPassword: 'Password attuale',
    newPassword: 'Nuova password',
    confirmPassword: 'Conferma password',
    cancel: 'Annulla',
    chooseFile: 'Scegli il file',
    newPasswordPopupTitle: 'Imposta una nuova password',
  },
};
