import styled, { css } from 'styled-components';

export default styled.span`
  transition: top 0.2s ease, font-size 0.2s ease, left 0.2s ease;
  position: relative;
  top: -4px;
  left: 0;

  ${(props) =>
    (props.value || props.isFocused) && css
      ? `
    font-size: 12px;
    top: -26px;
    ${props.icon ? `left: -30px;` : ``}
    `
      : ``}

  ${(props) =>
    props.isFocused && css ? `color: ${props.tenants ? props.tenants.color1 : ''}` : ``}
`;
