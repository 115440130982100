import React from 'react';
import { Box, Flex } from '@jcm-technologies/uikit/dist/atoms/Layout';
import { Icon } from '@jcm-technologies/uikit/dist/atoms/Icon';
import IconChartLine from '@jcm-technologies/uikit/dist/atoms/Icons/IconChartLine';
import { Text } from '@jcm-technologies/uikit/dist/atoms/Typography';
import './Statistics.css';
import { SelectInput } from '@jcm-technologies/uikit/dist/atoms/Select';
import {
  Chart,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartSeries,
  ChartSeriesItem,
  ChartTitle,
} from '@progress/kendo-react-charts';
import { withTranslation } from 'react-i18next';

const StatisticsCharts = ({ actionSelected, baseUnits, onSetAction, t, data }) => (
  <Flex width='100%' flexDirection='column'>
    <Flex alignItems='center' width='100%'>
      <Icon sizeIcon='display24' marginRight={2}>
        <IconChartLine />
      </Icon>
      <Text style={{ flex: '1' }}>{t('title.statistics')}</Text>
    </Flex>
    <Box marginTop={3} width='100%'>
      <SelectInput
        id='units'
        defaultValue={
          actionSelected.label == 'r1Activations'
            ? (actionSelected.label = t('selectOption.r1Activations'))
            : (actionSelected.label = actionSelected.label)
        }
        onChange={(name, value) => {
          onSetAction({ label: value.label, value: value.value });
        }}
        options={baseUnits?.map((option) => ({
          value: option.value,
          label: t(`selectOption.${option.label}`),
        }))}
        placeholderMessage={t('general.selectOne')}
      />
    </Box>
    <Flex justifyContent='center' flex={1} flexDirection='column' marginTop={3} width='100%'>
      {data?.length != 0 && (
        <Chart>
          <ChartTitle
            text={
              actionSelected.label == 'r1Activations'
                ? t('selectOption.r1Activations')
                : t(`${actionSelected.label}`)
            }
          />
          <ChartSeries>
            <ChartSeriesItem
              type='column'
              field={actionSelected.value}
              categoryField='Data'
              data={data}
              tooltip={{
                visible: true,
              }}
            />
            <ChartSeriesItem
              type='line'
              field={actionSelected.value}
              categoryField='Data'
              data={data}
            />
          </ChartSeries>
          <ChartCategoryAxis>
            <ChartCategoryAxisItem
              labels={{
                format: 'd',
                rotation: -45,
              }}
            />
          </ChartCategoryAxis>
        </Chart>
      )}
    </Flex>
  </Flex>
);

const StatisticsChartsTemplate = withTranslation()(StatisticsCharts);

export default StatisticsChartsTemplate;
