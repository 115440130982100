import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import GenericForm from '../GenericForm';
import './ExportGroupsFormPopUpContent.css';
import { Modal } from '@jcm-technologies/uikit/dist/molecules/Modal';
import { Box, Flex } from '@jcm-technologies/uikit/dist/atoms/Layout';
import { Field } from '@jcm-technologies/uikit/dist/atoms/Field';
import { Switch } from '@jcm-technologies/uikit/dist/atoms/Switch';
import { Button } from '@jcm-technologies/uikit/dist/atoms/Button';
import { getContrastColor } from '../../core/helpers/colorManager';

export const ExportGroupsFormPopUpContent = ({ isOpen, onCloseForm, onSubmit }) => {
  const { t } = useTranslation();
  const groupList = useSelector((state) => state.instalationGroups.facilityGroups);
  const tenants = useSelector((state) => state.tenants);
  const [data, setData] = useState({
    includeReservations: false,
    includeConfigFields: false,
    modifiedGroups: groupList?.map((dataItem) => ({ selected: false, ...dataItem })) || [],
  });

  const handleChange = (isChecked, name) => setData({ ...data, [name]: isChecked });

  const handleSubmit = (e) => {
    e?.preventDefault();
    onSubmit(data);
    onCloseForm(false);
  };

  const handleCancel = (e) => {
    e?.preventDefault();
    onCloseForm(false);
  };
  const changeGroupSelection = (event) => {
    const dataModification = data.modifiedGroups.map((item) => {
      if (item.id === event.dataItem.id) {
        item.selected = !event.dataItem.selected;
      }
      return item;
    });
    handleChange(dataModification, 'modifiedGroups');
  };

  useEffect(() => {
    if (groupList) {
      setData({
        ...data,
        modifiedGroups: groupList?.map((dataItem) => ({ selected: false, ...dataItem })) || [],
      });
    }
  }, [groupList]);

  return (
    <Modal
      isOpen={isOpen}
      onModalClose={handleCancel}
      title={t('instalations.exportPopUpTitle')}
      closeWithButton
      id='modal-export-groups'
      width={[300, 500, 664]}
    >
      <Modal.Content>
        <Flex maxHeight='50vh' overflow='hidden'>
          <Box overflow='auto'>
            <GenericForm
              isSubmitHidden
              className='export-group-popup'
              tenants={tenants}
              fields={[
                [
                  {
                    type: 'selectableKendoGrid',
                    id: 'selectableGroupsGrid',
                    groupsList: data.modifiedGroups,
                    changeGroupSelection,
                  },
                ],
              ]}
            />
          </Box>
        </Flex>
      </Modal.Content>
      <Modal.Actions>
        <Flex flexDirection='column' justifyContent='center' marginBottom={2}>
          <Field
            label={t('instalations.includeReservations')}
            id='includeReservations'
            display='flex'
            style={{ justifyContent: 'flex-end' }}
          >
            <Switch
              sizeInput='small'
              defaultValue={data.includeReservations}
              themeColor={tenants?.color1}
              action={(isChecked) => {
                handleChange(isChecked, 'includeReservations');
              }}
              marginLeft={2}
              id='includeReservations'
            />
          </Field>
          <Field
            label={t('instalations.includeConfigFields')}
            id='includeConfigFields'
            display='flex'
            style={{ justifyContent: 'flex-end' }}
          >
            <Switch
              sizeInput='small'
              defaultValue={data.includeConfigFields}
              themeColor={tenants?.color1}
              action={(isChecked) => {
                handleChange(isChecked, 'includeConfigFields');
              }}
              marginLeft={2}
              id='includeConfigFields'
            />
          </Field>
        </Flex>
        <Flex alignItems='center' justifyContent='flex-end'>
          <Button
            id='exportGroupsAction'
            sizeButton='small'
            backgroundColor={tenants?.color1}
            action={() => {
              handleSubmit();
            }}
            marginRight={2}
            color={getContrastColor(tenants?.color1)}
          >
            {t('instalations.acceptExportPopUpButton')}
          </Button>
          <Button backgroundColor='blackLight' id='cancel-button' sizeButton='small'>
            {t('popupConfirm.cancel')}
          </Button>
        </Flex>
      </Modal.Actions>
    </Modal>
  );
};
