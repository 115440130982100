import React, { useEffect, useState } from 'react';
import { Box } from '@jcm-technologies/uikit/dist/atoms/Layout';
import { useDispatch, useSelector } from 'react-redux';
import { detect } from 'detect-browser';
import ip from 'ip';
import moment from 'moment';
import { useMsal } from '@azure/msal-react';
import { NotificationAlert } from '@jcm-technologies/uikit/dist/molecules/Notifications/Alert';
import { ToastContainer } from 'react-toastify';
import * as Sentry from '@sentry/react';
import Breadcrumbs from '../../../old_components/Breadcrumbs';
import VersionPopUp from '../../../old_components/Layout/components/VersionPopUp';
import PolicyAgreementPopUp from '../../../old_components/Layout/components/PolicyAgreementPopUp';
import ToolTip from '../../../old_components/ToolTip';
import {
  resetTimeouts,
  setGDPRPopupIsOpen,
  setVersionPopupIsOpen,
} from '../../../modules/old_to_refact/actions/login';
import {
  deleteCookieAll,
  getCookieAgreementPending,
  getCookieShowVersionModal,
  saveCookies,
  setCookie,
} from '../../../core/old_common/utils/cookiesManager';
import versionDictionary from '../../../old_components/Layout/components/VersionPopUp/index';
import { postOwnerGDPR } from '../../../modules/owner/action';
import { HeaderNavbar } from '../Header';
import { showAlert } from '../../../modules/state/alerts';

export const Main = ({ children }) => {
  const { instance } = useMsal();
  const dispatch = useDispatch();

  const alert = useSelector((state) => state.alert);
  const tenants = useSelector((state) => state.tenants);
  const isCollapsed = useSelector((state) => state.app.isSidebarCollapsed);
  const { manualShowVersionPopup, agreementPending, showGDPRPopup } = useSelector(
    (state) => state.login
  );
  const {
    user: { email, infoVersion },
  } = useSelector((state) => state.user);

  const [showPopUpVersionInfo, setShowPopUpVersionInfo] = useState(false);
  const [userAgreement, setUserAgreement] = useState(false);
  const agreementPendingCookie = getCookieAgreementPending();

  useEffect(() => {
    const versionFromJson = versionDictionary.default[0].Version;
    if (infoVersion) {
      if (versionFromJson === infoVersion && !manualShowVersionPopup) {
        setShowPopUpVersionInfo(false);
      } else if (!manualShowVersionPopup && !getCookieShowVersionModal()) {
        setShowPopUpVersionInfo(false);
      } else {
        setShowPopUpVersionInfo(true);
      }
    }

    if (showGDPRPopup) {
      setUserAgreement(showGDPRPopup);
    } else if (agreementPending) {
      setUserAgreement(agreementPending);
    } else {
      setUserAgreement(agreementPendingCookie);
    }
  }, [infoVersion, agreementPendingCookie, manualShowVersionPopup]);

  const handleLogout = () => {
    deleteCookieAll();
    Sentry.setUser(null);
    dispatch(resetTimeouts());
    instance.logoutRedirect();
  };

  const acceptVersion = () => {
    if (manualShowVersionPopup) {
      dispatch(setVersionPopupIsOpen(false));
    } else {
      setCookie('hideVerInfo', versionDictionary.default[0].Version);
    }
  };

  const onClosePopUp = () => {
    if (manualShowVersionPopup) {
      dispatch(setVersionPopupIsOpen(false));
    } else {
      setShowPopUpVersionInfo(false);
      saveCookies({ showVersionModal: false });
      setCookie('hideVerInfo', versionDictionary.default[0].Version);
    }
  };

  const onCloseGDPR = () => {
    dispatch(setGDPRPopupIsOpen(false));
  };

  const acceptGDPR = () => {
    const browser = detect();
    const gdprAgreement = {
      browser_agent: browser.name,
      userName: email,
      os: browser.os,
      iPv4_source: ip.address(),
      version: '1.0',
      created_at: moment().format('YYYY-MM-DDTHH:mm:ss.zzz'),
    };
    dispatch(postOwnerGDPR(gdprAgreement));
  };

  return (
    <>
      <NotificationAlert
        isOpened={alert?.show}
        colorType={alert?.colorType}
        message={alert?.message}
        setIsOpened={() => dispatch(showAlert('', -1, ''))}
        autoClose
        width={300}
      />
      <ToastContainer />
      <Box
        as='main'
        minHeight='100vh'
        width={[
          'calc(100% - 70px)',
          'calc(100% - 70px)',
          isCollapsed ? '100%' : 'calc(100% - 260px)',
        ]}
        marginLeft={[70, 70, isCollapsed ? 74 : 260]}
        position='relative'
      >
        <HeaderNavbar />
        <Box
          backgroundColor={tenants?.backgroundimage ? 'transparent' : 'whiteSmooth'}
          minHeight='calc(100vh - 48px)'
          marginTop={48}
          paddingTop={3}
          paddingX={3}
          paddingBottom={2}
        >
          <Breadcrumbs />
          {children}
          <VersionPopUp
            handleAccept={() => acceptVersion()}
            isOpen={showPopUpVersionInfo && !userAgreement}
            onClosePopUp={() => onClosePopUp()}
          />
          <PolicyAgreementPopUp
            onClose={onCloseGDPR}
            handleAccept={acceptGDPR}
            handleCancel={handleLogout}
            isOpen={userAgreement}
            closeOnDocumentClick={false}
            tenants={tenants}
          />
          <ToolTip />
        </Box>
      </Box>
    </>
  );
};
