import { call, fork, takeLatest } from 'redux-saga/effects';
import a5KservicesActions from '../actions/a5Kservices';
import fetchEntitySaga from './utils/fetchEntitySaga';
import apiPathFactory from '../../../core/old_common/rooting/apiPathFactory';
import api from '../../../core/helpers/api';
import {
  a5K_COMUNICATIONS_GET,
  a5K_FORMAT_EVENTS_POST,
  a5K_ONLINE_DEVICES_GET,
  a5K_POST_GET_EVENTS,
  a5K_READ_EVENTS_POST,
  a5K_READ_MEMORY_POST,
  a5K_SET_MEMORY_POST,
} from '../reducers/a5Kservices';
import { a5K_POST_COMPARE_FOBS } from '../reducers/drivers';

export const getRemoteComunication = {
  base: (facilityId, equipmentId) => ({
    facilityId,
    equipmentId,
    type: a5K_COMUNICATIONS_GET.BASE,
  }),
  request: () => ({ type: a5K_COMUNICATIONS_GET.REQUEST }),
  success: (result) => ({ type: a5K_COMUNICATIONS_GET.SUCCESS, result }),
  failure: (error) => ({ type: a5K_COMUNICATIONS_GET.FAILURE, error }),
};

export const postGetEvents = {
  base: (facilityId, equipmentId, file) => ({
    facilityId,
    equipmentId,
    type: a5K_POST_GET_EVENTS.BASE,
    file,
  }),
  request: () => ({ type: a5K_POST_GET_EVENTS.REQUEST }),
  success: (result) => ({ type: a5K_POST_GET_EVENTS.SUCCESS, result }),
  failure: (error) => ({ type: a5K_POST_GET_EVENTS.FAILURE, error }),
};

export const postCompareFobs = {
  base: (facilityId, equipmentId, cc, ic, file) => ({
    facilityId,
    equipmentId,
    type: a5K_POST_COMPARE_FOBS,
    cc,
    ic,
    file,
  }),
  request: () => ({ type: a5K_POST_COMPARE_FOBS.REQUEST }),
  success: (result) => ({ type: a5K_POST_COMPARE_FOBS.SUCCESS, result }),
  failure: (error) => ({ type: a5K_POST_COMPARE_FOBS.FAILURE, error }),
};

export const getOnlineServices = {
  base: (facilityId, equipmentId) => ({
    facilityId,
    equipmentId,
    type: a5K_ONLINE_DEVICES_GET.BASE,
  }),
  request: () => ({ type: a5K_ONLINE_DEVICES_GET.REQUEST }),
  success: (result) => ({ type: a5K_ONLINE_DEVICES_GET.SUCCESS, result }),
  failure: (error) => ({ type: a5K_ONLINE_DEVICES_GET.FAILURE, error }),
};

export const postFormatEvents = {
  base: (facilityId, equipmentId, cmdParameters) => ({
    facilityId,
    equipmentId,
    type: a5K_FORMAT_EVENTS_POST.BASE,
    cmdParameters,
  }),
  request: () => ({ type: a5K_FORMAT_EVENTS_POST.REQUEST }),
  success: (result) => ({ type: a5K_FORMAT_EVENTS_POST.SUCCESS, result }),
  failure: (error) => ({ type: a5K_FORMAT_EVENTS_POST.FAILURE, error }),
};

export const postReadEvents = {
  base: (facilityId, equipmentId, cmdParameters) => ({
    type: a5K_READ_EVENTS_POST.BASE,
    facilityId,
    equipmentId,
    cmdParameters,
  }),
  request: () => ({ type: a5K_READ_EVENTS_POST.REQUEST }),
  success: (result) => ({ type: a5K_READ_EVENTS_POST.SUCCESS, result }),
  failure: (error) => ({ type: a5K_READ_EVENTS_POST.FAILURE, error }),
};

export const postSetMemory = {
  base: (facilityId, equipmentId, cmdParameters) => ({
    type: a5K_SET_MEMORY_POST.BASE,
    facilityId,
    equipmentId,
    cmdParameters,
  }),
  request: () => ({ type: a5K_SET_MEMORY_POST.REQUEST }),
  success: (result) => ({ type: a5K_SET_MEMORY_POST.SUCCESS, result }),
  failure: (error) => ({ type: a5K_SET_MEMORY_POST.FAILURE, error }),
};

export const postReadMemory = {
  base: (facilityId, equipmentId, cmdParameters, ic, cc) => ({
    type: a5K_READ_MEMORY_POST.BASE,
    facilityId,
    equipmentId,
    cmdParameters,
    ic,
    cc,
  }),
  request: () => ({ type: a5K_READ_MEMORY_POST.REQUEST }),
  success: (result) => ({ type: a5K_READ_MEMORY_POST.SUCCESS, result }),
  failure: (error) => ({ type: a5K_READ_MEMORY_POST.FAILURE, error }),
};

export function* getRemoteComunicationsSaga({ facilityId, equipmentId }) {
  try {
    const url = apiPathFactory.a5KservicesRemoteComunicationsGet(facilityId, equipmentId);
    const response = yield call(() => fetchEntitySaga(getRemoteComunication, api.get, url));
    return response;
  } catch (error) {
    return error;
  }
}

export function* getOnlineServicesSaga(facilityId, equipmentId) {
  try {
    const url = apiPathFactory.a5kGetOnlineDevices(facilityId, equipmentId);
    const response = yield call(() => fetchEntitySaga(getOnlineServices, api.get, url));
    return response;
  } catch (error) {
    return error;
  }
}

export function* postReadMemorySaga({ facilityId, equipmentId, cmdParameters, ic, cc }) {
  try {
    const url = apiPathFactory.a5KservicesReadMemoryPost(facilityId, equipmentId, ic, cc);
    const response = yield call(() =>
      fetchEntitySaga(postReadMemory, api.post, url, { data: cmdParameters })
    );
    return response;
  } catch (error) {
    return error;
  }
}

export function* postSetMemorySaga({ facilityId, equipmentId, cmdParameters, ic, cc }) {
  try {
    const url = apiPathFactory.a5KservicesSetMemoryPost(facilityId, equipmentId, ic, cc);
    const response = yield call(() =>
      fetchEntitySaga(postSetMemory, api.post, url, { data: cmdParameters })
    );
    return response;
  } catch (error) {
    return error;
  }
}

export function* postReadEventsSaga({ facilityId, equipmentId, cmdParameters }) {
  try {
    const url = apiPathFactory.a5KservicesReadEventsPost(facilityId, equipmentId);
    const response = yield call(() =>
      fetchEntitySaga(postReadEvents, api.post, url, { data: { cmdParameters } })
    );
    return response;
  } catch (error) {
    return error;
  }
}

export function* postFormatEventsSaga({ facilityId, equipmentId, cmdParameters }) {
  try {
    const url = apiPathFactory.a5KservicesFormatEventsPost(facilityId, equipmentId);
    const response = yield call(() =>
      fetchEntitySaga(postFormatEvents, api.post, url, { data: cmdParameters })
    );
    return response;
  } catch (error) {
    return error;
  }
}

export function* postGetEventsSaga({ facilityId, equipmentId, file, index }) {
  try {
    const url = apiPathFactory.a5kGetEvents(facilityId, equipmentId, index);
    const response = yield call(() =>
      fetchEntitySaga(postGetEvents, api.post, url, {
        formData: [{ fieldName: 'file', file }],
        toastNotShow: true,
      })
    );
    return response;
  } catch (error) {
    return error;
  }
}

export function* postCompareFobsSaga({ facilityId, id, cc, ic, file }) {
  try {
    const url = apiPathFactory.a5kPostCompareFobs(facilityId, id, cc, ic);
    const response = yield call(() =>
      fetchEntitySaga(postCompareFobs, api.post, url, {
        formData: [{ fieldName: 'file', file }],
        toastNotShow: true,
      })
    );
    return response;
  } catch (error) {
    return error;
  }
}

function* watchGetRemoteComunications() {
  yield takeLatest(
    a5KservicesActions.GET_5KSERVICES_REMOTE_COMUNICATIONS,
    getRemoteComunicationsSaga
  );
}

function* watchgetOnlineServices() {
  yield takeLatest(a5KservicesActions.GET_5KSERVICES_ONLINE_DEVICES, getOnlineServicesSaga);
}

function* watchPostReadMemory() {
  yield takeLatest(a5KservicesActions.POST_READ_MEMORY, postReadMemorySaga);
}

function* watchPostSetMemory() {
  yield takeLatest(a5KservicesActions.POST_SET_MEMORY, postSetMemorySaga);
}

function* watchPostReadEvents() {
  yield takeLatest(a5KservicesActions.POST_READ_EVENTS, postReadEventsSaga);
}

function* watchPostFormatEvents() {
  yield takeLatest(a5KservicesActions.POST_FORMAT_EVENTS, postFormatEventsSaga);
}

function* watchPostGetEvent() {
  yield takeLatest(a5KservicesActions.POST_GET_EVENTS, postGetEventsSaga);
}

function* watchPostCompareFobs() {
  yield takeLatest(a5KservicesActions.POST_5KSERVICES_COMPARE_FOBS, postCompareFobsSaga);
}

export default function* watchers() {
  yield fork(watchGetRemoteComunications);
  yield fork(watchgetOnlineServices);
  yield fork(watchPostFormatEvents);
  yield fork(watchPostReadMemory);
  yield fork(watchPostSetMemory);
  yield fork(watchPostReadEvents);
  yield fork(watchPostGetEvent);
  yield fork(watchPostCompareFobs);
}
