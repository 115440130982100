import { call, fork, takeLatest } from 'redux-saga/effects';
import fetchEntitySaga from '../old_to_refact/sagas/utils/fetchEntitySaga';
import apiPathFactory from '../../core/old_common/rooting/apiPathFactory';
import api from '../../core/helpers/api';
import {
  GET_CITIES,
  GET_DASHBOARD_FILTER,
  GET_DASHBOARD_FILTER_BY_TYPE,
  GET_DASHBOARD_INFO,
  GET_EQUIPMENTS_SEARCH,
  GET_POSTAL_CODE,
} from './constants';
import { GET_FIELD } from '../old_to_refact/reducers/instalations';


export const getDashboardFilter = {
  base: () => ({
    type: GET_DASHBOARD_FILTER.BASE,
  }),
  request: () => ({ type: GET_DASHBOARD_FILTER.REQUEST }),
  success: (result) => ({ type: GET_DASHBOARD_FILTER.SUCCESS, result }),
  failure: (error) => ({ type: GET_DASHBOARD_FILTER.FAILURE, error }),
};

export const getDashboardFilterByType = {
  base: (filterType) => ({
    type: GET_DASHBOARD_FILTER_BY_TYPE.BASE,
    filterType,
  }),
  request: (filterType) => ({ type: GET_DASHBOARD_FILTER_BY_TYPE.REQUEST, filterType }),
  success: (result) => ({ type: GET_DASHBOARD_FILTER_BY_TYPE.SUCCESS, result }),
  failure: (error) => ({ type: GET_DASHBOARD_FILTER_BY_TYPE.FAILURE, error }),
};

export const getDashboardInfo = {
  base: () => ({
    type: GET_DASHBOARD_INFO.BASE,
  }),
  request: () => ({ type: GET_DASHBOARD_INFO.REQUEST }),
  success: (result) => ({ type: GET_DASHBOARD_INFO.SUCCESS, result }),
  failure: (error) => ({ type: GET_DASHBOARD_INFO.FAILURE, error }),
};

export const getPostalCode = {
  base: () => ({
    type: GET_POSTAL_CODE.BASE,
  }),
  request: () => ({ type: GET_POSTAL_CODE.REQUEST }),
  success: (result) => ({ type: GET_POSTAL_CODE.SUCCESS, result }),
  failure: (error) => ({ type: GET_POSTAL_CODE.FAILURE, error }),
};

export const getCities = {
  base: () => ({
    type: GET_CITIES.BASE,
  }),
  request: () => ({ type: GET_CITIES.REQUEST }),
  success: (result) => ({ type: GET_CITIES.SUCCESS, result }),
  failure: (error) => ({ type: GET_CITIES.FAILURE, error }),
};

export const getField = {
  base: (fieldId, value) => ({
    type: GET_FIELD.BASE,
    fieldId,
    value,
  }),
  request: () => ({ type: GET_FIELD.REQUEST }),
  success: (result) => ({ type: GET_FIELD.SUCCESS, result }),
  failure: (error) => ({ type: GET_FIELD.FAILURE, error }),
};

export const getEquipmentsSearch = {
  base: (fieldId, value) => ({
    type: GET_EQUIPMENTS_SEARCH.BASE,
    fieldId,
    value,
  }),
  request: () => ({ type: GET_EQUIPMENTS_SEARCH.REQUEST }),
  success: (result) => ({ type: GET_EQUIPMENTS_SEARCH.SUCCESS, result }),
  failure: (error) => ({ type: GET_EQUIPMENTS_SEARCH.FAILURE, error }),
};

export function* getDashboardFilterSaga() {
  try {
    const url = apiPathFactory.dashboardFilter();
    const response = yield call(() => fetchEntitySaga(getDashboardFilter, api.get, url));
    return response;
  } catch (error) {
    return error;
  }
}

export function* getDashboardFilterByTypeSaga({ filterType, ...other }) {
  try {
    const url = apiPathFactory.getDashboardFilterByType(filterType);
    const response = yield call(() => fetchEntitySaga(getDashboardFilterByType, api.get, url));
    return response;
  } catch (error) {
    return error;
  }
}

export function* getDashboardInfoSaga() {
  try {
    const url = apiPathFactory.getDashboardInfo();
    const response = yield call(() => fetchEntitySaga(getDashboardInfo, api.get, url));
    return response;
  } catch (error) {
    return error;
  }
}

export function* getPostalCodeSaga() {
  try {
    const url = apiPathFactory.facilitiesGetPostalCodes();
    const response = yield call(() => fetchEntitySaga(getPostalCode, api.get, url));
    return response;
  } catch (error) {
    return error;
  }
}

export function* getCitiesSaga() {
  try {
    const url = apiPathFactory.facilitiesGetCities();
    const response = yield call(() => fetchEntitySaga(getCities, api.get, url));
    return response;
  } catch (error) {
    return error;
  }
}

export function* getFieldSaga({ fieldId, value }) {
  try {
    const url = apiPathFactory.facilitiesField(fieldId, value);
    const response = yield call(() => fetchEntitySaga(getField, api.get, url));
    return response;
  } catch (error) {
    return error;
  }
}

export function* getEquipmentSearchSaga({ fieldId, value }) {
  try {
    const url = apiPathFactory.getDashboardEquipmentSearch(fieldId, value);
    const response = yield call(() => fetchEntitySaga(getEquipmentsSearch, api.get, url));
    return response;
  } catch (error) {
    return error;
  }
}

function* watchDashboard() {
  yield takeLatest(GET_DASHBOARD_FILTER.BASE, getDashboardFilterSaga);
  yield takeLatest(GET_DASHBOARD_FILTER_BY_TYPE.BASE, getDashboardFilterByTypeSaga);
  yield takeLatest(GET_DASHBOARD_INFO.BASE, getDashboardInfoSaga);
  yield takeLatest(GET_CITIES.BASE, getCitiesSaga);
  yield takeLatest(GET_POSTAL_CODE.BASE, getPostalCodeSaga);
  yield takeLatest(GET_FIELD.BASE, getFieldSaga);
  yield takeLatest(GET_EQUIPMENTS_SEARCH.BASE, getEquipmentSearchSaga);
}

export default function* watchers() {
  yield fork(watchDashboard);
}
