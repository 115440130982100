import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { v1 as uuidv1 } from 'uuid';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { toast } from 'react-toastify';
import IconMuvAlarm from '@jcm-technologies/uikit/dist/atoms/Icons/IconMuvAlarm';
import {
  deleteInstalations,
  getInstalationInfo,
  getInstalations,
  putFacilityGeolocation,
  resetInstalationInfo,
} from '../../modules/old_to_refact/actions/instalations';
import { RootPath_UG } from '../../core/old_common/constats/genericListPaths';
import GenericGrow from '../GenericGrow/GenericGrow';
import GenericMapButton from '../GenericMapButton/GenericMapButton';
import InstalationMapPopUpContent from '../InstalationMapPupUpContent/InstalationMapPopUpContent';
import PropsList from '../PropsList/PropsList';
import { generateAlarmSumary } from '../utils/mappers/alarms';

import './InstalationGrow.css';
import {
  getCookieDateTimeFormat,
  getCookieUserRoles,
} from '../../core/old_common/utils/cookiesManager';
import apiPathFactory from '../../core/old_common/rooting/apiPathFactory';
import store from '../../modules/old_to_refact/stores/configureStore';
import { showDialog } from '../../modules/old_to_refact/actions/loading';
import { downloadFile, getFilesFromApi } from '../../core/helpers/filesApi';
import { IconUserShield } from '@jcm-technologies/uikit/dist/atoms/Icons';
import { Icon } from '@jcm-technologies/uikit/dist/atoms/Icon';

export class InstalationGrow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      transferModal: {
        isOpen: false,
        facilityId: '',
      },
      mapProps: {
        isOpen: false,
        facilityId: '',
      },
    };
  }

  changePopUpState = (newState, id) => {
    this.setState((prev) => ({
      ...prev,
      mapProps: {
        isOpen: newState,
        facilityId: id,
      },
    }));
  };

  getHeaderTitles = (isEditMode) => {
    const { t, name, match, item, address, city } = this.props;
    if (isEditMode) {
      return [
        {
          value: name,
          className: 'UG-portlet-header-item UG-header-title',
        },
        {
          value: item ? item.address : address,
          className: 'UG-portlet-header-item',
        },
        {
          value: item ? item.city : city,
          className: 'UG-portlet-header-item',
        },
      ];
    }
    return [
      {
        value: t('updateInstalationForm.createFacility'),
        className: 'UG-portlet-header-item UG-header-title',
      },
    ];
  };

  getGeolocationFromAttribute = (facility) => facility.facilityAttributes.find((x) => x.type === 6);

  getGeolocation = (facility) => {
    const foundGeolocation = this.getGeolocationFromAttribute(facility);
    if (foundGeolocation) {
      return foundGeolocation;
    }
    return {
      id: '00000000-0000-0000-0000-000000000000',
      type: 6,
      facilityId: facility.id,
      tag: 'Geolocation',
      value: '',
    };
  };

  ondragoverGeo = (e, facility) => {
    const { putFacilityGeolocation, t } = this.props;
    const geolocationURL = { ...this.getGeolocation(facility) };
    geolocationURL.value = `@${e.dataTransfer.getData('text/plain').split('@')[1]}`;
    e?.stopPropagation();
    e?.preventDefault();
    putFacilityGeolocation(facility.id, geolocationURL);
  };

  dragOverHandler = (ev) => {
    ev.preventDefault();
  };

  onGeolocationClick = (e, facility) => {
    const foundGeolocation = this.getGeolocationFromAttribute(facility);
    if (foundGeolocation) {
      e?.preventDefault();
      e?.stopPropagation();
      window.open(`https://www.google.es/maps/${foundGeolocation.value}`, '_blank');
    }
  };

  haveGeoLocation = (facilityAttributes) => {
    let isGeolocation = false;
    if (facilityAttributes) {
      facilityAttributes.forEach((attr) => {
        if (attr.tag === 'Geolocation') {
          isGeolocation = true;
        }
      });
    }
    return isGeolocation;
  };

  onDownloadHtmlClick = (e, facility) => {
    e?.preventDefault();
    e?.stopPropagation();
    const endpoint = apiPathFactory.facilityGetPdf(facility.id);
    store.dispatch(showDialog(1));
    getFilesFromApi(
      endpoint,
      (isError, response) => {
        if (!isError) {
          const fileName = `${facility.name}.html`;
          downloadFile(response, fileName);
        } else {
          toast.error(this.props.t('apitoasters.errors.timeout'));
        }
      },
      'application/json',
      'binary'
    );
  };

  needMaintenance = (date) => {
    const realTimeFormated = moment(new Date()).format('YYYY-MM-DD');
    const maintenanceTimeFormated = moment(date).format('YYYY-MM-DD');
    return moment(realTimeFormated).isAfter(maintenanceTimeFormated) ? 'fas fa-tools' : '';
  };

  getActionButtons = (item, isCreationMode) => {
    const { isConfigVisible, t, history, match, facilityId, toolTip, onConfigClick } = this.props;
    const dateFormat =
      getCookieDateTimeFormat() !== undefined ? getCookieDateTimeFormat() : 'yyyy-MM-dd';
    let actionButtons = [];
    if (isConfigVisible) {
      const baseURI = `${RootPath_UG}/${match.params.universalGroupId || match.params.id}`;
      const relaysURI = `${baseURI}/Equipments/${facilityId}`;
      actionButtons = [
        {
          id: 'config',
          toolTip: toolTip || t('instalations.add'),
          defaultColor: '#a4aebb',
          iconClassName: 'generic-grow-action-button fas fa-cog',
          cursor: 'pointer',
          onClick: (e) => {
            if (onConfigClick) {
              onConfigClick({ e, item });
            }
            const encodedRelaysURI = encodeURI(relaysURI);
            history.push(encodedRelaysURI.replace('%09', '%2509'));
          },
        },
      ];
    } else if (item) {
      // TODO: Fix parent and old components to style from parent comp
      actionButtons = [
        {
          id: 'equipmentStatus',
          toolTip: `${
            item.allEquipmentsConnected
              ? t('instalations.allEquipmentsStatusOk')
              : `${item.connectedDevicesAmount} ${t('instalations.numberOfConnectedDevices')}`
          }`,
          defaultColor: `${
            item.allEquipmentsConnected && item.connectedDevicesAmount > 0 ? '#00e600' : 'red'
          }`,
          iconClassName: `${
            item.connectedDevicesAmount > 0 ? 'generic-grow-action-button fas fa-wifi' : ''
          }`,
          cursor: 'auto',
        },
        {
          id: 'connecteds',
          toolTip: `${`${t('instalations.allDevicesDisconnected')} ${moment(
            item.maintenanceDate
          ).format(dateFormat?.toUpperCase())}`}`,
          defaultColor: '#ff7903',
          iconClassName: `${
            item.facilityEquipments && this.needMaintenance(item.maintenanceDate)
              ? `generic-grow-action-button fas ${this.needMaintenance(item.maintenanceDate)}`
              : 'display-none-icon'
          }`,
          cursor: 'auto',
        },
        {
          toolTip: this.haveGeoLocation(item.facilityAttributes)
            ? t('updateInstalationForm.geolocalization')
            : t('updateInstalationForm.noneGeolocalization'),
          defaultColor: '#a4aebb',
          iconClassName: this.haveGeoLocation(item.facilityAttributes)
            ? `generic-grow-action-button fas fa-map-marker-alt`
            : `generic-grow-action-button fas fa-map-marker-alt crossed`,
          onDragOver: this.dragOverHandler,
          onDrop: (e) => this.ondragoverGeo(e, item),
          onClick: (e) => this.onGeolocationClick(e, item),
          cursor: this.haveGeoLocation(item.facilityAttributes) ? 'pointer' : 'not-allowed',
        },
        {
          toolTip: t('general.downloadHtml'),
          defaultColor: '#a4aebb',
          iconClassName: 'generic-grow-action-button fas fa-file-pdf',
          isHidden: isCreationMode,
          onClick: (e) => {
            e?.preventDefault();
            e?.stopPropagation();
            this.onDownloadHtmlClick(e, item);
          },
        },
      ];

      if (item?.alarms?.length) {
        actionButtons.unshift({
          toolTip: generateAlarmSumary(item.alarms),
          //TODO: We neeed to refact the other icons in order to fix this styling
          icon: <IconMuvAlarm className='generic-grow-action-button d-grid' fill='red' />,
          isHidden: isCreationMode,
          cursor: 'auto',
          onClick: (e) => {
            e?.preventDefault();
            e?.stopPropagation();
          },
        });
      }

      if (item?.installerCode) {
        actionButtons.splice(3, 0, {
          toolTip: t('updateInstalationForm.installerCodeIconTooltip'),
          //TODO: We neeed to refact the other icons in order to fix this styling
          icon: (
            <IconUserShield
              fill='grey'
              className='generic-grow-action-button d-grid refactored-icon'
            />
          ),
          isHidden: isCreationMode,
          onClick: (e) => {
            e?.preventDefault();
            e?.stopPropagation();
          },
        });
      }
    }
    return actionButtons;
  };

  deleteUniqueKey = (item) => {
    const response = { ...item };
    response.oldInstalationId = item.id;
    response.uniqueIdentity = moment().format('MMDDHHmmssSSS');
    if (response.facilityAttributes) {
      response.facilityAttributes = response.facilityAttributes.map((attribute) => ({
        ...attribute,
        id: uuidv1(),
        facilityId: response.id,
      }));
    }
    return response;
  };

  isMapOpen = (popUpState) => {
    this.props.resetInstalationInfo();
    this.setState((prev) => ({
      ...prev,
      mapProps: {
        isOpen: popUpState,
      },
    }));
  };

  generateInstalationMap = (id) => {
    const { mapProps } = this.state;
    const { t, instalationInfo } = this.props;
    if (mapProps.facilityId === id) {
      return (
        <InstalationMapPopUpContent
          t={t}
          isOpen={mapProps.isOpen}
          onCloseForm={() => this.isMapOpen(false)}
          resetInstalationInfo={this.props.resetInstalationInfo}
          mapInfo={instalationInfo}
        />
      );
    }
    return '';
  };

  popupDeleteChildren = (item) => {
    const { t } = this.props;
    if (item) {
      return [
        {
          key: t('form.name'),
          value: item.name,
        },
        {
          key: t('updateInstalationForm.address'),
          value: item.address,
        },
        {
          key: t('form.city'),
          value: item.city,
        },
        {
          key: t('updateInstalationForm.telephone'),
          value: item.telephone,
        },
        {
          key: t('updateInstalationForm.country'),
          value: item.country,
        },
      ];
    }
    return [];
  };

  handleDelete = (e) => {
    const { id, customerId, deleteInstalations, handleDelete } = this.props;
    deleteInstalations({ id, customerId });
    handleDelete(e);
  };

  render() {
    const {
      children,
      tenants,
      item,
      customerId,
      id,
      baseURI,
      fatherDelete,
      creationURI,
      getInstalations,
      getInstalationInfo,
      deleteInstalations,
      match,
      t,
      resetInstalationInfo,
      canClone,
      canExpand,
      needSecondPopup,
      dashboardFilterId,
    } = this.props;
    const idForMap = id === undefined ? item.facilityId : id;
    const { mapProps } = this.state;
    const isCreationMode =
      match.params.id && match.params.id === creationURI ? decodeURI(match.params.id) : '';
    const role = getCookieUserRoles();
    const roleMask = role ? role.mask : '';
    const isAdminUserRole = roleMask == 2;
    const isAdvancedManagerRole = roleMask == 4;
    return (
      <>
        <GenericGrow
          isExpanded={children !== undefined}
          key={customerId}
          isAdminUserRole={isAdminUserRole}
          isAdvancedManagerRole={isAdvancedManagerRole}
          item={item}
          dashboardFilterId={dashboardFilterId}
          rootPath={baseURI}
          creationURI={creationURI}
          headerTitles={this.getHeaderTitles(!isCreationMode)}
          handleDelete={() => {
            if (fatherDelete) fatherDelete(item.facilityId);
            else {
              deleteInstalations({ id, customerId });
            }
          }}
          customActionButtons={this.getActionButtons(item, isCreationMode)}
          manipulateCloneObject={this.deleteUniqueKey}
          MapComponent={() => (
            <GenericMapButton
              isMapButtonVisible={false}
              mapProps={mapProps}
              isMapButtonDisabled={false}
              resetMapProps={this.resetMapProps}
              changePopUpState={this.changePopUpState}
              mapId={idForMap}
              onClosePopUp={() => {
                this.changePopUpState(false, '');
                resetInstalationInfo();
              }}
              onClick={(mapId) => {
                this.changePopUpState(true, mapId);
                getInstalationInfo(mapId);
              }}
            >
              {this.generateInstalationMap(idForMap)}
            </GenericMapButton>
          )}
          deletePopupChildren={<PropsList propsArray={this.popupDeleteChildren(item)} />}
          deletePopupTitle={`${t('instalations.deleteConfirmation')}`}
          secondDeletePopup={!!needSecondPopup}
          secondDeletePopupTitle={`${t('instalations.criticalDeleteTitle')}`}
          firstTextSecondPopup={`${t('instalations.firstAreYouSureCriticalDelete')}`}
          firstSecondTextSecondPopup={`${t('instalations.secondAreYouSureCriticalDelete')}`}
          secondTextSecondPopup={`${t('instalations.noGoBack')}`}
          canClone={canClone}
          canExpand={canExpand}
        >
          {children}
        </GenericGrow>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  tenants: state.tenants,
  instalationInfo: state.instalations.instalationInfo,
});

const withRouterComponent = withRouter(InstalationGrow);
const withTranslationComponent = withTranslation()(withRouterComponent);

export default connect(mapStateToProps, {
  deleteInstalations,
  getInstalationInfo,
  getInstalations,
  resetInstalationInfo,
  putFacilityGeolocation,
})(withTranslationComponent);
