export default {
  equipments: {
    add: 'Add equipment',
    createEquipment: 'Create equipment',
    deleteConfirmation: 'Are you sure you want to delete this equipment?',
    facilityName: 'Facility name',
    productName: 'Product name',
    parameters: 'Parameters',
    relays: 'Relays',
    info: 'Information',
    fobs: 'Fobs',
    groups: 'Groups',
    actions: 'Actions',
    events: 'Events',
    'remote comm': 'Remote communications',
    changePasswordAction: 'Change password',
    adjustDateTimeAction: 'Adjust date and time',
    formatMemory: 'Format memory',
    disconnectedKey: 'Disconnected',
    connectedKey: 'Connected',
    notFoundKey: 'Device not found',
    saveConfiguration: 'Save configuration',
    compareMemory: 'Compare memory',
    savedMemory: 'Memory saved',
    sendMemoryAction: 'Update memory locally',
    saveMemoryAction: 'Update memory remotely',
    verifyConnectivity: 'Verify connectivity',
    getdoorstatus: 'Get door status',
    device: 'Device',
    deleteEventsConfirmation: 'Are you sure you want to delete the events?',
  },
};
