import React, { useEffect } from 'react';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import { Loading } from '@jcm-technologies/uikit/dist/atoms/Loading';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { passwordChangeRequest } from '../../core/config/authConfig';

const View = () => {
  const { instance, inProgress } = useMsal();
  const history = useHistory();
  const isAuthenticated = useIsAuthenticated();
  const tenants = useSelector((state) => state?.tenants);
  useEffect(async () => {
    if (!isAuthenticated && inProgress === InteractionStatus.None) {
      await instance.loginRedirect(passwordChangeRequest);
    } else if (isAuthenticated && inProgress === InteractionStatus.None) {
      history.push('/dashboard');
    }
  }, [instance, isAuthenticated, inProgress]);

  return (
    <>
      <Loading show themeColor={tenants?.color1} />
    </>
  );
};

export default View;
