import {
  GET_DASHBOARD_FILTER,
  GET_DASHBOARD_FILTER_BY_TYPE,
  GET_DASHBOARD_INFO,
  GET_POSTAL_CODE,
  GET_CITIES,
  GET_EQUIPMENTS_SEARCH
} from './constants';

/* Redux state init */
const initialState = {
  isSuccess: false,
  filters: [],
  filterByType: [],
  cities: [],
  postalCode: [],
  info: null,
  errorMessage: undefined,
};

export default function dashboardReducer(state = initialState, action) {
  if (action.type !== undefined) {
    switch (action.type) {
      case GET_DASHBOARD_INFO.SUCCESS:
        return {
          ...state,
          info: action.result,
          isSuccess: true,
        };
      case GET_DASHBOARD_FILTER.SUCCESS:
        return {
          ...state,
          filters: action.result,
          isSuccess: true,
        };
      case GET_DASHBOARD_FILTER_BY_TYPE.SUCCESS:
        return {
          ...state,
          filterByType: action.result,
          isSuccess: true,
        };
      case GET_POSTAL_CODE.SUCCESS:
        return {
          ...state,
          postalCode: action.result?.map((el) => ({
            value: el.zip,
            label: el.zip,
          })),
          isSuccess: true,
        };
      case GET_CITIES.SUCCESS:
        return {
          ...state,
          cities: action.result?.map((el) => ({
            value: el.city,
            label: el.city,
          })),
          isSuccess: true,
        };
      default:
        return state;
    }
  }
  return state;
}
