import styled, { css } from 'styled-components';

export default styled.span`
  ${(props) =>
    props.isFocused && !props.iconDisabled && css ? `color: ${props.tenants.color1}` : ``}

  &:hover {
    color: ${(props) => (props.iconDisabled ? props.tenants.color1 : '')};
  }
`;
