import React from 'react';

export class Renderers {
  constructor(
    enterEdit,
    exitEdit,
    editFieldName,
    onBlurSave,
    onTabFocusNextItem,
    onTabFocusPrevItem
  ) {
    this.enterEdit = enterEdit;
    this.exitEdit = exitEdit;
    this.editFieldName = editFieldName;
    this.onBlurSave = onBlurSave;
    this.onTabFocusNextItem = onTabFocusNextItem;
    this.onTabFocusPrevItem = onTabFocusPrevItem;
  }

  cellRender = (tdElement, cellProps) => {
    const { dataItem } = cellProps;
    const cellField = cellProps.field;
    const inEditField = dataItem[this.editFieldName];
    const onBlur =
      cellField !== 'selected' ? (e) => this.onBlurSave(e, cellProps.dataItem) : () => {};
    const onClick = cellField !== 'selected' ? () => this.enterEdit(dataItem, cellField) : () => {};
    const additionalProps =
      cellField && cellField === inEditField
        ? {
            ref: (td) => {
              const input = td && td.querySelector('input');
              const { activeElement } = document;

              if (
                !input ||
                !activeElement ||
                input === activeElement ||
                !activeElement.contains(input)
              ) {
                return;
              }

              if (input.type === 'checkbox') {
                input.focus();
              } else {
                input.select();
              }
            },
            onBlur: (e) => {
              onBlur(e);
            },
            onKeyDown: (e) => {
              if (e.keyCode === 13 || e.keyCode === 9) {
                e?.preventDefault();
                onBlur(e);
              }
            },
          }
        : {
            onClick: (e) => {
              onClick();
            },
          };
    return React.cloneElement(
      tdElement,
      { ...tdElement.props, ...additionalProps },
      tdElement.props.children
    );
  };

  rowRender = (trElement, cellProps) => {
    const lockedRowUser = cellProps.dataItem.locked ? 'grid-kendo-row-locked' : '';
    const trProps = {
      ...trElement.props,
      className: `${trElement.props.className} ${lockedRowUser}`,
      onMouseDown: () => {
        this.preventExit = true;
        clearTimeout(this.preventExitTimeout);
        this.preventExitTimeout = setTimeout(() => {
          this.preventExit = undefined;
        });
      },
      onBlur: (e) => {
        clearTimeout(this.blurTimeout);
        if (!this.preventExit) {
          this.blurTimeout = setTimeout(() => {
            this.exitEdit();
          });
        }
      },
      onFocus: () => {
        clearTimeout(this.blurTimeout);
      },
    };
    return React.cloneElement(trElement, { ...trProps }, trElement.props.children);
  };
}
