export default {
  remoteState: {
    noContent: 'Nem elérhető',
    title: 'Távoli állapot',
    doorState0: 'Meghatározatlan',
    doorState1: 'Nyitva',
    doorState2: 'Zárva',
    doorState3: 'Nyílik',
    doorState4: 'Csukódik',
    doorState5: 'Nyitás megállítása',
    doorState6: 'Zárás megállítása',
    doorState7: 'Várakozás az automatikus zárásra',
    deviceType: 'Eszköz típusa',
    connectionSignal: 'Csatlakozási jel',
    rssiValue: 'RSSI érték',
    hwVersion: 'Hardver verziószám',
    swVersion: 'Szoftver verziószám',
    doorStateTitle: 'Kapu állapota',
    inputStateTitle: 'Bemenet állapota',
    deviceInfoTitle: 'Eszközinformáció',
    relayTitle: 'aktiválási idő másodpercben',
    relaysTitle: 'Relék',
    refreshStatus: 'Állapot frissítése',
    activateRel1: '1. relé aktiválása',
    activateRel2: '2. relé aktiválása',
    doorState: 'Állapot',
    activateRelError: 'Az eszköz nem válaszol',
    activateRelSuccess: 'A művelet sikeresen befejeződött',
    connectionSignalUnknown: 'Hatótávolságon kívül',
    connectionSignalExcellent: 'Kiváló',
    connectionSignalGood: 'Jó',
    connectionSignalFair: 'OK',
    connectionSignalWeak: 'Gyenge',
    connectionType: 'Csatlakozás típusa',
    deviceType0: 'eBase',
    deviceType1: 'eConnect',
    deviceType2: 'BtConnect',
    deviceType3: 'Sesame',
    deviceType4: 'Blink',
    deviceType5: 'eAccess',
    deviceType6: 'Vdp',
    deviceType7: 'HONOADOOR / EBASEDOOR',
    deviceType8: 'HONOARELÉK',
    sensors: 'Érzékelők',
    open: 'Nyitva',
    battery: 'Akkumulátor',
    deactivated: 'Deaktivált',
    activated: 'Aktivált',
  },
};
