import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getTimezones } from '../../modules/countries/actions';

import './Timezone.css';
import { ComboboxFlyer } from '../ComboboxFlyer/ComboboxFlyer';

export class Timezone extends Component {
  componentDidMount = () => {
    const { getTimezones, needCallTimezones, timezones, fromParameters } = this.props;
    if (needCallTimezones && !timezones && !fromParameters) {
      getTimezones();
    }
  };

  getTimezonesOptions = () => {
    const timeZonesDropDown = this.props.timezones
      ? this.props.timezones.map((value) => (
          <option key={value.code} value={value.id}>
            {value.code}
          </option>
        ))
      : [];
    return [...timeZonesDropDown];
  };

  getParameterTimezonesOptions = () => {
    const { values } = this.props;
    const timeZonesDropDown = values
      ? this.props.values.map((value) => (
          <option key={value.key} value={value.key}>
            {value.value}
          </option>
        ))
      : [];
    return [...timeZonesDropDown];
  };

  render() {
    return (
      <ComboboxFlyer
        {...this.props}
        className={this.props.className}
        options={
          this.props.fromParameters
            ? this.getParameterTimezonesOptions()
            : this.getTimezonesOptions()
        }
      />
    );
  }
}

const mapStateToProps = (state) => ({
  timezones: state.countries.timezones,
});
const timezone = connect(mapStateToProps, {
  getTimezones,
})(Timezone);

export default timezone;
