export default {
  breadcrumb: {
    dashboard: 'Dashboard',
    Facilities: 'Instal·lacions',
    FacilityEquipments: 'Equips',
    Groups: 'Grups',
    Equipments: 'Equips',
    AddUniversalGroup: 'Afegir grup universal',
    Configuration: 'Configuració',
    Enroll: "Procés d'inscripció",
    administration: 'Administració',
    'user-config': "Configuració d'usuari",
    UniversalGroups: 'Grups universals',
    Users: 'Usuaris',
    Help: 'Ajuda',
    Parameters: 'Paràmetres',
    Relays: 'Relés',
    Fobs: 'Emissors',
    Actions: 'Accions',
    Events: 'Esdeveniments',
    'Remote comm': 'Comunicacions remotes',
    Facility: 'Nova instal·lació',
    Equipment: 'Nou equip',
    FacilityGroup: 'Nou grup',
    Neighbours: 'Llistat de veïns',
    UniversalGroup: 'Nou grup universal',
    Schedule: 'Nou horari',
    customization: 'Personalització web',
    Invitations: 'Invitacions',
    Companies: 'Empreses',
    UpdateUser: 'El meu compte',
    preferences: 'Preferències',
    'assistant-panel': 'Assistant Panel',
    Holidays: 'Dies festius',
    Information: 'Informació',
    recycle: 'Paperera',
  },
};
