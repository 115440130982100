export default {
  changePassword: {
    title: 'Změnit heslo',
    submitText: 'Změnit heslo',
    currentPassword: 'Aktuální heslo',
    newPassword: 'Nové helso',
    confirmPassword: 'Potvrdit heslo',
    cancel: 'Zrušit',
    chooseFile: 'Vybrat soubor',
    newPasswordPopupTitle: 'Vložit nové heslo',
  },
};
