import { Modal } from '@jcm-technologies/uikit/dist/molecules/Modal';
import { Flex, Grid, GridItem } from '@jcm-technologies/uikit/dist/atoms/Layout';
import { Text } from '@jcm-technologies/uikit/dist/atoms/Typography';
import { Button } from '@jcm-technologies/uikit/dist/atoms/Button';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { getContrastColor } from '../../../../../../core/helpers/colorManager';

export const ModalDeleteUser = ({ userToDelete, setUserToDelete, onDeleteUser }) => {
  const { t } = useTranslation();
  const { color1 } = useSelector((state) => state.tenants);
  return (
    <Modal
      isOpen={!!userToDelete}
      title={t('instalationUserGroups.deleteUserConfirmation')}
      closeWithButton
      id='modal-delete-user'
      width={[300, 500, 500]}
      onModalClose={() => setUserToDelete(null)}
    >
      <Modal.Content>
        <Grid gridTemplateAreas="'. .'" gridTemplateColumns='1fr 1fr' gridGap={2}>
          <GridItem>
            <Text>{t('form.email')}:</Text>
          </GridItem>
          <GridItem>
            <Text>{userToDelete?.email}</Text>
          </GridItem>
          <GridItem>
            <Text>{t('form.phone')}:</Text>
          </GridItem>
          <GridItem>
            <Text>{userToDelete?.phone}</Text>
          </GridItem>
          <GridItem>
            <Text>{t('form.name')}:</Text>
          </GridItem>
          <GridItem>
            <Text>{userToDelete?.name}</Text>
          </GridItem>
          <GridItem>
            <Text>{t('form.surname')}:</Text>
          </GridItem>
          <GridItem>
            <Text>{userToDelete?.surname}</Text>
          </GridItem>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Flex alignItems='center' justifyContent='flex-end'>
          <Button
            backgroundColor={color1}
            marginRight={2}
            color={getContrastColor(color1)}
            action={() => onDeleteUser(userToDelete.id)}
          >
            {t('general.delete')}
          </Button>
          <Button
            sizeText='display14'
            backgroundColor='blackLight'
            id='cancel-button'
            action={() => setUserToDelete(null)}
          >
            {t('general.cancel')}
          </Button>
        </Flex>
      </Modal.Actions>
    </Modal>
  );
};

ModalDeleteUser.propTypes = {
  onDeleteUser: PropTypes.func.isRequired,
  setUserToDelete: PropTypes.func.isRequired,
  userToDelete: PropTypes.shape({}),
};

ModalDeleteUser.defaultProps = {
  userToDelete: null,
};
