export default {
  changePassword: {
    title: 'Passwort ändern',
    submitText: 'Ändere das Passwort',
    currentPassword: 'Jetziges Passwort',
    newPassword: 'Neues Kennwort',
    confirmPassword: 'Bestätige das Passwort',
    cancel: 'Abbrechen',
    chooseFile: 'Datei wählen',
    newPasswordPopupTitle: 'Legen Sie ein neues Passwort fest',
  },
};
