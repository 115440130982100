export default {
  errors: {
    title: {
      honoa: 'HONOA Show door status:',
    },
    1000: 'Login failed',
    100: 'User logged in',
    101: 'User logged out',
    102: 'Password changed successfully',
    103: 'Forgot password process completed',
    104: 'Reset password process completed',
    105: 'Sending Email to:',
    106: 'Invalid User',
    107: 'User not updated',
    108: 'Invitation expired',
    109: 'Invitation not deleted',
    10: 'Starting CloudAssistant API configuration section',
    110: 'User not confirmed and updated in IS4',
    111: 'User not registered successfully',
    112: 'User or Customer id mismatch',
    113: 'Customer Id does not exist',
    114: 'Invitation not sent',
    115: 'Invitation has been erased',
    116: 'Rollback invitation completed',
    117: 'Customer attributes not created properly',
    118: 'Not enough permissions for showing this elements',
    119: 'Not enough permissions for showing or updating this elements',
    11: 'Configuration done. API is ready for incoming calls',
    120: 'Not enough permissions for creating this elements',
    121: 'Not enough permissions for deleting this elements',
    122: 'User not deleted in identity',
    123: 'User not found',
    124: 'Conflict when deleting user in identity',
    125: 'Concurrency failure',
    126: 'Default error',
    127: 'E-mail already exists',
    128: 'The role name already exists',
    129: 'The user name already exists',
    12: 'Set Api version configuration',
    130: 'Invalid e-mail',
    131: 'Invalid role name',
    132: 'Invalid token',
    133: 'Invalid user name',
    134: 'Login already associated',
    135: 'Password requires lower case',
    136: 'Password requires non alphanumeric values',
    137: 'Password requires unique chars',
    138: 'Password requires upper case',
    139: 'Password too short',
    13: 'Overall API Settings',
    140: 'Recovery code redemption failed',
    141: 'User already has a password',
    142: 'User already has a role',
    143: 'User without role',
    144: 'User blocking not enabled',
    145: 'Authentication failed, invalid client id or hidden',
    146: 'Invalid request',
    147: 'E-mail or password are not correct',
    148: 'Invalid scope',
    149: 'This customer is not authorized to use the type of request requested',
    14: 'SMTP settings',
    150: 'Unsupported grant type',
    151: 'User not deleted in identity',
    152: 'Conflict when deleting user in identity',
    153: 'Subscriptions not updated',
    154: 'Subscriptions have been updated',
    155: 'Error getting subscriptions',
    156: 'Customer has universal groups or facilities with installer code',
    157: 'Unknown error',
    158: 'Not enough permissions',
    159: 'User e-mail already exists',
    15: 'Configuring Cookie policies',
    160: 'Not enough subscriptions',
    161: 'User roles not valid',
    162: 'User role not updated',
    163: 'User not enrolled or without subscriptions',
    164: 'User roles or customer not valid',
    165: 'Admin cannot be updated',
    166: 'Installer code not updated',
    167: 'Product families not updated',
    168: 'Products not updated',
    169: 'No element found with this id',
    16: 'Set compatibility environment:',
    170: 'Refresh Token',
    171: 'Not enough subscriptions',
    172: 'Device not found',
    17: 'Setting up db Context',
    18: 'Authentication services',
    19: 'Add swagger documentation',
    200: 'Get all users list',
    300: 'Bad arguments or Number of items exceeded for a single request',
    301: 'Update successfully completed.',
    302: 'Validation error',
    400: 'Tenant successfully saved',
    401: 'Missing some tenant data! Save unsuccessfully',
    402: 'Payment required',
    404: 'Device offline or connection failure.',
    90000: 'Loopback test has been requested:',
    90001: 'An exception has been requested:',
    90002: 'Return status code has been requested:',
    9000: 'Login failed',
    9001: 'Reset password failed',
    9002: 'Forgot password failed',
    9003: 'Change password failed',
    9004: 'Password mismatch',
    9005: 'Password requires at least one digit',
    9006: 'Unknown user',
    90100: 'Uncatch SQL Exception',
    90101: 'Creation failed.',
    90102: 'The name you entered already exists, you must change it',
    90103: 'Not found.',
    90104: 'Update failed',
    90105: 'Delete failed',
    90106: 'This content already exists',
    90107: 'Content cannot be null.',
    90108: 'Import failed.',
    90109: 'Export failed.',
    90110: 'Cannot update installer code in facilities with fobs assigned',
    90111: 'Facility ID or URL is not valid',
    90112: 'Facility entity group not created',
    90113: 'Facility group have to had name',
    90114: 'Facility entity group name already exists for this facility',
    90115: 'Facility entity group not updated',
    90116: 'Facility entity group not deleted',
    90117: 'Entity contact can not be null',
    90118: 'Facility entity not updated',
    90119: 'Facility entity description or code already exists for this group',
    90120: 'Unable to delete contact with position 0',
    90121: 'Contact not deleted',
    90122: 'Contact not created',
    90123: 'Entity already has 5 contacts',
    90124: 'Entity contact not updated',
    90125: 'GroupId not exists for this facility',
    90126: 'FacilityId not valid',
    90127: 'Facility entity contact not valid',
    90128: 'Installer code  must be the same for every facility in the universal group',
    90129: 'Installer code must be the same for facility and universal group',
    91000: 'VDP mac or id not found',
    91001: 'Invalid mac',
    91002: 'E-mail extension not found',
    91003: 'Error updating vdp name',
    91004: 'Error creating vdp contact relation',
    91005: 'Error creating contact',
    91006: 'Error deleting contacts',
    91007: 'Error updating contact name',
    91008: 'Customer name already exists',
    91009: 'Flat or door code or not valid',
    91010: 'Code not introduced',
    91011: 'Number of channels corrupted',
    91012: 'The last relay relationship cannot be deleted',
    91013: 'Cannot disable relay because it has groups assigned',
    91014: 'Invalid range of codes',
    91015: 'Codes must be the same type',
    91016: 'Not enought positions in group',
    91017: 'Code already exists in facility',
    91018: 'Code already exists in universals',
    91019: 'Code is a substitution of a deleted universal',
    91020: "This facility equipment don't support schedules",
    91021: 'Nothing to copy',
    91022: 'Nothing to move',
    91023: 'Product not valid',
    91024: 'Schedule not found',
    91025: 'Cannot delete schedule with a facility assigned',
    91026: 'The group requested is not universal',
    91027: 'The equipment has some invalid params',
    91028: 'Cannot delete a universal group with facilities assigned',
    91029: 'Serial number or unique key not registered',
    91030: 'Serial number or unique Key can not be null',
    91031: 'Client code is not correct',
    91032: 'Installer code is not correct',
    91033: 'Password is not correct',
    91034: 'File corrupted',
    91065: 'link already assigned',
    91066: 'An error occurred while deactivating the link',
    91067: 'Incorrect activation key',
    91068: 'Unsuccessful API request',
    91069: 'Product not enabled',
    91070: 'Product not found',
    91071: 'No relays selected. Use configure button',
    91072: 'Relay name already exists',
    91073: 'Maximum number of installations reached.',
    91074: 'This activation key is in use by another user.',
    91100: 'Disconnected Device',
    91101: 'Unhandled exception reading device twins',
    91102: 'Read memory exception',
    91103: 'Write memory exception',
    91104: 'Write memory mismatch',
    91105: 'Device has installer code',
    91106: 'Device does not have installer code',
    91201: 'User already exists. All data will be inherited',
    91203: 'User already exists in this facility',
    91203: 'User already exists in this facility',
    91302: 'SIM information is not available',
    91303: 'The SIM contract is not available',
    91307: 'Cannot get serial number',
    91308: 'Activation key zero or empty',
    91309: 'Activation key null, empty or invalid',
    91310: 'This serial number is not assigned to a user',
    91311: 'Error communicating with prod service',
    91312: 'Error generating license',
    91400: 'Error clearing alarm',
    91400: 'Error clearing alarm',
    91450: 'The following schedules do not have a valid range: ',
    91451: 'Minimum one holiday day must be selected',
    91452: 'An in-use device does not support calendars with holidays',
    91452: 'The device does not support schedules with holidays',
    91453: 'The schedule name already exists',
    91527: 'Cannot delete facility with assigned universal group.',
    91528: 'Cannot delete equipment with assigned group.',
    91529: 'Cannot delete group with fobs.',
    91530: 'Cannot deallocate this device because the remaining device does not support fobs',
    91531: "Facility can't be reassigned because it uses universal groups",
    91532: 'Facility contains incompatible equipments',
    91534: 'Maximum Honoa devices reached for this facility ',
    91536: 'The installation name already exists',
    91537: 'Cannot delete a group with Honoa users',
    91538: 'Error downloading PDF',
    91539: 'You cannot disable a device currently being used at a facility.',
    91540: 'You cannot un-assign the device, since the group contains HONOA users',
    91800: 'Unsupported phone number format',
    91801: 'Unsupported url web format',
    91802: 'This device does not support fobs',
    91803: 'This group does not support fobs',
    91804: 'The name of one or more hours assigned to this installation is too long.',
    91853: 'The time zone cannot be empty.',
    91854: 'The group does not support HONOA users',
    92030: 'Your subscription is not compatible with that function.',
    92060: 'Initial date cannot be later than final date',
    92061: 'Initial date has to be later than today',
    92031: 'Function not allowed without limit switches (sensors/inputs)',
    communicationError: 'Communication error try again later',
    deletedUser: 'User deleted',
    generic: 'Unique identifier already used',
    groupsAssigned: 'Groups assigned: ',
    name: 'This name is not available',
    nameRequired: 'Could not save. Names required in families and products.',
    needsMinOnRelay: 'There must be at least one assigned relay',
    noContent: 'No content available for this list',
    operationNotCompleted: 'The operation could not be completed',
    redirectWarning: ' You have been redirected to the main page',
    selectTypeFirst: 'Select a type first',
    timeout: 'Timeout.',
    unexpectedError: 'Unexpected error',
    uniqueIdentity: 'This content already exists',
    universalGroupsAssigned: 'UG assigned: ',
    zeroCode: 'Careful! Work is being done with installer code 0',
    91075: 'Not enough available reservations',
    304: 'Literal is not valid (blank, lead or trail whitespaces, ...)',
  },
};
