import React from 'react';
import { TabsMenu } from '@jcm-technologies/uikit/dist/molecules/TabsMenu';
import { Box } from '@jcm-technologies/uikit/dist/atoms/Layout';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { getContrastColor } from '../../core/helpers/colorManager';
import { TableAcceptedGDPR } from '../../components/Owner/Accepted/Table';
import { ListClearGDPR } from '../../components/Owner/Clear/List';
import { ListPendingGDPR } from '../../components/Owner/Pending/List';

function Owner({ active }) {
  const history = useHistory();
  const { t } = useTranslation();
  const { color1 } = useSelector((state) => state.tenants);
  return (
    <Box
      backgroundColor='white'
      borderRadius={3}
      height='100%'
      minHeight='calc(100vh - 156px)'
      padding={2}
    >
      <TabsMenu
        id='userConfigTab'
        colorActive={getContrastColor(color1)}
        themeColor={color1}
        defaultSelected={active}
        components={{
          owner: <TableAcceptedGDPR />,
          clear: <ListClearGDPR />,
          pending: <ListPendingGDPR />,
        }}
        items={[
          {
            id: 'owner',
            title: t('owner.gdprAccepted'),
            onClick: () => history.push(`/owner`),
          },
          {
            id: 'clear',
            title: t('owner.gdprClear'),
            onClick: () => history.push(`/owner/clear-gdpr`),
          },
          {
            id: 'pending',
            title: t('owner.gdprPending'),
            onClick: () => history.push(`/owner/pending-gdpr`),
          },
        ]}
      />
    </Box>
  );
}

export default Owner;

Owner.propTypes = {
  active: PropTypes.oneOf(['owner, clear, pending']).isRequired,
};
