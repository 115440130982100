import _ from 'lodash';

const getNewArray = (array, i, valueName, value) => {
  array[i][valueName] = value;
  return [...array];
};

const getArrayNodeIndexByPropName = (array, obj, propName) =>
  array.findIndex((element) => element[propName] === obj[propName]);

const getArrayNodeByPropName = (array, propName, value) =>
  array.find((element) => element[propName] === value);

const updateArrayNodeByPropName = (array, obj, propName) => {
  const index = getArrayNodeIndexByPropName(array, obj, propName);
  array[index] = { ...obj };
  return [...array];
};

const addNodeToArray = (array, object) => [...array, object];

const deleteArrayNodeByPropName = (array, obj, propName) => {
  const newArray = [...array];
  const index = getArrayNodeIndexByPropName([...newArray], obj, propName);
  newArray.splice(index, 1);
  return [...newArray];
};

const deleteArrayElementsByPropName = (array, elementsList, propName) => {
  let newArray = [...array];
  elementsList.forEach((element) => {
    newArray = deleteArrayNodeByPropName(newArray, element, propName);
  });
  return [...newArray];
};

const updateArrayFromArrayByPropName = (array, elementsList, propName) => {
  let newArray = [...array];
  elementsList.forEach((element) => {
    newArray = updateArrayNodeByPropName(newArray, element, propName);
  });
  return [...newArray];
};

const convertBinaryToArray = (channelsMap) => {
  let channelBinary = channelsMap.toString(2);
  channelBinary = channelBinary.toString(2).padStart(4, '0');
  const binaryArray = _.reverse(channelBinary.split(''));
  return binaryArray;
};

const convertArrayToBinary = (array) => {
  const binaryString = _.reverse(array).join('');
  const binaryNum = parseInt(binaryString, 2);
  return binaryNum;
};

const convertObjectToArray = (object) => {
  const objectArray = Object.entries(object);
  const arrayResponse = [];
  objectArray.forEach(([key, value]) => {
    arrayResponse[key] = value;
  });
  return arrayResponse;
};

//returns an array of the passed params value getted from a object
const createArrayFromObjectParam = (array, param) => {
  return array.map((item) => item[param]);
};

export {
  getNewArray,
  updateArrayNodeByPropName,
  updateArrayFromArrayByPropName,
  deleteArrayNodeByPropName,
  getArrayNodeByPropName,
  getArrayNodeIndexByPropName,
  addNodeToArray,
  convertBinaryToArray,
  convertArrayToBinary,
  convertObjectToArray,
  deleteArrayElementsByPropName,
  createArrayFromObjectParam,
};
