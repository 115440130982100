import styled from 'styled-components';
import { increaseBrightness } from '../../core/helpers/colorManager';

export default styled.span`
  ${(props) =>
    props.active
      ? `
        color:white;
    `
      : ``}
  ${(props) =>
    props.isBlackIcone
      ? `
        color: black;
    `
      : ``}
  ${(props) =>
    props.customDefaultColor
      ? `
    color: ${props.customDefaultColor};
`
      : ``}
  ${(props) => (props.isHidden ? 'visibility:hidden;' : '')}
  ${(props) => `cursor:${props.cursor || 'default'}`}
  &:hover {
    ${(props) =>
      !props.disabled
        ? `color:${
            props.isIncreaseBrightnes
              ? increaseBrightness(props.tenants.color1, 60)
              : props.tenants.color1
          }`
        : `cursor: not-allowed;`}
  }
`;
