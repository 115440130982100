export default {
  signalRError: {
    11050: 'Risposta inattesa',
    11051: 'Dispositivo sbagliato',
    11052: 'Codifica errata',
    11053: 'Il dispositivo utilizza il codice di installazione',
    11054: 'Il dispositivo non utilizza il codice di installazione',
    11055: 'Tempo scaduto',
    11056: 'Assistente non pronto',
    11057: "L'assistente è occupato",
    11058: "C'è stato un errore",
    11059: 'Operazione negata',
    11060: 'Versione di avvio errata',
    11061: 'Ancora in corso',
    11062: 'Contatore sbagliato',
    11063: 'Numero seriale errato',
    11064: 'Errore durante la configurazione del dispositivo',
    11065: 'Azione sconosciuta',
    11066: 'Dispositivo sbagliato',
    11067: 'Errore fatale',
    11068: 'Cattivo crc',
    11069: 'Mancata corrispondenza della password',
    connectionTimeout: "Verificare la connessione con il pannello dell'assistente",
    dontMoveTag:
      "Si è verificato un errore. Ripeti l'operazione senza spostare il dispositivo dalla zona di programmazione.",
  },
};
