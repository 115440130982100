import {
  GET_STATISTICS,
  GET_STATISTICS_EQUIPMENT
} from './constants';

/* Redux state init */
const initialState = {
  isSuccess: false,
  data: null,
};

export default function statisticsReducer(state = initialState, action) {
  if (action.type !== undefined) {
    switch (action.type) {
      case GET_STATISTICS.REQUEST: {
        return {
          data: null,
          isSuccess: false,
        };
      }
      case GET_STATISTICS.SUCCESS: {
        return {
          ...state,
          data: action.result,
          isSuccess: true,
        };
      }
      case GET_STATISTICS_EQUIPMENT.REQUEST: {
        return {
          isSuccess: false,
        };
      }
      case GET_STATISTICS_EQUIPMENT.SUCCESS: {
        return {
          ...state,
          data: action.result,
          isSuccess: true,
        };
      }
      default:
        return state;
    }
  }
  return state;
}
