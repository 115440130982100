import React from 'react';
import ReactTooltip from 'react-tooltip';
import './ToolTip.css';

const ToolTip = ({ id, type, effect, children, className, dataEventOff }) => (
  <ReactTooltip
    html
    data-event-off={dataEventOff}
    className={className}
    id={id}
    type={type || 'dark'}
    effect={effect || 'solid'}
  >
    {children}
  </ReactTooltip>
);

export default ToolTip;
