export default {
  parameters: {
    title: 'Configuración de parámetros proxy Access 5k',
    pinCode: 'Código Pin',
    apn: 'APN',
    apnUser: 'Usuario APN',
    apnPassword: 'Contraseña APN',
    serverURL: 'Servidor URL',
    editproxy: 'Editar A5K Proxy',
    titleRelays: 'Relés en programación',
    errorRelaysPopUp: 'Error: No puedes exceder el numero máximo de relés',
    CONFIGRELAYSNEW1_ICON: 'Editar relés 1',
    CONFIGRELAYSNEW2_ICON: 'Editar relés 2',
    CONFIGRELAYSNEW3_ICON: 'Editar relés 3',
    CONFIGRELAYSNEW4_ICON: 'Editar relés 4',
    ACTIVEEVENTSTOSD_ICON: 'Editar eventos activos',
    A5KPROXY_ICON: 'Editar A5K proxy',
  },
};
