export default {
  neighbourPopUp: {
    title: 'Add Neighbor',
    email: 'E-mail',
    phoneNumber: 'Phone Number',
    floor: 'Floor',
    door: 'Door',
    titleEdit: 'Edit contact',
    titleDelete: 'Are you sure you want to delete the contact?',
  },
};
