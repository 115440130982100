export default {
  fobsPopUps: {
    instalations: 'Installationen',
    groups: 'Benutzergruppen',
    move: 'Bewegung',
    copy: 'Kopieren',
    remove: 'Löschen',
    removeQuestion: 'Möchten Sie die folgenden Geräte löschen?',
    selectedItemsShow1: 'Möchten Sie die ',
    selectedItemsShow2: ' ausgewählten Elemente löschen?',
    warningDeleteFobs:
      'ACHTUNG: Eventuell haben Sie mehr Elemente ausgewählt als Sie gerade auf dem Bildschirm sehen.',
    selectedItemsMove1: 'Möchten Sie die ',
    selectedItemsCopy1: 'Möchten Sie die ',
    add: 'Hinzufügen',
    selectedItemsAdd1: 'Möchten Sie die hinzufügen',
  },
};
