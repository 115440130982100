import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { v1 as uuidv1 } from 'uuid';
import Popup from '../../../../old_components/Popup';
import ContentContainer from '../../../../old_components/ContentContainer';
import ButtonsGroup from '../../../../old_components/ButtonsGroup/ButtonsGroup';
import CheckableContent from './components/CheckableContent';
import {
  getMemoryCodes,
  getMemoryCompare,
  getMemoryConfig,
  resetCompareMemory,
  resetMemoryConfig,
} from '../../../../modules/old_to_refact/actions/drivers';
import { getFamiliesSingleList } from '../../../../modules/productFamilies/action';

import { putEquipmentIsUpdated } from '../../../../modules/old_to_refact/actions/equipments';
import './CompareMemoryPopup.css';
import GenericForm from '../../../../old_components/GenericForm/GenericForm';
import {
  getInstalationGroupsAssigned,
  resetInstalationGroups,
} from '../../../../modules/old_to_refact/actions/instalationGroups';
import {
  deleteCompareMemoryFobs,
  postCompareMemoryFOBList,
} from '../../../../modules/group/fobs/actions';
import DeletePopup from '../../../../old_components/DeletePopup/DeletePopup';
import { objectsAreEquals } from '../../../../core/old_common/utils/objectsService';
import {
  checkAssistantPanelConnection,
  invokeSendCICC,
  invokeSendFunction,
  setAssistantPanelCheckSuccessFunction,
  setMessageHubFunction,
} from '../../../../core/old_common/utils/signalRService';
import { getFamilyListByType, getProduct } from '../../../../core/old_common/utils/familiesService';
import apiPathFactory from '../../../../core/old_common/rooting/apiPathFactory'; // '../../common/rooting/apiPathFactory'
import { downloadFile, getFilesFromApi } from '../../../../core/helpers/filesApi';

class CompareMemoryPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFoundBBDDChecked: false,
      isFoundMemoryChecked: false,
      isDeleteOpen: false,
      compareFOBS: [],
      isGroupOpen: false,
      selectedGroup: '',
      selectedButton: '',
      codeFilter: '',
      canSaveMemory: false,
      needIconRefresh: false,
      equipmentIsFullUpdated: true,
    };
  }

  componentWillUnmount = () => {
    this.props.resetInstalationGroups();
  };

  componentDidUpdate(prevProps) {
    const {
      equipment,
      isOpen,
      codes,
      compare,
      getInstalationGroupsAssigned,
      config,
      updatedEquipment,
      isUpdated,
    } = this.props;
    if (!prevProps.isOpen && isOpen) {
      getInstalationGroupsAssigned(equipment.facilityId, equipment.id);
    }
    if (compare && !objectsAreEquals(compare, prevProps.compare)) {
      this.setState({ compareFOBS: compare });
    }

    if (this.state.canSaveMemory && codes && config) {
      this.setState({ canSaveMemory: false });
      setAssistantPanelCheckSuccessFunction(invokeSendCICC);
      setMessageHubFunction(this.readCICC);
      checkAssistantPanelConnection();
    }

    if (isUpdated === 1 && updatedEquipment) {
      if (updatedEquipment && !this.state.needIconRefresh && this.state.equipmentIsFullUpdated) {
        //   this.changeSaveMemoryIconColor('green');
        this.setState({ equipmentIsFullUpdated: false });
      } else if (this.state.needIconRefresh) {
        this.changeSaveMemoryIconColor('#a4aebb');
        this.setState({ needIconRefresh: false });
      }
    }
  }

  changeSaveMemoryIconColor = (color) => {
    const element = document.getElementById('saveConfig');
    if (element != null) {
      document.getElementById('saveConfig').style.color = color;
    }
  };

  getColumns = () => {
    const { t } = this.props;
    return [
      {
        key: 'code',
        name: t('form.code'),
        sortable: false,
        filterable: false,
      },
    ];
  };

  handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  setDisabledFobsList = (fob, selectedFob) => {
    const fobPropNames = this.getFobOppositeStatePropName(selectedFob);
    if (fob[fobPropNames.selectedProp]) {
      fob.isDisabled = false;
    } else if (fob[fobPropNames.opposite]) {
      fob.isDisabled = true;
    }
  };

  getFobOppositeStatePropName = (fob) => {
    let propName = '';
    if (fob.existsInEquipment) {
      propName = 'existsInEquipment';
    } else if (fob.existsInMemory) {
      propName = 'existsInMemory';
    }
    return {
      selectedProp: propName,
      ...this.oppositePropName[propName],
    };
  };

  oppositePropName = {
    existsInMemory: {
      opposite: 'existsInEquipment',
      selectedState: 'isFoundMemoryChecked',
      oppositeState: 'isFoundBBDDChecked',
    },
    existsInEquipment: {
      opposite: 'existsInMemory',
      selectedState: 'isFoundBBDDChecked',
      oppositeState: 'isFoundMemoryChecked',
    },
  };

  fobOnChange = (id, isChecked) => {
    const { compareFOBS } = { ...this.state };
    const selectedCode = parseInt(id);
    const fobIndex = compareFOBS.findIndex((fob) => fob.code === selectedCode);
    compareFOBS[fobIndex].selected = isChecked;
    if (isChecked) {
      compareFOBS.forEach((fob) => this.setDisabledFobsList(fob, compareFOBS[fobIndex]));
    } else {
      const selectedCount = compareFOBS.filter((fob) => fob.selected).length;
      if (!selectedCount) {
        compareFOBS.forEach((fob) => (fob.isDisabled = false));
      }
    }
    this.setSelectAllCheck(compareFOBS, compareFOBS[fobIndex]);
    this.setState({ compareFOBS });
  };

  setSelectAllCheck = (fobsList, selectedFob) => {
    const fobPropNames = this.getFobOppositeStatePropName(selectedFob);
    const total = fobsList.filter(
      (fob) => fob[fobPropNames.selectedProp] && !fob[fobPropNames.opposite]
    );
    const allSelected = total.length === total.filter((fob) => fob.selected).length;
    this.setState({ [fobPropNames.selectedState]: allSelected });
  };

  fobListClassColor = (fob) => {
    let className = '';
    if (fob.existsInEquipment && fob.existsInMemory) {
      className = 'found-on-both-checkable compare-memory-popup-grid';
    } else if (fob.existsInEquipment) {
      className = 'found-on-bbdd-checkable compare-memory-popup-grid';
    } else if (fob.existsInMemory) {
      className = 'found-on-memory-checkable compare-memory-popup-grid';
    }
    return className;
  };

  onSelectorsChange = (isChecked, propName, id) => {
    const { compareFOBS } = { ...this.state };
    compareFOBS.forEach((fob) => {
      if (!this.checkedBothFOBProps(fob)) {
        if (fob[propName] && fob.selected !== isChecked) {
          fob.selected = isChecked;
          fob.isDisabled = false;
        } else if (fob[this.oppositePropName[propName].opposite]) {
          fob.selected = false;
          fob.isDisabled = isChecked;
        }
      }
    });
    const oppositeGroupPropName =
      id === 'isFoundBBDDChecked' ? 'isFoundMemoryChecked' : 'isFoundBBDDChecked';
    this.setState({
      compareFOBS,
      [id]: isChecked,
      [oppositeGroupPropName]: false,
    });
  };

  checkedBothFOBProps = (fob) => fob.existsInEquipment && fob.existsInMemory;

  onPopupClose = () => {
    const { resetCompareMemory, closePopup, resetMemoryConfig } = this.props;
    resetCompareMemory();
    this.setState({
      isFoundBBDDChecked: false,
      isFoundMemoryChecked: false,
      compareFOBS: [],
      isGroupOpen: false,
      canSaveMemory: false,
    });
    resetMemoryConfig();
    closePopup();
  };

  getGroupsComboList = (list) => {
    let values = list;
    if (values) {
      values = values.map((object, i) => ({
        key: object.groupId,
        id: object.groupId,
        value: object.name,
      }));
      return [{ key: '', id: '', value: '--' }, ...values];
    }
    return [];
  };

  getFields = () => {
    const { facilityGroups, t } = this.props;
    return [
      [
        {
          type: 'dropdown',
          placeHolder: t('title.group'),
          value: this.state.selectedGroup,
          values: this.getGroupsComboList(facilityGroups),
          id: 'selectedGroup',
          onChange: this.handleChange,
        },
      ],
    ];
  };

  handleSubmitBBDD = (selectedButton = this.state.selectedButton) => {
    const { deleteCompareMemoryFobs, postCompareMemoryFOBList, equipment } = this.props;
    const selectedFobs = this.state.compareFOBS.filter((fob) => fob.selected);
    if (selectedButton === 'addBBDD') {
      postCompareMemoryFOBList(
        equipment.facilityId,
        this.state.selectedGroup,
        selectedFobs.map((x) => ({ ...x, id: uuidv1() }))
      );
      this.onPopupClose();
    } else {
      deleteCompareMemoryFobs(
        equipment.facilityId,
        '00000000-0000-0000-0000-000000000000',
        selectedFobs
      );
      this.setState({ compareFOBS: this.state.compareFOBS.filter((fob) => !fob.selected) });
    }
    this.setState({ isGroupOpen: false, isDeleteOpen: false, selectedButton: '' });
  };

  handleCancelBBDD = () => {
    this.setState({ isGroupOpen: false, selectedGroup: '', selectedButton: '' });
  };

  readCICC = () => {
    const { codes, equipment, config, familiesSingleList, installerCode } = this.props;
    setMessageHubFunction(this.memorySaved);
    const product = getProduct(getFamilyListByType(familiesSingleList, 3), equipment.productId);
    invokeSendFunction(
      `${product.driverName}::${config}::${codes}::${product.productType}::${installerCode}`,
      90000
    );
  };

  memorySaved = () => {
    const { t } = this.props;
    toast.success(t('equipments.savedMemory'));
    this.onPopupClose();
  };

  fobCodeIsReserved = (code) => code >= 1000000 && code <= 1047000;

  render() {
    const { isOpen, tenants, t, getMemoryCodes, codes, equipment } = this.props;
    const { compareFOBS, codeFilter } = this.state;
    const selectedFobList = compareFOBS
      ? compareFOBS.filter(
          (fob) =>
            !codeFilter || (`${fob.code}`.includes(codeFilter) && !this.fobCodeIsReserved(fob.code))
        )
      : [];
    return (
      <div>
        <Popup isOpen={isOpen} onClose={this.onPopupClose} className='compare-memory-ppup'>
          <ContentContainer title='Comparacion de memoria'>
            {' '}
            {/* traduce esto perro */}
            <span className='compare-memory-popup-content'>
              <span className='compare-memory-popup-grid-container'>
                <span className='compare-memory-popup-grid-header'>{t('form.code')}</span>
                <span className='compare-memory-popup-grid-filter'>
                  <i className='fas fa-search compare-memory-popup-grid-search-icon' />
                  <input
                    className='compare-memory-popup-grid-filter-input'
                    placeholder={t('compareMemory.filter')}
                    value={this.state.codeFilter}
                    onChange={(e) => this.setState({ codeFilter: e.target.value })}
                    type='search'
                  />
                </span>
                <span className='compare-memory-popup-grid-list'>
                  {selectedFobList && selectedFobList.length ? (
                    selectedFobList.map((fob, i) => (
                      <CheckableContent
                        id={fob.code}
                        key={i}
                        isDisabled={fob.isDisabled}
                        checkHidden={fob.existsInEquipment && fob.existsInMemory}
                        value={
                          this.fobCodeIsReserved(fob.code) ? t('compareMemory.reserved') : fob.code
                        }
                        checked={fob.selected}
                        onChange={this.fobOnChange}
                        className={this.fobListClassColor(fob)}
                      />
                    ))
                  ) : (
                    <span className='compare-memory-popup-grid-nodata'>
                      {t('compareMemory.nodata')}
                    </span>
                  )}
                </span>
              </span>
              <span className='compare-memory-popup-selector'>
                <CheckableContent
                  value={t('compareMemory.foundInBoth')}
                  id='both'
                  checkHidden
                  className='found-on-both-checkable'
                />
                <CheckableContent
                  value={t('compareMemory.foundInBBDD')}
                  id='isFoundBBDDChecked'
                  checked={this.state.isFoundBBDDChecked}
                  isDisabled={
                    !this.state.compareFOBS.filter(
                      (fob) => fob.existsInEquipment && !fob.existsInMemory
                    ).length
                  }
                  onChange={(id, isChecked) =>
                    this.onSelectorsChange(isChecked, 'existsInEquipment', id)
                  }
                  className='found-on-bbdd-checkable'
                />
                <CheckableContent
                  value={t('compareMemory.foundInMemory')}
                  id='isFoundMemoryChecked'
                  checked={this.state.isFoundMemoryChecked}
                  isDisabled={
                    !this.state.compareFOBS.filter(
                      (fob) => !fob.existsInEquipment && fob.existsInMemory
                    ).length
                  }
                  onChange={(id, isChecked) =>
                    this.onSelectorsChange(isChecked, 'existsInMemory', id)
                  }
                  className='found-on-memory-checkable'
                />
              </span>
            </span>
            <ButtonsGroup
              className='compare-memory-popup-buttons'
              buttons={[
                {
                  value: t('compareMemory.refreshMemory'),
                  onClick: () => {
                    if (!codes && equipment.productCode !== 307) {
                      getMemoryCodes(equipment.id, equipment.facilityId);
                    } else if (equipment.productCode === 307) {
                      const endpoint = apiPathFactory.a5kGetFileContent(
                        equipment.facilityId,
                        equipment.id,
                        0
                      );
                      getFilesFromApi(
                        endpoint,
                        (isError, response) => {
                          if (!isError) {
                            const fileName = 'Access5KCodesM.JCMbin';
                            downloadFile(response, fileName);
                          }
                        },
                        'application/json',
                        'binary'
                      );
                      this.props.putEquipmentIsUpdated(equipment.facilityId, equipment.id);
                      this.setState({ needIconRefresh: true });
                    }
                    this.setState({ canSaveMemory: true });
                  },
                },
                {
                  value: t('compareMemory.deleteBBDD'),
                  disabled: !this.state.compareFOBS.filter(
                    (fob) => fob.existsInEquipment && !fob.existsInMemory && fob.selected
                  ).length,
                  onClick: () =>
                    this.setState({ isDeleteOpen: true, selectedButton: 'deleteBBDD' }),
                },
                {
                  value: t('compareMemory.addBBDD'),
                  disabled: !this.state.compareFOBS.filter(
                    (fob) => fob.existsInMemory && !fob.existsInEquipment && fob.selected
                  ).length,
                  onClick: () => this.setState({ isGroupOpen: true, selectedButton: 'addBBDD' }),
                },
              ]}
            />
            <Popup
              isOpen={this.state.isGroupOpen}
              onClose={() => this.setState({ isGroupOpen: false })}
            >
              <ContentContainer title={t('title.group')}>
                <GenericForm
                  tenants={tenants}
                  isSubmitDisabled={!this.state.selectedGroup}
                  fields={this.getFields()}
                  handleCancel={this.handleCancelBBDD}
                  handleSubmit={() => this.handleSubmitBBDD('addBBDD')}
                  isCancelVisible
                />
              </ContentContainer>
            </Popup>
            <DeletePopup
              isOpen={this.state.isDeleteOpen}
              title={t('compareMemory.confirmDelete')}
              handleAccept={() => this.handleSubmitBBDD('deleteBBDD')}
              handleCancel={() => this.setState({ isDeleteOpen: false })}
            />
          </ContentContainer>
        </Popup>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  config: state.drivers.config,
  compare: state.drivers.compare,
  codes: state.drivers.codes,
  familiesSingleList: state.families.familiesSingleList,
  tenants: state.tenants,
  facilityGroups: state.instalationGroups.facilityGroups,
  isUpdated: state.equipments.isUpdated,
  updatedEquipment: state.equipments.updatedEquipment,
});

const CompareMemoryPopupWithTranslation = withTranslation()(CompareMemoryPopup);

export default connect(mapStateToProps, {
  getInstalationGroupsAssigned,
  resetInstalationGroups,
  putEquipmentIsUpdated,
  resetMemoryConfig,
  getFamiliesSingleList,
  getMemoryCompare,
  resetCompareMemory,
  getMemoryCodes,
  getMemoryConfig,
  postCompareMemoryFOBList,
  deleteCompareMemoryFobs,
})(CompareMemoryPopupWithTranslation);
