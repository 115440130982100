import i18next from 'i18next';
import { getter } from '@progress/kendo-react-common';
import moment from 'moment/moment';
import IconClockRotateLeft from '@jcm-technologies/uikit/dist/atoms/Icons/IconClockRotateLeft';
import IconLock from '@jcm-technologies/uikit/dist/atoms/Icons/IconLock';
import IconUnlock from '@jcm-technologies/uikit/dist/atoms/Icons/IconUnlock';
import IconError from '@jcm-technologies/uikit/dist/atoms/Icons/IconError';
import IconHourglassEnd from '@jcm-technologies/uikit/dist/atoms/Icons/IconHourglassEnd';
import React from 'react';
import { replaceStringValue } from '../../../../core/old_common/utils/replaceStringService';
import { IconLockOpen } from '@jcm-technologies/uikit/dist/atoms/Icons';

export const formatDateValidationLicence = 'MM/DD/YYYY HH:MM:SS A';

export const DATA_ITEM_KEY = 'id';
export const EDIT_FIELD = 'inEdit';
export const SELECTED_FIELD = 'selected';
export const idGetter = getter(DATA_ITEM_KEY);

export const actionGroup = ({
  setIsOpenModalDeleteMultiUsers,
  setIsOpenModalMoveMultiUsers,
  setIsOpenModalCopyMultiUsers,
}) => [
  {
    action: () => {
      setIsOpenModalDeleteMultiUsers(true);
    },
    children: i18next.t('general.delete'),
    id: 'delete-users',
  },
  {
    action: () => {
      setIsOpenModalMoveMultiUsers(true);
    },
    children: i18next.t('general.move'),
    id: 'move-users',
  },
  {
    action: () => {
      setIsOpenModalCopyMultiUsers(true);
    },
    children: i18next.t('general.copy'),
    id: 'copy-users',
  },
];

export const fileExcelName = ({ idFacility, idGroup }) =>
  `${moment().format('YYYY-MM-DD')}_${replaceStringValue(
    idFacility.substring(0, 50),
    'Facility'
  )}_${replaceStringValue(idGroup, 'Group')}`;

export const setStatusCellsTable = ({
  locked,
  userId,
  isPropertyManagerLiteRole,
  onToggleLockUserLicence,
}) => ({
  0: {
    type: 'pending',
    icon: <IconClockRotateLeft />,
    tooltip: i18next.t('invitation.pending'),
    action: () => {},
  },
  1: {
    type: 'lock',
    icon: locked ? <IconLock /> : <IconLockOpen />,
    tooltip: locked
      ? i18next.t('instalationUserGroups.locked')
      : i18next.t('instalationUserGroups.unlocked'),
    action: () => !isPropertyManagerLiteRole && onToggleLockUserLicence(locked, userId),
  },
  2: {
    type: 'declined',
    icon: <IconError />,
    tooltip: i18next.t('invitation.declined'),
    action: () => {},
  },
  3: {
    type: 'expired',
    icon: <IconHourglassEnd />,
    tooltip: i18next.t('invitation.expired'),
    action: () => {},
  },
  undefined: {
    type: 'declined',
    icon: <IconError />,
    tooltip: i18next.t('invitation.declined'),
    action: () => {},
  },
});
