import { fork } from 'redux-saga/effects';
import tenants from '../../tenants/saga';
import login from './login';
import invitations from '../../invitations/saga';
import user from '../../user/saga';
import customers from '../../customers/sagas';
import families from '../../productFamilies/saga';
import universalGroups from './universalGroups';
import schedules from '../../schedule/saga';
import instalations from './instalations';
import instalationGroups from './instalationGroups';
import fobs from '../../group/fobs/saga';
import equipments from './equipments';
import owners from '../../owner/sagas';
import universalGroupsFobs from './universalGroupsFobs';
import a5KServices from './a5Kservices';
import neighbour from './neighbour';
import drivers from './drivers';
import globalSearch from '../../globalSearch/saga';
import groups from './groups';
import devices from './devices';
import groupUsers from '../../group/users/saga';
import dashboard from '../../dashboard/saga';
import holidays from '../../holidays/saga'
import statistics from '../../statistics/saga';
import notifications from '../../notifications/saga';
import mifare from '../../group/mifare/saga';
import countries from '../../countries/saga';

export default function* root() {
  yield fork(tenants);
  yield fork(login);
  yield fork(invitations);
  yield fork(user);
  yield fork(customers);
  yield fork(families);
  yield fork(universalGroups);
  yield fork(schedules);
  yield fork(instalations);
  yield fork(instalationGroups);
  yield fork(fobs);
  yield fork(equipments);
  yield fork(universalGroupsFobs);
  yield fork(owners);
  yield fork(a5KServices);
  yield fork(neighbour);
  yield fork(drivers);
  yield fork(globalSearch);
  yield fork(groups);
  yield fork(devices);
  yield fork(groupUsers);
  yield fork(dashboard);
  yield fork(holidays);
  yield fork(statistics);
  yield fork(notifications);
  yield fork(mifare);
  yield fork(countries);
}
