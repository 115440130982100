import { Header } from '@jcm-technologies/uikit/dist/organisms/Header';
import { useDispatch, useSelector } from 'react-redux';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';
import { setSidebarCollapsed } from '../../../modules/state/sidebar/actions';
import {
  deleteCookieAll,
  getCookieAccessToken,
  setCookie,
} from '../../../core/old_common/utils/cookiesManager';
import {
  getListOfGlobalSearch,
  manualDownloaded,
  manualDownloadedBeta,
  menuItemsList,
} from './config';
import { getGlobalSearch, resetGlobalSearchResult } from '../../../modules/globalSearch/actions';
import { setPageNumber } from '../../../modules/state/pagination/actions';
import {
  setSearchQuery,
  setSelectedDescendants,
} from '../../../modules/state/visibleDescendants/actions';
import {
  FACILITY_DESCENDANT,
  GROUP_DESCENDANT,
  SCHEDULE_DESCENDANT,
  UG_DESCENDANT,
} from '../../../modules/state/visibleDescendants/constants';
import { base64ToImage } from '../../../core/helpers/getImagesBase64';
import { clearNotification, resetNotifications } from '../../../modules/notifications/actions';
import {
  FACILITY_LIST,
  GROUPS_LIST,
  SCHEDULE_LIST,
  UG_LIST,
} from '../../../modules/state/pagination/constants';

export const HeaderNavbar = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const match = useRouteMatch();
  const { instance } = useMsal();
  const { t } = useTranslation();
  const isCollapsed = useSelector((state) => state.app.isSidebarCollapsed);
  const tenants = useSelector((state) => state.tenants);
  const { result: globalSearchResult } = useSelector((state) => state.globalSearch);
  const { viewMore, searchQuery } = useSelector((state) => state.visibleDescendants);
  const { notifications } = useSelector((state) => state.notifications);
  const isAuthenticated = useIsAuthenticated();
  const userToken = getCookieAccessToken();
  const isAuth = isAuthenticated && userToken;
  const {
    user: { avatar: avatarUser, language, customerId, roleMask },
  } = useSelector((state) => state.user);

  const onCloseSession = () => {
    deleteCookieAll();
    Sentry.setUser(null);
    instance.logoutRedirect();
  };

  const setVisibleDescendants = () => {
    if (roleMask === 8 || roleMask === 16) {
      return [{ label: t('globalSearch.allOwners'), value: customerId }];
    }
    return [
      {
        label: t('globalSearch.allOwners'),
        value: 'ffffffff-ffff-ffff-ffff-ffffffffffff',
      },
    ];
  };

  const handleSearchGoToAll = (base) => {
    const url = `/${base !== 'facilityGroups' ? base : 'groups'}`;
    dispatch(setSelectedDescendants(FACILITY_DESCENDANT, setVisibleDescendants()));
    dispatch(setSelectedDescendants(UG_DESCENDANT, setVisibleDescendants()));
    dispatch(setSelectedDescendants(SCHEDULE_DESCENDANT, setVisibleDescendants()));
    dispatch(setSelectedDescendants(GROUP_DESCENDANT, setVisibleDescendants()));
    dispatch(getListOfGlobalSearch[base]);
    dispatch(setPageNumber(FACILITY_LIST, 1));
    dispatch(setPageNumber(SCHEDULE_LIST, 1));
    dispatch(setPageNumber(UG_LIST, 1));
    dispatch(setPageNumber(GROUPS_LIST, 1));
    history.push(url);
  };

  const handleSearchClearListed = () => {
    dispatch(setSearchQuery('', false));
    dispatch(setSelectedDescendants(FACILITY_DESCENDANT, []));
    dispatch(setSelectedDescendants(UG_DESCENDANT, []));
    dispatch(setSelectedDescendants(SCHEDULE_DESCENDANT, []));
    dispatch(setSelectedDescendants(GROUP_DESCENDANT, []));
    if (match.path === '/Facilities' || match.path === '/facilities') {
      dispatch(getListOfGlobalSearch.facilities);
    } else if (match.path === '/UniversalGroups' || match.path === '/universalGroups') {
      dispatch(getListOfGlobalSearch.universalGroups);
    } else if (match.path === '/groups') {
      history.push('/facilities');
    } else if (match.path === '/schedules') {
      dispatch(getListOfGlobalSearch.schedules);
    }
  };

  // eslint-disable-next-line no-unused-vars
  const handleSearchGoTo = (base, id = '', customerID = '', secondaryID = '') => {
    const url = !secondaryID ? `/${base}/${id}` : `/facilities/${id}/groups/${secondaryID}`;
    // Refactor: This if it's for facilityGroups case, the store it's broken and not reload correctly. When we refact, we can remove this. :pray:
    if (url.includes('/groups/')) {
      window.location.replace(url);
    } else {
      handleSearchClearListed();
      history.push(url);
    }
  };

  const handleGoToEquipment = (facilityId, equipmentId) => {
    history.push(`/facilities/${facilityId}/Equipments/${equipmentId}/Info`);
    window.location.reload(); // TODO: Remove this line after Equipments refactor.
  };

  return (
    <Header
      searchQuery={searchQuery}
      handleNotificationsMarkAllRead={() => dispatch(resetNotifications())}
      handleSearchGoTo={handleSearchGoTo}
      handleSearchGoToAll={handleSearchGoToAll}
      isAuth={isAuth}
      isSidebarCollapsed={isCollapsed}
      menuItems={menuItemsList(history, onCloseSession)}
      messageNotificationsEmpty={t('general.notNotifications')}
      messageNotificationsInstallation={t('title.facility')}
      messageNotificationsMarkAll={t('general.markAllSee')}
      messageNotificationsTitle={t('title.notifications')}
      messageSearchPlaceholder={t('general.searchDotted')}
      messageSearchEmpty={t('general.notResults')}
      messageSearchViewMore={t('general.viewMore')}
      messageSearchFacilities={t('title.facilities')}
      messagesSearchUniversalGroups={t('title.universalGroups')}
      messageSearchSchedules={t('title.schedules')}
      messageSearchGroups={t('title.groups')}
      notificationsCount={notifications?.length}
      notificationsList={notifications}
      onClickHelp={() => {
        window.location.origin.includes('-beta')
          ? window.open(manualDownloadedBeta[language] || manualDownloadedBeta.default, '_blank')
          : window.open(manualDownloaded[language] || manualDownloaded.default, '_blank');
      }}
      onNotificationsGoTo={(facilityId, id, item) => {
        dispatch(clearNotification(id));
        item?.actionCode === 44802
          ? handleGoToEquipment(facilityId, item?.sourceId)
          : history.push(`/facilities/${facilityId}`);
      }}
      onSearchAction={(searchQueryType) => {
        setCookie('facilitiesSearch', null);
        setCookie('groupsSearch', null);
        setCookie('fobHistorySearch', null);
        dispatch(
          setSearchQuery(
            searchQueryType.replace(/\&/g, '%26').replace(/\#/g, '%23').replace(/\=/g, '%3D'),
            true
          )
        );
        dispatch(getGlobalSearch(searchQueryType.replace(/\#/g, '%23').replace(/\&/g, '%26')));
      }}
      onSearchCleanList={() => dispatch(resetGlobalSearchResult())}
      onSidebarCollapseAction={() => {
        dispatch(setSidebarCollapsed(!isCollapsed));
      }}
      picture={base64ToImage(avatarUser)}
      searchList={globalSearchResult}
      setterNotificationActionTitle={{
        44800: t('code.44800'),
        44801: t('code.44801'),
        44802: t('code.44802'),
      }}
      themeColor={tenants?.color1}
      haveSearchListed={viewMore}
      onSearchClearListed={handleSearchClearListed}
      messageNotificationsDevice={t('general.device.one')}
    />
  );
};
