import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BoxHistory from '../../../../components/Facility/Group/HistoryFob/Box';
import { getGroupFobHistory } from '../../../../modules/old_to_refact/actions/instalationGroups';
import { getFacility } from '../../../../modules/old_to_refact/actions/instalations';
import { resetGroupFobHistory } from '../../../../modules/old_to_refact/actions/groups';
import { mapperLogs } from './config';

const FacilityGroupFobView = () => {
  const { id: idGroup, idFob, instalationId } = useParams();
  const { groupFobHistory } = useSelector((state) => state.instalationGroups);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getFacility(instalationId));
    dispatch(getGroupFobHistory(instalationId, idGroup, idFob));
    return () => dispatch(resetGroupFobHistory());
  }, []);
  return <BoxHistory item={mapperLogs(groupFobHistory)} />;
};

export default FacilityGroupFobView;
