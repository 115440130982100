import { Modal } from '@jcm-technologies/uikit/dist/molecules/Modal';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';
import Table from '../../../common/Table';
import { CustomFilter } from '../../../common/Table/renderers';

export function ModalScheduleAssignedFacilities({ data, isOpened = false, setIsOpened }) {
  const { t } = useTranslation();
  const [dataLoaded, setDataLoaded] = useState(data || []);
  return (
    <Modal
      isOpen={isOpened}
      title={t('schedule.affectedFacilitiesUsedAt')}
      closeWithButton
      id='modal-schedule-assigned-facilities'
      width={[300, 500, 664]}
      onModalClose={() => setIsOpened(null)}
    >
      <Modal.Content>
        <Table
          editable={false}
          data={data}
          dataLoaded={dataLoaded}
          setDataLoaded={setDataLoaded}
          pageable
          sortable
        >
          <GridColumn field='name' title={t('form.name')} filterCell={CustomFilter} />
          <GridColumn field='address' title={t('form.address')} filterCell={CustomFilter} />
          <GridColumn field='city' title={t('form.city')} filterCell={CustomFilter} />
          <GridColumn field='groupName' title={t('title.group')} filterCell={CustomFilter} />
        </Table>
      </Modal.Content>
    </Modal>
  );
}

ModalScheduleAssignedFacilities.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  setIsOpened: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      address: PropTypes.string,
      city: PropTypes.string,
      groupName: PropTypes.string,
      name: PropTypes.string,
    })
  ).isRequired,
};
