export default {
  signalRError: {
    11050: 'Réponse inattendue',
    11051: 'Mauvais appareil',
    11052: 'Mauvais codage',
    11053: "L'appareil utilise le code d'installation",
    11054: "L'appareil n'utilise pas le code d'installation",
    11055: 'Temps libre',
    11056: 'Assistant pas prêt',
    11057: "L'assistant est occupé",
    11058: 'Une erreur est survenue',
    11059: 'Opération refusée',
    11060: 'Version de démarrage incorrecte',
    11061: 'Encore en progrès',
    11062: 'Mauvais compteur',
    11063: 'Numéro de série incorrect',
    11064: "Erreur lors de la configuration de l'appareil",
    11065: 'Action inconnue',
    11066: 'Mauvais appareil',
    11067: 'Erreur fatale',
    11068: 'Mauvais crc',
    11069: 'Non concordance des mots de passe',
    connectionTimeout: 'Vérifier la connexion avec le panneau assistant',
    dontMoveTag:
      "Une erreur est survenue. Répétez l'opération sans déplacer le dispositif de la zone de programmation.",
  },
};
