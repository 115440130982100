import signalRActions from '../actions/signalr';

/* Redux state init */
const initialState = {
  connectionData: {},
  connection: undefined,
  hubFunction: undefined,
  onAssistantCheckSuccess: undefined,
  onSignalRError: undefined,
  isConnected: false,
  needPopup: undefined,
  showSignalRPopup: false,
  message: undefined,
  productId: undefined,
};

export default function tenantsReducer(state = initialState, action) {
  if (action.type !== undefined) {
    switch (action.type) {
      case signalRActions.SET_SIGNALR_CONNECTION_DATA:
        return { ...state, connectionData: action.connectionData };
      case signalRActions.SET_SIGNALR_CONNECTION:
        return { ...state, connection: action.connection };
      case signalRActions.SET_NEW_MESSAGE_HUB_FUNCTION:
        return { ...state, hubFunction: action.hubFunction };
      case signalRActions.SET_ASSISTANT_CHECK_SUCCESS_FUNCTION:
        return { ...state, onAssistantCheckSuccess: action.onSuccessFunction };
      case signalRActions.SET_SIGNALR_CONNECTION_STATUS:
        return { ...state, isConnected: action.isConnected };
      case signalRActions.SET_SIGNALR_CALL_ERROR:
        return { ...state, onSignalRError: action.errorFunction };
      case signalRActions.SET_SIGNALR_POPUP:
        return { ...state, needPopup: action.needPopup };
      case signalRActions.SET_SIGNALR_SHOW_POPUP:
        return { ...state, showSignalRPopup: action.showPopup, message: action.message };
      case signalRActions.RESET_SIGNALR_POPUP:
        return { ...state, showSignalRPopup: false, message: undefined, needPopup: undefined };
      case signalRActions.SET_SIGNALR_PRODUCT_ID: {
        return { ...state, productId: action.productId };
      }
      case signalRActions.RESET_SIGNALR_PRODUCT_ID: {
        return { ...state, productId: undefined };
      }
      default:
        return state;
    }
  }
  return state;
}
