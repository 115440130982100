export default {
  resetPassword: {
    email: 'E-mail',
    newPassword: 'Nuova password',
    backToLogin: 'Torna alla pagina di accesso',
    mailReseted: 'La tua e-mail è stata ripristinata',
    passwordReset: 'Resetta la password',
    send: 'Spedire',
  },
};
