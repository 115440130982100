export default {
  compareMemory: {
    nodata: 'No data available in this table',
    filter: 'Filter',
    foundInBoth: 'Found on both sites',
    foundInBBDD: 'Found in database',
    foundInMemory: 'Found in memory',
    refreshMemory: 'Refresh memory',
    deleteBBDD: 'Delete from database',
    addBBDD: 'Add to database',
    confirmDelete: 'Do you want to remove the fobs?',
    reserved: 'Reserved',
  },
};
