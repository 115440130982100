import {
  GET_CUSTOMERS_CUSTOMER_ROLES,
  GET_CUSTOMERS_HONOA,
  GET_CUSTOMERS_INSTALLER_CODE,
  GET_CUSTOMERS_LICENSES,
  GET_CUSTOMERS_PROPERTY_MANAGERS,
  PATCH_CUSTOMERS_CUSTOMER_ROLE,
  PUT_CUSTOMER_ATTRIBUTES,
  PUT_CUSTOMER_ATTRIBUTES_HONOA,
  PUT_CUSTOMER_LICENSES,
  PUT_CUSTOMERS_INSTALLER_CODE,
  RESET_CUSTOMERS_CUSTOMER_ROLES,
} from './constants';

export const getCustomerInstallerCode = () => ({
  type: GET_CUSTOMERS_INSTALLER_CODE.BASE,
});

export const updateCustomersCostumer = ({ data, id }) => ({
  type: PUT_CUSTOMER_LICENSES.BASE,
  data,
  id,
});

export const updateCustomersInstallerCode = (installerCode) => ({
  type: PUT_CUSTOMERS_INSTALLER_CODE.BASE,
  installerCode,
});
export const getCustomersHonoa = (id) => ({
  type: GET_CUSTOMERS_HONOA.BASE,
  customerId: id,
});

export const updateCustomerAttributes = (attributes, customerId) => ({
  type: PUT_CUSTOMER_ATTRIBUTES.BASE,
  attributes,
  customerId,
});

export const updateCustomerAttributesHonoa = (attributes) => ({
  type: PUT_CUSTOMER_ATTRIBUTES_HONOA.BASE,
  attributes,
});

export const getCustomersPropertyManagers = () => ({
  type: GET_CUSTOMERS_PROPERTY_MANAGERS.BASE,
});

export const getCustomersLicenses = () => ({
  type: GET_CUSTOMERS_LICENSES.BASE,
});

export const getCustomerRoles = (roleMask) => ({
  type: GET_CUSTOMERS_CUSTOMER_ROLES.BASE,
  roleMask,
});

export const updateCustomerRoles = ({ newRoleMask, customerId }) => ({
  type: PATCH_CUSTOMERS_CUSTOMER_ROLE.BASE,
  newRoleMask,
  customerId,
});
