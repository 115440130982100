import {
  RESET_GROUP_USERS_FOBS,
  PUT_GROUP_USERS_LOCK_USER_LICENSE,
  UPDATE_GROUP_USERS,
  RESET_GROUP_USERS,
  PUT_GROUP_USERS_UNLOCK_USER,
  PUT_GROUP_USERS_LOCK_USER,
  POST_GROUP_USERS_RESEND_INVITATION_LICENCES,
  POST_GROUP_USERS_COPY,
  PUT_GROUP_USERS_MOVE,
  GET_GROUP_USERS_AVAILABLE_FACILITIES,
  GET_GROUP_USERS_AVAILABLE_GROUPS,
  GET_GROUP_USERS_COUNTER,
  PUT_GROUP_USERS_EXCEL,
  POST_GROUP_USERS_FOBS,
  GET_GROUP_USERS_LICENSES_LIST,
  GET_GROUP_USERS_LIST_BY_USERID,
  GET_GROUP_USERS_FOBS_LIST,
  DELETE_GROUP_USERS_LIST,
  DELETE_GROUP_USERS,
  POST_GROUP_USERS,
  GET_GROUP_USERS_LIST,
} from './constants.js';

export const getGroupUsers = (facilityId, groupId) => ({
  type: GET_GROUP_USERS_LIST.BASE,
  facilityId,
  groupId,
});
export const getGroupUsersCounter = (facilityId, groupId) => ({
  type: GET_GROUP_USERS_COUNTER.BASE,
  facilityId,
  groupId,
});
export const getGroupUsersAvailableFacilities = (facilityId, groupId, exceptme) => ({
  type: GET_GROUP_USERS_AVAILABLE_FACILITIES.BASE,
  facilityId,
  groupId,
  exceptme,
});
export const getGroupUsersAvailableGroups = (facilityId, groupId, exceptme) => ({
  type: GET_GROUP_USERS_AVAILABLE_GROUPS.BASE,
  facilityId,
  groupId,
  exceptme,
});
export const postGroupUser = (facilityId, groupId, user) => ({
  type: POST_GROUP_USERS.BASE,
  facilityId,
  groupId,
  user,
});

export const postGroupUserInvitationLicence = (facilityId, groupId, userId) => ({
  type: POST_GROUP_USERS_RESEND_INVITATION_LICENCES.BASE,
  facilityId,
  groupId,
  userId,
});

export const deleteUserGroup = (facilityId, groupId, userId) => ({
  type: DELETE_GROUP_USERS.BASE,
  facilityId,
  groupId,
  userId,
});
export const deleteUsersList = (facilityId, groupId, userList) => ({
  type: DELETE_GROUP_USERS_LIST.BASE,
  facilityId,
  groupId,
  userList,
});
export const updateUserGroup = (facilityId, groupId, userId, data) => ({
  type: UPDATE_GROUP_USERS,
  facilityId,
  groupId,
  userId,
  data,
});
export const getUserFobs = (facilityUser, groupUser) => ({
  type: GET_GROUP_USERS_FOBS_LIST.BASE,
  facilityUser,
  groupUser,
});
export const getUserFobsByUserId = (facilityUser, groupUser, groupUserId) => ({
  type: GET_GROUP_USERS_LIST_BY_USERID.BASE,
  facilityUser,
  groupUser,
  groupUserId,
});
export const getUserLicenses = (facilityUser, groupUser, groupUserId) => ({
  type: GET_GROUP_USERS_LICENSES_LIST.BASE,
  facilityUser,
  groupUser,
  groupUserId,
});
export const postGroupUserFobs = (facilityUser, groupUser, groupUserId, fobsIds) => ({
  type: POST_GROUP_USERS_FOBS.BASE,
  facilityUser,
  groupUser,
  groupUserId,
  fobsIds,
});
export const deleteUserGroupFob = (facilityUser, groupUser, groupUserId, fobId) => ({
  type: DELETE_GROUP_USERS_FOB.BASE,
  facilityUser,
  groupUser,
  groupUserId,
  fobId,
});
export const resetGroupUserFobs = () => ({ type: RESET_GROUP_USERS_FOBS });
export const resetGroupUsers = () => ({ type: RESET_GROUP_USERS });
export const lockUserLicense = (facilityUser, groupUser, groupUserId, id) => ({
  type: PUT_GROUP_USERS_LOCK_USER_LICENSE.BASE,
  facilityUser,
  groupUser,
  groupUserId,
  id,
});
export const unLockUserLicense = (facilityUser, groupUser, groupUserId, id) => ({
  type: PUT_GROUP_USERS_LICENSE_UNLOCK.BASE,
  facilityUser,
  groupUser,
  groupUserId,
  id,
});
export const putUserMoveGroup = (facilityId, groupId, toFacilityID, toGroupID, list) => ({
  type: PUT_GROUP_USERS_MOVE.BASE,
  facilityId,
  groupId,
  toFacilityID,
  toGroupID,
  list,
});
export const putUserCopyGroup = (facilityId, groupId, toFacilityID, toGroupID, list) => ({
  type: POST_GROUP_USERS_COPY.BASE,
  facilityId,
  groupId,
  toFacilityID,
  toGroupID,
  list,
});

export const lockUser = (facilityId, groupId, userId) => ({
  type: PUT_GROUP_USERS_LOCK_USER.BASE,
  facilityId,
  groupId,
  userId,
});
export const unLockUser = (facilityId, groupId, userId) => ({
  type: PUT_GROUP_USERS_UNLOCK_USER.BASE,
  facilityId,
  groupId,
  userId,
});

export const importGroupUsers = (facilityId, groupId, file) => ({
  type: PUT_GROUP_USERS_EXCEL.BASE,
  facilityId,
  groupId,
  file,
});
