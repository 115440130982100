export default {
  formValidation: {
    notSameValue: 'El valor no coincide',
    notSamePassword: 'Las contraseñas no coinciden',
    numericField: 'Este campo es numérico',
    minNumber: 'El valor mínimo es 1',
    passwordFormat:
      'La contraseña debe tener un mínimo de ocho caracteres, al menos una letra mayúscula otra minúscula, un número y un carácter especial',
    isNotValidDate: 'Formato de fecha incorrecto, formato: YYYY-MM-DDTHH:mm:ss',
    isRequired: 'Campo requerido',
    outOfRange: 'Valor fuera del rango',
    maxLength: 'La longitud máxima de caracteres es',
    regEx: 'Formato incorrecto. Utiliza estos caracteres ',
    deviceLimit: 'Límite de dispositivo alcanzado',
    onlySpaces: 'No están permitidos sólo espacios en blanco',
    isReservedWord: 'es una palabra reservada',
  },
};
