import PathEntity from '../../core/old_common/rooting/apiPaths/PathEntity';
import { apiVersion, mainPath } from '../../core/old_common/rooting/common';

class Routes extends PathEntity {
  constructor() {
    super('users');
  }

  getRoles = () => {
    this.mainPath = `${mainPath()}4.1`;
    const endpoint = this.manufacturePath(`${this.entityName}/roles`);
    this.mainPath = `${mainPath()}${apiVersion}`;
    return endpoint;
  };

  getRolesEmployees = () => {
    this.mainPath = `${mainPath()}4.1`;
    const endpoint = this.manufacturePath(`${this.entityName}/userRoles`);
    this.mainPath = `${mainPath()}${apiVersion}`;
    return endpoint;
  };

  get = () => {
    this.mainPath = `${mainPath()}4.1`;
    const endpoint = this.manufacturePath(`${this.entityName}`);
    this.mainPath = `${mainPath()}${apiVersion}`;
    return endpoint;
  };

  putPreferences = (userId) => this.manufacturePath(`${this.entityName}/${userId}/preferences`);

  patchPreferences = (userId) => this.manufacturePath(`${this.entityName}/${userId}/preferences`);

  putAvatar = (userId) => this.manufacturePath(`${this.entityName}/${userId}/avatar`);

  getOwn = () => this.manufacturePath(`${this.entityName}/own`);

  putPermissions = (userId) => this.manufacturePath(`${this.entityName}/permissions/${userId}`);

  getPaths = () => ({
    usersGetRoles: this.getRoles,
    userGetRolesEmployees: this.getRolesEmployees,
    usersGet: this.get,
    usersPatchPreferences: this.patchPreferences,
    usersPutPreferences: this.putPreferences,
    usersPutAvatar: this.putAvatar,
    usersGetOwn: this.getOwn,
    usersPutPermissions: this.putPermissions,
  });
}

export default new Routes().getPaths();
