import { GET_STATISTICS_EQUIPMENT } from '../../statistics/constants';
import createFetchTypes from '../../../core/typeActions/types';

const GET_EQUIPMENT_LIST = 'GET_EQUIPMENT_LIST';
const DELETE_EQUIPMENT = 'DELETE_EQUIPMENT';
const POST_EQUIPMENT = 'POST_EQUIPMENT';
const PUT_EQUIPMENT = 'PUT_EQUIPMENT';
const GET_EQUIPMENT_RELAYS = 'GET_EQUIPMENT_RELAYS';
const RESET_EQUIPMENT_LIST = 'RESET_EQUIPMENT_LIST';
const GET_EQUIPMENT_FOBS = 'GET_EQUIPMENT_FOBS';
const PUT_EQUIPMENT_RELAY = 'PUT_EQUIPMENT_RELAY';
const GET_EQUIMENT_ACTIONS = 'GET_EQUIMENT_ACTIONS';
const GET_EQUIPMENT_CONNECTIVITY = 'GET_EQUIPMENT_CONNECTIVITY';
const GET_EQUIPMENT_VERTEX_CONNECTIVITY = 'GET_EQUIPMENT_VERTEX_CONNECTIVITY';
const GET_EQUIPMENT = 'GET_EQUIPMENT';
const RESET_EQUIPMENT = 'RESET_EQUIPMENT';
const RESET_EQUIPMENTS = 'RESET_EQUIPMENTS';
const GET_EQUIPMENT_COUNTERS = 'GET_EQUIPMENT_COUNTERS';
const RESET_VERTEX_CONNECTIVITY = 'RESET_VERTEX_CONNECTIVITY';
const PUT_EQUIPMENT_IS_UPDATED = 'PUT_EQUIPMENT_IS_UPDATED';
const RESET_EQUIPMENT_CONNECTIVITY = 'RESET_EQUIPMENT_CONNECTIVITY';
const GET_EQUIPMENT_SIM = 'GET_EQUIPMENT_SIM';
const RESET_EQUIPMENT_SIM = 'RESET_EQUIPMENT_SIM';
const RESET_EQUIPMENTS_SCROLL = 'RESET_EQUIPMENTS_SCROLL';
const SET_EQUIPMENTS_SCROLL = 'SET_EQUIPMENTS_SCROLL';
const RESET_EQUIPMENT_RELAYS = 'RESET_EQUIPMENT_RELAYS';
const GET_EQUIPMENT_EVENTS = 'GET_EQUIPMENT_EVENTS';
const DELETE_EQUIPMENT_EVENTS = 'DELETE_EQUIPMENT_EVENTS';
const RESET_EQUIPMENT_EVENTS = 'RESET_EQUIPMENT_EVENTS';
export const GET_EQUIPMENTS_WIEGAND_CONFIG = createFetchTypes('GET_EQUIPMENTS_WIEGAND_CONFIG');

export default {
  GET_EQUIPMENT_LIST,
  GET_EQUIPMENT_RELAYS,
  DELETE_EQUIPMENT,
  POST_EQUIPMENT,
  PUT_EQUIPMENT,
  RESET_EQUIPMENT_LIST,
  PUT_EQUIPMENT_RELAY,
  GET_EQUIPMENT_FOBS,
  GET_EQUIMENT_ACTIONS,
  GET_EQUIPMENT_CONNECTIVITY,
  GET_EQUIPMENT_VERTEX_CONNECTIVITY,
  GET_EQUIPMENT,
  RESET_EQUIPMENT,
  RESET_EQUIPMENTS,
  GET_EQUIPMENT_COUNTERS,
  RESET_VERTEX_CONNECTIVITY,
  PUT_EQUIPMENT_IS_UPDATED,
  RESET_EQUIPMENT_CONNECTIVITY,
  GET_EQUIPMENT_SIM,
  RESET_EQUIPMENT_SIM,
  RESET_EQUIPMENTS_SCROLL,
  SET_EQUIPMENTS_SCROLL,
  RESET_EQUIPMENT_RELAYS,
  GET_EQUIPMENT_EVENTS,
  DELETE_EQUIPMENT_EVENTS,
  RESET_EQUIPMENT_EVENTS,
};

export const deleteEquipment = (facilityId, equipmentId) => ({
  type: DELETE_EQUIPMENT,
  facilityId,
  equipmentId,
});
export const getEquipmentList = (facilityId, include = true) => ({
  type: GET_EQUIPMENT_LIST,
  facilityId,
  include,
});
export const postEquipment = (facilityId, equipment) => ({
  type: POST_EQUIPMENT,
  facilityId,
  equipment,
});
export const putEquipment = (facilityId, equipment, tabType) => ({
  type: PUT_EQUIPMENT,
  facilityId,
  equipment,
  tabType,
});
export const resetEquipmentList = () => ({ type: RESET_EQUIPMENT_LIST });
export const getEquipmentFOBS = (facilityId, equipmentId) => ({
  type: GET_EQUIPMENT_FOBS,
  facilityId,
  equipmentId,
});
export const putEquipmentRelay = (facilityId, equipmentId, relayId, relay) => ({
  type: PUT_EQUIPMENT_RELAY,
  facilityId,
  equipmentId,
  relayId,
  relay,
});
export const getEquipmentRelays = (facilityId, equipmentId) => ({
  type: GET_EQUIPMENT_RELAYS,
  facilityId,
  equipmentId,
});
export const getEquipmentActions = (facilityId, equipmentId) => ({
  type: GET_EQUIMENT_ACTIONS,
  facilityId,
  equipmentId,
});
export const getEquipmentConnectivity = (facilityId, equipmentId) => ({
  type: GET_EQUIPMENT_CONNECTIVITY,
  facilityId,
  equipmentId,
});
export const resetEquipmentConnectivity = () => ({ type: RESET_EQUIPMENT_CONNECTIVITY });
export const getEquipmentVertexConnectivity = (facilityId, equipmentId, key) => ({
  type: GET_EQUIPMENT_VERTEX_CONNECTIVITY,
  facilityId,
  equipmentId,
  key,
});
export const getEquipment = (id, facilityId, include = true) => ({
  type: GET_EQUIPMENT,
  id,
  include,
  facilityId,
});
export const resetEquipment = () => ({ type: RESET_EQUIPMENT });
export const resetEquipments = () => ({ type: RESET_EQUIPMENTS });
export const getEquipmentCounters = (facilityId, equipmentId) => ({
  type: GET_EQUIPMENT_COUNTERS,
  facilityId,
  equipmentId,
});
export const resetVertexConnectivity = () => ({ type: RESET_VERTEX_CONNECTIVITY });

export const putEquipmentIsUpdated = (facilityId, equipmentId) => ({
  type: PUT_EQUIPMENT_IS_UPDATED,
  facilityId,
  equipmentId,
});

export const getEquipmentSim = (facilityId, equipmentId) => ({
  type: GET_EQUIPMENT_SIM,
  facilityId,
  equipmentId,
});

export const resetEquipmentSim = () => ({
  type: RESET_EQUIPMENT_SIM,
});

export const getStatisticsEquipment = (facilityId, equipmentId) => ({
  type: GET_STATISTICS_EQUIPMENT.BASE,
  facilityId,
  equipmentId,
});

export const resetEquipmentScroll = () => ({
  type: RESET_EQUIPMENTS_SCROLL,
});

export const setEquipmentScroll = () => ({
  type: SET_EQUIPMENTS_SCROLL,
});

export const resetEquipmentRelays = () => ({
  type: RESET_EQUIPMENT_RELAYS,
});

export const getEquipmentEvents = (facilityId, equipmentId) => ({
  type: GET_EQUIPMENT_EVENTS,
  facilityId,
  equipmentId,
});

export const deleteEvents = (facilityId, equipmentId) => ({
  type: DELETE_EQUIPMENT_EVENTS,
  facilityId,
  equipmentId,
});

export const resetEquipmentsEvents = () => ({
  type: RESET_EQUIPMENT_EVENTS,
});

export const getWiegandConfig = (facilityId, equipmentId) => ({
  type: GET_EQUIPMENTS_WIEGAND_CONFIG.BASE,
  facilityId,
  equipmentId,
});
