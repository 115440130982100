export default {
  universalGroups: {
    description: 'Popis',
    informationTab: 'Informace',
    expand: 'Rozbalit',
    collapse: 'Sbalit',
    delete: 'Vymazat',
    add: 'Přidat novou Univerzální skupinu',
    create: 'Vytvořit Univerzální skupinu',
    instalationsTab: 'Instalace',
    fobsTab: 'Ovladače',
    deleteConfirmation: 'Jsi si jistý, že chceš vymazat tuto skupinu?',
  },
};
