import PathEntity from './PathEntity.js';
import { apiVersion, mainPath } from '../common';

class FacilityGroups extends PathEntity {
  constructor() {
    super('groups');
  }

  get = (facilityId, include) =>
    this.manufacturePath(
      `facilities/${facilityId}/${this.entityName}${
        include !== undefined ? `?include=${include}` : ''
      }`
    );

  post = (facilityId) => this.manufacturePath(`facilities/${facilityId}/${this.entityName}`);

  getById = (facilityId, groupId) => {
    let finalEndpoint = this.manufacturePath(
      `facilities/${facilityId}/${this.entityName}/${groupId}`
    );
    finalEndpoint = this.addParameterToEndpoint(finalEndpoint, 'include', true);
    return finalEndpoint;
  };

  put = (facilityId, groupId) =>
    this.manufacturePath(`facilities/${facilityId}/${this.entityName}/${groupId}`);

  delete = (facilityId, groupId) =>
    this.manufacturePath(`facilities/${facilityId}/${this.entityName}/${groupId}`);

  getSingleList = (facilityId, groupId, exceptMe) =>
    this.manufacturePath(
      `facilities/${facilityId}/${this.entityName}/${groupId}/singlelist${
        exceptMe !== undefined ? `?exceptme=${exceptMe}` : ''
      }`
    );

  getAvailableFobs = (facilityId, groupId) => {
    this.mainPath = `${mainPath()}4.1`;

    const endpoint = this.manufacturePath(
      `facilities/${facilityId}/${this.entityName}/${groupId}/groupcounters`
    );
    this.mainPath = `${mainPath()}${apiVersion}`;
    return endpoint;
  };

  getUniversalGroups = ({ facilityId, groupId, include }) => {
    let endpoint = this.manufacturePath(`facilities/${facilityId}/${this.entityName}/Universals`);
    if (include !== undefined) {
      endpoint = this.addParameterToEndpoint(endpoint, 'include', include);
    }
    if (groupId) {
      endpoint = this.addParameterToEndpoint(endpoint, 'groupId', groupId);
    }
    return endpoint;
  };

  getAssignedGroups = (facilityId, equipmentId) => {
    let endpoint = this.manufacturePath(
      `facilities/${facilityId}/${this.entityName}/assigned/${equipmentId}`
    );
    endpoint = this.addParameterToEndpoint(endpoint, 'universals', false);
    return endpoint;
  };

  getGroupFobHistory = (facilityId, facilityGroupId, groupFobId) => {
    this.mainPath = `${mainPath()}4.1`;
    const finalEndpoint = this.manufacturePath(
      `facilities/${facilityId}/${this.entityName}/${facilityGroupId}/fob/${groupFobId}/history`
    );
    this.mainPath = `${mainPath()}${apiVersion}`;
    return finalEndpoint;
  };

  getGroupFobHistoryFile = (facilityId, facilityGroupId, fobId) => {
    this.mainPath = `${mainPath()}4.1`;
    const endpoint = this.manufacturePath(
      `facilities/${facilityId}/groups/${facilityGroupId}/fob/${fobId}/history/pdf`
    );
    this.mainPath = `${mainPath()}${apiVersion}`;
    return endpoint;
  };

  getPaths = () => ({
    facilityGroupsGet: this.get,
    facilityGroupGet: this.getById,
    facilityGroupsDelete: this.delete,
    facilityGroupsPut: this.put,
    facilityGroupsPost: this.post,
    facilityGroupsGetSingleList: this.getSingleList,
    facilityGroupsGetAvailableFobs: this.getAvailableFobs,
    facilityGroupsGetUniversals: this.getUniversalGroups,
    assignedFacilityGroupsGet: this.getAssignedGroups,
    getGroupFobHistory: this.getGroupFobHistory,
    getGroupFobHistoryFile: this.getGroupFobHistoryFile,
  });
}

export default new FacilityGroups().getPaths();
