import styled from 'styled-components';
import { getContrastColor, increaseBrightness } from '../../core/helpers/colorManager';

export default styled.button`
  ${(props) =>
    props.isActualPage
      ? `background-color:${props.tenants.color1} !important;
  color:${getContrastColor(props.tenants.color1)};`
      : ''}
  border-color: #a4aebb;
  &:hover,
  &:focus {
    background-color: ${(props) => increaseBrightness(props.tenants.color1, 30)};
  }
`;
