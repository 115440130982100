export default {
  instalationUserGroups: {
    manageFobsAndLicenses: 'Másolt távirányítók és engedélyek kezelése',
    fobsLicensesGridTitle: 'Másolt távirányítók / Engedélyek',
    email: 'E-mail',
    phone: 'Telefonszám',
    addUser: 'Felhasználó hozzáadása',
    deleteUserConfirmation: 'Biztosan törölni szeretné ezt a felhasználót?',
    Fobs: 'Másolt távirányítók',
    Licenses: 'Engedélyek',
    addFobToUser: 'Másolt távirányító hozzáadása a felhasználóhoz',
    expiration: 'Lejárat',
    licenseStatus: 'Státusz',
    locked: 'Zárolt',
    unlocked: 'Feloldott',
    0: 'Bluetooth',
    1: 'Távoli nyitás',
    users: 'Felhasználók',
    information: 'Információk',
  },
};
