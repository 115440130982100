import { getNewArray } from '../../core/helpers/arrayService';
import {
  GET_FAMILIES,
  GET_FAMILIES_SINGLE_LIST,
  GET_FAMILY_PRODUCTS,
  POST_FAMILIES,
  RESET_FAMILIES_PRODUCTS,
  SET_FAMILIES_VALUES,
} from './contants';

/* Redux state init */
const initialState = {
  families: [],
  selectedFamilyId: '',
  familiesSingleList: [undefined, undefined, undefined, undefined],
  needCallFamilies: true,
};

const addProductsToFamily = (families, selectedFamilyId, products) => {
  const index = families.findIndex((family) => family.id === selectedFamilyId);
  return getNewArray(families, index, 'products', products);
};

export default function familiesReducer(state = initialState, action) {
  if (action.type !== undefined) {
    switch (action.type) {
      case GET_FAMILIES.SUCCESS:
        return {
          ...state,
          families: action.result,
        };
      case POST_FAMILIES.SUCCESS:
        return {
          ...state,
        };
      case GET_FAMILY_PRODUCTS.BASE:
        return { ...state, selectedFamilyId: action.familyId };
      case GET_FAMILY_PRODUCTS.SUCCESS:
        return {
          ...state,
          families: addProductsToFamily(state.families, state.selectedFamilyId, action.result),
        };
      case SET_FAMILIES_VALUES:
        return { ...state, families: [...action.newFamilies] };
      case GET_FAMILIES_SINGLE_LIST.REQUEST:
        return { ...state, needCallFamilies: false };
      case GET_FAMILIES_SINGLE_LIST.SUCCESS:
        const families = [...state.familiesSingleList];
        families[action.payload[1].productType] = [...action.result];
        return { ...state, familiesSingleList: [...families], needCallFamilies: true };
      case RESET_FAMILIES_PRODUCTS:
        return {
          ...state,
          familiesSingleList: [undefined, undefined, undefined, undefined],
          families: undefined,
        };
      default:
        return state;
    }
  }
  return state;
}
