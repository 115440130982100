// import { createAction } from 'redux-actions';

const SUCCESS_GET = 'SUCCESS_GET';
const RESET_SUCCESS = 'RESET_SUCCESS';

export default {
  SUCCESS_GET,
  RESET_SUCCESS,
};

export const getToasterSuccess = () => ({ type: SUCCESS_GET });
export const resetToastSuccess = () => ({ type: RESET_SUCCESS });
