export default {
  updateInstalationForm: {
    contact: 'Contatto',
    telephone: 'Telefono',
    address: 'Indirizzo',
    country: 'Nazione',
    saveButton: 'Salva',
    maintenanceDate: 'Data prossima manutenzione',
    createFacility: 'Crea installazione',
    installerCode: 'Usa il codice di installazione',
    installerCodeIconTooltip: 'Installa il codice',
    comment: 'Commenti',
    createFacilityGroup: 'Crea un gruppo di installazione',
    uniqueIdentity: 'Identificatore univoco',
    geolocalization: 'Set di geolocalizzazione',
    exportUsersGroups: 'Esporta utenti',
    noneGeolocalization: 'Geolocalizzazione non impostata',
    addUniversalGroup: 'Aggiungi gruppo universale',
    needSetLocation: 'Devi aggiungere una posizione specifica',
    exportUsersTitle: 'esporta_utentis',
    creationDate: 'Data di creazione',
    installerCodeFirstMessage: 'Assicuratevi di operare con il codice installatore.',
    installerCodeSecondMessage: 'In caso di dubbio, rivolgetevi al vostro amministratore'
  },
};
