import { Box, Grid, GridItem } from '@jcm-technologies/uikit/dist/atoms/Layout';
import { SelectInput } from '@jcm-technologies/uikit/dist/atoms/Select';
import { ErrorMessage } from '@jcm-technologies/uikit/dist/atoms/ErrorMessage/ErrorMessage';
import { Field } from '@jcm-technologies/uikit/dist/atoms/Field';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Input } from '@jcm-technologies/uikit/dist/atoms/Input';
import { Text } from '@jcm-technologies/uikit/dist/atoms/Typography';
import { Switch } from '@jcm-technologies/uikit/dist/atoms/Switch';
import { isNumberValidation } from '../../../../core/helpers/numbers';
import { getLanguagesOptions } from '../../../../core/helpers/getLanguages';

export const FormLicenses = ({
  onChangeData,
  isDisableCompany,
  isDisabledSubscriptions,
  handleBlur,
  errors,
  data,
  isVisibleFacility,
  isVisibleFacilityLabel,
  isVisibleSubscriptions,
}) => {
  const { userRoles } = useSelector((state) => state.user);
  const { color1 } = useSelector((state) => state.tenants);
  const { t } = useTranslation();

  return (
    <Box>
      <Box
        borderBottomStyle='solid'
        borderBottomWidth={1}
        borderBottomColor={color1}
        paddingBottom={2}
      >
        <Text textAlign='center' sizeText='display24' fontWeight='bold'>
          {t('general.createEntity', { entity: t('title.licenses') })}
        </Text>
      </Box>
      <Grid
        gridTemplateAreas={["'.'", "'. .'", "'. .'", "'. . .'"]}
        gridGap={2}
        width='100%'
        gridTemplateColumns='1fr 1fr 1fr'
        gridTemplateRows='auto'
        paddingTop={3}
        paddingX={4}
      >
        <GridItem>
          <Field label={t('form.role')} required marginY={1}>
            <SelectInput
              name='role'
              id='role'
              options={
                userRoles?.map((rol) => ({
                  value: rol?.id,
                  label: rol?.name,
                })) || []
              }
              onChange={(name, value) => {
                onChangeData(name, value?.value);
              }}
              placeholderMessage={t('general.selectOne')}
              hasError={errors?.role?.error}
              errorMessage={errors?.role?.errorMessage}
            />
            {errors?.role?.error && errors?.role?.errorMessage && (
              <ErrorMessage message={errors?.role?.errorMessage} />
            )}
          </Field>
        </GridItem>
        {isVisibleSubscriptions && (
          <GridItem>
            <Field
              label={t('form.numberOf', { name: t('title.licenses') })}
              required={!isDisabledSubscriptions}
              marginY={1}
            >
              <Input
                id='subscriptions'
                name='subscriptions'
                onBlur={(name) => handleBlur(name)}
                onChange={(name, value) => {
                  if (isNumberValidation(value)) {
                    onChangeData('subscriptions', value);
                  }
                }}
                disabled={isDisabledSubscriptions}
                readOnly={isDisabledSubscriptions}
                value={data.subscriptions}
                hasError={errors?.subscriptions?.error}
                errorMessage={errors?.subscriptions?.errorMessage}
              />
            </Field>
          </GridItem>
        )}

        <GridItem>
          <Field label={t('form.companyName')} required={!isDisableCompany} marginY={1}>
            <Input
              id='name'
              name='name'
              onBlur={(name) => handleBlur(name)}
              onChange={(name, value) => {
                onChangeData('name', value);
              }}
              disabled={isDisableCompany}
              readOnly={isDisableCompany}
              value={data.name}
              hasError={errors?.name?.error}
              errorMessage={errors?.name?.errorMessage}
            />
          </Field>
        </GridItem>
        <GridItem>
          <Field label={t('form.email')} required marginY={1}>
            <Input
              id='email'
              name='email'
              onBlur={(name) => handleBlur(name)}
              onChange={(name, value) => {
                onChangeData('email', value);
              }}
              value={data.email}
              hasError={errors?.email?.error}
              errorMessage={errors?.email?.errorMessage}
            />
          </Field>
        </GridItem>
        <GridItem>
          <Field label={t('form.emailConfirm')} required marginY={1}>
            <Input
              id='emailConfirm'
              name='emailConfirm'
              onBlur={(name) => handleBlur(name)}
              onChange={(name, value) => {
                onChangeData('emailConfirm', value);
              }}
              value={data.emailConfirm}
              hasError={errors?.emailConfirm?.error}
              errorMessage={errors?.emailConfirm?.errorMessage}
            />
          </Field>
        </GridItem>
        <GridItem>
          <Field label={t('form.preferredLanguage')} required marginY={1}>
            <SelectInput
              name='language'
              id='language'
              options={getLanguagesOptions()}
              onChange={(name, value) => {
                onChangeData(name, value?.value);
              }}
              defaultValue={getLanguagesOptions()?.find(
                (element) => element.value === data.language
              )}
              onBlur={(name) => handleBlur(name)}
              placeholderMessage={t('general.selectOne')}
              hasError={errors?.language?.error}
              errorMessage={errors?.language?.errorMessage}
            />
            {errors?.language?.error && errors?.language?.errorMessage && (
              <ErrorMessage message={errors?.language?.errorMessage} />
            )}
          </Field>
        </GridItem>
        {isVisibleFacility && (
          <GridItem>
            <Field label={isVisibleFacilityLabel} required marginY={1}>
              <Switch
                name='visibleFacilities'
                id='visibleFacilities'
                onBlur={(name) => handleBlur(name)}
                placeholderMessage={t('general.selectOne')}
                hasError={errors?.visibleFacilities?.error}
                errorMessage={errors?.visibleFacilities?.errorMessage}
              />
              {errors?.visibleFacilities?.error && errors?.visibleFacilities?.errorMessage && (
                <ErrorMessage message={errors?.visibleFacilities?.errorMessage} />
              )}
            </Field>
          </GridItem>
        )}
      </Grid>
    </Box>
  );
};
