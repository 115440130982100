export default {
  alarms: {
    deleteAlarmPopUp: "Sei sicuro di voler contrassegnare l'allarme come visto?",
    deleteAlarmPopUpWithMove:
      'Spostando un emettitore in un altro gruppo, gli allarmi verranno contrassegnati come visti. Vuoi continuare?',
    deleteAlarmPopUpWithCloneUniversal:
      "La copia dell'emettitore universale condividerà gli allarmi.",
    deleteAlarmPopUpWithClone:
      'Quando si copia un trasmettitore in un altro gruppo, la copia apparirà senza allarmi',
  },
};
