import PathEntity from './PathEntity.js';
import { apiVersion, mainPath } from '../common.js';

class UniversalGroupsFobs extends PathEntity {
  constructor() {
    super('universalGroupsFobs');
  }

  putFob = (universalGroupId, fobId) =>
    this.manufacturePath(`universalgroups/${universalGroupId}/fob/${fobId}`);

  putImportFob = (universalGroupId) => {
    this.mainPath = `${mainPath()}4.1`;
    const endpoint = this.manufacturePath(`universalgroups/${universalGroupId}/fobs/import`);
    this.mainPath = `${mainPath()}${apiVersion}`;
    return endpoint;
  };

  copyFob = (universalGroupId, toGroupId) => {
    this.mainPath = `${mainPath()}4.1`;
    const endpoint = this.manufacturePath(
      `universalgroups/${universalGroupId}/fobs/copy/${toGroupId}`
    );
    this.mainPath = `${mainPath()}${apiVersion}`;
    return endpoint;
  };

  moveFob = (universalGroupId, toGroupId) => {
    this.mainPath = `${mainPath()}4.1`;
    const endpoint = this.manufacturePath(
      `universalgroups/${universalGroupId}/fobs/move/${toGroupId}`
    );
    this.mainPath = `${mainPath()}${apiVersion}`;
    return endpoint;
  };

  deleteFobs = (universalGroupId) => {
    this.mainPath = `${mainPath()}4.1`;
    const endpoint = this.manufacturePath(`universalgroups/${universalGroupId}/fobs`);
    this.mainPath = `${mainPath()}${apiVersion}`;
    return endpoint;
  };

  deleteFob = (universalGroupId, id) =>
    this.manufacturePath(`universalgroups/${universalGroupId}/fob/${id}`);

  getFobs = (universalGroupId) => this.manufacturePath(`universalgroups/${universalGroupId}/fobs`);

  postFob = (universalGroupId, force) => {
    this.mainPath = `${mainPath()}4.1`;
    let finalEndpoint = this.manufacturePath(`universalgroups/${universalGroupId}/fob`);
    finalEndpoint = this.addParameterToEndpoint(finalEndpoint, 'force', force);
    this.mainPath = `${mainPath()}${apiVersion}`;
    return finalEndpoint;
  };

  postSequentialFob = ({ universalGroupId, initialCode, codesAmount, isReservation }) => {
    this.mainPath = `${mainPath()}4.1`;
    let finalEndpoint = this.manufacturePath(
      `universalgroups/${universalGroupId}/fobs/addsequential`
    );
    finalEndpoint = this.addParameterToEndpoint(finalEndpoint, 'IsReservation', isReservation);
    finalEndpoint = this.addParameterToEndpoint(finalEndpoint, 'codesAmount', codesAmount);
    if (initialCode) {
      finalEndpoint = this.addParameterToEndpoint(finalEndpoint, 'initialCode', initialCode);
    }
    this.mainPath = `${mainPath()}${apiVersion}`;
    return finalEndpoint;
  };

  getFobsCounter = (universalGroupId) => {
    this.mainPath = `${mainPath()}4.1`;
    const endpoint = this.manufacturePath(`universalgroups/${universalGroupId}/groupcounters`);
    this.mainPath = `${mainPath()}${apiVersion}`;
    return endpoint;
  };

  checkContent = (universalGroupId, columnName, value) => {
    let finalEndpoint = this.manufacturePath(`universalgroups/${universalGroupId}/checkcontent`);
    finalEndpoint = this.addParameterToEndpoint(finalEndpoint, 'columnName', columnName);
    finalEndpoint = this.addParameterToEndpoint(finalEndpoint, 'value', value);
    return finalEndpoint;
  };

  checkCodes = (universalGroupId, initialCode, codesAmount) => {
    let finalEndpoint = this.manufacturePath(`universalgroups/${universalGroupId}/fobs/checkcodes`);
    finalEndpoint = this.addParameterToEndpoint(finalEndpoint, 'initialCode', initialCode);
    finalEndpoint = this.addParameterToEndpoint(finalEndpoint, 'codesAmount', codesAmount);
    return finalEndpoint;
  };

  getSubs = (id, fobId) => this.manufacturePath(`universalgroups/${id}/fob/${fobId}/substitutions`);

  getPaths = () => ({
    universalGroupsFobsGetCheckContent: this.checkContent,
    universalGroupsFobsGetCheckCodes: this.checkCodes,
    universalGroupsFobsGetCounter: this.getFobsCounter,
    universalGroupsFobsGet: this.getFobs,
    universalGroupsFobsPost: this.postFob,
    universalGroupsFobsPostSequential: this.postSequentialFob,
    universalGroupsFobsPut: this.putFob,
    universalGroupsFobsImportPut: this.putImportFob,
    universalGroupsFobsPostCopy: this.copyFob,
    universalGroupsFobsDeleteList: this.deleteFobs,
    universalGroupsFobsDelete: this.deleteFob,
    universalGroupsFobsPutMove: this.moveFob,
    universalGroupsFobGetSubs: this.getSubs,
  });
}

export default new UniversalGroupsFobs().getPaths();
