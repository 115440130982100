import React, { Component } from 'react';
import { connect } from 'react-redux';
import { resetEquipmentScroll } from '../../modules/old_to_refact/actions/equipments';

import './GenericTable.css';

class GenericTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeRow: null,
      scrollY: null,
    };
  }

  getTableColumn = () => {
    const { columnsConfig } = this.props;
    const th = columnsConfig.map((column, i) => <th key={i}>{column.value}</th>);
    return <tr>{th}</tr>;
  };

  getTableRow = () => {
    const { rowsConfig, tenants } = this.props;
    const { activeRow } = this.state;
    const rows = rowsConfig.map((column, i) => {
      if (column.inputType !== 'hidden') {
        const ActionComponent = column.hasAction ? column.getActionComponent() : undefined;
        const AlarmSummary = column.hasAlarms ? column.getAlarms : '';
        const value = column.hasAction ? ActionComponent : column.value;
        return (
          <tr
            key={i}
            as='tr'
            onMouseOver={column.onMouseOver}
            onMouseOut={column.onMouseOut}
            className={`jcm-class ${activeRow === column.type ? 'row-background' : ''}`}
          >
            {column.hasAlarms && (
              <td className='generic-table-alarm text-center' style={column.rowStyle || null}>
                {AlarmSummary}
              </td>
            )}
            <td className='generic-table-tagValue' style={column.rowStyle || null}>
              {column.tagValue || column.type}
            </td>
            <td className='generic-table-value' id='edit' style={column.rowStyle || null}>
              {value}
            </td>
          </tr>
        );
      }
    });
    return rows;
  };

  setScroll = () => {
    this.setState({ scrollY: this.props.parametersScroll });
  };

  manageScroll = () => {
    const { parametersScroll } = this.props;
    const elmnt = document.getElementById('scrollit');
    if (elmnt !== null && (this.state.scrollY === null || this.state.scrollY === 0)) {
      if (
        elmnt.scrollTop !== parametersScroll &&
        parametersScroll !== null &&
        elmnt.scrollTop === 0
      ) {
        this.setScroll();
      }
    }

    if (elmnt && this.state.scrollY) {
      if (
        elmnt.scrollTop !== this.state.scrolly &&
        elmnt.scrollTop !==
          document.getElementById('scrollit').scrollHeight -
            document.getElementById('scrollit').clientHeight &&
        this.state.scrollY !== 0
      ) {
        if (
          this.state.scrollY <=
          document.getElementById('scrollit').scrollHeight -
            document.getElementById('scrollit').clientHeight
        ) {
          document.getElementById('scrollit').scrollTop = this.state.scrollY;
        } else {
          document.getElementById('scrollit').scrollTop =
            document.getElementById('scrollit').scrollHeight -
            document.getElementById('scrollit').clientHeight;
        }
        this.setState({ scrollY: 0 });
      }
    }
  };

  render() {
    const { className, needScrollManage } = this.props;
    if (needScrollManage) {
      this.manageScroll();
    }

    return (
      <div className='scrollit' id='scrollit' onMouseMove={this.props.resetEquipmentScroll}>
        <table className={`table-jcm ${className || ''}`}>
          <thead className='jcm-thead'>{this.getTableColumn()}</thead>
          <tbody className='jcm-table'>{this.getTableRow()}</tbody>
        </table>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  parametersScroll: state.equipments.parametersScroll,
  updatedEquipment: state.equipments.updatedEquipment,
});

export default connect(mapStateToProps, {
  resetEquipmentScroll,
})(GenericTable);
