export default {
  equipments: {
    add: 'Přidat zařízení',
    createEquipment: 'Vytvořit zařízení',
    deleteConfirmation: 'Jsi si jistý, že chceš smazat toto zařízení?',
    facilityName: 'Název zařízení',
    productName: 'Název produktu',
    parameters: 'Parametry',
    relays: 'Relé',
    fobs: 'Ovladače',
    groups: 'Skupiny',
    actions: 'Akce',
    events: 'Události',
    info: 'Informace',
    'remote comm': 'Vzdálená komunikace',
    changePasswordAction: 'Změň heslo',
    adjustDateTimeAction: 'Uprav datum a čas',
    formatMemory: 'Formátování paměti',
    disconnectedKey: 'Přerušeno',
    connectedKey: 'Připojeno',
    notFoundKey: 'Zařízení nenalezeno',
    saveConfiguration: 'Uložit nastavení',
    compareMemory: 'Vyrovnat paměť PC/Cloud',
    savedMemory: 'Paměť uložena',
    sendMemoryAction: 'Místní aktualizace paměti',
    saveMemoryAction: 'Vzdálená aktualizace paměti',
    verifyConnectivity: 'Ověření paměti',
    getdoorstatus: 'Získat stav dveří',
    device: 'Typ zařízení',
    deleteEventsConfirmation: 'Jsi si jistý, že chceš vymazat události?',
  },
};
