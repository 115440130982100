import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import { Flex } from '@jcm-technologies/uikit/dist/atoms/Layout';
import { Button } from '@jcm-technologies/uikit/dist/atoms/Button';
import {
  getEquipmentVertexConnectivity,
  putEquipment,
  resetVertexConnectivity,
} from '../../modules/old_to_refact/actions/equipments';
import GenericTable from '../GenericTable';
import parametersDictionary from './Dictionary/parametersDictionary';
import PopUps from '../Popup';
import parametersPopUpsDictionary from './Dictionary/ParametersPopUpsDictionary';

import './InstalationEquipmentsInfo.css';
import { getContrastColor } from '../../core/helpers/colorManager';

export class Index extends Component {
  constructor(props) {
    super(props);
    this.hasChanged = false;
    this.state = {
      isPopupOpen: false,
      popupComponent: null,
      equipmentParameters: props.equipmentParameters,
      parameterNameForDescription: '',
      originalActivationKey: undefined,
    };
    this.fieldsFunct = [];
    this.fieldsFunct.ActivationKey = (parameters) => ({
      checkConnectionFunc: this.checkConnection,
      getClassName: this.getClassName,
    });
  }

  checkConnection = (value) => {
    const { facilityId, equipment, getEquipmentVertexConnectivity, resetVertexConnectivity } =
      this.props;
    const activationKey = value.replace(/_/g, '');
    this.setState({ originalActivationKey: activationKey });
    resetVertexConnectivity();
    getEquipmentVertexConnectivity(facilityId, equipment.id, activationKey || '0000000000000000');
  };

  getClassName = (datas) =>
    this.verifyActivationKeyFormat(datas.value) ? '' : 'incorrect-activation-key';

  changeClassName = (action) => {
    if (action === 'add') {
      document.getElementById('TimeZoneId').classList.add('timezone-wrong');
    } else {
      document.getElementById('TimeZoneId').classList.remove('timezone-wrong');
    }
  };

  componentDidMount() {
    const { itemsList } = this.props;
    if (itemsList) {
      this.setState({ equipmentParameters: itemsList });
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { vertexConnectivity } = this.props;
    if (prevProps.vertexConnectivity === undefined && vertexConnectivity) {
      if (
        !vertexConnectivity.connected.includes('OffLine') &&
        !vertexConnectivity.connected.includes('OnLine')
      ) {
        toast.error(i18next.t('equipments.notFoundKey'));
      }
    }
  };

  componentWillUnmount = () => {
    this.setState({ equipmentParameters: [] });
  };

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    const { equipmentParameters } = this.state;
    if (!equipmentParameters && nextProps.itemsList) {
      this.setState({ equipmentParameters: nextProps.itemsList });
    }
  };

  verifyActivationKeyFormat = (value) => {
    const replace2 = value ? value.replace(/_/g, '') : '';
    return (
      this.props.equipment.productCode === 307 ||
      (value && (replace2.length === 0 || replace2.length === value.length))
    );
  };

  handleChange = (e, tagLocalization) => {
    const { setParamsOnForm, saveButtonIsEnabledCallback, equipment } = this.props;
    const { equipmentParameters } = { ...this.state };
    const equipmentParams = [...equipmentParameters];
    const target = e.target || e.currentTarget;
    const activationParam = equipmentParams.find((param) => param.name === 'ActivationKey');
    const timezoneParam = equipmentParams.find((param) => param.name === 'TimeZoneId');
    if (target.id === 'ActivationKey' && !this.state.originalActivationKey) {
      this.setState({ originalActivationKey: activationParam ? activationParam.value : '' });
    }
    equipmentParams.map((product) => {
      if (target.id === product.name) {
        product.value = target.value;
      }
      return product;
    });
    if (
      (this.verifyActivationKeyFormat(target.value) &&
        target.id === 'ActivationKey' &&
        timezoneParam?.value !== '00000000-0000-0000-0000-000000000000') ||
      (target.id !== 'ActivationKey' &&
        ((activationParam &&
          this.verifyActivationKeyFormat(activationParam.value) &&
          timezoneParam.value !== '00000000-0000-0000-0000-000000000000') ||
          (activationParam === undefined && timezoneParam === undefined))) ||
      target.id === 'Connectivity_Extension'
    ) {
      if (
        equipment.productCode === 612 ||
        equipment.productCode === 613 ||
        equipment.productCode === 614 ||
        equipment.productCode === 615
      ) {
        this.changeClassName('remove');
      }
      if (
        target.className.includes('incorrect-activation-key') &&
        target.id.includes('KEY') &&
        !target.value.includes('_')
      ) {
        saveButtonIsEnabledCallback(false);
      } else {
        saveButtonIsEnabledCallback(true);
      }
    } else if (
      (!this.verifyActivationKeyFormat(target.value) && target.id === 'ActivationKey') ||
      (target.id === 'TimeZoneId' && timezoneParam.value === '00000000-0000-0000-0000-000000000000')
    ) {
      if (
        equipment.productCode === 612 ||
        equipment.productCode === 613 ||
        equipment.productCode === 614 ||
        equipment.productCode === 615
      ) {
        this.changeClassName('add');
      }
      saveButtonIsEnabledCallback(false);
    }

    if (target.className.includes('incorrect-activation-key') && target.id.includes('KEY')) {
      saveButtonIsEnabledCallback(false);
    }

    this.props.setExitConfirmationCallback(true);
    setParamsOnForm(equipmentParams);
    this.setState({ equipmentParameters: equipmentParams });
  };

  onSaveParam = (param) => {
    const { setParamsOnForm, saveButtonIsEnabledCallback } = this.props;
    const { equipmentParameters } = this.state;
    const newParams = [...equipmentParameters].map((newParam) => {
      if (newParam.name === param.name) {
        newParam = param;
      }
      return newParam;
    });
    setParamsOnForm(newParams);
    saveButtonIsEnabledCallback(true);
    this.setState({ equipmentParameters: newParams });
  };

  changePopUpState = (onPopUpOpen, popupComponent, data) => {
    this.setState({ isPopupOpen: onPopUpOpen, popupComponent, data });
  };

  handleClosePopup = () => {
    this.setState({ isPopupOpen: false });
  };

  parseParamValue = (product) => {
    const { t } = this.props;
    if (product?.value && product?.type === 'readonly') {
      if (typeof product?.value !== 'number') {
        if (product?.value?.toUpperCase() === 'NO') {
          return t('paramsName.Configuration_Params_No');
        }
        if (product.value.toUpperCase() === 'SI') {
          return t('paramsName.Configuration_Params_Yes');
        }
      }
    }
    return product.value;
  };

  getRows = () => {
    const { t } = this.props;
    const { equipmentParameters } = this.state;
    let rows = [];
    if (equipmentParameters) {
      rows = equipmentParameters.map((product, i) => {
        const selectedAction = parametersPopUpsDictionary[product.type];
        let selectedFunctions = this.fieldsFunct[product.name];

        selectedFunctions = selectedFunctions ? selectedFunctions(product) : {};
        const paramNameTranslate =
          this.props.equipment.productCode === 308
            ? `paramsName.${product.name}`
            : `paramsName.${product.name}`;
        const paramValueTranslate = `paramsName.${product.valueLocalization}`;
        const productsList = [];
        if (product.ProductParamsList !== undefined && product.name !== 'ActiveEventsToSD') {
          product.ProductParamsList.forEach((dropdownParams) => {
            productsList.push({
              value:
                product.name !== 'TimeZoneId'
                  ? t(`paramsName.${dropdownParams.localization}`)
                  : dropdownParams.localization,
              key: dropdownParams.value,
            });
            // dropdownParams.value = t(`paramsName.${dropdownParams.localization}`);
            // dropdownParams.key = dropdownParams.localization;
          });
        }
        const row = {
          key: i,
          type: product.name,
          inputType:
            (this.props.product?.code !== 614 &&
              this.props.product?.code !== 615 &&
              product.name === 'SerialNumber') ||
            product.name === 'UniqueIdentity'
              ? 'hidden'
              : product.type,
          tagValue: i18next.exists(paramNameTranslate)
            ? i18next.t(paramNameTranslate)
            : product.name,
          hasAction: true,
          min: product.min,
          max: product.max,
          onMouseOver: () => this.setState({ parameterNameForDescription: product.name }),
          onMouseOut: () => this.setState({ parameterNameForDescription: '' }),
          getActionComponent: () => {
            const InputType =
              product.type &&
              parametersDictionary[product.type]({
                id: product.name,
                values: productsList,
                value: i18next.exists(paramValueTranslate)
                  ? i18next.t(paramValueTranslate)
                  : this.parseParamValue(product),
                onChange: (e) => this.handleChange(e, paramValueTranslate),
                min: product.min,
                max: product.max || product.length,
                length: product.length,
                className: 'instalation-equipment-parameters-field',
                regexPattern: product.length === 16 ? '****-****-****-****' : '*****-*****',
                onClick: () => this.changePopUpState(true, selectedAction.component, product),
                ...selectedFunctions,
              });
            return InputType;
          },
        };
        return row;
      });
    }
    return rows;
  };

  getColumns = () => {
    const { t } = this.props;
    return [
      {
        value: t('form.name'),
      },
      {
        value: t('facilitiesUserGroupsGrid.values'),
      },
    ];
  };

  getFacility = () =>
    this.props.facilities.find((facility) => facility.id === this.props.facilityId);

  render() {
    const { tenants, t } = this.props;
    const PopupContent = this.state.popupComponent;
    const descriptionParamTranslation = `descriptionParams.${this.state.parameterNameForDescription.toUpperCase()}`;
    return (
      <Flex
        height='100%'
        flexDirection='column'
        className='equipment-parameter-tab'
        justifyContent='space-between'
      >
        <GenericTable
          tenants={tenants}
          columnsConfig={this.getColumns()}
          rowsConfig={this.getRows()}
          needScrollManage
        />
        <label className='equipment-parameter-description'>
          {i18next.exists(descriptionParamTranslation) ? t(descriptionParamTranslation) : ` `}
        </label>
        <Flex id='save' marginTop={2} justifyContent='flex-end'>
          <Button
            backgroundColor={tenants?.color1}
            sizeButton='small'
            id='entry'
            type='submit'
            disabled={false}
            action={(e) => {
              const { name, ...rest } = this.props.equipment;
              const equipment = { ...rest };
              this.props.updateItem(equipment, 'Info');
            }}
            marginRight={1}
            color={getContrastColor(tenants?.color1)}
          >
            {t('general.save')}
          </Button>
        </Flex>
        <PopUps onClose={this.handleClosePopup} isOpen={this.state.isPopupOpen}>
          //This check is needed because the popup is closed before the component is mounted.
          Prevents infinite warnings
          {PopupContent && (
            <PopupContent
              tenants={tenants}
              onSave={this.onSaveParam}
              closePopup={this.handleClosePopup}
              data={this.state.data}
            />
          )}
        </PopUps>
      </Flex>
    );
  }
}

const instalationEquipmentsInfo = withRouter(Index);
const InstalationEquipmentsInfoWithTranslation = withTranslation()(instalationEquipmentsInfo);

const mapStateToProps = (state) => ({
  equipmentParameters: state.instalations.equipmentParameters,
  facilities: state.instalations.instalations,
  tenants: state.tenants,
  vertexConnectivity: state.equipments.vertexConnectivity,
});

export default connect(mapStateToProps, {
  putEquipment,
  getEquipmentVertexConnectivity,
  resetVertexConnectivity,
})(InstalationEquipmentsInfoWithTranslation);
