export default {
  changePassword: {
    title: 'Cambiar contraseña',
    submitText: 'Cambiar contraseña',
    currentPassword: 'Contraseña actual',
    newPassword: 'Nueva contraseña',
    confirmPassword: 'Confirmar contraseña',
    cancel: 'Cancelar',
    chooseFile: 'Examinar...',
    newPasswordPopupTitle: 'Establecer nueva contraseña',
  },
};
