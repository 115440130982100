import { Modal } from '@jcm-technologies/uikit/dist/molecules/Modal';
import { Flex, Grid, GridItem } from '@jcm-technologies/uikit/dist/atoms/Layout';
import { Text } from '@jcm-technologies/uikit/dist/atoms/Typography';
import { Button } from '@jcm-technologies/uikit/dist/atoms/Button';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { getContrastColor } from '../../../../../../core/helpers/colorManager';

export const DeleteMifare = ({ fobToDelete, setFobToDelete, onDeleteMifare }) => {
  const { t } = useTranslation();
  const { color1 } = useSelector((state) => state.tenants);

  return (
    <Modal
      isOpen={!!fobToDelete}
      title={t('instalationUserGroups.deleteUserConfirmation')}
      closeWithButton
      id='modal-delete-user'
      width={[300, 500, 500]}
      onModalClose={() => setFobToDelete(null)}
    >
      <Modal.Content>
        <Grid gridTemplateAreas="'. .'" gridTemplateColumns='1fr 1fr' gridGap={2}>
          <GridItem>
            <Text>{t('form.code')}:</Text>
          </GridItem>
          <GridItem>
            <Text>{fobToDelete?.code}</Text>
          </GridItem>
          <GridItem>
            <Text>{t('form.type')}:</Text>
          </GridItem>
          <GridItem>
            <Text>{fobToDelete?.type || t('title.mifare')}</Text>
          </GridItem>
          <GridItem>
            <Text>{t('form.name')}:</Text>
          </GridItem>
          <GridItem>
            <Text>{fobToDelete?.name}</Text>
          </GridItem>
          <GridItem>
            <Text>{t('form.surname')}:</Text>
          </GridItem>
          <GridItem>
            <Text>{fobToDelete?.surname}</Text>
          </GridItem>
          <GridItem>
            <Text>{t('form.identityCard')}:</Text>
          </GridItem>
          <GridItem>
            <Text>{fobToDelete?.nic}</Text>
          </GridItem>
          <GridItem>
            <Text>{t('form.slot')}:</Text>
          </GridItem>
          <GridItem>
            <Text>{fobToDelete?.plaza}</Text>
          </GridItem>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Flex alignItems='center' justifyContent='flex-end'>
          <Button
            backgroundColor={color1}
            marginRight={2}
            color={getContrastColor(color1)}
            action={() => onDeleteMifare(fobToDelete.id)}
          >
            {t('general.delete')}
          </Button>
          <Button
            sizeText='display14'
            backgroundColor='blackLight'
            id='cancel-button'
            action={() => setFobToDelete(null)}
          >
            {t('general.cancel')}
          </Button>
        </Flex>
      </Modal.Actions>
    </Modal>
  );
};

DeleteMifare.propTypes = {
  onDeleteMifare: PropTypes.func.isRequired,
  setFobToDelete: PropTypes.func.isRequired,
  fobToDelete: PropTypes.shape({
    code: PropTypes.string,
    id: PropTypes.string,
    type: PropTypes.string,
    name: PropTypes.string,
    surname: PropTypes.string,
    plaza: PropTypes.string,
    nic: PropTypes.string,
  }),
};

DeleteMifare.defaultProps = {
  fobToDelete: null,
};
