import styled, { css } from 'styled-components';

export default styled.input`
  position: relative;
  top: 20px;
  border: none;
  border-bottom: 1px solid ${(props) => (props.error ? 'red' : '#b3b3b3')};
  display: block;
  font-size: 14px;
  font-weight: 100;
  width: 100%;
  outline: none !important;
  padding-bottom: 2px;
  ${(props) => (props.icon ? 'text-indent: 30px;' : '')};

  ${(props) => (props.isFocused && css ? `border-bottom: 1px solid ${props.tenants.color1}` : ``)}
  &:hover {
    border-bottom: 1px solid ${(props) => props.tenants.color1};
  }

  &:read-only {
    pointer-events: none;
    border-bottom: 1px solid #b3b3b3;
    background-color: #f4f4f4;
  }

  &:disabled {
    color: #f4f4f4;
    border-bottom: 1px solid #f4f4f4;
    background-color: white;
  }
`;
