import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Button } from '@jcm-technologies/uikit/dist/atoms/Button';
import '../../PopUpConfirm/PopupConfirm.css';
import { Flex } from '@jcm-technologies/uikit/dist/atoms/Layout';
import { Text } from '@jcm-technologies/uikit/dist/atoms/Typography';
import { ContentContainer } from '../../ContentContainer/ContentContainer';
import { getContrastColor } from '../../../core/helpers/colorManager';
import IconSpan from '../../IconSpan/IconSpan';

export class RemoveSelectedItemsPopUp extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { onAcceptFn, onCancelFn, text, selectedItems, tenants, t } = this.props;
    return (
      <ContentContainer title={text} tenants={tenants} isSecondDeletePopup>
        <div className='container-secondDelete'>
          <div className='iconWarning'>
            <IconSpan defaultColor='red' iconClassName='fas fa-exclamation-triangle fa-5x' />{' '}
          </div>
          <div className='delete-fobs-popup-text'>
            <Text as='span' sizeText='display14'>
              {`${
                this.props.action !== 'save'
                  ? t('instalations.firstAreYouSureCriticalDelete')
                  : t('fobsPopUps.selectedItemsAdd1')
              }`}
            </Text>
            <Text as='span' sizeText='display24' fontWeight='bold' color='red'>
              {selectedItems.length}
            </Text>
            <Text as='span' sizeText='display14'>
              {t('fobsPopUps.selectedItemsShow2')}
            </Text>
            <Text sizeText='display14' fontWeight='bold'>{`${t(
              'fobsPopUps.warningDeleteFobs'
            )}`}</Text>
          </div>
          <div className='secondText'>{t('instalations.noGoBack')}</div>
        </div>
        <Flex alignItems='center' justifyContent='center' marginTop={5}>
          <Button
            id='acceptDelete'
            sizeButton='small'
            backgroundColor={tenants?.color1}
            action={(e) => {
              e?.preventDefault();
              e?.stopPropagation();
              onAcceptFn(e, selectedItems);
              onCancelFn(e);
            }}
            marginRight={2}
            color={getContrastColor(tenants?.color1)}
          >
            {t('popupConfirm.accept')}
          </Button>
          <Button
            backgroundColor='blackLight'
            id='cancel-button'
            sizeButton='small'
            action={(e) => {
              e?.preventDefault();
              e?.stopPropagation();
              onCancelFn(e);
            }}
          >
            {t('popupConfirm.cancel')}
          </Button>
        </Flex>
      </ContentContainer>
    );
  }
}

const popupWithTranslation = withTranslation()(RemoveSelectedItemsPopUp);

export default popupWithTranslation;
