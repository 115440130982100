import React, { Component } from 'react';
import { connect } from 'react-redux';
import StyledTextarea from './StyledTextarea';
import ErrorMessage from '../ErrorMessage';
import StyledSpan from './StyledSpan';
import './Textarea.css';

class Textarea extends Component {
  render() {
    const {
      type,
      placeHolder,
      value,
      id,
      onChange,
      tenants,
      error,
      disable,
      required,
      icon,
      onFocus,
      onBlur,
      isFocused,
      className,
    } = this.props;
    return (
      <label className={`jcm-label text-area-input ${className || ''}`}>
        <StyledSpan tenants={tenants} icon={icon} value={value} isFocused={isFocused}>
          {placeHolder}
        </StyledSpan>
        <StyledTextarea
          tenants={tenants}
          icon={icon}
          error={error}
          id={id}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          isFocused={isFocused}
          value={value || ''}
          type={type}
          disabled={disable}
          required={required}
          autoComplete='off'
          rows='5'
          cols='10'
        />
        {error ? <ErrorMessage message={error.errorMessage} /> : null}
      </label>
    );
  }
}

const mapStateToProps = (state) => ({
  tenants: state.tenants,
});

export default connect(mapStateToProps, {})(Textarea);
