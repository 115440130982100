export default {
  gdprPopup: {
    accept: 'Accepter',
    cancel: 'Annuler',
    conditionsAgreement: "Acceptez les conditions d'utilisation",
    close: 'Accepter',
    firstPolicyAgreementText:
      'CloudAssistant continue d’évoluer pour vous fournir de meilleurs services et accueillir de nouvelles fonctionnalités, telles que le nouveau rôle Property Manager (Syndic de copropriété) qui permet de déléguer la gestion des télécommandes aux syndics de copropriété désireux d’offrir un service de plus grande proximité à leurs clients.',
    firstPolicyAgreementSecondText:
      'Nous avons modifié nos politiques pour pouvoir intégrer ces nouveaux services.',
    firstPolicyAgreementThirdText: 'En cas de doute, n’hésitez pas à nous contacter via l’adresse',
    privacyText: 'Politique de confidentialité',
    legalNoticeText: 'Mention légale',
    cookiesText: 'Politique de cookies',
    conditionsText: 'Conditions générales',
    PolicyAgreementPopupTitle1: 'CloudAssistant se met à jour',
    PolicyAgreementPopupTitle2: 'nouveaux services et politiques',
    PolicyAgreementPopupAnd: 'et',
  },
};
