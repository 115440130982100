export default {
  neighbourPopUp: {
    title: 'Nachbarn hinzufügen',
    email: 'E-Mail',
    phoneNumber: 'Telefonnummer',
    floor: 'Etage',
    door: 'Tor',
    titleEdit: 'Kontakt bearbeiten',
    titleDelete: 'Möchten Sie den Kontakt wirklich löschen?',
  },
};
