import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import InstalationGrow from '../../old_components/InstalationGrow';
import IndividualInstalationForm from '../../old_components/IndividualInstalationForm';
import { getFacility } from '../../modules/old_to_refact/actions/instalations';
import {
  CreationPath_Instalations,
  RootPath_Instalations,
} from '../../core/old_common/constats/genericListPaths';
import GenericIndividualGrow from '../../old_components/GenericIndividualGrow/GenericIndividualGrow';
import InstalationGroupsList from '../../old_components/InstalationGroupsList/InstalationGroupsList';
import EquipmentsList from '../../old_components/EquipmentsList/EquipmentsList';
import { resetSignalRConnectionOnReducer } from '../../core/old_common/utils/signalRService';
import {
  getCookie_selectedFacilityCustomerId,
  saveCookies,
} from '../../core/old_common/utils/cookiesManager';
import LogsTab from '../../components/Facility/Logs';
import EventsTab from '../../components/Facility/Events';

class IndividualInstalation extends Component {
  componentDidUpdate() {
    const selectedItem = this.getSelectedItem();
    if (selectedItem && getCookie_selectedFacilityCustomerId() !== selectedItem.customerId) {
      saveCookies({ selectedFacilityCustomerId: selectedItem.customerId });
    }
  }

  componentWillUnmount() {
    resetSignalRConnectionOnReducer();
  }

  getSelectedItem = () => {
    const { match, facility, instalations } = this.props;
    if (facility) {
      return facility;
    }
    if (instalations) {
      return instalations.find((instalation) => instalation.name === match.params.id);
    }
    return undefined;
  };

  render() {
    const { t, getFacility, active, history, match, facility } = this.props;
    const tabBaseUri = `${RootPath_Instalations}/${match.params.id}`;
    const selectedItem = this.getSelectedItem();
    return (
      <GenericIndividualGrow
        Grow={(props) => <InstalationGrow {...props} canClone needSecondPopup />}
        baseURI={RootPath_Instalations}
        creationURI={CreationPath_Instalations}
        selectedItem={selectedItem}
        getItem={getFacility}
        facility={facility}
        needSecondPopup
        tabsData={[
          {
            title: t('universalGroups.informationTab'),
            id: 'information',
            bodyComponent: IndividualInstalationForm,
            onClick: () => {
              history.push(
                `${encodeURI(tabBaseUri).replace(/\&/g, '%26').replace('%09', '%2509')}`
              );
            },
            active: !active,
          },
          {
            title: t('instalations.equipmentsTab'),
            id: 'equipments',
            bodyComponent: EquipmentsList,
            active: active === 'Equipments',
            onClick: () => {
              history.push(`${encodeURI(tabBaseUri)}/Equipments`);
            },
            componentState: {
              baseURI: `${encodeURI(tabBaseUri)}/Equipments`,
              instalationId: selectedItem ? selectedItem.id : '',
            },
          },
          {
            title: t('instalations.groupsTab'),
            id: 'groups',
            bodyComponent: InstalationGroupsList,
            active: active === 'Groups',
            onClick: () => history.push(`${encodeURI(tabBaseUri)}/groups`),
            componentState: {
              baseURI: `${encodeURI(tabBaseUri)}/groups`,
              instalationId: selectedItem ? selectedItem.id : '',
            },
          },
          {
            title: t('title.events'),
            id: 'events',
            bodyComponent: EventsTab,
            active: active === 'events',
            onClick: () => {
              history.push(`${encodeURI(tabBaseUri)}/events`);
            },
            componentState: {
              baseURI: `${encodeURI(tabBaseUri)}/events`,
              instalationId: selectedItem ? selectedItem.id : '',
            },
          },
          {
            title: t('title.log'),
            id: 'logs',
            bodyComponent: LogsTab,
            active: active === 'logs',
            onClick: () => {
              history.push(`${encodeURI(tabBaseUri)}/logs`);
            },
            componentState: {
              baseURI: `${encodeURI(tabBaseUri)}/logs`,
              instalationId: selectedItem ? selectedItem.id : '',
            },
          },
        ]}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  tenants: state.tenants,
  facility: state.instalations.facility,
  instalations: state.instalations.instalations,
});
const IndividualInstalationWithTranslation = withTranslation()(IndividualInstalation);
const IndividualInstalationWithRouter = withRouter(IndividualInstalationWithTranslation);

export default connect(mapStateToProps, {
  getFacility,
})(IndividualInstalationWithRouter);
