import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { Flex } from '@jcm-technologies/uikit/dist/atoms/Layout';
import { getEquipmentParameters } from '../../modules/old_to_refact/actions/instalations';
import { putEquipmentIsUpdated } from '../../modules/old_to_refact/actions/equipments';
import {
  getEvents,
  getRemoteComunication,
  postFormatEvents,
  postReadEvents,
  postReadMemory,
  postSetMemory,
  resetNeedUpdateList,
} from '../../modules/old_to_refact/actions/a5Kservices';
import GenericKendoGrid from '../GenericKendoGrid/GenericKendoGrid';
import ButtonsGroup from '../ButtonsGroup/ButtonsGroup';
import { IconSpan } from '../IconSpan';

import './InstalationEquipmentsRemoteComunications.css';
import apiPathFactory from '../../core/old_common/rooting/apiPathFactory';
import { downloadFile, getFilesFromApi } from '../../core/helpers/filesApi';
import {
  checkAssistantPanelConnection,
  invokeSendCICC,
  parseCCIC,
  setAssistantPanelCheckSuccessFunction,
  setMessageHubFunction,
} from '../../core/old_common/utils/signalRService';

export class InstalationEquipmentsRemoteComunications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      signalRAction: '',
      tableIndex: 0,
      fileName: '',
      needIconRefresh: false,
      equipmentIsFullUpdated: true,
      showMessage: false,
    };
  }

  componentDidMount() {
    const { getRemoteComunication, getEquipmentParameters, facilityId, equipmentId } = this.props;
    getEquipmentParameters(facilityId, equipmentId);
    getRemoteComunication(facilityId, equipmentId);
  }

  componentDidUpdate() {
    if (this.props.needUpdateList) {
      this.props.resetNeedUpdateList();
      this.onClickRefreshList();
    }
    if (this.props.isUpdated === 1 && this.props.updatedEquipment) {
      if (
        this.props.updatedEquipment &&
        !this.state.needIconRefresh &&
        this.state.equipmentIsFullUpdated
      ) {
        this.changeSaveMemoryIconColor('red');
        this.setState({ equipmentIsFullUpdated: false });
      } else if (this.state.needIconRefresh) {
        this.changeSaveMemoryIconColor('#a4aebb');
        this.setState({ needIconRefresh: false });
      }
    }
  }

  changeSaveMemoryIconColor = (color) => {
    const element = document.getElementById('saveConfig');
    if (element != null) {
      document.getElementById('saveConfig').style.color = color;
    }
  };

  getColumns = () => {
    const { t, tenants } = this.props;
    return [
      // {
      //   key: 'index',
      //   name: t('remoteComunications.index'),
      //   sortable: false,
      //   filterable: false,
      //   tooltip: t('remoteComunications.index'),
      //   editable: false,
      //   width: 70,
      //   headerClassName: 'remote-comm-grid-column',
      //   className: 'remote-comm-grid-column',
      // },
      {
        key: 'date',
        name: t('form.date'),
        sortable: false,
        filterable: false,
        tooltip: t('form.date'),
        editable: false,
        width: 150,
        headerClassName: 'remote-comm-grid-column',
        className: 'remote-comm-grid-column',
        resizable: true,
      },
      {
        key: 'content',
        name: t('remoteComunications.content'),
        sortable: false,
        filterable: false,
        tooltip: t('remoteComunications.content'),
        editable: false,
        width: 90,
        headerClassName: 'remote-comm-grid-column',
        className: 'remote-comm-grid-column',
        resizable: true,
      },
      {
        key: 'serialNumber',
        name: t('remoteComunications.serialNumber'),
        sortable: false,
        filterable: false,
        tooltip: t('remoteComunications.serialNumber'),
        editable: false,
        width: 100,
        headerClassName: 'remote-comm-grid-column',
        className: 'remote-comm-grid-column',
        resizable: true,
      },
      {
        key: 'uniqueKey',
        name: t('remoteComunications.uniqueKey'),
        sortable: false,
        filterable: false,
        tooltip: t('remoteComunications.uniqueKey'),
        editable: false,
        width: 90,
        headerClassName: 'remote-comm-grid-column',
        className: 'remote-comm-grid-column',
        resizable: true,
      },
      {
        key: 'completion',
        name: t('remoteComunications.operationsCode'),
        sortable: false,
        filterable: false,
        tooltip: t('remoteComunications.operationsCode'),
        editable: false,
        width: 75,
        headerClassName: 'remote-comm-grid-column',
        className: 'remote-comm-grid-column',
        resizable: true,
      },
      {
        key: 'statusTranslate',
        name: t('remoteComunications.status'),
        sortable: false,
        filterable: false,
        tooltip: t('remoteComunications.status'),
        editable: false,
        width: 90,
        headerClassName: 'remote-comm-grid-column',
        className: 'remote-comm-grid-column',
        resizable: true,
      },
      {
        key: 'progress',
        name: t('remoteComunications.progress'),
        sortable: false,
        filterable: false,
        tooltip: t('remoteComunications.progress'),
        editable: false,
        width: 70,
        headerClassName: 'remote-comm-grid-column',
        className: 'remote-comm-grid-column',
        resizable: true,
      },
      {
        key: 'actions',
        name: ' ',
        cell: (props, childProps) => (
          <td className='grid-buttons'>
            <>
              {props.dataItem.progress === '100' ? (
                <IconSpan
                  cursor='pointer'
                  toolTip={t('remoteComunications.export')}
                  tenants={tenants}
                  iconClassName='fas fa-file-export'
                  onClick={() => this.onClickDownloadFile(props, childProps)}
                />
              ) : null}
              <span />
              {props.dataItem.progress === '100' && props.dataItem.content === 'ReadEvents' ? (
                <IconSpan
                  cursor='pointer'
                  toolTip={t('remoteComunications.seeEvents')}
                  tenants={tenants}
                  iconClassName='fas fa-eye see-event-icon'
                  onClick={() => this.onClickSeeEvents(props)}
                />
              ) : null}
            </>
          </td>
        ),
        tooltip: t('form.type'),
        // width: 100,
        headerClassName: 'remote-comm-grid-column',
        className: 'remote-comm-grid-column',
        resizable: true,
        // locked: true,
      },
    ];
  };

  onClickDownloadFile = (props) => {
    const fileName =
      props.dataItem.content === 'ReadMemory' || 'SetMemory'
        ? 'Access5KCodesM.JCMbin'
        : 'Access5KEventsM.JCMbin';
    this.setState({
      signalRAction: 'downloadFileFromGrid',
      tableIndex: props.dataItem.index,
      fileName,
    });
    setMessageHubFunction(this.readICCC);
    setAssistantPanelCheckSuccessFunction(invokeSendCICC);
    checkAssistantPanelConnection();
  };

  onClickSeeEvents = (props) => {
    const { facilityId, equipmentId, history, match } = this.props;
    this.props.getEvents(facilityId, equipmentId, null, props.dataItem.index);
    history.push(match.url.replace('Remote comm', 'Events'));
  };

  onClickRefreshList = () => {
    const { getRemoteComunication, getEquipmentParameters, facilityId, equipmentId } = this.props;
    getEquipmentParameters(facilityId, equipmentId);
    getRemoteComunication(facilityId, equipmentId);
  };

  readICCC = (connID, message) => {
    const { facilityId, equipmentId, postReadMemory, postSetMemory } = this.props;
    const ICCC = parseCCIC(message);
    if (this.state.signalRAction === 'readMemory') {
      postReadMemory(facilityId, equipmentId, ICCC.ic, ICCC.cc);
    } else if (this.state.signalRAction === 'setMemory') {
      postSetMemory(facilityId, equipmentId, ICCC.ic, ICCC.cc);
    } else if (this.state.signalRAction === 'downloadFileFromGrid') {
      const endpoint = apiPathFactory.a5kGetFileContent(
        facilityId,
        equipmentId,
        ICCC.ic,
        ICCC.cc,
        this.state.tableIndex
      );
      getFilesFromApi(
        endpoint,
        (isError, response) => {
          if (!isError) {
            downloadFile(response, this.state.fileName);
          }
          this.setState({ signalRAction: '', fileName: '', tableIndex: 0 });
        },
        'application/json',
        'binary'
      );
      this.props.putEquipmentIsUpdated(facilityId, equipmentId);
      this.setState({ needIconRefresh: true });
    }
    if (this.state.signalRAction !== 'downloadFileFromGrid') {
      this.setState({ signalRAction: '', fileName: '', tableIndex: 0 });
    }
  };

  handleSetMemory = () => {
    this.setState({ signalRAction: 'readMemory' });
    setMessageHubFunction(this.readICCC);
    setAssistantPanelCheckSuccessFunction(invokeSendCICC);
    checkAssistantPanelConnection();
  };

  handleReadMemory = () => {
    this.setState({ signalRAction: 'setMemory' });
    setMessageHubFunction(this.readICCC);
    setAssistantPanelCheckSuccessFunction(invokeSendCICC);
    checkAssistantPanelConnection();
  };

  handleReadEvents = () => {
    const { facilityId, equipmentId, postReadEvents } = this.props;
    postReadEvents(facilityId, equipmentId);
  };

  handleFormatEvents = () => {
    const { facilityId, equipmentId, postFormatEvents } = this.props;
    postFormatEvents(facilityId, equipmentId);
  };

  getCommunicationsTranslated = () => {
    const { remoteComunication, t } = this.props;
    if (remoteComunication) {
      return remoteComunication.map((comunicationTranslated) => ({
        ...comunicationTranslated,
        statusTranslate: t(`remoteComunications.${comunicationTranslated.status}`),
      }));
    }
    return [];
  };

  render() {
    const { remoteComunication, tenants, t } = this.props;
    return (
      <Flex height='100%' flexDirection='column' justifyContent='space-between'>
        <Flex flexDirection='column'>
          <span className='remote-comunications-refresh'>
            <IconSpan
              cursor='pointer'
              toolTip={t('remoteComunications.refresh')}
              tenants={tenants}
              iconClassName='fas fa-sync-alt'
              onClick={() => this.onClickRefreshList()}
            />
          </span>
          <GenericKendoGrid
            resizable
            getColumns={this.getColumns}
            enableCellSelect={false}
            list={remoteComunication}
            value={remoteComunication ? this.getCommunicationsTranslated() : []}
          />
        </Flex>
        <ButtonsGroup
          className='remote-buttons'
          buttons={[
            {
              id: 'acceptDelete',
              value: t('remoteComunications.saveConfig'),
              onClick: this.handleReadMemory,
            },
            {
              id: 'cancelDelete',
              value: t('remoteComunications.readConfig'),
              onClick: this.handleSetMemory,
            },
            {
              id: 'acceptDelete',
              value: t('remoteComunications.readEvents'),
              onClick: this.handleReadEvents,
            },
            {
              id: 'cancelDelete',
              value: t('remoteComunications.removeEvents'),
              onClick: this.handleFormatEvents,
            },
          ]}
        />
      </Flex>
    );
  }
}

const InstalationEquipmentsRemoteComunicationsWithTranslation = withTranslation()(
  InstalationEquipmentsRemoteComunications
);
const InstalationEquipmentsRemoteComunicationsWithRouter = withRouter(
  InstalationEquipmentsRemoteComunicationsWithTranslation
);

const mapStateToProps = (state) => ({
  remoteComunication: state.a5Kservices.remoteComunication,
  needUpdateList: state.a5Kservices.needUpdateList,
  equipmentParameters: state.instalations.equipmentParameters,
  tenants: state.tenants,
  isUpdated: state.equipments.isUpdated,
  updatedEquipment: state.equipments.updatedEquipment,
});

export default connect(mapStateToProps, {
  putEquipmentIsUpdated,
  getEquipmentParameters,
  getRemoteComunication,
  postReadMemory,
  postSetMemory,
  postReadEvents,
  postFormatEvents,
  getEvents,
  resetNeedUpdateList,
})(InstalationEquipmentsRemoteComunicationsWithRouter);
