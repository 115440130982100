import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Flex } from '@jcm-technologies/uikit/dist/atoms/Layout';
import {
  patchPreferencesUser,
  resetNeedCheckSignalRConnection,
} from '../../../modules/user/actions';
import Panel from '../../../components/UserConfig/AssistantPanel/Panel';
import { showAlert } from '../../../modules/state/alerts';
import {
  checkAssistantPanelConnection,
  createSignalRConnectionOnReducer,
  resetSignalRConnectionOnReducer,
  setAssistantPanelCheckSuccessFunction,
  setMessageHubFunction,
  setSignalRErrorFunction,
} from '../../../core/old_common/utils/signalRService';
import { getCustomerInstallerCode } from '../../../modules/customers/actions';

const AssistantPanel = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { user, assistantPanelID, needExecuteSignalRCheck } = useSelector((state) => state.user);
  const [isConnectionDone, setIsConnectionDone] = useState(false);
  const [statusAssistantState, setStatusAssistantState] = useState('unknown');
  const tenants = useSelector((state) => state.tenants);

  const handlerDrop = (ev) => {
    const assistId = ev.dataTransfer.getData('text/plain');
    const dataC = assistId.split('@');
    const dataB = assistId.split('*');
    if (dataC.length !== 2 || dataB.length !== 2) {
      dispatch(showAlert('red', 1, t('error.assistantIDInvalid')));
    } else {
      dispatch(patchPreferencesUser(user.id, { assistantConfigId: assistId }));
    }
  };

  const onCheckAssistantSuccess = () => {
    setStatusAssistantState('success');
    setIsConnectionDone(true);
    dispatch(showAlert('green', 1, t('assistant.isReady')));
  };

  const onCheckError = () => {
    setStatusAssistantState('error');
  };

  const readCICC = (connId, codes) => {
    const installerCodeReaded = codes?.split('|')?.[1]?.split('::')?.[0];
    if (Number(installerCodeReaded) === 0) {
      dispatch(showAlert('orange', 1, t('apitoasters.errors.zeroCode')));
    }
  };
  const handleSendTestMessage = () => {
    dispatch(showAlert('green', 1, t('assistant.checkingAssistantPanel')));
    setMessageHubFunction(readCICC);
    checkAssistantPanelConnection();
  };

  useEffect(() => {
    dispatch(getCustomerInstallerCode());
    createSignalRConnectionOnReducer(() => setIsConnectionDone(true));
    setMessageHubFunction(undefined);
    setAssistantPanelCheckSuccessFunction(onCheckAssistantSuccess);
    setSignalRErrorFunction(onCheckError);
    return () => {
      resetSignalRConnectionOnReducer();
      setSignalRErrorFunction(undefined);
    };
  }, []);

  useEffect(() => {
    if (needExecuteSignalRCheck) {
      resetNeedCheckSignalRConnection();
      if (isConnectionDone) {
        handleSendTestMessage();
      }
    }
  }, [needExecuteSignalRCheck]);

  return (
    <Flex justifyContent='space-between' flexDirection='column' padding={2}>
      <Panel
        assistantPanelID={assistantPanelID}
        handlerDrop={handlerDrop}
        tenants={tenants}
        isConnectionDone={isConnectionDone}
        handleSendTestMessage={handleSendTestMessage}
        handleShowWarningToast={() =>
          dispatch(showAlert('orange', 1, t(`warning.needInstallerCode`)))
        }
        statusAssistantState={statusAssistantState}
      />
    </Flex>
  );
};

export default AssistantPanel;
