export default {
  parameters: {
    title: 'Configuration des paramètres de Proxy Access 5K',
    pinCode: 'Code PIN',
    apn: 'APN',
    apnUser: 'Utilisateur APN',
    apnPassword: 'Mot de passe APN',
    serverURL: 'Serveur URL',
    editproxy: 'Modifier le proxy A5K',
    titleRelays: 'Relais de programmation',
    errorRelaysPopUp: 'Erreur: vous ne pouvez pas dépasser le nombre maximum de relais',
    CONFIGRELAYSNEW1_ICON: 'Modifier le relais 1',
    CONFIGRELAYSNEW2_ICON: 'Modifier le relais 2',
    CONFIGRELAYSNEW3_ICON: 'Modifier le relais 3',
    CONFIGRELAYSNEW4_ICON: 'Modifier le relais 4',
    ACTIVEEVENTSTOSD_ICON: 'Modifier les événements actifs',
    A5KPROXY_ICON: 'Modifier le proxy A5K',
  },
};
