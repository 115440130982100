import { Modal } from '@jcm-technologies/uikit/dist/molecules/Modal';
import { Flex } from '@jcm-technologies/uikit/dist/atoms/Layout';
import { Button } from '@jcm-technologies/uikit/dist/atoms/Button';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { getContrastColor } from '../../../../../core/helpers/colorManager';

export const SaveActionModal = ({ handleSave, isOpened, setIsOpened }) => {
  const { t } = useTranslation();
  const { color1 } = useSelector((state) => state.tenants);
  return (
    <Modal
      isOpen={isOpened}
      width='100%'
      maxWidth={[300, 400, 400]}
      closeWithButton
      title={t('general.confirmSaveChanges')}
      id='modal-sure-save'
      onModalClose={() => setIsOpened(false)}
    >
      <Modal.Actions>
        <Flex alignItems='center' justifyContent='center' marginTop={3}>
          <Button
            id='acceptModifyData'
            sizeButton='small'
            backgroundColor={color1}
            action={() => {
              handleSave();
            }}
            marginRight={2}
            color={getContrastColor(color1)}
          >
            {t('popupConfirm.accept')}
          </Button>
          <Button backgroundColor='blackLight' id='cancel-button' sizeButton='small'>
            {t('popupConfirm.cancel')}
          </Button>
        </Flex>
      </Modal.Actions>
    </Modal>
  );
};

SaveActionModal.propTypes = {
  setIsOpened: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  isOpened: PropTypes.bool.isRequired,
};
