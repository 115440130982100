import { call, fork, select, takeLatest } from 'redux-saga/effects';
import fetchEntitySaga from '../old_to_refact/sagas/utils/fetchEntitySaga';
import apiPathFactory from '../../core/old_common/rooting/apiPathFactory';
import api from '../../core/helpers/api';
import {
  OWNERS_DELETE,
  OWNERS_GDPR_POST,
  OWNERS_LOGS_LIST,
  OWNERS_PENDING_LIST_GET,
  OWNERS_SINGLELIST_GET,
} from './constants';
import { getCookiePageSizeItems } from '../../core/old_common/utils/cookiesManager';
import { GDPR_LIST, GDPR_LIST_PENDING } from '../state/pagination/constants';

export const getOwnersLogList = {
  base: (pageSize, pageNumber) => ({
    type: OWNERS_LOGS_LIST.BASE,
    pageSize,
    pageNumber,
  }),
  request: () => ({ type: OWNERS_LOGS_LIST.REQUEST }),
  success: (result) => ({ type: OWNERS_LOGS_LIST.SUCCESS, result }),
  failure: (error) => ({ type: OWNERS_LOGS_LIST.FAILURE, error }),
};

export const getOwnersSingleList = {
  base: () => ({
    type: OWNERS_SINGLELIST_GET.BASE,
  }),
  request: () => ({ type: OWNERS_SINGLELIST_GET.REQUEST }),
  success: (result) => ({ type: OWNERS_SINGLELIST_GET.SUCCESS, result }),
  failure: (error) => ({ type: OWNERS_SINGLELIST_GET.FAILURE, error }),
};

export const getOwnersPendingList = {
  base: () => ({
    type: OWNERS_PENDING_LIST_GET.BASE,
  }),
  request: () => ({ type: OWNERS_PENDING_LIST_GET.REQUEST }),
  success: (result) => ({ type: OWNERS_PENDING_LIST_GET.SUCCESS, result }),
  failure: (error) => ({ type: OWNERS_PENDING_LIST_GET.FAILURE, error }),
};

export const delOwner = {
  base: () => ({
    type: OWNERS_DELETE.BASE,
  }),
  request: () => ({ type: OWNERS_DELETE.REQUEST }),
  success: (result) => ({ type: OWNERS_DELETE.SUCCESS, result }),
  failure: (error) => ({ type: OWNERS_DELETE.FAILURE, error }),
};

export const postOwnerGDPR = {
  base: (gdprAgreement) => ({
    type: OWNERS_GDPR_POST.BASE,
    gdprAgreement,
  }),
  request: () => ({ type: OWNERS_GDPR_POST.REQUEST }),
  success: (result) => ({ type: OWNERS_GDPR_POST.SUCCESS, result }),
  failure: (error) => ({ type: OWNERS_GDPR_POST.FAILURE, error }),
};

export function* getOwnersLogListSaga(data) {
  try {
    const url = apiPathFactory.ownersGetLogsList(data.pageSize, data.pageNumber);
    const response = yield call(() => fetchEntitySaga(getOwnersLogList, api.get, url));
    return response;
  } catch (error) {
    return error;
  }
}

export function* getOwnersSingleListSaga({ string }) {
  try {
    const state = yield select();
    const { backendParams } = state;
    const url = apiPathFactory.ownersGetSingleList(
      backendParams.pagesize,
      backendParams.pagenumber[GDPR_LIST],
      string
    );
    const response = yield call(() => fetchEntitySaga(getOwnersSingleList, api.get, url));
    return response;
  } catch (error) {
    return error;
  }
}

export function* getOwnersPendingListSaga({ string }) {
  try {
    const state = yield select();
    const { backendParams } = state;
    const pageSize = getCookiePageSizeItems() || backendParams.pageSize;
    const url = apiPathFactory.ownersGetPendingList(
      pageSize,
      backendParams.pagenumber[GDPR_LIST_PENDING],
      string
    );
    const response = yield call(() => fetchEntitySaga(getOwnersPendingList, api.get, url));
    return response;
  } catch (error) {
    return error;
  }
}

export function* delOwnerSaga({ email }) {
  try {
    const url = apiPathFactory.ownersDelete(email);
    const response = yield call(() => fetchEntitySaga(delOwner, api.del, url));
    yield call(getOwnersSingleListSaga, {});
    return response;
  } catch (error) {
    return error;
  }
}

export function* postOwnerSaga({ gdprAgreement }) {
  try {
    const url = apiPathFactory.ownersPostGDPR(gdprAgreement.userName);
    const response = yield call(() =>
      fetchEntitySaga(postOwnerGDPR, api.post, url, { data: gdprAgreement })
    );
    return response;
  } catch (error) {
    return error;
  }
}

function* watchGetOwnersLogListSaga() {
  yield takeLatest(OWNERS_LOGS_LIST.BASE, getOwnersLogListSaga);
}

function* watchGetOwnersSingleList() {
  yield takeLatest(OWNERS_SINGLELIST_GET.BASE, getOwnersSingleListSaga);
}

function* watchDelOwners() {
  yield takeLatest(OWNERS_DELETE.BASE, delOwnerSaga);
}

function* watchGetOwnersPendingList() {
  yield takeLatest(OWNERS_PENDING_LIST_GET.BASE, getOwnersPendingListSaga);
}

function* watchPostOwners() {
  yield takeLatest(OWNERS_GDPR_POST.BASE, postOwnerSaga);
}

export default function* watchers() {
  yield fork(watchGetOwnersSingleList);
  yield fork(watchDelOwners);
  yield fork(watchGetOwnersPendingList);
  yield fork(watchPostOwners);
  yield fork(watchGetOwnersLogListSaga);
}
