import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BoxMifare from '../../../../components/Facility/Group/Mifare/Box';
import { idGetter, SELECTED_FIELD } from '../../../../components/Facility/Group/Users/config';
import {
  copyGroupMifare,
  deleteGroupMifare,
  deleteGroupMifareList,
  getGroupMifareList,
  importGroupMifare,
  moveGroupMifare,
  updateGroupMifare,
} from '../../../../modules/group/mifare/actions';
import { getFamilyListByType, getProduct } from '../../../../core/old_common/utils/familiesService';
import { getFamiliesSingleList } from '../../../../modules/productFamilies/action';
import {
  CUSTOM_ATTRIBUTES_KEY,
  PRODUCT_FAMILY_TYPE,
} from '../../../../components/Facility/Group/Mifare/config';

const FacilityGroupMifareList = () => {
  const { id: idGroup, instalationId: idFacility } = useParams();
  const { list: mifareList, customFields } = useSelector((state) => state.mifare);
  const { fobsCount } = useSelector((state) => state.instalationGroups);
  const { familiesSingleList } = useSelector((state) => state.families);
  const [data, setData] = useState(mifareList);
  const [changes, setChanges] = useState(false);
  const [fobToDelete, setFobToDelete] = useState(null);
  const [selectedState, setSelectedState] = useState({});
  const [selectedCountState, setSelectedCountState] = useState(0);
  const [isOpenModalDeleteMultiMifare, setIsOpenModalDeleteMultiMifare] = useState(false);
  const [isOpenModalMoveMultiMifare, setIsOpenModalMoveMultiMifare] = useState(false);
  const [isOpenModalCopyMultiMifare, setIsOpenModalCopyMultiMifare] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getGroupMifareList({ idFacility, idGroup }));
    if (!familiesSingleList[4]) {
      dispatch(getFamiliesSingleList(4, true));
    }
  }, []);

  useEffect(() => {
    if (mifareList) {
      setData(
        mifareList.map((dataItem) => ({
          selected: false,
          ...dataItem,
        }))
      );
    }
  }, [mifareList]);

  const onBlurSaveTable = (dataFob) => {
    if (changes) {
      dispatch(updateGroupMifare({ idFacility, idGroup, idFob: dataFob?.id, data: dataFob }));
    }
  };

  const deleteMifare = (idFob) => {
    dispatch(deleteGroupMifare({ idFacility, idGroup, idFob }));
    setFobToDelete(null);
  };

  const deleteMultipleMifare = () => {
    const idFobsList = [];
    for (const key in selectedState) {
      if (selectedState[key]) {
        idFobsList.push(key);
      }
    }
    dispatch(deleteGroupMifareList({ idFacility, idGroup, list: idFobsList }));
    setIsOpenModalDeleteMultiMifare(false);
    setSelectedCountState(0);
    setSelectedState({});
  };

  const moveMultipleMifare = (idFacilityTo, idGroupTo) => {
    const fobList = [];
    for (const key in selectedState) {
      if (selectedState[key]) {
        fobList.push(data.find((item) => item.id === key).id);
      }
    }

    dispatch(moveGroupMifare({ idFacility, idGroup, idFacilityTo, idGroupTo, list: fobList }));
    setIsOpenModalMoveMultiMifare(false);
    setSelectedCountState(0);
    setSelectedState({});
  };

  const copyMultiMifare = (idFacilityTo, idGroupTo) => {
    const fobList = [];
    for (const key in selectedState) {
      if (selectedState[key]) {
        fobList.push(data.find((item) => item.id === key).id);
      }
    }
    dispatch(copyGroupMifare({ idFacility, idGroup, idFacilityTo, idGroupTo, list: fobList }));
    setIsOpenModalCopyMultiMifare(false);
  };

  const importExcelMifare = (file) => dispatch(importGroupMifare({ idFacility, idGroup, file }));

  const productFamily = getFamilyListByType(familiesSingleList, PRODUCT_FAMILY_TYPE);

  return (
    <BoxMifare
      items={
        data?.map((item) => {
          let type;
          if (productFamily) {
            type = getProduct(productFamily, item?.productId)?.name;
          }
          const customFieldsData = customFields.map((custom) => ({
            [custom.tag]: item[CUSTOM_ATTRIBUTES_KEY].find((element) => element.tag === custom.tag)
              ?.value,
            [`${custom.tag}Id`]: item[CUSTOM_ATTRIBUTES_KEY].find(
              (element) => element.tag === custom.tag
            )?.id,
          }));
          const customData = {};
          customFieldsData.forEach((element) => {
            for (const elementKey in element) {
              customData[elementKey] = element[elementKey];
            }
          });

          return {
            ...item,
            type,
            [SELECTED_FIELD]: selectedState[idGetter(item)],
            ...customData,
          };
        }) || []
      }
      counter={
        fobsCount?.find((counter) => counter.schema === 'Fobs Availability')?.counters[0] || {}
      }
      setData={setData}
      setChanges={setChanges}
      onBlurSaveTable={onBlurSaveTable}
      setFobToDelete={setFobToDelete}
      fobToDelete={fobToDelete}
      onDeleteMifare={deleteMifare}
      onDeleteMultiMifare={deleteMultipleMifare}
      onMoveMultiMifare={moveMultipleMifare}
      onCopyMultiMifare={copyMultiMifare}
      onImportExcelMifare={importExcelMifare}
      onSelectedState={setSelectedState}
      selectedState={selectedState}
      selectedCountState={selectedCountState}
      setSelectedCountState={setSelectedCountState}
      isOpenModalDeleteMultiMifare={isOpenModalDeleteMultiMifare}
      setIsOpenModalDeleteMultiMifare={setIsOpenModalDeleteMultiMifare}
      isOpenModalMoveMultiMifare={isOpenModalMoveMultiMifare}
      setIsOpenModalMoveMultiMifare={setIsOpenModalMoveMultiMifare}
      isOpenModalCopyMultiMifare={isOpenModalCopyMultiMifare}
      setIsOpenModalCopyMultiMifare={setIsOpenModalCopyMultiMifare}
    />
  );
};

export default FacilityGroupMifareList;
