import React, { Component } from 'react';
import { connect } from 'react-redux';
import fields from './FieldService';
import './Field.css';

export class Field extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFocused: false,
    };
  }

  onFocus = () => {
    const { isFocused } = this.state;
    this.setState({ isFocused: !isFocused });
  };

  onBlur = (e) => {
    this.onFocus();
    if (this.props.onBlur) {
      this.props.onBlur(e);
    }
  };

  render() {
    const { type } = this.props;
    const field = fields[type] || fields.input;
    return (
      <>
        {field({
          ...this.props,
          onFocus: () => this.onFocus(),
          onBlur: this.onBlur,
          isFocused: this.state.isFocused,
        })}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  tenants: state.tenants,
});

export default connect(mapStateToProps, {})(Field);
