import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import {
  createUniversalGroup,
  setUniversalGroup,
} from '../../modules/old_to_refact/actions/universalGroups';
import { getCookieCustomerId } from '../../core/old_common/utils/cookiesManager';
import { CreationPath_UG, RootPath_UG } from '../../core/old_common/constats/genericListPaths';
import IndividualGrowGenericForm from '../IndividualGrowGenericForm/IndividualGrowGenericForm';

import './IndividualUniversalGroupForm.css';
import validationsDictionary from '../../core/old_common/validations';
import { setOnFlyErrors } from '../../core/old_common/validations/service';
import apiPathFactory from '../../core/old_common/rooting/apiPathFactory';
import getOnFlyValidations from '../../core/old_common/utils/validationsApi';
import { getCustomerInstallerCode } from '../../modules/customers/actions';

export class IndividualUniversalGroupForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formErrors: [],
      onFlyFormErrors: [],
    };
  }

  createNewState = (props) => ({
    customerId: props.customerId || getCookieCustomerId(),
    customerName: props.customerName || '',
    description: props.description || '',
    id: props.id || '',
    installerCode: this.getCheckValue(props),
    name: props.name || '',
    universalsCount: props.universalsCount || 0,
  });

  componentDidMount = () => {
    this.props.getCustomerInstallerCode();
  };

  componentDidUpdate() {
    const { roleMask, availableSpots, history } = this.props;
    if (roleMask === 512 && !availableSpots) {
      history.goBack();
    }
  }

  getCheckValue = (props) => {
    if (this.props.location.pathname === '/UniversalGroups/UniversalGroup') {
      return this.props.installerCodeDefined.useInstallerCode;
    }
    return props.installerCode === undefined ? false : props.installerCode;
  };

  handleBlur = (e) => {
    if (this.props[e.target.id] !== e.target.defaultValue) {
      this.executeOnFlyValidations(
        e.target.id,
        apiPathFactory.universalGroupsGetCheckContent(e.target.id, e.target.defaultValue)
      );
    } else {
      this.setErrorsCallback({
        onFlyFormErrors: this.state.onFlyFormErrors,
        error: undefined,
        id: e.target.id,
      });
    }
  };

  executeOnFlyValidations = (inputId, endPoint) => {
    const { t } = this.props;
    getOnFlyValidations(endPoint, (isError, response) => {
      this.setErrorsCallback({
        onFlyFormErrors: this.state.onFlyFormErrors,
        error: isError,
        id: inputId,
        errorMessage: isError ? t(`errors.${response?.error?.code}`) : '',
      });
    });
  };

  setErrorsCallback = ({ onFlyFormErrors, error, id, errorMessage }) => {
    this.setState({
      onFlyFormErrors: [...setOnFlyErrors({ onFlyFormErrors, error, id, errorMessage })],
    });
  };

  getFields = (newFields) => {
    const { t } = this.props;
    const fields = [
      [
        {
          type: 'name',
          placeHolder: `${t('form.name')} *`,
          value: 'name',
          id: 'name',
          onBlur: this.handleBlur,
          validation: [
            // () => validationsDictionary['regEx'](newFields.name, '[a-zA-Z0-9-+]+'),
            () => validationsDictionary.isInTextLength(newFields.name, 255),
            () => validationsDictionary.isRequired(newFields.name),
            () => validationsDictionary.isNotCreationName(newFields.name, 'UNIVERSALGROUP'),
          ],
        },
        {
          type: 'description',
          placeHolder: t('universalGroups.description'),
          value: 'description',
          id: 'description',
          validation: [() => validationsDictionary.isInTextLength(newFields.description, 255)],
        },
      ],
      [
        {
          type: 'switchCheckbox',
          placeHolder: t('updateInstalationForm.installerCode'),
          value: 'installerCode',
          isChecked: 'installerCode',
          id: 'installerCode',
        },
      ],
    ];
    return fields;
  };

  render() {
    const { t, setUniversalGroup, createUniversalGroup, universalGroup } = this.props;
    return (
      <div className='form'>
        <IndividualGrowGenericForm
          item={this.createNewState(this.props)}
          updateItem={setUniversalGroup}
          createItem={createUniversalGroup}
          baseURI={RootPath_UG}
          goToIdRedirect={universalGroup?.id}
          creationURI={CreationPath_UG}
          fields={(newFields) => this.getFields(newFields)}
          onFlyformErrors={this.state.onFlyFormErrors}
          submitText={t('general.save')}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  tenants: state.tenants,
  availableSpots: state.universalGroups.availableSpots,
  installerCodeDefined: state.customers.installerCode,
  universalGroup: state.universalGroups.universalGroup,
  roleMask: state.user.user.roleMask,
});

const individualUniversalGroupFormWithTranslation = withTranslation()(IndividualUniversalGroupForm);
const individualUniversalGroupFormWithRouter = withRouter(
  individualUniversalGroupFormWithTranslation
);

export default connect(mapStateToProps, {
  setUniversalGroup,
  createUniversalGroup,
  getCustomerInstallerCode,
})(individualUniversalGroupFormWithRouter);
