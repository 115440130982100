import {
  ADD_NOTIFICATION,
  DELETE_NOTIFICATION,
  GET_NOTIFICATIONS,
  RESET_NOTIFICATIONS,
} from './constants';

export const getNotifications = () => ({
  type: GET_NOTIFICATIONS.BASE,
});

export const clearNotification = (id) => ({
  type: DELETE_NOTIFICATION.BASE,
  id,
});
export const resetNotifications = () => ({
  type: RESET_NOTIFICATIONS.BASE,
});
export const addNotification = (noti) => ({
  type: ADD_NOTIFICATION,
  noti,
});
