import React, { Component } from 'react';
import { connect } from 'react-redux';
import { generatePath, withRouter } from 'react-router-dom';
import { Button } from '@jcm-technologies/uikit/dist/atoms/Button';
import { Flex } from '@jcm-technologies/uikit/dist/atoms/Layout';
import InstalationRelayContent from './components/InstalationRelayContent';
import { resetInstalationsRelays } from '../../modules/old_to_refact/actions/instalations';

import './Relays.css';
import { withTranslation } from 'react-i18next';
import { ContentContainer } from '../ContentContainer/ContentContainer';
import {
  resetUGRelays,
  resetUniversalGroup,
} from '../../modules/old_to_refact/actions/universalGroups';

class Relays extends Component {
  constructor(props) {
    super(props);
    this.state = {
      areRelaysRequested: false,
      secondaryItemRequested: false,
      primaryItemRequested: false,
    };
  }

  componentDidMount = () => {
    this.getInstalationsRelays();
  };

  componentDidUpdate = () => {
    this.getInstalationsRelays();
  };

  componentWillUnmount = () => {
    const { resetInstalationsRelays, resetUGRelays, resetUniversalGroup } = this.props;
    resetInstalationsRelays();
    resetUGRelays();
    resetUniversalGroup();
  };

  getInstalationsRelays = () => {
    const {
      principalItem,
      secondaryItem,
      getSecondaryItem,
      getPrincipalItem,
      getRelays,
      relays,
      sessionExpired,
    } = this.props;
    if (!sessionExpired) {
      if (!this.state.primaryItemRequested) {
        getPrincipalItem();
        this.setState({ primaryItemRequested: true });
      }
      if (principalItem && !this.state.secondaryItemRequested) {
        getSecondaryItem();
        this.setState({ secondaryItemRequested: true });
      } else if (
        principalItem &&
        secondaryItem &&
        (!relays || relays?.length === 0) &&
        !this.state.areRelaysRequested
      ) {
        getRelays(principalItem.id, secondaryItem.id);
        this.setState({ areRelaysRequested: true });
      }
    }
  };

  getRelay = () => {
    const {
      principalItem,
      secondaryItem,
      relays,
      principalParameter,
      secondaryParameter,
      putRelay,
    } = this.props;
    const principalId = principalItem ? principalItem.id : '';
    const secondaryId = secondaryItem ? secondaryItem.id : '';
    let relaysList = [];

    if (relays) {
      relaysList = relays.map((relay) =>
        // TODO: Cambiar nombre del componeente a uno especifico de instlaciones
        !relay?.warnings?.length ? (
          <InstalationRelayContent
            className='individual-relay-content'
            key={relay.name}
            relay={relay}
            principalId={principalId}
            secondaryId={secondaryId}
            principalParameter={principalParameter}
            secondaryParameter={secondaryParameter}
            secondaryItem={secondaryItem}
            principalItem={principalItem}
            putRelay={putRelay}
            instalationsGroupRelays={relays}
          />
        ) : (
          relay?.warnings?.map((warn) =>
            warn.code !== 91069 ? (
              <InstalationRelayContent
                className='individual-relay-content'
                key={relay.name}
                relay={relay}
                principalId={principalId}
                secondaryId={secondaryId}
                principalParameter={principalParameter}
                secondaryParameter={secondaryParameter}
                secondaryItem={secondaryItem}
                principalItem={principalItem}
                putRelay={putRelay}
                instalationsGroupRelays={relays}
              />
            ) : null
          )
        )
      );
    }
    return relaysList;
  };

  getErrors = () => {
    const { relaysWarnings } = this.props;
    if (relaysWarnings) {
      const errors = relaysWarnings.map((warning, i) => (
        <span key={i} className='relays-error-message'>
          {warning.message}
        </span>
      ));
      return errors;
    }
  };

  redirectToBack = () => {
    const { match, history, goFacilities, urlParam1, urlParam2, facilityGroup } = this.props;
    const { params, path } = match;

    if (goFacilities) {
      history.push(`${urlParam1}/${urlParam2}`);
    } else if (match.params.id && match.params.universalGroupId) {
      history.goBack();
    } else {
      const urlToRedirect = path.replace(/\/Configuration$/, '');
      const decoded = generatePath(urlToRedirect, {
        instalationId: match.params.instalationId,
        instalationGroupId: facilityGroup?.id,
      });
      history.push(`${decoded}`);
    }
  };

  render() {
    const { t, facilityGroup, canShowBackButton, match, tenants } = this.props;
    return (
      <>
        <ContentContainer title={facilityGroup?.name} tenants={tenants}>
          <div className='relays-fader'>
            <div className='relays-content'>{this.getRelay()}</div>
            {this.getErrors()}
          </div>
        </ContentContainer>
        {canShowBackButton ? (
          <Flex justifyContent='flex-end' paddingBottom={3} paddingRight={3}>
            <Button
              backgroundColor='blackLight'
              sizeButton='small'
              onClick={() => this.redirectToBack()}
            >
              {t('relaysConfiguration.goBack')}
            </Button>
          </Flex>
        ) : null}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  tenants: state.tenants,
  sessionExpired: state.popup.isOpenAuth,
  facilityGroup: state.instalationGroups.facilityGroup,
  needCallUG: state.universalGroups.needCallUG,
  needCallFacility: state.instalations.needCallFacility,
  needCallInstalationGroups: state.instalationGroups.needCallInstalationGroups,
  goFacilities: state.instalations.goFacilities,
  urlParam1: state.instalations.urlParam1,
  urlParam2: state.instalations.urlParam2,
});

const relays = withRouter(Relays);

const relaysWithTranslation = withTranslation()(relays);

export default connect(mapStateToProps, {
  resetInstalationsRelays,
  resetUGRelays,
  resetUniversalGroup,
})(relaysWithTranslation);
