import { useEffect, useRef, useState } from 'react';
import { Grid as TableGrid, GridColumn } from '@progress/kendo-react-grid';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { filterBy, orderBy } from '@progress/kendo-data-query';
import { CustomFilter } from '../../../common/Table/renderers';

const TableHistory = ({ data, lastSearchValue }) => {
  const { t } = useTranslation();
  const exportRef = useRef();
  const tableRef = useRef();
  const {
    user: { pageItems },
  } = useSelector((state) => state.user);
  const [page, setPage] = useState({
    skip: 0,
    take: pageItems || 15,
  });
  const [sort, setSort] = useState([]);
  const [filter, setFilter] = useState({
    logic: 'and',
    filters: [
      {
        field: 'email',
        operator: 'contains',
        value: '',
      },
    ],
  });
  const [dataLoaded, setDataLoaded] = useState(data || []);

  const pageChange = (event) => {
    setPage(event.page);
  };

  const sortChange = (event) => {
    setSort(event.sort);
    setDataLoaded(filterBy(orderBy(data, event.sort), filter));
    setPage({
      ...page,
      skip: 0,
    });
  };

  const filterChange = (event) => {
    setFilter(event.filter);
    setDataLoaded(filterBy(orderBy(data, sort), event.filter));
    setPage({
      ...page,
      skip: 0,
    });
  };

  const exportExcel = (e) => {
    e.preventDefault();
    e.stopPropagation();
    exportRef?.current?.save();
  };

  useEffect(() => {
    setDataLoaded(data);
  }, [data]);

  useEffect(() => {
    if (lastSearchValue) {
      filterChange({
        filter: {
          logic: 'and',
          filters: [
            {
              field: 'email',
              operator: 'contains',
              value: lastSearchValue,
            },
          ],
        },
      });
    }
  }, [lastSearchValue]);

  return (
    <TableGrid
      ref={tableRef}
      data={dataLoaded.slice(page.skip, page.take + page.skip)}
      skip={page.skip}
      take={page.take}
      total={dataLoaded.length}
      pageable
      onPageChange={pageChange}
      filterable
      onFilterChange={filterChange}
      filter={filter}
      filterOperators={{
        text: [{ text: 'grid.filterContainsOperator', operator: 'contains' }],
      }}
      sortable
      onSortChange={sortChange}
      sort={sort}
    >
      <GridColumn field='date' title={t('form.date')} filterCell={CustomFilter} />
      <GridColumn field='action' title={t('form.action')} filter='text' filterCell={CustomFilter} />
      <GridColumn field='type' title={t('form.type')} filter='text' filterCell={CustomFilter} />
      <GridColumn field='name' title={t('form.id')} filter='text' filterCell={CustomFilter} />
      <GridColumn field='info' title={t('form.info')} filter='text' filterCell={CustomFilter} />
      <GridColumn field='email' title={t('title.user')} filter='text' filterCell={CustomFilter} />
    </TableGrid>
  );
};

export default TableHistory;

TableHistory.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      dateTime: PropTypes.string,
      user: PropTypes.string,
    })
  ).isRequired,
  lastSearchValue: PropTypes.string,
};

TableHistory.defaultProps = {
  lastSearchValue: '',
};
