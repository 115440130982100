export default {
  paramsName: {
    A5KPROXY: 'Configure proxy A5K',
    ACTIVATIONKEY: 'Clave de activación',
    ACTIVEELEMENTS: 'Eventos activos',
    ACTIVEEVENTSTOSD: 'Configurar eventos A5K',
    ANTIPANICCONFIGRELE1: 'Configuración Relé 1 (Impulsional / Biestable / Antipánico)',
    ANTIPANICCONFIGRELE2: 'Configuración Relé 2 (Impulsional / Biestable / Antipánico)',
    ANTIPANICCONFIGRELE3: 'Configuración Relé 3 (Impulsional / Biestable / Antipánico)',
    ANTIPANICCONFIGRELE4: 'Configuración Relé 4 (Impulsional / Biestable / Antipánico)',
    APBKMODE: 'Modo Anti-Passback (APB)',
    APBKTIMEBACK: 'Tiempo de reset Anti-Passback en minutos (Anti-timeback)',
    APNCONFIG: 'Configuración APN',
    BADGE_MAITRE01: 'Código maestro 1',
    BADGE_MAITRE10: 'Código maestro 10',
    BADGE_MAITRE2: 'Código maestro 2',
    BADGE_MAITRE3: 'Código maestro 3',
    BADGE_MAITRE4: 'Código maestro 4',
    BADGE_MAITRE5: 'Código maestro 5',
    BADGE_MAITRE6: 'Código maestro 6',
    BADGE_MAITRE7: 'Código maestro 7',
    BADGE_MAITRE8: 'Código maestro 8',
    BADGE_MAITRE9: 'Código maestro 9',
    CONFIGRELAYSNEW1: 'Relés activos en la central mediante canal 1',
    CONFIGRELAYSNEW2: 'Relés activos en la central mediante canal 2',
    CONFIGRELAYSNEW3: 'Relés activos en la central mediante canal 3',
    CONFIGRELAYSNEW4: 'Relés activos en la central mediante canal 4',
    CONFIGRELAYSPIN1: 'Relés en programación PIN. Canal:1',
    CONFIGRELAYSPIN2: 'Relés en programación PIN. Canal:2',
    CONFIGRELAYSPIN3: 'Relés en programación PIN. Canal:3',
    CONFIGRELAYSPIN4: 'Relés en programación PIN. Canal:4',
    CONFIGRELE1: 'Configuración Relé 1 (Impulsional / Biestable)',
    CONFIGRELE2: 'Configuración Relé 2 (Impulsional / Biestable)',
    CONFIGRELE3: 'Configuración Relé 3 (Impulsional / Biestable)',
    CONFIGRELE4: 'Configuración Relé 4 (Impulsional / Biestable)',
    CONFIGRELE_ANTIPANIC: 'Antipánico',
    CONNECTIVITY_EXTENSION: 'Accesorio LINK instalado',
    Configuration_Params_BUTT_NC: 'Pulsador normalmente cerrado',
    Configuration_Params_BUTT_NO: 'Pulsador normalmente abierto',
    Configuration_Params_ConfigRele_Antipanic: 'Antipanic',
    Configuration_Params_ConfigRele_Biestable: 'Biestable',
    Configuration_Params_ConfigRele_Impulsional: 'Impulsional',
    Configuration_Params_D2_HSCLS: '[Puerta 2] Sensor de final de carrera de cierre',
    Configuration_Params_D2_HSOLS: '[Puerta 2] Sensor de final de carrera de apertura',
    Configuration_Params_D2_NCCLS: '[Puerta 2] Final de carrera cierre normalmente cerrado',
    Configuration_Params_D2_NCOLS: '[Puerta 2] Final de carrera apertura normalmente cerrado',
    Configuration_Params_D2_NOCLS: '[Puerta 2] Final de carrera cierre normalmente abierto',
    Configuration_Params_D2_NOOLS: '[Puerta 2] Final de carrera apertura normalmente abierto',
    Configuration_Params_DEBUG: 'Depuración',
    Configuration_Params_GO: 'GO',
    Configuration_Params_GOPRO: 'GO-PRO',
    Configuration_Params_HANDSFREEALLOWED: 'HONOA Permitir manos libres Bluetooth (BETA)',
    Configuration_Params_HFADVANCE: 'HF-ADVANCE',
    Configuration_Params_HIGH: 'Alto',
    Configuration_Params_HSCLS: '[Puerta 1] Sensor de final de carrera de cierre',
    Configuration_Params_HSOLS: '[Puerta 1] Sensor de final de carrera de apertura',
    Configuration_Params_LOW: 'Bajo',
    Configuration_Params_MIN: 'Mínimo',
    Configuration_Params_MODE1: 'Modo 1 (monocanal entrada/salida, salida libre)',
    Configuration_Params_MODE2: 'Modo 2 (monocanal entrada / salida)',
    Configuration_Params_MODE3: 'Modo 3 (canal 1 entrada /canal 2 salida)',
    Configuration_Params_MODE4: 'Modo 4 (canal 1 y 3 entrada / canal 2 y 4 salida)',
    Configuration_Params_NCCLS: '[Puerta 1] Final de carrera de cierre normalmente cerrado',
    Configuration_Params_NCOLS: '[Puerta 1] Final de carrera apertura normalmente cerrado',
    Configuration_Params_NCSC: 'Cadena de seguridad normalmente cerrada',
    Configuration_Params_NOCLS: '[Puerta 1] Final de carrera de cierre normalmente abierto',
    Configuration_Params_NOOLS: '[Puerta 1] Final de carrera de apertura normalmente abierto',
    Configuration_Params_NOSC: 'Cadena de seguridad normalmente abierta',
    Configuration_Params_NSCLS: '[Puerta 1] Sensor de final de carrera de apertura',
    Configuration_Params_NSOLS: '[Puerta 1] Sensor de final de carrera de cierre',
    Configuration_Params_No: 'No',
    Configuration_Params_NoFixed: 'No fijado',
    Configuration_Params_OPENREMOTE: 'HONOA Permitir apertura remota',
    Configuration_Params_PIN: 'PIN',
    Configuration_Params_PINCADUCAT: 'PIN caducado',
    Configuration_Params_UNUSED: 'No usado',
    Configuration_Params_ValueTEVENTS: 'Si',
    Configuration_Params_W26FORMAT0: 'Código (16 bits) + Sitecode (8 bits)',
    Configuration_Params_W26FORMAT1: 'Código (24 bits)',
    Configuration_Params_Yes: 'Si',
    Configuration_Params_entranceNC: 'APB entrada normalmente cerrado',
    Configuration_Params_entranceNO: 'APB entrada normalmente abierto',
    Configuration_Params_exitNC: 'APB salida normalmente cerrado',
    Configuration_Params_exitNO: 'APB salida normalmente abierto',
    DATAUSAGE: 'Uso de datos',
    DISABLEMANUALPROG: 'Prohibir la programación manual',
    DISABLERADIOPROG: 'Prohibir la programación por radio',
    DOOROPENEDEVENTTIME: 'Evento de apertura de puerta',
    EMMITTERNOTUSEDEVENTTIME: 'No utilizar el evento tiempo del emisor',
    FREEACTIVE: 'Grupo del Sistema FREE activo',
    FREEGROUP0LOCKED: 'Grupo 0 del Sistema FREE bloqueado',
    FREEGROUPNUM: 'Número del grupo del Sistema FREE',
    FREEGROUPRFPROGALLOWED: 'Programación vía radio para grupo del Sistema FREE permitido',
    GO: 'Go',
    GOPRO: 'Go-Pro',
    HANDSFREEALLOWED: 'HONOA Permitir manos libres Bluetooth (BETA)',
    HFADVANCE: 'HF-ADVANCE',
    IMPBIRELE1: 'Configurar el relé 1 como biestable',
    INPUT10KEY: 'Clave sensor radio 10',
    INPUT10MODE: 'Modo sensor radio 10',
    INPUT10NAME: 'Nombre sensor radio 10',
    INPUT1KEY: 'Clave entrada 1',
    INPUT1MODE: 'Modo entrada 1',
    INPUT1NAME: 'Nombre entrada 1',
    INPUT2KEY: 'Clave entrada 2',
    INPUT2MODE: 'Modo entrada 2',
    INPUT2NAME: 'Nombre entrada 2',
    INPUT3KEY: 'Clave sensor radio 3',
    INPUT3MODE: 'Modo sensor radio 3',
    INPUT3NAME: 'Nombre sensor radio 3',
    INPUT4KEY: 'Clave sensor radio 4',
    INPUT4MODE: 'Modo sensor radio 4',
    INPUT4NAME: 'Nombre sensor radio 4',
    INPUT5KEY: 'Clave sensor radio 5',
    INPUT5MODE: 'Modo sensor radio 5',
    INPUT5NAME: 'Nombre sensor radio 5',
    INPUT6KEY: 'Clave sensor radio 6',
    INPUT6MODE: 'Modo sensor radio 6',
    INPUT6NAME: 'Nombre sensor radio 6',
    INPUT7KEY: 'Clave sensor radio 7',
    INPUT7MODE: 'Modo sensor radio 7',
    INPUT7NAME: 'Nombre sensor radio 7',
    INPUT8KEY: 'Clave sensor radio 8',
    INPUT8MODE: 'Modo sensor radio 8',
    INPUT8NAME: 'Nombre sensor radio 8',
    INPUT9KEY: 'Clave sensor radio 9',
    INPUT9MODE: 'Modo sensor radio 9',
    INPUT9NAME: 'Nombre sensor radio 9',
    INPUTS: 'Entradas',
    INSTAL: 'Instalador',
    MINIDDEVICE: 'Dispositivo mínimo',
    MessageBox_ActivationRelay: 'Activación de relé.',
    MessageBox_ConnectionError: 'Error de conexión.',
    MessageBox_DeviceAccepted: 'Dispositivo aceptado.',
    MessageBox_DeviceRecieved: 'Dispositivo recibido.',
    MessageBox_FormatMemoryError: 'Memoria con formato erróneo.',
    MessageBox_InvalidDevice: 'Dispositivo no válido.',
    MessageBox_MemoryExtracted: 'Memoria extraída.',
    MessageBox_MemoryInserted: 'Memoria insertada.',
    MessageBox_Message: 'Configurar Eventos activos:',
    MessageBox_NotInMemory: 'No presente en memoria.',
    MessageBox_OutOfSchedule: 'Fuera de horario.',
    MessageBox_PowerUp: 'Encendido.',
    MessageBox_RelayState: 'Cambio de estado del relé.',
    Name: 'Nombre',
    OPENREMOTE: 'HONOA Permitir apertura remota',
    PASSWORD: 'Password',
    PIN: 'PIN',
    PINCADUCAT: 'PIN caducado',
    POWER: 'Alimentación',
    PRICE1: 'Valor de descuento del relé 1',
    PRICE2: 'Valor de descuento del relé 2',
    PRICE3: 'Valor de descuento del relé 3',
    PRICE4: 'Valor de descuento del relé 4',
    Product: 'Dispositivo',
    RELAYPRICE1: 'Valor de descuento del relé 1',
    RELAYPRICE2: 'Valor de descuento del relé 2',
    RELAYPRICE3: 'Valor de descuento del relé 3',
    RELAYPRICE4: 'Valor de descuento del relé 4',
    RELAYS: 'Relés',
    RELE1IMP: 'Relé 1 configuración (Impulso/Biestable)',
    RELE1TEMP: 'Relé 1 Temporización',
    RELE2IMP: 'Relé 2 configuración (Impulso/Biestable)',
    RELE2TEMP: 'Relé 2 Temporización',
    RELE3IMP: 'Relé 3 configuración (Impulso/Biestable)',
    RELE3TEMP: 'Relé 3 Temporización',
    RELE4IMP: 'Relé 4 configuración (Impulso/Biestable)',
    RELE4TEMP: 'Relé 4 Temporización',
    Relay_Sound: 'Idioma mensaje puerta abierta',
    SCHEDULESDATA: 'Datos horarios',
    SENSORS: 'Sensores',
    SITECODE: 'Sitecode',
    SerialNumber: 'Número de serie',
    Sip_Address: 'Dirección SIP',
    Sip_Status: 'Estado conexión SIP',
    TEMPRELE1: 'Temporización Relé 1',
    TEMPRELE2: 'Temporización Relé 2',
    TEMPRELE3: 'Temporización Relé 3',
    TEMPRELE4: 'Temporización Relé 4',
    TEVENT: 'Tarjeta de eventos',
    TIMEZONE: 'Identificador de zona horaria',
    TIMEZONEID: 'Identificador de zona horaria',
    TMEM: 'Tarjeta de memoria',
    UNIQUEKEY: 'Clave única',
    UniqueIdentity: 'Identificador único',
    W26FORMAT: 'Formato Wiegand 26',
    access_code: 'Código de accesso',
    call_max_duration: 'Máxima duración de la llamada',
    call_outgoing_timeout: 'Fuera de tiempo llamada saliente',
    cell_id: 'CELL ID',
    connection_type: 'Tipo de conexión',
    correctVertexKey: 'El código introducido es válido',
    dbm: 'Decibelios',
    door_english: 'Ingles',
    door_french: 'Frances',
    door_spanish: 'Español',
    false: 'False',
    fw_version: 'Versión de firmware',
    hw_version: 'Versión de hardware',
    iccid: 'Numero ICCID',
    imei: 'Numero IMEI',
    imsi: 'IMSI',
    incorrectVertexKey: 'El código introducido no es válido',
    lac: 'LAC',
    level: 'Nivel de señal',
    mac_address: 'Dirección MAC',
    mcc: 'MCC',
    mic_volume: 'Volumen del micrófono',
    mnc: 'MNC',
    none: 'Ninguno',
    relay_dtmf_hang_call: 'Activación de relé por marcación de tono',
    speaker_volume: 'Volumen del altavoz',
    true: 'Verdadero',
    ACCESSCONTROL: 'Control de acceso',
    WIEGANDCONFIG: 'Configuración Wiegand',
    ALLOWNOTIFICATIONS: 'HONOA Mostrar estado puerta',
    LOCALEVENTMANAGEMENTCONFIG: 'Configuración de evento',
  },
};
