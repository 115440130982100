export default {
  gsmPopup: {
    title: 'Szolgáltatás állapota',
    deviceNotSimInfo: 'A készülék offline állapotban van',
    simContractInfo: 'Szerződés információ',
    expiration: 'Lejárat',
    status: 'Státusz',
    infoNotAvailable: 'Információ nem elérhető',
    simConnectionInfo: 'SIM csatlakozási információ',
    cardIdentifier: 'Kártyaazonosító (ICCID)',
    activationDate: 'Aktiválás dátuma',
    lastMonthDataUpload: 'Utolsó havi adatfeltöltés',
    lastMonthDataDownload: 'Múlt havi adatok letöltése',
    lastSeen: 'Utoljára látott',
    contractStatus: 'Szerződés állapota',
    simStatus: 'SIM státusz',
    deviceStatus: 'Eszköz státusz',
    enabled: 'Engedélyezett',
    disabled: 'Nem engedélyezett',
    notAvailable: 'Nem elérhető',
    connected: 'Csatlakoztatva',
    disconnected: 'Lecsatlakoztatva',
  },
};
