/* eslint-disable react/prop-types */
import React from 'react';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import GenericGrow from '../GenericGrow/GenericGrow';
import './InstallationRecycleGrow.css';
// eslint-disable-next-line camelcase
import { getCookieDateTimeFormat } from '../../core/old_common/utils/cookiesManager';

const InstallationRecycleGrow = ({
  name,
  address,
  city,
  deletedOn,
  accountableUser,
  children,
  item,
  customerId,
  baseURI,
  creationURI,
  handleRestore,
}) => {
  const getHeaderTitles = () => [
    {
      value: name,
      className: 'UG-portlet-header-item UG-header-title',
    },
    {
      value: item ? item.address : address,
      className: 'UG-portlet-header-item',
    },
    {
      value: item ? item.city : city,
      className: 'UG-portlet-header-item',
    },
    {
      value: item
        ? item?.deletedOn || 'yyyy-MM-dd'
        : deletedOn,
      className: 'UG-portlet-header-item',
    },

    {
      value: item ? item?.accountableUser?.email : accountableUser?.email,
      toolTip: `${item?.accountableUser?.name} ${item?.accountableUser?.surname}`,
      className: 'UG-portlet-header-item',
    },
  ];

  return (
    <GenericGrow
      isExpanded={false}
      key={customerId}
      item={item}
      rootPath={baseURI}
      creationURI={creationURI}
      headerTitles={getHeaderTitles()}
      handleRestore={(id) => handleRestore(id)}
      customActionButtons={[]}
      manipulateCloneObject={null}
      canClone={false}
      canExpand={false}
    >
      {children}
    </GenericGrow>
  );
};
const InstallationRecycleGrowComponent = withTranslation()(InstallationRecycleGrow);

export default InstallationRecycleGrowComponent;
