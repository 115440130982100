import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import GenericList from '../GenericList';
import {
  deleteFacilityUniversalGroup,
  getFacilityListByUniversalGroup,
  getUniversalGroup,
  resetFacilities,
  resetUniversalGroupFacilities,
} from '../../modules/old_to_refact/actions/universalGroups';
import InstalationGrow from '../InstalationGrow/InstalationGrow';
import { objectsAreEquals } from '../../core/old_common/utils/objectsService';
import AddButton from '../AddButton';
import { setFacility } from '../../modules/old_to_refact/actions/instalations';
import { setPageNumber } from '../../modules/state/pagination/actions';

import './UniversalGroupFacilitiesList.css';
import { UG_FACILITIES_LIST } from '../../modules/state/pagination/constants';

export class UniversalGroupFacilitiesList extends Component {
  componentDidMount = () => {
    const { resetFacilities, needUGFacilitesUpdate, match, getUniversalGroup } = this.props;
    const selectedUniversalGroup = this.getSelectedUniversalGroup();
    if (selectedUniversalGroup) {
      this.callToFacilitiesListAction(selectedUniversalGroup);
    } else {
      getUniversalGroup(match.params.id);
    }
    if (needUGFacilitesUpdate) {
      resetFacilities();
    }
  };

  componentDidUpdate = (prevProps) => {
    const { facilities } = this.props;
    const selectedUniversalGroup = this.getSelectedUniversalGroup();
    const prevSelectedUniversalGroup = this.getSelectedUniversalGroup(prevProps);
    if (!objectsAreEquals(prevSelectedUniversalGroup, selectedUniversalGroup) || !facilities) {
      this.callToFacilitiesListAction(selectedUniversalGroup);
    }
  };

  componentWillUnmount = () => {
    this.props.setPageNumber(UG_FACILITIES_LIST, 1);
  };

  getUniversalGroup = () => {
    const selectedUniversalGroup = this.getSelectedUniversalGroup();
    this.callToFacilitiesListAction(selectedUniversalGroup);
  };

  callToFacilitiesListAction = (selectedUniversalGroup) => {
    const { getFacilityListByUniversalGroup, facilities, needFacilitiesByUg } = this.props;
    if (selectedUniversalGroup && needFacilitiesByUg) {
      getFacilityListByUniversalGroup(selectedUniversalGroup.id, false, false);
    }
  };

  getSelectedUniversalGroup = (props = this.props) => {
    const { match, universalGroup, universalGroups } = props;
    if (universalGroup) {
      return universalGroup;
    }
    if (universalGroups) {
      return universalGroups.find(
        (universalGroup) => universalGroup.name === match.params.universalGroupId
      );
    }
    return undefined;
  };

  handleDelete = (facilityId) => {
    const { universalGroupId, deleteFacilityUniversalGroup } = this.props;
    deleteFacilityUniversalGroup(facilityId, universalGroupId);
  };

  onConfigClick = (event) => {
    this.props.setFacility(event.item);
  };

  render() {
    const { facilities, match, tenants, t, history, universalGroupId } = this.props;
    const baseURI = `/UniversalGroups/${encodeURIComponent(match.params.id).replace(
      '%09',
      '%2509'
    )}/Equipments`;
    return (
      <span className='ug-facilities-list'>
        <GenericList
          dataList={facilities}
          CustomGrow={(props) => (
            <InstalationGrow
              {...props}
              fatherDelete={this.handleDelete}
              isConfigVisible
              onConfigClick={this.onConfigClick}
              canExpand={false}
              canClone={false}
              toolTip={t('instalations.config')}
            />
          )}
          baseURI={baseURI}
          creationURI='facility'
          listType={UG_FACILITIES_LIST}
          handlePageChange={this.getUniversalGroup}
          getList={this.getUniversalGroup}
        />
        <AddButton
          id='add'
          tenants={tenants}
          toolTip={t('instalations.add')}
          redirectData={{ universalGroupId }}
          history={history}
          url={`${baseURI}/Facilities`}
        />
      </span>
    );
  }
}

const mapStateToProps = (state) => ({
  tenants: state.tenants,
  facilities: state.universalGroups.facilities,
  needFacilitiesByUg: state.universalGroups.needFacilitiesByUg,
  universalGroups: state.universalGroups.universalGroups,
  universalGroup: state.universalGroups.universalGroup,
  needUGFacilitesUpdate: state.universalGroups.needUGFacilitesUpdate,
  facilitiesTotalPages: state.universalGroups.facilitiesTotalPages,
});

const UniversalGroupFacilitiesListPage = withTranslation()(UniversalGroupFacilitiesList);
const UniversalGroupFacilitiesListPageWithHistory = withRouter(UniversalGroupFacilitiesListPage);

export default connect(mapStateToProps, {
  setPageNumber,
  getFacilityListByUniversalGroup,
  resetFacilities,
  getUniversalGroup,
  deleteFacilityUniversalGroup,
  resetUniversalGroupFacilities,
  setFacility,
})(UniversalGroupFacilitiesListPageWithHistory);
