import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import EnrollCode from '../EnrollCode/EnrollCode';
import apiPathFactory from '../../core/old_common/rooting/apiPathFactory';
import { getUniversalGroup } from '../../modules/old_to_refact/actions/universalGroups';
import {
  getUniversalGroupsFobsCount,
  postUniversalFOB,
  postUniversalSequentialFOB,
} from '../../modules/old_to_refact/actions/universalGroupsFobs';

import { getDecodedUrl } from '../../core/old_common/utils/getUrlParams';

class UniversalGroupEnrollCode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCountersCalled: false,
    };
  }

  componentDidMount = () => {
    const { universalGroup, getUniversalGroupsFobsCount, getUniversalGroup, match } = this.props;
    if (!universalGroup) {
      getUniversalGroup(match.params.id);
    } else {
      getUniversalGroupsFobsCount(universalGroup.id);
    }
  };

  componentDidUpdate() {
    if (!this.state.isCountersCalled && this.getSelectedUGId()) {
      this.setState({ isCountersCalled: true });
      this.getFobsCounters();
    }
  }

  getFobsCounters = () => {
    const { getUniversalGroupsFobsCount } = this.props;
    const ugId = this.getSelectedUGId();
    if (ugId) {
      getUniversalGroupsFobsCount(ugId);
    }
  };

  getSelectedUG = () => {
    const { universalGroup, universalGroups, match } = this.props;
    if (universalGroup) {
      return universalGroup;
    }
    if (universalGroups) {
      return universalGroups.find(
        (universalGroup) => universalGroup.name === getDecodedUrl(match.params.id)
      );
    }
    return undefined;
  };

  getSelectedUGId = () => {
    const selectedUG = this.getSelectedUG();
    return selectedUG ? selectedUG.id : '';
  };

  getFobsCounts = () => {
    const { fobsCount } = this.props;
    if (fobsCount && fobsCount.length) {
      const countersObject = fobsCount.find((counter) => counter.schema === 'Fobs Availability');
      return {
        ...countersObject.counters[0],
      };
    }
    return {
      free: 0,
      busy: 0,
      reserved: 0,
    };
  };

  render() {
    const { postUniversalFOB, postUniversalSequentialFOB, universalGroup } = this.props;
    const universalGroupId = universalGroup ? universalGroup.id : '';
    return (
      <EnrollCode
        isUG
        needInstallerCode={universalGroup ? universalGroup.installerCode : false}
        familyType={2}
        conters={this.getFobsCounts()}
        tabsProps={{
          isUniversalEnroll: true,
          postFOB: (fobData, force) =>
            postUniversalFOB({ ...fobData, universalGroupId, plaza: 0, isUniversal: true }, force),
          postSequentialFOB: (fobData, isReservation) =>
            postUniversalSequentialFOB({
              data: { ...fobData, isUniversal: true, plaza: 0 },
              isReservation,
              universalGroupId,
            }),
          fobsCheckCodesEndpoint: (initialCode, count) =>
            apiPathFactory.universalGroupsFobsGetCheckCodes(universalGroupId, initialCode, count),
          fobsCheckContentEndpoint: (elementId, value) =>
            apiPathFactory.universalGroupsGetCheckContent(universalGroupId, elementId, value),
          hiddenFields: ['plaza'],
          getFobCounters: this.getFobsCounters,
        }}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  universalGroup: state.universalGroups.universalGroup,
  universalGroups: state.universalGroups.universalGroups,
  fobsCount: state.universalGroupsFobs.fobsCount,
});

const UniversalGroupEnrollCodeWithRouter = withRouter(UniversalGroupEnrollCode);

export default connect(mapStateToProps, {
  getUniversalGroup,
  postUniversalFOB,
  postUniversalSequentialFOB,
  getUniversalGroupsFobsCount,
})(UniversalGroupEnrollCodeWithRouter);
