import i18next from 'i18next';
import moment from 'moment-timezone';
import { replaceStringValue } from '../../../core/old_common/utils/replaceStringService';

// Set a function because we need that if language are updated, rerender this translations
export const ColumnsTable = () => [
  {
    key: 'equipmentName',
    name: i18next.t('equipment.name'),
    sortable: true,
    filterable: true,
    tooltip: i18next.t('equipment.name'),
  },
  {
    key: 'datetime',
    name: i18next.t('form.date'),
    sortable: true,
    filterable: true,
    tooltip: i18next.t('form.date'),
  },
  {
    key: 'event',
    name: i18next.t('title.event'),
    sortable: true,
    filterable: true,
    tooltip: i18next.t('title.event'),
  },
  {
    key: 'owner',
    name: i18next.t('title.user'),
    sortable: true,
    filterable: true,
    tooltip: i18next.t('title.user'),
  },
  {
    key: 'code',
    name: i18next.t('form.code'),
    sortable: true,
    filterable: true,
    tooltip: i18next.t('form.code'),
  },
  {
    key: 'group',
    name: i18next.t('title.group'),
    sortable: true,
    filterable: true,
    tooltip: i18next.t('title.group'),
  },
  {
    key: 'info',
    name: i18next.t('form.additionalInfo'),
    sortable: true,
    filterable: true,
    tooltip: i18next.t('form.additionalInfo'),
  },
];

export const infoValueMapped = (code, infoValue) => {
  const translatedInfoLanguageCode = code !== 0 ? i18next.t(`event.status.${code}`) : '';

  return `${translatedInfoLanguageCode}${
    translatedInfoLanguageCode.includes(':') || !translatedInfoLanguageCode ? '' : ''
  } ${infoValue}`;
};

export const fileExcelName = ({ facilityName, event }) =>
  `${moment().format('YYYY-MM-DD')}_${replaceStringValue(
    facilityName?.substring(0, 50),
    'Facility'
  )}_${event}`;

export const rgpdAlertLanguages = ['fr_FR'];
export const rgpdLinkLanguages = {
  fr_FR: 'https://www.jcm-tech.com/fr/legal/cloudassistant-evenements-rgpd',
};
