export default {
  relaysConfiguration: {
    tabRelays: 'Relés',
    tabSchedules: 'Horaris',
    tabInfo: 'Informació',
    time: 'Temps d\'activació relé {{count}}',
    delay: 'Temps de retard del relé {{count}}',
    infoTime: 'El valor ha de ser entre 1 i 60 segons',
    infoDelay: 'El valor ha de ser entre 0 i 10 segons',
    infoCustomOpenDoorTime: 'El valor ha de ser entre 1 i 86.400 segons',
    relayNotassigned: 'Relé no assignat',
    goBack: 'Enrere',
    type: 'Tipus de relé',
    keepDoorOpenSchedule: 'Horari de porta oberta',
    customOpenDoorTimeSchedule: 'Horari de porta oberta personalitzat',
    customOpenDoorTime: 'Temps de porta oberta',
    Relay: 'Relé',
  },
};