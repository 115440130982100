import PathEntity from './PathEntity.js';
import { apiVersion, mainPath } from '../common';

class Equipments extends PathEntity {
  constructor() {
    super('equipments');
  }

  get = (facilityId, include) => {
    this.mainPath = `${mainPath()}4.2`;
    let endpoint = this.manufacturePath(`facilities/${facilityId}/${this.entityName}`);
    endpoint = this.addParameterToEndpoint(endpoint, 'include', include);
    this.mainPath = `${mainPath()}${apiVersion}`;
    return endpoint;
  };

  getEquipmentConnectivity = (facilityId, equipmentId) =>
    this.manufacturePath(`facilities/${facilityId}/${this.entityName}/${equipmentId}/connectivity`);

  getEquipmentVertexConnectivity = (facilityId, equipmentId, key) =>
    this.manufacturePath(`facilities/${facilityId}/equipments/${equipmentId}/onlinestatus/${key}`);

  getEquipmentRelays = (facilityId, equipmentId) => {
    this.mainPath = `${mainPath()}4.1`;
    const endpoint = this.manufacturePath(
      `facilities/${facilityId}/equipments/${equipmentId}/relays`
    );
    this.mainPath = `${mainPath()}${apiVersion}`;
    return endpoint;
  };

  post = (facilityId) => this.manufacturePath(`facilities/${facilityId}/${this.entityName}`);

  put = (facilityId, equipmentId) =>
    this.manufacturePath(`facilities/${facilityId}/${this.entityName}/${equipmentId}/v2`);

  putRelay = (facilityId, equipmentId, relayId) => {
    if (relayId) {
      return this.manufacturePath(
        `facilities/${facilityId}/equipments/${equipmentId}/relays/${relayId}`
      );
    }
    return this.manufacturePath(`facilities/${facilityId}/equipments/${equipmentId}/relays`);
  };

  delete = (facilityId, equipmentId) =>
    this.manufacturePath(`facilities/${facilityId}/${this.entityName}/${equipmentId}`);

  getFobs = (facilityId, equipmentId) =>
    this.manufacturePath(`facilities/${facilityId}/equipments/${equipmentId}/fobs`);

  getActions = (facilityId, equipmentId) =>
    this.manufacturePath(`facilities/${facilityId}/equipments/${equipmentId}/actions`);

  getEquipment = (facilityId, id, include) => {
    this.mainPath = `${mainPath()}4.2`;
    let finalEndpoint = this.manufacturePath(`facilities/${facilityId}/equipments/${id}`);
    finalEndpoint = this.addParameterToEndpoint(finalEndpoint, 'include', include);
    this.mainPath = `${mainPath()}${apiVersion}`;
    return finalEndpoint;
  };

  getEquipmentCounters = (facilityId, equipmentId) => {
    this.mainPath = `${mainPath()}4.3`;
    const endpoint = this.manufacturePath(
      `facilities/${facilityId}/${this.entityName}/${equipmentId}/counters`
    );
    this.mainPath = `${mainPath()}${apiVersion}`;
    return endpoint;
  };

  getWiegandConfiguration = (facilityId, equipmentId) => {
    this.mainPath = `${mainPath()}4.2`;
    const endpoint = this.manufacturePath(
      `/facilities/${facilityId}/equipments/${equipmentId}/wiegandDefaults`
    );
    this.mainPath = `${mainPath()}${apiVersion}`;
    return endpoint;
  };

  getEquipmentsGetCheckContent = (columnName, value, id) => {
    let finalEndpoint = this.manufacturePath(`facilities/${id}/equipments/checkcontent`);
    finalEndpoint = this.addParameterToEndpoint(finalEndpoint, 'columnName', columnName);
    finalEndpoint = this.addParameterToEndpoint(finalEndpoint, 'value', value);
    finalEndpoint = this.addParameterToEndpoint(finalEndpoint, 'ForFacility', true);
    return finalEndpoint;
  };

  putIsUpdated = (facilityId, equipmentId) => {
    const finalEndpoint = this.manufacturePath(
      `facilities/${facilityId}/equipments/${equipmentId}/isupdated`
    );
    return finalEndpoint;
  };

  getEquipmentSim = (facilityId, equipmentId) =>
    this.manufacturePath(`facilities/${facilityId}/${this.entityName}/${equipmentId}/sim`);

  getStatisticsEquipmentAPI = (facilityId, equipmentId, statisticsType) =>
    this.manufacturePath(`facilities/${facilityId}/equipments/${equipmentId}/statistics/type/1`);

  getEvents = (facilityId, equipmentId) => {
    this.mainPath = `${mainPath()}4.1`;
    const endpoint = this.manufacturePath(
      `facilities/${facilityId}/equipments/${equipmentId}/events`
    );
    this.mainPath = `${mainPath()}${apiVersion}`;
    return endpoint;
  };

  deleteEvents = (facilityId, equipmentId) =>
    this.manufacturePath(`facilities/${facilityId}/equipments/${equipmentId}/events`);

  getPaths = () => ({
    equipmentsGet: this.get,
    equipmentGet: this.getEquipment,
    equipmentsDelete: this.delete,
    equipmentPost: this.post,
    equipmentPutIsUpdated: this.putIsUpdated,
    equipmentPut: this.put,
    equipmentRelayPut: this.putRelay,
    equipmentsGetFobs: this.getFobs,
    equipmentsRelaysGet: this.getEquipmentRelays,
    equipmentGetActions: this.getActions,
    equipmentGetConnectivity: this.getEquipmentConnectivity,
    equipmentGetVertexConnectivity: this.getEquipmentVertexConnectivity,
    equipmentsGetCheckContent: this.getEquipmentsGetCheckContent,
    equipmentsGetCounters: this.getEquipmentCounters,
    equipmentGetSim: this.getEquipmentSim,
    getStatisticsEquipment: this.getStatisticsEquipmentAPI,
    equipmentsGetEvents: this.getEvents,
    equipmentsDeleteEvents: this.deleteEvents,
    equipmentWiegandConfiguration: this.getWiegandConfiguration,
  });
}

export default new Equipments().getPaths();
