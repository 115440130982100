export default {
  formValidation: {
    notSameValue: 'Non è lo stesso valore',
    notSamePassword: 'le passwords non corrispondono',
    numericField: 'Questo campo è numerico',
    minNumber: 'Il valore minimo è 1',
    passwordFormat:
      'La password deve contenere almeno otto caratteri, almeno una lettera maiuscola, una minuscola, un numero e un carattere speciale',
    isNotValidDate: 'Formato data errato, formato: AAAA-MM-GGTHH: mm: ss',
    isRequired: 'Campo richiesto',
    outOfRange: 'Numero fuori intervallo',
    maxLength: 'La lunghezza massima dei caratteri è',
    regEx: 'Formato errato. Usa questo personaggio',
    deviceLimit: 'Limite del dispositivo raggiunto',
    onlySpaces: 'Non sono ammessi solo spazi vuoti',
    isReservedWord: 'è una parola riservata',
  },
};
