import IconWarehouse from '@jcm-technologies/uikit/dist/atoms/Icons/IconWarehouse';
import IconUsers from '@jcm-technologies/uikit/dist/atoms/Icons/IconUsers';
import IconCalendarAlt from '@jcm-technologies/uikit/dist/atoms/Icons/IconCalendarAlt';
import IconUser from '@jcm-technologies/uikit/dist/atoms/Icons/IconUser';
import IconCells from '@jcm-technologies/uikit/dist/atoms/Icons/IconCells';
import IconHalfStroke from '@jcm-technologies/uikit/dist/atoms/Icons/IconHalfStroke';
import { getCookieUserRoles } from '../old_common/utils/cookiesManager';

const rolesWithFullAccess = ['Admin', 'Advanced manager', 'Advanced user'];

export const menus = [
  {
    translateKey: 'title.dashboard',
    active: true,
    navigateTo: '/dashboard',
    icon: <IconCells />,
    roles: [
      ...rolesWithFullAccess,
      'Basic user',
      'Basic manager',
      'Property manager',
      'Property manager lite',
      'Admin lite',
    ],
    id: 'dashboard',
  },
  {
    translateKey: 'title.facilities',
    active: true,
    navigateTo: '/Facilities',
    icon: <IconWarehouse />,
    roles: [
      ...rolesWithFullAccess,
      'Basic user',
      'Basic manager',
      'Property manager',
      'Property manager lite',
      'Admin lite',
    ],
    id: 'Facilities',
  },
  {
    translateKey: 'title.schedules',
    active: false,
    navigateTo: '/schedules',
    icon: <IconCalendarAlt />,
    roles: [...rolesWithFullAccess, 'Property manager', 'Property manager lite', 'Admin lite'],
    id: 'schedules',
  },
  {
    translateKey: 'title.universalGroups',
    active: false,
    navigateTo: '/UniversalGroups',
    icon: <IconUsers />,
    roles: [...rolesWithFullAccess, 'Admin lite'],
    id: 'Universal',
  },
  {
    translateKey: 'title.administration',
    active: false,
    navigateTo: '/administration',
    icon: <IconHalfStroke />,
    roles: ['Admin', 'Owner', 'Admin lite'],
    id: 'Administration',
  },
  {
    translateKey: 'title.administration',
    active: false,
    navigateTo: '/administration/honoa',
    icon: <IconHalfStroke />,
    roles: ['Property manager', 'Property manager lite'],
    id: 'Honoa',
  },
  {
    translateKey: 'title.owner',
    active: false,
    navigateTo: '/owner',
    icon: <IconUser />,
    roles: ['Owner'],
    id: 'owner',
  },
];

const actualRole = () => {
  const roleCookie = getCookieUserRoles();
  if (roleCookie !== undefined) {
    return getCookieUserRoles().name;
  }
  return '';
};

export const getMenuList = () => menus.filter((x) => x.roles.includes(actualRole()));
