import superagent from 'superagent';
import { getCookieAccessToken } from '../old_common/utils/cookiesManager';
import store from '../../modules/old_to_refact/stores/configureStore';
import { showDialog } from '../../modules/old_to_refact/actions/loading';

export const getFilesFromApi = (path, callBack, contentType, type) =>
  apiFileCalls({ path, method: 'GET', callBack, contentType: contentType || 'text/plain', type });

export const apiFileCalls = ({
  path,
  callBack,
  data,
  method,
  contentType,
  type,
  attachFiles = [],
  additionalHeaders = [],
}) => {
  new Promise(() => {
    const request = superagent(method, path);
    request.set('Access-Control-Allow-Origin', '*');
    if (contentType) {
      request.set('Content-Type', contentType);
    }
    const accessToken = getCookieAccessToken();
    if (accessToken) {
      request.set('Authorization', `Bearer ${accessToken}`);
    }
    if (data) {
      request.send(data);
    }
    if (type === 'xlsx' || type === 'binary') {
      request.responseType('blob');
    }
    additionalHeaders.forEach((header) => request.set(header.name, header.value));
    attachFiles.forEach((file) => request.attach(file.fieldName, file.file));

    request
      .query()
      .then((res) => {
        store.dispatch(showDialog(-1));
        callBack(false, res);
      })
      .catch((error) => {
        store.dispatch(showDialog(-1));
        if (error.status !== 401) {
          callBack(true, error.response);
        }
      });
  });
};

export const downloadFile = (content, fileName) => {
  let urlrtMP;
  if (content.body && content.body instanceof Blob) {
    urlrtMP = window.URL.createObjectURL(content.body);
  } else {
    urlrtMP = window.URL.createObjectURL(new Blob([content.text ? content.text : content.body]));
  }
  const link = document.createElement('a');
  link.href = urlrtMP;
  link.setAttribute('download', fileName);
  link.click();
};

export const loadFile = ({ callBack, accept, id }) => {
  const input = document.createElement('input');
  input.type = 'file';
  input.onchange = callBack;
  if (accept) {
    input.accept = accept;
  }
  if (id) {
    input.id = id;
  }
  input.click();
};

export default { getFilesFromApi, apiFileCalls, downloadFile, loadFile };
