import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import UniversalGroupsList from '../../old_components/UniversalGroupsList';
import { reactPlugin, appInsights } from '../../core/old_common/utils/appInsights';

export class UniversalGroups extends Component {
  trackPageView = appInsights.trackPageView({ name: 'Listado Universales' });

  render() {
    return <UniversalGroupsList />;
  }
}

const UniversalGroupsPageWithAITracking = withAITracking(reactPlugin, UniversalGroups);
const UniversalGroupsPage = withTranslation()(UniversalGroupsPageWithAITracking);

export default UniversalGroupsPage;
