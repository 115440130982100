export default {
  adjustTimePopUp: {
    manualDateTimeAdjust: 'Manual date and time adjust',
    actualHour: 'Current time:',
    minutesCompensation: 'Minutes compensation from now',
    insertSdMemory: 'Insert SD memory and power the device exactly at the following hour',
    inputTime: 'It shows time',
    save: 'Save',
  },
};
