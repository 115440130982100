export default {
  fobsPopUps: {
    instalations: 'Facilities',
    groups: 'Groups',
    move: 'Move',
    copy: 'Copy',
    remove: 'Delete',
    removeQuestion: 'Do you want to delete the following items?',
    selectedItemsShow1: ' Do you wish to delete ',
    selectedItemsShow2: ' selected elements?',
    warningDeleteFobs:
      'CAUTION: You may have selected more elements than you are seeing on the screen.',
    selectedItemsMove1: 'Do you wish to move the ',
    selectedItemsCopy1: 'Do you wish to copy the ',
    add: 'Add',
    selectedItemsAdd1: 'Do you wish to add the',
  },
};
