import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import { useTranslation } from 'react-i18next';
import IconDownload from '@jcm-technologies/uikit/dist/atoms/Icons/IconDownload';
import { IconTooltip } from '@jcm-technologies/uikit/dist/molecules/IconTooltip';
import PropTypes from 'prop-types';
import React from 'react';
import { fileExcelName } from '../../config';
import { getCookieDateTimeFormat } from '../../../../../core/old_common/utils/cookiesManager';

export const ExcelExportEvents = ({ data, facilityName }) => {
  const { t } = useTranslation();
  let exportExcel;
  return (
    <>
      <IconTooltip
        id='export-log-excel'
        color='grey'
        sizeIcon='display36'
        toolTipContent={t('instalationGroups.exportExcel')}
        onClick={() => exportExcel.save()}
        marginRight={2}
      >
        <IconDownload />
      </IconTooltip>
      <ExcelExport
        fileName={fileExcelName({ facilityName, event: t('title.events') })}
        data={data}
        ref={(exporter) => (exportExcel = exporter)}
      >
        <ExcelExportColumn
          key={t('equipment.name')}
          title={t('equipment.name')}
          field='equipmentName'
          cellOptions={{
            textAlign: 'left',
            background: '#ccc',
          }}
          locked
          headerCellOptions={{ textAlign: 'center', bold: true }}
        />
        <ExcelExportColumn
          key={t('form.date')}
          title={t('form.date')}
          field='datetime'
          cellOptions={{
            background: '#ccc',
            textAlign: 'left',
            wrap: true,
            format: `${getCookieDateTimeFormat()} HH:mm`,
          }}
          locked
          headerCellOptions={{ textAlign: 'center', bold: true }}
        />
        <ExcelExportColumn
          key={t('form.action')}
          title={t('form.action')}
          field='event'
          cellOptions={{ background: '#ccc', wrap: true }}
          locked
          headerCellOptions={{ textAlign: 'center', bold: true }}
        />
        <ExcelExportColumn
          key={t('title.user')}
          title={t('title.user')}
          field='owner'
          cellOptions={{ background: '#ccc', wrap: true }}
          locked
          headerCellOptions={{ textAlign: 'center', bold: true }}
        />
        <ExcelExportColumn
          key={t('form.code')}
          title={t('form.code')}
          field='code'
          cellOptions={{ background: '#ccc', wrap: true }}
          locked
          headerCellOptions={{ textAlign: 'center', bold: true }}
        />
        <ExcelExportColumn
          key={t('title.group')}
          title={t('title.group')}
          field='group'
          cellOptions={{ background: '#ccc', wrap: true }}
          locked
          headerCellOptions={{ textAlign: 'center', bold: true }}
        />
        <ExcelExportColumn
          key={t('form.additionalInfo')}
          title={t('form.additionalInfo')}
          field='info'
          cellOptions={{ background: '#ccc', wrap: true }}
          locked
          headerCellOptions={{ textAlign: 'center', bold: true }}
        />
      </ExcelExport>
    </>
  );
};

ExcelExportEvents.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  facilityName: PropTypes.string.isRequired,
};
