import PathEntity from './PathEntity';

class Devices extends PathEntity {
  constructor() {
    super('devices');
  }

  getState = (facilityId, equipmentId) => {
    const endpoint = this.manufacturePath(
      `facilities/${facilityId}/equipments/${equipmentId}/${this.entityName}/getstate`
    );
    // endpoint = this.addParameterToEndpoint(endpoint, 'dummyReturn', true);
    return endpoint;
  };

  putRelay = (facilityId, equipmentId, relay, ms) => {
    let endpoint = this.manufacturePath(
      `facilities/${facilityId}/equipments/${equipmentId}/${this.entityName}/setrelay`
    );
    if (relay !== undefined) {
      endpoint = this.addParameterToEndpoint(endpoint, 'relay', relay);
    }
    if (ms !== undefined) {
      endpoint = this.addParameterToEndpoint(endpoint, 'ms', ms);
    }
    return endpoint;
  };

  putWriteMemorySingle = (facilityId, id, connId, ic, cc) => {
    let endpoint = this.manufacturePath(
      `facilities/${facilityId}/equipments/${id}/${this.entityName}/writememorysingle`
    );
    if (connId !== null && ic !== null && cc !== null) {
      endpoint = this.addParameterToEndpoint(endpoint, 'subscriberId', connId);
      endpoint = this.addParameterToEndpoint(endpoint, 'customerCode', cc);
      endpoint = this.addParameterToEndpoint(endpoint, 'installerCode', ic);
    }
    return endpoint;
  };

  getPaths = () => ({
    devicesGetState: this.getState,
    devicesPutRelays: this.putRelay,
    devicesPostWriteMemorySingle: this.putWriteMemorySingle,
  });
}

export default new Devices().getPaths();
