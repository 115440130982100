export default {
  neighbourPopUp: {
    title: 'Aggiungi vicino',
    email: 'E-mail',
    phoneNumber: 'Numero di telefono',
    floor: 'Pavimento',
    door: 'da',
    titleEdit: 'Modifica il contatto',
    titleDelete: 'Sei sicuro di voler eliminare il contatto?',
  },
};
