import React, { Component } from 'react';
import { connect } from 'react-redux';
import './TabMenu.css';
import StyledLink from './StyledLink';
import { objectsAreEquals } from '../../core/old_common/utils/objectsService';

class TabMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.getSelectedTabState(props.tabsData),
    };
  }

  getSelectedTabState = (tabsData) => {
    let activeTab = tabsData;
    if (tabsData) {
      activeTab = tabsData.find((tab) => tab.active);
    }
    return {
      Component: activeTab ? activeTab.bodyComponent : tabsData[0].bodyComponent,
      componentState: activeTab ? activeTab.componentState : tabsData[0].componentState,
      selectedTabName: activeTab ? activeTab.title : tabsData[0].title,
      haveBorder: activeTab ? activeTab.haveBorder : tabsData[0].haveBorder,
    };
  };

  componentDidUpdate() {
    const actualTab = this.props.tabsData.find((x) => x.title === this.state.selectedTabName);
    const actualActiveTab = this.props.tabsData.find((x) => x.active);
    if (
      actualTab &&
      actualActiveTab &&
      actualActiveTab.id === actualTab.id &&
      !objectsAreEquals(this.state.componentState, actualTab.componentState)
    ) {
      this.setState({ componentState: actualTab.componentState });
    } else if (actualTab && actualActiveTab && actualActiveTab.id !== actualTab.id) {
      this.changeTabComponent(actualActiveTab.title, true);
    }
  }

  changeTabComponent = (tab, saveOnState) => {
    const { tabsData } = this.props;
    const actualTab = tabsData.find((x) => x.title === tab);
    if (actualTab) {
      if (saveOnState || !actualTab.onClick) {
        this.setState({
          Component: actualTab.bodyComponent,
          componentState: actualTab.componentState,
          selectedTabName: tab,
          haveBorder: actualTab.haveBorder,
        });
      } else if (actualTab.onClick) {
        actualTab.onClick(actualTab);
      }
    }
  };

  render() {
    const { tabType, tabsData, tenants, onDrop, onDragOver, tabClassName } = this.props;
    const { Component, componentState, haveBorder, selectedTabName } = this.state;
    return (
      <div className='tabMenu' onDrop={onDrop} onDragOver={onDragOver}>
        <ul className={`nav nav-${tabType}`} id='pills-tab' role='tablist'>
          {tabsData.map((tab, i) => {
            const bootstrapTabOptions = !tab.onClick
              ? { 'data-toggle': 'pill', href: '#pills-home', role: 'tab' }
              : {};
            return (
              <li
                key={i}
                id={tab?.idTab ? tab.idTab : tab.id}
                className={`tab ${haveBorder ? 'tab-border' : ''}`}
              >
                <StyledLink
                  tenants={tenants}
                  onClick={() => this.changeTabComponent(tab.title || tab.id)}
                  className={`nav-link ${selectedTabName === tab.title ? 'active' : ''}`}
                  {...bootstrapTabOptions}
                >
                  {tab.title}
                </StyledLink>
              </li>
            );
          })}
        </ul>
        <div className={haveBorder ? 'tab-content' : ''} id='pills-tabContent'>
          <div className={`tab-pane fade ${true ? 'show active' : ''} ${tabClassName || ''}`}>
            <Component tenants={tenants} {...componentState} />
          </div>
        </div>
      </div>
    );
  }

  static defaultProps = {
    tabType: 'pills',
  };
}

const mapStateToProps = (state) => ({
  tenants: state.tenants,
});

export default connect(mapStateToProps, {})(TabMenu);
