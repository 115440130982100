import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from '@jcm-technologies/uikit/dist/molecules/Card';
import { Flex } from '@jcm-technologies/uikit/dist/atoms/Layout';
import { Text } from '@jcm-technologies/uikit/dist/atoms/Typography';
import { Icon } from '@jcm-technologies/uikit/dist/atoms/Icon';
import IconExclamationTriangle from '@jcm-technologies/uikit/dist/atoms/Icons/IconExclamationTriangle';
import IconArrowRight from '@jcm-technologies/uikit/dist/atoms/Icons/IconArrowRight';
import { iconAlarmTypeList } from './config';
import { getCookieDateTimeFormat } from '../../../../core/old_common/utils/cookiesManager';
import moment from 'moment';
import PropTypes from 'prop-types';

const Alarms = ({ handleRedirectTo, activeAlarms }) => {
  const dateFormat = getCookieDateTimeFormat() ? getCookieDateTimeFormat() : 'yyyy-MM-dd';
  const { t } = useTranslation();

  return (
    <Card
      rounded='soft'
      width='100%'
      height='100%'
      maxWidth='100%'
      position='relative'
      marginBottom={2}
      display='flex'
    >
      <Flex width='100%' flexDirection='column'>
        <Flex alignItems='center'>
          <Icon sizeIcon='display24' marginRight={2}>
            <IconExclamationTriangle id='active-alarms-icon' />
          </Icon>
          <Text id='active-alarms-title' style={{ flex: '1' }}>
            {t('form.activeAlarms')}
          </Text>
          <Icon
            sizeIcon='display24'
            style={{ cursor: 'pointer' }}
            onClick={() => handleRedirectTo(7, '', t('form.activeAlarms'))}
          >
            <IconArrowRight id='active-alarms-icon-arrow' />
          </Icon>
        </Flex>
        <Flex
          id='active-alarms-list'
          justifyContent='space-between'
          flex={1}
          flexDirection='column'
          marginTop={3}
        >
          {activeAlarms?.activeAlarms?.map((el, i) => (
            <Text
              key={el?.lastSeen || i}
              sizeText='display13'
              as='span'
              display='flex'
              style={{ alignItems: 'center' }}
            >
              {el.distinctCount || 1}
              <Icon marginX={1} sizeIcon='display16'>
                {iconAlarmTypeList[el.alarmCode]}
              </Icon>
              x {t(`code.${el.alarmCode}`)}: {el.facilityName} <br />
              {moment(el.lastSeen).format(dateFormat.toUpperCase())}
            </Text>
          ))}
          <Text
            id='totalAlarms'
            sizeText={['display24', 'display24', 'display36']}
            style={{ alignSelf: 'flex-end' }}
          >
            {t('general.total.zero')}: {activeAlarms?.totalAlarms || '0'}
          </Text>
        </Flex>
      </Flex>
    </Card>
  );
};

export default Alarms;

Alarms.propTypes = {
  handleRedirectTo: PropTypes.func.isRequired,
  activeAlarms: PropTypes.shape({
    activeAlarms: PropTypes.arrayOf(
      PropTypes.shape({
        distinctCount: PropTypes.number,
        alarmCode: PropTypes.number,
        alarm: PropTypes.string,
        facilityName: PropTypes.string,
        lastSeen: PropTypes.string,
      })
    ),
    totalAlarms: PropTypes.number,
  }),
};
