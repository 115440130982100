import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import ContentContainer from '../ContentContainer';
import GenericForm from '../GenericForm';
import validationsDictionary from '../../core/old_common/validations';
import { validateForm } from '../../core/old_common/validations/service';
import {
  invokeSendFunction,
  setAssistantPanelCheckSuccessFunction,
  setMessageHubFunction,
  checkAssistantPanelConnection,
  invokeSendCICC,
} from '../../core/old_common/utils/signalRService';
import SignalRComponent from '../SignalRComponent/SignalRComponent';

class ChangePasswordPopup extends SignalRComponent {
  constructor(props) {
    super(props);
    this.state = {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    };
  }

  onICCCReceived = () => {
    setMessageHubFunction(this.newMessageHubFunction);
    this.changePasswordSignalR();
  };

  changePasswordSignalR = () => {
    const { product, facility } = this.props;
    invokeSendFunction(
      `${product.driverName}_changepassword::${product.productType}::${facility.installerCode}::${this.state.oldPassword}::${this.state.newPassword}`,
      90000
    );
  };

  handleChange = (e) => {
    if (!isNaN(e.target.value) && e.target.value.length <= 4) {
      this.setState({ [e.target.id]: e.target.value });
    }
  };

  handleSubmit = (e) => {
    e?.preventDefault();
    const formErrors = validateForm(this.getFields());
    this.setState({ formErrors: formErrors.errors });
    if (!formErrors.hasError) {
      setMessageHubFunction(this.onICCCReceived);
      setAssistantPanelCheckSuccessFunction(invokeSendCICC);
      checkAssistantPanelConnection();
    }
  };

  newMessageHubFunction = (connId, id) => {
    const { t } = this.props;
    toast.success(t('apitoasters.success.changeSignalRPassword'));
  };

  getFields = () => {
    const { t } = this.props;
    const fields = [
      [
        {
          type: 'password',
          placeHolder: `${t('changePassword.currentPassword')} *`,
          value: this.state.oldPassword,
          maxlength: 4,
          id: 'oldPassword',
          onChange: this.handleChange,
          validation: [
            () => validationsDictionary.isNumeric(this.state.oldPassword),
            () => validationsDictionary.isRequired(this.state.oldPassword),
          ],
        },
      ],
      [
        {
          type: 'password',
          placeHolder: `${t('changePassword.newPassword')} *`,
          value: this.state.newPassword,
          id: 'newPassword',
          onChange: this.handleChange,
          maxlength: 4,
          validation: [
            () => validationsDictionary.isNumeric(this.state.newPassword),
            () => validationsDictionary.isRequired(this.state.newPassword),
          ],
        },
      ],
      [
        {
          type: 'password',
          placeHolder: `${t('changePassword.confirmPassword')} *`,
          value: this.state.confirmPassword,
          id: 'confirmPassword',
          onChange: this.handleChange,
          maxlength: 4,
          validation: [
            () =>
              validationsDictionary.isSameValue(this.state.newPassword, this.state.confirmPassword),
            () => validationsDictionary.isNumeric(this.state.newPassword),
            () => validationsDictionary.isRequired(this.state.confirmPassword),
          ],
        },
      ],
    ];
    return fields;
  };

  render() {
    const { tenants, closePopup, t } = this.props;
    return (
      <div>
        <ContentContainer title={t('changePassword.newPasswordPopupTitle')}>
          <GenericForm
            tenants={tenants}
            fields={this.getFields()}
            handleCancel={closePopup}
            isCancelVisible
            handleSubmit={this.handleSubmit}
            formErrors={this.state.formErrors}
          />
        </ContentContainer>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  tenants: state.tenants,
});

const ChangePasswordPopupWithTranslation = withTranslation()(ChangePasswordPopup);

export default connect(mapStateToProps, {})(ChangePasswordPopupWithTranslation);
