import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from '@jcm-technologies/uikit/dist/molecules/Card';
import { Flex } from '@jcm-technologies/uikit/dist/atoms/Layout';
import { Text } from '@jcm-technologies/uikit/dist/atoms/Typography';
import { Icon } from '@jcm-technologies/uikit/dist/atoms/Icon';
import IconWifi from '@jcm-technologies/uikit/dist/atoms/Icons/IconWifi';
import PropTypes from 'prop-types';

const DeviceStatus = ({ handleRedirectTo, onlineStatus }) => {
  const { t } = useTranslation();

  return (
    <Card
      rounded='soft'
      width='100%'
      height='100%'
      maxWidth='100%'
      position='relative'
      display='flex'
    >
      <Flex width='100%' flexDirection='column'>
        <Flex alignItems='center'>
          <Icon sizeIcon='display24' marginRight={2}>
            <IconWifi id='connected-icon' />
          </Icon>
          <Text id='connected-title' style={{ flex: '1' }}>
            {t('dashboard.smartDevicesStatus')}
          </Text>
        </Flex>
        <Flex justifyContent='space-between' flex={1} marginTop={3} alignItems='center'>
          <Card
            id='connected-card'
            rounded='solid'
            width='100%'
            height='100%'
            marginRight={2}
            maxWidth='100%'
            style={{ cursor: 'pointer' }}
            onClick={() => handleRedirectTo(8, '', t('status.online'))}
          >
            <Text id='connected-text' sizeText='display16'>
              {t('status.online')}
            </Text>
            <Text id='connected-amount' color='green' sizeText='display48'>
              {onlineStatus?.connected}
            </Text>
          </Card>
          <Card
            id='disconnected-card'
            rounded='solid'
            width='100%'
            height='100%'
            maxWidth='100%'
            style={{ cursor: 'pointer' }}
            onClick={() => handleRedirectTo(9, '', t('status.offline'))}
          >
            <Text id='disconnected-text' sizeText='display16'>
              {t('status.offline')}
            </Text>
            <Text id='disconnected-amount' color='red' sizeText='display48'>
              {onlineStatus?.disconnected}
            </Text>
          </Card>
        </Flex>
      </Flex>
    </Card>
  );
};

export default DeviceStatus;

DeviceStatus.propTypes = {
  handleRedirectTo: PropTypes.func.isRequired,
  onlineStatus: PropTypes.shape({
    connected: PropTypes.number,
    disconnected: PropTypes.number,
  }),
};
