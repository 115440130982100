import { toast } from 'react-toastify';
import i18next from 'i18next';
import createFetchTypes from '../../../core/typeActions/types';
import driversActions, { resetDriversAsyncResponse } from '../actions/drivers';
import store from '../stores/configureStore';
import { showDialog } from '../actions/loading';
// import errorsAction from '../actions/errors';

export const drivers_CONFIG_POST = createFetchTypes('drivers_CONFIG_POST');
export const drivers_CODES_POST = createFetchTypes('drivers_CODES_POST');
export const drivers_COMPARE_POST = createFetchTypes('drivers_COMPARE_POST');
export const drivers_EVENTS_POST = createFetchTypes('drivers_EVENTS_POST');
export const drivers_SEND_MEMORY_CODES_POST = createFetchTypes('drivers_SEND_MEMORY_CODES_POST');
export const drivers_PING_GET = createFetchTypes('drivers_PING_GET');
export const a5K_POST_COMPARE_FOBS = createFetchTypes('a5K_POST_COMPARE_FOBS');

/* Redux state init */
const initialState = {
  config: undefined,
  codes: undefined,
  compare: [],
  listFobs: [],
  asyncResponseTimeout: undefined,
};

export default function driversReducer(state = initialState, action) {
  if (action.type !== undefined) {
    switch (action.type) {
      case drivers_CONFIG_POST.SUCCESS:
        return {
          ...state,
          config: action.result.data,
        };
      case driversActions.RESET_DRIVER_MEMORY_CONFIG:
        return { ...state, config: undefined, codes: undefined };
      case drivers_CODES_POST.SUCCESS:
        return {
          ...state,
          codes: action.result.data,
        };
      case drivers_COMPARE_POST.SUCCESS:
      case a5K_POST_COMPARE_FOBS.SUCCESS:
        return {
          ...state,
          compare: action.result,
        };
      case drivers_EVENTS_POST.SUCCESS:
        return {
          ...state,
          listFobs: [...action.result],
        };
      case driversActions.RESET_DRIVER_COMPARE:
        return {
          ...state,
          compare: [],
        };
      case drivers_SEND_MEMORY_CODES_POST.SUCCESS:
        return {
          ...state,
          asyncResponseTimeout: setTimeout(() => {
            store.dispatch(showDialog(-1));
            store.dispatch(resetDriversAsyncResponse());
            toast.error(i18next.t('event.status.91038'));
          }, 90000),
        };

      case driversActions.RESET_DRIVER_EVENTS:
        return {
          ...state,
          listFobs: [],
        };
      case driversActions.RESET_DRIVER_ASYNC_RESPONSE:
        if (state.asyncResponseTimeout !== undefined) {
          clearTimeout(state.asyncResponseTimeout);
        }
        return {
          ...state,
          asyncResponseTimeout: undefined,
        };
      default:
        return state;
    }
  }
  return state;
}
