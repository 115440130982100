import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ContentContainer from '../../ContentContainer';
import { putInstalationsRelay } from '../../../modules/old_to_refact/actions/instalations';
import FacilityEquipmentRelaySchedules from './FacilityEquipmentRelaySchedules';

export class FacilitySchedulesContent extends Component {
  onClickRedirect = (url) => this.props.history.push(url);

  render() {
    const {
      relays,
      name,
      groupId,
      facilityId,
      id,
      className,
      putRelay,
      secondaryId,
      principalId,
      principalParameter,
      secondaryParameter,
    } = this.props;
    return (
      <div className={`facilitySchedules-divs ${className || ''}`}>
        <ContentContainer title={name.replace(/%23/g, '#').replace(/%26/g, '&')}>
          {relays.map((relay) => (
            <FacilityEquipmentRelaySchedules
              {...relay}
              groupId={groupId}
              facilityId={facilityId}
              equipmentId={id}
              putRelay={putRelay}
              secondaryId={secondaryId}
              principalId={principalId}
              principalParameter={principalParameter}
              secondaryParameter={secondaryParameter}
            />
          ))}
        </ContentContainer>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const facilitySchedulesContent = withRouter(FacilitySchedulesContent);

export default connect(mapStateToProps, {
  putInstalationsRelay,
})(facilitySchedulesContent);
