export default {
  alarms: {
    deleteAlarmPopUp: 'Biztos vagy benne, hogy a riasztást látottként akarja megjelölni?',
    deleteAlarmPopUpWithMove:
      'A klónozott távirányítót áthelyezi egy másik csoportba és a riasztást látottnak tekinti. Biztosan folytatja?',
    deleteAlarmPopUpWithCloneUniversal:
      'Az univerzális távirányító másolata megosztja a riasztásokat.',
    deleteAlarmPopUpWithClone:
      'Egy klónozott távirányító másolása egy másik csoportba, a másolat riasztás nélkül jelenik meg.',
  },
};
