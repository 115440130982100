export default {
  breadcrumb: {
    dashboard: 'Dashboard',
    Facilities: 'Strutture',
    FacilityEquipments: 'apparecchiature',
    Groups: 'Gruppi',
    Equipments: 'apparecchiature',
    AddUniversalGroup: 'Aggiungi gruppo universale',
    Configuration: 'Configurazione',
    Enroll: 'Iscrizione al processo',
    administration: 'Amministrazione',
    'user-config': 'Il mio account',
    UniversalGroups: 'Gruppi universali',
    Users: 'utenti',
    Help: 'Aiuto',
    Parameters: 'Parametri',
    Relays: 'Relè',
    Fobs: 'Emettitori',
    Actions: 'Azioni',
    Events: 'eventi',
    'Remote comm': 'Comunicazioni remote',
    Facility: 'Nuova struttura',
    Equipment: 'Nuovo equipaggiamento',
    FacilityGroup: 'Nuovo gruppo',
    Neighbours: 'Elenco dei vicini',
    UniversalGroup: 'Nuovo gruppo universale',
    Schedule: 'Nuovo programma',
    customization: 'Personalizza il Web',
    Invitations: 'inviti',
    Companies: 'Aziende',
    UpdateUser: 'Aggiorna utenti',
    preferences: 'Impostazioni',
    'assistant-panel': 'Assistant Panel',
    Holidays: 'Aggiungi giorni festivi',
    Information: 'Informazione',
    recycle: 'Cestino',
  },
};
