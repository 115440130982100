export default {
  adjustTimePopUp: {
    manualDateTimeAdjust: 'Manuální nastavení data a času',
    actualHour: 'Aktuální čas:',
    minutesCompensation: 'Náhradní minuty od teď',
    insertSdMemory: 'Připoj SD kartu a napájení přesně v následující hodinu',
    inputTime: 'Zobrazuje čas',
    save: 'Uložit',
  },
};
