import validationsDictionary from '../../../core/old_common/validations';

export const statusMapper = {
  Accepted: {
    key: 'status.accepted',
    color: 'green',
    canResend: false,
    canEdit: true,
    canDelete: false,
  },
  Expired: {
    key: 'status.expired',
    color: 'red',
    canResend: true,
    canEdit: false,
    canDelete: true,
  },
  Pending: {
    key: 'status.pending',
    color: 'orange',
    canResend: true,
    canEdit: false,
    canDelete: true,
  },
};

export const calculateTotalString = (free, total) => {
  if (free || total) {
    return `${free}/${total}`;
  }
  return '-';
};

export const validationRules = (data, isDisabledSubscriptions, userRoles, isDisableCompany) => ({
  role: [validationsDictionary.isRequired(data.role)],
  subscriptions: isDisabledSubscriptions
    ? []
    : [
        validationsDictionary.isRequired(
          data.role === userRoles?.find((x) => x.mask === 512)?.id ? '1' : data.subscriptions
        ),
        validationsDictionary.isNumeric(data.subscriptions),
        validationsDictionary.isMin1Value(
          data.role === userRoles?.find((x) => x.mask === 512)?.id ? '1' : data.subscriptions
        ),
      ],
  name: isDisableCompany
    ? []
    : [
        validationsDictionary.isRequired(data.name),
        validationsDictionary.isInTextLength(data.name, 50),
      ],
  email: [
    validationsDictionary.isRequired(data.email),
    validationsDictionary.isInTextLength(data.email, 50),
  ],
  emailConfirm: [
    validationsDictionary.isRequired(data.emailConfirm),
    validationsDictionary.isSameValue(data.email, data.emailConfirm),
    validationsDictionary.isInTextLength(data.emailConfirm, 50),
  ],
  language: [validationsDictionary.isRequired(data.language)],
});

export const keyType = {
  honoa: 'honoaLicenses',
  kaimai: 'kaimaiLicenses',
  cloudAssistant: 'caLicenses',
};

export const keyAvailableMapper = {
  cloudAssistant: 'caTotalLicenses',
  kaimai: 'kaimaiAvailableLicenses',
  honoa: 'honoaAvailableLicenses',
};
export const actionMapper = {
  plus: '+',
  minus: '-',
};
export const rolesNoConsumeLicenses = [128, 256, 512];
