import React from 'react';
import LoginPage from '../views/Login/View';
import DashboardPage from '../views/Dashboard/View';
import ResetPasswordPage from '../views/ResetPassword/View';
import UnauthorizedPage from '../views/Unauthorized/View';
import UserConfigPage from '../views/UserConfig/View';
import LicensesCreate from '../views/Administration/Licenses/Create';
import AdministrationPage from '../views/Administration/View';
import UniversalGroups from '../old_containers/UniversalGroups';
import IndividualUniversalGroup from '../old_containers/IndividualUniversalGroup';
import Instalations from '../old_containers/Instalations';
import IndividualInstalation from '../old_containers/IndividualInstalation';
import IndividualInstalationGroup from '../old_containers/IndividualInstalationGroup';
import RelaysConfigurationInstalation from '../old_containers/RelaysConfiguration';
import RelaysConfigurationUG from '../old_containers/RelaysConfigurationUG.js';
import FacilitiesListUniversalGroups from '../old_components/FacilitiesListUniversalGroups';
import IndividualEquipment from '../old_containers/IndividualEquipment';
import FacilityGroupEnrollCode from '../old_containers/FacilityGroupEnrollCode';
import UniversalGroupEnrollCode from '../old_containers/UniversalGroupEnrollCode/UniversalGroupEnrollCode';
import Owner from '../views/Owner/View';
import InstalationsAddUG from '../old_containers/InstalationsAddUG';
import IndividualUniversalGroupEquipmentRelays from '../old_containers/IndividualUniversalGroupEquipmentRelays';
import Groups from '../views/Group/List';
import Equipments from '../old_containers/Equipments/Equipments';
import RecyclePage from '../old_containers/Recycle';
import FacilityGroupFobView from '../views/Facility/Group/Fob/View';
import UniversalGroupFobView from '../views/UniversalGroups/Fob/View';
import FacilityGroupMifareCreate from '../views/Facility/Group/Mifare/Create';
import SchedulesList from '../views/Schedule/List';
import SchedulesCreate from '../views/Schedule/Create';
import ScheduleEdit from '../views/Schedule/Edit';

const logedRoutes = [
  {
    path: '/dashboard',
    component: DashboardPage,
    title: 'Dashboard',
  },
  {
    path: '/Facilities',
    component: Instalations,
    title: 'Facilities List',
  },
  {
    path: '/Facilities/recycle',
    component: RecyclePage,
    title: 'Recycle Bin',
  },
  {
    path: '/Facilities/:id',
    component: IndividualInstalation,
    title: 'Facility Information',
  },
  {
    path: '/Facilities/:id/groups',
    component: (props) => <IndividualInstalation active='Groups' {...props} />,
    title: 'FacilityGroups List',
  },
  {
    path: '/Facilities/:id/logs',
    component: (props) => <IndividualInstalation active='logs' {...props} />,
    title: 'Facility Logs',
  },
  {
    path: '/Facilities/:id/events',
    component: (props) => <IndividualInstalation active='events' {...props} />,
    title: 'Facility Events',
  },
  {
    path: '/Facilities/:instalationId/groups/:id/Users',
    component: (props) => <IndividualInstalationGroup active='users' {...props} />,
    title: 'FacilityGroups Users',
  },
  {
    path: '/Facilities/:instalationId/groups/:id/wiegand',
    component: (props) => <IndividualInstalationGroup active='mifare' {...props} />,
    title: 'FacilityGroups mifare',
  },
  {
    path: '/Facilities/:instalationId/groups/:id/Fobs',
    component: (props) => <IndividualInstalationGroup active='Fobs' {...props} />,
    title: 'FacilityGroups Fobs',
  },
  {
    path: '/Facilities/:instalationId/groups/:id/Information',
    component: (props) => <IndividualInstalationGroup active='info' {...props} />,
    title: 'FacilityGroups Information',
  },
  {
    path: '/Facilities/:id/Equipments',
    component: (props) => <IndividualInstalation active='Equipments' {...props} />,
    title: 'Equipments List',
  },
  {
    path: '/Facilities/:instalationId/Equipments/:id',
    component: IndividualEquipment,
    title: 'Equipment Form',
  },
  {
    path: '/Facilities/:instalationId/Equipments/:id/:tab',
    component: IndividualEquipment,
    title: 'Equipment Tab',
  },
  {
    path: '/Facilities/:instalationId/groups/AddUniversalGroup',
    component: InstalationsAddUG,
    title: 'FaciltyGroups Add UG',
  },
  {
    path: '/Facilities/:instalationId/groups/AddUniversalGroup/:id',
    component: IndividualUniversalGroupEquipmentRelays,
    title: 'Universal Group Relays',
  },
  {
    path: '/Facilities/:instalationId/groups/:id',
    component: IndividualInstalationGroup,
    title: 'Group Form',
  },
  {
    path: '/Facilities/:instalationId/groups/:id/fobs/:idFob',
    component: FacilityGroupFobView,
    title: 'Fob Detail',
  },
  {
    path: '/Facilities/:instalationId/groups/:instalationGroupId/Configuration',
    component: RelaysConfigurationInstalation,
    title: 'Group Relays',
  },
  {
    path: '/Facilities/:instalationId/groups/:instalationGroupId/Enroll',
    component: FacilityGroupEnrollCode,
    title: 'Group Enroll',
  },
  {
    path: '/Facilities/:idFacility/groups/:idGroup/wiegand/new',
    component: FacilityGroupMifareCreate,
    title: 'New mifare fob',
  },
  {
    path: '/schedules',
    component: SchedulesList,
    title: 'Schedules List',
  },
  {
    path: '/schedules/create',
    component: SchedulesCreate,
    title: 'Schedule Create or Duplicate',
  },
  {
    path: '/schedules/:id',
    component: ScheduleEdit,
    title: 'Schedule Detail',
  },
  {
    path: '/administration',
    component: AdministrationPage,
    title: 'Administration Page',
  },
  {
    path: '/administration/:tab',
    component: AdministrationPage,
    title: 'Administration Tab',
  },
  {
    path: '/administration/licenses/create',
    component: LicensesCreate,
    title: 'Create License',
  },
  {
    path: '/owner',
    component: (props) => <Owner {...props} active='owner' />,
    title: 'GDPR List',
  },
  {
    path: '/owner/clear-gdpr',
    component: (props) => <Owner {...props} active='clear' />,
    title: 'Clear GDPR',
  },
  {
    path: '/owner/pending-gdpr',
    component: (props) => <Owner {...props} active='pending' />,
    title: 'Pending GDPR',
  },
  {
    path: '/user-config/preferences',
    component: (props) => <UserConfigPage {...props} active='preferences' />,
    title: 'User Information',
  },
  {
    path: '/user-config/assistant-panel',
    component: (props) => <UserConfigPage {...props} active='assistantPanel' />,
    title: 'Assistant Panel',
  },
  {
    path: '/UniversalGroups',
    component: UniversalGroups,
    title: 'UniversalGroups List',
  },
  {
    path: '/UniversalGroups/:id',
    component: IndividualUniversalGroup,
    title: 'Universal Group Information',
  },
  {
    path: '/UniversalGroups/:id/Equipments',
    component: (props) => <IndividualUniversalGroup active='Equipments' {...props} />,
    title: 'Universal Group Assigned Facilities',
  },
  {
    path: '/UniversalGroups/:id/fobs',
    component: (props) => <IndividualUniversalGroup active='fobs' {...props} />,
    title: 'Universal Group Users',
  },
  {
    path: '/UniversalGroups/:id/fobs/Enroll',
    component: UniversalGroupEnrollCode,
    title: 'Universal Group Enroll',
  },
  {
    path: '/UniversalGroups/:id/fobs/:idFob',
    component: UniversalGroupFobView,
    title: 'Fob Detail',
  },
  {
    path: '/UniversalGroups/:universalGroupId/Equipments/Facilities',
    component: FacilitiesListUniversalGroups,
    title: 'Universal Group Facilities',
  },
  {
    path: '/UniversalGroups/:universalGroupId/Equipments/Facilities/:id',
    component: (props) => <RelaysConfigurationUG isFacility {...props} />,
    title: 'Universal Group Facility Relays',
  },
  {
    path: '/UniversalGroups/:universalGroupId/Equipments/:id',
    component: RelaysConfigurationUG,
    title: 'Universal Group Assigned Facility Relays',
  },
  {
    path: '/groups',
    component: Groups,
    title: 'Groups list',
  },
  {
    path: '/Equipments',
    component: Equipments,
    title: 'Equipments list',
  },
];

const notLogedRoutes = [
  {
    path: '/login',
    component: LoginPage,
    title: 'LogIn',
  },
  {
    path: '/reset-password',
    component: ResetPasswordPage,
    title: 'ResetPassword',
  },
  {
    path: '/unauthorized',
    component: UnauthorizedPage,
    title: 'Unauthorized',
  },
];

export { logedRoutes, notLogedRoutes };
