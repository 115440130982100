import React from 'react';
import { Card } from '@jcm-technologies/uikit/dist/molecules/Card';
import { Grid, GridItem } from '@jcm-technologies/uikit/dist/atoms/Layout';
import DashboardAdvancedSearch from './AdvancedSearch';
import CardFacilitiesMaintenance from './Card/FacilitiesMaintenance';
import CardDeviceStatus from './Card/DeviceStatus';
import CardAlarmsActive from './Card/Alarms';
import CardDeviceUpdated from './Card/DeviceUpdated';
import CardQuickActions  from './Card/QuickActions';
import CardHonoaUsers from './Card/HonoaUsers';
import CardStatistics from './Card/Statistics';

const Dashboard = ({
  filterSelected,
  filterTypeSelected,
  valueFilter,
  onSetFilter,
  onSetFilterType,
  onSetValueFilter,
  pendingMaintenance,
  honoaUsersCount,
  honoaLicenses,
  onlineStatus,
  missconfiguredDevices,
  activeAlarms,
  handleSearch,
  handleRedirectTo,
}) => {

  return (
    <Grid
      gridGap={2}
      gridTemplateColumns={['1fr', '1fr', '1fr 1fr 1fr']}
      gridTemplateRows={['auto', 'auto', '1fr 1fr 1fr 1fr']}
      gridTemplateAreas={[
        '"grid1""grid2""grid3""grid4""grid5""grid6""grid7""grid8"',
        '"grid1 grid1"" grid2 grid3" "grid4 grid5" "grid6 grid6""grid7"',
        '"grid1 grid1 grid2""grid3 grid4 grid5""grid6 grid6 grid7""grid6 grid6 grid8"',
      ]}
    >
      <GridItem gridArea='grid1'>
        <DashboardAdvancedSearch
          handleSearch={handleSearch}
          onSetValueFilter={onSetValueFilter}
          filterTypeSelected={filterTypeSelected}
          filterSelected={filterSelected}
          onSetFilterType={onSetFilterType}
          onSetFilter={onSetFilter}
          valueFilter={valueFilter}
        />
      </GridItem>
      <GridItem gridArea='grid2'>
        <CardFacilitiesMaintenance
          handleRedirectTo={handleRedirectTo}
          pendingMaintenance={pendingMaintenance}
        />
      </GridItem>
      <GridItem gridArea='grid3'>
        <CardDeviceStatus
          handleRedirectTo={handleRedirectTo}
          onlineStatus={onlineStatus}
        />
      </GridItem>
      <GridItem gridArea='grid4'>
        <CardAlarmsActive
          handleRedirectTo={handleRedirectTo}
          activeAlarms={activeAlarms}
        />
      </GridItem>
      <GridItem gridArea='grid5'>
        <CardDeviceUpdated
          handleRedirectTo={handleRedirectTo}
          missconfiguredDevices={missconfiguredDevices}
        />
      </GridItem>
      <GridItem gridArea='grid6'>
        <Card
          rounded='soft'
          width='100%'
          height='100%'
          maxWidth='100%'
          position='relative'
          marginBottom={2}
          display='flex'
        >
          <CardStatistics />
        </Card>
      </GridItem>
      <GridItem gridArea='grid7'>
        <CardHonoaUsers
          honoaUsersCount={honoaUsersCount}
          honoaLicenses={honoaLicenses}
        />
      </GridItem>
      <GridItem gridArea='grid8'>
        <CardQuickActions />
      </GridItem>
    </Grid>
  );
};

export default Dashboard;
