import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import IconMuvAlarm from '@jcm-technologies/uikit/dist/atoms/Icons/IconMuvAlarm';
import {
  getEquipmentUserGroups,
  resetAllFobLists,
  resetInstalation,
  resetInstalationFOBS,
  resetInstalationsRelays,
} from '../../modules/old_to_refact/actions/instalations';
import { setManualGrowRedirectURL } from '../../modules/old_to_refact/actions/genericGrow';

import AddButton from '../AddButton';
import GenericTable from '../GenericTable';
import IconSpan from '../IconSpan';
import './InsalationEquipmentsUserGroups.css';
import { getEncodedUrl } from '../../core/old_common/utils/getUrlParams';
import { setSelectedDescendants } from '../../modules/state/visibleDescendants/actions';
import {
  getCookieCustomerId,
  getCookieUserRoles,
} from '../../core/old_common/utils/cookiesManager';
import { generateAlarmSumary } from '../utils/mappers/alarms';
import { resetUGRelays } from '../../modules/old_to_refact/actions/universalGroups';

export class InstalationEquipmentsUserGroups extends Component {
  componentDidMount() {
    const { getEquipmentUserGroups, facilityId, equipmentId } = this.props;
    getEquipmentUserGroups(facilityId, equipmentId);
  }

  componentWillUnmount = () => {
    this.props.resetUGRelays();
    this.props.resetInstalationsRelays();
    this.props.resetAllFobLists();
    this.props.resetInstalationFOBS();
  };

  onClickRedirect = (url) => {
    this.props.history.push(`${url}`);
  };

  getColumns = () => {
    const { t } = this.props;
    return [
      {
        value: '',
      },
      {
        value: t('form.name'),
      },
      {
        value: t(`equipments.actions`),
      },
    ];
  };

  getVisibleDescendants = () => {
    const { t } = this.props;
    const customerId = getCookieCustomerId();
    const role = getCookieUserRoles();
    const visibleDesc = [];
    if (role.mask === 8 || role.mask === 16) {
      visibleDesc.push({ label: t('globalSearch.allOwners'), value: customerId });
    } else {
      visibleDesc.push({
        label: t('globalSearch.allOwners'),
        value: 'ffffffff-ffff-ffff-ffff-ffffffffffff',
      });
    }
    return visibleDesc;
  };

  getRows = () => {
    const { t, equipmentUserGroups, facility, match } = this.props;
    const rows = equipmentUserGroups
      ? equipmentUserGroups.map((userGroup) => {
          let dataAlarmsSummary = {};
          let alarmSummary = null;
          let url = `/Facilities/${getEncodedUrl(
            facility ? facility.id : match.params.instalationId
          )}/groups/${getEncodedUrl(userGroup.groupId)}`;
          if (userGroup.isUniversal) {
            url = `/UniversalGroups/${getEncodedUrl(userGroup.groupId)}`;
          }
          if (userGroup.alarms.length) {
            dataAlarmsSummary = {
              toolTip: generateAlarmSumary(userGroup.alarms),
              icon: <IconMuvAlarm fill='red' />,
              iconClassName: `generic-grow-action-button d-grid`,
              cursor: 'auto',
              onClick: (e) => {
                e?.preventDefault();
                e?.stopPropagation();
              },
            };
            alarmSummary = <IconSpan {...dataAlarmsSummary} />;
          }
          const row = {
            getAlarms: alarmSummary,
            hasAlarms: true,
            type: userGroup.name,
            hasAction: true,
            getActionComponent: () => (
              <IconSpan
                id='edit-action'
                toolTip={t('general.edit')}
                defaultColor='#a4aebb'
                iconClassName='generic-grow-action-button fas fa-pencil-alt'
                cursor='pointer'
                onClick={() => {
                  this.onClickRedirect(url);
                }}
              />
            ),
          };
          return row;
        })
      : [];
    return rows || [];
  };

  render() {
    const { t, tenants, history, setManualGrowRedirectURL, facility, match } = this.props;
    return (
      <>
        <GenericTable columnsConfig={this.getColumns()} rowsConfig={this.getRows()} />

        <AddButton
          id='add'
          tenants={tenants}
          toolTip={t('facilitiesUserGroupsGrid.add')}
          onClick={() => {
            setManualGrowRedirectURL(
              `/Facilities/${getEncodedUrl(
                facility ? facility.id : match.params.instalationId
              )}/groups/~~~name~~~/Configuration`
            );
            history.push(
              `/Facilities/${getEncodedUrl(
                facility ? facility.id : match.params.instalationId
              )}/groups/FacilityGroup`
            );
          }}
        />
      </>
    );
  }
}

const instalationEquipmentsUserGroups = withRouter(InstalationEquipmentsUserGroups);
const InstalationEquipmentsUserGroupsWithTranslation = withTranslation()(
  instalationEquipmentsUserGroups
);
const mapStateToProps = (state) => ({
  equipmentUserGroups: state.instalations.equipmentUserGroups,
  facilities: state.instalations.instalations,
  visibleDescendants: state.universalGroups.visibleDescendants,
});

export default connect(mapStateToProps, {
  setSelectedDescendants,
  getEquipmentUserGroups,
  setManualGrowRedirectURL,
  resetInstalation,
  resetInstalationsRelays,
  resetInstalationFOBS,
  resetUGRelays,
  resetAllFobLists,
})(InstalationEquipmentsUserGroupsWithTranslation);
