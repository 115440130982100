export default {
  gdprPopup: {
    accept: 'Accettare',
    cancel: 'Annulla',
    conditionsAgreement: "Accetta le condizioni d'uso",
    close: 'Vicino',
    firstPolicyAgreementText:
      'CloudAssistant continua ad evolversi per fornirti servizi migliori e integrare nuove prestazioni come il nuovo ruolo di Property Manager che ti consente di delegare la gestione dei telecomandi a quegli Amministratori condominiali che vogliono fornire un servizio più vicino alle loro comunità.',
    firstPolicyAgreementSecondText:
      'Abbiamo modificato le nostre politiche per poter integrare questi nuovi servizi.',
    firstPolicyAgreementThirdText: 'Per qualsiasi dubbio, puoi contattarci all’indirizzo e-mail',
    privacyText: 'Politica sulta privacy',
    legalNoticeText: 'Nota leggale',
    cookiesText: 'Politica dei cookie',
    conditionsText: 'Condizioni generali',
    PolicyAgreementPopupTitle1: 'CloudAssistant si aggiorna',
    PolicyAgreementPopupTitle2: 'nuovi servizi e politiche',
    PolicyAgreementPopupAnd: 'e',
  },
};
