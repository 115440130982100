import React, { useState } from 'react';
import { Icon } from '@jcm-technologies/uikit/dist/atoms/Icon';
import IconSettings from '@jcm-technologies/uikit/dist/atoms/Icons/IconSettings';
import EquipmentConfigurationWiegandModal from '../Modal';

const EquipmentConfigurationWiegandAction = ({ data, name, onSave }) => {
  const [isOpened, setIsOpened] = useState(false);
  return (
    <>
      <Icon onClick={() => setIsOpened(true)}>
        <IconSettings />
      </Icon>
      <EquipmentConfigurationWiegandModal
        isOpen={isOpened}
        name={name}
        onClose={() => setIsOpened(false)}
        data={data}
        onSave={onSave}
      />
    </>
  );
};

export default EquipmentConfigurationWiegandAction;
