export default {
  instalationUserGroups: {
    manageFobsAndLicenses: 'FOB und Lizenzen verwalten',
    fobsLicensesGridTitle: 'FOBS / Lizenzen',
    email: 'Email',
    phone: 'Telefon',
    addUser: 'Nutzer hinzufügen',
    deleteUserConfirmation: 'Möchten Sie diesen Benutzer wirklich löschen?',
    Fobs: 'Sender',
    Licenses: 'Lizenzen',
    addFobToUser: 'FOB zum Benutzer hinzufügen',
    expiration: 'Ablauf',
    licenseStatus: 'Status',
    locked: 'Gesperrt',
    unlocked: 'Freigeschaltet',
    0: 'Bluetooth',
    1: 'Fernbedienung öffnen',
    users: 'Benutzer',
    information: 'Information',
  },
};
