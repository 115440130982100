export default {
  relaysConfiguration: {
    tabRelays: 'Relés',
    tabSchedules: 'Horarios',
    tabInfo: 'Información',
    time: 'Tiempo de activación relé {{count}}',
    delay: 'Tiempo de retardo relé {{count}}',
    infoTime: 'El valor debe ser comprendido entre 1 y 60 segundos',
    infoDelay: 'El valor debe ser comprendido entre 0 y 10 segundos',
    infoCustomOpenDoorTime: 'El valor debe ser comprendido entre 1 y 86400 segundos',
    relayNotassigned: 'Relé no asignado',
    goBack: 'Atrás',
    type: 'Tipo de relé',
    keepDoorOpenSchedule: 'Horario de puerta abierta',
    customOpenDoorTimeSchedule: 'Horario de puerta abierta personalizado',
    customOpenDoorTime: 'Tiempo de puerta abierta',
    Relay: 'Relé',
  },
};
