import { TabsMenu } from '@jcm-technologies/uikit/dist/molecules/TabsMenu';
import { Box } from '@jcm-technologies/uikit/dist/atoms/Layout';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getContrastColor } from '../../../core/helpers/colorManager';
import { Edit as CustomizationLayout } from './Layout/Edit';
import { Edit as CustomizationFamilies } from './Families/Edit';
import { View as CustomizationUserFields } from './UserFields/View';

export const View = () => {
  const { tab } = useParams();
  const { t } = useTranslation();
  const tenants = useSelector((state) => state.tenants);

  return (
    <Box height='100%' padding={2}>
      <TabsMenu
        id='administrationTab'
        colorActive={getContrastColor(tenants?.color1)}
        themeColor={tenants?.color1}
        defaultSelected={tab}
        components={{
          customization: <CustomizationLayout />,
          families: <CustomizationFamilies />,
          configFields: <CustomizationUserFields />,
        }}
        items={[
          {
            id: 'customization',
            title: t('title.layout'),
          },
          {
            id: 'families',
            title: t('title.families'),
          },
          {
            id: 'configFields',
            title: t('title.configFields'),
          },
        ]}
      />
    </Box>
  );
};
