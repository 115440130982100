import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { getUniversalGroupEquipmentRelays } from '../../modules/old_to_refact/actions/universalGroups';
import { getFacility } from '../../modules/old_to_refact/actions/instalations';
import GenericList from '../../old_components/GenericList/GenericList';
import UniversalGroupGrow from '../../old_components/UniversalGroupGrow/UniversalGroupGrow';
import ButtonsGroup from '../../old_components/ButtonsGroup/ButtonsGroup';

import './InstalationsAddUG.css';
import { FACILITY_UG_LIST } from '../../modules/state/pagination/constants';

class InstalationsAddUG extends Component {
  componentDidMount() {
    const { needCallFacility, getUniversalGroupEquipmentRelays, facility, match, getFacility } =
      this.props;
    if (facility) {
      getUniversalGroupEquipmentRelays(facility.id);
    } else if (needCallFacility) {
      getFacility(match.params.instalationId);
    }
  }

  componentDidUpdate(provProps) {
    const { getUniversalGroupEquipmentRelays, facility } = this.props;
    if (!provProps.facility && facility) {
      getUniversalGroupEquipmentRelays(facility.id);
    }
  }

  goBack = () => {
    const { history, match } = this.props;
    history.push(match.url.replace('/AddUniversalGroup', '').replace('%09', '%2509'));
  };

  handlePageChange = () => {
    this.props.getUniversalGroupEquipmentRelays(this.props.facility.id);
  };

  render() {
    const { equipmentRelays, match, t, getUniversalGroupEquipmentRelays } = this.props;
    return (
      <div className='instalation-add-ug'>
        <GenericList
          dataList={
            equipmentRelays
              ? equipmentRelays.map((relay) => ({
                  ...relay,
                  name: relay.universalGroupName,
                  id: relay.universalGroupId,
                }))
              : []
          }
          CustomGrow={(props) => (
            <UniversalGroupGrow
              secondaryRootPath
              isMapButtonVisible={false}
              hideActionButtons
              {...props}
              creationURI='needForUG'
            />
          )}
          handlePageChange={this.handlePageChange}
          baseURI={match.url}
          creationURI=''
          listType={FACILITY_UG_LIST}
          // getList={getUniversalGroupEquipmentRelays(this.props.facility.id)}
        />
        <ButtonsGroup
          buttons={[
            {
              value: t('relaysConfiguration.goBack'),
              onClick: this.goBack,
            },
          ]}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  tenants: state.tenants,
  equipmentRelays: state.universalGroups.equipmentRelays,
  facility: state.instalations.facility,
  needCallFacility: state.instalations.needCallFacility,
});

const InstalationsAddUGWithRouter = withRouter(InstalationsAddUG);
const InstalationsAddUGWithTranslation = withTranslation()(InstalationsAddUGWithRouter);

export default connect(mapStateToProps, { getUniversalGroupEquipmentRelays, getFacility })(
  InstalationsAddUGWithTranslation
);
