import PropTypes from 'prop-types';
import { Box, Flex } from '@jcm-technologies/uikit/dist/atoms/Layout';
import { Icon } from '@jcm-technologies/uikit/dist/atoms/Icon';
import IconClose from '@jcm-technologies/uikit/dist/atoms/Icons/IconClose';
import { Text } from '@jcm-technologies/uikit/dist/atoms/Typography';
import { Image } from '@jcm-technologies/uikit/dist/atoms/Image';
import { useTranslation } from 'react-i18next';


const HonoaInfo = ({dataForm, dataHonoaLogo, logo})=>{
  const { t } = useTranslation();
  return (
    <Box width={['100%', '100%', '40%']} padding={2} height='100%'>
      <Text sizeText='display16' color='black' marginY={2} fontWeight='bold'>
        {t('form.mobilePreview')}
      </Text>
      <Flex flexWrap='wrap' height='100%' minHeight={328}>
        <Box bg='black' padding={3} width='100%' height='100%'>
          <Flex bg='white' borderRadius={2} margin={3} flexDirection='column' height='100%'>
            <Flex width='100%' justifyContent='flex-end'>
              <Icon sizeIcon='display36' color='black'>
                <IconClose />
              </Icon>
            </Flex>
            <Flex flexDirection='column'>
              <Flex paddingX={4} marginY={3} justifyContent='center'>
                <Image
                  src={dataHonoaLogo?.base64 || logo}
                  alt='HonoaCustomerLogo1'
                  maxHeight={120}
                  minHeight={90}
                  width='100%'
                />
              </Flex>
              <Box paddingX={4} minHeight={120} marginBottom={5}>
                <Text as='p'>
                  {dataForm?.find((element) => element.tag === 'HonoaCustomerAddress')?.value ||
                    ''}
                </Text>
                <Text as='span'>
                  {dataForm?.find((element) => element.tag === 'HonoaCustomerCity')?.value ||
                    ''}
                </Text>
                <Text as='p'>
                  {dataForm?.find((element) => element.tag === 'HonoaCustomerPhone')?.value ||
                    ''}
                </Text>
                <Text as='p'>
                  {dataForm?.find((element) => element.tag === 'HonoaCustomerMail')?.value ||
                    ''}
                </Text>
                <Text as='p'>
                  {dataForm?.find((element) => element.tag === 'HonoaCustomerWeb')?.value || ''}
                </Text>
              </Box>
            </Flex>
          </Flex>
        </Box>
      </Flex>
    </Box>
  )
}

export default HonoaInfo;

HonoaInfo.propTypes = {
 dataForm: PropTypes.arrayOf(
  PropTypes.shape({
    tag: PropTypes.string,
    value: PropTypes.string,
  })
 ),
 dataHonoaLogo: PropTypes.string,
 logo: PropTypes.string,
};

HonoaInfo.defaultProps = {
  dataForm: [],
  dataHonoaLogo: '',
  logo: ''
};

