import {
  GET_INVITATION_USER,
  GET_OWN_USERS,
  GET_USER,
  GET_USER_ROLES,
  GET_USER_ROLES_EMPLOYEES,
  PATCH_PREFERENCES_USER,
  PUT_AVATAR_USER,
  PUT_PREFERENCES_USER,
  PUT_USER_PERMISSIONS,
  RESET_NEED_SIGNALR_CHECK,
  USER_SET_NEED_UPDATE_LNG_FALSE,
} from './constants';

export const getUserRoles = (mask) => ({ type: GET_USER_ROLES.BASE, mask });
export const getUserRolesEmployees = () => ({ type: GET_USER_ROLES_EMPLOYEES.BASE });

export const getUserInvitation = (id) => ({
  type: GET_INVITATION_USER.BASE,
  id,
});

export const getUser = () => ({
  type: GET_USER.BASE,
});

export const putAvatarUser = (id, avatar) => ({
  type: PUT_AVATAR_USER.BASE,
  id,
  avatar,
});

export const putPreferencesUser = (id, data) => ({
  type: PUT_PREFERENCES_USER.BASE,
  id,
  data,
});

export const patchPreferencesUser = (id, data) => ({
  type: PATCH_PREFERENCES_USER.BASE,
  id,
  data,
});

export const getOwnUser = () => ({
  type: GET_OWN_USERS.BASE,
});

export const putUserPermissions = (userDatas) => ({ type: PUT_USER_PERMISSIONS.BASE, userDatas });

export const setUserNeedUpdateLngFalse = () => ({ type: USER_SET_NEED_UPDATE_LNG_FALSE });

export const resetNeedCheckSignalRConnection = () => ({ type: RESET_NEED_SIGNALR_CHECK });
