import createFetchTypes from '../../../core/typeActions/types';

export const GET_GROUP_FOBS_PDF = createFetchTypes('GET_GROUP_FOBS_PDF');
export const GET_GROUP_FOBS_CHECK_CODES = createFetchTypes('GET_GROUP_FOBS_CHECK_CODES');
export const POST_GROUP_FOBS_COMPARE_MEMORY_LIST = createFetchTypes(
  'POST_GROUP_FOBS_COMPARE_MEMORY_LIST'
);
export const DELETE_GROUP_FOBS_COMPARE_MEMORY_LIST = createFetchTypes(
  'DELETE_GROUP_FOBS_COMPARE_MEMORY_LIST'
);
export const PUT_GROUP_FOBS_IMPORT = createFetchTypes('PUT_GROUP_FOBS_IMPORT');
export const PUT_GROUP_FOBS_GRID = createFetchTypes('PUT_GROUP_FOBS_GRID');
export const DELETE_GROUP_FOBS_LIST = createFetchTypes('DELETE_GROUP_FOBS_LIST');
export const DELETE_GROUP_FOBS_FOB = createFetchTypes('DELETE_GROUP_FOBS_FOB');
export const POST_GROUP_FOBS_LIST = createFetchTypes('POST_GROUP_FOBS_LIST');
export const POST_GROUP_FOBS_LIST_COPY = createFetchTypes('POST_GROUP_FOBS_LIST_COPY');
export const PUT_GROUP_FOBS_LIST_MOVE = createFetchTypes('PUT_GROUP_FOBS_LIST_MOVE');
export const POST_GROUP_FOBS_SEQUENTIAL = createFetchTypes('POST_GROUP_FOBS_SEQUENTIAL');
export const POST_GROUP_FOBS_FOB = createFetchTypes('POST_GROUP_FOBS_FOB');
//Actions
export const RESET_GROUP_FOBS = 'RESET_GROUP_FOBS';
export const RESET_GROUP_FOBS_TABLE_FOBS = 'RESET_GROUP_FOBS_TABLE_FOBS';
export const RESET_GROUP_FOBS_CHECKED_FOBS = 'RESET_GROUP_FOBS_CHECKED_FOBS';
export const RESET_GROUP_FOBS_IS_POST_FOB = 'RESET_GROUP_FOBS_IS_POST_FOB';
