export default {
  universalGroups: {
    description: 'Descripció',
    informationTab: 'Informació',
    expand: 'Expandir',
    collapse: 'Col·lapsar',
    delete: 'Eliminar',
    add: 'Crear nou grup universal',
    create: 'Crear grup universal',
    instalationsTab: 'Instal·lacions',
    fobsTab: 'Emissors',
    deleteConfirmation: 'Segur que vols suprimir aquest grup?',
    universalGroups: 'Grups universals',
  },
};
