export default {
  relaysConfiguration: {
    tabRelays: 'Relais',
    tabSchedules: 'Zeitpläne',
    tabInfo: 'Information',
    time: 'Aktivierungszeit Relais {{count}}',
    delay: 'Verzögerunszeit Relais {{count}}',
    infoTime: 'Der Wert muss zwischen 1 und 60 Sekunden liegen',
    infoDelay: 'Der Wert muss zwischen 0 und 10 Sekunden liegen',
    infoCustomOpenDoorTime: 'Der Wert muss zwischen 1 und 86400 Sekunden liegen',
    relayNotassigned: 'Relais nicht zugeordnet',
    goBack: 'Zurück',
    type: 'Typ Relais',
    keepDoorOpenSchedule: 'Zeitplan für offene Tür',
    customOpenDoorTimeSchedule: 'Benutzerdefinierter Zeitplan für offene Tür',
    customOpenDoorTime: 'Benutzerdefinierte Türöffnungszeit',
    Relay: 'Relais',
  },
};
