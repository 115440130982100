import superagent from 'superagent';
import { getCookieAccessToken } from './cookiesManager';

const getOnFlyValidations = (path, callBack) => {
  new Promise((resolve, reject) => {
    const request = superagent.get(path);
    request.set('Access-Control-Allow-Origin', '*');
    request.set('Content-Type', 'application/json');
    const accessToken = getCookieAccessToken();
    if (accessToken) {
      request.set('Authorization', `Bearer ${accessToken}`);
    }
    request
      .query()
      .then((res) => callBack(false, res.body || res.text))
      .catch((error) => {
        if (error.status !== 401) {
          callBack(true, error.response.body);
        }
      });
  });
};

export default getOnFlyValidations;
