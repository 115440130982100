export default {
  remoteState: {
    noContent: 'Nicht verfügbar',
    title: 'Fernzustand',
    doorState0: 'Nicht definiert',
    doorState1: 'Öffnen',
    doorState2: 'Geschlossen',
    doorState3: 'Öffnung',
    doorState4: 'Schließen',
    doorState5: 'Öffnen stoppen',
    doorState6: 'Parando cierre',
    doorState7: 'Verschluss stoppen',
    deviceType: 'Art des Geräts',
    connectionSignal: 'Verbindungssignal',
    rssiValue: 'RSSI-Wert',
    hwVersion: 'Hardware version',
    swVersion: 'Software version',
    doorStateTitle: 'Türstatus',
    inputStateTitle: 'Eingabestatus',
    deviceInfoTitle: 'Geräteinformation',
    relayTitle: 'Aktivierungszeit von in Sek',
    relaysTitle: 'Relais',
    refreshStatus: 'Status aktualisieren',
    activateRel1: 'Relais 1 aktivieren',
    activateRel2: 'Relais 2 aktivieren',
    doorState: 'Status',
    activateRelError: 'Gerät reagiert nicht',
    activateRelSuccess: 'Aktion erfolgreich abgeschlossen',
    connectionSignalUnknown: 'außer Reichweite',
    connectionSignalExcellent: 'Ausgezeichnet',
    connectionSignalGood: 'Gut',
    connectionSignalFair: 'OK',
    connectionSignalWeak: 'Geringfügig',
    connectionType: 'Verbindungstyp',
    deviceType0: 'eBase',
    deviceType1: 'eConnect',
    deviceType2: 'BtConnect',
    deviceType3: 'Sesame',
    deviceType4: 'Blink',
    deviceType5: 'eAccess',
    deviceType6: 'Vdp',
    deviceType7: 'HONOADOOR / EBASEDOOR',
    deviceType8: 'HONOARELAY',
    sensors: 'Sensoren',
    open: 'Öffnen',
    battery: 'Batterie',
    deactivated: 'Deaktiviert',
    activated: 'Aktiviert',
  },
};
