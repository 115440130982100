import IconUpload from '@jcm-technologies/uikit/dist/atoms/Icons/IconUpload';
import { IconTooltip } from '@jcm-technologies/uikit/dist/molecules/IconTooltip';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { loadFile } from '../../../../../../core/helpers/filesApi';

export const ExcelImportUsers = ({ onImport }) => {
  const { t } = useTranslation();
  return (
    <IconTooltip
      id='import-users-excel'
      color='grey'
      sizeIcon='display36'
      toolTipContent={t('instalationGroups.importExcel')}
      onClick={() =>
        loadFile({
          callBack: (e) => {
            if (e.target.files.length > 0) {
              onImport(e.target.files[0]);
            }
          },
        })
      }
    >
      <IconUpload />
    </IconTooltip>
  );
};

ExcelImportUsers.propTypes = {
  onImport: PropTypes.func.isRequired,
};
