export default {
  gdprPopup: {
    accept: 'Přijmout',
    cancel: 'Zrušit',
    conditionsAgreement: 'Přijmou dobu použití',
    close: 'Zavřít',
    firstPolicyAgreementText:
      'CloudAssistant se dál vyvíjí, aby vám nabízel ty nejlepší služby. Přináší vám také nové služby, jako je nová role Property Manager, která vám umožní delegovat správu ovladačůtěmsprávcům nemovitostí, kteříchtějí být svým komunitám ještě blíže.',
    firstPolicyAgreementSecondText:
      'Upravili jsme naše zásady tak, aby reflektovaly tyto nové služby.',
    firstPolicyAgreementThirdText: 'S případnými dotazy se s námi spojte na adrese',
    privacyText: 'Zásady ochrany osobních údajů',
    legalNoticeText: 'Právní upozornění',
    cookiesText: 'Zásady souborů cookie',
    conditionsText: 'Všeobecné podmínky',
    PolicyAgreementPopupTitle1: 'Aktualizovaný CloudAssistant',
    PolicyAgreementPopupTitle2: 'nové služby a zásady',
    PolicyAgreementPopupAnd: 'a',
  },
};
