export default {
  instalations: {
    groupsTab: 'Groups',
    equipmentsTab: 'Equipments',
    addGroup: 'Add new group',
    deleteConfirmation: 'Are you sure you want to remove this facility?',
    add: 'Add facility',
    config: 'Configure facility',
    notAllow: ' The assigned devices do not allow  schedules',
    allDevicesConnected: 'All connected',
    allDevicesDisconnected: 'Last maintenance:',
    includeReservations: 'Include reservations?',
    includeConfigFields: 'Include configuration fields?',
    acceptExportPopUpButton: 'Export',
    exportPopUpTitle: 'Export Groups',
    nameTableheader: 'Name',
    allEquipmentsStatusOk: 'All devices connected',
    numberOfConnectedDevices: 'Devices found',
    filters: 'Filters',
    criticalDeleteTitle: 'CRITICAL ACTION THAT CANNOT BE UNDONE',
    firstAreYouSureCriticalDelete: 'Are you sure you wish to delete ',
    secondAreYouSureCriticalDelete: '',
    noGoBack: 'THIS ACTION CANNOT BE UNDONE',
    transfer: 'Transfer',
    transferPopUpLabel: 'Administrator who will manage the installation',
    transferPopUpSelector: 'Select user',
    restore: 'Restore',
    recycleBin: 'Recycle bin',
    creation: 'Creation',
    saveMemory: 'Save Memory',
    substitution: 'Substitution',
    fob: 'Fob',
    equipment: 'Equipment',
    deletion: 'Deletion',
    moved: 'Moved',
    locked: 'Locked',
    unlocked: 'Unlocked'
  },
};
