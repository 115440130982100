export default {
  relaysConfiguration: {
    tabRelays: 'Relays',
    tabSchedules: 'Schedules',
    tabInfo: 'Information',
    time: 'Relay {{count}} activation time',
    delay: 'Relay {{count}} delay time',
    infoTime: 'This number must be between 1 and 60 seconds',
    infoDelay: 'This number must be between 0 and 10 seconds',
    infoCustomOpenDoorTime: 'This number must be between 1 and 86400 seconds',
    relayNotassigned: 'Relay not assigned',
    goBack: 'Back',
    type: 'Relay type',
    keepDoorOpenSchedule: 'Open door schedule',
    customOpenDoorTimeSchedule: 'Custom open door time schedule',
    customOpenDoorTime: 'Door open time',
    Relay: 'Relay',
  },
};
