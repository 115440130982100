export default {
  formValidation: {
    notSameValue: 'Nem ugyanaz az érték',
    notSamePassword: 'A megadott jelszó nem egyezik',
    numericField: 'Ez a mező numerikus',
    minNumber: 'A minimális érték 1',
    passwordFormat:
      'A jelszónak legalább nyolc karaktert kell tartalmaznia, legalább egy nagybetűt, egy számot és egy speciális karaktert.',
    isNotValidDate: 'Rossz dátumformátum, formátum: mm:ss: ÉÉÉÉÉ-HH-NN-ÓÓ:pp:mm',
    isRequired: 'Kötelező mező',
    outOfRange: 'Szám a tartományon kívül',
    maxLength: 'A maximális karakterek hossza',
    regEx: 'Helytelen formátum. Használja ezeket a karaktereket ',
    deviceLimit: 'Eszközhatár elérve',
    onlySpaces: 'Csak az üres helyek nem engedélyezettek',
    isReservedWord: 'ez egy fenntartott szó',
  },
};
