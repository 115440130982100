import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { generatePath, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getEquipment } from '../../modules/old_to_refact/actions/equipments';
import { resetInstalationGroup } from '../../modules/old_to_refact/actions/instalationGroups';
import { getFacility } from '../../modules/old_to_refact/actions/instalations';
import {
  CreationPath_Equipments,
  RootPath_Instalations,
} from '../../core/old_common/constats/genericListPaths';
import GenericIndividualGrow from '../../old_components/GenericIndividualGrow/GenericIndividualGrow';
import { getDecodedUrl } from '../../core/old_common/utils/getUrlParams';
import EquipmentsGrow from '../../old_components/EquipmentsGrow/EquipmentsGrow';
import IndividualEquipmentForm from './components/IndividualEquipmentForm/IndividualEquipmentForm';

import './IndividualEquipment.css';
import {
  createSignalRConnectionOnReducer,
  resetSignalRConnectionOnReducer,
} from '../../core/old_common/utils/signalRService';

class IndividualEquipment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedFacility: undefined,
    };
  }

  componentDidMount() {
    const { getFacility, match, history, needCallFacility, getEquipment } = this.props;
    const { selectedFacility } = this.state;
    this.setState({ selectedFacility: this.getSelectedInstalation() });
    const decodedEquipmentName = decodeURIComponent(match.params.id);
    const encodedEquipmentName = encodeURIComponent(decodedEquipmentName);
    if (!match.params.tab && match.params.id !== CreationPath_Equipments && selectedFacility) {
      const decoded = generatePath(`${match.path}`, {
        instalationId: encodeURIComponent(selectedFacility.name),
        id: encodedEquipmentName,
      });
      history.push(`${decoded}/Info`);
    }
    if (!selectedFacility && needCallFacility) {
      getFacility(getDecodedUrl(match.params.instalationId));
    }
    if (match.params.tab && match.params.id !== CreationPath_Equipments) {
      createSignalRConnectionOnReducer();
    }
    if (selectedFacility) {
      getEquipment(getDecodedUrl(selectedFacility.name), selectedFacility.id, false);
    }
  }

  componentDidUpdate(prevProps) {
    const { match, history, isEquipmentUpdated, getEquipment, facility } = this.props;
    const { selectedFacility } = this.state;

    if (match.params.id !== CreationPath_Equipments && selectedFacility) {
      this.refreshEquipments(getDecodedUrl(match.params.id));
      if (!match.params.tab) {
        if (this.props.tabType) {
          const decoded = generatePath(`${match.path}`, {
            instalationId: selectedFacility?.id,
            id: match.params.id,
          });
          history.push(`${decoded}/${this.props.tabType}`);
        } else {
          const decoded = generatePath(`${match.path}`, {
            instalationId: selectedFacility?.id,
            id: match.params.id,
          });
          history.push(`${decoded}/Info`);
        }
      }
    }
    if (isEquipmentUpdated !== prevProps.isEquipmentUpdated) {
      this.setState({ selectedFacility: this.getSelectedInstalation() });
      const selectedItem = this.getSelectedItem();
      if (selectedFacility) {
        getEquipment(getDecodedUrl(selectedItem.id), selectedFacility?.id, false);
      }
    }
    if (
      prevProps?.facility?.id !== facility?.id ||
      (prevProps?.facility?.id === facility?.id && !facility)
    ) {
      getFacility(getDecodedUrl(match.params.instalationId));
      if (!match.params.tab && match.params.id !== CreationPath_Equipments && selectedFacility) {
        const decoded = generatePath(`${match.path}`, {
          instalationId: match.params.instalationId,
          id: match.params.id,
        });
        history.push(`${decoded}/${match.params.tab}`);
      }
    }

    if (!prevProps?.facility && facility) {
      this.setState({ selectedFacility: this.getSelectedInstalation() });
    }
  }

  componentWillUnmount() {
    resetSignalRConnectionOnReducer();
    resetInstalationGroup();
  }

  refreshEquipments = (equipmentId) => {
    const { getEquipment, needCallEquipment, equipment, goBack, match, history } = this.props;
    const { selectedFacility } = this.state;
    if (!selectedFacility) {
      this.setState({ selectedFacility: this.getSelectedInstalation() });
    }
    if (selectedFacility && needCallEquipment && !equipment) {
      getEquipment(equipmentId, selectedFacility.id, false);
    }

    if (goBack && selectedFacility) {
      history.push(`/Facilities/${match.params.instalationId}/Equipments`);
    }
  };

  getSelectedInstalation = () => {
    const { facility, instalations, match } = this.props;
    if (facility) {
      return facility;
    }
    if (instalations) {
      return instalations.find(
        (instalation) => instalation.id === getDecodedUrl(match.params.instalationId)
      );
    }
    return undefined;
  };

  getSelectedItem = () => {
    const { match, equipment, equipments, t } = this.props;
    if (equipment) {
      if (equipment.equipmentConfiguration?.warnings !== null) {
        const error = equipment.equipmentConfiguration?.warnings?.forEach((warning) => {
          const splittedWarn = warning.split('::');
          toast.error(`${t(`errors.${splittedWarn[0]}`)}: ${splittedWarn[2]}`);
        });
      } else {
        return equipment;
      }
    } else if (equipments) {
      return equipments.find((equipment) => equipment.id === match.params.id);
    }
    return undefined;
  };

  render() {
    const { t, match, getFacility } = this.props;
    const baseURI = `${RootPath_Instalations}/${match.params.instalationId}/Equipments`;
    const selectedItem = this.getSelectedItem();
    const { selectedFacility } = this.state;
    return (
      <GenericIndividualGrow
        Grow={(props) => <EquipmentsGrow {...props} canClone />}
        baseURI={baseURI}
        creationURI={CreationPath_Equipments}
        selectedItem={selectedItem}
        getItem={this.refreshEquipments}
        childGeneralProps={{
          instalationId: selectedFacility ? selectedFacility.id : '',
          selectedFacility,
        }}
      >
        <IndividualEquipmentForm
          selectedFacility={selectedFacility}
          baseURI={baseURI}
          creationURI={CreationPath_Equipments}
          refreshEquipment={this.refreshEquipments}
        />
      </GenericIndividualGrow>
    );
  }
}

const mapStateToProps = (state) => ({
  tabType: state.equipments.tabType,
  equipment: state.equipments.equipment,
  equipments: state.equipments.equipments,
  facility: state.instalations.facility,
  tenants: state.tenants,
  needCallFacility: state.instalations.needCallFacility,
  needCallEquipment: state.equipments.needCallEquipment,
  goBack: state.equipments.goBack,
  isEquipmentUpdated: state.equipments.isEquipmentUpdated,
});
const IndividualEquipmentWithTranslation = withTranslation()(IndividualEquipment);
const IndividualEquipmentWithRouter = withRouter(IndividualEquipmentWithTranslation);

export default connect(mapStateToProps, {
  getEquipment,
  getFacility,
  resetInstalationGroup,
})(IndividualEquipmentWithRouter);
