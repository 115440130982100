export default {
  instalationGroups: {
    exportExcel: 'Scarica Excel',
    importExcel: 'Importa Excel',
    exportPDF: 'Scarica il pdf',
    identification: "Carta d'identità",
    place: 'Fessura',
    enrollFOB: 'Iscriviti al codice',
    config: 'Configurazione',
    deleteConfirmation: 'Sei sicuro di voler eliminare questo gruppo?',
    description: 'Descrizione',
    facilityName: 'Nome della struttura',
    replace: 'Sostituire',
    delete: 'Elimina',
    deleteFobConfirmation: 'Sei sicuro di voler eliminare questo emettitore?',
    alarms: 'Allarme',
    selectConfigFields: 'Scegli i CAMPI DI UTENTE / TRASMETTITORI da esportare',
    totalSelected: 'Totale selezionato',
  },
};
