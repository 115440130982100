import { Box, Flex, Grid, GridItem } from '@jcm-technologies/uikit/dist/atoms/Layout';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt, useHistory } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '@jcm-technologies/uikit/dist/atoms/Typography';
import { Button } from '@jcm-technologies/uikit/dist/atoms/Button';
import { ScheduleForm } from '../../components/Schedule/Form/Detail';
import { WeeklyCalendar } from '../../components/Schedule/Calendar/WeeklyCalendar';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { postSchedules, resetDuplicateSchedule } from '../../modules/schedule/actions';
import { getContrastColor } from '../../core/helpers/colorManager';
import { DayCalendar } from '../../components/Schedule/Calendar/DayCalendar';
import { getHolidays } from '../../modules/holidays/actions';
import { validationRules } from '../../components/Schedule/config';
import { getErrors } from '../../core/old_common/validations/service';
import getOnFlyValidations from '../../core/old_common/utils/validationsApi';
import apiPathFactory from '../../core/old_common/rooting/apiPathFactory';

function ScheduleCreate() {
  const { color1 } = useSelector((state) => state.tenants);
  const { schedule, toDuplicate } = useSelector((state) => state.schedules);
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [data, setData] = useState({
    name: null,
    holidayEntityId: '',
    scheduleEntries: [],
  });
  const [isActiveHolidays, setIsActiveHolidays] = useState(false);
  const [isOpenedEditEvent, setIsOpenedEditEvent] = useState(false);
  const [isOpenedEditEventHolidays, setIsOpenedEditEventHolidays] = useState(false);
  const [errorsForm, setErrosForm] = useState({});
  const [hasChange, setHasChange] = useState(false);

  const validationOnFly = (name, value) => {
    getOnFlyValidations(apiPathFactory.schedulesGetCheckContent(name, value), (isError) => {
      if (isError) {
        setErrosForm((prev) => ({
          ...prev,
          name: {
            error: isError,
            errorMessage: t(`errors.${name}`),
          },
        }));
      }
    });
  };
  const handleBlur = (name) => {
    const validations = validationRules(data);
    validationOnFly('name', data?.name);
    setErrosForm((prev) => ({
      ...prev,
      [name]: getErrors(validations[name]) || false,
    }));
  };
  const handleSubmit = () => {
    handleBlur('name');
    setHasChange(false);

    dispatch(postSchedules(data));
  };

  const handleGoToHolidays = () => history.push('/administration/holidays');

  useEffect(() => {
    dispatch(getHolidays());
    return () => dispatch(resetDuplicateSchedule());
  }, []);

  useEffect(() => {
    if (toDuplicate) {
      setData({
        ...data,
        holidayEntityId: toDuplicate?.holidayEntityId,
        scheduleEntries: toDuplicate?.scheduleEntries,
      });
    }
  }, [toDuplicate]);

  useEffect(() => {
    if (schedule?.id) {
      history.push(`/schedules/${schedule?.id}`);
    }
  }, [schedule]);

  return (
    <Flex
      backgroundColor='white'
      borderRadius={3}
      height='100%'
      overflow='scroll'
      minHeight='calc(100vh - 156px)'
      paddingTop={2}
      flexDirection='column'
      justifyContent='space-between'
    >
      <Box
        borderBottomStyle='solid'
        borderBottomWidth={1}
        borderBottomColor={color1}
        paddingBottom={2}
      >
        <Text textAlign='center' sizeText='display24' fontWeight='bold'>
          {t('general.createEntity', { entity: t('title.schedule') })}
        </Text>
      </Box>
      <ScheduleForm
        data={data}
        handleGoToHolidays={handleGoToHolidays}
        setIsActiveHolidays={setIsActiveHolidays}
        onChangeAction={(name, value) => {
          setData((prev) => ({ ...prev, [name]: value }));
          setHasChange(true);
        }}
        isActiveHolidays={isActiveHolidays}
        handleBlur={handleBlur}
        errors={errorsForm}
      />
      <Grid
        width='100%'
        gridTemplateAreas={[
          "'week week' 'holidays holidays'",
          "'week week' 'holidays holidays'",
          "'week holidays' 'week holidays'",
        ]}
        gridTemplateColumns={['1fr', '1fr', '1fr 275px']}
        gridAutoRows='auto'
        gridGap={2}
        paddingTop={3}
        paddingX={4}
      >
        <GridItem gridArea='week'>
          <WeeklyCalendar
            data={data?.scheduleEntries}
            onChangeAction={(name, value) => {
              setData((prev) => ({ ...prev, scheduleEntries: value }));
              setHasChange(true);
            }}
            isOpenedEditEvent={isOpenedEditEvent}
            setIsOpenedEditEvent={setIsOpenedEditEvent}
          />
        </GridItem>
        <GridItem gridArea='holidays'>
          <DayCalendar
            isActiveHolidays={isActiveHolidays}
            data={data?.scheduleEntries}
            onChangeAction={(name, value) => {
              setData((prev) => ({ ...prev, scheduleEntries: value }));
              setHasChange(true);
            }}
            isOpenedEditEvent={isOpenedEditEventHolidays}
            setIsOpenedEditEvent={setIsOpenedEditEventHolidays}
            isEditingHolidays
          />
        </GridItem>
      </Grid>
      <Prompt when={hasChange || false} message={() => `${t('genericForm.mayNotSave')}`} />

      <Flex alignItems='center' justifyContent='flex-end' paddingY={2} paddingX={4}>
        <Button
          id='saveCreateCalendar'
          sizeButton='small'
          backgroundColor={color1}
          action={() => {
            handleSubmit();
          }}
          disabled={!hasChange}
          marginRight={2}
          color={getContrastColor(color1)}
        >
          {t('general.save')}
        </Button>
        <Button
          backgroundColor='blackLight'
          id='cancel-button'
          sizeButton='small'
          action={() => {
            history.push('/schedules');
          }}
        >
          {t('general.cancel')}
        </Button>
      </Flex>
    </Flex>
  );
}

export default ScheduleCreate;
