import React from 'react';
import Layout from '../../components/Layout';
import './App.css';
import '../../static/dist/css/jcm.css';

const App = () => (
  <>
    <Layout />
  </>
);

export default App;
