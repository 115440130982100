import PathEntity from '../../core/old_common/rooting/apiPaths/PathEntity';

class Routes extends PathEntity {
  constructor() {
    super('statistics');
  }

  getStatisticsAPI = (statisticsType = 1) =>
    this.manufacturePath(`statistics/type/${statisticsType}`);

  getPaths = () => ({
    getStatisticsAPI: this.getStatisticsAPI,
  });
}

export default new Routes().getPaths();
