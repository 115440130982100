export default {
  fobsPopUps: {
    instalations: 'Instalaciones',
    groups: 'Grupos',
    move: 'Mover',
    copy: 'Copiar',
    remove: 'Borrar',
    removeQuestion: 'Quieres borrar los siguientes elementos?',
    selectedItemsShow1: '¿Desea borrar los ',
    selectedItemsShow2: ' elementos seleccionados?',
    warningDeleteFobs:
      'ATENCIÓN: Puede que tenga más elementos seleccionados de los que está visualizando en pantalla.',
    selectedItemsMove1: '¿Desea mover los ',
    selectedItemsCopy1: '¿Desea copiar los ',
    add: 'Añadir',
    selectedItemsAdd1: '¿Desea añadir los ',
  },
};
