import React from 'react';
import { SelectInput } from '@jcm-technologies/uikit/dist/atoms/Select';
import Input from '../Input';
import FileInput from '../FileInput';
import ContryRegion from '../ContryRegion';
import Timezone from '../Timezone';
import Dropdown from '../Dropdown';
import ImageFileInput from '../ImageFileInput';
import SliderColorPicker from '../SliderColorPicker';
import SwitchCheckbox from '../SwitchCheckbox';
import Textarea from '../Textarea';
import ImportExportGrid from '../ImportExportGrid';
import CheckableInput from '../CheckableInput';
import GenericKendoGrid from '../GenericKendoGrid';
import SelectableExportGroupsGrid from '../SelectableExportGroupsGrid';
import DateTimeInput from '../DatTimeInput';
import EquipmentDynamicTabs from '../EquipmentDynamicTabs';
import Label from '../Label/Label';
import IconSpan from '../IconSpan/IconSpan';
import ButtonsGroup from '../ButtonsGroup/ButtonsGroup';
import Password from '../Password/Password';

const genericRender = (CustomInput, fieldDatas) => <CustomInput {...fieldDatas} />;

const fields = [];
fields.input = (fieldDatas) => genericRender(Input, fieldDatas);
fields.file = (fieldDatas) => genericRender(FileInput, fieldDatas);
fields.contryRegion = (fieldDatas) => genericRender(ContryRegion, fieldDatas);
fields.timezone = (fieldDatas) => genericRender(Timezone, fieldDatas);
fields.dropdown = (fieldDatas) => genericRender(Dropdown, fieldDatas);
fields.dropdownSearch = (fieldDatas) => genericRender(SelectInput, fieldDatas);
fields.imageFile = (fieldDatas) => genericRender(ImageFileInput, fieldDatas);
fields.hueColorPicker = (fieldDatas) => genericRender(SliderColorPicker, fieldDatas);
fields.switchCheckbox = (fieldDatas) => genericRender(SwitchCheckbox, fieldDatas);
fields.textarea = (fieldDatas) => genericRender(Textarea, fieldDatas);
fields.importExportGrid = (fieldDatas) => genericRender(ImportExportGrid, fieldDatas);
fields.checkableInput = (fieldDatas) => genericRender(CheckableInput, fieldDatas);
fields.genericKendoGrid = (fieldDatas) => genericRender(GenericKendoGrid, fieldDatas);
fields.selectableKendoGrid = (fieldDatas) => genericRender(SelectableExportGroupsGrid, fieldDatas);
fields.dateTime = (fieldDatas) => genericRender(DateTimeInput, fieldDatas);
fields.equipmentTabs = (fieldDatas) => genericRender(EquipmentDynamicTabs, fieldDatas);
fields.label = (labelDatas) => genericRender(Label, labelDatas);
fields.iconSpan = (iconDatas) => genericRender(IconSpan, iconDatas);
fields.buttonsGroup = (fieldDatas) => genericRender(ButtonsGroup, fieldDatas);
fields.customComponent = (fieldDatas) => genericRender(fieldDatas.component, fieldDatas);
fields.password = (fieldDatas) => genericRender(Password, fieldDatas);

export default fields;
