import { useEffect, useState } from 'react';
import { Modal } from '@jcm-technologies/uikit/dist/molecules/Modal';
import { Flex } from '@jcm-technologies/uikit/dist/atoms/Layout';
import { Button } from '@jcm-technologies/uikit/dist/atoms/Button';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Field } from '@jcm-technologies/uikit/dist/atoms/Field';
import { Switch } from '@jcm-technologies/uikit/dist/atoms/Switch';

const EquipmentConfigurationEventsModal = ({ data, name, isOpen, onClose, onSave, list }) => {
  const [dataForm, setDataForm] = useState(data);
  const { t } = useTranslation();
  const { color1 } = useSelector((state) => state.tenants);
  const [isChanged, setIsChanged] = useState(false);


  useEffect(() => {
    if (isChanged) {
      setDataForm({
        ...dataForm,
      });

      setIsChanged(false);
    }
  }, [isChanged]);

  const valuesActiveSplit = dataForm.split(',');
  return (
    <Modal
      width='100%'
      isOpen={isOpen}
      title={name}
      closeWithButton
      id='modal-config-event'
      onModalClose={onClose}
      maxWidth={[300, 500, 500]}
    >
      <Modal.Content>
        <Flex
          flexDirection='column'
          justifyContent='center'
          alignItems='baseline'
          paddingX={4}
          style={{
            overflow: 'hidden',
            maxHeight: 550,
            overflowY: 'auto',
          }}
        >
          {list?.map((element) => (
            <Flex key={element.localization}>
              <Field
                display='flex'
                label={t(`event.params.${element?.localization}`)}
                id={element.localization}
                width='100%'
                marginTop={1}
                style={{
                  flexDirection: 'row-reverse',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Switch
                  marginRight={2}
                  id={element.value}
                  disabled={!element.enabled}
                  label={t(`event.params.${element?.localization}`)}
                  defaultValue={valuesActiveSplit?.find(
                    (el) => Number(el) === Number(element.value)
                  )}
                  action={(checked, value) => {
                    if (value) {
                      if (checked) {
                        valuesActiveSplit.push(value);
                        const convertDataToString = valuesActiveSplit.toString();
                        setDataForm(convertDataToString);
                      } else {
                        const indexToDelete = dataForm
                          .split(',')
                          .findIndex((val) => Number(val) === Number(value));
                        valuesActiveSplit?.splice(indexToDelete, 1);
                        const convertDataToString = valuesActiveSplit.toString();
                        setDataForm(convertDataToString);
                      }
                    }
                  }}
                />
              </Field>
            </Flex>
          ))}
        </Flex>
      </Modal.Content>
      <Modal.Actions>
        <Flex alignItems='center' justifyContent='flex-end'>
          <Button
            sizeText='display14'
            backgroundColor={color1}
            // Refact: When we refact save parameters, clean this format
            action={() => {
              onSave({
                target: {
                  id: 'LOCALEVENTMANAGEMENTCONFIG',
                  value: dataForm,
                },
              });
              onClose();
            }}
            marginRight={2}
          >
            {t('general.save')}
          </Button>
          <Button sizeText='display14' backgroundColor='blackLight' id='cancel-button'>
            {t('general.cancel')}
          </Button>
        </Flex>
      </Modal.Actions>
    </Modal>
  );
};

export default EquipmentConfigurationEventsModal;
