export default {
  instalationUserGroups: {
    manageFobsAndLicenses: 'Gérer FOBS et licences',
    fobsLicensesGridTitle: 'FOBS / Licences',
    email: 'Courrier électronique',
    phone: 'Téléphone',
    addUser: 'Ajouter un utilisateur',
    deleteUserConfirmation: 'Voulez-vous vraiment supprimer cet utilisateur?',
    Fobs: 'Émetteurs',
    Licenses: 'Licence',
    addFobToUser: "Ajouter FOB à l'utilisateur",
    expiration: 'Expiration',
    licenseStatus: 'État',
    locked: 'Verrouillé',
    unlocked: 'Déverrouillé',
    0: 'Bluetooth',
    1: 'Ouverture à distance',
    users: 'Utilisateurs',
    information: 'Information',
  },
};
