import styled, { createGlobalStyle, css } from 'styled-components';

import { DateTimePicker } from '@progress/kendo-react-dateinputs';
import { getContrastColor } from '../../core/helpers/colorManager';

export default styled(DateTimePicker)`
  ${(props) =>
    `border-bottom-color: ${props.isFocused && css ? props.tenants.color1 : '#b3b3b3'} !important;`}
  &:hover {
    border-bottom-color: ${(props) => props.tenants.color1} !important;
  }
`;

export const DateTimePickerGlobalStyles = createGlobalStyle`
.date-time-input-field-picker .k-textbox::selection,
.date-time-input-field-picker .k-input::selection,
.date-time-input-field-picker .k-textarea::selection {
    background-color: ${(props) => props.tenants.color1};
    color:${(props) => getContrastColor(props.tenants.color1)};
}
`;
