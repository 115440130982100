import React, { Component } from 'react';
import './GenericGrid.css';

export class CustomHeader extends Component {
  render() {
    const { columnKey } = this.props;
    const { name } = this.props.column;
    return <label key={columnKey}>{name}</label>;
  }
}
export default CustomHeader;
