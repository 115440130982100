import PathEntity from '../../core/old_common/rooting/apiPaths/PathEntity';
import { apiVersion, mainPath } from '../../core/old_common/rooting/common';

class Router extends PathEntity {
  constructor() {
    super('notifications');
  }

  get = () => {
    this.mainPath = `${mainPath()}4.2`;
    return this.manufacturePath(this.entityName);
  };

  postClear = (data) => {
    this.mainPath = `${mainPath()}${apiVersion}`;
    const url = this.manufacturePath(`${this.entityName}/clear/${data.id}`);
    return url;
  };

  postReset = () => {
    this.mainPath = `${mainPath()}${apiVersion}`;
    return this.manufacturePath(`${this.entityName}/clear/all`);
  };

  getPaths = () => ({
    notificationsGet: this.get,
    notificationsClear: this.postClear,
    notificationsReset: this.postReset,
  });
}

export default new Router().getPaths();
