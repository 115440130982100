export default {
  resetPassword: {
    email: 'E-Mail',
    newPassword: 'Neues Kennwort',
    backToLogin: 'Zurück zur Anmeldeseite',
    mailReseted: 'Ihre E-Mail wurde wiederhergestellt',
    passwordReset: 'Passwort zurücksetzen',
    send: 'Senden',
  },
};
