export default {
  equipments: {
    add: 'Añadir equipo',
    createEquipment: 'Crear equipo',
    deleteConfirmation: '¿Seguro que quieres eliminar este equipo?',
    facilityName: 'Instalación',
    productName: 'Producto',
    parameters: 'Parámetros',
    relays: 'Relés',
    fobs: 'Emisores',
    groups: 'Grupos',
    info: 'Información',
    actions: 'Acciones',
    events: 'Eventos',
    'remote comm': 'Comunicaciones remotas',
    changePasswordAction: 'Cambiar contraseña',
    adjustDateTimeAction: 'Ajustar fecha y hora',
    formatMemory: 'Formatear memoria',
    disconnectedKey: 'Desconectado',
    connectedKey: 'Conectado',
    notFoundKey: 'Dispositivo no encontrado',
    saveConfiguration: 'Guardar configuración',
    compareMemory: 'Comparar memoria',
    savedMemory: 'Memoria guardada',
    sendMemoryAction: 'Actualizar memoria localmente',
    saveMemoryAction: 'Actualizar memoria remotamente',
    verifyConnectivity: 'Verificando conectividad',
    getdoorstatus: 'Obtener el estado de la puerta',
    device: 'Dispositivo',
    deleteEventsConfirmation: '¿Seguro que quieres eliminar los eventos?',
  },
};
