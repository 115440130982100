export default {
  compareMemory: {
    nodata: 'No hi ha dades disponibles en aquesta taula',
    filter: 'Filtre',
    foundInBoth: 'Trobats a ambdós llocs',
    foundInBBDD: 'Trobats a la base de dades',
    foundInMemory: 'Trobats a la memòria',
    refreshMemory: 'Actualitzar memòria',
    deleteBBDD: 'Eliminar de la base de dades',
    addBBDD: 'Afegir a la base de dades',
    confirmDelete: 'Vols eliminar els emissors?',
    reserved: 'Reservat',
  },
};
