import React from 'react';
import { Icon } from '@jcm-technologies/uikit/dist/atoms/Icon';
import { Box } from '@jcm-technologies/uikit/dist/atoms/Layout';
import IconPlus from '@jcm-technologies/uikit/dist/atoms/Icons/IconPlus';
import { v1 as uuidv1 } from 'uuid';
import { useSelector } from 'react-redux';
import { Button } from '@jcm-technologies/uikit/dist/atoms/Button';
import { useHistory } from 'react-router-dom';
import ToolTip from '../ToolTip/ToolTip';
import { getCookieUserRoles } from '../../core/old_common/utils/cookiesManager';
import { getContrastColor } from '../../core/helpers/colorManager';

export const AddButton = ({ toolTip, url, disabled, onClick, id, redirectData, ignoreRole }) => {
  const toolTipID = uuidv1();
  const role = getCookieUserRoles();
  const roleName = role ? role.name?.toLowerCase() : '';
  const { color1 } = useSelector((state) => state.tenants);
  const history = useHistory();

  return (
    !['basic user'].includes(roleName) &&
    !['property manager'].includes(roleName) &&
    !ignoreRole && (
      <Box position='fixed' bottom={3} right={3}>
        <Button
          id={id}
          data-tip={toolTip}
          data-for={toolTipID}
          backgroundColor={color1}
          action={() =>
            onClick
              ? onClick()
              : history.push({
                  pathname: url,
                  state: redirectData
                    ? { ...redirectData, isCreating: true }
                    : { isCreating: true },
                })
          }
          sizeButton='small'
          disabled={disabled}
          rounded
          padding={2}
        >
          <Icon fontWeight='bold' color={getContrastColor(color1)}>
            <IconPlus />
          </Icon>
        </Button>
        <ToolTip id={toolTipID} />
      </Box>
    )
  );
};

export default AddButton;
