export default {
  fobsPopUps: {
    instalations: 'Létesítmények',
    groups: 'Csoportok',
    move: 'Áthelyezés',
    copy: 'Másolás',
    remove: 'Törlés',
    removeQuestion: 'Biztosan törölni szeretné a következő elemeket?',
    selectedItemsShow1: 'Szeretné törölni ',
    selectedItemsShow2: 'a kiválasztott elemeket?',
    warningDeleteFobs:
      'FIGYELEM: Lehet, hogy több elemet választott ki, mint amennyit a képernyőn lát.',
    selectedItemsMove1: 'Szeretné áthelyezni a ',
    selectedItemsCopy1: 'Szeretné másolni a ',
    add: 'Hozzáadás',
    selectedItemsAdd1: 'Szeretné hozzáadni a ',
  },
};
