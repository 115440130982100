import { useRef } from 'react';
import PropTypes from 'prop-types';
import { Flex, Grid, GridItem } from '@jcm-technologies/uikit/dist/atoms/Layout';
import { Field } from '@jcm-technologies/uikit/dist/atoms/Field';
import { Input } from '@jcm-technologies/uikit/dist/atoms/Input';
import { Button } from '@jcm-technologies/uikit/dist/atoms/Button';
import { UploaderIcon } from '@jcm-technologies/uikit/dist/molecules/Uploader';
import { useTranslation } from 'react-i18next';
import { useMsal } from '@azure/msal-react';
import { editProfileRequest, passwordChangeRequest } from '../../../../core/config/authConfig';
import { getContrastColor } from '../../../../core/helpers/colorManager';

const UserInfo = ({
  name,
  surname,
  tenants,
  avatarState,
  handleAvatarChange,
  handleAvatarDelete,
}) => {
  const { t } = useTranslation();
  const { instance } = useMsal();
  const imgRef = useRef();
  return (
    <Flex
      borderWidth={1}
      borderStyle='solid'
      borderColor='grey'
      borderRadius={3}
      boxShadow='none'
      flexDirection='column'
      alignItems='center'
    >
      <Grid
        gridGap={2}
        gridTemplateAreas={['"." "." "."', '"." "." "."', '". . ."']}
        gridTemplateColumns={['1fr', '1fr', '1fr 1fr 1fr']}
        width='100%'
        height='100%'
        padding={2}
        maxWidth='100%'
      >
        <Field label={t('form.name')}>
          <Input id='name' name='name' defaultValue={name} readOnly />
        </Field>
        <Field label={t('form.surname')}>
          <Input id='surname' name='surname' defaultValue={surname} readOnly />
        </Field>
        <Flex alignItems='center' justifyContent='center' width='100%' marginTop={3}>
          <Button
            id='edit'
            backgroundColor={tenants?.color1}
            action={() => instance.loginRedirect(editProfileRequest)}
            sizeButton='small'
            color={getContrastColor(tenants?.color1)}
          >
            {t('general.edit')}
          </Button>
        </Flex>
      </Grid>
      <Grid
        style={{ flex: 1 }}
        gridGap={2}
        gridTemplateAreas={['"avatar" "."', '"avatar" "."', '"avatar avatar ."']}
        gridTemplateColumns={['1fr', '1fr', '1fr 1fr 1fr']}
        width='100%'
        height='100%'
        padding={2}
        maxWidth='100%'
        alignItems='center'
      >
        <GridItem gridArea='avatar'>
          <Flex justifyContent='center' alignItems='center' height='100%'>
            <Flex maxWidth='150px' minHeight='auto' marginRight={-56}>
              <Flex justifyContent='center' alignItems='center' flexGrow={1}>
                <UploaderIcon
                  imgRef={imgRef}
                  defaultValue={avatarState?.base64}
                  images={[avatarState]}
                  onImagesLoad={handleAvatarChange}
                  messageEdit={t('general.edit')}
                  messageDelete={t('instalationGroups.delete')}
                  handleImageDelete={handleAvatarDelete}
                  name='avatar'
                  themeColor={tenants?.color1}
                />
              </Flex>
            </Flex>
          </Flex>
        </GridItem>
        <Flex alignItems='center' justifyContent='center' width='100%'>
          <Button
            id='changepassword'
            backgroundColor={tenants?.color1}
            action={() => instance.loginRedirect(passwordChangeRequest)}
            sizeButton='small'
            color={getContrastColor(tenants?.color1)}
          >
            {t('form.changePassword')}
          </Button>
        </Flex>
      </Grid>
    </Flex>
  );
};

export default UserInfo;

UserInfo.propTypes = {
  name: PropTypes.string,
  surname: PropTypes.string,
  tenants: PropTypes.shape({
    color1: PropTypes.string,
  }).isRequired,
  avatarState: PropTypes.oneOfType([
    PropTypes.shape({ base64: PropTypes.string }),
    PropTypes.string,
  ]),
  handleAvatarChange: PropTypes.func.isRequired,
  handleAvatarDelete: PropTypes.func.isRequired,
};

UserInfo.defaultProps = {
  name: '',
  surname: '',
  avatarState: '',
};
