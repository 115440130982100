import React, { Component } from 'react';
import { connect } from 'react-redux';
import Input from '../Input/Input';
import SwitchCheckbox from '../SwitchCheckbox/SwitchCheckbox';

import './CheckableInput.css';

class CheckableInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFocused: false,
    };
  }

  setFocusedState = (isFocused) => this.setState({ isFocused });

  render() {
    const { id, placeHolder, isSelected, onCheckChanged, onChange, value, onBlur, disabled } =
      this.props;
    return (
      <div className='config-field-input-container'>
        <Input
          id={id}
          type='text'
          value={value}
          placeHolder={placeHolder}
          isFocused={this.state.isFocused}
          onChange={onChange}
          disable={disabled}
          onFocus={(e) => this.setFocusedState(true)}
          onBlur={(e) => {
            onBlur(e.currentTarget.id, e);
            this.setFocusedState(false);
          }}
        />
        <SwitchCheckbox onChange={(e) => onCheckChanged(id, e)} value={isSelected} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(CheckableInput);
