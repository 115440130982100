export default {
  gsmPopUp: {
    title: 'Dienststatus',
    deviceNotSimInfo: 'Gerät ist offline',
    simContractInfo: 'Vertragsinformationen',
    expiration: 'Ablaufdatum',
    status: 'Status',
    infoNotAvailable: 'Informationen nicht verfügbar',
    simConnectionInfo: 'SIM-Verbindungsinformationen',
    cardIdentifier: 'Kennung der Karte (ICCID)',
    activationDate: 'Aktivierungsdatum',
    lastMonthDataUpload: 'Daten-Upload im letzten Monat',
    lastMonthDataDownload: 'Daten-Download im letzten Monat',
    lastSeen: 'Zuletzt gesehen',
    contractStatus: 'Vertragsstatus',
    simStatus: 'SIM-Status',
    deviceStatus: 'Gerätestatus',
    enabled: 'Aktiviert',
    disabled: 'Deaktiviert',
    notAvailable: 'Nicht verfügbar',
    connected: 'Verbunden',
    disconnected: 'getrennt',
  },
};
