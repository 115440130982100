import React from 'react';
import Popup from 'reactjs-popup';
import './Popup.css';

const PopUps = ({
  children,
  isOpen,
  className,
  onClose,
  closeOnDocumentClick,
  closeOnEscape,
  onCloseIconClick,
  hideCloseButton,
}) => (
  <Popup
    closeOnDocumentClick={closeOnDocumentClick !== undefined ? closeOnDocumentClick : false}
    open={isOpen}
    position='right center'
    className={className}
    onClose={onClose}
    closeOnEscape={closeOnEscape !== undefined ? closeOnEscape : true}
  >
    {!hideCloseButton && (onCloseIconClick || onClose) ? (
      <span className='fas fa-times closePopupIcon' onClick={onCloseIconClick || onClose} />
    ) : null}
    {children}
  </Popup>
);
export default PopUps;
