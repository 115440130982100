import { constants } from '../config';

const initialState = {
  show: 0,
  colorType: null,
};

export default function setAlertReducer(state = initialState, action) {
  switch (action.type) {
    case constants.SHOW_ALERT: {
      const nextShowValue = state.show + action.value;
      return {
        ...state,
        show: nextShowValue > 0 ? nextShowValue : false,
        colorType: action.colorType || state.colorType,
        message: action.message || state.message,
      };
    }
    default:
      return state;
  }
}
