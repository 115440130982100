import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { SortableContainer, SortableElement, sortableHandle, arrayMove } from 'react-sortable-hoc';
import ContactNeighbour from './ContactNeighbour';
import IconSpan from '../../IconSpan';

export class IndividualNeighbour extends Component {
  constructor(props) {
    super(props);
    this.state = {
      facilityEntityContacts: props.neighbour.facilityEntityContacts,
      isNewOrder: false,
      neighbour: props.neighbour,
      isOpen: true,
    };
  }

  componentDidUpdate = () => {
    const { facilityEntityContacts, isNewOrder } = this.state;
    if (!_.isEqual(this.props.facilityEntityContacts, facilityEntityContacts) && !isNewOrder) {
      this.setState({
        facilityEntityContacts: this.props.facilityEntityContacts,
        neighbour: this.props.neighbour,
      });
    }
  };

  getDoorAndFloor = () => {
    const { code, codeFormat } = this.state.neighbour;
    let floor = '';
    let door = '';
    if (codeFormat === '0@00') {
      floor = code.substring(0, 1);
      door = code.substring(1, 3);
    } else if ('00@0' && code.length === 3) {
      floor = code.substring(0, 2);
      door = code.substring(2, 3);
    } else {
      floor = code.substring(0, 1);
      door = code.substring(1, 2);
    }
    return { floor, door };
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { onAccept, neighbour } = this.props;
    const newFAcilityContacts = [
      ...arrayMove(neighbour.facilityEntityContacts, oldIndex, newIndex),
    ];
    this.setState({
      facilityEntityContacts: newFAcilityContacts,
      isNewOrder: true,
    });
    // TODO: JAVI
    const { contact } = newFAcilityContacts[oldIndex];
    contact.fromPosition = oldIndex + 1;
    contact.toPosition = newIndex + 1;
    onAccept(contact, newFAcilityContacts[oldIndex].id);
  };

  getSortableItems = () => {
    const { neighbour, facilityId, equipmentId } = this.props;
    const DragHandle = sortableHandle(() => (
      <IconSpan iconClassName='fas fa-sort' defaultColor='#a4aebb' cursor='pointer' />
    ));

    const SortableItem = SortableElement(({ contact, handleEdit, handleDelete }) => (
      <ContactNeighbour
        DragHandle={() => <DragHandle />}
        key={contact.id}
        {...contact}
        handleEdit={handleEdit}
        handleDelete={handleDelete}
        facilityId={facilityId}
        equipmentId={equipmentId}
      />
    ));

    const SortableList = SortableContainer(({ items }) => {
      const { handleEdit, handleDelete } = this.props;
      return (
        <ul>
          {items.map((contact, index) => (
            <SortableItem
              key={contact.id}
              index={index}
              contact={contact}
              handleEdit={handleEdit}
              handleDelete={handleDelete}
            />
          ))}
        </ul>
      );
    });
    return (
      <SortableList
        useDragHandle
        items={neighbour.facilityEntityContacts}
        onSortEnd={this.onSortEnd}
      />
    );
  };

  onNeigChange = (e) => {
    const { neighbour } = this.state;
    e?.stopPropagation();
    e?.preventDefault();
    this.setState({ [e.target.id]: e.target.value });
    const newNeig = { ...neighbour };
    newNeig[e.target.id] = e.target.value;
    this.setState({ neighbour: newNeig });
  };

  onNeigBlur = (e) => {
    const { onAccept } = this.props;
    const { neighbour } = this.state;
    const newNeig = { ...neighbour.facilityEntityContacts[0].contact };
    newNeig.description = e.target.value;
    delete newNeig.code;
    this.setState({ isNewOrder: false });
    onAccept(newNeig, neighbour.facilityEntityContacts[0].id);
  };

  render() {
    const { description, tenants } = this.state.neighbour;
    const { floor, door } = this.getDoorAndFloor();
    return (
      <div>
        <div
          onClick={() => this.setState({ isOpen: !this.state.isOpen })}
          className='individual-neighbour-container'
        >
          <span>
            {this.state.isOpen ? (
              <IconSpan
                tenants={tenants}
                id='edit'
                defaultColor='#a4aebb'
                iconClassName='generic-grow-action-button fas fa-angle-down'
                cursor='pointer'
              />
            ) : (
              <IconSpan
                tenants={tenants}
                id='edit'
                defaultColor='#a4aebb'
                iconClassName='generic-grow-action-button fas fa-angle-up'
                cursor='pointer'
              />
            )}
          </span>
          <span>{floor}</span>
          <span>{door}</span>
          <span>
            <input
              type='text'
              id='description'
              className='input-neighbours'
              onBlur={this.onNeigBlur}
              onChange={this.onNeigChange}
              value={description}
            />
          </span>
        </div>
        <div className={this.state.isOpen ? '' : 'field-display-none'}>
          {this.getSortableItems()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(IndividualNeighbour);
