import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Flex } from '@jcm-technologies/uikit/dist/atoms/Layout';
import { Card } from '@jcm-technologies/uikit/dist/molecules/Card';
import { Text } from '@jcm-technologies/uikit/dist/atoms/Typography';
import { Pagination } from '@jcm-technologies/uikit/dist/molecules/Pagination';
import IconTrash from '@jcm-technologies/uikit/dist/atoms/Icons/IconTrash';
import { IconTooltip } from '@jcm-technologies/uikit/dist/molecules/IconTooltip';
import IconSearch from '@jcm-technologies/uikit/dist/atoms/Icons/IconSearch';
import { Input } from '@jcm-technologies/uikit/dist/atoms/Input';
import { deleteOwner, getGDPRSingleList } from '../../../../modules/owner/action';
import { setSearchQuery } from '../../../../modules/state/visibleDescendants/actions';
import { GDPR_LIST } from '../../../../modules/state/pagination/constants';
import { setPageNumber } from '../../../../modules/state/pagination/actions';
import { ModalOwnerDelete } from '../Modal';

export function ListClearGDPR() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { ownersSingleList } = useSelector((state) => state.owners);
  const { totalPages, pagenumber } = useSelector((state) => state.backendParams);
  const { searchQuery } = useSelector((state) => state.visibleDescendants);
  const [isOpened, setIsOpened] = useState(false);
  const [selected, setSelected] = useState(null);
  const getList = (value = '') => dispatch(getGDPRSingleList(value));
  const handlePageChange = (infoPage) => {
    dispatch(setPageNumber(GDPR_LIST, infoPage?.actualPage));
    getList();
  };
  const handleDelete = (id) => {
    dispatch(deleteOwner(id));
    setIsOpened(false);
  };
  useEffect(() => {
    getList();
  }, []);

  return (
    <Box paddingTop={4} maxWidth={[225, 622, 800, '100%']}>
      <Input
        id='user-filter'
        name='user-filter'
        sizeInput='small'
        iconRight={<IconSearch />}
        iconRightAction={() => getList(searchQuery)}
        onChange={(name, value) => {
          dispatch(setSearchQuery(value || '', !!value));
        }}
      />

      {ownersSingleList?.map((el) => (
        <Card as='article' width='100%' maxWidth='100%' position='relative' shadow='bottom'>
          <Flex
            paddingY={1}
            paddingX={2}
            marginY={1}
            justifyContent='space-between'
            alignItems='center'
          >
            <Text sizeText='display14'>{el.email}</Text>
            <IconTooltip
              sizeIcon='display16'
              cursor='pointer'
              color='blackLight'
              toolTipContent={t('general.delete')}
              tooltipProps={{
                place: 'top',
              }}
              onClick={() => {
                setIsOpened(true);
                setSelected(el);
              }}
            >
              <IconTrash />
            </IconTooltip>
          </Flex>
        </Card>
      ))}
      <Flex alignItems='center' justifyContent='center' paddingY={2}>
        {!!totalPages && (
          <Pagination
            actionChangePage={(infoPage) => {
              handlePageChange(infoPage);
            }}
            initNumber={pagenumber[GDPR_LIST]}
            maxPageVisibleNumber={5}
            minPageNumber={1}
            totalPages={totalPages}
          />
        )}
      </Flex>
      <ModalOwnerDelete
        isOpened={isOpened}
        setIsOpened={setIsOpened}
        data={selected}
        action={handleDelete}
      />
    </Box>
  );
}
