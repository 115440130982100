import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  main {
    background-image: url('${(props) => props.tenants.backgroundimage}');
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
  }

  main .rat-TimeSlot_component {
    background-color: ${(props) => props.tenants.color1};
  }
`;

export default GlobalStyle;
