export default {
  remoteState: {
    noContent: 'Non disponible',
    title: 'État distant',
    doorState0: 'Indéfini',
    doorState1: 'Ouverte',
    doorState2: 'Fermée',
    doorState3: 'Ouverture',
    doorState4: 'Fermeture',
    doorState5: "Arrêter l'ouverture",
    doorState6: 'Arrêter la fermeture',
    doorState7: 'En attente de fermeture automatique',
    deviceType: "Type d'appareil",
    connectionSignal: 'Signal de connexion',
    rssiValue: 'Valeur RSSI',
    hwVersion: 'Version hardware',
    swVersion: 'Version software ',
    doorStateTitle: 'État de la porte',
    inputStateTitle: "État d'entrée",
    deviceInfoTitle: "Informations sur l'appareil",
    relayTitle: "Temps d'activation en sec",
    relaysTitle: 'Relais',
    refreshStatus: "Actualiser l'état",
    activateRel1: 'Activer le relais 1',
    activateRel2: 'Activer le relais 2',
    doorState: 'État',
    activateRelError: "L'appareil ne répond pas",
    activateRelSuccess: 'Action terminée avec succès',
    connectionSignalUnknown: 'Inconnu',
    connectionSignalExcellent: 'Excellent',
    connectionSignalGood: 'Bien',
    connectionSignalFair: 'OK',
    connectionSignalWeak: 'Marginal',
    connectionType: 'Type de connexion',
    deviceType0: 'eBase',
    deviceType1: 'eConnect',
    deviceType2: 'BtConnect',
    deviceType3: 'Sesame',
    deviceType4: 'Blink',
    deviceType5: 'eAccess',
    deviceType6: 'Vdp',
    deviceType7: 'HONOADOOR / EBASEDOOR',
    deviceType8: 'HONOARELAY',
    sensors: 'Capteurs',
    open: 'Ouvert',
    battery: 'batterie',
    deactivated: 'Désactivé',
    activated: 'Activé',
  },
};
