import React, { Component } from 'react';
// import Button from '../../../GenericForm/Button';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { putDeviceRelay, resetRelayActivation } from '../../../../modules/old_to_refact/actions/devices';
import InformationRelay from './InformationRelay';

class DeviceInformationRelays extends Component {
  componentDidUpdate(prevProps, prevState) {
    const { relayActivation, t, resetRelayActivation } = this.props;
    if (!prevProps.relayActivation && relayActivation) {
      if (relayActivation.error) {
        toast.error(t('remoteState.activateRelError'));
      } else {
        toast.success(t('remoteState.activateRelSuccess'));
      }
      resetRelayActivation();
    }
  }

  render() {
    const { device, putDeviceRelay, t, driverState } = this.props;
    return (
      <div className='device-information-relay-container'>
        <h6 className='bold'>{t('remoteState.relaysTitle')}</h6>
        {driverState.relays[0] ? (
          <InformationRelay
            label={
              driverState ? `${driverState.relays[0].name} ${t('remoteState.relayTitle')}` : 'Relay'
            }
            id='1'
            putRelay={putDeviceRelay}
            buttonText={t('remoteState.activateRel1')}
            device={device}
            driverState={driverState}
          />
        ) : (
          ''
        )}
        {driverState.relays[1] ? (
          <InformationRelay
            label={
              driverState ? `${driverState.relays[1].name} ${t('remoteState.relayTitle')}` : 'Relay'
            }
            id='2'
            putRelay={putDeviceRelay}
            buttonText={t('remoteState.activateRel2')}
            device={device}
            driverState={driverState}
          />
        ) : (
          ''
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  relayActivation: state.devices.relayActivation,
});

const DeviceInformationRelaysWithTranslation = withTranslation()(DeviceInformationRelays);

export default connect(mapStateToProps, { putDeviceRelay, resetRelayActivation })(
  DeviceInformationRelaysWithTranslation
);
