import React, { Component } from 'react';
import { connect } from 'react-redux';
import { v1 as uuidv1 } from 'uuid';
import { withTranslation } from 'react-i18next';
import GenericForm from '../../GenericForm/GenericForm';

class FacilityEquipmentRelaySchedules extends Component {
  constructor(props) {
    super(props);
    this.state = {
      relayId: props.relayId || uuidv1(),
      relayName: props.relayName || '',
      relayPosition: props.relayPosition || 0,
      relayAssignationId: props.relayAssignationId || uuidv1(),
      scheduleId: props.scheduleId || uuidv1(),
      isUniversal: props.isUniversal,
      channelsMap: props.channelsMap || 0,
    };
  }

  handleChange = (e) => {
    const {
      putRelay,
      secondaryId,
      principalId,
      principalParameter,
      secondaryParameter,
      equipmentId,
    } = this.props;
    const newRelay = { ...this.state, scheduleId: e.target.value, equipmentId };
    putRelay({
      [principalParameter]: principalId,
      [secondaryParameter]: secondaryId,
      relay: newRelay,
    });
    this.setState({ scheduleId: e.target.value });
  };

  getSchedulesFields = () => {
    const { schedulesSingleList, relayName, channelsMap, t } = this.props;
    const fields = [
      [
        {
          type: 'dropdown',
          values: schedulesSingleList
            ? [
                { key: '', id: '', value: '--' },
                ...schedulesSingleList.map((schedule) => ({
                  key: schedule.scheduleId,
                  id: schedule.scheduleId,
                  value: schedule.name,
                })),
              ]
            : [],
          placeHolder: channelsMap > 0 ? relayName : t('relaysConfiguration.relayNotassigned'),
          onChange: this.handleChange,
          value: this.state.scheduleId,
          isDisabled: channelsMap <= 0,
          id: 'scheduleId',
        },
      ],
    ];
    return fields;
  };

  render() {
    const { tenants } = this.props;
    return (
      <GenericForm
        cancelExitConfirmation
        tenants={tenants}
        fields={this.getSchedulesFields()}
        isSubmitHidden
      />
    );
  }
}

const mapStateToProps = (state) => ({
  tenants: state.tenants,
  schedulesSingleList: state.schedules.singleSchedules,
});

const FacilityEquipmentRelaySchedulesWithTranslation = withTranslation()(
  FacilityEquipmentRelaySchedules
);

export default connect(mapStateToProps, {})(FacilityEquipmentRelaySchedulesWithTranslation);
