export default {
  universalGroups: {
    description: 'Description',
    informationTab: 'Information',
    expand: 'Expand',
    collapse: 'Collapse',
    delete: 'Delete',
    add: 'Add new universal group',
    create: 'Create universal group',
    instalationsTab: 'Facilities',
    fobsTab: 'Fobs',
    deleteConfirmation: 'Are you sure you want to delete this group?',
  },
};
