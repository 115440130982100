import React, { Component } from 'react';
import { v1 as uuidv1 } from 'uuid';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { generatePath, withRouter } from 'react-router-dom';
import IndividualGrowGenericForm from '../../../../old_components/IndividualGrowGenericForm/IndividualGrowGenericForm';
import validationsDictionary from '../../../../core/old_common/validations';
import {
  postEquipment,
  putEquipment,
  resetEquipmentList,
  resetEquipments,
  setEquipmentScroll,
} from '../../../../modules/old_to_refact/actions/equipments';
import {
  getFamiliesSingleList,
  resetFamilyProducts,
} from '../../../../modules/productFamilies/action';
import { postNeighbour, putNeighbour } from '../../../../modules/old_to_refact/actions/neighbours';
import { capitalizeText } from '../../../../core/old_common/utils/objectsService';
import { setOnFlyErrors } from '../../../../core/old_common/validations/service';
import getOnFlyValidations from '../../../../core/old_common/utils/validationsApi';
import apiPathFactory from '../../../../core/old_common/rooting/apiPathFactory';
import { getFamilyListByType, getProduct } from '../../../../core/old_common/utils/familiesService';
import { showAlert } from '../../../../modules/state/alerts';

class IndividualEquipmentForm extends Component {
  constructor(props) {
    super(props);
    this.defaultModelId = uuidv1();
    this.state = {
      onFlyFormErrors: [],
      isButtonVisible: false,
      isExitConfirmation: false,
      parametersScroll: 0,
    };
  }

  componentDidMount = () => {
    const { getFamiliesSingleList } = this.props;
    getFamiliesSingleList(3, true);
  };

  componentDidUpdate(prevProps) {
    const { equipment, equipmentConfigurationInfo, t } = this.props;
    const serialNumber = equipment?.serialNumber;

    if (
      prevProps.match.params.tab !== this.props.match.params.tab &&
      this.state.isExitConfirmation
    ) {
      this.setState({ isExitConfirmation: false });
    }
    if (equipment?.fwUpdated === false) {
      this.props.showAlert(
        'red',
        1,
        `${t('alert.fotarequest')}: ${t('general.updatesoftwaredevice')}: ${serialNumber}`
      );
    }
  }

  componentWillUnmount = () => {
    this.props.resetFamilyProducts();
    this.props.resetEquipments();
    this.props.resetEquipmentList();
  };

  createNewState = (props) => {
    const isCreateMode = props.match.params.id === this.props.creationURI;
    if (isCreateMode) {
      const products = this.getProducts();
      const firstProductId = products.length
        ? products.find((product) => !product.disabled).id
        : '';

      if (this.state.productParams) {
        props.equipmentConfiguration.tabs[0].itemsList = this.state.productParams;
      }

      return {
        id: isCreateMode ? '' : props.id,
        name: props.name || '',
        productId: props.productId || firstProductId || '',
        equipmentConfiguration: props.equipmentConfiguration || { tabs: [] },
        facilityEquipmentAttributes: props.facilityEquipmentAttributes || [],
        productParams: props.equipmentParameters || [],
        facilityId: props.instalationId,
      };
    }

    return {
      id: props.id,
      name: props.name,
      productId: props.productId || '',
      productName: props.productName,
      equipmentConfiguration: props.equipmentConfiguration || { tabs: [] },
      facilityEquipmentAttributes: props.facilityEquipmentAttributes || [],
      productParams: props.equipmentParameters || [],
      facilityId: props.instalationId,
    };
  };

  getProducts = () => {
    const productsArray = [];
    const { familiesSingleList } = this.props;
    const familiesByType = getFamilyListByType(familiesSingleList, 3);
    if (familiesByType) {
      const families = familiesByType.flat();
      families.forEach((family, i) => {
        productsArray.push({
          key: family.section,
          id: family.section,
          value: family.section,
          disabled: true,
          className: 'enroll-code-combo-family',
        });
        family.items.forEach((product) => {
          productsArray.push({
            key: product.productId,
            id: product.productId,
            value: product.name,
          });
        });
      });
    }
    return [{ key: '', id: '', value: '--' }, ...productsArray];
  };

  getProduct = (productId) => {
    const { familiesSingleList } = this.props;
    const families = familiesSingleList.flat();
    const products = families.map((family) => family.items).flat();
    return products.find((product) => product.productId === productId);
  };

  setParamsOnForm = (productParams) => {
    this.setState({ productParams });
  };

  setErrorsCallback = ({ onFlyFormErrors, error, id, errorMessage }) => {
    this.setState({
      onFlyFormErrors: setOnFlyErrors({ onFlyFormErrors, error, id, errorMessage }),
    });
  };

  validationOnFly = (name, value, id) => {
    const { t } = this.props;
    getOnFlyValidations(apiPathFactory.equipmentsGetCheckContent(name, value, id), (isError) =>
      this.setErrorsCallback({
        onFlyFormErrors: this.state.onFlyFormErrors,
        error: isError,
        id: name,
        errorMessage: t(`errors.${name}`),
      })
    );
  };

  getFields = (newFields) => {
    const {
      t,
      facilityId,
      id,
      instalationId,
      match,
      creationURI,
      configFieldsEquipments,
      facilityEquipmentAttributes,
      baseURI,
      history,
      name,
      facility,
      familiesSingleList,
      selectedFacility,
      parametersScroll,
    } = this.props;
    const isCreation = creationURI === match.params.id;
    const products = this.getProducts();
    const fields = [
      isCreation
        ? [
            {
              type: 'input',
              placeHolder: `${t('form.name')} *`,
              value: 'name',
              id: 'name',
              validation: [
                () => validationsDictionary.isRequired(newFields.name),
                () => validationsDictionary.isInTextLength(newFields.name, 100),
                () => validationsDictionary.isNotCreationName(newFields.name, 'EQUIPMENT'),
              ],
              onBlur: () =>
                newFields.name !== this.props.name
                  ? this.validationOnFly('name', newFields.name, instalationId)
                  : this.setErrorsCallback({
                      onFlyFormErrors: this.state.onFlyFormErrors,
                      error: undefined,
                      id: 'name',
                    }),
            },
            {
              type: isCreation ? 'dropdown' : 'input',
              values: isCreation ? products : undefined,
              placeHolder: `${t('equipments.device')} *`,
              value: isCreation ? 'productId' : 'productName',
              id: isCreation ? 'productId' : 'productName',
              isDisabled: match.params.id !== creationURI,
              disable: true,
              validation: [() => validationsDictionary.isRequired(newFields.productId)],
            },
          ]
        : [],
      // ...generateFields(configFieldsEquipments, 4, 2, 'facilityEquipmentAttributes'),
      [
        {
          type: 'equipmentTabs',
          value: 'equipmentConfiguration',
          id: 'equipmentConfiguration',
          notShow: match.params.id === creationURI,
          equipmentId: id,
          setParamsOnForm: (productParams) => this.setParamsOnForm(productParams),
          facilityEquipmentAttributes,
          facilityId,
          parametersScroll,
          product: getProduct(getFamilyListByType(familiesSingleList, 3), newFields.productId),
          equipment: this.createNewState(this.props),
          facility: selectedFacility || facility,
          updateItem: this.formSubmitEvent,
          selectedTab: match.params.tab,
          saveButtonIsEnabledCallback: (buttonVisible) => this.isButtonVisible(buttonVisible),
          setExitConfirmationCallback: this.setExitConfirmationCallback,
          onClick: (e, tabName) => {
            const decoded = generatePath(`${match.path}`, {
              instalationId: encodeURIComponent(selectedFacility?.id),
              id: match.params.id,
              tab: tabName && capitalizeText(tabName),
            });
            history.push(`${decoded}`);
          },
        },
      ],
    ];
    return fields;
  };

  isButtonVisible = (buttonVisible) => {
    if (buttonVisible !== this.state.isButtonVisible) {
      this.setState({ isButtonVisible: buttonVisible });
    }
  };

  setExitConfirmationCallback = (isExitConfirmation) => {
    if (this.state.isExitConfirmation !== isExitConfirmation) {
      this.setState({ isExitConfirmation });
    }
  };

  formSubmitEvent = (equipment, type) => {
    const newEquipemnt = {
      ...equipment,
    };
    this.setState({ updatedEquipment: equipment, isExitConfirmation: false });
    const { instalationId, postEquipment, putEquipment, creationURI, match, setEquipmentScroll } =
      this.props;
    newEquipemnt.productParams = this.state ? this.state.productParams : newEquipemnt.productParams;
    const parameterTabIndex = newEquipemnt.equipmentConfiguration.tabs.findIndex(
      (x) => x.name === 'Parameters'
    );

    if (parameterTabIndex >= 0) {
      newEquipemnt.equipmentConfiguration.tabs[parameterTabIndex].itemsList =
        newEquipemnt.productParams;
    }
    if (decodeURI(match.params.id) !== creationURI) {
      setEquipmentScroll();
      putEquipment(instalationId, newEquipemnt, type);
    } else {
      postEquipment(instalationId, newEquipemnt, type);
    }
  };

  createAttributesOnChange = (value, field) => ({
    id: uuidv1(),
    type: 4,
    tag: field.value,
    equipmentId: this.props.id,
    value,
  });

  formatFormStateToPost = (formState) => {
    const newFormState = { ...formState, id: formState.id || uuidv1() };
    if (newFormState.facilityEquipmentAttributes) {
      newFormState.facilityEquipmentAttributes.forEach((attribute) => {
        attribute.equipmentId = newFormState.id;
      });
    }
    return newFormState;
  };

  render() {
    const { t, baseURI, refreshEquipment, creationURI, equipment, match } = this.props;
    return (
      <div className='form'>
        <IndividualGrowGenericForm
          item={this.createNewState(this.props)}
          updateItem={this.formSubmitEvent}
          isSubmitHidden={creationURI !== match.params.id}
          createItem={this.formSubmitEvent}
          baseURI={encodeURI(baseURI)}
          creationURI={creationURI}
          getItem={refreshEquipment}
          goToIdRedirect={equipment?.id}
          fields={(newFields) => this.getFields(newFields)}
          createItemOnChange={this.createAttributesOnChange}
          getContentToCreate={this.formatFormStateToPost}
          submitText={t('general.save')}
          onFlyformErrors={this.state.onFlyFormErrors}
          isSaveButtonEnabled={this.state.isButtonVisible}
          forceSaveChangePopup={
            this.state.isExitConfirmation && this.props.match.params.tab === 'Info'
          }
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  familiesSingleList: state.families.familiesSingleList,
  originalTenants: state.tenants.originalTenants,
  tenants: state.tenants,
  equipmentParameters: state.instalations.equipmentParameters,
  sessionExpired: state.popup.isOpenAuth,
  configFieldsEquipments: state.equipments.configFieldsEquipments,
  parametersScroll: state.equipments.parametersScroll,
  equipment: state.equipments?.equipment,
  equipmentConfigurationInfo: state.equipments?.equipment?.equipmentConfiguration?.tabs[0],
});

const IndividualEquipmentFormWithTranslation = withTranslation()(IndividualEquipmentForm);
const IndividualEquipmentFormWithRouter = withRouter(IndividualEquipmentFormWithTranslation);

export default connect(mapStateToProps, {
  postEquipment,
  postNeighbour,
  putEquipment,
  putNeighbour,
  getFamiliesSingleList,
  resetFamilyProducts,
  resetEquipments,
  resetEquipmentList,
  setEquipmentScroll,
  showAlert,
})(IndividualEquipmentFormWithRouter);
