import PathEntity from './PathEntity.js';
import { apiVersion, mainPath } from '../common.js';

class UniversalGroups extends PathEntity {
  constructor() {
    super('universalGroups');
  }

  get = (id) => this.manufacturePath(`${this.entityName}/${id}`);

  post = () => this.manufacturePath(this.entityName);

  put = (id) => this.manufacturePath(`${this.entityName}/${id}`);

  getSingleList = (id, descendants, exceptMe) => {
    let finalEndpoint = this.manufacturePath(`${this.entityName}/${id}/singlelist`);
    finalEndpoint = this.getEndPointWithVisibleDescendants(
      finalEndpoint,
      descendants,
      'CustomerIds'
    );
    if (exceptMe !== undefined) {
      finalEndpoint = this.addParameterToEndpoint(finalEndpoint, 'exceptme', exceptMe);
    }
    return finalEndpoint;
  };

  delete = (id) => this.manufacturePath(`${this.entityName}/${id}`);

  deleteFacility = (facilityId, universalGroupId) =>
    this.manufacturePath(`universalgroups/${universalGroupId}/facilities/${facilityId}`);

  getInfo = (facilityId, singleList, allocated) =>
    this.manufacturePath(
      `${this.entityName}/${facilityId}/facilities?maps=${singleList}&allocated=${allocated}`
    );

  checkContent = (columnName, value) => {
    let finalEndpoint = this.manufacturePath(`${this.entityName}/checkcontent`);
    finalEndpoint = this.addParameterToEndpoint(finalEndpoint, 'columnName', columnName);
    finalEndpoint = this.addParameterToEndpoint(finalEndpoint, 'value', value);
    return finalEndpoint;
  };

  facilityGetEquipmentRelays = (item) => {
    const endpoint = this.manufacturePath(
      `${this.entityName}/facilities/${item.facilityId}/equipmentsrelays`
    );
    return this.pagedEndPoint(endpoint, item.pageSize, item.pageNumber);
  };

  putRelaysUG = ({ id, universalGroupId, equipmentId, relayId, assignationId }) => {
    this.mainPath = `${mainPath()}4.1`;
    const endpoint = this.manufacturePath(
      `universalgroups/${universalGroupId}/facilities/${id}/equipments/${equipmentId}/relays/${relayId}/assignation/${assignationId}`
    );
    this.mainPath = `${mainPath()}${apiVersion}`;
    return endpoint;
  };

  getUGRelays = (facilityId, universalGroupId) =>
    this.manufacturePath(
      `universalgroups/${universalGroupId}/facilities/${facilityId}/equipmentsrelays`
    );

  getFacilities = ({
    universalGroupId,
    singleList,
    allocated,
    pageSize,
    pageNumber,
    searchQuery,
    selectedDescendants,
  }) => {
    let endpoint = this.manufacturePath(`universalgroups/${universalGroupId}/facilities/`);
    if (singleList) {
      endpoint = this.addParameterToEndpoint(endpoint, 'singleList', singleList);
      endpoint = this.addParameterToEndpoint(endpoint, 'allocated', allocated);
      endpoint = this.getEndPointWithVisibleDescendants(endpoint, searchQuery, 'queryText');
      endpoint = this.getEndPointWithVisibleDescendants(
        endpoint,
        selectedDescendants,
        'CustomerIds'
      );
    }
    return this.pagedEndPoint(endpoint, pageSize, pageNumber);
  };

  getPaths = () => ({
    universalGroupsGetInfo: this.getInfo,
    universalGroupsGetPage: this.getPaged,
    universalGroupsPost: this.post,
    universalGroupsPut: this.put,
    universalGroupGet: this.get,
    universalGroupsDelete: this.delete,
    universalGroupsFacilityDelete: this.deleteFacility,
    universalGroupsGetCheckContent: this.checkContent,
    universalGroupsGetEquipmentRelays: this.facilityGetEquipmentRelays,
    universalGroupEquipmentRelaysGet: this.getUGRelays,
    universalGroupEquipmentRelaysPut: this.putRelaysUG,
    universalGroupGetFacilities: this.getFacilities,
    universalGroupGetSingleList: this.getSingleList,
  });
}

export default new UniversalGroups().getPaths();
