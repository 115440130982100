import React, { Component } from 'react';
import { connect } from 'react-redux';
import { v1 as uuidv1 } from 'uuid';
import Span from './Span';
import ToolTip from '../ToolTip/ToolTip';

export class IconSpan extends Component {
  constructor(props) {
    super(props);
    this.toolTipID = uuidv1();
  }

  render() {
    const {
      toolTip,
      iconClassName,
      onClick,
      isIncreaseBrightnes,
      defaultColor,
      id,
      disabled,
      secondaryId,
      icon,
      ...rest
    } = this.props;
    return (
      <span id={secondaryId || ''}>
        <Span
          as='i'
          {...this.props}
          id={id}
          isIncreaseBrightnes={isIncreaseBrightnes}
          data-tip={toolTip}
          data-for={this.toolTipID}
          customDefaultColor={defaultColor}
          className={iconClassName}
          onClick={disabled || !onClick ? null : onClick}
          {...rest}
        >
          {icon && icon}
        </Span>
        <ToolTip id={this.toolTipID} />
      </span>
    );
  }
}

const mapStateToProps = (state) => ({
  tenants: state.tenants,
});

export default connect(mapStateToProps, {})(IconSpan);
