export default {
  neighbourPopUp: {
    title: 'Afegir un veí',
    email: 'E-Mail',
    phoneNumber: 'Número de telèfon',
    floor: 'Planta',
    door: 'Porta',
    titleEdit: 'Editar contacte',
    titleDelete: 'Segur que vols esborrar contacte?',
  },
};
