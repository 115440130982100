import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { Flex } from '@jcm-technologies/uikit/dist/atoms/Layout';
import { Text } from '@jcm-technologies/uikit/dist/atoms/Typography';
import { Pagination } from '@jcm-technologies/uikit/dist/molecules/Pagination';
import { useHistory } from 'react-router-dom';
import { Icon } from '@jcm-technologies/uikit/dist/atoms/Icon';
import IconCircleInfo from '@jcm-technologies/uikit/dist/atoms/Icons/IconCircleInfo';
import { SCHEDULE_LIST } from '../../modules/state/pagination/constants';
import {
  deleteSchedule,
  getDuplicateScheduleData,
  getSchedules,
} from '../../modules/schedule/actions';
import { setPageNumber } from '../../modules/state/pagination/actions';
import { ScheduleListItem } from '../../components/Schedule/ListItem';
import { AddButton } from '../../old_components/AddButton';
import { ModalScheduleDelete } from '../../components/Schedule/Modal/Delete';
import { FiltersList } from '../../components/common/Filters';
import { SCHEDULE_DESCENDANT } from '../../modules/state/visibleDescendants/constants';
import {
  setSearchQuery,
  setSelectedDescendants,
} from '../../modules/state/visibleDescendants/actions';

function ScheduleList() {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { color1 } = useSelector((state) => state.tenants);
  const { roleMask } = useSelector((state) => state.user.user);
  const { schedules } = useSelector((state) => state.schedules);
  const { totalPages, pagenumber } = useSelector((state) => state.backendParams);
  const { searchQuery } = useSelector((state) => state.visibleDescendants);
  const [isOpened, setIsOpened] = useState(false);
  const [selected, setSelected] = useState(null);
  const [dataSorted, setDataSorted] = useState(schedules || []);
  const groupBy = 'customerName';
  const getList = (value = '') => dispatch(getSchedules(value));

  const handlePageChange = (infoPage) => {
    dispatch(setPageNumber(SCHEDULE_LIST, infoPage?.actualPage));
    getList();
  };

  const handleDelete = ({ id, customerID }) => {
    dispatch(deleteSchedule({ id, customerID }));
    setIsOpened(false);
  };

  const handleDuplicate = (id) => {
    dispatch(getDuplicateScheduleData(id));
  };

  useEffect(() => {
    getList();
    dispatch(setSearchQuery(searchQuery, !!searchQuery));
  }, []);

  useEffect(() => {
    if (schedules) {
      setDataSorted(
        schedules.sort((a, b) => {
          if (a[groupBy] > b[groupBy]) {
            return 1;
          }
          if (a[groupBy] > b[groupBy]) {
            return -1;
          }
          return 0;
        })
      );
    }
  }, [schedules]);

  const handleDescendantsAction = (values) => {
    dispatch(setSelectedDescendants(SCHEDULE_DESCENDANT, values));
    getList();
  };

  let dataGroupName = '';
  return (
    <>
      <FiltersList
        listType={SCHEDULE_DESCENDANT}
        descendantsAction={
          Number(roleMask) !== 8 &&
          Number(roleMask) !== 128 &&
          Number(roleMask) !== 256 &&
          ((options) => handleDescendantsAction(options))
        }
        searchAction={getList}
      />
      {dataSorted?.length ? (
        <>
          {dataSorted?.map((el) => {
            if (dataGroupName === el?.customerName) {
              return (
                <ScheduleListItem
                  data={el}
                  setIsOpened={setIsOpened}
                  setSelected={setSelected}
                  handleDuplicate={handleDuplicate}
                />
              );
            }
            dataGroupName = el.customerName;
            return (
              <>
                <Flex alignItems='center' justifyContent='space-between'>
                  <Text as='span' marginTop={1} marginLeft={1} fontWeight='bold'>
                    {el.customerName}
                  </Text>
                </Flex>
                <ScheduleListItem
                  data={el}
                  setIsOpened={setIsOpened}
                  setSelected={setSelected}
                  handleDuplicate={handleDuplicate}
                />
              </>
            );
          })}
          <Flex alignItems='center' justifyContent='center' paddingY={2}>
            {!!totalPages && (
              <Pagination
                actionChangePage={(infoPage) => {
                  handlePageChange(infoPage);
                }}
                initNumber={pagenumber[SCHEDULE_LIST]}
                maxPageVisibleNumber={5}
                minPageNumber={1}
                totalPages={totalPages}
                themeColor={color1}
              />
            )}
          </Flex>
        </>
      ) : (
        <Flex alignItems='center' justifyContent='center' paddingY={8}>
          <Icon sizeIcon='display24' marginRight={2}>
            <IconCircleInfo />
          </Icon>
          <Text sizeText='display16'>{t('errors.noContent')}</Text>
        </Flex>
      )}
      <ModalScheduleDelete
        isOpened={isOpened}
        setIsOpened={setIsOpened}
        data={selected}
        action={handleDelete}
      />
      <AddButton id='add' toolTip={t('schedule.add')} history={history} url='/schedules/create' />
    </>
  );
}

export default ScheduleList;
