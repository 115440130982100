import loadingActions from '../actions/loading';

/* Redux state init */
const initialState = {
  show: 0,
  percent: undefined,
};

export default function dialogReducer(state = initialState, action) {
  if (action.type !== undefined) {
    switch (action.type) {
      case loadingActions.SHOW_LOADING:
        const nextShowValue = state.show + action.value;
        return {
          ...state,
          show: nextShowValue > 0 ? nextShowValue : false,
          percent: action.percent,
        };
      default:
        return state;
    }
  }
  return state;
}
