import React, { useState } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { GridColumn as Column } from '@progress/kendo-react-grid/dist/npm/GridColumn';
import { Flex } from '@jcm-technologies/uikit/dist/atoms/Layout';
import { IconTooltip } from '@jcm-technologies/uikit/dist/molecules/IconTooltip';
import IconTrash from '@jcm-technologies/uikit/dist/atoms/Icons/IconTrash';
import IconReply from '@jcm-technologies/uikit/dist/atoms/Icons/IconReply';
import { idGetter, SELECTED_FIELD, setStatusCellsTable } from '../config';
import Table from '../../../../common/Table';
import { CustomFilter } from '../../../../common/Table/renderers';

const TableUsers = ({
  data,
  setData,
  setChanges,
  onBlurSaveTable,
  onResendAction,
  onToggleLockUserLicence,
  onUserToDelete,
  onSelectedState,
  selectedState,
  setSelectedCountState,
}) => {
  const { t } = useTranslation();
  const {
    user: { roleMask },
  } = useSelector((state) => state.user);
  const [dataLoaded, setDataLoaded] = useState(data || []);
  const isPropertyManagerLiteRole = roleMask === 256;
  return (
    <Table
      data={data}
      setData={setData}
      setChanges={setChanges}
      onBlurSaveTable={onBlurSaveTable}
      onSelectedState={onSelectedState}
      selectedState={selectedState}
      setSelectedCountState={setSelectedCountState}
      setDataLoaded={setDataLoaded}
      dataLoaded={dataLoaded}
      editable
      pageable
      filterable
      sortable
    >
      {!isPropertyManagerLiteRole && (
        <Column
          field={SELECTED_FIELD}
          title=' '
          width={32}
          filterable={false}
          editalbe={false}
          headerSelectionValue={
            dataLoaded.findIndex((item) => !selectedState[idGetter(item)]) === -1
          }
        />
      )}
      <GridColumn
        editor='text'
        field='email'
        width='auto'
        title={t('form.email')}
        filterCell={CustomFilter}
        filter='text'
        editable={false}
      />
      <GridColumn
        editor='text'
        field='name'
        width='auto'
        title={t('form.name')}
        filter='text'
        filterCell={CustomFilter}
      />
      <GridColumn
        editor='text'
        field='surname'
        width='auto'
        title={t('form.surname')}
        filter='text'
        filterCell={CustomFilter}
      />
      <GridColumn
        editor='text'
        field='phone'
        width='auto'
        title={t('form.phone')}
        filter='text'
        filterCell={CustomFilter}
      />
      <GridColumn
        field='validFrom'
        width='auto'
        title={t('form.from')}
        editor='date'
        format='{0:d}'
        filterCell={CustomFilter}
      />
      <GridColumn
        field='validTo'
        width='auto'
        title={t('form.to')}
        editor='date'
        format='{0:d}'
        filterCell={CustomFilter}
      />
      <GridColumn
        field='status'
        title=' '
        filterable={false}
        editalbe={false}
        width={50}
        cell={(props) => {
          const {
            dataItem: { locked, id: userId, status },
          } = props;
          const stateNoted = setStatusCellsTable({
            locked,
            userId,
            isPropertyManagerLiteRole,
            onToggleLockUserLicence,
          })[status];
          return (
            <td colSpan='1'>
              <Flex justifyContent='center' alignItems='center'>
                <IconTooltip
                  sizeIcon='display16'
                  cursor='pointer'
                  color='blackLight'
                  toolTipContent={stateNoted?.tooltip}
                  tooltipProps={{
                    place: 'top',
                  }}
                  onClick={() => stateNoted?.action()}
                >
                  {stateNoted?.icon}
                </IconTooltip>
              </Flex>
            </td>
          );
        }}
      />

      {!isPropertyManagerLiteRole && (
        <Column
          field=''
          title=' '
          width={50}
          filterable={false}
          editalbe={false}
          cell={(props) => {
            const {
              dataItem,
              dataItem: { locked, id: userId, status },
            } = props;
            const stateNoted =
              setStatusCellsTable({
                locked,
                userId,
                isPropertyManagerLiteRole,
                onToggleLockUserLicence,
              })[status]?.type !== 'lock';
            return (
              <td colSpan='1'>
                <Flex alignItems='center' justifyContent='center'>
                  {stateNoted && (
                    <IconTooltip
                      sizeIcon='display16'
                      cursor='pointer'
                      color='blackLight'
                      toolTipContent={t('invitation.resend')}
                      tooltipProps={{
                        place: 'top',
                      }}
                      onClick={() => onResendAction(userId)}
                      marginRight={1}
                    >
                      <IconReply />
                    </IconTooltip>
                  )}
                  <IconTooltip
                    sizeIcon='display16'
                    cursor='pointer'
                    color='blackLight'
                    toolTipContent={t('genericGrow.delete')}
                    tooltipProps={{
                      place: 'top',
                    }}
                    onClick={() => onUserToDelete(dataItem)}
                  >
                    <IconTrash />
                  </IconTooltip>
                </Flex>
              </td>
            );
          }}
        />
      )}
    </Table>
  );
};

export default TableUsers;

TableUsers.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setData: PropTypes.func.isRequired,
  setChanges: PropTypes.func.isRequired,
  onBlurSaveTable: PropTypes.func.isRequired,
  onResendAction: PropTypes.func.isRequired,
  onToggleLockUserLicence: PropTypes.func.isRequired,
  onUserToDelete: PropTypes.func.isRequired,
  onSelectedState: PropTypes.func.isRequired,
  selectedState: PropTypes.shape({}).isRequired,
  setSelectedCountState: PropTypes.func.isRequired,
};
