import {
  deleteArrayNodeByPropName,
  updateArrayNodeByPropName,
} from '../../core/helpers/arrayService';
import {
  DEL_SCHEDULE,
  GET_DUPLICATE_SCHEDULE,
  GET_SCHEDULE,
  GET_SCHEDULES,
  GET_SCHEDULES_ASSIGNED_FACILITIES,
  GET_SCHEDULES_SINGLE_LIST,
  POST_SCHEDULES,
  PUT_SCHEDULE,
  RESET_DUPLICATE_SCHEDULE,
  RESET_SCHEDULE,
} from './contants';

/* Redux state init */
const initialState = {
  schedules: undefined,
  schedule: undefined,
  toDuplicate: undefined,
  assignedFacilities: [],
  singleSchedules: [],
  overallPages: 0,
  overallRows: 0,
  currentPage: 0,
};

export default function schedulesReducer(state = initialState, action) {
  if (action.type !== undefined) {
    switch (action.type) {
      case GET_DUPLICATE_SCHEDULE.SUCCESS:
        return {
          ...state,
          toDuplicate: action.result,
        };
      case RESET_DUPLICATE_SCHEDULE:
        return {
          ...state,
          toDuplicate: undefined,
        };
      case GET_SCHEDULES.SUCCESS: {
        const { overallPages, overallRows, currentPage, pageItems } = action.result;
        return {
          ...state,
          overallPages,
          overallRows,
          currentPage,
          schedules: pageItems,
        };
      }
      case GET_SCHEDULES.FAILURE:
        return {
          ...state,
        };
      case DEL_SCHEDULE.SUCCESS:
        return {
          ...state,
          schedules: state.schedules
            ? deleteArrayNodeByPropName(state.schedules, action.result, 'id')
            : state.schedules,
        };
      case GET_SCHEDULES_SINGLE_LIST.SUCCESS:
        return { ...state, singleSchedules: action.result };
      case POST_SCHEDULES.SUCCESS:
        return {
          ...state,
          schedule: action.result,
        };
      case PUT_SCHEDULE.SUCCESS:
        return {
          ...state,
          schedules: state.schedules
            ? updateArrayNodeByPropName(state.schedules, action.result, 'id')
            : state.schedules,
          schedule: { ...state.schedule, ...action.result },
        };
      case GET_SCHEDULE.SUCCESS:
        return {
          ...state,
          schedule: action.result,
        };
      case RESET_SCHEDULE:
        return {
          ...state,
          schedule: undefined,
        };

      case GET_SCHEDULES_ASSIGNED_FACILITIES.REQUEST:
        return {
          ...state,
          assignedFacilities: [],
        };
      case GET_SCHEDULES_ASSIGNED_FACILITIES.SUCCESS:
        return {
          ...state,
          assignedFacilities: [...action.result],
        };
      default:
        return state;
    }
  }
  return state;
}
