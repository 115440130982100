import { saveCookies } from '../../core/old_common/utils/cookiesManager';
import { updateArrayNodeByPropName } from '../../core/helpers/arrayService';
import {
  GET_INVITATION_USER,
  GET_OWN_USERS,
  GET_USER,
  GET_USER_ROLES,
  GET_USER_ROLES_EMPLOYEES,
  PUT_PREFERENCES_USER,
  PUT_USER_PERMISSIONS,
  RESET_NEED_SIGNALR_CHECK,
  RESET_USER_ROLES,
  USER_SET_NEED_UPDATE_LNG_FALSE,
} from './constants';

/* Redux state init */
const initialState = {
  userRoles: [],
  user: {},
  ownUsers: [],
  assistantPanelID: '',
  needExecuteSignalRCheck: false,
  userIsSuccess: false,
};

export default function userReducer(state = initialState, action) {
  if (action.type !== undefined) {
    switch (action.type) {
      case GET_USER_ROLES.SUCCESS:
        return { ...state, userRoles: [{ id: '', name: '' }, ...action.result] };
      case GET_USER_ROLES_EMPLOYEES.SUCCESS:
        return { ...state, userRoles: [{ id: '', name: '' }, ...action.result] };
      case GET_INVITATION_USER.REQUEST:
        return { ...state, userIsSuccess: false };
      case GET_INVITATION_USER.SUCCESS:
        return { ...state, user: { ...action.result[0] }, userIsSuccess: true };
      case GET_USER.SUCCESS:
        return {
          ...state,
          user: { ...action.result, infoVersion: action.result.infoVersion || 'none' },
          assistantPanelID: action.result.assistantConfigId,
        };
      case PUT_USER_PERMISSIONS.SUCCESS:
        return {
          ...state,
          ownUsers: updateArrayNodeByPropName(state.ownUsers, action.payload[1].data, 'id'),
        };
      case GET_OWN_USERS.SUCCESS:
        return { ...state, ownUsers: [...action.result] };
      case USER_SET_NEED_UPDATE_LNG_FALSE:
        return { ...state, needUpdateLng: false };
      case PUT_PREFERENCES_USER.SUCCESS: {
        const user = action.payload[1].data;
        let cookies = {
          assitantId: user.assistantConfigId,
          pageSizeItems: user.pageItems,
          dateTimeFormat: user.dateTimeFormat,
          language: user.language,
        };
        let newState = { ...state, user, assistantPanelID: user.assistantConfigId };
        if (state.user.language !== user.language) {
          cookies = { ...cookies };
          newState = { ...state, ...cookies, needUpdateLng: true };
        }
        saveCookies(cookies);
        return {
          ...state,
          ...newState,
          needExecuteSignalRCheck: action.payload[1].needExecuteSignalRCheck
            ? true
            : state.needExecuteSignalRCheck,
        };
      }
      case RESET_USER_ROLES:
        return {
          ...state,
          userRoles: [],
        };
      case RESET_NEED_SIGNALR_CHECK:
        return {
          ...state,
          needExecuteSignalRCheck: false,
        };
      default:
        return state;
    }
  }
  return state;
}
