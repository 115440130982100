export default {
  parameters: {
    title: 'Impostazione parametri proxy Access 5k',
    pinCode: 'Codice PIN',
    apn: 'APN',
    apnUser: 'Utente APN',
    apnPassword: 'Password APN',
    serverURL: 'Server URL',
    editproxy: 'Modifica proxy A5K',
    titleRelays: 'Relè di programmazione',
    errorRelaysPopUp: 'Errore: non è possibile superare il numero massimo di relè',
    CONFIGRELAYSNEW1_ICON: 'Modifica relè 1',
    CONFIGRELAYSNEW2_ICON: 'Modifica relè 2',
    CONFIGRELAYSNEW3_ICON: 'Modifica relè 3',
    CONFIGRELAYSNEW4_ICON: 'Modifica relè 4',
    ACTIVEEVENTSTOSD_ICON: 'Modifica eventi attivi',
    A5KPROXY_ICON: 'Modifica proxy A5K',
  },
};
