export const valuesSetter = (dataForm) => ({
  1: 'white',
  2: dataForm.siteCodeLength >= 1 ? 'redLight' : 'blue',
  3: dataForm.siteCodeLength >= 2 ? 'redLight' : 'blue',
  4: dataForm.siteCodeLength >= 3 ? 'redLight' : 'blue',
  5: dataForm.siteCodeLength >= 4 ? 'redLight' : 'blue',
  6: dataForm.siteCodeLength >= 5 ? 'redLight' : 'blue',
  7: dataForm.siteCodeLength >= 6 ? 'redLight' : 'blue',
  8: dataForm.siteCodeLength >= 7 ? 'redLight' : 'blue',
  9: dataForm.siteCodeLength >= 8 ? 'redLight' : 'blue',
  10: dataForm.siteCodeLength >= 9 ? 'redLight' : 'blue',
  11: dataForm.siteCodeLength >= 10 ? 'redLight' : 'blue',
  12: dataForm.siteCodeLength >= 11 ? 'redLight' : 'blue',
  13: dataForm.siteCodeLength >= 12 ? 'redLight' : 'blue',
  14: dataForm.siteCodeLength >= 13 ? 'redLight' : 'blue',
  15: dataForm.siteCodeLength >= 14 ? 'redLight' : 'blue',
  16: dataForm.siteCodeLength >= 15 ? 'redLight' : 'blue',
  17: dataForm.siteCodeLength >= 16 ? 'redLight' : 'blue',
  18: 'blue',
  19: 'blue',
  20: 'blue',
  21: 'blue',
  22: 'blue',
  23: 'blue',
  24: 'blue',
  25: 'blue',
  26: dataForm?.length === 26 ? 'white' : 'blue',
  27: 'blue',
  28: 'blue',
  29: 'blue',
  30: 'blue',
  31: 'blue',
  32: 'blue',
  33: 'blue',
  34: 'white',
});

export const valuesSetterW37 = (dataForm) => ({
  1: 'white',
  2: 'grey',
  3: 'grey',
  4: 'grey',
  5: 'grey',
  6: 'grey',
  7: 'grey',
  8: 'grey',
  9: 'grey',
  10: 'grey',
  11: 'grey',
  12: 'grey',
  13: 'grey',
  14: 'grey',
  15: dataForm.channelLength >= 1 ? 'green' : 'blue',
  16: dataForm.channelLength >= 2 ? 'green' : 'blue',
  17: dataForm.channelLength >= 3 ? 'green' : 'blue',
  18: 'blue',
  19: 'blue',
  20: 'blue',
  21: 'blue',
  22: 'blue',
  23: 'blue',
  24: 'blue',
  25: 'blue',
  26: 'blue',
  27: 'blue',
  28: 'blue',
  29: 'blue',
  30: 'blue',
  31: 'blue',
  32: 'blue',
  33: 'blue',
  34: 'blue',
  35: 'blue',
  36: 'blue',
  37: 'white',
});

export const convertToBinary = (length, value) =>
  Number(!Number(length) ? 0 : Number(value)).toString(2);
