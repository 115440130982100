import i18next from 'i18next';
import PopUpFormat from '../PopUpFormat/PopUpFormat';
import PopUpAdjustDateTime from '../PopUpAdjustDateTime/PopUpAdjustDateTime';
import ChangePasswordPopup from '../ChangePasswordPopup';
import ChangePasswordPopupWithFile from '../ChangePasswordPopupWithFile';

export const actionNames = {
  changePassword: 'change_password',
  adjustDatetime: 'adjust_datetime',
  formatMemory: 'format_memory',
  saveMemory: 'save_memory',
  sendMemory: 'send_memory',
  verifyConnectivity: 'verify_connectivity',
};

const actionType = [];
actionType[actionNames.changePassword] = (data) => {
  let selectedComponent = ChangePasswordPopup;
  if (data.type !== 'driver') {
    selectedComponent = ChangePasswordPopupWithFile;
  }
  return {
    title: i18next.t('equipments.changePasswordAction'),
    component: selectedComponent,
    ...data,
  };
};
actionType[actionNames.adjustDatetime] = () => ({
  title: i18next.t('equipments.adjustDateTimeAction'),
  component: PopUpAdjustDateTime,
});
actionType[actionNames.formatMemory] = (data) => ({
  title: i18next.t('equipments.formatMemory'),
  component: PopUpFormat,
  ...data,
});
// VDP
actionType.factory_reset = () => ({
  title: i18next.t('equipments.formatMemory'),
  component: PopUpFormat,
});
actionType.reboot = () => ({
  title: i18next.t('equipments.formatMemory'),
  component: PopUpFormat,
});
actionType.updrade = () => ({
  title: i18next.t('equipments.formatMemory'),
  component: PopUpFormat,
});
actionType.snapshot = () => ({
  title: i18next.t('equipments.formatMemory'),
  component: PopUpFormat,
});
actionType.record_voice = () => ({
  title: i18next.t('equipments.formatMemory'),
  component: PopUpFormat,
});
actionType.record_tone = () => ({
  title: i18next.t('equipments.formatMemory'),
  component: PopUpFormat,
});
actionType.play_voice = () => ({
  title: i18next.t('equipments.formatMemory'),
  component: PopUpFormat,
});
actionType.play_tone = () => ({
  title: i18next.t('equipments.formatMemory'),
  component: PopUpFormat,
});
actionType.open_relay1 = () => ({
  title: i18next.t('equipments.formatMemory'),
  component: PopUpFormat,
});
actionType.open_relay2 = () => ({
  title: i18next.t('equipments.formatMemory'),
  component: PopUpFormat,
});
actionType.remote_call = () => ({
  title: i18next.t('equipments.formatMemory'),
  component: PopUpFormat,
});
// Base Link
actionType[actionNames.saveMemory] = (data) => ({
  title: i18next.t('equipments.sendMemoryAction'),
  ...data,
});
actionType[actionNames.sendMemory] = (data) => ({
  title: i18next.t('equipments.saveMemoryAction'),
  ...data,
});
actionType[actionNames.verifyConnectivity] = (data) => ({
  title: i18next.t('equipments.verifyConnectivity'),
  ...data,
});
// TODO

export default actionType;
