import React from 'react';
import { connect } from 'react-redux';
import { generatePath, withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { v1 as uuidv1 } from 'uuid';
import _ from 'lodash';
import { CodesCounters } from '@jcm-technologies/uikit/dist/molecules/CodesCounters';
import GenericForm from '../GenericForm/GenericForm';
import { setOnFlyErrors, validateForm } from '../../core/old_common/validations/service';
import validationsDictionary from '../../core/old_common/validations';
import getOnFlyValidations from '../../core/old_common/utils/validationsApi';
import {
  invokeSendFunction,
  setAssistantPanelCheckSuccessFunction,
  setMessageHubFunction,
} from '../../core/old_common/utils/signalRService';
import SignalRComponent from '../SignalRComponent';

import './ManualEnrollCode.css';
import { resetIsPostFobSuccess } from '../../modules/group/fobs/actions';
import { resetIsPostUniversalFobSuccess } from '../../modules/old_to_refact/actions/universalGroupsFobs';
import Popup from '../Popup/Popup';
import ContentContainer from '../ContentContainer/ContentContainer';
import ButtonsGroup from '../ButtonsGroup/ButtonsGroup';
import { resetSignalRPopup, setProductId } from '../../modules/old_to_refact/actions/signalr';
import PopUpErrorMessage from '../PopupErrorMessage/PopUpErrorMessage';

class ManualEnrollCode extends SignalRComponent {
  constructor(props) {
    super(props);
    this.state = { ...this.initialState };
  }

  initialState = {
    id: uuidv1(),
    productId: '',
    e_code: '',
    name: '',
    surname: '',
    nic: '',
    plaza: 0,
    formErrors: [],
    onFlyFormErrors: [],
    isForcePopupOpen: false,
    isForceCodeMode: false,
    productIdWrite: undefined,
    needValidate: false,
  };

  componentDidMount() {
    setMessageHubFunction(this.newMessageHubFunction);
    setAssistantPanelCheckSuccessFunction(this.getFOBCode);
  }

  componentDidUpdate() {
    const {
      isFobPostedSuccess,
      resetIsPostFobSuccess,
      getFobCounters,
      resetIsPostUniversalFobSuccess,
      isUniversalFobPostedSuccess,
    } = this.props;
    if (isFobPostedSuccess || isUniversalFobPostedSuccess) {
      getFobCounters();
      isFobPostedSuccess ? resetIsPostFobSuccess() : resetIsPostUniversalFobSuccess();
    }
    if (this.state.needValidate) {
      const formErrors = validateForm(this.getFields());
      this.setState({ needValidate: false, formErrors: formErrors.errors });
    }
  }

  getFOBCode = () => {
    const { products, needInstallerCode, setProductId } = this.props;
    const productsData = _.compact(products.map((family) => family.options).flat());
    const selectedProduct = productsData.find(
      (product) => product.id === this.state.productId
    ).product;
    setProductId(selectedProduct.productIdWrite);
    invokeSendFunction(
      `${selectedProduct.name}::${selectedProduct.productIdWrite}::${selectedProduct.productIdRead}::null::${needInstallerCode}`,
      20000
    );
    this.setState({ onFlyFormErrors: [], e_code: '' });
  };

  newMessageHubFunction = (connId, id) => {
    this.setState({ e_code: id });
    this.executeOnFlyValidations('e_code', this.props.fobsCheckCodesEndpoint(id, 1));
  };

  handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value, needValidate: true });
  };

  handleNumericChange = (e) => {
    const { e_code } = this.state;
    const { fobsCheckCodesEndpoint, fobsCheckCodesLongEndpoint } = this.props;
    const selectedProduct = this.getProductBySelectedId();

    if (!isNaN(e.target.value)) {
      if (parseInt(e.target.value) > parseInt(e.target.max)) {
        e.target.value = e.target.max;
      } else if (parseInt(e.target.value) < parseInt(e.target.min)) {
        e.target.value = e.target.min;
      }
      if (e.target.value && e.target.id === 'e_code' && parseInt(e.target.value) !== e_code) {
        const endpoint =
          selectedProduct && selectedProduct.code === 169 && fobsCheckCodesLongEndpoint
            ? fobsCheckCodesLongEndpoint(e.target.value, 1)
            : fobsCheckCodesEndpoint(e.target.value, 1);
        this.executeOnFlyValidations(e.target.id, endpoint);
      } else {
        this.setState({ onFlyFormErrors: [] });
      }
      this.setState({ [e.target.id]: parseInt(e.target.value.split(':')[0]), needValidate: true });
    }
  };

  setErrorsCallback = ({ onFlyFormErrors, error, id, errorMessage }) => {
    this.setState({
      onFlyFormErrors: setOnFlyErrors({ onFlyFormErrors, error, id, errorMessage }),
    });
  };

  executeOnFlyValidations = (inputId, endPoint) => {
    const { t } = this.props;
    getOnFlyValidations(endPoint, (isError, response) => {
      if (this.props.isUniversalEnroll && response.status === 300) {
        this.setState({
          isForcePopupOpen: true,
          provisionalOnFlyError: {
            error: isError,
            id: inputId,
            errorMessage: isError ? t(`errors.${response?.error?.code}`) : '',
          },
        });
      } else {
        this.setErrorsCallback({
          onFlyFormErrors: this.state.onFlyFormErrors,
          error: isError,
          id: inputId,
          errorMessage: isError ? t(`errors.${response?.error?.code}`) : '',
        });
      }
    });
  };

  getProductBySelectedId = () => {
    const { products } = this.props;
    if (products) {
      const productsList = products
        .filter((product) => product.options !== undefined)
        .map((product) => product.options)
        .flat();
      const selectedProduct = productsList.find(
        (product) => product.product.productId === this.state.productId
      );
      return selectedProduct ? selectedProduct.product : undefined;
    }
    return undefined;
  };

  onClickSignalR = () => {
    setAssistantPanelCheckSuccessFunction(this.getFOBCode());
  };

  getFields = () => {
    const { t, products, hiddenFields, isConnected } = this.props;
    const fields = [
      [
        {
          type: 'dropdown',
          className: 'col',
          values: products,
          placeHolder: `${t('enrollCode.device')} *`,
          onChange: this.handleChange,
          value: this.state.productId,
          id: 'productId',
          validation: [() => validationsDictionary.isRequired(this.state.productId)],
        },
        {
          type: 'input',
          className: 'col',
          placeHolder: `${t('form.code')} *`,
          onChange: this.handleNumericChange,
          value: this.state.e_code,
          id: 'e_code',
          icon: this.state.productId ? 'fas fa-external-link-alt' : '',
          iconDisabled: !isConnected,
          onIconClick: () => this.onClickSignalR(),
          validation: [
            () => validationsDictionary.isRequired(this.state.e_code),
            () => validationsDictionary.isNumeric(this.state.e_code),
          ],
        },
      ],
      [
        {
          type: 'input',
          className: 'col',
          placeHolder: t('form.name'),
          onChange: this.handleChange,
          value: this.state.name,
          id: 'name',
          validation: [() => validationsDictionary.isInTextLength(this.state.name, 50)],
        },
        {
          type: 'input',
          className: 'col',
          placeHolder: t('form.surname'),
          onChange: this.handleChange,
          value: this.state.surname,
          id: 'surname',
          validation: [() => validationsDictionary.isInTextLength(this.state.surname, 80)],
        },
      ],
      [
        {
          type: 'input',
          className: 'col',
          placeHolder: t('form.identityCard'),
          onChange: this.handleChange,
          value: this.state.nic,
          id: 'nic',
          validation: [() => validationsDictionary.isInTextLength(this.state.nic, 50)],
        },
        {
          type: 'input',
          className: 'col',
          placeHolder: t('enrollCode.spot'),
          onChange: this.handleNumericChange,
          value: this.state.plaza,
          id: 'plaza',
          validation: [() => validationsDictionary.isNumeric(this.state.plaza)],
          notShow: hiddenFields ? hiddenFields.includes('plaza') : false,
        },
      ],
    ];
    return fields;
  };

  handleCancel = () => {
    const { match, isUG } = this.props;
    if (isUG) {
      const decoded = generatePath(`/UniversalGroups/:id/fobs`, {
        id: encodeURIComponent(decodeURIComponent(match.params.id)),
      });
      this.props.history.push(`${decoded}`);
    } else {
      const decoded = generatePath(`/Facilities/:instalationId/groups/:instalationGroupId`, {
        instalationId: encodeURIComponent(decodeURIComponent(match.params.instalationId)),
        instalationGroupId: encodeURIComponent(decodeURIComponent(match.params.instalationGroupId)),
      });
      this.props.history.push(`${decoded}`);
    }
  };

  handleSubmit = (e) => {
    const { postFOB } = this.props;
    e?.preventDefault();
    const formErrors = validateForm(this.getFields());
    this.setState({ formErrors: formErrors.errors });
    if (!formErrors.hasError && !this.state.onFlyFormErrors.length) {
      postFOB(this.state, this.state.isForceCodeMode);
      this.setState({ ...this.initialState });
    }
  };

  onAcceptForceCode = () => {
    this.setState({ isForcePopupOpen: false, isForceCodeMode: true });
  };

  onCancelForceCode = () => {
    this.setState({ isForcePopupOpen: false });
    this.setErrorsCallback({
      onFlyFormErrors: this.state.onFlyFormErrors,
      ...this.state.provisionalOnFlyError,
    });
  };

  closePopupError = () => {
    this.props.resetSignalRPopup();
    this.setState({ productIdWrite: undefined });
  };

  render() {
    const { tenants, fobsCount, t, showLoading, isUG, productId } = this.props;
    const { formErrors, onFlyFormErrors } = this.state;
    const isSubmitDisabled = parseInt(fobsCount.free) === 0 && !isUG;
    return (
      <div className='manual-enroll-form'>
        <CodesCounters
          availableValue={fobsCount.free}
          availablePlaceholder={t('codesCount.available')}
          reservationValue={fobsCount.reserved}
          reservationPlaceholder={t('enrollCode.notAsigned')}
          usedValue={fobsCount.busy}
          usedPlaceholder={t('codesCount.used')}
        />
        <div className='col-md-12 col-lg-12'>
          <GenericForm
            tenants={tenants}
            fields={this.getFields()}
            isCancelVisible
            isSubmitDisabled={isSubmitDisabled}
            handleCancel={this.handleCancel}
            handleSubmit={this.handleSubmit}
            formErrors={[...(formErrors || []), ...(onFlyFormErrors || [])]}
          />
        </div>
        <Popup
          className='manual-enroll-code-exist-popup'
          onCloseIconClick={() => this.setState({ isForcePopupOpen: false })}
          isOpen={this.state.isForcePopupOpen}
        >
          <ContentContainer title={t('enrollCode.codeAlreadyExist')}>
            <ButtonsGroup
              buttons={[
                {
                  value: t('enrollCode.ok'),
                  onClick: this.onAcceptForceCode,
                },
              ]}
            />
          </ContentContainer>
        </Popup>
        <PopUpErrorMessage
          isOpen={this.props.showSignalRPopup}
          productId={this.props.productId ? this.props.productId : ''}
          needTagMessage={!!(this.props.productId === 8 || this.props.productId === 10)}
          fromEnroll
          onClose={() => this.closePopupError()}
        />
        {isSubmitDisabled && showLoading <= 0 ? (
          <div className='enroll-error-message'> {t('enrollCode.cantEnroll')}</div>
        ) : (
          ''
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  tenants: state.tenants,
  isFobPostedSuccess: state.fobs.isFobPostedSuccess,
  isUniversalFobPostedSuccess: state.universalGroupsFobs.isUniversalFobPostedSuccess,
  isConnected: state.signalR.isConnected,
  showLoading: state.loading.show,
  instalationInfo: state.instalations.instalationInfo,
  instalations: state.instalations.instalations,
  showSignalRPopup: state.signalR.showSignalRPopup,
  productId: state.signalR.productId,
});

const ManualEnrollCodeWithTranslation = withTranslation()(ManualEnrollCode);
const ManualEnrollCodeWithRoute = withRouter(ManualEnrollCodeWithTranslation);

export default connect(mapStateToProps, {
  resetIsPostFobSuccess,
  resetIsPostUniversalFobSuccess,
  setProductId,
  resetSignalRPopup,
})(ManualEnrollCodeWithRoute);
