import { Flex, Grid } from '@jcm-technologies/uikit/dist/atoms/Layout';
import { Text } from '@jcm-technologies/uikit/dist/atoms/Typography';
import { IconTooltip } from '@jcm-technologies/uikit/dist/molecules/IconTooltip';
import IconClone from '@jcm-technologies/uikit/dist/atoms/Icons/IconClone';
import IconTrash from '@jcm-technologies/uikit/dist/atoms/Icons/IconTrash';
import IconChevronRight from '@jcm-technologies/uikit/dist/atoms/Icons/IconChevronRight';
import { Card } from '@jcm-technologies/uikit/dist/molecules/Card';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

export function ScheduleListItem({ data, setIsOpened, setSelected, handleDuplicate }) {
  const history = useHistory();
  const { t } = useTranslation();
  return (
    <Card
      as='article'
      width='100%'
      maxWidth='100%'
      position='relative'
      marginY={1}
      shadow='bottom'
      style={{ cursor: 'pointer ' }}
    >
      <Grid
        paddingY={1}
        paddingX={2}
        gridTemplateColumns='1fr 1fr auto'
        justifyContent='space-between'
        style={{ alignItems: 'center' }}
        gridGap={2}
      >
        <Text
          id={`schedule-name-${data.name}`}
          sizeText='display14'
          onClick={() => history.push(`/schedules/${data.id}`)}
          color='black'
        >
          {data.name}
        </Text>
        <Text
          id={`schedule-customer-name-${data.customerName}`}
          sizeText='display14'
          color='blackLight'
          onClick={() => history.push(`/schedules/${data.id}`)}
        >
          {data.customerName}
        </Text>
        <Flex justifyContent='space-between' alignItems='center'>
          <IconTooltip
            zIndex={1}
            id={`schedule-clone-${data.id}`}
            sizeIcon='display16'
            cursor='pointer'
            color='blackLight'
            toolTipContent={t('general.clone')}
            tooltipProps={{
              place: 'top',
            }}
            onClick={() => {
              handleDuplicate(data?.id);
              history.push(`/schedules/create`);
            }}
            marginX={1}
          >
            <IconClone />
          </IconTooltip>
          <IconTooltip
            zIndex={1}
            id={`schedule-delete-${data.id}`}
            sizeIcon='display16'
            cursor='pointer'
            color='blackLight'
            toolTipContent={t('general.delete')}
            tooltipProps={{
              place: 'top',
            }}
            onClick={() => {
              setIsOpened(true);
              setSelected(data);
            }}
          >
            <IconTrash />
          </IconTooltip>
          <IconTooltip
            id={`schedule-go-to-${data.id}`}
            sizeIcon='display16'
            cursor='pointer'
            color='blackLight'
            toolTipContent={t('general.goTo', { destination: data?.name })}
            tooltipProps={{
              place: 'top',
            }}
            marginLeft={1}
            onClick={() => history.push(`/schedules/${data.id}`)}
          >
            <IconChevronRight />
          </IconTooltip>
        </Flex>
      </Grid>
    </Card>
  );
}

ScheduleListItem.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    customerName: PropTypes.string,
  }),
  setIsOpened: PropTypes.func.isRequired,
  setSelected: PropTypes.func.isRequired,
  handleDuplicate: PropTypes.func.isRequired,
};
