import html2canvas from 'html2canvas';
import jsPdf from 'jspdf';
import store from '../../modules/old_to_refact/stores/configureStore';
import { showDialog } from '../../modules/old_to_refact/actions/loading';

function createBlobFunction() {
  if (!HTMLCanvasElement.prototype.toBlob) {
    Object.defineProperty(HTMLCanvasElement.prototype, 'toBlob', {
      value(callback, type, quality) {
        const dataURL = this.toDataURL(type, quality).split(',')[1];
        setTimeout(() => {
          const binStr = atob(dataURL);
          const len = binStr.length;
          const arr = new Uint8Array(len);

          for (let i = 0; i < len; i++) {
            arr[i] = binStr.charCodeAt(i);
          }

          callback(new Blob([arr], { type: type || 'image/png' }));
        });
      },
    });
  }
}

const imageCompression = ({ file, width, height, quality, stretchFromMajorSide }) => {
  createBlobFunction();
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      const img = new Image();
      img.src = event.target.result;
      img.onload = () => {
        const elem = document.createElement('canvas');
        if (!stretchFromMajorSide) {
          elem.width = width;
          elem.height = height;
        } else if (img.naturalWidth && img.naturalHeight) {
          const isWidthShorter = img.naturalWidth < img.naturalHeight;
          const sizeRatios = isWidthShorter
            ? img.naturalHeight / parseFloat(img.naturalWidth)
            : img.naturalWidth / parseFloat(img.naturalHeight);
          elem.width = isWidthShorter ? width : height * sizeRatios;
          elem.height = isWidthShorter ? width * sizeRatios : height;
        } else {
          elem.width = width;
          elem.height = height;
        }
        const ctx = elem.getContext('2d');
        ctx.drawImage(img, 0, 0, elem.width, elem.height);
        ctx.canvas.toBlob(
          (blob) => {
            const blobArray = [blob];
            resolve(
              new Blob(blobArray, {
                type: file.type,
                lastModified: Date.now(),
              })
            );
          },
          'image/jpeg',
          quality
        );
      };
    };
  });
};

const getBase64 = (file, isWithoutHeader) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const { result } = reader;
      resolve(isWithoutHeader ? result.replace(`data:${file.type};base64,`, '') : result);
    };
    reader.onerror = (error) => reject(error);
  });

const getBase64WithCompression = (file, isWithoutHeader) =>
  new Promise((resolve, reject) => {
    imageCompression({ file, height: 45, width: 45, quality: 0.8, stretchFromMajorSide: true })
      .then((newFile) =>
        getBase64(newFile, isWithoutHeader).then((base64File) => resolve(base64File))
      )
      .catch((error) => reject(error));
  });

const fileToBase64 = (files, isWithCompression, isWithoutHeader) => {
  if (files !== undefined && files.length > 0) {
    const fileArray = Array.from(files).map((file) =>
      isWithCompression
        ? getBase64WithCompression(file, isWithoutHeader)
        : getBase64(file, isWithoutHeader)
    );
    return Promise.all(fileArray);
  }
  return Promise.resolve([]);
};

export const exportComponentToPdf = (elementId, fileName = 'download') => {
  store.dispatch(showDialog(1));
  html2canvas(document.getElementById(elementId), { logging: false }).then((canvas) => {
    const imgData = canvas.toDataURL('image/png');
    const width = (3 / 4) * (canvas.width + 5);
    const height = (3 / 4) * (canvas.height + 5);

    const pdf =
      width > height
        ? new jsPdf('l', 'pt', [width, height])
        : new jsPdf('p', 'pt', [height, width]);
    pdf.addImage(imgData, 'PNG', 5, 5);
    pdf.save(`${fileName}.pdf`);
    store.dispatch(showDialog(-1));
  });
};

export const imageFilesToBase64 = (files, isWithoutHeader = false) =>
  fileToBase64(files, false, isWithoutHeader);
export const imageFilesToBase64WithCompression = (files, isWithoutHeader = false) =>
  fileToBase64(files, true, isWithoutHeader);

export const base64ToImage = (base64) => {
  if (!base64) {
    return '';
  }
  return base64.includes('data:image') ? base64 : `data:image;base64,${base64}`;
};
