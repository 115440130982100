import * as React from 'react';
import { GridColumnMenuSort } from '@progress/kendo-react-grid';

export class ColumnMenu extends React.Component {
  render() {
    return (
      <div>
        <GridColumnMenuSort {...this.props} />
      </div>
    );
  }
}
