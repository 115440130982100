import React from 'react';

import './PropsList.css';

const PropsList = ({ propsArray }) => (
  <div className='props-list-container'>
    {propsArray.map((prop) => (
      <span className='props-list-prop'>
        <p className='props-list-prop-key'>{prop.key}:</p>
        <p className='props-list-prop-value'>{prop.value}</p>
      </span>
    ))}
  </div>
);

export default PropsList;
