export default {
  signalRError: {
    11050: 'Unerwartete Antwort',
    11051: 'Falsches Gerät',
    11052: 'Falsche Codierung',
    11053: 'Das Gerät verwendet den Installationscode',
    11054: 'Das Gerät verwendet keinen Installationscode',
    11055: 'Auszeit',
    11056: 'Assistent nicht bereit',
    11057: 'Assistent ist beschäftigt',
    11058: 'Ein Fehler ist aufgetreten',
    11059: 'Operation abgelehnt',
    11060: 'Falsche Boot-Version',
    11061: 'Noch in Arbeit',
    11062: 'Falscher Zähler',
    11063: 'Falsche Seriennummer',
    11064: 'Fehler beim Konfigurieren des Geräts',
    11065: 'Unbekannte Aktion',
    11066: 'Falsches Gerät',
    11067: 'Fataler Fehler',
    11068: 'Schlechte crc',
    11069: 'Die Passwörter stimmen nicht überein',
    connectionTimeout: 'Überprüfen Sie die Verbindung mit dem Programmiergerät Assistant',
    dontMoveTag:
      'Es ist ein Fehler aufgetreten. Wiederholen Sie den Vorgang, ohne das Gerät aus dem Programmierbereich zu bewegen.',
  },
};
