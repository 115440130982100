export default {
  remoteState: {
    noContent: 'No disponible',
    title: 'Estado remoto',
    doorState0: 'Indefinida',
    doorState1: 'Abierta',
    doorState2: 'Cerrada',
    doorState3: 'Abriendo',
    doorState4: 'Cerrando',
    doorState5: 'Parando apertura',
    doorState6: 'Parando cierre',
    doorState7: 'En espera de cierre automático',
    deviceType: 'Tipo de dispositivo',
    connectionSignal: 'Señal de conexión',
    rssiValue: 'Valor RSSI',
    hwVersion: 'Versión Hardware',
    swVersion: 'Versión Software',
    doorStateTitle: 'Estado de la puerta',
    inputStateTitle: 'Estado del Input',
    deviceInfoTitle: 'Información del dispositivo',
    relayTitle: 'tiempo de activación en seg',
    relaysTitle: 'Relés',
    refreshStatus: 'Refrescar estado',
    activateRel1: 'Activar relé 1',
    activateRel2: 'Activar relé 2',
    doorState: 'Estado',
    activateRelError: 'Dispositivo no responde',
    activateRelSuccess: 'Acción completada con éxito',
    connectionSignalUnknown: 'Fuera de rango',
    connectionSignalExcellent: 'Excelente',
    connectionSignalGood: 'Buena',
    connectionSignalFair: 'Bien',
    connectionSignalWeak: 'Marginal',
    connectionType: 'Tipo de conexión',
    deviceType0: 'eBase',
    deviceType1: 'eConnect',
    deviceType2: 'BtConnect',
    deviceType3: 'Sesame',
    deviceType4: 'Blink',
    deviceType5: 'eAccess',
    deviceType6: 'Vdp',
    deviceType7: 'HONOADOOR / EBASEDOOR',
    deviceType8: 'HONOARELAY',
    sensors: 'Sensores',
    open: 'Abierto',
    battery: 'Batería',
    deactivated: 'Desactivado',
    activated: 'Activado',
  },
};
