export default {
  updateInstalationForm: {
    contact: 'Névjegy',
    telephone: 'Telefon',
    address: 'Cím',
    country: 'Ország',
    saveButton: 'Mentés',
    maintenanceDate: 'Következő karbantartás dátuma',
    createFacility: 'Létesítmény létrehozása',
    installerCode: 'Telepítő kód használata',
    installerCodeIconTooltip: 'Telepítői kód',
    comment: 'Megjegyzések',
    createFacilityGroup: 'Létesítmény csoport létrehozása',
    uniqueIdentity: 'Egyedi azonosító',
    geolocalization: 'Geolokáció beállítása',
    exportUsersGroups: 'Felhasználók exportálása',
    noneGeolocalization: 'Geolokalizáció nincs beállítva',
    addUniversalGroup: 'Univerzális csoport hozzáadása',
    needSetLocation: 'Hozzá kell adnia egy konkrét helyet',
    exportUsersTitle: 'felhasználók_exportálása',
    creationDate: 'Létrehozás dátuma',
    installerCodeFirstMessage: 'Győződjön meg róla, hogy az installációs kódot használja.',
    installerCodeSecondMessage: 'Kérdéseivel forduljon az adminisztrátorához'
  },
};
