export default {
  breadcrumb: {
    dashboard: 'Dashboard',
    Facilities: 'Létesítmények',
    FacilityEquipments: 'Berendezések',
    Groups: 'Csoportok',
    Equipments: 'Berendezések',
    AddUniversalGroup: 'Univerzális csoport hozzáadása',
    Configuration: 'Konfiguráció',
    Enroll: 'Regisztrációs folyamat',
    administration: 'Adminisztráció',
    'user-config': 'Számlám',
    UniversalGroups: 'Univerzális csoportok',
    Users: 'Felhasználók',
    Help: 'Segítség',
    Parameters: 'Paraméterek',
    Relays: 'Relék',
    Fobs: 'Klónozott távirányítók',
    Actions: 'Tevékenységek',
    Events: 'Események',
    'Remote comm': 'Távoli kommunikáció',
    Facility: 'Új létesítmény',
    Equipment: 'Új berendezés',
    FacilityGroup: 'Új csoport',
    Neighbours: 'Szomszédok listája',
    UniversalGroup: 'Új univerzális csoport',
    Schedule: 'Új ütemterv',
    customization: 'Web testreszabása',
    Invitations: 'Meghívók',
    Companies: 'Cégek',
    UpdateUser: 'Profilom',
    preferences: 'Beállítások',
    'assistant-panel': 'Assistant Panel',
    Holidays: 'Ünnepnapok',
    Information: 'Információk',
    recycle: 'Kuka',
  },
};
