import { Flex } from '@jcm-technologies/uikit/dist/atoms/Layout';
import { Text } from '@jcm-technologies/uikit/dist/atoms/Typography';
import { Image } from '@jcm-technologies/uikit/dist/atoms/Image';
import { Button } from '@jcm-technologies/uikit/dist/atoms/Button';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import GifOps from '../../static/jcm-oops.gif';
import { getContrastColor } from '../../core/helpers/colorManager';

const ErrorBoundaryView = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { color1 } = useSelector((state) => state.tenants);
  const goToDashboard = () => {
    history.push('/dashboard');
    window.location.reload();
  };

  return (
    <Flex justifyContent='center' alignItems='center' flexDirection='column' height='100vh'>
      <Text sizeText='display36' marginY={3}>
        {t('error.titleCrash')}
      </Text>
      <Image src={GifOps} width='100%' height={400} />
      <Text marginY={3} sizeText='display24'>
        <Trans> {t('error.descriptionCrash')}</Trans>
      </Text>
      <Button action={goToDashboard} backgroundColor={color1} color={getContrastColor(color1)}>
        {t('general.goTo', {
          destination: t('title.dashboard'),
        })}
      </Button>
    </Flex>
  );
};

export default ErrorBoundaryView;
