import { useSelector } from 'react-redux';
import React, { useCallback, useEffect, useState } from 'react';
import { getSelectedState } from '@progress/kendo-react-grid';
import { filterBy, orderBy } from '@progress/kendo-data-query';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { Grid as TableGrid } from '@progress/kendo-react-grid/dist/npm/Grid';
import PropTypes from 'prop-types';
import { Box } from '@jcm-technologies/uikit/dist/atoms/Layout';
import { getTransparentColor } from '../../../core/helpers/colorManager';
import { getActualLanguage } from '../../../core/helpers/getLanguages';
import { CellRender, RowRender } from './renderers';
import { DATA_ITEM_KEY, EDIT_FIELD, idGetter, SELECTED_FIELD } from './config';
import { updateArrayNodeByPropName } from '../../../core/helpers/arrayService';

const Table = ({
  data,
  setData,
  setChanges,
  onBlurSaveTable,
  onSelectedState,
  selectedState,
  setSelectedCountState,
  filterable,
  editable,
  sortable,
  pageable,
  children,
  setDataLoaded,
  dataLoaded,
  customFields,
  customFieldsParentName,
}) => {
  const {
    user: { pageItems },
  } = useSelector((state) => state.user);
  const { color1 } = useSelector((state) => state.tenants);

  const [filter, setFilter] = useState({
    logic: 'and',
    filters: [],
  });
  const [sort, setSort] = useState([]);
  const [page, setPage] = useState({
    skip: 0,
    take: pageItems || 15,
  });

  const pageChange = (event) => {
    setPage(event.page);
  };

  const sortChange = (event) => {
    setSort(event.sort);
    setDataLoaded(filterBy(orderBy(data, event.sort), filter));
    setPage({
      ...page,
      skip: 0,
    });
  };

  const itemChange = (event) => {
    const { inEdit, ...dataItem } = event.dataItem;
    const changeCustomField = customFields.find((element) => element.tag === inEdit);
    const field = event.field || '';
    if (changeCustomField) {
      const itemExist = dataItem[customFieldsParentName].find(
        (element) => element.tag === event.field
      );
      if (itemExist) {
        updateArrayNodeByPropName(
          dataItem[customFieldsParentName],
          {
            ...changeCustomField,
            id: itemExist?.id,
            fobId: dataItem?.id,
            value: event.value,
          },
          'tag'
        );
      } else {
        dataItem[customFieldsParentName].push({
          ...changeCustomField,
          id: '00000000-0000-0000-0000-000000000000',
          fobId: dataItem?.id,
          value: event.value,
        });
      }
    } else {
      dataItem[field] = event.value;
    }
    const newData = dataLoaded.map((item) => {
      if (item.id === dataItem.id) {
        item[field] = event.value;
      }
      return item;
    });
    setData(newData);
    setChanges(true);
  };
  const enterEdit = (dataItem, field) => {
    const newData = dataLoaded.map((item) => ({
      ...item,
      [EDIT_FIELD]: item.id === dataItem.id ? field : undefined,
    }));
    setData(newData);
  };
  const exitEdit = () => {
    const newData = dataLoaded.map((item) => ({
      ...item,
      [EDIT_FIELD]: undefined,
    }));
    setData(newData);
  };

  const extractColumnsInfo = () => {
    const columns = [];
    children.forEach((column) => {
      if (!Array.isArray(column)) {
        columns.push(column.props);
      } else {
        column?.forEach((subColumn) => columns.push(subColumn.props));
      }
    });

    return columns;
  };

  const handleTabNextRowFocus = (dataItem, isReversed) => {
    let nextRowItem = dataItem;
    const rows = dataLoaded.map((row, index) => {
      if (dataItem?.inEdit && row.id === dataItem.id) {
        nextRowItem = { ...row, inEdit: undefined };
      }
      if (!isReversed && index > 0 && dataItem.inEdit && dataLoaded[index - 1].id === dataItem.id) {
        nextRowItem = { ...row, inEdit: row.inEdit };
        enterEdit(nextRowItem, dataItem?.inEdit);
        return nextRowItem;
      }
      if (isReversed && index + 1 < dataLoaded.length && dataLoaded[index + 1].id === dataItem.id) {
        nextRowItem = { ...row, inEdit: row.inEdit };
        enterEdit(nextRowItem, dataItem?.inEdit);
        return nextRowItem;
      }
      return { ...row, inEdit: row.inEdit || undefined };
    });
    setDataLoaded(rows);
  };

  const handleTabNextFocus = (dataItem, isReverse) => {
    const columns = extractColumnsInfo();
    const inEditColumn = columns.findIndex((column) => column.field === dataItem.inEdit);
    let nextElement = '';
    if (inEditColumn > -1 && inEditColumn + 2 < columns.length) {
      nextElement = isReverse ? columns[inEditColumn - 1].field : columns[inEditColumn + 1].field;
      dataItem.inEdit = nextElement;
      enterEdit(dataItem, nextElement);
    } else {
      const firstEditableColumnNameNextRow =
        columns[columns.findIndex((column) => column.field === 'name')].field;
      dataItem.inEdit = firstEditableColumnNameNextRow;
      handleTabNextRowFocus(dataItem, isReverse);
    }
  };

  const navigateWithTabulation = (dataItem, cellField, event) => {
    if (!event?.shiftKey && event?.keyCode === 9) {
      handleTabNextFocus(dataItem, false);
    } else if (event?.shiftKey && event?.keyCode === 9) {
      handleTabNextFocus(dataItem, true);
    } else if (event?.keyCode === 13) {
      onBlurSaveTable(dataItem);
      // We use timeout because wen user want save the row, we need get the updated data
      // We waiting with timeout for no lost the scope tab
      setTimeout(() => handleTabNextRowFocus(dataItem, event?.shiftKey), 700);
    } else {
      // We use timeout because went user want to go a other place, we want save too. We use timeout for wait the
      // redirection lag and then, save, if we use it of reverse no works
      setTimeout(() => onBlurSaveTable(dataItem), 700);
    }
  };

  const customCellRender = (td, props) => (
    <CellRender
      originalProps={props}
      td={td}
      enterEdit={editable ? enterEdit : () => {}}
      editField={editable && EDIT_FIELD}
      onBlur={editable ? navigateWithTabulation : () => {}}
    />
  );
  const customRowRender = (tr, props) => (
    <RowRender
      originalProps={props}
      tr={tr}
      exitEdit={editable ? exitEdit : () => {}}
      editField={editable && EDIT_FIELD}
    />
  );

  const onSelectionChange = useCallback(
    (event) => {
      const newSelectedState = getSelectedState({
        event,
        selectedState,
        dataItemKey: DATA_ITEM_KEY,
      });
      if (event?.dataItem) {
        onSelectedState(newSelectedState);
        let counter = 0;
        for (const newSelectedStateKey in newSelectedState) {
          if (newSelectedState[newSelectedStateKey]) {
            counter += 1;
          }
        }
        setSelectedCountState(counter);
      }
    },
    [selectedState]
  );

  const onHeaderSelectionChange = useCallback(
    (event) => {
      const { checked } = event.syntheticEvent.target;
      const newSelectedState = {};
      // We select without pagination because we want all data are selected
      const gridData = filterBy(dataLoaded, filter);

      gridData.forEach((item) => {
        newSelectedState[idGetter(item)] = checked;
      });
      setSelectedCountState(checked ? gridData?.length : null);
      onSelectedState(newSelectedState);
    },
    [dataLoaded]
  );

  const language = getActualLanguage();
  const filterChange = (event) => {
    setFilter(event.filter);
    setDataLoaded(filterBy(orderBy(data, sort), event.filter));
    setPage({
      ...page,
      skip: 0,
    });
  };

  useEffect(() => {
    setDataLoaded(filterBy(data, filter));
  }, [data]);

  useEffect(() => {
    setPage({ ...page, take: pageItems });
  }, [pageItems]);

  return (
    <LocalizationProvider language={language}>
      <IntlProvider locale={language.split('-')[0]}>
        <style>
          {`
            .k-grid .k-state-selected > td { background: ${getTransparentColor(
              color1,
              0.25
            )} !important;}
            .k-checkbox:checked, .k-checkbox.k-checked { border-color: ${color1}; background-color: ${color1}; }
           `}
        </style>
        <Box maxWidth='100%' overflow='hidden' overflowX='auto'>
          <TableGrid
            style={{ width: '100%', maxWidth: '100%' }}
            data={filterBy(dataLoaded.slice(page.skip, page.take + page.skip), filter)}
            filterable={filterable}
            onFilterChange={filterChange}
            filter={filter}
            skip={page.skip}
            take={page.take}
            total={dataLoaded.length}
            pageable={pageable}
            editable={editable}
            sortable={sortable}
            onSortChange={sortChange}
            sort={sort}
            onPageChange={pageChange}
            editField={EDIT_FIELD}
            selectedField={SELECTED_FIELD}
            dataItemKey={DATA_ITEM_KEY}
            onSelectionChange={onSelectionChange}
            onHeaderSelectionChange={onHeaderSelectionChange}
            onItemChange={itemChange}
            cellRender={customCellRender}
            rowRender={customRowRender}
          >
            {children}
          </TableGrid>
        </Box>
      </IntlProvider>
    </LocalizationProvider>
  );
};

export default Table;

Table.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setData: PropTypes.func,
  setChanges: PropTypes.func,
  onBlurSaveTable: PropTypes.func,
  onSelectedState: PropTypes.func,
  selectedState: PropTypes.shape({}),
  setSelectedCountState: PropTypes.func,
  filterable: PropTypes.bool,
  editable: PropTypes.bool,
  sortable: PropTypes.bool,
  pageable: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  setDataLoaded: PropTypes.func.isRequired,
  dataLoaded: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  customFields: PropTypes.arrayOf(PropTypes.shape({})),
  customFieldsParentName: PropTypes.string,
};

Table.defaultProps = {
  filterable: false,
  editable: false,
  pageable: false,
  sortable: false,
  customFields: [],
  customFieldsParentName: null,
  setSelectedCountState: () => {},
  onBlurSaveTable: () => {},
  onSelectedState: () => {},
  selectedState: null,
  setData: () => {},
  setChanges: () => {},
};
