import { PUT_GROUP_FOBS_GRID } from '../../../modules/group/fobs/constants';
import { UNIVERSAL_FOBS_GRID_PUT } from './universalGroupsFobs';

/* Redux state init */
const initialState = {
  gridUpdated: true,
  gridUploading: false,
  gridFailed: false,
};

export default function gridControllerReducer(state = initialState, action) {
  if (action.type !== undefined) {
    switch (action.type) {
      case PUT_GROUP_FOBS_GRID.SUCCESS:
      case UNIVERSAL_FOBS_GRID_PUT.SUCCESS:
        return {
          ...state,
          gridUpdated: true,
          gridUploading: false,
          gridFailed: false,
        };
      case PUT_GROUP_FOBS_GRID.REQUEST:
      case UNIVERSAL_FOBS_GRID_PUT.REQUEST:
        return {
          ...state,
          gridUpdated: false,
          gridUploading: true,
          gridFailed: false,
        };
      case PUT_GROUP_FOBS_GRID.FAILURE:
      case UNIVERSAL_FOBS_GRID_PUT.FAILURE:
        return {
          ...state,
          gridUpdated: false,
          gridUploading: false,
          gridFailed: true,
        };
      default:
        return state;
    }
  }
  return state;
}
