import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { v1 as uuidv1 } from 'uuid';
import { Flex } from '@jcm-technologies/uikit/dist/atoms/Layout';
import { Button } from '@jcm-technologies/uikit/dist/atoms/Button';
import {
  getEquipmentRelays,
  putEquipmentRelay,
  resetEquipmentRelays,
} from '../../modules/old_to_refact/actions/equipments';
import RelayCheckableInput from './components/RelayCheckableInput';
import { getSchedulesSingleList } from '../../modules/schedule/actions';
import { getContrastColor } from '../../core/helpers/colorManager';

export class EquipmentRelayTabContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scheduleId: props.scheduleId || uuidv1(),
      formErrors: [],
      onFlyFormErrors: [],
      newRelayParams: [],
      needVerifyPut: undefined,
    };
  }

  componentDidMount() {
    const { getEquipmentRelays, facilityId, equipmentId, getSchedulesSingleList } = this.props;
    getEquipmentRelays(facilityId, equipmentId);
    getSchedulesSingleList(facilityId);
  }

  componentDidUpdate = () => {
    const { putRelaysIsSucced, history, match, location, isUpdatedEquipment } = this.props;
    if (putRelaysIsSucced && this.state.needVerifyPut) {
      this.changeIconColors('red');
      this.setState({ needVerifyPut: false });
    } else if (isUpdatedEquipment === 1 && this.state.needVerifyPut === undefined) {
      this.changeIconColors('#a4aebb');
    }
  };

  componentWillUnmount = () => {
    this.props.resetEquipmentRelays();
  };

  changeIconColors = (color) => {
    const element = document.getElementById(`saveConfig-${this.props.equipmentId}`);
    const element2 = document.getElementById(`remoteStateus-${this.props.equipmentId}`);
    if (element !== null) {
      document.getElementById(`saveConfig-${this.props.equipmentId}`).style.color = color;
    }
    if (element2 !== null) {
      document.getElementById(`remoteStateus-${this.props.equipmentId}`).style.color = color;
    }
  };

  handleSubmit = () => {
    const { putEquipmentRelay, facilityId, equipmentId } = this.props;
    const { newRelayParams } = this.state;
    const paramsFormatted = newRelayParams.map((el) => ({
      ...el,
      time: Number.isInteger(el.time / 1000) ? el.time : el.time * 1000,
      delay: Number.isInteger(el.delay / 1000) ? el.delay : el.delay * 1000,
    }));
    putEquipmentRelay(facilityId, equipmentId, null, paramsFormatted);
    this.setState({ needVerifyPut: true });
  };

  setParamsOnForm = (relayParams) => {
    const { relays } = this.props;
    relays.map((relay, i) => {
      if (relay.id === relayParams.id) {
        this.props.relays[i] = relayParams;
      }
    });
    this.setState({ newRelayParams: this.props.relays });
  };

  getControlButtons = () => {};

  render() {
    const { t, tenants, relays, equipmentId, facilityId, singleSchedules } = this.props;
    return relays ? (
      <Flex flexDirection='column' justifyContent='space-between' height='100%'>
        <div className='container-pather'>
          <div className='row'>
            <div className='col'>
              {relays.map((relay, i) => (
                <div className='container-son' key={relay.id}>
                  <RelayCheckableInput
                    key={relay.id}
                    relay={relay}
                    equipmentId={equipmentId}
                    facilityId={facilityId}
                    i={i}
                    schedules={singleSchedules}
                    setParamsOnForm={(relayParams) => this.setParamsOnForm(relayParams)}
                  />
                  {i !== relays?.length - 1 && <hr />}
                </div>
              ))}
            </div>
          </div>
        </div>
        <Flex id='save' marginTop={2} justifyContent='flex-end'>
          <Button
            backgroundColor={tenants?.color1}
            sizeButton='small'
            id='save'
            type='submit'
            disabled={false}
            action={this.handleSubmit}
            marginRight={1}
            color={getContrastColor(tenants?.color1)}
          >
            {t('general.save')}
          </Button>
        </Flex>
      </Flex>
    ) : (
      ''
    );
  }
}

const mapStateToProps = (state) => ({
  relays: state.equipments.relays,
  singleSchedules: state.schedules.singleSchedules,
  tenants: state.tenants,
  putRelaysIsSucced: state.equipments.putRelaysIsSucced,
  isUpdatedEquipment: state.equipments.isUpdated,
});

const EquipmentRelayTabContentWithTranslation = withTranslation()(EquipmentRelayTabContent);
const EquipmentRelayTabContentWithRoute = withRouter(EquipmentRelayTabContentWithTranslation);

export default connect(mapStateToProps, {
  putEquipmentRelay,
  getEquipmentRelays,
  getSchedulesSingleList,
  resetEquipmentRelays,
})(EquipmentRelayTabContentWithRoute);
