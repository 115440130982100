import PathEntity from './PathEntity.js';
import { apiVersion, mainPath } from '../common';

class Relays extends PathEntity {
  constructor() {
    super('relays');
  }

  getPaged = (facilityId, groupId) =>
    this.manufacturePath(`facilities/${facilityId}/groups/${groupId}/equipmentsrelays`);

  post = (facilityId, groupId) =>
    this.manufacturePath(`facilities/${facilityId}/groups/${groupId}/equipmentsrelays`);

  put = ({ facilityId, groupId, equipmentId, relayId, assignationId }) => {
    this.mainPath = `${mainPath()}4.1`;
    const endpoint = this.manufacturePath(
      `facilities/${facilityId}/groups/${groupId}/equipments/${equipmentId}/relays/${relayId}/assignation/${assignationId}`
    );
    this.mainPath = `${mainPath()}${apiVersion}`;
    return endpoint;
  };

  delete = (facilityId, groupId) =>
    this.manufacturePath(`facilities/${facilityId}/groups/${groupId}/equipmentsrelays`);

  get = (facilityId, groupId) =>
    this.manufacturePath(`facilities/${facilityId}/groups/${groupId}/equipmentsrelays`);

  getPaths = () => ({
    relaysGetList: this.getPaged,
    relaysPost: this.post,
    relaysPut: this.put,
    relaysDelete: this.delete,
    relaysGet: this.get,
  });
}

export default new Relays().getPaths();
