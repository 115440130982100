export default {
  adjustTimePopUp: {
    manualDateTimeAdjust: 'Kézi dátum-és időbeállítás',
    actualHour: 'Jelenlegi idő:',
    minutesCompensation: 'Perc kompenzáció mostantól',
    insertSdMemory:
      'Helyezze be az SD kártyát, és pontosan a következő órában kapcsolja be a készüléket',
    inputTime: 'Mutassa az időt',
    save: 'Mentés',
  },
};
