import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { Loading } from '@jcm-technologies/uikit/dist/atoms/Loading';
import { getFamilies, resetFamilyProducts } from '../../../../modules/productFamilies/action';
import CustomizationFamilies from '../../../../components/Administration/Customization/Families';

export const Edit = () => {
  const dispatch = useDispatch();
  const { families } = useSelector((state) => state.families);

  useEffect(() => {
    dispatch(getFamilies());
    return () => dispatch(resetFamilyProducts());
  }, []);

  if (families?.length) {
    return <CustomizationFamilies />;
  }
  return <Loading show />;
};
