import { call, fork, takeLatest } from 'redux-saga/effects';
import driverActions from '../actions/drivers';
import fetchEntitySaga from './utils/fetchEntitySaga';
import apiPathFactory from '../../../core/old_common/rooting/apiPathFactory';
import api from '../../../core/helpers/api';
import {
  drivers_CODES_POST,
  drivers_COMPARE_POST,
  drivers_CONFIG_POST,
  drivers_EVENTS_POST,
  drivers_PING_GET,
  drivers_SEND_MEMORY_CODES_POST,
} from '../reducers/drivers';

export const postCompare = {
  base: () => ({
    type: drivers_COMPARE_POST.BASE,
  }),
  request: () => ({ type: drivers_COMPARE_POST.REQUEST }),
  success: (result) => ({ type: drivers_COMPARE_POST.SUCCESS, result }),
  failure: (error) => ({ type: drivers_COMPARE_POST.FAILURE, error }),
};

export const getPingDevice = {
  base: () => ({
    type: drivers_PING_GET.BASE,
  }),
  request: () => ({ type: drivers_PING_GET.REQUEST }),
  success: (result) => ({ type: drivers_PING_GET.SUCCESS, result }),
  failure: (error) => ({ type: drivers_PING_GET.FAILURE, error }),
};

export const postConfig = {
  base: () => ({
    type: drivers_CONFIG_POST.BASE,
  }),
  request: () => ({ type: drivers_CONFIG_POST.REQUEST }),
  success: (result) => ({ type: drivers_CONFIG_POST.SUCCESS, result }),
  failure: (error) => ({ type: drivers_CONFIG_POST.FAILURE, error }),
};

export const postCodes = {
  base: () => ({
    type: drivers_CODES_POST.BASE,
  }),
  request: () => ({ type: drivers_CODES_POST.REQUEST }),
  success: (result) => ({ type: drivers_CODES_POST.SUCCESS, result }),
  failure: (error) => ({ type: drivers_CODES_POST.FAILURE, error }),
};

export const postEvents = {
  base: () => ({
    type: drivers_EVENTS_POST.BASE,
  }),
  request: () => ({ type: drivers_EVENTS_POST.REQUEST }),
  success: (result) => ({ type: drivers_EVENTS_POST.SUCCESS, result }),
  failure: (error) => ({ type: drivers_EVENTS_POST.FAILURE, error }),
};

export const postSendMemoryCodes = {
  base: () => ({
    type: drivers_SEND_MEMORY_CODES_POST.BASE,
  }),
  request: () => ({ type: drivers_SEND_MEMORY_CODES_POST.REQUEST }),
  success: (result) => ({ type: drivers_SEND_MEMORY_CODES_POST.SUCCESS, result }),
  failure: (error) => ({ type: drivers_SEND_MEMORY_CODES_POST.FAILURE, error }),
};

export function* postCompareSaga({ id, facilityId, binaryData }) {
  try {
    const url = apiPathFactory.driversPostCompareMemory(id, facilityId);
    const file = new Blob([binaryData], { type: 'text/plain;charset=utf-8' });
    const response = yield call(() =>
      fetchEntitySaga(postCompare, api.post, url, {
        formData: [{ fieldName: 'file', file }],
        toastNotShow: true,
      })
    );
    return response;
  } catch (error) {
    return error;
  }
}

export function* postCodesSaga({ id, facilityId }) {
  try {
    const url = apiPathFactory.driversGetMemoryCodes(id, facilityId);
    const response = yield call(() => fetchEntitySaga(postCodes, api.get, url));
    return response;
  } catch (error) {
    return error;
  }
}

export function* getPingDeviceSaga({ id, facilityId, connectionId, productType }) {
  try {
    const url = apiPathFactory.driversGetPingDevice(id, facilityId, connectionId, productType);
    const response = yield call(() => fetchEntitySaga(getPingDevice, api.get, url));
    return response;
  } catch (error) {
    return error;
  }
}

export function* postConfigSaga({ id, facilityId }) {
  try {
    const url = apiPathFactory.driversGetMemoryConfig(id, facilityId);
    const response = yield call(() => fetchEntitySaga(postConfig, api.get, url));
    return response;
  } catch (error) {
    return error;
  }
}

export function* postEventsSaga({ id, facilityId, file }) {
  try {
    const url = apiPathFactory.driversGetEvents(id, facilityId);
    const response = yield call(() =>
      fetchEntitySaga(postEvents, api.post, url, { data: { content: file }, toastNotShow: true })
    );
    return response;
  } catch (error) {
    return error;
  }
}

export function* postSendMemoryCodesSaga({ id, facilityId, ic, cc, signalRConnId }) {
  try {
    const url = apiPathFactory.driversPostSendMemoryCodes(id, facilityId, ic, cc, signalRConnId);
    const response = yield call(() =>
      fetchEntitySaga(postSendMemoryCodes, api.post, url, { toastNotShow: true })
    );
    return response;
  } catch (error) {
    return error;
  }
}

function* watchPostDriverCompare() {
  yield takeLatest(driverActions.POST_DRIVER_COMPARE, postCompareSaga);
}

function* watchPostDriverCodes() {
  yield takeLatest(driverActions.POST_DRIVER_MEMORY_CODES, postCodesSaga);
}

function* watchPostDriverConfig() {
  yield takeLatest(driverActions.POST_DRIVER_MEMORY_CONFIG, postConfigSaga);
}

function* watchPostDriverEvents() {
  yield takeLatest(driverActions.POST_DRIVER_EVENTS, postEventsSaga);
}

function* watchPostDriverSendMemoryCodes() {
  yield takeLatest(driverActions.POST_DRIVER_SEND_MEMORY_CODES, postSendMemoryCodesSaga);
}

function* watchGetPingDevice() {
  yield takeLatest(driverActions.GET_DRIVER_PING_DEVICE, getPingDeviceSaga);
}

export default function* watchers() {
  yield fork(watchPostDriverCompare);
  yield fork(watchPostDriverCodes);
  yield fork(watchPostDriverConfig);
  yield fork(watchPostDriverEvents);
  yield fork(watchPostDriverSendMemoryCodes);
  yield fork(watchGetPingDevice);
}
