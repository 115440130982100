const validateForm = (form) => {
  let hasError = false;
  const errors = [];
  form.forEach((element) =>
    element.forEach((elementItem) => {
      const validatedInput = validateInput(elementItem);
      if (validatedInput) {
        hasError = true;
        errors.push(validatedInput);
      }
    })
  );
  return { hasError, errors };
};

const validateInput = (input) => {
  let validatedElementItem;
  if (input.validation) {
    const validatedInput = getError(input.validation);
    if (validatedInput.error) {
      validatedElementItem = { ...validatedInput, id: input.id };
    }
  }
  return validatedElementItem;
};

const getError = (validation) => {
  let isError = {};
  let error = {};
  validation.forEach((validationFunc) => {
    isError = validationFunc();
    if (isError.error) {
      error = isError;
    }
  });
  return error;
};

export const getErrors = (validations) => {
  for (let i = 0; i < validations.length; i += 1) {
    const element = validations[i];
    if (element?.error) {
      return element;
      break;
    }
  }

  return false;
};

const setOnFlyErrors = ({ onFlyFormErrors, error, id, errorMessage }) => {
  const newOnFlyFormErrors = [...onFlyFormErrors];
  let onFlyErrors = [];

  if (error) {
    onFlyErrors = [...newOnFlyFormErrors, { error, id, errorMessage }];
  } else {
    onFlyErrors = onFlyFormErrors.filter((error) => error.id !== id);
  }
  return [...onFlyErrors];
};

export { validateForm, setOnFlyErrors, validateInput };
