import {
  SET_SEARCH_QUERY,
  SET_SELECTED_CITIES,
  SET_SELECTED_DESCENDANTS,
  SET_SELECTED_POSTALCODES,
} from './constants';
import {
  getCookie_visibleDescendants,
  saveCookies,
} from '../../../core/old_common/utils/cookiesManager';

/* Redux state init */
const initialState = {
  selectedDescendants: getCookie_visibleDescendants(),
  selectedCities: undefined,
  selectedPostalCodes: undefined,
  searchQuery: '',
  viewMore: false,
};

export default function reducer(state = initialState, action) {
  if (action.type !== undefined) {
    switch (action.type) {
      case SET_SELECTED_DESCENDANTS: {
        const newSelectedDescendants = { ...state.selectedDescendants };
        newSelectedDescendants[action.descType] = [...(action.selectedDescendants || [])];
        saveCookies({ visibleDescendants: newSelectedDescendants });
        return {
          ...state,
          selectedDescendants: newSelectedDescendants,
        };
      }
      case SET_SELECTED_CITIES:
        return {
          ...state,
          selectedCities: action.selectedCities,
        };
      case SET_SELECTED_POSTALCODES:
        return {
          ...state,
          selectedPostalCodes: action.selectedPostalCodes,
        };
      case 'GET_FIELD': {
        return {
          ...state,
          selectedCities: undefined,
          selectedPostalCodes: undefined,
        };
      }
      case SET_SEARCH_QUERY:
        return {
          ...state,
          searchQuery: action.searchQuery,
          viewMore: action.viewMore,
        };
      default:
        return state;
    }
  }
  return state;
}
