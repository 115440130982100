import { useTranslation } from 'react-i18next';
import { Card } from '@jcm-technologies/uikit/dist/molecules/Card';
import { Icon } from '@jcm-technologies/uikit/dist/atoms/Icon';
import { Text } from '@jcm-technologies/uikit/dist/atoms/Typography';
import IconUsers from '@jcm-technologies/uikit/dist/atoms/Icons/IconUsers';
import IconUser from '@jcm-technologies/uikit/dist/atoms/Icons/IconUser';
import IconChevronDown from '@jcm-technologies/uikit/dist/atoms/Icons/IconChevronDown';
import { Flex, Grid } from '@jcm-technologies/uikit/dist/atoms/Layout';
import { useHistory } from 'react-router-dom';
import { Loading } from '@jcm-technologies/uikit/dist/atoms/Loading';
import PropTypes from 'prop-types';
import { statusMapper } from './config';

const CardHistory = ({ item }) => {
  const { t } = useTranslation();
  const history = useHistory();
  if (item) {
    return (
      <Card
        padding={1}
        marginTop={1}
        style={{ cursor: 'pointer' }}
        onClick={() =>
          item?.isUniversal
            ? history.push(`/universalGroups/${item.id}/fobs/${item.facilityFobId}`)
            : history.push(
                `/facilities/${item.facilityId}/groups/${item.id}/fobs/${item.facilityFobId}`
              )
        }
      >
        <Grid gridTemplateAreas='. . . .' gridTemplateColumns='1fr 1fr 1fr 24px'>
          <Flex alignItems='center'>
            <Icon sizeIcon='display14' color='blackLight' marginRight={2}>
              {item?.isUniversal ? <IconUsers /> : <IconUser />}
            </Icon>
            <Text sizeText='display14' color='blackLight'>
              {item.name}
            </Text>
          </Flex>
          <Text sizeText='display14' color='blackLight'>
            {item.facilityName}
          </Text>
          <Text sizeText='display14' color='blackLight'>
            {t(statusMapper[item.status])}
          </Text>
          <Icon sizeIcon='display24' color='blackLight'>
            <IconChevronDown />
          </Icon>
        </Grid>
      </Card>
    );
  }

  return <Loading show />;
};

export default CardHistory;

CardHistory.propTypes = {
  item: PropTypes.shape({
    facilityId: PropTypes.string,
    id: PropTypes.string,
    facilityFobId: PropTypes.string,
    name: PropTypes.string,
    facilityName: PropTypes.string,
    status: PropTypes.number,
    isUniversal: PropTypes.bool,
  }),
};

CardHistory.defaultProps = {
  item: null,
};
