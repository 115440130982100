import {
  GET_FAMILIES,
  GET_FAMILIES_SINGLE_LIST,
  GET_FAMILY_PRODUCTS,
  POST_FAMILIES,
  RESET_FAMILIES_PRODUCTS,
  SET_FAMILIES_VALUES,
} from './contants';

export const getFamilies = () => ({
  type: GET_FAMILIES.BASE,
});
export const getFamiliesSingleList = (productType, isExtended) => ({
  type: GET_FAMILIES_SINGLE_LIST.BASE,
  productType,
  isExtended,
});
export const getFamilyProducts = (familyId) => ({
  type: GET_FAMILY_PRODUCTS.BASE,
  familyId,
});

export const setFamiliesValues = (newFamilies) => ({
  type: SET_FAMILIES_VALUES,
  newFamilies,
});

export const postFamily = (family) => ({
  type: POST_FAMILIES.BASE,
  family,
});

export const resetFamilyProducts = () => ({
  type: RESET_FAMILIES_PRODUCTS,
});
