export default {
  instalations: {
    groupsTab: 'Grupos',
    equipmentsTab: 'Equipos',
    addGroup: 'Añadir nuevo grupo',
    deleteConfirmation: '¿Seguro que quieres eliminar esta instalación?',
    add: 'Añadir instalación',
    config: 'Configurar instalación',
    notAllow: ' Los equipos asignados no admiten horarios',
    allDevicesConnected: 'Todos conectados',
    allDevicesDisconnected: 'Último mantenimiento:',
    includeReservations: 'Incluir las reservas?',
    includeConfigFields: 'Incluir los campos configurables?',
    acceptExportPopUpButton: 'Exportar',
    exportPopUpTitle: 'Exportar grupos',
    nameTableheader: 'Nombre',
    allEquipmentsStatusOk: 'Todos los dispositivos conectados',
    numberOfConnectedDevices: 'Dispositivos encontrados',
    filters: 'Filtros',
    criticalDeleteTitle: 'ACCIÓN CRÍTICA IRREVERSIBLE',
    firstAreYouSureCriticalDelete: '¿Seguro que desea eliminar ',
    secondAreYouSureCriticalDelete: '',
    noGoBack: 'ESTA ACCIÓN ES IRREVERSIBLE',
    transfer: 'Trasnferir',
    transferPopUpLabel: 'Administrador que gestionará la instalación',
    transferPopUpSelector: 'Selecciona usuario',
    restore: 'Restaurar',
    recycleBin: 'Papelera',
    creation: 'Creación',
    saveMemory: 'Guardar memoria',
    substitution: 'Sustitución',
    fob: 'Emisor',
    equipment: 'Equipo',
    deletion: 'Supresión',
    moved: 'Movido',
    locked: 'Bloqueado',
    unlocked: 'Desbloqueado'
  },
};
