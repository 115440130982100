import React, { Component } from 'react';
import ButtonsGroup from '../../../ButtonsGroup/ButtonsGroup';

export default class informationRelay extends Component {
  maxRange;

  minRange;

  constructor(props) {
    super(props);
    this.maxRange = 20;
    this.minRange = 1;
    this.state = {
      ms: this.minRange,
    };
  }

  validateNumberValue = (e) => {
    if (parseInt(e.target.value) > this.maxRange) {
      e.target.value = this.maxRange;
    } else if (parseInt(e.target.value) < this.minRange) {
      e.target.value = this.minRange;
    }
    this.setState({ ms: e.target.value });
  };

  putRelay = () => {
    const { putRelay, device, id } = this.props;
    putRelay(device.facilityId, device.id, id, this.state.ms * 1000);
  };

  render() {
    const { label, id, buttonText } = this.props;
    return (
      <div>
        <div className='device-information-relay'>
          <label htmlFor='relay' className='device-information-relay-label'>
            <span>{label}</span>
            <input
              type='number'
              name='relay'
              id={`relay${id}`}
              min={this.minRange}
              max={this.maxRange}
              value={this.state.ms}
              onChange={this.validateNumberValue}
            />
          </label>

          <ButtonsGroup
            className='activate-relay-device-group'
            buttons={[
              {
                value: buttonText,
                className: 'set-relay',
                onClick: this.putRelay,
              },
            ]}
          />
        </div>
      </div>
    );
  }
}
