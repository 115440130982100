import { Flex } from '@jcm-technologies/uikit/dist/atoms/Layout';
import { Icon } from '@jcm-technologies/uikit/dist/atoms/Icon';
import { IconExclamationTriangle } from '@jcm-technologies/uikit/dist/atoms/Icons';
import { Text } from '@jcm-technologies/uikit/dist/atoms/Typography';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

export const SelectedCounter = ({ counter }) => {
  const { t } = useTranslation();
  return (
    <>
      {counter ? (
        <Flex marginBottom={2}>
          <Icon color='red' sizeIcon='display24' marginRight={1}>
            <IconExclamationTriangle />
          </Icon>
          <Text sizeText='display16'>
            {t('instalationGroups.totalSelected')}: {counter}
          </Text>
        </Flex>
      ) : null}
    </>
  );
};

SelectedCounter.propTypes = {
  counter: PropTypes.number,
};

SelectedCounter.defaultProps = {
  counter: 0,
};
