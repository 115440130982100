import { useTranslation } from 'react-i18next';
import { Button, ButtonGroup } from '@jcm-technologies/uikit/dist/atoms/Button';
import { Box, Flex } from '@jcm-technologies/uikit/dist/atoms/Layout';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { CodesCounters } from '@jcm-technologies/uikit/dist/molecules/CodesCounters';
import { useSelector } from 'react-redux';
import { IconTooltip } from '@jcm-technologies/uikit/dist/molecules/IconTooltip';
import IconPlus from '@jcm-technologies/uikit/dist/atoms/Icons/IconPlus';
import TableUsers from '../Table';
import { ModalDeleteUser } from '../Modal/DeleteUser';
import { ModalDeleteMultiUsers } from '../Modal/DeleteMultiUsers';
import { getContrastColor } from '../../../../../core/helpers/colorManager';
import { SelectedCounter } from '../../../../common/Counter';
import { ModalMoveMultiUsers } from '../Modal/MoveMultiUsers';
import { ModalCopyMultiUsers } from '../Modal/CopyMultiUsers';
import { ModalAddUser } from '../Modal/AddUser';
import { actionGroup } from '../config';
import { ExcelExportUsers } from '../Excel/Export';
import { ExcelImportUsers } from '../Excel/Import';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

const BoxUsers = ({
  items,
  counter,
  setData,
  setChanges,
  onBlurSaveTable,
  onResendAction,
  onToggleLockUserLicence,
  setUserToDelete,
  userToDelete,
  onDeleteUser,
  onDeleteMultiUsers,
  onSelectedState,
  selectedState,
  selectedCountState,
  setSelectedCountState,
  isOpenModalDeleteMultiUsers,
  setIsOpenModalDeleteMultiUsers,
  isOpenModalMoveMultiUsers,
  setIsOpenModalMoveMultiUsers,
  onMoveMultiUsers,
  isOpenModalCopyMultiUsers,
  setIsOpenModalCopyMultiUsers,
  onCopyMultiUsers,
  isOpenModalAddUser,
  setIsOpenModalAddUser,
  newUserErrors,
  setNewUser,
  onCreateNewUser,
  onImportExcelUsers,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { color1 } = useSelector((state) => state.tenants);
  const { instalationId: facilityId } = useParams();
  const {
    user: { roleMask },
  } = useSelector((state) => state.user);
  const isPropertyManagerLiteRole = roleMask === 256;

  const handleClickAddModalUser = () => {
    setNewUser(null);
    setIsOpenModalAddUser(true);
  };

  return (
    <>
      <Box paddingX={4} paddingY={1}>
        <Flex alignItems='center'>
          <CodesCounters
            haveReservationNumber={false}
            availableValue={counter[0]?.free || 0}
            availablePlaceholder={t('codesCount.available')}
            usedValue={counter[0]?.busy || 0}
            usedPlaceholder={t('codesCount.used')}
          />
        </Flex>
        <SelectedCounter counter={selectedCountState} />
        <TableUsers
          data={items}
          setData={setData}
          setChanges={setChanges}
          onBlurSaveTable={onBlurSaveTable}
          onToggleLockUserLicence={onToggleLockUserLicence}
          onResendAction={onResendAction}
          onUserToDelete={setUserToDelete}
          onSelectedState={onSelectedState}
          selectedState={selectedState}
          setSelectedCountState={setSelectedCountState}
        />
        {selectedCountState ? (
          <Flex alignItems='center' justifyContent='flex-end' marginY={1}>
            <ButtonGroup
              backgroundColor={color1}
              color={getContrastColor(color1)}
              items={actionGroup({
                setIsOpenModalDeleteMultiUsers,
                setIsOpenModalMoveMultiUsers,
                setIsOpenModalCopyMultiUsers,
              })}
              sizeButton='small'
            />
          </Flex>
        ) : null}
      </Box>
      <Flex paddingX={4} paddingBottom={2} alignItems='center' justifyContent='space-between'>
        <Flex>
          <ExcelExportUsers data={items} />
          <ExcelImportUsers onImport={onImportExcelUsers} />
        </Flex>
        {!isPropertyManagerLiteRole ? (
          <IconTooltip
            sizeIcon='display36'
            color='grey'
            toolTipContent={t('instalationUserGroups.addUser')}
            onClick={handleClickAddModalUser}
          >
            <IconPlus />
          </IconTooltip>
        ) : (
          ''
        )}
      </Flex>
      <Flex paddingX={4} paddingY={2} alignItems='center' justifyContent='flex-end'>
        <Button
          backgroundColor='blackLight'
          onClick={() => history.push(`/Facilities/${facilityId}/groups/`)}
        >
          {t('general.back')}
        </Button>
      </Flex>
      <ModalDeleteUser
        onDeleteUser={onDeleteUser}
        userToDelete={userToDelete}
        setUserToDelete={setUserToDelete}
      />
      <ModalDeleteMultiUsers
        count={selectedCountState}
        isOpenModalDeleteMultiUsers={isOpenModalDeleteMultiUsers}
        setIsOpenModalDeleteMultiUsers={setIsOpenModalDeleteMultiUsers}
        onDeleteMultiUsers={onDeleteMultiUsers}
      />
      <ModalMoveMultiUsers
        count={selectedCountState}
        isOpenModalMoveMultiUsers={isOpenModalMoveMultiUsers}
        setIsOpenModalMoveMultiUsers={setIsOpenModalMoveMultiUsers}
        onMoveMultiUsers={onMoveMultiUsers}
      />
      <ModalCopyMultiUsers
        count={selectedCountState}
        isOpenModalCopyMultiUsers={isOpenModalCopyMultiUsers}
        setIsOpenModalCopyMultiUsers={setIsOpenModalCopyMultiUsers}
        onCopyMultiUsers={onCopyMultiUsers}
      />
      <ModalAddUser
        isOpenModalAddUser={isOpenModalAddUser}
        setIsOpenModalAddUser={setIsOpenModalAddUser}
        setNewUser={setNewUser}
        newUserErrors={newUserErrors}
        onCreateNewUser={onCreateNewUser}
      />
    </>
  );
};

export default BoxUsers;

BoxUsers.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})),
  counter: PropTypes.shape({}),
  userToDelete: PropTypes.shape({}),
  selectedState: PropTypes.shape({}),
  selectedCountState: PropTypes.number,
  isOpenModalDeleteMultiUsers: PropTypes.bool.isRequired,
  isOpenModalMoveMultiUsers: PropTypes.bool.isRequired,
  isOpenModalCopyMultiUsers: PropTypes.bool.isRequired,
  isOpenModalAddUser: PropTypes.bool.isRequired,
  newUserErrors: PropTypes.shape({}),
  onCopyMultiUsers: PropTypes.func.isRequired,
  setIsOpenModalAddUser: PropTypes.func.isRequired,
  setIsOpenModalCopyMultiUsers: PropTypes.func.isRequired,
  setSelectedCountState: PropTypes.func.isRequired,
  setNewUser: PropTypes.func.isRequired,
  onCreateNewUser: PropTypes.func.isRequired,
  setIsOpenModalMoveMultiUsers: PropTypes.func.isRequired,
  setIsOpenModalDeleteMultiUsers: PropTypes.func.isRequired,
  onDeleteUser: PropTypes.func.isRequired,
  onDeleteMultiUsers: PropTypes.func.isRequired,
  onSelectedState: PropTypes.func.isRequired,
  setData: PropTypes.func.isRequired,
  setChanges: PropTypes.func.isRequired,
  onBlurSaveTable: PropTypes.func.isRequired,
  onResendAction: PropTypes.func.isRequired,
  onToggleLockUserLicence: PropTypes.func.isRequired,
  setUserToDelete: PropTypes.func.isRequired,
  onMoveMultiUsers: PropTypes.func.isRequired,
  onImportExcelUsers: PropTypes.func.isRequired,
};

BoxUsers.defaultProps = {
  items: [],
  counter: {},
  userToDelete: null,
  selectedState: null,
  selectedCountState: 0,
  newUserErrors: null,
};
