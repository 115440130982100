import PathEntity from '../../core/old_common/rooting/apiPaths/PathEntity';
import { apiVersion, mainPath } from '../../core/old_common/rooting/common';

class Routes extends PathEntity {
  constructor() {
    super('productfamilies');
  }

  get = () => this.manufacturePath(this.entityName);

  post = () => {
    this.mainPath = `${mainPath()}4.1`;
    const endpoint = this.manufacturePath(this.entityName);
    this.mainPath = `${mainPath()}${apiVersion}`;
    return endpoint;
  };

  getPaths = () => ({
    familiesGet: this.get,
    familiesPost: this.post,
  });
}

export default new Routes().getPaths();
