import React, { useCallback, useEffect, useState } from 'react';
import { Flex } from '@jcm-technologies/uikit/dist/atoms/Layout';
import BoxHolidays from '../../../components/Administration/Holidays/Box';
import MonthCalendar from '../../../components/Administration/Holidays/Calendar/MonthCalendar';
import ModalDelete from '../../../components/Administration/Holidays/Modal/DeleteAction';
import { useDispatch, useSelector } from 'react-redux';
import { setHolidays } from '../../../modules/holidays/actions';
import moment from 'moment';


const months = [
  'Januar',
  'Februar',
  'März',
  'April',
  'Mai',
  'Juni',
  'Juli',
  'August',
  'September',
  'Oktober',
  'November',
  'Dezember',
];

export const View = ()=>{
  const {
    user: { language: lang },
  } = useSelector((state) => state.user);
  const langDefault = 'en_GB';
  const [dataSelected, setDataSelected] = useState([]);
  const [isOpenedDeletePopUp, setIsOpenedDeletePopUp] = useState(false);
  const dispatch = useDispatch();
  const { holidays } = useSelector((state) => state.holidays);
  const defaultValue = '00000000-0000-0000-0000-000000000000';

  const setHolidaysAPI = useCallback((id, data) => {
    dispatch(setHolidays(id, data));
  });
  const removeItem = (element) => {
    const updateData = dataSelected.filter((item) => item.formatted !== element);
    setHolidaysAPI(holidays[0]?.id || defaultValue, updateData);
    setDataSelected(updateData);
  };
  const removeAll = () => {
    setHolidaysAPI(holidays[0]?.id || defaultValue, []);
    setDataSelected([]);
  };

  useEffect(() => {
    if (holidays) {
      const mappedHolidays = holidays[0]?.scheduleHolidayEntries?.map((el) => ({
        day: el?.day,
        value: el?.day,
        timestamp: moment(el?.day).unix(),
        formatted: moment(el?.day).format('DD-MM-yy'),
        holidayEntityId: holidays[0]?.id || defaultValue,
        id: el?.id || defaultValue,
      }));

      if (mappedHolidays?.length) {
        setDataSelected(mappedHolidays);
      }
    }
  }, [holidays]);


  return (
    <Flex padding={2} flexWrap={['wrap', 'wrap', 'nowrap']}>
      <MonthCalendar
        lang={lang || langDefault}
        holidays={holidays}
        dataSelected={dataSelected}
        setHolidaysAPI={setHolidaysAPI}
        setDataSelected={setDataSelected}
      />
      <BoxHolidays
        lang={lang || langDefault}
        months={months}
        dataSelected={dataSelected}
        setIsOpenedDeletePopUp={setIsOpenedDeletePopUp}
        removeItem={removeItem}
      />
      <ModalDelete
        isOpenedDeletePopUp={isOpenedDeletePopUp}
        setIsOpenedDeletePopUp={setIsOpenedDeletePopUp}
        removeAll={removeAll}
      />
    </Flex>
  )
};
