export default {
  instalationUserGroups: {
    manageFobsAndLicenses: 'Manage FOB and licenses',
    fobsLicensesGridTitle: 'FOBS / Licenses',
    email: 'Email',
    phone: 'Phone',
    addUser: 'Add user',
    deleteUserConfirmation: 'Are you sure you want to delete this user?',
    Fobs: 'Fobs',
    Licenses: 'Licenses',
    addFobToUser: 'Add FOB to user',
    expiration: 'Expiration',
    licenseStatus: 'Status',
    locked: 'Locked',
    unlocked: 'Unlocked',
    0: 'Bluetooth',
    1: 'Remote open',
    users: 'Users',
    information: 'Information',
  },
};
