export default {
  fobsPopUps: {
    instalations: 'Instal·lacions',
    groups: 'Grups',
    move: 'Moure',
    copy: 'Copiar',
    remove: 'Esborrar',
    removeQuestion: 'Esborrar els elements següents?',
    selectedItemsShow1: 'Esborrar els',
    selectedItemsShow2: 'elements seleccionats?',
    warningDeleteFobs: 'ATENCIÓ: Pot ser que tinguis més elements seleccionats dels que s veuen en pantalla.',
    selectedItemsMove1: 'Moure els',
    selectedItemsCopy1: 'Copiar els',
    add: 'Afegir',
    selectedItemsAdd1: 'Afegir els',
  },
};