import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import TabMenu from '../../old_components/TabMenu/TabMenu';
import ManualEnrollCode from '../../old_components/ManualEnrollCode';
import SequentialEnrollCode from '../../old_components/SequentialEnrollCode/SequentialEnrollCode';
import { getInstalations } from '../../modules/old_to_refact/actions/instalations';
import { getFamiliesSingleList } from '../../modules/productFamilies/action';
import { getInstalationGroups } from '../../modules/old_to_refact/actions/instalationGroups';
import ReservationsEnrollCode from '../../old_components/ReservationsEnrollCode/ReservationsEnrollCode';
import {
  createSignalRConnectionOnReducer,
  resetSignalRConnectionOnReducer,
} from '../../core/old_common/utils/signalRService';

import './EnrollCode.css';
import { getFamilyListByType } from '../../core/old_common/utils/familiesService';

class EnrollCode extends Component {
  componentDidMount = () => {
    const { getFamiliesSingleList, familyType } = this.props;
    getFamiliesSingleList(familyType, true);
    createSignalRConnectionOnReducer();
  };

  componentWillUnmount() {
    resetSignalRConnectionOnReducer();
  }

  getProducts = ({ withoutDevice169 } = {}) => {
    const productsArray = [];
    const family = getFamilyListByType(this.props.familiesSingleList, this.props.familyType);
    if (family) {
      const products = family.flat();
      products.forEach((family, i) => {
        productsArray.push({
          key: family.section,
          id: family.section,
          value: family.section,
          disabled: true,
          className: 'enroll-code-combo-family',
          options: family.items
            .filter((product) => (withoutDevice169 && product.code !== 169) || !withoutDevice169)
            .map((product) => ({
              key: product.productId,
              id: product.productId,
              value: product.name,
              product,
            })),
        });
      });
    }
    return productsArray;
  };

  tabs = () => {
    const { t, conters, tabsProps, needInstallerCode, isUG } = this.props;
    const tabList = [
      {
        title: t('title.manual'),
        active: true,
        bodyComponent: ManualEnrollCode,
        componentState: {
          ...tabsProps,
          needInstallerCode,
          fobsCount: conters,
          products: [{ key: '', id: '', value: '--' }, ...this.getProducts()],
          isUG: this.props.isUG,
        },
      },
      {
        title: t('title.sequential'),
        active: false,
        bodyComponent: SequentialEnrollCode,
        componentState: {
          ...tabsProps,
          needInstallerCode,
          fobsCount: conters,
          isUG: this.props.isUG,
          products: [
            { key: '', id: '', value: '--' },
            ...this.getProducts({ withoutDevice169: false }),
          ],
        },
      },
      {
        title: t('title.reservations'),
        active: false,
        bodyComponent: ReservationsEnrollCode,
        componentState: {
          ...tabsProps,
          needInstallerCode,
          fobsCount: conters,
          isUG: this.props.isUG,
          products: [
            { key: '', id: '', value: '--' },
            ...this.getProducts({ withoutDevice169: false }),
          ],
        },
      },
    ];
    return tabList;
  };

  render() {
    return <TabMenu tabType='tabs' tabsData={this.tabs()} />;
  }
}

const mapStateToProps = (state) => ({
  familiesSingleList: state.families.familiesSingleList,
});

const enrollCodeWithTranslation = withTranslation()(EnrollCode);
const enrollCodeWithRoute = withRouter(enrollCodeWithTranslation);

export default connect(mapStateToProps, {
  getInstalations,
  getInstalationGroups,
  getFamiliesSingleList,
})(enrollCodeWithRoute);
