import { Box, Flex } from '@jcm-technologies/uikit/dist/atoms/Layout';
import { Text } from '@jcm-technologies/uikit/dist/atoms/Typography';
import { Input } from '@jcm-technologies/uikit/dist/atoms/Input';
import { Checkbox } from '@jcm-technologies/uikit/dist/atoms/Checkbox';
import { useTranslation } from 'react-i18next';
import { IconTooltip } from '@jcm-technologies/uikit/dist/molecules/IconTooltip';
import PropTypes from 'prop-types';

const CustomizationFamiliesProductItem = ({
  family,
  familyIndex,
  onChangeNameProduct,
  onCheckProductItem,
  product,
  productIndex,
}) => {
  const { t } = useTranslation();
  return (
    <Box paddingX={4} key={product.id}>
      <hr />
      <Flex alignItems='center' justifyContent='space-between'>
        <Flex width='20%'>
          <Text>{product.masterName}</Text>
        </Flex>
        <Flex width='60%'>
          <Input
            width='100%'
            defaultValue={product.name}
            id={product.id}
            name={product.id}
            hasError={product.error}
            errorMessage={t('formValidation.isRequired')}
            onBlur={(name, value) =>
              onChangeNameProduct(name, value, family, product, productIndex, familyIndex)
            }
          />
        </Flex>
        <Flex>
          <IconTooltip
            color='red'
            sizeIcon='display16'
            toolTipContent={product.isEnabled ? t('form.disable') : t('form.enable')}
          >
            <Checkbox
              id={product.id}
              name={product.id}
              hasError={!product.isEnabled}
              defaultChecked={product.isEnabled}
              action={(name, value) =>
                onCheckProductItem(name, value, family, familyIndex, productIndex)
              }
            />
          </IconTooltip>
        </Flex>
      </Flex>
    </Box>
  );
};

export default CustomizationFamiliesProductItem;

CustomizationFamiliesProductItem.propTypes = {
  family: PropTypes.shape({
    id: PropTypes.string,
    isEnabled: PropTypes.bool,
    name: PropTypes.string,
    masterName: PropTypes.string,
    error: PropTypes.bool,
    products: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      })
    ),
  }).isRequired,
  product: PropTypes.shape({
    id: PropTypes.string,
    isEnabled: PropTypes.bool,
    name: PropTypes.string,
    masterName: PropTypes.string,
    error: PropTypes.bool,
  }).isRequired,
  productIndex: PropTypes.number.isRequired,
  familyIndex: PropTypes.number.isRequired,
  onCheckProductItem: PropTypes.func.isRequired,
  onChangeNameProduct: PropTypes.func.isRequired,
};
