import React, { Component } from 'react';
import { connect } from 'react-redux';
import { generatePath, withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { v1 as uuidv1 } from 'uuid';
import { CodesCounters } from '@jcm-technologies/uikit/dist/molecules/CodesCounters';
import GenericForm from '../GenericForm/GenericForm';

import { validateForm } from '../../core/old_common/validations/service';
import validationsDictionary from '../../core/old_common/validations';

import './ReservationsEnrollCode.css';
import { resetIsPostFobSuccess } from '../../modules/group/fobs/actions';
import { resetIsPostUniversalFobSuccess } from '../../modules/old_to_refact/actions/universalGroupsFobs';

class ReservationsEnrollCode extends Component {
  constructor(props) {
    super(props);
    this.state = { ...this.initialState };
  }

  initialState = {
    id: uuidv1(),
    productId: '',
    name: '',
    surname: '',
    nic: '',
    plaza: '',
    e_code: 0,
    codesAmount: '',
    formErrors: [],
    forceSaveChangePopup: true,
    fobSaved: false,
    needValidate: false,
  };

  componentDidUpdate() {
    const {
      history,
      match,
      isFobPostedSuccess,
      getFobCounters,
      resetIsPostFobSuccess,
      resetIsPostUniversalFobSuccess,
      isUniversalFobPostedSuccess,
    } = this.props;
    const { fobSaved } = this.state;
    if (fobSaved && isFobPostedSuccess) {
      const decoded = generatePath(`/Facilities/:instalationId/groups/:instalationGroupId`, {
        instalationId: match.params.instalationId,
        instalationGroupId: match.params.instalationGroupId,
      });
      this.props.history.push(`${decoded}`);
    } else if (this.state.fobSaved && isUniversalFobPostedSuccess) {
      const decoded = generatePath(`/UniversalGroups/:id/fobs`, {
        id: match.params.id.replace('%09', '%2509'),
      });
      this.props.history.push(`${decoded}`);
    }
    if (isFobPostedSuccess || isUniversalFobPostedSuccess) {
      getFobCounters();
      isFobPostedSuccess ? resetIsPostFobSuccess() : resetIsPostUniversalFobSuccess();
    }
    if (this.state.needValidate) {
      const formErrors = validateForm(this.getFields());
      this.setState({ needValidate: false, formErrors: formErrors.errors });
    }
  }

  checkValidations = () => {
    const { isUG, fobsCount } = this.props;
    if (isUG) {
      return [
        () => validationsDictionary.isRequired(this.state.codesAmount),
        () => validationsDictionary.isNumeric(this.state.codesAmount),
      ];
    }
    return [
      () => validationsDictionary.isRequired(this.state.codesAmount),
      () => validationsDictionary.isNumeric(this.state.codesAmount),
      () => validationsDictionary.deviceLimit(this.state.codesAmount, 1, fobsCount.free),
    ];
  };

  handleChange = (e) => this.setState({ [e.target.id]: e.target.value, needValidate: true });

  handleNumericChange = (e) => {
    if (!isNaN(e.target.value)) {
      this.setState({ [e.target.id]: parseInt(e.target.value), needValidate: true });
    }
  };

  getFields = () => {
    const { t, products, fobsCount, hiddenFields } = this.props;
    const fields = [
      [
        {
          type: 'dropdown',
          values: products,
          className: 'col',
          placeHolder: `${t('enrollCode.device')} *`,
          onChange: this.handleChange,
          value: this.state.productId,
          id: 'productId',
          validation: [() => validationsDictionary.isRequired(this.state.productId)],
        },
        {
          type: 'input',
          className: 'col',
          placeHolder: `${t('enrollCode.numberOfFobs')} *`,
          onChange: this.handleNumericChange,
          value: this.state.codesAmount,
          id: 'codesAmount',
          validation: this.checkValidations(),
        },
      ],
      [
        {
          type: 'input',
          className: 'col',
          placeHolder: t('form.name'),
          onChange: this.handleChange,
          value: this.state.name,
          id: 'name',
          validation: [() => validationsDictionary.isInTextLength(this.state.name, 50)],
        },
        {
          type: 'input',
          className: 'col',
          placeHolder: t('form.surname'),
          onChange: this.handleChange,
          value: this.state.surname,
          id: 'surname',
          validation: [() => validationsDictionary.isInTextLength(this.state.surname, 80)],
        },
      ],
      [
        {
          type: 'input',
          className: 'col',
          placeHolder: t('form.identityCard'),
          onChange: this.handleChange,
          value: this.state.nic,
          id: 'nic',
          validation: [() => validationsDictionary.isInTextLength(this.state.nic, 50)],
        },
        {
          type: 'input',
          className: 'col',
          placeHolder: t('enrollCode.spot'),
          onChange: this.handleNumericChange,
          value: this.state.plaza,
          id: 'plaza',
          validation: [() => validationsDictionary.isNumeric(this.state.plaza)],
          notShow: hiddenFields ? hiddenFields.includes('plaza') : false,
        },
      ],
    ];
    return fields;
  };

  handleCancel = () => {
    const { match, isUG } = this.props;
    if (isUG) {
      const decoded = generatePath(`/UniversalGroups/:id/fobs`, {
        id: encodeURIComponent(decodeURIComponent(match.params.id)),
      });
      this.props.history.push(`${decoded}`);
    } else {
      const decoded = generatePath(`/Facilities/:instalationId/groups/:instalationGroupId`, {
        instalationId: encodeURIComponent(decodeURIComponent(match.params.instalationId)),
        instalationGroupId: encodeURIComponent(decodeURIComponent(match.params.instalationGroupId)),
      });
      this.props.history.push(`${decoded}`);
    }
  };

  handleSubmit = (e) => {
    const { postSequentialFOB, history, match } = this.props;
    e?.preventDefault();
    const formErrors = validateForm(this.getFields());
    this.setState({ formErrors: formErrors.errors });
    if (!formErrors.hasError) {
      postSequentialFOB(this.state, true);
      this.setState({ ...this.initialState, forceSaveChangePopup: false, fobSaved: true });
    }
  };

  render() {
    const { tenants, fobsCount, t, isUG } = this.props;
    const { formErrors, onFlyFormErrors } = this.state;
    const isSubmitDisabled = fobsCount.free === 0 && !isUG;
    return (
      <div className='reservations-enroll-form'>
        <CodesCounters
          availableValue={fobsCount.free}
          availablePlaceholder={t('codesCount.available')}
          reservationValue={fobsCount.reserved}
          reservationPlaceholder={t('enrollCode.notAsigned')}
          usedValue={fobsCount.busy}
          usedPlaceholder={t('codesCount.used')}
        />
        <div className='col-md-12 col-lg-12'>
          <GenericForm
            tenants={tenants}
            fields={this.getFields()}
            isCancelVisible
            isSubmitDisabled={isSubmitDisabled}
            handleCancel={this.handleCancel}
            handleSubmit={this.handleSubmit}
            formErrors={[...(formErrors || []), ...(onFlyFormErrors || [])]}
            forceSaveChangePopup
          />
        </div>
        {isSubmitDisabled ? (
          <div className='enroll-error-message'> {t('enrollCode.cantEnroll')}</div>
        ) : (
          ''
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  tenants: state.tenants,
  isFobPostedSuccess: state.fobs.isFobPostedSuccess,
  isUniversalFobPostedSuccess: state.universalGroupsFobs.isUniversalFobPostedSuccess,
});

const ReservationsEnrollCodeWithTranslation = withTranslation()(ReservationsEnrollCode);
const ReservationsEnrollCodeWithRoute = withRouter(ReservationsEnrollCodeWithTranslation);

export default connect(mapStateToProps, { resetIsPostFobSuccess, resetIsPostUniversalFobSuccess })(
  ReservationsEnrollCodeWithRoute
);
