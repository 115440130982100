import { useEffect, useState } from 'react';
import { Modal } from '@jcm-technologies/uikit/dist/molecules/Modal';
import { Flex } from '@jcm-technologies/uikit/dist/atoms/Layout';
import { Text } from '@jcm-technologies/uikit/dist/atoms/Typography';
import { Button } from '@jcm-technologies/uikit/dist/atoms/Button';
import { Field } from '@jcm-technologies/uikit/dist/atoms/Field';
import { SelectInput } from '@jcm-technologies/uikit/dist/atoms/Select';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getContrastColor } from '../../../../../../core/helpers/colorManager';
import {
  getGroupUsersAvailableGroups,
  getGroupUsersAvailableFacilities,
} from '../../../../../../modules/group/users/actions';

export const ModalMoveMultiUsers = ({
  count,
  isOpenModalMoveMultiUsers,
  setIsOpenModalMoveMultiUsers,
  onMoveMultiUsers,
}) => {
  const { t } = useTranslation();
  const { id: idGroup, instalationId: idFacility } = useParams();
  const { availableFacilities, availableGroups } = useSelector((state) => state.groupUsers);
  const { color1 } = useSelector((state) => state.tenants);
  const dispatch = useDispatch();
  const [toFacility, setToFacility] = useState(null);
  const [toGroup, setToGroup] = useState(null);

  useEffect(() => {
    if (toFacility) {
      dispatch(getGroupUsersAvailableGroups(toFacility, idGroup, true));
    }
  }, [toFacility]);

  useEffect(() => {
    if (isOpenModalMoveMultiUsers) {
      dispatch(getGroupUsersAvailableFacilities(idFacility, idGroup, false));
    }
  }, [isOpenModalMoveMultiUsers]);

  return (
    <Modal
      isOpen={isOpenModalMoveMultiUsers}
      title={t('general.move')}
      closeWithButton
      id='modal-move-user'
      width={[300, 500, 664]}
      onModalClose={() => setIsOpenModalMoveMultiUsers(false)}
    >
      <Modal.Content>
        <Flex flexDirection='column' alignItems='center'>
          <Text fontWeight='bold' marginY={2} textAlign='center'>
            {t('fobsPopUps.selectedItemsMove1')}
            <Text as='span' color='red' sizeText='display24'>
              {count}
            </Text>
            {t('fobsPopUps.selectedItemsShow2')}
          </Text>
          <Text fontWeight='bold' marginY={2} textAlign='center'>
            {t('fobsPopUps.warningDeleteFobs')}
          </Text>
          <Field label={t('title.facilities')}>
            <SelectInput
              id='toFacility'
              name='toFacility'
              options={availableFacilities?.map((facility) => ({
                value: facility.name,
                label: facility.name,
                id: facility.id,
              }))}
              onChange={(name, value) => {
                setToFacility(value.id);
              }}
              placeholderMessage={t('general.selectOne')}
            />
          </Field>
          <Field label={t('title.group')}>
            <SelectInput
              id='toGroup'
              name='toGroup'
              isDisabled={!toFacility}
              options={availableGroups?.map((group) => ({
                value: group.name,
                label: group.name,
                id: group.id,
              }))}
              onChange={(name, value) => {
                setToGroup(value.id);
              }}
              placeholderMessage={t('general.selectOne')}
            />
          </Field>
        </Flex>
      </Modal.Content>
      <Modal.Actions>
        <Flex alignItems='center' justifyContent='flex-end'>
          <Button
            backgroundColor={color1}
            marginRight={2}
            color={getContrastColor(color1)}
            action={() => onMoveMultiUsers(toFacility, toGroup)}
          >
            {t('general.move')}
          </Button>
          <Button
            sizeText='display14'
            backgroundColor='blackLight'
            id='cancel-button'
            action={() => () => setIsOpenModalMoveMultiUsers(false)}
          >
            {t('general.cancel')}
          </Button>
        </Flex>
      </Modal.Actions>
    </Modal>
  );
};

ModalMoveMultiUsers.propTypes = {
  onMoveMultiUsers: PropTypes.func.isRequired,
  setIsOpenModalMoveMultiUsers: PropTypes.func.isRequired,
  isOpenModalMoveMultiUsers: PropTypes.bool.isRequired,
  count: PropTypes.number.isRequired,
};
