import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import SwitchCheckbox from '../../SwitchCheckbox';
import { convertArrayToBinary, convertBinaryToArray } from '../../../core/helpers/arrayService';

export class ChannelsCheckboxes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      channelsArray: convertBinaryToArray(props.relay.channelsMap),
      needsOneRelayError: {
        error: false,
        message: 'Needs min one Relay',
      },
    };
  }

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    // if (nextProps.channelsMap !== null) {
    this.setState({ channelsArray: convertBinaryToArray(nextProps.relay.channelsMap) });
    // }
  };

  getBinayArray = () => {
    const { channelsMap } = this.props.relay;
    let channelBinary = channelsMap.toString(2);
    channelBinary = channelBinary.toString(2).padStart(4, '0');
    const binaryArray = _.reverse(channelBinary.split(''));
    return binaryArray;
  };

  getBinaryNumber = (array) => {
    const binaryString = _.reverse(array).join('');
    const binaryNum = parseInt(binaryString, 2);
    return binaryNum;
  };

  onChange = (e) => {
    const { canUpdateRelay, t } = this.props;
    const { channelsArray } = this.state;
    const array = [...channelsArray];
    array[e.target.id] = e.target.checked ? '1' : '0';
    const binaryNumber = convertArrayToBinary(array);
    if (canUpdateRelay(binaryNumber)) {
      this.props.onChange({ relay: this.props.relay, channelsMap: binaryNumber });
      this.setState({
        needsOneRelayError: {
          error: false,
          message: t('errors.needsMinOnRelay'),
        },
      });
    } else {
      this.setState({
        needsOneRelayError: {
          error: true,
          message: t('errors.needsMinOnRelay'),
        },
      });
    }
  };

  getChannels = () => {
    const { channelsArray } = this.state;
    const { t } = this.props;
    const channels = channelsArray.map((channel, i) => (
      <SwitchCheckbox
        key={i}
        id={i}
        value={channel === '1'}
        placeHolder={`${t(`event.status.91058`)} ${i + 1}`}
        onChange={this.onChange}
      />
    ));

    return channels;
  };

  render() {
    const { id } = this.props;
    return (
      <div key={id} className='relays-channels'>
        {this.getChannels()}
        <div className='relays-error-message'>
          {this.state.needsOneRelayError.error ? this.state.needsOneRelayError.message : ''}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const ChannelsCheckboxesPage = withTranslation()(ChannelsCheckboxes);
const channelsCheckboxesWithRouter = withRouter(ChannelsCheckboxesPage);

export default connect(mapStateToProps, {})(channelsCheckboxesWithRouter);
