export default {
  neighbourPopUp: {
    title: 'Přidat souseda',
    email: 'E-mail',
    phoneNumber: 'Telefonní číslo',
    floor: 'Patro',
    door: 'Dveře',
    titleEdit: 'Upavit kontakt',
    titleDelete: 'Jsi si jistý, že chceš vymazat tento kontakt?',
  },
};
