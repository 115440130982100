export default {
  instalationGroups: {
    exportExcel: 'Export als Excel',
    importExcel: 'Excel importieren',
    exportPDF: 'PDF Herunterladen',
    identification: 'Personalausweisnummer',
    place: 'Parkplatz',
    enrollFOB: 'Seriennummernverwaltung',
    config: 'Konfigurieren',
    deleteConfirmation: 'Möchten Sie diese Gruppe wirklich löschen?',
    description: 'Beschreibung',
    facilityName: 'Name der Einrichtung',
    replace: 'Ersetzen',
    delete: 'Löschen',
    deleteFobConfirmation: 'Möchten Sie diesen emitter wirklich löschen?',
    alarms: 'Alarm',
    selectConfigFields: 'Wählen Sie die zu exportierenden BENUTZERFELDER / SENDER',
    totalSelected: 'Insgesamt ausgewählt',
  },
};
