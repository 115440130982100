import { CREATE_GROUP_MIFARE, GET_GROUP_MIFARE_LIST, MIFARE_STORE } from './constants';

/* Redux state init */
const initialState = {
  list: [],
  customFields: [],
  isCreationSuccess: false,
};

export default function mifareReducer(state = initialState, action) {
  if (action.type !== undefined) {
    switch (action.type) {
      case GET_GROUP_MIFARE_LIST.SUCCESS:
        return { ...state, list: action.result.Data, customFields: action.result.MetaData };
      case CREATE_GROUP_MIFARE.REQUEST:
        return { ...state, isCreationSuccess: false };
      case CREATE_GROUP_MIFARE.SUCCESS:
        return { ...state, isCreationSuccess: true };
      case MIFARE_STORE:
        return { ...state, [action.key]: action.value };
      default:
        return state;
    }
  }
  return state;
}
