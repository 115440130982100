import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { v1 as uuidv1 } from 'uuid';

import moment from 'moment';
import {
  getFacility,
  postInstalations,
  putInstalations,
  resetInstallerCodePopup,
} from '../../modules/old_to_refact/actions/instalations';
import { getCookieCustomerId } from '../../core/old_common/utils/cookiesManager';
import validationsDictionary from '../../core/old_common/validations/index';
import { setOnFlyErrors } from '../../core/old_common/validations/service';

import './IndividualInstalationForm.css';
import {
  CreationPath_Instalations,
  RootPath_Instalations,
} from '../../core/old_common/constats/genericListPaths';
import IndividualGrowGenericForm from '../IndividualGrowGenericForm/IndividualGrowGenericForm';
import { generateFields } from '../../core/old_common/utils/createInputByTenant';
import apiPathFactory from '../../core/old_common/rooting/apiPathFactory';
import getOnFlyValidations from '../../core/old_common/utils/validationsApi';
import { getCustomerInstallerCode } from '../../modules/customers/actions';
import { getUser } from '../../modules/user/actions';
import PopupInstallerCode from './components/PopupInstallerCode';
import { convertDateTimeForBackend } from '../../core/old_common/utils/dataService';

class IndividualInstalationForm extends Component {
  constructor(props) {
    super(props);
    this.state = { onFlyFormErrors: [], idOpened: null };
  }

  createNewState = (props) => ({
    id: props.id || '',
    customerId: props.customerId || getCookieCustomerId(),
    name: props.name || '',
    customerName: props.customerName || '',
    maintenanceDate: props.maintenanceDate || this.getDefaultDate(),
    city: props.city || '',
    country: props.country || '',
    telephone: props.telephone || '',
    contact: props.contact || '',
    address: props.address || '',
    zip: props.zip || '',
    comment: props.comment || '',
    installerCode: this.getCheckValue(props),
    uniqueIdentity: props.uniqueIdentity,
    facilityGroups: props.facilityGroups || [],
    facilityAttributes: props.facilityAttributes || [],
    productParams: [],
    oldInstalationId: props.oldInstalationId,
    creationDate: props.creationDate ? props.creationDate : this.createDateWhenIsCreating(),
  });

  componentDidMount = () => {
    const { id, match } = this.props;
    const { idOpened } = this.state;
    if (!id) this.props.getCustomerInstallerCode();
    this.setState({
      idOpened: match?.params?.id,
    });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.id !== this.props.match?.params?.id &&
      this.props.match?.params?.id !== 'Facility'
    ) {
      this.props.getFacility(this.props.match?.params?.id);
    }
  }

  createDateWhenIsCreating = () => {
    const { match } = this.props;
    if (match.url === '/Facilities/Facility') {
      const formatedDate = convertDateTimeForBackend(new Date());
      return formatedDate;
    }
    return null;
  };

  getCheckValue = (props) => {
    if (this.props.location.pathname === '/Facilities/Facility') {
      return this.props.installerCodeDefined.useInstallerCode;
    }
    return props.installerCode === undefined ? false : props.installerCode;
  };

  getDefaultDate = (format) => {
    const dateMaintenance = new Date();
    const year = dateMaintenance.getFullYear();
    const month = dateMaintenance.getMonth();
    const day = dateMaintenance.getDate();
    const nextYearDate = new Date(year + 1, month, day);
    const formatedDate = convertDateTimeForBackend(nextYearDate);
    return formatedDate;
  };

  setErrorsCallback = ({ onFlyFormErrors, error, id, errorMessage }) => {
    this.setState({
      onFlyFormErrors: setOnFlyErrors({ onFlyFormErrors, error, id, errorMessage }),
    });
  };

  getFields = (newFields) => {
    const { t, instalationConfigFields, match, creationURI } = this.props;
    const fields = [
      [
        {
          type: 'input',
          placeHolder: `${t('form.name')} *`,
          value: 'name',
          id: 'name',
          onBlur: () =>
            newFields.name !== this.props.name
              ? this.validationOnFly('name', newFields.name)
              : this.setErrorsCallback({
                  onFlyFormErrors: this.state.onFlyFormErrors,
                  error: undefined,
                  id: 'name',
                }),
          validation: [
            () => validationsDictionary.isRequired(newFields.name),
            () => validationsDictionary.isInTextLength(newFields.name, 255),
            () => validationsDictionary.isNotCreationName(newFields.name, 'FACILITY'),
          ],
        },
        {
          type: 'input',
          placeHolder: t('updateInstalationForm.contact'),
          value: 'contact',
          id: 'contact',
          validation: [() => validationsDictionary.isInTextLength(newFields.contact, 80)],
        },
        {
          type: 'input',
          placeHolder: t('updateInstalationForm.telephone'),
          value: 'telephone',
          id: 'telephone',
          validation: [() => validationsDictionary.isInTextLength(newFields.telephone, 25)],
        },
        {
          type: 'input',
          placeHolder: t('updateInstalationForm.uniqueIdentity'),
          value: 'uniqueIdentity',
          id: 'uniqueIdentity',
          onBlur: () =>
            newFields.uniqueIdentity !== this.props.uniqueIdentity
              ? this.validationOnFly('uniqueIdentity', newFields.uniqueIdentity)
              : this.setErrorsCallback({
                  onFlyFormErrors: this.state.onFlyFormErrors,
                  error: undefined,
                  id: 'uniqueIdentity',
                }),
          validation: [() => validationsDictionary.isInTextLength(newFields.uniqueIdentity, 20)],
        },
      ],
      [
        {
          type: 'input',
          placeHolder: `${t('updateInstalationForm.address')} *`,
          value: 'address',
          id: 'address',
          validation: [
            () => validationsDictionary.isRequired(newFields.address),
            () => validationsDictionary.isInTextLength(newFields.address, 255),
          ],
        },
        {
          type: 'input',
          placeHolder: t('form.postalCode'),
          value: 'zip',
          id: 'zip',
          validation: [
            () => validationsDictionary.notOnlySpaces(newFields.zip),
            () => validationsDictionary.isInTextLength(newFields.zip, 25),
          ],
        },
        {
          type: 'input',
          placeHolder: `${t('form.city')} *`,
          value: 'city',
          id: 'city',
          validation: [
            () => validationsDictionary.notOnlySpacesIsRequired(newFields.city),
            () => validationsDictionary.isInTextLength(newFields.city, 80),
          ],
        },
        {
          type: 'input',
          placeHolder: `${t('updateInstalationForm.country')} *`,
          value: 'country',
          id: 'country',
          validation: [
            () => validationsDictionary.isRequired(newFields.country),
            () => validationsDictionary.isInTextLength(newFields.country, 50),
          ],
        },
      ],
      [
        {
          type:
            match.url === '/Facilities/Facility' || !newFields.creationDate ? 'hidden' : 'dateTime',
          placeHolder:
            newFields.creationDate === null || match.url === '/Facilities/Facility'
              ? null
              : t('updateInstalationForm.creationDate'),
          value: 'creationDate',
          id: 'creationDate',
          isDisabled: true,
          nonSelect: true,
          labelClassName: 'jcm-label',
          dateFormat:
            match.url === '/Facilities/Facility'
              ? this.props.user.dateTimeFormat
              : this.props.dateTimeFormat,
        },
        {
          type: 'dateTime',
          placeHolder: `${t('updateInstalationForm.maintenanceDate')} *`,
          value: 'maintenanceDate',
          id: 'maintenanceDate',
          min: new Date(1753, 1, 1),
          max: new Date(9999, 31, 12),
          validation: [() => validationsDictionary.isRequired(newFields.maintenanceDate)],
          labelClassName: 'jcm-label',
          dateFormat:
            match.url === '/Facilities/Facility'
              ? this.props.user.dateTimeFormat
              : this.props.dateTimeFormat,
        },
        {
          type: 'switchCheckbox',
          labelId: 'installerCodeCheck',
          placeHolder: t('updateInstalationForm.installerCode'),
          value: 'installerCode',
          isChecked: 'installerCode',
          id: 'installerCode',
          labelClassName: 'installer-code-instalation-form',
        },
      ],
      ...generateFields(instalationConfigFields, 3, 4, 'facilityAttributes'),
      [
        {
          type: 'textarea',
          placeHolder: t('updateInstalationForm.comment'),
          value: 'comment',
          id: 'comment',
          validation: [() => validationsDictionary.isInTextLength(newFields.comment, 255)],
        },
      ],
    ];

    return fields;
  };

  createAttributesOnChange = (value, field, formState) => ({
    id: uuidv1(),
    type: 3,
    tag: field.value,
    facilityId: formState.id,
    value,
  });

  formatFormStateToPost = (formState) => {
    const newFormState = { ...formState, id: formState.id || uuidv1() };
    if (newFormState.facilityAttributes) {
      newFormState.facilityAttributes.forEach((attribute) => {
        attribute.facilityId = newFormState.facilityId;
      });
    }
    return newFormState;
  };

  validationOnFly = (name, value) => {
    const { t } = this.props;
    getOnFlyValidations(apiPathFactory.facilitiesGetCheckContent(name, value), (isError) =>
      this.setErrorsCallback({
        onFlyFormErrors: this.state.onFlyFormErrors,
        error: isError,
        id: name,
        errorMessage: t(`errors.${name}`),
      })
    );
  };

  render() {
    const {
      t,
      getFacility,
      postInstalations,
      putInstalations,
      facility,
      showInstallerCodePopup,
      resetInstallerCodePopup,
    } = this.props;
    return (
      <div className='instalation-information-form'>
        <IndividualGrowGenericForm
          item={this.createNewState(this.props)}
          updateItem={putInstalations}
          createItem={postInstalations}
          baseURI={RootPath_Instalations}
          creationURI={CreationPath_Instalations}
          getItem={getFacility}
          goToIdRedirect={facility?.id}
          fields={(fields) => this.getFields(fields)}
          createItemOnChange={this.createAttributesOnChange}
          onFlyformErrors={this.state.onFlyFormErrors}
          getContentToCreate={this.formatFormStateToPost}
          submitText={t('general.save')}
          justifyContent='flex-start'
        />
        <PopupInstallerCode
          title={t('updateInstalationForm.installerCode')}
          isOpen={showInstallerCodePopup}
          onClose={() => resetInstallerCodePopup()}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  tenants: state.tenants,
  facility: state.instalations.facility,
  originalTenants: state.tenants.originalTenants,
  installerCodeDefined: state.customers.installerCode,
  instalationConfigFields: state.instalations.instalationConfigFields,
  user: state.user.user,
  showInstallerCodePopup: state.instalations.showInstallerCodePopup,
});

const IndividualInstalationFormWithTranslation = withTranslation()(IndividualInstalationForm);
const IndividualInstalationFormWithRouter = withRouter(IndividualInstalationFormWithTranslation);

export default connect(mapStateToProps, {
  putInstalations,
  postInstalations,
  getCustomerInstallerCode,
  getFacility,
  getUser,
  resetInstallerCodePopup,
})(IndividualInstalationFormWithRouter);
