import React, { Component } from 'react';
import { connect } from 'react-redux';
import IconSpan from '../IconSpan/IconSpan';

import Popup from '../Popup/Popup';
import './customMapModal.css';
import { withTranslation } from 'react-i18next';

export class GenericMapButton extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }

  render() {
    const {
      isMapButtonVisible,
      isMapButtonDisabled,
      mapProps,
      children,
      onClick,
      onClosePopUp,
      mapId,
      t,
      customIcon,
      isUniversalOnFacilities,
    } = this.props;
    return (
      <span className='generic-grow-header-item'>
        <IconSpan
          defaultColor='#a4aebb'
          iconClassName={customIcon || 'fas fa-sitemap generic-grow-map-icon'}
          cursor={isUniversalOnFacilities ? 'default' : 'pointer'}
          isHidden={isMapButtonVisible}
          disabled={isMapButtonDisabled}
          toolTip={isUniversalOnFacilities ? '' : t('genericGrow.map')}
          id={mapId}
          onClick={(e) => {
            e?.stopPropagation();
            onClick(mapId);
          }}
        />

        <Popup isOpen={mapProps.isOpen} onClose={onClosePopUp} className='map'>
          {children}
        </Popup>
      </span>
    );
  }
}

const mapStateToProps = (state) => ({
  // instalation: state.instalations.instalationInfo
});

const GenericMapButtonWithTranslation = withTranslation()(GenericMapButton);

export default connect(mapStateToProps, {})(GenericMapButtonWithTranslation);
