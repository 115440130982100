import { GridColumn } from '@progress/kendo-react-grid';
import { Flex } from '@jcm-technologies/uikit/dist/atoms/Layout';
import { Icon } from '@jcm-technologies/uikit/dist/atoms/Icon';
import IconLockOpen from '@jcm-technologies/uikit/dist/atoms/Icons/IconLockOpen';
import IconLock from '@jcm-technologies/uikit/dist/atoms/Icons/IconLock';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { CustomFilter, SelectCell } from '../../../common/Table/renderers';
import Table from '../../../common/Table';

export const TableEmployees = ({
  dataLoaded = [],
  setDataLoaded,
  data = [],
  setIsOpened,
  setActionToSave,
  setDataToSave,
  userRoles = [],
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Table
        dataLoaded={dataLoaded}
        setDataLoaded={setDataLoaded}
        pageable
        filterable
        editable
        sortable
        data={data}
      >
        <GridColumn
          field='name'
          title={t('form.name')}
          filterCell={CustomFilter}
          filter='text'
          editable={false}
        />
        <GridColumn
          field='surname'
          title={t('form.surname')}
          filterCell={CustomFilter}
          filter='text'
          editable={false}
        />
        <GridColumn
          field='email'
          title={t('form.email')}
          filterCell={CustomFilter}
          filter='text'
          editable={false}
        />
        <GridColumn
          field='role'
          title={t('form.role')}
          filterCell={CustomFilter}
          filter='text'
          editable
          cell={(props) => {
            const user = data?.find((el) => el.id === props.dataItem.id);
            const roleUser = userRoles?.find((role) => role.id === user.roleId);
            return (
              <SelectCell
                id={user.id}
                selectedName={roleUser?.name || 'Admin'}
                options={userRoles}
                action={(selected) => {
                  setIsOpened(true);
                  setActionToSave('role');
                  setDataToSave({ ...selected, user });
                }}
              />
            );
          }}
        />
        <GridColumn
          field='enabled'
          title={t('form.enabled')}
          filterable={false}
          editable={false}
          width={100}
          cell={(props) => {
            const user = data.find((el) => el.id === props.dataItem.id);
            return (
              <td colSpan='1'>
                <Flex alignItems='center' justifyContent='center'>
                  <Icon
                    sizeIcon='display24'
                    onClick={() => {
                      setIsOpened(true);
                      setActionToSave('enabled');
                      setDataToSave({
                        ...user,
                        isEnabled: !user.isEnabled,
                      });
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    {user?.isEnabled ? <IconLockOpen /> : <IconLock />}
                  </Icon>
                </Flex>
              </td>
            );
          }}
        />
      </Table>
    </>
  );
};

TableEmployees.propTypes = {
  dataLoaded: PropTypes.arrayOf(PropTypes.shape({})),
  setDataLoaded: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})),
  setIsOpened: PropTypes.func.isRequired,
  setActionToSave: PropTypes.func.isRequired,
  setDataToSave: PropTypes.func.isRequired,
  userRoles: PropTypes.arrayOf(PropTypes.shape({})),
};
