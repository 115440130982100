import styled from 'styled-components';
import { getContrastColor } from '../../core/helpers/colorManager';

export default styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: stretch;
  align-items: center;
  height: 55px;
  width: 100%;
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => getContrastColor(props.backgroundColor)};
`;
