import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DashboardTemplate from '../../components/Dashboard';
import {
  getCities,
  getDashboardFilter,
  getDashboardFilterByType,
  getDashboardInfo,
  getEquipments,
  getField,
  getPostalCode,
} from '../../modules/dashboard/actions';
import { getGroups, getGroupsFobHistory } from '../../modules/old_to_refact/actions/groups';
import { getCookieAccessToken, setCookie } from '../../core/old_common/utils/cookiesManager';
import { getFacilityLogsByUser } from '../../modules/old_to_refact/actions/instalations';

const Dashboard = () => {
  const [filterSelected, setFilterSelected] = useState({ value: '', label: '' });
  const [valueFilter, setValueFilter] = useState('');
  const [filterTypeSelected, setFilterTypeSelected] = useState('');
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const accessToken = getCookieAccessToken();
  const dashboard = useSelector((state) => state.dashboard);
  const getFilterAPI = useCallback(() => dispatch(getDashboardFilter()), [dispatch]);
  const getFilterByTypeAPI = useCallback(
    (type) => dispatch(getDashboardFilterByType(type)),
    [dispatch]
  );
  const getInfoAPI = useCallback(() => dispatch(getDashboardInfo()), [dispatch]);
  const getPostalCodesAPI = useCallback(() => dispatch(getPostalCode()), [dispatch]);
  const getCitiesAPI = useCallback(() => dispatch(getCities()), [dispatch]);
  const getFieldAPI = useCallback(
    (fieldId, value) => dispatch(getField(fieldId, value)),
    [dispatch]
  );
  const getFacilitiesLogsByUserAPI = useCallback(
    (userId) => dispatch(getFacilityLogsByUser(userId)),
    [dispatch]
  );
  const getGroupsAPI = useCallback(
    (fieldId, value) => dispatch(getGroups(fieldId, value)),
    [dispatch]
  );
  const getGroupsFobHistoryAPI = useCallback((value) => dispatch(getGroupsFobHistory(value)));
  const getEquipmentsSearchAPI = useCallback(
    (fieldId, value) => dispatch(getEquipments(fieldId, value)),
    [dispatch]
  );
  const history = useHistory();

  useEffect(() => {
    if (accessToken) {
      getFilterAPI();
      getInfoAPI();
    }
  }, [accessToken]);

  useEffect(() => {
    if (filterSelected.value === 4) {
      getPostalCodesAPI();
    }
    if (filterSelected.value === 3) {
      getCitiesAPI();
    }
  }, [filterSelected]);

  useEffect(() => {
    if (filterTypeSelected.value) getFilterByTypeAPI(filterTypeSelected.value);
    setFilterSelected({ value: '', label: '' });
    setValueFilter('');
  }, [filterTypeSelected]);

  const redirectTo = (field, value) => {
    if (filterTypeSelected?.value === 2) {
      getEquipmentsSearchAPI(field, value);
      setCookie('equipmentSearch', { fieldId: field, value, label: filterSelected.label });
      history.push('/Equipments');
    } else if (filterTypeSelected?.value === 3 || filterTypeSelected?.value === 6) {
      // REFACT: Now we send fieldId 40 if user select type 6 because BE need update the BACK
      setCookie('groupsSearch', {
        fieldId: field || 40,
        value,
        label: filterSelected.label || 'mifare',
      });
      getGroupsAPI(field || 40, value);
      history.push('/groups');
    } else if (filterTypeSelected?.value === 4) {
      getFacilitiesLogsByUserAPI(field);
      setCookie('facilitiesSearch', {
        fieldId: 'caUsers',
        value: filterSelected.label,
        label: t('title.caUsers'),
        valueId: filterSelected.value,
      });
      history.push('/Facilities');
    } else if (filterTypeSelected?.value === 5) {
      setCookie('fobHistorySearch', { fieldId: 'fobHistory', value, label: 'fobHistory' });
      getGroupsFobHistoryAPI(value);
      history.push('/groups');
    } else {
      getFieldAPI(field, value);
      setCookie('facilitiesSearch', {
        fieldId: field,
        value,
        label: filterSelected.label,
      });
      history.push('/Facilities');
    }
  };

  useEffect(() => {
    setCookie('facilitiesSearch', null);
    setCookie('groupsSearch', null);
    setCookie('fobHistorySearch', null);
  }, []);

  return (
    <DashboardTemplate
      onSetFilterType={(value) => setFilterTypeSelected(value)}
      onSetFilter={(value) => setFilterSelected(value)}
      filterTypeSelected={filterTypeSelected}
      filterSelected={filterSelected}
      pendingMaintenance={dashboard.info?.pendingMaintenance}
      honoaUsersCount={dashboard.info?.honoaUsersCount}
      honoaLicenses={dashboard.info?.honoaLicenses}
      onlineStatus={dashboard.info?.onlineStatus}
      missconfiguredDevices={dashboard.info?.missconfiguredDevices}
      activeAlarms={dashboard.info?.alarms}
      valueFilter={valueFilter}
      onSetValueFilter={(value) => setValueFilter(value)}
      handleSearch={redirectTo}
      handleRedirectTo={redirectTo}
    />
  );
};

const DashboardPage = Dashboard;

export default DashboardPage;
