import React from 'react';
import { Route } from 'react-router-dom';
import ErrorMessage from '../../old_components/ErrorMessage';
import { logedRoutes, notLogedRoutes } from '../../routes';
import { isAuth } from './Auth';

const getLogedRoutes = () =>
  logedRoutes.map((route) => logedRoute(route.path, route.component, route.title));

const getNotLogedRoutes = () =>
  notLogedRoutes.map((route) => notLogedRoute(route.path, route.component, route.title));

const logedRoute = (route, Component, title) =>
  isAuth(route) ? (
    <Route key={route} exact path={route} render={() => <Component />} />
  ) : (
    <Route
      key={route}
      exact
      path={route}
      render={() => <ErrorMessage message='No permissions for this page' />}
    />
  );

const notLogedRoute = (route, Component, title) => (
  <Route key={route} exact path={route} render={() => <Component />} />
);

export { getLogedRoutes, getNotLogedRoutes };
