export default {
  breadcrumb: {
    dashboard: 'Dashboard',
    Facilities: 'Instalaciones',
    FacilityEquipments: 'Equipos',
    Groups: 'Grupos',
    Equipments: 'Equipos',
    AddUniversalGroup: 'Añadir Grupo Universal',
    Configuration: 'Configuración',
    Enroll: 'Proceso de inscripción',
    administration: 'Administración',
    'user-config': 'Mi cuenta',
    UniversalGroups: 'Grupos Universales',
    Users: 'Usuarios',
    Help: 'Ayuda',
    Parameters: 'Parámetros',
    Relays: 'Relés',
    Fobs: 'Emisores',
    Actions: 'Acciones',
    Events: 'Eventos',
    'Remote comm': 'Comunicaciones remotas',
    Facility: 'Nueva instalación',
    Equipment: 'Nuevo equipo',
    FacilityGroup: 'Nuevo grupo',
    Neighbours: 'Lista de vecinos',
    UniversalGroup: 'Nuevo grupo Universal',
    Schedule: 'Nuevo Horario',
    customization: 'Personalización web',
    Invitations: 'Invitaciones',
    Companies: 'Compañías',
    UpdateUser: 'Mi cuenta',
    preferences: 'Preferencias',
    'assistant-panel': 'Assistant Panel',
    Holidays: 'Días festivos',
    Information: 'Información',
    recycle: 'Papelera',
  },
};
