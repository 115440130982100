import i18Next from 'i18next';

const mapperLog = (log) => {
  const codeSplit = log.code.split('->');
  return {
    ...log,
    code: log.code.includes('reserved')
      ? `${i18Next.t('form.reserved')} -> ${codeSplit[1]}`
      : log.code,
  };
};

export const mapperLogs = (info) => {
  if (info) {
    return {
      ...info,
      logs: info.logs?.map((log) => mapperLog(log)) || [],
    };
  }
  return undefined;
};
