export default {
  instalations: {
    groupsTab: 'Groupes',
    equipmentsTab: 'Équipements',
    addGroup: 'Ajouter un nouveau groupe',
    deleteConfirmation: 'Voulez-vous vraiment supprimer cette fonctionnalité?',
    add: 'Ajouter une installation',
    config: "Configurer l'installation",
    notAllow: " Les appareils attribués n'autorisent pas les planifications",
    allDevicesConnected: 'Tous connectés',
    allDevicesDisconnected: 'Dernière mantinence:',
    includeReservations: 'Inclure les réservations?',
    includeConfigFields: 'Inclure les champs de configuration?',
    acceptExportPopUpButton: 'Exportation',
    exportPopUpTitle: 'Exporter des groupes',
    nameTableheader: 'Nom',
    allEquipmentsStatusOk: 'Tous les appareils connectés',
    numberOfConnectedDevices: 'Appareils trouvés',
    filters: 'Filtres',
    criticalDeleteTitle: 'ACTION CRITIQUE IRRÉVERSIBLE',
    firstAreYouSureCriticalDelete: 'Vous êtes sûr(e) de vouloir supprimer  ',
    secondAreYouSureCriticalDelete: '',
    noGoBack: 'CETTE ACTION EST IRRÉVERSIBLE',
    transfer: 'Transférer',
    transferPopUpLabel: "Administrateur qui gère l'installation",
    transferPopUpSelector: "Sélectionnez l'utilisateur",
    restore: 'Restaurer',
    recycleBin: 'Poubelle',
    creation: 'Création',
    saveMemory: 'Sauvegarde de la mémoire',
    substitution: 'Substitution',
    fob: 'Émetteur',
    equipment: 'Équipement',
    deletion: 'Effacement',
    moved: 'Déplacé',
    locked: 'Fermé à clé',
    unlocked: 'Débloqué'
  },
};
