export default {
  gsmPopUp: {
    title: 'Estat del servei',
    deviceNotSimInfo: 'El dispositiu està desconnectat',
    simContractInfo: 'Informació del contracte',
    expiration: 'Caducitat',
    status: 'Estat',
    infoNotAvailable: 'Informació no disponible',
    simConnectionInfo: 'Informació de connexió SIM',
    cardIdentifier: 'Identificador de targeta (ICCID)',
    activationDate: "Data d'activació",
    lastMonthDataUpload: 'Càrrega de dades del mes passat',
    lastMonthDataDownload: 'Descàrrega de dades del mes passat',
    lastSeen: 'Vist per últim cop',
    contractStatus: 'Estat del contracte',
    simStatus: 'Estat de la SIM',
    deviceStatus: 'Estat del dispositiu',
    enabled: 'Habilitat',
    disabled: 'Deshabilitat',
    notAvailable: 'No disponible',
    connected: 'Connectat',
    disconnected: 'Desconnectat',
  },
};
