export default {
  enrollCode: {
    manualTab: 'Manual',
    reservationsTab: 'Reserva',
    sequentialTab: 'Seqüencial',
    universalsTab: 'Universal',
    device: 'Dispositiu',
    initialCode: 'Codi inicial',
    name: 'Nom',
    surname: 'Cognom',
    idCard: 'DNI',
    spot: 'Plaça',
    numberOfFobs: "Número d'emissors",
    endCode: 'Codi final',
    cantEnroll: "Per a donar d'alta nous codis necessites un dispositiu i tenir posicions lliures",
    cancel: 'Cancel·lar',
    accept: 'Acceptar',
    ok: 'Ok',
    forceCodeQuestion: "Vols forçar el registre d'aquest codi?",
    codeAlreadyExist: 'Aquest codi ja existeix en un altre grup',
    notAsigned: 'No assignat',
    reserved: 'Reserva',
  },
};
