import { Modal } from '@jcm-technologies/uikit/dist/molecules/Modal';
import { ModalGeneric } from '../ModalGeneric';
import { Flex } from '@jcm-technologies/uikit/dist/atoms/Layout';
import { Button } from '@jcm-technologies/uikit/dist/atoms/Button';
import { useTranslation } from 'react-i18next';
import { SaveActionModal } from '../../../Administration/Employees/Modal/SaveAction';
import { useSelector } from 'react-redux';
import { getContrastColor } from '../../../../core/helpers/colorManager';
import { Text } from '@jcm-technologies/uikit/dist/atoms/Typography/Typography';

export const SaveModal = ({ handleSave, isOpened, setIsOpened, text }) => {
  const handleClose = () => setIsOpened(false);
  const { color1 } = useSelector((state) => state.tenants);
  const { t } = useTranslation();
  return (
    <ModalGeneric
      isOpen={isOpened}
      onClose={handleClose}
      maxWidth={[300, 400, 400]}
      id='modal-save-action'
    >
      <Modal.Content>
        <Text fontWeight='bold' textAlign='center'>
          {text}
        </Text>
      </Modal.Content>
      <Modal.Actions>
        <Flex alignItems='center' justifyContent='center' marginTop={3}>
          <Button
            id='acceptModifyData'
            sizeButton='small'
            backgroundColor={color1}
            action={() => {
              handleSave();
            }}
            marginRight={2}
            color={getContrastColor(color1)}
          >
            {t('general.accept')}
          </Button>
          <Button backgroundColor='blackLight' id='cancel-button' sizeButton='small'>
            {t('general.cancel')}
          </Button>
        </Flex>
      </Modal.Actions>
    </ModalGeneric>
  );
};

export default SaveActionModal;
