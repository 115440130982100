import React from 'react';
import { toast } from 'react-toastify';
import CustomToaster from '../../old_components/CustomToaster';

export const toastError = (message) => {
  toast.error(<CustomToaster message={message} />);

  toast.configure({
    closeOnClick: false,
    autoClose: 8000,
    draggable: false,
    className: 'toastMessage',
  });
};

export const toastDone = (message, time = 8000) => {
  toast.success(
    <CustomToaster message={message} autoClose={time} />,

    { closeOnClick: false, autoClose: time, draggable: false, className: 'toastMessage' }
  );
};
