export default {
  gdprPopup: {
    accept: 'Aceptar',
    cancel: 'Cancelar',
    conditionsAgreement: 'Aceptar los términos de uso',
    close: 'Cerrar',
    firstPolicyAgreementText:
      'CloudAssistant sigue evolucionando para brindarte mejores servicios y dar cabida a nuevas prestaciones como el nuevo rol Property Manager que te permite delegar la gestión de los mandos a aquellos Administrador de Fincas que quieran dar un servicio más próximo a sus comunidades.',
    firstPolicyAgreementSecondText:
      'Hemos modificado nuestras políticas para poder dar cabida a estos nuevos servicios.',
    firstPolicyAgreementThirdText: 'Para cualquier duda, puedes contactarnos en',
    privacyText: 'Política de privacidad',
    legalNoticeText: 'Aviso legal',
    conditionsText: 'Condiciones generales',
    cookiesText: 'Política de cookies',
    PolicyAgreementPopupTitle1: 'CloudAssistant se actualiza',
    PolicyAgreementPopupTitle2: 'nuevos servicios y políticas',
    PolicyAgreementPopupAnd: 'y',
  },
};
