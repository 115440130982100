export default {
  gsmPopUp: {
    title: 'Service Status',
    deviceNotSimInfo: 'Device is offline',
    simContractInfo: 'Contract info',
    expiration: 'Expiration',
    status: 'Status',
    infoNotAvailable: ' Information not available',
    simConnectionInfo: 'SIM Connection info',
    cardIdentifier: 'Card Identifier (ICCID)',
    activationDate: 'Activation date',
    lastMonthDataUpload: 'Last month data upload',
    lastMonthDataDownload: 'Last month data download',
    lastSeen: 'Last seen',
    contractStatus: 'Contract status',
    simStatus: 'SIM Status',
    deviceStatus: 'Device Status',
    enabled: ' Enabled',
    disabled: 'Disabled',
    notAvailable: 'Not available',
    connected: 'Connected',
    disconnected: 'Disconnected',
  },
};
