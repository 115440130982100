export default {
  descriptionParams: {
    ACCESSCONTROL:
      'Megmutatja, hogy a berendezés rendelkezik-e bemenettel a beléptető eszközök számára.',
    ACTIVEEVENTSTOSD: 'Megadja a készülék aktív eseményeit',
    APNCONFIG: 'A készülék APN-jének konfigurálása',
    BADGE_MAITRE1: 'Főkulcsként használt proximity elem kódja',
    BADGE_MAITRE10: 'Főkulcsként használt proximity elem kódja',
    BADGE_MAITRE2: 'Főkulcsként használt proximity elem kódja',
    BADGE_MAITRE3: 'Főkulcsként használt proximity elem kódja',
    BADGE_MAITRE4: 'Főkulcsként használt proximity elem kódja',
    BADGE_MAITRE5: 'Főkulcsként használt proximity elem kódja',
    BADGE_MAITRE6: 'Főkulcsként használt proximity elem kódja',
    BADGE_MAITRE7: 'Főkulcsként használt proximity elem kódja',
    BADGE_MAITRE8: 'Főkulcsként használt proximity elem kódja',
    BADGE_MAITRE9: 'Főkulcsként használt proximity elem kódja',
    CONFIGRELAYSNEW1:
      'Megjelöli azokat a reléket, amelyek a készülék által küldött csatornának megfelelően aktiválódnak.',
    CONFIGRELAYSNEW2:
      'Megjelöli azokat a reléket, amelyek a készülék által küldött csatornának megfelelően aktiválódnak.',
    CONFIGRELAYSNEW3:
      'Megjelöli azokat a reléket, amelyek a készülék által küldött csatornának megfelelően aktiválódnak.',
    CONFIGRELAYSNEW4:
      'Megjelöli azokat a reléket, amelyek a készülék által küldött csatornának megfelelően aktiválódnak.',
    CONFIGRELAYSPIN1:
      'Megjelöli azokat a reléket, amelyek a készülék által küldött csatornának megfelelően aktiválódnak.',
    CONFIGRELAYSPIN2:
      'Megjelöli azokat a reléket, amelyek a készülék által küldött csatornának megfelelően aktiválódnak.',
    CONFIGRELAYSPIN3:
      'Megjelöli azokat a reléket, amelyek a készülék által küldött csatornának megfelelően aktiválódnak.',
    CONFIGRELAYSPIN4:
      'Megjelöli azokat a reléket, amelyek a készülék által küldött csatornának megfelelően aktiválódnak.',
    DISABLEMANUALPROG: 'Lehetővé teszi az eszközök manuális regisztrálását',
    DISABLERADIOPROG:
      'Megakadályozza a rádióprogramozási üzemmódban (standard üzemmód) működő távirányítók regisztrálását.',
    FREEACTIVE: 'A INGYENES rendszercsoport jellemzőinek aktiválása / deaktiválása',
    FREEGROUP0LOCKED: 'Az ingyenes rendszer 0-ás csoportjának zárolása/feloldása',
    FREEGROUPNUM: 'Az ingyenes rendszer csoportszámának beállítása',
    FREEGROUPRFPROGALLOWED:
      'Rádióprogramozási mód engedélyezése / letiltása az ingyenes rendszercsoport számára',
    IMPBIRELE1: 'Az 1. relé működését impulzusról ON/OFF-ra változtatja.',
    INSTAL: 'Megmutatja, ha telepítő kóddal dolgozik',
    MINIDDEVICE:
      'A berendezés elfogadja az olyan eszközöket, mint a jelzettek vagy annál magasabbak.',
    PASSWORD: '4 számjegyű jelszó a központi menü eléréséhez',
    PIN: '6 számjegyű numerikus kód, amely megfelel a vevő PIN-kódjának',
    PINCADUCAT: 'Megmutatja, hogy a vevő elfogadja-e a lejárt PIN-kóddal rendelkező eszközöket.',
    POWER: 'Megmutatja a berendezés tápegységének típusát',
    RELAYPRICE1: 'Az előre fizetett készülék hiteléből levonandó érték 1',
    RELAYPRICE2: 'Az előre fizetett készülék hiteléből levonandó érték 2',
    RELAYPRICE3: 'Az előre fizetett készülék hiteléből levonandó érték 3',
    RELAYPRICE4: 'Az előre fizetett készülék hiteléből levonandó érték 4',
    RELAYS: 'A vevőkészülékben lévő relék száma',
    RELE1IMP: 'A relét az impulzus ON/OFF vagy pánik releként konfigurálja.',
    RELE1TEMP:
      'Beállítja, hogy a relé mennyi ideig maradjon aktív impulzus üzemmódban (1 és 30 mp között) vagy pánik üzemmódban (1 és 15 perc között).',
    RELE2IMP: 'A relét impulzusként ON/OFF vagy pánikként állítja be.',
    RELE2TEMP:
      'Beállítja, hogy a relé mennyi ideig maradjon aktív impulzus üzemmódban (1 és 30 mp között) vagy pánik üzemmódban (1 és 15 perc között).',
    RELE3IMP: 'A relét impulzusként ON/OFF vagy pánikként állítja be.',
    RELE3TEMP:
      'Beállítja, hogy a relé mennyi ideig maradjon aktív impulzus üzemmódban (1 és 30 mp között) vagy pánik üzemmódban (1 és 15 perc között).',
    RELE4IMP: 'A relét impulzusként ON/OFF vagy pánikként állítja be.',
    RELE4TEMP:
      'Beállítja, hogy a relé mennyi ideig maradjon aktív impulzus üzemmódban (1 és 30 mp között) vagy pánik üzemmódban (1 és 15 perc között).',
    SERIALNUMBER: 'A sorozatszám kötelező a távoli műveletekhez',
    SITECODE:
      'Helyszínkód / létesítménykód / telepítési kód, amelyet a Wiegand 26 protokollban kell elküldeni a Helyszínkóddal együtt.',
    TEVENT: 'Megmutatja, hogy a vezérlőpanel rendelkezhet-e eseménykártyával.',
    TIMEZONEID: 'Beállítja azt az időzónát, ahol a berendezés telepítve van.',
    TMEM: 'Megmutatja, hogy a berendezés memóriakártyával működik-e.',
    UNIQUEKEY: 'Egyetlen gomb a távoli műveletekhez',
    W26FORMAT: 'A Wiegand 26 protokoll átviteli formátumának beállítása',
    SETNEWACCESSPW: 'Jelszó módosítása',
    SERIALNUMBERLINK: 'A sorozatszám kötelező a távoli műveletekhez',
    CONNECTIVITY_EXTENSION: 'Link kártya telepítve',
    ACTIVATIONKEY: 'Aktiválási kulcs',
    EMMITTERNOTUSEDEVENTTIME: 'Ne használja az távirányító eseményidőpontját',
    DOOROPENEDEVENTTIME: 'Az ajtónyitás eseménye',
    SCHEDULESDATA: 'Ütemtervek adatai',
    A5KPROXY: 'Access 5k Proxy',
    WIEGANDCONFIG: 'Testreszabja a használandó Wiegand konfigurációt',
  },
};
