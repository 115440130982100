export default {
  compareMemory: {
    nodata: 'No hay datos disponibles en esta tabla',
    filter: 'Filtro',
    foundInBoth: 'Encontrados en ambos sitios',
    foundInBBDD: 'Encontrados en la base de datos',
    foundInMemory: 'Encontrados en la memoria',
    refreshMemory: 'Actualizar memoria',
    deleteBBDD: 'Eliminar de la base de datos',
    addBBDD: 'Añadir a la base de datos',
    confirmDelete: 'Desea eliminar los emisores?',
    reserved: 'Reservado',
  },
};
