import { useTranslation } from 'react-i18next';
import { Modal } from '@jcm-technologies/uikit/dist/molecules/Modal';
import { Flex } from '@jcm-technologies/uikit/dist/atoms/Layout';
import { Text } from '@jcm-technologies/uikit/dist/atoms/Typography';
import { Icon } from '@jcm-technologies/uikit/dist/atoms/Icon';
import IconExclamationTriangle from '@jcm-technologies/uikit/dist/atoms/Icons/IconExclamationTriangle';

const PopupInstallerCode = ({ title, isOpen, onClose }) => {
  const { t } = useTranslation();

  return (
    <Modal 
      maxWidth='800px'
      width='100%'
      isOpen={isOpen}
      title={title}
      id='modal-facilitily-installercode'
      onModalClose={() => onClose()}
      closeWithButton
    >
      <Modal.Content width='100%'>
          <Flex flexDirection='column' alignItems='center' justifyContent='center'>
            <Icon sizeIcon='display48'>
              <IconExclamationTriangle />
            </Icon>      
            <Text fontWeight='bold' sizeText='display16'>{t('updateInstalationForm.installerCodeFirstMessage')}</Text>
            <Text fontWeight='bold' sizeText='display16'>{t('updateInstalationForm.installerCodeSecondMessage')}</Text>
          </Flex>
      </Modal.Content>
    </Modal>
  );
};

export default PopupInstallerCode;