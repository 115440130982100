import PathEntity from '../../core/old_common/rooting/apiPaths/PathEntity';

class Routes extends PathEntity {
  constructor() {
    super('holidays');
  }

  getHolidaysFull = () => this.manufacturePath(`holidays/full`);

  getHolidays = () => this.manufacturePath(`holidays`);

  setHolidays = (holidayEntityId) => this.manufacturePath(`holidays/${holidayEntityId}/entries`);

  delHolidays = (holidayEntityId) => this.manufacturePath(`holidays/${holidayEntityId}/entries`);

  getPaths = () => ({
    getHolidaysFull: this.getHolidaysFull,
    getHolidays: this.getHolidays,
    setHolidays: this.setHolidays,
    delHolidays: this.delHolidays,
  });
}

export default new Routes().getPaths();
