import React, { Component } from 'react';
import { connect } from 'react-redux';
import GenericGrid from '../GenericGrid';
import { IconSpan } from '../IconSpan/IconSpan';

import './ImportExportGrid.css';
import { withTranslation } from 'react-i18next';

class ImportExportGrid extends Component {
  render() {
    const { t, tenants, value, getColumns } = this.props;
    return (
      <div className='col-md-12 col-lg-12'>
        <GenericGrid
          getColumns={getColumns || (() => [])}
          getCellActions={this.getCellActions}
          onGridRowsUpdated={this.onGridRowsUpdated}
          enableCellSelect
          minHeight={400}
          list={value}
        />
        <div className='grid-buttons'>
          <div className='grid-import-button'>
            <IconSpan
              toolTip={t('instalationGroups.importExcel')}
              defaultColor='#a4aebb'
              iconClassName='fas fa-file-upload'
              tenants={tenants}
              cursor='pointer'
            />
          </div>
          <div className='grid-export-button'>
            <IconSpan
              toolTip={t('instalationGroups.exportExcel')}
              defaultColor='#a4aebb'
              iconClassName='fas fa-file-download'
              tenants={tenants}
              cursor='pointer'
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  tenants: state.tenants,
});

const ImportExcelGridWithTranslation = withTranslation()(ImportExportGrid);

export default connect(mapStateToProps, {})(ImportExcelGridWithTranslation);
