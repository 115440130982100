export default {
  fobsPopUps: {
    instalations: 'Strutture',
    groups: 'Gruppi',
    move: 'Mossa',
    copy: 'Copia',
    remove: 'Elimina',
    removeQuestion: 'Vuoi eliminare i seguenti elementi?',
    selectedItemsShow1: 'Vuoi eliminare gli ',
    selectedItemsShow2: ' elementi selezionati?',
    warningDeleteFobs:
      'ATTENZIONE: potrebbero essere stati selezionati altri elementi oltre a quelli che visualizzi sullo schermo.',
    selectedItemsMove1: 'Vuoi spostare gli ',
    selectedItemsCopy1: 'Vuoi copiare gli ',
    add: 'Inserisci',
    selectedItemsAdd1: 'Vuoi inserisci gli ',
  },
};
