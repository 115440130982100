export default {
  paramsName: {
    A5KPROXY: 'Configure A5K proxy',
    ACCESSCONTROL: 'Access control',
    ACTIVATIONKEY: 'Activation key',
    ACTIVEELEMENTS: 'Active events',
    ACTIVEEVENTSTOSD: 'Setting A5K events',
    ALLOWNOTIFICATIONS: 'HONOA Show door status',
    ANTIPANICCONFIGRELE1: 'Setting Relay 1 (Impulse or ON/OFF or Panic)',
    ANTIPANICCONFIGRELE2: 'Setting Relay 2 (Impulse or ON/OFF or Panic)',
    ANTIPANICCONFIGRELE3: 'Setting Relay 3 (Impulse or ON/OFF or Panic)',
    ANTIPANICCONFIGRELE4: 'Setting Relay 4 (Impulse or ON/OFF or Panic)',
    APBKMODE: 'Anti-Passback Mode (APB)',
    APBKTIMEBACK: 'Reset time Anti-Passback in minutes (Anti-timeback)',
    APNCONFIG: 'APN setting',
    BADGE_MAITRE01: 'Master code 1',
    BADGE_MAITRE10: 'Master code 10',
    BADGE_MAITRE2: 'Master code 2',
    BADGE_MAITRE3: 'Master code 3',
    BADGE_MAITRE4: 'Master code 4',
    BADGE_MAITRE5: 'Master code 5',
    BADGE_MAITRE6: 'Master code 6',
    BADGE_MAITRE7: 'Master code 7',
    BADGE_MAITRE8: 'Master code 8',
    BADGE_MAITRE9: 'Master code 9',
    CONFIGRELAYSNEW1: 'Active relays on channel 1',
    CONFIGRELAYSNEW2: 'Active relays on channel 2',
    CONFIGRELAYSNEW3: 'Active relays on channel 3',
    CONFIGRELAYSNEW4: 'Active relays on channel 4',
    CONFIGRELAYSPIN1: 'Relays on PIN programming. Channel: 1',
    CONFIGRELAYSPIN2: 'Relays on PIN programming. Channel: 2',
    CONFIGRELAYSPIN3: 'Relays on PIN programming. Channel: 3',
    CONFIGRELAYSPIN4: 'Relays on PIN programming. Channel: 4',
    CONFIGRELE1: 'Setting relay 1 (Impulse  or ON/OFF)',
    CONFIGRELE2: 'Setting relay 2 (Impulse or ON/OFF)',
    CONFIGRELE3: 'Relay 3 setting (Impulse or ON/OFF)',
    CONFIGRELE4: 'Relay 4 setting (Impulse or ON/OFF)',
    CONFIGRELE_ANTIPANIC: 'Panic',
    CONNECTIVITY_EXTENSION: 'Link card installed',
    Configuration_Params_BUTT_NC: 'Normally closed button',
    Configuration_Params_BUTT_NO: 'Normally open button',
    Configuration_Params_ConfigRele_Antipanic: 'Antipanic',
    Configuration_Params_ConfigRele_Biestable: 'Bistable',
    Configuration_Params_ConfigRele_Impulsional: 'Impulsive',
    Configuration_Params_D2_HSCLS: '[Door 2] Closing limit switch sensor',
    Configuration_Params_D2_HSOLS: '[Door 2] Opening limit switch sensor',
    Configuration_Params_D2_NCCLS: '[Door 2] Normally closed closing limit switch',
    Configuration_Params_D2_NCOLS: '[Door 2] Normally closed opening limit switch',
    Configuration_Params_D2_NOCLS: '[Door 2] Normally open closing limit switch',
    Configuration_Params_D2_NOOLS: '[Door 2] Normally open opening limit switch',
    Configuration_Params_DEBUG: 'Debug',
    Configuration_Params_GO: 'GO',
    Configuration_Params_GOPRO: 'GO-PRO',
    Configuration_Params_HANDSFREEALLOWED: 'HONOA Allow BlueTooth hands-free (BETA)',
    Configuration_Params_HFADVANCE: 'HF-ADVANCE',
    Configuration_Params_HIGH: 'High',
    Configuration_Params_HSCLS: '[Door 1] Closing limit switch sensor',
    Configuration_Params_HSOLS: '[Door 1] Opening limit switch sensor',
    Configuration_Params_LOW: 'Low',
    Configuration_Params_MIN: 'Minimum',
    Configuration_Params_MODE1: 'Mode 1 (monochannel entrance/exit, free exit)',
    Configuration_Params_MODE2: 'Mode 2 (monochannel entrance / exit)',
    Configuration_Params_MODE3: 'Mode 3 (channel 1 entrance/channel 2 exit)',
    Configuration_Params_MODE4: 'Mode 4 (channel 1 and 3 entrance / channel 2 and 4 exit)',
    Configuration_Params_NCCLS: '[Door 1] Normally closed closing limit switch',
    Configuration_Params_NCOLS: '[Door 1] Normally closed opening limit switch',
    Configuration_Params_NCSC: 'Normally closed safety chain',
    Configuration_Params_NOCLS: '[Door 1] Normally open closing limit switch',
    Configuration_Params_NOOLS: '[Door 1] Normally open opening limit switch',
    Configuration_Params_NOSC: 'Normally open safety chain',
    Configuration_Params_NSCLS: '[Door 1] Opening limit switch sensor',
    Configuration_Params_NSOLS: '[Door 1] Closing limit switch sensor',
    Configuration_Params_No: 'No',
    Configuration_Params_NoFixed: 'Not fixed',
    Configuration_Params_OPENREMOTE: 'HONOA Allow remote opening',
    Configuration_Params_PIN: 'PIN',
    Configuration_Params_PINCADUCAT: 'PIN timed out',
    Configuration_Params_UNUSED: 'Unused',
    Configuration_Params_ValueTEVENTS: 'Yes',
    Configuration_Params_W26FORMAT0: 'Code (16 bits) + Site code (8 bits)',
    Configuration_Params_W26FORMAT1: 'Code (24 bits)',
    Configuration_Params_Yes: 'Yes',
    Configuration_Params_entranceNC: 'Entrance APB normally closed',
    Configuration_Params_entranceNO: 'Entrance APB normally open',
    Configuration_Params_exitNC: 'Exit APB normally closed',
    Configuration_Params_exitNO: 'Exit APB normally open',
    DATAUSAGE: 'Data usage',
    DISABLEMANUALPROG: 'Manual programing invalided',
    DISABLERADIOPROG: 'Radio programming invalided',
    DOOROPENEDEVENTTIME: 'Opening door event',
    EMMITTERNOTUSEDEVENTTIME: 'Do not use the event time of transmitter',
    FREEACTIVE: 'Active group on FREE system',
    FREEGROUP0LOCKED: 'Group 0 on FREE system blocked',
    FREEGROUPNUM: 'Group number on FREE system',
    FREEGROUPRFPROGALLOWED: 'Radio programming mode allowed on FREE system',
    GO: 'Go',
    GOPRO: 'Go-Pro',
    HANDSFREEALLOWED: 'HONOA Allow BlueTooth hands-free (BETA)',
    HFADVANCE: 'HF-ADVANCE',
    IMPBIRELE1: 'Configures relay 1 as bistable',
    INPUT10KEY: 'Radio sensor 10 key',
    INPUT10MODE: 'Radio sensor 10 mode',
    INPUT10NAME: 'Radio sensor 10 name',
    INPUT1KEY: 'Input 1 key',
    INPUT1MODE: 'Input sensor 1 mode',
    INPUT1NAME: 'Input 1 name',
    INPUT2KEY: 'Input 2 key',
    INPUT2MODE: 'Input sensor 2 mode',
    INPUT2NAME: 'Input 2 name',
    INPUT3KEY: 'Radio sensor 3 key',
    INPUT3MODE: 'Radio sensor 3 mode',
    INPUT3NAME: 'Radio sensor 3 name',
    INPUT4KEY: 'Radio sensor 4 key',
    INPUT4MODE: 'Radio sensor 4 mode',
    INPUT4NAME: 'Radio sensor 4 name',
    INPUT5KEY: 'Radio sensor 5 key',
    INPUT5MODE: 'Radio sensor 5 mode',
    INPUT5NAME: 'Radio sensor 5 name',
    INPUT6KEY: 'Radio sensor 6 key',
    INPUT6MODE: 'Radio sensor 6 mode',
    INPUT6NAME: 'Radio sensor 6 name',
    INPUT7KEY: 'Radio sensor 7 key',
    INPUT7MODE: 'Radio sensor 7 mode',
    INPUT7NAME: 'Radio sensor 7 name',
    INPUT8KEY: 'Radio sensor 8 key',
    INPUT8MODE: 'Radio sensor 8 mode',
    INPUT8NAME: 'Radio sensor 8 name',
    INPUT9KEY: 'Radio sensor 9 key',
    INPUT9MODE: 'Radio sensor 9 mode',
    INPUT9NAME: 'Radio sensor 9 name',
    INPUTS: 'Inputs',
    INSTAL: 'Installer',
    MINIDDEVICE: 'Minimum device',
    MessageBox_ActivationRelay: 'Relay activation',
    MessageBox_ConnectionError: 'Connection error',
    MessageBox_DeviceAccepted: 'Accepted device',
    MessageBox_DeviceRecieved: 'Received device',
    MessageBox_FormatMemoryError: 'Wrong format on memory',
    MessageBox_InvalidDevice: 'Device not valid',
    MessageBox_MemoryExtracted: 'Memory extracted',
    MessageBox_MemoryInserted: 'Memory inserted',
    MessageBox_Message: 'Configure active events',
    MessageBox_NotInMemory: 'Not found in memory',
    MessageBox_OutOfSchedule: 'Out of schedule',
    MessageBox_PowerUp: 'ON',
    MessageBox_RelayState: 'Change of relay status',
    Name: 'Name',
    OPENREMOTE: 'HONOA Allow remote opening',
    PASSWORD: 'Password',
    PIN: 'PIN',
    PINCADUCAT: 'PIN expired',
    POWER: 'Power supply',
    PRICE1: 'Substraction value for relay 1',
    PRICE2: 'Substraction value for relay 2',
    PRICE3: 'Subtraction value for relay 3',
    PRICE4: 'Subtraction value for relay 4',
    Product: 'Device',
    RELAYPRICE1: 'Subtraction value for relay 1',
    RELAYPRICE2: 'Subtraction value for relay 2',
    RELAYPRICE3: 'Subtraction value for relay 3',
    RELAYPRICE4: 'Subtraction value for relay 4',
    RELAYS: 'Relays',
    RELE1IMP: 'Relay 1 setting (Impulse or ON/OFF)',
    RELE1TEMP: 'Time setting relay 1',
    RELE2IMP: 'Relay 2 setting (Impulse or ON/OFF)',
    RELE2TEMP: 'Time setting relay 2',
    RELE3IMP: 'Relay 3 setting (Impulse or ON/OFF)',
    RELE3TEMP: 'Time setting relay 3',
    RELE4IMP: 'Relay 4 setting (Impulse or ON/OFF)',
    RELE4TEMP: 'Time setting relay 4',
    Relay_Sound: 'Door open message language',
    SCHEDULESDATA: 'Schedule data',
    SENSORS: 'Sensors',
    SITECODE: 'Site code',
    SerialNumber: 'Serial number',
    Sip_Address: 'SIP address',
    Sip_Status: 'SIP status',
    TEMPRELE1: 'Time setting relay 1',
    TEMPRELE2: 'Time setting relay 2',
    TEMPRELE3: 'Time setting relay 3',
    TEMPRELE4: 'Time setting relay 4',
    TEVENT: 'Events card',
    TIMEZONE: 'Time zone identifier',
    TIMEZONEID: 'Timezone',
    TMEM: 'Memory card',
    UNIQUEKEY: 'Unique key',
    UniqueIdentity: 'Unique identifier',
    W26FORMAT: 'Wiegand 26 format',
    WIEGANDCONFIG: 'Wiegand configuration',
    access_code: 'Access code',
    call_max_duration: 'Maximum call duration',
    call_outgoing_timeout: 'Outgoing call timeout',
    cell_id: 'CELL ID',
    connection_type: 'Connection type',
    correctVertexKey: 'The entered code is valid',
    dbm: 'Decibels',
    door_english: 'English',
    door_french: 'French',
    door_spanish: 'Spanish',
    false: 'False',
    fw_version: 'Firmware version',
    hw_version: 'Versión de hardware',
    iccid: 'ICCID number',
    imei: 'IMEI number',
    imsi: 'IMSI',
    incorrectVertexKey: 'The entered code is not valid',
    lac: 'LAC',
    level: 'Signal level',
    mac_address: 'MAC address',
    mcc: 'MCC',
    mic_volume: 'Microphone volume',
    mnc: 'MNC',
    none: 'None',
    relay_dtmf_hang_call: 'Relay activation by tone',
    speaker_volume: 'Speaker volume',
    true: 'True',
    LOCALEVENTMANAGEMENTCONFIG: 'Event settings',
  },
};
