import {
  DEL_SCHEDULE,
  GET_DUPLICATE_SCHEDULE,
  GET_SCHEDULE,
  GET_SCHEDULES,
  GET_SCHEDULES_ASSIGNED_FACILITIES,
  GET_SCHEDULES_SINGLE_LIST,
  POST_SCHEDULES,
  PUT_SCHEDULE,
  RESET_DUPLICATE_SCHEDULE,
  RESET_SCHEDULE,
} from './contants';

/**
 * Sets the form state
 * @param  {int} value                The value for show loading
 * @return {object}                   Formatted action for the reducer to handle
 */
export const getSchedules = () => ({ type: GET_SCHEDULES.BASE });

export const getSchedule = (scheduleId) => ({
  type: GET_SCHEDULE.BASE,
  scheduleId,
});

export const getDuplicateScheduleData = (scheduleId) => ({
  type: GET_DUPLICATE_SCHEDULE.BASE,
  scheduleId,
});
export const resetDuplicateSchedule = () => ({ type: RESET_DUPLICATE_SCHEDULE });
export const resetSchedule = () => ({ type: RESET_SCHEDULE });

export const putSchedules = (schedule) => ({ type: PUT_SCHEDULE.BASE, schedule });

export const deleteSchedule = (id, costumerID) => ({ type: DEL_SCHEDULE.BASE, id, costumerID });

export const postSchedules = (schedule) => ({ type: POST_SCHEDULES.BASE, schedule });

export const getSchedulesSingleList = (facilityId) => ({
  type: GET_SCHEDULES_SINGLE_LIST.BASE,
  facilityId,
});

export const getSchedulesAssignedFacilities = (id) => ({
  type: GET_SCHEDULES_ASSIGNED_FACILITIES.BASE,
  id,
});
