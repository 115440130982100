export default {
  changePassword: {
    title: 'Changer le mot de passe',
    submitText: 'Changer le mot de passe',
    currentPassword: 'Mot de passe actuel',
    newPassword: 'Nouveau mot de passe',
    confirmPassword: 'Confirmez le mot de passe',
    cancel: 'Annuler',
    chooseFile: 'Choisir le fichier',
    newPasswordPopupTitle: 'Definir un nouveau mot de passe',
  },
};
