export default {
  changePassword: {
    title: 'Canviar contrasenya',
    submitText: 'Canviar contrasenya',
    currentPassword: 'Contrasenya actual',
    newPassword: 'Nova contrasenya',
    confirmPassword: 'Confirmar contrasenya',
    cancel: 'Cancel·lar',
    chooseFile: 'Examinar...',
    newPasswordPopupTitle: 'Establir nova contrasenya',
  },
};