import PathEntity from '../../core/old_common/rooting/apiPaths/PathEntity';
import { apiVersion, mainPath } from '../../core/old_common/rooting/common';

class Routes extends PathEntity {
  constructor() {
    super('customers');
  }

  putInstallerCode = (useInstallerCode) => {
    let endpoint = `${this.entityName}/installercode`;
    if (useInstallerCode !== undefined) {
      endpoint = this.addParameterToEndpoint(endpoint, 'UseInstallerCode', useInstallerCode);
    }

    return this.manufacturePath(endpoint);
  };

  getInstallerCode = () => this.manufacturePath(`${this.entityName}/installercode`);

  getVisibleDescendants = () => this.manufacturePath(`${this.entityName}/VisibleDescendants`);

  putAttributes = (customerId) =>
    this.manufacturePath(`${this.entityName}/${customerId}/attributes`);

  putAttributesHonoa = (customerId) =>
    this.manufacturePath(`${this.entityName}/${customerId}/attributes/honoa`);

  getAttributes = (customerId) =>
    this.manufacturePath(`${this.entityName}/${customerId}/attributes`);

  getAttributesHonoa = (customerId) =>
    this.manufacturePath(`${this.entityName}/${customerId}/attributes/honoa`);

  postAttributes = (customerId) =>
    this.manufacturePath(`${this.entityName}/${customerId}/attributes`);

  customerPutLicenses = (customerId) => {
    this.mainPath = `${mainPath()}4.1`;
    const endpoint = this.manufacturePath(`${this.entityName}/${customerId}`);
    this.mainPath = `${mainPath()}${apiVersion}`;
    return endpoint;
  };

  getPropertyManagers = () => this.manufacturePath(`${this.entityName}/propertymanagers`);

  getLicences = () => {
    const endpoint = this.manufacturePath(`${this.entityName}/licenses/info`);
    return endpoint;
  };

  getCustomerRoles = (roleMask) => {
    this.mainPath = `${mainPath()}4.1`;
    let endpoint = this.manufacturePath(`${this.entityName}/customerRoles`);
    this.mainPath = `${mainPath()}${apiVersion}`;
    if (roleMask) endpoint = this.addParameterToEndpoint(endpoint, 'srcCustMask', roleMask);
    return endpoint;
  };

  patchCustomerRole = () => {
    this.mainPath = `${mainPath()}4.1`;
    const endpoint = this.manufacturePath(`${this.entityName}/customerRoles`);
    this.mainPath = `${mainPath()}${apiVersion}`;
    return endpoint;
  };

  getPaths = () => ({
    customerGetVisibleDescendants: this.getVisibleDescendants,
    customerGetAttributes: this.getAttributes,
    customerGetAttributesHonoa: this.getAttributesHonoa,
    customerPutInstallerCode: this.putInstallerCode,
    customerGetInstallerCode: this.getInstallerCode,
    customerPutAttributes: this.putAttributes,
    customerPutAttributesHonoa: this.putAttributesHonoa,
    customerPostAttributes: this.postAttributes,
    customerPutLicenses: this.customerPutLicenses,
    customerGetPropertyManagers: this.getPropertyManagers,
    customerGetLicenses: this.getLicences,
    customerGetRoles: this.getCustomerRoles,
    customerPatchRole: this.patchCustomerRole,
  });
}

export default new Routes().getPaths();
