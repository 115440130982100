import React from 'react';
import { connect } from 'react-redux';
import { Button } from '@jcm-technologies/uikit/dist/atoms/Button';
import { Flex } from '@jcm-technologies/uikit/dist/atoms/Layout';
import { withTranslation } from 'react-i18next';
import Popup from '../Popup/Popup';
import { ContentContainer } from '../ContentContainer/ContentContainer';
import { getContrastColor } from '../../core/helpers/colorManager';

const DeletePopup = ({
  handleAccept,
  handleCancel,
  isOpen,
  children,
  title,
  tenants,
  isSecondDeletePopup,
  t,
}) => (
  <Popup onCloseIconClick={handleCancel} isOpen={isOpen} onClose={handleCancel}>
    <ContentContainer isSecondDeletePopup={isSecondDeletePopup} tenants={tenants} title={title}>
      <Flex alignItems='center' justifyContent='space-between' flexDirection='column'>
        {children}
        <Flex alignItems='center' justifyContent='center'>
          <Button
            id='acceptDelete'
            sizeButton='small'
            backgroundColor={tenants?.color1}
            action={handleAccept}
            marginRight={2}
            color={getContrastColor(tenants?.color1)}
          >
            {t('popupConfirm.accept')}
          </Button>
          <Button
            backgroundColor='blackLight'
            id='cancel-button'
            sizeButton='small'
            action={handleCancel}
          >
            {t('popupConfirm.cancel')}
          </Button>
        </Flex>
      </Flex>
    </ContentContainer>
  </Popup>
);

const mapStateToProps = (state) => ({
  tenants: state.tenants,
});
const DeletePopupTranslation = withTranslation()(DeletePopup);
export default connect(mapStateToProps, {})(DeletePopupTranslation);
