export default {
  updateInstalationForm: {
    contact: 'Kontakt',
    telephone: 'Telefon',
    address: 'Adresa',
    country: 'Země',
    saveButton: 'Uložit',
    maintenanceDate: 'Datum příští servisní prohlídky',
    createFacility: 'Vytvořit instalaci',
    installerCode: 'Použít instalační kód',
    installerCodeIconTooltip: 'Instalační kód',
    comment: 'Popisky',
    createFacilityGroup: 'Vytvořit instalační skupinu',
    uniqueIdentity: 'Unikátní identifikátor',
    geolocalization: 'Nastavení geolokace',
    exportUsersGroups: 'Exportovat uživatele',
    noneGeolocalization: 'Geolokace nenastavena',
    addUniversalGroup: 'Přidat Univerzální skupinu',
    needSetLocation: 'Je třeba přidat konkrétní místo',
    creationDate: 'Datum vzniku',
    installerCodeFirstMessage: 'Ujistěte se, že pracujete s instalačním kódem.',
    installerCodeSecondMessage: 'V případě dotazů se spojte se správcem'
  },
};
