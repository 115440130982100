export default {
  remoteComunications: {
    index: 'Índice',
    content: 'Contenido',
    serialNumber: 'Número de serie',
    uniqueKey: 'Clave única',
    operationsCode: 'Código Oper.',
    finalCode: 'Código fin',
    progress: 'Progreso',
    status: 'Estado',
    refresh: 'Refrescar',
    saveConfig: 'Guardar configuración',
    readConfig: 'Leer configuración',
    readEvents: 'Leer eventos',
    removeEvents: 'Eliminar eventos',
    seeEvents: 'Ver eventos',
    export: 'Exportar',
    Completed: 'Completada',
    Received: 'Recibida',
    Processing: 'En proceso',
  },
};
