export default {
  equipments: {
    add: 'Aggiungi apparecchiatura',
    createEquipment: 'Crea apparecchiatura',
    deleteConfirmation: 'Sei sicuro di voler eliminare questa apparecchiatura?',
    facilityName: 'Nome struttura',
    productName: 'Nome del prodotto',
    parameters: 'Parametri',
    relays: 'Relè',
    fobs: 'Emettitori',
    groups: 'Gruppi',
    actions: 'Azioni',
    events: 'Eventi',
    info: 'Információk',
    'remote comm': 'Comunicazioni remote',
    changePasswordAction: 'Cambia la password',
    adjustDateTimeAction: 'Regolare data e ora',
    formatMemory: 'Formattare memoria',
    disconnectedKey: 'Disconected',
    connectedKey: 'Collegato',
    notFoundKey: 'Dispositivo non trovato',
    saveConfiguration: 'Salva configurazione',
    compareMemory: 'Confronta la memoria',
    savedMemory: 'Memoria salvata',
    sendMemoryAction: 'Aggiorna memoria localmente',
    saveMemoryAction: 'Aggiorna memoria remoto',
    verifyConnectivity: 'Verifica la connettività',
    getdoorstatus: 'Ottieni lo stato della porta',
    device: 'Dispositivo',
    deleteEventsConfirmation: 'Sei sicuro di voler eliminare gli eventi?',
  },
};
