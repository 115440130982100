import createFetchTypes from '../../../core/typeActions/types';
import loginActions from '../actions/login';
import { saveCookies } from '../../../core/old_common/utils/cookiesManager';
import { OWNERS_GDPR_POST } from '../../owner/constants';
import { GET_USER } from '../../user/constants';

export const RE_LOGIN_POST = createFetchTypes('RE_LOGIN_POST');
export const RESET_PASSWORD_POST = createFetchTypes('RESET_PASSWORD_POST');
export const CHANGE_PASSWORD_POST = createFetchTypes('CHANGE_PASSWORD_POST');
export const LOGIN_VERSION_ACCOUNT = createFetchTypes('LOGIN_VERSION_ACCOUNT');

/* Redux state init */
const initialState = {
  authorization: {
    access_token: '',
    expires_in: 0,
    token_type: '',
  },
  roles: undefined,
  language: '',
  avatar: '',
  assitantId: '',
  email: '',
  userId: '',
  agreementPending: false,
  isResetPasswordSended: false,
  hideVerInfo: '',
  pageSizeItems: 15,
  globalTimeoutId: '',
  eventTimeoutId: '',
  canRefresh: false,
  showGDPRPopup: false,
  manualShowVersionPopup: false,
};

export default function tenantsReducer(state = initialState, action) {
  if (action.type !== undefined) {
    switch (action.type) {
      case RE_LOGIN_POST.SUCCESS:
        saveCookies({
          userRoles: action.result.roles,
          token: action.result.authorization.access_token,
          userId: action.result.userId,
          customerId: action.result.customerId,
          agreementPending: action.result.agreementPending,
          hideVerInfo: action.result.hideVerInfo,
          pageSizeItems: action.result.pageSizeItems,
          manualShowVersionPopup: false,
          dateTimeFormat: action.result.dateTimeFormat,
          email: action.result.email,
        });
        return { ...state, ...action.result };
      case GET_USER.SUCCESS:
        saveCookies({
          language: action.result.language,
        });
        return { ...state, avatar: action.result.avatar };
      case RESET_PASSWORD_POST.SUCCESS: {
        return { ...state, isResetPasswordSended: true };
      }
      case OWNERS_GDPR_POST.SUCCESS:
        saveCookies({ agreementPending: false });
        return { ...state, agreementPending: false };
      case loginActions.RESET_TIMEOUTS:
        clearTimeout(state.globalTimeoutId);
        clearTimeout(state.eventTimeoutId);
        return { ...state, eventTimeoutId: '', globalTimeoutId: '' };
      case loginActions.SET_GDPR_IS_OPEN:
        return { ...state, showGDPRPopup: action.isOpen };
      case loginActions.SET_VERSION_POPUP_IS_OPEN:
        return { ...state, manualShowVersionPopup: action.isOpen };
      default:
        return state;
    }
  }
  return state;
}
