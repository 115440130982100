import { Flex } from '@jcm-technologies/uikit/dist/atoms/Layout';
import { Button } from '@jcm-technologies/uikit/dist/atoms/Button';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { getContrastColor } from '../../../core/helpers/colorManager';
import { getErrors } from '../../../core/old_common/validations/service';
import { getActualLanguage, getLanguagesOptions } from '../../../core/helpers/getLanguages';
import { getUserRoles } from '../../../modules/user/actions';
import { postInvitation } from '../../../modules/invitations/actions';
import { FormLicenses } from '../../../components/Administration/Licenses/Form';
import {
  rolesNoConsumeLicenses,
  validationRules,
} from '../../../components/Administration/Licenses/config';
import { resetSuccess } from '../../../modules/old_to_refact/actions/formsControl';

const initData = {
  name: '',
  role: '',
  email: '',
  emailConfirm: '',
  subscriptions: '1',
  visibleFacilities: false,
  language:
    getLanguagesOptions()?.find((element) => element.label === getActualLanguage())?.value || '',
};
const LicensesCreate = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { color1 } = useSelector((state) => state.tenants);
  const { userRoles, user } = useSelector((state) => state.user);
  const { isSuccess } = useSelector((state) => state.formsControl);
  const [data, setData] = useState(initData);

  const [errorsForm, setErrosForm] = useState({});
  const [hasChanged, setHasChanged] = useState(false);
  const [visibleSubscriptions, setVisibleSubscriptions] = useState(false);

  const isDisabledSubscriptions = data.role !== userRoles?.find((x) => x.mask === 2)?.id;
  const isDisableCompany =
    data.role !== userRoles?.find((x) => x.mask === 2)?.id &&
    data.role !== userRoles?.find((x) => x.mask === 128)?.id &&
    data.role !== userRoles?.find((x) => x.mask === 256)?.id &&
    data.role !== userRoles?.find((x) => x.mask === 512)?.id;
  const isVisibleFacilityLabel =
    user?.showVisibilityOption && data.role === userRoles?.find((x) => x.mask === 2)?.id
      ? t('form.corporation')
      : '';
  const isVisibleFacility =
    user?.showVisibilityOption && data.role === userRoles?.find((x) => x.mask === 2)?.id;
  const onChangeData = (name, value) => {
    const role = userRoles?.find((x) => x.id === value);
    if (name === 'role' && rolesNoConsumeLicenses.includes(role?.mask)) {
      setData((prev) => ({
        ...prev,
        subscriptions: 0,
      }));
      setVisibleSubscriptions(!rolesNoConsumeLicenses.includes(role?.mask));
    } else if (name === 'role') {
      setData((prev) => ({
        ...prev,
        subscriptions: 1,
      }));
      setVisibleSubscriptions(true);
    }
    setData((prev) => ({
      ...prev,
      [name]: value,
    }));
    setHasChanged(true);
  };

  const handleBlur = (name) => {
    const validations = validationRules(data, isDisabledSubscriptions, userRoles, isDisableCompany);
    setErrosForm((prev) => ({
      ...prev,
      [name]: getErrors(validations[name]) || false,
    }));
  };

  const handleSubmit = () => {
    dispatch(postInvitation(data));
  };

  useEffect(() => {
    dispatch(getUserRoles());
  }, []);

  useEffect(() => {
    if (data) {
      handleBlur('name');
      handleBlur('subscriptions');
    }
  }, [data]);

  useEffect(() => {
    if (isSuccess) {
      setData(initData);
      setHasChanged(false);
      setErrosForm({});
      dispatch(resetSuccess());
      history.push(`/administration/licenses`);
    }
  }, [isSuccess]);

  return (
    <Flex
      backgroundColor='white'
      borderRadius={3}
      height='100%'
      minHeight='calc(100vh - 156px)'
      paddingTop={2}
      flexDirection='column'
      justifyContent='space-between'
    >
      <FormLicenses
        onChangeData={onChangeData}
        isDisableCompany={isDisableCompany}
        isDisabledSubscriptions={isDisabledSubscriptions}
        handleBlur={handleBlur}
        errors={errorsForm}
        data={data}
        isVisibleFacilityLabel={isVisibleFacilityLabel}
        isVisibleFacility={isVisibleFacility}
        isVisibleSubscriptions={visibleSubscriptions}
      />
      <Flex justifyContent='flex-end' alignItems='flex-end' padding={2} paddingX={4}>
        <Button
          color={getContrastColor(color1)}
          background={color1}
          marginRight={1}
          disabled={!hasChanged}
          onClick={() => handleSubmit()}
        >
          {t('general.save')}
        </Button>
        <Button
          backgroundColor='blackLight'
          onClick={() => history.push(`/administration/licenses`)}
        >
          {t('general.back')}
        </Button>
      </Flex>
    </Flex>
  );
};

export default LicensesCreate;
