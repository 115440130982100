export default {
  updateInstalationForm: {
    contact: 'Contact',
    telephone: 'Phone',
    address: 'Address',
    country: 'Country',
    saveButton: 'Save',
    maintenanceDate: 'Next maintenance date',
    createFacility: 'Create facility',
    installerCode: 'Use installer code',
    installerCodeIconTooltip: 'Installer code',
    comment: 'Comments',
    createFacilityGroup: 'Create facility group',
    uniqueIdentity: 'Unique identifier',
    geolocalization: 'Geolocation set',
    exportUsersGroups: 'Export users',
    noneGeolocalization: 'Geolocalization not set',
    addUniversalGroup: 'Add universal group',
    needSetLocation: ' You need to add a specific location',
    exportUsersTitle: 'export_users',
    creationDate: 'Creation date',
    installerCodeFirstMessage: 'Make sure you are working with an installer code.',
    installerCodeSecondMessage: 'If you have any questions, please contact your administrator'
  },
};
