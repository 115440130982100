import { Text } from '@jcm-technologies/uikit/dist/atoms/Typography';
import { Flex } from '@jcm-technologies/uikit/dist/atoms/Layout';
import { Card } from '@jcm-technologies/uikit/dist/molecules/Card';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { HuePicker } from 'react-color';
import PropTypes from 'prop-types';
import { Button } from '@jcm-technologies/uikit/dist/atoms/Button';
import { getContrastColor } from '../../../../../core/helpers/colorManager';

const CustomizationColorBrand = ({ onChangeComplete, newColor, onColorSave }) => {
  const { t } = useTranslation();
  const tenants = useSelector((state) => state.tenants);
  const { color1 } = tenants;
  return (
    <Card rounded='solid' marginTop={3} marginBottom={1}>
      <Text color='blackLight' sizeText='display16' marginBottom={1}>
        {t('form.theme')}
      </Text>
      <Flex
        backgroundColor={newColor}
        height={56}
        width='100%'
        color={getContrastColor(newColor)}
        alignItems='center'
        justifyContent='center'
        borderRadius={2}
      >
        {newColor}
      </Flex>
      <Flex
        width='100%'
        alignItems='center'
        justifyContent='center'
        marginY={2}
        maxWidth={[80, 100, '100%']}
      >
        <HuePicker onChangeComplete={onChangeComplete} color={newColor} id='pickerColor' />
      </Flex>

      <Button
        fullWidth
        backgroundColor={color1}
        color={getContrastColor(color1)}
        action={onColorSave}
      >
        {t('general.save')}
      </Button>
    </Card>
  );
};

export default CustomizationColorBrand;

CustomizationColorBrand.propTypes = {
  onChangeComplete: PropTypes.func.isRequired,
  newColor: PropTypes.string.isRequired,
  onColorSave: PropTypes.func.isRequired,
};
