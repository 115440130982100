export default {
  universalGroups: {
    description: 'Beschreibung',
    informationTab: 'Stammdaten',
    expand: 'Erweitern',
    collapse: 'Überlastung ',
    delete: 'Löschen',
    add: 'Neue universelle Gruppe hinzufügen',
    create: 'Erstellen Sie eine universelle Gruppe',
    instalationsTab: 'Installationen',
    fobsTab: 'Sender',
    deleteConfirmation: 'Möchten Sie diese Gruppe wirklich löschen?',
  },
};
