export default {
  compareMemory: {
    nodata: 'Ebben a táblázatban nincsenek adatok',
    filter: 'Szűrő',
    foundInBoth: 'Mindkét oldalon megtalálható',
    foundInBBDD: 'Megtalálható az adatbázisban',
    foundInMemory: 'Megtalálható a memóriában',
    refreshMemory: 'Memória frissítése',
    deleteBBDD: 'Törlés az adatbázisból',
    addBBDD: 'Hozzáadás az adatbázishoz',
    confirmDelete: 'El akarja távolítani a klónozott távirányítókat?',
    reserved: 'Fenntartott',
  },
};
