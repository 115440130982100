import { call, fork, takeLatest } from 'redux-saga/effects';
import fetchEntitySaga from '../../old_to_refact/sagas/utils/fetchEntitySaga';
import api from '../../../core/helpers/api';
import apiPathFactory from '../../../core/old_common/rooting/apiPathFactory';
//TODO: change this when refactoring instalationGroups
import {
  getInstalationGroupSaga,
  getInstalationGroupsFobsCountSaga,
} from '../../../modules/old_to_refact/sagas/instalationGroups';
import {
  DELETE_GROUP_FOBS_COMPARE_MEMORY_LIST,
  DELETE_GROUP_FOBS_FOB,
  DELETE_GROUP_FOBS_LIST,
  POST_GROUP_FOBS_COMPARE_MEMORY_LIST,
  GET_GROUP_FOBS_CHECK_CODES,
  GET_GROUP_FOBS_PDF,
  POST_GROUP_FOBS_LIST_COPY,
  POST_GROUP_FOBS_FOB,
  POST_GROUP_FOBS_LIST,
  POST_GROUP_FOBS_SEQUENTIAL,
  PUT_GROUP_FOBS_GRID,
  PUT_GROUP_FOBS_IMPORT,
  PUT_GROUP_FOBS_LIST_MOVE,
} from './constants';
import { getInstalationsFOBS } from '../../old_to_refact/sagas/instalations';

export const deleteFob = {
  base: (facilityId, groupId, fobId) => ({
    type: DELETE_GROUP_FOBS_FOB.BASE,
    facilityId,
    groupId,
    fobId,
  }),
  request: () => ({ type: DELETE_GROUP_FOBS_FOB.REQUEST }),
  success: (result) => ({ type: DELETE_GROUP_FOBS_FOB.SUCCESS, result }),
  failure: (error) => ({ type: DELETE_GROUP_FOBS_FOB.FAILURE, error }),
};

export const deleteFobList = {
  base: (facilityId, groupId, fobList) => ({
    type: DELETE_GROUP_FOBS_LIST.BASE,
    facilityId,
    groupId,
    fobList,
  }),
  request: () => ({ type: DELETE_GROUP_FOBS_LIST.REQUEST }),
  success: (result, payload) => ({ type: DELETE_GROUP_FOBS_LIST.SUCCESS, result, payload }),
  failure: (error) => ({ type: DELETE_GROUP_FOBS_LIST.FAILURE, error }),
};
export const deleteCompareMemoryFobList = {
  base: (facilityId, groupId, fobList) => ({
    type: DELETE_GROUP_FOBS_COMPARE_MEMORY_LIST.BASE,
    facilityId,
    groupId,
    fobList,
  }),
  request: () => ({ type: DELETE_GROUP_FOBS_COMPARE_MEMORY_LIST.REQUEST }),
  success: (result) => ({ type: DELETE_GROUP_FOBS_COMPARE_MEMORY_LIST.SUCCESS, result }),
  failure: (error) => ({ type: DELETE_GROUP_FOBS_COMPARE_MEMORY_LIST.FAILURE, error }),
};

export const postCompareMemoryFobList = {
  base: (facilityId, groupId, fobList) => ({
    type: POST_GROUP_FOBS_COMPARE_MEMORY_LIST.BASE,
    facilityId,
    groupId,
    fobList,
  }),
  request: () => ({ type: POST_GROUP_FOBS_COMPARE_MEMORY_LIST.REQUEST }),
  success: (result) => ({ type: POST_GROUP_FOBS_COMPARE_MEMORY_LIST.SUCCESS, result }),
  failure: (error) => ({ type: POST_GROUP_FOBS_COMPARE_MEMORY_LIST.FAILURE, error }),
};

export const postFobList = {
  base: (facilityId, groupId, fobList) => ({
    type: POST_GROUP_FOBS_LIST.BASE,
    facilityId,
    groupId,
    fobList,
  }),
  request: () => ({ type: POST_GROUP_FOBS_LIST.REQUEST }),
  success: (result) => ({ type: POST_GROUP_FOBS_LIST.SUCCESS, result }),
  failure: (error) => ({ type: POST_GROUP_FOBS_LIST.FAILURE, error }),
};

export const putFobMoveList = {
  base: (fobList) => ({
    type: PUT_GROUP_FOBS_LIST_MOVE.BASE,
    fobList,
  }),
  request: () => ({ type: PUT_GROUP_FOBS_LIST_MOVE.REQUEST }),
  success: (result, payload) => ({ type: PUT_GROUP_FOBS_LIST_MOVE.SUCCESS, result, payload }),
  failure: (error) => ({ type: PUT_GROUP_FOBS_LIST_MOVE.FAILURE, error }),
};

export const postFobCopyList = {
  base: (fobList) => ({
    type: POST_GROUP_FOBS_LIST_COPY.BASE,
    fobList,
  }),
  request: () => ({ type: POST_GROUP_FOBS_LIST_COPY.REQUEST }),
  success: (result) => ({ type: POST_GROUP_FOBS_LIST_COPY.SUCCESS, result }),
  failure: (error) => ({ type: POST_GROUP_FOBS_LIST_COPY.FAILURE, error }),
};

export const putFobsImport = {
  base: (facilityId, id) => ({
    type: PUT_GROUP_FOBS_IMPORT,
    facilityId,
    id,
  }),
  request: () => ({ type: PUT_GROUP_FOBS_IMPORT.REQUEST }),
  success: (result) => ({ type: PUT_GROUP_FOBS_IMPORT.SUCCESS, result }),
  failure: (error) => ({ type: PUT_GROUP_FOBS_IMPORT.FAILURE, error }),
};

export const postFob = {
  base: (fob, isReservation) => ({
    type: POST_GROUP_FOBS_FOB.BASE,
    fob,
    isReservation,
  }),
  request: () => ({ type: POST_GROUP_FOBS_FOB.REQUEST }),
  success: (result) => ({ type: POST_GROUP_FOBS_FOB.SUCCESS, result }),
  failure: (error) => ({ type: POST_GROUP_FOBS_FOB.FAILURE, error }),
};

export const postSequentialFOB = {
  base: (fob) => ({
    type: POST_GROUP_FOBS_SEQUENTIAL.BASE,
    fob,
  }),
  request: () => ({ type: POST_GROUP_FOBS_SEQUENTIAL.REQUEST }),
  success: (result) => ({ type: POST_GROUP_FOBS_SEQUENTIAL.SUCCESS, result }),
  failure: (error) => ({ type: POST_GROUP_FOBS_SEQUENTIAL.FAILURE, error }),
};

export const putFob = {
  base: (fob) => ({
    type: PUT_GROUP_FOBS_GRID.BASE,
    fob,
  }),
  request: () => ({ type: PUT_GROUP_FOBS_GRID.REQUEST }),
  success: (result, fob) => ({ type: PUT_GROUP_FOBS_GRID.SUCCESS, result, fob }),
  failure: (error) => ({ type: PUT_GROUP_FOBS_GRID.FAILURE, error }),
};

export const getCheckFobCodes = {
  base: (facilityId, equipmentId, initialCode, count) => ({
    type: GET_GROUP_FOBS_CHECK_CODES.BASE,
    facilityId,
    equipmentId,
    initialCode,
    count,
  }),
  request: () => ({ type: GET_GROUP_FOBS_CHECK_CODES.REQUEST }),
  success: (result, fob) => ({ type: GET_GROUP_FOBS_CHECK_CODES.SUCCESS, result, fob }),
  failure: (error) => ({ type: GET_GROUP_FOBS_CHECK_CODES.FAILURE, error }),
};

export const getPDFFobs = {
  base: (facilityId, groupId, configFields) => ({
    type: GET_GROUP_FOBS_PDF.BASE,
    facilityId,
    groupId,
    configFields,
  }),
  request: () => ({ type: GET_GROUP_FOBS_PDF.REQUEST }),
  success: (result) => ({ type: GET_GROUP_FOBS_PDF.SUCCESS, result }),
  failure: (error) => ({ type: GET_GROUP_FOBS_PDF.FAILURE, error }),
};

export function* deleteFobsListSaga({ facilityId, groupId, fobList }) {
  try {
    const url = apiPathFactory.fobsDeleteList(facilityId, groupId);
    const response = yield call(() =>
      fetchEntitySaga(deleteFobList, api.del, url, { data: fobList })
    );
    yield getInstalationGroupSaga({ facilityId, groupId });
    yield getInstalationsFOBS({ facilityId, groupId });
    yield getInstalationGroupsFobsCountSaga({
      instalationID: facilityId,
      instalationGroupID: groupId,
    });
    return response;
  } catch (error) {
    return error;
  }
}

export function* deleteCompareMemoryFobsListSaga({ facilityId, groupId, fobList }) {
  try {
    const url = apiPathFactory.fobsDeleteCompareMemory(facilityId, groupId);
    const response = yield call(() =>
      fetchEntitySaga(deleteCompareMemoryFobList, api.del, url, { data: fobList })
    );
    return response;
  } catch (error) {
    return error;
  }
}

export function* postCompareMemoryFobsListSaga({ facilityId, groupId, fobList }) {
  try {
    const url = apiPathFactory.fobsPostCompareMemory(facilityId, groupId);
    const response = yield call(() =>
      fetchEntitySaga(postCompareMemoryFobList, api.post, url, { data: fobList })
    );
    return response;
  } catch (error) {
    return error;
  }
}

export function* postFobsListSaga({ facilityId, groupId, fobList }) {
  try {
    const url = apiPathFactory.fobsPostList(facilityId, groupId);
    const response = yield call(() =>
      fetchEntitySaga(postFobList, api.post, url, { data: fobList })
    );
    return response;
  } catch (error) {
    return error;
  }
}

export function* deleteFobSaga({ fob }) {
  try {
    const url = apiPathFactory.fobsDelete(fob.facilityId, fob.facilityGroupId, fob.id);
    const response = yield call(() => fetchEntitySaga(deleteFob, api.del, url, { data: fob }));
    yield getInstalationGroupsFobsCountSaga({
      instalationID: fob.facilityId,
      instalationGroupID: fob.facilityGroupId,
    });
    return response;
  } catch (error) {
    return error;
  }
}

export function* postFobSaga({ fob }) {
  try {
    const url = apiPathFactory.fobsPost(fob.facilityId, fob.facilityGroupId);
    const response = yield call(() => fetchEntitySaga(postFob, api.post, url, { data: fob }));
    return response;
  } catch (error) {
    return error;
  }
}

export function* postSequentialFobSaga({ payload }) {
  try {
    const url = apiPathFactory.fobsPostSequential(
      payload.facilityId,
      payload.facilityGroupId,
      payload.data.codesAmount,
      payload.isReservation,
      payload.data.e_code
    );
    const response = yield call(() =>
      fetchEntitySaga(postSequentialFOB, api.post, url, { data: payload.data })
    );
    return response;
  } catch (error) {
    return error;
  }
}

export function* putFobSaga({ fob }) {
  try {
    const url = apiPathFactory.fobsPut(fob.facilityId, fob.facilityGroupId, fob.id);
    const response = yield call(() =>
      fetchEntitySaga(putFob, api.put, url, {
        data: fob,
        notShowLoadSpinner: true,
      })
    );
    return response;
  } catch (error) {
    return error;
  }
}

export function* putFobMoveListSaga({ facilityId, toFacilityId, groupId, toGroupId, fobList }) {
  try {
    const url = apiPathFactory.fobsPutMove(facilityId, groupId, toFacilityId, toGroupId);
    const response = yield call(() =>
      fetchEntitySaga(putFobMoveList, api.put, url, { data: fobList })
    );
    yield getInstalationGroupSaga({ facilityId, groupId });
    yield getInstalationGroupsFobsCountSaga({
      instalationID: facilityId,
      instalationGroupID: groupId,
    });
    return response;
  } catch (error) {
    return error;
  }
}

export function* postFobCopyListSaga({ facilityId, toFacilityId, groupId, toGroupId, fobList }) {
  try {
    const url = apiPathFactory.fobsPostCopy(facilityId, groupId, toFacilityId, toGroupId);
    const response = yield call(() =>
      fetchEntitySaga(postFobCopyList, api.post, url, { data: fobList })
    );
    return response;
  } catch (error) {
    return error;
  }
}

export function* putImportFobsSaga({ facilityId, id, files }) {
  try {
    const url = apiPathFactory.fobsPutImport(facilityId, id);
    const response = yield call(() =>
      fetchEntitySaga(putFobsImport, api.put, url, {
        formData: [{ fieldName: 'file', file: files }],
      })
    );
    return response;
  } catch (error) {
    return error;
  }
}

export function* getCheckFobCodesSaga({ facilityId, equimentId, initialCode, count }) {
  try {
    const url = apiPathFactory.fobsGetCheckCodes(facilityId, equimentId, initialCode, count);
    const response = yield call(() => fetchEntitySaga(getCheckFobCodes, api.get, url));
    return response;
  } catch (error) {
    return error;
  }
}

export function* getPDFFobsSaga({ facilityId, groupId, configFields }) {
  try {
    const url = apiPathFactory.fobsGetFile(facilityId, groupId, configFields);
    const response = yield call(() => fetchEntitySaga(getPDFFobs, api.get, url));
    return response;
  } catch (error) {
    return error;
  }
}

function* watchDeleteFobList() {
  yield takeLatest(DELETE_GROUP_FOBS_LIST.BASE, deleteFobsListSaga);
}

function* watchPostFobList() {
  yield takeLatest(POST_GROUP_FOBS_LIST.BASE, postFobsListSaga);
}

function* watchPutFobMoveList() {
  yield takeLatest(PUT_GROUP_FOBS_LIST_MOVE.BASE, putFobMoveListSaga);
}

function* watchPostFobCopyListSaga() {
  yield takeLatest(POST_GROUP_FOBS_LIST_COPY.BASE, postFobCopyListSaga);
}

function* watchPostFOB() {
  yield takeLatest(POST_GROUP_FOBS_FOB.BASE, postFobSaga);
}

function* watchPostSequential() {
  yield takeLatest(POST_GROUP_FOBS_SEQUENTIAL.BASE, postSequentialFobSaga);
}

function* watchPutFob() {
  yield takeLatest(PUT_GROUP_FOBS_GRID.BASE, putFobSaga);
}

function* watchDeleteFob() {
  yield takeLatest(DELETE_GROUP_FOBS_FOB.BASE, deleteFobSaga);
}

function* watchPutFobImport() {
  yield takeLatest(PUT_GROUP_FOBS_IMPORT.BASE, putImportFobsSaga);
}

function* watchDeleteCompareMemoryDelete() {
  yield takeLatest(DELETE_GROUP_FOBS_COMPARE_MEMORY_LIST.BASE, deleteCompareMemoryFobsListSaga);
}

function* watchPostCompareMemoryDelete() {
  yield takeLatest(POST_GROUP_FOBS_COMPARE_MEMORY_LIST.BASE, postCompareMemoryFobsListSaga);
}

function* watchGetCheckFobCodes() {
  yield takeLatest(GET_GROUP_FOBS_CHECK_CODES.BASE, getCheckFobCodesSaga);
}

function* watchGetPDFFobs() {
  yield takeLatest(GET_GROUP_FOBS_PDF.BASE, getPDFFobsSaga);
}

export default function* watchers() {
  yield fork(watchPostFOB);
  yield fork(watchPostSequential);
  yield fork(watchPutFobMoveList);
  yield fork(watchPostFobCopyListSaga);
  yield fork(watchDeleteFobList);
  yield fork(watchDeleteFob);
  yield fork(watchPutFob);
  yield fork(watchPostFobList);
  yield fork(watchPutFobImport);
  yield fork(watchDeleteCompareMemoryDelete);
  yield fork(watchPostCompareMemoryDelete);
  yield fork(watchGetCheckFobCodes);
  yield fork(watchGetPDFFobs);
}
