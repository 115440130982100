import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  getUniversalGroupEquipmentRelays,
  putInstalationRelaysUG,
} from '../../modules/old_to_refact/actions/universalGroups';
import { getFacility } from '../../modules/old_to_refact/actions/instalations';
import { resetInstalationGroups } from '../../modules/old_to_refact/actions/instalationGroups';
import UniversalGroupGrow from '../../old_components/UniversalGroupGrow/UniversalGroupGrow';
import InstalationRelayContent from '../../old_components/Relays/components/InstalationRelayContent';
import { getDecodedUrl } from '../../core/old_common/utils/getUrlParams';

class IndividualUniversalGroupEquipmentRelays extends Component {
  componentDidMount() {
    const { needCallFacility, facility, match, getFacility } = this.props;
    if (facility) {
      // getUniversalGroupEquipmentRelays(facility.id);
    } else if (needCallFacility) {
      getFacility(match.params.instalationId);
    }
  }

  componentDidUpdate(prevProps) {
    const { getUniversalGroupEquipmentRelays, facility } = this.props;
    if (!prevProps.facility && facility) {
      getUniversalGroupEquipmentRelays(facility.id);
    }
  }

  componentWillUnmount = () => {
    this.props.resetInstalationGroups();
  };

  getSelectedUG = () => {
    const { equipmentRelays, match } = this.props;
    if (equipmentRelays) {
      return equipmentRelays.find(
        (equipment) => equipment.universalGroupId === getDecodedUrl(match.params.id)
      );
    }
    return undefined;
  };

  getRelays = (equipments) => {
    const { putInstalationRelaysUG, facility } = this.props;
    const ug = this.getSelectedUG();
    const principalId = facility ? facility.id : '';
    const secondaryId = ug ? ug.universalGroupId : '';
    let equipmentsList = [];
    if (equipments) {
      equipmentsList = equipments.map((relay) => (
        <InstalationRelayContent
          className='individual-relay-content'
          key={relay.name}
          relay={relay}
          principalParameter='id'
          principalId={principalId}
          putRelay={putInstalationRelaysUG}
          secondaryParameter='universalGroupId'
          secondaryId={secondaryId}
          instalationsGroupRelays={equipments}
        />
      ));
    }
    return equipmentsList;
  };

  render() {
    const { equipmentRelays, match } = this.props;
    const selectedUG = this.getSelectedUG();
    const baseUri = `/Facilities/${match.params.instalationId}/groups`;
    const formattedSelectedUG = selectedUG
      ? { ...selectedUG, id: selectedUG.universalGroupId, name: selectedUG.universalGroupName }
      : {};
    return (
      <UniversalGroupGrow
        itemsList={equipmentRelays}
        isMapButtonVisible={false}
        hideActionButtons
        {...formattedSelectedUG}
        item={formattedSelectedUG}
        baseURI={baseUri}
      >
        <div className='relays-content'>
          {this.getRelays(selectedUG ? selectedUG.equipments : [])}
        </div>
      </UniversalGroupGrow>
    );
  }
}

const mapStateToProps = (state) => ({
  tenants: state.tenants,
  equipmentRelays: state.universalGroups.equipmentRelays,
  facility: state.instalations?.facility,
  needCallFacility: state.instalations.needCallFacility,
});

const IndividualUniversalGroupEquipmentRelaysWithRouter = withRouter(
  IndividualUniversalGroupEquipmentRelays
);

export default connect(mapStateToProps, {
  resetInstalationGroups,
  getUniversalGroupEquipmentRelays,
  getFacility,
  putInstalationRelaysUG,
})(IndividualUniversalGroupEquipmentRelaysWithRouter);
