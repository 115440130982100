const POST_GRID_UNIVERSAL_FOB = 'POST_GRID_UNIVERSAL_FOB';
const POST_SEQUENTIAL_UNIVERSAL_FOB = 'POST_SEQUENTIAL_UNIVERSAL_FOB';
const GET_UNIVERSAL_FOB_COUNTS = 'GET_UNIVERSAL_FOB_COUNTS';
const GET_UNIVERSAL_FOBS = 'GET_UNIVERSAL_FOBS';
const PUT_UNIVERSAL_FOB = 'PUT_UNIVERSAL_FOB';
const PUT_UNIVERSAL_IMPORT_FOBS = 'PUT_UNIVERSAL_IMPORT_FOBS';
const POST_UNIVERSAL_FOBS_COPY = 'POST_UNIVERSAL_FOBS_COPY';
const DELETE_UNIVERSAL_FOBS_LIST = 'DELETE_UNIVERSAL_FOBS_LIST';
const PUT_UNIVERSAL_FOBS_MOVE = 'PUT_UNIVERSAL_FOBS_MOVE';
const DELETE_UNIVERSAL_FOB = 'DELETE_UNIVERSAL_FOB';
const RESET_IS_POST_UG_FOBS = 'RESET_IS_POST_UG_FOBS';
const GET_UNIVERSAL_FOBS_SUBS = 'GET_UNIVERSAL_FOBS_SUBS';
const DELETE_ALARMS = 'DELETE_ALARMS';
const GET_UG_CHECK_FOB_CODES = 'GET_UG_CHECK_FOB_CODES';
const RESET_UG_CHECKED_FOBS = 'RESET_UG_CHECKED_FOBS';
const RESET_PUT_UGFOBS_ERROR = 'RESET_PUT_UGFOBS_ERROR';
const RESET_UG_FOBS = 'RESET_UG_FOBS';

export default {
  POST_GRID_UNIVERSAL_FOB,
  POST_SEQUENTIAL_UNIVERSAL_FOB,
  GET_UNIVERSAL_FOB_COUNTS,
  GET_UNIVERSAL_FOBS,
  PUT_UNIVERSAL_FOB,
  PUT_UNIVERSAL_IMPORT_FOBS,
  POST_UNIVERSAL_FOBS_COPY,
  PUT_UNIVERSAL_FOBS_MOVE,
  DELETE_UNIVERSAL_FOBS_LIST,
  DELETE_UNIVERSAL_FOB,
  RESET_IS_POST_UG_FOBS,
  GET_UNIVERSAL_FOBS_SUBS,
  DELETE_ALARMS,
  GET_UG_CHECK_FOB_CODES,
  RESET_UG_CHECKED_FOBS,
  RESET_PUT_UGFOBS_ERROR,
  RESET_UG_FOBS,
};

/**
 * Sets the form state
 * @param  {int} value                The value for show loading
 * @return {object}                   Formatted action for the reducer to handle
 */
export const postUniversalFOB = (universalFob, force) => ({
  type: POST_GRID_UNIVERSAL_FOB,
  universalFob,
  force,
});
export const postUniversalSequentialFOB = (payload) => ({
  type: POST_SEQUENTIAL_UNIVERSAL_FOB,
  payload,
});
export const getUniversalGroupsFobsCount = (universalGroupId) => ({
  type: GET_UNIVERSAL_FOB_COUNTS,
  universalGroupId,
});
export const getUniversalGroupsFobs = (universalGroupId) => ({
  type: GET_UNIVERSAL_FOBS,
  universalGroupId,
});
export const putUniversalGroupsFob = (universalGroupId, fob) => ({
  type: PUT_UNIVERSAL_FOB,
  universalGroupId,
  fob,
});
export const putUniversalImportFobs = (universalGroupId, fobs) => ({
  type: PUT_UNIVERSAL_IMPORT_FOBS,
  universalGroupId,
  fobs,
});
export const postUniversalFobsCopy = (universalGroupId, toGroupId, fobs) => ({
  type: POST_UNIVERSAL_FOBS_COPY,
  universalGroupId,
  toGroupId,
  fobs,
});
export const putUniversalFobsMove = (universalGroupId, toGroupId, fobs, groupName) => ({
  type: PUT_UNIVERSAL_FOBS_MOVE,
  universalGroupId,
  toGroupId,
  fobs,
  groupName,
});
export const deleteUniversalFobsList = (universalGroupId, fobs) => ({
  type: DELETE_UNIVERSAL_FOBS_LIST,
  universalGroupId,
  fobs,
});
export const deleteUniversalFob = (universalGroupId, fob) => ({
  type: DELETE_UNIVERSAL_FOB,
  universalGroupId,
  fob,
});
export const resetIsPostUniversalFobSuccess = () => ({ type: RESET_IS_POST_UG_FOBS });
export const getUniversalFobsSubstitutions = (id, fobId) => ({
  type: GET_UNIVERSAL_FOBS_SUBS,
  id,
  fobId,
});
export const deleteAlarms = (alarmId, universalGroupId) => ({
  type: DELETE_ALARMS,
  alarmId,
  universalGroupId,
});

export const getUniversalGroupsFobsCheckCodes = (universalGroupId, initialCode, codesAmount) => ({
  type: GET_UG_CHECK_FOB_CODES,
  universalGroupId,
  initialCode,
  codesAmount,
});

export const resetUGCheckedFobs = () => ({
  type: RESET_UG_CHECKED_FOBS,
});

export const resetUGFobs = () => ({
  type: RESET_UG_FOBS,
});

export const resetPutUGFobsError = () => ({ type: RESET_PUT_UGFOBS_ERROR });
