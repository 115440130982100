export const SET_PAGE_NUMBER = 'SET_PAGE_NUMBER';
export const RESET_PAGE_NUMBER = 'RESET_PAGE_NUMBER';
export const RESET_TOTAL_PAGE_NUMBER = 'RESET_TOTAL_PAGE_NUMBER';

export const SCHEDULE_LIST = 'SCHEDULE_LIST';
export const FACILITY_LIST = 'FACILITY_LIST';
export const RECYCLE_LIST = 'RECYCLE_LIST';
export const UG_LIST = 'UG_LIST';
export const FACILITY_GROUP_LIST = 'FACILITY_GROUP_LIST';
export const EQUIPMENT_LIST = 'EQUIPMENT_LIST';
export const GDPR_LIST = 'GDPR_LIST';
export const UG_FACILITIES_LIST = 'UG_FACILITIES_LIST';
export const GDPR_LIST_PENDING = 'GDPR_LIST_PENDING';
export const UG_FACILITIES_LIST_SELECTION = 'UG_FACILITIES_LIST_SELECTION';
export const GROUPS_LIST = 'GROUPS_LIST';
export const FACILITY_UG_LIST = 'FACILITY_UG_LIST';

export const getPageListTypesDefaultState = () => {
  const state = {
    FACILITY_UG_LIST: 1,
    SCHEDULE_LIST: 1,
    UG_LIST: 1,
    FACILITY_LIST: 1,
    RECYCLE_LIST: 1,
    UG_FACILITIES_LIST: 1,
    UG_FACILITIES_LIST_SELECTION: 1,
    GDPR_LIST: 1,
    GDPR_LIST_PENDING: 1,
    GROUPS_LIST: 1,
    EQUIPMENT_LIST: 1,
  };
  return state;
};
