import {
  DEL_HOLIDAYS,
  GET_HOLIDAYS,
  GET_HOLIDAYS_FULL,
  SET_HOLIDAYS,
} from './constants';

/* Redux state init */
const initialState = {
  isSuccess: false,
  holidays: [],
  holidaysActive: [],
  errorMessage: undefined,
};

export default function holidayReducer(state = initialState, action) {
  if (action.type !== undefined) {
    switch (action.type) {
      case GET_HOLIDAYS.SUCCESS:
        return {
          ...state,
          holidaysActive: action.result,
          isSuccess: true,
        };
      case GET_HOLIDAYS_FULL.SUCCESS:
        return {
          ...state,
          holidays: action.result,
          isSuccess: true,
        };
      case SET_HOLIDAYS.SUCCESS:
        return {
          ...state,
          isSuccess: true,
        };
      case DEL_HOLIDAYS.BASE:
        return {
          ...state,
          holidays: action.result,
          isSuccess: true,
        };
      default:
        return state;
    }
  }
  return state;
}
