import PropTypes from 'prop-types';
import { Card } from '@jcm-technologies/uikit/dist/molecules/Card';
import { Grid } from '@jcm-technologies/uikit/dist/atoms/Layout';
import { Field } from '@jcm-technologies/uikit/dist/atoms/Field';
import { Input } from '@jcm-technologies/uikit/dist/atoms/Input';
import { useTranslation } from 'react-i18next';

const CompanyInfo = ({ company, roleName, email }) => {
  const { t } = useTranslation();
  return (
    <Card borderWidth={1} borderStyle='solid' borderColor='grey' borderRadius={3} boxShadow='none'>
      <Grid
        gridGap={2}
        gridTemplateAreas={['"." "." "."', '"." "." "."', '". . ."']}
        gridTemplateColumns={['1fr', '1fr', '1fr 1fr 1fr']}
        width='100%'
        height='100%'
        padding={2}
        maxWidth='100%'
        borderWidth={1}
        borderStyle='solid'
        borderColor='grey'
        borderRadius={3}
        boxShadow='none'
        alignItems='center'
      >
        <Field label={t('form.companyName')}>
          <Input id='companyName' name='companyName' readOnly defaultValue={company} />
        </Field>
        <Field label={t('form.role')}>
          <Input id='role' name='role' defaultValue={roleName} readOnly />
        </Field>
        <Field label={t('form.email')}>
          <Input id='email' name='email' defaultValue={email} readOnly />
        </Field>
      </Grid>
    </Card>
  );
};

export default CompanyInfo;

CompanyInfo.propTypes = {
  company: PropTypes.string,
  roleName: PropTypes.string,
  email: PropTypes.string,
};

CompanyInfo.defaultProps = {
  company: '',
  roleName: '',
  email: '',
};
