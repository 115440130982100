import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import moment from 'moment-timezone';
import IconPlus from '@jcm-technologies/uikit/dist/atoms/Icons/IconPlus';
import { IconTooltip } from '@jcm-technologies/uikit/dist/molecules/IconTooltip';
import GenericKendoGrid from '../GenericKendoGrid/GenericKendoGrid';
import IconSpan from '../IconSpan/IconSpan';

import {
  deleteAlarms,
  deleteUniversalFob,
  deleteUniversalFobsList,
  getUniversalFobsSubstitutions,
  getUniversalGroupsFobs,
  getUniversalGroupsFobsCheckCodes,
  postUniversalFobsCopy,
  putUniversalFobsMove,
  putUniversalGroupsFob,
  putUniversalImportFobs,
  resetUGCheckedFobs,
  resetUGFobs,
} from '../../modules/old_to_refact/actions/universalGroupsFobs';

import {
  getUniversalGroupSingleList,
  resetUniversalGroupSingleList,
} from '../../modules/old_to_refact/actions/universalGroups';
import { getFamiliesSingleList } from '../../modules/productFamilies/action';

import KendoDropDownCell from '../KendoDropDownCell';

import './IndividualUniversalGroupUsersGrid.css';
import { getFamilyListByType, getProduct } from '../../core/old_common/utils/familiesService';
import { getECodeTranslated } from '../../core/old_common/utils/facilityService';
import {
  createSignalRConnectionOnReducer,
  invokeSendCICC,
  invokeSendFunction,
  setAssistantPanelCheckSuccessFunction,
  setMessageHubFunction,
} from '../../core/old_common/utils/signalRService';
import { generateRows, getItemWithAttributtes, } from '../../core/old_common/utils/createInputByTenant';
import DeletePopup from '../DeletePopup/DeletePopup';
import PropsList from '../PropsList/PropsList';
import { replaceStringValue } from '../../core/old_common/utils/replaceStringService';
import Popup from '../Popup/Popup';
import PopUpConfirm from '../PopUpConfirm';
import { resetSignalRPopup, setNeedPopup, showPopUp, } from '../../modules/old_to_refact/actions/signalr';
import PopUpErrorMessage from '../PopupErrorMessage/PopUpErrorMessage';
import { resetAllFobLists } from '../../modules/old_to_refact/actions/instalations';
import { getCookieUserRoles } from '../../core/old_common/utils/cookiesManager';
import { createArrayFromObjectParam } from '../../core/helpers/arrayService';

class IndividualUniversalGroupUsersGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      substitutionReady: false,
      isDeleteFobPopupOpen: false,
      isCheckingCodes: undefined,
      fobCodeSignalRTrack: '',
      substitutionSignalRTrack: '',
      deleteFobItem: {},
      alarmId: null,
      isErrorMessagePopupOpen: false,
      productIdWrite: undefined,
      popup: {
        isPopupOpen: false,
        text: '',
        onAcceptFn: () => {
          this.onCleanAlarm();
        },
        onCancelFn: () => {
          this.onToggleCleanAlarm();
        },
      },
      needUpdateViewUg: false,
      codesSubstitutionDone: undefined,
    };
  }

  componentDidMount() {
    const { getUniversalGroupsFobs, getFamiliesSingleList, match } = this.props;
    const id = match.params.id;

    if (id) {
      getUniversalGroupsFobs(id);
    }
    setMessageHubFunction(this.newMessageHubFunction);
    setAssistantPanelCheckSuccessFunction(this.codesSubstitution);
    getFamiliesSingleList(2, true); // TODO: ORIGINAL: 0
    createSignalRConnectionOnReducer();
  }

  componentDidUpdate() {
    const {
      resetUGCheckedFobs,
      codeExists,
      universalGroupFobSubs,
      errorMessage,
      t,
      errorPutUGFobsMessage,
      setNeedPopup,
      showSignalRPopup,
      id,
      getUniversalGroupsFobs,
    } = this.props;
    const { substitutionSignalRTrack, isCheckingCodes, needUpdateViewUg, substitutionReady } =
      this.state;
    if (codeExists && isCheckingCodes) {
      resetUGCheckedFobs();
      this.setState({ isCheckingCodes: undefined, isErrorMessagePopupOpen: true });
    } else if (codeExists === false && isCheckingCodes) {
      setNeedPopup();
      if ((showSignalRPopup === false || showSignalRPopup === undefined) && substitutionReady) {
        if (universalGroupFobSubs.length === 0) {
          invokeSendFunction(substitutionSignalRTrack);
          this.setState({ substitutionReady: false });
        } else {
          while (universalGroupFobSubs.length < 3) {
            universalGroupFobSubs.push({ e_Code: '0' });
          }
          invokeSendFunction(
            `${substitutionSignalRTrack}::${universalGroupFobSubs
              .map((subs) => subs.e_Code)
              .join('::')}`
          );
          this.setState({ substitutionReady: false });
        }
      }
    }
    if (errorPutUGFobsMessage !== undefined && isCheckingCodes) {
      this.setState({ isCheckingCodes: undefined });
    }
  }

  componentWillUnmount() {
    this.props.resetUniversalGroupSingleList();
    this.props.resetAllFobLists();
    this.props.resetUGFobs();
  }

  getColumns = () => {
    const { t, tenants, universalConfigFields } = this.props;
    return [
      {
        key: ' ',
        name: '',
        width: '50px',
        hideOnPDFExport: true,
        cell: (props, childProps) => (
          <td className='grid-buttons'>
            <IconSpan
              cursor='pointer'
              toolTip={t('fobs.substitution')}
              tenants={tenants}
              iconClassName='fas fa-sync-alt icon-cursor'
              cursos='pointer'
              onClick={() => this.onClickSignalR(props, childProps)}
              {...childProps}
            />
          </td>
        ),
        tooltip: t('form.type'),
      },
      {
        key: 'alarms',
        name: t('instalationGroups.alarms'),
        width: '85px',
        sortable: true,
        hideOnPDFExport: true,
        cell: (props, childProps) => (
          <td>
            {props?.dataItem?.alarms?.length ? (
              <div className='d-flex justify-content-center'>
                {props?.dataItem?.alarms.map((element, key) => {
                  if (element.alarmCode) {
                    const icon =
                      element.alarmCode === 44801 ? 'fa-skull-crossbones' : 'fa-battery-quarter';
                    return (
                      <IconSpan
                        key={element?.id || key}
                        cursor='pointer'
                        defaultColor='#ff0000'
                        toolTip={t(`code.${element.alarmCode}`)}
                        tenants={tenants}
                        style={{
                          marginLeft: key > 0 && '4px',
                        }}
                        iconClassName={`fas ${icon}`}
                        cursos='pointer'
                        onClick={() => this.onToggleCleanAlarm(element.id, props)}
                        {...childProps}
                      />
                    );
                  }
                })}
              </div>
            ) : (
              ''
            )}
          </td>
        ),
      },
      {
        key: 'fobCode',
        name: t('form.code'),
        sortable: true,
        filterable: true,
        tooltip: t('form.code'),
      },
      {
        key: 'productName',
        name: t('form.type'),
        sortable: true,
        filterable: true,
        editable: true,
        cell: (props, childProps) => (
          <KendoDropDownCell
            onChangeSave={this.onGroupSave}
            datas={this.getProducts()}
            layout={this.getTypeValueName(props)}
            {...props}
            {...childProps}
            field='productId'
          />
        ),
        tooltip: t('form.type'),
      },
      {
        key: 'name',
        name: t('form.name'),
        sortable: true,
        filterable: true,
        editable: true,
        tooltip: t('form.type'),
      },
      {
        key: 'surname',
        name: t('form.surname'),
        sortable: true,
        filterable: true,
        editable: true,
        tooltip: t('form.type'),
      },
      {
        key: 'nic',
        name: t('form.identityCard'),
        sortable: true,
        filterable: true,
        editable: true,
        tooltip: t('form.type'),
      },
      ...generateRows(universalConfigFields, 5, true),
      {
        key: ' ',
        name: '',
        width: '50px',
        hideOnPDFExport: true,
        cell: (props, childProps) => (
          <td colSpan='1' className='grid-buttons'>
            <IconSpan
              cursor='pointer'
              toolTip={t('genericGrow.delete')}
              tenants={tenants}
              iconClassName='fas fa-trash'
              onClick={() => this.onClickDeleteFob(props, childProps)}
              {...childProps}
            />
          </td>
        ),
        tooltip: t('form.type'),
      },
    ];
  };

  getProducts = () => {
    const productsArray = [];
    const family = getFamilyListByType(this.props.familiesSingleList, 2);
    if (family) {
      const products = [...family].flat();
      products.forEach((family, i) => {
        productsArray.push({
          key: family.section,
          id: family.section,
          value: family.section,
          disabled: true,
          className: 'enroll-code-combo-family',
          options: family.items.map((product) => ({
            key: product.productId,
            id: product.productId,
            value: product.name,
          })),
        });
      });
    }
    return productsArray;
  };

  getTypeValueName = (props) => {
    const products = this.getProducts();
    const { productId } = props.dataItem;
    let productName = '';
    products.forEach((family) => {
      family.options.forEach((product) => {
        if (product.id === productId) {
          productName = product.value;
        }
      });
    });
    return productName;
  };

  onGroupSave = (item) => {
    const { putUniversalGroupsFob, id, universalConfigFields, universalGroupFobs } = this.props;

    const itemWithAttributes = {
      ...getItemWithAttributtes(
        item,
        'facilityFobAttributes',
        item.facilityFobAttributes,
        universalConfigFields
      ),
    };
    const selectedFob = {
      ...universalGroupFobs.find((fob) => fob.e_code === parseInt(item.e_code)),
    };

    if (selectedFob) {
      item.facilityFobAttributes.map((newAttribute) => {
        const storeAttributeForTag = selectedFob.facilityFobAttributes.find(
          (z) => z.tag == newAttribute.tag
        );

        if (storeAttributeForTag) {
          newAttribute.id = storeAttributeForTag.id;
        }
      });
    }

    putUniversalGroupsFob(id, itemWithAttributes);
  };

  putUniversalImportFobs = (e) => {
    const { id, putUniversalImportFobs } = this.props;
    if (e.target.files.length > 0) {
      putUniversalImportFobs(id, e.target.files[0]);
      this.props.resetUGFobs();
      this.props.resetAllFobLists();
    }
  };

  getExportExcelColumns = () => {
    const { t, universalConfigFields } = this.props;
    return [
      {
        field: 'id',
        title: 'Id',
        locked: true,
        cellOptions: { background: '#ccc', wrap: true },
        headerCellOptions: { textAlign: 'center', bold: true },
      },
      {
        field: 'fobCode',
        title: t('form.code'),
        locked: true,
        cellOptions: { background: '#ccc', wrap: true },
        headerCellOptions: { textAlign: 'center', bold: true },
      },
      {
        field: 'productName',
        title: t('form.type'),
        locked: true,
        cellOptions: { background: '#ccc', wrap: true },
        headerCellOptions: { textAlign: 'center', bold: true },
      },
      {
        field: 'name',
        title: t('form.name'),
        width: 150,
        cellOptions: { wrap: true },
        headerCellOptions: { textAlign: 'center', bold: true },
      },
      {
        field: 'surname',
        title: t('form.surname'),
        width: 150,
        cellOptions: { wrap: true },
        headerCellOptions: { textAlign: 'center', bold: true },
      },
      {
        field: 'nic',
        title: t('form.identityCard'),
        width: 150,
        cellOptions: { wrap: true },
        headerCellOptions: { textAlign: 'center', bold: true },
      },
      {
        field: 'plaza',
        title: t('instalationGroups.place'),
        width: 150,
        cellOptions: { wrap: true },
        headerCellOptions: { textAlign: 'center', bold: true },
      },
      ...generateRows(universalConfigFields, 5, true).map((configField) => ({
        field: configField.key,
        title: configField.name,
        width: 150,
        cellOptions: { wrap: true },
        headerCellOptions: { textAlign: 'center', bold: true },
      })),
    ];
  };

  putMoveFobList = (toUGId, secondaryListId, fobList) => {
    const { id, putUniversalFobsMove, universalGroupsSingleList, name } = this.props;
    putUniversalFobsMove(
      id,
      universalGroupsSingleList.find((ug) => ug.groupId === toUGId).groupId,
      createArrayFromObjectParam(fobList, 'id'),
      name
    );
  };

  postCopyFobList = (toUGId, secondaryListId, fobList) => {
    const { id, postUniversalFobsCopy, universalGroupsSingleList } = this.props;
    postUniversalFobsCopy(
      id,
      universalGroupsSingleList.find((ug) => ug.groupId === toUGId).groupId,
      createArrayFromObjectParam(fobList, 'id')
    );
  };

  deleteFobList = (e, fobList) => {
    const { id, deleteUniversalFobsList } = this.props;
    deleteUniversalFobsList(id, createArrayFromObjectParam(fobList, 'id'));
  };

  onClickDeleteFob = (props) => {
    this.setState({ isDeleteFobPopupOpen: true, deleteFobItem: props.dataItem });
  };

  handleAcceptDeleteIndividualFob = () => {
    const { deleteUniversalFob, id } = this.props;

    deleteUniversalFob(id, this.state.deleteFobItem);
    this.setState({ isDeleteFobPopupOpen: false, deleteFobItem: {} });
  };

  onClickSignalR = (props) => {
    const { t, id, selectedUG } = this.props;
    const { dataItem } = props;
    const families = getFamilyListByType(this.props.familiesSingleList, 2).flat();
    const products = families.map((family) => family.items).flat();
    const selectedProduct = products.find((product) => product.productId === dataItem.productId);

    if (selectedProduct) {
      invokeSendCICC();
      this.props.getUniversalFobsSubstitutions(id, dataItem.id);
      setAssistantPanelCheckSuccessFunction(this.codesSubstitution);
      this.setState({
        substitutionSignalRTrack: `${selectedProduct.name}::${selectedProduct.productIdWrite}::${selectedProduct.productIdRead}::${dataItem.e_code}::${selectedUG.installerCode}`,
        fobCodeSignalRTrack: `${selectedProduct.name}::${selectedProduct.productIdWrite}::${selectedProduct.productIdRead}::null::${selectedUG.installerCode}`,
        isCheckingCodes: true,
        productIdWrite: selectedProduct.productIdWrite,
        substitutionReady: true,
        codesSubstitutionDone: false,
      });
    } else {
      toast.error(t('apitoasters.errors.generic'));
    }
  };

  onToggleCleanAlarm = (alarmId, props) => {
    const { popup } = this.state;
    if (popup.isPopupOpen) {
      this.setState({
        popup: {
          ...popup,
          isPopupOpen: false,
        },
        alarmId: null,
        dataItem: null,
      });
    } else {
      this.setState({
        popup: {
          ...popup,
          isPopupOpen: true,
        },
        alarmId,
        dataItem: props.dataItem,
      });
    }
  };

  onCleanAlarm = async () => {
    const { id, deleteAlarms } = this.props;
    const { alarmId } = this.state;
    await deleteAlarms(alarmId, id);
    await this.onToggleCleanAlarm();
  };

  codesSubstitution = () => {
    const { fobCodeSignalRTrack } = this.state;
    invokeSendFunction(fobCodeSignalRTrack);
  };

  newMessageHubFunction = (name, message) => {
    const {
      id,
      getUniversalGroupsFobsCheckCodes,
      codeExists,
      putUniversalGroupsFob,
      universalGroupFobs,
      resetUGCheckedFobs,
      resetSignalRPopup,
      getUniversalGroupsFobs,
    } = this.props;
    const { isCheckingCodes, codesSubstitutionDone } = this.state;
    const splittedResponse = message.split('::');
    const fobCodes = { newCode: splittedResponse[0], oldCode: splittedResponse[1] };
    if (isCheckingCodes && codeExists === undefined && codesSubstitutionDone) {
      getUniversalGroupsFobsCheckCodes(id, message, 1);
    }
    if (codeExists === false && isCheckingCodes) {
      const selectedFob = {
        ...universalGroupFobs.find((fob) => fob.e_code === parseInt(fobCodes.oldCode)),
      };
      selectedFob.e_code = parseInt(fobCodes.newCode);
      putUniversalGroupsFob(id, selectedFob);
      resetUGCheckedFobs();
      resetSignalRPopup();
      this.setState({ isCheckingCodes: undefined, productIdWrite: undefined });
    } else if (codesSubstitutionDone === false) {
      this.codesSubstitution();
      this.setState({ codesSubstitutionDone: true });
    }
  };

  parseConfigFieldsToGrid = (fob) => {
    const responseObject = {};
    if (fob.facilityFobAttributes) {
      fob.facilityFobAttributes.forEach(
        (attribute) => (responseObject[attribute.tag] = attribute.value)
      );
    }
    return responseObject;
  };

  popupDeleteChildren = () => {
    const { t } = this.props;
    const { deleteFobItem } = this.state;
    return [
      {
        key: t('form.code'),
        value:
          deleteFobItem.fobCode === t('enrollCode.reserved')
            ? deleteFobItem.fobCode
            : deleteFobItem.e_code,
      },
      {
        key: t('form.type'),
        value: deleteFobItem.productName,
      },
      {
        key: t('form.name'),
        value: deleteFobItem.name,
      },
      {
        key: t('form.surname'),
        value: deleteFobItem.surname,
      },
      {
        key: t('form.identityCard'),
        value: deleteFobItem.nic,
      },
      {
        key: t('instalationGroups.place'),
        value: deleteFobItem.plaza,
      },
    ];
  };

  render() {
    const {
      t,
      tenants,
      history,
      baseURI,
      universalGroupFobs,
      universalGroupsSingleList,
      familiesSingleList,
      name,
    } = this.props;
    const role = getCookieUserRoles();
    const roleMask = role ? role.mask : '';
    const isBasicUserRole = roleMask === 16;
    const isBasicManagerRole = roleMask === 32;
    const isPropertyManagerRole = roleMask === 128;
    const isPropertyManagerLiteRole = roleMask === 256;
    const productFamily = getFamilyListByType(familiesSingleList, 2);
    const fobs = universalGroupFobs || [];
    return (
      <div className='universal-users-grid'>
        <Popup isOpen={this.state.popup.isPopupOpen} onCloseIconClick={this.state.popup.onCancelFn}>
          <PopUpConfirm
            {...this.state.popup}
            text={t('alarms.deleteAlarmPopUp')}
            tenants={tenants}
          />
        </Popup>
        <GenericKendoGrid
          value={fobs.map((fob) => {
            const product = getProduct(productFamily, fob.productId);
            return {
              ...fob,
              ...this.parseConfigFieldsToGrid(fob),
              fobCode: getECodeTranslated(fob.e_code),
              productName: product ? product.name : '',
            };
          })}
          needMultiselection
          needUpdateView
          needExport={
            !(
              isBasicUserRole ||
              isBasicManagerRole ||
              isPropertyManagerRole ||
              isPropertyManagerLiteRole
            )
          }
          getColumns={this.getColumns}
          excelExportColumns={this.getExportExcelColumns()}
          importExcel={this.putUniversalImportFobs}
          excelName={`${replaceStringValue(name, 'Universal_Group')}_${moment().format(
            'YYYY-MM-DD'
          )}`}
          pdfName={`${moment().format('YYYY-MM-DD')}_${replaceStringValue(
            name,
            'Universal_Group'
          )}`}
          // firstSortedColumn={'fobCode'}
          gridCallbacks={{
            deleteItemsCallback: this.deleteFobList,
            moveItemsCallback: this.putMoveFobList,
            copyItemsCallback: this.postCopyFobList,
            onCellBlurSave: this.onGroupSave,
          }}
          gridDatas={{
            isUniversal: true,
            moveToPrincipalList: universalGroupsSingleList,
            firstCallAction: 'ugSingleList',
            idPrincipalCall: this.props.id,
            principalListPlaceHolder: t('title.universalGroups'),
          }}
          showItemsSelected
        />
        <span className='instalation-group-config-icons-container'>
          <IconTooltip
            toolTipContent={t('instalationGroups.enrollFOB')}
            sizeIcon='display36'
            color='grey'
            onClick={() => {
              history.push(`${baseURI.replace('%09', '%2509')}/Enroll`);
            }}
          >
            <IconPlus />
          </IconTooltip>
          <DeletePopup
            title={t('instalationGroups.deleteFobConfirmation')}
            isOpen={this.state.isDeleteFobPopupOpen}
            handleAccept={this.handleAcceptDeleteIndividualFob}
            handleCancel={() => this.setState({ isDeleteFobPopupOpen: false, deleteFobItem: {} })}
          >
            <PropsList propsArray={this.popupDeleteChildren()} />
          </DeletePopup>
          <PopUpErrorMessage
            isOpen={
              !!(this.props.showSignalRPopup || this.props.errorPutUGFobsMessage !== undefined)
            }
            onClose={() =>
              this.setState({ isErrorMessagePopupOpen: false, isCheckingCodes: false })
            }
            productId={this.state.productIdWrite ? this.state.productIdWrite : ''}
          />
        </span>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  tenants: state.tenants,
  originalTenants: state.tenants.originalTenants,
  facilityFobs: state.instalations.facilityFobs,
  familiesSingleList: state.families.familiesSingleList,
  universalGroupFobs: state.universalGroupsFobs.universalGroupFobs,
  universalGroupFobSubs: state.universalGroupsFobs.universalGroupFobSubs,
  universalGroupsSingleList: state.universalGroups.universalGroupsSingleList,
  needCallUGSingleList: state.universalGroups.needCallUGSingleList,
  universalGroupsInfo: state.universalGroups.universalGroupsInfo,
  universalConfigFields: state.universalGroupsFobs.universalConfigFields,
  codeExists: state.universalGroupsFobs.codeExists,
  errorMessage: state.universalGroupsFobs.errorMessage,
  showSignalRPopup: state.signalR.showSignalRPopup,
  message: state.signalR.message,
  errorPutUGFobsMessage: state.universalGroupsFobs.errorPutUGFobsMessage,
});

const IndividualUniversalGroupUsersGridWithTranslation = withTranslation()(
  IndividualUniversalGroupUsersGrid
);
const IndividualUniversalGroupUsersGridWithRouter = withRouter(
  IndividualUniversalGroupUsersGridWithTranslation
);

export default connect(mapStateToProps, {
  putUniversalImportFobs,
  getUniversalGroupsFobs,
  putUniversalGroupsFob,
  getFamiliesSingleList,
  getUniversalGroupSingleList,
  postUniversalFobsCopy,
  putUniversalFobsMove,
  deleteUniversalFobsList,
  deleteUniversalFob,
  resetUniversalGroupSingleList,
  getUniversalFobsSubstitutions,
  deleteAlarms,
  getUniversalGroupsFobsCheckCodes,
  resetUGCheckedFobs,
  setNeedPopup,
  showPopUp,
  resetSignalRPopup,
  resetAllFobLists,
  resetUGFobs,
})(IndividualUniversalGroupUsersGridWithRouter);
