import createFetchTypes from '../../core/typeActions/types';

export const GET_DUPLICATE_SCHEDULE = createFetchTypes('GET_DUPLICATE_SCHEDULE');
export const GET_SCHEDULES = createFetchTypes('GET_SCHEDULES');
export const GET_SCHEDULE = createFetchTypes('GET_SCHEDULE');
export const PUT_SCHEDULE = createFetchTypes('PUT_SCHEDULE');
export const DEL_SCHEDULE = createFetchTypes('DEL_SCHEDULE');
export const POST_SCHEDULES = createFetchTypes('POST_SCHEDULES');
export const GET_SCHEDULES_SINGLE_LIST = createFetchTypes('GET_SCHEDULES_SINGLE_LIST');
export const GET_SCHEDULES_ASSIGNED_FACILITIES = createFetchTypes(
  'GET_SCHEDULES_ASSIGNED_FACILITIES'
);
export const RESET_SCHEDULE = 'RESET_SCHEDULE';
export const RESET_DUPLICATE_SCHEDULE = 'RESET_DUPLICATE_SCHEDULE';
