import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Flex } from '@jcm-technologies/uikit/dist/atoms/Layout';
import { Card } from '@jcm-technologies/uikit/dist/molecules/Card';
import { Text } from '@jcm-technologies/uikit/dist/atoms/Typography';
import { Pagination } from '@jcm-technologies/uikit/dist/molecules/Pagination';
import IconSearch from '@jcm-technologies/uikit/dist/atoms/Icons/IconSearch';
import { Input } from '@jcm-technologies/uikit/dist/atoms/Input';
import { getGDPRPendingList } from '../../../../modules/owner/action';
import { setSearchQuery } from '../../../../modules/state/visibleDescendants/actions';
import { GDPR_LIST_PENDING } from '../../../../modules/state/pagination/constants';
import { setPageNumber } from '../../../../modules/state/pagination/actions';

export function ListPendingGDPR() {
  const dispatch = useDispatch();
  const { ownersPendingList } = useSelector((state) => state.owners);
  const { totalPages, pagenumber } = useSelector((state) => state.backendParams);
  const { searchQuery } = useSelector((state) => state.visibleDescendants);
  const getList = (value = '') => dispatch(getGDPRPendingList(value));
  const handlePageChange = (infoPage) => {
    dispatch(setPageNumber(GDPR_LIST_PENDING, infoPage?.actualPage));
    getList();
  };

  useEffect(() => {
    getList();
  }, []);

  return (
    <Box paddingTop={4} maxWidth={[225, 622, 800, '100%']}>
      <Input
        id='user-filter'
        name='user-filter'
        sizeInput='small'
        iconRight={<IconSearch />}
        iconRightAction={() => getList(searchQuery)}
        onChange={(name, value) => {
          dispatch(setSearchQuery(value || '', !!value));
        }}
      />

      {ownersPendingList?.map((el) => (
        <Card as='article' width='100%' maxWidth='100%' position='relative' shadow='bottom'>
          <Flex
            paddingY={1}
            paddingX={2}
            marginY={1}
            justifyContent='space-between'
            alignItems='center'
          >
            <Text sizeText='display14'>{el.email}</Text>
          </Flex>
        </Card>
      ))}
      <Flex alignItems='center' justifyContent='center' paddingY={2}>
        {!!totalPages && (
          <Pagination
            actionChangePage={(infoPage) => {
              handlePageChange(infoPage);
            }}
            initNumber={pagenumber[GDPR_LIST_PENDING]}
            maxPageVisibleNumber={5}
            minPageNumber={1}
            totalPages={totalPages}
          />
        )}
      </Flex>
    </Box>
  );
}
