export default {
  resetPassword: {
    email: 'E-mail',
    newPassword: 'Nouveau mot de passe',
    backToLogin: 'Retour à la page de connexion',
    mailReseted: 'Votre e-mail a été restauré',
    passwordReset: 'Réinitialiser le mot de passe',
    send: 'Envoyer',
  },
};
