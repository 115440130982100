import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import $ from 'jquery';
import { withTranslation } from 'react-i18next';
import { IconSpan } from '../IconSpan/IconSpan';
import InstallMaps from '../../core/old_common/utils/InstallMaps';
import './custom.css';
import { exportComponentToPdf } from '../../core/helpers/getImagesBase64';
import moment from 'moment-timezone';

export class InstalationMapPopUpContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      devices: '',
      groups: '',
    };
  }

  componentDidMount = () => {
    this.setMap();
  };

  componentDidUpdate = () => {
    this.setMap();
  };

  setMap = () => {
    const { mapInfo } = this.props;
    const { devices } = this.state;
    if (mapInfo && !devices) {
      this.setState({
        devices: InstallMaps.outputDevices(mapInfo),
        groups: InstallMaps.outputGroups(mapInfo),
      });
    }
    this.expandMap();
  };

  expandMap = () => {
    $('.toggle-branch').on('click', function () {
      const link = $(this);
      link
        .parent()
        .siblings('.branch')
        .toggle('linear', function () {
          if ($(this).is(':visible')) {
            link.children('i').removeClass('fa-plus').addClass('fa-minus');
          } else {
            link.children('i').removeClass('fa-minus').addClass('fa-plus');
          }
        });
    });
  };

  render() {
    const { t, mapInfo, onCloseForm } = this.props;
    return (
      <div className='modal-content'>
        <div className='modal-header'>
          <h4 className='modal-title' id='install-tree-title'>
            {mapInfo ? mapInfo.name : ''}
          </h4>

          <span>
            {this.state.devices.length > 44 ||
            this.state.groups.length > 44 ||
            this.state.devices === '' ? (
              <span
                className='fas fa-file-pdf export-pdf-map'
                data-dismiss='modal'
                aria-label='Export to PDF'
                onClick={() =>
                  exportComponentToPdf(
                    'facilityMap',
                    mapInfo ? `${moment().format('YYYY-MM-DD')}_${mapInfo.name}` : undefined
                  )
                }
              />
            ) : (
              ''
            )}
            <button
              type='button'
              className='close'
              data-dismiss='modal'
              aria-label='Close'
              onClick={onCloseForm}
            >
              <span aria-hidden='true'>&times;</span>
            </button>
          </span>
        </div>
        <div className='modal-body'>
          {this.state.devices.length > 44 ||
          this.state.groups.length > 44 ||
          this.state.devices === '' ? (
            <div className='testFlex' id='facilityMap'>
              <fieldset className='structure-col'>
                <legend>{t('instalationsMaps.structure')}</legend>
                <div
                  className='tree-container'
                  dangerouslySetInnerHTML={{ __html: this.state.devices }}
                />
              </fieldset>
              <fieldset className='groups-col'>
                <legend>{t('instalationsMaps.groups')}</legend>
                <div
                  className='tree-container'
                  dangerouslySetInnerHTML={{ __html: this.state.groups }}
                />
              </fieldset>
            </div>
          ) : (
            <div className='no-content'>
              <h6>
                <IconSpan
                  tenants={this.props.tenants}
                  defaultColor='grey'
                  iconClassName='fas fa-info-circle empty-map-warn'
                />
                {t('instalationsMaps.noContent')}
              </h6>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  tenants: state.tenants,
});

const instalationMapTranslation = withTranslation()(InstalationMapPopUpContent);
const instalationMapTranslationWithRouter = withRouter(instalationMapTranslation);
const AcceptInvitationWithRedux = connect(mapStateToProps, {})(instalationMapTranslationWithRouter);

export default AcceptInvitationWithRedux;
