import { cloneElement, useState } from 'react';
import { filterBy } from '@progress/kendo-data-query';
import { Grid as TableGrid, GridColumn } from '@progress/kendo-react-grid';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getCookieFobHistorySearch } from '../../../../../core/old_common/utils/cookiesManager';
import { getContrastColor, increaseBrightness } from '../../../../../core/helpers/colorManager';
import { CustomFilter } from '../../../../common/Table/renderers';

const TableHistory = ({ data }) => {
  const { t } = useTranslation();
  const { color1 } = useSelector((state) => state.tenants);
  const {
    user: { pageItems },
  } = useSelector((state) => state.user);

  const [filter, setFilter] = useState({
    logic: 'and',
    filters: [],
  });
  const [page, setPage] = useState({
    skip: 0,
    take: pageItems || 15,
  });

  const rowRender = (trElement, { dataItem }) => {
    const fobSearched = getCookieFobHistorySearch()?.value;
    const highLight = Number(dataItem.code.split('->')[0]) === fobSearched;

    const colorHighLight = {
      backgroundColor: increaseBrightness(color1, 65),
      color: getContrastColor(color1),
      fontWeight: 'bold',
    };
    const trProps = {
      style: highLight ? colorHighLight : {},
    };
    return cloneElement(
      trElement,
      {
        ...trProps,
      },
      trElement.props.children
    );
  };

  const pageChange = (event) => {
    setPage(event.page);
  };

  return (
    <TableGrid
      data={filterBy(data.slice(page.skip, page.take + page.skip), filter)}
      filterable
      filter={filter}
      onFilterChange={(e) => setFilter(e.filter)}
      rowRender={rowRender}
      skip={page.skip}
      take={page.take}
      total={data.length}
      pageable
      onPageChange={pageChange}
    >
      <GridColumn field='code' title={t('form.code')} filterCell={CustomFilter} />
      <GridColumn
        field='dateTime'
        title={t('form.substitutionOn')}
        filter='date'
        filterCell={CustomFilter}
      />
      <GridColumn
        field='user'
        title={t('form.userCloudAssistant')}
        filter='text'
        filterCell={CustomFilter}
      />
    </TableGrid>
  );
};

export default TableHistory;

TableHistory.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      dateTime: PropTypes.string,
      user: PropTypes.string,
    })
  ).isRequired,
};
