import {
  isSameValue,
  isNumeric,
  isMin1Value,
  isCorrectPassword,
  isDate,
  isRequired,
  isInRange,
  isInTextLength,
  regEx,
  deviceLimit,
  isSamePassword,
  notOnlySpaces,
  notOnlySpacesIsRequired,
  isNotCreationName,
} from './common';

const validationsDictionary = [];
validationsDictionary.isSameValue = isSameValue;
validationsDictionary.isSamePassword = isSamePassword;
validationsDictionary.isNumeric = isNumeric;
validationsDictionary.isMin1Value = isMin1Value;
validationsDictionary.isInRange = isInRange;
validationsDictionary.isCorrectPassword = isCorrectPassword;
validationsDictionary.isDate = isDate;
validationsDictionary.isRequired = isRequired;
validationsDictionary.isInTextLength = isInTextLength;
validationsDictionary.regEx = regEx;
validationsDictionary.deviceLimit = deviceLimit;
validationsDictionary.notOnlySpaces = notOnlySpaces;
validationsDictionary.notOnlySpacesIsRequired = notOnlySpacesIsRequired;
validationsDictionary.isNotCreationName = isNotCreationName;

export default validationsDictionary;
