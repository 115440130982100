export default {
  errors: {
    title: {
      honoa: 'HONOA Mostrar estado puerta:',
    },
    402: 'Pago requerido',
    1000: 'Ha fallado el inicio de sesión',
    name: 'Este nombre no esta disponible',
    generic: 'Identificador único ya esta utilizado',
    selectTypeFirst: 'Seleccione un tipo primero',
    unexpectedError: 'Ha ocurrido un error',
    uniqueIdentity: 'Este contenido ya existe',
    10: 'Iniciando la sección de configuración de CloudAssistant API ',
    11: 'Configuración completa. La API esta lista para atender llamadas entrantes',
    12: 'Configura la versión de configuración de la API',
    13: 'Configuración general de API',
    14: 'Configuración de SMTP',
    15: 'Configurando las políticas de Cookies',
    16: 'Establecer entorno de compatibilidad:',
    17: 'Configurando db Context',
    18: 'Servicios de autenticación',
    19: 'Agregar documentación de swagger',
    100: 'Usuario conectado',
    101: 'Usuario desconectado',
    102: 'Cambio de contraseña exitoso',
    103: 'Proceso de recuperación de contraseña completado',
    104: 'Proceso de restablecimiento de contraseña completado',
    105: 'Enviando email a:',
    106: 'Usuario inválido',
    107: 'usuario no actualizado',
    108: 'Invitación caducada',
    109: 'Invitación no eliminada',
    110: 'Usuario no confirmado y actualizado en IS4',
    111: 'El usuario no se ha registrado correctamente',
    112: 'No coincide la identificación de usuario o cliente',
    113: 'El ID del cliente no existe',
    114: 'Invitación no enviada',
    115: 'La invitación ha sido borrada',
    116: 'Invitación de reversión completada',
    117: 'Atributos del cliente no creados correctamente',
    118: 'No hay suficientes permisos para mostrar estos elementos',
    119: 'No hay suficientes permisos para mostrar o actualizar estos elementos',
    120: 'No hay suficientes permisos para crear estos elementos',
    121: 'No hay suficientes permisos para eliminar estos elementos',
    122: 'Usuario no eliminado en identidad',
    123: 'Usuario no encontrado',
    124: 'Conflicto al eliminar usuario en identity',
    125: 'Fallo de concurrencia',
    126: 'Error predeterminado',
    127: 'El correo electrónico ya existe',
    128: 'El nombre del rol ya existe',
    129: 'El nombre de usuario ya existe',
    130: 'Correo electrónico no válido',
    131: 'Nombre de rol no válido',
    132: 'Token inválido',
    133: 'Nombre de usuario no válido',
    134: 'Inicio de sesión ya asociado',
    135: 'La contraseña requiere minúsculas',
    136: 'La contraseña requiere valores no alfanuméricos',
    137: 'La contraseña requiere caracteres únicos',
    138: 'La contraseña requiere superior',
    139: 'Contraseña demasiado corta',
    140: 'Error al canjear el código de recuperación',
    141: 'El usuario ya contiene una contraseña',
    142: 'El usuario ya contiene un rol',
    143: 'Usuario no contiene un rol',
    144: 'Bloqueo de usuario no habilitado',
    145: 'Error de autenticación, ID de cliente no válido o oculto',
    146: 'Solicitud no válida',
    147: 'El correo electrónico o la contraseña no son correctos',
    148: 'Alcance no válido',
    149: 'Este cliente no está autorizado a utilizar el tipo de petición solicitada',
    150: 'Tipo de concesión no admitido',
    151: 'Usuario no eliminado en identity',
    152: 'Conflicto al eliminar usuario en identity',
    153: 'Suscripciones no actualizadas',
    154: 'Las licencias se han actualizado',
    155: 'Error al obtener licencias',
    156: 'El cliente tiene grupos universales o instalaciones con código de instalador',
    157: 'Error desconocido',
    158: 'No hay suficientes permisos',
    159: 'El correo electrónico del usuario ya existe',
    160: 'No hay suficientes licencias',
    161: 'Roles de usuario no válidos',
    162: 'Rol de usuario no actualizado',
    163: 'Usuario no inscrito o sin licencia',
    164: 'Roles de usuario o cliente no válidos',
    165: 'El administrador no se puede actualizar',
    166: 'Código de instalador no actualizado',
    167: 'Familias de productos no actualizadas',
    168: 'Productos no actualizados',
    169: 'No se encontró ningún elemento con este id',
    170: 'Actualizar Token',
    171: 'No hay suficientes licencias',
    172: 'Dispositivo no encontrado',
    200: 'Obtener la lista de todos los usuarios',
    300: 'Argumentos incorrectos o Número de elementos excedidos para una sola solicitud',
    301: 'Actualización completada con éxito',
    302: 'Error de validación',
    400: 'Tenant guardado con éxito',
    401: '¡Faltan algunos datos del Tenant! Guardado sin éxito',
    404: 'Dispositivo fuera de línea o fallo de la conexión.',
    9000: 'Error de inicio de sesión',
    9001: 'Error al restablecer contraseña',
    9002: 'Ha fallado la recuperación de contraseña',
    9003: 'Error al cambiar la contraseña',
    9004: 'Contraseña no coincide',
    9005: 'La contraseña requiere al menos de un dígito',
    9006: 'Usuario desconocido',
    90000: 'Se ha solicitado una prueba de bucle invertido:',
    90001: 'Se ha solicitado la excepción:',
    90002: 'Se ha solicitado el código de estado de retorno:',
    90100: 'Excepción SQL no controlada',
    90101: 'Fallo en la creación',
    90102: 'El nombre introducido ya existe, debe cambiarlo',
    90103: 'No encontrado',
    90104: 'Actualización fallida',
    90105: 'Error al eliminar',
    90106: 'Este contenido ya existe',
    90107: 'El contenido no puede ser nulo',
    90108: 'Error al importar',
    90109: 'Error de exportación',
    90110: 'No se puede actualizar el código de instalador en instalaciones con mandos asignados',
    90111: 'La ID o URL de la instalación no es válida',
    90112: 'Grupo de entidades de instalación no creado',
    90113: 'El grupo de instalaciones debe tener nombre',
    90114: 'El nombre del grupo de entidades de la instalación ya existe para esta instalación',
    90115: 'Grupo de entidades de instalaciones no actualizado',
    90116: 'Grupo de entidades de instalaciones no eliminado',
    90117: 'El contacto de la entidad no puede ser nulo',
    90118: 'Entidad de la instalación no actualizada',
    90119: 'La descripción o el código de la entidad de la instalación ya existe para este grupo',
    90120: 'No se puede eliminar el contacto con la posición 0',
    90121: 'Contacto no eliminado',
    90122: 'Contacto no creado',
    90123: 'La entidad ya tiene 5 contactos',
    90124: 'Contacto de entidad no actualizado',
    90125: 'GroupId no existe para esta instalación',
    90126: 'FacilityId no válido',
    90127: 'Contacto de entidad de instalación no válido',
    90128:
      'El código del instalador debe ser el mismo para todas las instalaciones del grupo universal',
    90129: 'El código de instalador debe ser el mismo para la instalación y el grupo universal',
    91000: 'VDP mac o id no encontrado',
    91001: 'Mac no válido',
    91002: 'Extensión de correo electrónico no encontrada',
    91003: 'Error al actualizar el nombre del vdp',
    91004: 'Error al crear la relación de contacto vdp',
    91005: 'Error al crear contacto',
    91006: 'Error al eliminar contactos',
    91007: 'Error al actualizar el nombre del contacto',
    91008: 'El nombre del cliente ya existe',
    91009: 'Código de piso o puerta no válido',
    91010: 'Código no introducido',
    91011: 'Número de canales corrupto',
    91012: 'No se puede eliminar la última relación de retransmisión',
    91013: 'No se puede desactivar el relé porque tiene grupos asignados',
    91014: 'Rango de códigos no válido',
    91015: 'Los códigos deben ser del mismo tipo',
    91016: 'No hay suficientes puestos en el grupo',
    91017: 'El código ya existe en la instalación',
    91018: 'El código ya existe en universales',
    91019: 'El código es una sustitución de un universal eliminado',
    91020: 'Este equipo de instalación no admite horarios',
    91021: 'Nada que copiar',
    91022: 'Nada que mover',
    91023: 'Producto no válido',
    91024: 'Horario no encontrado',
    91025: 'No se puede eliminar el horario con la instalación asignada',
    91026: 'El grupo solicitado no es universal',
    91027: 'El equipo contiene parámetros inválidos',
    91028: 'No se puede eliminar el grupo universal con instalaciones asignadas',
    91029: 'Número de serie o clave de activación no registrada',
    91030: 'El número de serie o clave de activación no puede ser nulo',
    91031: 'El código del cliente no es correcto',
    91032: 'El código de instalador no es correcto',
    91033: 'La contraseña no es correcta.',
    91034: 'Archivo corrupto',
    91065: 'enlace ya asignado',
    91066: 'Se produjo un error al desactivar el enlace',
    91067: 'Clave de activación incorrecta',
    91068: 'Solicitud API fallida',
    91069: 'Producto no habilitado',
    91070: 'Producto no encontrado',
    91071: 'No se seleccionaron relés. Use el botón de configuración',
    91072: 'El nombre del relé ya existe',
    91073: 'Número máximo de instalaciones alcanzadas',
    91074: 'Esta clave de activación está en uso por otro usuario.',
    91100: 'Dispositivo desconectado',
    91101: 'Gemelos de dispositivo de lectura de excepciones no manejadas',
    91102: 'Fallo al intentar leer la memoria',
    91103: 'Fallo al intentar grabar la memoria',
    91104: 'No coincide la memoria de escritura',
    operationNotCompleted: 'No se ha podido completar la operación',
    91102: 'Leer excepción de memoria',
    91103: 'Escribir excepción de memoria',
    91104: 'No coincide la memoria de escritura',
    91105: 'El dispositivo tiene código instalador',
    91106: 'El dispositivo no tiene código instalador',
    91302: 'La información de la SIM no está disponible',
    91303: 'El contrato de la SIM no está disponible',
    91307: 'No se puede obtener el número de serie',
    91308: 'Clave de activación cero o vacía',
    91309: 'Clave de activación nula, vacía o no válida',
    91201: 'El usuario ya existe. Todos los datos serán heredados',
    91452: 'El dispositivo no admite calendarios con festivos',
    91527: 'No es posible eliminar instalación con grupo universal asignado.',
    91528: 'No es posible eliminar equipo con grupo asignado.',
    91529: 'No es posible eliminar grupo con emisores.',
    91540: 'No puede desasignar el dispositivo, ya que el grupo contiene usuarios de HONOA',
    91203: 'El usuario ya existe en esta instalación',
    universalGroupsAssigned: 'UG asignados: ',
    groupsAssigned: 'Grupos asignados: ',
    redirectWarning: 'Ha sido redirigido a la página principal.',
    noContent: 'No hay contenido disponible para este listado',
    communicationError: 'Error de comunicación intentelo más tarde',
    91530: 'No se puede desasignar este equipo porque el equipo restante no admite emisores',
    91531: 'La instalación no se puede reasignar porque usa grupos universales',
    91532: 'La instalación contiene equipos incompatibles',
    91534: 'Máximo de dispositivos Honoa alcanzado para esta instalación',
    91310: 'Este número de serie no está asignado a ningún usuario',
    91311: 'Error de comunicación con servicio de productos',
    91312: 'Error al generar licencia',
    91400: 'Error al desactivar alarma',
    91450: 'Los siguientes horarios no tienen un rango válido: ',
    91451: 'Debe seleccionarse un día festivo como mínimo',
    91452: 'Un dispositivo en uso no admite calendarios con fiestas',
    91203: 'El usuario ya existe en esta instalación',
    91537: 'No se puede eliminar un grupo con usuarios Honoa',
    91453: 'El nombre del horario ya existe',
    91536: 'El nombre de la instalación ya existe',
    91800: 'Formato número teléfono no soportado',
    91801: 'Formato url web no soportado',
    needsMinOnRelay: 'Debe haber mínimo un relé assignado',
    91802: 'Este dispositivo no soporta emisores',
    91803: 'Este grupo no soporta emisores',
    nameRequired: 'No se ha podido guardar. Nombres requeridos en familias y productos.',
    deletedUser: 'Usuario eliminado',
    91804: 'El nombre de uno o más horarios asignados a esta instalación es demasiado largo',
    91538: 'Error descargando PDF',
    timeout: 'Tiempo de espera superado.',
    91853: 'La zona horaria no puede estar vacía.',
    91539: 'No se puede deshabilitar un equipo utilizado actualmente en instalaciones.',
    zeroCode: 'Cuidado! Se está trabajando con código instalador 0',
    92030: 'Su suscripción no es compatible con esa función.',
    92060: 'La fecha inicial no puede ser posterior a la fecha final',
    92061: 'La fecha inicial tiene que ser posterior a hoy',
    92031: 'Función no permitida sin finales de carrera (sensores/entradas)',
    91075: 'No hay suficientes reservas disponibles',
    304: 'Literal no es válido (espacios en blanco vacíos, al principio o al final, ...)',
  },
};
