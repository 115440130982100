import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import ContentContainer from '../ContentContainer';
import GenericForm from '../GenericForm';
import validationsDictionary from '../../core/old_common/validations';
import { validateForm } from '../../core/old_common/validations/service';

import './ChangePasswordPopupWithFile.css';
import apiPathFactory from '../../core/old_common/rooting/apiPathFactory';
import { apiFileCalls, downloadFile } from '../../core/helpers/filesApi';
import {
  checkAssistantPanelConnection,
  invokeSendCICC,
  parseCCIC,
  setAssistantPanelCheckSuccessFunction,
  setMessageHubFunction,
} from '../../core/old_common/utils/signalRService';

class ChangePasswordPopupWithFile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
      file: { name: '' },
      formErrors: [],
    };
  }

  handleChange = (e) => {
    if (!isNaN(e.target.value) && e.target.value.length <= 4) {
      this.setState({ [e.target.id]: e.target.value });
    }
  };

  readICCC = (connId, message) => {
    const { closePopup, facilityId, equipmentId } = this.props;
    const ICCC = parseCCIC(message);
    const endpoint = apiPathFactory.a5kPostChangePassword(
      facilityId,
      equipmentId,
      this.state.oldPassword,
      this.state.newPassword,
      ICCC.ic,
      ICCC.cc
    );
    const callBack = (isError, response) => {
      if (!isError) {
        downloadFile(response, 'Access5KCodesM.JCMbin');
        closePopup();
      }
    };
    apiFileCalls({
      path: endpoint,
      method: 'POST',
      callBack,
      attachFiles: [{ file: this.state.file, fieldName: 'file' }],
    });
  };

  handleSubmit = (e) => {
    e?.preventDefault();
    const formErrors = validateForm(this.getFields());
    this.setState({ formErrors: formErrors.errors });
    if (!formErrors.hasError) {
      setMessageHubFunction(this.readICCC);
      setAssistantPanelCheckSuccessFunction(invokeSendCICC);
      checkAssistantPanelConnection();
    }
  };

  handleFileChange = (e) => {
    this.setState({ [e.target.id]: e.target.files[0] });
  };

  getFields = () => {
    const { t } = this.props;
    const fields = [
      [
        {
          type: 'password',
          placeHolder: `${t('changePassword.currentPassword')} *`,
          value: this.state.oldPassword,
          maxlength: 4,
          id: 'oldPassword',
          onChange: this.handleChange,
          validation: [
            () => validationsDictionary.isNumeric(this.state.oldPassword),
            () => validationsDictionary.isRequired(this.state.oldPassword),
          ],
        },
      ],
      [
        {
          type: 'password',
          placeHolder: `${t('changePassword.newPassword')} *`,
          value: this.state.newPassword,
          id: 'newPassword',
          onChange: this.handleChange,
          maxlength: 4,
          validation: [
            () => validationsDictionary.isNumeric(this.state.newPassword),
            () => validationsDictionary.isRequired(this.state.newPassword),
          ],
        },
      ],
      [
        {
          type: 'password',
          placeHolder: `${t('changePassword.confirmPassword')} *`,
          value: this.state.confirmPassword,
          id: 'confirmPassword',
          onChange: this.handleChange,
          maxlength: 4,
          validation: [
            () =>
              validationsDictionary.isSameValue(this.state.newPassword, this.state.confirmPassword),
            () => validationsDictionary.isNumeric(this.state.confirmPassword),
            () => validationsDictionary.isRequired(this.state.confirmPassword),
          ],
        },
      ],
      [
        {
          type: 'file',
          placeHolder: t('changePassword.chooseFile'),
          id: 'file',
          className: 'change-password-file',
          value: this.state.file.name,
          onChange: this.handleFileChange,
        },
      ],
    ];
    return fields;
  };

  getCanAccept = () =>
    this.state.oldPassword &&
    this.state.newPassword &&
    this.state.confirmPassword &&
    this.state.file.name;

  render() {
    const { tenants, closePopup, t } = this.props;
    return (
      <div>
        <ContentContainer title={t('changePassword.newPasswordPopupTitle')}>
          <GenericForm
            tenants={tenants}
            fields={this.getFields()}
            handleSubmit={this.handleSubmit}
            handleCancel={closePopup}
            isCancelVisible
            isSubmitDisabled={!this.getCanAccept()}
            formErrors={this.state.formErrors}
          />
        </ContentContainer>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  tenants: state.tenants,
});

const ChangePasswordPopupWithFileWithTranslation = withTranslation()(ChangePasswordPopupWithFile);

export default connect(mapStateToProps, {})(ChangePasswordPopupWithFileWithTranslation);
