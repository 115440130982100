export default {
  breadcrumb: {
    dashboard: 'Dashboard',
    Facilities: 'Installations',
    FacilityEquipments: 'Équipements',
    Groups: 'Groupes',
    Equipments: 'Équipements',
    AddUniversalGroup: 'Ajouter un groupe universel',
    Configuration: 'Configuration',
    Enroll: "Processus d'inscription",
    administration: 'Administration',
    'user-config': 'Mon compte',
    UniversalGroups: 'Groupes universels',
    Users: 'Utilisateurs',
    Help: 'Aidez-moi',
    Parameters: 'Paramètres',
    Relays: 'Relais',
    Fobs: 'Émetteurs',
    Actions: 'Actions',
    Events: 'Événements',
    'Remote comm': 'Communications à distance',
    Facility: 'Nouvelle installation',
    Equipment: 'Nouvel équipement',
    FacilityGroup: 'Nouveau groupe',
    Neighbours: 'Liste des voisins',
    UniversalGroup: 'Nouveau groupe universel',
    Schedule: 'Nouveau programme',
    customization: 'Personnaliser le Web',
    Invitations: 'Des invitations',
    Companies: 'Entreprises',
    UpdateUser: 'Mon compte',
    preferences: 'Préférences',
    'assistant-panel': 'Assistant Panel',
    Holidays: 'Jours fériés',
    Information: 'Information',
    recycle: 'Poubelle',
  },
};
