import PathEntity from './PathEntity.js';

class Drivers extends PathEntity {
  constructor() {
    super('drivers');
  }

  getMemoryConfig = (id, facilityId) =>
    this.manufacturePath(
      `facilities/${facilityId}/equipments/${id}/${this.entityName}/getmemoryconfig`
    );

  getPingDevice = (id, facilityId, connectionId, productType) => {
    let finalEndpoint = this.manufacturePath(
      `facilities/${facilityId}/equipments/${id}/${this.entityName}/sendpingtodevice`
    );
    finalEndpoint = this.addParameterToEndpoint(finalEndpoint, 'subscriberId', connectionId);
    finalEndpoint = this.addParameterToEndpoint(finalEndpoint, 'deviceType', productType);
    return finalEndpoint;
  };

  getMemoryCodes = (id, facilityId) =>
    this.manufacturePath(
      `facilities/${facilityId}/equipments/${id}/${this.entityName}/getmemorycodes`
    );

  memoryCompare = (id, facilityId, binaryData) =>
    this.manufacturePath(
      `facilities/${facilityId}/equipments/${id}/${this.entityName}/memorycompare`
    );

  getEvents = (id, facilityId) =>
    this.manufacturePath(`facilities/${facilityId}/equipments/${id}/${this.entityName}/getevents`);

  postSendMemoryCodes = (id, facilityId, ic, cc, signalRConnId) => {
    let endpoint = this.manufacturePath(
      `facilities/${facilityId}/equipments/${id}/${this.entityName}/sendmemorycodes`
    );
    endpoint = this.addParameterToEndpoint(endpoint, 'cc', cc);
    endpoint = this.addParameterToEndpoint(endpoint, 'ci', ic);
    endpoint = this.addParameterToEndpoint(endpoint, 'subscriberId', signalRConnId);
    return endpoint;
  };

  getPaths = () => ({
    driversGetMemoryConfig: this.getMemoryConfig,
    driversGetMemoryCodes: this.getMemoryCodes,
    driversPostCompareMemory: this.memoryCompare,
    driversGetEvents: this.getEvents,
    driversPostSendMemoryCodes: this.postSendMemoryCodes,
    driversGetPingDevice: this.getPingDevice,
  });
}

export default new Drivers().getPaths();
