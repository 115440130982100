// import { createAction } from 'redux-actions';

const ERRORS_GET = 'ERRORS_GET';
const RESET_ERRORS = 'RESET_ERRORS';

export default {
  ERRORS_GET,
  RESET_ERRORS,
};

export const getErrors = () => ({ type: ERRORS_GET });
export const resetErrors = () => ({ type: RESET_ERRORS });
