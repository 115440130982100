import React, { useCallback, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getStatistics } from '../../../../modules/statistics/actions';
import { getCookieAccessToken } from '../../../../core/old_common/utils/cookiesManager';
import { baseUnits } from './config';
import GraphStatistics from './BarChart';
import 'hammerjs';

const Statistics = () => {
  const [actionSelected, setActionSelected] = useState({ value: 'sum_r1', label: 'r1Activations' });
  const dispatch = useDispatch();
  const accessToken = getCookieAccessToken();
  const statistics = useSelector((state) => state.statistics);
  const getStatisticsAPI = useCallback(() => dispatch(getStatistics()), [dispatch]);

  useEffect(() => {
    if (accessToken) {
      getStatisticsAPI();
    }
  }, [accessToken]);

  return (
    <GraphStatistics
      actionSelected={actionSelected}
      onSetAction={(value) => setActionSelected(value)}
      baseUnits={baseUnits}
      data={statistics.data}
    />
  );
};

export default Statistics;
