import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getDecodedUrl } from '../../core/old_common/utils/getUrlParams';
import { getFacility } from '../../modules/old_to_refact/actions/instalations';
import {
  getInstalationGroup,
  getInstalationGroupsFobsCounts,
} from '../../modules/old_to_refact/actions/instalationGroups';
import { postFOB, postSequentialFOB } from '../../modules/group/fobs/actions';
import EnrollCode from '../EnrollCode/EnrollCode';
import apiPathFactory from '../../core/old_common/rooting/apiPathFactory';

class FacilityGroupEnrollCode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      facilityId: '',
      facilityGroupId: '',
      isCountersCalled: false,
    };
  }

  componentDidMount = () => {
    const { match, facility, facilityGroup, getFacility, getInstalationGroup } = this.props;
    if (!facility) {
      getFacility(getDecodedUrl(match.params.instalationId));
    } else if (!facilityGroup) {
      getInstalationGroup(facility.id, match.params.instalationGroupId);
    } else {
      this.getFobsCounters();
    }
  };

  componentDidUpdate() {
    const { facility, facilityGroup, match, getInstalationGroup } = this.props;
    if (!facilityGroup && facility) {
      getInstalationGroup(facility.id, getDecodedUrl(match.params.instalationGroupId));
    }
    if (!this.state.isCountersCalled) {
      this.getFobsCounters();
    }
  }

  getFobsCounters = () => {
    const { getInstalationGroupsFobsCounts } = this.props;
    const instalationId = this.getSelectedInstalationId();
    const instalationGroupId = this.getSelectedInstalationGroupId();
    if (instalationId && instalationGroupId) {
      if (!this.state.isCountersCalled) {
        this.setState({ isCountersCalled: true });
      }
      getInstalationGroupsFobsCounts(instalationId, instalationGroupId);
    }
  };

  getSelectedInstalation = () => {
    const { instalations, facility, match } = this.props;
    if (facility) {
      return facility;
    }
    if (instalations) {
      return instalations.find(
        (instalation) => instalation.name === getDecodedUrl(match.params.instalationId)
      );
    }
    return undefined;
  };

  getSelectedInstalationGroups = () => {
    const { facilityGroups, facilityGroup, match } = this.props;
    if (facilityGroup) {
      return facilityGroup;
    }
    if (facilityGroups) {
      return facilityGroups.find(
        (instalationGroup) =>
          instalationGroup.name === getDecodedUrl(match.params.instalationGroupId)
      );
    }
    return undefined;
  };

  getSelectedInstalationId = () => {
    const selectedInstalation = this.getSelectedInstalation();
    return selectedInstalation ? selectedInstalation.id : '';
  };

  getSelectedInstalationGroupId = () => {
    const selectedInstalationGroup = this.getSelectedInstalationGroups();
    return selectedInstalationGroup ? selectedInstalationGroup.id : '';
  };

  getFobsCounts = () => {
    const { fobsCount } = this.props;
    if (fobsCount && fobsCount.length) {
      const countersObject = fobsCount.find((counter) => counter.schema === 'Fobs Availability');
      return {
        ...countersObject.counters[0],
      };
    }
    return {
      free: 0,
      busy: 0,
      reserved: 0,
    };
  };

  render() {
    const { facility, facilityGroup, postFOB, postSequentialFOB } = this.props;
    const selectedInstalation = this.getSelectedInstalation();

    const ids =
      facilityGroup && facility
        ? { facilityGroupId: facilityGroup.id, facilityId: facility.id }
        : {};
    return (
      <EnrollCode
        needInstallerCode={
          selectedInstalation
            ? selectedInstalation.installerCode
            : facility
            ? facility.installerCode
            : false
        }
        isUG={false}
        familyType={1}
        conters={this.getFobsCounts()}
        tabsProps={{
          postFOB: (fobData) => postFOB({ ...fobData, ...ids }),
          postSequentialFOB: (fobData, isReservation) =>
            postSequentialFOB({
              data: { ...fobData, isUniversal: false },
              isReservation,
              ...ids,
            }),
          fobsCheckCodesEndpoint: (initialCode, count) =>
            apiPathFactory.fobsGetCheckCodes(
              ids.facilityId,
              ids.facilityGroupId,
              initialCode,
              count
            ),
          fobsCheckCodesLongEndpoint: (initialCode, count) =>
            apiPathFactory.fobsGetCheckCodesLong(
              ids.facilityId,
              ids.facilityGroupId,
              initialCode,
              count
            ),
          fobsCheckContentEndpoint: (elementId, value) =>
            apiPathFactory.fobsGetCheckContent(
              ids.facilityId,
              ids.facilityGroupId,
              elementId,
              value
            ),
          getFobCounters: this.getFobsCounters,
        }}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  fobsCount: state.instalationGroups.fobsCount,
  facilityGroup: state.instalationGroups.facilityGroup,
  facilityGroups: state.instalationGroups.facilityGroups,
  facility: state.instalations.facility,
  instalations: state.instalations.instalations,
});

const FacilityGroupEnrollCodeWithRouter = withRouter(FacilityGroupEnrollCode);

export default connect(mapStateToProps, {
  getFacility,
  getInstalationGroup,
  getInstalationGroupsFobsCounts,
  postFOB,
  postSequentialFOB,
})(FacilityGroupEnrollCodeWithRouter);
