export default {
  alarms: {
    deleteAlarmPopUp: "Voulez-vous vraiment marquer l'alarme comme vue?",
    deleteAlarmPopUpWithMove:
      'Déplacer un émetteur vers un autre groupe marquera les alarmes comme vues. Souhaitez-vous continuer?',
    deleteAlarmPopUpWithCloneUniversal: "La copie de l'émetteur universel partagera les alarmes.",
    deleteAlarmPopUpWithClone:
      " Lors de la copie d'un émetteur vers un autre groupe, la copie apparaîtra sans alarme.",
  },
};
