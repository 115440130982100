export default {
  signalRError: {
    11050: 'Unexpected response',
    11051: 'Wrong device',
    11052: 'Wrong coding',
    11053: 'Device is using installer code',
    11054: 'Device is not using installer code',
    11055: 'Timeout',
    11056: 'Assistant not ready',
    11057: 'Assistant is busy ',
    11058: 'An error has occurred',
    11059: 'Operation denied',
    11060: 'Wrong boot version',
    11061: 'Still in progress ',
    11062: 'Wrong counter',
    11063: 'Wrong serial number',
    11064: 'Error configuring device',
    11065: 'Unknown action',
    11066: 'Wrong device ',
    11067: 'Fatal error',
    11068: 'Bad crc',
    11069: 'Password mismatch',
    connectionTimeout: 'Check connection with assistant panel',
    dontMoveTag:
      'An error occurred. Repeat the operation without moving the device from the programming zone.',
  },
};
