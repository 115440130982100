import React, { Component } from 'react';
import { connect } from 'react-redux';

import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import PopUpConfirm from '../PopUpConfirm';

import {
  setMessageHubFunction,
  invokeSendFunction,
  invokeSendCICC,
  checkAssistantPanelConnection,
  setAssistantPanelCheckSuccessFunction,
} from '../../core/old_common/utils/signalRService';

class PopUpFormat extends Component {
  onICCCReceived = () => {
    setMessageHubFunction(this.newMessageHubFunction);
    this.formatMemorySignalR();
  };

  formatMemorySignalR = () => {
    const { product, facility } = this.props;
    invokeSendFunction(
      `${product.driverName}_formatmemory::${product.productType}::${facility.installerCode}`,
      90000
    );
  };

  newMessageHubFunction = (connId, id) => {
    const { t } = this.props;
    toast.success(t('apitoasters.success.formatSignalRMemory'));
  };

  handleAccept = () => {
    const { closePopup } = this.props;
    setMessageHubFunction(this.onICCCReceived);
    setAssistantPanelCheckSuccessFunction(invokeSendCICC);
    checkAssistantPanelConnection();
    closePopup();
  };

  render() {
    const { tenants, t, closePopup } = this.props;
    return (
      <PopUpConfirm
        text={t('facilitiesUserGroupsGrid.formatMemoryQuestion')}
        tenants={tenants}
        onAcceptFn={this.handleAccept}
        onCancelFn={closePopup}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  tenants: state.tenants,
});

const PopUpFormatTranslation = withTranslation()(PopUpFormat);

export default connect(mapStateToProps, {})(PopUpFormatTranslation);
