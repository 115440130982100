export default {
  relaysConfiguration: {
    tabRelays: 'Relais',
    tabSchedules: 'Des horaires',
    tabInfo: 'Information',
    time: "Temps d'activation relai {{count}}",
    delay: 'Temps de retard relai {{count}}',
    infoTime: 'La valeur doit être comprise entre 1 et 60 secondes',
    infoDelay: 'La valeur doit être comprise entre 0 et 10 secondes',
    infoCustomOpenDoorTime: 'La valeur doit être comprise entre 1 et 86400 secondes',
    relayNotassigned: 'Relais non affecté',
    goBack: 'Arrière',
    type: 'Type de relais',
    keepDoorOpenSchedule: "Horaire d'activation maintenue",
    customOpenDoorTimeSchedule: " Horaire d'activation personnalisé",
    customOpenDoorTime: "Temps d'ouverture de porte personnalisé",
    Relay: 'Relais',
  },
};
