import 'bootstrap/dist/js/bootstrap.bundle.min';
import '@fortawesome/fontawesome-free/css/all.css';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Root from './old_containers/Root';
import './core/i18n';
import * as serviceWorker from './serviceWorker';

const createFlatFunctionIfNoExists = () => {
  if (!Array.prototype.flat) {
    // eslint-disable-next-line no-extend-native
    Object.defineProperty(Array.prototype, 'flat', {
      value(depth = 1) {
        return this.reduce(
          (flat, toFlatten) =>
            flat.concat(
              Array.isArray(toFlatten) && depth > 1 ? toFlatten.flat(depth - 1) : toFlatten
            ),
          []
        );
      },
    });
  }
};

createFlatFunctionIfNoExists();

ReactDOM.render(<Root />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
