export default {
  fobs: {
    code: 'Codi',
    group: 'Grup',
    type: 'Tipus',
    name: 'Nom',
    surname: 'Cognom',
    idCard: 'DNI',
    place: 'Plaça',
    substitution: 'Substituir',
    clone: 'Emissor sospitós',
    battery: 'Bateria baixa',
  },
};
