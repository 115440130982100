import i18next from 'i18next';
import PopUpA5KProxy from '../../PopUpA5KProxy';
import PopUpBitMap from '../../PopUpBitMap';
import PopUpConfigActiveEvents from '../../PopUpConfigActiveEvents';

const parametersPopUpsDictionary = [];
parametersPopUpsDictionary.A5kProxy = {
  title: i18next.t('parameters.editproxy'),
  component: PopUpA5KProxy,
};
parametersPopUpsDictionary.bitmap = {
  title: i18next.t('parameters.bitmap'),
  component: PopUpBitMap,
};
parametersPopUpsDictionary.active_events_enum = {
  title: i18next.t('parameters.bitmap'),
  component: PopUpConfigActiveEvents,
};

export default parametersPopUpsDictionary;
