import i18next from 'i18next';
import moment from 'moment';

const isSameValue = (firstValue, secondValue) => {
  if (firstValue !== secondValue) {
    return {
      error: true,
      errorMessage: i18next.t('formValidation.notSameValue'),
    };
  }
  return {
    error: false,
  };
};

const isSamePassword = (firstValue, secondValue) => {
  if (firstValue !== secondValue) {
    return {
      error: true,
      errorMessage: i18next.t('formValidation.notSamePassword'),
    };
  }
  return {
    error: false,
  };
};

const isNumeric = (value) => {
  if (isNaN(value)) {
    return {
      error: true,
      errorMessage: i18next.t('formValidation.numericField'),
    };
  }
  return {
    error: false,
  };
};

const notOnlySpaces = (value) => {
  if (/^\s+$/.test(value) && value.length > 0) {
    return {
      error: true,
      errorMessage: i18next.t('formValidation.onlySpaces'),
    };
  }
  return {
    error: false,
  };
};

const notOnlySpacesIsRequired = (value) => {
  if ((/^\s+$/.test(value) && value.length > 0) || !value) {
    return {
      error: true,
      errorMessage: `${i18next.t('formValidation.onlySpaces')}. ${i18next.t(
        'formValidation.isRequired'
      )}`,
    };
  }
  return {
    error: false,
  };
};

const isMin1Value = (value) => {
  if (value !== '' && value !== '0' && value !== 0) {
    return {
      error: false,
    };
  }
  return {
    error: true,
    errorMessage: i18next.t('formValidation.minNumber'),
  };
};

const isCorrectPassword = (value) => {
  const expr = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@$./_\-!%*#(){}=,;:?&])(?=.{8,})/;
  //
  if (!expr.test(value)) {
    return {
      error: true,
      errorMessage: i18next.t('formValidation.passwordFormat'),
    };
  }
  return {
    error: false,
  };
};

const isDate = (value) => {
  if (!moment(value, 'YYYY-MM-DD[T]HH:mm:ss', true).isValid()) {
    return {
      error: true,
      errorMessage: i18next.t('formValidation.isNotValidDate'),
    };
  }
  return {
    error: false,
  };
};

const isRequired = (value) => {
  if (!value) {
    return {
      error: true,
      errorMessage: i18next.t('formValidation.isRequired'),
    };
  }
  return {
    error: false,
  };
};

const isInRange = (value, minNumber, maxNumber) => {
  try {
    const number = parseInt(value);
    if (number < minNumber || number > maxNumber) {
      return {
        error: true,
        errorMessage: i18next.t('formValidation.outOfRange'),
      };
    }
    return {
      error: false,
    };
  } catch {
    return {
      error: true,
      errorMessage: i18next.t('formValidation.numericField'),
    };
  }
};

const isInTextLength = (value, maxLength) => {
  const valueLength = `${value}`.length;
  if (valueLength > maxLength) {
    return {
      error: true,
      errorMessage: `${i18next.t('formValidation.maxLength')} ${maxLength}`,
    };
  }
  return {
    error: false,
  };
};

const regEx = (value, regExParams) => {
  const params = new RegExp(regExParams);
  if (params.test(value)) {
    return { error: false };
  }
  return { error: true, errorMessage: `${i18next.t('formValidation.regEx')} ${regExParams}` };
};

const deviceLimit = (value, minNumber, maxNumber) => {
  try {
    const number = parseInt(value);

    if (number < minNumber || number > maxNumber) {
      return {
        error: true,
        errorMessage: i18next.t('formValidation.deviceLimit'),
      };
    }
    return {
      error: false,
    };
  } catch {
    return {
      error: true,
      errorMessage: i18next.t('formValidation.numericField'),
    };
  }
};

const isNotCreationName = (name, reservedName) => {
  if (name.toUpperCase() === reservedName) {
    return {
      error: true,
      errorMessage: `${name} ${i18next.t('formValidation.isReservedWord')}`,
    };
  }
  return {
    error: false,
  };
};

export {
  isSameValue,
  isNumeric,
  isMin1Value,
  isCorrectPassword,
  isDate,
  isRequired,
  isInRange,
  isInTextLength,
  regEx,
  deviceLimit,
  isSamePassword,
  notOnlySpaces,
  notOnlySpacesIsRequired,
  isNotCreationName,
};
