export default {
  parameters: {
    title: 'Configuració de paràmetres proxy Access 5k',
    pinCode: 'Codi Pin',
    apn: 'APN',
    apnUser: 'Usuari APN',
    apnPassword: 'Contrasenya APN',
    serverURL: 'Url del servidor',
    editproxy: 'Edita A5K Proxy',
    titleRelays: 'Programació de relés',
    errorRelaysPopUp: 'Error: Nombre màxim de relés assolit',
    CONFIGRELAYSNEW1_ICON: 'Editar relés 1',
    CONFIGRELAYSNEW2_ICON: 'Editar relés 2',
    CONFIGRELAYSNEW3_ICON: 'Editar relés 3',
    CONFIGRELAYSNEW4_ICON: 'Editar relés 4',
    ACTIVEEVENTSTOSD_ICON: 'Editar esdeveniments actius',
    A5KPROXY_ICON: 'Editar A5K proxy',
  },
};