export default {
  adjustTimePopUp: {
    manualDateTimeAdjust: 'Manuelle Einstellung von Datum und Uhrzeit',
    actualHour: 'Aktuelle Uhrzeit:',
    minutesCompensation: 'Minutenausgleich von jetzt an',
    insertSdMemory:
      'Legen Sie eine SD-Karte ein und schalten Sie das Gerät genau zur nächsten Stunde ein',
    inputTime: 'Es zeigt die Zeit',
    save: 'Speichern',
  },
};
