const SET_SIGNALR_CONNECTION_DATA_FRONT = 'SET_SIGNALR_CONNECTION_DATA_FRONT';
const SET_SIGNALR_CONNECTION_FRONT = 'SET_SIGNALR_CONNECTION_FRONT';
const SET_SIGNALR_CONNECTION_STATUS_FRONT = 'SET_SIGNALR_CONNECTION_STATUS_FRONT';
const SET_SIGNALR_CALL_ERROR_FRONT = 'SET_SIGNALR_CALL_ERROR_FRONT';

export default {
  SET_SIGNALR_CONNECTION_DATA_FRONT,
  SET_SIGNALR_CONNECTION_FRONT,
  SET_SIGNALR_CONNECTION_STATUS_FRONT,
  SET_SIGNALR_CALL_ERROR_FRONT,
};

export const setSignalRConnectionData = (connectionData) => ({
  type: SET_SIGNALR_CONNECTION_DATA_FRONT,
  connectionData,
});

export const setSignalRConnection = (connection) => ({
  type: SET_SIGNALR_CONNECTION_FRONT,
  connection,
});

export const setSignalRConnectionStatus = (isConnected) => ({
  type: SET_SIGNALR_CONNECTION_STATUS_FRONT,
  isConnected,
});

export const setSignalRCallError = (errorFunction) => ({
  type: SET_SIGNALR_CALL_ERROR_FRONT,
  errorFunction,
});
