import { Modal } from '@jcm-technologies/uikit/dist/molecules/Modal';

export const ModalGeneric = ({ isOpen, onClose, title, xButton, maxWidth, children }) => {
  return (
    <Modal
      maxWidth={maxWidth}
      width='100%'
      isOpen={isOpen}
      id='modal-rgpd'
      {...(xButton ? { closeWithButton: true } : {})}
      onModalClose={onClose}
      title={title}
    >
      {children}
    </Modal>
  );
};
