import { call, fork, select, takeLatest } from 'redux-saga/effects';
import groupsActions, { GET_LIST_GROUP_FOB_HISTORY } from '../actions/groups';
import fetchEntitySaga from './utils/fetchEntitySaga';
import apiPathFactory from '../../../core/old_common/rooting/apiPathFactory';
import api from '../../../core/helpers/api';
import { GROUP_GET_LIST } from '../reducers/groups';
import { GROUP_DESCENDANT } from '../../state/visibleDescendants/constants';
import { getCookiePageSizeItems } from '../../../core/old_common/utils/cookiesManager';
import { GROUPS_LIST } from '../../state/pagination/constants';

export const getGroups = {
  base: (fieldId, searchText) => ({
    type: GROUP_GET_LIST.BASE,
    fieldId,
    searchText,
  }),
  request: (fieldId, searchText) => ({ type: GROUP_GET_LIST.REQUEST, fieldId, searchText }),
  success: (result) => ({ type: GROUP_GET_LIST.SUCCESS, result }),
  failure: (error) => ({ type: GROUP_GET_LIST.FAILURE, error }),
};

export const getGroupsFobHistory = {
  base: (searchText) => ({
    type: GET_LIST_GROUP_FOB_HISTORY.BASE,
    searchText,
  }),
  request: (searchText) => ({ type: GET_LIST_GROUP_FOB_HISTORY.REQUEST, searchText }),
  success: (result) => ({ type: GET_LIST_GROUP_FOB_HISTORY.SUCCESS, result }),
  failure: (error) => ({ type: GET_LIST_GROUP_FOB_HISTORY.FAILURE, error }),
};

// -----------------------------------------------------------------------------

export function* getGroupsSaga({ fieldId, searchText }) {
  try {
    const state = yield select();
    const selectedDescendants = state.visibleDescendants.selectedDescendants[GROUP_DESCENDANT];
    const searchQuery =
      state.visibleDescendants.searchQuery.replace(/\#/g, '%23').replace(/\&/g, '%26') ||
      searchText;

    const { backendParams } = state;
    const pageSize = getCookiePageSizeItems() || backendParams.pagesize;
    const url = apiPathFactory.getGroupList(pageSize, backendParams.pagenumber[GROUPS_LIST], {
      selectedDescendants,
      searchQuery,
      fieldId,
    });
    const response = yield call(() => fetchEntitySaga(getGroups, api.get, url));
    return response;
  } catch (error) {
    return error;
  }
}

export function* getGroupsFobHistorySaga({ searchText }) {
  try {
    const state = yield select();
    const selectedDescendants = state.visibleDescendants.selectedDescendants[GROUP_DESCENDANT];
    const searchQuery =
      state.visibleDescendants.searchQuery.replace(/\#/g, '%23').replace(/\&/g, '%26') ||
      searchText;

    const { backendParams } = state;
    const pageSize = getCookiePageSizeItems() || backendParams.pagesize;
    const url = apiPathFactory.getGroupsFobsHistory(
      pageSize,
      backendParams.pagenumber[GROUPS_LIST],
      {
        selectedDescendants,
        searchQuery,
      }
    );

    const response = yield call(() => fetchEntitySaga(getGroupsFobHistory, api.get, url));
    return response;
  } catch (error) {
    return error;
  }
}

// -----------------------------------------------------------------------------

function* watchGetGroups() {
  yield takeLatest(groupsActions.GET_GROUPS, getGroupsSaga);
}

function* watchGetGroupsFobHistory() {
  yield takeLatest(GET_LIST_GROUP_FOB_HISTORY.BASE, getGroupsFobHistorySaga);
}

// -----------------------------------------------------------------------------

export default function* watchers() {
  yield fork(watchGetGroups);
  yield fork(watchGetGroupsFobHistory);
}
