import { menus } from '../../core/services/menus';
import { getCookieUserRoles, saveCookies } from '../../core/old_common/utils/cookiesManager';

const isAuth = (route) => {
  const foundPage = menus.find((x) => x.navigateTo.toLowerCase() === route.toLowerCase());
  const userRules = getCookieUserRoles();

  if (foundPage) {
    let roles = { name: null, mask: null };
    let foundRole = '';
    if (userRules && userRules.name === null) {
      const url = window.location.href;
      const searchParams = new URLSearchParams(url);
      const maskRoleFromUrl = new URLSearchParams(window.location.search).get('userRolesMask');
      const nameRoleFromUrl = new URLSearchParams(window.location.search).get('userRolesName');
      roles = { name: nameRoleFromUrl, mask: maskRoleFromUrl };
      saveCookies({ userRoles: roles });
    }

    if (userRules) {
      const isRole = foundPage.roles.find((x) =>
        (x?.toLowerCase() === userRules.name) !== null
          ? userRules.name?.toLowerCase()
          : roles.name?.toLowerCase()
      );
      if (isRole) {
        foundRole = isRole;
      }
      if (!foundRole) {
        return false;
      }
    }
  }
  return true;
};

export { isAuth };
