import superagent from 'superagent';
import { getCookieAccessToken } from '../old_common/utils/cookiesManager';

const methods = ['get', 'post', 'put', 'patch', 'del'];

export default methods.reduce(
  (api, method) => ({
    ...api,
    [method]: (path, { params, data, avoidProxy = false, formData = [] } = {}) =>
      new Promise((resolve, reject) => {
        const request = superagent[method]((avoidProxy && path) || path);
        request.set('Access-Control-Allow-Origin', '*');
        if (!formData.length) {
          request.set('Content-Type', 'application/json');
        }
        const accessToken = getCookieAccessToken();
        if (accessToken) {
          request.set('Authorization', `Bearer ${accessToken}`);
        }
        if (params) {
          request.query(params);
        }
        if (data) {
          request.send(data);
        }

        formData.forEach((file) => request.attach(file.fieldName, file.file));

        request.on('error', (res) => {
          if (typeof res.status === 'undefined') {
            // window.location.replace('/');
          }
        });
        request.end((err, { body, ...data } = {}) => {
          if (err) {
            return reject(err || body);
          }
          if (data.status === 204) {
            return resolve([]);
          }
          return resolve(body);
        });
      }),
  }),
  {}
);
