import React from 'react';
import { withTranslation } from 'react-i18next';
import { Flex } from '@jcm-technologies/uikit/dist/atoms/Layout';
import { Text } from '@jcm-technologies/uikit/dist/atoms/Typography';
import { Button } from '@jcm-technologies/uikit/dist/atoms/Button';
import { getContrastColor } from '../../core/helpers/colorManager';

const PopupConfirm = ({ text, onAcceptFn, onCancelFn, tenants, t, children }) => (
  <Flex flexDirection='column' alignItems='center' justifyContent='space-between' marginY={3}>
    <Text>{text}</Text>
    {children}

    <Flex alignItems='center' justifyContent='center' marginTop={5}>
      <Button
        id='accept-button'
        sizeButton='small'
        backgroundColor={tenants?.color1}
        action={() => onAcceptFn()}
        marginRight={2}
        color={getContrastColor(tenants?.color1)}
      >
        {t('popupConfirm.accept')}
      </Button>
      {onCancelFn && (
        <Button
          backgroundColor='blackLight'
          id='cancel-button'
          sizeButton='small'
          action={() => onCancelFn()}
        >
          {t('popupConfirm.cancel')}
        </Button>
      )}
    </Flex>
  </Flex>
);

const popupWithTranslation = withTranslation()(PopupConfirm);

export default popupWithTranslation;
