import createFetchTypes from '../../../core/typeActions/types';

//const with saga
export const PUT_GROUP_USERS_EXCEL = createFetchTypes('PUT_GROUP_USERS_EXCEL');
export const POST_GROUP_USERS_COPY = createFetchTypes('POST_GROUP_USERS_COPY');
export const PUT_GROUP_USERS_MOVE = createFetchTypes('PUT_GROUP_USERS_MOVE');
export const GET_GROUP_USERS_AVAILABLE_FACILITIES = createFetchTypes(
  'GET_GROUP_USERS_AVAILABLE_FACILITIES'
);
export const GET_GROUP_USERS_AVAILABLE_GROUPS = createFetchTypes(
  'GET_GROUP_USERS_AVAILABLE_GROUPS'
);
export const PUT_GROUP_USERS_LICENSE_UNLOCK = createFetchTypes('PUT_GROUP_USERS_LICENSE_UNLOCK');
export const PUT_GROUP_USERS_LICENSE_LOCK = createFetchTypes('PUT_GROUP_USERS_LICENSE_LOCK');
export const DELETE_GROUP_USERS_FOB = createFetchTypes('DELETE_GROUP_USERS_FOB');
export const POST_GROUP_USERS_FOBS = createFetchTypes('POST_GROUP_USERS_FOBS');
export const GET_GROUP_USERS_LICENSES_LIST = createFetchTypes('GET_GROUP_USERS_LICENSES_LIST');
export const POST_GROUP_USERS_UPDATE = createFetchTypes('POST_GROUP_USERS_UPDATE');
export const GET_GROUP_USERS_LIST_BY_USERID = createFetchTypes('GET_GROUP_USERS_LIST_BY_USERID');
export const GET_GROUP_USERS_FOBS_LIST = createFetchTypes('GET_GROUP_USERS_FOBS_LIST');
export const DELETE_GROUP_USERS_LIST = createFetchTypes('DELETE_GROUP_USERS_LIST');
export const DELETE_GROUP_USERS = createFetchTypes('DELETE_GROUP_USERS');
export const POST_GROUP_USERS = createFetchTypes('POST_GROUP_USERS');
export const GET_GROUP_USERS_COUNTER = createFetchTypes('GET_GROUP_USERS_COUNTER');
export const GET_GROUP_USERS_LIST = createFetchTypes('GET_GROUP_USERS_LIST');
export const POST_GROUP_USERS_RESEND_INVITATION_LICENCES = createFetchTypes(
  'POST_GROUP_USERS_RESEND_INVITATION_LICENCES'
);
export const PUT_GROUP_USERS_UNLOCK_USER = createFetchTypes('PUT_GROUP_USERS_UNLOCK_USER');
export const PUT_GROUP_USERS_LOCK_USER_LICENSE = createFetchTypes(
  'PUT_GROUP_USERS_LOCK_USER_LICENSE'
);
export const PUT_GROUP_USERS_LOCK_USER = createFetchTypes('PUT_GROUP_USERS_LOCK_USER');
//const actions without saga
export const RESET_GROUP_USERS = 'RESET_GROUP_USERS';
export const UPDATE_GROUP_USERS = 'UPDATE_GROUP_USERS';
export const RESET_GROUP_USERS_FOBS = 'RESET_GROUP_USERS_FOBS';
