import i18Next from 'i18next';

export const getECodeTranslated = (eCode, productName, equipmentName) => {
  if (eCode >= 1000000 && eCode <= 1048400) {
    if(equipmentName === 'HONOADOOR'){
      return i18Next.t('enrollCode.notAsigned');
    }else if (productName !== 'NO') {
      return i18Next.t('enrollCode.reserved');
    }
    return i18Next.t('enrollCode.notAsigned');
  }
  return eCode.toString();
};
