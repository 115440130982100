import { GridColumn } from '@progress/kendo-react-grid';
import React, { useState } from 'react';
import { Box, Flex } from '@jcm-technologies/uikit/dist/atoms/Layout';
import IconEdit from '@jcm-technologies/uikit/dist/atoms/Icons/IconEdit';
import IconTrash from '@jcm-technologies/uikit/dist/atoms/Icons/IconTrash';
import IconReply from '@jcm-technologies/uikit/dist/atoms/Icons/IconReply';
import { Button } from '@jcm-technologies/uikit/dist/atoms/Button';
import { useTranslation } from 'react-i18next';
import { Text } from '@jcm-technologies/uikit/dist/atoms/Typography';
import { IconTooltip } from '@jcm-technologies/uikit/dist/molecules/IconTooltip';
import Table from '../../../common/Table';
import { CustomFilter, SelectCell } from '../../../common/Table/renderers';

export const TableLicenses = ({
  data,
  handlerSendInvitationLicense,
  setIsOpenedDeleteModal,
  setIdToDelete,
  setIsOpenedEditModal,
  setDataToEdit,
  customerRoles = [],
  canEditRole,
  displayToastError,
  setIsOpenedModal,
  setActionToSave,
  setDataToSave,
  setDataToGet,
  setActionToGet,
}) => {
  const [dataLoaded, setDataLoaded] = useState(data || []);
  const { t } = useTranslation();
  return (
    <Box paddingTop={4} maxWidth={[225, 622, 800, '100%']}>
      <Table
        editable={false}
        data={data}
        dataLoaded={dataLoaded}
        setDataLoaded={setDataLoaded}
        pageable
        filterable
        sortable
      >
        <GridColumn
          field='name'
          title={t('form.company')}
          filterCell={CustomFilter}
          filter='text'
          editable={false}
        />
        <GridColumn
          field='email'
          title={t('form.email')}
          filterCell={CustomFilter}
          filter='text'
          editable={false}
        />
        <GridColumn
          field='role'
          width={150}
          title={t('form.role')}
          filterCell={CustomFilter}
          filter='text'
          editable
          cell={(props) => {
            const user = data?.find((el) => el.id === props.dataItem.id);
            const roleUser = { name: user?.role, value: user?.roleMask };
            const availableRoles = customerRoles?.[user?.roleMask];
            let userRoles = [roleUser].concat(availableRoles || []);

            return canEditRole ? (
              <SelectCell
                id={user?.id}
                selectedName={roleUser?.name}
                options={userRoles}
                action={(selected) => {
                  setIsOpenedModal(true);
                  setActionToSave('role');
                  setDataToSave({ role: selected, user });
                }}
                getActions={() => {
                  if (!availableRoles) {
                    setActionToGet('getroles');
                    setDataToGet({ ...user });
                  }
                }}
              />
            ) : (
              <td
                onClick={(event) => {
                  event.preventDefault();
                  displayToastError(t('errors.160'));
                }}
                colSpan='1'
              >
                <Text sizeText='display14'>{roleUser?.name}</Text>
              </td>
            );
          }}
        />
        <GridColumn
          field='status.key'
          width={140}
          title={t('form.status')}
          filterCell={CustomFilter}
          filter='text'
          editable={false}
          cell={(props) => {
            const { status } = props.dataItem;
            return (
              <td colSpan='1'>
                <Text color={status?.color} textAlign='center' sizeText='display14'>
                  {t(status.key)}
                </Text>
              </td>
            );
          }}
        />
        <GridColumn
          field=''
          width={140}
          title={t('title.cloudAssistant')}
          editable={false}
          filterable={false}
        >
          <GridColumn
            width={140}
            field='caAvailableTotal'
            title={`${t('general.availables')}/${t('general.total.other')}`}
            filterable={false}
            cell={(props) => {
              const { caAvailableTotal } = props.dataItem;
              return (
                <td colSpan='1'>
                  <Text textAlign='center' sizeText='display14'>
                    {caAvailableTotal}
                  </Text>
                </td>
              );
            }}
          />
        </GridColumn>

        <GridColumn
          field=''
          width={140}
          title={t('title.honoa')}
          editable={false}
          filterable={false}
        >
          <GridColumn
            width={140}
            field='honoaAvailableTotal'
            title={`${t('general.availables')}/${t('general.total.other')}`}
            filterable={false}
            cell={(props) => {
              const { honoaAvailableTotal } = props.dataItem;
              return (
                <td colSpan='1'>
                  <Text textAlign='center' sizeText='display14'>
                    {honoaAvailableTotal}
                  </Text>
                </td>
              );
            }}
          />
        </GridColumn>
        <GridColumn
          field='actions'
          width={100}
          title={t('form.actions')}
          editable={false}
          sortable={false}
          filterable={false}
          resizable
          cell={(props) => {
            const { status, invitationId } = props.dataItem;
            return (
              <td colSpan='1'>
                <Flex alignItems='center' justifyContent='center'>
                  <Button
                    sizeButton='none'
                    display={!status?.canEdit ? 'none' : 'inherit'}
                    action={() => {
                      setIsOpenedEditModal(true);
                      setDataToEdit(props.dataItem);
                    }}
                  >
                    <IconTooltip
                      sizeIcon='display16'
                      cursor='pointer'
                      color='black'
                      toolTipContent={t('general.edit')}
                      tooltipProps={{
                        place: 'top',
                      }}
                    >
                      <IconEdit />
                    </IconTooltip>
                  </Button>
                  <Button
                    sizeButton='none'
                    display={!status?.canResend ? 'none' : 'inherit'}
                    action={() => {
                      handlerSendInvitationLicense(invitationId);
                    }}
                  >
                    <IconTooltip
                      sizeIcon='display16'
                      cursor='pointer'
                      color='black'
                      toolTipContent={t('invitation.resend')}
                      tooltipProps={{
                        place: 'top',
                      }}
                      marginX={1}
                    >
                      <IconReply />
                    </IconTooltip>
                  </Button>
                  <Button
                    sizeButton='none'
                    display={!status?.canDelete ? 'none' : 'inherit'}
                    action={() => {
                      setIsOpenedDeleteModal(true);
                      setIdToDelete(invitationId);
                    }}
                  >
                    <IconTooltip
                      sizeIcon='display16'
                      cursor='pointer'
                      color='black'
                      toolTipContent={t('general.delete')}
                      tooltipProps={{
                        place: 'top',
                      }}
                    >
                      <IconTrash />
                    </IconTooltip>
                  </Button>
                </Flex>
              </td>
            );
          }}
        />
      </Table>
    </Box>
  );
};
