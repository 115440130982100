const GET_DEVICE_STATE = 'GET_DEVICE_STATE';
const SET_DEVICE_RELAY = 'SET_DEVICE_RELAY';
const RESET_RELAY_ACTIVATION = 'RESET_RELAY_ACTIVATION';
const RESET_ALL_DEVICE_PROPS = 'RESET_ALL_DEVICE_PROPS';
const POST_WRITE_MEMORY_SINGLE = 'POST_WRITE_MEMORY_SINGLE';
export default {
  GET_DEVICE_STATE,
  SET_DEVICE_RELAY,
  RESET_RELAY_ACTIVATION,
  RESET_ALL_DEVICE_PROPS,
  POST_WRITE_MEMORY_SINGLE,
};

export const getDeviceState = (facilityId, equipmentId) => ({
  type: GET_DEVICE_STATE,
  facilityId,
  equipmentId,
});

export const putDeviceRelay = (facilityId, equipmentId, relay, ms) => ({
  type: SET_DEVICE_RELAY,
  facilityId,
  equipmentId,
  relay,
  ms,
});

export const resetRelayActivation = () => ({ type: RESET_RELAY_ACTIVATION });

export const resetAllDeviceProps = () => ({ type: RESET_ALL_DEVICE_PROPS });

export const writeMemorySingle = (facilityId, id, connId, cc, ic) => ({
  type: POST_WRITE_MEMORY_SINGLE,
  facilityId,
  id,
  connId,
  cc,
  ic,
});
