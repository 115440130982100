export default {
  enrollCode: {
    manualTab: 'Manual',
    reservationsTab: 'Reserva',
    sequentialTab: 'Secuencial',
    universalsTab: 'Universal',
    device: 'Dispositivo',
    initialCode: 'Código inicial',
    idCard: 'DNI',
    spot: 'Plaza',
    numberOfFobs: 'N.º de emisores',
    endCode: 'Código final',
    cantEnroll:
      'Para dar de alta códigos necesitas un dispositivo o tener espacios libres de dispositivos',
    cancel: 'Cancelar',
    accept: 'Aceptar',
    ok: 'OK',
    forceCodeQuestion: '¿Desea forzar el registro de este código?',
    codeAlreadyExist: 'Este código ya existe en otro grupo',
    notAsigned: 'No asignado',
    reserved: 'Reserva',
  },
};
