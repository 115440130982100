import {
  ADD_NOTIFICATION,
  DELETE_NOTIFICATION,
  GET_NOTIFICATIONS,
  RESET_NOTIFICATIONS,
} from './constants';

/* Redux state init */
const initialState = {
  notifications: [],
};

export default function notificationsReducer(state = initialState, action) {
  if (action.type !== undefined) {
    switch (action.type) {
      case GET_NOTIFICATIONS.SUCCESS:
        return {
          ...state,
          notifications: [...action.result],
        };

      case DELETE_NOTIFICATION.SUCCESS:
        return {
          ...state,
          notifications: [...state.notifications.filter((item) => item.id !== action.result)],
        };
      case RESET_NOTIFICATIONS.SUCCESS:
        return { ...state, notifications: [] };

      case ADD_NOTIFICATION:
        return {
          ...state,
          notifications: [...state.notifications, action.noti],
        };
      default:
        return state;
    }
  }
  return state;
}
