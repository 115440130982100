export default {
  remoteComunications: {
    index: 'Rejstřík',
    content: 'Obsah',
    serialNumber: 'Sériové číslo',
    uniqueKey: 'Unikátní kód',
    operationsCode: 'Oper. kód',
    finalCode: 'Zadat kód',
    progress: 'Průběh',
    status: 'Stav',
    refresh: 'Obnovit',
    saveConfig: 'Uložit nastavení',
    readConfig: 'Načíst nastavení',
    readEvents: 'Načíst události',
    removeEvents: 'Přesunout události',
    seeEvents: 'Zobrazit události',
    export: 'Export',
    Completed: 'Dokončeno',
    Received: 'Přijato',
    Processing: 'Zpracovávání',
  },
};
