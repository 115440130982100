import { call, fork, takeLatest } from 'redux-saga/effects';

import fetchEntitySaga from '../old_to_refact/sagas/utils/fetchEntitySaga';
import apiPathFactory from '../../core/old_common/rooting/apiPathFactory';
import api from '../../core/helpers/api';

import {
  DELETE_NOTIFICATION,
  GET_NOTIFICATIONS,
  HUB_ADD_NOTIFICATIONS,
  RESET_NOTIFICATIONS,
} from './constants';

export const getNotifications = {
  base: () => ({
    type: GET_NOTIFICATIONS.BASE,
  }),
  request: () => ({ type: GET_NOTIFICATIONS.REQUEST }),
  success: (result) => ({ type: GET_NOTIFICATIONS.SUCCESS, result }),
  failure: (error) => ({ type: GET_NOTIFICATIONS.FAILURE, error }),
};

export const addNotifications = {
  base: () => ({
    type: HUB_ADD_NOTIFICATIONS.BASE,
  }),
  request: () => ({ type: HUB_ADD_NOTIFICATIONS.REQUEST }),
  success: (result) => ({ type: GET_NOTIFICATIONS.SUCCESS, result }),
  failure: (error) => ({ type: GET_NOTIFICATIONS.FAILURE, error }),
};

export const clearNotification = {
  base: (notificationId) => ({
    type: DELETE_NOTIFICATION.BASE,
    notificationId,
  }),
  request: () => ({ type: DELETE_NOTIFICATION.REQUEST }),
  success: (result) => ({ type: DELETE_NOTIFICATION.SUCCESS, result }),
  failure: (error) => ({ type: DELETE_NOTIFICATION.FAILURE, error }),
};

export const resetNotifications = {
  base: () => ({
    type: RESET_NOTIFICATIONS.BASE,
  }),
  request: () => ({ type: RESET_NOTIFICATIONS.REQUEST }),
  success: (result) => ({ type: RESET_NOTIFICATIONS.SUCCESS, result }),
  failure: (error) => ({ type: RESET_NOTIFICATIONS.FAILURE, error }),
};

//----------------------------------------------------------------------

export function* getNotificationsSaga() {
  try {
    const url = apiPathFactory.notificationsGet();
    const response = yield call(() => fetchEntitySaga(getNotifications, api.get, url));
    return response;
  } catch (error) {
    return error;
  }
}

export function* addNotificationSaga(noti) {
  try {
    return noti;
  } catch (error) {
    return error;
  }
}

export function* clearNotificationSaga(id) {
  try {
    const url = apiPathFactory.notificationsClear(id);
    const response = yield call(() =>
      fetchEntitySaga(clearNotification, api.del, url, { toastNotShow: true })
    );
    return response;
  } catch (error) {
    return error;
  }
}

export function* resetNotificationsSaga() {
  try {
    const url = apiPathFactory.notificationsReset();
    const response = yield call(() => fetchEntitySaga(resetNotifications, api.post, url));
    return response;
  } catch (error) {
    return error;
  }
}

//----------------------------------------------------------------------

function* watchGetNotifications() {
  yield takeLatest(GET_NOTIFICATIONS.BASE, getNotificationsSaga);
}

//----------------------------------------------------------------------

function* watchClearNotifications() {
  yield takeLatest(DELETE_NOTIFICATION.BASE, clearNotificationSaga);
}

//----------------------------------------------------------------------

function* watchResetNotifications() {
  yield takeLatest(RESET_NOTIFICATIONS.BASE, resetNotificationsSaga);
}

function* watchAddNotifications() {
  yield takeLatest(HUB_ADD_NOTIFICATIONS.BASE, addNotificationSaga);
}

//----------------------------------------------------------------------

export default function* badRuleWarningWatchers() {
  yield fork(watchGetNotifications);
  yield fork(watchClearNotifications);
  yield fork(watchResetNotifications);
  yield fork(watchAddNotifications);
}
