import React, { Component } from 'react';
import StyledLabel from './StyledLabel';

export default class Label extends Component {
  render() {
    const { value, id, className, onClick, tenants, withoutUnderline } = this.props;
    return (
      <span className={className || ''}>
        <StyledLabel
          tenants={tenants}
          id={id}
          name={id}
          onClick={onClick}
          withoutUnderline={withoutUnderline}
        >
          {value || ''}
        </StyledLabel>
      </span>
    );
  }
}
