import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import GenericForm from '../GenericForm/GenericForm';
import ContentContainer from '../ContentContainer';

import { setOnFlyErrors, validateForm } from '../../core/old_common/validations/service';
import validationsDictionary from '../../core/old_common/validations';

class PopUpNeighbour extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.id || '00000000-0000-0000-0000-000000000000',
      email: props.email || props.popUpToEdit.email || '',
      phone: props.phone || props.popUpToEdit.phone || '',
      name: props.name || props.popUpToEdit.name || '',
      door: props.door || props.popUpToEdit.code.door || '',
      floor: props.floor || props.popUpToEdit.code.flat || '',
      surname: props.surname || props.popUpToEdit.surname || '',
      formErrors: [],
      onFlyFormErrors: [],
      floorRange: 0,
      doorRange: 0,
    };
    this.isPost = false;
  }

  getDoorAndFloor = (props) => {
    const { code, codeFormat } = props;
    let floor = '';
    let door = '';
    if (codeFormat === '0@00') {
      floor = code.substring(0, 1);
      door = code.substring(1, 3);
    } else {
      floor = code.substring(0, 1);
      door = code.substring(2, 3);
    }
    return { floor, door };
  };

  handleChange = (e) => this.setState({ [e.target.id]: e.target.value });

  handleNumericChange = (e) => {
    if (!isNaN(e.target.value)) {
      this.setState({ [e.target.id]: parseInt(e.target.value) });
    }
  };

  handleFloorChange = (e) => {
    e.currentTarget.value.length < 2
      ? this.setState({ doorRange: 99 })
      : this.setState({ doorRange: 9 });
    this.handleNumericChange(e);
  };

  handleDoorChange = (e) => {
    e.currentTarget.value.length < 2
      ? this.setState({ floorRange: 99 })
      : this.setState({ floorRange: 9 });
    this.handleNumericChange(e);
  };

  setErrorsCallback = ({ onFlyFormErrors, error, id, errorMessage }) => {
    this.setState({
      onFlyFormErrors: setOnFlyErrors({ onFlyFormErrors, error, id, errorMessage }),
    });
  };

  getFloorAndDoor = () => {
    const { id } = this.state;
    const { t } = this.props;
    if (id === '00000000-0000-0000-0000-000000000000') {
      this.isPost = true;
      return [
        {
          type: 'input',
          placeHolder: t('neighbourPopUp.floor'),
          onChange: this.handleFloorChange,
          value: this.state.floor,
          id: 'floor',
          validation: [
            () => validationsDictionary.isNumeric(this.state.floor),
            () => validationsDictionary.isRequired(this.state.floor),
            () => validationsDictionary.isInRange(this.state.floor, 1, this.state.floorRange),
          ],
        },
        {
          type: 'input',
          placeHolder: t('neighbourPopUp.door'),
          onChange: this.handleDoorChange,
          value: this.state.door,
          id: 'door',
          validation: [
            () => validationsDictionary.isNumeric(this.state.door),
            () => validationsDictionary.isRequired(this.state.door),
            () => validationsDictionary.isInRange(this.state.door, 1, this.state.doorRange),
          ],
        },
      ];
    }
    return [];
  };

  getFields = () => {
    const { t } = this.props;
    const fields = [
      [
        {
          type: 'input',
          placeHolder: t('neighbourPopUp.email'),
          onChange: this.handleChange,
          value: this.state.email,
          id: 'email',
          validation: [() => validationsDictionary.isRequired(this.state.email)],
        },
        {
          type: 'input',
          placeHolder: t('neighbourPopUp.phoneNumber'),
          onChange: this.handleChange,
          value: this.state.phone,
          id: 'phone',
          validation: [() => validationsDictionary.isRequired(this.state.phone)],
        },
        ...this.getFloorAndDoor(),
      ],
      [
        {
          type: 'input',
          placeHolder: t('form.name'),
          onChange: this.handleChange,
          value: this.state.name,
          id: 'name',
          validation: [() => validationsDictionary.isRequired(this.state.name)],
        },
        {
          type: 'input',
          placeHolder: t('form.surname'),
          onChange: this.handleChange,
          value: this.state.surname,
          id: 'surname',
          validation: [() => validationsDictionary.isRequired(this.state.surname)],
        },
      ],
    ];
    return fields;
  };

  onSubmit = () => {
    const { onAcceptFn } = this.props;
    const { id, email, phone, floor, door, name, surname } = this.state;
    const newNeighbour = {
      id,
      email,
      phone,
      password: 'a',
      name,
      surname,
      code: {
        flat: floor,
        door,
      },
    };
    const formErrors = validateForm(this.getFields());
    this.setState({ formErrors: formErrors.errors });
    if (!formErrors.hasError) {
      onAcceptFn(newNeighbour, this.isPost);
    }
  };

  render() {
    const { tenants, t, onCancelFn } = this.props;
    return (
      <div>
        <ContentContainer tenants={tenants} title={t('neighbourPopUp.titleEdit')}>
          <GenericForm
            tenants={tenants}
            formErrors={this.state.formErrors}
            fields={this.getFields()}
            isCancelVisible
            handleCancel={onCancelFn}
            handleSubmit={this.onSubmit}
          />
        </ContentContainer>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  tenants: state.tenants,
  popUpToEdit: state.neighbours.popUpToEdit,
});

const PopUpNeighbourWithTranslation = withTranslation()(PopUpNeighbour);

export default connect(mapStateToProps, {})(PopUpNeighbourWithTranslation);
