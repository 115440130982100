export default {
  remoteComunications: {
    index: 'Índex',
    content: 'Contingut',
    serialNumber: 'Número de sèrie',
    uniqueKey: 'Clau única',
    operationsCode: 'Codi Oper.',
    finalCode: 'Codi fi',
    progress: 'Progrés',
    status: 'Estat',
    refresh: 'Refrescar',
    saveConfig: 'Desar configuració',
    readConfig: 'Llegir configuració',
    readEvents: 'Llegir esdeveniments',
    removeEvents: 'Eliminar esdeveniments',
    seeEvents: 'Veure esdeveniments',
    export: 'Exportar',
    Completed: 'Completada',
    Received: 'Rebuda',
    Processing: 'En procés',
  },
};
