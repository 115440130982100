import { useTranslation } from 'react-i18next';
import { Card } from '@jcm-technologies/uikit/dist/molecules/Card';
import { Icon } from '@jcm-technologies/uikit/dist/atoms/Icon';
import { Button } from '@jcm-technologies/uikit/dist/atoms/Button';
import { Text } from '@jcm-technologies/uikit/dist/atoms/Typography';
import IconUsers from '@jcm-technologies/uikit/dist/atoms/Icons/IconUsers';
import IconUser from '@jcm-technologies/uikit/dist/atoms/Icons/IconUser';
import IconChevronUp from '@jcm-technologies/uikit/dist/atoms/Icons/IconChevronUp';
import { Box, Flex, Grid } from '@jcm-technologies/uikit/dist/atoms/Layout';
import { useHistory, useParams } from 'react-router-dom';
import { Loading } from '@jcm-technologies/uikit/dist/atoms/Loading';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { IconTooltip } from '@jcm-technologies/uikit/dist/molecules/IconTooltip';
import moment from 'moment-timezone';
import Table from '../Table';
import Form from '../Form';
import { downloadFile, getFilesFromApi } from '../../../../../core/helpers/filesApi';
import apiPathFactory from '../../../../../core/old_common/rooting/apiPathFactory';
import { replaceStringValue } from '../../../../../core/old_common/utils/replaceStringService';
import IconFile from '@jcm-technologies/uikit/dist/atoms/Icons/IconFile';

const BoxHistory = ({ item }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { instalationId: idFacility, id: idGroup, idFob } = useParams();
  const { color1 } = useSelector((state) => state.tenants);

  const onHTMLExport = async () => {
    const namePDF = `${moment().format('YYYY-MM-DD')}_${replaceStringValue(
      item.name?.substring(0, 50),
      'Fob'
    )}_${replaceStringValue(item.groupName?.substring(0, 50), 'Group')}`;
    const endpoint = apiPathFactory.getGroupFobHistoryFile(idFacility, idGroup, idFob);
    await getFilesFromApi(
      endpoint,
      (isError, response) => {
        if (!isError) {
          const fileName = `${namePDF}.html`;
          downloadFile(response, fileName);
        }
      },
      'application/json',
      'binary'
    );
    return true;
  };

  if (item) {
    return (
      <Card marginTop={1}>
        <Grid
          gridTemplateAreas="'. .'"
          gridTemplateColumns='1fr 38px'
          padding={1}
          onClick={() =>
            item?.isUniversal
              ? history.push(`/universalGroups/${idGroup}`)
              : history.push(`/facilities/${idFacility}/groups/${idGroup}`)
          }
          style={{ cursor: 'pointer' }}
        >
          <Flex alignItems='center'>
            <Icon sizeIcon='display14' color='blackLight' marginRight={2}>
              {item?.isUniversal ? <IconUsers /> : <IconUser />}
            </Icon>
            <Text sizeText='display14' color='blackLight'>
              {item.groupName}
            </Text>
          </Flex>
          <Flex alignItems='center'>
            <IconTooltip
              sizeIcon='display16'
              toolTipContent={t('general.downloadHtml')}
              color='grey'
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onHTMLExport();
              }}
            >
              <IconFile />
            </IconTooltip>
            <Icon sizeIcon='display24' color='blackLight'>
              <IconChevronUp />
            </Icon>
          </Flex>
        </Grid>
        <Box
          borderTopColor={color1}
          borderTopStyle='solid'
          borderTopWidth={2}
          paddingX={4}
          paddingY={2}
        >
          <Form item={item} />
        </Box>
        <Box paddingX={4} paddingY={2}>
          <Table data={item.logs} />
        </Box>
        <Flex paddingX={4} paddingY={2} alignItems='center' justifyContent='flex-end'>
          <Button backgroundColor='blackLight' onClick={() => history.push('/dashboard')}>
            {t('general.back')}
          </Button>
        </Flex>
      </Card>
    );
  }

  return <Loading show />;
};

export default BoxHistory;

BoxHistory.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string,
    surname: PropTypes.string,
    slot: PropTypes.string,
    identityCard: PropTypes.string,
    groupName: PropTypes.string,
    isUniversal: PropTypes.bool,
    logs: PropTypes.arrayOf(
      PropTypes.shape({
        code: PropTypes.string,
        dateTime: PropTypes.string,
        user: PropTypes.string,
      })
    ),
    configFields: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.string,
      })
    ),
  }),
};

BoxHistory.defaultProps = {
  item: null,
};
