export default {
  errors: {
    title: {
      honoa: 'HONOA Mostra estat de la porta:',
    },
    402: 'Pagament requerit',
    1000: "L'inici de sessió ha fallat",
    name: 'Aquest nom no està disponible',
    generic: 'Identificador únic ja està utilitzat',
    selectTypeFirst: 'Seleccioneu un tipus primer',
    unexpectedError: 'Ha succeït un error',
    uniqueIdentity: 'Aquest contingut ja existeix',
    10: 'Iniciant la secció de configuració de CloudAssistant API',
    11: "Configuració completa. L'API està a punt per atendre trucades entrants",
    12: "Configura la versió de configuració de l'API",
    13: "Configuració general d'API",
    14: 'Configuració de SMTP',
    15: 'Configurant les polítiques de Cookies',
    16: 'Establir entorn de compatibilitat:',
    17: 'Configurant context de la base de dades',
    18: "Serveis d'autenticació",
    19: 'Afegeix documentació del swagger',
    100: 'Usuari connectat',
    101: 'Usuari desconnectat',
    102: 'Canvi de contrasenya exitós',
    103: 'Procés de recuperació de contrasenya completat',
    104: 'Procés de restabliment de contrasenya completat',
    105: 'Enviant email a:',
    106: 'Usuari no vàlid',
    107: 'usuari no actualitzat',
    108: 'Invitació caducada',
    109: 'Invitació no eliminada',
    110: 'Usuari no confirmat i actualitzat a IS4',
    111: "L'usuari no s'ha enregistrat correctament",
    112: "No coincideix la identificació d'usuari o client",
    113: "L'id del client no existeix",
    114: 'Invitació no enviada',
    115: 'La invitació ha estat esborrada',
    116: 'Invitació de reversió completada',
    117: 'Atributs del client no creats correctament',
    118: 'No hi ha prou permisos per mostrar aquests elements',
    119: 'No hi ha prou permisos per mostrar o actualitzar aquests elements',
    120: 'No hi ha prou permisos per crear aquests elements',
    121: 'No hi ha prou permisos per eliminar aquests elements',
    122: 'Usuari no eliminat en identitat',
    123: 'Usuari no trobat',
    124: 'Conflicte en eliminar usuari en identity',
    125: 'Fallada de concurrència',
    126: 'Error predeterminat',
    127: 'El correu electrònic ja existeix',
    128: 'El nom del rol ja existeix',
    129: "El nom d'usuari ja existeix",
    130: 'Correu electrònic no vàlid',
    131: 'Nom de rol no vàlid',
    132: 'Token no vàlid',
    133: "Nom d'usuari no vàlid",
    134: 'Inici de sessió ja associat',
    135: 'La contrasenya requereix minúscules',
    136: 'La contrasenya requereix valors no alfanumèrics',
    137: 'La contrasenya requereix caràcters únics',
    138: 'La contrasenya requereix majúscules',
    139: 'Contrasenya massa curta',
    140: 'Error en bescanviar el codi de recuperació',
    141: "L'usuari ja conté una contrasenya",
    142: "L'usuari ja conté un rol",
    143: 'Usuari no conté un rol',
    144: "Bloqueig d'usuari no habilitat",
    145: "Error d'autenticació, id de client no vàlid o ocult",
    146: 'Sol·licitud no vàlida',
    147: 'El correu electrònic o la contrasenya no són correctes',
    148: 'Abast no vàlid',
    149: 'Aquest client no està autoritzat a utilitzar el tipus de petició sol·licitada',
    150: 'Tipus de concessió no admès',
    151: 'Usuari no eliminat en identity',
    152: 'Conflicte en eliminar usuari en identity',
    153: 'Subscripcions no actualitzades',
    154: 'Les llicències han estat actualitzades',
    155: 'Error en obtenir llicències',
    156: "El client té grups universals o instal·lacions amb codi d'instal·lador",
    157: 'Error desconegut',
    158: 'No hi ha prou permisos',
    159: "El correu electrònic de l'usuari ja existeix",
    160: 'No hi ha prou llicències',
    161: "Rols d'usuari no vàlids",
    162: "Rol d'usuari no actualitzat",
    163: 'Usuari no inscrit o sense llicència',
    164: "Rols d'usuari o client no vàlids",
    165: "L'administrador no es pot actualitzar",
    166: "Codi d'instal·lador no actualitzat",
    167: 'Famílies de productes no actualitzades',
    168: 'Productes no actualitzats',
    169: "No s'ha trobat cap element amb aquest id",
    170: 'Actualitzar Token',
    171: 'No hi ha prou llicències',
    172: 'Dispositiu no trobat',
    200: 'Obtenir la llista de tots els usuaris',
    300: "Arguments incorrectes o nombre d'elements excedits per a una sola sol·licitud",
    301: 'Actualització completada amb èxit',
    302: 'Error de validació',
    400: 'Tenant guardat amb èxit',
    401: 'Falten algunes dades del Tenant! Desat sense èxit',
    404: 'Dispositiu fora de línia o fallada de la connexió.',
    9000: "Error d'inici de sessió",
    9001: 'Error en restablir contrasenya',
    9002: 'La recuperació de contrasenya ha fallat',
    9003: 'Error en canviar la contrasenya',
    9004: 'Contrasenya no coincideix',
    9005: 'La contrasenya requereix almenys un dígit',
    9006: 'Usuari desconegut',
    90000: "S'ha sol·licitat una prova de bucle invertit:",
    90001: "S'ha sol·licitat l'excepció:",
    90002: "S'ha sol·licitat el codi d'estat de tornada:",
    90100: 'Excepció SQL no controlada',
    90101: 'Fallada en la creació',
    90102: 'El nom que heu introduit ja existeix, heu de canviar-lo',
    90103: 'No trobat',
    90104: 'Actualització fallida',
    90105: 'Error en eliminar',
    90106: 'Aquest contingut ja existeix',
    90107: 'El contingut no pot ser nul',
    90108: 'Error en importar',
    90109: "Error d'exportació",
    90110:
      "El codi d'instal·lador no es pot actualitzar en instal·lacions amb comandaments assignats",
    90111: 'La ID o URL de la instal·lació no és vàlida',
    90112: "Grup d'entitats d'instal·lació no creat",
    90113: "El grup d'instal·lacions ha de tenir nom",
    90114: "El nom del grup d'entitats de la instal·lació ja existeix per a aquesta instal·lació",
    90115: "Grup d'entitats d'instal·lacions no actualitzat",
    90116: "Grup d'entitats d'instal·lacions no eliminat",
    90117: "El contacte de l'entitat no pot ser nul",
    90118: 'Entitat de la instal·lació no actualitzada',
    90119: "La descripció o el codi de l'entitat de la instal·lació ja existeix per a aquest grup",
    90120: 'No es pot suprimir el contacte amb la posició 0',
    90121: 'Contacte no eliminat',
    90122: 'Contacte no creat',
    90123: "L'entitat ja té 5 contactes",
    90124: "Contacte d'entitat no actualitzat",
    90125: 'GroupId no existeix per a aquesta instal·lació',
    90126: 'FacilityId no vàlid',
    90127: "Contacte d'entitat d'instal·lació no vàlid",
    90128:
      "El codi d'instal·lador ha de ser el maeix per a totes les instal·lacions del grup universal",
    90129: "El codi d'instal·lador ha de ser el mateix per a la instal·lació i el grup universal",
    91000: 'VDP mac o id no trobat',
    91001: 'Mac no vàlida',
    91002: 'Extensió de correu electrònic no trobada',
    91003: 'Error en actualitzar el nom del vdp',
    91004: 'Error en crear la relació de contacte vdp',
    91005: 'Error en crear contacte',
    91006: 'Error en eliminar contactes',
    91007: 'Error en actualitzar el nom del contacte',
    91008: 'El nom del client ja existeix',
    91009: 'Codi de pis o porta no vàlid',
    91010: 'Codi no introduït',
    91011: 'Nombre de canals corrupte',
    91012: 'No es pot eliminar la darrera relació de retransmissió',
    91013: 'No es pot desactivar el relé perquè té grups assignats',
    91014: 'Rang de codis no vàlid',
    91015: 'Els codis han de ser del mateix tipus',
    91016: 'No hi ha prou llocs al grup',
    91017: 'El codi ja existeix a la instal·lació',
    91018: 'El codi ja existeix en universals',
    91019: "El codi és una substitució d'un eliminat universal",
    91020: "Aquest equip d'instal·lació no admet horaris",
    91021: 'Res a copiar',
    91022: 'Res a moure',
    91023: 'Producte no vàlid',
    91024: 'Horari no trobat',
    91025: "No es pot eliminar l'horari amb la instal·lació assignada",
    91026: 'El grup sol·licitat no és universal',
    91027: "L'equip conté paràmetres no vàlids",
    91028: 'No es pot eliminar el grup universal amb instal·lacions assignades',
    91029: "Número de sèrie o clau d'activació no registrada",
    91030: "El número de sèrie o clau d'activació no pot ser nul",
    91031: 'El codi del client no és correcte',
    91032: "El codi d'instal·lador no és correcte",
    91033: 'La contrasenya no és correcta.',
    91034: 'Arxiu corrupte',
    91065: 'enllaç ja assignat',
    91066: "S'ha produït un error en desactivar l'enllaç",
    91067: "Clau d'activació incorrecta",
    91068: 'Sol·licitud API fallida',
    91069: 'Producte no habilitat',
    91070: 'Producte no trobat',
    91071: "No s'han seleccionat relés. Utilitzeu el botó de configuració",
    91072: 'El nom del relé ja existeix',
    91073: "Nombre màxim d'instal·lacions assolides",
    91074: 'Aquesta clau dactivació ja està en ús per un altre usuari.',
    91100: 'Dispositiu desconnectat',
    91101: "Bessons de dispositiu de lectura d'excepcions no manejades",
    91102: 'Error llegint la memòria',
    91103: 'Error gravant la memòria',
    91104: "La memòria d'escriptura no coincideix",
    operationNotCompleted: "No s'ha pogut completar l'operació",
    91102: 'Llegir excepció de memòria',
    91103: 'Escriure excepció de memòria',
    91104: "La memòria d'escriptura no coincideix",
    91105: 'El dispositiu té codi instal·lador',
    91106: 'El dispositiu no té codi instal·lador',
    91302: 'La informació de la SIM no està disponible',
    91303: 'El contracte de la SIM no està disponible',
    91307: 'No es pot obtenir el número de sèrie',
    91308: "Clau d'activació zero o buida",
    91309: "Clau d'activació nul·la, buida o no vàlida",
    91201: "L'usuari ja existeix. Totes les dades seran heretades",
    91452: 'El dispositiu no admet calendaris amb festius',
    91527: 'No es pot eliminar la instal·lació amb un grup universal assignat.',
    91528: 'No es pot suprimir equip amb grup assignat.',
    91529: 'No es pot suprimir grup amb emissors.',
    91540: 'No es pot dessasignar equips si el grup conté usuaris HONOA',
    91203: "L'usuari ja existeix en aquesta instal·lació",
    universalGroupsAssigned: 'Grups universals assignats:',
    groupsAssigned: 'Grups assignats:',
    redirectWarning: 'Ha estat redirigit a la pàgina principal.',
    noContent: 'No hi ha contingut disponible per a aquest llistat',
    communicationError: 'Error de comunicació intenteu-ho més tard',
    91530: "Aquest equip no es pot desassignar perquè l'equip restant no admet emissors",
    91531: 'La instal·lació no es pot reassignar perquè utilitza grups universals',
    91532: 'La instal·lació conté equips incompatibles',
    91534: 'Nombre màxim de dispositius Honoa assolit per a aquesta instal·lació',
    91310: 'Aquest número de sèrie no està assignat a cap usuari',
    91311: 'Error de comunicació amb servei de productes',
    91312: 'Error en generar llicència',
    91400: 'Error en desactivar alarma',
    91450: 'Els horaris següents no tenen un rang vàlid:',
    91451: "S'ha de seleccionar un dia festiu com a mínim",
    91452: 'Un dispositiu en ús no admet calendaris amb festes',
    91203: "L'usuari ja existeix en aquesta instal·lació",
    91523: 'Porta 2 oberta',
    91524: 'Porta 2 tancada',
    91525: 'Obertura de la porta 2',
    91526: 'Tancament de la porta 2',
    91537: 'No es pot suprimir un grup amb usuaris Honoa',
    91453: "El nom de l'horari ja existeix",
    91536: 'El nom de la instal·lació ja existeix',
    91800: 'Format número telèfon no suportat',
    91801: 'Format url web no suportat',
    needsMinOnRelay: "Hi ha d'haver mínim un relé assignat",
    91802: 'Aquest dispositiu no suporta emissors',
    91803: 'Aquest grup no suporta emissors',
    nameRequired: "No s'ha pogut desar. Noms requerits en famílies i productes.",
    deletedUser: 'Usuari eliminat',
    91804: "El nom d'un o més horaris assignats a aquesta instal·lació és massa llarg",
    91538: 'Error descarregant PDF',
    timeout: "Temps d'espera superat.",
    91853: 'La zona horària no pot ser buida.',
    91539: 'No es pot desactivar un ordinador utilitzat actualment en instal·lacions.',
    zeroCode: "Compte! S'està treballant amb codi instal·lador 0",
    91075: 'No hi ha suficients reserves disponibles',
    304: 'Literal no és vàlid (espais en blanc buits, al capdavant o al final, ...)',
  },
};
