const SHOW_LOADING = 'SHOW_LOADING';

export default {
  SHOW_LOADING,
};

/**
 * Sets the form state
 * @param  {int} value                The value for show loading
 * @return {object}                   Formatted action for the reducer to handle
 */
export const showDialog = (value, percent) => ({ type: SHOW_LOADING, value, percent });
