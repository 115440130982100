export default {
  universalGroups: {
    description: 'Leírás',
    informationTab: 'Információ',
    expand: 'Kibővítés',
    collapse: 'Összeomlás',
    delete: 'Törlés',
    add: 'Új univerzális csoport hozzáadása',
    create: 'Univerzális csoport létrehozása',
    instalationsTab: 'Létesítmények',
    fobsTab: 'Másolt távirányítók',
    deleteConfirmation: 'Biztosan törölni szeretné az univerzális csoportot?',
  },
};
