export default {
  remoteState: {
    noContent: 'Not available',
    title: 'Remote state',
    doorState0: 'Undefined',
    doorState1: 'Open',
    doorState2: 'Closed',
    doorState3: 'Opening',
    doorState4: 'Closing',
    doorState5: 'Stop opening',
    doorState6: 'Stop closing',
    doorState7: 'Waiting for autoclose',
    deviceType: 'Device type',
    connectionSignal: 'Connection signal',
    rssiValue: 'RSSI Value',
    hwVersion: 'Hardware version',
    swVersion: 'Software version',
    doorStateTitle: 'Door status',
    inputStateTitle: 'Input status',
    deviceInfoTitle: 'Device information',
    relayTitle: 'activation time in sec',
    relaysTitle: 'Relays',
    refreshStatus: 'Refresh status',
    activateRel1: 'Activate relay 1',
    activateRel2: 'Activate relay 2',
    doorState: 'Status',
    activateRelError: 'Device is not responding',
    activateRelSuccess: 'Action successfully completed',
    connectionSignalUnknown: 'Out of range',
    connectionSignalExcellent: 'Excellent',
    connectionSignalGood: 'Good',
    connectionSignalFair: 'OK',
    connectionSignalWeak: 'Marginal',
    connectionType: 'Connection type',
    deviceType0: 'eBase',
    deviceType1: 'eConnect',
    deviceType2: 'BtConnect',
    deviceType3: 'Sesame',
    deviceType4: 'Blink',
    deviceType5: 'eAccess',
    deviceType6: 'Vdp',
    deviceType7: 'HONOADOOR / EBASEDOOR',
    deviceType8: 'HONOARELAY',
    sensors: 'Sensors',
    open: 'Open',
    battery: 'Battery',
    deactivated: 'Deactivated',
    activated: 'Activated',
  },
};
