import { Card } from '@jcm-technologies/uikit/dist/molecules/Card';
import { Icon } from '@jcm-technologies/uikit/dist/atoms/Icon';
import { Text } from '@jcm-technologies/uikit/dist/atoms/Typography';
import IconUser from '@jcm-technologies/uikit/dist/atoms/Icons/IconUser';
import IconChevronDown from '@jcm-technologies/uikit/dist/atoms/Icons/IconChevronDown';
import { Flex, Grid } from '@jcm-technologies/uikit/dist/atoms/Layout';
import { useHistory } from 'react-router-dom';
import { Loading } from '@jcm-technologies/uikit/dist/atoms/Loading';
import PropTypes from 'prop-types';

const CardMifare = ({ item }) => {
  const history = useHistory();
  if (item) {
    return (
      <Card
        padding={1}
        marginTop={1}
        style={{ cursor: 'pointer' }}
        onClick={() => history.push(`/facilities/${item.facilityId}/groups/${item.id}/wiegand`)}
      >
        <Grid gridTemplateAreas='. . .' gridTemplateColumns='1fr 1fr 24px'>
          <Flex alignItems='center'>
            <Icon sizeIcon='display14' color='blackLight' marginRight={2}>
              <IconUser />
            </Icon>
            <Text sizeText='display14' color='blackLight'>
              {item.name}
            </Text>
          </Flex>
          <Text sizeText='display14' color='blackLight'>
            {item.facilityName}
          </Text>
          <Icon sizeIcon='display24' color='blackLight'>
            <IconChevronDown />
          </Icon>
        </Grid>
      </Card>
    );
  }

  return <Loading show />;
};

export default CardMifare;

CardMifare.propTypes = {
  item: PropTypes.shape({
    facilityId: PropTypes.string,
    id: PropTypes.string,
    facilityFobId: PropTypes.string,
    name: PropTypes.string,
    facilityName: PropTypes.string,
  }),
};

CardMifare.defaultProps = {
  item: null,
};
