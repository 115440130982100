import styled from 'styled-components';
import { getContrastColor, increaseBrightness } from '../../core/helpers/colorManager';

export default styled.button`
  padding: 8px 32px;
  border: 0px solid;
  border-radius: 2em;
  margin: auto;
  margin-top: 5%;
  display: inline-block;
  color: ${(props) => getContrastColor(props.tenants.color1)};
  background-color: ${(props) => props.tenants.color1};
  &:focus {
    background-color: ${(props) => increaseBrightness(props.tenants.color1, 50)};
    color: ${(props) => getContrastColor(increaseBrightness(props.tenants.color1, 50))};
    border-color: ${(props) => getContrastColor(props.tenants.color1)};
  }
  &:hover {
    background-color: ${(props) => increaseBrightness(props.tenants.color1, 30)};
    color: ${(props) => getContrastColor(increaseBrightness(props.tenants.color1, 30))};
    border-color: ${(props) => getContrastColor(props.tenants.color1)};
  }
`;
