// TODO: Change this import when refactoring instalations
import { INSTALATIONS_FOBS_GET } from '../../../modules/old_to_refact/reducers/instalations';
import {
  GET_GROUP_FOBS_CHECK_CODES,
  DELETE_GROUP_FOBS_LIST,
  GET_GROUP_FOBS_PDF,
  POST_GROUP_FOBS_FOB,
  POST_GROUP_FOBS_LIST_COPY,
  POST_GROUP_FOBS_SEQUENTIAL,
  PUT_GROUP_FOBS_IMPORT,
  PUT_GROUP_FOBS_LIST_MOVE,
  DELETE_GROUP_FOBS_FOB,
  PUT_GROUP_FOBS_GRID,
  RESET_GROUP_FOBS_CHECKED_FOBS,
  RESET_GROUP_FOBS_IS_POST_FOB,
  RESET_GROUP_FOBS,
  RESET_GROUP_FOBS_TABLE_FOBS,
} from './constants';

/* Redux state init */
const initialState = {
  isSuccess: false,
  isDeleteSuccess: false,
  isFobPostedSuccess: false,
  fobsImported: false,
  codeExists: undefined,
  errorMessage: undefined,
  isFobsCalled: undefined,
  needUpdateTableFobs: false,
  readingCodes: false,
  isFobSuccess: false,
};

export default function fobsReducer(state = initialState, action) {
  if (action.type !== undefined) {
    switch (action.type) {
      case POST_GROUP_FOBS_LIST_COPY.SUCCESS:
      case PUT_GROUP_FOBS_LIST_MOVE.SUCCESS:
      case DELETE_GROUP_FOBS_LIST.SUCCESS:
      case PUT_GROUP_FOBS_GRID.SUCCESS:
        return {
          ...state,
          isFobSuccess: true,
        };
      case PUT_GROUP_FOBS_GRID.REQUEST:
        return {
          ...state,
          isFobSuccess: false,
        };
      case DELETE_GROUP_FOBS_FOB.SUCCESS:
        return {
          ...state,
          isDeleteSuccess: true,
        };
      case RESET_GROUP_FOBS.SUCCESS:
        return {
          ...state,
          isSuccess: false,
          isDeleteSuccess: false,
        };
      case POST_GROUP_FOBS_FOB.SUCCESS:
      case POST_GROUP_FOBS_SEQUENTIAL.SUCCESS:
        return {
          ...state,
          isFobPostedSuccess: true,
        };
      case RESET_GROUP_FOBS_IS_POST_FOB:
        return {
          ...state,
          isFobPostedSuccess: false,
        };
      case PUT_GROUP_FOBS_IMPORT.SUCCESS:
        return {
          ...state,
          fobsImported: true,
        };
      case INSTALATIONS_FOBS_GET.REQUEST:
        return {
          ...state,
          isFobsCalled: true,
        };
      case INSTALATIONS_FOBS_GET.SUCCESS:
        return {
          ...state,
          fobsImported: false,
          isFobsCalled: false,
        };
      case GET_GROUP_FOBS_CHECK_CODES.REQUEST:
        return {
          ...state,
          codeExists: undefined,
          readingCodes: true,
        };
      case GET_GROUP_FOBS_CHECK_CODES.SUCCESS:
        return {
          ...state,
          codeExists: false,
          readingCodes: false,
        };

      case RESET_GROUP_FOBS_CHECKED_FOBS:
        return {
          ...state,
          codeExists: undefined,
          needUpdateTableFobs: true,
        };
      case RESET_GROUP_FOBS_TABLE_FOBS:
        return {
          ...state,
          needUpdateTableFobs: false,
        };
      case INSTALATIONS_FOBS_GET.FAILURE:
        return {
          ...state,
          isFobsCalled: false,
        };
      case GET_GROUP_FOBS_PDF.SUCCESS:
        return {
          ...state,
        };
      default:
        return state;
    }
  }
  return state;
}
