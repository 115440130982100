export const getFamilyListByType = (familiesList, productType) =>
  familiesList && familiesList[productType] ? familiesList[productType] : [];

export const getProduct = (familiesList, productId) => {
  if (familiesList && familiesList.length && productId) {
    const families = familiesList.flat();
    const products = families.map((family) => family?.items).flat();
    return products.find((product) => product?.productId === productId);
  }
  return undefined;
};

export default { getFamilyListByType, getProduct };
