const getUrlParams = () => {
  const query = window.location.search.substring(1);
  const vars = query.split('&');
  let urlParams = {};
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=');
    urlParams = { ...urlParams, [pair[0]]: pair[1] };
  }
  return urlParams;
};

const getDecodedUrl = (url) => (url ? decodeURIComponent(url.replace(/%3F/g, '?')) : '');

const getEncodedUrl = (url) => (url ? encodeURIComponent(url) : '');

const getUrlLastParam = () => {
  const query = window.location.pathname;
  const vars = query.split('/');
  return vars[vars.length - 1];
};

const getDecodedUrlLastParam = () => getDecodedUrl(getUrlLastParam());

export { getUrlParams, getDecodedUrl, getDecodedUrlLastParam, getEncodedUrl };
