import i18Next from 'i18next';
import { createSignalRConnection } from '../../services/signalRFront';
import store from '../../../modules/old_to_refact/stores/configureStore';
import { getCookieAccessToken, getCookieCustomerId } from './cookiesManager';
import {
  setSignalRCallError,
  setSignalRConnection,
  setSignalRConnectionData,
  setSignalRConnectionStatus,
} from '../../../modules/old_to_refact/actions/signalrFront';
import { showAlert } from '../../../modules/state/alerts';
import { addNotification } from '../../../modules/notifications/actions';
import { hubConnectionPath } from '../rooting/hubConnectionFront';

const dispatchReducerAction = (action) => store.dispatch(action);

const setConnectionStatus = (isConnected) =>
  dispatchReducerAction(setSignalRConnectionStatus(isConnected));

const hubNewMessage = (connId, id) => {
  if (!saveConnectionId(id, connId)) {
    const notification = JSON.parse(id);
    dispatchAddNotification(notification);
    const alarmcode = notification.actionCode;
    if (alarmcode === 44801 || alarmcode === 44800) {
      const message = i18Next.t(`code.${alarmcode}`);
      dispatchReducerAction(showAlert('red', 1, `${message} : ${notification.entityName}`));
    }
  }
};

const getHubSubscriptionFunctions = () => [
  {
    name: 'Alarm',
    function: hubNewMessage,
  },
];

const dispatchSignalRConnectionOnReducer = (connection) =>
  dispatchReducerAction(setSignalRConnection(connection));

const dispatchAddNotification = (noti) => {
  dispatchReducerAction(addNotification(noti));
};

const createConnection = (onConnectionStarts, onConnectionError, connectionPath) => {
  const customerId = getCookieCustomerId();
  const accessToken = getCookieAccessToken();
  const connection = createSignalRConnection({
    connectionPath,
    customerId,
    accessToken,
    hubCanals: getHubSubscriptionFunctions(),
    onConnectionStarts,
    onConnectionError,
  });
  connection.onclose(() => setConnectionStatus(false));
  dispatchSignalRConnectionOnReducer(connection);
};

export const createSignalRConnectionOnReducer = (
  onConnectionStarts = () => setConnectionStatus(true),
  onConnectionError = () => setConnectionStatus(false),
  connectionPath = hubConnectionPath
) => {
  const state = store.getState();
  if (
    !state.signalRNotifications.connection ||
    !Object.keys(state.signalRNotifications.connection).length
  ) {
    createConnection(onConnectionStarts, onConnectionError, connectionPath);
  }
};

const saveConnectionId = (id, connId) => {
  if (connId.toLowerCase() === 'connectionid') {
    dispatchReducerAction(setSignalRConnectionData({ propName: connId, connectionId: id }));
    return true;
  }
  return false;
};

export const resetSignalRConnectionOnReducer = () => {
  const conn = store.getState().signalR.connection;
  if (conn) {
    conn.stop();
    dispatchSignalRConnectionOnReducer(undefined);
  }
};

export const setSignalRErrorFunction = (errorFunction) =>
  dispatchReducerAction(setSignalRCallError(errorFunction));
