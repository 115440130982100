export default {
  descriptionParams: {
    ACCESSCONTROL: 'Zobrazuje, zda má zařízení vstup pro kontrolu přístupu',
    ACTIVEEVENTSTOSD: 'Určuje aktivní události pro zařízení',
    APNCONFIG: 'Nastavuje APN zařízení',
    BADGE_MAITRE1: 'Kód proximity karty použit jako master klíč',
    BADGE_MAITRE10: 'Kód proximity karty použit jako master klíč',
    BADGE_MAITRE2: 'Kód proximity karty použit jako master klíč',
    BADGE_MAITRE3: 'Kód proximity karty použit jako master klíč',
    BADGE_MAITRE4: 'Kód proximity karty použit jako master klíč',
    BADGE_MAITRE5: 'Kód proximity karty použit jako master klíč',
    BADGE_MAITRE6: 'Kód proximity karty použit jako master klíč',
    BADGE_MAITRE7: 'Kód proximity karty použit jako master klíč',
    BADGE_MAITRE8: 'Kód proximity karty použit jako master klíč',
    BADGE_MAITRE9: 'Kód proximity karty použit jako master klíč',
    CONFIGRELAYSNEW1: 'Ukazuje relé, které bude aktivováno podle kanálu vyslaného zařízením',
    CONFIGRELAYSNEW2: 'Ukazuje relé, které bude aktivováno podle kanálu vyslaného zařízením',
    CONFIGRELAYSNEW3: 'Ukazuje relé, které bude aktivováno podle kanálu vyslaného zařízením',
    CONFIGRELAYSNEW4: 'Ukazuje relé, které bude aktivováno podle kanálu vyslaného zařízením',
    CONFIGRELAYSPIN1: 'Ukazuje relé, které bude aktivováno podle kanálu vyslaného zařízením',
    CONFIGRELAYSPIN2: 'Ukazuje relé, které bude aktivováno podle kanálu vyslaného zařízením',
    CONFIGRELAYSPIN3: 'Ukazuje relé, které bude aktivováno podle kanálu vyslaného zařízením',
    CONFIGRELAYSPIN4: 'Ukazuje relé, které bude aktivováno podle kanálu vyslaného zařízením',
    DISABLEMANUALPROG: 'Umožňuje registrovat zařízení ručně',
    DISABLERADIOPROG:
      'Zabraňuje registraci ovladačů pomocí rádiového programování (standardní mód)',
    FREEACTIVE: 'Aktivje/deaktivuje charakteristiky skupiny FREE systému',
    FREEGROUP0LOCKED: 'Zamyká/odemyká skupinu 0 Free systému',
    FREEGROUPNUM: 'Nastavuje číslo skupiny FREE systému',
    FREEGROUPRFPROGALLOWED: 'Umožňuje/neumožňuje rádiové programování skupin FREE systému',
    IMPBIRELE1: 'Mění nastavení relé 1 z impulzního na ON/OF',
    INSTAL: 'Zobrazuje, zda pracujete s instalačním kódem',
    MINIDDEVICE: 'Zařízení bude akceptovat zařízení jako uvedená nebo vyšší ',
    PASSWORD: '4-místný kód pro vstup do hlavního menu',
    PIN: '6-místný číselný kód odpovídající PIN kódu přijímače',
    PINCADUCAT: 'Zobrazuje, zda přijímač akceptuje zařízení s prošlým PIN kódem',
    POWER: 'Zobrazuje typ napájení zařízení',
    RELAYPRICE1: 'Hodnota k odečtení z kreditu předplatného zařízení 1',
    RELAYPRICE2: 'Hodnota k odečtení z kreditu předplatného zařízení 2',
    RELAYPRICE3: 'Hodnota k odečtení z kreditu předplatného zařízení 3',
    RELAYPRICE4: 'Hodnota k odečtení z kreditu předplatného zařízení 4',
    RELAYS: 'Počet relé v přijímači',
    RELE1IMP: 'Konfiguruje relé jako impulzní, ON/OF nebo panikové',
    RELE1TEMP:
      'Nastavuje čas, po který zůstane relé sepnuto v impulzním režimu  (od 1 do 30 sec) nebo v panikovém režimu (od 1 do 15 min)',
    RELE2IMP: 'Nastavuje relé jako impulzní, ON/OF nebo panikové',
    RELE2TEMP:
      'Nastavuje čas, po který zůstane relé sepnuto v impulzním režimu  (od 1 do 30 sec) nebo v panikovém režimu (od 1 do 15 min)',
    RELE3IMP: 'Nastavuje relé jako impulzní, ON/OF nebo panikové',
    RELE3TEMP:
      'Nastavuje čas, po který zůstane relé sepnuto v impulzním režimu  (od 1 do 30 sec) nebo v panikovém režimu (od 1 do 15 min)',
    RELE4IMP: 'Nastavuje relé jako impulzní, ON/OF nebo panikové',
    RELE4TEMP:
      'Nastavuje čas, po který zůstane relé sepnuto v impulzním režimu  (od 1 do 30 sec) nebo v panikovém režimu (od 1 do 15 min)',
    SERIALNUMBER: 'Nastavuje relé jako impulzní, ON/OF nebo panikové',
    SITECODE:
      'Site kód / kód zařízení / instalační kód  který bude poslán v protokolu Wiegand 26 se site kódem',
    TEVENT: 'Zobrazuje, zda může být řídící jednotka vybavena kartou událostí',
    TIMEZONEID: 'Nastavuje časové pásmo, kde je zařízení nainstalováno',
    TMEM: 'Zobrazuje, zda zařízení pracuje s paměťovou kartou',
    UNIQUEKEY: 'Jednotlivý klíč je pro bezdrátové operace',
    W26FORMAT: 'Nastavuje přenosový formát protokolu Wiegand 26',
    SETNEWACCESSPW: 'Změna hesla',
    SERIALNUMBERLINK: 'Sériové číslo je povinné pro bezdrátové operace',
    CONNECTIVITY_EXTENSION: 'Link card nainstalována',
    ACTIVATIONKEY: 'Aktivační klíč',
    EMMITTERNOTUSEDEVENTTIME: 'Nepoužívat čas události vysílače',
    DOOROPENEDEVENTTIME: 'Událost otevření dveří',
    SCHEDULESDATA: 'Data časových rozvrhů',
    A5KPROXY: 'ACCESS 5K Proxy',
  },
};
