export default {
  gdprPopup: {
    accept: 'Accept',
    cancel: 'Cancel',
    conditionsAgreement: 'Accept terms of use',
    close: 'Close',
    firstPolicyAgreementText:
      'CloudAssistant continues to evolve to bring you the best services and provide new features, such as the new Property Manager role so you can delegate control management to Property Administrators who wish to provide more personal service to their communities.',
    firstPolicyAgreementSecondText:
      'We have modified our policies to provide for these new services.',
    firstPolicyAgreementThirdText: 'If you have any questions, please contact us at',
    privacyText: 'Privacy Policy',
    conditionsText: 'General Conditions',
    legalNoticeText: 'Legal Notice',
    cookiesText: 'Cookies Policy',
    PolicyAgreementPopupTitle1: 'CloudAssistant is updating',
    PolicyAgreementPopupTitle2: 'new services and policies',
    PolicyAgreementPopupAnd: 'and',
  },
};
