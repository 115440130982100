import { call, fork, select, takeLatest } from 'redux-saga/effects';
import fetchEntitySaga from '../old_to_refact/sagas/utils/fetchEntitySaga';
import apiPathFactory from '../../core/old_common/rooting/apiPathFactory';
import api from '../../core/helpers/api';
import { GET_TENANTS, PUT_TENANTS } from './constants';
import { getCookieCustomerId } from '../../core/old_common/utils/cookiesManager';

export const getTenants = {
  base: () => ({
    type: GET_TENANTS.BASE,
  }),
  request: () => ({ type: GET_TENANTS.REQUEST }),
  success: (result) => ({ type: GET_TENANTS.SUCCESS, result }),
  failure: (error) => ({ type: GET_TENANTS.FAILURE, error }),
};

export const putTenants = {
  base: (newTenants) => ({
    type: PUT_TENANTS.BASE,
    newTenants,
  }),
  request: (params) => ({ type: PUT_TENANTS.REQUEST, params }),
  success: (result, payload) => ({ type: PUT_TENANTS.SUCCESS, result, payload }),
  failure: (error) => ({ type: PUT_TENANTS.FAILURE, error }),
};

//---------------------------------------------------------------
export function* getTenantsSaga() {
  try {
    const customerId = getCookieCustomerId();
    let url = apiPathFactory.tenantsGet();
    if (customerId) {
      url = apiPathFactory.customerGetAttributes(customerId);
    }
    const response = yield call(() => fetchEntitySaga(getTenants, api.get, url));
    return response;
  } catch (error) {
    return error;
  }
}

export function* putTenantsSaga({ newTenants }) {
  try {
    const state = yield select();
    const attributes = state.tenants.originalTenants;
    attributes.map((att) => {
      if (newTenants[att.tag.toLowerCase()] !== undefined) {
        att.value = newTenants[att.tag.toLowerCase()];
      }
      return att;
    });
    const customerId = getCookieCustomerId();
    const url = apiPathFactory.customerPutAttributes(customerId);
    const response = yield call(() =>
      fetchEntitySaga(putTenants, api.put, url, { data: attributes })
    );
    return response;
  } catch (error) {
    return error;
  }
}

//-----------------------------------------------------------
function* watchTenantsGet() {
  yield takeLatest(GET_TENANTS.BASE, getTenantsSaga);
}

//------------------------------------------------------------------------------

function* watchTenantsPut() {
  yield takeLatest(PUT_TENANTS.BASE, putTenantsSaga);
}

//------------------------------------------------------------------------------
export default function* badRuleWarningWatchers() {
  yield fork(watchTenantsGet);
  yield fork(watchTenantsPut);
}
