import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Box } from '@jcm-technologies/uikit/dist/atoms/Layout';
import { withRouter } from 'react-router-dom';
import { v1 as uuidv1 } from 'uuid';
import { resetCanRedirect, setCloneGrow } from '../../modules/old_to_refact/actions/genericGrow';
import GenericForm from '../GenericForm/GenericForm';
import { validateForm } from '../../core/old_common/validations/service';

import './IndividualGrowGenericForm.css';
import { addNodeToArray, updateArrayNodeByPropName } from '../../core/helpers/arrayService';
import {
  getObjectPropDifferences,
  objectsAreEquals,
} from '../../core/old_common/utils/objectsService';
import { getEncodedUrl } from '../../core/old_common/utils/getUrlParams';
import { resetEquipmentScroll } from '../../modules/old_to_refact/actions/equipments';

class IndividualGrowGenericForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props.item,
      formErrors: [],
      saveFlag: true,
      isNameFocused: false,
      saveNowRedirect: false,
    };
  }

  nameRef;

  componentDidMount = () => {
    const { isCloned, resetCanRedirect, baseURI, goToIdRedirect, history, canRedirect } =
      this.props;
    //resetCanRedirect();
    if (isCloned) {
      this.setState({ saveFlag: false });
      if (this.nameRef && !this.nameRef.value) {
        this.nameRef.focus();
      }
    }

    if (canRedirect) {
      if (goToIdRedirect) {
        history.push(`${baseURI}/${goToIdRedirect}`);
      } else {
        history.push(baseURI);
      }

      setCloneGrow(false, {});
      resetCanRedirect();
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    const {
      baseURI,
      setCloneGrow,
      canRedirect,
      item,
      resetCanRedirect,
      history,
      isCloned,
      goToIdRedirect,
    } = this.props;
    if (this.state.saveNowRedirect) {
      setTimeout(() => {
        const item = document.getElementById('cancel');
        if (item) {
          item.click();
        }
      }, 2000);
    }
    if (canRedirect) {
      if (goToIdRedirect) {
        history.push(`${baseURI}/${goToIdRedirect}`);
      } else {
        history.push(baseURI);
      }

      setCloneGrow(false, {});
      resetCanRedirect();
    } else if (!objectsAreEquals(prevProps.item, item)) {
      const objectsDiffProps = getObjectPropDifferences(item, prevProps.item);
      this.setState({ ...objectsDiffProps });
    }
    if (isCloned && !this.state.isNameFocused && this.nameRef && !this.nameRef.value) {
      this.setState({ isNameFocused: true });
      this.nameRef.focus();
    }

    if (prevProps?.match?.url !== this.props.match?.url) {
      this.setState({ ...prevState, ...this.props.item, saveFlag: false });
    }
  };

  handleChange = (e) => {
    const { createItemOnChange } = this.props;
    let inputValue = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    let stateKey = e.target.id;
    if (stateKey === 'name' && inputValue.includes('%')) {
      return;
    }
    if (Object.keys(this.state).find((key) => key === stateKey) === undefined) {
      stateKey = this.getFieldByPropName('id', e.target.id).parentLevel;
      const stateArrayItem = this.getArrayItemByPropName(stateKey, 'tag', e.target.id);
      if (stateArrayItem !== undefined) {
        inputValue = updateArrayNodeByPropName(
          this.state[stateKey],
          { ...stateArrayItem, value: inputValue },
          'id'
        );
      } else {
        inputValue = addNodeToArray(
          this.state[stateKey],
          createItemOnChange(inputValue, this.getFieldByPropName('id', e.target.id), this.state)
        );
      }
    }
    const validationFormErrors = validateForm(
      this.getFormFields({ ...this.state, [stateKey]: inputValue })
    );
    const { formErrors } = this.state;
    const newErrors = [...formErrors];
    const validationInputError = validationFormErrors.errors.find((error) => error.id === stateKey);
    if (validationInputError) {
      newErrors.push(validationInputError);
    } else {
      const errorIndex = newErrors.findIndex((error) => error.id === stateKey);
      if (errorIndex > -1) {
        newErrors.splice(errorIndex, 1);
      }
    }
    this.setState({ [stateKey]: inputValue, formErrors: newErrors });

    if (validationFormErrors.errors.length > 0) {
      this.setState({ saveFlag: true });
    } else {
      this.setState({ saveFlag: false });
    }
  };

  getArrayItemByPropName = (stateKey, propName, propValue) =>
    this.state[stateKey] && Array.isArray(this.state[stateKey])
      ? this.state[stateKey].find((parentValue) => parentValue[propName] === propValue)
      : undefined;

  handleSubmit = (e) => {
    const {
      successUrlRedirect,
      creationURI,
      updateItem,
      createItem,
      match,
      getContentToCreate,
      isCloned,
    } = this.props;
    const formErrors = validateForm(this.getFormFields());
    const localState = { ...this.state };
    if (!formErrors.hasError) {
      if (match.params.id !== creationURI) {
        updateItem(localState);
        if (successUrlRedirect) {
          this.setState({ saveNowRedirect: true });
        }
      } else {
        const contentToSave = getContentToCreate
          ? getContentToCreate(localState)
          : { ...localState, id: localState.id || uuidv1() };
        createItem(contentToSave, isCloned);
        if (successUrlRedirect) {
          this.setState({ saveNowRedirect: true });
        }
      }
    } else {
      this.setState({ formErrors: formErrors.errors });
    }
  };

  convertURL = (url) => {
    let finalURL = url;
    const splittedURLWithParams = finalURL
      .split('~~~')
      .filter((urlSection) => urlSection.indexOf('/') === -1); // TODO: definir formato paramsURL
    splittedURLWithParams.forEach((urlParam) => {
      const stateValue = this.state[urlParam] || '';
      finalURL = finalURL.replace(
        `~~~${urlParam}~~~`,
        getEncodedUrl(stateValue).replace(/\?/g, '%3F').replace(/\&/g, '%26')
      );
    });
    return finalURL;
  };

  getRedirectURL = (state) => {
    const { baseURI, redirectURL } = this.props;
    if (redirectURL) {
      return this.convertURL(redirectURL);
    }

    const decodedName = encodeURIComponent(state.name);
    return `${baseURI}/${decodedName
      .replace(/\//g, '%2F')
      .replace(/\?/g, '%3F')
      .replace(/\&/g, '%26')
      .replace('%09', '%2509')}`;
  };

  handleCancel = () => {
    const { history, setCloneGrow, baseURI, resetEquipmentScroll, baseRoute } = this.props;
    setCloneGrow(false, {});
    resetEquipmentScroll();
    history.push(baseURI.replace('%09', '%2509'));
  };

  getFormFields = (state = this.state) => {
    const fields = this.props.fields(state).map((row) =>
      row.map((field) => ({
        ...field,
        value: this.getFieldValue(field, state),
        onChange: field.onChange || this.handleChange,
        componentRef:
          field.id === 'name'
            ? (input) => {
                this.nameRef = input;
              }
            : () => {},
      }))
    );
    return fields;
  };

  getField = (fieldId, state = this.state) => {
    const field = this.getFormFields(state).flat();
    return field.find((field) => field.id === fieldId);
  };

  getFieldValue = ({ parentLevel, value }, state = this.state) => {
    const finalValue = state[value];
    if (finalValue === undefined) {
      const originalParentName = state[parentLevel];
      const nodeToUpdate = originalParentName
        ? originalParentName.find((parentValue) => parentValue.tag === value)
        : undefined;
      return nodeToUpdate ? nodeToUpdate.value : '';
    }
    return finalValue;
  };

  getFieldByPropName = (fieldPropName, fieldPropValue) => {
    const fieldsArray = this.props.fields(this.state).flat();
    return fieldsArray.find((field) => field[fieldPropName] === fieldPropValue);
  };

  render() {
    const {
      tenants,
      submitText,
      onFlyformErrors,
      isSaveButtonEnabled,
      forceSaveChangePopup,
      id,
      idDiv,
      alignColumn,
      match,
      history,
      isSubmitHidden,
      justifyContent,
    } = this.props;
    const errors = [...(this.state.formErrors || []), ...(onFlyformErrors || [])]; // TODO: delete when the setState works fine
    const { saveFlag } = this.state;

    return (
      <Box id={idDiv}>
        <GenericForm
          id={idDiv}
          buttonsId={id}
          isSubmitDisabled={
            (saveFlag && !isSaveButtonEnabled) || (onFlyformErrors && onFlyformErrors.length)
          }
          isSubmitHidden={isSubmitHidden}
          tenants={tenants}
          fields={this.getFormFields()}
          handleSubmit={this.handleSubmit}
          alignColumn={alignColumn}
          justifyContent={justifyContent}
          submitText={submitText}
          isCancelVisible
          handleCancel={this.handleCancel}
          formErrors={errors}
          match={match}
          forceSaveChangePopup={forceSaveChangePopup || false}
          history={history}
        />
      </Box>
    );
  }
}

const mapStateToProps = (state) => ({
  tenants: state.tenants,
  canRedirect: state.genericGrow.canRedirect,
  isCloned: state.genericGrow.isCloned,
  redirectURL: state.genericGrow.redirectURL,
});

const individualUniversalGroupFormWithRouter = withRouter(IndividualGrowGenericForm);

export default connect(mapStateToProps, {
  setCloneGrow,
  resetCanRedirect,
  resetEquipmentScroll,
})(individualUniversalGroupFormWithRouter);
