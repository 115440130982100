import { Modal } from '@jcm-technologies/uikit/dist/molecules/Modal';
import { Box, Flex, Grid, GridItem } from '@jcm-technologies/uikit/dist/atoms/Layout';
import { Text } from '@jcm-technologies/uikit/dist/atoms/Typography';
import { Button } from '@jcm-technologies/uikit/dist/atoms/Button';
import { Input } from '@jcm-technologies/uikit/dist/atoms/Input';
import { Image } from '@jcm-technologies/uikit/dist/atoms/Image';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Icon } from '@jcm-technologies/uikit/dist/atoms/Icon';
import IconPlus from '@jcm-technologies/uikit/dist/atoms/Icons/IconPlus';
import IconMinus from '@jcm-technologies/uikit/dist/atoms/Icons/IconMinus';
import { getContrastColor } from '../../../../../core/helpers/colorManager';
import { actionMapper, keyAvailableMapper, keyType, rolesNoConsumeLicenses } from '../../config';
import { isNumberOnRange, isNumberValidation } from '../../../../../core/helpers/numbers';

export const EditLicensesModal = ({
  isOpenedEditModal,
  setIsOpenedEditModal,
  setDataToEdit,
  handleEditLicense,
  dataToEdit,
}) => {
  const { t } = useTranslation();
  const { color1 } = useSelector((state) => state.tenants);
  const { licenses } = useSelector((state) => state.customers.licenses);
  const [dataForm, setDataForm] = useState({
    honoa: '',
    kaimai: '',
    cloudAssistant: '',
  });
  const [hasChange, setHasChange] = useState(false);
  const [typeAction, setTypeAction] = useState({
    honoa: null,
    kaimai: null,
    cloudAssistant: null,
  });

  useEffect(() => {
    if (!isOpenedEditModal) {
      setTypeAction({
        honoa: null,
        kaimai: null,
        cloudAssistant: null,
      });
      setDataForm({
        honoa: '',
        kaimai: '',
        cloudAssistant: '',
      });
    }
  }, [isOpenedEditModal]);

  useEffect(() => {
    if (dataForm) {
      let count = 0;
      for (const argumentsKey in dataForm) {
        if (dataForm[argumentsKey]) {
          count += 1;
        }
      }
      if (!count) {
        setHasChange(false);
      }
    }
  }, [dataForm]);

  return (
    <Modal
      isOpen={isOpenedEditModal}
      closeWithButton
      id='edit-licenses'
      title={t('general.edit')}
      onModalClose={() => {
        setIsOpenedEditModal(false);
        setDataToEdit(null);
      }}
      width={[300, 500, 664]}
    >
      <Modal.Content>
        <Flex flexDirection='column'>
          <Box width={(60, 60, 250)} height={60} alignSelf='center' justifySelf='center'>
            <Image
              src={dataToEdit?.logo}
              alt={dataToEdit?.name}
              styleImg={{ maxWidth: '100%', width: '100%', maxHeight: '100% ' }}
            />
          </Box>
          <Box paddingY={3} paddingX={2}>
            {licenses?.map((element) => (
              <Grid
                key={element.value}
                gridTemplateAreas="'. . .'"
                gridTemplateRows='1fr'
                gridTemplateColumns='1fr 1fr 1fr'
                marginY={1}
                paddingX={3}
                style={{ alignItems: 'center', justifyContent: 'center' }}
              >
                <GridItem>
                  <Text>{t(`title.${element.value}`)}</Text>
                </GridItem>
                <GridItem>
                  <Text>
                    {t(`licenses.availableLicenses`)}:{' '}
                    <Text color='green' as='span'>
                      {element.availableLicenses}
                    </Text>
                  </Text>
                </GridItem>

                <Grid gridTemplateColumns='1fr 1fr'>
                  <Input
                    id={element.value}
                    name={element.value}
                    max={element.availableLicenses}
                    sizeInput='small'
                    maxWidth={50}
                    min={0}
                    maxLength='9'
                    pattern='\d{1,9}'
                    minWidth={60}
                    textAlign='right'
                    value={dataForm[element.value]}
                    onChange={(name, value) => {
                      if (isNumberValidation(value)) {
                        if (typeAction[element.value] === 'plus') {
                          setDataForm({
                            ...dataForm,
                            [element.value]: isNumberOnRange(value, 0, element.availableLicenses),
                          });
                        } else if (typeAction[element.value] === 'minus') {
                          setDataForm({
                            ...dataForm,
                            [element.value]: isNumberOnRange(
                              value,
                              0,
                              dataToEdit?.[keyAvailableMapper[element.value]]
                            ),
                          });
                        } else {
                          setDataForm({
                            ...dataForm,
                            [element.value]: value,
                          });
                        }
                      } else {
                        setDataForm({
                          ...dataForm,
                          [element.value]: dataForm[element.value],
                        });
                      }
                      if (!value) {
                        setTypeAction({ ...typeAction, [element.value]: null });
                      }
                    }}
                  />
                  <Flex
                    flexDirection='column'
                    alignItems='center'
                    justifyContent='center'
                    marginLeft={1}
                    width='auto'
                  >
                    <Flex>
                      <Button
                        sizeButton='none'
                        backgroundColor={typeAction[element.value] === 'plus' ? color1 : 'black'}
                        borderRadius={1}
                        padding='4px'
                        disabled={
                          !dataForm[element.value] ||
                          //This conditional checks if you can add  Admin lite, porperty manager and property manager lite licenses when no cloudA licenses left.
                          //So with 0 licenses you can add only 1 license. This is because this roles don't consume a license
                          !(
                            (element.value === 'cloudAssistant' &&
                              ((!element.availableLicenses &&
                                rolesNoConsumeLicenses.includes(dataToEdit?.roleMask)) ||
                                element.availableLicenses > 0)) ||
                            element.availableLicenses > 0
                          )
                        }
                        marginBottom='2px'
                        action={() => {
                          setTypeAction({ ...typeAction, [element.value]: 'plus' });

                          setHasChange(true);
                          setDataForm({
                            ...dataForm,
                            [element.value]: isNumberOnRange(
                              dataForm[element.value],
                              0,
                              element.value === 'cloudAssistant' &&
                                rolesNoConsumeLicenses.includes(dataToEdit?.roleMask)
                                ? dataToEdit?.[keyAvailableMapper[element.value]]
                                  ? 0
                                  : 1
                                : element.availableLicenses
                            ),
                          });
                        }}
                      >
                        <Icon color='white' sizeIcon='display12'>
                          <IconPlus />
                        </Icon>
                      </Button>
                      <Text
                        sizeText='display12'
                        as='span'
                        width={60}
                        marginLeft={1}
                        style={{ whiteSpace: 'nowrap' }}
                      >
                        Max.{' '}
                        {element.value === 'cloudAssistant' &&
                        rolesNoConsumeLicenses.includes(dataToEdit?.roleMask)
                          ? dataToEdit?.[keyAvailableMapper[element.value]]
                            ? 0
                            : 1
                          : element.availableLicenses}
                      </Text>
                    </Flex>
                    <Flex>
                      <Button
                        sizeButton='none'
                        backgroundColor={typeAction[element.value] === 'minus' ? color1 : 'black'}
                        borderRadius={1}
                        padding='4px'
                        disabled={!dataForm[element.value]}
                        action={() => {
                          setTypeAction({ ...typeAction, [element.value]: 'minus' });

                          setHasChange(true);
                          setDataForm({
                            ...dataForm,
                            [element.value]: isNumberOnRange(
                              dataForm[element.value],
                              0,
                              dataToEdit?.[keyAvailableMapper[element.value]]
                            ),
                          });
                        }}
                      >
                        <Icon color='white' sizeIcon='display12'>
                          <IconMinus />
                        </Icon>
                      </Button>
                      <Text
                        sizeText='display12'
                        as='span'
                        width={60}
                        marginLeft={1}
                        style={{ whiteSpace: 'nowrap' }}
                      >
                        Max. {dataToEdit?.[keyAvailableMapper[element.value]]}
                      </Text>
                    </Flex>
                  </Flex>
                </Grid>
              </Grid>
            ))}
          </Box>
        </Flex>
      </Modal.Content>
      <Modal.Actions>
        <Flex alignItems='center' justifyContent='flex-end'>
          <Button
            backgroundColor={color1}
            marginRight={2}
            disabled={!hasChange}
            color={getContrastColor(color1)}
            action={() => {
              const data = {};
              for (const key in dataForm) {
                if (typeAction[key]) {
                  data[keyType[key]] = `${actionMapper[typeAction[key]]}${dataForm[key]}`;
                }
              }
              handleEditLicense(dataToEdit.id, data);
            }}
          >
            {t('general.save')}
          </Button>
          <Button
            sizeText='display14'
            backgroundColor='blackLight'
            id='cancel-button'
            action={() => {
              setIsOpenedEditModal(false);
              setDataToEdit(null);
            }}
          >
            {t('general.cancel')}
          </Button>
        </Flex>
      </Modal.Actions>
    </Modal>
  );
};
