export default {
  instalationGroups: {
    exportExcel: 'Excel letöltése',
    exportPDF: 'PDF letöltése',
    identification: 'Személyazonosító',
    place: 'Bemenet',
    enrollFOB: 'Beiratkozási kód',
    config: 'Konfiguráció',
    deleteConfirmation: 'Biztosan törölni szeretné a csoportot?',
    description: 'Leírás',
    facilityName: 'Létesítmény neve',
    replace: 'Helyettesítés',
    delete: 'Törlés',
    deleteFobConfirmation: 'Biztosan törölni szeretné a másolt távirányítót?',
    alarms: 'Riasztás',
    selectConfigFields: 'Válassza ki a felhasználó/ távirányító mezőket az exportáláshoz',
    totalSelected: 'Összesen kiválasztva',
  },
};
