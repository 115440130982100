import React, { Component } from 'react';
import { connect } from 'react-redux';
import './SwitchCheckbox.css';
import Div from './Div';
import Label from './Label';

class SwitchCheckbox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOver: false,
    };
  }

  onMouseOver = () => this.setState({ isOver: true });

  onMouseLeave = () => this.setState({ isOver: false });

  render() {
    const {
      t,
      onChange,
      className,
      value,
      id,
      placeHolder,
      tenants,
      labelClassName,
      keepLabelDefaultColor,
      labelId,
    } = this.props;
    const { isOver } = this.state;
    return (
      <Label
        id={labelId}
        isOver={isOver}
        className={labelClassName}
        onMouseOver={this.onMouseOver}
        onMouseLeave={this.onMouseLeave}
        tenants={tenants}
      >
        <span className={`switch ${className}`}>
          <input
            type='checkbox'
            className='slider'
            id={id}
            checked={value}
            onChange={(e) => onChange(e)}
          />
          <Div isOver={isOver} tenants={tenants} isChecked={value} />
        </span>
        <span className={keepLabelDefaultColor ? 'switch-check-box-default' : ''}>
          {placeHolder}
        </span>
      </Label>
    );
  }
}

const mapStateToProps = (state) => ({
  tenants: state.tenants,
});

export default connect(mapStateToProps, {})(SwitchCheckbox);
