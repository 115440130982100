import React, { Component } from 'react';
import $ from 'jquery';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import { IconSpan } from '../IconSpan/IconSpan';
import InstallMaps from '../../core/old_common/utils/InstallMaps';
import './custom.css';
import '../InstalationMapPupUpContent/custom.css';
import { exportComponentToPdf } from '../../core/helpers/getImagesBase64';

export class UniversalGroupsMapPopUpContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      groups: '',
    };
  }

  componentDidMount = () => {
    this.setMap();
  };

  componentDidUpdate = () => {
    this.expandMap();
  };

  setMap = () => {
    const { mapInfo, universalGroupId } = this.props;

    if (mapInfo) {
      this.setState({
        groups: InstallMaps.outputUniversalGroups(mapInfo, universalGroupId),
      });
    }
    this.expandMap();
  };

  expandMap = () => {
    $('.toggle-branch').on('click', function () {
      const link = $(this);
      link
        .parent()
        .siblings('.branch')
        .toggle('linear', function () {
          if ($(this).is(':visible')) {
            link.children('i').removeClass('fa-plus').addClass('fa-minus');
          } else {
            link.children('i').removeClass('fa-minus').addClass('fa-plus');
          }
        });
    });
  };

  render() {
    const { mapInfo, onCloseForm, t } = this.props;
    return (
      <div className='modal-content'>
        <div className='modal-header'>
          <h4 className='modal-title' id='group-tree-title'>
            {mapInfo ? mapInfo.name : ''}
          </h4>
          <span>
            {this.state.groups.length > 44 || this.state.groups === '' ? (
            <span
              className='fas fa-file-pdf export-pdf-map'
              data-dismiss='modal'
              aria-label='Export to PDF'
              onClick={() =>
                exportComponentToPdf(
                  'universalGroupId',
                  mapInfo ? `${moment().format('YYYY-MM-DD')}_${mapInfo.name}` : undefined
                )
              }
            />):''
            }
            <button
              type='button'
              className='close'
              data-dismiss='modal'
              aria-label='Close'
              onClick={onCloseForm}
            >
              <span aria-hidden='true'>&times;</span>
            </button>
          </span>
        </div>

        <div className='modal-body'>
          {this.state.groups.length > 44 || this.state.groups === '' ? (
            <div className='testFlex' id='universalGroupId'>
              <fieldset className='groups-col'>
                <legend>{t('instalationsMaps.structure')}</legend>
                <div
                  className='tree-container'
                  dangerouslySetInnerHTML={{ __html: this.state.groups }}
                  id='group-tree'
                />
              </fieldset>
            </div>
          ) : (
            <div className='no-content'>
              <h6>
                <IconSpan
                  tenants={this.props.tenants}
                  defaultColor='grey'
                  iconClassName='fas fa-info-circle empty-map-warn'
                />
                {t('instalationsMaps.noUGContent')}
              </h6>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  tenants: state.tenants,
});

const UniversalGroupsMapPopUpContentTranslation = withTranslation()(UniversalGroupsMapPopUpContent);
const UniversalGroupsMapWithRouter = withRouter(UniversalGroupsMapPopUpContentTranslation);
const UniversalGroupsMapWithRedux = connect(mapStateToProps, {})(UniversalGroupsMapWithRouter);

export default UniversalGroupsMapWithRedux;
