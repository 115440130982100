import styled from 'styled-components';

export default styled.div`
  position: absolute;
  cursor: pointer;
  top: 4px;
  left: 0;
  right: 0;
  bottom: -4px;
  transition: 0.4s;
  border-radius: 34px;
  border: 1px solid;
  border-color: ${(props) => (props.isOver ? props.tenants.color1 : 'rgba(200,200,200,.9)')};
  ${(props) =>
    props.isChecked ? `background-color: ${props.tenants.color1};` : 'background-color: white;'}

  &:before {
    position: absolute;
    content: '';
    height: calc(100% - 2px);
    width: calc(50% - 1px);
    left: 1px;
    bottom: 1px;
    background-color: rgba(235, 235, 240, 0.9);
    transition: 0.4s;
    border-radius: 50%;
    border: 1px solid;
    border-color: ${(props) => (props.isOver ? props.tenants.color1 : 'rgba(200,200,200,.6)')};
    ${(props) =>
      props.isChecked
        ? `
    transform: translateX(100%);`
        : ''}
  }
`;
