import createFetchTypes from '../../../core/typeActions/types';
import neighbourAction from '../actions/neighbours';

export const NEIGHBOUR_POST = createFetchTypes('NEIGHBOUR_POST');
export const NEIGHBOUR_PUT = createFetchTypes('NEIGHBOUR_PUT');
export const NEIGHBOUR_GET = createFetchTypes('NEIGHBOUR_GET');
export const NEIGHBOUR_DELETE = createFetchTypes('NEIGHBOUR_DELETE');
export const SHOW_NEIGHBOUR_POPUP = createFetchTypes('SHOW_NEIGHBOUR_POPUP');
export const SET_EDITED_POPUP = createFetchTypes('SET_EDITED_POPUP');
export const RESET_EDITED_POPUP = createFetchTypes('RESET_EDITED_POPUP');

const newNeighbour = {
  id: '',
  email: '',
  phone: '',
  password: 'a',
  name: '',
  surname: '',
  code: {
    floor: '',
    door: '',
  },
};

const initialState = {
  neighbours: [],
  showPopUp: false,
  needRefreshNeighbours: false,
  popUpToEdit: {
    id: '',
    email: '',
    phone: '',
    password: 'a',
    name: '',
    surname: '',
    code: {
      floor: '',
      door: '',
    },
  },
};

export default function neighbourReducer(state = initialState, action) {
  if (action.type !== undefined) {
    switch (action.type) {
      case NEIGHBOUR_GET.SUCCESS:
        return {
          ...state,
          neighbours: [...action.result.pageItems],
        };
      case NEIGHBOUR_POST.SUCCESS:
        return {
          ...state,
          postErrors: false,
          showPopUp: false,
          needRefreshNeighbours: true,
        };
      case NEIGHBOUR_POST.FAILURE:
        return {
          ...state,
          postErrors: true,
          showPopUp: true,
          popUpToEdit: newNeighbour,
        };
      case NEIGHBOUR_PUT.SUCCESS:
        return {
          ...state,
          postErrors: false,
          needRefreshNeighbours: true,
        };
      case SHOW_NEIGHBOUR_POPUP.BASE:
        return {
          ...state,
          showPopUp: action.status,
        };
      case SET_EDITED_POPUP.BASE:
        return {
          ...state,
          popUpToEdit: action.neighbour,
        };
      case RESET_EDITED_POPUP.BASE:
        return {
          ...state,
          popUpToEdit: newNeighbour,
        };
      case NEIGHBOUR_DELETE.SUCCESS:
        return { ...state, needRefreshNeighbours: true };
      case neighbourAction.RESET_REFRESH_NEIGHBOURS:
        return { ...state, needRefreshNeighbours: false };
      default:
        return state;
    }
  }
  return state;
}
