export const isNumberValidation = (value) => {
  if (!isNaN(value)) {
    return true;
  }
  return false;
};

export const isNumberOnRange = (value, min, max) => {
  let rangeValue = value;
  if (parseInt(value) > parseInt(max)) {
    rangeValue = max;
  } else if (parseInt(value) < parseInt(min)) {
    rangeValue = min;
  } else if (value === '') {
    return 0;
  }

  return rangeValue;
};
