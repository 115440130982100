export default {
  gdprPopup: {
    accept: 'Elfogad',
    cancel: 'Mégsem',
    conditionsAgreement: 'Használati feltételek elfogadása',
    close: 'Bezár',
    firstPolicyAgreementText:
      'A CloudAssistant folyamatosan fejlődik, hogy a legjobb szolgáltatást tudja nyújtani Önnek és új funkciókat biztosítson felhasználójának. Ilyen az új Ingatlankezelő funkció, melynek segítségével átruházhatja a kezelési szintű irányítást az Ingatlankezelő Vállalkozásokra, akik még személyre szabottabb szolgáltatást tudnak nyújtani a lakóközösségeiknek.',
    firstPolicyAgreementSecondText:
      'Az új szolgáltatások következtében módosítottuk irányelveinket.',
    firstPolicyAgreementThirdText:
      'Ha bármilyen kérdése van, kérjük, lépjen kapcsolatba velünk a következő címen',
    privacyText: 'Adatvédelmi szabályzatunk',
    legalNoticeText: 'Jogi nyilatkozat',
    cookiesText: 'Sütikre vonatkozó irányelvek',
    conditionsText: 'Všeobecné podmínky',
    PolicyAgreementPopupTitle1: 'A CloudAssistant éppen frissül',
    PolicyAgreementPopupTitle2: 'szolgáltatások és irányelvek',
    PolicyAgreementPopupAnd: 'és',
  },
};
