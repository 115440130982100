import React, { cloneElement, useRef } from 'react';
import styled from 'styled-components';
import moment from 'moment-timezone';
import { Flex } from '@jcm-technologies/uikit/dist/atoms/Layout';
import { Input } from '@jcm-technologies/uikit/dist/atoms/Input';
import IconSearch from '@jcm-technologies/uikit/dist/atoms/Icons/IconSearch';
import { getCookieDateTimeFormat } from '../../../core/old_common/utils/cookiesManager';
import { event } from 'jquery';

export const CellRender = (props) => {
  const { dataItem } = props.originalProps;
  const cellField = props.originalProps.field;
  const inEditField = dataItem[props.editField || ''];
  const onBlur =
    cellField !== 'selected' ? (event) => props.onBlur(dataItem, cellField, event) : () => {};
  const onClick = cellField !== 'selected' ? () => props.enterEdit(dataItem, cellField) : () => {};
  const additionalProps =
    cellField && cellField === inEditField
      ? {
          ref: (td) => {
            const input = td && td.querySelector('input');
            const { activeElement } = document;
            if (
              !input ||
              !activeElement ||
              input === activeElement ||
              !activeElement.contains(input)
            ) {
              return;
            }
            if (input.type === 'checkbox') {
              input.focus();
            } else {
              input.select();
            }
          },
          onBlur: () => {
            onBlur();
          },
          onKeyDown: (e) => {
            if (e.keyCode === 13 || e.keyCode === 9) {
              e?.preventDefault();
              onBlur(e);
            }
          },
        }
      : {
          onClick: () => {
            onClick();
          },
        };
  const clonedProps = {
    ...props.td.props,
    ...additionalProps,
  };
  return cloneElement(props.td, clonedProps, props.td.props.children);
};
export const RowRender = (props) => {
  const {
    dataItem: { locked },
  } = props.originalProps;
  const lockedRowUser = locked ? 'grid-kendo-row-locked' : '';
  const trProps = {
    ...props.tr.props,
    className: `${props.tr.props.className} ${lockedRowUser}`,
    onBlur: () => {
      props.exitEdit();
    },
  };
  return cloneElement(
    props.tr,
    {
      ...trProps,
    },
    props.tr.props.children
  );
};

const SelectWrapperLikeText = styled('select')({
  fontSize: 14,
  lineHeight: '20px',
  border: 'none',
  textAlign: 'left',
  background: 'transparent',
});
export const SelectCell = ({ selectedName, options, id, action, getActions = () => {} }) => {
  return (
    <td colSpan='1'>
      <SelectWrapperLikeText
        id={`select-user-role-${id}`}
        onClick={(event) => {
          event.stopPropagation();
          event.preventDefault();
          getActions();
        }}
        onChange={(event) => {
          action(options?.find((option) => option.name === event?.target?.value));
        }}
      >
        {options?.map((option) => (
          <option value={option.value} selected={option?.name === selectedName}>
            {option.name}
          </option>
        ))}
      </SelectWrapperLikeText>
    </td>
  );
};

export const CustomFilter = ({ onChange, value }) => {
  const filterRef = useRef();
  const formattedData = `${getCookieDateTimeFormat()?.toUpperCase()} HH:mm:ss`;
  const inRange = (current, values) => {
    if (Object.prototype.toString.call(current) === '[object Date]') {
      const date = moment(current);
      const dateFormat = date.format(formattedData);
      return (dateFormat || '').includes(values);
    }
    return (current || '').includes(values);
  };

  const handleChange = (event) => {
    onChange({
      value: filterRef.current.value,
      operator: inRange,
      syntheticEvent: event.syntheticEvent,
    });
  };

  return (
    <Flex alignItems='center'>
      <Input
        id='table-filter'
        name='table-filter'
        sizeInput='tab'
        iconRight={<IconSearch />}
        value={value || ''}
        ref={filterRef}
        onChange={handleChange}
      />
    </Flex>
  );
};
