import React from 'react';
import PropTypes from 'prop-types';
import { Flex } from '@jcm-technologies/uikit/dist/atoms/Layout';
import { IconTooltip } from '@jcm-technologies/uikit/dist/molecules/IconTooltip';
import { LinkText } from '@jcm-technologies/uikit/dist/atoms/Typography';
import IconCloudDownload from '@jcm-technologies/uikit/dist/atoms/Icons/IconCloudDownload';
import IconVideo from '@jcm-technologies/uikit/dist/atoms/Icons/IconVideo';
import { useTranslation } from 'react-i18next';
import { statusCheckIcon, versionVideoAssistant } from './config';
import { SpanStyle } from './styles';
import { getActualLanguage } from '../../../../core/helpers/getLanguages';

const Panel = ({
  handlerDrop,
  assistantPanelID,
  tenants,
  isConnectionDone,
  handleSendTestMessage,
  handleShowWarningToast,
  statusAssistantState,
}) => {
  const { t } = useTranslation();
  return (
    <Flex
      marginY={3}
      justifyContent='space-between'
      flexDirection={['column', 'column', 'row']}
      onDrop={handlerDrop}
      onDragOver={(event) => event.preventDefault()}
    >
      <Flex flexGrow={1} justifyContent='center' alignItems='center' color='grey'>
        <SpanStyle sizeText='display14'>
          {` ${t('assistant.assistantID')} : ${assistantPanelID?.split('@')[0] ?? ''}`}
        </SpanStyle>
      </Flex>
      <Flex flexGrow={1} justifyContent='center' alignItems='center' color='grey'>
        <SpanStyle
          tenants={tenants}
          sizeText='display14'
          id='testAssistant'
          color='grey'
          disabled={!isConnectionDone}
          onClick={
            isConnectionDone && assistantPanelID?.split('@')[0] !== ''
              ? handleSendTestMessage
              : handleShowWarningToast
          }
        >
          {t('assistant.testAssistantPanel')}
        </SpanStyle>
        {statusCheckIcon[statusAssistantState]}
      </Flex>
      <Flex
        flexGrow={1}
        justifyContent='center'
        alignItems='center'
        color='grey'
        flexDirection='column'
      >
        <Flex>
          <LinkText
            as='a'
            href='https://staticfiles.jcm-tech.net/ca/v4/prod/panel/assistant.application'
            target='_blank'
            rel='noreferrer'
          >
            <SpanStyle tenants={tenants} color='grey' style={{ display: 'inline-flex' }}>
              {t('assistant.downloadAssistantPanel')}
            </SpanStyle>
          </LinkText>
          <IconTooltip
            toolTipContent={t('assistant.downloadAssistantPanel')}
            sizeIcon='display24'
            color='grey'
            marginLeft={1}
          >
            <IconCloudDownload />
          </IconTooltip>
        </Flex>

        <Flex>
          <LinkText
            as='a'
            href='https://staticfiles.jcm-tech.net/ca/v4/prod/Drivers.zip'
            target='_blank'
            rel='noreferrer'
          >
            <SpanStyle tenants={tenants} color='grey' style={{ display: 'inline-flex' }}>
              {t('assistant.downloadDrivers')}
            </SpanStyle>
          </LinkText>
          <IconTooltip
            toolTipContent={t('assistant.downloadDrivers')}
            sizeIcon='display24'
            color='grey'
            marginLeft={1}
          >
            <IconCloudDownload />
          </IconTooltip>
        </Flex>
        <Flex>
          <LinkText
            href={versionVideoAssistant[getActualLanguage() || 'en-GB']}
            target='_blank'
            rel='noreferrer'
          >
            <SpanStyle tenants={tenants} color='grey' style={{ display: 'inline-flex' }}>
              {t('assistant.videoAssistant')}
            </SpanStyle>
          </LinkText>
          <IconTooltip
            toolTipContent={t('assistant.videoAssistant')}
            sizeIcon='display24'
            color='grey'
            marginLeft={1}
          >
            <IconVideo />
          </IconTooltip>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Panel;

Panel.propTypes = {
  handlerDrop: PropTypes.func.isRequired,
  assistantPanelID: PropTypes.string.isRequired,
  tenants: PropTypes.shape().isRequired,
  isConnectionDone: PropTypes.bool.isRequired,
  handleSendTestMessage: PropTypes.func.isRequired,
  handleShowWarningToast: PropTypes.func.isRequired,
  statusAssistantState: PropTypes.string.isRequired,
};
