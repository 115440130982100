export default {
  equipments: {
    add: "Ajouter de l'équipement",
    createEquipment: "Créer de l'équipement",
    deleteConfirmation: 'Voulez-vous vraiment supprimer cet équipement?',
    facilityName: "Nom de l'installation",
    productName: 'Nom du produit',
    parameters: 'Paramètres',
    relays: 'Relais',
    fobs: 'Émetteurs',
    groups: 'Groupes',
    actions: 'Actions',
    events: 'Événements',
    info: 'Information',
    'remote comm': 'Communications à distance',
    changePasswordAction: 'Changer le mot de passe',
    adjustDateTimeAction: "Ajuster la date et l'heure",
    formatMemory: 'Formater la mémoire',
    disconnectedKey: 'Déconnecté',
    connectedKey: 'Lié',
    notFoundKey: 'Appareil non trouvé',
    saveConfiguration: 'Enregistrer la configuration',
    compareMemory: 'Comparer la mémoire',
    savedMemory: 'Mémoire sauvegardée',
    sendMemoryAction: 'Mettre à jour la mémoire localement',
    saveMemoryAction: 'Mettre à jour la mémoire à distance',
    verifyConnectivity: 'Vérifier la connectivité',
    getdoorstatus: 'Obtenir le statut de la porte',
    device: 'Dispositif',
    deleteEventsConfirmation: 'Voulez-vous vraiment supprimer les événements?',
  },
};
