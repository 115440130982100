/* Redux state init */
import { OWNERS_LOGS_LIST, OWNERS_PENDING_LIST_GET, OWNERS_SINGLELIST_GET } from './constants';

const initialState = {
  ownersSingleList: [],
  ownersPendingList: [],
  logs: [],
};

export default function ownersReducer(state = initialState, action) {
  if (action.type !== undefined) {
    switch (action.type) {
      case OWNERS_LOGS_LIST.SUCCESS:
        return {
          ...state,
          logs: [...action.result.pageItems],
        };
      case OWNERS_SINGLELIST_GET.SUCCESS:
        return {
          ...state,
          overallPages: action.result.overallPages,
          overallRows: action.result.overallRows,
          currentPage: action.result.currentPage,
          ownersSingleList: action.result.pageItems,
        };
      case OWNERS_PENDING_LIST_GET.SUCCESS:
        return {
          ...state,
          overallPages: action.result.overallPages,
          overallRows: action.result.overallRows,
          currentPage: action.result.currentPage,
          ownersPendingList: action.result.pageItems,
        };

      default:
        return state;
    }
  }

  return state;
}
