import styled from 'styled-components';

export default styled.label`
  ${(props) =>
    props.isOver
      ? `
    color:${props.tenants.color1};
    border-color:  ${props.tenants.color1};`
      : ''}
`;
