export default {
  equipments: {
    add: 'Empfänger zufügen',
    createEquipment: 'Empfänger auswählen und anlegen',
    deleteConfirmation: 'Möchten Sie dieses Gerät wirklich löschen?',
    facilityName: 'Name der Einrichtung',
    productName: 'Produktname',
    parameters: 'Parameter',
    relays: 'Relais',
    info: 'Information',
    fobs: 'Sender',
    groups: 'Benutzergruppen',
    actions: 'Aktionen',
    events: 'Ereignisse',
    'remote comm': 'Fernkommunikation',
    changePasswordAction: 'Passwort ändern',
    adjustDateTimeAction: 'Passen Sie Datum und Uhrzeit an',
    formatMemory: 'Speicher formatieren',
    disconnectedKey: 'Nicht verbunden',
    connectedKey: 'In Verbindung gebracht',
    notFoundKey: 'Gerät nicht gefunden',
    saveConfiguration: 'Konfiguration speichern',
    compareMemory: 'Speicher vergleichen',
    savedMemory: 'Speicher gespeichert',
    sendMemoryAction: 'Speicher lokal aktualisieren',
    saveMemoryAction: 'Speicher dunkel aktualisieren',
    verifyConnectivity: 'Überprüfen Sie die Konnektivität',
    getdoorstatus: 'Türstatus erhalten',
    device: 'Empfängertyp auswählen',
    deleteEventsConfirmation: 'Möchten Sie die Ereignisse wirklich löschen?',
  },
};
