import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import IconDownload from '@jcm-technologies/uikit/dist/atoms/Icons/IconDownload';
import { IconTooltip } from '@jcm-technologies/uikit/dist/molecules/IconTooltip';
import PropTypes from 'prop-types';
import { fileExcelName } from '../../config';

export const ExcelExportUsers = ({ data }) => {
  const { t } = useTranslation();
  const { id: idGroup, instalationId: idFacility } = useParams();
  let exportExcel;
  return (
    <>
      <IconTooltip
        id='export-mifare-excel'
        color='grey'
        sizeIcon='display36'
        toolTipContent={t('instalationGroups.exportExcel')}
        onClick={() => exportExcel.save()}
        marginRight={2}
      >
        <IconDownload />
      </IconTooltip>
      <ExcelExport
        fileName={fileExcelName({ idFacility, idGroup })}
        data={data}
        ref={(exporter) => (exportExcel = exporter)}
      >
        <ExcelExportColumn
          key={'groupUserId'}
          title={'groupUserId'}
          field='groupUserId'
          cellOptions={{ background: '#ccc', wrap: true }}
          locked
          headerCellOptions={{ textAlign: 'center', bold: true }}
        />
        <ExcelExportColumn
          key={t('form.email')}
          title={t('form.email')}
          field='email'
          cellOptions={{ background: '#ccc', wrap: true }}
          locked
          headerCellOptions={{ textAlign: 'center', bold: true }}
        />
        <ExcelExportColumn
          key={t('form.phone')}
          title={t('form.phone')}
          field='phone'
          cellOptions={{ wrap: true }}
          locked
          headerCellOptions={{ textAlign: 'center', bold: true }}
        />
        <ExcelExportColumn
          key={t('form.name')}
          title={t('form.name')}
          field='name'
          cellOptions={{ wrap: true }}
          locked
          headerCellOptions={{ textAlign: 'center', bold: true }}
        />
        <ExcelExportColumn
          key={t('form.surname')}
          title={t('form.surname')}
          field='surname'
          cellOptions={{ wrap: true }}
          locked
          headerCellOptions={{ textAlign: 'center', bold: true }}
        />
        <ExcelExportColumn
          key={t('form.from')}
          title={t('form.from')}
          field='validFrom'
          width={200}
          cellOptions={{ wrap: true, format: 'dd/MM/yyyy' }}
          locked
          headerCellOptions={{ textAlign: 'center', bold: true }}
        />
        <ExcelExportColumn
          key={t('form.to')}
          title={t('form.to')}
          width={200}
          field='validTo'
          cellOptions={{ wrap: true, format: 'dd/MM/yyyy' }}
          locked
          headerCellOptions={{ textAlign: 'center', bold: true }}
        />
      </ExcelExport>
    </>
  );
};

ExcelExportUsers.propTypes = {
  data: PropTypes.shape({}).isRequired,
};
