import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { getArrayNodeIndexByPropName, getNewArray } from '../../core/helpers/arrayService';
import { getPDFFobs } from '../../modules/group/fobs/actions';
import { downloadFile, getFilesFromApi } from '../../core/helpers/filesApi';
import apiPathFactory from '../../core/old_common/rooting/apiPathFactory';
import store from '../../modules/old_to_refact/stores/configureStore';
import { showDialog } from '../../modules/old_to_refact/actions/loading';
import { Modal } from '@jcm-technologies/uikit/dist/molecules/Modal';
import { Flex } from '@jcm-technologies/uikit/dist/atoms/Layout';
import { Button } from '@jcm-technologies/uikit/dist/atoms/Button';
import { Switch } from '@jcm-technologies/uikit/dist/atoms/Switch';
import { Field } from '@jcm-technologies/uikit/dist/atoms/Field';

class PdfPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
    };
  }

  onClose = () => {
    const { onClose } = this.props;
    onClose();
    this.props.items.map((field, i) => {
      this.reset(field.id);
    });
    this.setState({ items: [] });
  };

  reset = (id) => this.updateFieldsState(id, 'visible', false);

  updateFieldsState = (fieldId, propName, value) => {
    const { items } = { ...this.props };
    const indexOfField = getArrayNodeIndexByPropName(items, { id: fieldId }, 'id');
    if (items[indexOfField][propName] === value) {
      const newFields = getNewArray(items, indexOfField, propName, !value);
      this.setState({ items: newFields });
    }
  };

  handleSubmit = (e) => {
    e?.preventDefault();
    const { items } = this.state;
    const { facilityId, groupId, name } = this.props;
    const itemsSelected = items.filter(this.checkVisible);
    const tagsSelected = itemsSelected.map((x) => x.tag);
    const endpoint = apiPathFactory.fobsGetFile(facilityId, groupId, tagsSelected);
    store.dispatch(showDialog(1));
    getFilesFromApi(
      endpoint,
      (isError, response) => {
        if (!isError) {
          const fileName = `${name}.html`;
          downloadFile(response, fileName);
        }
      },
      'application/json',
      'binary'
    );
    this.onClose();
  };

  checkVisible(item) {
    if (!item.visible) {
      return item.tag;
    }
  }

  render() {
    const { isOpen, t, items, tenants } = this.props;
    return (
      <Modal
        width='100%'
        maxWidth={[300, 600, 600]}
        closeWithButton
        isOpen={isOpen}
        title={t('instalationGroups.selectConfigFields')}
        id='modal-export-group'
        onModalClose={() => this.onClose()}
      >
        <Modal.Content>
          <Flex
            flexDirection='column'
            justifyContent='center'
            alignItems='baseline'
            paddingX={4}
            style={{
              overflow: 'hidden',
              maxHeight: 500,
              overflowY: 'auto',
            }}
          >
            {items?.map((field) => (
              <Flex>
                <Field
                  display='flex'
                  label={field.value}
                  id='config-field'
                  width='100%'
                  marginTop={1}
                  style={{
                    flexDirection: 'row-reverse',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Switch
                    marginRight={2}
                    label={field.value}
                    id={field.id}
                    defaultValue={false}
                    themeColor={tenants?.color1}
                    action={(isChecked) => {
                      this.updateFieldsState(field.id, 'visible', isChecked);
                    }}
                  />
                </Field>
              </Flex>
            ))}
          </Flex>
        </Modal.Content>
        <Modal.Actions>
          <Flex alignItems='center' justifyContent='flex-end'>
            <Button
              id='acceptDeleteHolidays'
              sizeButton='small'
              backgroundColor={tenants?.color1}
              marginRight={2}
              action={() => {
                this.handleSubmit();
              }}
            >
              {t('general.downloadHtml')}
            </Button>
          </Flex>
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  tenants: state.tenants,
  showLoading: state.loading.show,
});

const PdfPopupWithTranslation = withTranslation()(PdfPopup);
const PdfPopupWithRouter = withRouter(PdfPopupWithTranslation);

export default connect(mapStateToProps, { getPDFFobs })(PdfPopupWithRouter);
