import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Pill } from '@jcm-technologies/uikit/dist/atoms/Pill';
import { Icon } from '@jcm-technologies/uikit/dist/atoms/Icon';
import IconClose from '@jcm-technologies/uikit/dist/atoms/Icons/IconClose';
import i18next from 'i18next';
import GenericList from '../GenericList';
import {
  CreationPath_Instalations,
  RootPath_GROUPS,
} from '../../core/old_common/constats/genericListPaths';
import SelectableFilters from '../SelectableFilters';
import { getGroups, getGroupsFobHistory } from '../../modules/old_to_refact/actions/groups';
import {
  setSearchQuery,
  setSelectedDescendants,
} from '../../modules/state/visibleDescendants/actions';
import { getVisibleDescendants } from '../../modules/old_to_refact/actions/universalGroups';
import { GROUP_DESCENDANT } from '../../modules/state/visibleDescendants/constants';
import InstalationGroupGrow from '../InstalationGroupGrow';
import {
  deleteCookie_selectedItemCustomerId,
  getCookieFobHistorySearch,
  getCookieGroupsSearch,
  setCookie,
} from '../../core/old_common/utils/cookiesManager';
import { getContrastColor } from '../../core/helpers/colorManager';
import { GROUPS_LIST } from '../../modules/state/pagination/constants';
import CardHistory from '../../components/Group/Card/FacilityGroupAdvancedHistorySearch/Card';
import CardMifare from '../../components/Group/Card/FacilityGroupAdvancedMifareSearch/Card';

export class GroupsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      haveFilter: false,
      filterSelected: null,
    };
  }

  componentDidMount = () => {
    const { visibleDescendants, getVisibleDescendants, needCallGroups } = this.props;
    if (!visibleDescendants) {
      getVisibleDescendants();
    }
    if (needCallGroups && !getCookieFobHistorySearch() && !getCookieGroupsSearch()) {
      this.props.getGroups();
    } else if (getCookieGroupsSearch() && needCallGroups) {
      this.props.getGroups(getCookieGroupsSearch().fieldId, getCookieGroupsSearch().value);
    } else if (getCookieFobHistorySearch() && needCallGroups) {
      this.props.getGroupsFobHistory(getCookieFobHistorySearch().value);
    }

    if (this.setPillFilter()) {
      this.setState({
        haveFilter: true,
        filterSelected: this.setPillFilter(),
      });
    }
    // this.props.getVisibleDescendants()
    deleteCookie_selectedItemCustomerId();
    this.props.setSearchQuery(this.props.searchQuery, !!this.props.searchQuery);
  };

  handleSearchQueryChange = (e) => {
    const { setSearchQuery } = this.props;
    setSearchQuery(e.currentTarget.value || '', !!e.currentTarget.value);
  };

  handleSelectionChange = (value) => {
    const { setSelectedDescendants, getGroups } = this.props;
    setSelectedDescendants(GROUP_DESCENDANT, value);

    setCookie('groupsSearch', null);
    setCookie('fobHistorySearch', null);
    this.setState({
      haveFilter: false,
      filterSelected: null,
    });
    getGroups();
  };

  handlePageChange = () => {
    if (this.props.needCallGroups) {
      this.props.getGroups();
    }
  };

  getCustomStyles = () => {
    const { tenants } = this.props;
    const customStyles = {
      multiValue: (provided, state) => ({
        ...provided,
        background: tenants.color1,
        color: 'white',
      }),
      multiValueLabel: (provided, state) => ({
        ...provided,
        color: 'white',
      }),
    };
    return customStyles;
  };

  getVisibleDescendants = () => {
    const { t } = this.props;
    let visibleDesc = [];
    if (this.props.visibleDescendants) {
      visibleDesc = this.props.visibleDescendants.map((vDes) => ({
        label: vDes.name,
        value: vDes.id,
      }));
      visibleDesc.unshift({
        label: t('globalSearch.allOwners'),
        value: 'ffffffff-ffff-ffff-ffff-ffffffffffff',
      });
    }
    return visibleDesc;
  };

  setPillFilter = () => {
    const { t } = this.props;
    const lastSearch = getCookieGroupsSearch() || getCookieFobHistorySearch();

    return lastSearch?.value
      ? i18next.exists(`title.${lastSearch.label}`)
        ? `${t(`title.${lastSearch.label}`)}: ${lastSearch.value}`
        : `${lastSearch?.label}: ${lastSearch?.value}`
      : lastSearch?.label;
  };

  componentWillUnmount() {}

  render() {
    const {
      groups,
      searchQuery,
      getGroups,
      getGroupsFobHistory,
      tenants,
      selectedDescendants,
      history,
    } = this.props;
    const { haveFilter, filterSelected } = this.state;
    return (
      <>
        <SelectableFilters
          descendatsOptions={this.getVisibleDescendants()}
          selectedDescendants={selectedDescendants}
          onChangeDescendants={(e) => this.handleSelectionChange(e)}
          renderDescendants
          onChangeSearchQuery={(e) => this.handleSearchQueryChange(e)}
          searchQuery={searchQuery}
          onSubmit={this.props.groups}
          renderSearch
          customStyles={this.getCustomStyles()}
        />
        {haveFilter && filterSelected && (
          <Pill
            backgroundColor={tenants.color1}
            marginTop={1}
            color={getContrastColor(tenants?.colo1)}
          >
            {filterSelected}
            <Icon
              sizeIcon='display24'
              onClick={() => {
                this.setState({ haveFilter: false, filterSelected: null });
                history.push('/dashboard');
              }}
            >
              <IconClose />
            </Icon>
          </Pill>
        )}
        <GenericList
          dataList={groups || []}
          CustomGrow={
            getCookieFobHistorySearch()
              ? CardHistory
              : getCookieGroupsSearch()?.fieldId === 40
              ? CardMifare
              : InstalationGroupGrow
          }
          handlePageChange={this.handlePageChange}
          baseURI={RootPath_GROUPS}
          creationURI={CreationPath_Instalations}
          listType={GROUPS_LIST}
          getList={getCookieFobHistorySearch() ? getGroupsFobHistory : getGroups}
          groupByProp='customerName'
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  tenants: state.tenants,
  groups: state.groups.groups,
  needCallGroups: state.groups.needCallGroups,
  searchQuery: state.visibleDescendants.searchQuery,
  selectedDescendants: state.visibleDescendants.selectedDescendants[GROUP_DESCENDANT],
  visibleDescendants: state.universalGroups.visibleDescendants,
});

const GroupsListPage = withTranslation()(GroupsList);
const GroupsListPageHistory = withRouter(GroupsListPage);

export default connect(mapStateToProps, {
  getGroups,
  getGroupsFobHistory,
  getVisibleDescendants,
  setSelectedDescendants,
  setSearchQuery,
})(GroupsListPageHistory);
