import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { getActualLanguage } from '../helpers/getLanguages';
import translationEN from './translations/lenguajes/en';
import translationES from './translations/lenguajes/es';
import translationFR from './translations/lenguajes/fr';
import translationIT from './translations/lenguajes/it';
import translationDE from './translations/lenguajes/de';
import translationCS from './translations/lenguajes/cs';
import translationCA from './translations/lenguajes/ca';
import translationHU from './translations/lenguajes/hu';

// Get PhraseApp Translations
export const getTranslations = async (lang) => {
  const response = await fetch(
    `https://api.phrase.com/v2/projects/965a0a800959695b0f0dc6a2dd42e40f/locales/${lang}/download?file_format=json`,
    {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'X-Frame-Options': 'SAMEORIGIN',
        'X-XSS-Protection': '1; mode=block',
        'X-Content-Type-Options': 'nosniff',
        'X-Download-Options': 'NOOPEN',
        Authorization: `token 1b4d481084d73f5ee850843b6cfa8ccfbbe17754da6ad8a53011634416178a90`,
      },
    }
  )
    .then((responseData) => responseData.json())
    .then((translations) => {
      const data = {};
      // eslint-disable-next-line guard-for-in
      for (const key in translations) {
        data[key] = translations[key].message || translations[key];
      }
      return data;
    })
    .then((result) => result);
  return response;
};

// TODO: Clean translations, migrate to 'PrhaseApp Strings'
export const resources = {
  en_GB: {
    translation: translationEN,
  },
  es_ES: {
    translation: translationES,
  },
  ca_ES: {
    translation: translationCA,
  },
  fr_FR: {
    translation: translationFR,
  },
  it_IT: {
    translation: translationIT,
  },
  de_DE: {
    translation: translationDE,
  },
  cs_CZ: {
    translation: translationCS,
  },
  hu_HU: {
    translation: translationHU,
  },
};

const getResource = async (language) => {
  const newResource = {
    ...resources,
    [language || getActualLanguage().replace('-', '_')]: {
      translation: {
        ...resources[language || getActualLanguage().replace('-', '_')]?.translation,
        ...(await getTranslations(language?.split('_')?.[0] || getActualLanguage()?.split('-')[0])),
      },
    },
  };
  return newResource;
};

// Init i18n
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init(
    {
      // TODO: When we finish migration, we can remove this resources
      resources,
      lng: getActualLanguage().replace('-', '_'),
      fallbackLng: 'en_GB',
      partialBundledLanguages: true,
      // parseMissingKeyHandler: () => '',
      interpolation: {
        escapeValue: false, // react already safes from xss
      },
      react: {
        useSuspense: false,
      },
    },
    (err, t) => {
      if (err) return console.log('something went wrong loading', err);
      t('key'); // -> same as i18next.t
    }
  );

// Init with translations legacy +  phraseApp
export const initTranslations = async (language) => {
  const resource = Promise.resolve(getResource(language));
  const newResources = await resource;
  i18n.init({
    resources: newResources,
    ns: ['translation'],
    defaultNS: 'translation',
    compatibilityJSON: 'v3',
    partialBundledLanguages: true,
    lng: getActualLanguage().replace('-', '_'),
    fallbackLng: 'en_GB',
    // parseMissingKeyHandler: () => '',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    react: {
      useSuspense: false,
    },
  });

  if (language) {
    i18n.changeLanguage(language);
  }
};

initTranslations();

export default i18n;
