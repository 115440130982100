import createFetchTypes from '../../core/typeActions/types';

export const GET_INVITATION_USER = createFetchTypes('GET_INVITATION_USER');
export const GET_OWN_USERS = createFetchTypes('GET_OWN_USERS');
export const GET_USER = createFetchTypes('GET_USER');
export const GET_USER_ROLES = createFetchTypes('GET_USER_ROLES');
export const GET_USER_ROLES_EMPLOYEES = createFetchTypes('GET_USER_ROLES_EMPLOYEES');
export const PATCH_PREFERENCES_USER = createFetchTypes('PATCH_PREFERENCES_USER');
export const PUT_AVATAR_USER = createFetchTypes('PUT_AVATAR_USER');
export const PUT_PREFERENCES_USER = createFetchTypes('PUT_PREFERENCES_USER');
export const PUT_USER_PERMISSIONS = createFetchTypes('PUT_USER_PERMISSIONS');
export const RESET_NEED_SIGNALR_CHECK = 'RESET_NEED_SIGNALR_CHECK';
export const RESET_USER_ROLES = 'RESET_USER_ROLES';
export const USER_SET_NEED_UPDATE_LNG_FALSE = 'USER_SET_NEED_UPDATE_LNG_FALSE';
