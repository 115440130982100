export default {
  updateInstalationForm: {
    contact: 'Contacto',
    telephone: 'Teléfono',
    address: 'Dirección',
    country: 'País',
    saveButton: 'Guardar',
    maintenanceDate: 'Próxima fecha de mantenimiento',
    createFacility: 'Crear una instalación',
    installerCode: 'Usar código de instalador',
    installerCodeIconTooltip: 'Código de instalador',
    comment: 'Comentarios',
    createFacilityGroup: 'Crear un grupo de instalación',
    uniqueIdentity: 'Identificador único',
    geolocalization: 'Geolocalización asignada',
    exportUsersGroups: 'Exportar grupos',
    noneGeolocalization: 'Geolocalización no asignada',
    addUniversalGroup: 'Añadir un grupo universal',
    needSetLocation: 'Debes agregar una ubicación específica',
    exportUsersTitle: 'exportar_usuarios',
    creationDate: 'Fecha de creación',
    installerCodeFirstMessage: 'Asegúrese de trabajar con código de instalador.',
    installerCodeSecondMessage: 'En caso de duda contacte con su administrador'
  },
};
