export default {
  relaysConfiguration: {
    tabRelays: 'Relék',
    tabSchedules: 'Ütemtervek',
    tabInfo: 'Információk',
    time: '{{count}}. relé aktivációs ideje',
    delay: '{{count}}. relé késleltetési ideje',
    infoTime: 'Az érték 1 és 60 másodperc között értendő',
    infoDelay: 'Az érték 0 és 10 másodperc között értendő',
    infoCustomOpenDoorTime: 'Az érték 1 és 86400 másodperc között értendő',
    relayNotassigned: 'Relé nincs hozzárendelve',
    goBack: 'Vissza',
    type: 'Relé típusa',
    keepDoorOpenSchedule: 'Nyitott kapus ütemtervek',
    customOpenDoorTimeSchedule: 'Egyedi nyitott kapus ütemterv',
    customOpenDoorTime: 'Kapu nyitvatartási idő',
    Relay: 'Relé'
  }
};
