import createFetchTypes from '../../core/typeActions/types';

export const GET_CUSTOMERS_INSTALLER_CODE = createFetchTypes('GET_CUSTOMERS_INSTALLER_CODE');
export const GET_CUSTOMERS_LICENSES = createFetchTypes('GET_CUSTOMERS_LICENSES');
export const PUT_CUSTOMER_LICENSES = createFetchTypes('PUT_CUSTOMER_LICENSES');
export const PUT_CUSTOMERS_INSTALLER_CODE = createFetchTypes('PUT_CUSTOMERS_INSTALLER_CODE');
export const PUT_CUSTOMER_ATTRIBUTES = createFetchTypes('PUT_CUSTOMER_ATTRIBUTES');
export const GET_CUSTOMERS_HONOA = createFetchTypes('GET_CUSTOMERS_HONOA');
export const PUT_CUSTOMER_ATTRIBUTES_HONOA = createFetchTypes('PUT_CUSTOMER_ATTRIBUTES_HONOA');
export const GET_CUSTOMERS_PROPERTY_MANAGERS = createFetchTypes('GET_CUSTOMERS_PROPERTY_MANAGERS');
export const PATCH_CUSTOMERS_CUSTOMER_ROLE = createFetchTypes('PATCH_CUSTOMERS_CUSTOMER_ROLE');
export const GET_CUSTOMERS_CUSTOMER_ROLES = createFetchTypes('GET_CUSTOMERS_CUSTOMER_ROLES');
