import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Popup from '../../Popup';
import PopUpNeighbour from '../../PopUpNeighbour';
import IndividualNeighbour from './IndividualNeighbour';
import {
  putNeighbour,
  postNeighbour,
  deleteNeighbour,
  showNeighbourPopUp,
  setEditedPopUp,
  resetEditedPopUp,
  getNeighbours,
  resetRefreshNeighbours,
} from '../../../modules/old_to_refact/actions/neighbours';
import IconSpan from '../../IconSpan';
import PopUpConfirm from '../../PopUpConfirm';

import '../InstalationEquipmentsNeighbours.css';

export class Neighbours extends Component {
  constructor() {
    super();
    this.state = {
      PopUpEdit: {
        onAcceptFn: this.onClickEditAccept,
        onCancelFn: () => {
          this.props.showNeighbourPopUp(false);
        },
        neighbourToEdit: {},
      },
      PopUpDelete: {
        isDeletePopupOpen: false,
        onAcceptFn: this.onClickDeleteAccept,
        onCancelFn: () => {
          this.onClickDeleteCancel();
        },
      },
    };
  }

  componentDidUpdate() {
    const {
      facilityId,
      equipmentId,
      getNeighbours,
      needRefreshNeighbours,
      resetRefreshNeighbours,
    } = this.props;
    if (needRefreshNeighbours) {
      getNeighbours(facilityId, equipmentId);
      resetRefreshNeighbours();
    }
  }

  onClickEditCancel = () => {
    this.props.showNeighbourPopUp(false);
    this.props.resetEditedPopUp();
  };

  onClickDeleteCancel = () => {
    this.setState({
      PopUpDelete: {
        isDeletePopupOpen: false,
        tenants: this.props.tenants,
      },
    });
  };

  onClickEditAccept = (contact, neiId, isPost) => {
    const { putNeighbour, postNeighbour, facilityId, equipmentId } = this.props;

    if (isPost) {
      postNeighbour(facilityId, equipmentId, contact);
    } else {
      putNeighbour(facilityId, equipmentId, neiId, contact);
    }
  };

  onClickDeleteAccept = (facilityId, equipmentId, neighbourId) => {
    const { deleteNeighbour } = this.props;
    deleteNeighbour(facilityId, equipmentId, neighbourId);
    this.setState({
      PopUpDelete: {
        isDeletePopupOpen: false,
        tenants: this.props.tenants,
      },
    });
  };

  handleEdit = (contact, id) => {
    this.setState({
      PopUpEdit: {
        onAcceptFn: (newContact, isPost) => this.onClickEditAccept(newContact, id, isPost),
        onCancelFn: this.onClickEditCancel,
        tenants: this.props.tenants,
      },
      neighbourToEdit: contact,
    });
    this.props.showNeighbourPopUp(true);
  };

  handleDelete = (facilityId, equipmentId, neighbourId) => {
    this.setState({
      PopUpDelete: {
        isDeletePopupOpen: true,
        onAcceptFn: () => this.onClickDeleteAccept(facilityId, equipmentId, neighbourId),
        onCancelFn: this.onClickDeleteCancel,
        tenants: this.props.tenants,
      },
    });
  };

  getNeighbours = () => {
    const { neighbours } = this.props;
    const { facilityId, equipmentId } = this.props;
    const neighbourList = neighbours.map((neig) => (
      <IndividualNeighbour
        key={neig.id}
        onAccept={this.onClickEditAccept}
        neighbour={neig}
        handleEdit={this.handleEdit}
        handleDelete={this.handleDelete}
        facilityId={facilityId}
        equipmentId={equipmentId}
      />
    ));
    return neighbourList;
  };

  render() {
    const { tenants, t } = this.props;
    return (
      <div>
        <div className='individual-neighbour-container individual-neighbour-container-header'>
          <span />
          <span>Floor</span>
          <span>Door</span>
          <span>Description</span>
        </div>
        <div className='neighbours-table'>{this.getNeighbours()}</div>
        <div className='popup-icon'>
          <IconSpan
            onClick={(e) => {
              this.handleEdit();
            }}
            toolTip={t('neighbourPopUp.title')}
            defaultColor='#a4aebb'
            iconClassName='fas fa-user-cog iconNeighbour'
            tenants={tenants}
            cursor='pointer'
          />
        </div>
        <Popup
          isOpen={this.props.showPopUp}
          onClose={this.onClickEditCancel}
          closeOnDocumentClick={false}
        >
          <PopUpNeighbour
            {...this.state.PopUpEdit}
            {...this.state.neighbourToEdit}
            neighbour={this.contantToEdit}
          />
        </Popup>
        <Popup isOpen={this.state.PopUpDelete.isDeletePopupOpen} onClose={this.onClickDeleteCancel}>
          <PopUpConfirm {...this.state.PopUpDelete} text={t('neighbourPopUp.titleDelete')} />
        </Popup>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  tenants: state.tenants,
  showPopUp: state.neighbours.showPopUp,
  needRefreshNeighbours: state.neighbours.needRefreshNeighbours,
});

const NeighboursTranslation = withTranslation()(Neighbours);

export default connect(mapStateToProps, {
  getNeighbours,
  putNeighbour,
  postNeighbour,
  deleteNeighbour,
  showNeighbourPopUp,
  setEditedPopUp,
  resetEditedPopUp,
  resetRefreshNeighbours,
})(NeighboursTranslation);
