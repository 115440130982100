export default {
  signalRError: {
    11050: 'Resposta inesperada',
    11051: 'Dispositiu incorrecte',
    11052: 'Codificació incorrecta',
    11053: 'El dispositiu està usant codi d\'instal·lador',
    11054: 'El dispositiu no utilitza codi d\'instal·lador',
    11055: 'Temps d\'espera',
    11056: 'L\'Assistant no està llest',
    11057: 'L\'Assistant està ocupat',
    11058: 'Ha succeït un error',
    11059: 'Operació denegada',
    11060: 'Versió d\'arrencada incorrecta',
    11061: 'Encara en progrés',
    11062: 'Comptador incorrecte',
    11063: 'Número de sèrie incorrecte',
    11064: 'Error en configurar el dispositiu',
    11065: 'Acció desconeguda',
    11066: 'Dispositiu incorrecte',
    11067: 'Error fatal',
    11068: 'Crc incorrecte',
    11069: 'La contrasenya no coincideix',
    connectionTimeout: 'Verifica la connexió amb Assistant Panel',
    dontMoveTag: 'Ha succeït un error. Repetix l\'operació sense moure el dispositiu de la zona de programació.',
  },
};