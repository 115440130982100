export default {
  universalGroups: {
    description: 'Descrizione',
    informationTab: 'Informazione',
    expand: 'Espandere',
    collapse: 'Crollo',
    delete: 'Elimina',
    add: 'Aggiungi un nuovo gruppo universale',
    create: 'Crea un gruppo universale',
    instalationsTab: 'strutture',
    fobsTab: 'Emettitori',
    deleteConfirmation: 'Sei sicuro di voler eliminare questo gruppo?',
  },
};
