export default {
  alarms: {
    deleteAlarmPopUp: 'Möchten Sie den Alarm wirklich als gesehen markieren?',
    deleteAlarmPopUpWithMove:
      'Wenn Sie einen Sender in eine andere Gruppe verschieben, werden die Alarme als angezeigt markiert. Möchten Sie fortfahren?',
    deleteAlarmPopUpWithCloneUniversal: 'Die Kopie des Universalanhängers teilt die Alarme.',
    deleteAlarmPopUpWithClone:
      'Wenn Sie einen Anhänger in eine andere Gruppe kopieren, wird die Kopie ohne Alarm angezeigt.',
  },
};
