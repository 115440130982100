export const RootPath_UG = '/UniversalGroups';
export const CreationPath_UG = 'UniversalGroup';
export const RootPath_Instalations = '/Facilities';
export const CreationPath_Instalations = 'Facility';
export const CreationPath_Instalation_Group = 'FacilityGroup';
export const CreationPath_Equipments = 'Equipment';
export const RootPath_GROUPS = '/groups';
export const RootPath_Equipments = '/Equipments';

export default {
  RootPath_UG,
  CreationPath_UG,
  RootPath_Instalations,
  CreationPath_Instalations,
  CreationPath_Instalation_Group,
  CreationPath_Equipments,
  RootPath_GROUPS,
  RootPath_Equipments,
};
