export default {
  updateInstalationForm: {
    contact: 'Contacte',
    telephone: 'Telèfon',
    name: 'Nom',
    address: 'Direcció',
    postalcode: 'Codi postal',
    country: 'País',
    city: 'Ciutat',
    saveButton: 'Desar',
    maintenanceDate: 'Propera data de manteniment',
    createInstalation: 'Crear una instal·lació',
    installerCode: 'Utilitzar codi d\'instal·lador',
    installerCodeIconTooltip: 'Codi d\'instal·lador',
    comment: 'Comentaris',
    createInstalationGroup: 'Crear un grup d\'instal·lació',
    uniqueIdentity: 'Identificador únic',
    geolocalization: 'Geolocalització assignada',
    exportUsersGroups: 'Exportar grups',
    noneGeolocalization: 'Geolocalització no assignada',
    addUniversalGroup: 'Afegir un grup universal',
    needSetLocation: 'Cal d\'afegir una ubicació específica',
    exportUsersTitle: 'Exportar usuaris',
    creationDate: 'Data de creació',
    installerCodeFirstMessage: 'Marca aquesta opció només si realment treballes amb codi instal·lador.',
    installerCodeSecondMessage: 'Si tens dubtes, contacta amb el teu administrator.'
  },
};
