import signalRActions from '../actions/signalrFront';

/* Redux state init */
const initialState = {
  connectionData: {},
  connection: undefined,
  onSignalRError: undefined,
  isConnected: false,
};

export default function notificationsReducer(state = initialState, action) {
  if (action.type !== undefined) {
    switch (action.type) {
      case signalRActions.SET_SIGNALR_CONNECTION_DATA_FRONT:
        return { ...state, connectionData: action.connectionData };
      case signalRActions.SET_SIGNALR_CONNECTION_FRONT:
        return { ...state, connection: action.connection };
      case signalRActions.SET_SIGNALR_CONNECTION_STATUS_FRONT:
        return { ...state, isConnected: action.isConnected };
      case signalRActions.SET_SIGNALR_CALL_ERROR_FRONT:
        return { ...state, onSignalRError: action.errorFunction };
      default:
        return state;
    }
  }
  return state;
}
