import Cookies from 'js-cookie';
import i18next from 'i18next';
import { getDescendantTypesDefaultState } from '../../../modules/state/visibleDescendants/constants';
import { getPageListTypesDefaultState } from '../../../modules/state/pagination/constants';

const saveCookies = ({
  token,
  tenants,
  userId,
  email,
  userRoles,
  customerId,
  language,
  visibleDescendants,
  pagenumbers,
  agreementPending,
  showVersionModal,
  hideVerInfo,
  pageSizeItems,
  selectedFacilityCustomerId,
  selectedScheduleCustomerId,
  selectedUniversalGroupCustomerId,
  groupIsUniversal,
  dateTimeFormat,
}) => {
  if (token) {
    sessionStorage.setItem('access_token', token);
    sessionStorage.setItem('refresh_check', false);
  }

  if (tenants) {
    Cookies.set('tenants', JSON.stringify(tenants));
  }

  if (userId) {
    Cookies.set('userId', userId);
  }

  if (customerId) {
    Cookies.set('customerId', customerId);
  }

  if (email) {
    Cookies.set('email', email);
  }

  if (userRoles) {
    Cookies.set('userRoles', JSON.stringify(userRoles));
  }

  if (language) {
    Cookies.set('language', language);
  }

  if (visibleDescendants) {
    Cookies.set('visibleDescendants', JSON.stringify(visibleDescendants));
  }
  if (pagenumbers) {
    Cookies.set('pagenumbers', JSON.stringify(pagenumbers));
  }
  if (agreementPending !== undefined) {
    Cookies.set('agreementPending', agreementPending);
  }
  if (showVersionModal !== undefined) {
    Cookies.set('showVersionModal', showVersionModal);
  }

  if (hideVerInfo) {
    Cookies.set('hideVerInfo', hideVerInfo);
  }
  if (pageSizeItems) {
    Cookies.set('pageSizeItems', pageSizeItems);
  }

  if (selectedFacilityCustomerId) {
    Cookies.set('selectedFacilityCustomerId', selectedFacilityCustomerId);
  }

  if (selectedScheduleCustomerId) {
    Cookies.set('selectedScheduleCustomerId', selectedScheduleCustomerId);
  }

  if (selectedUniversalGroupCustomerId) {
    Cookies.set('selectedUniversalGroupCustomerId', selectedUniversalGroupCustomerId);
  }

  if (groupIsUniversal !== undefined) {
    Cookies.set('groupIsUniversal', groupIsUniversal);
  }

  if (dateTimeFormat) {
    Cookies.set('dateTimeFormat', dateTimeFormat);
  }
};

const getCookieAsParsedJSON = (cookieName, getDefaultValue) => {
  const cookieValue = Cookies.get(cookieName);
  if (cookieValue) {
    try {
      return JSON.parse(cookieValue);
    } catch {
      return getDefaultValue();
    }
  }
  return getDefaultValue();
};

const getCookieAsParsedBoolean = (cookieName) => {
  const cookieValue = Cookies.get(cookieName);
  if (cookieValue) {
    return cookieValue.toLowerCase() === 'true';
  }
  return undefined;
};

const getCookieAccessToken = () => sessionStorage.getItem('access_token');
export const getExpiresTimeToken = () => sessionStorage.getItem('expires_token');
export const getRefreshCheckToken = () => sessionStorage.getItem('refresh_check');
const getCookieHideVerInfo = () => Cookies.get('hideVerInfo');

const getCookieEmail = () => Cookies.get('email');

const getCookieAgreementPending = () => getCookieAsParsedBoolean('agreementPending');

const getCookieShowVersionModal = () => getCookieAsParsedBoolean('showVersionModal');

const getCookie_visibleDescendants = () =>
  getCookieAsParsedJSON('visibleDescendants', () => ({ ...getDescendantTypesDefaultState() }));

const getCookie_pageNumbers = () =>
  getCookieAsParsedJSON('pagenumbers', () => ({ ...getPageListTypesDefaultState() }));

const getCookieUserId = () => Cookies.get('userId');

const getCookieEquipmentSearch = () => getCookieAsParsedJSON('equipmentSearch', () => null);

const getCookieGroupsSearch = () => getCookieAsParsedJSON('groupsSearch', () => null);

const getCookieFacilitiesSearch = () => getCookieAsParsedJSON('facilitiesSearch', () => null);

const getCookieFobHistorySearch = () => getCookieAsParsedJSON('fobHistorySearch', () => null);

const getCookieCustomerId = () => Cookies.get('customerId');

const getCookie_selectedFacilityCustomerId = () => Cookies.get('selectedFacilityCustomerId');

const getCookie_selectedScheduleCustomerId = () => Cookies.get('selectedScheduleCustomerId');

const getCookie_selectedUniversalGroupCustomerId = () =>
  Cookies.get('selectedUniversalGroupCustomerId');

const getCookieUserRoles = () => getCookieAsParsedJSON('userRoles', () => Cookies.get('userRoles'));

const getCookieDateTimeFormat = () => Cookies.get('dateTimeFormat');

const getCookieLanguage = () => {
  let language = Cookies.get('language') || i18next.language;
  if (language)
    if (
      language &&
      /^[A-Z]{2}/.test(language) &&
      (language.includes('_') || language.includes('-'))
    ) {
      const splittedLanguage = language.includes('_') ? language.split('_') : language.split('-');
      language = `${splittedLanguage[0].toLowerCase()}-${splittedLanguage[1].toUpperCase()}`;
    }
  return language && language.includes('_', 2) ? language.replace('_', '-') : getUrlById();
};

const getNavigatorLanguage = () => {
  if (navigator.language.length === 5) {
    return navigator.language;
  }
  return `${navigator.language}-${navigator.language.toUpperCase()}`;
};

const getUrlById = () => {
  const url = window.location.href;
  const langFromUrl = new URLSearchParams(window.location.search).get('lang');
  if (langFromUrl) {
    return langFromUrl.replace('_', '-');
  }
  getNavigatorLanguage();
};

const getCookie_groupIsUniversal = () => getCookieAsParsedBoolean('groupIsUniversal');

const getCookiePageSizeItems = () => {
  const cookieValue = Cookies.get('pageSizeItems');
  return cookieValue ? parseInt(cookieValue) : 10;
};

const deleteCookie_selectedItemCustomerId = () => {
  Cookies.remove('selectedFacilityCustomerId');
  Cookies.remove('selectedScheduleCustomerId');
  Cookies.remove('selectedUniversalGroupCustomerId');
};

const setCookie = (name, value) => Cookies.set(name, value);

const deleteCookieAll = () => {
  Cookies.remove('customerId');
  Cookies.remove('tenants');
  Cookies.remove('userId');
  Cookies.remove('email');
  Cookies.remove('userRoles');
  Cookies.remove('token');
  sessionStorage.removeItem('access_token');
  sessionStorage.removeItem('expires_token');
  Cookies.remove('visibleDescendants');
  Cookies.remove('pagenumbers');
  Cookies.remove('agreementPending');
  Cookies.remove('showVersionModal');
  Cookies.remove('hideVerInfo');
  Cookies.remove('pageSizeItems');
  Cookies.remove('groupIsUniversal');
  Cookies.remove('dateTimeFormat');
  deleteCookie_selectedItemCustomerId();
};

export {
  saveCookies,
  setCookie,
  getCookieAccessToken,
  getCookieUserId,
  getCookieCustomerId,
  getCookieUserRoles,
  getCookieLanguage,
  getCookie_visibleDescendants,
  getCookieEmail,
  getCookieAgreementPending,
  getCookieShowVersionModal,
  getCookie_pageNumbers,
  getCookieHideVerInfo,
  getCookiePageSizeItems,
  getCookie_selectedFacilityCustomerId,
  getCookie_selectedScheduleCustomerId,
  getCookieDateTimeFormat,
  getCookie_selectedUniversalGroupCustomerId,
  getCookie_groupIsUniversal,
  getCookieFobHistorySearch,
  deleteCookieAll,
  deleteCookie_selectedItemCustomerId,
  getCookieEquipmentSearch,
  getCookieGroupsSearch,
  getCookieFacilitiesSearch,
};
