export default {
  alarms: {
    deleteAlarmPopUp: 'Jste si jisti, že chcete označit alarm jako spatřený?',
    deleteAlarmPopUpWithMove:
      'Přesunutím ovladače do jiné skupiny označí alarm jako spatřený. Chcete pokračovat?',
    deleteAlarmPopUpWithCloneUniversal: 'Kopírování univerzálního ovladače bude sdílet alarmy.',
    deleteAlarmPopUpWithClone:
      'Kopírováním ovladače do jiné skupiny se kopie bude jevit jako bez alarmů.',
  },
};
