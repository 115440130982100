import styled from 'styled-components';
import { getContrastColor, increaseBrightness } from '../../core/helpers/colorManager';

export default styled.label`
  border: 1px solid;
  padding: 8px 32px;
  border-radius: 2em;
  display: flex;
  cursor: pointer;
  margin-top: 3%;
  background-color: ${(props) => props.tenants.color1};
  color: ${(props) => getContrastColor(props.tenants.color1)};
  &:focus {
    background-color: ${(props) => increaseBrightness(props.tenants.color1, 50)};
    color: ${(props) => getContrastColor(increaseBrightness(props.tenants.color1, 50))};
    border-color: ${(props) => getContrastColor(props.tenants.color1)};
  }
  &:hover {
    background-color: ${(props) => increaseBrightness(props.tenants.color1, 30)};
    color: ${(props) => getContrastColor(increaseBrightness(props.tenants.color1, 30))};
    border-color: ${(props) => getContrastColor(props.tenants.color1)};
  }
`;
