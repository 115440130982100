export default {
  fobsPopUps: {
    instalations: 'Installations',
    groups: 'Groupes',
    move: 'Bouger',
    copy: 'Copier',
    remove: 'Supprimer',
    removeQuestion: 'Voulez-vous supprimer les éléments suivants?',
    selectedItemsShow1: 'Souhaitez-vous supprimer les ',
    selectedItemsShow2: ' éléments sélectionnés ?',
    warningDeleteFobs:
      "ATTENTION : il se peut que vous ayez plus d'éléments sélectionnés que vous n'en voyez à l'écran.",
    selectedItemsMove1: 'Souhaitez-vous déplacer les ',
    selectedItemsCopy1: 'Souhaitez-vous copier les ',
    add: 'Ajouter',
    selectedItemsAdd1: 'Souhaitez-vous ajouter les ',
  },
};
