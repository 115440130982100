import _ from 'lodash';

export const objectsAreEquals = (firstObject, secondObject) => {
  if (firstObject === secondObject) return true;

  if (!(firstObject instanceof Object) || !(secondObject instanceof Object)) return false;

  if (firstObject.constructor !== secondObject.constructor) return false;

  for (const property in firstObject) {
    if (!firstObject.hasOwnProperty(property)) continue;

    if (!secondObject.hasOwnProperty(property)) return false;

    if (firstObject[property] === secondObject[property]) continue;

    if (typeof firstObject[property] !== 'object') return false;

    if (!objectsAreEquals(firstObject[property], secondObject[property])) return false;
  }

  for (const property in secondObject) {
    if (secondObject.hasOwnProperty(property) && !firstObject.hasOwnProperty(property))
      return false;
  }
  return true;
};

export const parsePropValueFromString = (propValue) => {
  let response = propValue;
  try {
    response = JSON.parse(propValue);
  } catch {
    if (!isNaN(propValue)) {
      response = propValue * 1;
    } else if (propValue.match(/^(true)|(false)$/gi)) {
      response = !!propValue.match(/^true$/gi);
    }
  }
  return response;
};

export const updateObjectWithPropName = (originalObject, propName, newValue) => {
  const updatedObject = { ...originalObject };
  updatedObject[propName] = newValue;
  return updatedObject;
};

export const getObjectPropDifferences = (firstObject, secondObject) =>
  _.transform(firstObject, (result, value, key) => {
    if (!_.isEqual(value, secondObject[key])) {
      if (_.isArray(value) && _.isArray(secondObject[key])) {
        result[key] = value;
      } else if (_.isObject(value) && _.isObject(secondObject[key])) {
        result[key] = getObjectPropDifferences(value, secondObject[key]);
      } else {
        result[key] = value;
      }
    }
  });

export const getArrayDifferences = (array1, array2) =>
  array1
    .filter((actualArray) => !array2.includes(actualArray))
    .concat(array2.filter((actualArray) => !array1.includes(actualArray)));

export const capitalizeText = (text) => text && text[0].toUpperCase() + text.slice(1);

export const stringToJson = (string) => {
  try {
    return JSON.parse(string);
  } catch (error) {}
};

export default {
  objectsAreEquals,
  parsePropValueFromString,
  updateObjectWithPropName,
  getObjectPropDifferences,
  capitalizeText,
  getArrayDifferences,
  stringToJson,
};
