import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import '../SelectableFilters.css';

class Search extends Component {
  render() {
    const { t, value, onChange, onSubmit, onKeyDown, onKeyUp } = this.props;
    return (
      <div className='selectable-filters-search'>
        <form
          id='searchForm'
          className='search-form'
          action='/'
          method='get'
          onSubmit={(e) => {
            e?.preventDefault();
            onSubmit();
          }}
        >
          <input
            autoComplete='off'
            id='Search'
            type='search'
            onKeyDown={onKeyDown}
            onKeyUp={onKeyUp}
            onChange={(e) => onChange(e)}
            value={value ? value.replace(/%26/g, '&') : ''}
            className='searchInput-individual'
            placeholder={t('general.searchDotted')}
            aria-label='Search'
          />
          <span
            className='fas fa-search ser-search-btn search-button-individual'
            onClick={onSubmit ? () => onSubmit() : () => {}}
          />
        </form>
      </div>
    );
  }
}

const searchWithTranslation = withTranslation()(Search);

export default searchWithTranslation;
