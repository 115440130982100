export default {
  errors: {
    title: {
      honoa: 'HONOA Afficher l’état de la porte:',
    },
    402: 'Paiement Requis',
    1000: 'Échec de la connexion',
    name: "Ce nom n'est pas disponible",
    generic: 'Identifiant unique déjà utilisé',
    selectTypeFirst: "Sélectionnez d'abord un type",
    unexpectedError: 'Erreur inattendue',
    uniqueIdentity: 'Ce contenu existe déjà',
    10: "Démarrage de la section de configuration de l'API CloudAssistant",
    11: "Configuration terminée. L'API est prête pour les appels entrants",
    12: 'Définir la configuration de la version Api',
    13: "Paramètres globaux de l'API",
    14: 'Paramètres SMTP',
    15: 'Configuration des politiques de Coookie',
    16: "Définir l'environnement de compatibilité:",
    17: 'Configuration du contexte db',
    18: "Services d'authentification",
    19: 'Ajouter une documentation swagger',
    100: 'Utilisateur connecté',
    101: 'Utilisateur déconnecté',
    102: 'Mot de passe changé avec succès',
    103: 'Processus de mot de passe oublié terminé',
    104: 'Processus de réinitialisation du mot de passe terminé',
    105: "Envoi d'un e-mail à:",
    106: 'Utilisateur invalide',
    107: 'Utilisateur non mis à jour',
    108: 'Invitation expirée',
    109: 'Invitation non supprimée',
    110: 'Utilisateur non confirmé et mis à jour dans IS4',
    111: 'Utilisateur non enregistré avec succès',
    112: "Correspondance erronée de l'ID utilisateur ou client",
    113: "L'ID client n'existe pas",
    114: 'Invitation non envoyée',
    115: "L'invitation a été effacée",
    116: 'Invitation de restauration terminée',
    117: 'Attributs client non créés correctement',
    118: "Pas assez d'autorisations pour afficher ces éléments",
    119: "Pas assez d'autorisations pour afficher ou mettre à jour ces éléments",
    120: "Pas assez d'autorisations pour créer ces éléments",
    121: "Pas assez d'autorisations pour supprimer ces éléments",
    122: 'Utilisateur non supprimé dans son identité',
    123: 'Utilisateur non trouvé',
    124: "Conflit lors de la suppression d'un utilisateur dans son identité",
    125: "Échec de l'accès simultané",
    126: 'Erreur par défaut',
    127: "L'email existe déjà",
    128: 'Le nom du rôle existe déjà',
    129: 'Cet identifiant existe déjà',
    130: 'Email invalide',
    131: 'Nom de rôle non valide',
    132: 'Jeton invalide',
    133: "Nom d'utilisateur invalide",
    134: 'Login déjà associé',
    135: 'Le mot de passe nécessite des minuscules',
    136: 'Le mot de passe nécessite des valeurs non aplhanumériques',
    137: 'Le mot de passe nécessite des caractères uniques',
    138: 'Le mot de passe nécessite des majuscules',
    139: 'Mot de passe trop court',
    140: "Échec de l'échange du code de récupération",
    141: "L'utilisateur a déjà un mot de passe",
    142: "L'utilisateur a déjà un rôle",
    143: 'Utilisateur sans rôle',
    144: 'Blocage utilisateur non activé',
    145: "Échec de l'authentification, identifiant client non valide ou masqué",
    146: 'Requête invalide',
    147: "L'e-mail ou le mot de passe ne sont pas corrects",
    148: 'Étendue non valide',
    149: "Ce client n'est pas autorisé à utiliser le type de demande demandé",
    150: 'Type de subvention non pris en charge',
    151: 'Utilisateur non supprimé dans son identité',
    152: "Conflit lors de la suppression d'un utilisateur dans son identité",
    153: 'Abonnements non mis à jour',
    154: 'Les abonnements ont été mis à jour',
    155: "Erreur lors de l'obtention des abonnements",
    156: "Le client dispose de groupes ou d'installations universels avec code installateur",
    157: 'Erreur inconnue',
    158: 'Permissions insuffisantes',
    159: "L'e-mail de l'utilisateur existe déjà",
    160: 'Abonnements insuffisants',
    161: 'Rôles utilisateur non valides',
    162: 'Rôle utilisateur non mis à jour',
    163: 'Utilisateur non inscrit ou sans abonnement',
    164: 'Rôles utilisateur ou client non valides',
    165: "L'administrateur ne peut pas être mis à jour",
    166: 'Code installateur non mis à jour',
    167: 'Familles de produits non mises à jour',
    168: 'Produits non mis à jour',
    169: 'Aucun élément trouvé avec cet identifiant',
    170: "jeton d'actualisation",
    171: 'Abonnements insuffisants',
    172: 'Dispositif introuvable',
    200: 'Obtenir la liste de tous les utilisateurs',
    300: "Arguments incorrects ou nombre d'éléments dépassés pour une seule demande",
    301: 'Mise à jour réussie',
    302: 'Erreur de validation',
    400: 'Locataire enregistré avec succès',
    401: 'Il manque des données sur les locataires! Enregistrer sans succès',
    404: 'Appareil hors ligne ou échec de connexion.',
    9000: 'Échec de la connexion',
    9001: 'Échec de la réinitialisation du mot de passe',
    9002: 'Le mot de passe oublié a échoué',
    9003: 'Échec du changement de mot de passe',
    9004: 'Erreur de correspondance de mot de passe',
    9005: 'Le mot de passe nécessite au moins un chiffre',
    9006: 'Utilisateur inconnu',
    90000: 'Un test de bouclage a été demandé:',
    90001: 'Une exception a été demandée:',
    90002: "Un code d'état de retour a été demandé:",
    90100: "Débloquer l'exception SQL",
    90101: 'La création a échoué.',
    90102: 'Le nom que vous avez entré existe déjà, vous devez le changer',
    90103: 'Introuvable.',
    90104: 'La mise à jour a échoué',
    90105: 'Échec de la suppression',
    90106: 'Ce contenu existe déjà',
    90107: 'Le contenu ne peut pas être nul.',
    90108: "Échec de l'importation.",
    90109: "Échec de l'exportation.",
    90110:
      "Impossible de mettre à jour le code d'installation dans les installations avec des porte-clés attribués",
    90111: "L'ID de l'installation ou l'URL n'est pas valide",
    90112: "Groupe d'entités d'installation non créé",
    90113: 'Le groupe de ressources doit avoir un nom',
    90114: "Le nom du groupe d'entités d'installation existe déjà pour cette installation",
    90115: "Groupe d'entités d'installation non mis à jour",
    90116: "Groupe d'entités d'installation non supprimé",
    90117: "Le contact d'entité ne peut pas être nul",
    90118: "Entité d'installation non mise à jour",
    90119: "La description ou le code d'entité d'installation existe déjà pour ce groupe",
    90120: 'Impossible de supprimer le contact avec la position 0',
    90121: 'Contact non supprimé',
    90122: 'Contact non créé',
    90123: "L'entité a déjà 5 contacts",
    90124: "Contact d'entité non mis à jour",
    90125: "GroupId n'existe pas pour cette fonction",
    90126: 'FacilityId non valide',
    90127: "Contact d'entité d'installation non valide",
    90128: 'Le code installateur doit être le même pour chaque installation du groupe universel',
    90129: "Le code installateur doit être le même pour l'installation et le groupe universel",
    91000: 'Mac ou identifiant VDP introuvable',
    91001: 'Mac non valide',
    91002: 'Extension de messagerie introuvable',
    91003: 'Erreur lors de la mise à jour du nom vdp',
    91004: 'Erreur lors de la création de la relation de contact vdp',
    91005: 'Erreur lors de la création du contact',
    91006: 'Erreur lors de la suppression des contacts',
    91007: 'Erreur lors de la mise à jour du nom du contact',
    91008: 'Le nom du client existe déjà',
    91009: 'Code plat ou porte ou non valide',
    91010: 'Code non introduit',
    91011: 'Nombre de canaux corrompus',
    91012: 'La dernière relation de relais ne peut pas être supprimée',
    91013: 'Impossible de désactiver le relais car des groupes lui sont attribués',
    91014: 'Plage de codes invalide',
    91015: 'Les codes doivent être du même type',
    91016: 'Postes insuffisants dans le groupe',
    91017: "Le code existe déjà dans l'installation",
    91018: 'Le code existe déjà dans les universaux',
    91019: 'Le code remplace un universel supprimé',
    91020: "Cet équipement d'installation ne prend pas en charge les horaires",
    91021: 'Rien à copier',
    91022: 'Rien à déplacer',
    91023: 'Produit non valide',
    91024: 'Horaire introuvable',
    91025: 'Impossible de supprimer la planification avec une installation affectée',
    91026: "Le groupe demandé n'est pas universel",
    91027: "L'équipement a des paramètres invalides",
    91028: 'Impossible de supprimer un groupe universel avec des ressources affectées',
    91029: 'Numéro de série ou clé unique non enregistré',
    91030: 'Le numéro de série ou la clé unique ne peut pas être nul',
    91031: "Le code client n'est pas correct",
    91032: "Le code d'installation n'est pas correct",
    91033: "Le mot de passe n'est pas correct",
    91034: 'Fichier corrompu',
    91065: 'lien déjà attribué',
    91066: "Une erreur s'est produite lors de la désactivation du lien",
    91067: "Clé d'activation incorrecte",
    91068: "Demande d'API infructueuse",
    91069: 'Produit non activé',
    91070: 'Produit non trouvé',
    91071: 'Aucun relais sélectionné. Utilisez le bouton de configuration',
    91072: 'Le nom du relais existe déjà',
    91073: "Nombre maximum d'installations atteint.",
    91074: "Cette clé d'activation est utilisée par un autre utilisateur.",
    91100: 'Appareil déconnecté',
    91101: "Jumeaux d'appareil de lecture d'exception non gérés",
    91102: "Lire l'exception de la mémoire",
    91103: 'Ecrire une exception depuis la mémoire',
    91104: "La mémoire d'écriture ne correspond pas",
    91105: 'Le dispositif possède un code d’installateur',
    91106: 'Le dispositif n’a pas de code d’installateur',
    91302: 'Les informations SIM ne sont pas disponibles',
    91303: "Le contrat SIM n'est pas disponible",
    91307: "Impossible d'obtenir le numéro de série",
    91308: "Clé d'activation zéro ou vide",
    91309: "Clé d'activation nulle, vide ou invalide",
    91400: "Erreur lors de la suppression de l'alarme",
    91201: "L'utilisateur existe déjà. Toutes les données seront héritées",
    91452: 'Le dispositif ne prend pas en charge les calendriers avec jours fériés',
    91527: "Impossible de supprimer l'installation avec le groupe universel assigné.",
    91528: 'Impossible de supprimer le dispositif avec le groupe assigné.',
    91529: 'Impossible de supprimer le groupe avec les émetteurs.',
    91203: "L'utilisateur existe déjà dans cette installation",
    universalGroupsAssigned: 'UG affectés: ',
    groupsAssigned: 'Groupes affectés: ',
    operationNotCompleted: "L'opération n'a pas pu être terminée",
    redirectWarning: 'Vous avez été redirigé vers la page principale',
    noContent: 'Aucun contenu disponible pour cette annonce',
    communicationError: 'Erreur de communication, réessayez plus tard',
    91530:
      'Impossible de désaffecter ce dispositif car le matériel qui reste ne prend pas en charge les émetteurs',
    91531: "Impossible de réassigner l'installation parce qu'elle utilise des groupes universels",
    91532: "L'installation contient des dispositifs incompatibles",
    91534: 'Massimo numero di dispositivi Honoa raggiunti da questa installazione',
    91310: "Ce numéro de série n'est pas assigné à l'utilisateur",
    91311: 'Erreur de communication avec les serveurs',
    91312: 'Erreur de production de la licence',
    91400: "Erreur de désactivation de l'alarme",
    91450: 'Les horaires suivants n’ont pas de plage valide: ',
    91451: 'Au moins un jour férié doit être sélectionné',
    91452:
      "Un dispositif en cours d'utilisation ne prend pas en charge les calendriers avec jours fériés",
    91203: "L'utilisateur existe déjà dans cette installation",
    91537: 'Impossible de supprimer un groupe comprenant des utilisateurs Honoa',
    91453: 'ce nom d’horaire existe déjà',
    91536: 'ce nom d’installation existe déjà',
    91800: 'Format de numéro de téléphone non pris en charge',
    91801: "Format d'URL web non pris en charge",
    needsMinOnRelay: 'Il doit y avoir au moins un relais assigné',
    91802: 'Ce dispositif ne prend pas en charge les émetteurs',
    91803: 'Ce groupe ne prend pas en charge les émetteurs',
    nameRequired: "Impossible d'enregistrer. Noms requis dans les familles et les produits.",
    deletedUser: 'Utilisateur supprimé',
    91804: 'Le nom d’un ou plusieurs horaire(s) affecté(s) à cette installation est trop long.',
    91538: 'Erreur lors du téléchargement du PDF',
    timeout: "Temps d'attente dépassé.",
    91853: 'Le fuseau horaire ne peut être vide.',
    91539: 'Impossible de désactiver un dispositif actuellement utilisé dans les installations.',
    zeroCode: 'Attention ! Vous travaillez avec le code d’installateur 0',
    91540: 'Impossible de désaffecter le dispositif, car le groupe contient des utilisateurs HONOA',
    91854: 'Le groupe ne prend pas en charge les utilisateurs HONOA',
    92030: 'Votre abonnement n´est pas compatible avec cette fonction.',
    92060: 'La date de début ne peut être postérieure à la date de fin',
    92061: 'La date de début doit être postérieure à celle d’aujourd’hui',
    92031: 'Fonction non autorisée sans fins de course (capteurs/entrées)',
    91075: 'Réservations disponibles insuffisantes',
    304: "Littéral n'est pas valide (espaces vides, espaces en tête ou en fin, ...)",
  },
};
