import React from 'react';
import { connect } from 'react-redux';
import { generatePath, withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { v1 as uuidv1 } from 'uuid';
import _ from 'lodash';
import { CodesCounters } from '@jcm-technologies/uikit/dist/molecules/CodesCounters';
import GenericForm from '../GenericForm/GenericForm';

import { setOnFlyErrors, validateForm } from '../../core/old_common/validations/service';
import validationsDictionary from '../../core/old_common/validations';
import getOnFlyValidations from '../../core/old_common/utils/validationsApi';

import './SequentialEnrollCode.css';
import {
  invokeSendFunction,
  setAssistantPanelCheckSuccessFunction,
  setMessageHubFunction,
} from '../../core/old_common/utils/signalRService';
import SignalRComponent from '../SignalRComponent/SignalRComponent';
import { resetIsPostFobSuccess } from '../../modules/group/fobs/actions';
import { resetIsPostUniversalFobSuccess } from '../../modules/old_to_refact/actions/universalGroupsFobs';
import { setProductId } from '../../modules/old_to_refact/actions/signalr';
import PopUpErrorMessage from '../PopupErrorMessage/PopUpErrorMessage';

class SequentialEnrollCode extends SignalRComponent {
  constructor(props) {
    super(props);
    this.state = { ...this.initialState };
  }

  initialState = {
    id: uuidv1(),
    productId: '',
    e_code: '',
    endCode: '',
    name: '',
    surname: '',
    nic: '',
    plaza: '',
    codesAmount: 1,
    formErrors: [],
    onFlyFormErrors: [],
    isUniversal: false,
    selectedSignalRInput: '',
    forceSaveChangePopup: true,
    fobSaved: false,
    productIdWrite: undefined,
    needValidate: false,
  };

  componentDidMount() {
    setMessageHubFunction(this.newMessageHubFunction);
    setAssistantPanelCheckSuccessFunction(this.getFOBCode);
  }

  componentDidUpdate() {
    const {
      history,
      match,
      isFobPostedSuccess,
      getFobCounters,
      resetIsPostFobSuccess,
      resetIsPostUniversalFobSuccess,
      isUniversalFobPostedSuccess,
    } = this.props;

    if (this.state.fobSaved && isFobPostedSuccess) {
      const decoded = generatePath(`/Facilities/:instalationId/groups/:instalationGroupId`, {
        instalationId: match.params.instalationId,
        instalationGroupId: match.params.instalationGroupId,
      });
      this.props.history.push(`${decoded}`);
    } else if (this.state.fobSaved && isUniversalFobPostedSuccess) {
      const decoded = generatePath(`/UniversalGroups/:id/fobs`, {
        id: match.params.id.replace('%09', '%2509'),
      });
      this.props.history.push(`${decoded}`);
    }

    if (isFobPostedSuccess || isUniversalFobPostedSuccess) {
      getFobCounters();
      isFobPostedSuccess ? resetIsPostFobSuccess() : resetIsPostUniversalFobSuccess();
    }

    if (this.state.needValidate) {
      const formErrors = validateForm(this.getFields());
      this.setState({ needValidate: false, formErrors: formErrors.errors });
    }
  }

  getFOBCode = () => {
    const { needInstallerCode, products, setProductId } = this.props;
    const productsData = _.compact(products.map((family) => family.options).flat());
    const selectedProduct = productsData.find(
      (product) => product.id === this.state.productId
    ).product;
    setProductId(selectedProduct.productIdWrite);
    invokeSendFunction(
      `${selectedProduct.name}::${selectedProduct.productIdWrite}::${selectedProduct.productIdRead}::null::${needInstallerCode}`,
      20000
    );
    this.setState({ onFlyFormErrors: [], e_code: '' });
  };

  newMessageHubFunction = (connId, id) => {
    this.setState({ [this.state.selectedSignalRInput]: id });
    this.executeOnFlyValidations('e_code', this.getCheckCodesEndpoint(id, 1));
    if (this.state.selectedSignalRInput === 'endCode') {
      this.executeOnFlyValidations('endCode', this.getCheckCodesEndpoint(id, 1));
      this.calculateInitialCode(id);
    }
    if (this.state.selectedSignalRInput === 'e_code') {
      this.executeOnFlyValidations('e_code', this.getCheckCodesEndpoint(id, 1));
      this.calculateFinalCodeWithSignalR(parseInt(id));
    }
  };

  handleChange = (e) => this.setState({ [e.target.id]: e.target.value, needValidate: true });

  handleNumericChange = (e) => {
    this.setState({ onFlyFormErrors: [], endCode: '' });
    if (!isNaN(e.target.value)) {
      if (e.target.id === 'e_code') {
        if (parseInt(e.target.value) > parseInt(e.target.max)) {
          e.target.value = e.target.max;
        } else if (parseInt(e.target.value) < parseInt(e.target.min)) {
          e.target.value = e.target.min;
        } else if (e.target.value === '') {
          this.setState({ onFlyFormErrors: [], endCode: '' });
        }
      }
      this.setState({ [e.target.id]: parseInt(e.target.value.split(':')[0]), needValidate: true });
      if (e.target.value !== '') {
        this.calculateFinalCode(e);
      }
    }
  };

  calculateFinalCodeWithSignalR = (initCode) => {
    const { codesAmount } = this.state;
    const finalCode = initCode > 0 ? initCode + (parseInt(codesAmount) - 1) : 0;
    if (!isNaN(finalCode)) {
      this.setState({ endCode: finalCode });
    }
    if (initCode) {
      this.handleNumberBlur('e_code', initCode, codesAmount);
    }
  };

  calculateFinalCode = (e) => {
    const { codesAmount, e_code } = this.state;
    const initialCode =
      parseInt(e.target.id !== 'e_code' ? (codesAmount !== 0 ? e_code : 0) : e.target.value) || 0;
    const finalCode =
      initialCode > 0
        ? initialCode + (parseInt(e.target.id === 'codesAmount' ? e.target.value : codesAmount) - 1)
        : 0;
    if (!isNaN(finalCode)) {
      this.setState({ endCode: finalCode });
    }
    if (e_code && codesAmount && e.target.value && e.target.id !== 'codesAmount') {
      this.handleNumberBlur(e.target.id, initialCode, codesAmount);
    }
    if (e.target.id === 'codesAmount') {
      this.handleNumberBlur('e_code', initialCode, e.target.value);
    }
  };

  calculateInitialCode = (finalCode) => {
    const { codesAmount } = this.state;
    const initialCode = parseInt(finalCode) - (parseInt(codesAmount) - 1);
    if (!isNaN(initialCode)) {
      this.setState({ e_code: initialCode });
    }
    if (initialCode && codesAmount) {
      this.handleNumberBlur('finalCode', initialCode, codesAmount);
    }
  };

  handleBlur = (e) => {
    const { fobsGetCheckContent } = this.props;
    if (e.target.value) {
      this.executeOnFlyValidations(
        e.target.id,
        fobsGetCheckContent(e.target.id, this.state[e.target.id])
      );
    }
  };

  getCheckCodesEndpoint = (code, count) => {
    const { fobsCheckCodesEndpoint, fobsCheckCodesLongEndpoint } = this.props;
    const selectedProduct = this.getProductBySelectedId();
    return selectedProduct && selectedProduct.code === 169 && fobsCheckCodesLongEndpoint
      ? fobsCheckCodesLongEndpoint(code, count)
      : fobsCheckCodesEndpoint(code, count);
  };

  handleNumberBlur = (id, initialCode, codesCount) => {
    this.executeOnFlyValidations(id, this.getCheckCodesEndpoint(initialCode, codesCount));
  };

  getProductBySelectedId = () => {
    const { products } = this.props;
    if (products) {
      const productsList = products
        .filter((product) => product.options !== undefined)
        .map((product) => product.options)
        .flat();
      const selectedProduct = productsList.find(
        (product) => product.product.productId === this.state.productId
      );
      return selectedProduct ? selectedProduct.product : undefined;
    }
    return undefined;
  };

  executeOnFlyValidations = (inputId, endPoint) => {
    const { t } = this.props;
    getOnFlyValidations(endPoint, (isError, response) => {
      this.setErrorsCallback({
        onFlyFormErrors: this.state.onFlyFormErrors,
        error: isError,
        id: inputId,
        errorMessage: isError ? t(`errors.${response?.error?.code}`) : '',
      });
    });
  };

  setErrorsCallback = ({ onFlyFormErrors, error, id, errorMessage }) => {
    this.setState({
      onFlyFormErrors: setOnFlyErrors({ onFlyFormErrors, error, id, errorMessage }),
    });
  };

  checkValidations = () => {
    const { isUG, fobsCount } = this.props;
    if (isUG) {
      return [
        () => validationsDictionary.isRequired(this.state.codesAmount),
        () => validationsDictionary.isNumeric(this.state.codesAmount),
      ];
    }
    return [
      () => validationsDictionary.isRequired(this.state.codesAmount),
      () => validationsDictionary.isNumeric(this.state.codesAmount),
      () => validationsDictionary.deviceLimit(this.state.codesAmount, 1, fobsCount.free),
    ];
  };

  getFields = () => {
    const { t, products, fobsCount, hiddenFields, isUG } = this.props;
    const fields = [
      [
        {
          type: 'dropdown',
          className: 'col',
          values: products,
          placeHolder: `${t('enrollCode.device')} *`,
          onChange: this.handleChange,
          value: this.state.productId,
          id: 'productId',
          validation: [() => validationsDictionary.isRequired(this.state.productId)],
        },
        {
          type: 'input',
          className: 'col',
          placeHolder: `${t('enrollCode.numberOfFobs')} *`,
          onChange: this.handleNumericChange,
          value: this.state.codesAmount,
          id: 'codesAmount',
          validation: this.checkValidations(), // [
          //   () => validationsDictionary['isRequired'](this.state.codesAmount),
          //   () => validationsDictionary['isNumeric'](this.state.codesAmount),
          //   () => isUG ? validationsDictionary['deviceLimit'](this.state.codesAmount, 1, fobsCount.free) : null,
          // ],
        },
      ],
      [
        {
          type: 'input',
          placeHolder: `${t('enrollCode.initialCode')} *`,
          onChange: this.handleNumericChange,
          onBlur: this.calculateFinalCode,
          value: this.state.e_code,
          id: 'e_code',
          className: 'col',
          icon: this.state.productId ? 'fas fa-external-link-alt' : '',
          onIconClick: () => {
            this.setState({ selectedSignalRInput: 'e_code' });
            this.getFOBCode();
          },
          validation: [
            () => validationsDictionary.isRequired(this.state.e_code),
            () => validationsDictionary.isNumeric(this.state.e_code),
          ],
        },
        {
          type: 'input',
          className: 'col',
          placeHolder: `${t('enrollCode.endCode')} *`,
          onChange: this.handleNumericChange,
          value: this.state.endCode,
          readonly: true,
          id: 'endCode',
          icon: this.state.productId ? 'fas fa-external-link-alt' : '',
          onIconClick: () => {
            this.setState({ selectedSignalRInput: 'endCode' });
            this.getFOBCode();
          },
          validation: [
            () => validationsDictionary.isRequired(this.state.endCode),
            () => validationsDictionary.isNumeric(this.state.endCode),
          ],
        },
      ],
      [
        {
          type: 'input',
          placeHolder: t('form.name'),
          className: 'col',
          onChange: this.handleChange,
          value: this.state.name,
          id: 'name',
          validation: [() => validationsDictionary.isInTextLength(this.state.name, 50)],
        },
        {
          type: 'input',
          placeHolder: t('form.surname'),
          className: 'col',
          onChange: this.handleChange,
          value: this.state.surname,
          id: 'surname',
          validation: [() => validationsDictionary.isInTextLength(this.state.surname, 80)],
        },
      ],
      [
        {
          type: 'input',
          placeHolder: t('form.identityCard'),
          className: 'col',
          onChange: this.handleChange,
          value: this.state.nic,
          id: 'nic',
          validation: [() => validationsDictionary.isInTextLength(this.state.nic, 50)],
        },
        {
          type: 'input',
          className: 'col',
          placeHolder: t('enrollCode.spot'),
          onChange: this.handleNumericChange,
          value: this.state.plaza,
          id: 'plaza',
          validation: [() => validationsDictionary.isNumeric(this.state.plaza)],
          notShow: hiddenFields ? hiddenFields.includes('plaza') : false,
        },
      ],
    ];
    return fields;
  };

  handleCancel = () => {
    const { match, isUG } = this.props;
    if (isUG) {
      const decoded = generatePath(`/UniversalGroups/:id/fobs`, {
        id: encodeURIComponent(decodeURIComponent(match.params.id)),
      });
      this.props.history.push(`${decoded}`);
    } else {
      const decoded = generatePath(`/Facilities/:instalationId/groups/:instalationGroupId`, {
        instalationId: encodeURIComponent(decodeURIComponent(match.params.instalationId)),
        instalationGroupId: encodeURIComponent(decodeURIComponent(match.params.instalationGroupId)),
      });
      this.props.history.push(`${decoded}`);
    }
  };

  handleSubmit = (e) => {
    const { postSequentialFOB, history, match } = this.props;
    e?.preventDefault();
    const formErrors = validateForm(this.getFields());
    this.setState({ formErrors: formErrors.errors });
    if (!formErrors.hasError && !this.state.onFlyFormErrors.length) {
      postSequentialFOB(this.state, false);
      this.setState({ ...this.initialState, forceSaveChangePopup: false, fobSaved: true });
    }
  };

  closePopupError = () => {
    this.props.resetSignalRPopup();
    this.setState({ productIdWrite: undefined });
  };

  render() {
    const { tenants, fobsCount, t, isUG } = this.props;
    const { formErrors, onFlyFormErrors } = this.state;
    const isSubmitDisabled = fobsCount.free === 0 && !isUG;
    return (
      <div className='sequential-enroll-form'>
        <CodesCounters
          availableValue={fobsCount.free}
          availablePlaceholder={t('codesCount.available')}
          usedValue={fobsCount.busy}
          usedPlaceholder={t('codesCount.used')}
          reservationValue={fobsCount.reserved}
          reservationPlaceholder={t('codesCount.reserved')}
        />
        <div className='col-md-12 col-lg-12'>
          <GenericForm
            tenants={tenants}
            fields={this.getFields()}
            isCancelVisible
            isSubmitDisabled={isSubmitDisabled}
            handleCancel={this.handleCancel}
            handleSubmit={this.handleSubmit}
            formErrors={[...(formErrors || []), ...(onFlyFormErrors || [])]}
            forceSaveChangePopup
          />
        </div>
        <PopUpErrorMessage
          isOpen={this.props.showSignalRPopup}
          productId={this.props.productId ? this.props.productId : ''}
          needTagMessage={!!(this.props.productId === 8 || this.props.productId === 10)}
          fromEnroll
          onClose={() => this.closePopupError()}
        />
        {isSubmitDisabled ? (
          <div className='enroll-error-message'> {t('enrollCode.cantEnroll')}</div>
        ) : (
          ''
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  tenants: state.tenants,
  isFobPostedSuccess: state.fobs.isFobPostedSuccess,
  isUniversalFobPostedSuccess: state.universalGroupsFobs.isUniversalFobPostedSuccess,
  showSignalRPopup: state.signalR.showSignalRPopup,
  productId: state.signalR.productId,
});

const SequentialEnrollCodeWithTranslation = withTranslation()(SequentialEnrollCode);
const SequentialEnrollCodeWithRoute = withRouter(SequentialEnrollCodeWithTranslation);

export default connect(mapStateToProps, {
  resetIsPostFobSuccess,
  resetIsPostUniversalFobSuccess,
  setProductId,
})(SequentialEnrollCodeWithRoute);
