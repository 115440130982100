const POST_NEIGHBOUR = 'POST_NEIGHBOUR';
const PUT_NEIGHBOUR = 'PUT_NEIGHBOUR';
const GET_NEIGHBOURS = 'GET_NEIGHBOURS';
const DELETE_NEIGHBOUR = 'DELETE_NEIGHBOURS';
const SHOW_NEIGHBOUR_POPUP = 'SHOW_NEIGHBOUR_POPUP';
const SET_EDITED_POPUP = 'SET_EDITED_POPUP';
const RESET_EDITED_POPUP = 'RESET_EDITED_POPUP';
const RESET_REFRESH_NEIGHBOURS = 'RESET_REFRESH_NEIGHBOURS';

export default {
  POST_NEIGHBOUR,
  PUT_NEIGHBOUR,
  GET_NEIGHBOURS,
  DELETE_NEIGHBOUR,
  SHOW_NEIGHBOUR_POPUP,
  SET_EDITED_POPUP,
  RESET_EDITED_POPUP,
  RESET_REFRESH_NEIGHBOURS,
};

export const postNeighbour = (facilityId, equipmentId, objectNeighbour) => ({
  type: POST_NEIGHBOUR,
  facilityId,
  equipmentId,
  objectNeighbour,
});
export const putNeighbour = (facilityId, equipmentId, neighbourId, objectNeighbour) => ({
  type: PUT_NEIGHBOUR,
  facilityId,
  equipmentId,
  neighbourId,
  objectNeighbour,
});
export const getNeighbours = (facilityId, equipmentId) => ({
  type: GET_NEIGHBOURS,
  facilityId,
  equipmentId,
});
export const deleteNeighbour = (facilityId, equipmentId, neighbourId) => ({
  type: DELETE_NEIGHBOUR,
  facilityId,
  equipmentId,
  neighbourId,
});
export const showNeighbourPopUp = (status) => ({ type: SHOW_NEIGHBOUR_POPUP, status });
export const setEditedPopUp = (neighbour) => ({ type: SET_EDITED_POPUP, neighbour });
export const resetEditedPopUp = () => ({ type: RESET_EDITED_POPUP });
export const resetRefreshNeighbours = () => ({ type: RESET_REFRESH_NEIGHBOURS });
