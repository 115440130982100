export default {
  breadcrumb: {
    dashboard: 'Dashboard',
    Facilities: 'Installationen',
    FacilityEquipments: 'Geräte',
    Groups: 'Benutzergruppen',
    Equipments: 'Geräte',
    AddUniversalGroup: 'Universalgruppe hinzufügen',
    Configuration: 'Aufbau',
    Enroll: 'Registrierungsprozess',
    administration: 'Administrator',
    'user-config': 'Mein Konto',
    UniversalGroups: 'Universelle Gruppen',
    Users: 'Benutzer',
    Help: 'Hilfe',
    Parameters: 'Parameter',
    Relays: 'Relais',
    Fobs: 'Sender',
    Actions: 'Aktivitäten',
    Events: 'Ereignisse',
    'Remote comm': 'Fernzugriff',
    Facility: 'Neue Einrichtung',
    Equipment: 'Neues Gerät',
    FacilityGroup: 'Benutzergruppen',
    Neighbours: 'Liste der Nachbarn',
    UniversalGroup: 'Neue universelle Gruppe',
    Schedule: 'Neuer Zeitplan',
    customization: 'Passen Sie das Web an',
    Invitations: 'Einladungen',
    Companies: 'Firmen',
    UpdateUser: 'Mein Konto',
    preferences: 'Einstellungen',
    'assistant-panel': 'Assistant Panel',
    Holidays: 'Feiertage',
    Information: 'Information',
    recycle: 'Papierkorb',
  },
};
