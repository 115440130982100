const CLONE_GROW = 'CLONE_GROW';
const RESET_CAN_REDIRECT = 'RESET_CAN_REDIRECT';
const SET_MANUAL_GROW_REDIRECT_URL = 'SET_MANUAL_GROW_REDIRECT_URL';
const RESET_MANUAL_GROW_REDIRECT_URL = 'RESET_MANUAL_GROW_REDIRECT_URL';

export default {
  CLONE_GROW,
  RESET_CAN_REDIRECT,
  SET_MANUAL_GROW_REDIRECT_URL,
  RESET_MANUAL_GROW_REDIRECT_URL,
};
export const setCloneGrow = (isCloned, item) => ({ type: CLONE_GROW, isCloned, item });
export const resetCanRedirect = () => ({ type: RESET_CAN_REDIRECT });
export const setManualGrowRedirectURL = (url) => ({ type: SET_MANUAL_GROW_REDIRECT_URL, url });
export const resetManualGrowRedirectURL = () => ({ type: RESET_MANUAL_GROW_REDIRECT_URL });
