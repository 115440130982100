import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { JCMProvider } from '@jcm-technologies/uikit/dist/JCMProvider';
import { GlobalStyles, theme } from '@jcm-technologies/uikit/dist';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations';
import store from '../../modules/old_to_refact/stores/configureStore';
import { getLogedRoutes, getNotLogedRoutes } from './service';
import App from '../App';

import PathNotExist from '../PathNotExist/PathNotExist';
import { msalConfig } from '../../core/config/authConfig';
import Layout from '../../components/Layout';

export const isSentryEnvLoaded =
  !process.env.REACT_APP_FE_BASE?.includes('test') &&
  !process.env.REACT_APP_FE_BASE?.includes('dev') &&
  !process.env.REACT_APP_FE_BASE?.includes('localhost');

if (isSentryEnvLoaded) {
  Sentry.init({
    dsn: 'https://8b3c132ec7e24cfab32dcdb9b274f18c@o4503981925859328.ingest.sentry.io/4504050053087232',
    normalizeDepth: 10,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
      new CaptureConsoleIntegration({
        // array of methods that should be captured
        // defaults to ['log', 'info', 'warn', 'error', 'debug', 'assert']
        levels: ['warn', 'error', 'assert'],
      }),
      new Sentry.Replay({
        // Additional SDK configuration goes in here, for example:
        maskAllText: true,
        blockAllMedia: true,
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    environment: process?.env?.NODE_ENV,
    release: process?.env?.npm_package_version,
  });
}

const pca = new PublicClientApplication(msalConfig);

if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register('/sw_jcm.js')
    .then((reg) => {
      // all ok
    })
    .catch((err) => {
      console.warn('Error whilst registering service worker', err);
    });
}

const Root = () => (
  <MsalProvider instance={pca}>
    <Provider store={store}>
      <JCMProvider theme={theme}>
        <GlobalStyles />
        <Suspense fallback='loading'>
          <Router>
            <Layout>
              <Switch>
                <Route exact path='/' component={App} />
                {getLogedRoutes()}
                {getNotLogedRoutes()}
                <Route component={PathNotExist} />
              </Switch>
            </Layout>
          </Router>
        </Suspense>
      </JCMProvider>
    </Provider>
  </MsalProvider>
);

export default Sentry.withProfiler(Root);
