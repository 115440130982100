import {
  GET_DASHBOARD_FILTER,
  GET_DASHBOARD_FILTER_BY_TYPE,
  GET_DASHBOARD_INFO,
  GET_POSTAL_CODE,
  GET_CITIES,
  GET_EQUIPMENTS_SEARCH
} from './constants';

import { GET_FIELD} from '../old_to_refact/reducers/instalations';

/**
 * Sets the form state
 * @param  {int} value                The value for show loading
 * @return {object}                   Formatted action for the reducer to handle
 */

export const getDashboardFilter = () => ({
  type: GET_DASHBOARD_FILTER.BASE
});

export const getDashboardFilterByType = (filterType) => ({
  type: GET_DASHBOARD_FILTER_BY_TYPE.BASE,
  filterType,
});

export const getDashboardInfo = () => ({
  type: GET_DASHBOARD_INFO.BASE
});

export const getPostalCode = () => ({
  type: GET_POSTAL_CODE.BASE
});

export const getCities = () => ({
  type: GET_CITIES.BASE
});

export const getEquipments = (fieldId, value) => ({
  type: GET_EQUIPMENTS_SEARCH.BASE,
  fieldId,
  value,
});


export const getField = (fieldId, value) => ({ type: GET_FIELD.BASE, fieldId, value });
