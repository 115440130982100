import createFetchTypes from '../../../core/typeActions/types';
import a5kAction from '../actions/a5Kservices';

export const a5K_COMUNICATIONS_GET = createFetchTypes('a5K_COMUNICATIONS_GET');
export const a5K_POST_GET_EVENTS = createFetchTypes('a5K_POST_GET_EVENTS');
export const a5K_ONLINE_DEVICES_GET = createFetchTypes('a5K_ONLINE_DEVICES_GET');
export const a5K_FORMAT_EVENTS_POST = createFetchTypes('a5K_FORMAT_EVENTS_POST');
export const a5K_READ_EVENTS_POST = createFetchTypes('a5K_READ_EVENTS_POST');
export const a5K_READ_MEMORY_POST = createFetchTypes('a5K_READ_MEMORY_POST');
export const a5K_SET_MEMORY_POST = createFetchTypes('a5K_SET_MEMORY_POST');

/* Redux state init */
const initialState = {
  remoteComunication: [],
  events: [],
  onlineDevices: [],
  needUpdateList: false,
  fobs: [],
};

export default function equipmentsReducer(state = initialState, action) {
  if (action.type !== undefined) {
    switch (action.type) {
      case a5K_COMUNICATIONS_GET.SUCCESS:
        return {
          ...state,
          remoteComunication: [...action.result.commands],
        };
      case a5K_POST_GET_EVENTS.SUCCESS:
        return { ...state, events: [...action.result] };

      case a5K_ONLINE_DEVICES_GET.SUCCESS:
        return {
          ...state,
          onlineDevices: [...action.result],
        };
      case a5K_FORMAT_EVENTS_POST.SUCCESS:
        return {
          ...state,
          needUpdateList: true,
        };
      case a5K_READ_EVENTS_POST.SUCCESS:
        return {
          ...state,
          needUpdateList: true,
        };
      case a5K_READ_MEMORY_POST.SUCCESS:
        return {
          ...state,
          needUpdateList: true,
        };
      case a5K_SET_MEMORY_POST.SUCCESS:
        return {
          ...state,
          needUpdateList: true,
        };
      case a5kAction.RESET_NEED_UPDATE_LIST:
        return {
          ...state,
          needUpdateList: false,
        };
      case a5kAction.RESET_A5KSERVICES_EVENTS:
        return {
          ...state,
          events: [],
        };
      default:
        return state;
    }
  }
  return state;
}
