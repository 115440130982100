import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import {
  getEquipmentActions,
  getEquipmentConnectivity,
  putEquipmentIsUpdated,
} from '../../modules/old_to_refact/actions/equipments';
import { showDialog } from '../../modules/old_to_refact/actions/loading';
import { writeMemorySingle } from '../../modules/old_to_refact/actions/devices';
import {
  getDevicePing,
  getMemoryCodes,
  getMemoryConfig,
  resetDriversAsyncResponse,
  sendMemoryCodes,
} from '../../modules/old_to_refact/actions/drivers';
import GenericTable from '../GenericTable/GenericTable';
import IconSpan from '../IconSpan/IconSpan';
import Popup from '../Popup';
import actionType, { actionNames } from './actionType';
import {
  checkAssistantPanelConnection,
  invokeSendCICC,
  invokeSendFunction,
  parseCCIC,
  setAssistantPanelCheckSuccessFunction,
  setMessageHubFunction,
} from '../../core/old_common/utils/signalRService';

class EquipmentActionTabContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPopupOpen: false,
      popupComponent: null,
      popupProps: {},
      signalRActionType: '',
      canExecuteSaveMemory: false,
      showMessage: false,
      needIconRefresh: false,
      equipmentIsFullUpdated: true,
      iChangeColor1Time: false,
    };
    this.myRef = React.createRef();
  }

  componentDidMount() {
    const { getEquipmentActions, facilityId, equipmentId } = this.props;
    getEquipmentActions(facilityId, equipmentId);
  }

  componentDidUpdate(prevProps) {
    const {
      driverWriteError,
      codes,
      config,
      asyncResponseTimeout,
      needCallConnectivity,
      equipmentConnection,
      t,
      driverWriteDone,
      driverNotResponseError,
      isUpdatedEquipment,
      updatedEquipment,
    } = this.props;
    if (driverWriteDone && this.state.showMessage) {
      this.props.showDialog(-1);
      toast.success(t('equipments.savedMemory'));
      this.setState({ showMessage: false });
      // }else if(this.props.driverWriteError !== undefined && this.state.showMessage){
      //     const message = t(`errors.${driverWriteError.error.code}`);
      //     toast.error(message, { autoClose: false });
      //     this.props.showDialog(-1);
      //     this.setState({showMessage: false});
      // }
    } else if (
      this.props.driverNotResponseError &&
      !this.props.driverWriteDone &&
      this.state.showMessage
    ) {
      this.props.showDialog(-1);
      const message = t(`errors.operationNotCompleted`);
      toast.error(message, { autoClose: false });
      this.setState({ showMessage: false });
    }
    if (codes && config && this.state.canExecuteSaveMemory) {
      this.setState({ canExecuteSaveMemory: false });
      checkAssistantPanelConnection();
    }
    if (prevProps.asyncResponseTimeout === undefined && asyncResponseTimeout) {
      this.props.showDialog(1);
    }

    if (isUpdatedEquipment === 1 && updatedEquipment && this.state.iChangeColor1Time) {
      if (updatedEquipment && !this.state.needIconRefresh && this.state.equipmentIsFullUpdated) {
        this.changeSaveMemoryIconColor('red');
        this.setState({ equipmentIsFullUpdated: false });
      } else if (this.state.needIconRefresh) {
        this.changeSaveMemoryIconColor('#a4aebb');
        this.setState({ needIconRefresh: false, iChangeColor1Time: false });
      }
    }

    if (!prevProps.needCallConnectivity && needCallConnectivity && equipmentConnection) {
      if (!equipmentConnection.message && equipmentConnection.connected) {
        if (equipmentConnection.connected.includes('OffLine')) {
          this.changeIconColor('red');
          toast.error(t('equipments.disconnectedKey'));
        } else if (equipmentConnection.connected.includes('OnLine')) {
          this.changeIconColor('#00e600');
          toast.success(t('equipments.connectedKey'));
        } else {
          this.changeIconColor('red');
          toast.error(t('equipments.disconnectedKey'));
        }
      }
    }
  }

  getColumns = () => {
    const { t, name } = this.props;
    const tabName = name.toLowerCase().replace('.', '');
    return [
      {
        value: t('form.name'),
      },
      {
        value: i18next.exists(`equipments.${tabName}`) ? `${t(`equipments.${tabName}`)}` : name,
      },
    ];
  };

  handleClosePopup = () => {
    this.setState({ isPopupOpen: false });
  };

  changePopUpState = (onPopUpOpen, popupComponent, popupProps) => {
    this.setState({ isPopupOpen: onPopUpOpen, popupComponent, popupProps });
  };

  onClickVerifyConnectivity = (e) => {
    const { getEquipmentConnectivity, facilityId, equipmentId } = this.props;
    getEquipmentConnectivity(facilityId, equipmentId);
  };

  changeIconColor = (color) => {
    const element = document.getElementById('connection');
    if (element != null) {
      document.getElementById('connection').style.color = color;
    }
  };

  changeSaveMemoryIconColor = (color) => {
    const element = document.getElementById('saveConfig');
    if (element != null) {
      document.getElementById('saveConfig').style.color = color;
    }
  };

  sendMemoryNotification = () => {
    this.props.showDialog(-1);
    this.props.resetDriversAsyncResponse();
    toast.success(this.props.t('equipments.savedMemory'));
  };

  saveMemoryResponse = () => {
    const { t } = this.props;
    toast.success(t('equipments.savedMemory'));
  };

  readCICC = (connId, ciccCode) => {
    const {
      sendMemoryCodes,
      facilityId,
      equipmentId,
      product,
      config,
      codes,
      facility,
      writeMemorySingle,
    } = this.props;
    if (this.state.signalRActionType === 'sendMemory') {
      const signalRCodes = parseCCIC(ciccCode);
      sendMemoryCodes(facilityId, equipmentId, signalRCodes.ic, signalRCodes.cc, connId);
      setMessageHubFunction(this.sendMemoryNotification);
    } else if (this.state.signalRActionType === 'saveMemory') {
      setMessageHubFunction(this.saveMemoryResponse);
      invokeSendFunction(
        `${product.driverName}::${config}::${codes}::${product.productType}::${facility.installerCode}`,
        90000
      );
    } else if (this.state.signalRActionType === 'sendEBaseDoorMemory') {
      setMessageHubFunction(this.progressEbaseDoor);
      writeMemorySingle(facilityId, equipmentId, connId);
      this.props.putEquipmentIsUpdated(facilityId, equipmentId);
      this.setState({
        signalRActionType: '',
        showMessage: true,
        needIconRefresh: true,
        iChangeColor1Time: true,
      });
    }
    this.setState({ signalRActionType: '' });
  };

  setICCCResponseFunctions = () => {
    setAssistantPanelCheckSuccessFunction(invokeSendCICC);
    setMessageHubFunction(this.readCICC);
  };

  progressEbaseDoor = (id, message) => {
    const { driverWriteDone, driverWriteError, driverNotResponseError } = this.props;

    if ((driverWriteDone || driverWriteError || driverNotResponseError) !== undefined) {
      const values = message.split('::');
      const actualValue = values[1];
      const totalValues = values[2];
      const percent = (actualValue / totalValues) * 100;
      this.props.showDialog(1, Math.trunc(percent));
    } else {
      this.props.showDialog(-1, '');
    }
  };

  sendMemory = (actionData) => {
    const { equipment } = this.props;
    if (
      equipment.productCode === 612 ||
      equipment.productCode === 613 ||
      equipment.productCode === 614 ||
      equipment.productCode === 615
    ) {
      this.setICCCResponseFunctions();
      this.setState({ signalRActionType: 'sendEBaseDoorMemory' });
      checkAssistantPanelConnection();
    } else if (actionData.type === 'online') {
      this.setICCCResponseFunctions();
      this.setState({ signalRActionType: 'sendMemory' });
      checkAssistantPanelConnection();
    }
  };

  saveMemory = () => {
    const { getMemoryConfig, getMemoryCodes, facilityId, equipmentId, config, codes } = this.props;
    this.setICCCResponseFunctions();
    this.setState({ signalRActionType: 'saveMemory', canExecuteSaveMemory: true });
    if (!config) {
      getMemoryConfig(equipmentId, facilityId);
    }
    if (!codes) {
      getMemoryCodes(equipmentId, facilityId);
    }
  };

  actionProps = (actionName) => {
    const actions = {
      [actionNames.verifyConnectivity]: {
        onClick: this.onClickVerifyConnectivity,
        iconClassName: 'fas fa-satellite-dish',
      },
      [actionNames.saveMemory]: { iconClassName: 'fas fa-play', onClick: this.saveMemory },
      [actionNames.sendMemory]: { iconClassName: 'fas fa-play', onClick: this.sendMemory },
      [actionNames.changePassword]: {
        facilityId: this.props.facilityId,
        equipmentId: this.props.equipmentId,
        facility: this.props.facility,
      },
      [actionNames.formatMemory]: { facility: this.props.facility },
    };
    return actions[actionName];
  };

  getRows = () => {
    const { actions } = this.props;
    let rows = [];
    if (actions) {
      rows = actions.map((action) => {
        const selectedAction = actionType[action.action]({
          type: action.type,
          ...this.actionProps(action.action),
        });
        const row = {
          type: selectedAction.title,
          hasAction: true,
          getActionComponent: () => (
            <IconSpan
              id='edit'
              toolTip={selectedAction.title}
              defaultColor='#a4aebb'
              iconClassName={`generic-grow-action-button ${
                selectedAction.iconClassName || 'fas fa-cog'
              }`}
              cursor='pointer'
              onClick={(e) => {
                if (selectedAction.component) {
                  this.changePopUpState(true, selectedAction.component, selectedAction);
                } else if (selectedAction.onClick) {
                  selectedAction.onClick(selectedAction);
                }
              }}
            />
          ),
        };
        return row;
      });
    }
    return rows;
  };

  render() {
    const { tenants, product } = this.props;
    const PopupContent = this.state.popupComponent;
    return (
      <div>
        <GenericTable columnsConfig={this.getColumns()} rowsConfig={this.getRows()} />

        <Popup onClose={this.handleClosePopup} isOpen={this.state.isPopupOpen}>
          <PopupContent
            tenants={tenants}
            product={product}
            closePopup={this.handleClosePopup}
            {...this.state.popupProps}
          />
        </Popup>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  actions: state.equipments.actions,
  tenants: state.tenants,
  familiesSingleList: state.families.familiesSingleList,
  config: state.drivers.config,
  codes: state.drivers.codes,
  connectionId: state.signalR.connectionData.connectionId,
  asyncResponseTimeout: state.drivers.asyncResponseTimeout,
  equipmentConnection: state.equipments.equipmentConnection,
  needCallConnectivity: state.equipments.needCallConnectivity,
  driverWriteDone: state.devices.driverWriteDone,
  driverWriteError: state.devices.driverWriteError,
  driverNotResponseError: state.devices.driverNotResponseError,
  isUpdatedEquipment: state.equipments.isUpdated,
  updatedEquipment: state.equipments.updatedEquipment,
});

const EquipmentActionTabContentWithTranslation = withTranslation()(EquipmentActionTabContent);

export default connect(mapStateToProps, {
  writeMemorySingle,
  getEquipmentActions,
  getDevicePing,
  sendMemoryCodes,
  getMemoryConfig,
  getMemoryCodes,
  showDialog,
  resetDriversAsyncResponse,
  getEquipmentConnectivity,
  putEquipmentIsUpdated,
})(EquipmentActionTabContentWithTranslation);
