import {
  FACILITY_LOGS_BY_USER_GET,
  FACILITY_LOGS_GET,
  GET_FACILITIES_PDF,
  GET_FACILITY_EVENTS,
} from '../reducers/instalations';

const GET_INSTALATIONS = 'GET_INSTALATIONS';
const GET_INSTALATION_INFO = 'GET_INSTALATION_INFO';
const PUT_INSTALATIONS = 'PUT_INSTALATIONS';
const POST_INSTALATIONS = 'POST_INSTALATIONS';
const DEL_INSTALATIONS = 'DEL_INSTALATIONS';
const GET_INSTALATIONS_RELAYS = 'GET_INSTALATIONS_RELAYS';
const PUT_INSTALATIONS_RELAY = 'PUT_INSTALATIONS_RELAY';
const PUT_INSTALATIONS_GEOLOCATION = 'PUT_INSTALATIONS_GEOLOCATION';
const GET_INSTALATIONS_FOBS = 'GET_INSTALATIONS_FOBS';
const GET_INSTALATIONS_SINGLE_LIST = 'GET_INSTALATIONS_SINGLE_LIST';
const RESET_INSTALATIONS_RELAYS = 'RESET_INSTALATIONS_RELAYS';
const RESET_INTALATIONS_INFO = 'RESET_INTALATIONS_INFO';
const RESET_INTALATIONS_FOBS = 'RESET_INTALATIONS_FOBS';
const GET_EQUIPMENT_USER_GROUPS = 'GET_EQUIPMENT_USER_GROUPS';
const GET_EQUIPMENT_PARAMETERS = 'GET_EQUIPMENT_PARAMETERS';
const GET_FACILITY_FILTERS = 'GET_FACILITY_FILTERS';
const GET_FACILITY = 'GET_FACILITY';
const RESET_FACILITY = 'RESET_FACILITY';
const SET_FACILITY = 'SET_FACILITY';
const RESET_FOBS_LIST = 'RESET_FOBS_LIST';
const SET_RETURN_TO_FACILITIES = 'SET_RETURN_TO_FACILITIES';
const DELETE_ALARMS_INSTALATION = 'DELETE_ALARMS_INSTALATION';
const PUT_TRANSFER_INSTALATION = 'PUT_TRANSFER_INSTALATION';
const GET_RECYCLE_INSTALLATION_CHECKER = 'GET_RECYCLE_INSTALLATION_CHECKER';
const GET_RECYCLE_INSTALLATIONS = 'GET_RECYCLE_INSTALLATIONS';
const SET_RECYCLE_RESTORE_INSTALLATION = 'SET_RECYCLE_RESTORE_INSTALLATION';
const RESET_PUT_FOBS_ERROR = 'RESET_PUT_FOBS_ERROR';
const RESET_INSTALLERCODE_POPUP = 'RESET_INSTALLERCODE_POPUP';

export default {
  GET_INSTALATIONS,
  PUT_INSTALATIONS,
  POST_INSTALATIONS,
  DEL_INSTALATIONS,
  GET_INSTALATIONS_RELAYS,
  PUT_INSTALATIONS_RELAY,
  GET_INSTALATION_INFO,
  GET_INSTALATIONS_FOBS,
  GET_INSTALATIONS_SINGLE_LIST,
  RESET_INSTALATIONS_RELAYS,
  RESET_INTALATIONS_INFO,
  RESET_INTALATIONS_FOBS,
  GET_EQUIPMENT_USER_GROUPS,
  GET_EQUIPMENT_PARAMETERS,
  PUT_INSTALATIONS_GEOLOCATION,
  GET_FACILITY_FILTERS,
  GET_FACILITY,
  RESET_FACILITY,
  SET_FACILITY,
  RESET_FOBS_LIST,
  SET_RETURN_TO_FACILITIES,
  DELETE_ALARMS_INSTALATION,
  PUT_TRANSFER_INSTALATION,
  RESET_PUT_FOBS_ERROR,
  GET_RECYCLE_INSTALLATION_CHECKER,
  GET_RECYCLE_INSTALLATIONS,
  SET_RECYCLE_RESTORE_INSTALLATION,
  RESET_INSTALLERCODE_POPUP,
};

/**
 * Sets the form state
 * @param  {int} value                The value for show loading
 * @return {object}                   Formatted action for the reducer to handle
 */
export const getInstalations = () => ({ type: GET_INSTALATIONS });

export const getFacilitiesPDF = ({ customerIDs, queryText, city, zip, typeFilter, userId }) => ({
  type: GET_FACILITIES_PDF.BASE,
  customerIDs,
  queryText,
  city,
  zip,
  typeFilter,
  userId,
});
export const getInstalationInfo = (id, except) => ({ type: GET_INSTALATION_INFO, id, except });

export const putInstalations = (instalation) => ({ type: PUT_INSTALATIONS, instalation });

export const deleteInstalations = (id, costumerID) => ({ type: DEL_INSTALATIONS, id, costumerID });

export const postInstalations = (instalation, isClonation) => ({
  type: POST_INSTALATIONS,
  instalation,
  isClonation,
});

export const getInstalationsRelays = (instalationId, groupId) => ({
  type: GET_INSTALATIONS_RELAYS,
  instalationId,
  groupId,
});

export const putInstalationsRelay = ({ facilityId, groupId, relay }) => ({
  type: PUT_INSTALATIONS_RELAY,
  facilityId,
  groupId,
  relay,
});

export const getInstalationsFOBS = (facilityId, groupId) => ({
  type: GET_INSTALATIONS_FOBS,
  facilityId,
  groupId,
});

export const getInstalationsSingleList = (facilityId, except) => ({
  type: GET_INSTALATIONS_SINGLE_LIST,
  facilityId,
  except,
});

export const resetInstalationsRelays = () => ({ type: RESET_INSTALATIONS_RELAYS });

export const resetInstalationInfo = () => ({ type: RESET_INTALATIONS_INFO });

export const resetInstalationFOBS = () => ({ type: RESET_INTALATIONS_FOBS });

export const getEquipmentUserGroups = (facilityId, equipmentId) => ({
  type: GET_EQUIPMENT_USER_GROUPS,
  facilityId,
  equipmentId,
});

export const getEquipmentParameters = (facilityId, equipmentId) => ({
  type: GET_EQUIPMENT_PARAMETERS,
  facilityId,
  equipmentId,
});

export const putFacilityGeolocation = (facilityId, geolocationURL) => ({
  type: PUT_INSTALATIONS_GEOLOCATION,
  facilityId,
  geolocationURL,
});

export const getInstalationFilters = (facilityId) => ({ type: GET_FACILITY_FILTERS, facilityId });

export const getFacility = (id, include = true, fromGlobalSearch) => ({
  type: GET_FACILITY,
  id,
  include,
  fromGlobalSearch,
});

export const getFacilityLogs = (facilityId) => ({ type: FACILITY_LOGS_GET.BASE, facilityId });

export const getFacilityLogsByUser = (userId) => ({ type: FACILITY_LOGS_BY_USER_GET.BASE, userId });

export const getFacilityEvents = (facilityId) => ({ type: GET_FACILITY_EVENTS.BASE, facilityId });

export const resetInstalation = () => ({ type: RESET_FACILITY });

export const setFacility = (facility) => ({ type: SET_FACILITY, facility });

export const resetAllFobLists = () => ({ type: RESET_FOBS_LIST });

export const needReturnToFacilities = (urlParam1, urlParam2) => ({
  type: SET_RETURN_TO_FACILITIES,
  urlParam1,
  urlParam2,
});

export const putTransferInstalation = (facilityId, newCustomerId, valueSelected) => ({
  type: PUT_TRANSFER_INSTALATION,
  facilityId,
  newCustomerId,
  valueSelected,
});

export const deleteAlarms = (
  alarmId,
  groupId,
  facilityId,
  isUniversal,
  groupName,
  instalationsName
) => ({
  type: DELETE_ALARMS_INSTALATION,
  alarmId,
  groupId,
  facilityId,
  isUniversal,
  groupName,
  instalationsName,
});

export const resetPutFobsError = () => ({ type: RESET_PUT_FOBS_ERROR });

export const checkFacilityRecycle = () => ({ type: GET_RECYCLE_INSTALLATION_CHECKER });

export const listFacilityRecycle = () => ({ type: GET_RECYCLE_INSTALLATIONS });

export const restoreFacilityRecycle = (facilityId) => ({
  type: SET_RECYCLE_RESTORE_INSTALLATION,
  facilityId,
});

export const resetInstallerCodePopup = () => ({ type: RESET_INSTALLERCODE_POPUP });
