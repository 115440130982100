import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import TabMenu from '../TabMenu';
import { resetCanRedirect, setCloneGrow } from '../../modules/old_to_refact/actions/genericGrow';
import { objectsAreEquals } from '../../core/old_common/utils/objectsService';

export class GenericIndividualGrow extends Component {
  componentDidMount = () => {
    const { selectedItem, getItem, match, creationURI } = this.props;
    if (getItem) {
      if (!selectedItem && match.params.id !== creationURI) {
        getItem(match.params.id);
      }
    }
  };

  shouldComponentUpdate(nextProps) {
    const actualSelectedData = this.getComponentPrincipalData(this.props);
    const newSelectedData = this.getComponentPrincipalData(nextProps);
    if (!objectsAreEquals(actualSelectedData, newSelectedData)) {
      return true;
    }
    return false;
  }

  getComponentPrincipalData = (props) => {
    const { children, creationURI, tabsData, match, childGeneralProps } = props;
    const selectedItem = this.getSelectedItem(true, props);

    if (children) {
      return { ...selectedItem, ...childGeneralProps };
    }
    if (match.params.id === creationURI && tabsData && tabsData.length) {
      return { ...tabsData[0].componentState, ...selectedItem, ...childGeneralProps };
    }
    return { tabsData, selectedItem, childGeneralProps };
  };

  componentDidUpdate = () => {
    const { getItem, match, creationURI } = this.props;
    if (this.needUpdate()) {
      if (getItem && match.params.id !== creationURI) {
        getItem(encodeURIComponent(match.params.id));
      }
    }
  };

  needUpdate = () => {
    const { canRedirect, creationURI, match, sessionExpired, facility, getItem, forceUpdate } =
      this.props;
    const urlItemName = match.params.id;
    const newSelectedItems = this.getSelectedItem();
    let needUpdate = false;
    if (!canRedirect) {
      if (forceUpdate) {
        needUpdate = true;
      }
      if (_.isEmpty(newSelectedItems) && !sessionExpired && facility) {
        if (urlItemName !== creationURI) {
          needUpdate = true;
        }
      } else if (
        !!newSelectedItems?.id &&
        newSelectedItems?.id !== match?.params?.id &&
        !!getItem
      ) {
        needUpdate = true;
      }
    }
    return needUpdate;
  };

  componentWillUnmount() {
    const { onUnmount, setCloneGrow, isCloned } = this.props;
    if (onUnmount) {
      onUnmount();
    }
    if (isCloned) {
      setCloneGrow(false, {});
    }
  }

  getSelectedItem = (includeClone = true, props = this.props) => {
    const { isCloned, clonedGrow, selectedItem } = props;
    if (isCloned && includeClone) {
      return clonedGrow;
    }
    return { ...selectedItem } || {};
  };

  getGrowChildren = () => {
    const { children, creationURI, tabsData, match, childGeneralProps } = this.props;
    const selectedItem = this.getSelectedItem();

    let growChildren;

    if (children) {
      const childrenArray = Array.isArray(children) ? children : [children];
      growChildren = childrenArray.map((child, i) =>
        this.injectPropsOnComponent(child, { ...selectedItem, ...childGeneralProps, key: i })
      );
    } else if (match.params.id === creationURI && tabsData && tabsData.length) {
      const TabComponent = tabsData[0].bodyComponent;
      const tabComponentProps = {
        ...tabsData[0].componentState,
        ...selectedItem,
        ...childGeneralProps,
      };
      growChildren = <TabComponent {...tabComponentProps} />;
    } else {
      growChildren = (
        <TabMenu
          tabsData={tabsData?.map((data) => ({
            ...data,
            componentState: { ...data.componentState, ...selectedItem, ...childGeneralProps },
          }))}
        />
      );
    }
    return growChildren;
  };

  injectPropsOnComponent = (component, props) => React.cloneElement(component, props);

  render() {
    const { tenants, Grow, growTitle, baseURI, creationURI } = this.props;
    const selectedItem = this.getSelectedItem();
    return (
      <Grow
        tenants={tenants}
        {...selectedItem}
        item={selectedItem}
        creationURI={creationURI}
        baseURI={baseURI}
        title={growTitle}
      >
        {this.getGrowChildren()}
      </Grow>
    );
  }
}

const mapStateToProps = (state) => ({
  tenants: state.tenants,
  canRedirect: state.genericGrow.canRedirect,
  clonedGrow: state.genericGrow.clonedGrow,
  isCloned: state.genericGrow.isCloned,
  needUpdate: state.genericGrow.needUpdate,
  sessionExpired: state.popup.isOpenAuth,
  forceUpdate: state.genericGrow.forceUpdate,
});

const GenericIndividualGrowWithRoute = withRouter(GenericIndividualGrow);

export default connect(mapStateToProps, { resetCanRedirect, setCloneGrow })(
  GenericIndividualGrowWithRoute
);
