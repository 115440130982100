import { Flex } from '@jcm-technologies/uikit/dist/atoms/Layout';
import { Field } from '@jcm-technologies/uikit/dist/atoms/Field';
import { Input } from '@jcm-technologies/uikit/dist/atoms/Input';
import { Switch } from '@jcm-technologies/uikit/dist/atoms/Switch';
import PropTypes from 'prop-types';

const CustomizationUserFieldItem = ({
  key,
  field,
  onChangeName,
  onToggleActivation,
  messageLabel,
}) => (
  <Flex key={key}>
    <Field label={messageLabel} id={field.id} fontWeigth='bold'>
      <Flex>
        <Input
          value={field.value}
          name={field.id}
          id={field.id}
          onChange={(name, value) => onChangeName(name, value)}
        />
        <Switch
          defaultValue={field.visible}
          name={field.id}
          id={field.id}
          action={(isChecked) => onToggleActivation(isChecked)}
        />
      </Flex>
    </Field>
  </Flex>
);

export default CustomizationUserFieldItem;

CustomizationUserFieldItem.propTypes = {
  field: PropTypes.shape({
    id: PropTypes.string,
    value: PropTypes.string,
    visible: PropTypes.bool,
  }).isRequired,
  onChangeName: PropTypes.func.isRequired,
  onToggleActivation: PropTypes.func.isRequired,
  messageLabel: PropTypes.string.isRequired,
};
