import React, { Component } from 'react';
import { connect } from 'react-redux';
import './Pagination.css';
import { withTranslation } from 'react-i18next';
import { v1 as uuidv1 } from 'uuid';
import Button from './Button';
import { resetPageCount, setPageNumber } from '../../modules/state/pagination/actions';
import ToolTip from '../ToolTip/ToolTip';

class Pagination extends Component {
  constructor(props) {
    super(props);
    this.minPageNumber = 1;
    this.maxPageNumberButtons = 10;
    this.toolTipID = uuidv1();
  }

  componentDidUpdate = (newProps) => {
    const { onPageChange, pagenumber, listType } = this.props;
    const totalPages = this.getTotalPages();
    if (newProps.pagenumber[listType] !== pagenumber[listType]) {
      if (onPageChange) {
        onPageChange();
      }
    }
    if (totalPages && totalPages < pagenumber[listType]) {
      this.changePage(-1);
    }
  };

  componentWillUnmount() {
    const { listType, setPageNumber } = this.props;
    if (listType === 'FACILITY_UG_LIST') {
      setPageNumber(listType, 1);
    }
  }

  getTotalPages = () =>
    this.props.listTotalPages !== undefined ? this.props.listTotalPages : this.props.totalPages;

  changePage = (pagesToAdd) => {
    const { pagenumber, listType } = this.props;
    const nextPage = pagenumber[listType] + pagesToAdd;
    return this.goToPage(nextPage);
  };

  goToPage = (nextPage) => {
    const { pagenumber, setPageNumber, listType } = this.props;
    const totalPages = this.getTotalPages();
    if (nextPage > totalPages) {
      nextPage = totalPages;
    } else if (nextPage < this.minPageNumber) {
      nextPage = this.minPageNumber;
    }
    const pageChangeProps = { prevPage: pagenumber[listType], actualPage: nextPage };
    setPageNumber(listType, pageChangeProps.actualPage);
    return pageChangeProps;
  };

  getPageNumberButtons = () => {
    const { pagenumber, listType } = this.props;
    const paginatorPage = Math.trunc(pagenumber[listType] / this.maxPageNumberButtons);
    const pageButtons = this.createPageButtons(paginatorPage * this.maxPageNumberButtons + 1);
    const totalPages = this.getTotalPages();
    if (
      totalPages > this.maxPageNumberButtons &&
      pagenumber[listType] + this.maxPageNumberButtons <= totalPages
    ) {
      pageButtons.push(
        this.createChangePageBlockButton((paginatorPage + 1) * this.maxPageNumberButtons + 1)
      );
    }
    if (paginatorPage > 0) {
      pageButtons.unshift(
        this.createChangePageBlockButton((paginatorPage - 1) * this.maxPageNumberButtons + 1)
      );
    }
    return pageButtons;
  };

  createChangePageBlockButton = (nextBlockFirstPage) => (
    <Button
      tenants={this.props.tenants}
      onClick={(e) => {
        this.goToPage(nextBlockFirstPage);
        e.target.blur();
      }}
    >
      ...
    </Button>
  );

  createPageButtons = (startPageNumber = 1) => {
    const { tenants, pagenumber, listType } = this.props;
    const pageButtons = [];
    const totalPages = this.getTotalPages();
    let totalButtons = startPageNumber + this.maxPageNumberButtons - 1;
    if (totalButtons > totalPages) {
      totalButtons = totalPages;
    } else if (totalButtons < this.minPageNumber) {
      totalButtons = this.minPageNumber;
    }
    for (let i = startPageNumber; i <= totalButtons; i++) {
      const isActualPage = pagenumber[listType] === i;
      pageButtons.push(
        <Button
          key={i}
          tenants={tenants}
          isActualPage={isActualPage}
          onClick={(e) => this.goToPage(parseInt(e.target.innerHTML))}
        >
          {i}
        </Button>
      );
    }
    return pageButtons;
  };

  render() {
    const { pagenumber, t, listType } = this.props;
    const totalPages = this.getTotalPages();
    const goBackDisabled = pagenumber[listType] === this.minPageNumber;
    const goFrontDisabled = !totalPages || pagenumber[listType] === totalPages;
    return (
      <div>
        <ToolTip />
        <div className='paginationContainer'>
          <button
            data-tip={t('pagination.first')}
            disabled={goBackDisabled}
            className={`paginationControlButton ${
              goBackDisabled ? 'disabled' : ''
            } fas fa-angle-double-left`}
            onClick={() => this.goToPage(this.minPageNumber)}
          />
          <button
            data-tip={t('pagination.prev')}
            disabled={goBackDisabled}
            className={`paginationControlButton ${
              goBackDisabled ? 'disabled' : ''
            } fas fa-angle-left`}
            onClick={() => this.changePage(-1)}
          />
          {this.getPageNumberButtons()}
          <button
            data-tip={t('pagination.next')}
            disabled={goFrontDisabled}
            className={`paginationControlButton ${
              goFrontDisabled ? 'disabled' : ''
            } fas fa-angle-right`}
            onClick={() => this.changePage(+1)}
          />
          <button
            data-tip={t('pagination.last')}
            disabled={goFrontDisabled}
            className={`paginationControlButton ${
              goFrontDisabled ? 'disabled' : ''
            } fas fa-angle-double-right`}
            onClick={() => this.changePage(totalPages)}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  tenants: state.tenants,
  pagenumber: state.backendParams.pagenumber,
  totalPages: state.backendParams.totalPages,
});

const withTranslationPagionation = withTranslation()(Pagination);

export default connect(mapStateToProps, { setPageNumber, resetPageCount })(
  withTranslationPagionation
);
