export default {
  gsmPopUp: {
    title: 'Estado del servicio',
    deviceNotSimInfo: 'El dispositivo está desconectado',
    simContractInfo: 'Información del contrato',
    expiration: 'Caducidad',
    status: 'Estado',
    infoNotAvailable: 'Información no disponible',
    simConnectionInfo: 'Información de conexión SIM',
    cardIdentifier: 'Identificador de tarjeta (ICCID)',
    activationDate: 'Fecha de activación',
    lastMonthDataUpload: 'Carga de datos del mes pasado',
    lastMonthDataDownload: 'Descarga de datos del mes pasado',
    lastSeen: 'Visto por última vez',
    contractStatus: 'Estado del contrato',
    simStatus: 'Estado de la SIM',
    deviceStatus: 'Estado del dispositivo',
    enabled: 'Habilitado',
    disabled: 'Deshabilitado',
    notAvailable: 'No disponible',
    connected: 'Conectado',
    disconnected: 'Desconectado',
  },
};
