export default {
  paramsName: {
    ACCESSCONTROL: "Contrôle d'accès",
    ACTIVEELEMENTS: 'Événements actifs',
    CONFIGRELE1: 'Réglage du relais 1 (impulsion ou ON / OFF)',
    CONFIGRELE2: 'Réglage du relais 2 (impulsion ou ON / OFF)',
    INSTAL: 'Installler',
    POWER: 'Source de courant',
    PRICE1: 'Valeur de soustraction pour le relais 1',
    PRICE2: 'Valeur de soustraction pour le relais 2',
    RELAYS: 'Relais',
    TEMPRELE1: "Relais de mise à l'heure 1",
    TEMPRELE2: "Relais de mise à l'heure 2",
    TEVENT: 'Carte des événements',
    TIMEZONE: 'Identifiant du fuseau horaire',
    TMEM: 'Carte mémoire',
    MessageBox_ActivationRelay: 'Activation du relais',
    MessageBox_ConnectionError: 'Erreur de connexion',
    MessageBox_DeviceAccepted: 'Appareil accepté',
    MessageBox_DeviceRecieved: 'Appareil reçu',
    MessageBox_FormatMemoryError: 'Format incorrect dans la mémoire',
    MessageBox_InvalidDevice: 'Appareil non valide',
    MessageBox_MemoryExtracted: 'Mémoire extraite',
    MessageBox_MemoryInserted: 'Mémoire insérée',
    MessageBox_NotInMemory: 'Pas trouvé en mémoire',
    MessageBox_OutOfSchedule: 'Hors calendrier',
    MessageBox_PowerUp: 'SUR',
    MessageBox_RelayState: "Changement d'état du relais",
    MessageBox_Message: 'Configurer les événements actifs',
    CONFIGRELAYSPIN1: 'Relais sur programmation PIN. Chaîne: 1',
    CONFIGRELAYSPIN2: 'Relais sur programmation PIN. Canal: 2',
    CONFIGRELAYSPIN3: 'Relais sur programmation PIN. Canal: 3',
    CONFIGRELAYSPIN4: 'Relais sur programmation PIN. Canal: 4',
    DISABLEMANUALPROG: 'Pour interdire la Programmation manuelle',
    DISABLERADIOPROG: 'Pour interdire la programmation radio',
    GO: 'Aller',
    GOPRO: 'Go Pro',
    HFADVANCE: 'HF-ADVANCE',
    IMPBIRELE1: 'Configure le relais 1 comme bistable',
    MINIDDEVICE: 'Appareil minimum',
    PIN: 'ÉPINGLE',
    PINCADUCAT: 'PIN expiré',
    CONFIGRELAYSNEW1: 'Relais actifs sur le canal 1',
    CONFIGRELAYSNEW2: 'Relais actifs sur le canal 2',
    CONFIGRELAYSNEW3: 'Relais actifs sur le canal 3',
    CONFIGRELAYSNEW4: 'Relais actifs sur le canal 4',
    CONFIGRELE3: 'Réglage du relais 3 (impulsion ou ON / OFF)',
    CONFIGRELE4: 'Réglage du relais 4 (impulsion ou ON / OFF)',
    TEMPRELE3: "Relais de mise à l'heure 3",
    TEMPRELE4: "Relais de mise à l'heure 4",
    PRICE3: 'Valeur de soustraction pour le relais 3',
    PRICE4: 'Valeur de soustraction pour le relais 4',
    FREEACTIVE: 'Groupe actif sur le système FREE',
    FREEGROUP0LOCKED: 'Groupe 0 sur le système FREE bloqué',
    FREEGROUPNUM: 'Numéro de groupe sur le système FREE',
    FREEGROUPRFPROGALLOWED: 'Mode de programmation radio autorisé sur le système FREE',
    BADGE_MAITRE01: 'Code maître 1',
    BADGE_MAITRE10: 'Code maître 10',
    BADGE_MAITRE2: 'Code maître 2',
    BADGE_MAITRE3: 'Code maître 3',
    BADGE_MAITRE4: 'Code maître 4',
    BADGE_MAITRE5: 'Code maître 5',
    BADGE_MAITRE6: 'Code maître 6',
    BADGE_MAITRE7: 'Code maître 7',
    BADGE_MAITRE8: 'Code maître 8',
    BADGE_MAITRE9: 'Code maître 9',
    SITECODE: 'Le code du site',
    W26FORMAT: 'Format Wiegand 26',
    Configuration_Params_W26FORMAT0: 'Code (16 bits) + Code du site (8 bits)',
    Configuration_Params_W26FORMAT1: 'Code (24 bits)',
    CONFIGRELE_ANTIPANIC: 'Panique',
    ANTIPANICCONFIGRELE1: 'Réglage du relais 1 (impulsion ou ON / OFF ou panique)',
    ANTIPANICCONFIGRELE2: 'Réglage du relais 2 (impulsion ou ON / OFF ou panique)',
    ANTIPANICCONFIGRELE3: 'Réglage du relais 3 (impulsion ou ON / OFF ou panique)',
    ANTIPANICCONFIGRELE4: 'Réglage du relais 4 (impulsion ou ON / OFF ou panique)',
    RELE1IMP: 'Réglage du relais 1 (impulsion ou ON / OFF)',
    RELE1TEMP: "Réglage de l'heure compter 1",
    RELE2IMP: 'Réglage du relais 2 (impulsion ou ON / OFF)',
    RELE2TEMP: "Réglage de l'heure compter 2",
    RELE3IMP: 'Réglage du relais 3 (impulsion ou ON / OFF)',
    RELE3TEMP: "Le réglage de l'heure repose sur 3",
    RELE4IMP: 'Réglage du relais 4 (impulsion ou ON / OFF)',
    RELE4TEMP: "Le réglage de l'heure repose sur 4",
    RELAYPRICE1: 'Valeur de soustraction pour le relais 1',
    RELAYPRICE2: 'Valeur de soustraction pour le relais 2',
    RELAYPRICE3: 'Valeur de soustraction pour le relais 3',
    RELAYPRICE4: 'Valeur de soustraction pour le relais 4',
    PASSWORD: 'Mot de passe',
    CONNECTIVITY_EXTENSION: 'Carte Ling installée',
    ACTIVATIONKEY: "Clé d'activation",
    UNIQUEKEY: 'Clé unique',
    TIMEZONEID: 'Fuseau horaire',
    EMMITTERNOTUSEDEVENTTIME: "N'utilisez pas l'heure de l'événement de l'émetteur",
    DOOROPENEDEVENTTIME: "Événement d'ouverture de la porte",
    ACTIVEEVENTSTOSD: 'Définition des événements A5K',
    SCHEDULESDATA: 'Planifier les données',
    APNCONFIG: 'Paramètre APN',
    A5KPROXY: 'Configurer le proxy A5K',
    Configuration_Params_ConfigRele_Impulsional: 'Impulsionnel',
    Configuration_Params_ConfigRele_Biestable: 'Bistable',
    Configuration_Params_ConfigRele_Antipanic: 'Antipanique',
    Configuration_Params_Yes: 'Oui',
    Configuration_Params_No: 'No',
    Configuration_Params_PIN: 'PIN',
    Configuration_Params_PINCADUCAT: 'PIN a expiré',
    Configuration_Params_ValueTEVENTS: 'Oui',
    Configuration_Params_BUTT_NO: 'Bouton normalement ouvert',
    Configuration_Params_BUTT_NC: 'Bouton normalement fermé',
    Sip_Address: 'Adresse SIP',
    Sip_Status: 'Statut SIP',
    fw_version: 'Version du firmware',
    hw_version: 'Version matérielle',
    mac_address: 'Adresse Mac',
    imei: 'Numéro IMEI',
    iccid: 'Numéro ICCID',
    connection_type: 'Type de connexion',
    level: 'Niveau du signal',
    dbm: 'Décibels',
    mcc: 'MCC',
    mnc: 'MNC',
    lac: 'LAC',
    call_max_duration: "Durée d'appel maximale",
    call_outgoing_timeout: "Délai d'expiration des appels sortants",
    relay_dtmf_hang_call: 'Activation du relais par tonalité',
    Relay_Sound: 'Langue du message de porte ouverte',
    mic_volume: 'Volume du microphone',
    speaker_volume: 'Volume haut-parleur',
    access_code: "Code d'accès",
    true: 'Vrai',
    false: 'Faux',
    door_english: 'Anglais',
    door_spanish: 'Espagnol',
    door_french: 'français',
    none: 'Aucun',
    imsi: 'IMSI',
    cell_id: 'CELL ID',
    Configuration_Params_LOW: 'Faible',
    Configuration_Params_HIGH: 'Haute',
    Configuration_Params_DEBUG: 'Déboguer',
    Configuration_Params_UNUSED: 'Non utilisé',
    Configuration_Params_NCOLS:
      "[Porte 1] Interrupteur de fin de course d'ouverture normalement fermé",
    Configuration_Params_NCCLS:
      '[Porte 1] Interrupteur de fin de course de fermeture normalement fermé',
    Configuration_Params_NOOLS:
      "[Porte 1] Interrupteur de fin de course d'ouverture normalement ouvert",
    Configuration_Params_NOCLS:
      '[Porte 1] Interrupteur de fin de course de fermeture normalement ouvert',
    Configuration_Params_NCSC: 'Chaîne de sécurité normalement fermée',
    Configuration_Params_NOSC: 'Chaîne de sécurité normalement ouverte',
    Configuration_Params_entranceNC: "APB d'entrée normalement fermé",
    Configuration_Params_exitNC: 'APB de sortie normalement fermé',
    Configuration_Params_entranceNO: "APB d'entrée normalement ouvert",
    Configuration_Params_exitNO: 'APB de sortie normalement ouvert',
    Configuration_Params_D2_NCOLS:
      "[Porte 2] Interrupteur de fin de course d'ouverture normalement fermé",
    Configuration_Params_D2_NCCLS:
      '[Porte 2] Interrupteur de fin de course de fermeture normalement fermé',
    Configuration_Params_D2_NOOLS:
      "[Porte 2] Interrupteur de fin de course d'ouverture normalement ouvert",
    Configuration_Params_D2_NOCLS:
      '[Porte 2] Interrupteur de fin de course de fermeture normalement ouvert',
    Configuration_Params_NSCLS: "[Porte 1] Capteur de fin de course d'ouverture",
    Configuration_Params_NSOLS: '[Porte 1] Capteur de fin de course de fermeture',
    Configuration_Params_D2_HSOLS: "[Porte 2] Capteur de fin de course d'ouverture",
    Configuration_Params_D2_HSCLS: '[Porte 2] Capteur de fin de course de fermeture',
    Configuration_Params_OPENREMOTE: "HONOA Permettre l'ouverture à distance",
    Configuration_Params_HANDSFREEALLOWED: 'HONOA Permettre le mode mains libres Bluetooth (BETA)',
    APBKMODE: 'Mode Anti-Passback (APB)',
    Configuration_Params_MODE1: 'Mode 1 (monocanal entrée/sortie, sortie libre)',
    Configuration_Params_MODE2: 'Mode 2 (monocanal entrée/sortie)',
    Configuration_Params_MODE3: 'Mode 3 (canal 1 entrée/canal 2 sortie)',
    Configuration_Params_MODE4: 'Mode 4 (canal 1 et 3 entrée/canal 2 et 4 sortie)',
    APBKTIMEBACK: 'Délai de réinitialisation Anti-Passback en minutes (Anti-timeback)',
    Configuration_Params_HSCLS: '[Porte 1] Capteur de fin de course de fermeture',
    Configuration_Params_HSOLS: "[Porte 1] Capteur de fin de course d'ouverture",
    Configuration_Params_MIN: 'Le minimum',
    DATAUSAGE: "L'utilisation de données",
    INPUTS: 'Entrées',
    INPUT1NAME: "Nom d'entrée 1",
    INPUT2NAME: "Nom d'entrée 2",
    INPUT3NAME: 'Nom capteur radio 3',
    INPUT4NAME: 'Nom capteur radio 4',
    INPUT5NAME: 'Nom capteur radio 5',
    INPUT6NAME: 'Nom capteur radio 6',
    INPUT7NAME: 'Nom capteur radio 7',
    INPUT8NAME: 'Nom capteur radio 8',
    INPUT9NAME: 'Nom capteur radio 9',
    INPUT10NAME: 'Nom capteur radio 10',
    INPUT1MODE: "Mode d'entrée 1",
    INPUT2MODE: "Mode d'entrée 2",
    INPUT3MODE: 'Mode capteur radio 3',
    INPUT4MODE: 'Mode capteur radio 4',
    INPUT5MODE: 'Mode capteur radio 5',
    INPUT6MODE: 'Mode capteur radio 6',
    INPUT7MODE: 'Mode capteur radio 7',
    INPUT8MODE: 'Mode capteur radio 8',
    INPUT9MODE: 'Mode capteur radio 9',
    INPUT10MODE: 'Mode capteur radio 10',
    INPUT1KEY: "Touche d'entrée 1",
    INPUT2KEY: "Touche d'entrée 2",
    INPUT3KEY: 'ID capteur radio 3',
    INPUT4KEY: 'ID capteur radio 4',
    INPUT5KEY: 'ID capteur radio 5',
    INPUT6KEY: 'ID capteur radio 6',
    INPUT7KEY: 'ID capteur radio 7',
    INPUT8KEY: 'ID capteur radio 8',
    INPUT9KEY: 'ID capteur radio 9',
    INPUT10KEY: 'ID capteur radio 10',
    SENSORS: 'Capteurs',
    incorrectVertexKey: "Le code entré n'est pas valide",
    correctVertexKey: 'Le code saisi est valide',
    OPENREMOTE: "HONOA Permettre l'ouverture à distance",
    HANDSFREEALLOWED: 'HONOA Permettre le mode mains libres Bluetooth (BETA)',
    Name: 'Nom',
    UniqueIdentity: 'Identificateur unique',
    Product: 'Dispositif',
    SerialNumber: 'Numéro de série',
    WIEGANDCONFIG: 'Configuration Wiegand',
    ALLOWNOTIFICATIONS: 'HONOA Afficher l’état de la porte',
    LOCALEVENTMANAGEMENTCONFIG: 'Configuration des événements',
  },
};
