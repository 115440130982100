import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Checkbox } from '@jcm-technologies/uikit/dist/atoms/Checkbox';
import { Field } from '@jcm-technologies/uikit/dist/atoms/Field';
import { Button } from '@jcm-technologies/uikit/dist/atoms/Button';
import { Flex } from '@jcm-technologies/uikit/dist/atoms/Layout';
import { Text } from '@jcm-technologies/uikit/dist/atoms/Typography';
import { getActualLanguage } from '../../../../core/helpers/getLanguages';
import { postAccountVersion } from '../../../../modules/old_to_refact/actions/login';
import versionDictionary from './index';
import Popup from '../../../Popup';
import { getCookieHideVerInfo } from '../../../../core/old_common/utils/cookiesManager';
import './VersionPopUp.css';
import { getArrayNodeIndexByPropName } from '../../../../core/helpers/arrayService';

class VersionPopUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      versionChecked: false,
    };
  }

  getVersionJson = () => {
    const selectedLanguage = getActualLanguage();
    const hideVerInfo = getCookieHideVerInfo();
    const version = versionDictionary[selectedLanguage];
    const selectedVersion = version === undefined ? versionDictionary['en-GB'] : version;
    const actualVersionIndex =
      hideVerInfo !== undefined
        ? getArrayNodeIndexByPropName(selectedVersion, { Version: hideVerInfo }, 'Version')
        : null;
    if (
      actualVersionIndex !== null &&
      actualVersionIndex > -1 &&
      !this.props.manualShowVersionPopup
    ) {
      return selectedVersion.slice(0, actualVersionIndex);
    }
    return selectedVersion;
  };

  handleChange = (name, isChecked) => {
    this.setState({ versionChecked: isChecked });
  };

  acceptVersion = (checkValue) => {
    const { postAccountVersion } = this.props;
    if (checkValue !== false) {
      postAccountVersion(versionDictionary.default[0].Version);
    }
  };

  render() {
    const { isOpen, onClose, t, manualShowVersionPopup, tenants } = this.props;
    const versionInfo = this.getVersionJson();
    return (
      <div className='version-popup'>
        <Popup isOpen={isOpen} onClose={onClose} closeOnDocumentClick={false} closeOnEscape={false}>
          <span>
            <div className='modal-content'>
              <div className='modal-header-version'>
                <h4 className='version-popup-title'> {t('versionPopUp.VersionInfo')}</h4>
                <hr className='modal-line' />
                {versionInfo.map((v, versionIndex) => (
                  <div className='modal-body-version' key={`${versionIndex}_version`}>
                    <span>
                      {v.Version ? (
                        <h5>
                          {t('general.version')} {v.Version}
                        </h5>
                      ) : (
                        ''
                      )}
                      {v.Title
                        ? v.Title.map((note, noteIndex) => (
                            <p className='version-popup-notes' key={`${noteIndex}note`}>
                              {note[noteIndex + 1]}
                            </p>
                          ))
                        : ''}
                      {v.TitleWithLink
                        ? v.TitleWithLink.map((note, noteIndex) => {
                            const titleWithLinkAdapted = note[noteIndex + 1].replace(v.Link, '');
                            return (
                              <p className='version-popup-notes' key={`${noteIndex}note`}>
                                {titleWithLinkAdapted}
                                <Text
                                  as='span'
                                  fontWeight='bold'
                                  color='blue'
                                  sizeText='display16'
                                  textAlign='left'
                                  marginBottom={1}
                                  onClick={() => window.open(v.Link, '_blank', 'noreferrer')}
                                >
                                  {t('versionPopUp.here')}.
                                </Text>
                              </p>
                            );
                          })
                        : ''}
                      {v.Descriptions
                        ? v.Descriptions.map((feature, featureIndex) => (
                            <p className='version-popup-features' key={`${featureIndex}_feature`}>
                              - {feature[featureIndex + 1]}
                            </p>
                          ))
                        : ''}
                      {v.Note
                        ? v.Note.map((note, noteIndex) => (
                            <p className='version-popup-note' key={`${noteIndex}note`}>
                              {t('versionPopUp.note')}
                              <a className='version-popup-notes-cursive'>{note[noteIndex + 1]}</a>
                            </p>
                          ))
                        : ''}
                    </span>
                  </div>
                ))}
              </div>
            </div>

            {!manualShowVersionPopup ? (
              <Flex alignItems='center'>
                <Checkbox action={this.handleChange} defaultChecked={this.state.versionChecked} />
                <Field label={t('versionPopUp.DontShowMe')} marginTop={0} marginBottom={0} />
              </Flex>
            ) : null}
            <Flex justifyContent='flex-end' paddingY={1}>
              <Button
                sizeButton='small'
                backgroundColor='blackLight'
                action={() => {
                  if (!this.props.manualShowVersionPopup) {
                    this.acceptVersion(this.state.versionChecked);
                  }
                  this.props.onClosePopUp();
                }}
              >
                {t('versionPopUp.Close')}
              </Button>
            </Flex>
          </span>
        </Popup>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  hideVerInfo: state.login.hideVerInfo,
  manualShowVersionPopup: state.login.manualShowVersionPopup,
  tenants: state.tenants,
});

const VersionPopUpWithTranslation = withTranslation()(VersionPopUp);
export default connect(mapStateToProps, { postAccountVersion })(VersionPopUpWithTranslation);
