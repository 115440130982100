import { call, fork, takeLatest } from 'redux-saga/effects';
import fetchEntitySaga from '../../old_to_refact/sagas/utils/fetchEntitySaga';
import apiPathFactory from '../../../core/old_common/rooting/apiPathFactory';
import api from '../../../core/helpers/api';
import {
  COPY_GROUP_MIFARE,
  CREATE_GROUP_MIFARE,
  DELETE_GROUP_MIFARE,
  DELETE_GROUP_MIFARE_LIST,
  GET_GROUP_MIFARE_LIST,
  IMPORT_GROUP_MIFARE,
  MOVE_GROUP_MIFARE,
  UPDATE_GROUP_MIFARE,
} from './constants';
import { getInstalationGroupsFobsCountSaga } from '../../old_to_refact/sagas/instalationGroups';

export const getMifareList = {
  base: ({ idFacility, idGroup }) => ({
    type: GET_GROUP_MIFARE_LIST.BASE,
    idFacility,
    idGroup,
  }),
  request: () => ({ type: GET_GROUP_MIFARE_LIST.REQUEST }),
  success: (result) => ({ type: GET_GROUP_MIFARE_LIST.SUCCESS, result }),
  failure: (error) => ({ type: GET_GROUP_MIFARE_LIST.FAILURE, error }),
};

//-----------------------------------------------------------------------------------

export function* getMifareListSaga({ idFacility, idGroup }) {
  try {
    const url = apiPathFactory.getMifareList(idFacility, idGroup);
    return yield call(() => fetchEntitySaga(getMifareList, api.get, url));
  } catch (error) {
    return error;
  }
}

//-----------------------------------------------------------------------------------

function* watchGetMifareList() {
  yield takeLatest(GET_GROUP_MIFARE_LIST.BASE, getMifareListSaga);
}

export const deleteMifareList = {
  base: ({ idFacility, idGroup, list }) => ({
    type: DELETE_GROUP_MIFARE_LIST.BASE,
    idFacility,
    idGroup,
    list,
  }),
  request: () => ({ type: DELETE_GROUP_MIFARE_LIST.REQUEST }),
  success: (result) => ({ type: DELETE_GROUP_MIFARE_LIST.SUCCESS, result }),
  failure: (error) => ({ type: DELETE_GROUP_MIFARE_LIST.FAILURE, error }),
};

export function* deleteMifareListSaga({ idFacility, idGroup, list }) {
  try {
    const url = apiPathFactory.deleteMifareList(idFacility, idGroup);
    const response = yield call(() =>
      fetchEntitySaga(deleteMifareList, api.del, url, { data: list })
    );
    yield call(getMifareListSaga, { idFacility, idGroup });
    yield call(getInstalationGroupsFobsCountSaga, {
      instalationID: idFacility,
      instalationGroupID: idGroup,
    });
    return response;
  } catch (error) {
    return error;
  }
}

function* watchDeleteMifareList() {
  yield takeLatest(DELETE_GROUP_MIFARE_LIST.BASE, deleteMifareListSaga);
}

//-----------------------------------------------------------------------------------
export const updateGroupMifare = {
  base: ({ idFacility, idGroup, idFob }) => ({
    type: UPDATE_GROUP_MIFARE.BASE,
    idFacility,
    idGroup,
    idFob,
  }),
  request: () => ({ type: UPDATE_GROUP_MIFARE.REQUEST }),
  success: (result) => ({ type: UPDATE_GROUP_MIFARE.SUCCESS, result }),
  failure: (error) => ({ type: UPDATE_GROUP_MIFARE.FAILURE, error }),
};

export function* updateGroupMifareSaga({ idFacility, idGroup, idFob, data }) {
  try {
    const url = apiPathFactory.updateMifareFob(idFacility, idGroup, idFob);
    const response = yield call(() => fetchEntitySaga(updateGroupMifare, api.put, url, { data }));
    yield call(getMifareListSaga, { idFacility, idGroup });
    return response;
  } catch (error) {
    yield call(getMifareListSaga, { idFacility, idGroup });
    return error;
  }
}

function* watchUpdateMifare() {
  yield takeLatest(UPDATE_GROUP_MIFARE.BASE, updateGroupMifareSaga);
}

//-----------------------------------------------------------------------------------
export const createGroupMifare = {
  base: ({ idFacility, idGroup, data }) => ({
    type: CREATE_GROUP_MIFARE.BASE,
    idFacility,
    idGroup,
    data,
  }),
  request: () => ({ type: CREATE_GROUP_MIFARE.REQUEST }),
  success: (result) => ({ type: CREATE_GROUP_MIFARE.SUCCESS, result }),
  failure: (error) => ({ type: CREATE_GROUP_MIFARE.FAILURE, error }),
};

export function* createGroupMifareSaga({ idFacility, idGroup, data }) {
  try {
    const url = apiPathFactory.createMifareFob(idFacility, idGroup);
    const response = yield call(() =>
      fetchEntitySaga(
        createGroupMifare,
        api.post,
        `${url}?codesAmount=${data.codesAmount}&initialCode=${data.initialCode}`,
        { data }
      )
    );
    yield call(getMifareListSaga, { idFacility, idGroup });
    yield call(getInstalationGroupsFobsCountSaga, {
      instalationID: idFacility,
      instalationGroupID: idGroup,
    });
    return response;
  } catch (error) {
    return error;
  }
}

function* watchCreateMifare() {
  yield takeLatest(CREATE_GROUP_MIFARE.BASE, createGroupMifareSaga);
}

//-----------------------------------------------------------------------------------
export const deleteGroupMifare = {
  base: ({ idFacility, idGroup, idFob }) => ({
    type: DELETE_GROUP_MIFARE.BASE,
    idFacility,
    idGroup,
    idFob,
  }),
  request: () => ({ type: DELETE_GROUP_MIFARE.REQUEST }),
  success: (result) => ({ type: DELETE_GROUP_MIFARE.SUCCESS, result }),
  failure: (error) => ({ type: DELETE_GROUP_MIFARE.FAILURE, error }),
};

export function* deleteGroupMifareSaga({ idFacility, idGroup, idFob }) {
  try {
    const url = apiPathFactory.deleteMifareFob(idFacility, idGroup, idFob);
    const response = yield call(() => fetchEntitySaga(deleteGroupMifare, api.del, url));
    yield call(getMifareListSaga, { idFacility, idGroup });
    yield call(getInstalationGroupsFobsCountSaga, {
      instalationID: idFacility,
      instalationGroupID: idGroup,
    });
    return response;
  } catch (error) {
    return error;
  }
}

function* watchDeleteMifare() {
  yield takeLatest(DELETE_GROUP_MIFARE.BASE, deleteGroupMifareSaga);
}

//-----------------------------------------------------------------------------------
export const moveGroupMifare = {
  base: ({ idFacility, idGroup, idFacilityTo, idGroupTo, list }) => ({
    type: MOVE_GROUP_MIFARE.BASE,
    idFacility,
    idGroup,
    idFacilityTo,
    idGroupTo,
    list,
  }),
  request: () => ({ type: MOVE_GROUP_MIFARE.REQUEST }),
  success: (result) => ({ type: MOVE_GROUP_MIFARE.SUCCESS, result }),
  failure: (error) => ({ type: MOVE_GROUP_MIFARE.FAILURE, error }),
};

export function* moveGroupMifareSaga({ idFacility, idGroup, idFacilityTo, idGroupTo, list }) {
  try {
    const url = apiPathFactory.moveGroupMifare(idFacility, idGroup, idFacilityTo, idGroupTo);
    const response = yield call(() =>
      fetchEntitySaga(moveGroupMifare, api.put, url, { data: list })
    );
    yield call(getMifareListSaga, { idFacility, idGroup });
    yield call(getInstalationGroupsFobsCountSaga, {
      instalationID: idFacility,
      instalationGroupID: idGroup,
    });
    return response;
  } catch (error) {
    return error;
  }
}

function* watchMoveMifare() {
  yield takeLatest(MOVE_GROUP_MIFARE.BASE, moveGroupMifareSaga);
}

//-----------------------------------------------------------------------------------
export const copyGroupMifare = {
  base: ({ idFacility, idGroup, idFacilityTo, idGroupTo, list }) => ({
    type: COPY_GROUP_MIFARE.BASE,
    idFacility,
    idGroup,
    idFacilityTo,
    idGroupTo,
    list,
  }),
  request: () => ({ type: COPY_GROUP_MIFARE.REQUEST }),
  success: (result) => ({ type: COPY_GROUP_MIFARE.SUCCESS, result }),
  failure: (error) => ({ type: COPY_GROUP_MIFARE.FAILURE, error }),
};

export function* copyGroupMifareSaga({ idFacility, idGroup, idFacilityTo, idGroupTo, list }) {
  try {
    const url = apiPathFactory.copyGroupMifare(idFacility, idGroup, idFacilityTo, idGroupTo);
    return yield call(() => fetchEntitySaga(copyGroupMifare, api.post, url, { data: list }));
  } catch (error) {
    return error;
  }
}

function* watchCopyMifare() {
  yield takeLatest(COPY_GROUP_MIFARE.BASE, copyGroupMifareSaga);
}

//-----------------------------------------------------------------------------------
//-----------------------------------------------------------------------------------
export const importGroupMifare = {
  base: ({ idFacility, idGroup, file }) => ({
    type: IMPORT_GROUP_MIFARE.BASE,
    idFacility,
    idGroup,
    file,
  }),
  request: () => ({ type: IMPORT_GROUP_MIFARE.REQUEST }),
  success: (result) => ({ type: IMPORT_GROUP_MIFARE.SUCCESS, result }),
  failure: (error) => ({ type: IMPORT_GROUP_MIFARE.FAILURE, error }),
};

export function* importGroupMifareSaga({ idFacility, idGroup, file }) {
  try {
    const url = apiPathFactory.importGroupMifare(idFacility, idGroup);

    const response = yield call(() =>
      fetchEntitySaga(importGroupMifare, api.put, url, {
        formData: [{ fieldName: 'file', file }],
      })
    );
    yield call(getMifareListSaga, { idFacility, idGroup });
    yield call(getInstalationGroupsFobsCountSaga, {
      instalationID: idFacility,
      instalationGroupID: idGroup,
    });

    return response;
  } catch (error) {
    return error;
  }
}

function* watchImportMifare() {
  yield takeLatest(IMPORT_GROUP_MIFARE.BASE, importGroupMifareSaga);
}

//-----------------------------------------------------------------------------------
export default function* badRuleWarningWatchers() {
  yield fork(watchGetMifareList);
  yield fork(watchDeleteMifareList);
  yield fork(watchCreateMifare);
  yield fork(watchUpdateMifare);
  yield fork(watchDeleteMifare);
  yield fork(watchMoveMifare);
  yield fork(watchCopyMifare);
  yield fork(watchImportMifare);
}
