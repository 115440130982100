import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DateTimePickerGlobalStyles } from './StyledDateTimePicker';
import './DateTimeInput.css';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { IntlProvider, load, loadMessages, LocalizationProvider } from '@progress/kendo-react-intl';

import likelySubtags from 'cldr-core/supplemental/likelySubtags.json';
import currencyData from 'cldr-core/supplemental/currencyData.json';
import weekData from 'cldr-core/supplemental/weekData.json';

import numbers from 'cldr-numbers-full/main/es/numbers.json';
import caGregorian from 'cldr-dates-full/main/es/ca-gregorian.json';
import dateFields from 'cldr-dates-full/main/es/dateFields.json';
import timeZoneNames from 'cldr-dates-full/main/es/timeZoneNames.json';

import itnumbers from 'cldr-numbers-full/main/it/numbers.json';
import itcaGregorian from 'cldr-dates-full/main/it/ca-gregorian.json';
import itdateFields from 'cldr-dates-full/main/it/dateFields.json';
import ittimeZoneNames from 'cldr-dates-full/main/it/timeZoneNames.json';

import denumbers from 'cldr-numbers-full/main/de/numbers.json';
import decaGregorian from 'cldr-dates-full/main/de/ca-gregorian.json';
import dedateFields from 'cldr-dates-full/main/de/dateFields.json';
import detimeZoneNames from 'cldr-dates-full/main/de/timeZoneNames.json';

import frnumbers from 'cldr-numbers-full/main/fr/numbers.json';
import frcaGregorian from 'cldr-dates-full/main/fr/ca-gregorian.json';
import frdateFields from 'cldr-dates-full/main/fr/dateFields.json';
import frtimeZoneNames from 'cldr-dates-full/main/fr/timeZoneNames.json';

import csnumbers from 'cldr-numbers-full/main/cs/numbers.json';
import cscaGregorian from 'cldr-dates-full/main/cs/ca-gregorian.json';
import csdateFields from 'cldr-dates-full/main/cs/dateFields.json';
import cstimeZoneNames from 'cldr-dates-full/main/cs/timeZoneNames.json';

import hznumbers from 'cldr-numbers-full/main/hu/numbers.json';
import hzcaGregorian from 'cldr-dates-full/main/hu/ca-gregorian.json';
import hzdateFields from 'cldr-dates-full/main/hu/dateFields.json';
import hztimeZoneNames from 'cldr-dates-full/main/hu/timeZoneNames.json';

import canumbers from 'cldr-numbers-full/main/ca/numbers.json';
import cacaGregorian from 'cldr-dates-full/main/ca/ca-gregorian.json';
import cadateFields from 'cldr-dates-full/main/ca/dateFields.json';
import catimeZoneNames from 'cldr-dates-full/main/ca/timeZoneNames.json';

import {
  getCookieDateTimeFormat,
  getCookieLanguage,
} from '../../core/old_common/utils/cookiesManager';
import { getFormatDateTime } from '../../core/old_common/utils/dataService';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import StyledSpan from './StyledSpan';

import esMessages from './translations/es.json';
import itMessages from './translations/it.json';
import frMessages from './translations/fr.json';
import deMessages from './translations/de.json';
import csMessages from './translations/cs.json';
import caMessages from './translations/ca.json';
import hzMessages from './translations/hz.json';

load(
  likelySubtags,
  currencyData,
  weekData,
  numbers,
  caGregorian,
  dateFields,
  timeZoneNames,
  itnumbers,
  itcaGregorian,
  itdateFields,
  ittimeZoneNames,
  denumbers,
  decaGregorian,
  dedateFields,
  detimeZoneNames,
  frnumbers,
  frcaGregorian,
  frdateFields,
  frtimeZoneNames,
  csnumbers,
  cscaGregorian,
  csdateFields,
  cstimeZoneNames,
  hznumbers,
  hzcaGregorian,
  hzdateFields,
  hztimeZoneNames,
  canumbers,
  cacaGregorian,
  cadateFields,
  catimeZoneNames
);

loadMessages(esMessages, 'es-ES');
loadMessages(itMessages, 'it-IT');
loadMessages(frMessages, 'fr-FR');
loadMessages(deMessages, 'de-DE');
loadMessages(csMessages, 'cs-CZ');
loadMessages(hzMessages, 'hz-HZ');
loadMessages(caMessages, 'ca-ES');

class DateTimeInput extends Component {
  render() {
    const {
      id,
      placeHolder,
      value,
      tenants,
      error,
      onChange,
      isFocused,
      onFocus,
      onBlur,
      min,
      max,
      isDisabled,
      nonSelect,
      labelClassName,
      dateFormat,
    } = this.props;
    const lang = getCookieLanguage();
    const cookieDateFormat = getCookieDateTimeFormat();
    return (
      <label
        className={`${
          !labelClassName && nonSelect ? 'date-time-input-field-nonselect' : 'date-time-input-field'
        } ${labelClassName ?? ''}`}
      >
        <StyledSpan tenants={tenants} isFocused={isFocused}>
          {placeHolder}
        </StyledSpan>
        <DateTimePickerGlobalStyles tenants={tenants} />
        <LocalizationProvider language={lang || ''}>
          <IntlProvider locale={`${lang?.slice(0, 2)}` || 'en'}>
            <DatePicker
              id={id}
              tenants={tenants}
              className='date-time-input-field-picker'
              format={
                dateFormat
                  ? dateFormat.includes(' ')
                    ? dateFormat?.substring(0, dateFormat.indexOf(' '))
                    : dateFormat
                  : cookieDateFormat
              }
              min={min}
              max={max}
              formatPlaceholder='formatPattern'
              onFocus={onFocus}
              onBlur={onBlur}
              isFocused={isFocused}
              disabled={isDisabled}
              onChange={(e) =>
                onChange({
                  ...e,
                  target: {
                    ...e.target,
                    id: e.target.props.id,
                    value: getFormatDateTime(e.target.value),
                  },
                })
              }
              defaultValue={value ? new Date(value) : new Date()}
            />
          </IntlProvider>
        </LocalizationProvider>
        {error ? <ErrorMessage message={error.errorMessage} /> : null}
      </label>
    );
  }
}

const mapStateToProps = (state) => ({
  tenants: state.tenants,
});

export default connect(mapStateToProps, {})(DateTimeInput);
