import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import EquipmentsListSearch from '../../old_components/EquipmentsList/EquipmentsListSearch';

export class Equipments extends Component {
  render() {
    return <EquipmentsListSearch />;
  }
}

const EquipmentsPage = withTranslation()(Equipments);

export default EquipmentsPage;
