import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from '@jcm-technologies/uikit/dist/molecules/Card';
import { Flex } from '@jcm-technologies/uikit/dist/atoms/Layout';
import { Text } from '@jcm-technologies/uikit/dist/atoms/Typography';
import { Icon } from '@jcm-technologies/uikit/dist/atoms/Icon';
import IconHardDrive from '@jcm-technologies/uikit/dist/atoms/Icons/IconHardDrive';
import PropTypes from 'prop-types';

const DeviceUpdated = ({ handleRedirectTo, missconfiguredDevices }) => {
  const { t } = useTranslation();

  return (
    <Card
      rounded='soft'
      width='100%'
      height='100%'
      maxWidth='100%'
      position='relative'
      display='flex'
    >
      <Flex id='missconfiguredTitle' width='100%' flexDirection='column'>
        <Flex alignItems='center'>
          <Icon sizeIcon='display24' marginRight={2} color='red'>
            <IconHardDrive />
          </Icon>
          <Text style={{ flex: '1' }}>{t('dashboard.devicesMissconfigured')}</Text>
        </Flex>
        <Flex justifyContent='center' flex={1} marginTop={3}>
          <Card
            rounded='solid'
            width='100%'
            height='100%'
            marginRight={2}
            maxWidth='100%'
            style={{ cursor: 'pointer' }}
          >
            <Text sizeText='display16'>{t('dashboard.withUpdates')}</Text>
            <Flex justifyContent='center'>
              <Text id='missconfiguredConnected-total' as='span' color='red' sizeText='display48'>
                {missconfiguredDevices?.misconfiguredRemoteEquipments}
              </Text>
            </Flex>
            <Text
              id='missconfiguredConnectedOnline'
              sizeText={['display14', 'display14', 'display14', 'display16']}
              onClick={() => handleRedirectTo(12, '', t('dashboard.withUpdates'))}
            >
              {t('status.online')}:
              <Text
                id='missconfiguredConnectedOnline-total'
                color='red'
                sizeText='display16'
                as='span'
                paddingLeft={1}
              >
                {missconfiguredDevices?.missconfiguredConnected}
              </Text>
            </Text>
            <Text
              id='missconfiguredConnectedOffline'
              sizeText={['display14', 'display14', 'display14', 'display16']}
              paddingTop={1}
              onClick={() => handleRedirectTo(18, '', t('dashboard.withUpdates'))}
            >
              {t('status.offline')}:
              <Text
                id='missconfiguredConnectedOffline-total'
                color='red'
                sizeText='display16'
                as='span'
                paddingLeft={1}
              >
                {missconfiguredDevices?.missconfiguredDisconnected}
              </Text>
            </Text>
          </Card>
          <Card
            rounded='solid'
            width='100%'
            height='100%'
            padding={2}
            style={{ cursor: 'pointer' }}
            onClick={() => handleRedirectTo(10, '', t('dashboard.withoutUpdates'))}
          >
            <Text sizeText='display16'>{t('dashboard.withoutUpdates')}</Text>
            <Flex justifyContent='center'>
              <Text
                id='missconfiguredDisconnected-total'
                as='span'
                color='red'
                sizeText='display48'
              >
                {missconfiguredDevices?.missconfiguredNoConnectivity}
              </Text>
            </Flex>
            <Text
              id='missconfiguredDisconnectedOffline'
              sizeText={['display14', 'display14', 'display14', 'display16']}
              paddingTop={1}
            >
              {t('status.offline')}:
              <Text
                id='missconfiguredDisconnectedOffline-total'
                color='red'
                sizeText='display16'
                as='span'
                paddingLeft={1}
              >
                {missconfiguredDevices?.missconfiguredNoConnectivity}
              </Text>
            </Text>
          </Card>
        </Flex>
      </Flex>
    </Card>
  );
};

export default DeviceUpdated;

DeviceUpdated.propTypes = {
  handleRedirectTo: PropTypes.func.isRequired,
  missconfiguredDevices: PropTypes.shape({
    misconfiguredRemoteEquipments: PropTypes.number,
    missconfiguredConnected: PropTypes.number,
    missconfiguredDisconnected: PropTypes.number,
    missconfiguredNoConnectivity: PropTypes.number,
  }),
};
