export default {
  instalationUserGroups: {
    manageFobsAndLicenses: 'Administrar FOBS y licencias',
    fobsLicensesGridTitle: 'FOBS/Licencias',
    email: 'Correo electrónico',
    phone: 'Teléfono',
    addUser: 'Añadir usuario',
    deleteUserConfirmation: '¿Seguro que desea eliminar este usuario?',
    Fobs: 'Emisores',
    Licenses: 'Licencias',
    addFobToUser: 'Añadir FOB al usuario',
    expiration: 'Vencimiento',
    licenseStatus: 'Estado',
    locked: 'Bloqueada',
    unlocked: 'Desbloqueado',
    0: 'Bluetooth',
    1: 'Apertura remota',
    users: 'Usuarios',
    information: 'Información',
  },
};
