import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import TabMenu from '../TabMenu/TabMenu';
import tabType from './tabTypes';

import './EquipmentDynamicTabs.css';

class EquipmentDynamicTabs extends Component {
  render() {
    const {
      value,
      t,
      facilityId,
      equipmentId,
      facilityEquipmentAttributes,
      setParamsOnForm,
      equipment,
      product,
      selectedTab,
      onClick,
      saveButtonIsEnabledCallback,
      setExitConfirmationCallback,
      facility,
      updateItem,
    } = this.props;

    const { tabs } = value;
    return (
      <div className='equipment-dynamic-tabs'>
        {tabs.length ? (
          <TabMenu
            tabType='tabs'
            tabClassName='dynamic-tabs-content'
            tabsData={tabs.map((tab, i) => {
              const tabName = tab.name.toLowerCase().replace('.', '');

              return {
                id: i18next.exists(`title.${tabName}`)
                  ? t(`title.${tabName}`)
                  : i18next.exists(`equipments.${tabName}`)
                  ? `${t(`equipments.${tabName}`)}`
                  : tab.name,
                title: i18next.exists(`title.${tabName}`)
                  ? t(`title.${tabName}`)
                  : i18next.exists(`equipments.${tabName}`)
                  ? `${t(`equipments.${tabName}`)}`
                  : tab.name,
                bodyComponent: tabType[tabName],
                active: selectedTab ? selectedTab.toLowerCase() === tabName.toLowerCase() : i === 0,
                haveBorder: true,
                onClick: onClick ? (e) => onClick(e, tabName) : null,
                componentState: {
                  ...tab,
                  facilityId,
                  equipmentId,
                  facilityEquipmentAttributes,
                  setParamsOnForm,
                  equipment,
                  product,
                  saveButtonIsEnabledCallback,
                  setExitConfirmationCallback,
                  facility,
                  updateItem,
                },
              };
            })}
          />
        ) : null}
      </div>
    );
  }
}

const EquipmentDynamicTabsWithTranslation = withTranslation()(EquipmentDynamicTabs);

export default EquipmentDynamicTabsWithTranslation;
