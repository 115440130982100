import React, { Component } from 'react';
import { connect } from 'react-redux';
import Popup from '../../../Popup/Popup';
import ButtonsGroup from '../../../ButtonsGroup/ButtonsGroup';
import { getDeviceState, resetAllDeviceProps } from '../../../../modules/old_to_refact/actions/devices';

import './DeviceInformationPopup.css';
import { withTranslation } from 'react-i18next';
import ContentContainer from '../../../ContentContainer/ContentContainer';
import GenericTable from '../../../GenericTable/GenericTable';
import inputsDictionary from './InputsDictionary.js';
import DeviceInformationRelays from './DeviceInformationRelays';
import IconSpan from '../../../IconSpan/IconSpan';

class DeviceInformationPopups extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidUpdate(prevProps) {
    const { item, isOpen, getDeviceState } = this.props;
    if (!prevProps.isOpen && isOpen) {
      getDeviceState(item.facilityId, item.id);
    }
  }

  getColumns = (value) => [
    {
      value,
    },
  ];

  getRowsDoorState = () => {
    const { driverState, t } = this.props;
    const rows = driverState
      ? [
          {
            type: driverState.DS,
            tagValue: t('remoteState.doorState'),
            value: t(`remoteState.doorState${driverState.DS}`),
          },
        ]
      : [];
    return rows;
  };

  getRowsDoorState2 = () => {
    const { driverState, t } = this.props;
    const rows = driverState
      ? [
          {
            type: driverState.D2S,
            tagValue: t('remoteState.doorState'),
            value: t(`remoteState.doorState${driverState.D2S}`),
          },
        ]
      : [];
    return rows;
  };

  getRowsInputState = () => {
    const { driverState, tenants } = this.props;
    let rows = [];
    const inputValues = [];
    if (driverState) {
      const { inputs } = driverState;
      inputs.map((input, i) => {
        inputValues.push({
          checkedCode: input.state,
          id: `input ${i}`,
          value: `${input.name}`,
          value: `${input.name}`,
        });
      });

      rows = inputValues.map((input) => {
        const isActive = input.checkedCode === 1;
        return {
          tagValue: input.value,
          hasAction: true,
          rowStyle: isActive ? { background: tenants.color1 } : null,
          getActionComponent: () => {
            const InputType = inputsDictionary.checkbox({
              type: 'checkbox',
              id: input.id,
              checked: isActive,
              disabled: true,
            });
            return InputType;
          },
        };
      });
    }
    return rows;
  };

  getConnectionSignal = (signal) => {
    const { t } = this.props;
    if (signal == 0) {
      return t('remoteState.connectionSignalUnknown');
    }
    if (signal == 5) {
      return t('remoteState.connectionSignalExcellent');
    }
    if (signal == 4) {
      return t('remoteState.connectionSignalGood');
    }
    if (signal == 3) {
      return t('remoteState.connectionSignalFair');
    }
    if (signal == 2) {
      return t('remoteState.connectionSignalWeak');
    }
    if (signal == 1) {
      return t('remoteState.connectionSignalWeak');
    }
  };

  getRowsDeviceInformation = () => {
    const { driverState, t } = this.props;
    const rows = driverState
      ? [
          {
            type: driverState.dt,
            tagValue: t('remoteState.deviceType'), // 'Device Type',
            value: t(`remoteState.deviceType${driverState.DT}`),
          },
          {
            type: driverState.dt,
            tagValue: t('remoteState.connectionType'), // 'Device Type',
            value: driverState.CONTYP === 1 ? '2G' : 'WiFi',
          },
          {
            type: driverState.ds,
            tagValue: t('remoteState.connectionSignal'), // 'Connection Signal',
            value: this.getConnectionSignal(
              driverState.interfaces.filter((x) => x.enabled == true).length > 0
                ? driverState.interfaces.filter((x) => x.enabled == true)[0].quality
                : 0
            ),
          },
          {
            type: driverState.ds,
            tagValue: t('remoteState.rssiValue'), // 'RSSI value',
            value: `${
              driverState.interfaces.filter((x) => x.enabled == true).length > 0
                ? driverState.interfaces.filter((x) => x.enabled == true)[0].RSSI
                : 0
            } dBm`,
          },
          {
            type: driverState.ds,
            tagValue: t('remoteState.hwVersion'), // 'Hardware Version',
            value: driverState.HW,
          },
          {
            type: driverState.ds,
            tagValue: t('remoteState.swVersion'), // 'Software Version',
            value: driverState.SW,
          },
        ]
      : [];
    return rows;
  };

  getRowsSensorsInformation = () => {
    const { driverState, t } = this.props;
    const rows = [];
    driverState.sensors.map((sensor) => {
      rows.push({
        type: sensor.batt,
        tagValue: `${sensor.name}`,
        value: (
          <div>
            <div id='icon-battery'>
              <IconSpan
                defaultColor={sensor.batt === 100 ? 'green' : 'red'}
                iconClassName={sensor.batt === 100 ? 'fas fa-battery-full' : 'fas fa-battery-empty'}
              />
            </div>
            <div>
              <a>
                {sensor.state === 0 ? t('remoteState.deactivated') : t('remoteState.activated')}
              </a>
            </div>
          </div>
        ),
      });
    });

    return rows;
  };

  refreshState = () => {
    const { item, getDeviceState } = this.props;
    getDeviceState(item.facilityId, item.id);
  };

  onClose = () => {
    const { onClose, resetAllDeviceProps } = this.props;
    resetAllDeviceProps();
    onClose();
  };

  render() {
    const { isOpen, tenants, item, t, driverState, showLoading } = this.props;
    return (
      <Popup isOpen={isOpen} onClose={this.onClose} className='device-popup'>
        <ContentContainer title={t('remoteState.title')}>
          <div className='device-container'>
            {driverState ? (
              <span className='device-information-container'>
                <span className='device-information'>
                  {/* Separate doors depends on inputs configured */}
                  {(driverState.inputs.length > 0 || driverState.sensors.length > 0) &&
                  (driverState.inputs.some((x) =>
                    ['1', '2', '3', '4'].includes(x.function.split('[Function] ')[1])
                  ) ||
                    driverState.sensors.some((x) =>
                      ['50', '51'].includes(x?.function?.split('[Function] ')[1])
                    )) ? (
                    <GenericTable
                      className='table-striped'
                      columnsConfig={this.getColumns(`${t('remoteState.doorStateTitle')} 1`)}
                      rowsConfig={this.getRowsDoorState()}
                    />
                  ) : (
                    ''
                  )}

                  {(driverState.inputs.length > 0 || driverState.sensors.length > 0) &&
                  (driverState.inputs.some((x) =>
                    ['12', '13', '14', '15'].includes(x?.function?.split('[Function] ')[1])
                  ) ||
                    driverState.sensors.some((x) =>
                      ['52', '53'].includes(x?.function?.split('[Function] ')[1])
                    )) ? (
                    <GenericTable
                      className='table-striped'
                      columnsConfig={this.getColumns(`${t('remoteState.doorStateTitle')} 2`)}
                      rowsConfig={this.getRowsDoorState2()}
                    />
                  ) : (
                    ''
                  )}
                  {driverState.inputs.length > 0 ? (
                    <GenericTable
                      columnsConfig={this.getColumns(t('remoteState.inputStateTitle'))}
                      rowsConfig={this.getRowsInputState()}
                    />
                  ) : (
                    ''
                  )}
                  {/* <GenericTable className="table-striped" columnsConfig={this.getColumns(t('remoteState.deviceInfoTitle'))} rowsConfig={this.getRowsDeviceInformation()} /> */}
                </span>
                <span className='relays-information'>
                  <DeviceInformationRelays
                    className='table-striped'
                    tenants={tenants}
                    device={item}
                    driverState={driverState}
                  />
                </span>
                <div className='scrollit-remote-status'>
                  <div className='divTable-jiviri'>
                    <div className='divTableBody'>
                      <div className='divTableRow'>
                        <span className='device-information'>
                          {driverState.sensors.length > 0 ? (
                            <GenericTable
                              className='table-striped'
                              columnsConfig={this.getColumns(t('remoteState.sensors'))}
                              rowsConfig={this.getRowsSensorsInformation()}
                            />
                          ) : (
                            ''
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='divTable'>
                  <div className='divTableBody'>
                    <div className='divTableRow'>
                      <span className='device-information'>
                        <GenericTable
                          className='table-striped'
                          columnsConfig={this.getColumns(t('remoteState.deviceInfoTitle'))}
                          rowsConfig={this.getRowsDeviceInformation()}
                        />
                        <span className='refresh-button-container'>
                          <ButtonsGroup
                            buttons={[
                              {
                                id: 'refreshButton-remote-state',
                                className: 'refresh-button',
                                onClick: this.refreshState,
                                value: t('remoteState.refreshStatus'),
                              },
                            ]}
                          />
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </span>
            ) : (
              <div className='no-content-device'>
                {showLoading <= 0 ? (
                  <h6>
                    <IconSpan
                      tenants={this.props.tenants}
                      defaultColor='grey'
                      iconClassName='fas fa-info-circle empty-map-warn'
                    />
                    {t('remoteState.noContent')}
                  </h6>
                ) : (
                  ''
                )}
              </div>
            )}
          </div>
        </ContentContainer>
      </Popup>
    );
  }
}

const mapStateToProps = (state) => ({
  tenants: state.tenants,
  driverState: state.devices.driverState,
  driverStateError: state.devices.driverStateError,
  showLoading: state.loading.show,
});

const DeviceInformationPopupsWithTranslation = withTranslation()(DeviceInformationPopups);

export default connect(mapStateToProps, { getDeviceState, resetAllDeviceProps })(
  DeviceInformationPopupsWithTranslation
);
