import { call, cancelled, put, select } from 'redux-saga/effects';
import i18n from '../../../../core/i18n';
import { API_CALL_FAILURE } from '../../reducers/errors';
import popupActions from '../../actions/popUp';
import { showDialog } from '../../actions/loading';
import api from '../../../../core/helpers/api';
import {
  getCookieAccessToken,
  getExpiresTimeToken,
} from '../../../../core/old_common/utils/cookiesManager';
import { showAlert } from '../../../state/alerts';

/**
 * Reusable fetch Subroutine
 * @param {object} entity - object with the respective actions for a fetch flow: request, success...
 * @param {function} apiFn - method which execute the fetch call
 * @param {object} params - rest of the params and data which are going to be sent to the
 *                          fetch request
 * @return {any} - data
 */

function isHourSpent(date, timeSpent) {
  const currentDate = new Date();
  const difference = currentDate.getTime() - date.getTime();
  // comparing the hour's total milliseconds and the difference between the two dates.
  if (Math.abs(difference) < timeSpent) {
    return true;
  }
  return false;
}

function isExpiredToken(date) {
  const currentDate = new Date();
  const difference = currentDate.getTime() - date.getTime();
  // comparing the hour's total milliseconds and the difference between the two dates.
  if (Math.sign(difference) > 0) {
    return true;
  }
  return false;
}

const checkIfTokenNeedRefresh = () => {
  const expiresOnReceived = getExpiresTimeToken();
  const lastCallDoIt = sessionStorage.getItem('last_call');
  if (expiresOnReceived) {
    const date1 = new Date(expiresOnReceived);
    const date2 = new Date(lastCallDoIt);
    const minutes55 = 600 * 5.5 * 100;
    const oneHour = 600 * 6 * 100;

    // We check if token is expired
    if (isExpiredToken(date1)) {
      // The token are expired
      // If we use the spa in less hour but now the token are expired, we need refresh it, we set resfres_check to true, the layout call to acquireTokenSilent
      if (isHourSpent(date2, oneHour)) {
        sessionStorage.setItem('refresh_check', true);
      }
      // If we are in last 5 minutes of token expiration, we need refresh token, we set resfres_check to true, the layout call to acquireTokenSilent
    } else if (isHourSpent(date1, minutes55)) {
      sessionStorage.setItem('refresh_check', true);
    }
  }
};

export default function* fetchEntity(entity, apiFn, ...params) {
  try {
    checkIfTokenNeedRefresh();
    const state = yield select();
    if (state.popup.isOpenAuth && getCookieAccessToken()) {
      return;
    }
    // const callType = entity.request(params).type;

    if (!params[1] || !params[1].notShowLoadSpinner) yield put(showDialog(1));
    yield put(entity.request(params));
    const response = yield call(apiFn, ...params);
    yield put(entity.success(response, params));
    if (!params[1] || !params[1].toastNotShow) {
      if (apiFn === api.put || apiFn === api.post) {
        yield put(showAlert('green', 1, i18n.t('general.saved')));
      } else if (apiFn === api.del) {
        yield put(showAlert('green', 1, i18n.t('general.deletedSuccessfully.one')));
      }
    }
    yield put(showDialog(-1));
    sessionStorage.setItem('last_call', new Date());
    return response;
  } catch (error) {
    if (error.status === 401 && error.response.body === null) {
      yield put(showDialog(-1));
      yield put({ type: popupActions.POPUPSETTINGS_SET, payload: { isOpenAuth: true } });
    }
    // if (error.response === undefined && window.location.pathname !== '/login') {
    //   window.location.replace(`${window.location.origin}/login`);
    // } else if (error.response === undefined && window.location.pathname === '/login') {
    //   toast.error(i18next.t('errors.communicationError'));
    // }
    yield put(entity.failure(error, params));
    yield put(showDialog(-1));
    yield put({
      type: API_CALL_FAILURE,
      error,
      toastNotShow: params ? params[1]?.toastNotShow : false,
    });
    throw error;
  } finally {
    if (yield cancelled()) {
      yield put(showDialog(-1));
    }
  }
}
