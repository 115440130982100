import { apiVersion, mainPath } from '../common';

class PathEntity {
  constructor(entityName) {
    this.entityName = entityName;
    this.mainPath = `${mainPath()}${apiVersion}`;
  }

  manufacturePath = (endPoint) => `${this.mainPath}/${endPoint}`;

  pagedEndPoint = (url, pageSize, pageNumber) => {
    let finalEndpoint = url;
    finalEndpoint = this.addParameterToEndpoint(finalEndpoint, 'pagesize', pageSize);
    finalEndpoint = this.addParameterToEndpoint(finalEndpoint, 'pagenumber', pageNumber);
    return finalEndpoint;
  };

  getEndPointWithVisibleDescendants = (url, selectedDescendants, filterName) => {
    let finalEndpoint = url;
    if (selectedDescendants && Array.isArray(selectedDescendants) && selectedDescendants.length) {
      finalEndpoint = this.addParametersFromArray(
        finalEndpoint,
        filterName,
        selectedDescendants.map((descendant) => descendant.value)
      );
    } else if (!Array.isArray(selectedDescendants) && selectedDescendants) {
      finalEndpoint = this.addParameterToEndpoint(
        finalEndpoint,
        filterName,
        selectedDescendants,
        selectedDescendants
      );
    }
    return finalEndpoint;
  };

  addParametersFromArray = (endpoint, parameterName, parameterArray) => {
    let finalEndpoint = endpoint;
    parameterArray.forEach(
      (parameter) =>
        (finalEndpoint = this.addParameterToEndpoint(finalEndpoint, parameterName, parameter))
    );
    return finalEndpoint;
  };

  addParameterToEndpoint = (endpoint, parameterName, parameterValue) => {
    const endPointHasParameters = endpoint.indexOf('?') > -1;
    const parameterConnector = endPointHasParameters ? '&' : '?';
    return `${endpoint}${parameterConnector}${parameterName}=${encodeURIComponent(parameterValue)}`;
  };

  getPaged = (
    pageSize,
    pageNumber,
    { selectedDescendants, selectedCities, selectedPostalCodes, searchQuery }
  ) => {
    let finalEndpoint = this.getEndPointWithVisibleDescendants(
      this.entityName,
      selectedDescendants,
      'CustomerIds'
    );
    finalEndpoint = this.getEndPointWithVisibleDescendants(finalEndpoint, selectedCities, 'City');
    finalEndpoint = this.getEndPointWithVisibleDescendants(
      finalEndpoint,
      selectedPostalCodes,
      'ZIP'
    );
    finalEndpoint = this.getEndPointWithVisibleDescendants(finalEndpoint, searchQuery, 'queryText');
    finalEndpoint = this.manufacturePath(finalEndpoint);
    return this.pagedEndPoint(finalEndpoint, pageSize, pageNumber);
  };

  getPagedWithTextToSearch = (
    pageSize,
    pageNumber,
    { selectedDescendants, selectedCities, selectedPostalCodes, searchQuery, fieldId }
  ) => {
    let finalEndpoint = this.getEndPointWithVisibleDescendants(
      this.entityName,
      selectedDescendants,
      'CustomerIds'
    );
    finalEndpoint = this.getEndPointWithVisibleDescendants(finalEndpoint, selectedCities, 'City');
    finalEndpoint = this.getEndPointWithVisibleDescendants(
      finalEndpoint,
      selectedPostalCodes,
      'ZIP'
    );
    finalEndpoint = this.getEndPointWithVisibleDescendants(
      finalEndpoint,
      searchQuery,
      'textToSearch'
    );
    if (fieldId) {
      finalEndpoint = `${finalEndpoint}&fieldId=${fieldId}`;
    }
    finalEndpoint = this.manufacturePath(finalEndpoint);
    return this.pagedEndPoint(finalEndpoint, pageSize, pageNumber);
  };

  getPaths() {
    throw new Error('You have to implement the method getPaths!');
  }
}

export default PathEntity;
