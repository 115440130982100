export default {
  signalRError: {
    11050: 'Respuesta inesperada',
    11051: 'Dispositivo incorrecto',
    11052: 'Codificación incorrecta',
    11053: 'El dispositivo está usando el código de instalador',
    11054: 'El dispositivo no usa el código de instalador',
    11055: 'Tiempo de espera',
    11056: 'Assistant no está listo',
    11057: 'Assistant está ocupado',
    11058: 'Ha ocurrido un error',
    11059: 'Operación denegada',
    11060: 'Versión de arranque incorrecta',
    11061: 'Todavía en progreso',
    11062: 'Contador incorrecto',
    11063: 'Número de serie incorrecto',
    11064: 'Error al configurar el dispositivo',
    11065: 'Acción desconocida',
    11066: 'Dispositivo incorrecto',
    11067: 'Error fatal',
    11068: 'Crc incorrecto',
    11069: 'Contraseña no coincidente',
    connectionTimeout: 'Verifique la conexión con el assistant panel',
    dontMoveTag:
      'Sucedió un error. Repita la operación sin mover el dispositivo de la zona de programación.',
  },
};
