import {
  DEL_HOLIDAYS,
  GET_HOLIDAYS,
  GET_HOLIDAYS_FULL,
  SET_HOLIDAYS,
} from './constants';


export const getHolidays = () => ({
  type: GET_HOLIDAYS.BASE
});
export const getHolidaysFull = () => ({
  type: GET_HOLIDAYS_FULL.BASE
});

export const setHolidays = (id, data) => ({
  type: SET_HOLIDAYS.BASE, id, data
});

export const delHolidays = (id, data) => ({
  type: DEL_HOLIDAYS.BASE, id, data
});
