import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import {
  getSecondaryInstalationGroups,
  postInstalationGroup,
  putInstalationGroup,
} from '../../modules/old_to_refact/actions/instalationGroups';
import {
  deleteAlarms,
  getInstalationsFOBS,
  getInstalationsSingleList,
  needReturnToFacilities,
  resetInstalationFOBS,
} from '../../modules/old_to_refact/actions/instalations';
import {
  deleteFob,
  deleteFobList,
  fobsGetCheckCodes,
  importFobs,
  postCopyFobList,
  putFob,
  putMoveFobList,
  resetCheckedFobs,
} from '../../modules/group/fobs/actions';
import IndividualGrowGenericForm from '../IndividualGrowGenericForm/IndividualGrowGenericForm';
import { putUniversalGroupsFob } from '../../modules/old_to_refact/actions/universalGroupsFobs';
import { getArrayWithAttributes } from '../../core/old_common/utils/createInputByTenant';
import getOnFlyValidations from '../../core/old_common/utils/validationsApi';
import { setOnFlyErrors } from '../../core/old_common/validations/service';
import apiPathFactory from '../../core/old_common/rooting/apiPathFactory';
import { getFamiliesSingleList } from '../../modules/productFamilies/action';
import { getFamilyListByType, getProduct } from '../../core/old_common/utils/familiesService';
import {
  resetManualGrowRedirectURL,
  setManualGrowRedirectURL,
} from '../../modules/old_to_refact/actions/genericGrow';
import { getECodeTranslated } from '../../core/old_common/utils/facilityService';

import {
  createUniversalGroup,
  setUniversalGroup,
} from '../../modules/old_to_refact/actions/universalGroups';
import { getCookie_groupIsUniversal } from '../../core/old_common/utils/cookiesManager';
import validationsDictionary from '../../core/old_common/validations';
import {
  resetSignalRPopup,
  setNeedPopup,
  setProductId,
  showPopUp,
} from '../../modules/old_to_refact/actions/signalr';

class IndividualInstalationInformationForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.createNewState(props),
      substitutionSignalRTrack: '',
      fobCodeSignalRTrack: '',
      isCheckingCodes: undefined,
      isInstalationSingleListCalled: false,
      isDeleteFobPopupOpen: false,
      deleteFobItem: {},
      alarmId: null,
      isErrorMessagePopupOpen: false,
      productIdWrite: undefined,
      popup: {
        isPopupOpen: false,
        text: '',
        onAcceptFn: () => {
          this.onCleanAlarm();
        },
        onCancelFn: () => {
          this.onToggleCleanAlarm();
        },
      },
    };
    this.familyType = props.isUniversal || getCookie_groupIsUniversal() ? 2 : 1;
  }

  createNewState = (props) => {
    const { match, creationURI, familiesSingleList } = this.props;
    const isCreateMode = match.params.id === creationURI;
    const productFamily = getFamilyListByType(familiesSingleList, this.familyType);
    return {
      id: isCreateMode ? '' : props.id,
      name: props.name || '',
      description: props.description || '',
      facilityId: isCreateMode ? match?.params?.instalationId : props.facilityId,
      facilityFobs: getArrayWithAttributes(
        props.facilityFobs,
        'facilityFobAttributes',
        props.instalationConfigFields
      ).map((fob) => {
        const product = getProduct(productFamily, fob.productId);
        return {
          ...fob,
          fobCode: getECodeTranslated(fob.e_code),
          productName: product ? product.name : '',
        };
      }),
      formErrors: [],
      onFlyFormErrors: [],
      isUniversal: props.isUniversal,
    };
  };

  handleBlur = (e) => {
    const { match } = this.props;
    if (this.props[e.target.id] !== e.target.defaultValue) {
      this.executeOnFlyValidations(
        e.target.id,
        apiPathFactory.facilitiesGroupsCheckContent(
          e.target.id,
          e.target.defaultValue,
          match?.params?.instalationId
        )
      );
    } else {
      this.setErrorsCallback({
        onFlyFormErrors: this.state.onFlyFormErrors,
        error: undefined,
        id: e.target.id,
      });
    }
  };

  executeOnFlyValidations = (inputId, endPoint) => {
    const { t } = this.props;
    getOnFlyValidations(endPoint, (isError, response) => {
      this.setErrorsCallback({
        onFlyFormErrors: this.state.onFlyFormErrors,
        error: isError,
        id: inputId,
        errorMessage: isError ? t(`errors.${response?.error?.code}`) : '',
      });
    });
  };

  setErrorsCallback = ({ onFlyFormErrors, error, id, errorMessage }) => {
    this.setState({
      onFlyFormErrors: setOnFlyErrors({ onFlyFormErrors, error, id, errorMessage }),
    });
  };

  getFields = (newFields) => {
    const { t } = this.props;
    const fields = [
      [
        {
          type: 'name',
          placeHolder: `${t('form.name')} *`,
          value: 'name',
          id: 'name',
          onBlur: this.handleBlur,
          validation: [
            () => validationsDictionary.isRequired(newFields.name),
            () => validationsDictionary.isInTextLength(newFields.name, 255),
          ],
        },
        {
          type: 'description',
          placeHolder: t('instalationGroups.description'),
          value: 'description',
          id: 'description',
          validation: [() => validationsDictionary.isInTextLength(newFields.description, 255)],
        },
      ],
    ];
    return fields;
  };

  render() {
    const {
      t,
      isUniversal,
      postInstalationGroup,
      setUniversalGroup,
      createUniversalGroup,
      baseURI,
      putInstalationGroup,
      creationURI,
      successUrlRedirect,
      facilityGroup,
    } = this.props;
    const { formErrors, onFlyFormErrors } = this.state;
    return (
      <div className='form'>
        <IndividualGrowGenericForm
          item={this.createNewState(this.props)}
          fields={(newFields) => this.getFields(newFields)}
          onFlyformErrors={[...(formErrors || []), ...(onFlyFormErrors || [])]}
          submitText={t('general.save')}
          updateItem={
            isUniversal || getCookie_groupIsUniversal() ? setUniversalGroup : putInstalationGroup
          }
          goToIdRedirect={`${facilityGroup?.id}/Configuration`}
          createItem={isUniversal ? createUniversalGroup : postInstalationGroup}
          creationURI={creationURI}
          baseURI={baseURI}
          successUrlRedirect={successUrlRedirect}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  tenants: state.tenants,
  facilityGroup: state.instalationGroups.facilityGroup,
  originalTenants: state.tenants.originalTenants,
  instalationsSingleList: state.instalations.instalationsSingleList,
  facilityGroups: state.instalationGroups.secondaryFacilityGroups,
  familiesSingleList: state.families.familiesSingleList,
  fobsImported: state.fobs.fobsImported,
  redirectURL: state.genericGrow.redirectURL,
  facilityFobs: state.instalations.facilityFobs,
  universalGroupFobs: state.universalGroupsFobs.universalGroupFobs,
  instalationConfigFields: state.instalations.instalationConfigFields,
  universalConfigFields: state.universalGroupsFobs.universalConfigFields,
  codeExists: state.fobs.codeExists,
  errorMessage: state.fobs.errorMessage,
  showSignalRPopup: state.signalR.showSignalRPopup,
  message: state.signalR.message,
  errorPutFobsMessage: state.instalations.errorPutFobsMessage,
  productId: state.signalR.productId,
});

const IndividualInstalationInformationFormWithTranslation = withTranslation()(
  IndividualInstalationInformationForm
);
const IndividualInstalationInformationFormWithRouter = withRouter(
  IndividualInstalationInformationFormWithTranslation
);

export default connect(mapStateToProps, {
  getFamilyListByType,
  getSecondaryInstalationGroups,
  putInstalationGroup,
  postInstalationGroup,
  getInstalationsSingleList,
  putMoveFobList,
  postCopyFobList,
  deleteFobList,
  deleteFob,
  resetInstalationFOBS,
  putFob,
  getFamiliesSingleList,
  importFobs,
  setManualGrowRedirectURL,
  resetManualGrowRedirectURL,
  setUniversalGroup,
  createUniversalGroup,
  putUniversalGroupsFob,
  getInstalationsFOBS,
  needReturnToFacilities,
  deleteAlarms,
  fobsGetCheckCodes,
  resetCheckedFobs,
  setNeedPopup,
  showPopUp,
  resetSignalRPopup,
  setProductId,
})(IndividualInstalationInformationFormWithRouter);
