import createFetchTypes from '../../../core/typeActions/types';

export const GET_GROUP_MIFARE_LIST = createFetchTypes('GET_GROUP_MIFARE_LIST');
export const DELETE_GROUP_MIFARE_LIST = createFetchTypes('DELETE_GROUP_MIFARE_LIST');
export const CREATE_GROUP_MIFARE = createFetchTypes('CREATE_GROUP_MIFARE');
export const UPDATE_GROUP_MIFARE = createFetchTypes('UPDATE_GROUP_MIFARE');
export const DELETE_GROUP_MIFARE = createFetchTypes('DELETE_GROUP_MIFARE');
export const MOVE_GROUP_MIFARE = createFetchTypes('MOVE_GROUP_MIFARE');
export const COPY_GROUP_MIFARE = createFetchTypes('COPY_GROUP_MIFARE');
export const IMPORT_GROUP_MIFARE = createFetchTypes('IMPORT_GROUP_MIFARE');
export const MIFARE_STORE = 'MIFARE_STORE';
