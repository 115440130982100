import { Modal } from '@jcm-technologies/uikit/dist/molecules/Modal';
import { useTranslation } from 'react-i18next';
import { Flex } from '@jcm-technologies/uikit/dist/atoms/Layout';
import { Button } from '@jcm-technologies/uikit/dist/atoms/Button';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import { useEffect, useState } from 'react';
import { getContrastColor } from '../../../../core/helpers/colorManager';
import { FormEditEvent } from '../../Form/EditEvent';

export function ModalScheduleEditEvent({
  data,
  isOpened,
  setIsOpened,
  action,
  isEditingHolidays = false,
}) {
  const { color1 } = useSelector((state) => state.tenants);
  const [dataEvent, setDataEvent] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (data) {
      setDataEvent({
        dayStart: moment(data.start).format('D') || 1,
        timeStart: moment(data.start).format('HH:mm') || 1,
        dayEnd: moment(data.end).format('D') || 1,
        timeEnd: moment(data.end).format('HH:mm') || 1,
      });
    }
  }, [data]);

  const handleChange = (name, value) => {
    setDataEvent((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    const start = moment({
      y: moment(data.start, 'DD/MM/YYYY').year(),
      M: moment(data.start, 'DD/MM/YYYY').month(),
      d: dataEvent.dayStart,
      h: dataEvent.timeStart?.split(':')[0],
      m: dataEvent.timeStart?.split(':')[1],
    }).toDate();
    const end = moment({
      y: moment(data.end, 'DD/MM/YYYY').year(),
      M: moment(data.end, 'DD/MM/YYYY').month(),
      d: dataEvent.dayEnd,
      h: dataEvent.timeEnd?.split(':')[0],
      m: dataEvent.timeEnd?.split(':')[1],
    }).toDate();

    const ms = moment(end, 'DD/MM/YYYY HH:mm:ss').diff(moment(start, 'DD/MM/YYYY HH:mm:ss'));
    const d = moment.duration(ms);
    const difference = Math.floor(d.asHours()) + moment.utc(ms).format(':mm:ss');

    action({ start, end, difference, event: data });
    setDataEvent(null);
    setIsOpened(false);
  };
  return (
    <Modal
      isOpen={isOpened}
      title={t('general.updateEntity', { entity: t('title.schedule') })}
      closeWithButton
      id='modal-schedule-edit-event'
      width={[300, 500, 664]}
      onModalClose={() => setIsOpened(false)}
    >
      <Modal.Content>
        <FormEditEvent
          isOpened={isOpened}
          handleChange={handleChange}
          dataEvent={dataEvent}
          isEditingHolidays={isEditingHolidays}
        />
      </Modal.Content>
      <Modal.Actions>
        <Flex alignItems='center' justifyContent='flex-end'>
          <Button
            backgroundColor={color1}
            marginRight={2}
            color={getContrastColor(color1)}
            action={() => {
              handleSubmit();
            }}
          >
            {t('general.save')}
          </Button>
          <Button
            sizeText='display14'
            backgroundColor='blackLight'
            id='cancel-button'
            action={() => {
              setDataEvent(null);
              setIsOpened(false);
            }}
          >
            {t('general.cancel')}
          </Button>
        </Flex>
      </Modal.Actions>
    </Modal>
  );
}

ModalScheduleEditEvent.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  isEditingHolidays: PropTypes.bool,
  setIsOpened: PropTypes.func.isRequired,
  data: PropTypes.shape({
    start: PropTypes.instanceOf(Date),
    end: PropTypes.instanceOf(Date),
  }).isRequired,
  action: PropTypes.func.isRequired,
};
