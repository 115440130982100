import { Flex } from '@jcm-technologies/uikit/dist/atoms/Layout';
import { Text } from '@jcm-technologies/uikit/dist/atoms/Typography';
import { Icon } from '@jcm-technologies/uikit/dist/atoms/Icon';
import { Button } from '@jcm-technologies/uikit/dist/atoms/Button';
import { getContrastColor } from '@jcm-technologies/uikit/dist/utils/color';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Modal } from '@jcm-technologies/uikit/dist/molecules/Modal';
import IconExclamationTriangle from '@jcm-technologies/uikit/dist/atoms/Icons/IconExclamationTriangle';
import { Checkbox } from '@jcm-technologies/uikit/dist/atoms/Checkbox';
import { rgpdLinkLanguages } from '../../Facility/Events/config';
import { useState, useEffect } from 'react';
import { ModalGeneric } from './ModalGeneric';

export const ModalRgpd = ({ action, close, isOpen, maxWidth }) => {
  const { t } = useTranslation();
  const { color1 } = useSelector((state) => state.tenants);
  const [acceptRgpd, setAcceptRgpd] = useState(true);
  const closeModal = () => {
    close();
    setAcceptRgpd(true);
  };
  const actionModal = () => {
    action();
    setAcceptRgpd(true);
  };

  const {
    user: { language: lang },
  } = useSelector((state) => state.user);

  return (
    <ModalGeneric isOpen={isOpen} maxWidth={maxWidth} onClose={closeModal}>
      <Modal.Content width='100%'>
        <Flex flexDirection='column' alignItems='center' justifyContent='center'>
          <Icon marginBottom={2} sizeIcon='display48'>
            <IconExclamationTriangle />
          </Icon>

          <Text fontWeight='bold' sizeText='display16'>
            {t('alert.rgpd')}
          </Text>
        </Flex>
      </Modal.Content>
      <Modal.Actions>
        <Flex alignItems='center' justifyContent='flex-end'>
          <Flex marginRight={3} alignItems='center' justifyContent='center'>
            <Checkbox id='accept-rgpd' action={() => setAcceptRgpd(!acceptRgpd)} />
            <Text id='accept-rgpd-text' fontWeight='bold' sizeText='display16'>
              <a href={rgpdLinkLanguages[lang]} target='_blank'>
                {t('general.confidentialitydeclaration')}
              </a>
            </Text>
          </Flex>
          <Button
            disabled={acceptRgpd}
            backgroundColor={color1}
            marginRight={2}
            color={getContrastColor(color1)}
            action={actionModal}
          >
            {t('general.accept')}
          </Button>

          <Button
            sizeText='display14'
            backgroundColor='blackLight'
            id='cancel-button'
            action={closeModal}
          >
            {t('general.cancel')}
          </Button>
        </Flex>
      </Modal.Actions>
    </ModalGeneric>
  );
};
