export default {
  enrollCode: {
    manualTab: 'Manuel',
    reservationsTab: 'Réserve',
    sequentialTab: 'Séquentiel',
    universalsTab: 'Universel',
    device: 'Dispositif',
    initialCode: 'Code initial',
    idCard: 'DNI',
    spot: 'Place',
    numberOfFobs: 'Nombre de clés',
    endCode: 'Code de fin',
    cantEnroll:
      'Pour inscrire de nouveaux codes, un appareil doit être choisi et les postes disponibles sont nécessaires',
    cancel: 'Annuler',
    accept: 'Accepter',
    ok: 'OK',
    forceCodeQuestion: "Voulez-vous forcer l'enregistrement de ce code?",
    codeAlreadyExist: 'Ce code existe déjà dans un autre groupe',
    notAsigned: 'Non attribué',
    reserved: 'Réservé',
  },
};
