import { Modal } from '@jcm-technologies/uikit/dist/molecules/Modal';
import { useTranslation } from 'react-i18next';
import { Flex } from '@jcm-technologies/uikit/dist/atoms/Layout';
import { Text } from '@jcm-technologies/uikit/dist/atoms/Typography';
import { Button } from '@jcm-technologies/uikit/dist/atoms/Button';
import { useSelector } from 'react-redux';
import { getContrastColor } from '../../../../core/helpers/colorManager';

export function ModalOwnerDelete({ data, isOpened, setIsOpened, action }) {
  const { color1 } = useSelector((state) => state.tenants);
  const { t } = useTranslation();
  return (
    <Modal
      isOpen={isOpened}
      title={t('general.deleteEntity.question', { entity: t('title.user') })}
      closeWithButton
      id='modal-delete-grpd-user'
      width={[300, 500, 500]}
      onModalClose={() => setIsOpened(null)}
    >
      <Modal.Content>
        <Flex justifyContent='space-between' alignItems='center'>
          <Text fontWeight='bold'>{t('form.email')}:</Text>
          <Text>{data?.email}</Text>
        </Flex>
      </Modal.Content>
      <Modal.Actions>
        <Flex alignItems='center' justifyContent='flex-end'>
          <Button
            backgroundColor={color1}
            marginRight={2}
            color={getContrastColor(color1)}
            action={() => action(data?.email)}
          >
            {t('general.delete')}
          </Button>
          <Button
            sizeText='display14'
            backgroundColor='blackLight'
            id='cancel-button'
            action={() => setIsOpened(null)}
          >
            {t('general.cancel')}
          </Button>
        </Flex>
      </Modal.Actions>
    </Modal>
  );
}
