import PathEntity from './PathEntity.js';

class A5KServices extends PathEntity {
  constructor() {
    super('A5kServices');
  }

  getSyncTimeFile = (minutesFromNow, facilityId, equipmentId) => {
    let endpoint = this.manufacturePath(
      `facilities/${facilityId}/equipments/${equipmentId}/drivers/${this.entityName}/getfile_synctime`
    );
    endpoint = this.addParameterToEndpoint(endpoint, 'minutesFromNow', minutesFromNow);
    return endpoint;
  };

  postChangePassword = (facilityId, equipmentId, oldpassword, newpassword, ic, cc) => {
    let endpoint = this.manufacturePath(
      `facilities/${facilityId}/equipments/${equipmentId}/drivers/${this.entityName}/changepassword`
    );
    endpoint = this.addParameterToEndpoint(endpoint, 'oldpassword', oldpassword);
    endpoint = this.addParameterToEndpoint(endpoint, 'newpassword', newpassword);
    endpoint = this.addParameterToEndpoint(endpoint, 'installerCode', ic);
    endpoint = this.addParameterToEndpoint(endpoint, 'clientCode', cc);
    return endpoint;
  };

  getOnlineDevices = (facilityId, equipmentId) =>
    `facilities/${facilityId}/equipments/${equipmentId}/drivers/${this.entityName}/getonlinedevices`;

  getRemoteComunications = (facilityId, equipmentId) => {
    const endpoint = this.manufacturePath(
      `facilities/${facilityId}/equipments/${equipmentId}/drivers/${this.entityName}/getcommands`
    );
    return endpoint;
  };

  postReadMemory = (facilityId, equipmentId) =>
    this.manufacturePath(
      `facilities/${facilityId}/equipments/${equipmentId}/drivers/${this.entityName}/cmd_readmemory`
    );

  postSetMemory = (facilityId, equipmentId, ic, cc) => {
    let endpoint = this.manufacturePath(
      `facilities/${facilityId}/equipments/${equipmentId}/drivers/${this.entityName}/cmd_setmemory`
    );
    endpoint = this.addParameterToEndpoint(endpoint, 'IC', ic);
    endpoint = this.addParameterToEndpoint(endpoint, 'CC', cc);
    return endpoint;
  };

  postReadEvents = (facilityId, equipmentId, ic, cc) => {
    let finalEndpoint = this.manufacturePath(
      `facilities/${facilityId}/equipments/${equipmentId}/drivers/${this.entityName}/cmd_readevents`
    );
    finalEndpoint = this.addParameterToEndpoint(finalEndpoint, 'installerCode', ic);
    finalEndpoint = this.addParameterToEndpoint(finalEndpoint, 'clientCode', cc);
    return finalEndpoint;
  };

  postFormatEvents = (facilityId, equipmentId) =>
    this.manufacturePath(
      `facilities/${facilityId}/equipments/${equipmentId}/drivers/${this.entityName}/cmd_formatevents`
    );

  postGetApnConfigFile = (facilityId, equipmentId) =>
    this.manufacturePath(
      `facilities/${facilityId}/equipments/${equipmentId}/drivers/${this.entityName}/getfile_apnconfig`
    );

  getFileContent = (facilityId, equipmentId, cc, ic, index) => {
    let endpoint = this.manufacturePath(
      `facilities/${facilityId}/equipments/${equipmentId}/drivers/${this.entityName}/getfile_content`
    );
    endpoint = this.addParameterToEndpoint(endpoint, 'CC', cc);
    endpoint = this.addParameterToEndpoint(endpoint, 'IC', ic);
    endpoint = this.addParameterToEndpoint(endpoint, 'index', index);
    return endpoint;
  };

  getEmptyEvents = (facilityId, equipmentId, customerCode, installCode) => {
    let endpoint = this.manufacturePath(
      `facilities/${facilityId}/equipments/${equipmentId}/drivers/${this.entityName}/getfile_emptyevents`
    );
    endpoint = this.addParameterToEndpoint(endpoint, 'customerCode', customerCode);
    endpoint = this.addParameterToEndpoint(endpoint, 'installCode', installCode);
    return endpoint;
  };

  getEvents = (facilityId, equipmentId, index) => {
    let endpoint = this.manufacturePath(
      `facilities/${facilityId}/equipments/${equipmentId}/drivers/${this.entityName}/getevents`
    );
    if (index !== undefined) {
      endpoint = this.addParameterToEndpoint(endpoint, 'index', index);
    }
    return endpoint;
  };

  postCompareFobs = (facilityId, id, cc, ic) => {
    let endpoint = this.manufacturePath(
      `facilities/${facilityId}/equipments/${id}/drivers/${this.entityName}/compareFobs`
    );
    endpoint = this.addParameterToEndpoint(endpoint, 'clientCode', ic);
    endpoint = this.addParameterToEndpoint(endpoint, 'installerCode', cc);
    return endpoint;
  };

  getPaths = () => ({
    a5KServicesGetTimeFile: this.getSyncTimeFile,
    a5KservicesRemoteComunicationsGet: this.getRemoteComunications,
    a5kPostChangePassword: this.postChangePassword,
    a5KservicesReadMemoryPost: this.postReadMemory,
    a5KservicesSetMemoryPost: this.postSetMemory,
    a5KservicesReadEventsPost: this.postReadEvents,
    a5KservicesFormatEventsPost: this.postFormatEvents,
    a5kGetEmptyEvents: this.getEmptyEvents,
    a5kGetEvents: this.getEvents,
    a5kPostGetApnConfigFile: this.postGetApnConfigFile,
    a5kGetOnlineDevices: this.getOnlineDevices,
    a5kGetFileContent: this.getFileContent,
    a5kPostCompareFobs: this.postCompareFobs,
  });
}

export default new A5KServices().getPaths();
