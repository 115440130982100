import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BoxHistory from '../../../components/Facility/Group/HistoryFob/Box';
import { getGroupFobHistory } from '../../../modules/old_to_refact/actions/instalationGroups';
import { getUniversalGroup } from '../../../modules/old_to_refact/actions/universalGroups';
import { resetGroupFobHistory } from '../../../modules/old_to_refact/actions/groups';

const UniversalGroupFobView = () => {
  const { idFob, id } = useParams();
  const { groupFobHistory } = useSelector((state) => state.instalationGroups);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUniversalGroup(id));
    dispatch(getGroupFobHistory('000', id, idFob));
    return () => dispatch(resetGroupFobHistory());
  }, []);
  return <BoxHistory item={groupFobHistory} />;
};

export default UniversalGroupFobView;
