export default {
  remoteComunications: {
    index: 'Indice',
    content: 'Soddisfare',
    serialNumber: 'Numero di serie',
    uniqueKey: 'Chiave unica',
    operationsCode: 'Oper. codice',
    finalCode: 'Codice fine',
    progress: 'Progresso',
    status: 'Stato',
    refresh: 'ricaricare',
    saveConfig: 'Salva configurazione',
    readConfig: 'Leggi la configurazione',
    readEvents: 'Leggi gli eventi',
    removeEvents: 'Rimuovi eventi',
    seeEvents: 'Vedi gli eventi',
    export: 'Esportare',
    Completed: 'Completato',
    Received: 'ricevuto',
    Processing: 'in lavorazione',
  },
};
