import accounts from './apiPaths/accounts';
import alarms from './apiPaths/alarms';
import customers from '../../../modules/customers/routes';
import facilities from './apiPaths/facilities';
import facilityGroups from './apiPaths/facilityGroups';
import families from '../../../modules/productFamilies/routes';
import countries from '../../../modules/countries/routes';
import invitations from '../../../modules/invitations/routes';
import products from './apiPaths/products';
import schedules from '../../../modules/schedule/routes';
import tenants from '../../../modules/tenants/routes';
import universalGroups from './apiPaths/universalGroups';
import user from '../../../modules/user/routes';
import fobs from '../../../modules/group/fobs/routes';
import universalGroupFobs from './apiPaths/universalGroupsFobs';
import relays from './apiPaths/relays';
import equipments from './apiPaths/equipments';
import owners from '../../../modules/owner/routes';
import a5kServices from './apiPaths/a5KServices';
import neightbours from './apiPaths/neighbours';
import drivers from './apiPaths/drivers';
import globalSearch from '../../../modules/globalSearch/routes';
import mifare from '../../../modules/group/mifare/routes';
import groups from './apiPaths/groups';
import devices from './apiPaths/devices';
import groupUsers from '../../../modules/group/users/routes';
import notifications from '../../../modules/notifications/router';
import dashboard  from '../../../modules/dashboard/routes';
import statistics from '../../../modules/statistics/routes';
import holidays from '../../../modules/holidays/routes';

export default {
  ...alarms,
  ...neightbours,
  ...accounts,
  ...customers,
  ...countries,
  ...facilities,
  ...facilityGroups,
  ...families,
  ...invitations,
  ...products,
  ...schedules,
  ...tenants,
  ...universalGroups,
  ...universalGroupFobs,
  ...user,
  ...fobs,
  ...relays,
  ...equipments,
  ...owners,
  ...a5kServices,
  ...drivers,
  ...globalSearch,
  ...groups,
  ...devices,
  ...groupUsers,
  ...dashboard,
  ...statistics,
  ...notifications,
  ...holidays,
  ...mifare,
};
