import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from '@jcm-technologies/uikit/dist/molecules/Card';
import { Flex } from '@jcm-technologies/uikit/dist/atoms/Layout';
import { Text } from '@jcm-technologies/uikit/dist/atoms/Typography';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

const HonoaUsers = ({ honoaUsersCount, honoaLicenses }) => {
  const { t } = useTranslation();
  const tenants = useSelector((state) => state.tenants);

  return (
    <Card
      rounded='soft'
      width='100%'
      height='100%'
      maxWidth='100%'
      position='relative'
      marginBottom={2}
      display='flex'
      id='honoa-card'
    >
      <Flex width='100%' flexDirection='column'>
        <Flex alignItems='center'>
          <Text id='honoa-title'>{t('title.honoaUsers')}</Text>
        </Flex>
        <Flex
          justifyContent='center'
          alignItems='center'
          flex={1}
          flexDirection='column'
          marginTop={3}
        >
          <Text id='honoaUsersCount-text' sizeText='display14'>
            {t('dashboard.usersHonoaActive')}
          </Text>
          <Text id='honoaUsersCount' color={tenants?.color1 || 'orange'} sizeText='display48'>
            {honoaUsersCount}
          </Text>
          <Text id='honoaLicenses-text' sizeText='display14'>
            {t('licenses.availableLicenses')}
          </Text>
          <Text id='honoaLicenses' color={tenants?.color1 || 'orange'} sizeText='display48'>
            {honoaLicenses}
          </Text>
        </Flex>
      </Flex>
    </Card>
  );
};

export default HonoaUsers;

HonoaUsers.propTypes = {
  honoaUsersCount: PropTypes.number,
  honoaLicenses: PropTypes.number,
};
