export default {
  parameters: {
    title: 'Proxy Access 5k parameters setting',
    pinCode: 'Pin Code',
    apn: 'APN',
    apnUser: 'APN User',
    apnPassword: 'APN Password',
    serverURL: 'URL Server',
    editproxy: 'Edit A5K Proxy',
    titleRelays: 'Programming Relays',
    errorRelaysPopUp: 'Error: You cant exceed the maximum number of relays',
    CONFIGRELAYSNEW1_ICON: 'Edit relay 1',
    CONFIGRELAYSNEW2_ICON: 'Edit relay 2',
    CONFIGRELAYSNEW3_ICON: 'Edit relay 3',
    CONFIGRELAYSNEW4_ICON: 'Edit relay 4',
    ACTIVEEVENTSTOSD_ICON: 'Edit active events',
    A5KPROXY_ICON: 'Edit A5K proxy',
  },
};
