import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@jcm-technologies/uikit/dist/atoms/Layout';
import {
  getCustomerRoles,
  getCustomersLicenses,
  updateCustomerRoles,
  updateCustomersCostumer,
} from '../../../modules/customers/actions';
import { CardsLicenses } from '../../../components/Administration/Licenses/Cards';
import { TableLicenses } from '../../../components/Administration/Licenses/Table';
import {
  calculateTotalString,
  statusMapper,
} from '../../../components/Administration/Licenses/config';
import { deleteInvitation, postResendInvitation } from '../../../modules/invitations/actions';
import { DeleteInvitationLicensesModal } from '../../../components/Administration/Licenses/Modal/DeleteInvitationLicenses';
import { EditLicensesModal } from '../../../components/Administration/Licenses/Modal/EditLicenses';
import { getUserRoles, putUserPermissions } from '../../../modules/user/actions';
import { SaveActionModal } from '../../../components/Administration/Employees/Modal/SaveAction';
import { showAlert } from '../../../modules/state/alerts';
import { SaveModal } from '../../../components/common/Modals/SaveModal';
import { useTranslation } from 'react-i18next';

export const List = () => {
  const dispatch = useDispatch();
  const { results, licenses } = useSelector((state) => state.customers.licenses);
  const { userRoles } = useSelector((state) => state.user);
  const { customerEditableRoles } = useSelector((state) => state.customers);
  const [isOpenedDeleteModal, setIsOpenedDeleteModal] = useState(false);
  const [idToDelete, setIdToDelete] = useState(null);
  const [isOpenedEditModal, setIsOpenedEditModal] = useState(false);
  const [dataToEdit, setDataToEdit] = useState(null);
  const [canEditRole, setCanEditRole] = useState(true);
  const [isOpenedChangeRoleModal, setIsOpenedModal] = useState(false);
  const [actionToSave, setActionToSave] = useState('');
  const [actionToGet, setActionToGet] = useState('');
  const [dataToSave, setDataToSave] = useState('');
  const [dataToGet, setDataToGet] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getCustomersLicenses());
    dispatch(getUserRoles());
  }, []);
  const resendInvitationLicense = (id) => {
    dispatch(postResendInvitation(id));
  };

  const deleteInvitationLicense = () => {
    dispatch(deleteInvitation(idToDelete));
    setIsOpenedDeleteModal(false);
    setIdToDelete(null);
  };

  const editLicense = (id, data) => {
    dispatch(updateCustomersCostumer({ id, data }));
    setIsOpenedEditModal(false);
    setDataToEdit(null);
  };
  useEffect(() => {
    controlCloudALicenses();
  }, [licenses]);

  const controlCloudALicenses = () => {
    const cloudALicense = licenses?.find((el) => el.value === 'cloudAssistant');
    cloudALicense?.availableLicenses <= 0 ? setCanEditRole(false) : setCanEditRole(true);
  };

  const displayToastError = (message) => {
    dispatch(showAlert('red', 1, message));
  };

  const resetStateData = () => {
    setIsOpenedModal(false);
    setDataToSave('');
    setActionToSave('');
  };

  useEffect(() => {
    if (actionToGet) {
      handleGetters();
    }
  }, [actionToGet]);

  const resetGetters = () => {
    setDataToGet('');
    setActionToGet('');
  };

  const actionToSaveMapper = {
    role: () => {
      const saveData = {
        customerId: dataToSave.user.id,
        newRoleMask: dataToSave.role.mask,
      };
      dispatch(updateCustomerRoles(saveData));
      resetStateData();
    },
  };

  const actionToGetMapper = {
    getroles: () => {
      dispatch(getCustomerRoles(dataToGet.roleMask));
      resetGetters();
    },
  };
  const handleSave = () => actionToSaveMapper[actionToSave]();
  const handleGetters = () => actionToGetMapper[actionToGet]();

  return (
    <Box height='100%' maxWidth='100%'>
      <CardsLicenses />
      {results?.length && userRoles?.length ? (
        <>
          <TableLicenses
            handlerSendInvitationLicense={resendInvitationLicense}
            setIsOpenedDeleteModal={setIsOpenedDeleteModal}
            setIdToDelete={setIdToDelete}
            setIsOpenedEditModal={setIsOpenedEditModal}
            setDataToEdit={setDataToEdit}
            canEditRole={canEditRole}
            displayToastError={displayToastError}
            data={
              results?.map((el) => ({
                ...el,
                status: statusMapper[el.status],
                caAvailableTotal: calculateTotalString(el.caAvailableLicenses, el.caTotalLicenses),
                honoaAvailableTotal: calculateTotalString(
                  el.honoaAvailableLicenses,
                  el.honoaTotalLicenses
                ),
              })) || []
            }
            customerRoles={customerEditableRoles}
            setIsOpenedModal={setIsOpenedModal}
            setDataToSave={setDataToSave}
            setActionToSave={setActionToSave}
            setActionToGet={setActionToGet}
            setDataToGet={setDataToGet}
          />
          <SaveModal
            handleSave={handleSave}
            isOpened={isOpenedChangeRoleModal}
            setIsOpened={setIsOpenedModal}
            text={t('alert.consumeLicense')}
          />
        </>
      ) : (
        ''
      )}
      <EditLicensesModal
        isOpenedEditModal={isOpenedEditModal}
        setIsOpenedEditModal={setIsOpenedEditModal}
        setDataToEdit={setDataToEdit}
        handleEditLicense={editLicense}
        dataToEdit={dataToEdit}
      />
      <DeleteInvitationLicensesModal
        isOpenedDeleteModal={isOpenedDeleteModal}
        setIsOpenedDeleteModal={setIsOpenedDeleteModal}
        setIdToDelete={setIdToDelete}
        deleteInvitationLicense={deleteInvitationLicense}
      />
    </Box>
  );
};
