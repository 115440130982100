export default {
  instalationGroups: {
    exportExcel: 'Descargar Excel',
    importExcel: 'Importar Excel',
    exportPDF: 'Descargar PDF',
    identification: 'DNI',
    place: 'Plaza',
    enrollFOB: 'Alta de código',
    config: 'Configurar',
    deleteConfirmation: '¿Seguro que desea eliminar este grupo?',
    description: 'Descripción',
    facilityName: 'Nombre de la instalación',
    replace: 'Sustituir',
    delete: 'Borrar',
    deleteFobConfirmation: '¿Seguro que desea eliminar este emisor?',
    alarms: 'Alarma',
    selectConfigFields: 'Seleccione los CAMPOS DE USUARIO / EMISORES a exportar',
    totalSelected: 'Total seleccionados',
  },
};
