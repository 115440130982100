import PathEntity from './PathEntity.js';
import { apiVersion, mainPath } from '../common';

class Products extends PathEntity {
  constructor() {
    super('products');
  }

  get = (familyId) => {
    this.mainPath = `${mainPath()}4.1`;
    const endpoint = this.manufacturePath(`${this.entityName}?FamilyId=${familyId}`);
    this.mainPath = `${mainPath()}${apiVersion}`;
    return endpoint;
  };

  getSingleList = (type, extended) => {
    let finalEndPoint = this.manufacturePath(`${this.entityName}/singlelist`);
    if (type !== undefined) {
      finalEndPoint = this.addParameterToEndpoint(finalEndPoint, 'type', type);
    }
    if (extended !== undefined) {
      finalEndPoint = this.addParameterToEndpoint(finalEndPoint, 'extended', extended);
    }
    return finalEndPoint;
  };

  getPaths = () => ({
    productsGet: this.get,
    productsGetSingleList: this.getSingleList,
  });
}

export default new Products().getPaths();
