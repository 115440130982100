export default {
  universalGroups: {
    description: 'La description',
    informationTab: 'Information',
    expand: 'Développer',
    collapse: 'Effondrer',
    delete: 'Supprimer',
    add: 'Ajouter un nouveau groupe universel',
    create: 'Créer un groupe universel',
    instalationsTab: 'Installations',
    fobsTab: 'Émetteurs',
    deleteConfirmation: 'Voulez-vous vraiment supprimer ce groupe?',
  },
};
