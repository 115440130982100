import { Modal } from '@jcm-technologies/uikit/dist/molecules/Modal';
import { Icon } from '@jcm-technologies/uikit/dist/atoms/Icon';
import { useTranslation } from 'react-i18next';
import { Flex } from '@jcm-technologies/uikit/dist/atoms/Layout';
import IconExclamationTriangle from '@jcm-technologies/uikit/dist/atoms/Icons/IconExclamationTriangle';
import { Text } from '@jcm-technologies/uikit/dist/atoms/Typography';
import { Button } from '@jcm-technologies/uikit/dist/atoms/Button';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { getContrastColor } from '../../../../../../core/helpers/colorManager';

export const ModalDeleteMultiMifare = ({
  count,
  isOpenModalDeleteMultiMifare,
  setIsOpenModalDeleteMultiMifare,
  onDeleteMultiMifare,
}) => {
  const { t } = useTranslation();
  const { color1 } = useSelector((state) => state.tenants);
  return (
    <Modal
      isOpen={isOpenModalDeleteMultiMifare}
      title={
        <Text color='red' fontWeight='bold'>
          {t('instalations.criticalDeleteTitle')}
        </Text>
      }
      closeWithButton
      id='modal-delete-mifare'
      width={[300, 500, 800]}
      onModalClose={() => setIsOpenModalDeleteMultiMifare(false)}
    >
      <Modal.Content>
        <Flex flexDirection='column' alignItems='center'>
          <Icon color='red' fontSize='80px !important'>
            <IconExclamationTriangle />
          </Icon>
          <Text fontWeight='bold' marginY={2} textAlign='center'>
            {t('instalations.firstAreYouSureCriticalDelete')}
            <Text as='span' color='red' sizeText='display24'>
              {count}
            </Text>
            {t('fobsPopUps.selectedItemsShow2')}
          </Text>
          <Text fontWeight='bold' marginY={2} textAlign='center'>
            {t('fobsPopUps.warningDeleteFobs')}
          </Text>
          <Text sizeText='display24' color='red' fontWeight='bold' textAlign='center'>
            {t('instalations.noGoBack')}
          </Text>
        </Flex>
      </Modal.Content>
      <Modal.Actions>
        <Flex alignItems='center' justifyContent='flex-end'>
          <Button
            backgroundColor={color1}
            marginRight={2}
            color={getContrastColor(color1)}
            action={() => onDeleteMultiMifare()}
          >
            {t('general.delete')}
          </Button>
          <Button
            sizeText='display14'
            backgroundColor='blackLight'
            id='cancel-button'
            action={() => () => setIsOpenModalDeleteMultiMifare(false)}
          >
            {t('general.cancel')}
          </Button>
        </Flex>
      </Modal.Actions>
    </Modal>
  );
};

ModalDeleteMultiMifare.propTypes = {
  onDeleteMultiMifare: PropTypes.func.isRequired,
  setIsOpenModalDeleteMultiMifare: PropTypes.func.isRequired,
  isOpenModalDeleteMultiMifare: PropTypes.bool.isRequired,
  count: PropTypes.number.isRequired,
};
