import React from 'react';
import { Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Text } from '@jcm-technologies/uikit/dist/atoms/Typography';
import i18next from 'i18next';
import { useSelector } from 'react-redux';
import { getDecodedUrl } from '../../core/old_common/utils/getUrlParams';
import { BreadCrumbsSeparatorStyled, BreadCrumbsStyled, LinkStyled } from './Styles';

const Breadcrumb = () => {
  const { t } = useTranslation();
  const tenants = useSelector((state) => state.tenants);
  const { schedule } = useSelector((state) => state.schedules);
  const { universalGroup } = useSelector((state) => state.universalGroups);
  const { facility } = useSelector((state) => state.instalations);
  const { equipments, equipment } = useSelector((state) => state.equipments);
  const { facilityGroups, groupFobHistory, facilityGroup } = useSelector(
    (state) => state.instalationGroups
  );

  const breadCrumbTranslationExists = (part) => {
    const translation = `title.${getDecodedUrl(part)}`;
    const oldTranslation = `breadcrumb.${getDecodedUrl(part)}`;
    if (i18next.exists(translation)) {
      return t(translation.replace(/%26/g, '&'));
    }
    if (i18next.exists(oldTranslation)) {
      return t(oldTranslation.replace(/%26/g, '&'));
    }
    return getDecodedUrl(part?.replace(/%26/g, '&').split('&include')[0]);
  };
  const crumb = (part, partIndex, parts, id, noLink) => {
    const path = ['', ...parts?.slice(0, partIndex + 1)];
    if (id) path.splice(partIndex + 1, 1, id);
    const style = !noLink ? (
      <LinkStyled tenants={tenants} key={path} to={path.join('/')}>
        {breadCrumbTranslationExists(part)}
      </LinkStyled>
    ) : (
      <Text as='span' color='grey'>
        {breadCrumbTranslationExists(part)}
      </Text>
    );
    return style;
  };

  const Breadcrumbs = () => (
    <Route
      path='*'
      render={() => {
        let parts = document.location.pathname.split('/');
        const place = parts[parts.length - 1];
        parts = parts?.slice(1, parts.length - 1);
        if (parts[0]?.includes('schedules')) {
          return (
            <Text sizeText='display16' color='blackLight'>
              {crumb(parts[0], 0, parts)}
              <BreadCrumbsSeparatorStyled>{' / '}</BreadCrumbsSeparatorStyled>
              {breadCrumbTranslationExists(schedule?.name)}
            </Text>
          );
        }
        if (parts[0]?.includes('UniversalGroups') || parts[0]?.includes('universalGroups')) {
          return (
            <Text sizeText='display16' color='blackLight'>
              {parts?.length > 1 ? (
                <>
                  {parts[2]?.includes('fobs') || parts[2]?.includes('Equipments') ? (
                    <>
                      {parts.map((part, index) => (
                        <span key={part || index}>
                          {index === 1 ? (
                            <>{breadCrumbTranslationExists(universalGroup?.name)}</>
                          ) : (
                            <> {crumb(part, index, parts)}</>
                          )}

                          <BreadCrumbsSeparatorStyled>{' / '}</BreadCrumbsSeparatorStyled>
                        </span>
                      ))}
                      {breadCrumbTranslationExists(
                        groupFobHistory?.name || facility?.name || place
                      )}
                    </>
                  ) : (
                    <>
                      {parts.map((part, index) => (
                        <span key={part || index}>
                          {index === 1 ? (
                            <>{breadCrumbTranslationExists(universalGroup?.name)}</>
                          ) : (
                            <> {crumb(part, index, parts)}</>
                          )}

                          <BreadCrumbsSeparatorStyled>{' / '}</BreadCrumbsSeparatorStyled>
                        </span>
                      ))}
                      {breadCrumbTranslationExists(place)}
                    </>
                  )}
                </>
              ) : (
                <>
                  {crumb(parts[0], 0, parts)}
                  <BreadCrumbsSeparatorStyled>{' / '}</BreadCrumbsSeparatorStyled>
                  {breadCrumbTranslationExists(universalGroup?.name)}
                </>
              )}
            </Text>
          );
        }
        if (parts[4]?.includes('fobs')) {
          const nameOfFob = groupFobHistory?.name;
          return (
            <Text sizeText='display16' color='blackLight'>
              {parts.map((part, index) => {
                const nameOfGroupByIdPart = part;
                const idOfGroupByIdPart =
                  encodeURIComponent(facilityGroup?.name) === part
                    ? facilityGroup?.id
                    : encodeURIComponent(facilityGroup?.name) === part
                    ? facilityGroup.id
                    : null;
                const path = ['', ...parts?.slice(0, index + 1)];
                if (
                  (parts[0].includes('Facilities') || parts[0].includes('facilities')) &&
                  index === 1
                ) {
                  return (
                    <>
                      <LinkStyled tenants={tenants} key={facility?.name} to={path.join('/')}>
                        {breadCrumbTranslationExists(facility?.name)}
                      </LinkStyled>
                      <BreadCrumbsSeparatorStyled>{' / '}</BreadCrumbsSeparatorStyled>
                    </>
                  );
                }
                if ((parts[2].includes('Groups') || parts[2].includes('groups')) && index === 3) {
                  return (
                    <>
                      <LinkStyled
                        tenants={tenants}
                        key={groupFobHistory?.groupName}
                        to={path.join('/')}
                      >
                        {breadCrumbTranslationExists(groupFobHistory?.groupName)}
                      </LinkStyled>
                      <BreadCrumbsSeparatorStyled>{' / '}</BreadCrumbsSeparatorStyled>
                    </>
                  );
                }

                return (
                  <span key={part || index}>
                    {crumb(nameOfGroupByIdPart, index, parts, idOfGroupByIdPart)}
                    <BreadCrumbsSeparatorStyled>{' / '}</BreadCrumbsSeparatorStyled>
                  </span>
                );
              })}
              {breadCrumbTranslationExists(nameOfFob)}
            </Text>
          );
        }
        if (parts[2]?.includes('Groups') || parts[2]?.includes('groups')) {
          const nameOfGroup = facilityGroups?.find((el) => el.id === place)?.name;
          const nameOfGroupById =
            facilityGroup?.id === place
              ? facilityGroup?.name
              : facilityGroup?.id === place
              ? facilityGroup.name
              : place;
          return (
            <Text sizeText='display16' color='blackLight'>
              {parts.map((part, index) => {
                const nameOfGroupPart = facilityGroups?.find((el) => el.id === part)?.name;
                const nameOfGroupByIdPart = facilityGroup?.id === part ? facilityGroup?.name : part;

                const idOfGroupByIdPart =
                  encodeURIComponent(facilityGroup?.name) === part
                    ? facilityGroup?.id
                    : encodeURIComponent(facilityGroup?.name) === part
                    ? facilityGroup.id
                    : null;
                const path = ['', ...parts?.slice(0, index + 1)];
                if (
                  (parts[0].includes('Facilities') || parts[0].includes('facilities')) &&
                  index === 1
                ) {
                  return (
                    <>
                      <LinkStyled tenants={tenants} key={path} to={path.join('/')}>
                        {breadCrumbTranslationExists(facility?.name)}
                      </LinkStyled>
                      <BreadCrumbsSeparatorStyled>{' / '}</BreadCrumbsSeparatorStyled>
                    </>
                  );
                }

                return (
                  <span key={part || index}>
                    {crumb(nameOfGroupPart || nameOfGroupByIdPart, index, parts, idOfGroupByIdPart)}
                    <BreadCrumbsSeparatorStyled>{' / '}</BreadCrumbsSeparatorStyled>
                  </span>
                );
              })}
              {breadCrumbTranslationExists(nameOfGroup || nameOfGroupById)}
            </Text>
          );
        }
        if (parts[2]?.includes('Equipments')) {
          const nameOfGroup = facilityGroups?.find((el) => el.id === place)?.name;
          const nameOfGroupById =
            facilityGroup?.id === place
              ? facilityGroup?.name
              : facilityGroup?.id === place
              ? facilityGroup.name
              : place;
          return (
            <Text sizeText='display16' color='blackLight'>
              {parts.map((part, index) => {
                const nameOfGroupPart = equipments?.find((el) => el.id === part)?.name;
                const nameOfGroupByIdPart = equipment?.id === part ? equipment?.name : part;

                const idOfGroupByIdPart =
                  encodeURIComponent(equipment?.name) === part
                    ? equipment?.id
                    : encodeURIComponent(equipment?.name) === part
                    ? equipment.id
                    : null;
                const path = ['', ...parts?.slice(0, index + 1)];
                if (
                  (parts[0].includes('Facilities') || parts[0].includes('facilities')) &&
                  index === 1
                ) {
                  return (
                    <>
                      <LinkStyled tenants={tenants} key={path} to={path.join('/')}>
                        {breadCrumbTranslationExists(facility?.name)}
                      </LinkStyled>
                      <BreadCrumbsSeparatorStyled>{' / '}</BreadCrumbsSeparatorStyled>
                    </>
                  );
                }

                return (
                  <span key={part || index}>
                    {crumb(nameOfGroupPart || nameOfGroupByIdPart, index, parts, idOfGroupByIdPart)}
                    <BreadCrumbsSeparatorStyled>{' / '}</BreadCrumbsSeparatorStyled>
                  </span>
                );
              })}
              {breadCrumbTranslationExists(nameOfGroup || nameOfGroupById)}
            </Text>
          );
        }
        if (parts[0]?.includes('Facilities') || parts[0]?.includes('facilities')) {
          return (
            <Text sizeText='display16' color='blackLight'>
              {parts?.length > 1 ? (
                <>
                  {parts.map((part, index) => {
                    const path = ['', ...parts?.slice(0, index + 1)];
                    return (
                      <span key={part || index}>
                        {index === 1 ? (
                          <LinkStyled tenants={tenants} key={path} to={path.join('/')}>
                            {breadCrumbTranslationExists(facility?.name)}
                          </LinkStyled>
                        ) : (
                          <> {crumb(part, index, parts)}</>
                        )}

                        <BreadCrumbsSeparatorStyled>{' / '}</BreadCrumbsSeparatorStyled>
                      </span>
                    );
                  })}
                  {breadCrumbTranslationExists(place)}
                </>
              ) : (
                <>
                  {crumb(parts[0], 0, parts)}
                  <BreadCrumbsSeparatorStyled>{' / '}</BreadCrumbsSeparatorStyled>
                  {breadCrumbTranslationExists(facility?.name)}
                </>
              )}
            </Text>
          );
        }
        if (parts[0]?.includes('user-config') || !parts) {
          return (
            <Text sizeText='display16' color='blackLight'>
              {parts.map((part, index) => (
                <span key={part || index}>
                  {crumb(part, index, parts, null, true)}
                  <BreadCrumbsSeparatorStyled>{' / '}</BreadCrumbsSeparatorStyled>
                </span>
              ))}
              {breadCrumbTranslationExists(place)}
            </Text>
          );
        }
        if (!parts[0]?.includes('user-config') || !parts) {
          return (
            <Text sizeText='display16' color='blackLight'>
              {parts.map((part, index) => (
                <span key={part || index}>
                  {crumb(part, index, parts)}
                  <BreadCrumbsSeparatorStyled>{' / '}</BreadCrumbsSeparatorStyled>
                </span>
              ))}
              {breadCrumbTranslationExists(place)}
            </Text>
          );
        }

        return (
          <Text sizeText='display16' color='blackLight'>
            {breadCrumbTranslationExists(place)}
          </Text>
        );
      }}
    />
  );

  return (
    <BreadCrumbsStyled>
      <Breadcrumbs
        schedule={schedule}
        universalGroup={universalGroup}
        facilityGroups={facilityGroups}
        facilityGroup={facilityGroup}
        facility={facility}
        equipment={equipment}
        equipments={equipments}
      />
    </BreadCrumbsStyled>
  );
};

export default Breadcrumb;
