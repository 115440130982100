export default {
  signalRError: {
    11050: 'Neočekávaná odezva',
    11051: 'Nesprávené zařízení',
    11052: 'Nesprávné kódování',
    11053: 'Zařízení používá instalační kód',
    11054: 'Zařízení nepoužívá instalační kód',
    11055: 'Pauza',
    11056: 'Assistant nepřipraven',
    11057: 'Assistant zaneprázdněn',
    11058: 'Nastala chyba',
    11059: 'Operace zamítnuta',
    11060: 'Nesprávná boot verze',
    11061: 'Stále v průběhu',
    11062: 'Nesprávný čítač',
    11063: 'Nesprávné sériové číslo',
    11064: 'Chyba konfigurace zařízení',
    11065: 'Neznámá akce',
    11066: 'Nesprávné zařízení',
    11067: 'Fatal error',
    11068: 'Špatné crc',
    11069: 'Neshoda hesla',
    connectionTimeout: 'Zkotroluj připojení pomocí assistant panelu',
    dontMoveTag:
      'Došlo k chybě. Operaci opakujte, aniž byste zařízení přesunuli mimo čtecí oblast.',
  },
};
