import React from 'react';
import i18next from 'i18next';
import { IconTooltip } from '@jcm-technologies/uikit/dist/molecules/IconTooltip';
import IconTimes from '@jcm-technologies/uikit/dist/atoms/Icons/IconTimes';
import IconCheck from '@jcm-technologies/uikit/dist/atoms/Icons/IconCheck';

export const versionVideoAssistant = {
  'cs-CZ': 'https://cdn.jcm-tech.com/video/CLOUD-assistant-install-drivers-en.mp4',
  'it-IT': 'https://cdn.jcm-tech.com/video/CLOUD-assistant-install-drivers-it.mp4',
  'fr-FR': 'https://cdn.jcm-tech.com/video/CLOUD-assistant-install-drivers-fr.mp4',
  'de-DE': 'https://cdn.jcm-tech.com/video/CLOUD-assistant-install-drivers-de.mp4',
  'en-GB': 'https://cdn.jcm-tech.com/video/CLOUD-assistant-install-drivers-en.mp4',
  'hu-HU': 'https://cdn.jcm-tech.com/video/CLOUD-assistant-install-drivers-en.mp4',
  'es-ES': 'https://cdn.jcm-tech.com/video/CLOUD-assistant-install-drivers-es.mp4',
  'ca-ES': 'https://cdn.jcm-tech.com/video/CLOUD-assistant-install-drivers-es.mp4',
  default: 'https://cdn.jcm-tech.com/video/CLOUD-assistant-install-drivers-en.mp4',
};

export const statusCheckIcon = {
  error: (
    <IconTooltip
      color='red'
      sizeIcon='display16'
      toolTipContent={i18next.t('assistant.checkError')}
    >
      <IconTimes />
    </IconTooltip>
  ),
  success: (
    <IconTooltip
      color='green'
      sizeIcon='display16'
      toolTipContent={i18next.t('assistant.checkSuccess')}
    >
      <IconCheck />
    </IconTooltip>
  ),
};
