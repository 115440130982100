export default {
  resetPassword: {
    email: 'E-mail',
    newPassword: 'Nueva contraseña',
    backToLogin: 'Volver a la página de login',
    mailReseted: 'Tu mail ha sido recuperado',
    passwordReset: 'Recuperar contraseña',
    send: 'Enviar',
  },
};
