import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button } from '@jcm-technologies/uikit/dist/atoms/Button';
import { Flex } from '@jcm-technologies/uikit/dist/atoms/Layout';
import GenericList from '../GenericList/GenericList';
import {
  getFacilityListByUniversalGroup,
  getVisibleDescendants,
  resetFacilities,
  resetUniversalGroupFacilities,
} from '../../modules/old_to_refact/actions/universalGroups';
import { getInstalations, setFacility } from '../../modules/old_to_refact/actions/instalations';
import { CreationPath_Equipments } from '../../core/old_common/constats/genericListPaths';
import InstalationGrow from '../InstalationGrow/InstalationGrow';
import SelectableFilters from '../SelectableFilters';
import {
  setSearchQuery,
  setSelectedDescendants,
} from '../../modules/state/visibleDescendants/actions';
import { getCookieUserRoles } from '../../core/old_common/utils/cookiesManager';
import { FACILITY_DESCENDANT } from '../../modules/state/visibleDescendants/constants';
import { UG_FACILITIES_LIST_SELECTION } from '../../modules/state/pagination/constants';

export class FacilitiesListUniversalGroups extends Component {
  UNSAFE_componentWillMount() {
    const { resetUniversalGroupFacilities } = this.props;
    resetUniversalGroupFacilities();
  }

  componentDidMount = () => {
    // this.props.resetFacilities();
    // this.props.getInstalations();
    this.getFacilitiesUG();
  };

  componentWillUnmount() {
    const { resetUniversalGroupFacilities } = this.props;
    resetUniversalGroupFacilities();
  }

  getFacilitiesUG = () => {
    const { getFacilityListByUniversalGroup, history, visibleDescendants, getVisibleDescendants } =
      this.props;
    if (!visibleDescendants) {
      getVisibleDescendants();
    }
    getFacilityListByUniversalGroup(history.location.state.universalGroupId, true, false);
  };

  onConfigClick = (event) => {
    this.props.setFacility(event.item);
  };

  handleSearchQueryChange = (e) => {
    const { setSearchQuery } = this.props;
    setSearchQuery(e.currentTarget.value || '');
  };

  getCustomStyles = () => {
    const { tenants } = this.props;
    const customStyles = {
      multiValue: (provided, state) => ({
        ...provided,
        background: tenants.color1,
        color: 'white',
      }),
      multiValueLabel: (provided, state) => ({
        ...provided,
        color: 'white',
      }),
    };
    return customStyles;
  };

  searchUniversals = () => {
    const { history, getFacilityListByUniversalGroup } = this.props;
    getFacilityListByUniversalGroup(history.location.state.universalGroupId, true, false);
  };

  getVisibleDescendants = () => {
    const { visibleDescendants, t } = this.props;
    let visibleDesc = [];
    if (visibleDescendants) {
      visibleDesc = visibleDescendants.map((vDes) => ({ label: vDes.name, value: vDes.id }));
      visibleDesc.unshift({
        label: t('globalSearch.allOwners'),
        value: 'ffffffff-ffff-ffff-ffff-ffffffffffff',
      });
    }
    return visibleDesc;
  };

  handleSelectionChange = (value) => {
    const { setSelectedDescendants, getUniversalGroups } = this.props;
    // filter type (universal group)
    setSelectedDescendants(FACILITY_DESCENDANT, value);
    this.getFacilitiesUG();
  };

  handleGoBack = () => {
    const { history } = this.props;
    history.goBack();
  };

  render() {
    const {
      t,
      baseURI,
      facilities,
      facilitiesTotalPages,
      searchQuery,
      history,
      selectedDescendants,
    } = this.props;
    const role = getCookieUserRoles();
    return (
      <>
        <SelectableFilters
          placeholder={t('general.searchDotted')}
          descendatsOptions={this.getVisibleDescendants()}
          selectedDescendants={selectedDescendants}
          onChangeDescendants={(e) => this.handleSelectionChange(e)}
          renderDescendants={!(role.mask === 8 || role.mask === 16)}
          onChangeSearchQuery={(e) => this.handleSearchQueryChange(e)}
          searchQuery={searchQuery}
          onSubmit={this.searchUniversals}
          renderSearch
          customStyles={this.getCustomStyles()}
        />
        <GenericList
          dataList={facilities}
          groupByProp='customerName'
          CustomGrow={(props) => (
            <InstalationGrow
              {...props}
              onConfigClick={this.onConfigClick}
              isConfigVisible
              customHeaderTitles={this.headerTitles}
              canExpand={false}
              canClone={false}
            />
          )}
          baseURI={baseURI}
          creationURI={CreationPath_Equipments}
          handlePageChange={this.getFacilitiesUG}
          listType={UG_FACILITIES_LIST_SELECTION}
          totalPages={facilitiesTotalPages}
          isPaginable
          getList={this.getFacilitiesUG}
        />
        <Flex justifyContent='flex-end' paddingY={1}>
          <Button
            backgroundColor='blackLight'
            sizeButton='small'
            onClick={() => this.handleGoBack()}
          >
            {t('general.back')}
          </Button>
        </Flex>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  tenants: state.tenants,
  facilities: state.universalGroups.facilities,
  facilitiesTotalPages: state.universalGroups.facilitiesTotalPages,
  universalGroups: state.universalGroups.universalGroups,
  universalGroupsInfo: state.universalGroups.universalGroupsInfo,
  searchQuery: state.visibleDescendants.searchQuery,
  selectedDescendants: state.visibleDescendants.selectedDescendants[FACILITY_DESCENDANT],
  visibleDescendants: state.universalGroups.visibleDescendants,
});

const FacilitiesListUniversalGroupsPage = withTranslation()(FacilitiesListUniversalGroups);
const FacilitiesListUniversalGroupsWithHistory = withRouter(FacilitiesListUniversalGroupsPage);

export default connect(mapStateToProps, {
  getFacilityListByUniversalGroup,
  getInstalations,
  resetFacilities,
  resetUniversalGroupFacilities,
  setSearchQuery,
  setFacility,
  getVisibleDescendants,
  setSelectedDescendants,
})(FacilitiesListUniversalGroupsWithHistory);
