export default {
  errors: {
    title: {
      honoa: 'HONOA Zobrazit stav dveří:',
    },
    1000: 'Chybné přihlášení',
    100: 'Uživatel přihlášen',
    101: 'Uživatel odhlášen',
    102: 'Heslo úspěšně změněno',
    103: 'Proces zapomenutého hesla dokončen',
    104: 'Proces resetování hesla dokončen',
    105: 'Poslat e-mail na:',
    106: 'Neplatný uživatel',
    107: 'Uživatel nebyl aktualizován',
    108: 'Pozvání vypršelo',
    109: 'Pozvání nebylo vymazáno',
    10: 'Zahájit konfiguraci CloudAssistant API',
    110: 'Uživatel nebyl potvrzen a aktualizován v IS4',
    111: 'Uživatel nebyl úspěšně registrován',
    112: 'ID uživatele a zákazníka není shodné',
    113: 'ID zákazníka neexistuje',
    114: 'Pozvání neodesláno',
    115: 'Pozvání bylo vymazáno',
    116: 'Redukce pozvánek dokončena',
    117: 'Zákaznícké vlastnosti nebyly vytvořeny správně',
    118: 'Nedostatečné oprávnění pro prohlížení těchto údajů',
    119: 'Nedostatečné oprávnění pro prohlížení nebo aktualizaci těchto údajů',
    11: 'Konfigurace dokončena. API připravena na příchozí hovory',
    120: 'Nedostatečné oprávnění pro vytvoření tohoto údaje',
    121: 'Nedostatečné oprávnění pro smazání tohoto údaje',
    122: 'Uživatel nebyl vymazán v identitě',
    123: 'Uživatel nenalezen',
    124: 'Konflikt při vymazání uživatele v identitě',
    125: 'Souběžná chyba',
    126: 'Default error',
    127: 'E-mail již existuje',
    128: 'Název funkce již existuje',
    129: 'Uživatelské jméno již existuje',
    12: 'Nastav verzi API',
    130: 'Chybný e-mail',
    131: 'Chybný název funkce',
    132: 'Chybný doklad',
    133: 'Chybné uživatelské jméno',
    134: 'Přihlášení je již ztotožněné',
    135: 'Heslo vyžaduje méně znaků',
    136: 'Heslo vyžaduje numerickou hodnotu',
    137: 'Heslo vyžaduje unikátní znak',
    138: 'Heslo vyžaduje více znaků',
    139: 'Heslo je příliš krátké',
    13: 'Celkové nastavení API',
    140: 'Znovuzískání kódu se nezdařilo',
    141: 'Uživatel již heslo má',
    142: 'Uživatel již funkci má',
    143: 'Uživatel bez funkce',
    144: 'Blokování uživatele není povoleno',
    145: 'Ověření se nezdařilo, chybné nebo skryté klientské ID',
    146: 'Chybný požadavek',
    147: 'E-mail nebo neslo nejsou správné',
    148: 'Chybný rozsah',
    149: 'Tento uživatel nemá oprávnění pro zadání tohoto požadavku',
    14: 'Nastavení SMTP',
    150: 'Nepodporový typ příspěvku',
    151: 'Uživatel není vymazán z identity',
    152: 'Konflikt při vymazání uživatele v identitě',
    153: 'Předplatné nebylo aktualizováno',
    154: 'Předplatné bylo aktualizováno',
    155: 'Chyba při udělení předplatného',
    156: 'Uživatel má univerzální skupiny nebo instalace s instalačním kódem',
    157: 'Neznámá chyba',
    158: 'Nedostatečné povolení',
    159: 'E-mail uživatele již existuje',
    15: 'Nastavení pravidel cookie',
    160: 'Nedostatečné předplatné',
    161: 'Funkce uživatele není platná',
    162: 'Funkce uživatele není aktualizována',
    163: 'Uživatel není zapsán nebo je bez popisu',
    164: 'Funkce uživatele nebo zakazníka není platná',
    165: 'Admin nemůže být aktualizován',
    166: 'Instalační kód není aktualizován',
    167: 'Skupina produktu není aktualizována',
    168: 'Produkty nejsou aktualizovány',
    169: 'Nenalazen žádný prvek s tímto ID',
    16: 'Nastav kompatibilitu prostředí:',
    170: 'Obnovit token',
    171: 'Nedostatečný popis',
    172: 'Zařízení nebylo nalezeno',
    17: 'Nastavení db kontext',
    18: 'Ověření služeb',
    19: 'Přidej podklady',
    200: 'Obdržet všechny seznamy uživatelů',
    300: 'Vadný požadavek nebo překročen max. počet položek na jeden požadavek',
    301: 'Aktualizace úspěšně dokončena',
    302: 'Chyba platnosti',
    400: 'Uživatel úspěšně uložen',
    401: 'Některá data uživatele chybí! Uložení neúspěšné',
    402: 'Vyžadována platba',
    404: 'Zařízení offline nebo selhání připojení.',
    90000: 'Vyžádán loopback test',
    90001: 'Vyžádána výjimka',
    90002: 'Byl vyžádán kód pro návrat stavového kódu',
    9000: 'Přihlášení  neúspěšné',
    9001: 'Vymazání hesla neúspěšné',
    9002: 'Zapomenutí hesla neúspěšné',
    9003: 'Změna hesla neúspěšná',
    9004: 'Heslo nesouhlasí',
    9005: 'Heslo musí obsahovat nejméně jednu číslici',
    9006: 'Neznámý uživatel',
    90100: 'Nezastižená SQL výjimka',
    90101: 'Vytvoření se nezdařilo',
    90102: 'Zadaný název již existuje, musíte ho změnit',
    90103: 'Nenalezeno',
    90104: 'Aktualizace se nezdařila',
    90105: 'Vymazání se nezdařilo',
    90106: 'Tento obsah již existuje',
    90107: 'Nemůže obsahovat nulovou hodnotu',
    90108: 'Import se nezdařil',
    90109: 'Export se nezdařil',
    90110: 'Nelze aktualizovat instalační kód u instalací s přenesenými ovladači',
    90111: 'ID instalace nebo URL není platné',
    90112: 'Subjektová skupina instalace nevytvořena',
    90113: 'Skupina instalace musí mít název',
    90114: 'Název subjektové skupiny instalace již v této instalaci existuje',
    90115: 'Subjektová skupina instalace nebyla aktualizována',
    90116: 'Subjektová skupina instalace nebyla vymazána',
    90117: 'Kuntakt subjektu nemůže mít nulovou hodnotu',
    90118: 'Subjektová skupina nebyla aktualizována',
    90119: 'Popis subjektové skupiny nebo kód již v této skupině existují',
    90120: 'Nelze vymazat kontakt s hodnotou 0',
    90121: 'Kontakt nebyl vymazán',
    90122: 'Kontakt nebyl vytvořen',
    90123: 'Subjekt již má 5 kontaktů',
    90124: 'Kontakt subjektu nebyl aktualizován',
    90125: 'ID skupiny pro tuto instalaci neexistuje',
    90126: 'ID instalace není platné',
    90127: 'Kontakt entity pro tuto instalaci není platný',
    90128: 'Instalační kód musí být stejný pro všechny instalace v univerzální skupině',
    90129: 'Instalační kód musí být stejný pro instalaci a univerzální skupinu',
    91000: 'VDP mac nebo ID nenalezeno',
    91001: 'Neplatný mac',
    91002: 'Emailová přípona nenalezena',
    91003: 'Chyba aktualizace vdp jména',
    91004: 'Chyba při vytvoření souvislosti vdp kontaktu',
    91005: 'Chyba při vytvoření kontaktu',
    91006: 'Chyba při mazání kontaktu',
    91007: 'Chyba při aktualizaci kontaktního jména',
    91008: 'Zákaznické jméno již existuje',
    91009: 'Byt nebo číslo dveří není platné',
    91010: 'Kód nebyl uveden',
    91011: 'Počet kanálů poškozen',
    91012: 'Poslední úkon relé nemůž být vymazán',
    91013: 'Není možné deaktivovat relé, protože má přiřazené skupiny',
    91014: 'Neplatná délka kódu',
    91015: 'Kódy musejí být stejného typu',
    91016: 'Nedostatečný počet pozic ve skupině',
    91017: 'Kód již v instalaci exituje',
    91018: 'Kód již v unniverzálních existuje',
    91019: 'Kód je náhrada vymazaného univerzálního',
    91020: 'Zařízení této instalace jsou již hotová',
    91021: 'Nic ke kopírování',
    91022: 'Nic k přesouvání',
    91023: 'Produkt není platný',
    91024: 'Časový rozvrh nenalezen',
    91025: 'Nelze vymazat časový rozvrh, který má přidělenou instalaci',
    91026: 'Požadovná skupina není univerzální',
    91027: 'Zařízení má některé vadné parametry',
    91028: 'Není možné vymazat univerzální skupinu, která má přidělenou istalaci',
    91029: 'Sériové číslo nebo unikátní klíč není vyžadován',
    91030: 'Sériové číslo nebo unikátní klíč nemůže mít hodnotu 0',
    91031: 'Klientský kód není správný',
    91032: 'Instalační kód není správný',
    91033: 'Heslo není správné',
    91034: 'Poškozený soubor',
    91065: 'Link již přidělen',
    91066: 'Nastala chyba během deaktivace linku',
    91067: 'Nesprávný aktivační klíč',
    91068: 'Neúspěšný požadavek API',
    91069: 'Produkt nepovolen',
    91070: 'Produkt nenalezen',
    91071: 'Nevybráno žádné relé. Použij konfigurační tlačítko',
    91072: 'Název relé již existuje',
    91073: 'Dosažen maximální počet instalací',
    91074: 'Tento aktivační klíč je používán jiným uživatelem',
    91100: 'Zařízení odpojeno',
    91101: 'Nezvládnutá výjimka načtení zařízení',
    91102: 'Výjimka při načtení paměti',
    91103: 'Výjimka při zápisu paměti',
    91104: 'Nesoulad při zápisu paměti',
    91105: 'Zařízení má instalační kód',
    91106: 'Zařízení nemá instalační kód',
    91201: 'Uživatel již existuje. Všechna data budou zděděna',
    91203: 'Uživatel již v tomto zařízení existuje',
    91203: 'Uživatel již v tomto zařízení existuje',
    91302: 'Informace SIM není dostupná',
    91303: 'Smlouva SIM není dostupná',
    91307: 'Nelze získat sériové číslo',
    91308: 'Aktivace klíče nula nebo prázdné',
    91309: 'Aktivační klíč null, prázdný nebo neplatný',
    91310: 'Toto sériové č. není přiřazeno uživateli',
    91311: 'Chyba při komunikaci s databázovými servery',
    91312: 'Chyba při generování licence',
    91400: 'Chyba při mazání alarmu',
    91400: 'Chyba při odstraňování alarmu',
    91450: 'Následující rozvrhy nemají platný rozsah: ',
    91451: 'Musí být zvolen alespoň jeden svátek',
    91452: 'Používaný přístroj nepodporuje časové plány se svátky',
    91452: 'Přístroj nepodporuje časové plány se svátky',
    91453: 'Název časového plánu již existuje',
    91527: 'Nelze odstranit provoz s přiřazenou univerzální skupinou.',
    91528: 'Nelze odstranit zařízení s přiřazenou skupinou.',
    91529: 'Není možné odstranit skupinu s ovladače.',
    91530:
      'Nelze zrušit přiřazení tohoto zařízení, protože zbývající zařízení nep odporuje ovladače',
    91531: 'Provoz nelze znovu přiřadit, protože používá univerzální skupiny',
    91532: 'Provoz obsahuje nekompatibilní zařízení',
    91534: 'Maximum dosažené přístroji Honoa pro tento provoz',
    91536: 'Název provozu již existuje',
    91537: 'Skupinu s uživateli Honoa nelze odstranit',
    91538: 'Chyba při stahování PDF',
    91539: 'Nelze deaktivovat zařízení, které se aktuálně používá v instalacích.',
    91540: 'Nelze zrušit přiřazenízařízení, protože skupina obsahuje uživatele aplikace HONOA',
    91800: 'Nepodporovaný formát telefonního čísla',
    91801: 'Nepodporovaný formát url webu',
    91802: 'Toto zařízení nepodporuje ovladače',
    91803: 'Tato skupina nepodporuje ovladače',
    91804: 'Název jednoho nebo více rozvrhůpřiřazených k tomuto zařízení je příliš dlouhý.',
    91853: 'Časové pásmo nemůže zůstat prázdné.',
    91854: 'Skupina nepodporuje uživatele aplikace HONOA',
    92030: 'Váš typ odběru není s touto funkcí  kompatibilní.',
    92031: 'Funkce nepovolena bez dorazů (senzorů/vstupů)',
    communicationError: 'Komunikační chyba, zkuste znovu později',
    deletedUser: 'Uživatel smazán',
    generic: 'Unikátní identifikace již použita',
    groupsAssigned: 'Přiřazené skupiny: ',
    name: 'Toto jméno není dostupné',
    nameRequired: 'Nelze uložit. Jména povinná v rodinách a produktech.',
    needsMinOnRelay: 'Musí být přiřazenoalespoň jedno relé',
    noContent: 'Žádný dostupný obsah pro tento list',
    operationNotCompleted: 'Operace nemohla být dokončena',
    redirectWarning: 'Byli jste přesměrováni na hlavní stránku',
    selectTypeFirst: 'Nejprve zvol typ',
    timeout: 'Čekací doba překročena.',
    unexpectedError: 'Neočekávaná chyba',
    uniqueIdentity: 'Tento obsah již existuje',
    universalGroupsAssigned: 'Přiděleny univerzální skupiny: ',
    zeroCode: 'Pozor! Pracuje se s instalačním kódem 0',
    92060: 'Datum zahájení nemůže následovat po koncovém datu.',
    92061: 'Datum zahájení musí následovat po dnešním datu.',
    91075: 'Není dostatek volných rezervací',
    304: 'Literal není platný (prázdné, přední nebo zadní bílé znaky, ...)',
  },
};
