import { Flex } from '@jcm-technologies/uikit/dist/atoms/Layout';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button } from '@jcm-technologies/uikit/dist/atoms/Button';
import { TabsMenu } from '@jcm-technologies/uikit/dist/molecules/TabsMenu';
import { useHistory, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getContrastColor } from '../../../../core/helpers/colorManager';
import { TabManual } from '../../../../components/Facility/Group/Mifare/Form/Manual';
import { createGroupMifare, setMifareStore } from '../../../../modules/group/mifare/actions';
import { getFamiliesSingleList } from '../../../../modules/productFamilies/action';
import {
  getInstalationGroups,
  getInstalationGroupsFobsCounts,
} from '../../../../modules/old_to_refact/actions/instalationGroups';
import {
  CREATE_TAB_DEFAULT,
  PRODUCT_FAMILY_TYPE,
} from '../../../../components/Facility/Group/Mifare/config';
import { TabSequential } from '../../../../components/Facility/Group/Mifare/Form/Sequential';
import validationsDictionary from '../../../../core/old_common/validations';
import { getErrors } from '../../../../core/old_common/validations/service';
import { error, type } from 'jquery';
import { element } from 'prop-types';

const FacilityGroupMifareCreate = () => {
  const { idGroup, idFacility } = useParams();
  const { fobsCount } = useSelector((state) => state.instalationGroups);
  const { familiesSingleList } = useSelector((state) => state.families);
  const { color1 } = useSelector((state) => state.tenants);
  const { isCreationSuccess } = useSelector((state) => state.mifare);
  const { t } = useTranslation();
  const history = useHistory();
  const [data, setData] = useState({
    codesAmount: 1,
    initialCode: '',
    name: '',
    surname: '',
    nic: '',
    plaza: '',
  });
  const [errorsForm, setErrosForm] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    if (!familiesSingleList[PRODUCT_FAMILY_TYPE]) {
      dispatch(getFamiliesSingleList(PRODUCT_FAMILY_TYPE, true));
    }
    if (!fobsCount) {
      dispatch(getInstalationGroupsFobsCounts(idFacility, idGroup));
    }
    //This is required to load the breadcrumbs url properly
    dispatch(getInstalationGroups(idFacility));
  }, []);

  useEffect(() => {
    if (isCreationSuccess) {
      dispatch(setMifareStore({ key: 'isCreationSuccess', value: false }));
      history.goBack();
    }
  }, [isCreationSuccess]);

  const onChangeData = (name, value) => {
    setData((prev) => ({
      ...prev,
      [name]: value,
    }));
    const valuevalidation = {
      [name]: value,
    };
    const validations = {
      codesAmount: [
        validationsDictionary.isRequired(Number(valuevalidation?.codesAmount)),
        validationsDictionary.isNumeric(Number(valuevalidation?.codesAmount)),
        validationsDictionary.isInRange(
          Number(valuevalidation?.codesAmount),
          1,
          fobsCount[0].counters[0].free
        ),
      ],
      initialCode: [
        validationsDictionary.isRequired(Number(valuevalidation?.initialCode)),
        validationsDictionary.isNumeric(Number(valuevalidation?.initialCode)),
        validationsDictionary.isInRange(Number(valuevalidation?.initialCode), 0, 4294967295),
      ],
      endCode: [
        validationsDictionary.isInRange(
          Number(valuevalidation?.endCode) + Number(valuevalidation?.endCode),
          0,
          4294967295
        ),
      ],
      name: [validationsDictionary.isInTextLength(valuevalidation?.name, 50)],
      surname: [validationsDictionary.isInTextLength(valuevalidation?.surname, 80)],
      nic: [validationsDictionary.isInTextLength(valuevalidation?.nic, 50)],
      plaza: [validationsDictionary.isNumeric(valuevalidation?.plaza)],
      productId: [validationsDictionary.isRequired(valuevalidation?.productId)],
    };

    if (validations.hasOwnProperty(name)) {
      setErrosForm((prev) => ({
        ...prev,
        [name]: getErrors(validations[name]) || false,
      }));
    }
  };
  const handleBlur = (name) => {};

  const handleSubmit = async () => {
    await onChangeData('productId', data?.productId);
    await onChangeData('initialCode', data?.initialCode);

    // This is declared because the api don't accept defined values without a value, also we need to define the values because react warn us to do it on console
    const parsedData = Object.fromEntries(
      Object.entries(data).filter((element) => element[1] !== '')
    );

    dispatch(createGroupMifare({ idFacility, idGroup, data: parsedData }));
  };
  return (
    <Flex
      backgroundColor='white'
      borderRadius={3}
      height='100%'
      minHeight='calc(100vh - 156px)'
      paddingTop={2}
      flexDirection='column'
      justifyContent='space-between'
    >
      <TabsMenu
        id='createMifareTabs'
        colorActive={getContrastColor(color1)}
        themeColor={color1}
        defaultSelected={CREATE_TAB_DEFAULT}
        components={{
          manual: (
            <TabManual
              onChangeData={onChangeData}
              data={data}
              errors={errorsForm}
              handleBlur={handleBlur}
            />
          ),
          sequential: (
            <TabSequential
              onChangeData={onChangeData}
              data={data}
              errors={errorsForm}
              handleBlur={handleBlur}
            />
          ),
        }}
        items={[
          {
            id: 'manual',
            title: <>{t('title.manual')}</>,
            action: () => {
              onChangeData('codesAmount', 1);
            },
          },
          {
            id: 'sequential',
            title: t('title.sequential'),
            action: () => {},
          },
        ]}
      />

      <Flex justifyContent='flex-end' alignItems='flex-end' padding={2} paddingX={4}>
        <Button
          color={getContrastColor(color1)}
          disabled={
            Object.entries(errorsForm).filter(
              (element) => element[1].error === true || element === true
            ).length
          }
          background={color1}
          marginRight={1}
          onClick={() => handleSubmit()}
        >
          {t('general.save')}
        </Button>
        <Button
          backgroundColor='blackLight'
          onClick={() => history.push(`/facilities/${idFacility}/groups/${idGroup}/wiegand`)}
        >
          {t('general.back')}
        </Button>
      </Flex>
    </Flex>
  );
};
export default FacilityGroupMifareCreate;
