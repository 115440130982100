import PathEntity from '../../../core/old_common/rooting/apiPaths/PathEntity';
import { apiVersion, mainPath } from '../../../core/old_common/rooting/common';

class Routes extends PathEntity {
  getUsersList = (facilityId, groupId) =>
    this.manufacturePath(`facilities/${facilityId}/groups/${groupId}/users`);

  getUsersCounters = (facilityId, groupId) => {
    this.mainPath = `${mainPath()}4.2`;
    const endpoint = this.manufacturePath(
      `facilities/${facilityId}/groups/${groupId}/users/groupcounters`
    );
    this.mainPath = `${mainPath()}${apiVersion}`;
    return endpoint;
  };

  getUsersAvailableGroups = (facilityId, groupId, exceptme) => {
    let finalEndpoint = this.manufacturePath(
      `facilities/${facilityId}/groups/${groupId}/users/availableGroups`
    );
    finalEndpoint = this.addParameterToEndpoint(finalEndpoint, 'exceptme', exceptme);
    return finalEndpoint;
  };

  getUsersAvailableFacilities = (facilityId, groupId, exceptme) => {
    let finalEndpoint = this.manufacturePath(
      `facilities/${facilityId}/groups/${groupId}/users/availableFacilities`
    );
    finalEndpoint = this.addParameterToEndpoint(finalEndpoint, 'exceptme', exceptme);
    return finalEndpoint;
  };

  postUserGroup = (facilityId, groupId) => {
    this.mainPath = `${mainPath()}4.2`;
    const endpoint = this.manufacturePath(`facilities/${facilityId}/groups/${groupId}/user`);
    this.mainPath = `${mainPath()}${apiVersion}`;
    return endpoint;
  };

  deleteUserGroup = (facilityId, groupId, userId) => {
    this.mainPath = `${mainPath()}4.2`;
    const endpoint = this.manufacturePath(
      `facilities/${facilityId}/groups/${groupId}/users/${userId}`
    );
    this.mainPath = `${mainPath()}${apiVersion}`;
    return endpoint;
  };

  deleteUserGroupList = (facilityId, groupId) => {
    this.mainPath = `${mainPath()}4.2`;
    const endpoint = this.manufacturePath(`facilities/${facilityId}/groups/${groupId}/users`);
    this.mainPath = `${mainPath()}${apiVersion}`;
    return endpoint;
  };

  getUserFobsList = (facilityId, groupId) =>
    this.manufacturePath(`facilities/${facilityId}/groups/${groupId}/users/fobs`);

  getUserGobsListByUserId = (facilityId, groupId, userId) =>
    this.manufacturePath(`facilities/${facilityId}/groups/${groupId}/users/${userId}/fobs`);

  getUserLicensesList = (facilityId, groupId, userId) =>
    this.manufacturePath(`facilities/${facilityId}/groups/${groupId}/users/${userId}/licenses`);

  postGroupUsersFobs = (facilityId, groupId, userId) =>
    this.manufacturePath(`facilities/${facilityId}/groups/${groupId}/users/${userId}/fob`);

  deleteUserGroupFob = (facilityId, groupId, userId, fobId) =>
    this.manufacturePath(`facilities/${facilityId}/groups/${groupId}/users/${userId}/fob/${fobId}`);

  putUserLicenseLock = (facilityId, groupId, userId, id) =>
    this.manufacturePath(
      `facilities/${facilityId}/groups/${groupId}/users/${userId}/licenses/${id}/lock`
    );

  putUserLicenseUnlock = (facilityId, grouId, userId, id) =>
    this.manufacturePath(
      `facilities/${facilityId}/groups/${grouId}/users/${userId}/licenses/${id}/unlock`
    );

  putUserMoveGroup = (facilityId, groupId, toFacilityID, toGroupID) => {
    this.mainPath = `${mainPath()}4.2`;
    const endpoint = this.manufacturePath(
      `facilities/${facilityId}/groups/${groupId}/users/move/${toFacilityID}/${toGroupID}`
    );
    this.mainPath = `${mainPath()}${apiVersion}`;
    return endpoint;
  };

  putUserCopyGroup = (facilityId, groupId, toFacilityID, toGroupID) => {
    this.mainPath = `${mainPath()}4.2`;
    const endpoint = this.manufacturePath(
      `facilities/${facilityId}/groups/${groupId}/users/copy/${toFacilityID}/${toGroupID}`
    );
    this.mainPath = `${mainPath()}${apiVersion}`;
    return endpoint;
  };

  putUserGroup = (facilityId, groupId, userId) =>
    this.manufacturePath(`facilities/${facilityId}/groups/${groupId}/users/${userId}`);

  importGroupUsers = (facilityId, groupId) =>
    this.manufacturePath(`facilities/${facilityId}/groups/${groupId}/users/import`);

  setLockUserGroup = (facilityId, groupId, userId) => {
    this.mainPath = `${mainPath()}4.2`;
    const endpoint = this.manufacturePath(
      `facilities/${facilityId}/groups/${groupId}/users/${userId}/lock`
    );
    this.mainPath = `${mainPath()}${apiVersion}`;

    return endpoint;
  };

  setUnlockUserGroup = (facilityId, groupId, userId) => {
    this.mainPath = `${mainPath()}4.2`;
    const endpoint = this.manufacturePath(
      `facilities/${facilityId}/groups/${groupId}/users/${userId}/unlock`
    );
    this.mainPath = `${mainPath()}${apiVersion}`;

    return endpoint;
  };

  getPaths = () => ({
    userGroupsGetUsersList: this.getUsersList,
    userGroupsGetUserCounter: this.getUsersCounters,
    userGroupsGetUserAvailableGroups: this.getUsersAvailableGroups,
    userGroupsGetUserAvailableFacilities: this.getUsersAvailableFacilities,
    userGroupsPostUsersList: this.postUserGroup,
    userGroupDelete: this.deleteUserGroup,
    userGroupDeleteList: this.deleteUserGroupList,
    userGroupsGetUsersFobs: this.getUserFobsList,
    userGroupsGetUsersLicenses: this.getUserLicensesList,
    userGroupsGetUsersFobsByUserId: this.getUserGobsListByUserId,
    userGroupsPostUsersFobs: this.postGroupUsersFobs,
    userGroupFobDelete: this.deleteUserGroupFob,
    userLicenseLock: this.putUserLicenseLock,
    userLicenseUnlock: this.putUserLicenseUnlock,
    userMoveGroup: this.putUserMoveGroup,
    userCopyGroup: this.putUserCopyGroup,
    userGroupUpdate: this.putUserGroup,
    userGroupLock: this.setLockUserGroup,
    userGroupUnlock: this.setUnlockUserGroup,
    userImportGroupUsers: this.importGroupUsers,
  });
}

export default new Routes().getPaths();
