export default {
  alarms: {
    deleteAlarmPopUp: 'Are you sure you want to mark the alarm as seen?',
    deleteAlarmPopUpWithMove:
      'Moving a fob to another group will mark the alarms as seen. Do you wish to continue?',
    deleteAlarmPopUpWithCloneUniversal: 'The copy of the universal fob will share the alarms.',
    deleteAlarmPopUpWithClone:
      'Copying a fob to another group, the copy will appear without alarms.',
  },
};
