import { Flex, Grid, GridItem } from '@jcm-technologies/uikit/dist/atoms/Layout';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt, useHistory, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@jcm-technologies/uikit/dist/atoms/Button';
import { ScheduleForm } from '../../components/Schedule/Form/Detail';
import { WeeklyCalendar } from '../../components/Schedule/Calendar/WeeklyCalendar';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {
  deleteSchedule,
  getDuplicateScheduleData,
  getSchedule,
  getSchedulesAssignedFacilities,
  putSchedules,
  resetSchedule,
} from '../../modules/schedule/actions';
import { getContrastColor } from '../../core/helpers/colorManager';
import { DayCalendar } from '../../components/Schedule/Calendar/DayCalendar';
import { getHolidays } from '../../modules/holidays/actions';
import { validationRules } from '../../components/Schedule/config';
import { getErrors } from '../../core/old_common/validations/service';
import { ModalScheduleAssignedFacilities } from '../../components/Schedule/Modal/AssignedFacilities';
import { ModalScheduleDelete } from '../../components/Schedule/Modal/Delete';
import { HeaderDetailSchedule } from '../../components/Schedule/HeaderDetail';

function ScheduleEdit() {
  const { color1 } = useSelector((state) => state.tenants);
  const { schedule, assignedFacilities } = useSelector((state) => state.schedules);
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [data, setData] = useState(schedule || { name: null });
  const [isActiveHolidays, setIsActiveHolidays] = useState(!!schedule?.holidayEntityId);
  const [isOpened, setIsOpened] = useState(false);
  const [isOpenedEditEvent, setIsOpenedEditEvent] = useState(false);
  const [isOpenedEditEventHolidays, setIsOpenedEditEventHolidays] = useState(false);
  const [isAssignedFacilitiesOpened, setIsAssignedFacilitiesOpened] = useState(false);
  const [errorsForm, setErrosForm] = useState({});
  const [hasChange, setHasChange] = useState(false);

  const handleBlur = (name) => {
    const validations = validationRules(data);
    setErrosForm((prev) => ({
      ...prev,
      [name]: getErrors(validations[name]) || false,
    }));
  };

  const handleSubmit = () => {
    setHasChange(false);
    dispatch(putSchedules(data));
  };
  const handleDuplicate = () => {
    dispatch(getDuplicateScheduleData(id));
  };

  const handleDelete = ({ customerID }) => {
    dispatch(deleteSchedule({ id, customerID }));
    setIsOpened(false);
    history.push('/schedules');
  };

  const handleGoToHolidays = () => history.push('/administration/holidays');

  useEffect(() => {
    dispatch(getHolidays());
    dispatch(getSchedule(id));
    return () => dispatch(resetSchedule());
  }, []);

  useEffect(() => {
    if (schedule) {
      setData(schedule);
      setIsActiveHolidays(schedule?.holidayEntityId);
    }
  }, [schedule]);

  return (
    <Flex
      backgroundColor='white'
      borderRadius={3}
      height='100%'
      overflow='scroll'
      minHeight='calc(100vh - 156px)'
      flexDirection='column'
      justifyContent='space-between'
    >
      <HeaderDetailSchedule
        data={data}
        setIsOpened={setIsOpened}
        handleDuplicate={handleDuplicate}
      />
      <Flex justifyContent='flex-end' width='100%' paddingTop={3} paddingX={4}>
        <Button
          id='afected-instalations'
          sizeButton='small'
          backgroundColor={color1}
          color={getContrastColor(color1)}
          action={() => {
            setIsAssignedFacilitiesOpened(true);
            dispatch(getSchedulesAssignedFacilities(id));
          }}
        >
          {t('schedule.affectedFacilities')}
        </Button>
      </Flex>
      <ScheduleForm
        data={data}
        handleGoToHolidays={handleGoToHolidays}
        setIsActiveHolidays={setIsActiveHolidays}
        onChangeAction={(name, value) => {
          setData((prev) => ({ ...prev, [name]: value }));
          setHasChange(true);
        }}
        isActiveHolidays={isActiveHolidays}
        handleBlur={handleBlur}
        errors={errorsForm}
      />
      <Grid
        width='100%'
        gridTemplateAreas={[
          "'week week' 'holidays holidays'",
          "'week week' 'holidays holidays'",
          "'week holidays' 'week holidays'",
        ]}
        gridTemplateColumns={['1fr', '1fr', '1fr 275px']}
        gridAutoRows='auto'
        gridGap={2}
        paddingTop={3}
        paddingX={4}
      >
        <GridItem gridArea='week'>
          <WeeklyCalendar
            data={data?.scheduleEntries}
            onChangeAction={(name, value) => {
              setData((prev) => ({ ...prev, scheduleEntries: value }));
              setHasChange(true);
            }}
            id={id}
            isOpenedEditEvent={isOpenedEditEvent}
            setIsOpenedEditEvent={setIsOpenedEditEvent}
          />
        </GridItem>
        <GridItem gridArea='holidays'>
          <DayCalendar
            isActiveHolidays={isActiveHolidays}
            data={data?.scheduleEntries}
            onChangeAction={(name, value) => {
              setData((prev) => ({ ...prev, scheduleEntries: value }));
              setHasChange(true);
            }}
            id={id}
            isOpenedEditEvent={isOpenedEditEventHolidays}
            setIsOpenedEditEvent={setIsOpenedEditEventHolidays}
            isEditingHolidays
          />
        </GridItem>
      </Grid>
      <ModalScheduleAssignedFacilities
        isOpened={isAssignedFacilitiesOpened}
        setIsOpened={setIsAssignedFacilitiesOpened}
        data={assignedFacilities}
      />
      <ModalScheduleDelete
        isOpened={isOpened}
        setIsOpened={setIsOpened}
        data={schedule}
        action={handleDelete}
      />
      <Prompt when={hasChange || false} message={() => `${t('genericForm.mayNotSave')}`} />
      <Flex alignItems='center' justifyContent='flex-end' paddingY={2} paddingX={4}>
        <Button
          id='saveCreateCalendar'
          sizeButton='small'
          backgroundColor={color1}
          action={() => {
            handleSubmit();
          }}
          disabled={!hasChange}
          marginRight={2}
          color={getContrastColor(color1)}
        >
          {t('general.save')}
        </Button>
        <Button
          backgroundColor='blackLight'
          id='cancel-button'
          sizeButton='small'
          action={() => {
            dispatch(resetSchedule());
            history.push('/schedules');
          }}
        >
          {t('general.cancel')}
        </Button>
      </Flex>
    </Flex>
  );
}

export default ScheduleEdit;
