export default {
  adjustTimePopUp: {
    manualDateTimeAdjust: "Réglage manuel de la date et de l'heure",
    actualHour: 'Heure actuelle:',
    minutesCompensation: 'Compensation des minutes à partir de maintenant',
    insertSdMemory: "Insérez la mémoire SD et alimentez l'appareil exactement à l'heure suivante",
    inputTime: 'Montre le temps',
    save: 'Sauvegarder',
  },
};
