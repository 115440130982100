import IconUser from '@jcm-technologies/uikit/dist/atoms/Icons/IconUser';
import IconSignOut from '@jcm-technologies/uikit/dist/atoms/Icons/IconSignOut';
import i18Next from 'i18next';
import { getInstalations } from '../../../modules/old_to_refact/actions/instalations';
import { getUniversalGroups } from '../../../modules/old_to_refact/actions/universalGroups';
import { getGroups } from '../../../modules/old_to_refact/actions/groups';
import { getSchedules } from '../../../modules/schedule/actions';

export const menuItemsList = (history, onCloseSession) => [
  {
    label: i18Next.t('title.myAccount'),
    action: () => history.push('/user-config/preferences'),
    icon: <IconUser />,
  },
  {
    label: i18Next.t('general.logOut'),
    action: () => onCloseSession(),
    icon: <IconSignOut />,
  },
];

export const manualDownloaded = {
  default: `https://installgo.jcm-tech.com/en/4000190`,
  es_ES: `https://installgo.jcm-tech.com/es/4000190`,
  ca_ES: `https://installgo.jcm-tech.com/es/4000190`,
  en_GB: `https://installgo.jcm-tech.com/en/4000190`,
  de_DE: `https://installgo.jcm-tech.com/de/4000190`,
  fr_FR: `https://installgo.jcm-tech.com/fr/4000190`,
  it_IT: `https://installgo.jcm-tech.com/it/4000190`,
};

export const manualDownloadedBeta = {
  default: `https://cdn.jcm-tech.com/downloads/pdf/UM/UM_3201684_CLOUD-ASSISTANT_EN_beta.pdf`,
  es_ES: `https://cdn.jcm-tech.com/downloads/pdf/UM/UM_3201683_CLOUD-ASSISTANT_ES_beta.pdf`,
  ca_ES: `https://cdn.jcm-tech.com/downloads/pdf/UM/UM_3201683_CLOUD-ASSISTANT_ES_beta.pdf`,
  en_GB: `https://cdn.jcm-tech.com/downloads/pdf/UM/UM_3201684_CLOUD-ASSISTANT_EN_beta.pdf`,
  de_DE: `https://cdn.jcm-tech.com/downloads/pdf/UM/UM_3201685_CLOUD-ASSISTANT_DE_beta.pdf`,
  fr_FR: `https://cdn.jcm-tech.com/downloads/pdf/UM/UM_3201686_CLOUD-ASSISTANT_FR_beta.pdf`,
  it_IT: `https://cdn.jcm-tech.com/downloads/pdf/UM/UM_3201687_CLOUD-ASSISTANT_IT_beta.pdf`,
};
export const getListOfGlobalSearch = {
  facilities: getInstalations(),
  universalGroups: getUniversalGroups(),
  facilityGroups: getGroups(),
  schedules: getSchedules(),
};
