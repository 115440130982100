import { call, fork, takeLatest } from 'redux-saga/effects';
import fetchEntitySaga from '../old_to_refact/sagas/utils/fetchEntitySaga';
import apiPathFactory from '../../core/old_common/rooting/apiPathFactory';
import api from '../../core/helpers/api';
import {
  GET_FAMILIES,
  GET_FAMILIES_SINGLE_LIST,
  GET_FAMILY_PRODUCTS,
  POST_FAMILIES,
} from './contants';

export const getFamilies = {
  base: () => ({
    type: GET_FAMILIES.BASE,
  }),
  request: () => ({ type: GET_FAMILIES.REQUEST }),
  success: (result) => ({ type: GET_FAMILIES.SUCCESS, result }),
  failure: (error) => ({ type: GET_FAMILIES.FAILURE, error }),
};

export const getSingleListFamilies = {
  base: (productType, isExtended) => ({
    type: GET_FAMILIES_SINGLE_LIST.BASE,
    productType,
    isExtended,
  }),
  request: () => ({ type: GET_FAMILIES_SINGLE_LIST.REQUEST }),
  success: (result, payload) => ({ type: GET_FAMILIES_SINGLE_LIST.SUCCESS, result, payload }),
  failure: (error) => ({ type: GET_FAMILIES_SINGLE_LIST.FAILURE, error }),
};

export const getProducts = {
  base: (familyId) => ({
    type: GET_FAMILY_PRODUCTS.BASE,
    familyId,
  }),
  request: (familyId) => ({ type: GET_FAMILY_PRODUCTS.REQUEST, familyId }),
  success: (result) => ({ type: GET_FAMILY_PRODUCTS.SUCCESS, result }),
  failure: (error) => ({ type: GET_FAMILY_PRODUCTS.FAILURE, error }),
};

export const postFamily = {
  base: (family) => ({
    type: POST_FAMILIES.BASE,
    family,
  }),
  request: (family) => ({ type: POST_FAMILIES.REQUEST, family }),
  success: (result) => ({ type: POST_FAMILIES.SUCCESS, result }),
  failure: (error) => ({ type: POST_FAMILIES.FAILURE, error }),
};

//----------------------------------------------------------------------

export function* getFamiliesSaga() {
  try {
    const url = apiPathFactory.familiesGet();
    const response = yield call(() => fetchEntitySaga(getFamilies, api.get, url));
    return response;
  } catch (error) {
    return error;
  }
}

export function* getFamiliesSingleListSaga({ productType, isExtended }) {
  try {
    const url = apiPathFactory.productsGetSingleList(productType, isExtended);
    const response = yield call(() =>
      fetchEntitySaga(getSingleListFamilies, api.get, url, { productType })
    );
    return response;
  } catch (error) {
    return error;
  }
}

export function* getFamilyProductsSaga({ familyId }) {
  try {
    const url = apiPathFactory.productsGet(familyId);
    const response = yield call(() => fetchEntitySaga(getProducts, api.get, url));
    return response;
  } catch (error) {
    return error;
  }
}

export function* postFamilySaga({ family }) {
  try {
    const url = apiPathFactory.familiesPost();
    const response = yield call(() => fetchEntitySaga(postFamily, api.post, url, { data: family }));
    return response;
  } catch (error) {
    yield call(getFamiliesSaga);
    return error;
  }
}

//----------------------------------------------------------------------

function* watchGetFamilies() {
  yield takeLatest(GET_FAMILIES.BASE, getFamiliesSaga);
}

//----------------------------------------------------------------------

function* watchGetFamiliesSingleList() {
  yield takeLatest(GET_FAMILIES_SINGLE_LIST.BASE, getFamiliesSingleListSaga);
}

//----------------------------------------------------------------------

function* watchGetProductsSaga() {
  yield takeLatest(GET_FAMILY_PRODUCTS.BASE, getFamilyProductsSaga);
}

//----------------------------------------------------------------------

function* watchPostFamilySaga() {
  yield takeLatest(POST_FAMILIES.BASE, postFamilySaga);
}

//----------------------------------------------------------------------

export default function* badRuleWarningWatchers() {
  yield fork(watchGetFamilies);
  yield fork(watchGetProductsSaga);
  yield fork(watchPostFamilySaga);
  yield fork(watchGetFamiliesSingleList);
}
