export const legalNoticeDictionary = {
  default: 'https://www.jcm-tech.com/cloudassistant-legal-notice/',
  es_ES: 'https://www.jcm-tech.com/es/cloudassistant-aviso-legal/',
  ca_ES: 'https://www.jcm-tech.com/es/cloudassistant-aviso-legal/',
  en_GB: 'https://www.jcm-tech.com/cloudassistant-legal-notice/',
  hu_HU: 'https://www.jcm-tech.com/cloudassistant-legal-notice/',
  cs_CZ: 'https://www.jcm-tech.com/cloudassistant-legal-notice/',
  de_DE: 'https://www.jcm-tech.com/de/cloudassistant-rechtlicher-hinweis/',
  fr_FR: 'https://www.jcm-tech.com/fr/cloudassistant-mention-legale/',
  it_IT: 'https://www.jcm-tech.com/it/cloudassistant-nota-legale/',
};
