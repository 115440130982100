import React, { Component } from 'react';
import { connect } from 'react-redux';
import './GenericList.css';
import { Flex } from '@jcm-technologies/uikit/dist/atoms/Layout';
import { Text } from '@jcm-technologies/uikit/dist/atoms/Typography';
import { withTranslation } from 'react-i18next';
import Pagination from '../Pagination/Pagination';
import { resetTotalPagesCount, setPageNumber } from '../../modules/state/pagination/actions';

import { IconSpan } from '../IconSpan/IconSpan';

export class GenericList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shouldCallApi: false,
    };
  }

  getGrows = () => {
    const {
      CustomGrow,
      dataList,
      baseURI,
      creationURI,
      groupByProp,
      counter,
      hideActionButtons,
      titleLeftMessage,
    } = this.props;
    if (groupByProp && dataList) {
      const groupedDataList = dataList.sort((a, b) => {
        if (a[groupByProp] > b[groupByProp]) {
          return 1;
        }
        if (a[groupByProp] > b[groupByProp]) {
          return -1;
        }
        return 0;
      });
      let dataCustomerName = '';
      return groupedDataList.map((data, index) => {
        const Grow = () => (
          <CustomGrow
            key={`${data?.id}${index}A` || `${data?.name}${index}A` || index}
            item={data}
            {...data}
            hideActionButtons={hideActionButtons}
            baseURI={baseURI}
            creationURI={creationURI}
            handleDelete={this.growHandleDelete}
          />
        );
        const finalComponent =
          dataCustomerName === data.customerName ? (
            <Grow />
          ) : (
            <>
              <Flex alignItems='center' justifyContent='space-between'>
                <Text as='span' marginTop={1} marginLeft={1} fontWeight='bold'>
                  {data.customerName} {counter && <>({counter})</>}
                </Text>
                {titleLeftMessage && <Text color='red'>{titleLeftMessage}</Text>}
              </Flex>

              <Grow />
            </>
          );
        dataCustomerName = data.customerName;
        return finalComponent;
      });
    }
    return dataList
      ? dataList.map((data, index) => (
          <CustomGrow
            key={`${data?.id}${index}F` || `${data?.name}${index}F` || index}
            item={data}
            {...data}
            baseURI={baseURI}
            creationURI={creationURI}
            hideActionButtons={hideActionButtons}
            handleDelete={this.growHandleDelete}
          />
        ))
      : [];
  };

  growHandleDelete = () => {
    this.setState({ shouldCallApi: true });
  };

  componentDidUpdate(prevProps) {
    const { dataList, listType, totalPages, pagenumber, setPageNumber, getList } = this.props;
    if (dataList && !dataList.length && pagenumber[listType] > 1 && this.state.shouldCallApi) {
      if (totalPages === pagenumber[listType]) {
        setPageNumber(listType, pagenumber[listType] - 1);
      }
    }
    if (dataList && this.state.shouldCallApi) {
      if (prevProps.pagenumber[listType] !== this.props.pagenumber[listType]) {
        getList();
        this.setState({ shouldCallApi: false });
      }
    }
  }

  componentWillUnmount() {
    const { isPaginable } = this.props;
    if (isPaginable) {
      this.props.resetTotalPagesCount();
    }
  }

  render() {
    const {
      CustomThead,
      handlePageChange,
      dataList,
      totalPages,
      listType,
      isPaginable,
      pagenumber,
      t,
    } = this.props;
    return dataList !== undefined ? (
      dataList.length > 0 ? (
        <div id='genericList'>
          {CustomThead ? <CustomThead /> : null}
          <div className='generic-list'>
            {this.getGrows()}
            {((isPaginable === undefined || isPaginable) && dataList && dataList.length) ||
            pagenumber[listType] > 1 ? (
              <div className='pagingContainer'>
                <Pagination
                  listType={listType}
                  listTotalPages={totalPages}
                  onPageChange={handlePageChange}
                />
              </div>
            ) : null}
          </div>
        </div>
      ) : (
        <div className='no-content'>
          <h6>
            <IconSpan
              tenants={this.props.tenants}
              defaultColor='grey'
              iconClassName='fas fa-info-circle empty-map-warn'
            />
            {t('errors.noContent')}
          </h6>
        </div>
      )
    ) : (
      ''
    );
  }
}

const mapStateToProps = (state) => ({
  pagenumber: state.backendParams.pagenumber,
  totalPages: state.backendParams.totalPages,
  tenants: state.tenants,
});

const GenericListTranslation = withTranslation()(GenericList);

export default connect(mapStateToProps, { resetTotalPagesCount, setPageNumber })(
  GenericListTranslation
);
