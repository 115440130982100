import React, { Component } from 'react';
import { ComboboxFlyer } from '../ComboboxFlyer/ComboboxFlyer';

import './Dropdown.css';

export class Dropdown extends Component {
  getComboOptions = (options) => {
    const comboOptions = [];
    if (options) {
      options.forEach((option, i) => {
        if (option.options) {
          comboOptions.push(
            <optgroup key={option.key + i || option.id + i} label={option.value}>
              {this.getComboOptions(option.options)}
            </optgroup>
          );
        } else if (option.value !== null) {
          comboOptions.push(this.createOption(option));
        }
      });
    }
    return comboOptions;
  };

  createOption = (option, i) => (
    <option {...option} key={option.key + i || option.id + i} value={option.key || option.id}>
      {option.value || option.name}
    </option>
  );

  render() {
    const { values, isDisabled, className } = this.props;
    return (
      <ComboboxFlyer
        disabled={isDisabled}
        className={className}
        {...this.props}
        options={this.getComboOptions(values)}
      />
    );
  }
}
export default Dropdown;
