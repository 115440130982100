export default {
  formValidation: {
    notSameValue: 'El valor no coincideix',
    notSamePassword: 'Les contrasenyes no coincideixen',
    numericField: 'El valor ha de ser numèric',
    minNumber: 'El valor mínim és 1',
    passwordFormat: 'La contrasenya ha de tenir un mínim de 8 caràcters, majúscules, minúscules, números i caràcters especials',
    isNotValidDate: 'El format de la data ha de ser YYYY-MM-DDTHH:mm:ss',
    isRequired: 'Camp obligatori',
    outOfRange: 'Valor fora de rang',
    maxLength: 'La longitud màxima és',
    regEx: 'Format incorrecte. Utilitza aquests caràcters',
    deviceLimit: 'Límit de dispositius assolit',
    onlySpaces: 'No hi pot haver només espais en blanc',
    isReservedWord: 'Paraula reservada',
  },
};