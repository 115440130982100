import { call, fork, takeLatest } from 'redux-saga/effects';
import { GET_SEARCH } from './constants';
import apiPathFactory from '../../core/old_common/rooting/apiPathFactory';
import api from '../../core/helpers/api';
import fetchEntitySaga from '../old_to_refact/sagas/utils/fetchEntitySaga';

export const getGlobalSearch = {
  base: (loginDatas) => ({
    type: GET_SEARCH.BASE,
    loginDatas,
  }),
  request: (params) => ({ type: GET_SEARCH.REQUEST, params }),
  success: (result) => ({ type: GET_SEARCH.SUCCESS, result }),
  failure: (error) => ({ type: GET_SEARCH.FAILURE, error }),
};

export function* getGlobalSearchSagas({ searchQuery }) {
  try {
    let url = apiPathFactory.globalSearchGet();
    url = `${url}?textToSearch=${searchQuery}`;
    const data = JSON.stringify(searchQuery);
    const response = yield call(() => fetchEntitySaga(getGlobalSearch, api.get, url, { data }));
    return response;
  } catch (error) {
    return error;
  }
}

function* watchGetGlobalSearch() {
  yield takeLatest(GET_SEARCH.BASE, getGlobalSearchSagas);
}

export default function* badRuleWarningWatchers() {
  yield fork(watchGetGlobalSearch);
}
