export default {
  descriptionParams: {
    ACCESSCONTROL: 'Indica si la central dispone de entrada para dispositivos de control de acceso',
    ACTIVEEVENTSTOSD: 'Especifica los eventos activos para su dispositivo',
    APNCONFIG: 'Configura la APN del dispositivo',
    BADGE_MAITRE1: 'Código del elemento de proximidad utilizado como llave maestra',
    BADGE_MAITRE10: 'Código del elemento de proximidad utilizado como llave maestra',
    BADGE_MAITRE2: 'Código del elemento de proximidad utilizado como llave maestra',
    BADGE_MAITRE3: 'Código del elemento de proximidad utilizado como llave maestra',
    BADGE_MAITRE4: 'Código del elemento de proximidad utilizado como llave maestra',
    BADGE_MAITRE5: 'Código del elemento de proximidad utilizado como llave maestra',
    BADGE_MAITRE6: 'Código del elemento de proximidad utilizado como llave maestra',
    BADGE_MAITRE7: 'Código del elemento de proximidad utilizado como llave maestra',
    BADGE_MAITRE8: 'Código del elemento de proximidad utilizado como llave maestra',
    BADGE_MAITRE9: 'Código del elemento de proximidad utilizado como llave maestra',
    CONFIGRELAYSNEW1:
      'Indica los relés que se activarán en función del canal que envíe el dispositivo',
    CONFIGRELAYSNEW2:
      'Indica los relés que se activarán en función del canal que envié el dispositivo',
    CONFIGRELAYSNEW3:
      'Indica los relés que se activarán en función del canal que envíe el dispositivo',
    CONFIGRELAYSNEW4:
      'Indica los relés que se activarán en función del canal que envié el dispositivo',
    CONFIGRELAYSPIN1: 'Indica los relés que se activarán según el canal enviado por el dispositivo',
    CONFIGRELAYSPIN2: 'Indica los relés que se activarán según el canal enviado por el dispositivo',
    CONFIGRELAYSPIN3: 'Indica los relés que se activarán según el canal enviado por el dispositivo',
    CONFIGRELAYSPIN4: 'Indica los relés que se activarán según el canal enviado por el dispositivo',
    DISABLEMANUALPROG: 'Permite dar de alta dispositivos manualmente',
    DISABLERADIOPROG:
      'Impide que se pueda dar de alta emisores utilizando el sistema de programación vía radio (modo estándar)',
    FREEACTIVE: 'Activa/Desactiva las características del grupo del sistema FREE',
    FREEGROUP0LOCKED: 'Bloquea/Desbloquea el grupo 0 del sistema FREE',
    FREEGROUPNUM: 'Establece el número de grupo del sistema FREE',
    FREEGROUPRFPROGALLOWED:
      'Habilita/Deshabilita la programación vía radio para el grupo del sistema FREE',
    IMPBIRELE1: 'Cambia el funcionamiento del relé 1 de impulsional a biestable',
    INSTAL: 'Indica si trabaja con código instalador',
    MINIDDEVICE: 'El equipo aceptará dispositivos como los indicados o superiores',
    PASSWORD: 'Contraseña de 4 dígitos de acceso al menú de la central',
    PIN: 'Código numérico de 6 dígitos correspondiente al PIN del receptor',
    PINCADUCAT: 'Indica si el receptor acepta dispositivos con el código PIN caducado',
    POWER: 'Indica el tipo de alimentación del equipo',
    RELAYPRICE1: 'Valor que se restará del crédito de un dispositivo de prepago 1',
    RELAYPRICE2: 'Valor que se restará del crédito de un dispositivo de prepago 2',
    RELAYPRICE3: 'Valor que se restará del crédito de un dispositivo de prepago 3',
    RELAYPRICE4: 'Valor que se restará del crédito de un dispositivo de prepago 4',
    RELAYS: 'Número de relés del receptor',
    RELE1IMP: 'Configura el relé como impulsional, biestable o antipánico',
    RELE1TEMP:
      'Configura el tiempo que permanecerá activo el relé en modo impulsional (de 1 a 30 seg) o antipánico (de 1 a 15 min)',
    RELE2IMP: 'Configura el relé como impulsional, biestable o antipánico',
    RELE2TEMP:
      'Configura el tiempo que permanecerá activo el relé en modo impulsional (de 1 a 30 seg) o antipánico (de 1 a 15 min)',
    RELE3IMP: 'Configura el relé como impulsional, biestable o antipánico',
    RELE3TEMP:
      'Configura el tiempo que permanecerá activo el relé en modo impulsional (de 1 a 30 seg) o antipánico (de 1 a 15 min)',
    RELE4IMP: 'Configura el relé como impulsional, biestable o antipánico',
    RELE4TEMP:
      'Configura el tiempo que permanecerá activo el relé en modo impulsional (de 1 a 30 seg) o antipánico (de 1 a 15 min)',
    SERIALNUMBER: 'Número de serie obligatorio para acciones remotas',
    SITECODE:
      'Sitecode/facility code/código de instalación que se mandará en el protocolo Wiegand 26 con Sitecode',
    TEVENT: 'Indica si la central puede llevar tarjeta de eventos',
    TIMEZONEID: 'Especifica la zona horaria donde el equipo está instalado',
    TMEM: 'Indica si la central funciona con tarjeta de memoria',
    UNIQUEKEY: 'Clave única obligatoria para acciones remotas',
    W26FORMAT: 'Configura el formato de transmisión de protocolo Wiegand 26',
    SETNEWACCESSPW: 'Cambia la contraseña',
    SERIALNUMBERLINK: 'Número de serie obligatorio para acciones remotas',
    CONNECTIVITY_EXTENSION: 'Accesorio LINK instalado',
    ACTIVATIONKEY: 'Clave de Activación',
    EMMITTERNOTUSEDEVENTTIME: 'No utilizar el evento tiempo del emisor',
    DOOROPENEDEVENTTIME: 'Evento de apertura de puerta',
    SCHEDULESDATA: 'Datos horarios',
    A5KPROXY: 'Access 5k Proxy',
    WIEGANDCONFIG: 'Personaliza la configuración Wiegand a usar',
    ALLOWNOTIFICATIONS: 'HONOA Mostrar estado puerta',
  },
};
