import { Box, Flex } from '@jcm-technologies/uikit/dist/atoms/Layout';
import { Text } from '@jcm-technologies/uikit/dist/atoms/Typography';
import { Icon } from '@jcm-technologies/uikit/dist/atoms/Icon';
import IconTrash from '@jcm-technologies/uikit/dist/atoms/Icons/IconTrash';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import PropTypes from 'prop-types';

const BoxHolidays = ({ lang, months, dataSelected, setIsOpenedDeletePopUp, removeItem })=>{
  const { t } = useTranslation();

  return(
    <Box padding={2} width='100%'>
      <Text sizeText='display24' fontWeight='bold' color='black'>
        {t('title.holidaysDays')} {dataSelected?.length ? `(${dataSelected?.length})` : ''}
      </Text>
      <Flex
        alignItems='center'
        justifyContent='flex-end'
        style={{ cursor: 'pointer' }}
        onClick={() => {
          setIsOpenedDeletePopUp(true);
        }}
      >
        <Icon color='red' marginRight={1}>
          <IconTrash />
        </Icon>
        <Text sizeText='display13' marginY={2} style={{ cursor: 'pointer' }}>
          {t('holidays.deleteHolidays')}
        </Text>
      </Flex>
      <Flex flexWrap='wrap'>
        {months.map((month) => {
          if (dataSelected.filter((el) => moment(el.value).format('MMMM') === month).length) {
            return (
              <Box
                key={moment().month(month).locale(lang).format('MMMM').toUpperCase()}
                width='162px'
                height='303px'
                padding={2}
                maxWidth='100%'
                position='relative'
                overflow='auto'
                marginBottom={2}
                boxShadow='none'
                marginX={2}
                borderRadius={2}
                borderStyle='solid'
                borderWidth={1}
                borderColor='whiteSmooth'
              >
                <Text sizeText='display26' fontWeight='bold' color='black' marginBottom={2}>
                  {moment().month(month).locale(lang).format('MMMM').toUpperCase()}
                </Text>
                {dataSelected
                  .filter((el) => moment(el.value).format('MMMM') === month)
                  ?.sort((a, b) => a.timestamp - b.timestamp)
                  .map((ele) => (
                    <Flex
                      key={ele.formatted}
                      justifyContent='space-between'
                      alignItems='center'
                      borderStyle='solid'
                      borderWidth={1}
                      borderColor='whiteSmooth'
                      borderRadius={2}
                      padding={1}
                      marginY={1}
                    >
                      <Text color='black' sizeText='display13'>
                        {ele.formatted}
                      </Text>
                      <Icon
                        color='red'
                        onClick={async () => {
                          removeItem(ele.formatted);
                        }}
                        style={{ cursor: 'pointer' }}
                      >
                        <IconTrash />
                      </Icon>
                    </Flex>
                  ))}
              </Box>
            );
          }
          return '';
        })}
      </Flex>
    </Box>
  )
}

export default BoxHolidays;

BoxHolidays.propTypes = {
  lang: PropTypes.string.isRequired,
  months: PropTypes.arrayOf(PropTypes.string).isRequired,
  dataSelected: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.instanceOf(Date),
      day: PropTypes.string,
      formatted: PropTypes.string,
      timestamp: PropTypes.number,
      holidayEntityId: PropTypes.string,
      id: PropTypes.string
    })
  ).isRequired,
  setIsOpenedDeletePopUp: PropTypes.func.isRequired,
  removeItem: PropTypes.func.isRequired
};
