import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Flex, Grid } from '@jcm-technologies/uikit/dist/atoms/Layout';
import { useTranslation } from 'react-i18next';
import { Text } from '@jcm-technologies/uikit/dist/atoms/Typography';
import { putAvatarUser, putPreferencesUser } from '../../../modules/user/actions';
import {
  base64ToImage,
  imageFilesToBase64WithCompression,
} from '../../../core/helpers/getImagesBase64';
import Form from '../../../components/UserConfig/Preferences/Form';
import { getLanguages } from '../../../core/helpers/getLanguages';
import {
  getCookieDateTimeFormat,
  getCookiePageSizeItems,
} from '../../../core/old_common/utils/cookiesManager';
import CompanyInfo from '../../../components/UserConfig/Preferences/CompanyInfo';
import UserInfo from '../../../components/UserConfig/Preferences/UserInfo';

const UpdateUser = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const tenants = useSelector((state) => state.tenants);
  const { userId } = useSelector((state) => state.login);
  const {
    user,
    user: { company, roleName, name, surname, avatar, lastLogin, email },
  } = useSelector((state) => state.user);
  const [avatarState, setAvatarState] = useState(avatar ? { base64: base64ToImage(avatar) } : '');
  const [data, setData] = useState({
    language: user?.language ?? getLanguages()[0].value,
    timeZone: user?.timeZone ?? '',
    dateTimeFormat: user?.dateTimeFormat ?? getCookieDateTimeFormat(),
    assistantConfigId: user?.assistantConfigId,
    pageItems: user?.pageItems || getCookiePageSizeItems(),
  });

  useEffect(() => {
    if (user) {
      setData({
        language: user?.language,
        timeZone: user?.timeZone,
        dateTimeFormat: user?.dateTimeFormat ? user?.dateTimeFormat : getCookieDateTimeFormat(),
        assistantConfigId: user?.assistantConfigId,
        pageItems: user?.pageItems,
      });
    }
  }, [user]);

  useEffect(() => {
    setAvatarState({ base64: base64ToImage(avatar) });
  }, [avatar]);

  const handleAvatarDelete = () => {
    setAvatarState('');
    dispatch(putAvatarUser(userId, ''));
  };

  const handleAvatarChange = (files) => {
    if (files?.[0]?.file) {
      imageFilesToBase64WithCompression([files?.[0]?.file], true).then((base64File) => {
        setAvatarState({ base64: base64File[0] });
        dispatch(putAvatarUser(userId, base64File[0]));
      });
    }
  };

  return (
    <Grid
      gridGap={4}
      padding={2}
      gridTemplateColumns={['1fr', '1fr', '1fr', '1fr 1fr']}
      gridTemplateAreas={['"." "."', '"." "."', '"." "."', '". ."']}
    >
      <CompanyInfo company={company} roleName={roleName} email={email} />
      <Flex alignItems='center' justifyContent='center' width='100%' />
      <UserInfo
        name={name}
        surname={surname}
        tenants={tenants}
        avatarState={avatarState}
        handleAvatarChange={handleAvatarChange}
        handleAvatarDelete={handleAvatarDelete}
      />
      <Box borderWidth={1} borderStyle='solid' borderColor='grey' borderRadius={3} boxShadow='none'>
        <Form
          data={data}
          setData={setData}
          handleSubmit={() => {
            dispatch(putPreferencesUser(user.id, data));
          }}
        />
      </Box>
      <Flex alignItems='center' justifyContent='center' width='100%' />
      <Flex borderWidth={1} flexDirection='column' alignItems='end'>
        <Box padding={2}>
          <Text sizeText='display13' fontWeight='bold' color='black'>
            {`${t('account.lastLogin')}: ${lastLogin}`}
          </Text>
        </Box>
      </Flex>
    </Grid>
  );
};

export default UpdateUser;
