import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Relays from '../../old_components/Relays';
import FacilitySchedules from '../../old_components/FacilitySchedules/FacilitySchedules';
import { getDecodedUrl } from '../../core/old_common/utils/getUrlParams';
import {
  getFacility,
  getInstalationsRelays,
  putInstalationsRelay,
  resetInstalation,
  resetInstalationsRelays,
} from '../../modules/old_to_refact/actions/instalations';
import { getInstalationGroups } from '../../modules/old_to_refact/actions/instalationGroups';

import {
  getUniversalGroup,
  getUniversalGroupsEquipmentRelays,
  putInstalationRelaysUG,
  resetUGRelays,
} from '../../modules/old_to_refact/actions/universalGroups';
import TabMenu from '../../old_components/TabMenu/TabMenu';

export class RelaysConfigurationUG extends Component {
  componentWillUnmount = () => {
    this.props.resetUGRelays();
    this.props.resetInstalation();
    this.props.resetInstalationsRelays();
  };

  tabs = () => {
    const {
      facility,
      instalationsGroupRelays,
      universalGroup,
      getUniversalGroupsEquipmentRelays,
      getFacility,
      getUniversalGroup,
      putInstalationRelaysUG,
      t,
    } = this.props;
    let secondaryItem = facility;
    if (facility) {
      secondaryItem = { ...facility, id: facility.id || facility.facilityId };
    }
    const tabstate = {
      secondaryItem,
      principalItem: universalGroup,
      relays: instalationsGroupRelays,
    };
    const tabList = [
      {
        title: t('relaysConfiguration.tabRelays'),
        active: true,
        bodyComponent: Relays,
        componentState: {
          ...tabstate,
          principalParameter: 'universalGroupId',
          secondaryParameter: 'id',
          putRelay: putInstalationRelaysUG,
          getRelays: getUniversalGroupsEquipmentRelays,
          canShowBackButton: true,
          resetRelays: () => {},
          getPrincipalItem: () =>
            getUniversalGroup(getDecodedUrl(this.props.match.params.universalGroupId)),
          getSecondaryItem: () => getFacility(getDecodedUrl(this.props.match.params.id)),
        },
      },
      {
        title: t('relaysConfiguration.tabSchedules'),
        active: false,
        bodyComponent: FacilitySchedules,
        componentState: {
          ...tabstate,
          principalParameter: 'universalGroupId',
          secondaryParameter: 'id',
          putRelay: putInstalationRelaysUG,
          getRelays: getUniversalGroupsEquipmentRelays,
          canShowBackButton: true,
          resetRelays: () => {},
          getPrincipalItem: () =>
            getUniversalGroup(getDecodedUrl(this.props.match.params.universalGroupId)),
          getSecondaryItem: () => getFacility(getDecodedUrl(this.props.match.params.id)),
        },
      },
    ];
    return tabList;
  };

  getSelectedItem = () => {
    const { match, facility, instalations } = this.props;
    if (facility) {
      return facility;
    }
    if (instalations) {
      return instalations.find((instalation) => instalation.name === match.params.id);
    }
    return undefined;
  };

  render() {
    const { t, getFacility, match, isFacility, instalationsGroupRelays } = this.props;
    let baseURI = `/UniversalGroups/${match.params.universalGroupId}/Equipments/`;
    if (isFacility) {
      baseURI = `/UniversalGroups/${match.params.universalGroupId}/Equipments/Facilities`;
    }

    return <TabMenu tabType='tabs' tabsData={this.tabs()} />;
  }
}

const relaysConfigurationUGPage = withTranslation()(RelaysConfigurationUG);

const mapStateToProps = (state) => ({
  tenants: state.tenants,
  instalations: state.instalations.instalations,
  instalationsGroupRelays: state.universalGroups.instalationsGroupRelays,
  facility: state.instalations.facility,
  universalGroup: state.universalGroups.universalGroup,
});

const relaysConfigurationUG = withRouter(relaysConfigurationUGPage);

export default connect(mapStateToProps, {
  resetInstalationsRelays,
  getInstalationsRelays,
  getInstalationGroups,
  getFacility,
  putInstalationsRelay,
  putInstalationRelaysUG,
  getUniversalGroup,
  getUniversalGroupsEquipmentRelays,
  resetUGRelays,
  resetInstalation,
})(relaysConfigurationUG);
