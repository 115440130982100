import successAction from '../actions/successToaster';
import apiPathFactory from '../../../core/old_common/rooting/apiPathFactory';

/* Redux state init */
const initialState = {
  isSuccess: false,
  successMessage: 'saved',
  toastTime: 8000,
};

const getTime = (callType) => {
  if (callType.includes('GRID')) {
    return 4000;
  }
  return 8000;
};

export default function errorsReducer(state = initialState, action) {
  if (action.type !== undefined) {
    switch (action.type) {
      case successAction.SUCCESS_GET:
        return {
          ...state,
          isSuccess: action.params[0] !== apiPathFactory.accountsLogin(),
          toastTime: getTime(action.callType),
          successMessage: action.successMessage || 'saved',
        };
      case successAction.RESET_SUCCESS:
        return {
          ...state,
          ...initialState,
        };
      default:
        return state;
    }
  }
  return state;
}
