import PathEntity from './PathEntity.js';

class Alarms extends PathEntity {
  constructor() {
    super('alarms');
  }

  deleteAlarm = (alarmId) => this.manufacturePath(`${this.entityName}/${alarmId}`);

  getPaths = () => ({
    alarmsDelete: this.deleteAlarm,
  });
}

export default new Alarms().getPaths();
