export default {
  enrollCode: {
    manualTab: 'Manuális',
    reservationsTab: 'Fenntart',
    sequentialTab: 'Sorrendben',
    universalsTab: 'Univerzális',
    device: 'Eszköz',
    initialCode: 'Kezdeti kód',
    idCard: 'DNI',
    spot: 'Hely',
    numberOfFobs: 'Klónozott távirányítók száma',
    endCode: 'Végkód',
    cantEnroll:
      'Az új kódok beírásához ki kell választani egy eszközt és a szükséges rendelkezésre álló pozíciókat',
    cancel: 'Vissza',
    accept: 'Elfogad',
    ok: 'OK',
    forceCodeQuestion: 'Szeretné kikényszeríteni ennek a kódnak a regisztrációját?',
    codeAlreadyExist: 'Ez a kód már létezik egy másik csoportban',
    notAsigned: 'Nincs hozzárendelve',
    reserved: 'Fenntartott',
  },
};
