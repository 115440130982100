import { useEffect, useState } from 'react';
import { Modal } from '@jcm-technologies/uikit/dist/molecules/Modal';
import { Flex, Grid, GridItem } from '@jcm-technologies/uikit/dist/atoms/Layout';
import { Button } from '@jcm-technologies/uikit/dist/atoms/Button';
import { Input } from '@jcm-technologies/uikit/dist/atoms/Input';
import { Text } from '@jcm-technologies/uikit/dist/atoms/Typography';
import { Field } from '@jcm-technologies/uikit/dist/atoms/Field';
import { SelectInput } from '@jcm-technologies/uikit/dist/atoms/Select';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { convertToBinary, valuesSetter, valuesSetterW37 } from './config';
import { getWiegandConfig } from '../../../../../../modules/old_to_refact/actions/equipments';

const EquipmentConfigurationWiegandModal = ({ data, name, isOpen, onClose, onSave }) => {
  const [dataForm, setDataForm] = useState(data);
  const { t } = useTranslation();
  const [isChanged, setIsChanged] = useState(false);
  const [maxValueExponent, setMaxValueExponent] = useState(0);
  const [valueBinary, setValueBinary] = useState(null);
  const { color1 } = useSelector((state) => state.tenants);
  const { equipment } = useSelector((state) => state.equipments);
  const dispatch = useDispatch();

  const boxes = [];

  for (let i = 0; i < dataForm?.length; i += 1) {
    boxes.push(i);
  }

  const calculateData = (newData) => {
    setIsChanged(true);
    setDataForm({
      ...dataForm,
      ...newData,
    });
  };

  const calculateValueExponentAndBinary = () => {
    setMaxValueExponent(Math.pow(2, dataForm?.siteCodeLength) - 1);
    const binaryCalculate =
      Number(dataForm?.siteCodeValue) > maxValueExponent ||
      convertToBinary(dataForm?.siteCodeLength, dataForm.siteCodeValue) === '0'
        ? null
        : convertToBinary(dataForm?.siteCodeLength, dataForm.siteCodeValue);

    let literal = '';
    if (binaryCalculate) {
      for (let i = 0; i < dataForm?.siteCodeLength - binaryCalculate?.length; i += 1) {
        literal += '0';
      }
      literal += binaryCalculate;
    }

    setValueBinary(literal);
  };

  useEffect(() => {
    dispatch(getWiegandConfig(equipment?.facilityId, equipment?.id));
    if (dataForm) {
      calculateValueExponentAndBinary();
    }
  }, []);

  useEffect(() => {
    if (isChanged) {
      calculateValueExponentAndBinary();
      const currentValue = equipment?.wiegandConfigs?.find(
        (element) => dataForm?.length === element.length
      );
      setDataForm({
        ...dataForm,
        siteCodeFrom: dataForm?.siteCodeLength === 0 ? 0 : 2,
        siteCodeValue: !dataForm?.siteCodeValue
          ? currentValue?.siteCodeValue
          : Math.abs(
              Number(dataForm?.siteCodeValue) > maxValueExponent ? 0 : dataForm?.siteCodeValue
            ),
        serialFrom:
          dataForm?.siteCodeLength === 0
            ? currentValue?.serialFrom
            : dataForm?.siteCodeLength + currentValue?.serialFrom,
        serialLength:
          dataForm?.siteCodeLength === 0
            ? currentValue?.serialLength
            : currentValue?.serialLength - dataForm?.siteCodeLength,
        oddParityBit: currentValue?.oddParityBit,
        channelFrom: currentValue?.channelFrom,
        channelLength: currentValue?.channelLength,
        evenParityBit: currentValue?.evenParityBit,
      });

      setIsChanged(false);
    }
  }, [isChanged]);

  useEffect(() => {
    if (maxValueExponent) {
      setIsChanged(true);
    }
  }, [maxValueExponent]);

  return (
    <Modal
      width='100%'
      isOpen={isOpen}
      title={name}
      closeWithButton
      id='modal-config-wiegand'
      onModalClose={onClose}
      maxWidth={[300, 500, 800, 1000]}
    >
      <Modal.Content>
        <Grid
          padding={3}
          gridTemplateColumns='1fr'
          gridTemplateRows='48px 1fr 48px'
          gridTemplateAreas='"dropdown dropdown" "value value" "boxes boxes"'
          gridGap={3}
        >
          <GridItem
            gridArea='dropdown'
            display='flex'
            justifyContent='baseline'
            alignItems='center'
          >
            <Field label={t('form.wiegandconfig.format')} width='50%' marginRight={2}>
              <SelectInput
                defaultValue={[
                  {
                    value: dataForm.length,
                    label: `Wiegand ${dataForm.length} ${dataForm.length == 37 ? 'JCM' : ''}`,
                  },
                ]}
                options={equipment?.wiegandConfigs?.map((item) => {
                  return {
                    value: item.length,
                    label: item.name,
                  };
                })}
                onChange={(name, selected) => {
                  calculateData({
                    length: selected.value,
                  });
                  setDataForm((prevValue) => {
                    return {
                      ...prevValue,
                      siteCodeValue: 0,
                      siteCodeLength: 0,
                    };
                  });
                }}
              />
            </Field>
          </GridItem>
          <GridItem gridArea='value'>
            <Flex alignItems='center'>
              <Text marginRight={2} width={120}>
                {t('form.wiegandconfig.evenParity')}
              </Text>
              <Field label={t('form.from')} width='auto' marginRight={2}>
                <Input
                  value={dataForm?.evenParityBit}
                  id='even-parity-from'
                  sizeInput='small'
                  maxWidth={50}
                  readOnly
                />
              </Field>
              <Field label={t('form.length')} width='auto'>
                <Input value={1} id='even-parity-length' sizeInput='small' maxWidth={50} readOnly />
              </Field>
            </Flex>
            {/* Fields available for non wiegand 37 */}
            {dataForm?.length !== 37 ? (
              <>
                <Flex alignItems='center'>
                  <Text marginRight={2} width={120} bg='redLight'>
                    {t('form.wiegandconfig.siteCode')}
                  </Text>
                  <Field label={t('form.from')} width='auto' marginRight={2}>
                    <Input
                      value={dataForm?.siteCodeFrom}
                      id='site-code-from'
                      sizeInput='small'
                      maxWidth={50}
                      readOnly
                    />
                  </Field>
                  <Field label={t('form.length')} width='auto' marginRight={2}>
                    <Input
                      value={dataForm?.siteCodeLength}
                      id='site-code-length'
                      sizeInput='small'
                      maxWidth={50}
                      type='number'
                      min={0}
                      pattern='\d{1,2}'
                      max={16}
                      required
                      maxLength='2'
                      readOnly={dataForm?.length >= 37}
                      onChange={(name, value) => {
                        calculateData({
                          siteCodeLength: Math.abs(value > 16 ? 16 : value.slice(0, 2)) || 0,
                        });
                      }}
                    />
                  </Field>
                  <Field label={t('form.value')} width='auto'>
                    <Input
                      value={dataForm?.siteCodeValue}
                      id='site-code-value'
                      sizeInput='small'
                      maxWidth={50}
                      min={0}
                      max={65536}
                      maxLength='5'
                      type='number'
                      readOnly={dataForm?.length >= 37}
                      onChange={(name, value) => {
                        calculateData({
                          siteCodeValue:
                            Math.abs(
                              value > maxValueExponent ? maxValueExponent : value.slice(0, 5)
                            ) || 0,
                        });
                      }}
                    />
                  </Field>
                </Flex>

                <Flex alignItems='center'>
                  <Text marginRight={2} width={120} bg='blue'>
                    {t('form.wiegandconfig.serialNumber')}
                  </Text>
                  <Field label={t('form.from')} width='auto' marginRight={2}>
                    <Input
                      value={dataForm.serialFrom}
                      id='serial-from'
                      sizeInput='small'
                      maxWidth={50}
                      readOnly
                    />
                  </Field>
                  <Field label={t('form.length')} width='auto'>
                    <Input
                      value={dataForm?.serialLength}
                      id='serial-length'
                      sizeInput='small'
                      maxWidth={50}
                      readOnly
                    />
                  </Field>
                </Flex>
              </>
            ) : (
              <>
                <Flex alignItems='center'>
                  <Text marginRight={2} width={120} bg='grey'>
                    {t('form.reserved')}
                  </Text>
                  <Field label={t('form.from')} width='auto' marginRight={2}>
                    <Input
                      value={2}
                      id='serial-from'
                      sizeInput='small'
                      maxWidth={50}
                      readOnly
                    />
                  </Field>
                  <Field label={t('form.length')} width='auto'>
                    <Input
                      value={13}
                      id='serial-length'
                      sizeInput='small'
                      maxWidth={50}
                      readOnly
                    />
                  </Field>
                </Flex>
                <Flex alignItems='center'>
                  <Text marginRight={2} width={120} bg='green'>
                    {t('form.channel')}
                  </Text>
                  <Field label={t('form.from')} width='auto' marginRight={2}>
                    <Input
                      value={dataForm?.channelFrom}
                      id='serial-from'
                      sizeInput='small'
                      maxWidth={50}
                      readOnly
                    />
                  </Field>
                  <Field label={t('form.length')} width='auto'>
                    <Input
                      value={dataForm?.channelLength}
                      id='serial-length'
                      sizeInput='small'
                      maxWidth={50}
                      readOnly
                    />
                  </Field>
                </Flex>
                <Flex alignItems='center'>
                  <Text marginRight={2} width={120} bg='blue'>
                    {t('form.wiegandconfig.serialNumber')}
                  </Text>
                  <Field label={t('form.from')} width='auto' marginRight={2}>
                    <Input
                      value={dataForm.serialFrom}
                      id='serial-from'
                      sizeInput='small'
                      maxWidth={50}
                      readOnly
                    />
                  </Field>
                  <Field label={t('form.length')} width='auto'>
                    <Input
                      value={dataForm?.serialLength}
                      id='serial-length'
                      sizeInput='small'
                      maxWidth={50}
                      readOnly
                    />
                  </Field>
                </Flex>
              </>
            )}

            <Flex alignItems='center'>
              <Text marginRight={2} width={120}>
                {t('form.wiegandconfig.oddParity')}
              </Text>
              <Field label={t('form.from')} width='auto' marginRight={2}>
                <Input
                  value={dataForm?.oddParityBit}
                  id='odd-parity'
                  sizeInput='small'
                  maxWidth={50}
                  readOnly
                />
              </Field>
              <Field label={t('form.length')} width='auto'>
                <Input value={1} id='odd-partiy-length' sizeInput='small' maxWidth={50} readOnly />
              </Field>
            </Flex>
          </GridItem>
          <GridItem
            display='grid'
            gridTemplateColumns='repeat(auto-fit, minmax(20px, 1fr))'
            justifyContent='center'
          >
            {boxes.map((element) => (
              <Flex alignItems='center' flexDirection='column' key={element}>
                {element + 1}
                <Flex
                  alignItems='center'
                  justifyContent='center'
                  border='1px black solid'
                  height={20}
                  width={20}
                  backgroundColor={
                    dataForm?.length === 37
                      ? valuesSetterW37(dataForm)[element + 1]
                      : valuesSetter(dataForm)[element + 1]
                  }
                >
                  <Text sizeText='display11' alignText='center' margin='auto'>
                    {element === 0 && 'EP'}
                    {!(dataForm?.siteCodeLength <= element - 1) &&
                    !(element === 0) &&
                    !(element + 1 === dataForm.length)
                      ? valueBinary?.[element - 1] || 0
                      : !(element === 0) && !(element + 1 === dataForm.length)
                      ? 'X'
                      : ''}
                    {element + 1 === dataForm.length && 'OP'}
                  </Text>
                </Flex>
              </Flex>
            ))}
          </GridItem>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Flex alignItems='center' justifyContent='flex-end'>
          <Button
            sizeText='display14'
            backgroundColor={color1}
            // Refact: When we refact save parameters, clean this format
            action={() => {
              onSave({
                target: {
                  id: 'WIEGANDCONFIG',
                  value: JSON.stringify(dataForm),
                },
              });
              onClose();
            }}
            marginRight={2}
          >
            {t('general.save')}
          </Button>
          <Button sizeText='display14' backgroundColor='blackLight' id='cancel-button'>
            {t('general.cancel')}
          </Button>
        </Flex>
      </Modal.Actions>
    </Modal>
  );
};

export default EquipmentConfigurationWiegandModal;
