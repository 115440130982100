import { Box, Grid, GridItem } from '@jcm-technologies/uikit/dist/atoms/Layout';
import { theme } from '@jcm-technologies/uikit/dist/theme';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { base64ToImage, imageFilesToBase64 } from '../../../../core/helpers/getImagesBase64';
import { updateTenants } from '../../../../modules/tenants/actions';
import logoCollapsedDefault from '../../../../static/favicon.png';
import logoBrandDefault from '../../../../static/logo-tmp.png';
import CustomizationLogoBrand from './LogoBrand';
import CustomizationLogoCollapsed from './LogoCollapsed';
import CustomizationBackgroundImage from './BackgroundImage';
import CustomizationColorBrand from './ColorBrand';

const CustomizationLayout = () => {
  const dispatch = useDispatch();
  const tenants = useSelector((state) => state.tenants);
  const { logo1, logo2, color1, backgroundimage } = tenants;
  const [newColor, setNewColor] = useState(color1 || theme.colors.orange);
  const [imgLogo, setImgLogo] = useState([
    { base64: base64ToImage(logo2) || logoCollapsedDefault },
  ]);
  const [imgBackground, setImgBackground] = useState(
    backgroundimage ? [{ base64: backgroundimage }] : []
  );
  const [imgLogoBrand, setImgLogoBrand] = useState([
    { base64: base64ToImage(logo1) || logoBrandDefault },
  ]);

  useEffect(() => {
    setImgBackground(backgroundimage ? [{ base64: backgroundimage }] : []);
  }, [backgroundimage]);

  useEffect(() => {
    setImgLogo([{ base64: base64ToImage(logo2) || logoCollapsedDefault }]);
  }, [logo2]);
  useEffect(() => {
    setImgLogoBrand([{ base64: base64ToImage(logo1) || logoBrandDefault }]);
  }, [logo1]);
  useEffect(() => {
    setNewColor(color1);
  }, [color1]);
  return (
    <Box backgroundColor='white' borderRadius={3} height='100%' paddingTop={2}>
      <Grid
        gridTemplateAreas={[
          "'logoBrand logo' 'backgroundImage colorTheme'",
          "'logoBrand logo' 'backgroundImage colorTheme'",
          "'logoBrand logo' 'backgroundImage colorTheme'",
          "'logoBrand logo backgroundImage colorTheme'",
        ]}
        gridGap={2}
        gridTemplateRows={['1fr 1fr', '1fr 1fr', '1fr 1fr', '1fr']}
        gridTemplateColumns={['1fr 1fr', '1fr 1fr', '1fr 1fr', '1fr 1fr 1fr 1fr']}
      >
        <GridItem gridArea='logoBrand'>
          <CustomizationLogoBrand
            onImagesLoad={(files) => {
              if (files?.[0]?.file) {
                imageFilesToBase64([files?.[0]?.file], false).then((base64File) => {
                  setImgLogoBrand([{ base64: base64File[0] }]);
                  dispatch(updateTenants({ ...tenants, logo1: base64File[0] }));
                });
              }
            }}
            imgLogoBrand={imgLogoBrand}
            setImgLogoBrand={setImgLogoBrand}
            handleImageDelete={() => {
              setImgLogoBrand([{ base64: logoBrandDefault }]);
              dispatch(updateTenants({ ...tenants, logo1: logoBrandDefault }));
            }}
          />
        </GridItem>
        <GridItem gridArea='logo'>
          <CustomizationLogoCollapsed
            onImagesLoad={(files) => {
              if (files?.[0]?.file) {
                imageFilesToBase64([files?.[0]?.file], false).then((base64File) => {
                  setImgLogo([{ base64: base64File[0] }]);
                  dispatch(updateTenants({ ...tenants, logo2: base64File[0] }));
                });
              }
            }}
            imgLogo={imgLogo}
            handleImageDelete={() => {
              setImgLogo([{ base64: logoCollapsedDefault }]);
              dispatch(updateTenants({ ...tenants, logo2: logoCollapsedDefault }));
            }}
          />
        </GridItem>
        <GridItem gridArea='backgroundImage'>
          <CustomizationBackgroundImage
            onImagesLoad={(files) => {
              if (files?.[0]?.file) {
                imageFilesToBase64([files?.[0]?.file], false).then((base64File) => {
                  setImgBackground([{ base64: base64File[0] }]);
                  dispatch(updateTenants({ ...tenants, backgroundimage: base64File[0] }));
                });
              }
            }}
            imgBackground={imgBackground}
            handleImageDelete={() => {
              setImgBackground([]);
              dispatch(updateTenants({ ...tenants, backgroundimage: '' }));
            }}
          />
        </GridItem>
        <GridItem gridArea='colorTheme'>
          <CustomizationColorBrand
            onChangeComplete={(color) => {
              setNewColor(color.hex);
            }}
            newColor={newColor}
            onColorSave={() => dispatch(updateTenants({ ...tenants, color1: newColor }))}
          />
        </GridItem>
      </Grid>
    </Box>
  );
};

export default CustomizationLayout;
