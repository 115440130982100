import { combineReducers } from 'redux';
import a5Kservices from './a5Kservices';
import alert from '../../state/alerts/reducer';
import backendParams from '../../state/pagination/reducer';
import customers from '../../customers/reducer';
import dashboard from '../../dashboard/reducer';
import devices from './devices';
import drivers from './drivers';
import equipments from './equipments';
import errors from './errors';
import families from '../../productFamilies/reducer';
import fobs from '../../group/fobs/reducer';
import formsControl from './formsControl';
import genericGrow from './genericGrow';
import globalSearch from '../../globalSearch/reducer';
import gridControllers from './gridControllers';
import groupUsers from '../../group/users/reducer';
import groups from './groups';
import holidays from '../../holidays/reducer';
import instalationGroups from './instalationGroups';
import instalations from './instalations';
import invitations from '../../invitations/reducer';
import loading from './loading';
import login from './login';
import neighbours from './neighbours';
import notifications from '../../notifications/reducer';
import owners from '../../owner/reducer';
import popup from './popup';
import schedules from '../../schedule/reducer';
import signalR from './signalr';
import signalRNotifications from './signalrFront';
import statistics from '../../statistics/reducer';
import successToaster from './successToaster';
import tenants from '../../tenants/reducer';
import universalGroups from './universalGroups';
import universalGroupsFobs from './universalGroupsFobs';
import user from '../../user/reducer';
import mifare from '../../group/mifare/reducer';
import sidebar from '../../state/sidebar/reducer';
import countries from '../../countries/reducer';
import reducer from '../../state/visibleDescendants/reducer';

export default combineReducers({
  a5Kservices,
  alert,
  app: sidebar,
  backendParams,
  customers,
  countries,
  dashboard,
  devices,
  drivers,
  equipments,
  errors,
  families,
  fobs,
  formsControl,
  genericGrow,
  globalSearch,
  gridControllers,
  groupUsers,
  groups,
  holidays,
  instalationGroups,
  instalations,
  invitations,
  loading,
  login,
  mifare,
  neighbours,
  notifications,
  owners,
  popup,
  schedules,
  signalR,
  signalRNotifications,
  statistics,
  successToaster,
  tenants,
  universalGroups,
  universalGroupsFobs,
  user,
  visibleDescendants: reducer,
});
