import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';

export const createHubConnectionBuilder = (connectionPath, access_token) => {
  const connection = new HubConnectionBuilder()
    .withUrl(connectionPath, {
      accessTokenFactory: () => access_token,
    })
    .configureLogging(LogLevel.Information)
    .withAutomaticReconnect()
    .build();
  return connection;
};

export const addCanalOnHubConnection = (connection, hubCanal, canalFunc) => {
  connection.on(hubCanal, canalFunc);
};

async function startHubConnection(connection, errorFunction) {
  await connection.start().catch((error) => {
    if (errorFunction) {
      errorFunction();
    }
  });
}

export const createSignalRConnection = ({
  connectionPath,
  customerId,
  accessToken,
  hubCanals,
  onConnectionStarts,
  onConnectionError,
}) => {
  const connection = createHubConnectionBuilder(
    `${connectionPath}?customerId=${customerId}`,
    accessToken
  );
  hubCanals.map((canal) => addCanalOnHubConnection(connection, canal.name, canal.function));
  startHubConnection(connection, onConnectionError);
  return connection;
};

export const invokeHubFunction = (connection, callback, functionName, ...params) => {
  connection
    .invoke(functionName, ...params)
    .then((x) => callback(x))
    .catch((error) => console.log(error));
};

export default {
  createHubConnectionBuilder,
  addCanalOnHubConnection,
  startHubConnection,
  createSignalRConnection,
  invokeHubFunction,
};
