export default {
  gsmPopUp: {
    title: 'Stav zařízení',
    deviceNotSimInfo: 'Zařízení je offline',
    simContractInfo: 'Informace o smlouvě',
    expiration: 'Expirace',
    status: 'Stav',
    infoNotAvailable: ' Informace nedostupná',
    simConnectionInfo: 'Informace o spojení SIM',
    cardIdentifier: 'Identifikace karty (ICCID)',
    activationDate: 'Datum aktivace',
    lastMonthDataUpload: 'Upload dat minulého měsíce',
    lastMonthDataDownload: 'Download dat minulého měsíce',
    lastSeen: 'Naposledy sledované',
    contractStatus: 'Stav smlouvy',
    simStatus: 'Stav SIM',
    deviceStatus: 'Stav zařízení',
    enabled: ' Umožněno',
    disabled: 'Neumožněno',
    notAvailable: 'Nedostupné',
    connected: 'Připojeno',
    disconnected: 'Odpojeno',
  },
};
