import createFetchTypes from '../../../core/typeActions/types';
import ugFOBSActions from '../actions/universalGroupsFobs';
import instalationsActions from '../actions/instalations';
import { updateArrayNodeByPropName } from '../../../core/helpers/arrayService';
import { PUT_GROUP_FOBS_IMPORT } from '../../../modules/group/fobs/constants';

export const UNIVERSAL_POST_GROUP_FOBS_FOB = createFetchTypes('DELETE_UNIVERSAL_FOB');
export const UNIVERSAL_POST_GROUP_FOBS_SEQUENTIAL = createFetchTypes(
  'UNIVERSAL_POST_GROUP_FOBS_SEQUENTIAL'
);
export const UNIVERSAL_FOBS_GET = createFetchTypes('UNIVERSAL_FOBS_GET');
export const UNIVERSAL_FOBS_GET_COUNTER = createFetchTypes('UNIVERSAL_FOBS_GET_COUNTER');
export const UNIVERSAL_FOBS_GRID_PUT = createFetchTypes('UNIVERSAL_FOBS_GRID_PUT');
export const UNIVERSAL_FOBS_GRID_IMPORT_PUT = createFetchTypes('UNIVERSAL_FOBS_GRID_IMPORT_PUT');
export const UNIVERSAL_FOBS_COPY = createFetchTypes('UNIVERSAL_FOBS_COPY');
export const UNIVERSAL_DELETE_GROUP_FOBS_LIST = createFetchTypes(
  'UNIVERSAL_DELETE_GROUP_FOBS_LIST'
);
export const UNIVERSAL_FOBS_MOVE_LIST = createFetchTypes('UNIVERSAL_FOBS_MOVE_LIST');
export const UNIVERSAL_DELETE_GROUP_FOBS_FOB = createFetchTypes('UNIVERSAL_DELETE_GROUP_FOBS_FOB');
export const UNIVERSAL_FOB_GET_SUBS = createFetchTypes('UNIVERSAL_FOB_GET_SUBS');
export const UNIVERSAL_FOBS_DELETE_ALARM = createFetchTypes('DELETE_ALARM');
export const UNIVERSAL_FOBS_GET_CHECK_CODES = createFetchTypes('UNIVERSAL_FOBS_GET_CHECK_CODES');

/* Redux state init */
const initialState = {
  fobsCount: undefined,
  universalGroupFobs: undefined,
  isUniversalFobPostedSuccess: false,
  needCallUGFobs: true,
  universalGroupFobSubs: undefined,
  universalConfigFields: undefined,
  codeExists: undefined,
  errorMessage: undefined,
  errorPutUGFobsMessage: undefined,
  needUpdateTableUGFobs: false,
};

const deleteFromArray = (stateArray, newArray) => {
  const arrayCodes = newArray.map((item) => item.e_code);
  return stateArray.filter((item) => !arrayCodes.includes(item.e_code));
};

export default function universalGroupsFobs(state = initialState, action) {
  if (action.type !== undefined) {
    switch (action.type) {
      case UNIVERSAL_FOBS_GET_COUNTER.SUCCESS:
        return { ...state, fobsCount: [...action.result] };
      case UNIVERSAL_FOBS_GET_COUNTER.FAILURE:
        return { ...state, fobsCount: [] };
      case UNIVERSAL_FOBS_GET.REQUEST:
        return { ...state, needCallUGFobs: false };
      case UNIVERSAL_FOBS_GET.SUCCESS:
        return {
          ...state,
          universalGroupFobs: [...action.result.Data],
          universalConfigFields: [...action.result.MetaData],
          needCallUGFobs: true,
        };
      case UNIVERSAL_FOBS_GRID_PUT.SUCCESS:
        return {
          ...state,
          universalGroupFobs: updateArrayNodeByPropName(
            state.universalGroupFobs,
            action.result,
            'id'
          ),
        };
      case UNIVERSAL_FOBS_GRID_PUT.FAILURE:
        return {
          ...state,
          errorPutUGFobsMessage: action.error.response.body.error.code,
        };
      case UNIVERSAL_FOBS_GRID_IMPORT_PUT.SUCCESS:
        return {
          ...state,
          universalGroupFobs: action.result.fobs,
        };
      case UNIVERSAL_FOBS_GRID_IMPORT_PUT.REQUEST:
        return {
          ...state,
          universalGroupFobs: undefined,
        };
      case UNIVERSAL_DELETE_GROUP_FOBS_LIST.SUCCESS:
        return {
          ...state,
          universalGroupFobs: deleteFromArray(state.universalGroupFobs, action.payload[1].data),
        };
      case UNIVERSAL_FOBS_MOVE_LIST.SUCCESS:
        return {
          ...state,
          universalGroupFobs: deleteFromArray(state.universalGroupFobs, action.payload[1].data),
        };
      case UNIVERSAL_DELETE_GROUP_FOBS_FOB.SUCCESS:
        return {
          ...state,
          universalGroupFobs: state.universalGroupFobs.filter(
            (fob) => fob.id !== action.payload[1].data.id
          ),
        };
      case UNIVERSAL_POST_GROUP_FOBS_FOB.SUCCESS:
      case UNIVERSAL_POST_GROUP_FOBS_SEQUENTIAL.SUCCESS:
        return { ...state, isUniversalFobPostedSuccess: true };
      case ugFOBSActions.RESET_IS_POST_UG_FOBS:
        return { ...state, isUniversalFobPostedSuccess: false };
      case instalationsActions.RESET_FOBS_LIST:
        return {
          ...state,
          universalGroupFobs: undefined,
        };
      case PUT_GROUP_FOBS_IMPORT.SUCCESS:
        return { ...state, universalGroupFobs: undefined };
      case UNIVERSAL_FOB_GET_SUBS.SUCCESS:
        return {
          ...state,
          universalGroupFobSubs: [...action.result],
        };
      case UNIVERSAL_FOBS_GET_CHECK_CODES.REQUEST:
        return {
          ...state,
          codeExists: undefined,
        };
      case UNIVERSAL_FOBS_GET_CHECK_CODES.SUCCESS:
        return {
          ...state,
          codeExists: false,
        };
      case UNIVERSAL_FOBS_GET_CHECK_CODES.FAILURE:
        return {
          ...state,
          codeExists: true,
          errorMessage: action.error.response.body.error.code,
        };
      case ugFOBSActions.RESET_UG_CHECKED_FOBS:
        return {
          ...state,
          codeExists: undefined,
          needUpdateTableUGFobs: true,
        };
      case ugFOBSActions.RESET_PUT_UGFOBS_ERROR:
        return {
          ...state,
          errorPutUGFobsMessage: undefined,
        };
      case ugFOBSActions.RESET_UG_FOBS:
        return {
          ...state,
          universalGroupFobs: undefined,
          needUpdateTableUGFobs: true,
        };
      default:
        return state;
    }
  }
  return state;
}
