import React, { Component } from 'react';
import { connect } from 'react-redux';

import { withTranslation } from 'react-i18next';
import { ContentContainer } from '../ContentContainer/ContentContainer';
import { GenericForm } from '../GenericForm/GenericForm';

import apiPathFactory from '../../core/old_common/rooting/apiPathFactory';
import { apiFileCalls, downloadFile } from '../../core/helpers/filesApi';

class PopUpA5KProxy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pinCode: '',
      apn: '',
      apnUser: '',
      apnPassword: '',
      serverURL: '',
    };
  }

  handleChange = (e) => this.setState({ [e.target.id]: e.target.value });

  handleAccept = () => {
    const { closePopup } = this.props;
    const { pinCode, apn, apnUser, apnPassword, serverURL } = this.state;
    const configurationData = {
      pinCode,
      apn,
      apnUser,
      apnPassword,
      serverURL,
    };

    const endpoint = apiPathFactory.a5kPostGetApnConfigFile();

    const callBack = (isError, response) => {
      if (!isError) {
        downloadFile(response, 'Access5KProxyConfig.JCMBin');
      }
      closePopup();
    };
    apiFileCalls({
      path: endpoint,
      data: configurationData,
      method: 'POST',
      callBack,
    });
  };

  getFields = () => {
    const { t } = this.props;
    const fields = [
      [
        {
          type: 'input',
          placeHolder: t('parameters.pinCode'),
          value: this.state.pinCode,
          id: 'pinCode',
          onChange: this.handleChange,
        },
      ],
      [
        {
          type: 'input',
          placeHolder: t('parameters.apn'),
          value: this.state.apn,
          id: 'apn',
          onChange: this.handleChange,
        },
      ],
      [
        {
          type: 'input',
          placeHolder: t('parameters.apnUser'),
          value: this.state.apnUser,
          id: 'apnUser',
          onChange: this.handleChange,
        },
      ],
      [
        {
          type: 'input',
          placeHolder: t('parameters.apnPassword'),
          value: this.state.apnPassword,
          id: 'apnPassword',
          onChange: this.handleChange,
        },
      ],
      [
        {
          type: 'input',
          placeHolder: t('parameters.serverURL'),
          value: this.state.serverURL,
          id: 'serverURL',
          onChange: this.handleChange,
        },
      ],
    ];
    return fields;
  };

  render() {
    const { tenants, t, closePopup } = this.props;
    return (
      <ContentContainer tenants={tenants} title={t('parameters.title')}>
        <GenericForm
          className='adjust-datetime-popup-form'
          tenants={this.props.tenants}
          fields={this.getFields()}
          submitText={t('adjustTimePopUp.save')}
          cancelText={t('changePassword.cancel')}
          handleSubmit={() => this.handleAccept()}
          handleCancel={() => closePopup()}
          resetSuccess={() => ({})}
          isCancelVisible
          formErrors=''
          buttonsCentered
        />
      </ContentContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  tenants: state.tenants,
});

const PopUpA5KProxyTranslation = withTranslation()(PopUpA5KProxy);

export default connect(mapStateToProps, {})(PopUpA5KProxyTranslation);
