import React, { Component } from 'react';

import './CheckableContent.css';
import { Checkbox } from '@jcm-technologies/uikit/dist/atoms/Checkbox';

class CheckableContent extends Component {
  render() {
    const { id, value, checkHidden, onChange, className, checked, isDisabled } = this.props;
    return (
      <div
        className={`checkable-content ${className}${
          isDisabled ? ' checkable-content-disabled' : ''
        }`}
      >
        <span
          className={`checkable-content-label${isDisabled ? ' checkable-content-disabled' : ''}`}
        >
          {value}
        </span>
        {!checkHidden ? (
          <Checkbox
            id={id}
            name={id}
            disabled={isDisabled}
            action={onChange}
            defaultChecked={checked}
          />
        ) : null}
      </div>
    );
  }
}

export default CheckableContent;
