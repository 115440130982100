import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  getUniversalGroups,
  getVisibleDescendants,
  resetUniversalGroup,
  resetUniversalGroupFacilities,
} from '../../modules/old_to_refact/actions/universalGroups';
import {
  setSearchQuery,
  setSelectedDescendants,
} from '../../modules/state/visibleDescendants/actions';
import GenericList from '../GenericList';
import UniversalGroupGrow from '../UniversalGroupGrow';
import AddButton from '../AddButton';
import { CreationPath_UG, RootPath_UG } from '../../core/old_common/constats/genericListPaths';
import { UG_DESCENDANT } from '../../modules/state/visibleDescendants/constants';
import SelectableFilters from '../SelectableFilters';
import {
  deleteCookie_selectedItemCustomerId,
  getCookieUserRoles,
} from '../../core/old_common/utils/cookiesManager';
import { UG_LIST } from '../../modules/state/pagination/constants';

export class UniversalGroupsList extends Component {
  componentDidMount = () => {
    const {
      getUniversalGroups,
      visibleDescendants,
      getVisibleDescendants,
      resetUniversalGroup,
      resetUniversalGroupFacilities,
      isAuth,
    } = this.props;
    if (!visibleDescendants && isAuth) {
      getVisibleDescendants();
    }
    if (isAuth) {
      getUniversalGroups();
      resetUniversalGroup();
      resetUniversalGroupFacilities();
      deleteCookie_selectedItemCustomerId();
      this.props.setSearchQuery(this.props.searchQuery, !!this.props.searchQuery);
    }
  };

  // TODO: Refact. If user go direct with /UniversalGroups url and not logged, redirect to login, when return
  // have syncro problem, we need Auth before GETS
  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      getUniversalGroups,
      visibleDescendants,
      getVisibleDescendants,
      resetUniversalGroup,
      resetUniversalGroupFacilities,
      isAuth,
    } = this.props;
    // TODO: Refact. If user go direct with /UniversalGroups url and not logged, redirect to login, when return
    // have syncro problem, we need Auth before GETS
    if (!prevProps.isAuth && isAuth) {
      if (!visibleDescendants && isAuth) {
        getVisibleDescendants();
      }
      if (isAuth) {
        getUniversalGroups();
        resetUniversalGroup();
        resetUniversalGroupFacilities();
        deleteCookie_selectedItemCustomerId();
        this.props.setSearchQuery(this.props.searchQuery, !!this.props.searchQuery);
      }
    }
  }

  handleSelectionChange = (value) => {
    const { setSelectedDescendants, getUniversalGroups } = this.props;
    // filter type (universal group)
    setSelectedDescendants(UG_DESCENDANT, value);
    getUniversalGroups();
  };

  handleSearchQueryChange = (e) => {
    // filter type (schedules)
    const { setSearchQuery } = this.props;
    setSearchQuery(
      e.currentTarget.value.replace(/%23/g, '#').replace(/%26/g, '&') || '',
      !!e.currentTarget.value
    );
  };

  handlePageChange = () => {
    const { getUniversalGroups } = this.props;
    getUniversalGroups();
  };

  getCustomStyles = () => {
    const { tenants } = this.props;
    const customStyles = {
      multiValue: (provided, state) => ({
        ...provided,
        background: tenants.color1,
        color: 'white',
      }),
      multiValueLabel: (provided, state) => ({
        ...provided,
        color: 'white',
      }),
    };
    return customStyles;
  };

  getVisibleDescendants = () => {
    const { visibleDescendants, t } = this.props;
    let visibleDesc = [];
    if (visibleDescendants) {
      visibleDesc = visibleDescendants.map((vDes) => ({ label: vDes.name, value: vDes.id }));
      visibleDesc.unshift({
        label: t('globalSearch.allOwners'),
        value: 'ffffffff-ffff-ffff-ffff-ffffffffffff',
      });
    }
    return visibleDesc;
  };

  render() {
    const {
      universalGroups,
      availableSpots,
      t,
      selectedDescendants,
      history,
      searchQuery,
      getUniversalGroups,
    } = this.props;
    const role = getCookieUserRoles();
    const getTitleLeft = () => {
      if (role.mask === 512) {
        if (!availableSpots) {
          return t('general.newEntityAvailable.zero', {
            entity: t('title.groups').toLowerCase(),
          });
        }
        if (availableSpots < 5) {
          return t('general.newEntityAvailable.one', {
            entity: t('title.groups').toLowerCase(),
            count: availableSpots,
          });
        }
        return '';
      }

      return '';
    };
    return (
      <>
        <SelectableFilters
          descendatsOptions={this.getVisibleDescendants()}
          selectedDescendants={selectedDescendants}
          onChangeDescendants={(e) => this.handleSelectionChange(e)}
          renderDescendants={!(role?.mask === 8 || role?.mask === 16)}
          onChangeSearchQuery={(e) => this.handleSearchQueryChange(e)}
          searchQuery={searchQuery}
          onSubmit={this.props.getUniversalGroups}
          renderSearch
          customStyles={this.getCustomStyles()}
        />
        <GenericList
          dataList={universalGroups}
          CustomGrow={UniversalGroupGrow}
          handlePageChange={this.handlePageChange}
          baseURI={RootPath_UG}
          creationURI={CreationPath_UG}
          listType={UG_LIST}
          getList={getUniversalGroups}
          titleLeftMessage={getTitleLeft()}
          groupByProp='customerName'
        />
        <AddButton
          id='add'
          disabled={role.mask === 512 && !availableSpots}
          toolTip={t('universalGroups.add')}
          history={history}
          url={`${RootPath_UG}/${CreationPath_UG}`}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  tenants: state.tenants,
  isAuth: !!state.login.userId,
  availableSpots: state.universalGroups.availableSpots,
  universalGroups: state.universalGroups.universalGroups,
  selectedDescendants: state.visibleDescendants.selectedDescendants[UG_DESCENDANT],
  visibleDescendants: state.universalGroups.visibleDescendants,
  searchQuery: state.visibleDescendants.searchQuery,
});

const UniversalGroupsListPage = withTranslation()(UniversalGroupsList);

export default connect(mapStateToProps, {
  getUniversalGroups,
  getVisibleDescendants,
  setSelectedDescendants,
  setSearchQuery,
  resetUniversalGroup,
  resetUniversalGroupFacilities,
})(UniversalGroupsListPage);
