import { createAction } from 'redux-actions';

const RE_LOGIN_POST = 'RE_LOGIN_POST';
const POST_RESET_PASSWORD = 'POST_RESET_PASSWORD';
const POST_CHANGE_PASSWORD = 'POST_CHANGE_PASSWORD';
const POST_ACCOUNT_VERSION = 'POST_ACCOUNT_VERSION';
const RESET_TIMEOUTS = 'RESET_TIMEOUTS';
const SET_GDPR_IS_OPEN = 'SET_GDPR_IS_OPEN';
const SET_VERSION_POPUP_IS_OPEN = 'SET_VERSION_POPUP_IS_OPEN';

export default {
  RE_LOGIN_POST,
  POST_RESET_PASSWORD,
  POST_CHANGE_PASSWORD,
  POST_ACCOUNT_VERSION,
  RESET_TIMEOUTS,
  SET_GDPR_IS_OPEN,
  SET_VERSION_POPUP_IS_OPEN,
};

export const sendReLogin = createAction(RE_LOGIN_POST, (refreshToken) => ({
  refreshToken,
}));

export const sendResetPassword = createAction(
  POST_RESET_PASSWORD,
  (resetPassowordDatas) => resetPassowordDatas
);

export const postChangePassword = createAction(POST_CHANGE_PASSWORD, (datas) => datas);

export const postAccountVersion = (version) => ({ type: POST_ACCOUNT_VERSION, version });

export const resetTimeouts = () => ({ type: RESET_TIMEOUTS });

export const setGDPRPopupIsOpen = (isOpen) => ({ type: SET_GDPR_IS_OPEN, isOpen });

export const setVersionPopupIsOpen = (isOpen) => ({ type: SET_VERSION_POPUP_IS_OPEN, isOpen });
