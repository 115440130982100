export default {
  neighbourPopUp: {
    title: 'Ajouter un voisin',
    email: 'E-mail',
    phoneNumber: 'Numéro de téléphone',
    floor: 'Sol',
    door: 'Porte',
    titleEdit: 'Modifier le contact',
    titleDelete: 'Voulez-vous vraiment supprimer le contact?',
  },
};
