export default {
  universalGroups: {
    description: 'Descripción',
    informationTab: 'Información',
    expand: 'Expandir',
    collapse: 'Colapsar',
    delete: 'Eliminar',
    add: 'Crear nuevo grupo universal',
    create: 'Crear grupo universal',
    instalationsTab: 'Instalaciones',
    fobsTab: 'Emisores',
    deleteConfirmation: '¿Seguro que quieres eliminar este grupo?',
  },
};
