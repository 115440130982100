const hexToRGB = (hexColor, percent = 1) => {
  if (hexColor.indexOf('#') === 0) {
    hexColor = hexColor.slice(1);
  }
  if (hexColor.length === 3) {
    hexColor = hexColor[0] + hexColor[0] + hexColor[1] + hexColor[1] + hexColor[2] + hexColor[2];
  }
  if (hexColor.length !== 6) {
    throw new Error('Invalid HEX color.');
  }
  const r = parseInt(hexColor.substring(0, 2), 16);
  const g = parseInt(hexColor.substring(2, 4), 16);
  const b = parseInt(hexColor.substring(4, 6), 16);
  return { r, g, b, percent };
};

const padZero = (str, len) => {
  len = len || 2;
  const zeros = new Array(len).join('0');
  return (zeros + str).slice(-len);
};

const RGBToHex = ({ r, g, b }) => `#${padZero(r)}${padZero(g)}${padZero(b)}`;

const brightness = ({ r, g, b }) => (r * 299 + g * 587 + b * 114) / 1000;

const brighterThan = (rgbColor, x) => {
  const bright = brightness(rgbColor);
  return bright > x;
};

export const invertColor = (hex) => {
  if (hex !== undefined) {
    const rgbColor = hexToRGB(hex);
    const r = (255 - rgbColor.r).toString(16);
    const g = (255 - rgbColor.g).toString(16);
    const b = (255 - rgbColor.b).toString(16);
    return RGBToHex({ r, g, b });
  }
  return hex;
};

export const getContrastColor = (hexColor) => {
  if (hexColor !== undefined) {
    const rgbColor = hexToRGB(hexColor);
    let text_color = 'rgb(255,255,255)';

    if (brighterThan(rgbColor, 150)) {
      text_color = 'rgb(0,0,0)';
    }
    return text_color;
  }
  return hexColor;
};

export const increaseBrightness = (hex, percent) => {
  if (hex !== undefined) {
    const rgb = hexToRGB(hex);

    return `#${(0 | ((1 << 8) + rgb.r + ((256 - rgb.r) * percent) / 100)).toString(16).substr(1)}${(
      0 |
      ((1 << 8) + rgb.g + ((256 - rgb.g) * percent) / 100)
    )
      .toString(16)
      .substr(1)}${(0 | ((1 << 8) + rgb.b + ((256 - rgb.b) * percent) / 100))
      .toString(16)
      .substr(1)}`;
  }
  return hex;
};

export const getTransparentColor = (hex, percent) => {
  if (hex !== undefined) {
    const rgb = hexToRGB(hex);
    return `rgba(${rgb.r}, ${rgb.r}, ${rgb.b}, ${percent})`;
  }
  return hex;
}

export const verifyHexCode = (code) => /(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i.test(code);

export default {
  getContrastColor,
  invertColor,
  increaseBrightness,
  verifyHexCode,
  getTransparentColor
};
