// MSAL configuration
export const msalConfig = {
  auth: {
    clientId: process?.env.REACT_APP_B2C_FE_ID,
    postLogoutRedirectUri: `http://${process?.env.REACT_APP_B2C_NAME}.b2clogin.com/${process?.env.REACT_APP_B2C_NAME}.onmicrosoft.com/B2C_1A_SIGNUP_SIGNIN_JCM/oauth2/v2.0/logout?post_logout_redirect_uri=${process?.env?.REACT_APP_FE_BASE}/login`,
    redirectUri: `${process?.env?.REACT_APP_FE_BASE}/dashboard`,
    knownAuthorities: [`${process?.env.REACT_APP_B2C_NAME}.b2clogin.com`],
    authority: `https://${process?.env.REACT_APP_B2C_NAME}.b2clogin.com/${process?.env.REACT_APP_B2C_NAME}.onmicrosoft.com/b2c_1a_signup_signin_jcm`,
    responseType: 'code id_token',
  },
};

export const loginRequest = {
  scopes: [
    `https://${process?.env.REACT_APP_B2C_NAME}.onmicrosoft.com/${process?.env.REACT_APP_B2C_API_ID}/readwrite`,
    'openid',
    'offline_access ',
  ],
  loginHint: '',
};

export const passwordResetRequest = {
  authority: `https://${process?.env.REACT_APP_B2C_NAME}.b2clogin.com/${process?.env.REACT_APP_B2C_NAME}.onmicrosoft.com/b2c_1a_passwordreset_jcm`,
};

export const editProfileRequest = {
  authority: `https://${process?.env.REACT_APP_B2C_NAME}.b2clogin.com/${process?.env.REACT_APP_B2C_NAME}.onmicrosoft.com/B2C_1A_PROFILEEDIT_JCM`,
};

export const passwordChangeRequest = {
  authority: `https://${process?.env.REACT_APP_B2C_NAME}.b2clogin.com/${process?.env.REACT_APP_B2C_NAME}.onmicrosoft.com/B2C_1A_ProfileEdit_PasswordChange_jcm`,
};
