import '@progress/kendo-theme-default/dist/all.css';
import React, { Component } from 'react';
import Dropdown from '../Dropdown';

class DropDownCell extends Component {
  handleChange = (e, props) => {
    const { onBlur, onChangeSave, dataItem, datas, setValueOnChange } = this.props;
    const newItem = {
      dataItem: this.props.dataItem,
      field: this.props.field,
      syntheticEvent: e.syntheticEvent,
      value: setValueOnChange ? setValueOnChange(datas, e.target.value) : e.target.value,
    };
    this.props.onChange(newItem);
    onChangeSave({ ...dataItem, value: e.target.value });
    onBlur(e, newItem);
  };

  render() {
    const { datas, layout, onEdit, dataItem, field, editField, onKeyDown, onBlur, isDisabled } =
      this.props;
    const value = dataItem[field];
    if (!dataItem.inEdit || field !== editField) {
      return <td onClick={() => onEdit(dataItem, field)}>{layout}</td>;
    }
    return (
      <td>
        <Dropdown
          style={{ width: '120px' }}
          onChange={this.handleChange}
          value={value}
          values={datas}
          valueField='value'
          textField='text'
          onKeyDown={onKeyDown}
          isDisabled={isDisabled}
        />
      </td>
    );
  }
}

export default DropDownCell;
