const CookiesPolicyDictionary = [];
CookiesPolicyDictionary.default = 'https://www.jcm-tech.com/cloudassistant-cookies-policy/';
CookiesPolicyDictionary['es-ES'] =
  'https://www.jcm-tech.com/es/cloudassistant-politica-de-cookies/';
CookiesPolicyDictionary['en-GB'] = 'https://www.jcm-tech.com/cloudassistant-cookies-policy/';
CookiesPolicyDictionary['de-DE'] = 'https://www.jcm-tech.com/de/cloudassistant-cookie-richtlinie/';
CookiesPolicyDictionary['fr-FR'] =
  'https://www.jcm-tech.com/fr/cloudassistant-politique-de-cookies/';
CookiesPolicyDictionary['it-IT'] =
  'https://www.jcm-tech.com/it/cloudassistant-politica-dei-cookie/';

export default CookiesPolicyDictionary;
