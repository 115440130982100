import React from 'react';
import { useSelector } from 'react-redux';
import './ImageFileInput.css';
import { Button } from '@jcm-technologies/uikit/dist/atoms/Button';
import { Flex } from '@jcm-technologies/uikit/dist/atoms/Layout';
import { Icon } from '@jcm-technologies/uikit/dist/atoms/Icon';
import IconTrash from '@jcm-technologies/uikit/dist/atoms/Icons/IconTrash';
import styled from 'styled-components';
import css from '@styled-system/css';
import { loadFile } from '../../core/helpers/filesApi';
import { getContrastColor } from '../../core/helpers/colorManager';

const IconStyled = styled(Icon)(
  css({
    ':hover': {
      stroke: 'red',
      fill: 'red',
      cursor: 'pointer',
    },
  })
);

const ImageFileInput = ({
  id,
  value,
  placeHolder,
  height,
  imageSrc,
  defaultImage,
  customCSS,
  customButtonCSS,
  canRemove,
  handleRemove,
  onChange,
}) => {
  const tenants = useSelector((state) => state.tenants);
  return (
    <Flex className={`${customCSS || ''}`} flexDirection='column' width='auto' minHeight={150}>
      <Flex
        className='imageFileInputImageContainer'
        justifyContent='center'
        alignItems='center'
        flexGrow={1}
      >
        <img alt='' className='fileImage' height={height} src={imageSrc || defaultImage} />
      </Flex>
      {canRemove && imageSrc ? (
        <Flex justifyContent='flex-end' marginY={1} marginX={2} align-self='stretch'>
          <IconStyled sizeIcon='display24' color='blackLight'>
            <IconTrash onClick={(e) => handleRemove(id, e)} />
          </IconStyled>
        </Flex>
      ) : null}
      <Flex
        className={`imageFileInputButtonContainer ${customButtonCSS || ''}`}
        width='100%'
        justifyContent='center'
      >
        <Button
          fullWidth
          backgroundColor={tenants?.color1}
          action={() => loadFile({ callBack: onChange, accept: 'image/*', id })}
          sizeButton='small'
          color={getContrastColor(tenants?.color1)}
        >
          {placeHolder}
        </Button>
      </Flex>
    </Flex>
  );
};

export default ImageFileInput;
