import { call, fork, select, takeLatest } from 'redux-saga/effects';
import fetchEntitySaga from '../old_to_refact/sagas/utils/fetchEntitySaga';
import apiPathFactory from '../../core/old_common/rooting/apiPathFactory';
import api from '../../core/helpers/api';
import { SCHEDULE_DESCENDANT } from '../state/visibleDescendants/constants';
import { getCookiePageSizeItems } from '../../core/old_common/utils/cookiesManager';
import { SCHEDULE_LIST } from '../state/pagination/constants';
import {
  DEL_SCHEDULE,
  GET_DUPLICATE_SCHEDULE,
  GET_SCHEDULE,
  GET_SCHEDULES,
  GET_SCHEDULES_ASSIGNED_FACILITIES,
  GET_SCHEDULES_SINGLE_LIST,
  POST_SCHEDULES,
  PUT_SCHEDULE,
} from './contants';
import { convertDateTimeForBackend } from '../../core/old_common/utils/dataService';

export const getSchedules = {
  base: () => ({
    type: GET_SCHEDULES.BASE,
  }),
  request: () => ({ type: GET_SCHEDULES.REQUEST }),
  success: (result) => ({ type: GET_SCHEDULES.SUCCESS, result }),
  failure: (error) => ({ type: GET_SCHEDULES.FAILURE, error }),
};

export const getDuplicateSchedules = {
  base: (scheduleId) => ({
    type: GET_DUPLICATE_SCHEDULE.BASE,
    scheduleId,
  }),
  request: () => ({ type: GET_DUPLICATE_SCHEDULE.REQUEST }),
  success: (result) => ({ type: GET_DUPLICATE_SCHEDULE.SUCCESS, result }),
  failure: (error) => ({ type: GET_DUPLICATE_SCHEDULE.FAILURE, error }),
};

export const getSchedulesAssignedFacilities = {
  base: (id) => ({
    type: GET_SCHEDULES_ASSIGNED_FACILITIES.BASE,
    id,
  }),
  request: () => ({ type: GET_SCHEDULES_ASSIGNED_FACILITIES.REQUEST }),
  success: (result) => ({ type: GET_SCHEDULES_ASSIGNED_FACILITIES.SUCCESS, result }),
  failure: (error) => ({ type: GET_SCHEDULES_ASSIGNED_FACILITIES.FAILURE, error }),
};

export const getSchedule = {
  base: (scheduleId) => ({
    type: GET_SCHEDULE.BASE,
    scheduleId,
  }),
  request: () => ({ type: GET_SCHEDULE.REQUEST }),
  success: (result) => ({ type: GET_SCHEDULE.SUCCESS, result }),
  failure: (error) => ({ type: GET_SCHEDULE.FAILURE, error }),
};

export const putSchedules = {
  base: () => ({
    type: PUT_SCHEDULE.BASE,
  }),
  request: () => ({ type: PUT_SCHEDULE.REQUEST }),
  success: (result) => ({ type: PUT_SCHEDULE.SUCCESS, result }),
  failure: (error) => ({ type: PUT_SCHEDULE.FAILURE, error }),
};

export const postSchedules = {
  base: () => ({
    type: POST_SCHEDULES.BASE,
  }),
  request: () => ({ type: POST_SCHEDULES.REQUEST }),
  success: (result) => ({ type: POST_SCHEDULES.SUCCESS, result }),
  failure: (error) => ({ type: POST_SCHEDULES.FAILURE, error }),
};

export const delSchedules = {
  base: () => ({
    type: DEL_SCHEDULE.BASE,
  }),
  request: () => ({ type: DEL_SCHEDULE.REQUEST }),
  success: (result) => ({ type: DEL_SCHEDULE.SUCCESS, result }),
  failure: (error) => ({ type: DEL_SCHEDULE.FAILURE, error }),
};

export const getSchedulesSingleList = {
  base: (facilityId) => ({
    type: GET_SCHEDULES_SINGLE_LIST.BASE,
    facilityId,
  }),
  request: (facilityId) => ({ type: GET_SCHEDULES_SINGLE_LIST.REQUEST, facilityId }),
  success: (result) => ({ type: GET_SCHEDULES_SINGLE_LIST.SUCCESS, result }),
  failure: (error) => ({ type: GET_SCHEDULES_SINGLE_LIST.FAILURE, error }),
};

export function* getSchedulesSaga() {
  try {
    const state = yield select();
    const selectedDescendants = state.visibleDescendants.selectedDescendants[SCHEDULE_DESCENDANT];
    const searchQuery = state.visibleDescendants.searchQuery
      .replace(/\#/g, '%23')
      .replace(/\&/g, '%26');
    const { backendParams } = state;
    const pagesize = getCookiePageSizeItems() || backendParams.pagesize;
    const url = apiPathFactory.schedulesGetPage(pagesize, backendParams.pagenumber[SCHEDULE_LIST], {
      selectedDescendants,
      searchQuery,
    });
    const response = yield call(() => fetchEntitySaga(getSchedules, api.get, url));
    return response;
  } catch (error) {
    return error;
  }
}

export function* getScheduleToDuplicateSaga({ scheduleId }) {
  try {
    const url = apiPathFactory.scheduleGet(scheduleId);
    const response = yield call(() => fetchEntitySaga(getDuplicateSchedules, api.get, url));
    return response;
  } catch (error) {
    return error;
  }
}

export function* getSchedulesSingleListSaga({ facilityId }) {
  try {
    const url = apiPathFactory.schedulesSingleListGetPage(facilityId);
    const response = yield call(() => fetchEntitySaga(getSchedulesSingleList, api.get, url));
    return response;
  } catch (error) {
    return error;
  }
}

export function* getSchedulesAssignedFacilitiesSaga({ id }) {
  try {
    const url = apiPathFactory.schedulesGetAssignedFacilities(id);
    const response = yield call(() =>
      fetchEntitySaga(getSchedulesAssignedFacilities, api.get, url)
    );
    return response;
  } catch (error) {
    return error;
  }
}

export function* getScheduleSaga({ scheduleId }) {
  try {
    const url = apiPathFactory.scheduleGet(scheduleId);
    const response = yield call(() => fetchEntitySaga(getSchedule, api.get, url));
    return response;
  } catch (error) {
    return error;
  }
}

export function* postSchedulesSaga({ schedule }) {
  try {
    const url = apiPathFactory.schedulesPost();
    const response = yield call(() =>
      fetchEntitySaga(postSchedules, api.post, url, { data: schedule })
    );
    return response;
  } catch (error) {
    return error;
  }
}

export function* putSchedulesSaga({ schedule }) {
  try {
    const url = apiPathFactory.schedulesPut(schedule.id);
    if (schedule && schedule.scheduleEntries) {
      schedule.scheduleEntries = schedule.scheduleEntries.map((entity) => {
        if (!entity.from) {
          entity.from = entity.start.replace(/z/, '').replace(/Z/, '');
          entity.to = entity.end.replace(/z/, '').replace(/Z/, '');
        } else {
          entity.from = entity.from.replace(/z/, '').replace(/Z/, '');
          entity.to = entity.to.replace(/z/, '').replace(/Z/, '');
        }
        entity.start = convertDateTimeForBackend(entity.start);
        entity.end = convertDateTimeForBackend(entity.end);
        return entity;
      });
    }
    const response = yield call(() =>
      fetchEntitySaga(putSchedules, api.put, url, { data: schedule })
    );
    return response;
  } catch (error) {
    return error;
  }
}

export function* delSchedulesSaga({ id }) {
  try {
    const url = apiPathFactory.schedulesDelete(id.id);
    const response = yield call(() => fetchEntitySaga(delSchedules, api.del, url));
    yield getSchedulesSaga();
    return response;
  } catch (error) {
    return error;
  }
}

function* watchGetSchedules() {
  yield takeLatest(GET_SCHEDULES.BASE, getSchedulesSaga);
}

function* watchPutSchedules() {
  yield takeLatest(PUT_SCHEDULE.BASE, putSchedulesSaga);
}

function* watchDelSchedules() {
  yield takeLatest(DEL_SCHEDULE.BASE, delSchedulesSaga);
}

function* watchPostSchedules() {
  yield takeLatest(POST_SCHEDULES.BASE, postSchedulesSaga);
}

function* watchGetSchedulesSingleList() {
  yield takeLatest(GET_SCHEDULES_SINGLE_LIST.BASE, getSchedulesSingleListSaga);
}

function* watchGetSchedule() {
  yield takeLatest(GET_SCHEDULE.BASE, getScheduleSaga);
}

function* watchGetScheduleToDuplicate() {
  yield takeLatest(GET_DUPLICATE_SCHEDULE.BASE, getScheduleToDuplicateSaga);
}

function* watchGetScheduleAssignedFacilities() {
  yield takeLatest(GET_SCHEDULES_ASSIGNED_FACILITIES.BASE, getSchedulesAssignedFacilitiesSaga);
}

export default function* watchers() {
  yield fork(watchGetSchedules);
  yield fork(watchGetSchedule);
  yield fork(watchGetScheduleToDuplicate);
  yield fork(watchPutSchedules);
  yield fork(watchDelSchedules);
  yield fork(watchPostSchedules);
  yield fork(watchGetSchedulesSingleList);
  yield fork(watchGetScheduleAssignedFacilities);
}
