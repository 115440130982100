import { call, fork, select, takeLatest } from 'redux-saga/effects';
import universalGroupsActions from '../actions/universalGroups';
import fetchEntitySaga from './utils/fetchEntitySaga';
import apiPathFactory from '../../../core/old_common/rooting/apiPathFactory';
import api from '../../../core/helpers/api';
import {
  UNIVERSAL_GROUP_CREATE,
  UNIVERSAL_GROUP_DELETE,
  UNIVERSAL_GROUP_EQUIPMENT_GET,
  UNIVERSAL_GROUP_FACILITY_DELETE,
  UNIVERSAL_GROUP_GET,
  UNIVERSAL_GROUP_GET_INFO,
  UNIVERSAL_GROUP_GET_LIST,
  UNIVERSAL_GROUP_INSTALATIONS_PUT,
  UNIVERSAL_GROUP_PUT,
  UNIVERSAL_GROUP_SET,
  UNIVERSAL_GROUPS_EQUIPMENT_RELAYS_GET,
  UNIVERSAL_GROUPS_FACILITIES,
  UNIVERSAL_GROUPS_SINGLELIST_GET,
  VISIBLE_DESCENDANTS_GET,
} from '../reducers/universalGroups';
import { FACILITY_DESCENDANT, UG_DESCENDANT } from '../../state/visibleDescendants/constants';
import { getCookiePageSizeItems } from '../../../core/old_common/utils/cookiesManager';
import {
  FACILITY_UG_LIST,
  UG_FACILITIES_LIST,
  UG_FACILITIES_LIST_SELECTION,
  UG_LIST,
} from '../../state/pagination/constants';

export const putUniversalGroup = {
  base: (UniversalGroup) => ({
    type: UNIVERSAL_GROUP_PUT.BASE,
    UniversalGroup,
  }),
  request: () => ({ type: UNIVERSAL_GROUP_PUT.REQUEST }),
  success: (result) => ({ type: UNIVERSAL_GROUP_PUT.SUCCESS, result }),
  failure: (error) => ({ type: UNIVERSAL_GROUP_PUT.FAILURE, error }),
};

export const getFacilities = {
  base: (universalGroupId, singleList, allocated) => ({
    type: UNIVERSAL_GROUPS_FACILITIES.BASE,
    universalGroupId,
    singleList,
    allocated,
  }),
  request: () => ({ type: UNIVERSAL_GROUPS_FACILITIES.REQUEST }),
  success: (result) => ({ type: UNIVERSAL_GROUPS_FACILITIES.SUCCESS, result }),
  failure: (error) => ({ type: UNIVERSAL_GROUPS_FACILITIES, error }),
};

export const getUniversalGroups = {
  base: () => ({
    type: UNIVERSAL_GROUP_GET_LIST.BASE,
  }),
  request: () => ({ type: UNIVERSAL_GROUP_GET_LIST.REQUEST }),
  success: (result) => ({ type: UNIVERSAL_GROUP_GET_LIST.SUCCESS, result }),
  failure: (error) => ({ type: UNIVERSAL_GROUP_GET_LIST.FAILURE, error }),
};

export const getUniversalGroupsSingleList = {
  base: (id) => ({
    type: UNIVERSAL_GROUPS_SINGLELIST_GET.BASE,
    id,
  }),
  request: () => ({ type: UNIVERSAL_GROUPS_SINGLELIST_GET.REQUEST }),
  success: (result) => ({ type: UNIVERSAL_GROUPS_SINGLELIST_GET.SUCCESS, result }),
  failure: (error) => ({ type: UNIVERSAL_GROUPS_SINGLELIST_GET.FAILURE, error }),
};

export const getUniversalGroup = {
  base: (id) => ({
    type: UNIVERSAL_GROUP_GET.BASE,
    id,
  }),
  request: () => ({ type: UNIVERSAL_GROUP_GET.REQUEST }),
  success: (result, payload) => ({ type: UNIVERSAL_GROUP_GET.SUCCESS, result, payload }),
  failure: (error) => ({ type: UNIVERSAL_GROUP_GET.FAILURE, error }),
};

export const getUniversalGroupsEquipmentRelays = {
  base: (facilityId) => ({
    type: UNIVERSAL_GROUPS_EQUIPMENT_RELAYS_GET.BASE,
    facilityId,
  }),
  request: () => ({ type: UNIVERSAL_GROUPS_EQUIPMENT_RELAYS_GET.REQUEST }),
  success: (result) => ({ type: UNIVERSAL_GROUPS_EQUIPMENT_RELAYS_GET.SUCCESS, result }),
  failure: (error) => ({ type: UNIVERSAL_GROUPS_EQUIPMENT_RELAYS_GET.FAILURE, error }),
};

export const putInstalationsUG = {
  base: () => ({
    type: UNIVERSAL_GROUP_INSTALATIONS_PUT.BASE,
  }),
  request: () => ({ type: UNIVERSAL_GROUP_INSTALATIONS_PUT.REQUEST }),
  success: (result) => ({ type: UNIVERSAL_GROUP_INSTALATIONS_PUT.SUCCESS, result }),
  failure: (error) => ({ type: UNIVERSAL_GROUP_INSTALATIONS_PUT.FAILURE, error }),
};

export const getUniversalGroupsInfo = {
  base: () => ({
    type: UNIVERSAL_GROUP_GET_INFO.BASE,
  }),
  request: () => ({ type: UNIVERSAL_GROUP_GET_INFO.REQUEST }),
  success: (result) => ({ type: UNIVERSAL_GROUP_GET_INFO.SUCCESS, result }),
  failure: (error) => ({ type: UNIVERSAL_GROUP_GET_INFO.FAILURE, error }),
};

export const getVisibleDescendants = {
  base: () => ({
    type: VISIBLE_DESCENDANTS_GET.BASE,
  }),
  request: () => ({ type: VISIBLE_DESCENDANTS_GET.REQUEST }),
  success: (result) => ({ type: VISIBLE_DESCENDANTS_GET.SUCCESS, result }),
  failure: (error) => ({ type: VISIBLE_DESCENDANTS_GET.FAILURE, error }),
};

export const setUniversalGroup = {
  base: (UniversalGroup) => ({
    type: UNIVERSAL_GROUP_SET.BASE,
    UniversalGroup,
  }),
  request: () => ({ type: UNIVERSAL_GROUP_SET.REQUEST }),
  success: (result, payload) => ({ type: UNIVERSAL_GROUP_SET.SUCCESS, result, payload }),
  failure: (error) => ({ type: UNIVERSAL_GROUP_SET.FAILURE, error }),
};

export const createUniversalGroup = {
  base: (UniversalGroup) => ({
    type: UNIVERSAL_GROUP_CREATE.BASE,
    UniversalGroup,
  }),
  request: () => ({ type: UNIVERSAL_GROUP_CREATE.REQUEST }),
  success: (result, payload) => ({ type: UNIVERSAL_GROUP_CREATE.SUCCESS, result, payload }),
  failure: (error) => ({ type: UNIVERSAL_GROUP_CREATE.FAILURE, error }),
};

export const deleteUniversalGroup = {
  base: (id) => ({
    type: UNIVERSAL_GROUP_DELETE.BASE,
    id,
  }),
  request: () => ({ type: UNIVERSAL_GROUP_DELETE.REQUEST }),
  success: (result, payload) => ({ type: UNIVERSAL_GROUP_DELETE.SUCCESS, result, payload }),
  failure: (error) => ({ type: UNIVERSAL_GROUP_DELETE.FAILURE, error }),
};

export const deleteFacilityUniversalGroup = {
  base: (id) => ({
    type: UNIVERSAL_GROUP_FACILITY_DELETE.BASE,
    id,
  }),
  request: () => ({ type: UNIVERSAL_GROUP_FACILITY_DELETE.REQUEST }),
  success: (result, payload) => ({
    type: UNIVERSAL_GROUP_FACILITY_DELETE.SUCCESS,
    result,
    payload,
  }),
  failure: (error) => ({ type: UNIVERSAL_GROUP_FACILITY_DELETE.FAILURE, error }),
};

export const getUGRelays = {
  base: () => ({
    type: UNIVERSAL_GROUP_EQUIPMENT_GET.BASE,
  }),
  request: () => ({ type: UNIVERSAL_GROUP_EQUIPMENT_GET.REQUEST }),
  success: (result) => ({ type: UNIVERSAL_GROUP_EQUIPMENT_GET.SUCCESS, result }),
  failure: (error) => ({ type: UNIVERSAL_GROUP_EQUIPMENT_GET.FAILURE, error }),
};

// -----------------------------------------------------------------------------

export function* putUniversalGroupSaga({ UniversalGroup }) {
  try {
    const url = apiPathFactory.universalGroupsPut(UniversalGroup.id);
    const response = yield call(() =>
      fetchEntitySaga(putUniversalGroup, api.put, url, { data: UniversalGroup })
    );
    return response;
  } catch (error) {
    return error;
  }
}

export function* getUniversalGroupsInfoSaga({ facilityId, singleList, allocated }) {
  try {
    const url = apiPathFactory.universalGroupsGetInfo(facilityId, true, true);
    const response = yield call(() => fetchEntitySaga(getUniversalGroupsInfo, api.get, url));
    return response;
  } catch (error) {
    return error;
  }
}

export function* getUniversalGroupsSingleListSaga({ id, exceptMe }) {
  try {
    const state = yield select();
    const selectedDescendants = state.visibleDescendants.selectedDescendants[UG_DESCENDANT];
    const url = apiPathFactory.universalGroupGetSingleList(id, selectedDescendants, exceptMe);
    const response = yield call(() => fetchEntitySaga(getUniversalGroupsSingleList, api.get, url));
    return response;
  } catch (error) {
    return error;
  }
}

export function* getUniversalGroupSaga({ id }) {
  try {
    const url = apiPathFactory.universalGroupGet(id);
    const response = yield call(() => fetchEntitySaga(getUniversalGroup, api.get, url));
    return response;
  } catch (error) {
    return error;
  }
}

export function* getUniversalGroupsSaga() {
  try {
    const state = yield select();
    const selectedDescendants = state.visibleDescendants.selectedDescendants[UG_DESCENDANT];
    const searchQuery = state.visibleDescendants.searchQuery
      .replace(/\#/g, '%23')
      .replace(/\&/g, '%26');
    const { backendParams } = state;
    const pagesize = getCookiePageSizeItems() || backendParams.pagesize;
    const url = apiPathFactory.universalGroupsGetPage(pagesize, backendParams.pagenumber[UG_LIST], {
      selectedDescendants,
      searchQuery,
    });
    const response = yield call(() => fetchEntitySaga(getUniversalGroups, api.get, url));
    return response;
  } catch (error) {
    return error;
  }
}

export function* getFacilitiesSaga({ universalGroupId, singleList, allocated }) {
  try {
    const state = yield select();
    const { searchQuery } = state.visibleDescendants;
    const selectedDescendants = state.visibleDescendants.selectedDescendants[FACILITY_DESCENDANT];
    const { backendParams } = state;
    const pageSize = getCookiePageSizeItems() || backendParams.pagesize;
    const url = apiPathFactory.universalGroupGetFacilities({
      universalGroupId,
      singleList,
      allocated,
      pageSize,
      pageNumber:
        backendParams.pagenumber[singleList ? UG_FACILITIES_LIST_SELECTION : UG_FACILITIES_LIST],
      searchQuery,
      selectedDescendants,
    });
    const response = yield call(() => fetchEntitySaga(getFacilities, api.get, url));
    return response;
  } catch (error) {
    return error;
  }
}

export function* getVisibleDescendantsSaga() {
  try {
    const url = apiPathFactory.customerGetVisibleDescendants();
    const response = yield call(() => fetchEntitySaga(getVisibleDescendants, api.get, url));
    return response;
  } catch (error) {
    return error;
  }
}

export function* getUniversalGroupsEquipmentRelaysSaga({ facilityId }) {
  try {
    const state = yield select();
    const { backendParams } = state;
    const pageSize = getCookiePageSizeItems() || backendParams.pagesize;
    const url = apiPathFactory.universalGroupsGetEquipmentRelays({
      facilityId,
      pageSize,
      pageNumber: backendParams.pagenumber[FACILITY_UG_LIST],
    });

    const response = yield call(() =>
      fetchEntitySaga(getUniversalGroupsEquipmentRelays, api.get, url)
    );
    return response;
  } catch (error) {
    return error;
  }
}

export function* setUniversalGroupSaga({ UniversalGroup }) {
  try {
    const url = apiPathFactory.universalGroupsPut(UniversalGroup.id);
    const response = yield call(() =>
      fetchEntitySaga(setUniversalGroup, api.put, url, { data: UniversalGroup })
    );
    return response;
  } catch (error) {
    return error;
  }
}

export function* createUniversalGroupSaga({ UniversalGroup }) {
  try {
    const url = apiPathFactory.universalGroupsPost();
    const response = yield call(() =>
      fetchEntitySaga(createUniversalGroup, api.post, url, { data: UniversalGroup })
    );
    return response;
  } catch (error) {
    return error;
  }
}

export function* putInstalationsUGSaga({ universalGroupId, id, relay }) {
  try {
    const url = apiPathFactory.universalGroupEquipmentRelaysPut({
      id,
      universalGroupId,
      equipmentId: relay.equipmentId,
      relayId: relay.relayId,
      assignationId: relay.relayAssignationId,
    });
    const response = yield call(() =>
      fetchEntitySaga(putInstalationsUG, api.put, url, { data: relay })
    );
    return response;
  } catch (error) {
    return error;
  }
}

export function* deleteUniversalGroupSaga({ UniversalGroupId }) {
  try {
    const url = apiPathFactory.universalGroupsDelete(UniversalGroupId.id);
    const response = yield call(() =>
      fetchEntitySaga(deleteUniversalGroup, api.del, url, { id: UniversalGroupId.id })
    );
    yield getUniversalGroupsSaga();
    return response;
  } catch (error) {
    return error;
  }
}

export function* deleteFacilityUniversalGroupSaga({ facilityId, UniversalGroupId }) {
  try {
    const url = apiPathFactory.universalGroupsFacilityDelete(facilityId, UniversalGroupId);
    const response = yield call(() =>
      fetchEntitySaga(deleteFacilityUniversalGroup, api.del, url, { id: UniversalGroupId })
    );
    return response;
  } catch (error) {
    return error;
  }
}

export function* getUGRelaysSaga({ universalGroupId, facilityId }) {
  try {
    const url = apiPathFactory.universalGroupEquipmentRelaysGet(universalGroupId, facilityId);
    const response = yield call(() => fetchEntitySaga(getUGRelays, api.get, url));
    return response;
  } catch (error) {
    return error;
  }
}

// -----------------------------------------------------------------------------
function* watchPutUniversalGroup() {
  yield takeLatest(universalGroupsActions.PUT_UNIVERSAL_GROUP, putUniversalGroupSaga);
}

function* watchGetUniversalGroupsInfo() {
  yield takeLatest(universalGroupsActions.GET_UNIVERSAL_GROUPS_INFO, getUniversalGroupsInfoSaga);
}

function* watchGetUniversalGroups() {
  yield takeLatest(universalGroupsActions.GET_UNIVERSAL_GROUPS, getUniversalGroupsSaga);
}

function* watchGetVisibleDescendantsSaga() {
  yield takeLatest(universalGroupsActions.GET_VISIBLE_DESCENDANTS, getVisibleDescendantsSaga);
}

function* watchSetUniversalGroup() {
  yield takeLatest(universalGroupsActions.SET_UNIVERSAL_GROUP, setUniversalGroupSaga);
}

function* watchDeleteUniversalGroup() {
  yield takeLatest(universalGroupsActions.DELETE_UNIVERSAL_GROUP, deleteUniversalGroupSaga);
}

function* watchDeleteFacilityUniversalGroup() {
  yield takeLatest(
    universalGroupsActions.DELETE_FACILITY_UNIVERSAL_GROUP,
    deleteFacilityUniversalGroupSaga
  );
}

function* watchCreateUniversalGroup() {
  yield takeLatest(universalGroupsActions.CREATE_UNIVERSAL_GROUP, createUniversalGroupSaga);
}

function* watchGetUniversalGroupEquipmentRelays() {
  yield takeLatest(
    universalGroupsActions.GET_UNIVERSAL_GROUPS_EQUIPMENT_RELAYS,
    getUniversalGroupsEquipmentRelaysSaga
  );
}

function* watchGetUGRelays() {
  yield takeLatest(universalGroupsActions.GET_UNIVERSAL_GROUPS_EQUIPMENT, getUGRelaysSaga);
}

function* watchPutInstalationsUniversalGroup() {
  yield takeLatest(universalGroupsActions.PUT_INSTALATIONSRELAYS_UG, putInstalationsUGSaga);
}

function* watchGetFacilities() {
  yield takeLatest(universalGroupsActions.GET_FACILITIES_LIST_BY_UG, getFacilitiesSaga);
}

function* watchGetUG() {
  yield takeLatest(universalGroupsActions.GET_UNIVERSAL_GROUP, getUniversalGroupSaga);
}

function* watchGetUniversalGroupSingleList() {
  yield takeLatest(
    universalGroupsActions.GET_UNIVERSAL_GROUP_SINGLELIST,
    getUniversalGroupsSingleListSaga
  );
}

// -----------------------------------------------------------------------------

export default function* badRuleWarningWatchers() {
  yield fork(watchGetUniversalGroups);
  yield fork(watchGetUniversalGroupsInfo);
  yield fork(watchGetVisibleDescendantsSaga);
  yield fork(watchSetUniversalGroup);
  yield fork(watchDeleteUniversalGroup);
  yield fork(watchDeleteFacilityUniversalGroup);
  yield fork(watchCreateUniversalGroup);
  yield fork(watchGetUGRelays);
  yield fork(watchPutInstalationsUniversalGroup);
  yield fork(watchPutUniversalGroup);
  yield fork(watchGetUniversalGroupEquipmentRelays);
  yield fork(watchGetFacilities);
  yield fork(watchGetUG);
  yield fork(watchGetUniversalGroupSingleList);
}
