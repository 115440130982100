import { updateArrayNodeByPropName } from '../../../core/helpers/arrayService';
import {
  GET_GROUP_USERS_LIST,
  GET_GROUP_USERS_AVAILABLE_FACILITIES,
  GET_GROUP_USERS_AVAILABLE_GROUPS,
  GET_GROUP_USERS_COUNTER,
  GET_GROUP_USERS_FOBS_LIST,
  GET_GROUP_USERS_LICENSES_LIST,
  GET_GROUP_USERS_LIST_BY_USERID,
  POST_GROUP_USERS,
  POST_GROUP_USERS_FOBS,
  RESET_GROUP_USERS,
  RESET_GROUP_USERS_FOBS,
  PUT_GROUP_USERS_UNLOCK_USER,
  PUT_GROUP_USERS_LOCK_USER,
  DELETE_GROUP_USERS,
  DELETE_GROUP_USERS_LIST,
  DELETE_GROUP_USERS_FOB,
  PUT_GROUP_USERS_LICENSE_LOCK,
  PUT_GROUP_USERS_LICENSE_UNLOCK,
  POST_GROUP_USERS_UPDATE,
} from './constants.js';

/* Redux state init */
const initialState = {
  availableFacilities: undefined,
  availableGroups: undefined,
  groupUsers: undefined,
  isCreated: undefined,
  isSuccess: false,
  groupUserFobs: undefined,
  groupUserLicenses: undefined,
  needCallGroupUser: false,
  groupUsersCounter: {},
};

export default function groupsReducer(state = initialState, action) {
  if (action.type !== undefined) {
    switch (action.type) {
      case GET_GROUP_USERS_LIST.REQUEST:
        return {
          ...state,
          needCallGroupUser: false,
        };
      case GET_GROUP_USERS_LIST.SUCCESS:
        return {
          ...state,
          groupUsers: [...action.result],
          needCallGroupUser: true,
        };
      case GET_GROUP_USERS_FOBS_LIST.SUCCESS:
        return {
          ...state,
          groupUserFobs: [...action.result],
        };
      case GET_GROUP_USERS_COUNTER.SUCCESS:
        return {
          ...state,
          groupUsersCounter: action.result,
        };
      case GET_GROUP_USERS_AVAILABLE_FACILITIES.SUCCESS:
        return {
          ...state,
          availableFacilities: [...action.result],
        };
      case GET_GROUP_USERS_AVAILABLE_GROUPS.SUCCESS:
        return {
          ...state,
          availableGroups: [...action.result],
        };
      case GET_GROUP_USERS_LICENSES_LIST.SUCCESS:
        return {
          ...state,
          groupUserLicenses: [...action.result],
        };
      case GET_GROUP_USERS_LIST_BY_USERID.SUCCESS:
        return {
          ...state,
          groupUserFobs: [...action.result],
        };
      case POST_GROUP_USERS.FAILURE:
        return {
          ...state,
          isCreated: action.error.status === 300,
        };
      case POST_GROUP_USERS.SUCCESS:
        return {
          ...state,
          isCreated: true,
        };
      case POST_GROUP_USERS_FOBS.SUCCESS:
        return {
          ...state,
          isCreated: true,
          groupUserFobs: undefined,
        };
      case RESET_GROUP_USERS:
        return {
          ...state,
          groupUsers: undefined,
        };
      case RESET_GROUP_USERS_FOBS:
        return {
          ...state,
          groupUserFobs: undefined,
        };
      case DELETE_GROUP_USERS.SUCCESS:
        return {
          ...state,
          groupUsers: undefined,
        };
      case DELETE_GROUP_USERS_FOB.SUCCESS:
        return {
          ...state,
          groupUserFobs: undefined,
          needCallGroupUser: true,
        };
      case DELETE_GROUP_USERS_LIST.SUCCESS:
        return {
          ...state,
          groupUsers: undefined,
        };
      case PUT_GROUP_USERS_LICENSE_LOCK.SUCCESS:
        return {
          ...state,
          groupUserLicenses: undefined,
        };
      case PUT_GROUP_USERS_LICENSE_UNLOCK.SUCCESS:
        return {
          ...state,
          groupUserLicenses: undefined,
        };
      case POST_GROUP_USERS_UPDATE.SUCCESS:
        return {
          ...state,
          groupUsers: updateArrayNodeByPropName(state.groupUsers, action.result, 'id'),
        };
      case PUT_GROUP_USERS_UNLOCK_USER.SUCCESS:
      case PUT_GROUP_USERS_LOCK_USER.SUCCESS: {
        return {
          ...state,
          groupUsers: undefined,
        };
      }
      default:
        return state;
    }
  }
  return state;
}
