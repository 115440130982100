import React, { Component } from 'react';
import './CustomToaster.css';
import 'react-toastify/dist/ReactToastify.css';

class CustomToaster extends Component {
  render() {
    const { message } = this.props;

    return (
      <div>
        <p>{message}</p>
      </div>
    );
  }
}

export default CustomToaster;
