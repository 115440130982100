import { Box, Flex, Grid, GridItem } from '@jcm-technologies/uikit/dist/atoms/Layout';
import { CodesCounters } from '@jcm-technologies/uikit/dist/molecules/CodesCounters';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Field } from '@jcm-technologies/uikit/dist/atoms/Field';
import { Input } from '@jcm-technologies/uikit/dist/atoms/Input';
import { ErrorMessage } from '@jcm-technologies/uikit/dist/atoms/ErrorMessage/ErrorMessage';
import { SelectInput } from '@jcm-technologies/uikit/dist/atoms/Select';
import PropTypes from 'prop-types';
import {
  getFamilyListByType,
  getProduct,
} from '../../../../../../core/old_common/utils/familiesService';
import { PRODUCT_FAMILY_TYPE } from '../../config';
import { isNumberValidation } from '../../../../../../core/helpers/numbers';

export const TabSequential = ({ onChangeData, data, errors, handleBlur }) => {
  const { fobsCount } = useSelector((state) => state.instalationGroups);
  const { familiesSingleList } = useSelector((state) => state.families);
  const { t } = useTranslation();
  const counter =
    fobsCount?.find((count) => count.schema === 'Fobs Availability')?.counters[0] || {};
  const productFamily = getFamilyListByType(familiesSingleList, PRODUCT_FAMILY_TYPE);
  return (
    <Box paddingX={4} paddingY={1}>
      <Flex alignItems='center'>
        <CodesCounters
          availableValue={counter.free}
          availablePlaceholder={t('codesCount.available')}
          reservationValue={counter.reserved}
          reservationPlaceholder={t('enrollCode.notAsigned')}
          usedValue={counter.busy}
          usedPlaceholder={t('codesCount.used')}
        />
      </Flex>
      <Grid
        gridTemplateAreas={["'.'", "'. .'", "'. .'"]}
        gridGap={2}
        width='100%'
        gridTemplateColumns='1fr 1fr'
        gridTemplateRows='auto'
      >
        <GridItem>
          <Field label={t('form.device')} required marginY={1}>
            <>
              <SelectInput
                name='productId'
                id='productId'
                options={
                  productFamily[0]?.[0]?.items?.map((product) => ({
                    value: product?.productId,
                    label: product?.name,
                  })) || []
                }
                onChange={(name, value) => {
                  onChangeData(name, value?.value);
                }}
                placeholderMessage={t('general.selectOne')}
                defaultValue={
                  data?.productId
                    ? {
                        value: data?.productId,
                        label: getProduct(productFamily, data?.productId)?.name,
                      }
                    : null
                }
                hasError={errors?.productId?.error}
                errorMessage={errors?.productId?.errorMessage}
              />
              {errors?.productId?.error && errors?.productId?.errorMessage && (
                <ErrorMessage message={errors?.productId?.errorMessage} />
              )}
            </>
          </Field>
        </GridItem>
        <GridItem>
          <Field label={t('form.numberOf', { name: t('title.mifare') })} required marginY={1}>
            <Input
              id='codesAmount'
              name='codesAmount'
              onBlur={(name) => handleBlur(name)}
              onChange={(name, value) => {
                if (isNumberValidation(value)) {
                  onChangeData(name, Number(value));
                }
              }}
              value={Number(data.codesAmount)}
              hasError={errors?.codesAmount?.error}
              errorMessage={errors?.codesAmount?.errorMessage}
            />
          </Field>
        </GridItem>
        <GridItem>
          <Field label={t('form.code')} required marginY={1}>
            <Input
              id='initialCode'
              name='initialCode'
              onBlur={(name) => handleBlur(name)}
              onChange={(name, value) => {
                if (isNumberValidation(value)) {
                  onChangeData(name, value);
                }
              }}
              value={data.initialCode}
              hasError={errors?.initialCode?.error}
              errorMessage={errors?.initialCode?.errorMessage}
            />
          </Field>
        </GridItem>
        <GridItem>
          <Field label={t('form.endCode')} required marginY={1}>
            <Input
              id='endCode'
              name='endCode'
              type='number'
              readOnly
              value={Number(data.codesAmount) + Number(data.initialCode)}
              hasError={errors?.endCode?.error}
              errorMessage={errors?.endCode?.errorMessage}
            />
          </Field>
        </GridItem>
        <GridItem>
          <Field label={t('form.name')} marginY={1}>
            <Input
              id='name'
              name='name'
              onBlur={(name) => handleBlur(name)}
              onChange={(name, value) => onChangeData(name, value)}
              value={data.name}
              hasError={errors?.name?.error}
              errorMessage={errors?.name?.errorMessage}
            />
          </Field>
        </GridItem>
        <GridItem>
          <Field label={t('form.surname')} marginY={1}>
            <Input
              id='surname'
              name='surname'
              onChange={(name, value) => onChangeData(name, value)}
              value={data.surname}
              onBlur={(name) => handleBlur(name)}
              hasError={errors?.surname?.error}
              errorMessage={errors?.surname?.errorMessage}
            />
          </Field>
        </GridItem>
        <GridItem>
          <Field label={t('form.identityCard')} marginY={1}>
            <Input
              id='nic'
              name='nic'
              onChange={(name, value) => onChangeData(name, value)}
              value={data.nic}
              onBlur={(name) => handleBlur(name)}
              hasError={errors?.nic?.error}
              errorMessage={errors?.nic?.errorMessage}
            />
          </Field>
        </GridItem>
        <GridItem>
          <Field label={t('form.slot')} marginY={1}>
            <Input
              id='plaza'
              name='plaza'
              value={data.plaza}
              onBlur={(name) => handleBlur(name)}
              onChange={(name, value) => {
                if (isNumberValidation(value)) {
                  onChangeData(name, value);
                }
              }}
              hasError={errors?.plaza?.error}
              errorMessage={errors?.plaza?.errorMessage}
            />
          </Field>
        </GridItem>
      </Grid>
    </Box>
  );
};

TabSequential.propTypes = {
  onChangeData: PropTypes.func.isRequired,
  data: PropTypes.shape({
    plaza: PropTypes.string,
    nic: PropTypes.string,
    surname: PropTypes.string,
    name: PropTypes.string,
    productId: PropTypes.string,
    initialCode: PropTypes.string,
    codesAmount: PropTypes.number,
  }).isRequired,
};
