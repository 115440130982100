import { GET_COUNTRIES, GET_TIMEZONES, GET_TIMEZONES_BY_PRODUCT } from './constants';

const initialState = {
  countries: [],
  timezones: undefined,
};

export default function countriesReducer(state = initialState, action) {
  if (action.type !== undefined) {
    switch (action.type) {
      case GET_COUNTRIES.SUCCESS:
        return { ...state, countries: [...action.result] };
      case GET_TIMEZONES.REQUEST:
        return { ...state };
      case GET_TIMEZONES.SUCCESS:
        return { ...state, timezones: [...action.result] };
      case GET_TIMEZONES_BY_PRODUCT.SUCCESS:
        return { ...state, timezones: [...action.result] };
      default:
        return state;
    }
  }
  return state;
}
