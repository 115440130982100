import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import IconChartLine from '@jcm-technologies/uikit/dist/atoms/Icons/IconChartLine';
import { Modal } from '@jcm-technologies/uikit/dist/molecules/Modal';
import IconMuvAlarm from '@jcm-technologies/uikit/dist/atoms/Icons/IconMuvAlarm';
import GenericGrow from '../GenericGrow/GenericGrow';

import {
  deleteEquipment,
  getEquipmentConnectivity,
  putEquipmentIsUpdated,
  resetEquipmentConnectivity,
} from '../../modules/old_to_refact/actions/equipments';
import { showDialog } from '../../modules/old_to_refact/actions/loading';
import { RootPath_UG } from '../../core/old_common/constats/genericListPaths';
import PropsList from '../PropsList/PropsList';
import {
  getMemoryCodes,
  getMemoryCompare,
  getMemoryConfig,
  resetDriversAsyncResponse,
  resetMemoryConfig,
  sendMemoryCodes,
} from '../../modules/old_to_refact/actions/drivers';
import CompareMemoryPopup from '../../old_containers/IndividualEquipment/components/CompareMemoryPopup/CompareMemoryPopup';
import {
  checkAssistantPanelConnection,
  invokeSendCICC,
  invokeSendFunction,
  parseCCIC,
  setAssistantPanelCheckSuccessFunction,
  setMessageHubFunction,
} from '../../core/old_common/utils/signalRService';
import { getFamiliesSingleList } from '../../modules/productFamilies/action';
import apiPathFactory from '../../core/old_common/rooting/apiPathFactory';
import { downloadFile, getFilesFromApi, loadFile } from '../../core/helpers/filesApi';
import { getFamilyListByType, getProduct } from '../../core/old_common/utils/familiesService';
import { postCompareFobs } from '../../modules/old_to_refact/actions/a5Kservices';
import { writeMemorySingle } from '../../modules/old_to_refact/actions/devices';
import { getDecodedUrl } from '../../core/old_common/utils/getUrlParams';
import GsmPopup from './GsmPopup/GsmPopup';
import { generateAlarmSumary } from '../utils/mappers/alarms';

import './EquipmentsGrow.css';
import DeviceInformationPopup from './components/DeviceInformationPopup/DeviceInformationPopup';
import StatisticsCharts from '../../old_containers/StatisticsCharts/StatisticsCharts';

class EquipmentsGrow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isComparePopupOpen: false,
      canExecuteSignalR: false,
      cc: '',
      ic: '',
      signalRAction: '',
      a5kCompareFile: undefined,
      isFamilyCalled: false,
      isDevicePopupOpen: false,
      needIconRefresh: false,
      showMessage: false,
      equipmentIsFullUpdated: true,
      iChangeColor1Time: false,
      showConnectionMessage: false,
      isGsmPopupOpen: false,
      changedIdIcon: undefined,
      changedColorIcon: false,
      newColor: undefined,
      statisticsPopupIsOpened: false,
    };
  }

  componentDidMount = () => {
    const { needCallFacility } = this.props;
    if (needCallFacility) {
      this.getEquipmentProducts();
    }
  };

  componentDidUpdate(prevProps) {
    const {
      needCallConnectivity,
      equipmentConnectionInfo,
      t,
      driverWriteError,
      isUpdatedEquipment,
      updatedEquipment,
    } = this.props;
    if (!prevProps.needCallConnectivity && needCallConnectivity && equipmentConnectionInfo) {
      if (!equipmentConnectionInfo.message) {
        if (equipmentConnectionInfo.connected.includes('OffLine')) {
          this.changeIconColor('red');
        } else if (equipmentConnectionInfo.connected.includes('OnLine')) {
          this.changeIconColor('#00e600');
        } else {
          this.changeIconColor('red');
        }
      }
    }
    if (isUpdatedEquipment === 1 && this.state.iChangeColor1Time) {
      if (updatedEquipment && !this.state.needIconRefresh && this.state.equipmentIsFullUpdated) {
        this.changeSaveMemoryIconColor('red');
        this.setState({ equipmentIsFullUpdated: false });
      } else if (this.state.needIconRefresh) {
        this.changeSaveMemoryIconColor('#a4aebb');
        this.setState({ needIconRefresh: false, iChangeColor1Time: false });
      }
    }
    if (this.props.driverWriteDone && this.state.showMessage) {
      this.props.showDialog(-1);
      toast.success(t('equipments.savedMemory'));
      this.changeSaveMemoryIconColor('#a4aebb');
      this.setState({ showMessage: false, needIconRefresh: true, iChangeColor1Time: true });
    } else if (this.props.driverWriteError !== undefined && this.state.showMessage) {
      this.props.showDialog(-1);
      const message = t(`errors.${driverWriteError.error.code}`);
      toast.error(message, { autoClose: false });
      this.setState({ showMessage: false });
    } else if (
      this.props.driverNotResponseError &&
      !this.props.driverWriteDone &&
      this.state.showMessage
    ) {
      this.props.showDialog(-1);
      const message = t(`errors.operationNotCompleted`);
      toast.error(message, { autoClose: false });
      this.setState({ showMessage: false });
    }

    if (!this.state.showMessage && this.state.needIconRefresh && this.state.iChangeColor1Time) {
      this.props.putEquipmentIsUpdated(this.props.facilityId, this.props.id);
    }

    this.checkSignalRConnection();
  }

  changeIconColor = (color) => {
    const element = document.getElementById(`connection-${this.props.id}`);
    if (element != null) {
      document.getElementById(`connection-${this.props.id}`).style.color = color;
    }
  };

  changeSaveMemoryIconColor = (color) => {
    const element = document.getElementById(`saveConfig-${this.props.id}`);
    const elementRemoteStatus = document.getElementById(`remoteStateus-${this.props.id}`);
    if (element != null) {
      document.getElementById(`saveConfig-${this.props.id}`).style.color = color;
    }
    if (elementRemoteStatus != null) {
      document.getElementById(`remoteStateus-${this.props.id}`).style.color = color;
    }
    this.setState({ changedIdIcon: this.props.id, changedColorIcon: true, newColor: color });
  };

  openRemoteStatePopup = () => {
    this.setState({ isDevicePopupOpen: true });
  };

  getEquipmentProducts = () => {
    const { familiesSingleList, getFamiliesSingleList, children, needCallFamilies } = this.props;
    if (
      children !== undefined &&
      !getFamilyListByType(familiesSingleList, 3).length &&
      needCallFamilies
    ) {
      if (!familiesSingleList) {
        this.setState({ isFamilyCalled: true });
        getFamiliesSingleList(3, true);
      }
    }
  };

  checkSignalRConnection = () => {
    const { codes, config, familiesSingleList } = this.props;
    const equipmentConnectionInfo = this.getEquipmentConnectivityAttribute();
    const isSaveAction =
      this.state.signalRAction === 'save' &&
      config !== undefined &&
      ((equipmentConnectionInfo !== undefined &&
        equipmentConnectionInfo.value?.toLowerCase()?.includes('online')) ||
        codes !== undefined);
    const isCompareAction =
      this.state.signalRAction === 'compare' && config !== undefined && config !== '';
    const isCompareA5KAction = this.state.signalRAction === 'a5kCompare';
    const isSaveBaseLinkAction = this.state.signalRAction === 'saveBaseLink';
    const isSaveAccess5K = this.state.signalRAction === 'saveAccess5K';
    const isSaveEBaseDoor = this.state.signalRAction === 'saveEBaseDoor';
    const isSaveConnect = this.state.signalRAction === 'saveConnect';

    if (
      (isSaveAction ||
        isCompareAction ||
        isSaveBaseLinkAction ||
        isCompareA5KAction ||
        isSaveConnect) &&
      this.state.canExecuteSignalR &&
      getFamilyListByType(familiesSingleList, 3).length
    ) {
      this.startSignalRProcess();
      this.setState({ canExecuteSignalR: false });
    } else if ((isSaveAccess5K || isSaveEBaseDoor) && this.state.canExecuteSignalR) {
      this.startSignalRProcess();
      this.setState({ canExecuteSignalR: false });
    }
  };

  getHeaderTitles = (isEditMode) => {
    const { t, name, productName, match } = this.props;
    return [
      {
        value: isEditMode
          ? getDecodedUrl(name || match.params.id)
          : t('equipments.createEquipment'),
        className: 'UG-portlet-header-item UG-header-title',
      },
      {
        value: isEditMode ? getDecodedUrl(productName) : '',
        className: 'UG-portlet-header-item UG-header-title',
      },
    ];
  };

  getEquipmentTimeAttributesById = (equipment) => {
    if (equipment && equipment.type === 5) {
      const res = equipment.value.split('][', 1);
      return res[0].replace('[', '');
    }
  };

  getEquipmentStateAttributesById = (connected) => {
    if (connected === 2) {
      return true;
    }
    if (connected === 1) {
      return false;
    }
    return undefined;
  };

  getEquipmentConnectivityAttribute = () => {
    const { facilityEquipmentAttributes, id } = this.props;
    return facilityEquipmentAttributes
      ? facilityEquipmentAttributes.find((equipmentAttr) => equipmentAttr.equipmentId === id)
      : {};
  };

  getActionButtons = (item) => {
    const {
      isConfigVisible,
      t,
      history,
      match,
      name,
      creationURI,
      id,
      facilityId,
      hideActionButtons,
      connected,
      lastConnectionDateTime,
      facilityEquipmentAttributes,
      isUpdated,
      productCode,
    } = this.props;

    if (isConfigVisible) {
      const baseURI = `${RootPath_UG}/${match.params.universalGroupId || match.params.id}`;
      const relaysURI = `${baseURI}/Equipments/${id}`;
      return [
        {
          id: 'config',
          toolTip: t('equipments.add'),
          defaultColor: '#a4aebb',
          iconClassName: 'generic-grow-action-button fas fa-cog',
          cursor: 'pointer',
          onClick: () => history.push(relaysURI),
        },
      ];
    }

    if (match.params.id !== creationURI) {
      const equipmentStatusConnection = this.getEquipmentStateAttributesById(connected);
      const connectionIcon = {
        id: `connection-${item.id}`,
        toolTip: lastConnectionDateTime,
        defaultColor: equipmentStatusConnection ? '#00e600' : '#ff0000',
        iconClassName:
          equipmentStatusConnection !== undefined ? 'generic-grow-action-button fas fa-wifi' : '',
        cursor: 'pointer',
        onClick: (e) => {
          e?.stopPropagation();
          e?.preventDefault();
          this.setState({ isGsmPopupOpen: true });
        },
      };
      const doorIcon = {
        id: `remoteStateus-${item.id}`,
        defaultColor: item.isUpdated ? '#a4aebb' : '#ff0000',
        iconClassName:
          equipmentStatusConnection !== undefined && productCode !== 307
            ? 'generic-grow-action-button fas fa-door-open'
            : '',
        toolTip: t('equipments.getdoorstatus'),
        cursor: equipmentStatusConnection ? 'pointer' : 'not-allowed',
        onClick: (e) => {
          e?.stopPropagation();
          e?.preventDefault();
          if (equipmentStatusConnection) {
            this.setState({ isDevicePopupOpen: true });
          }
        },
      };
      const actionIcons = [
        {
          id: 'compare',
          toolTip: t('equipments.compareMemory'),
          defaultColor: '#a4aebb',
          iconClassName:
            productCode !== 612 && productCode !== 613 && productCode !== 614 && productCode !== 615
              ? 'generic-grow-action-button fas fa-exchange-alt'
              : '',
          cursor:
            this.props.productCode !== 612 &&
            this.props.productCode !== 613 &&
            this.props.productCode !== 614 &&
            this.props.productCode !== 615
              ? 'pointer'
              : 'not-allowed',
          onClick: (e) => {
            e?.stopPropagation();
            e?.preventDefault();
            this.getMemoryData(true);
          },
        },
        {
          //-----------------------------------------------------------------------------------------------------------------------------------
          id: `saveConfig-${item.id}`,
          toolTip: this.generateTooltipUserActions(item.userActions),
          defaultColor:
            this.state.changedIdIcon === item.id
              ? this.getColor(item)
              : item.isUpdated
              ? '#a4aebb'
              : '#ff0000',
          iconClassName: 'generic-grow-action-button fas fa-hdd',
          cursor: 'pointer',
          onClick: this.onSaveConfigurationClick,
        },
      ];
      if (equipmentStatusConnection !== undefined) {
        actionIcons.unshift(doorIcon);
        actionIcons.unshift(connectionIcon);
      }
      if (item?.alarms?.length) {
        actionIcons.unshift({
          toolTip: generateAlarmSumary(item.alarms),
          icon: <IconMuvAlarm fill='red' />,
          iconClassName: `generic-grow-action-button d-grid`,
          cursor: 'auto',
          onClick: (e) => {
            e?.preventDefault();
            e?.stopPropagation();
          },
        });
      }
      if (connected > 0 && productCode !== 307) {
        actionIcons.unshift({
          toolTip: t('title.statistics'),
          icon: <IconChartLine />,
          iconClassName: `generic-grow-action-button d-grid fill-defaultColor`,
          cursor: 'auto',
          onClick: (e) => {
            e?.preventDefault();
            e?.stopPropagation();
            this.setStatisticsPopUp();
          },
        });
      }

      return hideActionButtons ? [] : actionIcons;
    }
  };

  generateTooltipUserActions = (userActions) => {
    const { t } = this.props;
    let tooltipData = '';
    tooltipData += `<span>${t('equipments.saveConfiguration')} </span><br> <ul>`;
    if (userActions?.length > 0) {
      tooltipData += `<br>`;
    }
    userActions?.forEach((element) => {
      tooltipData += `<li> ${element.dateTime} - ${element.user} </li>`;
    });
    tooltipData += `</ul>`;
    return tooltipData;
  };

  setStatisticsPopUp = () => {
    this.setState({
      statisticsPopupIsOpened: true,
    });
  };

  getColor = (item) => {
    if (item) {
      const element = document.getElementById(`saveConfig-${item.id}`);
      if (this.state.changedIdIcon && this.state.changedColorIcon && item) {
        return this.state.newColor;
      }
      if (this.state.changeIconColor === undefined && item.id && element !== null) {
        return document.getElementById(`saveConfig-${item.id}`).style.color;
      }
      return item.isUpdated ? '#a4aebb' : '#ff0000';
    }
  };

  onSaveConfigurationClick = (e) => {
    const {
      id,
      facilityId,
      getMemoryCodes,
      productCode,
      facilityEquipmentAttributes,
      writeMemorySingle,
    } = this.props;
    const equipmentById = facilityEquipmentAttributes
      ? facilityEquipmentAttributes.find((equipmentAttr) => equipmentAttr.equipmentId === id)
      : '';
    const equipmentStatusConnection = this.getEquipmentStateAttributesById(equipmentById);
    e?.stopPropagation();
    e?.preventDefault();
    if (productCode === 307) {
      this.setState({ signalRAction: 'saveAccess5K', canExecuteSignalR: true });
    } else if (productCode === 612 || productCode === 613) {
      this.setState({ signalRAction: 'saveEBaseDoor', canExecuteSignalR: true });
    } else if (productCode === 614 || productCode === 615) {
      writeMemorySingle(facilityId, id, null, null, null);
      this.setState({ signalRAction: '', showMessage: true, canPutUpdateEquipment: true });
    } else if (
      equipmentStatusConnection === undefined ||
      (equipmentStatusConnection === false && productCode !== 307)
    ) {
      if (this.props.item.productCode === 401) {
        this.getMemoryData();
        this.setState({ signalRAction: 'saveConnect', canExecuteSignalR: true });
      } else {
        this.getMemoryData();
        getMemoryCodes(id, facilityId);
        this.setState({ signalRAction: 'save', canExecuteSignalR: true });
      }
    } else if (equipmentStatusConnection === true) {
      this.setState({ signalRAction: 'saveBaseLink', canExecuteSignalR: true });
    }
  };

  getMemoryData = (isComparingData) => {
    const { getMemoryConfig, item, familiesSingleList, getFamiliesSingleList, productCode } =
      this.props;
    if (!getFamilyListByType(familiesSingleList, 3)?.length) {
      getFamiliesSingleList(3, true);
    }
    if (productCode !== 307) {
      getMemoryConfig(item.id, item.facilityId);
      if (isComparingData) {
        this.setState({ signalRAction: 'compare', canExecuteSignalR: true });
      }
    } else {
      loadFile({
        callBack: (e) => {
          this.setState({
            a5kCompareFile: e.target.files[0],
            signalRAction: 'a5kCompare',
            canExecuteSignalR: true,
          });
        },
      });
    }
  };

  startSignalRProcess = () => {
    setAssistantPanelCheckSuccessFunction(invokeSendCICC);
    setMessageHubFunction(this.readCICC);
    checkAssistantPanelConnection();
  };

  readCICC = (connId, codes) => {
    const { sendMemoryCodes, facilityId, id, writeMemorySingle, facility } = this.props;
    if (this.state.signalRAction === 'save' || this.state.signalRAction === 'saveConnect') {
      setMessageHubFunction(this.saveMemoryResponse);
      this.writeMemorySignalR();
    } else if (this.state.signalRAction === 'compare') {
      setMessageHubFunction(this.compareMemoryResponse);
      this.compareMemorySignalR();
    } else if (this.state.signalRAction === 'saveBaseLink') {
      const signalRCodes = parseCCIC(codes);
      setMessageHubFunction(this.receivedCodesBaseLink);
      sendMemoryCodes(facilityId, id, signalRCodes.ic, signalRCodes.cc, connId);
      this.props.putEquipmentIsUpdated(facilityId, id);
      this.setState({ needIconRefresh: true, signalRAction: '', iChangeColor1Time: true });
    } else if (this.state.signalRAction === 'a5kCompare') {
      const signalRCodes = parseCCIC(codes);
      this.compareA5KMemorySignalR(signalRCodes);
    } else if (this.state.signalRAction === 'saveAccess5K') {
      const signalRCodes = parseCCIC(codes);
      const endpoint = apiPathFactory.a5kGetFileContent(
        facilityId,
        id,
        signalRCodes.cc,
        signalRCodes.ic,
        0
      );
      getFilesFromApi(
        endpoint,
        (isError, response) => {
          if (!isError) {
            const fileName = 'Access5KCodesM.JCMbin';
            downloadFile(response, fileName);
          }
        },
        'application/json',
        'binary'
      );
      this.props.putEquipmentIsUpdated(facilityId, id);
      this.setState({ needIconRefresh: true, iChangeColor1Time: true });
    } else if (this.state.signalRAction === 'saveEBaseDoor') {
      setMessageHubFunction(this.progressEbaseDoor);
      const signalRCodes = parseCCIC(codes);
      writeMemorySingle(facilityId, id, connId, signalRCodes.cc, signalRCodes.ic);
      // putEquipmentIsUpdated(facilityId, id);
      this.setState({ signalRAction: '', showMessage: true, canPutUpdateEquipment: true });
    }
    const installerCodeReaded = codes?.split('|')?.[1]?.split('::')?.[0];
    if (Number(installerCodeReaded) === 0 && !!facility.installerCode) {
      toast.warn(this.props.t('apitoasters.errors.zeroCode'));
    }
  };

  progressEbaseDoor = (id, message) => {
    const { driverWriteDone, driverWriteError, driverNotResponseError } = this.props;
    if ((driverWriteDone || driverWriteError || driverNotResponseError) !== undefined) {
      const values = message.split('::');
      const actualValue = values[1];
      const totalValues = values[2];
      const percent = (actualValue / totalValues) * 100;
      this.props.showDialog(1, Math.trunc(percent));
    } else {
      this.props.showDialog(-1, '');
    }
  };

  receivedCodesBaseLink = (id, message) => {
    const model = JSON.parse(message, (key, value) =>
      typeof value === 'object' || isNaN(value) ? value : parseInt(value)
    );
    this.props.showDialog(-1);
    this.props.resetDriversAsyncResponse();
    if (model.data.message.R === 1) {
      toast.success(this.props.t('apitoasters.success.saved'));
    } else {
      toast.error(this.props.t(`equipmentErrors.${model.data.message.R}`));
    }
  };

  writeMemorySignalR = () => {
    const { config, productId, codes, familiesSingleList, resetMemoryConfig } = this.props;
    const product = getProduct(getFamilyListByType(familiesSingleList, 3), productId);
    invokeSendFunction(
      `${product.driverName}::${config}::${codes || 0}::${product.productType}::${
        this.getSelectedInstalation().installerCode
      }`,
      90000
    );
    resetMemoryConfig();
    this.setState({ signalRAction: '' });
  };

  compareMemorySignalR = () => {
    const { config, productId, familiesSingleList } = this.props;
    const product = getProduct(getFamilyListByType(familiesSingleList, 3), productId);
    invokeSendFunction(
      `ReadCodes::${product.productType}::${
        this.getSelectedInstalation().installerCode
      }::${config}`,
      90000
    );
  };

  getSelectedInstalation = () => {
    const { facility, instalations, match } = this.props;
    if (facility) {
      return facility;
    }
    if (instalations) {
      return instalations.find(
        (instalation) =>
          instalation.id === getDecodedUrl(match.params.instalationId || match.params.id)
      );
    }
    return undefined;
  };

  compareA5KMemorySignalR = (codes) => {
    const { facilityId, id } = this.props;
    this.props.postCompareFobs(facilityId, id, codes.ic, codes.cc, this.state.a5kCompareFile);
    this.setState({ signalRAction: '', isComparePopupOpen: true });
  };

  saveMemoryResponse = (name, message) => {
    const { t, resetMemoryConfig } = this.props;
    resetMemoryConfig();
    toast.success(t('equipments.savedMemory'));
    this.props.putEquipmentIsUpdated(this.props.facilityId, this.props.id);
    this.setState({ needIconRefresh: true, iChangeColor1Time: true });
  };

  compareMemoryResponse = (name, message) => {
    const { id, facilityId, getMemoryCompare } = this.props;
    getMemoryCompare(id, facilityId, message.split('::')[1]);
    this.setState({ signalRAction: '', isComparePopupOpen: true });
  };

  popupDeleteChildren = (item) => {
    const { t, facility } = this.props;
    return [
      {
        key: t('form.name'),
        value: item.name,
      },
      {
        key: t('equipments.facilityName'),
        value: facility ? facility.name : '',
      },
      {
        key: t('equipments.productName'),
        value: item.productName,
      },
    ];
  };

  render() {
    const {
      children,
      customerId,
      id,
      facilityId,
      baseURI,
      creationURI,
      deleteEquipment,
      match,
      canExpand,
      hideActionButtons,
      handleExpand,
      t,
      canClone,
      item,
      facility,
    } = this.props;
    const isCreationMode =
      match.params.id && match.params.id === creationURI ? decodeURI(match.params.id) : '';
    return (
      <>
        <GenericGrow
          canClone={canClone}
          canExpand={canExpand !== undefined ? canExpand : undefined}
          customActionButtons={this.getActionButtons(item)}
          key={customerId}
          item={item}
          rootPath={baseURI}
          creationURI={creationURI}
          hideActionButtons={hideActionButtons !== undefined ? hideActionButtons : false}
          isExpanded={children !== undefined}
          headerTitles={this.getHeaderTitles(!isCreationMode)}
          handleDelete={(e) => {
            deleteEquipment(facilityId, id);
            if (this.props.handleDelete) {
              this.props.handleDelete();
            }
          }}
          deletePopupChildren={<PropsList propsArray={this.popupDeleteChildren(item)} />}
          deletePopupTitle={`${t('equipments.deleteConfirmation')}`}
          handleExpand={handleExpand}
        >
          {children}
        </GenericGrow>
        <CompareMemoryPopup
          equipment={item}
          installerCode={facility ? facility.installerCode : false}
          isOpen={this.state.isComparePopupOpen}
          closePopup={() => this.setState({ isComparePopupOpen: false })}
        />
        <DeviceInformationPopup
          item={item}
          isOpen={this.state.isDevicePopupOpen}
          onClose={() => this.setState({ isDevicePopupOpen: false })}
        />
        <GsmPopup
          item={item}
          isOpen={this.state.isGsmPopupOpen}
          onClose={() => this.setState({ isGsmPopupOpen: false })}
        />

        <Modal
          maxWidth='1200px'
          width='100%'
          isOpen={this.state.statisticsPopupIsOpened}
          id='equipments-statistics'
          onModalClose={() => this.setState({ statisticsPopupIsOpened: false })}
        >
          <Modal.Content width='100%'>
            <StatisticsCharts isEquipment element={item} />
          </Modal.Content>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  familiesSingleList: state.families.familiesSingleList,
  needCallConnectivity: state.equipments.needCallConnectivity,
  needCallFamilies: state.families.needCallFamilies,
  config: state.drivers.config,
  codes: state.drivers.codes,
  hubFunction: state.signalR.hubFunction,
  facility: state.instalations.facility,
  instalations: state.instalations.instalations,
  needCallFacility: state.instalations.needCallFacility,
  equipmentConnection: state.equipments.equipmentConnection,
  equipmentConnectionInfo: state.equipments.equipmentConnectionInfo,
  driverWriteDone: state.devices.driverWriteDone,
  driverWriteError: state.devices.driverWriteError,
  driverNotResponseError: state.devices.driverNotResponseError,
  isUpdatedEquipment: state.equipments.isUpdated,
  updatedEquipment: state.equipments.updatedEquipment,
});

const EquipmentsGrowPage = withTranslation()(EquipmentsGrow);
const EquipmentsGrowPageWithRouter = withRouter(EquipmentsGrowPage);

export default connect(mapStateToProps, {
  resetMemoryConfig,
  getFamiliesSingleList,
  getMemoryCodes,
  getMemoryConfig,
  deleteEquipment,
  getMemoryCompare,
  postCompareFobs,
  sendMemoryCodes,
  showDialog,
  resetDriversAsyncResponse,
  getEquipmentConnectivity,
  resetEquipmentConnectivity,
  writeMemorySingle,
  putEquipmentIsUpdated,
})(EquipmentsGrowPageWithRouter);
