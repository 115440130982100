export default {
  neighbourPopUp: {
    title: 'Añadir un vecino',
    email: 'E-Mail',
    phoneNumber: 'Numero teléfono',
    floor: 'Planta',
    door: 'Puerta',
    titleEdit: 'Editar contacto',
    titleDelete: 'Esta seguro de que quiere borrar contacto?',
  },
};
