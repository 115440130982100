import {
  COPY_GROUP_MIFARE,
  CREATE_GROUP_MIFARE,
  DELETE_GROUP_MIFARE,
  DELETE_GROUP_MIFARE_LIST,
  GET_GROUP_MIFARE_LIST,
  IMPORT_GROUP_MIFARE,
  MIFARE_STORE,
  MOVE_GROUP_MIFARE,
  UPDATE_GROUP_MIFARE,
} from './constants';

export const getGroupMifareList = ({ idFacility, idGroup }) => ({
  type: GET_GROUP_MIFARE_LIST.BASE,
  idFacility,
  idGroup,
});
export const deleteGroupMifareList = ({ idFacility, idGroup, list }) => ({
  type: DELETE_GROUP_MIFARE_LIST.BASE,
  idFacility,
  idGroup,
  list,
});
export const createGroupMifare = ({ idFacility, idGroup, data }) => ({
  type: CREATE_GROUP_MIFARE.BASE,
  idFacility,
  idGroup,
  data,
});
export const updateGroupMifare = ({ idFacility, idGroup, idFob, data }) => ({
  type: UPDATE_GROUP_MIFARE.BASE,
  idFacility,
  idGroup,
  idFob,
  data,
});
export const deleteGroupMifare = ({ idFacility, idGroup, idFob }) => ({
  type: DELETE_GROUP_MIFARE.BASE,
  idFacility,
  idGroup,
  idFob,
});
export const moveGroupMifare = ({ idFacility, idGroup, idFacilityTo, idGroupTo, list }) => ({
  type: MOVE_GROUP_MIFARE.BASE,
  idFacility,
  idGroup,
  idFacilityTo,
  idGroupTo,
  list,
});
export const copyGroupMifare = ({ idFacility, idGroup, idFacilityTo, idGroupTo, list }) => ({
  type: COPY_GROUP_MIFARE.BASE,
  idFacility,
  idGroup,
  idFacilityTo,
  idGroupTo,
  list,
});
export const importGroupMifare = ({ idFacility, idGroup, file }) => ({
  type: IMPORT_GROUP_MIFARE.BASE,
  idFacility,
  idGroup,
  file,
});

export const setMifareStore = ({ key, value }) => ({
  type: MIFARE_STORE,
  key,
  value,
});
