export default {
  descriptionParams: {
    ACCESSCONTROL: 'Shows if the equipment has an input for access control devices',
    ACTIVEEVENTSTOSD: 'Specifies the active events for your device',
    APNCONFIG: 'Configures APN of the device',
    BADGE_MAITRE1: 'Proximity element code used as master key',
    BADGE_MAITRE10: 'Proximity element code used as master key',
    BADGE_MAITRE2: 'Proximity element code used as master key',
    BADGE_MAITRE3: 'Proximity element code used as master key',
    BADGE_MAITRE4: 'Proximity element code used as master key',
    BADGE_MAITRE5: 'Proximity element code used as master key',
    BADGE_MAITRE6: 'Proximity element code used as master key',
    BADGE_MAITRE7: 'Proximity element code used as master key',
    BADGE_MAITRE8: 'Proximity element code used as master key',
    BADGE_MAITRE9: 'Proximity element code used as master key',
    CONFIGRELAYSNEW1:
      'Indicates the relays that will be activated according to the channel that the device sends',
    CONFIGRELAYSNEW2:
      'Indicates the relays that will be activated according to the channel that the device sends',
    CONFIGRELAYSNEW3:
      'Indicates the relays that will be activated according to the channel that the device sends',
    CONFIGRELAYSNEW4:
      'Indicates the relays that will be activated according to the channel that the device sends',
    CONFIGRELAYSPIN1:
      'Indicates the relays that will be activated according to the channel that the device sends',
    CONFIGRELAYSPIN2:
      'Indicates the relays that will be activated according to the channel that the device sends',
    CONFIGRELAYSPIN3:
      'Indicates the relays that will be activated according to the channel that the device sends',
    CONFIGRELAYSPIN4:
      'Indicates the relays that will be activated according to the channel that the device sends',
    DISABLEMANUALPROG: 'Allows you to register devices manually',
    DISABLERADIOPROG:
      'Prevents the registration of transmitters using the radio programming mode (standard mode)',
    FREEACTIVE: 'Activates / deactivates the characteristics of FREE system group',
    FREEGROUP0LOCKED: 'Locks / unlocks group 0 of FREE system',
    FREEGROUPNUM: 'Sets the group number of FREE system',
    FREEGROUPRFPROGALLOWED: 'Enables / disable radio programming mode for FREE system group',
    IMPBIRELE1: 'Changes the operation of relay 1 from impulse to ON/OFF',
    INSTAL: 'Shows if you work with installer code',
    MINIDDEVICE: 'The equipment will accept devices such as those indicated or higher',
    PASSWORD: '4-digit password to access the central menu',
    PIN: '6-digit numerical code corresponding to the receiver PIN code',
    PINCADUCAT: 'Shows if the receiver accepts devices with the expired PIN code',
    POWER: 'Shows the type power supply of equipment',
    RELAYPRICE1: 'Value to be subtracted from the credit of a prepaid device 1',
    RELAYPRICE2: 'Value to be subtracted from the credit of a prepaid device 2',
    RELAYPRICE3: 'Value to be subtracted from the credit of a prepaid device 3',
    RELAYPRICE4: 'Value to be subtracted from the credit of a prepaid device 4',
    RELAYS: 'Number of relays in the receiver',
    RELE1IMP: 'Configures the relay as impulse, ON/OFF or panic',
    RELE1TEMP:
      'Sets the time that the relay will remain active in impulse mode (from 1 to 30 sec) or panic (from 1 to 15 min)',
    RELE2IMP: 'Sets the relay as impulse, ON/OFF or panic',
    RELE2TEMP:
      'Sets the time that the relay will remain active in impulse mode (from 1 to 30 sec) or panic (from 1 to 15 min)',
    RELE3IMP: 'Sets the relay as impulse, ON/OFF or panic',
    RELE3TEMP:
      'Sets the time that the relay will remain active in impulse mode (from 1 to 30 sec) or panic (from 1 to 15 min)',
    RELE4IMP: 'Sets the relay as impulse, ON/OFF or panic',
    RELE4TEMP:
      'Sets the time that the relay will remain active in impulse mode (from 1 to 30 sec) or panic (from 1 to 15 min)',
    SERIALNUMBER: 'Serial number is mandatory for remote actions',
    SITECODE:
      'Site code / facility code / installation code to be sent in the Wiegand 26 protocol with Site code',
    TEVENT: 'Shows if the control panel can have an events card',
    TIMEZONEID: 'Sets the time zone where the equipment is installed',
    TMEM: 'Shows if the equipment works with a memory card',
    UNIQUEKEY: 'Single key is for remote actions',
    W26FORMAT: 'Sets the Wiegand 26 protocol transmission format',
    SETNEWACCESSPW: 'Change password',
    SERIALNUMBERLINK: 'Serial number is mandatory for remote actions',
    CONNECTIVITY_EXTENSION: 'Link card installed',
    ACTIVATIONKEY: 'Activation Key',
    EMMITTERNOTUSEDEVENTTIME: 'Do not use the event time of transmitter',
    DOOROPENEDEVENTTIME: 'Event of door opening',
    SCHEDULESDATA: 'Schedules data',
    A5KPROXY: 'Access 5k Proxy',
    WIEGANDCONFIG: 'Customizes the Wiegand configuration to be used',
  },
};
