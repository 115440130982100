import PathEntity from '../../../core/old_common/rooting/apiPaths/PathEntity';
import { apiVersion, mainPath } from '../../../core/old_common/rooting/common';

class Routes extends PathEntity {
  getMifareList = (idFacility, idGroup) =>
    this.manufacturePath(`facilities/${idFacility}/groups/${idGroup}/mifare`);

  deleteMifareList = (idFacility, idGroup) =>
    this.manufacturePath(`facilities/${idFacility}/groups/${idGroup}/mifare`);

  createMifareFob = (idFacility, idGroup) => {
    this.mainPath = `${mainPath()}4.1`;
    const endpoint = this.manufacturePath(
      `facilities/${idFacility}/groups/${idGroup}/mifare/addsequential`
    );
    this.mainPath = `${mainPath()}${apiVersion}`;
    return endpoint;
  };

  updateMifareFob = (idFacility, idGroup, idFob) =>
    this.manufacturePath(`facilities/${idFacility}/groups/${idGroup}/mifare/${idFob}`);

  deleteMifareFob = (idFacility, idGroup, idFob) =>
    this.manufacturePath(`facilities/${idFacility}/groups/${idGroup}/mifare/${idFob}`);

  moveGroupMifare = (idFacility, idGroup, idFacilityTo, idGroupTo) => {
    this.mainPath = `${mainPath()}4.1`;
    const endpoint = this.manufacturePath(
      `facilities/${idFacility}/groups/${idGroup}/mifare/move/${idFacilityTo}/${idGroupTo}`
    );
    this.mainPath = `${mainPath()}${apiVersion}`;
    return endpoint;
  };

  copyGroupMifare = (idFacility, idGroup, idFacilityTo, idGroupTo) => {
    this.mainPath = `${mainPath()}4.1`;
    const endpoint = this.manufacturePath(
      `facilities/${idFacility}/groups/${idGroup}/mifare/copy/${idFacilityTo}/${idGroupTo}`
    );
    this.mainPath = `${mainPath()}${apiVersion}`;
    return endpoint;
  };

  importGroupMifare = (idFacility, idGroup) =>
    this.manufacturePath(`facilities/${idFacility}/groups/${idGroup}/mifare/import`);

  getPaths = () => ({
    getMifareList: this.getMifareList,
    deleteMifareList: this.deleteMifareList,
    createMifareFob: this.createMifareFob,
    updateMifareFob: this.updateMifareFob,
    deleteMifareFob: this.deleteMifareFob,
    moveGroupMifare: this.moveGroupMifare,
    copyGroupMifare: this.copyGroupMifare,
    importGroupMifare: this.importGroupMifare,
  });
}

export default new Routes().getPaths();
