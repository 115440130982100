import { call, fork, takeLatest } from 'redux-saga/effects';
import fetchEntitySaga from '../old_to_refact/sagas/utils/fetchEntitySaga';
import apiPathFactory from '../../core/old_common/rooting/apiPathFactory';
import api from '../../core/helpers/api';
import {
  GET_CUSTOMERS_CUSTOMER_ROLES,
  GET_CUSTOMERS_HONOA,
  GET_CUSTOMERS_INSTALLER_CODE,
  GET_CUSTOMERS_LICENSES,
  GET_CUSTOMERS_PROPERTY_MANAGERS,
  PATCH_CUSTOMERS_CUSTOMER_ROLE,
  PUT_CUSTOMER_ATTRIBUTES,
  PUT_CUSTOMER_ATTRIBUTES_HONOA,
  PUT_CUSTOMER_LICENSES,
  PUT_CUSTOMERS_INSTALLER_CODE,
} from './constants';

import { getCookieCustomerId } from '../../core/old_common/utils/cookiesManager';

export const getCustomersHonoa = {
  base: (customerId) => ({
    type: GET_CUSTOMERS_HONOA.BASE,
    customerId,
  }),
  request: (params) => ({ type: GET_CUSTOMERS_HONOA.REQUEST, params }),
  success: (result) => ({ type: GET_CUSTOMERS_HONOA.SUCCESS, result }),
  failure: (error) => ({ type: GET_CUSTOMERS_HONOA.FAILURE, error }),
};

export const getCustomersInstallerCode = {
  base: () => ({
    type: GET_CUSTOMERS_INSTALLER_CODE.BASE,
  }),
  request: () => ({ type: GET_CUSTOMERS_INSTALLER_CODE.REQUEST }),
  success: (result) => ({ type: GET_CUSTOMERS_INSTALLER_CODE.SUCCESS, result }),
  failure: (error) => ({ type: GET_CUSTOMERS_INSTALLER_CODE.FAILURE, error }),
};

export const putCustomerLicenses = {
  base: (id, data) => ({
    type: PUT_CUSTOMER_LICENSES.BASE,
    id,
    data,
  }),
  request: () => ({ type: PUT_CUSTOMER_LICENSES.REQUEST }),
  success: (result) => ({ type: PUT_CUSTOMER_LICENSES.SUCCESS, result }),
  failure: (error) => ({ type: PUT_CUSTOMER_LICENSES.FAILURE, error }),
};

export const putCustomerAttributes = {
  base: (attributes, customerId) => ({
    type: PUT_CUSTOMER_ATTRIBUTES.BASE,
    attributes,
    customerId,
  }),
  request: () => ({ type: PUT_CUSTOMER_ATTRIBUTES.REQUEST }),
  success: (result) => ({ type: PUT_CUSTOMER_ATTRIBUTES.SUCCESS, result }),
  failure: (error) => ({ type: PUT_CUSTOMER_ATTRIBUTES.FAILURE, error }),
};

export const putCustomerAttributesHonoa = {
  base: (attributes) => ({
    type: PUT_CUSTOMER_ATTRIBUTES_HONOA.BASE,
    attributes,
  }),
  request: () => ({ type: PUT_CUSTOMER_ATTRIBUTES_HONOA.REQUEST }),
  success: (result) => ({ type: PUT_CUSTOMER_ATTRIBUTES_HONOA.SUCCESS, result }),
  failure: (error) => ({ type: PUT_CUSTOMER_ATTRIBUTES_HONOA.FAILURE, error }),
};

export const putCustomerInstallerCode = {
  base: (datas) => ({
    type: PUT_CUSTOMERS_INSTALLER_CODE.BASE,
    datas,
  }),
  request: (datas) => ({ type: PUT_CUSTOMERS_INSTALLER_CODE.REQUEST, payload: datas }),
  success: (result, payload) => ({ type: PUT_CUSTOMERS_INSTALLER_CODE.SUCCESS, result, payload }),
  failure: (error, payload) => ({ type: PUT_CUSTOMERS_INSTALLER_CODE.FAILURE, error, payload }),
};

export const getCustomersPropertyManagers = {
  base: () => ({
    type: GET_CUSTOMERS_PROPERTY_MANAGERS.BASE,
  }),
  request: () => ({ type: GET_CUSTOMERS_PROPERTY_MANAGERS.REQUEST }),
  success: (result) => ({ type: GET_CUSTOMERS_PROPERTY_MANAGERS.SUCCESS, result }),
  failure: (error) => ({ type: GET_CUSTOMERS_PROPERTY_MANAGERS.FAILURE, error }),
};

export const getCustomersLicenses = {
  base: () => ({
    type: GET_CUSTOMERS_LICENSES.BASE,
  }),
  request: () => ({ type: GET_CUSTOMERS_LICENSES.REQUEST }),
  success: (result) => ({ type: GET_CUSTOMERS_LICENSES.SUCCESS, result }),
  failure: (error) => ({ type: GET_CUSTOMERS_LICENSES.FAILURE, error }),
};

export const getCustomerRoles = {
  base: (roleMask) => ({
    roleMask,
    type: GET_CUSTOMERS_CUSTOMER_ROLES.BASE,
  }),
  request: () => ({ type: GET_CUSTOMERS_CUSTOMER_ROLES.REQUEST }),
  success: (result) => ({ type: GET_CUSTOMERS_CUSTOMER_ROLES.SUCCESS, result }),
  failure: (error) => ({ type: GET_CUSTOMERS_CUSTOMER_ROLES.FAILURE, error }),
};

export const patchCostumerRole = {
  base: (customerId, newRoleMask) => ({
    customerId,
    newRoleMask,
    type: PATCH_CUSTOMERS_CUSTOMER_ROLE.BASE,
  }),
  request: () => ({ type: PATCH_CUSTOMERS_CUSTOMER_ROLE.REQUEST }),
  success: (result) => ({ type: PATCH_CUSTOMERS_CUSTOMER_ROLE.SUCCESS, result }),
  failure: (error) => ({ type: PATCH_CUSTOMERS_CUSTOMER_ROLE.FAILURE, error }),
};

//---------------------------------------------------------------

export function* getCustomerHonoaSaga({ customerId }) {
  try {
    const url = apiPathFactory.customerGetAttributesHonoa(customerId);
    const response = yield call(() => fetchEntitySaga(getCustomersHonoa, api.get, url));
    return response;
  } catch (error) {
    return error;
  }
}

export function* getCustomersInstallerCodeSaga() {
  try {
    const url = apiPathFactory.customerGetInstallerCode();
    const response = yield call(() => fetchEntitySaga(getCustomersInstallerCode, api.get, url));
    return response;
  } catch (error) {
    return error;
  }
}

export function* getCustomersPropertyManagersSaga() {
  try {
    const url = apiPathFactory.customerGetPropertyManagers();

    const response = yield call(() => fetchEntitySaga(getCustomersPropertyManagers, api.get, url));
    return response;
  } catch (error) {
    return error;
  }
}

export function* putCustomerAttributesSaga({ attributes, customerId }) {
  try {
    const url = apiPathFactory.customerPutAttributes(customerId);
    const response = yield call(() =>
      fetchEntitySaga(putCustomerAttributes, api.put, url, { data: attributes })
    );
    return response;
  } catch (error) {
    return error;
  }
}

export function* putCustomerAttributesHonoaSaga({ attributes }) {
  try {
    const customerId = getCookieCustomerId();
    const url = apiPathFactory.customerPutAttributesHonoa(customerId);
    const response = yield call(() =>
      fetchEntitySaga(putCustomerAttributesHonoa, api.put, url, { data: attributes })
    );
    return response;
  } catch (error) {
    return error;
  }
}

export function* getCustomerLicensesSaga() {
  try {
    const url = apiPathFactory.customerGetLicenses();
    const response = yield call(() => fetchEntitySaga(getCustomersLicenses, api.get, url));
    return response;
  } catch (error) {
    return error;
  }
}

export function* putCustomerLicensesSaga({ data, id }) {
  try {
    const url = apiPathFactory.customerPutLicenses(id);
    const response = yield call(() => fetchEntitySaga(putCustomerLicenses, api.put, url, { data }));
    yield call(getCustomerLicensesSaga, {});
    return response;
  } catch (error) {
    return error;
  }
}

export function* putCustomerInstallerCodeSaga({ installerCode }) {
  try {
    const url = apiPathFactory.customerPutInstallerCode(installerCode);
    const response = yield call(() =>
      fetchEntitySaga(putCustomerInstallerCode, api.put, url, { installerCode })
    );
    return response;
  } catch (error) {
    return error;
  }
}

export function* getCustomerRolesSaga({ roleMask }) {
  try {
    const url = apiPathFactory.customerGetRoles(roleMask);
    const response = yield call(() => fetchEntitySaga(getCustomerRoles, api.get, url));
    return response;
  } catch (error) {
    return error;
  }
}

export function* patchCustomerRoleSaga({ customerId, newRoleMask }) {
  try {
    const url = apiPathFactory.customerPatchRole();
    const response = yield call(() =>
      fetchEntitySaga(patchCostumerRole, api.patch, url, {
        data: {
          customerId: customerId,
          newRoleMask: newRoleMask,
        },
      })
    );
    yield call(getCustomerLicensesSaga, {});
    return response;
  } catch (error) {
    return error;
  }
}

function* watchCustomerHonoaSaga() {
  yield takeLatest(GET_CUSTOMERS_HONOA.BASE, getCustomerHonoaSaga);
}

//-----------------------------------------------------------
function* watchCustomersInstallerCodeSaga() {
  yield takeLatest(GET_CUSTOMERS_INSTALLER_CODE.BASE, getCustomersInstallerCodeSaga);
}

//-----------------------------------------------------------
function* watchPutCustomerLicensesSaga() {
  yield takeLatest(PUT_CUSTOMER_LICENSES.BASE, putCustomerLicensesSaga);
}

//-----------------------------------------------------------
function* watchPutCustomerInstallerCodeSaga() {
  yield takeLatest(PUT_CUSTOMERS_INSTALLER_CODE.BASE, putCustomerInstallerCodeSaga);
}

//-----------------------------------------------------------

function* watchPutCustomerAttributes() {
  yield takeLatest(PUT_CUSTOMER_ATTRIBUTES.BASE, putCustomerAttributesSaga);
}

function* watchPutCustomerAttributesHonoa() {
  yield takeLatest(PUT_CUSTOMER_ATTRIBUTES_HONOA.BASE, putCustomerAttributesHonoaSaga);
}

//------------------------------------------------------------------------------
function* watchCustomersPropertySaga() {
  yield takeLatest(GET_CUSTOMERS_PROPERTY_MANAGERS.BASE, getCustomersPropertyManagersSaga);
}

function* watchCustomersLicensesSaga() {
  yield takeLatest(GET_CUSTOMERS_LICENSES.BASE, getCustomerLicensesSaga);
}

function* watchGetCustomerRolesSaga() {
  yield takeLatest(GET_CUSTOMERS_CUSTOMER_ROLES.BASE, getCustomerRolesSaga);
}

function* watchPatchCustomerRoleSaga() {
  yield takeLatest(PATCH_CUSTOMERS_CUSTOMER_ROLE.BASE, patchCustomerRoleSaga);
}

export default function* badRuleWarningWatchers() {
  yield fork(watchCustomerHonoaSaga);
  yield fork(watchCustomersPropertySaga);
  yield fork(watchPutCustomerLicensesSaga);
  yield fork(watchCustomersInstallerCodeSaga);
  yield fork(watchPutCustomerInstallerCodeSaga);
  yield fork(watchPutCustomerAttributes);
  yield fork(watchPutCustomerAttributesHonoa);
  yield fork(watchCustomersLicensesSaga);
  yield fork(watchGetCustomerRolesSaga);
  yield fork(watchPatchCustomerRoleSaga);
}
