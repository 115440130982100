import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { generatePath, withRouter } from 'react-router-dom';
import { Flex } from '@jcm-technologies/uikit/dist/atoms/Layout';
import { Button } from '@jcm-technologies/uikit/dist/atoms/Button';
import { getSchedulesSingleList } from '../../modules/schedule/actions';
import { IconSpan } from '../IconSpan/IconSpan';

import './FacilitySchedules.css';
import FacilitySchedulesContent from './components/FacilitySchedulesContent';

export class FacilitySchedules extends Component {
  constructor(props) {
    super(props);
    this.state = {
      needLoadRelays: true,
    };
  }

  componentDidMount = () => {
    const { getSchedulesSingleList, principalItem, principalParameter, secondaryItem } = this.props;
    this.getInstalationsRelays();
    if (principalParameter === 'universalGroupId' && principalItem && secondaryItem) {
      getSchedulesSingleList(secondaryItem.id);
    } else if (principalItem) {
      getSchedulesSingleList(principalItem.id);
    }
  };

  componentDidUpdate() {
    this.getInstalationsRelays();
  }

  getInstalationsRelays = () => {
    const { principalItem, secondaryItem, getSecondaryItem, getPrincipalItem, getRelays, relays } =
      this.props;
    if (principalItem === undefined) {
      getPrincipalItem();
    } else if (secondaryItem === undefined) {
      getSecondaryItem();
    } else if (!relays && this.state.needLoadRelays && principalItem && secondaryItem) {
      this.setState({ needLoadRelays: false });
      getRelays(principalItem.id, secondaryItem.id);
    }
  };

  showSchedules = () => {
    const { relays } = this.props;
    let selectedRelays;
    if (relays) {
      selectedRelays = relays.filter((relay) => relay.hasSchedules);
    }
    return selectedRelays;
  };

  showNotAuthorizedMessage = (selectedRelays) => {
    const { t, tenants } = this.props;
    if (selectedRelays) {
      return (
        <div className='facilitySchedules-error-message'>
          <br />
          <IconSpan tenants={tenants} defaultColor='#a4aebb' iconClassName='fas fa-info-circle' />
          {`${t('instalations.notAllow')}`}
        </div>
      );
    }
    return null;
  };

  redirectToBack = () => {
    const { match, history, urlParam1, urlParam2, goFacilities, facilityGroup } = this.props;
    const { params, path } = match;
    if (goFacilities) {
      history.push(`${urlParam1}/${urlParam2}`);
    } else if (params.id && params.universalGroupId) {
      // history.push(`/UniversalGroups/${params.universalGroupId}/Equipments`);
      history.goBack();
    } else {
      const urlToRedirect = path.replace(/\/Configuration$/, '');
      const decoded = generatePath(urlToRedirect, {
        instalationId: params.instalationId,
        instalationGroupId: facilityGroup?.id,
      });
      history.push(`${decoded}`);
    }
  };

  render() {
    const {
      putRelay,
      principalParameter,
      secondaryParameter,
      t,
      principalItem,
      secondaryItem,
      history,
      canShowBackButton,
    } = this.props;
    const principalId = principalItem ? principalItem.id : '';
    const secondaryId = secondaryItem ? secondaryItem.id : '';
    const selectedRelays = this.showSchedules();

    return (
      <>
        {selectedRelays && selectedRelays.length ? (
          <div className='facilitySchedules-fader'>
            <div className='facilitySchedules-content'>
              {selectedRelays
                ? selectedRelays.map((equipment) =>
                    !equipment?.warnings || equipment?.warnings?.length === 0 ? (
                      <FacilitySchedulesContent
                        className='individual-facilitySchedules-content'
                        {...equipment}
                        secondaryId={secondaryId}
                        principalId={principalId}
                        putRelay={putRelay}
                        principalParameter={principalParameter}
                        secondaryParameter={secondaryParameter}
                      />
                    ) : (
                      equipment.warnings.map((warn) =>
                        warn.code !== 91069 ? (
                          <FacilitySchedulesContent
                            className='individual-facilitySchedules-content'
                            {...equipment}
                            secondaryId={secondaryId}
                            principalId={principalId}
                            putRelay={putRelay}
                            principalParameter={principalParameter}
                            secondaryParameter={secondaryParameter}
                          />
                        ) : null
                      )
                    )
                  )
                : null}
            </div>
          </div>
        ) : (
          this.showNotAuthorizedMessage(selectedRelays)
        )}
        {canShowBackButton && selectedRelays ? (
          <Flex justifyContent='flex-end' paddingBottom={3} paddingRight={3}>
            <Button
              backgroundColor='blackLight'
              sizeButton='small'
              onClick={() => this.redirectToBack()}
            >
              {t('relaysConfiguration.goBack')}
            </Button>
          </Flex>
        ) : null}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  tenants: state.tenants,
  goFacilities: state.instalations.goFacilities,
  facilityGroup: state.instalationGroups.facilityGroup,
  urlParam1: state.instalations.urlParam1,
  urlParam2: state.instalations.urlParam2,
});

const facilitySchedules = withRouter(FacilitySchedules);

const FacilitySchedulesPage = withTranslation()(facilitySchedules);

export default connect(mapStateToProps, {
  getSchedulesSingleList,
})(FacilitySchedulesPage);
