export default {
  adjustTimePopUp: {
    manualDateTimeAdjust: 'Regolazione manuale di data e ora',
    actualHour: 'Ora attuale:',
    minutesCompensation: 'Minuti di compensazione da ora',
    insertSdMemory:
      "Inserire la memoria SD e alimentare il dispositivo esattamente all'ora successiva",
    inputTime: 'Mostra il tempo',
    save: 'Salva',
  },
};
