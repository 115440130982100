import React, { Component } from 'react';
import ButtonsGroup from '../../old_components/ButtonsGroup/ButtonsGroup';

import './PathNotExist.css';
import { withTranslation } from 'react-i18next';

class PathNotExist extends Component {
  render() {
    const { t } = this.props;
    return (
      <div className='page-not-exist'>
        <h1>{t('notAvailable.notFound')}</h1>
        <ButtonsGroup
          className='page-not-exist-button'
          buttons={[
            {
              value: t('notAvailable.returnHome'),
              onClick: () => this.props.history.push('/'),
            },
          ]}
        />
      </div>
    );
  }
}

const PathNotExistWithTranslation = withTranslation()(PathNotExist);

export default PathNotExistWithTranslation;
