import genericGrowAction from '../actions/genericGrow';
import {
  UNIVERSAL_GROUP_CREATE,
  UNIVERSAL_GROUP_GET,
  UNIVERSAL_GROUP_SET,
} from './universalGroups';
import { POST_SCHEDULES, PUT_SCHEDULE } from '../../schedule/contants';
import { INSTALATIONS_POST, INSTALATIONS_PUT } from './instalations';
import { INSTALATION_GROUPS_POST } from './instalationGroups';
import { EQUIPMENTS_POST, EQUIPMENTS_PUT } from './equipments';
import { UNIVERSAL_FOBS_DELETE_ALARM } from './universalGroupsFobs';

/* Redux state init */
const initialState = {
  isCloned: false,
  clonedGrow: {},
  canRedirect: false,
  redirectURL: '',
  forceUpdate: false,
};

export default function universalGroupsReducer(state = initialState, action) {
  if (action.type !== undefined) {
    switch (action.type) {
      case genericGrowAction.CLONE_GROW:
        return { ...state, isCloned: action.isCloned, clonedGrow: { ...action.item } };
      case genericGrowAction.RESET_CAN_REDIRECT:
        return { ...state, canRedirect: false };
      case UNIVERSAL_GROUP_SET.SUCCESS:
      case INSTALATIONS_PUT.SUCCESS:
      // case INSTALATION_GROUPS_PUT.SUCCESS:
      case UNIVERSAL_GROUP_CREATE.SUCCESS:
      case POST_SCHEDULES.SUCCESS:
      case PUT_SCHEDULE.SUCCESS:
      case INSTALATIONS_POST.SUCCESS:
      case INSTALATION_GROUPS_POST.SUCCESS:
      case EQUIPMENTS_POST.SUCCESS:
      case EQUIPMENTS_PUT.SUCCESS:
        return { ...state, canRedirect: true };
      case genericGrowAction.SET_MANUAL_GROW_REDIRECT_URL:
        return { ...state, redirectURL: action.url };
      case genericGrowAction.RESET_MANUAL_GROW_REDIRECT_URL:
        return { ...state, redirectURL: '' };
      case UNIVERSAL_FOBS_DELETE_ALARM.SUCCESS:
        return {
          ...state,
          forceUpdate: true,
        };
      case UNIVERSAL_GROUP_GET.SUCCESS:
        return {
          ...state,
          forceUpdate: false,
        };
      default:
        return state;
    }
  }
  return state;
}
