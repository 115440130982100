import { call, fork, takeLatest } from 'redux-saga/effects';
import { GET_COUNTRIES, GET_TIMEZONES, GET_TIMEZONES_BY_PRODUCT } from './constants';
import apiPathFactory from '../../core/old_common/rooting/apiPathFactory';
import fetchEntitySaga from '../old_to_refact/sagas/utils/fetchEntitySaga';
import api from '../../core/helpers/api';

export const getCountries = {
  base: (invitationData) => ({
    type: GET_COUNTRIES.BASE,
    invitationData,
  }),
  request: () => ({ type: GET_COUNTRIES.REQUEST }),
  success: (result) => ({ type: GET_COUNTRIES.SUCCESS, result }),
  failure: (error) => ({ type: GET_COUNTRIES.FAILURE, error }),
};

export const getTimezones = {
  base: (invitationData) => ({
    type: GET_TIMEZONES.BASE,
    invitationData,
  }),
  request: () => ({ type: GET_TIMEZONES.REQUEST }),
  success: (result) => ({ type: GET_TIMEZONES.SUCCESS, result }),
  failure: (error) => ({ type: GET_TIMEZONES.FAILURE, error }),
};

export const getTimezonesByProduct = {
  base: (code) => ({
    type: GET_TIMEZONES_BY_PRODUCT.BASE,
    code,
  }),
  request: () => ({ type: GET_TIMEZONES_BY_PRODUCT.REQUEST }),
  success: (result) => ({ type: GET_TIMEZONES_BY_PRODUCT.SUCCESS, result }),
  failure: (error) => ({ type: GET_TIMEZONES_BY_PRODUCT.FAILURE, error }),
};

export function* getCountriesSaga() {
  try {
    const url = apiPathFactory.countriesGetCountries();
    const response = yield call(() => fetchEntitySaga(getCountries, api.get, url));
    return response;
  } catch (error) {
    return error;
  }
}

export function* getTimezonesSaga() {
  try {
    const url = apiPathFactory.countriesGetTimeZones();
    const response = yield call(() => fetchEntitySaga(getTimezones, api.get, url));
    return response;
  } catch (error) {
    return error;
  }
}

export function* getTimezonesByProductSaga({ code }) {
  try {
    const url = apiPathFactory.countriesGetTimeZonesByProduct(code);
    const response = yield call(() => fetchEntitySaga(getTimezonesByProduct, api.get, url));
    return response;
  } catch (error) {
    return error;
  }
}

function* watchGetCountriesSaga() {
  yield takeLatest(GET_COUNTRIES.BASE, getCountriesSaga);
}

function* watchGetTimeZonesSaga() {
  yield takeLatest(GET_TIMEZONES.BASE, getTimezonesSaga);
}

// -------------------------------------------------------------------------------------

function* watchGetTimezonesByProductSaga() {
  yield takeLatest(GET_TIMEZONES_BY_PRODUCT.BASE, getTimezonesByProductSaga);
}

export default function* badRuleWarningWatchers() {
  yield fork(watchGetTimeZonesSaga);
  yield fork(watchGetCountriesSaga);
  yield fork(watchGetTimezonesByProductSaga);
}
