import React, { useCallback, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withTranslation } from 'react-i18next';
import StatisticsChartsTemplate from '../../old_components/StatisticsCharts';
import { getStatisticsEquipment } from '../../modules/old_to_refact/actions/equipments';
import { getStatistics } from '../../modules/statistics/actions';
import 'hammerjs';
import { getCookieAccessToken } from '../../core/old_common/utils/cookiesManager';

const StatisticsChartsContainer = ({ isEquipment, element, ...props }) => {
  const [actionSelected, setActionSelected] = useState({ value: 'sum_r1', label: 'r1Activations' });
  const dispatch = useDispatch();
  const accessToken = getCookieAccessToken();

  const statistics = useSelector((state) => state.statistics);
  const getStatisticsEquipmentAPI = useCallback(
    (facilityId, equipmentId) => dispatch(getStatisticsEquipment(facilityId, equipmentId)),
    [dispatch]
  );
  const getStatisticsAPI = useCallback(() => dispatch(getStatistics()), [dispatch]);

  const baseUnits = [
    { value: 'sum_r1', label: 'r1Activations' },
    { value: 'sum_r2', label: 'r2Activations' },
    { value: 'sum_fOk', label: 'fobsOk' },
    { value: 'sum_clon', label: 'suspiciusEmitters' },
    { value: 'sum_fBt', label: 'BLEactivations' },
    { value: 'sum_fKo', label: 'fobsKO' },
    { value: 'sum_rx', label: 'dataReceived' },
    { value: 'sum_tx', label: 'dataTransmitted' },
    { value: 'sumfBatt', label: 'emittersWithBatteryLow' },
    { value: 'sum_op', label: 'openedEvents' },
    { value: 'sum_cl', label: 'closedEvents' },
  ];

  useEffect(() => {
    if (accessToken) {
      if (isEquipment && element?.id && element?.facilityId) {
        getStatisticsEquipmentAPI(element.facilityId, element.id);
      } else {
        getStatisticsAPI();
      }
    }
  }, [accessToken]);

  return (
    <StatisticsChartsTemplate
      actionSelected={actionSelected}
      onSetAction={(value) => setActionSelected(value)}
      baseUnits={baseUnits}
      data={statistics.data}
    />
  );
};

const StatisticsCharts = withTranslation()(StatisticsChartsContainer);

export default StatisticsCharts;
