export default {
  compareMemory: {
    nodata: 'Nessun dato disponibile in questa tabella',
    filter: 'Filtro',
    foundInBoth: 'Trovato su entrambi i siti',
    foundInBBDD: 'Trovato nel database',
    foundInMemory: 'Trovato in memoria',
    refreshMemory: 'Aggiorna memoria',
    deleteBBDD: 'Elimina dal database',
    addBBDD: 'Aggiungi al database',
    confirmDelete: 'Vuoi rimuovere il emettitore?',
    reserved: 'Riservato',
  },
};
