import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@jcm-technologies/uikit/dist/atoms/Layout';
import React, { useEffect, useState } from 'react';
import {
  getOwnUser,
  getUserRolesEmployees,
  putUserPermissions,
} from '../../../modules/user/actions';
import { TableEmployees } from '../../../components/Administration/Employees/Table';
import { SaveActionModal } from '../../../components/Administration/Employees/Modal/SaveAction';

export function Edit() {
  const { ownUsers, userRoles } = useSelector((state) => state.user);
  const [dataLoaded, setDataLoaded] = useState(ownUsers || []);
  const [isOpened, setIsOpened] = useState(false);
  const [actionToSave, setActionToSave] = useState('');
  const [dataToSave, setDataToSave] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOwnUser());
    dispatch(getUserRolesEmployees());
  }, []);

  const resetStateData = () => {
    setIsOpened(false);
    setDataToSave('');
    setActionToSave('');
  };

  const actionToSaveMapper = {
    role: () => {
      const bodyUser = {
        ...dataToSave.user,
        roleId: dataToSave.id,
        role: dataToSave.name,
      };
      dispatch(putUserPermissions(bodyUser));
      resetStateData();
    },
    enabled: () => {
      dispatch(putUserPermissions(dataToSave));
      resetStateData();
    },
  };
  const handleSave = () => actionToSaveMapper[actionToSave]();
  return (
    <Box height='100%' maxWidth='100%' marginY={3}>
      <TableEmployees
        dataLoaded={dataLoaded}
        setDataLoaded={setDataLoaded}
        setIsOpened={setIsOpened}
        setActionToSave={setActionToSave}
        setDataToSave={setDataToSave}
        data={ownUsers}
        userRoles={userRoles}
      />
      <SaveActionModal handleSave={handleSave} isOpened={isOpened} setIsOpened={setIsOpened} />
    </Box>
  );
}
