export default {
  instalations: {
    groupsTab: 'Skupiny',
    equipmentsTab: 'Zařízení',
    addGroup: 'Přidat novu skupinu',
    deleteConfirmation: 'Jsi si jistý, že chceš nahradit tuto instalaci?',
    add: 'Vytvořit instalaci',
    config: 'Konfigurovat instalaci',
    notAllow: 'Zadaná zařízení neumožňují časové rozvrhy',
    allDevicesConnected: 'Vše připojeno',
    allDevicesDisconnected: 'Příští servisní prohlídka:',
    includeReservations: 'Zahrnout rezervace?',
    includeConfigFields: 'Zahrnout konfigurační pole?',
    acceptExportPopUpButton: 'Export',
    exportPopUpTitle: 'Export skupin',
    nameTableheader: 'Jméno',
    allEquipmentsStatusOk: 'Všechna zařízení připojena',
    numberOfConnectedDevices: 'Zařízení nalezena',
    filters: 'Filtry',
    criticalDeleteTitle: 'DŮLEŽITÁ NEVRATNÁ AKCE',
    firstAreYouSureCriticalDelete: 'Určitě chcete odstranit  ',
    secondAreYouSureCriticalDelete: '',
    noGoBack: 'TATO AKCE JE NEVRATNÁ',
    transfer: 'Převést',
    transferPopUpLabel: 'Správce, který bude řídit instalaci',
    transferPopUpSelector: 'Zvolte uživatele',
    restore: 'Obnovit',
    recycleBin: 'Odpadkový koš',
    creation: 'Vytvoření',
    saveMemory: 'Uložit paměť',
    substitution: 'Náhrada',
    fob: 'Vysílač',
    equipment: 'Vybavení',
    deletion: 'Vymazání',
    moved: 'Přestěhoval',
    locked: 'Zamčeno',
    unlocked: 'Odemčený'
  },
};
