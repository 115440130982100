const ConditionsPolicyDictionary = [];
ConditionsPolicyDictionary.default =
  'https://www.jcm-tech.com/cloudassistant-general-conditions-and-licence-of-use/';
ConditionsPolicyDictionary['es-ES'] =
  'https://www.jcm-tech.com/es/cloudassistant-condiciones-generales-y-licencia-de-uso/';
ConditionsPolicyDictionary['en-GB'] =
  'https://www.jcm-tech.com/cloudassistant-general-conditions-and-licence-of-use/';
ConditionsPolicyDictionary['de-DE'] =
  'https://www.jcm-tech.com/de/cloudassistant-allgemeine-nutzungsbedingungen-und-nutzungslizenz/';
ConditionsPolicyDictionary['fr-FR'] =
  'https://www.jcm-tech.com/fr/cloudassistant-conditions-generales-et-licence-dutilisation/';
ConditionsPolicyDictionary['it-IT'] =
  'https://www.jcm-tech.com/it/cloudassistant-condizioni-generali-e-licenza-duso/';

export default ConditionsPolicyDictionary;
