import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import PopUpConfirm from '../PopUpConfirm';
import SwitchCheckbox from '../SwitchCheckbox';

import './PopUpConfigActiveEvents.css';
import { ContentContainer } from '../ContentContainer/ContentContainer';

class PopUpConfigActiveEvents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeEventsToSD: props.data,
    };
  }

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    const { activeEventsToSD } = this.state;
    if (!activeEventsToSD.name && nextProps.data.name) {
      this.setState({ activeEventsToSD: nextProps.data });
    }
  };

  onChange = (e) => {
    const { activeEventsToSD } = this.state;
    const paramList = [...activeEventsToSD.ProductParamsList];
    const newParamList = paramList.map((param) => {
      if (param.value.toString() === e.target.id.toString()) {
        param.enabled = e.target.checked;
      }
      return param;
    });
    this.setState({ activeEventsToSD: { ...activeEventsToSD, ProductParamsList: newParamList } });
  };

  getCheckboxes = () => {
    const { activeEventsToSD } = this.state;
    const splitedActiveValues = activeEventsToSD.value ? activeEventsToSD.value.split(',') : [];
    if (activeEventsToSD.ProductParamsList) {
      const paramList = [...activeEventsToSD.ProductParamsList];
      const paramsCheck = paramList.map((param) => {
        const isActive = splitedActiveValues.find((x) => x === param.value.toString());
        if (isActive) {
          return (
            <div>
              <SwitchCheckbox
                key={param.value}
                id={param.value}
                value={param.enabled}
                onChange={this.onChange}
                placeHolder={this.props.t(`paramsName.${param.localization}`)}
              />
            </div>
          );
        }
        return '';
      });
      return paramsCheck;
    }
  };

  handleAccept = () => {
    const { closePopup, onSave } = this.props;
    const { activeEventsToSD } = this.state;
    onSave(activeEventsToSD);
    closePopup();
  };

  render() {
    const { tenants, closePopup, t } = this.props;
    return (
      <ContentContainer tenants={tenants} title={t('paramsName.MessageBox_Message')}>
        <PopUpConfirm
          className='config-active-events'
          tenants={tenants}
          onAcceptFn={this.handleAccept}
          onCancelFn={closePopup}
        >
          {this.getCheckboxes()}
        </PopUpConfirm>
      </ContentContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  tenants: state.tenants,
});

const PopUpConfigActiveEventsTranslation = withTranslation()(PopUpConfigActiveEvents);

export default connect(mapStateToProps, {})(PopUpConfigActiveEventsTranslation);
