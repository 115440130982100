export default {
  gsmPopUp: {
    title: 'Stato Servizio ',
    deviceNotSimInfo: 'Il dispositivo è offline',
    simContractInfo: 'Informazioni contratto',
    scadenza: 'Scadenza',
    status: 'Stato',
    infoNotAvailable: 'Informazioni non disponibili',
    simConnectionInfo: 'Informazioni sulla connessione SIM',
    cardIdentifier: 'Identificatore (ICCID)',
    activationDate: 'Data di attivazione',
    lastMonthDataUpload: 'Caricamento dati del mese scorso',
    lastMonthDataDownload: 'Download dati del mese scorso',
    lastSeen: 'Ultimo accesso',
    contractStatus: 'Stato del contratto',
    simStatus: 'Stato SIM',
    deviceStatus: 'Device Status',
    enabled: 'Abilitato',
    disabled: 'Disabilitato',
    notAvailable: 'Non disponibile',
    connected: 'Connesso',
    disconnected: 'Disconnected',
  },
};
