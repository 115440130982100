import moment from 'moment';

import IconSkull from '@jcm-technologies/uikit/dist/atoms/Icons/IconSkull';
import IconBatteryQuarter from '@jcm-technologies/uikit/dist/atoms/Icons/IconBatteryQuarter';
import React from 'react';
import { getCookieDateTimeFormat } from '../../../core/old_common/utils/cookiesManager';
import i18next from 'i18next';

export const iconAlarmTypeList = {
  44801: <IconSkull />,
  44800: <IconBatteryQuarter />,
};

export const generateAlarmSumary = (alarms) => {
  let tooltipData = '';
  const dateFormat = getCookieDateTimeFormat() ? getCookieDateTimeFormat() : 'yyyy-MM-dd';

  alarms.forEach((element) => {
    switch (element.alarmCode) {
      case 44801:
        tooltipData += `<span>${
          element.distinctCount || 1
        } x <i class="fas fa-skull-crossbones"></i> ${i18next.t(`code.${element.alarmCode}`)}: ${moment(
          element.lastSeen
        ).format(dateFormat.toUpperCase())}<br></span>`;
        break;
      case 44800:
        tooltipData += `<span>${
          element.distinctCount || 1
        } x <i class="fas fa-battery-quarter"></i> ${i18next.t(`code.${element.alarmCode}`)}: ${moment(
          element.lastSeen
        ).format(dateFormat.toUpperCase())}<br></span>`;
        break;
      default:
        return false;
    }
  });

  return tooltipData;
};
