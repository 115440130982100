export default {
  errors: {
    title: {
      honoa: 'HONOA Torzustand zeigen:',
    },
    1000: 'Anmeldung fehlgeschlagen',
    100: 'Benutzer angemeldet',
    101: 'Benutzer abgemeldet',
    102: 'Passwort erfolgreich geändert',
    103: 'Passwort vergessen abgeschlossen abgeschlossen',
    104: 'Passwort zurücksetzen abgeschlossen',
    105: 'E-Mail senden an:',
    106: 'Ungültiger Benutzer',
    107: 'Benutzer nicht aktualisiert',
    108: 'Einladung abgelaufen',
    109: 'Einladung nicht gelöscht',
    10: 'Starten des CloudAssistant API-Konfigurationsabschnitts',
    110: 'Benutzer in IS4 nicht bestätigt und aktualisiert',
    111: 'Benutzer nicht erfolgreich registriert',
    112: 'Fehlanpassung von Benutzer- oder Kunden-ID',
    113: 'Kunden-ID existiert nicht',
    114: 'Einladung nicht gesendet',
    115: 'Die Einladung wurde gelöscht',
    116: 'Rollback-Einladung abgeschlossen',
    117: 'Kundenattribute nicht richtig erstellt',
    118: 'Nicht genügend Berechtigungen zum Anzeigen dieser Elemente',
    119: 'Nicht genügend Berechtigungen zum Anzeigen oder Aktualisieren dieser Elemente',
    11: 'Konfiguration abgeschlossen. Die API ist für eingehende Anrufe bereit',
    120: 'Nicht genügend Berechtigungen zum Erstellen dieser Elemente',
    121: 'Nicht genügend Berechtigungen zum Löschen dieser Elemente',
    122: 'Benutzer nicht in Identität gelöscht',
    123: 'Benutzer nicht gefunden',
    124: 'Konflikt beim Löschen des Benutzers in der Identität',
    125: 'Parallelitätsfehler',
    126: 'Standardfehler',
    127: 'E-Mail ist bereits vorhanden',
    128: 'Der Rollenname ist bereits vorhanden',
    129: 'Der Benutzername existiert bereits',
    12: 'Legen Sie die Konfiguration der API-Version fest',
    130: 'Ungültige E-Mail',
    131: 'Ungültiger Rollenname',
    132: 'Ungültiger Token',
    133: 'Ungültiger Benutzername',
    134: 'Login bereits zugeordnet',
    135: 'Passwort erfordert Kleinbuchstaben',
    136: 'Das Passwort erfordert nicht aplhanumerische Werte',
    137: 'Das Passwort erfordert eindeutige Zeichen',
    138: 'Passwort erfordert Großbuchstaben',
    139: 'Passwort zu kurz',
    13: 'Allgemeine API-Einstellungen',
    140: 'Das Einlösen des Wiederherstellungscodes ist fehlgeschlagen',
    141: 'Der Benutzer hat bereits ein Passwort',
    142: 'Benutzer hat bereits eine Rolle',
    143: 'Benutzer ohne Rolle',
    144: 'Benutzerblockierung nicht aktiviert',
    145: 'Authentifizierung fehlgeschlagen, ungültige Client-ID oder ausgeblendet',
    146: 'Ungültige Anfrage',
    147: 'E-Mail oder Passwort sind nicht korrekt',
    148: 'Ungültiger Geltungsbereich',
    149: 'Dieser Kunde ist nicht berechtigt, die Art der angeforderten Anfrage zu verwenden',
    14: 'SMTP-Einstellungen',
    150: 'Nicht unterstützter Grant-Typ',
    151: 'Benutzer nicht in Identität gelöscht',
    152: 'Konflikt beim Löschen eines Benutzers in der Identität',
    153: 'Abonnements nicht aktualisiert',
    154: 'Abonnements wurden aktualisiert',
    155: 'Fehler beim Abrufen der Abonnements',
    156: 'Der Kunde verfügt über universelle Gruppen oder Einrichtungen mit Installationscode',
    157: 'Unbekannter Fehler',
    158: 'Nicht genug Genehmigungen',
    159: 'Benutzer-E-Mail existiert bereits',
    15: 'Konfigurieren von Coookie-Richtlinien',
    160: 'Nicht genug Abonnements',
    161: 'Benutzerrollen ungültig',
    162: 'Benutzerrolle nicht aktualisiert',
    163: 'Benutzer nicht registriert oder ohne Abonnement',
    164: 'Benutzerrollen oder Kunde ungültig',
    165: 'Der Administrator kann nicht aktualisiert werden',
    166: 'Installationscode nicht aktualisiert',
    167: 'Produktfamilien nicht aktualisiert',
    168: 'Produkte nicht aktualisiert',
    169: 'Kein Element mit dieser ID gefunden',
    16: 'Kompatibilitätsumgebung festlegen:',
    170: 'Token aktualisieren',
    171: 'Nicht genug Abonnements',
    172: 'Gerät nicht gefunden',
    17: 'Datenbankkontext einrichten',
    18: 'Authentifizierungsdienste',
    19: 'Fügen Sie eine Prahlerdokumentation hinzu',
    200: 'Liste aller Benutzer abrufen',
    300: 'Falsche Argumente oder Anzahl der Elemente für eine einzelne Anforderung überschritten',
    301: 'Update erfolgreich abgeschlossen',
    302: 'Validierungsfehler',
    400: 'Mieter erfolgreich gespeichert',
    401: 'Fehlende Mieterdaten! Speichern erfolglos',
    404: 'Gerät offline oder Verbindungsfehler.',
    90000: 'Loopback-Test wurde angefordert:',
    90001: 'Eine Ausnahme wurde angefordert:',
    90002: 'Der Rückgabestatuscode wurde angefordert:',
    9000: 'Anmeldung fehlgeschlagen',
    9001: 'Passwort zurücksetzen fehlgeschlagen',
    9002: 'Passwort vergessen fehlgeschlagen',
    9003: 'Passwort ändern fehlgeschlagen',
    9004: 'Passwort-Missmatch',
    9005: 'Das Passwort erfordert mindestens eine Ziffer',
    9006: 'Unbekannter Benutzer',
    90100: 'SQL-Ausnahme aufheben',
    90101: 'Erstellung fehlgeschlagen.',
    90102: 'Der von Ihnen eingegebene Name existiert bereits, Sie müssen ihn ändern',
    90103: 'Nicht gefunden.',
    90104: 'Update fehlgeschlagen',
    90105: 'Löschen fehlgeschlagen',
    90106: 'Dieser Inhalt existiert bereits',
    90107: 'Inhalt darf nicht null sein.',
    90108: 'Import fehlgeschlagen.',
    90109: 'Export fehlgeschlagen.',
    90110:
      'Installationscode kann in Einrichtungen mit zugewiesenen Anhängern nicht aktualisiert werden',
    90111: 'Einrichtungs-ID oder URL ist ungültig',
    90112: 'Facility Entity Group nicht erstellt',
    90113: 'Facility-Gruppe muss Namen haben',
    90114: 'Der Name der Facility-Entitätsgruppe ist für diese Facility bereits vorhanden',
    90115: 'Facility Entity Group nicht aktualisiert',
    90116: 'Facility Entity Group nicht gelöscht',
    90117: 'Entitätskontakt darf nicht null sein',
    90118: 'Facility Entity nicht aktualisiert',
    90119: 'Beschreibung oder Code der Facility-Entität für diese Gruppe ist bereits vorhanden',
    90120: 'Kontakt mit Position 0 kann nicht gelöscht werden',
    90121: 'Kontakt nicht gelöscht',
    90122: 'Kontakt nicht erstellt',
    90123: 'Entität hat bereits 5 Kontakte',
    90124: 'Entitätskontakt nicht aktualisiert',
    90125: 'GroupId existiert für diese Einrichtung nicht',
    90126: 'FacilityId ungültig',
    90127: 'Facility Entity Contact ungültig',
    90128: 'Der Installationscode muss für jede Einrichtung in der universellen Gruppe gleich sein',
    90129:
      'Der Installationscode muss für die Einrichtung und die universelle Gruppe identisch sein',
    91000: 'VDP-Mac oder ID nicht gefunden',
    91001: 'Ungültiger Mac',
    91002: 'E-Mail-Erweiterung nicht gefunden',
    91003: 'Fehler beim Aktualisieren des vdp-Namens',
    91004: 'Fehler beim Erstellen der vdp-Kontaktbeziehung',
    91005: 'Fehler beim Erstellen des Kontakts',
    91006: 'Fehler beim Löschen von Kontakten',
    91007: 'Fehler beim Aktualisieren des Kontaktnamens',
    91008: 'Kundenname existiert bereits',
    91009: 'Wohnungs- oder Türcode oder ungültig',
    91010: 'Code nicht eingeführt',
    91011: 'Anzahl der beschädigten Kanäle',
    91012: 'Die letzte Relaisbeziehung kann nicht gelöscht werden',
    91013: 'Relais kann nicht deaktiviert werden, da Gruppen zugewiesen sind',
    91014: 'Ungültiger Codebereich',
    91015: 'Codes müssen vom gleichen Typ sein',
    91016: 'Nicht genügend Positionen in der Gruppe',
    91017: 'Code existiert bereits in der Einrichtung',
    91018: 'Code existiert bereits in Universalien',
    91019: 'Code ist eine Ersetzung eines gelöschten Universums',
    91020: 'Diese Einrichtung unterstützt keine Zeitpläne',
    91021: 'Nichts zu kopieren',
    91022: 'Nichts zu bewegen',
    91023: 'Produkt ungültig',
    91024: 'Zeitplan nicht gefunden',
    91025: 'Zeitplan mit zugewiesener Einrichtung kann nicht gelöscht werden',
    91026: 'Die angeforderte Gruppe ist nicht universell',
    91027: 'Das Gerät hat einige ungültige Parameter',
    91028: 'Eine universelle Gruppe mit zugewiesenen Einrichtungen kann nicht gelöscht werden',
    91029: 'Seriennummer oder eindeutiger Schlüssel nicht registriert',
    91030: 'Seriennummer oder eindeutiger Schlüssel dürfen nicht null sein',
    91031: 'Kunden-Code ist nicht korrekt',
    91032: 'Der Installationscode ist nicht korrekt',
    91033: 'Passwort ist nicht korrekt',
    91034: 'Datei ist beschädigt',
    91065: 'Link bereits vergeben',
    91066: 'Beim Deaktivieren der Verbindung ist ein Fehler aufgetreten',
    91067: 'Falscher Aktivierungsschlüssel',
    91068: 'Nicht erfolgreiche API-Anforderung',
    91069: 'Produkt nicht aktiviert',
    91070: 'Produkt nicht gefunden',
    91071: 'Keine Relais ausgewählt. Schaltfläche "Konfigurieren" verwenden',
    91072: 'Relaisname existiert bereits',
    91073: 'Maximale Anzahl von Installationen erreicht',
    91074: 'Dieser Aktivierungsschlüssel wird von einem anderen Benutzer verwendet.',
    91100: 'Getrenntes Gerät',
    91101: 'Zwillinge für nicht behandelte Ausnahme-Lesegeräte',
    91102: 'Speicherausnahme lesen',
    91103: 'Speicherausnahme schreiben',
    91104: 'Schreibspeicherfehlanpassung',
    91105: 'Das Gerät hat einen Installateurcode',
    91106: 'Das Gerät hat keinen Installateurcode',
    91201: 'Benutzer existiert bereits. Alle Daten werden vererbt',
    91203: 'Benutzer existiert bereits in dieser Einrichtung',
    91203: 'Benutzer existiert bereits in dieser Einrichtung',
    91302: 'SIM-Informationen sind nicht verfügbar',
    91303: 'Der SIM-Vertrag ist nicht verfügbar',
    91307: 'Seriennummer kann nicht abgerufen werden',
    91308: 'Aktivierungsschlüssel null oder leer',
    91309: 'Aktivierungsschlüssel null, leer oder ungültig',
    91310: 'Diese Serie ist dem Benutzer nicht zugeordnet',
    91311: 'Fehler bei Kommunikation mit Prod-Dienst',
    91312: 'Fehler bei Lizenzerstellung',
    91400: 'Fehler bei Alarmlöschung',
    91400: 'Fehler beim Löschen des Fehlers',
    91450: 'Folgende Zeitpläne haben keinen gültigen Bereich: ',
    91451: 'Mindestens ein Feiertag muss ausgewählt werden',
    91452: 'Das Gerät unterstützt keine Zeitpläne mit Feiertagen',
    91452: 'Ein verwendetes Gerät unterstützt keine Zeitpläne mit Feiertagen',
    91453: 'Der Name des Zeitfensters existiert bereits',
    91527: 'Anlage mit zugeordneter Universalgruppe kann nicht gelöscht werden.',
    91528: 'Gerät mit zugeordneter Gruppe kann nicht gelöscht werden.',
    91529: 'Eine Gruppe mit Sendern kann nicht gelöscht weden.',
    91530:
      'Die Zuordnung dieses Geräts kann nicht aufgehoben werden, da das verbleibende Gerät keine Sender unterstützt',
    91531: 'Anlage kann nicht neu zugeordnet werden, weil sie Universalgruppen nutzt',
    91532: 'Anlage enthält inkompatible Geräte',
    91534: 'Maximale Honoa-Geräte für diese Anlage erreicht',
    91536: 'Der Name der Anlage existiert bereits',
    91537: 'Eine Gruppe mit Honoa-Benutzern kann nicht gelöscht werden',
    91538: 'Fehler beim PDF-Download',
    91539: 'Ein Gerät, das gerade in Anlagen verwendet wird, kann nicht deaktiviert werden.',
    91540:
      'Die Zuordnung des Geräts kann nicht aufgehoben werden, weil die Gruppe HONOA-Benutzer enthält',
    91800: 'Nicht unterstütztes Telefonnummernformat',
    91801: 'Nicht unterstütztes URL-Website-Format',
    91802: 'Dieses Gerät unterstützt keine Sender',
    91803: 'Diese Gruppe unterstützt keine Sender',
    91804: 'Der Name eines oder mehrerer dieser Anlage zugeordneten Zeitfenster ist zu lang.',
    91853: 'Die Zeitzone darf nicht leer sein.',
    91854: 'Die Gruppe unterstützt keine HONOA-Benutzer',
    92030: 'Ihr Abonnement ist mit dieser Funktion nicht kompatibel.',
    92060: 'Das Anfangsdatum darf nicht hinter dem Enddatum liegen',
    92061: 'Das Anfangsdatum muss später als heute sein',
    92031: 'Funktion ohne Endschalter (Sensoren/Eingänge) nicht zulässig',
    communicationError: 'Kommunikationsfehler versuchen Sie es später erneut',
    deletedUser: 'Benutzer gelöscht',
    generic: 'Eindeutige Kennung bereits verwendet',
    groupsAssigned: 'Gruppen zugewiesen: ',
    name: 'Dieser Name ist nicht verfügbar',
    nameRequired:
      'Konnte nicht gespeichert werden. Benennungen in Familien und Produkten erforderlich.',
    needsMinOnRelay: 'Es muss mindestens ein zugeordnetes Relais geben',
    noContent: 'Es sind noch keine Empfänger in dieser Installation angelegt',
    operationNotCompleted: 'Die Operation konnte nicht abgeschlossen werden',
    redirectWarning: 'Sie wurden zur Hauptseite weitergeleitet',
    selectTypeFirst: 'Wählen Sie zuerst einen Typ aus',
    timeout: 'Auszeit.',
    unexpectedError: 'Unerwarteter Fehler',
    uniqueIdentity: 'Dieser Inhalt existiert bereits',
    universalGroupsAssigned: 'UG zugewiesen: ',
    zeroCode: 'Vorsicht! Es wird mit Installateurcode 0 gearbeitet',
    402: 'Zahlung erforderlich',
    91075: 'Nicht genug verfügbare Reservierungen',
    304: 'Literal ist ungültig (leere, vorangehende oder abschließende Leerzeichen, ...)',
  },
};
