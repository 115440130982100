import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Popup from '../../Popup';
import RemoveSelectedItemsPopUp from './RemoveSelectedItemsPopUp';
import MultiselectionPopUpsContent from './MultiselectionPopUpsContent';
import Button from './Button';

export class MultiselectionGridPopUps extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isRemove: false,
      popup: {
        isPopupOpen: false,
        text: '',
        onAcceptFn: () => {},
        onCancelFn: () => {},
        action: '',
      },
    };
  }

  setPopUpData = (isPopupOpen, onAcceptFn, onCancelFn, text, action, isRemove) => {
    this.setState({ isRemove, popup: { isPopupOpen, onAcceptFn, onCancelFn, text, action } });
  };

  onCancel = () => {
    this.setState({
      popup: {
        isPopupOpen: false,
        text: '',
        onAcceptFn: () => {},
        onCancelFn: () => {},
      },
    });
  };

  render() {
    const { tenants, t, selectedItems, isHidden, gridCallbacks, gridDatas, onlyRemove, onlySave } =
      this.props;
    return (
      <>
        <div
          className={
            onlyRemove || onlySave
              ? 'one-button-only'
              : 'genericFormControl genericFormControlRight genericFormControlButtonsFobs'
          }
        >
          {!onlySave ? (
            <Button
              tenants={tenants}
              isHidden={isHidden}
              onClick={(e) => {
                e?.preventDefault();
                e?.stopPropagation();
                this.setPopUpData(
                  true,
                  gridCallbacks.deleteItemsCallback,
                  this.onCancel,
                  t('instalations.criticalDeleteTitle'),
                  'delete',
                  true
                );
              }}
            >
              {t('fobsPopUps.remove')}
            </Button>
          ) : (
            ''
          )}
          {onlyRemove || onlySave ? (
            ''
          ) : (
            <Button
              tenants={this.props.tenants}
              isHidden={isHidden || onlyRemove}
              onClick={(e) => {
                e?.preventDefault();
                e?.stopPropagation();
                this.setPopUpData(
                  true,
                  gridCallbacks.moveItemsCallback,
                  this.onCancel,
                  t('fobsPopUps.move'),
                  'move',
                  false
                );
              }}
            >
              {t('fobsPopUps.move')}
            </Button>
          )}

          {onlyRemove || onlySave ? (
            ''
          ) : (
            <Button
              tenants={this.props.tenants}
              isHidden={isHidden || onlyRemove}
              onClick={(e) => {
                e?.preventDefault();
                e?.stopPropagation();
                this.setPopUpData(
                  true,
                  gridCallbacks.copyItemsCallback,
                  this.onCancel,
                  t('fobsPopUps.copy'),
                  'copy',
                  false
                );
              }}
            >
              {t('fobsPopUps.copy')}
            </Button>
          )}
          {onlySave ? (
            <Button
              tenants={this.props.tenants}
              isHidden={isHidden || onlyRemove}
              onClick={(e) => {
                e?.preventDefault();
                e?.stopPropagation();
                this.setPopUpData(
                  true,
                  gridCallbacks.addItemCallback,
                  this.onCancel,
                  t('fobsPopUps.add'),
                  'save',
                  true
                );
              }}
            >
              {t('fobsPopUps.add')}
            </Button>
          ) : (
            ''
          )}
        </div>

        <Popup onCloseIconClick={this.state.popup.onCancelFn} isOpen={this.state.popup.isPopupOpen}>
          {this.state.isRemove ? (
            <RemoveSelectedItemsPopUp
              tenants={this.props.tenants}
              selectedItems={selectedItems}
              {...this.state.popup}
            />
          ) : (
            <MultiselectionPopUpsContent
              tenants={this.props.tenants}
              selectedFobList={selectedItems}
              {...this.state.popup}
              {...gridDatas}
              // moveToPrincipalList={gridDatas.moveToPrincipalList}
              // getSecondaryList={gridDatas.getSecondaryList}
              // moveToSecondaryList={gridDatas.moveToSecondaryList}
            />
          )}
        </Popup>
      </>
    );
  }
}

const MultiselectionGridPopUpsWithTranslation = withTranslation()(MultiselectionGridPopUps);

export default MultiselectionGridPopUpsWithTranslation;
