import { Modal } from '@jcm-technologies/uikit/dist/molecules/Modal';
import { Flex, Grid, GridItem } from '@jcm-technologies/uikit/dist/atoms/Layout';
import { Button } from '@jcm-technologies/uikit/dist/atoms/Button';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Field } from '@jcm-technologies/uikit/dist/atoms/Field';
import { Input } from '@jcm-technologies/uikit/dist/atoms/Input';
import PropTypes from 'prop-types';
import { getContrastColor } from '../../../../../../core/helpers/colorManager';

export const ModalAddUser = ({
  isOpenModalAddUser,
  setIsOpenModalAddUser,
  setNewUser,
  newUserErrors,
  onCreateNewUser,
}) => {
  const { t } = useTranslation();
  const { color1 } = useSelector((state) => state.tenants);
  return (
    <Modal
      isOpen={!!isOpenModalAddUser}
      title={t('instalationUserGroups.addUser')}
      closeWithButton
      id='modal-add-user'
      width={[300, 500, 664]}
      onModalClose={() => setIsOpenModalAddUser(null)}
    >
      <Modal.Content>
        <Grid gridTemplateAreas="'. .'" gridTemplateColumns='1fr 1fr' gridGap={2}>
          <GridItem>
            <Field label={t('form.email')}>
              <Input
                id='email'
                name='email'
                type='email'
                hasError={newUserErrors?.email}
                errorMessage={t('errors.130')}
                onChange={(name, value) => setNewUser((prev) => ({ ...prev, email: value }))}
              />
            </Field>
          </GridItem>
          <GridItem>
            <Field label={t('form.name')}>
              <Input
                id='name'
                name='name'
                onChange={(name, value) => setNewUser((prev) => ({ ...prev, name: value }))}
              />
            </Field>
          </GridItem>
          <GridItem>
            <Field label={t('form.surname')}>
              <Input
                id='surname'
                name='surname'
                onChange={(name, value) => setNewUser((prev) => ({ ...prev, surname: value }))}
              />
            </Field>
          </GridItem>
          <GridItem>
            <Field label={t('form.phone')}>
              <Input
                id='phone'
                name='phone'
                type='tel'
                hasError={newUserErrors?.phone}
                errorMessage={t('formValidation.numericField')}
                onChange={(name, value) => setNewUser((prev) => ({ ...prev, phone: value }))}
              />
            </Field>
          </GridItem>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Flex alignItems='center' justifyContent='flex-end'>
          <Button
            backgroundColor={color1}
            marginRight={2}
            color={getContrastColor(color1)}
            action={() => onCreateNewUser()}
          >
            {t('general.save')}
          </Button>
          <Button
            sizeText='display14'
            backgroundColor='blackLight'
            id='cancel-button'
            action={() => setIsOpenModalAddUser(null)}
          >
            {t('general.cancel')}
          </Button>
        </Flex>
      </Modal.Actions>
    </Modal>
  );
};

ModalAddUser.propTypes = {
  setNewUser: PropTypes.func.isRequired,
  setIsOpenModalAddUser: PropTypes.func.isRequired,
  isOpenModalAddUser: PropTypes.bool.isRequired,
  newUserErrors: PropTypes.shape({
    email: PropTypes.bool,
    phone: PropTypes.bool,
  }),
  onCreateNewUser: PropTypes.func.isRequired,
};

ModalAddUser.defaultProps = {
  newUserErrors: null,
};
