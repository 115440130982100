import React from 'react';
import InstalationEquipmentsUserGroups from '../InstalationEquipmentUserGroups';
import EquipmentRelayTabContent from '../EquipmentRelayTabContent/EquipmentRelayTabContent';
import FobsGrid from '../FobsGrid/FobsGrid';
import InstalationEquipmentsParameters from '../InstalationEquipmentsParameters';
import InstalationEquipmentsInfo from '../InstalationEquipmentsInfo';
import InstalationEquipmentsNeighbours from '../InstalationEquipmentsNeighbours';
import EquipmentActionTabContent from '../EquipmentActionTabContent/EquipmentActionTabContent';
import EquipmentEventsTab from '../EquipmentEventsTab';
import InstalationEquipmentsRemoteComunications from '../InstalationEquipmentsRemoteComunications';

const tabType = [];
tabType.info = (props) => <InstalationEquipmentsInfo {...props} />;
tabType.parameters = (props) => <InstalationEquipmentsParameters {...props} />;
tabType.relays = (props) => <EquipmentRelayTabContent {...props} />;
tabType.codes = (props) => <FobsGrid {...props} />;
tabType.groups = (props) => <InstalationEquipmentsUserGroups {...props} />;
tabType.actions = (props) => <EquipmentActionTabContent {...props} />;
tabType.events = (props) => <EquipmentEventsTab {...props} />;
tabType['remote comm'] = (props) => <InstalationEquipmentsRemoteComunications {...props} />;
tabType.neighbours = (props) => <InstalationEquipmentsNeighbours {...props} />;

export default tabType;
