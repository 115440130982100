export default {
  remoteState: {
    noContent: 'Nedostupné',
    title: 'Bezdrátový stav',
    doorState0: 'Neidentifikováno',
    doorState1: 'Otevřeno',
    doorState2: 'Zavřeno',
    doorState3: 'Otevírání',
    doorState4: 'Zavírání',
    doorState5: 'Zastavit otevírání',
    doorState6: 'Zastavit zavírání',
    doorState7: 'Čekání na automatické zavření',
    deviceType: 'Typ zařízení',
    connectionSignal: 'Připojovací signál',
    rssiValue: 'Hodnota RSSI',
    hwVersion: 'Verze hadrwaru',
    swVersion: 'Verze softwaru',
    doorStateTitle: 'Stav dveří',
    inputStateTitle: 'Stav vstupu',
    deviceInfoTitle: 'Informace o zařízení',
    relay1Title: 'Aktivační čas relé 1 v sec',
    relay2Title: 'Aktivační čas relé 2 v sec',
    relaysTitle: 'Relé',
    refreshStatus: 'Stav obnovení',
    activateRel1: 'Aktivovat relé 1',
    activateRel2: 'Aktivovat relé 2',
    doorState: 'Stav',
    activateRelError: 'Zařízení nereaguje',
    activateRelSuccess: 'Činnost úspěšně dokončena',
    connectionSignalUnknown: 'Mimo rozsah',
    connectionSignalExcellent: 'Vynikající',
    connectionSignalGood: 'Dobré',
    connectionSignalFair: 'OK',
    connectionSignalWeak: 'Zanedbatelné',
    connectionType: 'Typ spojení',
    deviceType0: 'eBase',
    deviceType1: 'eConnect',
    deviceType2: 'BtConnect',
    deviceType3: 'Sesame',
    deviceType4: 'Blink',
    deviceType5: 'eAccess',
    deviceType6: 'Vdp',
    deviceType7: 'HONOADOOR / EBASEDOOR',
    deviceType8: 'HONOARELAY',
    sensors: 'Snímač',
    open: 'Otevřít',
    battery: 'Akumulátor',
    deactivated: 'deaktivováno',
    activated: 'aktivován',
  },
};
