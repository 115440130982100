import { call, fork, takeLatest } from 'redux-saga/effects';
import neighboursActions from '../actions/neighbours';
import fetchEntitySaga from './utils/fetchEntitySaga';
import apiPathFactory from '../../../core/old_common/rooting/apiPathFactory';
import api from '../../../core/helpers/api';

import {
  NEIGHBOUR_DELETE,
  NEIGHBOUR_GET,
  NEIGHBOUR_POST,
  NEIGHBOUR_PUT,
} from '../reducers/neighbours';

export const deleteNeighbour = {
  base: (facilityId, equipmentId, neighbourId) => ({
    type: NEIGHBOUR_DELETE.BASE,
    facilityId,
    equipmentId,
    neighbourId,
  }),
  request: () => ({ type: NEIGHBOUR_DELETE.REQUEST }),
  success: (result, payload) => ({ type: NEIGHBOUR_DELETE.SUCCESS, result, payload }),
  failure: (error) => ({ type: NEIGHBOUR_DELETE.FAILURE, error }),
};

export const postNeighbour = {
  base: (facilityId, equipmentId, neighbour) => ({
    type: NEIGHBOUR_POST.BASE,
    facilityId,
    equipmentId,
    neighbour,
  }),
  request: () => ({ type: NEIGHBOUR_POST.REQUEST }),
  success: (result) => ({ type: NEIGHBOUR_POST.SUCCESS, result }),
  failure: (error) => ({ type: NEIGHBOUR_POST.FAILURE, error }),
};

export const putNeighbour = {
  base: (facilityId, equipmentId, neighbourId, neighbour) => ({
    type: NEIGHBOUR_PUT.BASE,
    facilityId,
    equipmentId,
    neighbourId,
    neighbour,
  }),
  request: () => ({ type: NEIGHBOUR_PUT.REQUEST }),
  success: (result) => ({ type: NEIGHBOUR_PUT.SUCCESS, result }),
  failure: (error) => ({ type: NEIGHBOUR_PUT.FAILURE, error }),
};

export const getNeighbour = {
  base: () => ({
    type: NEIGHBOUR_GET.BASE,
  }),
  request: () => ({ type: NEIGHBOUR_GET.REQUEST }),
  success: (result) => ({ type: NEIGHBOUR_GET.SUCCESS, result }),
  failure: (error) => ({ type: NEIGHBOUR_GET.FAILURE, error }),
};

export function* deleteNeighbourSaga({ facilityId, equipmentId, neighbourId }) {
  try {
    const url = apiPathFactory.neighbourDelete(facilityId, equipmentId, neighbourId);
    const response = yield call(() => fetchEntitySaga(deleteNeighbour, api.del, url, neighbourId));
    return response;
  } catch (error) {
    return error;
  }
}

export function* putNeighbourSaga({ facilityId, equipmentId, neighbourId, objectNeighbour }) {
  try {
    const url = apiPathFactory.neighbourPut(facilityId, equipmentId, neighbourId);
    const response = yield call(() =>
      fetchEntitySaga(postNeighbour, api.put, url, { data: objectNeighbour })
    );
    return response;
  } catch (error) {
    return error;
  }
}

export function* postNeighbourSaga(data) {
  try {
    const url = apiPathFactory.neighbourPost(data.facilityId, data.equipmentId);
    const response = yield call(() =>
      fetchEntitySaga(postNeighbour, api.post, url, { data: data.objectNeighbour })
    );
    return response;
  } catch (error) {
    return error;
  }
}

export function* getNeighbourSaga({ facilityId, equipmentId }) {
  try {
    const url = apiPathFactory.neighbourGet(facilityId, equipmentId);
    const response = yield call(() => fetchEntitySaga(getNeighbour, api.get, url));
    return response;
  } catch (error) {
    return error;
  }
}

function* watchDeleteNeighbour() {
  yield takeLatest(neighboursActions.DELETE_NEIGHBOUR, deleteNeighbourSaga);
}

function* watchPostNeighbour() {
  yield takeLatest(neighboursActions.POST_NEIGHBOUR, postNeighbourSaga);
}

function* watchPutNeighbour() {
  yield takeLatest(neighboursActions.PUT_NEIGHBOUR, putNeighbourSaga);
}

function* watchGetNeighbour() {
  yield takeLatest(neighboursActions.GET_NEIGHBOURS, getNeighbourSaga);
}

export default function* watchers() {
  yield fork(watchPostNeighbour);
  yield fork(watchPutNeighbour);
  yield fork(watchGetNeighbour);
  yield fork(watchDeleteNeighbour);
}
