import PathEntity from '../../core/old_common/rooting/apiPaths/PathEntity';

class Routes extends PathEntity {
  constructor() {
    super('countries');
  }

  getTimeZones = () => this.manufacturePath(`countries/timezones?DefaultList=true`);

  getTimeZonesByProduct = (code) => {
    let endpoint = this.manufacturePath(`countries/timezones/product`);
    endpoint = this.addParameterToEndpoint(endpoint, 'Product', code);
    return endpoint;
  };

  getCountries = () => this.manufacturePath(`countries`);

  getPaths = () => ({
    countriesGetTimeZones: this.getTimeZones,
    countriesGetCountries: this.getCountries,
    countriesGetTimeZonesByProduct: this.getTimeZonesByProduct,
  });
}

export default new Routes().getPaths();
