export default {
  compareMemory: {
    nodata: 'Aucune donnée disponible dans ce tableau',
    filter: 'Filtre',
    foundInBoth: 'Trouvé sur les deux sites',
    foundInBBDD: 'Trouvé dans la base de données',
    foundInMemory: 'Trouvé en mémoire',
    refreshMemory: 'Rafraîchir la mémoire',
    deleteBBDD: 'Supprimer de la base de données',
    addBBDD: 'Ajouter à la base de données',
    confirmDelete: "Voulez-vous retirer l'émetteur?",
    reserved: 'Réservé',
  },
};
