export default {
  remoteState: {
    noContent: 'Non disponibile',
    title: 'Stato remoto',
    doorState0: 'Non definito',
    doorState1: 'Ha aperto',
    doorState2: 'Chiusa',
    doorState3: 'Apertura',
    doorState4: 'Chiusura',
    doorState5: 'Smetti di aprire',
    doorState6: 'Smetti di chiudere',
    doorState7: 'In attesa di chiusura automatica',
    deviceType: 'Tipo di dispositivo',
    connectionSignal: 'Segnale di connessione',
    rssiValue: 'Valore RSSI',
    hwVersion: 'Versione hardware',
    swVersion: 'Versione software',
    doorStateTitle: 'Stato della porta',
    inputStateTitle: 'Stato di input',
    deviceInfoTitle: 'Informazioni sul dispositivo',
    relayTitle: 'Tempo di attivazione in sec',
    relaysTitle: 'Relès',
    refreshStatus: 'Aggiorna stato',
    activateRel1: 'Attiva il relè 1',
    activateRel2: 'Attiva il relè 2',
    doorState: 'Stato',
    activRelError: 'Il dispositivo non risponde',
    activRelSuccess: 'Azione completata correttamente',
    connectionSignalUnknown: 'Fuori dal limite',
    connectionSignalExcellent: 'Eccellente',
    connectionSignalGood: 'Buono',
    connectionSignalFair: 'OK',
    connectionSignalWeak: 'Marginale',
    connectionType: 'Tipo di connessione',
    deviceType0: 'eBase',
    deviceType1: 'eConnect',
    deviceType2: 'BtConnect',
    deviceType3: 'Sesame',
    deviceType4: 'Blink',
    deviceType5: 'eAccess',
    deviceType6: 'Vdp',
    deviceType7: 'HONOADOOR / EBASEDOOR',
    deviceType8: 'HONOARELAY',
    sensors: 'Sensori',
    open: 'Aperto',
    battery: 'Batteria',
    deactivated: 'Disattivato',
    activated: 'Attivato',
  },
};
