export default {
  errors: {
    title: {
      honoa: 'HONOA Mutassa az ajtó állapotát:',
    },
    402: 'Fizetés szükséges',
    1000: 'Sikertelen bejelentkezés',
    name: 'Ez a név nem elérhető',
    generic: 'Már használt egyedi azonosító',
    selectTypeFirst: 'Először válasszon ki egy típust',
    unexpectedError: 'Váratlan hiba',
    uniqueIdentity: 'Ez a tartalom már létezik',
    10: 'A CloudAssistant API konfigurációs szakaszának elindítása',
    11: 'Konfiguráció kész. Az API készen áll a bejövő hívásokra',
    12: 'API verzió konfiguráció beállítása',
    13: 'Általános API beállítások',
    14: 'SMTP beállítások',
    15: 'Cookie irányelvek konfigurálása',
    16: 'Kompatibilitási környezet beállítása:',
    17: 'A db kontextus beállítása',
    18: 'Hitelesítési szolgáltatások',
    19: 'Swagger dokumentáció hozzáadása',
    100: 'Bejelentkezett felhasználó',
    101: 'Kijelentkezett felhasználó',
    102: 'Jelszó sikeresen megváltozott',
    103: 'Jelszó elfelejtése befejeződött',
    104: 'Jelszó visszaállítása befejeződött',
    105: 'E-mail küldése a következő címre:',
    106: 'Érvénytelen felhasználó',
    107: 'Felhasználó nem frissített',
    108: 'A meghívó lejárt',
    109: 'Nem törölt meghívó',
    110: 'A felhasználó nem erősítette meg és nem frissítette az IS4-ben',
    111: 'A felhasználó nem regisztrált sikeresen',
    112: 'Felhasználó vagy ügyfél azonosító eltérés',
    113: 'Ügyfél azonosító nem létezik',
    114: 'Nem küldték el a meghívót',
    115: 'A meghívót törölték',
    116: 'Rollback meghívó elkészült',
    117: 'Nem megfelelően létrehozott ügyfélattribútumok',
    118: 'Nem jogosult az elemek megtekintéséhez',
    119: 'Nem jogosult az elemek megjelenítéséhez vagy frissítéséhez',
    120: 'Nem jogosult az elemek létrehozásához',
    121: 'Nem jogosult az elemek törléséhez',
    122: 'Az identitásban nem törölt felhasználó',
    123: 'Felhasználó nem található',
    124: 'Konfliktus a felhasználó törlésekor az identitásban',
    125: 'Párhuzamossági hiba',
    126: 'Alapértelmezett hiba',
    127: 'E-mail már létezik',
    128: 'A szerepkör neve már létezik',
    129: 'A felhasználónév már létezik',
    130: 'Érvénytelen e-mail',
    131: 'Érvénytelen szerepkör név',
    132: 'Érvénytelen token',
    133: 'Érvénytelen felhasználónév',
    134: 'A bejelentkezés már társítva lett',
    135: 'A jelszóhoz kisbetűs írásmód szükséges',
    136: 'A jelszó nem alfanumerikus értékeket igényel',
    137: 'A jelszó egyedi karaktereket igényel',
    138: 'A jelszó nagybetűket igényel',
    139: 'Túl rövid jelszó',
    140: 'A helyreállítási kód beváltása sikertelen',
    141: 'A felhasználónak már van jelszava',
    142: 'A felhasználónak már van szerepköre',
    143: 'Felhasználó szerepkör nélkül',
    144: 'A felhasználói blokkolás nincs engedélyezve',
    145: 'Hitelesítés sikertelen, érvénytelen vagy rejtett ügyfél azonosító',
    146: 'Érvénytelen kérés',
    147: 'Az e-mail cím vagy a jelszó nem megfelelő',
    148: 'Érvénytelen hatókör',
    149: 'Ez az ügyfél nem jogosult a kért kérelem típusának használatára',
    150: 'Nem támogatott támogatás típus',
    151: 'Az identitásban nem törölt felhasználó',
    152: 'Konfliktus a felhasználó törlésekor az identitásban',
    153: 'Nem frissített előfizetések',
    154: 'Az előfizetések frissültek',
    155: 'Hiba az előfizetések megszerzésében',
    156: 'Az ügyfél rendelkezik telepítői kóddal univerzális csoportokhoz és létesítményekhez',
    157: 'Ismeretlen hiba',
    158: 'Nincs elég jogosultság',
    159: 'A felhasználói e-mail már létezik',
    160: 'Nincs elég előfizetés',
    161: 'A felhasználói szerepek nem érvényesek',
    162: 'A felhasználói szerepkör nem frissült',
    163: 'Nem regisztrált vagy előfizetés nélküli felhasználó',
    164: 'Felhasználói szerepkörök vagy az ügyfél nem érvényes',
    165: 'Admin nem frissíthető',
    166: 'A telepítő kódja nem frissült',
    167: 'Nem frissített termékcsaládok',
    168: 'Nem frissített termékek',
    169: 'Nem találtunk elemet ezzel az azonosítóval',
    170: 'Token frissítése',
    171: 'Nincs elég előfizetés',
    172: 'Eszköz nem található',
    200: 'Az összes felhasználó lekérdezése',
    300: 'Rossz érvek vagy az elemek számának túllépése egyetlen kérésnél',
    301: 'A frissítés sikeresen befejeződött.',
    302: 'Hitelesítési hiba',
    400: 'A bérlő sikeresen mentve',
    401: 'Hiányzik néhány bérlői adat! Sikertelen mentés',
    404: 'Eszköz offline vagy kapcsolati hiba.',
    9000: 'A bejelentkezés sikertelen',
    9001: 'A jelszó visszaállítása sikertelen',
    9002: 'Jelszó elfelejtése sikertelen',
    9003: 'A jelszó módosítása sikertelen',
    9004: 'A jelszó nem egyezik',
    9005: 'A jelszóhoz legalább egy számjegy szükséges',
    9006: 'Ismeretlen felhasználó',
    90000: 'Loopback tesztet kértek:',
    90001: 'Kivételt kérelmeztek:',
    90002: 'Visszatérési státuszkódot kértek:',
    90100: 'SQL kivételek feloldása',
    90101: 'A létrehozás nem sikerült.',
    90102: 'A megadott név már létezik, meg kell változtatnia:',
    90103: 'Nem található.',
    90104: 'A frissítés sikertelen',
    90105: 'Sikertelen törlés',
    90106: 'Ez a tartalom már létezik',
    90107: 'A tartalom nem lehet nulla.',
    90108: 'Az importálás sikertelen volt.',
    90109: 'Az exportálás nem sikerült.',
    90110:
      'Nem lehet frissíteni a telepítői kódot azokban a létesítményekben, amelyekben a másolt távirányítók ki vannak jelölve',
    90111: 'A létesítmény azonosítója vagy URL címe nem érvényes',
    90112: 'Létesítmény egységcsoport nem jött létre',
    90113: 'A létesítménycsoportnak nevet kell kapnia',
    90114: 'A létesítmény egységcsoport neve már létezik ehhez a létesítményhez',
    90115: 'A létesítmény egységcsoport nem frissült',
    90116: 'Nem törölt létesítmény egységcsoport',
    90117: 'Az entitás kapcsolattartója nem lehet nulla',
    90118: 'Létesítményegység nem frissült',
    90119: 'A létesítményegység leírása vagy kódja már létezik ehhez a csoporthoz.',
    90120: 'A 0 pozíciójú kapcsolat törlése nem lehetséges',
    90121: 'Nem törölt kontakt',
    90122: 'Nem létrehozott kontakt',
    90123: 'Az entitásnak már 5 kapcsolattartója van',
    90124: 'Az entitás kapcsolattartója nem frissült',
    90125: 'A csoport nem létezik ehhez a létesítményhez',
    90126: 'Érvénytelen létesítmény',
    90127: 'A létesítményegység kapcsolattartója nem érvényes',
    90128:
      'A telepítő kódjának az univerzális csoport minden létesítményében azonosnak kell lennie.',
    90129:
      'A telepítő kódjának azonosnak kell lennie a létesítmény és az univerzális csoport esetében.',
    91000: 'VDP mac vagy azonosító nem található',
    91001: 'Érvénytelen mac',
    91002: 'E-mail kiterjesztés nem található',
    91003: 'Hiba a vdp név frissítésében',
    91004: 'Hiba a vdp kontaktok létrehozásában',
    91005: 'Hiba a névjegyek létrehozásában',
    91006: 'Hiba a névjegyek törlésében',
    91007: 'Hiba a kapcsolattartó nevének frissítésében',
    91008: 'Az ügyfél neve már létezik',
    91009: 'Lakás vagy ajtó kód vagy nem érvényes',
    91010: 'Nem bevezetett kód',
    91011: 'A sérült csatornák száma',
    91012: 'Az utolsó relékapcsolat nem törölhető',
    91013: 'Nem lehet letiltani a relét, mert vannak hozzárendelt csoportok',
    91014: 'Érvénytelen kódtartomány',
    91015: 'A kódoknak azonos típusúaknak kell lenniük',
    91016: 'Nincs elég pozíció a csoportban',
    91017: 'A kód már létezik a létesítményben',
    91018: 'A kód már létezik az univerzális távirányítóban',
    91019: 'A kód egy törölt univerzális kód helyettesítése',
    91020: 'Ez a berendezés nem támogatja a menetrendeket.',
    91021: 'Nincs mit másolni',
    91022: 'Nincs mit mozgatni',
    91023: 'Termék érvénytelen',
    91024: 'Ütemterv nem található',
    91025: 'Nem törölhető olyan ütemterv, amelyhez létesítmény van hozzárendelve',
    91026: 'A kért csoport nem univerzális',
    91027: 'A berendezésnek van néhány érvénytelen paramétere',
    91028:
      'Nem lehet törölni egy olyan univerzális csoportot, amelyhez létesítmények vannak hozzárendelve',
    91029: 'Sorozatszám vagy egyedi kulcs nincs regisztrálva',
    91030: 'Sorozatszám vagy egyedi kulcs nem lehet nulla',
    91031: 'Helytelen ügyfélkód',
    91032: 'Helytelen telepítői kód',
    91033: 'Helytelen jelszó',
    91034: 'A fájl sérült',
    91065: 'már hozzárendelt link',
    91066: 'Hiba történt a kapcsolat deaktiválásakor',
    91067: 'Helytelen aktiválási kulcs',
    91068: 'Sikertelen API kérelem',
    91069: 'A termék nincs engedélyezve',
    91070: 'A termék nem található',
    91071: 'Nincs relé kiválasztva. Használja a konfiguráció gombot',
    91072: 'A relé neve már létezik',
    91073: 'Elérte a telepítések maximális számát.',
    91074: 'Ezt az aktiváló kulcsot egy másik felhasználó használja.',
    91100: 'Kikapcsolt eszköz',
    91101: 'Kezeletlen kivétel eszköz ikrek olvasása',
    91102: 'Memória olvasási kivétel',
    91103: 'Memória írási kivétel',
    91104: 'Írási memória eltérés',
    91105: 'A berendezés rendelkezik installációs kóddal',
    91106: 'A berendezés nem rendelkezik installációs kóddal',
    91302: 'SIM információ nem áll rendelkezésre',
    91303: 'A SIM szerződés nem érhető el',
    91307: 'Nem kaphat sorozatszámot',
    91308: 'Aktiválási kulcs nulla vagy üres',
    91309: 'Aktiválási kulcs nulla, üres vagy érvénytelen',
    91400: 'Hiba törlési riasztás',
    91201: 'A felhasználó már létezik. Minden adat öröklődik',
    91452: 'A készülék nem támogatja az ünnep napokat tartalmazó ütemezéseket',
    91527: 'Nem lehet törölni a hozzárendelt univerzális csoporttal rendelkező létesítményt.',
    91528: 'Nem lehet törölni a hozzárendelt csoporthoz tartozó berendezést.',
    91529: 'Nem lehet törölni a másolt távirányítókkal rendelkező csoportotokat.',
    universalGroupsAssigned: 'UG hozzárendelt:',
    groupsAssigned: 'Hozzárendelt csoportok: ',
    91203: 'A felhasználó már létezik ebben a létesítményben',
    operationNotCompleted: 'A műveletet nem lehetett befejezni',
    redirectWarning: 'Átirányítottuk Önt a főoldalra',
    noContent: 'Ehhez a listához nem áll rendelkezésre tartalom',
    communicationError: 'Kommunikációs hiba próbálja meg később újra',
    91530:
      'Ezt az eszközt nem lehet kiosztani, mert a fennmaradó eszköz nem támogatja a másolt távirányítókat.',
    91531: 'Az eszköz nem rendelhető át, mert univerzális csoportokat használ.',
    91532: 'A létesítmény nem kompatibilis berendezéseket tartalmaz',
    91534: 'Maximális Honoa berendezések elérése ebben a létesítményben ',
    91310: 'Ez a sorozatszám nincs felhasználóhoz rendelve',
    91311: 'Hiba a prod szolgáltatással való kommunikációban',
    91312: 'Hiba az engedély generálásában',
    91400: 'Hiba törlési riasztás',
    91450: 'A következő ütemezéseknek nincs érvényes tartománya: ',
    91451: 'Legalább egy ünnepnapot kell kiválasztani',
    91452: 'A használatban lévő eszköz nem támogatja a nyaralásokat tartalmazó naptárakat',
    91203: 'A felhasználó már létezik ebben a létesítményben',
    91537: 'Nem lehet törölni egy Honoa felhasználókkal rendelkező csoportot',
    91453: 'Az ütemterv neve már létezik',
    91536: 'A telepítés neve már létezik',
    91800: 'Nem támogatott telefonszám formátum',
    91801: 'Nem támogatott url web formátum',
    needsMinOnRelay: 'Legalább egy kijelölt relének kell lennie',
    91802: 'Ez a készülék nem támogatja a másolt távirányítókat',
    91803: 'Ez a csoport nem támogatja a fobokat',
    deletedUser: 'Felhasználó törölve',
    91804: 'Az ehhez a berendezéshez rendelt egy vagy több órarend neve túlságosan hosszú.',
    91538: 'Hiba a PDF letöltése közben',
    timeout: 'Tempo di attesa superato.',
    91853: 'Az időzónát nem hagyhatja üresen.',
    91539: 'A létesítményen jelenleg üzemben levő berendezést nem lehet letiltani.',
    zeroCode: 'Figyelem! A használatban lévő installációs kód 0',
    91540:
      'Nem lehet a berendezést másnak átadni, mivel az adott csoportnak már vannak HONOA felhasználói.',
    91854: 'A csoport nem támogatja a HONOA felhasználókat.',
    92030: 'Az előfizetése nem tartalmazza ezt a funkciót.',
    92060: 'A kezdő időpont nem lehet későbbi, mint a befejezési időpont',
    92061: 'A kezdő időpont a mai dátumnál későbbi kell, hogy legyen',
    92031: 'Funkció nincs engedélyezve végálláskapcsoló nélkül (érzékelők/bemenetek)',
    91075: 'Nincs elég rendelkezésre álló foglalás',
    304: 'A szó szerinti nem érvényes (üres, vezető vagy záró szóközök, ...)',
  },
};
