import styled from 'styled-components';
import { increaseBrightness } from '../../core/helpers/colorManager';

export default styled.label`
  color: ${increaseBrightness('#000', 20)};
  text-decoration: ${(props) => (props.withoutUnderline ? 'none' : 'underline')};
  &:hover {
    color: ${(props) =>
      !props.withoutUnderline
        ? props.tenants
          ? increaseBrightness(props.tenants.color1, 20)
          : increaseBrightness('#222', 20)
        : ''};
  }
  &:focus {
    color: ${(props) =>
      !props.withoutUnderline
        ? props.tenants
          ? increaseBrightness(props.tenants.color1, 40)
          : increaseBrightness('#000', 40)
        : ''};
  }
`;
