import styled from 'styled-components';

export default styled.select`
  border-bottom: 1px solid ${(props) => (props.error ? 'red' : '#b3b3b3')};

  &:hover,
  &:focus {
    border-bottom: 1px solid ${(props) => (props.tenants ? props.tenants.color1 : '')};
  }

  &:disabled {
    border-bottom: 1px solid #f4f4f4
  }
;
}
`;
