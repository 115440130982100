import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { getUniversalGroup } from '../../modules/old_to_refact/actions/universalGroups';
import UniversalGroupGrow from '../../old_components/UniversalGroupGrow';
import IndividualUniversalGroupForm from '../../old_components/IndividualUniversalGroupForm';
import { CreationPath_UG, RootPath_UG } from '../../core/old_common/constats/genericListPaths';
import GenericIndividualGrow from '../../old_components/GenericIndividualGrow/GenericIndividualGrow';
import { getDecodedUrl } from '../../core/old_common/utils/getUrlParams';
import UniversalGroupFacilitiesList from '../../old_components/UniversalGroupFacilitiesList';
import IndividualUniversalGroupUsersGrid from '../../old_components/IndividualUniversalGroupUsersGrid/IndividualUniversalGroupUsersGrid';
import { resetSignalRConnectionOnReducer } from '../../core/old_common/utils/signalRService';
import {
  getCookie_selectedUniversalGroupCustomerId,
  saveCookies,
} from '../../core/old_common/utils/cookiesManager';

export class IndividualUniversalGroup extends Component {
  componentDidUpdate() {
    const selectedItem = this.getSelectedItem();
    if (selectedItem && getCookie_selectedUniversalGroupCustomerId() !== selectedItem.customerId) {
      saveCookies({ selectedUniversalGroupCustomerId: selectedItem.customerId });
    }
  }

  componentWillUnmount() {
    resetSignalRConnectionOnReducer();
  }

  getSelectedItem = () => {
    const { match, universalGroup, universalGroups } = this.props;
    if (universalGroup) {
      return universalGroup;
    }
    if (universalGroups) {
      return universalGroups.find(
        (universalGroup) => universalGroup.name === getDecodedUrl(match.params.id)
      );
    }
    return undefined;
  };

  render() {
    const { t, getUniversalGroup, match, active, history } = this.props;
    const selectedUG = this.getSelectedItem();
    return (
      <GenericIndividualGrow
        Grow={UniversalGroupGrow}
        baseURI={RootPath_UG}
        creationURI={CreationPath_UG}
        selectedItem={selectedUG}
        getItem={getUniversalGroup}
        tabsData={[
          {
            title: t('universalGroups.informationTab'),
            bodyComponent: IndividualUniversalGroupForm,
            onClick: () =>
              history.push(
                `${RootPath_UG.replace('%09', '%2509')}/${match.params.id.replace('%09', '%2509')}`
              ),
            active: !active,
          },
          {
            title: t('universalGroups.instalationsTab'),
            bodyComponent: UniversalGroupFacilitiesList,
            active: active === 'Equipments',
            onClick: () =>
              history.push(
                `${RootPath_UG.replace('%09', '%2509')}/${match.params.id.replace(
                  '%09',
                  '%2509'
                )}/Equipments`
              ),
            componentState: {
              baseURI: `${RootPath_UG.replace('%09', '%2509')}/${match.params.id.replace(
                '%09',
                '%2509'
              )}/Equipments`,
              universalGroupId: selectedUG ? selectedUG.id : '',
            },
          },
          {
            title: t('universalGroups.fobsTab'),
            bodyComponent: IndividualUniversalGroupUsersGrid,
            onClick: () =>
              history.push(
                `${RootPath_UG.replace('%09', '%2509')}/${match.params.id.replace(
                  '%09',
                  '%2509'
                )}/fobs`
              ),
            componentState: {
              baseURI: `${RootPath_UG.replace('%09', '%2509')}/${match.params.id.replace(
                '%09',
                '%2509'
              )}/fobs`,
              selectedUG,
            },
            active: active === 'fobs',
          },
        ]}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  universalGroups: state.universalGroups.universalGroups,
  universalGroup: state.universalGroups.universalGroup,
  needCallUG: state.universalGroups.needCallUG,
});
const IndividualUniversalGroupWithTranslation = withTranslation()(IndividualUniversalGroup);
const IndividualUniversalGroupWithRouter = withRouter(IndividualUniversalGroupWithTranslation);

export default connect(mapStateToProps, {
  getUniversalGroup,
})(IndividualUniversalGroupWithRouter);
