import createFetchTypes from '../../../core/typeActions/types';

const GET_GROUPS = 'GET_GROUPS';
export const GET_LIST_GROUP_FOB_HISTORY = createFetchTypes('GET_LIST_GROUP_FOB_HISTORY');
export const RESET_GROUP_FOB_HISTORY = 'RESET_GROUP_FOB_HISTORY';
export default {
  GET_GROUPS,
};
export const getGroups = (fieldId, searchText) => ({ type: GET_GROUPS, fieldId, searchText });

export const getGroupsFobHistory = (searchText) => ({
  type: GET_LIST_GROUP_FOB_HISTORY.BASE,
  searchText,
});

export const resetGroupFobHistory = () => ({
  type: RESET_GROUP_FOB_HISTORY,
});
