import React, { Component } from 'react';
import { connect } from 'react-redux';
import { IconSpan } from '../../IconSpan';

export class ContactNeighbour extends Component {
  render() {
    const { contact, tenants, handleEdit, handleDelete, DragHandle, id } = this.props;
    const { facilityId, equipmentId } = this.props;
    const { name, email, phone } = contact;
    return (
      <div
        onClick={() => {
          handleEdit(contact, id);
        }}
        className='contact-neighbour'
      >
        <span className='drag-contact-button'>
          <DragHandle />
        </span>
        <span>{name}</span>
        <span>{email}</span>
        <span>{phone}</span>
        <span className='delete-contact-button'>
          <IconSpan
            tenants={tenants}
            id='edit'
            //   toolTip={selectedAction.title}
            defaultColor='#a4aebb'
            iconClassName='generic-grow-action-button fas fa-trash'
            cursor='pointer'
            onClick={(e) => {
              e?.stopPropagation();
              handleDelete(facilityId, equipmentId, id);
            }}
          />
        </span>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  tenants: state.tenants,
});

export default connect(mapStateToProps, {})(ContactNeighbour);
