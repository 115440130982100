export default {
  descriptionParams: {
    ACCESSCONTROL:
      "Indica se l'apparecchiatura ha un ingresso per i dispositivi di controllo accessi",
    ACTIVEEVENTSTOSD: 'Specifica gli eventi attivi per il tuo dispositivo',
    APNCONFIG: "Configura l'APN del dispositivo",
    BADGE_MAITRE1: "Codice dell'elemento di prossimità utilizzato come chiave principale",
    BADGE_MAITRE10: "Codice dell'elemento di prossimità utilizzato come chiave principale",
    BADGE_MAITRE2: "Codice dell'elemento di prossimità utilizzato come chiave principale",
    BADGE_MAITRE3: "Codice dell'elemento di prossimità utilizzato come chiave principale",
    BADGE_MAITRE4: "Codice dell'elemento di prossimità utilizzato come chiave principale",
    BADGE_MAITRE5: "Codice dell'elemento di prossimità utilizzato come chiave principale",
    BADGE_MAITRE6: "Codice dell'elemento di prossimità utilizzato come chiave principale",
    BADGE_MAITRE7: "Codice dell'elemento di prossimità utilizzato come chiave principale",
    BADGE_MAITRE8: "Codice dell'elemento di prossimità utilizzato come chiave principale",
    BADGE_MAITRE9: "Codice dell'elemento di prossimità utilizzato come chiave principale",
    CONFIGRELAYSNEW1:
      'Indica i relè che verranno attivati in base al canale inviato dal dispositivo',
    CONFIGRELAYSNEW2:
      'Indica i relè che verranno attivati in base al canale inviato dal dispositivo',
    CONFIGRELAYSNEW3:
      'Indica i relè che verranno attivati in base al canale inviato dal dispositivo',
    CONFIGRELAYSNEW4:
      'Indica i relè che verranno attivati in base al canale inviato dal dispositivo',
    CONFIGRELAYSPIN1:
      'Indica i relè che verranno attivati in base al canale inviato dal dispositivo',
    CONFIGRELAYSPIN2:
      'Indica i relè che verranno attivati in base al canale inviato dal dispositivo',
    CONFIGRELAYSPIN3:
      'Indica i relè che verranno attivati in base al canale inviato dal dispositivo',
    CONFIGRELAYSPIN4:
      'Indica i relè che verranno attivati in base al canale inviato dal dispositivo',
    DISABLEMANUALPROG: 'Ti permette di registrare manualmente i dispositivi',
    DISABLERADIOPROG:
      'Impedisce la registrazione dei trasmettitori utilizzando la modalità di programmazione radio (modalità standard)',
    FREEACTIVE: 'Attiva / disattiva le caratteristiche del gruppo di sistemi FREE',
    FREEGROUP0LOCKED: 'Blocca / sblocca il gruppo 0 del sistema FREE',
    FREEGROUPNUM: 'Imposta il numero di gruppo del sistema FREE',
    FREEGROUPRFPROGALLOWED:
      'Abilita / disabilita la modalità di programmazione radio per il gruppo di sistemi FREE',
    IMPBIRELE1: 'Cambia il funzionamento del relè 1 da impulso a ON / OFF',
    INSTAL: 'Mostra se lavori con il codice di installazione',
    MINIDDEVICE: "L'apparecchiatura accetterà dispositivi come quelli indicati o superiori",
    PASSWORD: 'Password di 4 cifre per accedere al menu centrale',
    PIN: 'Codice numerico a 6 cifre corrispondente al codice PIN del ricevitore',
    PINCADUCAT: 'Indica se il ricevitore accetta dispositivi con il codice PIN scaduto',
    POWER: "Mostra il tipo di alimentazione dell'apparecchiatura",
    RELAYPRICE1: 'Valore da sottrarre dal credito di un dispositivo prepagato 1',
    RELAYPRICE2: 'Valore da sottrarre dal credito di un dispositivo prepagato 2',
    RELAYPRICE3: 'Valore da sottrarre dal credito di un dispositivo prepagato 3',
    RELAYPRICE4: 'Valore da sottrarre dal credito di un dispositivo prepagato 4',
    RELAYS: 'Numero di relè nel ricevitore',
    RELE1IMP: 'Configura il relè come impulso, ON / OFF o panico',
    RELE1TEMP:
      'Imposta il tempo in cui il relè rimarrà attivo in modalità impulso (da 1 a 30 secondi) o panico (da 1 a 15 minuti)',
    RELE2IMP: 'Imposta il relè come impulso, ON / OFF o panico',
    RELE2TEMP:
      'Imposta il tempo in cui il relè rimarrà attivo in modalità impulso (da 1 a 30 secondi) o panico (da 1 a 15 minuti)',
    RELE3IMP: 'Imposta il relè come impulso, ON / OFF o panico',
    RELE3TEMP:
      'Imposta il tempo in cui il relè rimarrà attivo in modalità impulso (da 1 a 30 secondi) o panico (da 1 a 15 minuti)',
    RELE4IMP: 'Imposta il relè come impulso, ON / OFF o panico',
    RELE4TEMP:
      'Imposta il tempo in cui il relè rimarrà attivo in modalità impulso (da 1 a 30 secondi) o panico (da 1 a 15 minuti)',
    SERIALNUMBER: 'Il numero di serie è obbligatorio per le azioni remote',
    SITECODE:
      'Codice sito / codice impianto / codice installazione da inviare nel protocollo Wiegand 26 con codice sito',
    TEVENT: 'Mostra se la centrale può avere una scheda eventi',
    TIMEZONEID: "Imposta il fuso orario in cui è installata l'apparecchiatura",
    TMEM: "Indica se l'apparecchiatura funziona con una scheda di memoria",
    UNIQUEKEY: 'La chiave singola è per le azioni remote',
    W26FORMAT: 'Imposta il formato di trasmissione del protocollo Wiegand 26',
    SETNEWACCESSPW: 'Cambia la password',
    SERIALNUMBERLINK: 'Il numero di serie è obbligatorio per le azioni remote',
    CONNECTIVITY_EXTENSION: 'Scheda di collegamento installata',
    ACTIVATIONKEY: 'Chiave di attivazione',
    EMMITTERNOTUSEDEVENTTIME: "Non utilizzare l'ora dell'evento del trasmettitore",
    DOOROPENEDEVENTTIME: 'Evento di apertura della porta',
    SCHEDULESDATA: 'Pianifica i dati',
    A5KPROXY: 'Accedi al proxy 5k',
    WIEGANDCONFIG: 'Personalizza la configurazione Wiegand da utilizzare',
  },
};
