import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
  listFacilityRecycle,
  restoreFacilityRecycle,
} from '../../modules/old_to_refact/actions/instalations';
import GenericList from '../../old_components/GenericList';
import InstallationRecycleGrow from '../../old_components/InstallationRecycleGrow';
import {
  CreationPath_Instalations,
  RootPath_Instalations,
} from '../../core/old_common/constats/genericListPaths';
import { RECYCLE_LIST } from '../../modules/state/pagination/constants';

const Recycle = () => {
  const dispatch = useDispatch();
  const recycleList = useSelector((state) => state.instalations.recycleList);
  const totalRecycleCount = useSelector((state) => state.instalations.totalRecycleCount);
  const getRecycleListAPI = useCallback(() => dispatch(listFacilityRecycle()), [dispatch]);
  const setRestoreAPI = useCallback(
    (facilityId) => dispatch(restoreFacilityRecycle(facilityId)),
    [dispatch]
  );

  useEffect(() => {
    getRecycleListAPI();
  }, []);

  const handlePageChange = () => {
    getRecycleListAPI();
  };

  return (
    <>
      {recycleList?.length ? (
        <GenericList
          dataList={recycleList}
          CustomGrow={(rest) => <InstallationRecycleGrow handleRestore={setRestoreAPI} {...rest} />}
          handlePageChange={handlePageChange}
          baseURI={RootPath_Instalations}
          creationURI={CreationPath_Instalations}
          listType={RECYCLE_LIST}
          getList={getRecycleListAPI}
          counter={totalRecycleCount}
          groupByProp='customerName'
        />
      ) : (
        <></>
      )}
    </>
  );
};

const RecyclePage = withTranslation()(Recycle);

export default RecyclePage;
