import i18next from 'i18next';
import moment from 'moment';

export const actionString = {
  1: 'instalations.saveMemory',
  2: 'equipments.formatMemory',
  3: 'instalations.creation',
  4: 'instalations.deletion',
  5: 'instalations.moved',
  6: 'instalations.locked',
  7: 'instalations.unlocked',
  11: 'instalations.substitution',
  13: 'event.loadEvents',
};

export const typeString = {
  1: 'instalations.equipment',
  2: 'title.group',
  3: 'form.code',
  4: 'title.user',
};

export const mapperNameLog = (name) => {
  const codeSplit = name.split('->');
  return name.includes('reserved') ? `${i18next.t('form.reserved')} -> ${codeSplit[1]}` : name;
};

export const fileExcelName = ({ facilityName, log }) =>
  `${moment().format('YYYY-MM-DD')}_${facilityName}_${log}`;
