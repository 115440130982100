export default {
  relaysConfiguration: {
    tabRelays: 'Relè',
    tabSchedules: 'Orari',
    tabInfo: 'Információk',
    time: 'Tempo di attivazione relè {{count}}',
    delay: 'Tempo di ritardo relè {{count}}',
    infoTime: 'Il valore deve essere compreso tra 1 e 60 secondi',
    infoDelay: 'Il valore deve essere compreso tra 0 e 10 secondi',
    infoCustomOpenDoorTime: 'Il valore deve essere compreso tra 1 e 86400 secondi',
    relayNotassigned: 'Relè non assegnato',
    goBack: 'Indietro',
    type: 'Tipo di relè',
    keepDoorOpenSchedule: 'Orario di porta aperta',
    customOpenDoorTimeSchedule: 'Orario di tempo personalizzato per porta aperta',
    customOpenDoorTime: 'Tempo di porta aperta personalizzata',
    Relay: 'Relè',
  },
};
