import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@jcm-technologies/uikit/dist/atoms/Button';
import { useTranslation } from 'react-i18next';
import { Flex } from '@jcm-technologies/uikit/dist/atoms/Layout';
import { useEffect } from 'react';
import CustomizationFamiliesItem from './FamilyItem';
import {
  getFamilyProducts,
  postFamily,
  setFamiliesValues,
} from '../../../../modules/productFamilies/action';
import { getNewArray } from '../../../../core/helpers/arrayService';

const CustomizationFamilies = () => {
  const { families } = useSelector((state) => state.families);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const onModifyFamilyState = (newItem) => dispatch(setFamiliesValues(newItem));
  const onCheckAllProducts = (value, newItemFamilyModified, family) => {
    let newProductsItemsModified;
    family.products?.forEach((product, productIndex) => {
      newProductsItemsModified = getNewArray(family.products, productIndex, 'isEnabled', value);
      newProductsItemsModified = getNewArray(
        newProductsItemsModified,
        productIndex,
        'isModified',
        true
      );
    });
  };
  const onCheckFamilyItem = (name, value, family, familyIndex) => {
    let newFamilyItemModified;
    newFamilyItemModified = getNewArray(families, familyIndex, 'isModified', true);
    newFamilyItemModified = getNewArray(newFamilyItemModified, familyIndex, 'isEnabled', value);
    onCheckAllProducts(value, newFamilyItemModified, family);
    onModifyFamilyState(newFamilyItemModified);
  };
  const onCheckProductItem = (name, value, family, familyIndex, productIndex) => {
    let newItemFamilyModified;
    let newItemProductModified;
    newItemProductModified = getNewArray(family.products, productIndex, 'isEnabled', value);
    newItemProductModified = getNewArray(newItemProductModified, productIndex, 'isModified', true);
    newItemFamilyModified = getNewArray(families, familyIndex, 'isModified', true);
    newItemFamilyModified = getNewArray(
      newItemFamilyModified,
      familyIndex,
      'isEnabled',
      newItemProductModified.some((item) => item.isEnabled)
    );
    onModifyFamilyState(newItemFamilyModified);
  };
  const onChangeNameFamily = (name, value, family, familyIndex) => {
    let newFamilyItemModified;
    if (value !== family.name) {
      if (value) {
        newFamilyItemModified = getNewArray(families, familyIndex, 'isModified', true);
        newFamilyItemModified = getNewArray(newFamilyItemModified, familyIndex, 'name', value);
        newFamilyItemModified = getNewArray(newFamilyItemModified, familyIndex, 'error', false);
      } else {
        newFamilyItemModified = getNewArray(families, familyIndex, 'error', true);
        newFamilyItemModified = getNewArray(newFamilyItemModified, familyIndex, 'isModified', true);
      }
      onModifyFamilyState(newFamilyItemModified);
    }
  };
  const onChangeNameProduct = (name, value, family, product, productIndex, familyIndex) => {
    let newItemProductModified;
    let newItemFamilyModified;
    if (value !== product.name) {
      newItemProductModified = getNewArray(family.products, productIndex, 'isModified', true);
      if (value) {
        newItemProductModified = getNewArray(newItemProductModified, productIndex, 'name', value);
        newItemProductModified = getNewArray(newItemProductModified, productIndex, 'error', false);
      } else {
        newItemProductModified = getNewArray(family.products, productIndex, 'error', true);
      }
      newItemFamilyModified = getNewArray(
        families,
        familyIndex,
        'products',
        newItemProductModified
      );
      onModifyFamilyState(newItemFamilyModified);
    }
  };
  const handleSubmitDisabled = () => {
    const hasFamilyModified = !!families.find((family) => family.isModified);
    const hasFamilyError = families.some((family) => family.error);
    let hasProductError = false;
    families.forEach((family) => {
      if (family.products) {
        hasProductError = family.products.some((product) => product.error);
      }
    });
    return !hasFamilyModified || hasFamilyError || hasProductError;
  };

  const handleSubmit = () => {
    const hasFamilyModified = families.filter((family) => family.isModified);
    dispatch(postFamily(hasFamilyModified));
    families.forEach((family, familyIndex) => {
      getNewArray(families, familyIndex, 'isModified', false);
    });
  };

  useEffect(() => {
    families.forEach((family) => {
      if (!Object.hasOwn(family, 'products')) {
        dispatch(getFamilyProducts(family.id));
      }
    });
  }, [families]);

  return (
    <>
      {families?.map((family, familyIndex) => (
        <CustomizationFamiliesItem
          key={family.id}
          family={family}
          familyIndex={familyIndex}
          families={families}
          onCheckFamilyItem={onCheckFamilyItem}
          onChangeNameFamily={onChangeNameFamily}
          onChangeNameProduct={onChangeNameProduct}
          onCheckProductItem={onCheckProductItem}
          onModifyFamilyState={onModifyFamilyState}
        />
      ))}
      <Flex justifyContent='end' marginTop={2}>
        <Button disabled={handleSubmitDisabled()} action={() => handleSubmit()}>
          {t('general.save')}
        </Button>
      </Flex>
    </>
  );
};

export default CustomizationFamilies;
