import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import InstalationsList from '../../old_components/InstalationsList';
import { reactPlugin, appInsights } from '../../core/old_common/utils/appInsights';

export class Instalations extends Component {
  trackPageView = appInsights.trackPageView({ name: 'Listado Instalaciones' });

  render() {
    return <InstalationsList />;
  }
}

const InstalationsPageWithAITracking = withAITracking(reactPlugin, Instalations);
const InstalationsPage = withTranslation()(InstalationsPageWithAITracking);

export default InstalationsPage;
