import React, { useRef } from 'react';
import { Box, Flex, GridItem, Grid } from '@jcm-technologies/uikit/dist/atoms/Layout';
import { Text } from '@jcm-technologies/uikit/dist/atoms/Typography';
import { Field } from '@jcm-technologies/uikit/dist/atoms/Field';
import { Input } from '@jcm-technologies/uikit/dist/atoms/Input';
import { theme } from '@jcm-technologies/uikit/dist/theme';
import { UploaderIcon } from '@jcm-technologies/uikit/dist/molecules/Uploader';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Card } from '@jcm-technologies/uikit/dist/molecules/Card';

const Form = ({
  dataForm,
  errorsForm,
  handleBlur,
  handleChange,
  dataHonoaLogo1,
  dataHonoaLogo2,
  logo,
  handleChangeLogo,
  handleRemoveLogo,
}) => {
  const { t } = useTranslation();
  const tenants = useSelector((state) => state.tenants);
  const { color1 } = tenants;
  const imgHonoaLogo1Ref = useRef();
  const imgHonoaLogo2Ref = useRef();
  return (
    <Box width={['100%', '100%', '60%']}>
      <Text sizeText='display16' color='black' marginTop={3} fontWeight='bold'>
        {t('honoa.customize')}
      </Text>

      <Grid>
        <GridItem>
          <Flex width='100%'>
            <Flex marginRight={1} flexDirection='column' width='100%'>
              <Card rounded='solid' marginTop={3} marginBottom={1}>
                <Text color='blackLight' sizeText='display16' textAlign='left' marginBottom={1}>
                  {t('form.logo')}
                </Text>
                <Box marginBottom={2}>
                  <UploaderIcon
                    id='dataHonoaLogo1'
                    imgRef={imgHonoaLogo1Ref}
                    defaultValue={logo}
                    images={[dataHonoaLogo1]}
                    onImagesLoad={handleChangeLogo['honoaLogo1']}
                    messageEdit={t('general.edit')}
                    messageDelete={t('general.delete')}
                    handleImageDelete={handleRemoveLogo['honoaLogo1']}
                    name='dataHonoaLogo1'
                    themeColor={color1}
                    stylesAvatar={{
                      maxWidth: '350px',
                      width: '100%',
                      maxHeight: '75px',
                      borderRadius: theme.radii[2],
                    }}
                    styleImg={{
                      objectFit: 'contain',
                    }}
                  />
                </Box>
              </Card>
            </Flex>
            <Flex marginLeft={1} flexDirection='column' width='100%'>
              <Card rounded='solid' marginTop={3} marginBottom={1}>
                <Text color='blackLight' sizeText='display16' textAlign='left' marginBottom={1}>
                  {t('form.collapsedLogo')}
                </Text>
                <Box marginBottom={2}>
                  <UploaderIcon
                    id='dataHonoaLogoCollapsed'
                    imgRef={imgHonoaLogo2Ref}
                    defaultValue={logo}
                    images={[dataHonoaLogo2]}
                    onImagesLoad={handleChangeLogo['honoaLogo2']}
                    messageEdit={t('general.edit')}
                    messageDelete={t('general.delete')}
                    handleImageDelete={handleRemoveLogo['honoaLogo2']}
                    name='dataHonoaLogoCollapsed'
                    themeColor={color1}
                    stylesAvatar={{
                      maxWidth: '350px',
                      width: '100%',
                      maxHeight: '75px',
                      borderRadius: theme.radii[2],
                    }}
                    styleImg={{
                      objectFit: 'contain',
                    }}
                  />
                </Box>
              </Card>
            </Flex>
          </Flex>
        </GridItem>
        <GridItem>
          <Card rounded='solid' marginTop={3} marginBottom={1}>
            <Flex width='100%'>
              <Flex marginX={3} flexDirection='column' width='100%'>
                <Field label={`${t('form.email')}`} id={dataForm[2]?.tag} marginY={1} required>
                  <Input
                    id={dataForm[2]?.tag}
                    name={dataForm[2]?.tag}
                    onBlur={(name) => handleBlur(name)}
                    onChange={(name, value) => handleChange(name, value)}
                    value={dataForm[2]?.value || ''}
                    hasError={errorsForm[dataForm[2]?.tag]?.error ? true : false}
                    errorMessage={errorsForm[dataForm[2]?.tag]?.errorMessage}
                  />
                </Field>
                <Field
                  label={`${t('form.phoneNumber')}`}
                  id={dataForm[3]?.tag}
                  marginY={1}
                  required
                >
                  <Input
                    id={dataForm[3]?.tag}
                    name={dataForm[3]?.tag}
                    onBlur={(name) => handleBlur(name)}
                    onChange={(name, value) => handleChange(name, value)}
                    value={dataForm[3]?.value || ''}
                    hasError={errorsForm[dataForm[3]?.tag]?.error ? true : false}
                    errorMessage={errorsForm[dataForm[3]?.tag]?.errorMessage}
                  />
                </Field>
                <Field label={`${t('form.city')}`} id={dataForm[1]?.tag} marginY={1} required>
                  <Input
                    id={dataForm[1]?.tag}
                    name={dataForm[1]?.tag}
                    onBlur={(name) => handleBlur(name)}
                    onChange={(name, value) => handleChange(name, value)}
                    value={dataForm[1]?.value || ''}
                    hasError={errorsForm[dataForm[1]?.tag]?.error ? true : false}
                    errorMessage={errorsForm[dataForm[1]?.tag]?.errorMessage}
                  />
                </Field>
              </Flex>
              <Flex marginX={3} flexDirection='column' width='100%'>
                <Field label={`${t('form.web')}`} id={dataForm[4]?.tag} marginY={1} required>
                  <Input
                    id={dataForm[4]?.tag}
                    name={dataForm[4]?.tag}
                    onBlur={(name) => handleBlur(name)}
                    onChange={(name, value) => handleChange(name, value)}
                    value={dataForm[4]?.value || ''}
                    hasError={errorsForm[dataForm[4]?.tag]?.error ? true : false}
                    errorMessage={errorsForm[dataForm[4]?.tag]?.errorMessage}
                  />
                </Field>
                <Field label={`${t('form.address')}`} id={dataForm[0]?.tag} marginY={1} required>
                  <Input
                    id={dataForm[0]?.tag}
                    name={dataForm[0]?.tag}
                    onBlur={(name) => handleBlur(name)}
                    onChange={(name, value) => handleChange(name, value)}
                    value={dataForm[0]?.value || ''}
                    hasError={errorsForm[dataForm[0]?.tag]?.error ? true : false}
                    errorMessage={errorsForm[dataForm[0]?.tag]?.errorMessage}
                  />
                </Field>
                <Box as='label' height={46} />
              </Flex>
            </Flex>
          </Card>
        </GridItem>
      </Grid>
    </Box>
  );
};

export default Form;

Form.propTypes = {
  dataForm: PropTypes.arrayOf(
    PropTypes.shape({
      tag: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  errorsForm: PropTypes.object,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  dataHonoaLogo1: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      base64: PropTypes.string,
    }),
  ]).isRequired,
  dataHonoaLogo2: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      base64: PropTypes.string,
    }),
  ]).isRequired,
  logo: PropTypes.string,
  handleChangeLogo: PropTypes.shape({
    honoaLogo1: PropTypes.func,
    honoaLogo2: PropTypes.func,
  }).isRequired,
  handleRemoveLogo: PropTypes.shape({
    honoaLogo1: PropTypes.func,
    honoaLogo2: PropTypes.func,
  }).isRequired,
};
