import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';

export const createHubConnectionBuilder = (connectionPath) => {
  const connection = new HubConnectionBuilder()
    .configureLogging(LogLevel.None)
    .withUrl(connectionPath)
    .withAutomaticReconnect()
    .build();
  return connection;
};

export const addCanalOnHubConnection = (connection, hubCanal, canalFunc) => {
  connection.on(hubCanal, canalFunc);
};

let restartConnectionId;

async function startHubConnection(
  connection,
  connectedFunction = () => console.log('connected'),
  errorFunction
) {
  try {
    clearTimeout(restartConnectionId);
    restartConnectionId = '';
    await connection.start();
    connectedFunction();
  } catch (err) {
    if (errorFunction) {
      errorFunction();
    }
    if (!restartConnectionId) {
      connection.stop();
      restartConnectionId = setTimeout(
        () => startHubConnection(connection, connectedFunction, errorFunction),
        1000
      );
    }
  }
}

export const createSignalRConnection = ({
  connectionPath,
  hubCanals,
  onConnectionStarts,
  onConnectionError,
}) => {
  const connection = createHubConnectionBuilder(connectionPath);
  hubCanals.map((canal) => addCanalOnHubConnection(connection, canal.name, canal.function));
  startHubConnection(connection, onConnectionStarts, onConnectionError);
  return connection;
};

export const invokeHubFunction = (connection, callback, functionName, ...params) => {
  connection
    .invoke(functionName, ...params)
    .then((x) => {
      callback(x);
    })
    .catch((error) => console.log(error));
};

export default {
  createHubConnectionBuilder,
  addCanalOnHubConnection,
  startHubConnection,
  createSignalRConnection,
  invokeHubFunction,
};
