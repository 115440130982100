import PathEntity from '../../../core/old_common/rooting/apiPaths/PathEntity';
import { apiVersion, mainPath } from '../../../core/old_common/rooting/common';

class Routes extends PathEntity {
  getPaged = (facilityId, groupId) =>
    this.manufacturePath(`facilities/${facilityId}/groups/${groupId}/fobs`);

  post = (facilityId, groupId) => {
    this.mainPath = `${mainPath()}4.1`;
    const endpoint = this.manufacturePath(`facilities/${facilityId}/groups/${groupId}/fob`);
    this.mainPath = `${mainPath()}${apiVersion}`;
    return endpoint;
  };

  put = (facilityId, groupId, fobId) =>
    this.manufacturePath(`facilities/${facilityId}/groups/${groupId}/fob/${fobId}`);

  copy = (facilityId, groupId, toFacilityId, toGroupId) => {
    this.mainPath = `${mainPath()}4.1`;
    const endpoint = this.manufacturePath(
      `facilities/${facilityId}/groups/${groupId}/fobs/copy/${toFacilityId}/${toGroupId}`
    );
    this.mainPath = `${mainPath()}${apiVersion}`;
    return endpoint;
  };

  move = (facilityId, groupId, toFacilityId, toGroupId) => {
    this.mainPath = `${mainPath()}4.1`;
    const endpoint = this.manufacturePath(
      `facilities/${facilityId}/groups/${groupId}/fobs/move/${toFacilityId}/${toGroupId}`
    );
    this.mainPath = `${mainPath()}${apiVersion}`;
    return endpoint;
  };

  delete = (facilityId, groupId, fobId) =>
    this.manufacturePath(`facilities/${facilityId}/groups/${groupId}/fob/${fobId}`);

  deleteList = (facilityId, groupId) => {
    this.mainPath = `${mainPath()}4.1`;
    const endpoint = this.manufacturePath(`facilities/${facilityId}/groups/${groupId}/fobs`);
    this.mainPath = `${mainPath()}${apiVersion}`;
    return endpoint;
  };

  deleteCompareMemoryList = (facilityId, groupId) =>
    this.manufacturePath(`facilities/${facilityId}/groups/${groupId}/fromcompare`);

  postCompareMemoryList = (facilityId, groupId) =>
    this.manufacturePath(`facilities/${facilityId}/groups/${groupId}/fromcompare`);

  postList = (facilityId, groupId) => {
    this.mainPath = `${mainPath()}4.1`;
    const endpoint = this.manufacturePath(`facilities/${facilityId}/groups/${groupId}/fobs`);
    this.mainPath = `${mainPath()}${apiVersion}`;
    return endpoint;
  };

  delete = (facilityId, groupId, id) => {
    const endpoint = this.manufacturePath(`facilities/${facilityId}/groups/${groupId}/fob/${id}`);
    return endpoint;
  };

  getById = (facilityId, groupId, fobId) =>
    this.manufacturePath(`facilities/${facilityId}/groups/${groupId}/fob/${fobId}`);

  postSequential = (facilityId, groupId, codesAmount, isReservation, initialCode) => {
    this.mainPath = `${mainPath()}4.1`;
    let finalEndpoint = this.manufacturePath(
      `facilities/${facilityId}/groups/${groupId}/fobs/addsequential`
    );
    finalEndpoint = this.addParameterToEndpoint(finalEndpoint, 'IsReservation', isReservation);
    finalEndpoint = this.addParameterToEndpoint(finalEndpoint, 'codesAmount', codesAmount);
    if (initialCode) {
      finalEndpoint = this.addParameterToEndpoint(finalEndpoint, 'initialCode', initialCode);
    }
    this.mainPath = `${mainPath()}${apiVersion}`;
    return finalEndpoint;
  };

  checkContent = (facilityId, groupId, columnName, value) => {
    let finalEndpoint = this.manufacturePath(
      `facilities/${facilityId}/groups/${groupId}/checkcontent`
    );
    finalEndpoint = this.addParameterToEndpoint(finalEndpoint, 'columnName', columnName);
    finalEndpoint = this.addParameterToEndpoint(finalEndpoint, 'value', value);
    return finalEndpoint;
  };

  checkCodes = (facilityId, groupId, initialCode, codesAmount) => {
    let finalEndpoint = this.manufacturePath(
      `facilities/${facilityId}/groups/${groupId}/fobs/checkcodes`
    );
    finalEndpoint = this.addParameterToEndpoint(finalEndpoint, 'initialCode', initialCode);
    finalEndpoint = this.addParameterToEndpoint(finalEndpoint, 'codesAmount', codesAmount);
    return finalEndpoint;
  };

  checkCodesLong = (facilityId, groupId, initialCode, codesAmount) => {
    let finalEndpoint = this.manufacturePath(
      `facilities/${facilityId}/groups/${groupId}/fobs/checkcodeslong`
    );
    finalEndpoint = this.addParameterToEndpoint(finalEndpoint, 'initialCode', initialCode);
    finalEndpoint = this.addParameterToEndpoint(finalEndpoint, 'codesAmount', codesAmount);
    return finalEndpoint;
  };

  fobsPutImport = (facilityId, id) => {
    this.mainPath = `${mainPath()}4.1`;
    const endpoint = this.manufacturePath(`facilities/${facilityId}/groups/${id}/fobs/import`);
    this.mainPath = `${mainPath()}${apiVersion}`;
    return endpoint;
  };

  getPDFFobs = (facilityId, groupId, configFields) => {
    let finalEndpoint = this.manufacturePath(`facilities/${facilityId}/groups/${groupId}/fobs/pdf`);
    if (configFields) {
      configFields.map((field) => {
        finalEndpoint = this.addParameterToEndpoint(finalEndpoint, 'configFieldsEnabled', field);
      });
      return finalEndpoint;
    }
    return finalEndpoint;
  };

  getPaths = () => ({
    fobsPutMove: this.move,
    fobsPostCopy: this.copy,
    fobsGetList: this.getPaged,
    fobsPost: this.post,
    fobsPostList: this.postList,
    fobsPut: this.put,
    fobsDelete: this.delete,
    fobsDeleteList: this.deleteList,
    fobsDeleteCompareMemory: this.deleteCompareMemoryList,
    fobsPostCompareMemory: this.postCompareMemoryList,
    fobsGet: this.getById,
    fobsPostSequential: this.postSequential,
    fobsGetCheckContent: this.checkContent,
    fobsGetCheckCodes: this.checkCodes,
    fobsGetCheckCodesLong: this.checkCodesLong,
    fobsPutImport: this.fobsPutImport,
    fobsGetFile: this.getPDFFobs,
  });
}

export default new Routes().getPaths();
