import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { getNeighbours } from '../../modules/old_to_refact/actions/neighbours';
import Neighbours from './components/Neighbours';
import './InstalationEquipmentsNeighbours.css';

export class InstalationEquipmentsNeighbours extends Component {
  componentDidMount = () => {
    const { getNeighbours, facilityId, equipmentId } = this.props;
    getNeighbours(facilityId, equipmentId);
  };

  render() {
    const { neighbours, facilityId, equipmentId } = this.props;
    return (
      <Neighbours neighbours={[...neighbours]} facilityId={facilityId} equipmentId={equipmentId} />
    );
  }
}

const mapStateToProps = (state) => ({
  neighbours: state.neighbours.neighbours,
});

export default connect(mapStateToProps, { getNeighbours })(InstalationEquipmentsNeighbours);
