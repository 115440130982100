/* eslint-disable no-useless-concat */
import $ from 'jquery';
import deviceIcon from './img/icon-device.png';
import groupIcon from './img/icon-universal-group.png';
import ugGroupIcon from './img/icon-group.png';
import instalationIcon from './img/icon-instalation.png';
import relayIcon from './img/icon-relay.png';
import { convertBinaryToArray } from '../../helpers/arrayService';

var InstallMaps = (function () {
  const installUrl = '';
  const installGroupsUrl = '';
  const universalGroupsUrl = '';
  const installId = '';
  const universalGroupId = '';
  const userid = '';
  const groups = '';
  const devicesResult = '';

  const installs = [];

  return {
    installUrl,
    installGroupsUrl,
    universalGroupsUrl,
    installId,
    universalGroupId,
    userid,
    groups,
    devices: devicesResult,
    installs,
    // getInstallData: getInstallData,
    getUniversalGroupData,
    outputGroups,
    outputDevices,
    outputGroup,
    outputUniversalGroups,
  };

  /**
   * Get Install Data
   *
   * @param {string} installId - The install id to query the API
   */
  // function getInstallData(installId) {
  // 	InstallMaps.installId = installId;
  // 	var getInstallGroupData = $.get(InstallMaps.installGroupsUrl, {
  // 		Installid: installId,
  // 		userid: InstallMaps.userid
  // 	});
  // 	var getInstallData = $.get(InstallMaps.installUrl + installId);
  // 	return $.when(getInstallGroupData, getInstallData).done(function(installGroupResult, installResult) {
  // 		devicesResult = installResult[0].InstallEquipments;
  // 		InstallMaps.devices = _getDevices(installResult[0].InstallEquipments);
  // 		InstallMaps.groups = installGroupResult[0];
  // 	});
  // }

  /**
   * Get UniversalGroups Data
   *
   * @param {string} universalGroupId - The universalGroupId id to query the API
   */
  function getUniversalGroupData(universalGroupId) {
    InstallMaps.universalGroupId = universalGroupId;
    const getInstallData = $.get(InstallMaps.universalGroupsUrl, { universalId: universalGroupId });
    return $.when(getInstallData).done((installResult) => {
      InstallMaps.installs = installResult;
    });
  }

  /**
   * Output Groups
   *
   * @returns {string} Generated HTML of the groups in an install with their devices, relays and channels.
   */
  function outputGroups(instalation) {
    let devicesG = [];
    let relayCount = 0;
    let output = '<div class="level-group first-level">';
    for (let i = 0; i < instalation.facilityGroups.length; i++) {
      output += '<div class="entry">';
      output +=
        '<div class="label">' +
        '<a class="toggle-branch">' +
        '<i class="fa fa-minus" aria-hidden="true"></i>' +
        '</a>';
      const nombre = encodeURIComponent(instalation.id);
      const nombreG = encodeURIComponent(instalation.facilityGroups[i].id.replace(/\//g, '%2F'));
      if (instalation.facilityGroups[i].isUniversal) {
        output += `${
          `<img src='${ugGroupIcon}' height='16' />` + '<a href="' + '/UniversalGroups/'
        }${nombreG}">${instalation.facilityGroups[i].name}</a>`;
      } else {
        output += `${
          `<img src='${groupIcon}' height='16' />` + '<a href="' + '/Facilities/'
        }${nombre}/groups/${nombreG}">${instalation.facilityGroups[i].name}</a>`;
      }
      output += '</div>';
      output += '<div class="branch level-device">';
      // devicesG = info.InstallEquipments;
      devicesG = _getDevices(
        instalation.facilityGroups[i].facilityEquipment,
        instalation.facilityGroups[i].id
      );

      for (let device = 0; device < devicesG.length; device++) {
        output += `<div class='entry ${devicesG.length === 1 ? 'sole' : ''}'>`;
        output += _deviceHTML(devicesG[device]);
        output += '<div class="branch level-relay">';
        relayCount = devicesG[device].relays.length;
        for (let relay = 0; relay < relayCount; relay++) {
          // output += _relayHTML(devicesG[device].relays[relay], true, relayCount);

          output += _relayHTML(devicesG[device].relays[relay], true, relayCount);
        }
        output += '</div>';
        output += '</div>';
      }
      output += '</div>';
      output += '</div>';
    }
    output += '</div>';
    return output;
  }

  /**
   * Output Groups
   *
   * @returns {string} Generated HTML of the groups in an install with their devices, relays and channels.
   */
  function outputUniversalGroups(universalGroup, universalGroupId) {
    let devicesG = [];
    let relayCount;
    let output = '<div class="level-group first-level">';
    if (universalGroup.items) {
      for (let i = 0; i < universalGroup.items.facilities.length; i++) {
        if (universalGroup.items.facilities[i]) {
          output += '<div class="entry">';
          output +=
            '<div class="label">' +
            '<a  class="toggle-branch">' +
            '<i class="fa fa-minus" aria-hidden="true"></i>' +
            '</a>';

          output +=
            // eslint-disable-next-line no-useless-concat
            `${`<img src=${instalationIcon} height="16" />` + '<span>'}${
              universalGroup.items.facilities[i].name
            }</span>`;

          output += '</div>';
          devicesG = _getDevices(
            universalGroup.items.facilities[i].facilityEquipments,
            universalGroup.universalGroupId
          );
          output += devicesG.length > 0 ? '<div class="branch level-device">' : '';
          for (let device = 0; device < devicesG.length; device++) {
            output += `<div class='entry ${devicesG.length === 1 ? 'sole' : ''}'>`;
            output += _deviceHTML(devicesG[device]);
            output += '<div class="branch level-relay">';
            relayCount = devicesG[device].relays.length;
            for (let relay = 0; relay < relayCount; relay++) {
              output += _relayHTML(devicesG[device].relays[relay], true, relayCount);
            }
            output += '</div>';
            output += '</div>';
          }
          output += devicesG.length > 0 ? '</div>' : '';
          output += '</div>';
        }
      }
    }
    output += '</div>';
    return output;
  }

  /**
   * Output Group
   *
   * @param {string} groupId - The group id to generate the group map HTML
   *
   * @returns {string} Generated HTML of the group with their devices, relays and channels.
   */
  function outputGroup(group) {
    // var devicesGroups = devicesResult;
    let devicesG = [];
    let relayCount;
    let output = '<div class="level-device first-level">';
    devicesG = _getDevices(group.facilityEquipment, group.id);
    for (let device = 0; device < devicesG.length; device++) {
      output += `<div class='entry ${devicesG.length === 1 ? 'sole' : ''}'>`;
      output += _deviceHTML(devicesG[device]);
      output += '<div class="branch level-relay">';
      relayCount = devicesG[device].relays.length;
      for (let relay = 0; relay < relayCount; relay++) {
        output += _relayHTML(devicesG[device].relays[relay], true, relayCount);
      }
      output += '</div>';
      output += '</div>';
    }
    output += '</div>';
    return output;
  }

  /**
   * Output Devices
   *
   * @returns {string} Generated HTML of the devices in an install with their relays.
   */
  function outputDevices(instalation) {
    let output = '<div class="level-device first-level">';
    let relayCount;
    for (let device = 0; device < instalation.facilityEquipments.length; device++) {
      output += `<div class='entry ${instalation.facilityEquipments.length === 1 ? 'sole' : ''}'>`;
      output += _deviceHTML(instalation.facilityEquipments[device]);
      output += '<div class="branch level-relay">';
      relayCount = instalation.facilityEquipments[device].facilityEquipmentRelays.length;
      for (let relay = 0; relay < relayCount; relay++) {
        output += _relayHTML(
          instalation.facilityEquipments[device].facilityEquipmentRelays[relay],
          false,
          relayCount
        );
      }
      output += '</div>';
      output += '</div>';
    }
    output += '</div>';

    return output;
  }

  /**
   * RelayCHannel HTML
   *
   * @param {Object} relayChannel - The relayChannel object from which to generate the HTML
   *
   * @returns {string} Generated HTML for relayChannels.
   */
  function _relayChannelHTML(relayChannel) {
    const output =
      `${
        '<div class="branch level-channel">' +
        '<ul class="ul-channels">' +
        '<li><div class="channel '
      }${relayChannel.ChannelOne ? 'on' : 'off'}">Chn. 1</div></li>` +
      `<li><div class='channel ${relayChannel.ChannelThree ? 'on' : 'off'}'>Chn. 3</div></li>` +
      `<li><div class='channel ${relayChannel.ChannelTwo ? 'on' : 'off'}'>Chn. 2</div></li>` +
      `<li><div class='channel ${relayChannel.ChannelFour ? 'on' : 'off'}'>Chn. 4</div></li>` +
      `</ul>` +
      `</div>`;
    return output;
  }

  /**
   * Relay HTML
   *
   * @param {Object} relay - The relay object from which to generate the HTML
   * @param {Boolean} showRelayChannels - Show the relayChannels in the HTML
   * @param {Number} relayCount - Number of relay to control the single relay with HTML class sole
   *
   * @returns {string} Generated HTML relays and channels.
   */
  function _relayHTML(relay, showRelayChannels, relayCount) {
    const relayOutput =
      relay.relayChannels && relay.relayChannels.length && relay.relayChannels.length !== 0
        ? _relayChannelHTML(relay.relayChannels[0])
        : '';
    const name = relay.name || relay.Name;
    const output =
      `<div class='entry ${relayCount === 1 ? 'sole' : ''}'>` +
      `<div class='label'>${
        showRelayChannels
          ? '<a class="toggle-branch"><i class="fa fa-minus" aria-hidden="true"></i></a>'
          : ''
      }<img src='${relayIcon}' height='16'/>` +
      `<span>${name}</span>` +
      `</div>${showRelayChannels ? relayOutput : ''}</div>`;
    return output;
  }

  function _deviceHTML(device) {
    const name = device.name || device.Name;
    let link = `<span>${name}</span>`;
    if (device.installGroupId && InstallMaps.installId) {
      link = `<a href='/installEquipments/DetailsChannels?InstallId=${InstallMaps.installId}&Id=${device.installGroupId}'>${device.name}</a>`;
    }
    const output = `${
      '<div class="label">' +
      '<a class="toggle-branch">' +
      '<i class="fa fa-minus" aria-hidden="true"></i>' +
      '</a>' +
      `<img src=${deviceIcon} height="16" />`
    }${link}</div>`;
    return output;
  }

  /**
   * Get Devices
   *
   * @param {Array} devicesArr - Install data from the API
   * @param {string} InstallGroupId - The install group id for filtering results to this install group
   *
   * @returns {Array} The devices array with cleaned device objects
   */
  function _getDevices(devicesArr, InstallGroupId) {
    const tempDevices = [];
    for (let i = 0; i < devicesArr.length; i++) {
      const relays = _getRelays(devicesArr[i].facilityEquipmentRelays, InstallGroupId);
      if (relays !== 0) {
        tempDevices.push({
          name: devicesArr[i].name,
          installGroupId: InstallGroupId,
          id: devicesArr[i].id,
          relays,
        });
      }
    }
    return tempDevices;
  }

  /**
   * Get Relays
   *
   * @param {Array} relaysArr - Install data from the API
   * @param {string} InstallGroupId - The install group id for filtering results to this install group
   *
   * @returns {Array} The relays array with cleaned relay objects
   */
  function _getRelays(relaysArr, InstallGroupId) {
    const relays = [];
    let relayChannels;
    for (let i = 0; i < relaysArr.length; i++) {
      relayChannels = _getRelayChannels(relaysArr[i].facilityGroupRelay, InstallGroupId);
      if (InstallGroupId == null || relayChannels.length !== 0) {
        relays.push({
          name: relaysArr[i].name,
          id: relaysArr[i].id,
          relayNumber: relaysArr[i].relayPosition,
          relayChannels,
        });
      }
    }
    return relays;
  }

  /**
   * Get RelayChannels
   *
   * @param {Array} relayChannelsArr - Install data from the API
   * @param {string} InstallGroupId - The install group id for filtering results to this install group
   *
   * @returns {Array} The relays array with cleaned relayChannel objects
   */

  function _getRelayChannels(relayChannelsNum, InstallGroupId) {
    const relayChannels = [];
    if (relayChannelsNum) {
      for (let i = 0; i < relayChannelsNum.length; i++) {
        const relayChannelsArr = convertBinaryToArray(relayChannelsNum[i].channelsMap);
        if (
          relayChannelsNum[i].universalGroupId != null &&
          InstallGroupId === relayChannelsNum[i].universalGroupId
        ) {
          relayChannels.push({
            ChannelOne: relayChannelsArr[0] > 0,
            ChannelTwo: relayChannelsArr[1] > 0,
            ChannelThree: relayChannelsArr[2] > 0,
            ChannelFour: relayChannelsArr[3] > 0,
            InstallGroupId: relayChannelsArr[i].universalGroupId,
          });
        } else if (
          InstallGroupId == null ||
          InstallGroupId === relayChannelsNum[i].facilityGroupId
        ) {
          relayChannels.push({
            ChannelOne: relayChannelsArr[0] > 0,
            ChannelTwo: relayChannelsArr[1] > 0,
            ChannelThree: relayChannelsArr[2] > 0,
            ChannelFour: relayChannelsArr[3] > 0,
            InstallGroupId: relayChannelsNum[i].facilityGroupId,
          });
        }
      }
    } else {
      relayChannels.push({
        ChannelOne: false,
        ChannelTwo: false,
        ChannelThree: false,
        ChannelFour: false,
        InstallGroupId,
      });
    }
    // relayChannels.push({
    // 	ChannelOne: true,
    // 	ChannelTwo: false,
    // 	ChannelThree: true,
    // 	ChannelFour: false,
    // 	InstallGroupId: InstallGroupId
    // });

    return relayChannels;
  }
})();

export default InstallMaps;
