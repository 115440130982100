import { call, fork, takeLatest } from 'redux-saga/effects';
import devicesActions from '../actions/devices';
import fetchEntitySaga from './utils/fetchEntitySaga';
import apiPathFactory from '../../../core/old_common/rooting/apiPathFactory';
import api from '../../../core/helpers/api';
import {
  DRIVERS_GET_STATE,
  DRIVERS_POST_WRITE_MEMORY_SINGLE,
  DRIVERS_SET_RELAY,
} from '../reducers/devices';

export const getDeviceState = {
  base: (facilityId, equipmentId) => ({
    type: DRIVERS_GET_STATE.BASE,
    facilityId,
    equipmentId,
  }),
  request: () => ({ type: DRIVERS_GET_STATE.REQUEST }),
  success: (result) => ({ type: DRIVERS_GET_STATE.SUCCESS, result }),
  failure: (error) => ({ type: DRIVERS_GET_STATE.FAILURE, error }),
};

export const setDeviceRelay = {
  base: (facilityId, equipmentId, relay, ms) => ({
    type: DRIVERS_SET_RELAY.BASE,
    facilityId,
    equipmentId,
    relay,
    ms,
  }),
  request: () => ({ type: DRIVERS_SET_RELAY.REQUEST }),
  success: (result) => ({ type: DRIVERS_SET_RELAY.SUCCESS, result }),
  failure: (error) => ({ type: DRIVERS_SET_RELAY.FAILURE, error }),
};

export const postWriteMemorySingle = {
  base: (facilityId, id, connId) => ({
    type: DRIVERS_POST_WRITE_MEMORY_SINGLE.BASE,
    facilityId,
    id,
    connId,
  }),
  request: () => ({ type: DRIVERS_POST_WRITE_MEMORY_SINGLE.REQUEST }),
  success: (result) => ({ type: DRIVERS_POST_WRITE_MEMORY_SINGLE.SUCCESS, result }),
  failure: (error) => ({ type: DRIVERS_POST_WRITE_MEMORY_SINGLE.FAILURE, error }),
};

export function* getDeviceStateSaga({ facilityId, equipmentId }) {
  try {
    const url = apiPathFactory.devicesGetState(facilityId, equipmentId);
    const response = yield call(() =>
      fetchEntitySaga(getDeviceState, api.get, url, { withoutErrorToast: true })
    );
    return response;
  } catch (error) {
    return error;
  }
}

export function* setDeviceRelaySaga({ facilityId, equipmentId, relay, ms }) {
  try {
    const url = apiPathFactory.devicesPutRelays(facilityId, equipmentId, relay, ms);
    const response = yield call(() =>
      fetchEntitySaga(setDeviceRelay, api.put, url, { withoutErrorToast: true, toastNotShow: true })
    );
    return response;
  } catch (error) {
    return error;
  }
}

export function* postWriteMemorySingleSaga({ facilityId, id, connId, ic, cc }) {
  try {
    const url = apiPathFactory.devicesPostWriteMemorySingle(facilityId, id, connId, ic, cc);
    const response = yield call(() =>
      fetchEntitySaga(postWriteMemorySingle, api.post, url, {
        withoutErrorToast: true,
        toastNotShow: true,
      })
    );
    return response;
  } catch (error) {
    return error;
  }
}

function* watchDeviceStateSaga() {
  yield takeLatest(devicesActions.GET_DEVICE_STATE, getDeviceStateSaga);
}

function* watchSetRelay() {
  yield takeLatest(devicesActions.SET_DEVICE_RELAY, setDeviceRelaySaga);
}

function* watchWriteMemorySingle() {
  yield takeLatest(devicesActions.POST_WRITE_MEMORY_SINGLE, postWriteMemorySingleSaga);
}

export default function* watchers() {
  yield fork(watchDeviceStateSaga);
  yield fork(watchSetRelay);
  yield fork(watchWriteMemorySingle);
}
