import styled from 'styled-components';
import { Text } from '@jcm-technologies/uikit/dist/atoms/Typography';
import css from '@styled-system/css';
import { variant } from 'styled-system';

export const SpanStyle = styled(Text)(
  {
    '&:hover': {
      color: (props) => props.tenants?.color1,
    },
    '&:hover > i': {
      fill: (props) => props.tenants?.color1,
      stroke: (props) => props.tenants?.color1,
    },
    'i:hover': {
      fill: (props) => props.tenants?.color1,
      stroke: (props) => props.tenants?.color1,
    },
  },
  css({
    marginRight: 1,
  }),
  variant({
    prop: 'disabled',
    variants: {
      true: {
        color: 'grey',
        cursor: 'not-allowed',
      },
      false: {
        cursor: 'pointer',
      },
    },
  })
);
