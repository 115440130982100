import { Box, Flex } from '@jcm-technologies/uikit/dist/atoms/Layout';
import { Text } from '@jcm-technologies/uikit/dist/atoms/Typography';
import { Icon } from '@jcm-technologies/uikit/dist/atoms/Icon';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { increaseBrightness } from '@jcm-technologies/uikit/dist/utils/color';
import { IconTooltip } from '@jcm-technologies/uikit/dist/molecules/IconTooltip';
import IconTrash from '@jcm-technologies/uikit/dist/atoms/Icons/IconTrash';
import { base64ToImage } from '../../../core/helpers/getImagesBase64';
import logo2 from '../../../static/favicon.png';
import logo1 from '../../../static/logo-tmp.png';
import { getMenuList } from '../../../core/services/menus';
import { appVersion } from '../../../core/old_common/constats/common';
import { legalNoticeDictionary } from './config';
import { setVersionPopupIsOpen } from '../../../modules/old_to_refact/actions/login';
import { ItemStyled } from './styles';
import {
  setSearchQuery,
  setSelectedDescendants,
} from '../../../modules/state/visibleDescendants/actions';
import {
  FACILITY_DESCENDANT,
  GROUP_DESCENDANT,
  SCHEDULE_DESCENDANT,
  UG_DESCENDANT,
} from '../../../modules/state/visibleDescendants/constants';

export const SidebarNav = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const tenants = useSelector((state) => state.tenants);
  const isCollapsed = useSelector((state) => state.app.isSidebarCollapsed);
  const { recycleExist } = useSelector((state) => state?.instalations);
  const {
    user: { language },
  } = useSelector((state) => state.user);

  const onClickClearAll = () => {
    dispatch(setSearchQuery('', false));
    dispatch(setSelectedDescendants(FACILITY_DESCENDANT, []));
    dispatch(setSelectedDescendants(SCHEDULE_DESCENDANT, []));
    dispatch(setSelectedDescendants(UG_DESCENDANT, []));
    dispatch(setSelectedDescendants(GROUP_DESCENDANT, []));
  };
  return (
    <Flex
      id='sidebar'
      flexDirection='column'
      backgroundColor='#000'
      width={[70, 70, isCollapsed ? 70 : 260]}
      height='100vh'
      position='fixed'
      zIndex={9}
    >
      <Flex
        maxWidth={250}
        maxHeight={60}
        height='100%'
        width='100%'
        marginY={3}
        alignItems='center'
        justifyContent='center'
        position='relative'
      >
        <Box
          id='sidebarLogo'
          as='img'
          alt='logo'
          src={base64ToImage(tenants.logo2) || logo2}
          display={['block', 'block', isCollapsed ? 'block' : 'none']}
          style={{
            maxWidth: '100%',
            width: '100%',
            maxHeight: '100%',
            objectFit: ' scale-down',
          }}
        />
        <Box
          id='sidebarLogoBrand'
          as='img'
          alt='logo'
          display={['none', 'none', isCollapsed ? 'none' : 'block']}
          style={{
            maxWidth: '100%',
            width: '100%',
            maxHeight: '100%',
            objectFit: ' scale-down',
          }}
          src={base64ToImage(tenants.logo1 || logo1)}
        />
      </Flex>
      <Flex id='sidebarNav' flex={1} flexDirection='column' as='nav' marginTop={4}>
        <Flex as='ul' id='sidebarNavList' flexDirection='column' height='100%'>
          {getMenuList().map((menu) => {
            const isActive = location?.pathname?.includes(menu.id);
            return (
              <ItemStyled
                key={menu.id}
                id={`sidebarNavListItem-${menu.id}`}
                as='li'
                alignItems='center'
                paddingX={[3, 3, isCollapsed ? 3 : 1]}
                paddingY={2}
                style={{ cursor: 'pointer' }}
                boxShadow={isActive ? 'bottomSoft' : 'none'}
                justifyContent='start'
                paddingLeft={[3, 3, isCollapsed ? 3 : 4]}
                zIndez={2}
                isActive={isActive}
                onClick={() => {
                  onClickClearAll();
                  history?.push(menu.navigateTo);
                }}
              >
                <Icon
                  sizeIcon='display16'
                  marginRight={2}
                  color={isActive ? increaseBrightness(tenants.color1, 40) : 'white'}
                >
                  {menu.icon}
                </Icon>
                <Box
                  flexDirection='column'
                  justifyContent='center'
                  display={['none', 'none', 'flex']}
                >
                  <>
                    {!isCollapsed && (
                      <Text
                        color={isActive ? increaseBrightness(tenants.color1, 40) : 'white'}
                        sizeText='display16'
                        fontWeight={isActive ? 'bold' : 'medium'}
                      >
                        {t(`${menu.translateKey}`)}
                      </Text>
                    )}
                  </>
                </Box>
              </ItemStyled>
            );
          })}

          <Flex
            flex={1}
            alignItems='baseline'
            flexDirection='column'
            justifyContent='end'
            width='100%'
          >
            {recycleExist && (
              <ItemStyled
                id='sidebarNavListItem-recycle'
                as='li'
                width='100%'
                paddingX={[3, 3, isCollapsed ? 3 : 1]}
                paddingY={2}
                style={{ cursor: 'pointer' }}
                boxShadow={location?.pathname?.includes('recycle') ? 'bottomSoft' : 'none'}
                justifyContent='start'
                paddingLeft={[3, 3, isCollapsed ? 3 : 4]}
                zIndez={2}
                alignItems='center'
                isActive={location?.pathname?.includes('recycle')}
                onClick={() => {
                  onClickClearAll();
                  history?.push('/facilities/recycle');
                }}
              >
                <IconTooltip
                  sizeIcon='display16'
                  cursor='pointer'
                  color={
                    location?.pathname?.includes('recycle')
                      ? increaseBrightness(tenants.color1, 40)
                      : 'white'
                  }
                  toolTipContent={t('genericGrow.delete')}
                  tooltipProps={{
                    place: 'top',
                  }}
                  marginRight={2}
                >
                  <IconTrash />
                </IconTooltip>
                <Box
                  flexDirection='column'
                  justifyContent='center'
                  display={['none', 'none', 'flex']}
                >
                  {!isCollapsed && (
                    <Text
                      color={
                        location?.pathname?.includes('recycle')
                          ? increaseBrightness(tenants.color1, 40)
                          : 'white'
                      }
                      sizeText='display16'
                      fontWeight={location?.pathname?.includes('recycle') ? 'bold' : 'medium'}
                    >
                      {t('title.recycleBin')}
                    </Text>
                  )}
                </Box>
              </ItemStyled>
            )}
          </Flex>
        </Flex>
      </Flex>
      <>
        {!isCollapsed ? (
          <Flex padding={[1, 1, 3]}>
            <Text color='blackLight' sizeText='display14'>
              {`© ${new Date().getFullYear()} `}
              <Text color='blackLight' sizeText='display14' display={['none', 'none', 'inline']}>
                {t('general.copyright')}
              </Text>
              <br />
              <Text
                id='sidebarLegalNotice'
                as='span'
                sizeText='display14'
                color={increaseBrightness(tenants?.color1, 40)}
                style={{ cursor: 'pointer' }}
                onClick={() => window.open(legalNoticeDictionary[language || 'default'], '_blank')}
                display={['none', 'none', 'inline']}
              >
                {' '}
                {t('general.useTerms')}
              </Text>
              <Text
                id='sidebarLegalNoticeMobile'
                as='span'
                sizeText='display14'
                color={increaseBrightness(tenants?.color1, 40)}
                style={{ cursor: 'pointer' }}
                onClick={() => window.open(legalNoticeDictionary[language || 'default'], '_blank')}
                display={['inline', 'inline', 'none']}
              >
                T&C
              </Text>
              <br />
              <Text
                id='sidebarVersionModal'
                as='span'
                sizeText='display14'
                style={{ cursor: 'pointer' }}
                color={increaseBrightness(tenants?.color1, 40)}
                onClick={() => dispatch(setVersionPopupIsOpen(true))}
                display={['none', 'none', 'inline']}
              >
                {`${t('general.version')} ${appVersion}`}
              </Text>
              <Text
                id='sidebarVersionModalMobile'
                as='span'
                sizeText='display14'
                style={{ cursor: 'pointer' }}
                color={increaseBrightness(tenants?.color1, 40)}
                onClick={() => dispatch(setVersionPopupIsOpen(true))}
                display={['inline', 'inline', 'none']}
              >
                {`V. ${appVersion}`}
              </Text>
            </Text>
          </Flex>
        ) : (
          <Flex padding={[1]}>
            <Text color='blackLight' sizeText='display14'>
              {`© ${new Date().getFullYear()}`}
              <br />
              <Text
                id='sidebarLegalNoticeCollapsed'
                as='span'
                sizeText='display14'
                color={increaseBrightness(tenants?.color1, 40)}
                style={{ cursor: 'pointer' }}
                onClick={() => window.open(legalNoticeDictionary[language || 'default'], '_blank')}
              >
                T&C
              </Text>
              <br />
              <Text
                id='sidebarVersionModalCollapsed'
                as='span'
                sizeText='display14'
                style={{ cursor: 'pointer' }}
                color={increaseBrightness(tenants?.color1, 40)}
                onClick={() => dispatch(setVersionPopupIsOpen(true))}
              >
                {`V. ${appVersion}`}
              </Text>
            </Text>
          </Flex>
        )}
      </>
    </Flex>
  );
};
