export default {
  signalRError: {
    11050: 'Váratlan válasz',
    11051: 'Rossz eszköz',
    11052: 'Rossz kódolás',
    11053: 'Az eszköz telepítő kódot használ',
    11054: 'Az eszköz nem használja a telepítő kódját',
    11055: 'Időkorlátozás',
    11056: 'Az asszisztens nem áll készen',
    11057: 'Az asszisztens elfoglalt ',
    11058: 'Hiba történt',
    11059: 'Művelet megtagadva',
    11060: 'Rossz indítási verzió',
    11061: 'Még mindig folyamatban van',
    11062: 'Rossz számláló',
    11063: 'Rossz gyártási szám',
    11064: 'Hiba az eszköz konfigurálásában',
    11065: 'Ismeretlen művelet',
    11066: 'Rossz eszköz',
    11067: 'Súlyos hiba',
    11068: 'Rossz crc',
    11069: 'Jelszó eltérés',
    connectionTimeout: 'Ellenőrizze a kapcsolatot a segédpanellel',
    dontMoveTag:
      'Hiba történt. Ismételje meg a műveletet anélkül, hogy a készüléket elmozdítaná a programozási zónából.',
  },
};
