import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import moment from 'moment-timezone';
import IconMuvAlarm from '@jcm-technologies/uikit/dist/atoms/Icons/IconMuvAlarm';
import GenericGrow from '../GenericGrow/GenericGrow';
import {
  deleteInstalationGroup,
  getInstalationGroup,
  resetInstalationGroup,
} from '../../modules/old_to_refact/actions/instalationGroups';
import InstalationGroupMapPopUpContent from '../InstalationGroupMapPopUpContent/InstalationGroupMapPopUpContent';
import GenericMapButton from '../GenericMapButton/GenericMapButton';
import PropsList from '../PropsList/PropsList';
import {
  getCookie_groupIsUniversal,
  getCookieUserRoles,
} from '../../core/old_common/utils/cookiesManager';
import { generateAlarmSumary } from '../utils/mappers/alarms';
import apiPathFactory from '../../core/old_common/rooting/apiPathFactory';
import { replaceStringValue } from '../../core/old_common/utils/replaceStringService';
import { downloadFile, getFilesFromApi } from '../../core/helpers/filesApi';
import PdfPopup from '../PdfPopup/PdfPopup';
import { getInstalationsFOBS } from '../../modules/old_to_refact/actions/instalations';
import store from '../../modules/old_to_refact/stores/configureStore';
import { showDialog } from '../../modules/old_to_refact/actions/loading';

class InstalationGroupGrow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popupOpenPDF: false,
      mapProps: {
        isOpen: false,
        facilityGroupId: '',
      },
    };
  }

  getHeaderTitles = (isEditMode) => {
    const { name, t, match, facilityName, item } = this.props;

    return [
      {
        value: isEditMode ? name : t('updateInstalationForm.createFacilityGroup'),
        className: 'UG-portlet-header-item UG-header-title',
      },
      {
        value: item ? item.facilityName : facilityName,
        className: 'UG-portlet-header-item',
      },
    ];
  };

  getSelectedFacility = () => {
    const { facility, instalations, item } = this.props;
    if (facility) {
      return facility;
    }
    if (instalations) {
      return instalations.find((instalation) => instalation.id === item.facilityId);
    }
    return undefined;
  };

  popupDeleteChildren = (item) => {
    const { t } = this.props;
    const selectedFacility = this.getSelectedFacility();
    return [
      {
        key: t('form.name'),
        value: item.name,
      },
      {
        key: t('instalationGroups.facilityName'),
        value: selectedFacility ? selectedFacility.name : '',
      },
      {
        key: t('instalationGroups.description'),
        value: item.description,
      },
    ];
  };

  generateInstalationGroupMapContent = (id) => {
    const { mapProps } = this.state;
    return (
      <InstalationGroupMapPopUpContent
        isOpen={mapProps.isOpen}
        facilityGroupId={id}
        onCloseForm={() => this.isMapOpen(false)}
        resetInstalationGroup={this.props.resetInstalationGroup}
        mapInfo={this.props.facilityGroup}
      />
    );
  };

  getActionButtons = (item) => {
    const { t, match, getInstalationsFOBS, facilityId, id } = this.props;
    const role = getCookieUserRoles();
    const roleMask = role ? role.mask : '';
    const isPropertyManagerLiteRole = roleMask === 256;
    const isPropertyManagerRole = roleMask === 128;
    const isBasicUserRole = roleMask === 16;
    const actionIcons = [];
    if (item?.alarms?.length) {
      actionIcons.unshift({
        toolTip: generateAlarmSumary(item.alarms),
        icon: <IconMuvAlarm fill='red' />,
        iconClassName: `generic-grow-action-button d-grid`,
        cursor: 'auto',
        onClick: (e) => {
          e?.preventDefault();
          e?.stopPropagation();
        },
      });
    }
    if (!isPropertyManagerRole && !isBasicUserRole && !isPropertyManagerLiteRole) {
      actionIcons.push({
        id: 'config',
        toolTip: t('instalationGroups.config'),
        defaultColor: '#a4aebb',
        iconClassName: 'generic-grow-action-button fas fa-cog',
        cursor: 'pointer',
        onClick: (e) => {
          e?.preventDefault();
          e?.stopPropagation();
          if (item.isUniversal) {
            this.props.history.push(
              `/UniversalGroups/${this.props.id}/Equipments/${
                this.props.id === decodeURI(match.params.id)
                  ? match.params.instalationId
                  : match.params.id
              }`
            );
          } else if (item?.facilityId) {
            this.props.history.push(
              `/Facilities/${item?.facilityId}/groups/${encodeURIComponent(item?.id)}/Configuration`
            );
          } else {
            this.props.history.push(
              `${this.props.baseURI}/${encodeURIComponent(item?.id)}/Configuration`
            );
          }
        },
      });
    }

    actionIcons.push({
      id: 'exportPDF',
      toolTip: t('general.downloadHtml'),
      defaultColor: '#a4aebb',
      iconClassName: 'generic-grow-action-button fas fa-file-pdf',
      cursor: 'pointer',
      onClick: async (e) => {
        e?.preventDefault();
        e?.stopPropagation();

        const setExportationPDF = async () => {
          if (this.props.instalationConfigFields.length !== 0) {
            this.setState({ popupOpenPDF: true });
          } else {
            store.dispatch(showDialog(1));
            const endpoint = apiPathFactory.fobsGetFile(this.props.facilityId, this.props.id, null);
            const name = `${moment().format('YYYY-MM-DD')}_${replaceStringValue(
              this.props.facility?.name?.substring(0, 50),
              'Facility'
            )}_${replaceStringValue(this.props.name, 'Group')}`;
            await getFilesFromApi(
              endpoint,
              (isError, response) => {
                if (!isError) {
                  const fileName = `${name}.html`;
                  downloadFile(response, fileName);
                }
              },
              'application/json',
              'binary'
            );
          }
        };

        await getInstalationsFOBS(facilityId, id);

        await setExportationPDF();
      },
    });

    return actionIcons;
  };

  changePopUpState = (newState, id) => {
    this.setState({
      mapProps: {
        isOpen: newState,
        facilityGroupId: id,
      },
    });
  };

  isMapOpen = (popUpState) => {
    this.props.resetInstalationGroup();
    this.setState({
      mapProps: {
        isOpen: popUpState,
      },
    });
  };

  render() {
    const {
      t,
      children,
      baseURI,
      creationURI,
      id,
      facilityId,
      item,
      customerId,
      deleteInstalationGroup,
      getInstalationGroup,
      resetInstalationGroup,
      match,
    } = this.props;
    const { mapProps } = this.state;
    const isUniversal = (item && item.isUniversal) || (getCookie_groupIsUniversal() && children);
    const isCreationMode = match.params.id === creationURI;
    return (
      <>
        <PdfPopup
          isOpen={this.state.popupOpenPDF}
          items={this.props.instalationConfigFields}
          facilityId={facilityId}
          groupId={id}
          name={`${moment().format('YYYY-MM-DD')}_${replaceStringValue(
            this.props.facilityId?.substring(0, 50),
            'Facility'
          )}_${replaceStringValue(this.props.name, 'Group')}`}
          onClose={() => this.setState({ popupOpenPDF: false })}
        />
        <GenericGrow
          key={customerId}
          item={item}
          rootPath={baseURI}
          customActionButtons={this.getActionButtons(item)}
          creationURI={creationURI}
          deleteButtonCustomId='deleteGroupId'
          isExpanded={children !== undefined}
          headerTitles={this.getHeaderTitles(!isCreationMode)}
          handleDelete={(e) => {
            deleteInstalationGroup(item);
            if (this.props.handleDelete) {
              this.props.handleDelete();
            }
          }}
          isMapButtonDisabled
          isMapButtonVisible
          deletePopupChildren={<PropsList propsArray={this.popupDeleteChildren(item)} />}
          deletePopupTitle={`${t('instalationGroups.deleteConfirmation')}`}
          MapComponent={() => (
            <GenericMapButton
              isExpanded={children !== undefined}
              customIcon={isUniversal ? 'fas fa-users' : null}
              isUniversalOnFacilities={isUniversal}
              isMapButtonVisible={false}
              mapProps={mapProps}
              isMapButtonDisabled={false}
              resetMapProps={this.resetMapProps}
              changePopUpState={this.changePopUpState}
              facilityGroup={id}
              mapId={id}
              onClosePopUp={() => {
                this.changePopUpState(false, '');
                resetInstalationGroup();
              }}
              onClick={(mapId) => {
                if (!isUniversal) {
                  this.changePopUpState(true, mapId);
                  getInstalationGroup(facilityId, mapId);
                }
              }}
            >
              {this.generateInstalationGroupMapContent(id)}
            </GenericMapButton>
          )}
        >
          {children}
        </GenericGrow>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  tenants: state.tenants,
  instalationGroups: state.instalationGroups.facilityGroups,
  instalations: state.instalations.instalations,
  facility: state.instalations.facility,
  facilityGroup: state.instalationGroups.facilityGroup,
  instalationConfigFields: state.instalations.instalationConfigFields,
});

const InstalationGroupGrowWithTranslation = withTranslation()(InstalationGroupGrow);
const InstalationGroupGrowWithRouter = withRouter(InstalationGroupGrowWithTranslation);

export default connect(mapStateToProps, {
  deleteInstalationGroup,
  getInstalationGroup,
  resetInstalationGroup,
  getInstalationsFOBS,
})(InstalationGroupGrowWithRouter);
