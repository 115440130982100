import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import IconDownload from '@jcm-technologies/uikit/dist/atoms/Icons/IconDownload';
import { IconTooltip } from '@jcm-technologies/uikit/dist/molecules/IconTooltip';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import React from 'react';
import { fileExcelName } from '../../config';

export const ExcelExportMifare = ({ data }) => {
  const { t } = useTranslation();
  const { id: idGroup, instalationId: idFacility } = useParams();
  const { customFields } = useSelector((state) => state.mifare);
  let exportExcel;
  return (
    <>
      <IconTooltip
        id='export-mifare-excel'
        color='grey'
        sizeIcon='display36'
        toolTipContent={t('instalationGroups.exportExcel')}
        onClick={() => exportExcel.save()}
        marginRight={2}
      >
        <IconDownload />
      </IconTooltip>
      <ExcelExport
        fileName={fileExcelName({ idFacility, idGroup })}
        data={data}
        ref={(exporter) => (exportExcel = exporter)}
      >
        <ExcelExportColumn
          key={t('form.id')}
          title={t('form.id')}
          field='id'
          cellOptions={{ background: '#ccc', wrap: true }}
          locked
          headerCellOptions={{ textAlign: 'center', bold: true }}
        />
        <ExcelExportColumn
          key={t('form.code')}
          title={t('form.code')}
          field='code'
          cellOptions={{ background: '#ccc', wrap: true }}
          locked
          headerCellOptions={{ textAlign: 'center', bold: true }}
        />
        <ExcelExportColumn
          key={t('form.type')}
          title={t('form.type')}
          field='type'
          cellOptions={{ background: '#ccc', wrap: true }}
          locked
          headerCellOptions={{ textAlign: 'center', bold: true }}
        />
        <ExcelExportColumn
          key={t('form.name')}
          title={t('form.name')}
          field='name'
          cellOptions={{ wrap: true }}
          locked
          headerCellOptions={{ textAlign: 'center', bold: true }}
        />
        <ExcelExportColumn
          key={t('form.surname')}
          title={t('form.surname')}
          field='surname'
          cellOptions={{ wrap: true }}
          locked
          headerCellOptions={{ textAlign: 'center', bold: true }}
        />
        <ExcelExportColumn
          key={t('form.identityCard')}
          title={t('form.identityCard')}
          field='nic'
          cellOptions={{ wrap: true }}
          locked
          headerCellOptions={{ textAlign: 'center', bold: true }}
        />
        <ExcelExportColumn
          key={t('form.slot')}
          title={t('form.slot')}
          field='plaza'
          cellOptions={{ wrap: true }}
          locked
          headerCellOptions={{ textAlign: 'center', bold: true }}
        />
        {customFields?.map((column) => (
          <ExcelExportColumn
            key={column.id}
            title={column.value}
            field={column.tag}
            cellOptions={{ wrap: true }}
            locked
            headerCellOptions={{ textAlign: 'center', bold: true }}
          />
        ))}
      </ExcelExport>
    </>
  );
};

ExcelExportMifare.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};
