import moment from 'moment/moment';
import { Flex } from '@jcm-technologies/uikit/dist/atoms/Layout';
import { Icon } from '@jcm-technologies/uikit/dist/atoms/Icon';
import IconPen from '@jcm-technologies/uikit/dist/atoms/Icons/IconPen';
import { Text } from '@jcm-technologies/uikit/dist/atoms/Typography';
import IconTimes from '@jcm-technologies/uikit/dist/atoms/Icons/IconTimes';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { getContrastColor } from '../../../../core/helpers/colorManager';

export function EventSchedule({ data, event, setIsOpenedEditEvent, onDelete, eventsData }) {
  const { color1 } = useSelector((state) => state.tenants);
  const { id, end, start } = event;
  const { t } = useTranslation();
  const getRealEvent = () => data?.find((eve) => eve.id === id);
  const realEvent = getRealEvent(id);
  const ms = moment(end, 'DD/MM/YYYY HH:mm:ss').diff(moment(start, 'DD/MM/YYYY HH:mm:ss'));
  const d = moment.duration(ms);
  const difference = Math.floor(d.asHours()) + moment.utc(ms).format(':mm:ss');

  return (
    <Flex alignItems='center' justifyContent='space-between'>
      <Icon
        sizeIcon='display13'
        marginRight={1}
        color={getContrastColor(color1)}
        onClick={() => {
          setIsOpenedEditEvent(true);
        }}
      >
        <IconPen />
      </Icon>
      <Text sizeText='display13' color={getContrastColor(color1)}>
        {`${
          difference
            ? difference === '24:00:00'
              ? t('form.allDay')
              : realEvent
              ? `${moment(realEvent?.start).format('HH:mm')} - ${moment(realEvent?.end).format(
                  'HH:mm'
                )}`
              : `${moment(event?.start).format('HH:mm')} - ${moment(event?.end).format('HH:mm')}`
            : ''
        }`}
      </Text>
      <Icon
        sizeIcon='display13'
        marginRight={1}
        color={getContrastColor(color1)}
        onClick={() => onDelete(id, eventsData)}
      >
        <IconTimes />
      </Icon>
    </Flex>
  );
}

EventSchedule.propTypes = {
  data: PropTypes.arrayOf([PropTypes.shape({})]).isRequired,
  event: PropTypes.shape({
    id: PropTypes.string,
    start: PropTypes.instanceOf(Date),
    end: PropTypes.instanceOf(Date),
  }).isRequired,
  eventsData: PropTypes.arrayOf([PropTypes.shape({})]),
  setIsOpenedEditEvent: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};
