import createFetchTypes from '../../../core/typeActions/types';
import ugActions from '../actions/universalGroups';
import {
  deleteArrayNodeByPropName,
  updateArrayNodeByPropName,
} from '../../../core/helpers/arrayService';

export const UNIVERSAL_GROUP_GET_LIST = createFetchTypes('UNIVERSAL_GROUP_GET_LIST');
export const UNIVERSAL_GROUP_GET = createFetchTypes('UNIVERSAL_GROUP_GET');
export const UNIVERSAL_GROUP_GET_INFO = createFetchTypes('UNIVERSAL_GROUP_GET_INFO');
export const VISIBLE_DESCENDANTS_GET = createFetchTypes('VISIBLE_DESCENDANTS_GET');
export const UNIVERSAL_GROUP_SET = createFetchTypes('UNIVERSAL_GROUP_SET');
export const UNIVERSAL_GROUP_DELETE = createFetchTypes('UNIVERSAL_GROUP_DELETE');
export const UNIVERSAL_GROUP_FACILITY_DELETE = createFetchTypes('UNIVERSAL_GROUP_FACILITY_DELETE');
export const UNIVERSAL_GROUP_CREATE = createFetchTypes('UNIVERSAL_GROUP_CREATE');
export const UNIVERSAL_GROUP_INSTALATIONS_PUT = createFetchTypes(
  'UNIVERSAL_GROUP_INSTALATIONS_PUT'
);
export const UNIVERSAL_GROUP_EQUIPMENT_GET = createFetchTypes('UNIVERSAL_GROUP_EQUIPMENT_GET');
export const UNIVERSAL_GROUP_RESET_INFO = createFetchTypes('UNIVERSAL_GROUP_RESET_INFO');
export const UNIVERSAL_GROUP_PUT = createFetchTypes('UNIVERSAL_GROUP_PUT');
export const UNIVERSAL_GROUPS_EQUIPMENT_RELAYS_GET = createFetchTypes(
  'UNIVERSAL_GROUPS_EQUIPMENT_RELAYS_GET'
);
export const UNIVERSAL_GROUPS_FACILITIES = createFetchTypes('UNIVERSAL_GROUPS_FACILITIES');
export const UNIVERSAL_GROUPS_SINGLELIST_GET = createFetchTypes('UNIVERSAL_GROUPS_SINGLELIST_GET');

/* Redux state init */
const initialState = {
  universalGroups: undefined,
  universalGroup: undefined,
  instalationsGroupRelays: undefined,
  universalGroupsInfo: undefined,
  equipmentRelays: undefined,
  visibleDescendants: undefined,
  overallPages: 0,
  overallRows: 0,
  currentPage: 0,
  isSuccess: false,
  facilities: undefined,
  facilitiesTotalPages: 1,
  needUGFacilitesUpdate: false,
  universalGroupsSingleList: undefined,
  needCallUG: true,
  needCallUGSingleList: true,
  needFacilitiesByUg: true,
  a: undefined,
};

const updateRelays = (relay, instalationsGroupRelays) => {
  const updatedRelays = instalationsGroupRelays
    ? instalationsGroupRelays.map((equipment) => {
        if (equipment.id === relay.equipmenId) {
          equipment.relays = equipment.relays.map((equipmentRelay) => {
            if (equipmentRelay.relayId === relay.relayAssignation.relayId) {
              equipmentRelay = relay.relayAssignation;
            }
            return equipmentRelay;
          });
        }
        return equipment;
      })
    : [];
  return [...updatedRelays];
};

const updateInstalationGroupRelays = (ugList, newRelay) => {
  if (!ugList) {
    return undefined;
  }
  const updatedUGList = ugList.map((ug) => ({
    ...ug,
    equipments: updateRelays(newRelay, ug.equipments),
  }));
  return [...updatedUGList];
};

export default function universalGroupsReducer(state = initialState, action) {
  if (action.type !== undefined) {
    switch (action.type) {
      case UNIVERSAL_GROUP_PUT.SUCCESS:
      case UNIVERSAL_GROUP_SET.SUCCESS:
        return {
          ...state,
          isSuccess: true,
          universalGroup: action.payload[1].data,
          universalGroups: state.universalGroups
            ? updateArrayNodeByPropName(state.universalGroups, action.payload[1].data, 'id')
            : state.universalGroups,
        };
      case UNIVERSAL_GROUP_RESET_INFO.SUCCESS:
        return {
          ...state,
          universalGroupsInfo: [],
        };
      case UNIVERSAL_GROUP_GET_LIST.SUCCESS:
        const { overallPages, overallRows, currentPage, pageItems, availableSpots } = action.result;
        return {
          ...state,
          availableSpots,
          overallPages,
          currentPage,
          overallRows,
          universalGroups: pageItems,
        };
      case UNIVERSAL_GROUP_GET_LIST.FAILURE:
        return {
          ...state,
        };
      case VISIBLE_DESCENDANTS_GET.SUCCESS:
        return {
          ...state,
          visibleDescendants: action.result,
        };
      case UNIVERSAL_GROUP_GET_INFO.SUCCESS:
        return {
          ...state,
          universalGroupsInfo: action.result,
        };
      case UNIVERSAL_GROUP_DELETE.SUCCESS:
        return {
          ...state,
          universalGroups: deleteArrayNodeByPropName(state.universalGroups, action.result, 'id'),
        };
      case UNIVERSAL_GROUP_INSTALATIONS_PUT.SUCCESS:
        return {
          ...state,
          needUGFacilitesUpdate: true,
          instalationsGroupRelays: updateRelays(action.result, state.instalationsGroupRelays),
          equipmentRelays: updateInstalationGroupRelays(state.equipmentRelays, action.result),
        };
      case UNIVERSAL_GROUP_EQUIPMENT_GET.SUCCESS:
        return {
          ...state,
          instalationsGroupRelays: action.result,
        };
      case UNIVERSAL_GROUPS_EQUIPMENT_RELAYS_GET.SUCCESS:
        const { result } = action;
        return {
          ...state,
          overallPages: result.overallPages,
          currentPage: result.currentPage,
          overallRows: result.overallRows,
          equipmentRelays: result.pageItems,
        };
      case UNIVERSAL_GROUPS_FACILITIES.REQUEST:
        return {
          ...state,
          needFacilitiesByUg: false,
        };
      case UNIVERSAL_GROUPS_FACILITIES.SUCCESS:
        return {
          ...state,
          needFacilitiesByUg: true,
          facilities: [...action.result.pageItems],
          facilitiesTotalPages: action.result.overallPages,
          needUGFacilitesUpdate: false,
        };

      case ugActions.RESET_FACILITIES_LIST_BY_UG:
        return {
          ...state,
          facilities: undefined,
          facilitiesTotalPages: 1,
        };
      case UNIVERSAL_GROUP_CREATE.SUCCESS:
        return {
          ...state,
          universalGroup: action.result,
        };
      case UNIVERSAL_GROUP_GET.REQUEST:
        return {
          ...state,
          needCallUG: false,
        };
      case UNIVERSAL_GROUP_GET.SUCCESS:
        return {
          ...state,
          universalGroup: action.result,
          needCallUG: true,
        };
      case ugActions.RESET_UNIVERSAL_GROUP:
        return {
          ...state,
          universalGroup: undefined,
        };
      case ugActions.RESET_UNIVERSAL_GROUP_RELAYS:
        return {
          ...state,
          instalationsGroupRelays: undefined,
        };
      case UNIVERSAL_GROUP_FACILITY_DELETE.SUCCESS:
        return {
          ...state,
          facilities: [
            ...state.facilities.filter((x) => x.facilityId !== action.result.facilityId),
          ],
        };
      case ugActions.RESET_UNIVERSAL_GROUP_FACILITIES:
        return { ...state, facilities: undefined };

      case UNIVERSAL_GROUPS_SINGLELIST_GET.REQUEST:
        return { ...state, needCallUGSingleList: false };
      case UNIVERSAL_GROUPS_SINGLELIST_GET.SUCCESS:
        return {
          ...state,
          universalGroupsSingleList: [...action.result],
          needCallUGSingleList: true,
        };
      case ugActions.RESET_UNIVERSAL_GROUP_SINGLELIST:
        return { ...state, universalGroupsSingleList: undefined };
      default:
        return state;
    }
  }
  return state;
}
