export default {
  remoteComunications: {
    index: 'Index',
    content: 'Inhalt',
    serialNumber: 'Ordnungsnummer',
    uniqueKey: 'Einzigartiger Schlüssel',
    operationsCode: 'Oper. Code',
    finalCode: 'Code beenden',
    progress: 'Fortschritt',
    status: 'Status',
    refresh: 'Aktualisierung',
    saveConfig: 'Konfiguration speichern',
    readConfig: 'Konfiguration lesen',
    readEvents: 'Ereignisse lesen',
    removeEvents: 'Ereignisse entfernen',
    seeEvents: 'Siehe Ereignisse',
    export: 'Export',
    Completed: 'Abgeschlossen',
    Received: 'Empfangen',
    Processing: 'wird bearbeitet',
  },
};
