export default {
  changePassword: {
    title: 'Jelszó módosítása',
    submitText: 'Jelszó módosítása',
    currentPassword: 'Jelenlegi jelszó',
    newPassword: 'Új jelszó',
    confirmPassword: 'Jelszó jóváhagyása',
    cancel: 'Vissza',
    chooseFile: 'Fájl kiválasztása',
    newPasswordPopupTitle: 'Új jelszó beállítása',
  },
};
