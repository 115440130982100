import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Flex } from '@jcm-technologies/uikit/dist/atoms/Layout';
import { Button } from '@jcm-technologies/uikit/dist/atoms/Button';
import Popup from '../../../Popup';
import CookiesPolicyDictionary from './CookiesPolicy';
import legalNoticeDictionary from './LegalNotice';
import privacyPolicyDictionary from './PrivacyPolicy';
import ConditionsPolicyDictionary from './Conditions';
import { getContrastColor } from '../../../../core/helpers/colorManager';
import { ContentContainer } from '../../../ContentContainer/ContentContainer';
import { getActualLanguage } from '../../../../core/helpers/getLanguages';

import './PolicyAgreementPopUp.css';

class PolicyAgreementPopUp extends Component {
  constructor(props) {
    super(props);
  }

  getLocalizedLinkCookies = () => {
    const selectedLanguage = getActualLanguage();
    const pdf = CookiesPolicyDictionary[selectedLanguage];
    const selectedPDF = pdf === undefined ? CookiesPolicyDictionary['en-GB'] : pdf;
    return selectedPDF;
  };

  getLocalizedLinkConditions = () => {
    const selectedLanguage = getActualLanguage();
    const pdf = ConditionsPolicyDictionary[selectedLanguage];
    const selectedPDF = pdf === undefined ? ConditionsPolicyDictionary['en-GB'] : pdf;
    return selectedPDF;
  };

  getLocalizedLinkLegalNotice = () => {
    const selectedLanguage = getActualLanguage();
    const pdf = legalNoticeDictionary[selectedLanguage];
    const selectedPDF = pdf === undefined ? legalNoticeDictionary['en-GB'] : pdf;
    return selectedPDF;
  };

  getLocalizedLinkPrivacy = () => {
    const selectedLanguage = getActualLanguage();
    const pdf = privacyPolicyDictionary[selectedLanguage];
    const selectedPDF = pdf === undefined ? privacyPolicyDictionary['en-GB'] : pdf;
    return selectedPDF;
  };

  render() {
    const { isOpen, onClose, t, onlySeeDocument, tenants } = this.props;
    return (
      <Popup
        hideCloseButton
        isOpen={isOpen}
        onClose={onClose}
        closeOnDocumentClick={false}
        closeOnEscape={false}
      >
        <ContentContainer
          tenants={tenants}
          title={`${t('gdprPopup.PolicyAgreementPopupTitle1')}: ${t(
            'gdprPopup.PolicyAgreementPopupTitle2'
          )}`}
        >
          {onlySeeDocument ? null : (
            <Flex alignItems='left' justifyContent='space-between' flexDirection='column'>
              <p>{t('gdprPopup.firstPolicyAgreementText')}</p>
              <p>
                {t('gdprPopup.firstPolicyAgreementSecondText')}
                <a
                  className='pdf-label'
                  href={this.getLocalizedLinkConditions()}
                  target='_blank'
                  rel='noreferrer'
                >
                  {` ${t('gdprPopup.conditionsText')}`},{' '}
                </a>
                <a
                  className='pdf-label'
                  href={this.getLocalizedLinkLegalNotice()}
                  target='_blank'
                  rel='noreferrer'
                >
                  {t('gdprPopup.legalNoticeText')},
                </a>
                <a
                  className='pdf-label'
                  href={this.getLocalizedLinkPrivacy()}
                  target='_blank'
                  rel='noreferrer'
                >
                  {` ${t('gdprPopup.privacyText')}`}{' '}
                </a>{' '}
                {` ${t('gdprPopup.PolicyAgreementPopupAnd')} `}
                <a
                  className='pdf-label'
                  href={this.getLocalizedLinkCookies()}
                  target='_blank'
                  rel='noreferrer'
                >
                  {t('gdprPopup.cookiesText')}.{' '}
                </a>
              </p>
              <p>
                {t('gdprPopup.firstPolicyAgreementThirdText')}
                <a className='pdf-label' href='mailto:admin@jcm-tech.com'>
                  {' '}
                  admin@jcm-tech.com
                </a>
              </p>
            </Flex>
          )}
          <Flex justifyContent='flex-end' paddingY={1}>
            <>
              <Button
                id='acceptGDPR'
                sizeButton='small'
                backgroundColor={tenants?.color1}
                action={this.props.handleAccept}
                marginRight={2}
                color={getContrastColor(tenants?.color1)}
              >
                {t('gdprPopup.accept')}
              </Button>
              <Button
                backgroundColor='blackLight'
                id='cancel-button'
                sizeButton='small'
                action={this.props.handleCancel}
              >
                {t('gdprPopup.cancel')}
              </Button>
            </>
          </Flex>
        </ContentContainer>
      </Popup>
    );
  }
}

const PolicyAgreementPopUpWithTranslation = withTranslation()(PolicyAgreementPopUp);

export default PolicyAgreementPopUpWithTranslation;
