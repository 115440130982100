import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { Loading } from '@jcm-technologies/uikit/dist/atoms/Loading';
import { useTranslation } from 'react-i18next';
import { Flex } from '@jcm-technologies/uikit/dist/atoms/Layout';
import { Button } from '@jcm-technologies/uikit/dist/atoms/Button';
import PropTypes from 'prop-types';
import { getTenants } from '../../../../modules/tenants/actions';
import CustomizationUserFieldItem from '../../../../components/Administration/Customization/UserFields/FieldItem';
import { getNewArray } from '../../../../core/helpers/arrayService';
import { updateCustomerAttributes } from '../../../../modules/customers/actions';
import { messageLabelByCode } from './config';

export const Edit = ({ code }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { originalTenants } = useSelector((state) => state.tenants);
  const { customerId } = useSelector((state) => state.user.user);
  const [fields, setFields] = useState([]);
  useEffect(() => {
    if (!originalTenants?.length) {
      dispatch(getTenants());
    }
  }, []);

  useEffect(() => {
    if (originalTenants?.length && code) {
      setFields(originalTenants.filter((element) => element.type === code));
    }
  }, [originalTenants, code]);

  const handleSubmit = () => dispatch(updateCustomerAttributes(fields, customerId));

  if (fields) {
    return (
      <>
        {fields.map((field, fieldIndex) => (
          <CustomizationUserFieldItem
            key={field.id}
            field={field}
            onChangeName={(name, value) => {
              const updateArray = getNewArray(fields, fieldIndex, 'value', value);
              setFields(updateArray);
            }}
            onToggleActivation={(isChecked) => {
              const updateArray = getNewArray(fields, fieldIndex, 'visible', isChecked);
              setFields(updateArray);
            }}
            messageLabel={t(messageLabelByCode[code], {
              count: fieldIndex + 1,
            })}
          />
        ))}
        <Flex justifyContent='end' marginTop={2}>
          <Button action={handleSubmit}>{t('general.save')}</Button>
        </Flex>
      </>
    );
  }
  return <Loading show />;
};

Edit.propTypes = {
  code: PropTypes.number.isRequired,
};
