export default {
  descriptionParams: {
    ACCESSCONTROL:
      "Il indique si l'équipement dispose d'une entrée pour les dispositifs de contrôle d'accès",
    ACTIVEEVENTSTOSD: 'Il spécifie les événements actifs pour votre appareil',
    APNCONFIG: "Il configure l'APN de l'appareil",
    BADGE_MAITRE1: "Code d'élément de proximité utilisé comme clé principale",
    BADGE_MAITRE10: "Code d'élément de proximité utilisé comme clé principale",
    BADGE_MAITRE2: "Code d'élément de proximité utilisé comme clé principale",
    BADGE_MAITRE3: "Code d'élément de proximité utilisé comme clé principale",
    BADGE_MAITRE4: "Code d'élément de proximité utilisé comme clé principale",
    BADGE_MAITRE5: "Code d'élément de proximité utilisé comme clé principale",
    BADGE_MAITRE6: "Code d'élément de proximité utilisé comme clé principale",
    BADGE_MAITRE7: "Code d'élément de proximité utilisé comme clé principale",
    BADGE_MAITRE8: "Code d'élément de proximité utilisé comme clé principale",
    BADGE_MAITRE9: "Code d'élément de proximité utilisé comme clé principale",
    CONFIGRELAYSNEW1:
      "Il indique les relais qui seront activés en fonction du canal que l'appareil envoie",
    CONFIGRELAYSNEW2:
      "Il indique les relais qui seront activés en fonction du canal que l'appareil envoie",
    CONFIGRELAYSNEW3:
      "Il indique les relais qui seront activés en fonction du canal que l'appareil envoie",
    CONFIGRELAYSNEW4:
      "Il indique les relais qui seront activés en fonction du canal que l'appareil envoie",
    CONFIGRELAYSPIN1:
      "Il indique les relais qui seront activés en fonction du canal que l'appareil envoie",
    CONFIGRELAYSPIN2:
      "Il indique les relais qui seront activés en fonction du canal que l'appareil envoie",
    CONFIGRELAYSPIN3:
      "Il indique les relais qui seront activés en fonction du canal que l'appareil envoie",
    CONFIGRELAYSPIN4:
      "Il indique les relais qui seront activés en fonction du canal que l'appareil envoie",
    DISABLEMANUALPROG: "Il vous permet d'enregistrer les appareils manuellement",
    DISABLERADIOPROG:
      "Il empêche l'enregistrement des émetteurs en utilisant le mode de programmation radio (mode standard)",
    FREEACTIVE: 'Il active / désactive les caractéristiques du groupe de systèmes FREE',
    FREEGROUP0LOCKED: 'Il verrouille / déverrouille le groupe 0 du système FREE',
    FREEGROUPNUM: 'Il définit le numéro de groupe du système FREE',
    FREEGROUPRFPROGALLOWED:
      'Il active / désactive le mode de programmation radio pour un groupe de systèmes FREE',
    IMPBIRELE1: "Change le fonctionnement du relais 1 de l'impulsion à ON / OFF",
    INSTAL: "Montre si vous travaillez avec le code d'installation",
    MINIDDEVICE: "L'équipement acceptera des appareils tels que ceux indiqués ou supérieurs",
    PASSWORD: 'Mot de passe à 4 chiffres pour accéder au menu central',
    PIN: 'Code numérique à 6 chiffres correspondant au code PIN du récepteur',
    PINCADUCAT: 'Montre si le récepteur accepte les appareils avec le code PIN expiré',
    POWER: "Montre le type d'alimentation électrique de l'équipement",
    RELAYPRICE1: "Valeur à soustraire du crédit d'un appareil prépayé 1",
    RELAYPRICE2: "Valeur à soustraire du crédit d'un appareil prépayé 2",
    RELAYPRICE3: "Valeur à soustraire du crédit d'un appareil prépayé 3",
    RELAYPRICE4: "Valeur à soustraire du crédit d'un appareil prépayé 4",
    RELAYS: 'Nombre de relais dans le récepteur',
    RELE1IMP: 'Il configure le relais comme impulsion, ON / OFF ou panique',
    RELE1TEMP:
      'Il définit la durée pendant laquelle le relais restera actif en mode impulsion (de 1 à 30 sec) ou panique (de 1 à 15 min)',
    RELE2IMP: 'Il définit le relais comme impulsion, ON / OFF ou panique',
    RELE2TEMP:
      'Il définit la durée pendant laquelle le relais restera actif en mode impulsion (de 1 à 30 sec) ou panique (de 1 à 15 min)',
    RELE3IMP: 'Il définit le relais comme impulsion, ON / OFF ou panique',
    RELE3TEMP:
      'Il définit la durée pendant laquelle le relais restera actif en mode impulsion (de 1 à 30 sec) ou panique (de 1 à 15 min)',
    RELE4IMP: 'Il définit le relais comme impulsion, ON / OFF ou panique',
    RELE4TEMP:
      'Il définit la durée pendant laquelle le relais restera actif en mode impulsion (de 1 à 30 sec) ou panique (de 1 à 15 min)',
    SERIALNUMBER: 'Le numéro de série est obligatoire pour les actions à distance',
    SITECODE:
      "Code de site / code d'installation / code d'installation à envoyer dans le protocole Wiegand 26 avec code de site",
    TEVENT: "Il montre si le panneau de contrôle peut avoir une carte d'événements",
    TIMEZONEID: "Il définit le fuseau horaire où l'équipement est installé",
    TMEM: "Il montre si l'équipement fonctionne avec une carte mémoire",
    UNIQUEKEY: 'La clé unique est destinée aux actions à distance',
    W26FORMAT: 'Il définit le format de transmission du protocole Wiegand 26',
    SETNEWACCESSPW: 'Changer le mot de passe',
    SERIALNUMBERLINK: 'Le numéro de série est obligatoire pour les actions à distance',
    CONNECTIVITY_EXTENSION: 'Carte de liaison installée',
    ACTIVATIONKEY: "Clé d'activation",
    EMMITTERNOTUSEDEVENTTIME: "N'utilisez pas l'heure de l'événement de l'émetteur",
    DOOROPENEDEVENTTIME: "Événement d'ouverture de porte",
    SCHEDULESDATA: 'Planifie les données',
    A5KPROXY: 'Accès au proxy 5k',
    WIEGANDCONFIG: 'Personnalise la configuration Wiegand à utiliser',
  },
};
