export default {
  instalationGroups: {
    exportExcel: 'Download Excel',
    importExcel: 'Import Excel',
    exportPDF: 'Download PDF',
    identification: 'Identity card',
    place: 'Slot',
    enrollFOB: 'Enroll code',
    config: 'Configure',
    deleteConfirmation: 'Are you sure you want to delete this group?',
    description: 'Description',
    facilityName: 'Name of facility',
    replace: 'Replace',
    delete: 'Delete',
    deleteFobConfirmation: 'Are you sure you want to delete this fob?',
    alarms: 'Alarm',
    selectConfigFields: 'Select the USER / TRANSMITTER FIELDS to export',
    totalSelected: 'Total selected',
  },
};
