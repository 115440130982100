import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { ContentContainer } from '../ContentContainer/ContentContainer';
import { GenericForm } from '../GenericForm/GenericForm';
import validationsDictionary from '../../core/old_common/validations';
import { downloadFile, getFilesFromApi } from '../../core/helpers/filesApi';
import apiPathFactory from '../../core/old_common/rooting/apiPathFactory';

import './PopupAdjustDateTime.css';

class PopupAdjustDateTime extends Component {
  constructor(props) {
    super(props);
    this.state = {
      time: moment().format('HH:mm:ss'),
      timeInput: 1,
    };
  }

  componentDidMount() {
    this.interval = setInterval(() => this.displayTime(), 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  displayTime = () => {
    const now = moment();
    this.setState({ time: now.format('HH:mm:ss') });
  };

  handleAccept = () => {
    const { timeInput } = this.state;
    const { closePopup } = this.props;

    const url = apiPathFactory.a5KServicesGetTimeFile(timeInput);

    getFilesFromApi(url, (isError, response) => {
      if (!isError) {
        downloadFile(response, 'TimeSincFile.txt');
        if (response !== 200) {
          closePopup();
        }
      }
    });
  };

  handleNumericChange = (e) => {
    if (!isNaN(e.target.value)) {
      this.setState({ [e.target.id]: parseInt(e.target.value) });
    }
  };

  getFields = () => {
    const { t } = this.props;
    const fields = [
      [
        {
          type: 'label',
          value: t('adjustTimePopUp.actualHour'),
          id: 'actualHourLabel',
          className: '',
        },
        {
          type: 'label',
          value: this.state.time,
          id: 'actualHour',
          className: '',
        },
      ],
      [
        {
          type: 'label',
          value: t('adjustTimePopUp.minutesCompensation'),
          id: 'minutesCompensationlabel',
          className: 'labelSelectTime',
        },
        {
          type: 'number',
          placeHolder: t('adjustTimePopUp.inputTime'),
          onChange: this.handleNumericChange,
          value: this.state.timeInput,
          id: 'timeInput',
          className: 'time',
          validation: [
            () => `${validationsDictionary.isRequired(this.state.e_code)} *`,
            () => validationsDictionary.isNumeric(this.state.e_code),
            () => validationsDictionary.isInRange(this.state.e_code, 1, 999999),
          ],
        },
      ],
      [
        {
          type: 'label',
          value: t('adjustTimePopUp.insertSdMemory'),
          id: 'insertSdMemory',
          className: '',
        },
      ],
      [
        {
          type: 'label',
          value: moment().add(this.state.timeInput, 'minutes').format('DD/MM/YYYY'),
          id: 'modificatedDateLabel',
          className: ' adjust-datetime-popup-label-date ',
        },
      ],
      [
        {
          type: 'label',
          value: moment(this.state.time, 'HH:mm:ss')
            .add(this.state.timeInput, 'minutes')
            .format('HH:mm'),
          id: 'modificatedTimeLabel',
          className: ' adjust-datetime-popup-label-insert adjust-datetime-popup-label-center',
        },
      ],
    ];
    return fields;
  };

  render() {
    const { tenants, t, closePopup } = this.props;
    return (
      <ContentContainer tenants={tenants} title={t('adjustTimePopUp.manualDateTimeAdjust')}>
        <GenericForm
          className='adjust-datetime-popup-form'
          tenants={this.props.tenants}
          fields={this.getFields()}
          submitText={t('adjustTimePopUp.save')}
          cancelText={t('changePassword.cancel')}
          handleSubmit={() => this.handleAccept()}
          handleCancel={() => closePopup()}
          resetSuccess={() => ({})}
          isCancelVisible
          formErrors=''
        />
      </ContentContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  tenants: state.tenants,
});

const PopupAdjustDateTimeTranslation = withTranslation()(PopupAdjustDateTime);

export default connect(mapStateToProps, {})(PopupAdjustDateTimeTranslation);
