import i18Next from 'i18next';
import { createSignalRConnection, invokeHubFunction } from '../../services/signalR';
import store from '../../../modules/old_to_refact/stores/configureStore';
import {
  setAssistantCheckSuccessFunction,
  setNewMessageHubFunction,
  setSignalRCallError,
  setSignalRConnection,
  setSignalRConnectionData,
  setSignalRConnectionStatus,
  showPopUp,
} from '../../../modules/old_to_refact/actions/signalr';
import { hubConnectionPath } from '../rooting/hubConnection';
import { showDialog } from '../../../modules/old_to_refact/actions/loading';
import { showAlert } from '../../../modules/state/alerts';

let signalRCallTimeout;

const dispatchReducerAction = (action) => store.dispatch(action);

export const invokeSendFunction = (message, timeout = 20000, callback = () => {}) => {
  const state = store.getState();
  const hubConnection = state.signalR.connection;
  dispatchReducerAction(showDialog(1));
  const signalRTimeoutFunctionId = setTimeout(() => {
    dispatchReducerAction(showDialog(-1));
    dispatchReducerAction(showAlert('red', 1, i18Next.t('signalRError.connectionTimeout')));
  }, timeout);
  signalRCallTimeout = signalRTimeoutFunctionId;
  invokeHubFunction(hubConnection, callback, 'Send', createNameArgumentForSend(), message);
};

export const invokeSendCICC = () => invokeSendFunction(`ReadCI::0::0::1048401::0`);

export const parseCCIC = (codes) => {
  const values = codes.split('::');
  const code = values[0].split('|');
  return { cc: code[0], ic: code[1] };
};

export const createNameArgumentForSend = () => {
  const state = store.getState();
  return `${state?.user.user.assistantConfigId}::${
    store.getState().signalR.connectionData.connectionId
  }`;
};

export const checkAssistantPanelConnection = (callback) =>
  invokeSendFunction('checkAssistantPanel', 10000, callback);

export const createSignalRConnectionOnReducer = (
  onConnectionStarts = () => setConnectionStatus(true),
  onConnectionError = () => setConnectionStatus(false),
  connectionPath = hubConnectionPath
) => {
  const state = store.getState();
  if (!state.signalR.connection || !Object.keys(state.signalR.connection).length) {
    createConnection(onConnectionStarts, onConnectionError, connectionPath);
  }
};

const createConnection = (onConnectionStarts, onConnectionError, connectionPath) => {
  const connection = createSignalRConnection({
    connectionPath,
    hubCanals: getHubSubscriptionFunctions(),
    onConnectionStarts,
    onConnectionError,
  });
  connection.onclose(() => setConnectionStatus(false));
  dispatchSignalRConnectionOnReducer(connection);
};

const setConnectionStatus = (isConnected) =>
  dispatchReducerAction(setSignalRConnectionStatus(isConnected));
const getHubSubscriptionFunctions = () => [
  {
    name: 'NewMessage',
    function: hubNewMessage,
  },
];

const saveConnectionId = (id, connId) => {
  if (connId.toLowerCase() === 'connectionid') {
    dispatchReducerAction(setSignalRConnectionData({ propName: connId, connectionId: id }));
    return true;
  }
  return false;
};

const hubNewMessage = (connId, id) => {
  clearTimeout(signalRCallTimeout);
  const state = store.getState();
  const useInstallerCode = state.customers?.installerCode?.useInstallerCode;
  if (!saveConnectionId(id, connId)) {
    dispatchReducerAction(showDialog(-1));
    if (id.includes('HResult') && !isNaN(id.split('::')[0])) {
      const errorMessageCode = id.split('::')[0];
      const translateCode = `signalRError.${errorMessageCode}`;
      if (
        state.signalR.needPopup ||
        (state.signalR.needPopup === undefined &&
          (state.signalR.productId === 8 || state.signalR.productId === 10) &&
          errorMessageCode === '11052')
      ) {
        dispatchReducerAction(
          showPopUp(true, errorMessageCode !== '11052' ? translateCode : `signalRError.dontMoveTag`)
        );
      } else {
        dispatchReducerAction(
          showAlert(
            'red',
            1,
            i18Next.exists(translateCode)
              ? i18Next.t(translateCode)
              : i18Next.t('apitoasters.errors.generic')
          )
        );
      }
      if (state.signalR.onSignalRError) {
        state.signalR.onSignalRError(connId, id);
      }
    } else if (id.includes('Check_done') && state.signalR.onAssistantCheckSuccess) {
      state.signalR.onAssistantCheckSuccess(connId, id);
    } else if (state.signalR.hubFunction) {
      state.signalR.hubFunction(connId, id);
    }
  }
};

export const resetSignalRConnectionOnReducer = () => {
  const conn = store.getState().signalR.connection;
  if (conn) {
    conn.stop();
    dispatchSignalRConnectionOnReducer(undefined);
  }
};

export const setAssistantPanelCheckSuccessFunction = (successFunction) =>
  dispatchReducerAction(setAssistantCheckSuccessFunction(successFunction));

export const setMessageHubFunction = (messageFunction) =>
  dispatchReducerAction(setNewMessageHubFunction(messageFunction));

export const setSignalRErrorFunction = (errorFunction) =>
  dispatchReducerAction(setSignalRCallError(errorFunction));

const dispatchSignalRConnectionOnReducer = (connection) =>
  dispatchReducerAction(setSignalRConnection(connection));

export default { invokeSendFunction, createNameArgumentForSend, parseCCIC };
