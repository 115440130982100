import { Grid, GridItem } from '@jcm-technologies/uikit/dist/atoms/Layout';
import { Field } from '@jcm-technologies/uikit/dist/atoms/Field';
import { SelectInput } from '@jcm-technologies/uikit/dist/atoms/Select';
import { Input } from '@jcm-technologies/uikit/dist/atoms/Input';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { setWeekDropdownDatas } from '../config';

export function FormEditEvent({ handleChange, dataEvent, isEditingHolidays }) {
  const [defaultValueStart, setDefaultValueStart] = useState(null);
  const [defaultValueEnd, setDefaultValueEnd] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (dataEvent) {
      setDefaultValueStart(
        setWeekDropdownDatas.find((el) => Number(el.value) === Number(dataEvent?.dayStart))
      );
      setDefaultValueEnd(
        setWeekDropdownDatas.find((el) => Number(el.value) === Number(dataEvent?.dayEnd))
      );
    } else {
      setDefaultValueStart(null);
      setDefaultValueEnd(null);
    }
  }, [dataEvent]);
  return (
    <Grid
      gridTemplateAreas={["'. .'"]}
      gridGap={2}
      width='100%'
      gridTemplateColumns='1fr 1fr'
      gridTemplateRows='auto'
      paddingTop={3}
      paddingX={4}
    >
      {!isEditingHolidays && (
        <GridItem>
          <Field label={t('form.dayStart')} required marginY={1}>
            <SelectInput
              name='dayStart'
              id='dayStart'
              options={
                setWeekDropdownDatas?.map((el) => ({
                  value: el.value,
                  label: t(el.label),
                })) || []
              }
              onChange={(name, value) => {
                handleChange(name, value?.value);
              }}
              defaultValue={{ label: t(defaultValueStart?.label), value: defaultValueStart?.value }}
              placeholderMessage={t('general.selectOne')}
            />
          </Field>
        </GridItem>
      )}
      <GridItem>
        <Field label={t('form.hourStart')} marginY={1}>
          <Input
            type='time'
            id='timeStart'
            name='timeStart'
            onChange={handleChange}
            value={dataEvent?.timeStart}
          />
        </Field>
      </GridItem>
      {!isEditingHolidays && (
        <GridItem>
          <Field label={t('form.dayEnd')} required marginY={1}>
            <SelectInput
              name='dayEnd'
              id='dayEnd'
              options={
                setWeekDropdownDatas?.map((el) => ({
                  value: el.value,
                  label: t(el.label),
                })) || []
              }
              onChange={(name, value) => {
                handleChange(name, value?.value);
              }}
              defaultValue={{ label: t(defaultValueEnd?.label), value: defaultValueEnd?.value }}
              placeholderMessage={t('general.selectOne')}
            />
          </Field>
        </GridItem>
      )}
      <GridItem>
        <Field label={t('form.hourEnd')} marginY={1}>
          <Input
            type='time'
            id='timeEnd'
            name='timeEnd'
            onChange={handleChange}
            value={dataEvent?.timeEnd}
          />
        </Field>
      </GridItem>
    </Grid>
  );
}

FormEditEvent.propTypes = {
  dataEvent: PropTypes.shape({
    timeEnd: PropTypes.string,
    dayEnd: PropTypes.string,
    timeStart: PropTypes.string,
    dayStart: PropTypes.string,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  isEditingHolidays: PropTypes.bool.isRequired,
};
