import { Box, Flex } from '@jcm-technologies/uikit/dist/atoms/Layout';
import { Icon } from '@jcm-technologies/uikit/dist/atoms/Icon';
import IconSearch from '@jcm-technologies/uikit/dist/atoms/Icons/IconSearch';
import { Text } from '@jcm-technologies/uikit/dist/atoms/Typography';
import { SelectInput } from '@jcm-technologies/uikit/dist/atoms/Select';
import { Button } from '@jcm-technologies/uikit/dist/atoms/Button';
import { Card } from '@jcm-technologies/uikit/dist/molecules/Card';
import { useTranslation } from 'react-i18next';
import { Input } from '@jcm-technologies/uikit/dist/atoms/Input';
import { useSelector } from 'react-redux';
import { getContrastColor } from '../../../core/helpers/colorManager';
import { filtersDisabled } from './config';

const DashboardAdvancedSearch = ({
  handleSearch,
  onSetValueFilter,
  filterTypeSelected,
  filterSelected,
  onSetFilterType,
  onSetFilter,
  valueFilter,
}) => {
  const { t } = useTranslation();
  const tenants = useSelector((state) => state.tenants);
  const { cities, postalCode, filters, filterByType } = useSelector((state) => state.dashboard);

  const setFilterOptions = (value) => {
    switch (value) {
      case 3:
      case 4: {
        return (
          <SelectInput
            id='valueFilter'
            onChange={(name, value) => onSetValueFilter({ label: value.label, value: value.value })}
            options={
              (filterSelected.value === 4 && postalCode) || (filterSelected.value === 3 && cities)
            }
            placeholderMessage={t('general.selectOne')}
          />
        );
      }
      case 1:
      case 2:
      case 5:
      case 6:
      case 13:
      case 14:
      case 15:
      case 16:
      case 17:
      case 18:
      case 19:
      case 20:
      case 21:
      case 22:
      case 23:
      case 24:
      case 25:
      case 26:
      case 27:
      case 28:
      case 29:
      case 30:
      case 31: {
        return (
          <Input
            id='valueFilter'
            value={valueFilter}
            onChange={(name, value) => onSetValueFilter(value)}
            placeholderMessage={t('general.selectOne')}
          />
        );
      }
      default:
        return <></>;
    }
  };

  return (
    <Card
      rounded='soft'
      width='100%'
      height='100%'
      maxWidth='100%'
      position='relative'
      marginBottom={2}
      display='flex'
    >
      <Flex
        as='form'
        onSubmit={() => handleSearch(filterSelected.value, valueFilter.value || valueFilter)}
        width='100%'
        height='100%'
      >
        <Flex width='100%' height='100%' flexDirection='column' justifyContent='space-between'>
          <Flex alignItems='center'>
            <Icon sizeIcon='display24' marginRight={2}>
              <IconSearch />
            </Icon>
            <Text>{t('general.advancedSearch')}</Text>
          </Flex>
          <Flex justifyContent='space-around' flexDirection={['column', 'column', 'row']}>
            <Box width='100%' padding={2}>
              <SelectInput
                id='advancedFacilitySearch'
                onChange={(name, value) => {
                  // Reset last Value filter
                  if (onSetValueFilter) onSetValueFilter('');
                  if (onSetFilterType) onSetFilterType({ label: value.label, value: value.value });
                }}
                options={filters?.map((option) => ({
                  value: option.filterType,
                  label: option.value ? option.value : t(`title.${option.localization}`),
                }))}
                placeholderMessage={t('general.selectOne')}
              />
            </Box>
          </Flex>
          <Flex justifyContent='space-around' flexDirection={['column', 'column', 'row']}>
            {!!filterTypeSelected?.value && filterByType?.length ? (
              <>
                <Box width='100%' padding={2}>
                  <SelectInput
                    id={`filter-${(filterTypeSelected?.label).toLowerCase()}`}
                    onChange={(name, value, other) => {
                      // Reset last Value filter
                      if (onSetValueFilter) onSetValueFilter('');
                      if (onSetFilter) onSetFilter({ label: value.label, value: value.value });
                    }}
                    options={filterByType?.map((option) => {
                      const nameValue = option.value
                        ? option.value
                        : t(`form.${option.localization}`);
                      return {
                        value: option.id,
                        name: nameValue,
                        label: nameValue,
                      };
                    })}
                    placeholderMessage={t('general.selectOne')}
                  />
                </Box>
                <Box width='100%' padding={2}>
                  {setFilterOptions(filterSelected.value)}
                </Box>
              </>
            ) : (
              <>
                {filterTypeSelected?.value === 5 || filterTypeSelected?.value === 6 ? (
                  <>
                    <Box width='100%' padding={2}>
                      <Input
                        id='valueFilter'
                        value={valueFilter}
                        onChange={(name, value) =>
                          onSetValueFilter(
                            filterTypeSelected?.value === 5
                              ? Math.abs(value.slice(0, 6)) || ''
                              : Math.abs(value.slice(0, 10)) || ''
                          )
                        }
                        placeholderMessage={
                          filterTypeSelected?.value === 5 ? t('form.fobCodeEnter') : t('form.code')
                        }
                        type='number'
                        min={1}
                        pattern={filterTypeSelected?.value === 5 ? 'd{1,5}' : 'd{1,10}'}
                        max={filterTypeSelected?.value === 5 ? 999999 : 9999999999}
                        required
                        maxLength={filterTypeSelected?.value === 5 ? '6' : '10'}
                      />
                    </Box>
                    <Box width='100%' padding={2} />
                  </>
                ) : (
                  <></>
                )}
              </>
            )}
          </Flex>
          <Box alignSelf='flex-end'>
            <Button
              type='submit'
              backgroundColor={tenants?.color1}
              action={() => handleSearch(filterSelected.value, valueFilter.value || valueFilter)}
              sizeButton='small'
              color={getContrastColor(tenants?.color1)}
              disabled={
                (filtersDisabled.includes(filterSelected.value) && !valueFilter) ||
                ((filterTypeSelected.value === 5 || filterTypeSelected.value === 6) &&
                  !valueFilter) ||
                (filterTypeSelected.value !== 5 &&
                  filterTypeSelected.value !== 6 &&
                  !filterSelected.value)
              }
            >
              {t('general.search')}
            </Button>
          </Box>
        </Flex>
      </Flex>
    </Card>
  );
};

export default DashboardAdvancedSearch;
