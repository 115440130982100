import {
  OWNERS_DELETE,
  OWNERS_GDPR_POST,
  OWNERS_LOGS_LIST,
  OWNERS_PENDING_LIST_GET,
  OWNERS_SINGLELIST_GET,
} from './constants';

export const getGDPRSingleList = (string) => ({ type: OWNERS_SINGLELIST_GET.BASE, string });
export const deleteOwner = (email) => ({ type: OWNERS_DELETE.BASE, email });
export const postOwnerGDPR = (gdprAgreement) => ({ type: OWNERS_GDPR_POST.BASE, gdprAgreement });
export const getGDPRPendingList = (string) => ({ type: OWNERS_PENDING_LIST_GET.BASE, string });
export const getAllGDPRLogs = (pageSize, pageNumber) => ({
  type: OWNERS_LOGS_LIST.BASE,
  pageSize,
  pageNumber,
});
