import styled, { css } from 'styled-components';
import { Flex } from '@jcm-technologies/uikit/dist/atoms/Layout';
import { theme } from '@jcm-technologies/uikit/dist/theme';

export const ItemStyled = styled(Flex)`
  background: ${(props) => (props.isActive && css ? theme.colors.blackLight : `transparent`)};

  &:hover {
    background: ${theme.colors.black};
  }
`;
