import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import 'moment/locale/es';
import 'moment/locale/ca';
import 'moment/locale/cs';
import 'moment/locale/en-gb';
import 'moment/locale/fr';
import 'moment/locale/it';
import 'moment/locale/de';
import { Box } from '@jcm-technologies/uikit/dist/atoms/Layout';
import { TabsMenu } from '@jcm-technologies/uikit/dist/molecules/TabsMenu';
import { View as CustomizationTabs } from './Customization/View';
import { List as LicensesView } from './Licenses/List';
import { View as HonoaView} from './Honoa/View';
import { View as HolidaysView} from './Holidays/View';
import { getHolidaysFull } from '../../modules/holidays/actions';
import { getContrastColor } from '../../core/helpers/colorManager';
import { Edit as EmployeesListEdit } from './Employees/Edit';

const View = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { tab } = useParams();
  const { t } = useTranslation();
  const tenants = useSelector((state) => state.tenants);
  const {
    user: { roleMask },
  } = useSelector((state) => state.user);
  const getFullHolidaysAPI = useCallback(() => dispatch(getHolidaysFull()));

  useEffect(() => {
    if (!tab && (roleMask === 128 || roleMask === 256 || roleMask === 512)) {
      history?.push('/administration/licenses');
    } else if (!tab) {
      history?.push('/administration/customization');
    }
    if (roleMask !== 128 && roleMask !== 256) {
      getFullHolidaysAPI();
    }
  }, []);

  const tabs = () => {
    const tabList = [];

    if (roleMask === 512) {
      tabList.push(
        {
          id: 'licenses',
          title: t('title.licenses'),
          action: () => history?.push('/administration/licenses'),
        },
        {
          id: 'holidays',
          title: t('title.holidaysDays'),
          action: () => history?.push('/administration/holidays'),
        }
      );
    } else if (roleMask !== 128 && roleMask !== 256) {
      tabList.push(
        {
          id: 'customization',
          title: t('title.customization'),
          action: () => history?.push('/administration/customization'),
        },
        {
          id: 'licenses',
          title: t('title.licenses'),
          action: () => history?.push('/administration/licenses'),
        },
        {
          id: 'users',
          title: t('title.users'),
          action: () => history?.push('/administration/users'),
        },
        {
          id: 'holidays',
          title: t('title.holidaysDays'),
          action: () => history?.push('/administration/holidays'),
        }
      );
    }

    if (roleMask === 128 || roleMask === 256 || roleMask === 2 || roleMask === 512) {
      tabList.push({
        id: 'honoa',
        title: t('title.honoa'),
        action: () => history?.push('/administration/honoa'),
      });
    }
    return tabList;
  };

  return (
    <Box
      backgroundColor='white'
      borderRadius={3}
      height='100%'
      minHeight='calc(100vh - 156px)'
      padding={2}
    >
      <TabsMenu
        id='administrationTab'
        colorActive={getContrastColor(tenants?.color1)}
        themeColor={tenants?.color1}
        defaultSelected={tab}
        components={{
          customization: <CustomizationTabs />,
          licenses: <LicensesView />,
          users: <EmployeesListEdit />,
          holidays: <HolidaysView />,
          honoa: <HonoaView />,
        }}
        items={tabs()}
      />
    </Box>
  );
};

export default View;
