import React, { Component } from 'react';
import { connect } from 'react-redux';
import StyledInput from './StyledInput';
import './FileInput.css';
import StyledLabel from './StyledLabel';

export class FileInput extends Component {
  render() {
    const { value, id, onChange, tenants, onFocus, onBlur, placeHolder, accept, className } =
      this.props;
    const text = value && value.split('\\')[value.split('\\').length - 1];
    return (
      <span className={`${className}`}>
        <StyledLabel htmlFor={id} tenants={tenants} className='custom-file-upload'>
          <i className='fa fa-cloud-upload' /> {placeHolder}
        </StyledLabel>
        <StyledInput
          type='file'
          tenants={tenants}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          accept={accept}
          id={id}
        />
        <label className='upload-lbl-wrapper inline'>{text}</label>
      </span>
    );
  }
}

const mapStateToProps = (state) => ({
  tenants: state.tenants,
});

export default connect(mapStateToProps, {})(FileInput);
