import { Box, Flex, Grid } from '@jcm-technologies/uikit/dist/atoms/Layout';
import { Text } from '@jcm-technologies/uikit/dist/atoms/Typography';
import { IconTooltip } from '@jcm-technologies/uikit/dist/molecules/IconTooltip';
import IconClone from '@jcm-technologies/uikit/dist/atoms/Icons/IconClone';
import IconTrash from '@jcm-technologies/uikit/dist/atoms/Icons/IconTrash';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

export function HeaderDetailSchedule({ setIsOpened, data, handleDuplicate }) {
  const history = useHistory();
  const { t } = useTranslation();
  const { color1 } = useSelector((state) => state.tenants);
  return (
    <Box
      borderBottomStyle='solid'
      borderBottomWidth={1}
      borderBottomColor={color1}
      paddingY={1}
      paddingX={2}
      style={{ cursor: 'pointer' }}
    >
      <Grid
        gridTemplateColumns='1fr 1fr auto'
        justifyContent='space-between'
        style={{ alignItems: 'center' }}
        gridGap={2}
      >
        <Text sizeText='display14' onClick={() => history.push(`/schedules`)} color='black'>
          {data.name}
        </Text>
        <Text sizeText='display14' color='blackLight' onClick={() => history.push(`/schedules`)}>
          {data.customerName}
        </Text>
        <Flex justifyContent='space-between' alignItems='center'>
          <IconTooltip
            zIndex={3}
            id='clone'
            sizeIcon='display16'
            cursor='pointer'
            color='blackLight'
            toolTipContent={t('general.clone')}
            tooltipProps={{
              place: 'top',
            }}
            onClick={() => {
              handleDuplicate();
              history.push(`/schedules/create`);
            }}
            marginX={1}
          >
            <IconClone />
          </IconTooltip>
          <IconTooltip
            zIndex={3}
            id='delete'
            sizeIcon='display16'
            cursor='pointer'
            color='blackLight'
            toolTipContent={t('general.delete')}
            tooltipProps={{
              place: 'top',
            }}
            onClick={() => {
              setIsOpened(true);
            }}
          >
            <IconTrash />
          </IconTooltip>
        </Flex>
      </Grid>
    </Box>
  );
}

HeaderDetailSchedule.propTypes = {
  setIsOpened: PropTypes.func.isRequired,
  data: PropTypes.shape({
    name: PropTypes.string,
    customerName: PropTypes.string,
  }).isRequired,
  handleDuplicate: PropTypes.func.isRequired,
};
