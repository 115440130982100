export default {
  compareMemory: {
    nodata: 'Žádná dostupná data v této tabulce',
    filter: 'Filtr',
    foundInBoth: 'Nalezeno na obou stránkách',
    foundInBBDD: 'Nalezeno v databázi',
    foundInMemory: 'Nalezeno v paměti',
    refreshMemory: 'Aktualizovat paměť',
    deleteBBDD: 'Vymazat z databáze',
    addBBDD: 'Přidat do databáze',
    confirmDelete: 'Chcete nahradit tyto ovladače?',
    reserved: 'Rezervováno',
  },
};
