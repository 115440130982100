import createFetchTypes from '../../../core/typeActions/types';

const GET_INSTALATION_GROUPS = 'GET_INSTALATION_GROUPS';
const GET_SECONDARY_INSTALATION_GROUPS = 'GET_SECONDARY_INSTALATION_GROUPS';
const GET_INSTALATION_FOBS_COUNTS = 'GET_INSTALATION_FOBS_COUNTS';
const GET_INSTALATION_GROUP_UNIVERSALS = 'GET_INSTALATION_GROUP_UNIVERSALS';
const PUT_INSTALATION_GROUPS = 'PUT_INSTALATION_GROUPS';
const POST_INSTALATION_GROUPS = 'POST_INSTALATION_GROUPS';
const DEL_INSTALATION_GROUP = 'DEL_INSTALATION_GROUP';
const GET_INSTALATION_GROUP = 'GET_INSTALATION_GROUP';
const RESET_INSTALATION_GROUP = 'RESET_INSTALATION_GROUP';
const RESET_INSTALATION_GROUPS = 'RESET_INSTALATION_GROUPS';
const GET_INSTALATION_GROUPS_ASSIGNED = 'GET_INSTALATION_GROUPS_ASSIGNED';

export const GET_GROUP_FOB_HISTORY = createFetchTypes('GET_GROUP_FOB_HISTORY');
export default {
  GET_INSTALATION_GROUPS,
  GET_SECONDARY_INSTALATION_GROUPS,
  PUT_INSTALATION_GROUPS,
  POST_INSTALATION_GROUPS,
  DEL_INSTALATION_GROUP,
  RESET_INSTALATION_GROUP,
  RESET_INSTALATION_GROUPS,
  GET_INSTALATION_FOBS_COUNTS,
  GET_INSTALATION_GROUP_UNIVERSALS,
  GET_INSTALATION_GROUP,
  GET_INSTALATION_GROUPS_ASSIGNED,
};

/**
 * Sets the form state
 * @param  {int} value                The value for show loading
 * @return {object}                   Formatted action for the reducer to handle
 */
export const getInstalationGroups = (instalationID) => ({
  type: GET_INSTALATION_GROUPS,
  instalationID,
});

export const getInstalationGroup = (facilityId, groupId) => ({
  type: GET_INSTALATION_GROUP,
  facilityId,
  groupId,
});

export const resetInstalationGroups = () => ({ type: RESET_INSTALATION_GROUPS });

export const resetInstalationGroup = () => ({ type: RESET_INSTALATION_GROUP });

export const getSecondaryInstalationGroups = (instalationID, groupId) => ({
  type: GET_SECONDARY_INSTALATION_GROUPS,
  instalationID,
  groupId,
});

export const putInstalationGroup = (instalationGroup) => ({
  type: PUT_INSTALATION_GROUPS,
  instalationGroup,
});

export const postInstalationGroup = (instalationGroup) => ({
  type: POST_INSTALATION_GROUPS,
  instalationGroup,
});

export const deleteInstalationGroup = (facilityGroup) => ({
  type: DEL_INSTALATION_GROUP,
  facilityGroup,
});

export const getInstalationGroupsFobsCounts = (instalationID, instalationGroupID) => ({
  type: GET_INSTALATION_FOBS_COUNTS,
  instalationID,
  instalationGroupID,
});

export const getInstalationGroupsAssigned = (facilityId, equipmentId) => ({
  type: GET_INSTALATION_GROUPS_ASSIGNED,
  facilityId,
  equipmentId,
});

export const getGroupFobHistory = (facilityId, facilityGroupId, groupFobId) => ({
  type: GET_GROUP_FOB_HISTORY.BASE,
  facilityId,
  facilityGroupId,
  groupFobId,
});
