export default {
  instalationGroups: {
    exportExcel: 'Téléchargez Excel',
    importExcel: 'Importer Excel',
    exportPDF: 'Télécharger le PDF',
    identification: "Carte d'identité",
    place: 'Place',
    enrollFOB: 'Inscrire le code',
    config: 'Configurer',
    deleteConfirmation: 'Voulez-vous vraiment supprimer ce groupe?',
    description: 'La description',
    facilityName: "Nom de l'installation",
    replace: 'Remplacer',
    delete: 'Supprimer',
    deleteFobConfirmation: 'Êtes-vous sûr de vouloir supprimer ce émetteur?',
    alarms: 'Alarme',
    selectConfigFields: "Sélectionnez les CHAMPS D'UTILISATEUR / ÉMETTEURS à exporter",
    totalSelected: 'Total sélectionné',
  },
};
