import popupActions from '../actions/popUp';

/* Redux state init */
const initialState = {
  isOpenAuth: false,
  text: '',
  onAcceptFn: null,
  onCancelFn: null,
};

export default function popUpReducer(state = initialState, action) {
  if (action.type !== undefined) {
    switch (action.type) {
      case popupActions.POPUPSETTINGS_SET: {
        return { ...state, ...action.payload };
      }
      case popupActions.RESET_AUTHPOPUP: {
        return { ...state, isOpenAuth: false };
      }
      default:
        return state;
    }
  }
  return state;
}
