import { call, fork, takeLatest } from 'redux-saga/effects';
import equipmentsActions, { GET_EQUIPMENTS_WIEGAND_CONFIG } from '../actions/equipments';
import fetchEntitySaga from './utils/fetchEntitySaga';
import apiPathFactory from '../../../core/old_common/rooting/apiPathFactory';
import api from '../../../core/helpers/api';
import {
  EQUIPMENT_GET,
  EQUIPMENT_IS_UPDATED_PUT,
  EQUIPMENTS_ACTIONS_GET,
  EQUIPMENTS_CONNECTIVITY_GET,
  EQUIPMENTS_COUNTERS_GET,
  EQUIPMENTS_DEL,
  EQUIPMENTS_EVENTS_DELETE,
  EQUIPMENTS_EVENTS_GET,
  EQUIPMENTS_FOBS_GET,
  EQUIPMENTS_LIST_GET,
  EQUIPMENTS_POST,
  EQUIPMENTS_PUT,
  EQUIPMENTS_RELAY_PUT,
  EQUIPMENTS_RELAYS_GET,
  EQUIPMENTS_SIM_GET,
  EQUIPMENTS_VERTEX_CONNECTIVITY_GET,
} from '../reducers/equipments';

import { GET_STATISTICS_EQUIPMENT } from '../../statistics/constants';

export const getEquipmentRelays = {
  base: (facilityId, equipmentId) => ({
    type: EQUIPMENTS_RELAYS_GET.BASE,
    facilityId,
    equipmentId,
  }),
  request: () => ({ type: EQUIPMENTS_RELAYS_GET.REQUEST }),
  success: (result) => ({ type: EQUIPMENTS_RELAYS_GET.SUCCESS, result }),
  failure: (error) => ({ type: EQUIPMENTS_RELAYS_GET.FAILURE, error }),
};

export const getEquipment = {
  base: (facilityId, id, include) => ({
    type: EQUIPMENT_GET.BASE,
    facilityId,
    id,
    include,
  }),
  request: () => ({ type: EQUIPMENT_GET.REQUEST }),
  success: (result) => ({ type: EQUIPMENT_GET.SUCCESS, result }),
  failure: (error) => ({ type: EQUIPMENT_GET.FAILURE, error }),
};

export const getEquipmentCounters = {
  base: () => ({
    type: EQUIPMENTS_COUNTERS_GET.BASE,
  }),
  request: () => ({ type: EQUIPMENTS_COUNTERS_GET.REQUEST }),
  success: (result) => ({ type: EQUIPMENTS_COUNTERS_GET.SUCCESS, result }),
  failure: (error) => ({ type: EQUIPMENTS_COUNTERS_GET.FAILURE, error }),
};

export const getEquipmentConnectivity = {
  base: (facilityId, equipmentId) => ({
    type: EQUIPMENTS_CONNECTIVITY_GET.BASE,
    facilityId,
    equipmentId,
  }),
  request: () => ({ type: EQUIPMENTS_CONNECTIVITY_GET.REQUEST }),
  success: (result, payload) => ({ type: EQUIPMENTS_CONNECTIVITY_GET.SUCCESS, result, payload }),
  failure: (error) => ({ type: EQUIPMENTS_CONNECTIVITY_GET.FAILURE, error }),
};

export const getEquipmentActions = {
  base: (facilityId, equipmentId) => ({
    type: EQUIPMENTS_ACTIONS_GET.BASE,
    facilityId,
    equipmentId,
  }),
  request: () => ({ type: EQUIPMENTS_ACTIONS_GET.REQUEST }),
  success: (result) => ({ type: EQUIPMENTS_ACTIONS_GET.SUCCESS, result }),
  failure: (error) => ({ type: EQUIPMENTS_ACTIONS_GET.FAILURE, error }),
};

export const putEquipmentRelay = {
  base: (facilityId, equipmentId, relayId) => ({
    type: EQUIPMENTS_RELAY_PUT.BASE,
    facilityId,
    equipmentId,
    relayId,
  }),
  request: () => ({ type: EQUIPMENTS_RELAY_PUT.REQUEST }),
  success: (result) => ({ type: EQUIPMENTS_RELAY_PUT.SUCCESS, result }),
  failure: (error) => ({ type: EQUIPMENTS_RELAY_PUT.FAILURE, error }),
};

export const getEquipmentsList = {
  base: (facilityId) => ({
    type: EQUIPMENTS_LIST_GET.BASE,
    facilityId,
  }),
  request: () => ({ type: EQUIPMENTS_LIST_GET.REQUEST }),
  success: (result) => ({ type: EQUIPMENTS_LIST_GET.SUCCESS, result }),
  failure: (error) => ({ type: EQUIPMENTS_LIST_GET.FAILURE, error }),
};

export const getEquipmentsFobsList = {
  base: (facilityId, equipmentId) => ({
    type: EQUIPMENTS_FOBS_GET.BASE,
    facilityId,
    equipmentId,
  }),
  request: () => ({ type: EQUIPMENTS_FOBS_GET.REQUEST }),
  success: (result) => ({ type: EQUIPMENTS_FOBS_GET.SUCCESS, result }),
  failure: (error) => ({ type: EQUIPMENTS_FOBS_GET.FAILURE, error }),
};

export const getEquipmentsEvents = {
  base: (facilityId, equipmentId) => ({
    type: EQUIPMENTS_EVENTS_GET.BASE,
    facilityId,
    equipmentId,
  }),
  request: () => ({ type: EQUIPMENTS_EVENTS_GET.REQUEST }),
  success: (result) => ({ type: EQUIPMENTS_EVENTS_GET.SUCCESS, result }),
  failure: (error) => ({ type: EQUIPMENTS_EVENTS_GET.FAILURE, error }),
};

export const deleteEquipmentsEvents = {
  base: (facilityId, equipmentId) => ({
    type: EQUIPMENTS_EVENTS_DELETE.BASE,
    facilityId,
    equipmentId,
  }),
  request: () => ({ type: EQUIPMENTS_EVENTS_DELETE.REQUEST }),
  success: (result) => ({ type: EQUIPMENTS_EVENTS_DELETE.SUCCESS, result }),
  failure: (error) => ({ type: EQUIPMENTS_EVENTS_DELETE.FAILURE, error }),
};

export const deleteEquipment = {
  base: (facilityId, equipmentId) => ({
    type: EQUIPMENTS_DEL.BASE,
    facilityId,
    equipmentId,
  }),
  request: () => ({ type: EQUIPMENTS_DEL.REQUEST }),
  success: (result) => ({ type: EQUIPMENTS_DEL.SUCCESS, result }),
  failure: (error) => ({ type: EQUIPMENTS_DEL.FAILURE, error }),
};

export const postEquipment = {
  base: (facilityId, equipment) => ({
    type: EQUIPMENTS_POST.BASE,
    facilityId,
    equipment,
  }),
  request: () => ({ type: EQUIPMENTS_POST.REQUEST }),
  success: (result) => ({ type: EQUIPMENTS_POST.SUCCESS, result }),
  failure: (error) => ({ type: EQUIPMENTS_POST.FAILURE, error }),
};

export const putEquipment = {
  base: (facilityId, equipment, tabType) => ({
    type: EQUIPMENTS_PUT.BASE,
    facilityId,
    equipment,
    tabType,
  }),
  request: (facilityId, equipment, tabType) => ({
    type: EQUIPMENTS_PUT.REQUEST,
    facilityId,
    equipment,
    tabType,
  }),
  success: (result, payload) => ({ type: EQUIPMENTS_PUT.SUCCESS, result, payload }),
  failure: (error) => ({ type: EQUIPMENTS_PUT.FAILURE, error }),
};

export const putEquipmentIsUpdated = {
  base: (facilityId, equipment) => ({
    type: EQUIPMENT_IS_UPDATED_PUT.BASE,
    facilityId,
    equipment,
  }),
  request: () => ({ type: EQUIPMENT_IS_UPDATED_PUT.REQUEST }),
  success: (result, payload) => ({ type: EQUIPMENT_IS_UPDATED_PUT.SUCCESS, result, payload }),
  failure: (error) => ({ type: EQUIPMENT_IS_UPDATED_PUT.FAILURE, error }),
};

export const getEquipmentVertexConnectivity = {
  base: (facilityId, equipmentId, key) => ({
    type: EQUIPMENTS_VERTEX_CONNECTIVITY_GET.BASE,
    facilityId,
    equipmentId,
    key,
  }),
  request: () => ({ type: EQUIPMENTS_VERTEX_CONNECTIVITY_GET.REQUEST }),
  success: (result, payload) => ({
    type: EQUIPMENTS_VERTEX_CONNECTIVITY_GET.SUCCESS,
    result,
    payload,
  }),
  failure: (error) => ({ type: EQUIPMENTS_VERTEX_CONNECTIVITY_GET.FAILURE, error }),
};

export const getEquipmentSim = {
  base: (facilityId, equipmentId) => ({
    type: EQUIPMENTS_SIM_GET.BASE,
    facilityId,
    equipmentId,
  }),
  request: () => ({ type: EQUIPMENTS_SIM_GET.REQUEST }),
  success: (result, payload) => ({ type: EQUIPMENTS_SIM_GET.SUCCESS, result, payload }),
  failure: (error) => ({ type: EQUIPMENTS_SIM_GET.FAILURE, error }),
};

export const getStatisticsEquipment = {
  base: (facilityId, equipmentId) => ({
    type: GET_STATISTICS_EQUIPMENT.BASE,
    facilityId,
    equipmentId,
  }),
  request: () => ({ type: GET_STATISTICS_EQUIPMENT.REQUEST }),
  success: (result) => ({ type: GET_STATISTICS_EQUIPMENT.SUCCESS, result }),
  failure: (error) => ({ type: GET_STATISTICS_EQUIPMENT.FAILURE, error }),
};

export const getEquipmentWiegandConfigs = {
  base: (facilityId, equipmentId) => ({
    type: GET_EQUIPMENTS_WIEGAND_CONFIG.BASE,
    facilityId,
    equipmentId,
  }),
  request: () => ({ type: GET_EQUIPMENTS_WIEGAND_CONFIG.REQUEST }),
  success: (result) => ({ type: GET_EQUIPMENTS_WIEGAND_CONFIG.SUCCESS, result }),
  failure: (error) => ({ type: GET_EQUIPMENTS_WIEGAND_CONFIG.FAILURE, error }),
};

export function* getEquipmentRelaysSaga({ facilityId, equipmentId }) {
  try {
    const url = apiPathFactory.equipmentsRelaysGet(facilityId, equipmentId);
    const response = yield call(() => fetchEntitySaga(getEquipmentRelays, api.get, url));
    return response;
  } catch (error) {
    return error;
  }
}

export function* getEquipmentCountersSaga({ facilityId, equipmentId }) {
  try {
    const url = apiPathFactory.equipmentsGetCounters(facilityId, equipmentId);
    const response = yield call(() => fetchEntitySaga(getEquipmentCounters, api.get, url));
    return response;
  } catch (error) {
    return error;
  }
}

export function* getEquipmentConnectivitySaga({ facilityId, equipmentId }) {
  try {
    const url = apiPathFactory.equipmentGetConnectivity(facilityId, equipmentId);
    const response = yield call(() =>
      fetchEntitySaga(getEquipmentConnectivity, api.get, url, { equipmentId })
    );
    return response;
  } catch (error) {
    return error;
  }
}

export function* getEquipmentActionsSaga({ facilityId, equipmentId }) {
  try {
    const url = apiPathFactory.equipmentGetActions(facilityId, equipmentId);
    const response = yield call(() => fetchEntitySaga(getEquipmentActions, api.get, url));
    return response;
  } catch (error) {
    return error;
  }
}

export function* putEquipmentRelaySaga({ facilityId, equipmentId, relayId, relay }) {
  try {
    const url = apiPathFactory.equipmentRelayPut(facilityId, equipmentId, relayId);
    const response = yield call(() =>
      fetchEntitySaga(putEquipmentRelay, api.put, url, { data: relay })
    );
    return response;
  } catch (error) {
    return error;
  }
}

export function* getEquipmentsListSaga({ facilityId, include }) {
  try {
    const url = apiPathFactory.equipmentsGet(facilityId, include);
    const response = yield call(() => fetchEntitySaga(getEquipmentsList, api.get, url));
    return response;
  } catch (error) {
    return error;
  }
}

export function* getEquipmentSaga({ facilityId, id, include }) {
  try {
    const url = apiPathFactory.equipmentGet(facilityId, id, include);
    const response = yield call(() => fetchEntitySaga(getEquipment, api.get, url));
    return response;
  } catch (error) {
    return error;
  }
}

export function* deleteEquipmentSaga({ facilityId, equipmentId }) {
  try {
    const url = apiPathFactory.equipmentsDelete(facilityId, equipmentId);
    const response = yield call(() =>
      fetchEntitySaga(deleteEquipment, api.del, url, { toastNotShow: false })
    );
    yield call(getEquipmentsListSaga, { facilityId });
    return response;
  } catch (error) {
    return error;
  }
}

export function* getEquipmentFobsSaga({ facilityId, equipmentId }) {
  try {
    const url = apiPathFactory.equipmentsGetFobs(facilityId, equipmentId);
    const response = yield call(() => fetchEntitySaga(getEquipmentsFobsList, api.get, url));
    return response;
  } catch (error) {
    return error;
  }
}

export function* getEquipmentEventsSaga({ facilityId, equipmentId }) {
  try {
    const url = apiPathFactory.equipmentsGetEvents(facilityId, equipmentId);
    const response = yield call(() => fetchEntitySaga(getEquipmentsEvents, api.get, url));
    return response;
  } catch (error) {
    return error;
  }
}

export function* deleteEquipmentEventsSaga({ facilityId, equipmentId }) {
  try {
    const url = apiPathFactory.equipmentsDeleteEvents(facilityId, equipmentId);
    const response = yield call(() => fetchEntitySaga(deleteEquipmentsEvents, api.del, url));
    return response;
  } catch (error) {
    return error;
  }
}

export function* postEquipmentSaga({ facilityId, equipment }) {
  try {
    const url = apiPathFactory.equipmentPost(facilityId);
    const response = yield call(() =>
      fetchEntitySaga(postEquipment, api.post, url, { data: equipment })
    );
    yield call(getEquipmentSaga, { facilityId, id: response?.id, include: true });

    return response;
  } catch (error) {
    return error;
  }
}

export function* putEquipmentSaga({ facilityId, equipment, tabType }) {
  try {
    const url = apiPathFactory.equipmentPut(facilityId, equipment.id);
    const response = yield call(() =>
      fetchEntitySaga(putEquipment, api.put, url, { data: equipment })
    );
    return response;
  } catch (error) {
    return error;
  }
}

export function* putEquipmentIsUpdatedSaga({ facilityId, equipmentId }) {
  try {
    const url = apiPathFactory.equipmentPutIsUpdated(facilityId, equipmentId);
    const response = yield call(() =>
      fetchEntitySaga(putEquipmentIsUpdated, api.put, url, { toastNotShow: true })
    );
    yield call(getEquipmentsListSaga, { facilityId, include: true });
    return response;
  } catch (error) {
    return error;
  }
}

export function* getEquipmentVertexConnectivitySaga({ facilityId, equipmentId, key }) {
  try {
    const url = apiPathFactory.equipmentGetVertexConnectivity(facilityId, equipmentId, key);
    const response = yield call(() =>
      fetchEntitySaga(getEquipmentVertexConnectivity, api.get, url, { equipmentId })
    );
    return response;
  } catch (error) {
    return error;
  }
}

export function* getEquipmentSimSaga({ facilityId, equipmentId }) {
  try {
    const url = apiPathFactory.equipmentGetSim(facilityId, equipmentId);
    const response = yield call(() =>
      fetchEntitySaga(getEquipmentSim, api.get, url, { toastNotShow: true })
    );
    return response;
  } catch (error) {
    return error;
  }
}

export function* getStatisticsEquipmentSaga({ facilityId, equipmentId }) {
  try {
    const url = apiPathFactory.getStatisticsEquipment(facilityId, equipmentId);
    const response = yield call(() => fetchEntitySaga(getStatisticsEquipment, api.get, url));
    return response;
  } catch (error) {
    return error;
  }
}

export function* getEquipmentWiegandConfigsSaga({ facilityId, equipmentId }) {
  try {
    const url = apiPathFactory.equipmentWiegandConfiguration(facilityId, equipmentId);
    const response = yield call(() => fetchEntitySaga(getEquipmentWiegandConfigs, api.get, url));
    return response;
  } catch (error) {
    return error;
  }
}

function* watchGetStatisticsEquipment() {
  yield takeLatest(GET_STATISTICS_EQUIPMENT.BASE, getStatisticsEquipmentSaga);
}

function* watchGetEquipmentVertexConnectivity() {
  yield takeLatest(
    equipmentsActions.GET_EQUIPMENT_VERTEX_CONNECTIVITY,
    getEquipmentVertexConnectivitySaga
  );
}

function* watchPutEquipmentIsUpdated() {
  yield takeLatest(equipmentsActions.PUT_EQUIPMENT_IS_UPDATED, putEquipmentIsUpdatedSaga);
}

function* watchGetEquipmentsRelay() {
  yield takeLatest(equipmentsActions.GET_EQUIPMENT_RELAYS, getEquipmentRelaysSaga);
}

function* watchGetEquipmentConnectivity() {
  yield takeLatest(equipmentsActions.GET_EQUIPMENT_CONNECTIVITY, getEquipmentConnectivitySaga);
}

function* watchPutEquipmentRelaySaga() {
  yield takeLatest(equipmentsActions.PUT_EQUIPMENT_RELAY, putEquipmentRelaySaga);
}

function* watchDelEquipments() {
  yield takeLatest(equipmentsActions.DELETE_EQUIPMENT, deleteEquipmentSaga);
}

function* watchGetEquipmentsList() {
  yield takeLatest(equipmentsActions.GET_EQUIPMENT_LIST, getEquipmentsListSaga);
}

function* watchPostEquipments() {
  yield takeLatest(equipmentsActions.POST_EQUIPMENT, postEquipmentSaga);
}

function* watchPutEquipments() {
  yield takeLatest(equipmentsActions.PUT_EQUIPMENT, putEquipmentSaga);
}

function* watchGetEquipmentsFobsList() {
  yield takeLatest(equipmentsActions.GET_EQUIPMENT_FOBS, getEquipmentFobsSaga);
}

function* watchGetEquipmentsActionsList() {
  yield takeLatest(equipmentsActions.GET_EQUIMENT_ACTIONS, getEquipmentActionsSaga);
}

function* watchGetEquipmentById() {
  yield takeLatest(equipmentsActions.GET_EQUIPMENT, getEquipmentSaga);
}

function* watchGetEquipmentCounters() {
  yield takeLatest(equipmentsActions.GET_EQUIPMENT_COUNTERS, getEquipmentCountersSaga);
}

function* watchGetEquipmentSim() {
  yield takeLatest(equipmentsActions.GET_EQUIPMENT_SIM, getEquipmentSimSaga);
}

function* watchGetEquipmentsEventsList() {
  yield takeLatest(equipmentsActions.GET_EQUIPMENT_EVENTS, getEquipmentEventsSaga);
}

function* watchDeleteEquipmentsEventsList() {
  yield takeLatest(equipmentsActions.DELETE_EQUIPMENT_EVENTS, deleteEquipmentEventsSaga);
}

function* watchGetEquipmentWiegandConfigs() {
  yield takeLatest(GET_EQUIPMENTS_WIEGAND_CONFIG.BASE, getEquipmentWiegandConfigsSaga);
}

export default function* watchers() {
  yield fork(watchGetStatisticsEquipment);
  yield fork(watchGetEquipmentsList);
  yield fork(watchDelEquipments);
  yield fork(watchPostEquipments);
  yield fork(watchPutEquipments);
  yield fork(watchGetEquipmentsFobsList);
  yield fork(watchPutEquipmentRelaySaga);
  yield fork(watchGetEquipmentsRelay);
  yield fork(watchGetEquipmentsActionsList);
  yield fork(watchGetEquipmentConnectivity);
  yield fork(watchGetEquipmentVertexConnectivity);
  yield fork(watchGetEquipmentById);
  yield fork(watchGetEquipmentCounters);
  yield fork(watchPutEquipmentIsUpdated);
  yield fork(watchGetEquipmentSim);
  yield fork(watchGetEquipmentsEventsList);
  yield fork(watchDeleteEquipmentsEventsList);
  yield fork(watchGetEquipmentWiegandConfigs);
}
