export default {
  changePassword: {
    title: 'Change Password',
    submitText: 'Change password',
    currentPassword: 'Current password',
    newPassword: 'New password',
    confirmPassword: 'Confirm password',
    cancel: 'Cancel',
    chooseFile: 'Choose file',
    newPasswordPopupTitle: 'Set new password',
  },
};
