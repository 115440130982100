export default {
  enrollCode: {
    manualTab: 'Manual',
    reservationsTab: 'Reserve',
    sequentialTab: 'Sequential',
    universalsTab: 'Universal',
    device: 'Device',
    initialCode: 'Initial Code',
    idCard: 'DNI',
    spot: 'Slot',
    numberOfFobs: 'Number of fobs',
    endCode: 'End code',
    cantEnroll:
      'For enrolling new codes a device must be chosen and available positions are needed ',
    cancel: 'Cancel',
    accept: 'Accept',
    ok: 'OK',
    forceCodeQuestion: 'Do you want to force the registration of this code?',
    codeAlreadyExist: 'This code already exists in another group',
    notAsigned: 'Not assigned',
    reserved: 'Reserved',
  },
};
