export default {
  descriptionParams: {
    ACCESSCONTROL: "Indica si la centraleta té entrada per a dispositius de control d'accés",
    ACTIVEEVENTSTOSD: 'Especifica els esdeveniments actius per al dispositiu',
    APNCONFIG: "Configura l'APN del dispositiu",
    BADGE_MAITRE1: "Codi de l'element de proximitat utilitzat com a clau mestra",
    BADGE_MAITRE10: "Codi de l'element de proximitat utilitzat com a clau mestra",
    BADGE_MAITRE2: "Codi de l'element de proximitat utilitzat com a clau mestra",
    BADGE_MAITRE3: "Codi de l'element de proximitat utilitzat com a clau mestra",
    BADGE_MAITRE4: "Codi de l'element de proximitat utilitzat com a clau mestra",
    BADGE_MAITRE5: "Codi de l'element de proximitat utilitzat com a clau mestra",
    BADGE_MAITRE6: "Codi de l'element de proximitat utilitzat com a clau mestra",
    BADGE_MAITRE7: "Codi de l'element de proximitat utilitzat com a clau mestra",
    BADGE_MAITRE8: "Codi de l'element de proximitat utilitzat com a clau mestra",
    BADGE_MAITRE9: "Codi de l'element de proximitat utilitzat com a clau mestra",
    CONFIGRELAYSNEW1: "Indica els relés que s'activaran segons el canal enviat pel dispositiu",
    CONFIGRELAYSNEW2: "Indica els relés que s'activaran segons el canal enviat pel dispositiu",
    CONFIGRELAYSNEW3: "Indica els relés que s'activaran segons el canal enviat pel dispositiu",
    CONFIGRELAYSNEW4: "Indica els relés que s'activaran segons el canal enviat pel dispositiu",
    CONFIGRELAYSPIN1: "Indica els relés que s'activaran segons el canal enviat pel dispositiu",
    CONFIGRELAYSPIN2: "Indica els relés que s'activaran segons el canal enviat pel dispositiu",
    CONFIGRELAYSPIN3: "Indica els relés que s'activaran segons el canal enviat pel dispositiu",
    CONFIGRELAYSPIN4: "Indica els relés que s'activaran segons el canal enviat pel dispositiu",
    DISABLEMANUALPROG: "Permet donar d'alta dispositius manualment",
    DISABLERADIOPROG:
      "Impedir que es pugui donar d'alta emissors utilitzant el sistema de programació via radio (mode estàndard)",
    FREEACTIVE: 'Activa/Desactiva les característiques del grup del sistema FREE',
    FREEGROUP0LOCKED: 'Bloqueja/Desbloqueja el grup 0 del sistema FREE',
    FREEGROUPNUM: 'Estableix el número de grup del sistema FREE',
    FREEGROUPRFPROGALLOWED:
      'Habilita/Deshabilita la programació via radio pel grup del sistema FREE',
    IMPBIRELE1: "Canvia el funcionament del relé 1 d'impulsional a biestable",
    INSTAL: 'Indica si treballa amb codi instal·lador',
    MINIDDEVICE: "L'equip acceptarà dispositius com els indicats o superiors",
    PASSWORD: "Contrasenya de 4 dígits d'accés al menú de la centraleta",
    PIN: 'Codi numèric de 6 dígits corresponent al PIN del receptor',
    PINCADUCAT: 'Indica si el receptor accepta dispositius amb el codi PIN caducat',
    POWER: "Indica el tipus d'alimentació de l'equip",
    RELAYPRICE1: "Valor que restarà del crèdit d'un dispositiu de prepagament 1",
    RELAYPRICE2: "Valor que restarà del crèdit d'un dispositiu de prepagament 2",
    RELAYPRICE3: "Valor que restarà del crèdit d'un dispositiu de prepagament 3",
    RELAYPRICE4: "Valor que restarà del crèdit d'un dispositiu de prepagament 4",
    RELAYS: 'Nombre de relés del receptor',
    RELE1IMP: 'Configura el relé com a impulsional, biestable o antipànic',
    RELE1TEMP:
      'Configura el temps que romadrà actiu el relé en mode impulsional (de 1 a 30 seg) o antipànic (de 1 a 15 min)',
    RELE2IMP: 'Configura el relé com a impulsional, biestable o antipànic',
    RELE2TEMP:
      'Configura el temps que romandrà actiu el relé en mode impulsional (de 1 a 30 seg) o antipànic (de 1 a 15 min)',
    RELE3IMP: 'Configura el relé com a impulsional, biestable o antipànic',
    RELE3TEMP:
      'Configura el temps que romandrà actiu el relé en mode impulsional (de 1 a 30 seg) o antipànic (de 1 a 15 min)',
    RELE4IMP: 'Configura el relé com a impulsional, biestable o antipànic',
    RELE4TEMP:
      'Configura el temps que romandrà actiu el relé en mode impulsional (de 1 a 30 seg) o antipànic (de 1 a 15 min)',
    SERIALNUMBER: 'Número de sèrie obligatori per a accions remotes',
    SITECODE: "Codi d'instalació que s'enviarà en el protocolo Wiegand 26 amb Sitecode",
    TEVENT: "Indica si la centraleta pot portar targeta d'esdeveniments",
    TIMEZONEID: "Especifica la zona horària on es troba l'equip",
    TMEM: 'Indica si la centraleta funciona amb targeta de memòria',
    UNIQUEKEY: 'Clau única obligatòria per a accions remotes',
    W26FORMAT: 'Configura el format de transmissió de protocol Wiegand 26',
    SETNEWACCESSPW: 'Canvia la contrasenya',
    SERIALNUMBERLINK: 'Número de sèrie obligatori per a accions remotes',
    CONNECTIVITY_EXTENSION: 'Accessori LINK instal·lat',
    ACTIVATIONKEY: "Clau d'activació",
    EMMITTERNOTUSEDEVENTTIME: "No utilitzeu l'esdeveniment temps de l'emissor",
    DOOROPENEDEVENTTIME: "Esdeveniment d'obertura de porta",
    SCHEDULESDATA: 'Dades horàries',
    A5KPROXY: 'Access 5k Proxy',
    WIEGANDCONFIG: 'Personalitza la configuració Wiegand a fer servir',
    ALLOWNOTIFICATIONS: 'HONOA Mostra estat de porta',
    LOCALEVENTMANAGEMENTCONFIG: 'Configuració d’events',
  },
};
