export default {
  instalationGroups: {
    exportExcel: 'Stáhnout Excel',
    importExcel: 'Importovat Excel',
    exportPDF: 'Stáhnout PDF',
    identification: 'Číslo OP',
    place: 'Slot',
    enrollFOB: 'Nahrát ovladač/čip',
    config: 'Konfigurovat',
    deleteConfirmation: 'Jsi si jistý, že chceš vymazat tuto skupinu?',
    description: 'Popis',
    facilityName: 'Název instalace',
    replace: 'Nahradit',
    delete: 'Smazat',
    deleteFobConfirmation: 'Jsi si jistý, že chceš vymazat tento ovladač?',
    alarms: 'Alarm',
    selectConfigFields: 'Zvolte UŽIVATELSKÁ POLE / OVLADAČE k exportu',
    totalSelected: 'Celkem vybráno',
  },
};
