import PathEntity from './PathEntity';
import { apiVersion, mainPath } from '../common';

class Groups extends PathEntity {
  constructor() {
    super('search/groups');
  }

  getGroupsFobsHistory = (pageSize, pageNumber, searchQuery) => {
    this.mainPath = `${mainPath()}4.1`;
    const finalEndpoint = this.manufacturePath(
      `${this.entityName}/${searchQuery.searchQuery}/history`
    );
    this.mainPath = `${mainPath()}${apiVersion}`;
    return this.pagedEndPoint(finalEndpoint, pageSize, pageNumber);
  };

  getPaths = () => ({
    getGroupList: this.getPagedWithTextToSearch,
    getGroupsFobsHistory: this.getGroupsFobsHistory,
  });
}

export default new Groups().getPaths();
