import PathEntity from '../../core/old_common/rooting/apiPaths/PathEntity';
import { getCookie_pageNumbers, getCookiePageSizeItems } from '../../core/old_common/utils/cookiesManager';
import { apiVersion, mainPath } from '../../core/old_common/rooting/common';

class Routes extends PathEntity {
  constructor() {
    super('dashboard');
  }

  getDashboardInfo = () => {
    this.mainPath = `${mainPath()}4.1`;
    const endpoint = this.manufacturePath(this.entityName);
    this.mainPath = `${mainPath()}${apiVersion}`;
    return endpoint;
  };

  getDashboardFilter = () => {
    this.mainPath = `${mainPath()}4.2`;
    const endpoint = this.manufacturePath(`${this.entityName}/filters/type`);
    this.mainPath = `${mainPath()}${apiVersion}`;
    return endpoint;
  };

  getDashboardFilterByType = (type) => {
    this.mainPath = `${mainPath()}4.2`;
    const endpoint = this.manufacturePath(`${this.entityName}/filters/type/${type}`);
    this.mainPath = `${mainPath()}${apiVersion}`;
    return endpoint;
  };

  getDashboardEquipmentSearch = (fieldId, searchText) => {
    let endpointFinal = this.manufacturePath(`search/equipments`);
    const pageSize = getCookiePageSizeItems();
    const pageNumber = getCookie_pageNumbers()?.EQUIPMENT_LIST || 1;
    endpointFinal = this.pagedEndPoint(endpointFinal, pageSize, pageNumber);
    if (fieldId) {
      endpointFinal = `${endpointFinal}&fieldId=${fieldId}`;
    }
    if (searchText) {
      endpointFinal = `${endpointFinal}&textToSearch=${searchText}`;
    }
    return endpointFinal;
  };

  getPaths = () => ({
    dashboardFilter: this.getDashboardFilter,
    getDashboardInfo: this.getDashboardInfo,
    getDashboardFilterByType: this.getDashboardFilterByType,
    getDashboardEquipmentSearch: this.getDashboardEquipmentSearch,
  });
}

export default new Routes().getPaths();
