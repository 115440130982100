import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Prompt } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Box, Flex } from '@jcm-technologies/uikit/dist/atoms/Layout';
import { Button } from '@jcm-technologies/uikit/dist/atoms/Button';
import Field from '../Field';
import { resetSuccess } from '../../modules/old_to_refact/actions/formsControl';
import './GenericForm.css';
import { getContrastColor } from '../../core/helpers/colorManager';

export class GenericForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasChanged: false,
    };
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.onSuccess) {
      this.props.onSuccess();
    }
    if (!this.state.hasChanged) {
      window.removeEventListener('beforeunload', this.onUnload);
    } else if (this.state.hasChanged && !this.props.cancelExitConfirmation) {
      window.addEventListener('beforeunload', this.onUnload);
    }
    if (
      !prevProps.forceSaveChangePopup &&
      this.props.forceSaveChangePopup &&
      !this.state.hasChanged
    ) {
      this.setState({ hasChanged: true });
    } else if (
      prevProps.forceSaveChangePopup &&
      !this.props.forceSaveChangePopup &&
      this.state.hasChanged
    ) {
      this.setState({ hasChanged: false });
    }

    if (prevProps?.match?.url !== this.props.match?.url) {
      this.setState({ hasChanged: false });
    }
  };

  componentWillUnmount = () => {
    this.props.resetSuccess();
    window.removeEventListener('beforeunload', this.onUnload);
  };

  onUnload = (event) => {
    const { t } = this.props;
    event.returnValue = `${t('genericForm.exitConfirmation')} ${
      event.currentTarget.location.pathName
    }?`;
  };

  onChange = (e, onChange) => {
    this.setState({ hasChanged: true });
    onChange(e);
  };

  onChangeNextGeneration = (name, value, onChange) => {
    this.setState({ hasChanged: true });
    onChange(name, value);
  };

  getInputs = (fields) =>
    fields.map((line, index) => {
      const lineFields = line.map((field, key) =>
        field.notShow ? null : (
          <Field
            key={field.id || field.id1 || field.id2 || key}
            {...field}
            onChange={
              field?.type !== 'dropdownSearch'
                ? (e) => this.onChange(e, field.onChange)
                : (name, value) => this.onChangeNextGeneration(name, value, field.onChange)
            }
            error={this.getError(field.id)}
          />
        )
      );
      return (
        <Flex
          flexDirection={this.props.alignColumn ? 'column' : 'row'}
          justifyContent={
            this.props.justifyContent ?? (this.props.alignCenter ? 'center' : 'flex-end')
          }
          marginY={1}
          key={line?.id || index}
        >
          {lineFields}
        </Flex>
      );
    });

  getError = (id) => {
    const { formErrors } = this.props;
    if (formErrors) {
      return formErrors.find((x) => x.id === id);
    }
    return null;
  };

  handleSubmit = (e) => {
    const { handleSubmit } = this.props;
    this.setState({ hasChanged: false });
    handleSubmit(e);
  };

  render() {
    const {
      t,
      fields,
      isCancelVisible,
      buttonsCentered,
      cancelExitConfirmation,
      buttonsId,
      submitText,
      tenants,
      id,
      isSubmitDisabled,
      isCancelDisabled,
      handleCancel,
      cancelText,
      isSubmitHidden,
    } = this.props;
    const { hasChanged } = this.state;
    return (
      <Box
        as='form'
        id={id}
        onSubmit={(e) => {
          e?.preventDefault();
        }}
        onReset={() => {}}
        width='100%'
      >
        <Prompt
          when={!cancelExitConfirmation ? hasChanged || false : false}
          message={(location) => `${t('genericForm.mayNotSave')}`}
        />
        {this.getInputs(fields)}
        <Flex id={buttonsId} marginTop={2} justifyContent={buttonsCentered ? 'center' : 'flex-end'}>
          {!isSubmitHidden && (
            <Button
              backgroundColor={tenants?.color1}
              sizeButton='small'
              id='entry'
              type='submit'
              disabled={isSubmitDisabled}
              action={(e) => this.handleSubmit(e)}
              marginRight={1}
              color={getContrastColor(tenants?.color1)}
            >
              {submitText || t('genericForm.save')}
            </Button>
          )}
          {isCancelVisible && (
            <Button
              backgroundColor='blackLight'
              sizeButton='small'
              id='cancel'
              disabled={isCancelDisabled}
              action={handleCancel}
            >
              {cancelText || t('general.back')}
            </Button>
          )}
        </Flex>
      </Box>
    );
  }
}

const genericFormWithTranslation = withTranslation()(GenericForm);

const mapStateToProps = () => ({});

export default connect(mapStateToProps, {
  resetSuccess,
})(genericFormWithTranslation);
