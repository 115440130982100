export default {
  instalationUserGroups: {
    manageFobsAndLicenses: 'Administrar fobs i llicències',
    fobsLicensesGridTitle: 'Fobs/Llicències',
    email: 'Correu electrònic',
    phone: 'Telèfon',
    addUser: 'Afegir usuari',
    deleteUserConfirmation: 'Segur que vols suprimir aquest usuari?',
    Fobs: 'Emissors',
    Licenses: 'Llicències',
    addFobToUser: "Afegir fob a l'usuari",
    expiration: 'Venciment',
    licenseStatus: 'Estat',
    locked: 'Bloquejada',
    unlocked: 'Desbloquejada',
    0: 'Bluetooth',
    1: 'Obertura remota',
    users: 'Usuaris',
    information: 'Informació',
  },
};
