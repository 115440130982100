export default {
  parameters: {
    title: 'Proxy Access 5k Parametereinstellung',
    pinCode: 'PIN Code',
    apn: 'APN',
    apnUser: 'APN-Benutzer',
    apnPassword: 'APN-Passwort',
    serverURL: 'URL Server',
    editproxy: 'Bearbeiten Sie den A5K-Proxy',
    titleRelays: 'Relais programmieren',
    errorRelaysPopUp: 'Fehler: Sie können die maximale Anzahl von Relais nicht überschreiten',
    CONFIGRELAYSNEW1_ICON: 'Relais 1 bearbeiten',
    CONFIGRELAYSNEW2_ICON: 'Relais 2 bearbeiten',
    CONFIGRELAYSNEW3_ICON: 'Relais 3 bearbeiten',
    CONFIGRELAYSNEW4_ICON: 'Relais bearbeiten 4',
    ACTIVEEVENTSTOSD_ICON: 'Aktive Ereignisse bearbeiten',
    A5KPROXY_ICON: 'Bearbeiten Sie den A5K-Proxy',
  },
};
