const GET_UNIVERSAL_GROUPS = 'GET_UNIVERSAL_GROUPS';
const RESET_UNIVERSAL_GROUP = 'RESET_UNIVERSAL_GROUP';
const GET_UNIVERSAL_GROUP = 'GET_UNIVERSAL_GROUP';
const GET_UNIVERSAL_GROUPS_INFO = 'GET_UNIVERSAL_GROUPS_INFO';
const GET_VISIBLE_DESCENDANTS = 'GET_VISIBLE_DESCENDANTS';
const SET_UNIVERSAL_GROUP = 'SET_UNIVERSAL_GROUP';
const DELETE_UNIVERSAL_GROUP = 'DELETE_UNIVERSAL_GROUP';
const DELETE_FACILITY_UNIVERSAL_GROUP = 'DELETE_FACILITY_UNIVERSAL_GROUP';
const CREATE_UNIVERSAL_GROUP = 'CREATE_UNIVERSAL_GROUP';
const RESET_UNIVERSAL_GROUP_INFO = 'RESET_UNIVERSAL_GROUP_INFO';
const RESET_UNIVERSAL_GROUP_RELAYS = 'RESET_UNIVERSAL_GROUP_RELAYS';
const PUT_UNIVERSAL_GROUP = 'PUT_UNIVERSAL_GROUP';
const GET_UNIVERSAL_GROUPS_EQUIPMENT_RELAYS = 'GET_UNIVERSAL_GROUPS_EQUIPMENT_RELAYS';
const PUT_INSTALATIONSRELAYS_UG = 'PUT_INSTALATIONSRELAYS_UG';
const GET_UNIVERSAL_GROUPS_EQUIPMENT = 'GET_UNIVERSAL_GROUPS_EQUIPMENT';
const GET_FACILITIES_LIST_BY_UG = 'GET_FACILITIES_LIST_BY_UG';
const RESET_FACILITIES_LIST_BY_UG = 'RESET_FACILITIES_LIST_BY_UG';
const RESET_UNIVERSAL_GROUP_FACILITIES = 'RESET_UNIVERSAL_GROUP_FACILITIES';
const GET_UNIVERSAL_GROUP_SINGLELIST = 'GET_UNIVERSAL_GROUP_SINGLELIST';
const RESET_UNIVERSAL_GROUP_SINGLELIST = 'RESET_UNIVERSAL_GROUP_SINGLELIST';

export default {
  RESET_UNIVERSAL_GROUP,
  GET_UNIVERSAL_GROUP,
  GET_UNIVERSAL_GROUPS,
  GET_VISIBLE_DESCENDANTS,
  SET_UNIVERSAL_GROUP,
  DELETE_UNIVERSAL_GROUP,
  CREATE_UNIVERSAL_GROUP,
  PUT_INSTALATIONSRELAYS_UG,
  GET_UNIVERSAL_GROUPS_INFO,
  GET_UNIVERSAL_GROUPS_EQUIPMENT,
  RESET_UNIVERSAL_GROUP_INFO,
  PUT_UNIVERSAL_GROUP,
  GET_UNIVERSAL_GROUPS_EQUIPMENT_RELAYS,
  GET_FACILITIES_LIST_BY_UG,
  RESET_FACILITIES_LIST_BY_UG,
  DELETE_FACILITY_UNIVERSAL_GROUP,
  RESET_UNIVERSAL_GROUP_RELAYS,
  RESET_UNIVERSAL_GROUP_FACILITIES,
  GET_UNIVERSAL_GROUP_SINGLELIST,
  RESET_UNIVERSAL_GROUP_SINGLELIST,
};

export const resetUniversalGroupInfo = () => ({ type: RESET_UNIVERSAL_GROUP_INFO });

export const getUniversalGroupsInfo = (facilityId, singleList, allocated) => ({
  type: GET_UNIVERSAL_GROUPS_INFO,
  facilityId,
  singleList,
  allocated,
});

export const getUniversalGroups = () => ({ type: GET_UNIVERSAL_GROUPS });

export const getUniversalGroupsEquipmentRelays = (facilityId, universalGroupId) => ({
  type: GET_UNIVERSAL_GROUPS_EQUIPMENT,
  facilityId,
  universalGroupId,
});

export const getVisibleDescendants = () => ({ type: GET_VISIBLE_DESCENDANTS });

export const createUniversalGroup = (UniversalGroup) => ({
  type: CREATE_UNIVERSAL_GROUP,
  UniversalGroup,
});

export const setUniversalGroup = (UniversalGroup) => ({
  type: SET_UNIVERSAL_GROUP,
  UniversalGroup,
});

export const deleteUniversalGroup = (UniversalGroupId) => ({
  type: DELETE_UNIVERSAL_GROUP,
  UniversalGroupId,
});

export const deleteFacilityUniversalGroup = (facilityId, UniversalGroupId) => ({
  type: DELETE_FACILITY_UNIVERSAL_GROUP,
  facilityId,
  UniversalGroupId,
});

export const putUniversalGroup = (UniversalGroup) => ({
  type: PUT_UNIVERSAL_GROUP,
  UniversalGroup,
});

export const getUniversalGroupEquipmentRelays = (facilityId) => ({
  type: GET_UNIVERSAL_GROUPS_EQUIPMENT_RELAYS,
  facilityId,
});

export const putInstalationRelaysUG = ({ universalGroupId, id, relay }) => ({
  type: PUT_INSTALATIONSRELAYS_UG,
  universalGroupId,
  id,
  relay,
});

export const getFacilityListByUniversalGroup = (universalGroupId, singleList, allocated) => ({
  type: GET_FACILITIES_LIST_BY_UG,
  universalGroupId,
  allocated,
  singleList,
});

export const resetFacilities = () => ({ type: RESET_FACILITIES_LIST_BY_UG });

export const resetUGRelays = () => ({ type: RESET_UNIVERSAL_GROUP_RELAYS });

export const getUniversalGroup = (id, include = true, customerId) => ({
  type: GET_UNIVERSAL_GROUP,
  id,
  include,
  customerId,
});
export const resetUniversalGroup = () => ({ type: RESET_UNIVERSAL_GROUP });

export const resetUniversalGroupFacilities = () => ({ type: RESET_UNIVERSAL_GROUP_FACILITIES });

export const getUniversalGroupSingleList = (id, exceptMe) => ({
  type: GET_UNIVERSAL_GROUP_SINGLELIST,
  id,
  exceptMe,
});

export const resetUniversalGroupSingleList = () => ({ type: RESET_UNIVERSAL_GROUP_SINGLELIST });
