import PathEntity from '../../core/old_common/rooting/apiPaths/PathEntity';

class Routes extends PathEntity {
  constructor() {
    super('tenants');
  }

  get = () => this.manufacturePath(this.entityName);

  getPaths = () => ({
    tenantsGet: this.get,
  });
}

export default new Routes().getPaths();
