import { call, fork, takeLatest } from 'redux-saga/effects';
import universalFobsActions from '../actions/universalGroupsFobs';
import fetchEntitySaga from './utils/fetchEntitySaga';
import api from '../../../core/helpers/api';
import { getUniversalGroupSaga, getUniversalGroupsSaga } from './universalGroups';
import {
  UNIVERSAL_DELETE_GROUP_FOBS_FOB,
  UNIVERSAL_FOB_GET_SUBS,
  UNIVERSAL_FOBS_COPY,
  UNIVERSAL_FOBS_DELETE_ALARM,
  UNIVERSAL_DELETE_GROUP_FOBS_LIST,
  UNIVERSAL_FOBS_GET,
  UNIVERSAL_FOBS_GET_CHECK_CODES,
  UNIVERSAL_FOBS_GET_COUNTER,
  UNIVERSAL_FOBS_GRID_IMPORT_PUT,
  UNIVERSAL_FOBS_GRID_PUT,
  UNIVERSAL_FOBS_MOVE_LIST,
  UNIVERSAL_POST_GROUP_FOBS_FOB,
  UNIVERSAL_POST_GROUP_FOBS_SEQUENTIAL,
} from '../reducers/universalGroupsFobs';
import apiPathFactory from '../../../core/old_common/rooting/apiPathFactory';

export const putUniversalFob = {
  base: (universalGroupId, fob) => ({
    type: UNIVERSAL_FOBS_GRID_PUT.BASE,
    universalGroupId,
    fob,
  }),
  request: () => ({ type: UNIVERSAL_FOBS_GRID_PUT.REQUEST }),
  success: (result) => ({ type: UNIVERSAL_FOBS_GRID_PUT.SUCCESS, result }),
  failure: (error) => ({ type: UNIVERSAL_FOBS_GRID_PUT.FAILURE, error }),
};

export const copyUniversalFob = {
  base: (universalGroupId, toGroupId, fobs) => ({
    type: UNIVERSAL_FOBS_COPY.BASE,
    universalGroupId,
    toGroupId,
    fobs,
  }),
  request: () => ({ type: UNIVERSAL_FOBS_COPY.REQUEST }),
  success: (result) => ({ type: UNIVERSAL_FOBS_COPY.SUCCESS, result }),
  failure: (error) => ({ type: UNIVERSAL_FOBS_COPY.FAILURE, error }),
};

export const moveUniversalFob = {
  base: (universalGroupId, toGroupId, fobs) => ({
    type: UNIVERSAL_FOBS_MOVE_LIST.BASE,
    universalGroupId,
    toGroupId,
    fobs,
  }),
  request: () => ({ type: UNIVERSAL_FOBS_MOVE_LIST.REQUEST }),
  success: (result, payload) => ({ type: UNIVERSAL_FOBS_MOVE_LIST.SUCCESS, result, payload }),
  failure: (error) => ({ type: UNIVERSAL_FOBS_MOVE_LIST.FAILURE, error }),
};

export const deleteUniversalFobList = {
  base: (universalGroupId, fobs) => ({
    type: UNIVERSAL_DELETE_GROUP_FOBS_LIST.BASE,
    universalGroupId,
    fobs,
  }),
  request: () => ({ type: UNIVERSAL_DELETE_GROUP_FOBS_LIST.REQUEST }),
  success: (result, payload) => ({
    type: UNIVERSAL_DELETE_GROUP_FOBS_LIST.SUCCESS,
    result,
    payload,
  }),
  failure: (error) => ({ type: UNIVERSAL_DELETE_GROUP_FOBS_LIST.FAILURE, error }),
};

export const deleteUniversalFob = {
  base: (universalGroupId, fobs) => ({
    type: UNIVERSAL_DELETE_GROUP_FOBS_FOB.BASE,
    universalGroupId,
    fobs,
  }),
  request: () => ({ type: UNIVERSAL_DELETE_GROUP_FOBS_FOB.REQUEST }),
  success: (result, payload) => ({
    type: UNIVERSAL_DELETE_GROUP_FOBS_FOB.SUCCESS,
    result,
    payload,
  }),
  failure: (error) => ({ type: UNIVERSAL_DELETE_GROUP_FOBS_FOB.FAILURE, error }),
};

export const putUniversalImportFobs = {
  base: (universalGroupId) => ({
    type: UNIVERSAL_FOBS_GRID_IMPORT_PUT.BASE,
    universalGroupId,
  }),
  request: () => ({ type: UNIVERSAL_FOBS_GRID_IMPORT_PUT.REQUEST }),
  success: (result) => ({ type: UNIVERSAL_FOBS_GRID_IMPORT_PUT.SUCCESS, result }),
  failure: (error) => ({ type: UNIVERSAL_FOBS_GRID_IMPORT_PUT.FAILURE, error }),
};

export const getUniversalFobs = {
  base: (universalGroupId) => ({
    type: UNIVERSAL_FOBS_GET.BASE,
    universalGroupId,
  }),
  request: () => ({ type: UNIVERSAL_FOBS_GET.REQUEST }),
  success: (result) => ({ type: UNIVERSAL_FOBS_GET.SUCCESS, result }),
  failure: (error) => ({ type: UNIVERSAL_FOBS_GET.FAILURE, error }),
};

export const getUniversalFobsCounter = {
  base: (universalGroupId) => ({
    type: UNIVERSAL_FOBS_GET_COUNTER.BASE,
    universalGroupId,
  }),
  request: () => ({ type: UNIVERSAL_FOBS_GET_COUNTER.REQUEST }),
  success: (result) => ({ type: UNIVERSAL_FOBS_GET_COUNTER.SUCCESS, result }),
  failure: (error) => ({ type: UNIVERSAL_FOBS_GET_COUNTER.FAILURE, error }),
};

export const getUniversalFobSubs = {
  base: (id, fobId) => ({
    type: UNIVERSAL_FOB_GET_SUBS.BASE,
    id,
    fobId,
  }),
  request: () => ({ type: UNIVERSAL_FOB_GET_SUBS.REQUEST }),
  success: (result) => ({ type: UNIVERSAL_FOB_GET_SUBS.SUCCESS, result }),
  failure: (error) => ({ type: UNIVERSAL_FOB_GET_SUBS.FAILURE, error }),
};

export const deleteAlarmFob = {
  base: (alarmId) => ({
    type: UNIVERSAL_FOBS_DELETE_ALARM.BASE,
    alarmId,
  }),
  request: () => ({ type: UNIVERSAL_FOBS_DELETE_ALARM.REQUEST }),
  success: (result) => ({ type: UNIVERSAL_FOBS_DELETE_ALARM.SUCCESS, result }),
  failure: (error) => ({ type: UNIVERSAL_FOBS_DELETE_ALARM.FAILURE, error }),
};

export const postUniversalFob = {
  base: (fob, isReservation) => ({
    type: UNIVERSAL_POST_GROUP_FOBS_FOB.BASE,
    fob,
    isReservation,
  }),
  request: () => ({ type: UNIVERSAL_POST_GROUP_FOBS_FOB.REQUEST }),
  success: (result) => ({ type: UNIVERSAL_POST_GROUP_FOBS_FOB.SUCCESS, result }),
  failure: (error) => ({ type: UNIVERSAL_POST_GROUP_FOBS_FOB.FAILURE, error }),
};

export const postUniversalSequentialFOB = {
  base: (fob) => ({
    type: UNIVERSAL_POST_GROUP_FOBS_SEQUENTIAL.BASE,
    fob,
  }),
  request: () => ({ type: UNIVERSAL_POST_GROUP_FOBS_SEQUENTIAL.REQUEST }),
  success: (result) => ({ type: UNIVERSAL_POST_GROUP_FOBS_SEQUENTIAL.SUCCESS, result }),
  failure: (error) => ({ type: UNIVERSAL_POST_GROUP_FOBS_SEQUENTIAL.FAILURE, error }),
};

export const getUniversalFobCheckCodes = {
  base: (universalGroupId, initialCode, codesAmount) => ({
    type: UNIVERSAL_FOBS_GET_CHECK_CODES.BASE,
    universalGroupId,
    initialCode,
    codesAmount,
  }),
  request: () => ({ type: UNIVERSAL_FOBS_GET_CHECK_CODES.REQUEST }),
  success: (result) => ({ type: UNIVERSAL_FOBS_GET_CHECK_CODES.SUCCESS, result }),
  failure: (error) => ({ type: UNIVERSAL_FOBS_GET_CHECK_CODES.FAILURE, error }),
};

export function* putUniversalFobSaga({ universalGroupId, fob }) {
  try {
    const url = apiPathFactory.universalGroupsFobsPut(universalGroupId, fob.id);
    const response = yield call(() =>
      fetchEntitySaga(putUniversalFob, api.put, url, {
        data: fob,
        notShowLoadSpinner: true,
      })
    );
    return response;
  } catch (error) {
    return error;
  }
}

export function* putUniversalImportFobsSaga({ universalGroupId, fobs }) {
  try {
    const url = apiPathFactory.universalGroupsFobsImportPut(universalGroupId);
    const response = yield call(() =>
      fetchEntitySaga(putUniversalImportFobs, api.put, url, {
        formData: [{ fieldName: 'file', file: fobs }],
      })
    );
    yield getUniversalFobsSaga({ universalGroupId });

    return response;
  } catch (error) {
    return error;
  }
}

export function* postUniversalFobsCopySaga({ universalGroupId, toGroupId, fobs }) {
  try {
    const url = apiPathFactory.universalGroupsFobsPostCopy(universalGroupId, toGroupId);
    const response = yield call(() =>
      fetchEntitySaga(copyUniversalFob, api.post, url, { data: fobs })
    );
    return response;
  } catch (error) {
    return error;
  }
}

// TODO: Limpiar el groupName que ya no es necesario
export function* putUniversalFobsMoveSaga({ universalGroupId, toGroupId, fobs, groupName }) {
  try {
    const url = apiPathFactory.universalGroupsFobsPutMove(universalGroupId, toGroupId);
    const response = yield call(() =>
      fetchEntitySaga(moveUniversalFob, api.put, url, { data: fobs })
    );
    yield getUniversalFobsSaga({ universalGroupId });
    yield getUniversalGroupSaga({ id: universalGroupId });
    return response;
  } catch (error) {
    return error;
  }
}

export function* deleteUniversalFobsListSaga({ universalGroupId, fobs }) {
  try {
    const url = apiPathFactory.universalGroupsFobsDeleteList(universalGroupId);
    const response = yield call(() =>
      fetchEntitySaga(deleteUniversalFobList, api.del, url, { data: fobs })
    );
    yield getUniversalFobsSaga({ universalGroupId });
    yield getUniversalGroupSaga({ id: universalGroupId });

    return response;
  } catch (error) {
    return error;
  }
}

export function* deleteUniversalFobSaga({ universalGroupId, fob }) {
  try {
    const url = apiPathFactory.universalGroupsFobsDelete(universalGroupId, fob.id);
    const response = yield call(() =>
      fetchEntitySaga(deleteUniversalFob, api.del, url, { data: fob })
    );
    return response;
  } catch (error) {
    return error;
  }
}

export function* getUniversalFobsSaga({ universalGroupId }) {
  try {
    const url = apiPathFactory.universalGroupsFobsGet(universalGroupId);
    const response = yield call(() =>
      fetchEntitySaga(getUniversalFobs, api.get, url, { data: universalGroupId })
    );
    return response;
  } catch (error) {
    return error;
  }
}

export function* getUniversalFobsCounterSaga({ universalGroupId }) {
  try {
    const url = apiPathFactory.universalGroupsFobsGetCounter(universalGroupId);
    const response = yield call(() => fetchEntitySaga(getUniversalFobsCounter, api.get, url));
    return response;
  } catch (error) {
    return error;
  }
}

export function* getUniversalFobSubsSaga({ id, fobId }) {
  try {
    const url = apiPathFactory.universalGroupsFobGetSubs(id, fobId);
    const response = yield call(() => fetchEntitySaga(getUniversalFobSubs, api.get, url));
    return response;
  } catch (error) {
    return error;
  }
}

export function* deleteAlarmFobSaga({ alarmId, universalGroupId }) {
  try {
    const url = apiPathFactory.alarmsDelete(alarmId);
    const response = yield call(() => fetchEntitySaga(deleteAlarmFob, api.del, url));
    yield call(getUniversalFobsSaga, { universalGroupId });
    yield call(getUniversalGroupsSaga, {});

    return response;
  } catch (error) {
    return error;
  }
}

export function* postUniversalFobSaga({ universalFob, force }) {
  try {
    const url = apiPathFactory.universalGroupsFobsPost(universalFob.universalGroupId, force);
    const response = yield call(() =>
      fetchEntitySaga(postUniversalFob, api.post, url, { data: universalFob })
    );
    return response;
  } catch (error) {
    return error;
  }
}

export function* postSequentialUniversalFobSaga({ payload }) {
  try {
    const url = apiPathFactory.universalGroupsFobsPostSequential({
      universalGroupId: payload.universalGroupId,
      initialCode: payload.data.e_code,
      codesAmount: payload.data.codesAmount,
      isReservation: payload.isReservation,
    });
    const response = yield call(() =>
      fetchEntitySaga(postUniversalSequentialFOB, api.post, url, { data: payload.data })
    );
    return response;
  } catch (error) {
    return error;
  }
}

export function* getUGCheckFobCodesSaga({ universalGroupId, initialCode, codesAmount }) {
  try {
    const url = apiPathFactory.universalGroupsFobsGetCheckCodes(
      universalGroupId,
      initialCode,
      codesAmount
    );
    const response = yield call(() => fetchEntitySaga(getUniversalFobCheckCodes, api.get, url));
    return response;
  } catch (error) {
    return error;
  }
}

function* watchPutUniversalFobs() {
  yield takeLatest(universalFobsActions.PUT_UNIVERSAL_FOB, putUniversalFobSaga);
}

function* watchPutUniversalImportFobs() {
  yield takeLatest(universalFobsActions.PUT_UNIVERSAL_IMPORT_FOBS, putUniversalImportFobsSaga);
}

function* watchGetUniversalFobs() {
  yield takeLatest(universalFobsActions.GET_UNIVERSAL_FOBS, getUniversalFobsSaga);
}

function* watchGetUniversalFobsCounter() {
  yield takeLatest(universalFobsActions.GET_UNIVERSAL_FOB_COUNTS, getUniversalFobsCounterSaga);
}

function* watchPostUniversalFOB() {
  yield takeLatest(universalFobsActions.POST_GRID_UNIVERSAL_FOB, postUniversalFobSaga);
}

function* watchPostUniversalSequential() {
  yield takeLatest(
    universalFobsActions.POST_SEQUENTIAL_UNIVERSAL_FOB,
    postSequentialUniversalFobSaga
  );
}

function* watchPostUniversalFOBCopy() {
  yield takeLatest(universalFobsActions.POST_UNIVERSAL_FOBS_COPY, postUniversalFobsCopySaga);
}

function* watchPutUniversalFOBMove() {
  yield takeLatest(universalFobsActions.PUT_UNIVERSAL_FOBS_MOVE, putUniversalFobsMoveSaga);
}

function* watchDeleteUniversalFOBSList() {
  yield takeLatest(universalFobsActions.DELETE_UNIVERSAL_FOBS_LIST, deleteUniversalFobsListSaga);
}

function* watchDeleteUniversalFOB() {
  yield takeLatest(universalFobsActions.DELETE_UNIVERSAL_FOB, deleteUniversalFobSaga);
}

function* watchGetUniversalFOBSubs() {
  yield takeLatest(universalFobsActions.GET_UNIVERSAL_FOBS_SUBS, getUniversalFobSubsSaga);
}

function* watchDeleteAlarmFOB() {
  yield takeLatest(universalFobsActions.DELETE_ALARMS, deleteAlarmFobSaga);
}

function* watchGetUGCheckFobCodes() {
  yield takeLatest(universalFobsActions.GET_UG_CHECK_FOB_CODES, getUGCheckFobCodesSaga);
}

export default function* watchers() {
  yield fork(watchGetUniversalFobs);
  yield fork(watchPutUniversalImportFobs);
  yield fork(watchPostUniversalFOB);
  yield fork(watchPostUniversalSequential);
  yield fork(watchGetUniversalFobsCounter);
  yield fork(watchPutUniversalFobs);
  yield fork(watchPostUniversalFOBCopy);
  yield fork(watchDeleteUniversalFOBSList);
  yield fork(watchPutUniversalFOBMove);
  yield fork(watchDeleteUniversalFOB);
  yield fork(watchGetUniversalFOBSubs);
  yield fork(watchDeleteAlarmFOB);
  yield fork(watchGetUGCheckFobCodes);
}
