import { React } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Flex } from '@jcm-technologies/uikit/dist/atoms/Layout';
import { useTranslation } from 'react-i18next';
import { Button } from '@jcm-technologies/uikit/dist/atoms/Button';
import i18Next from 'i18next';
import { Text } from '@jcm-technologies/uikit/dist/atoms/Typography';
import { ColumnsTable, infoValueMapped, rgpdAlertLanguages } from './config';
import GenericKendoGrid from '../../../old_components/GenericKendoGrid';
import { getFacilityEvents } from '../../../modules/old_to_refact/actions/instalations';
import { getContrastColor } from '../../../core/helpers/colorManager';
import { ExcelExportEvents } from './Excel/Export';
import { ModalRgpd } from '../../common/Modals/ModalRgpd';

const EventsTab = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { facility, isLoadingFacilityEvents } = useSelector((state) => state.instalations);
  const { color1 } = useSelector((state) => state.tenants);
  const { t } = useTranslation();
  const [openModalRgpd, setOpenModalRgpd] = useState(false);
  const {
    user: { language: lang },
  } = useSelector((state) => state.user);
  const getEventsAPI = useCallback(() => dispatch(getFacilityEvents(id)), []);
  const handleClickEvents = () => {
    rgpdAlertLanguages.includes(lang) ? setOpenModalRgpd(true) : getEventsAPI();
  };
  const closeModal = () => setOpenModalRgpd(false);

  const modalAction = () => {
    closeModal();
    getEventsAPI();
  };
  return (
    <>
      <Flex height='100%' flexDirection='column' justifyContent='space-between'>
        <GenericKendoGrid
          pageSize={20}
          getColumns={() => ColumnsTable()}
          needDateTransformation
          value={
            facility?.events?.map((event) => ({
              ...event,
              owner: i18Next.exists(`event.code.${event.owner}`)
                ? t(`event.code.${event.owner}`)
                : event.owner === 'Deleted User'
                ? t(`errors.deletedUser`)
                : event.owner,
              event: t(`event.status.${event?.actionLanguageCode}`),
              info: infoValueMapped(event.infoLanguageCode, event.infoValue),
            })) || []
          }
        />
        {facility?.dateTimeEventsGetter && (
          <Flex alignItems='center' justifyContent='end' marginBottom={1}>
            <Text sizeText='display14' textAlign='right'>
              {t('general.dataReadOn', { dateTime: facility?.dateTimeEventsGetter })}
            </Text>
          </Flex>
        )}
        <Flex alignItems='center' justifyContent='space-between'>
          <ExcelExportEvents
            facilityName={facility?.name}
            data={
              facility?.events?.map((event) => ({
                ...event,
                owner: i18Next.exists(`event.code.${event.owner}`)
                  ? t(`event.code.${event.owner}`)
                  : event.owner === 'Deleted User'
                  ? t(`errors.deletedUser`)
                  : event.owner,
                event: t(`event.status.${event?.actionLanguageCode}`),
                info: infoValueMapped(event.infoLanguageCode, event.infoValue),
              })) || []
            }
          />
          <Button
            id='facility-load-events'
            backgroundColor={color1}
            color={getContrastColor(color1)}
            onClick={handleClickEvents}
          >
            {t('event.loadEvents')}
          </Button>
        </Flex>
      </Flex>
      <ModalRgpd maxWidth='800px' action={modalAction} isOpen={openModalRgpd} close={closeModal} />
    </>
  );
};

export default EventsTab;
