import PathEntity from './PathEntity.js';

class Neighbours extends PathEntity {
  constructor() {
    super('neighbour');
  }

  post = (facilityId, equipmentId) =>
    this.manufacturePath(`facilities/${facilityId}/equipments/${equipmentId}/${this.entityName}`);

  get = (facilityId, equipmentId) =>
    this.manufacturePath(`facilities/${facilityId}/equipments/${equipmentId}/${this.entityName}s`);

  delete = (facilityId, equipmentId, neighbourId) =>
    this.manufacturePath(
      `facilities/${facilityId}/equipments/${equipmentId}/${this.entityName}/${neighbourId}`
    );

  put = (facilityId, equipmentId, neighbourId) =>
    this.manufacturePath(
      `facilities/${facilityId}/equipments/${equipmentId}/${this.entityName}/${neighbourId}`
    );

  getPaths = () => ({
    neighbourPost: this.post,
    neighbourPut: this.put,
    neighbourGet: this.get,
    neighbourDelete: this.delete,
  });
}

export default new Neighbours().getPaths();
