import React, { Component } from 'react';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { generatePath, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import GenericList from '../GenericList';
import IconSpan from '../IconSpan/IconSpan';
import { AddButton } from '../AddButton/AddButton';
import apiPathFactory from '../../core/old_common/rooting/apiPathFactory';
import { resetFamilyProducts } from '../../modules/productFamilies/action';
import {
  getInstalationGroups,
  resetInstalationGroup,
} from '../../modules/old_to_refact/actions/instalationGroups';
import { resetAllFobLists } from '../../modules/old_to_refact/actions/instalations';
import { CreationPath_Instalation_Group } from '../../core/old_common/constats/genericListPaths';
import { downloadFile, getFilesFromApi } from '../../core/helpers/filesApi';
import InstalationGroupGrow from '../InstalationGroupGrow/InstalationGroupGrow';
import { ExportGroupsFormPopUpContent } from '../ExportGroupsFormPopUpContent';

import './InstalationGroupsList.css';
import { showDialog } from '../../modules/old_to_refact/actions/loading';
import { FACILITY_GROUP_LIST } from '../../modules/state/pagination/constants';

export class InstalationGroupsList extends Component {
  constructor() {
    super();
    this.state = {
      PopUpExport: {
        isExportPopUpOpen: false,
        onAcceptFn: this.onClickExportAccept,
        onCancelFn: () => {
          this.onClickExportCancel();
        },
      },
    };
  }

  componentDidMount = () => {
    const { resetInstalationGroup, needCallGroups, resetAllFobLists } = this.props;
    if (needCallGroups || needCallGroups === undefined) {
      this.getInstalationGroupsList();
    }
    resetInstalationGroup();
    resetAllFobLists();
  };

  getInstalationGroupsList = () => {
    const { getInstalationGroups, instalationId } = this.props;
    if (instalationId) {
      getInstalationGroups(instalationId);
    }
  };

  componentWillUnmount = () => {
    this.props.resetFamilyProducts();
  };

  onClickExportAccept = (options) => {
    const now = moment();
    const { includeReservations, includeConfigFields, modifiedGroups } = options;
    const { name, t } = this.props;
    const idArray = modifiedGroups.filter((group) => group.selected).map((group) => group.id);
    this.props.showDialog(1);
    getFilesFromApi(
      apiPathFactory.facilitiesGetExportUsers(idArray, includeReservations, includeConfigFields),
      (isError, response) => {
        this.props.showDialog(-1);
        if (!isError) {
          downloadFile(
            response,
            `${now.format('YYYY-MM-DD')}_${t(
              'updateInstalationForm.exportUsersTitle'
            )}_${name}.xlsx`
          );
        }
      },
      null,
      'xlsx'
    );
    this.setState({
      PopUpExport: {
        ...this.state.PopUpExport,
        isExportPopUpOpen: false,
      },
    });
  };

  onClickExportCancel = () => {
    this.setState({
      PopUpExport: {
        isExportPopUpOpen: false,
        tenants: this.props.tenants,
      },
    });
  };

  isExportpopUpOpen = (isOpen) =>
    this.setState({
      PopUpExport: {
        isExportPopUpOpen: isOpen,
      },
    });

  getSelectedInstalation = () =>
    this.props.instalations.find((x) => x.id.trim() === this.props.instalationId) || {};

  getBaseURI = (decodedId) => {
    const { match } = this.props;
    const encoded = generatePath(`${match.path}/${CreationPath_Instalation_Group}`, {
      id: encodeURIComponent(match.params.id),
    });
    return encoded;
  };

  render() {
    const { baseURI, instalationGroups, t, tenants, history, match } = this.props;
    return (
      <div className='groups-list'>
        <GenericList
          dataList={instalationGroups}
          CustomGrow={InstalationGroupGrow}
          baseURI={baseURI}
          creationURI={CreationPath_Instalation_Group}
          listType={FACILITY_GROUP_LIST}
          isPaginable={false}
        />
        <span className='instalation-group-buttons-container'>
          <IconSpan
            defaultColor='#a4aebb'
            onClick={() => {
              this.isExportpopUpOpen(true);
            }}
            toolTip={t('updateInstalationForm.exportUsersGroups')}
            iconClassName='fas fa-file-export instalation-group-add-UG'
          />

          <IconSpan
            defaultColor='#a4aebb'
            onClick={() => {
              history.push(`${baseURI.replace('%09', '%2509')}/AddUniversalGroup`);
            }}
            toolTip={t('updateInstalationForm.addUniversalGroup')}
            iconClassName='fas fa-users instalation-group-add-UG'
          />
          <AddButton
            id='add'
            tenants={tenants}
            toolTip={t('instalations.addGroup')}
            history={history}
            url={`${this.getBaseURI(decodeURIComponent(match.params.id))}`}
          />
        </span>
        <ExportGroupsFormPopUpContent
          isOpen={this.state.PopUpExport.isExportPopUpOpen}
          onCloseForm={this.isExportpopUpOpen}
          onSubmit={this.onClickExportAccept}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  tenants: state.tenants,
  instalationGroups: state.instalationGroups.facilityGroups,
  instalations: state.instalations.instalations,
  needCallGroups: state.instalationGroups.needCallGroups,
});

const InstalationGroupsListPage = withTranslation()(InstalationGroupsList);
const InstalationGroupListPageWithHistory = withRouter(InstalationGroupsListPage);

export default connect(mapStateToProps, {
  getInstalationGroups,
  resetInstalationGroup,
  resetFamilyProducts,
  showDialog,
  resetAllFobLists,
})(InstalationGroupListPageWithHistory);
