import appActions from './actions';

/* Redux state init */
const initialState = {
  isSidebarCollapsed: false,
};

export default function reducer(state = initialState, action) {
  if (action.type !== undefined) {
    switch (action.type) {
      case appActions.SET_SIDEBAR_COLLAPSED:
        return {
          ...state,
          isSidebarCollapsed: action.isCollapsed,
        };
      default:
        return state;
    }
  }
  return state;
}
