export default {
  gsmPopUp: {
    title: 'État du service ',
    deviceNotSimInfo: "L'appareil est hors ligne",
    simContractInfo: 'Informations sur le contrat',
    expiration: 'Expiration',
    status: 'État',
    infoNotAvailable: 'Information non disponible',
    simConnectionInfo: 'Informations de connexion SIM',
    cardIdentifier: 'Card Identifier (ICCID)',
    activationDate: "Date d'activation",
    lastMonthDataUpload: 'Importation des données du mois dernier',
    lastMonthDataDownload: 'Téléchargement des données du mois dernier',
    lastSeen: 'Dernière vue',
    contractStatus: 'Statut du contrat',
    simStatus: 'Statut SIM',
    deviceStatus: 'État du périphérique',
    enabled: 'Activé',
    disabled: 'Disabled',
    notAvailable: 'Non disponible',
    connected: 'Connecté',
    disconnected: 'Disconnected',
  },
};
