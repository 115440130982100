import { useTranslation } from 'react-i18next';
import { Button, ButtonGroup } from '@jcm-technologies/uikit/dist/atoms/Button';
import { Box, Flex } from '@jcm-technologies/uikit/dist/atoms/Layout';
import { useHistory, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { CodesCounters } from '@jcm-technologies/uikit/dist/molecules/CodesCounters';
import { useSelector } from 'react-redux';
import { IconTooltip } from '@jcm-technologies/uikit/dist/molecules/IconTooltip';
import IconPlus from '@jcm-technologies/uikit/dist/atoms/Icons/IconPlus';
import { TableMifare } from '../Table';
import { ModalDeleteMultiMifare } from '../Modal/DeleteMultiMifare';
import { getContrastColor } from '../../../../../core/helpers/colorManager';
import { SelectedCounter } from '../../../../common/Counter';
import { ModalMoveMultiMifare } from '../Modal/MoveMultiMifare';
import { ModalCopyMultiMifare } from '../Modal/CopyMultiMifare';
import { actionGroup } from '../config';
import { DeleteMifare } from '../Modal/DeleteMifare';
import { ExcelExportMifare } from '../Excel/Export';
import { ExcelImportMifare } from '../Excel/Import';

const BoxMifare = ({
  items,
  counter,
  setData,
  setChanges,
  onBlurSaveTable,
  setFobToDelete,
  fobToDelete,
  onDeleteMifare,
  onDeleteMultiMifare,
  onImportExcelMifare,
  onSelectedState,
  selectedState,
  selectedCountState,
  setSelectedCountState,
  isOpenModalDeleteMultiMifare,
  setIsOpenModalDeleteMultiMifare,
  isOpenModalMoveMultiMifare,
  setIsOpenModalMoveMultiMifare,
  onMoveMultiMifare,
  isOpenModalCopyMultiMifare,
  setIsOpenModalCopyMultiMifare,
  onCopyMultiMifare,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { id: idGroup, instalationId: idFacility } = useParams();
  const { color1 } = useSelector((state) => state.tenants);
  const {
    user: { roleMask },
  } = useSelector((state) => state.user);
  const { customFields } = useSelector((state) => state.mifare);
  const isPropertyManagerLiteRole = roleMask === 256;

  return (
    <>
      <Box paddingX={4} paddingY={1}>
        <Flex alignItems='center'>
          <CodesCounters
            availableValue={counter.free}
            availablePlaceholder={t('codesCount.available')}
            reservationValue={counter.reserved}
            reservationPlaceholder={t('enrollCode.notAsigned')}
            usedValue={counter.busy}
            usedPlaceholder={t('codesCount.used')}
          />
        </Flex>
        <SelectedCounter counter={selectedCountState} />
        <TableMifare
          data={items}
          customFields={customFields}
          setData={setData}
          setChanges={setChanges}
          onBlurSaveTable={onBlurSaveTable}
          onFobToDelete={setFobToDelete}
          onSelectedState={onSelectedState}
          selectedState={selectedState}
          setSelectedCountState={setSelectedCountState}
        />
        {selectedCountState ? (
          <Flex alignItems='center' justifyContent='flex-end' marginY={1}>
            <ButtonGroup
              backgroundColor={color1}
              color={getContrastColor(color1)}
              items={actionGroup({
                setIsOpenModalDeleteMultiMifare,
                setIsOpenModalMoveMultiMifare,
                setIsOpenModalCopyMultiMifare,
              })}
              sizeButton='small'
            />
          </Flex>
        ) : null}
      </Box>
      <Flex paddingX={4} paddingBottom={2} alignItems='center' justifyContent='space-between'>
        <Flex>
          <ExcelExportMifare data={items} />
          <ExcelImportMifare onImport={onImportExcelMifare} />
        </Flex>
        {!isPropertyManagerLiteRole ? (
          <IconTooltip
            toolTipContent={t('instalationGroups.enrollFOB')}
            sizeIcon='display36'
            color='grey'
            onClick={() => history.push(`/facilities/${idFacility}/groups/${idGroup}/wiegand/new`)}
          >
            <IconPlus />
          </IconTooltip>
        ) : (
          ''
        )}
      </Flex>
      <Flex paddingX={4} paddingY={2} alignItems='center' justifyContent='flex-end'>
        <Button backgroundColor='blackLight' onClick={() => history.goBack()}>
          {t('general.back')}
        </Button>
      </Flex>
      <DeleteMifare
        onDeleteMifare={onDeleteMifare}
        fobToDelete={fobToDelete}
        setFobToDelete={setFobToDelete}
      />
      <ModalDeleteMultiMifare
        count={selectedCountState}
        isOpenModalDeleteMultiMifare={isOpenModalDeleteMultiMifare}
        setIsOpenModalDeleteMultiMifare={setIsOpenModalDeleteMultiMifare}
        onDeleteMultiMifare={onDeleteMultiMifare}
      />
      <ModalMoveMultiMifare
        count={selectedCountState}
        isOpenModalMoveMultiMifare={isOpenModalMoveMultiMifare}
        setIsOpenModalMoveMultiMifare={setIsOpenModalMoveMultiMifare}
        onMoveMultiMifare={onMoveMultiMifare}
      />
      <ModalCopyMultiMifare
        count={selectedCountState}
        isOpenModalCopyMultiMifare={isOpenModalCopyMultiMifare}
        setIsOpenModalCopyMultiMifare={setIsOpenModalCopyMultiMifare}
        onCopyMultiMifare={onCopyMultiMifare}
      />
    </>
  );
};

export default BoxMifare;

BoxMifare.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})),
  counter: PropTypes.shape({
    free: PropTypes.string,
    reserved: PropTypes.string,
    busy: PropTypes.string,
  }),
  fobToDelete: PropTypes.shape({}),
  selectedState: PropTypes.shape({}),
  selectedCountState: PropTypes.number,
  isOpenModalDeleteMultiMifare: PropTypes.bool.isRequired,
  isOpenModalMoveMultiMifare: PropTypes.bool.isRequired,
  isOpenModalCopyMultiMifare: PropTypes.bool.isRequired,
  onCopyMultiMifare: PropTypes.func.isRequired,
  setIsOpenModalCopyMultiMifare: PropTypes.func.isRequired,
  setSelectedCountState: PropTypes.func.isRequired,
  setIsOpenModalMoveMultiMifare: PropTypes.func.isRequired,
  setIsOpenModalDeleteMultiMifare: PropTypes.func.isRequired,
  onDeleteMifare: PropTypes.func.isRequired,
  onDeleteMultiMifare: PropTypes.func.isRequired,
  onImportExcelMifare: PropTypes.func.isRequired,
  onSelectedState: PropTypes.func.isRequired,
  setData: PropTypes.func.isRequired,
  setChanges: PropTypes.func.isRequired,
  onBlurSaveTable: PropTypes.func.isRequired,
  setFobToDelete: PropTypes.func.isRequired,
  onMoveMultiMifare: PropTypes.func.isRequired,
};

BoxMifare.defaultProps = {
  items: [],
  counter: {
    free: '0',
    reserved: '0',
    busy: '0',
  },
  fobToDelete: null,
  selectedState: null,
  selectedCountState: 0,
};
