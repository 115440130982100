export default {
  instalationUserGroups: {
    manageFobsAndLicenses: 'Správa FOB a licencí',
    fobsLicensesGridTitle: 'FOBS / Licence',
    email: 'E-mailem',
    phone: 'Telefon',
    addUser: 'Přidat uživatele',
    deleteUserConfirmation: 'Opravdu chcete tohoto uživatele smazat?',
    Fobs: 'Ovladače',
    Licenses: 'Licence',
    addFobToUser: 'Přidat FOB k uživateli',
    expiration: 'Vypršení',
    licenseStatus: 'Postavení',
    locked: 'Uzamčeno',
    unlocked: 'Odemčený',
    0: 'Bluetooth',
    1: 'Vzdálené otevření',
    users: 'Uživatelé',
    information: 'Informace',
  },
};
