import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from './Button';
import './ButtonsGroup.css';
import Div from './Div';

class ButtonsGroup extends Component {
  render() {
    const { buttons, tenants, flexAlignment, className, id } = this.props;
    return (
      <Div
        flexAlignment={flexAlignment}
        className={`${className || ''} ${
          buttons && buttons.filter((button) => !button.hidden).length > 1
            ? 'buttonsGroup'
            : 'buttonsGroup buttonsGroup-one'
        }`}
      >
        {buttons
          ? buttons.map((button, i) => (
              <Button
                id={button.id ? button.id : 'button-from-group'}
                key={button.id || i}
                {...button}
                onClick={!button.disabled ? button.onClick : null}
                tenants={tenants}
              >
                {button.value}
              </Button>
            ))
          : null}
      </Div>
    );
  }
}

const mapStateToProps = (state) => ({
  tenants: state.tenants,
});

export default connect(mapStateToProps, {})(ButtonsGroup);
