import React from 'react';
import InputMask from 'react-input-mask';
import i18next from 'i18next';
import { toast } from 'react-toastify';
import Input from '../../Input';
import Timezone from '../../Timezone';
import Dropdown from '../../Dropdown';
import Textarea from '../../Textarea';
import IconSpan from '../../IconSpan/IconSpan';
import { getVertexMotionType } from '../../../core/old_common/utils/vertexVerification';

const genericRender = (CustomInput, fieldDatas, funcs) => (
  <CustomInput mask={fieldDatas.regexPattern || ''} {...fieldDatas} {...funcs} onClick={() => {}} />
);

const fields = [];
fields.readonly = (fieldDatas) =>
  genericRender(Input, (fieldDatas = { ...fieldDatas, readonly: true }));
fields.input_numeric = (fieldDatas) =>
  genericRender(Input, {
    ...fieldDatas,
    type: 'number',
    min: fieldDatas.min,
    maxlength: fieldDatas.max,
    onChange: (e) => {
      if (parseInt(e.target.value) > fieldDatas.max) {
        e.target.value = fieldDatas.max;
      } else if (parseInt(e.target.value) < fieldDatas.min) {
        e.target.value = fieldDatas.min;
      }
      fieldDatas.onChange(e);
    },
  });
fields.password_numeric = (fieldDatas) =>
  genericRender(Input, {
    ...fieldDatas,
    className: `${fieldDatas.className} numericPass`,
    maxlength: fieldDatas.max,
    onChange: (e) => {
      if (!isNaN(e.target.value)) {
        fieldDatas.onChange(e);
      }
    },
  });

fields.timezone = (fieldDatas) =>
  genericRender(
    Timezone,
    (fieldDatas = {
      ...fieldDatas,
      fromParameters: true,
      className:
        fieldDatas.value === '00000000-0000-0000-0000-000000000000' ? 'timezone-wrong' : '',
    })
  );

fields.enumerable = (fieldDatas) => genericRender(Dropdown, fieldDatas);
fields.hidden = (fieldDatas) =>
  genericRender(Input, (fieldDatas = { ...fieldDatas, type: 'hidden' }));
fields.textarea = (fieldDatas) => genericRender(Textarea, fieldDatas);
fields.input_text = (fieldDatas) =>
  genericRender(Input, (fieldDatas = { ...fieldDatas, maxlength: fieldDatas.length }));
fields.password_alphanumeric = (fieldDatas, funcs) =>
  genericRender(
    InputMask,
    {
      ...fieldDatas,
      alwaysShowMask: true,
      onChange: (e) => {
        e.currentTarget.value = e.currentTarget.value.replace(/-/g, '');
        if (fieldDatas.id === 'ActivationKey') {
          if (
            e.currentTarget.value &&
            (!e.currentTarget.value.includes('_') || e.currentTarget.value.replace(/_/g, '') === '')
          ) {
            fieldDatas.checkConnectionFunc(e.currentTarget.value);
          }
        } else {
          const value = e.currentTarget.value.split('_').join('');
          if (value.length === 16) {
            const vertexCodeVerified = getVertexMotionType(value);
            if (vertexCodeVerified === -1) {
              document.getElementById(e.currentTarget.id).className =
                'mask-input incorrect-activation-key';
              toast.error(
                `${i18next.t(`paramsName.${e.currentTarget.id}`)}: ${i18next.t(
                  `paramsName.incorrectVertexKey`
                )}`
              );
            } else if (vertexCodeVerified === 60) {
              document.getElementById(e.currentTarget.id).className = 'mask-input';
              toast.success(
                `${i18next.t(`paramsName.${e.currentTarget.id}`)}: ${i18next.t(
                  `paramsName.correctVertexKey`
                )}`
              );
            }
          }
        }

        fieldDatas.onChange(e);
      },
      className: `mask-input ${
        fieldDatas.id === 'ActivationKey' ? fieldDatas.getClassName(fieldDatas) : ''
      }`,
      regexPattern: fieldDatas.regexPattern,
    },
    funcs
  );

fields.alphanumeric = (fieldDatas) =>
  genericRender(Input, (fieldDatas = { ...fieldDatas, maxlength: fieldDatas.max }));
fields.bitmap = (fieldDatas) => (
  <IconSpan
    toolTip={i18next.t(`parameters.${fieldDatas.id.toUpperCase()}_ICON`)}
    id='config'
    defaultColor='a4aebb'
    iconClassName='generic-grow-action-button fas fa-cog'
    onClick={fieldDatas.onClick}
  />
);
fields.active_events_enum = (fieldDatas) => (
  <IconSpan
    toolTip={i18next.t(`parameters.${fieldDatas.id.toUpperCase()}_ICON`)}
    id='config'
    defaultColor='a4aebb'
    iconClassName='generic-grow-action-button fas fa-cog'
    onClick={fieldDatas.onClick}
  />
);
fields.A5kProxy = (fieldDatas) => (
  <IconSpan
    toolTip={i18next.t(`parameters.${fieldDatas.id.toUpperCase()}_ICON`)}
    id='config'
    defaultColor='a4aebb'
    iconClassName='generic-grow-action-button fas fa-cog'
    onClick={fieldDatas.onClick}
  />
);

export default fields;
