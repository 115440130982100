import { Flex } from '@jcm-technologies/uikit/dist/atoms/Layout';
import * as Sentry from '@sentry/react';
import { Button } from '@jcm-technologies/uikit/dist/atoms/Button';
import { Text } from '@jcm-technologies/uikit/dist/atoms/Typography';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { deleteCookieAll } from '../../core/old_common/utils/cookiesManager';

const View = ({ message = null }) => {
  const { t } = useTranslation();
  const { instance, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const handleLogout = async () => {
    if (isAuthenticated && inProgress === InteractionStatus.None) {
      deleteCookieAll();
      Sentry.setUser(null);
      await instance.logoutRedirect();
    } else {
      await instance.logoutRedirect();
    }
  };

  return (
    <Flex
      alignItems='center'
      justifyContent='center'
      flexDirection='column'
      width='100%'
      height='100vh'
    >
      <Text sizeText='display36' marginY={4}>
        {message || t('error.158')}
      </Text>
      <Button id='logout-button' sizeButton='small' action={() => handleLogout()}>
        {t('general.logOut')}
      </Button>
    </Flex>
  );
};

export default View;

View.propTypes = {
  message: PropTypes.string,
};
