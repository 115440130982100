import styled from 'styled-components';
import css from '@styled-system/css';
import { Link } from 'react-router-dom';

export const LinkStyled = styled(Link)(css({ color: 'grey' }), {
  ':hover': {
    color: `${(props) => props.tenants.color1}`,
  },
});

export const BreadCrumbsStyled = styled('div')(
  css({
    borderRadius: 3,
    backgroundColor: '#e8e6e6',
    paddingY: 1,
    paddingX: 2,
    marginBottom: 3,
  })
);

export const BreadCrumbsSeparatorStyled = styled('span')(
  css({
    color: 'grey',
  })
);
