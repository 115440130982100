import { call, fork, takeLatest } from 'redux-saga/effects';
import InstalationGroupsActions, { GET_GROUP_FOB_HISTORY } from '../actions/instalationGroups';
import fetchEntitySaga from './utils/fetchEntitySaga';
import api from '../../../core/helpers/api';
import {
  INSTALATION_GROUP_UNIVERSALS_GET,
  INSTALATION_GROUPS_ASSIGNED_GET,
  INSTALATION_GROUPS_DELETE,
  INSTALATION_GROUPS_FOBS_COUNT,
  INSTALATION_GROUPS_GET,
  INSTALATION_GROUPS_POST,
  INSTALATION_GROUPS_PUT,
  INSTALATION_GROUPS_SECONDARY_GET,
  INSTALATIONS_GROUP_GET,
} from '../reducers/instalationGroups';
import apiPathFactory from '../../../core/old_common/rooting/apiPathFactory';

export const getInstalationGroups = {
  base: () => ({
    type: INSTALATION_GROUPS_GET.BASE,
  }),
  request: () => ({ type: INSTALATION_GROUPS_GET.REQUEST }),
  success: (result) => ({ type: INSTALATION_GROUPS_GET.SUCCESS, result }),
  failure: (error) => ({ type: INSTALATION_GROUPS_GET.FAILURE, error }),
};

export const getInstalationGroup = {
  base: (facilityId, groupId) => ({
    type: INSTALATIONS_GROUP_GET.BASE,
    facilityId,
    groupId,
  }),
  request: () => ({ type: INSTALATIONS_GROUP_GET.REQUEST }),
  success: (result) => ({ type: INSTALATIONS_GROUP_GET.SUCCESS, result }),
  failure: (error) => ({ type: INSTALATIONS_GROUP_GET.FAILURE, error }),
};

export const getInstalationGroupsUniversals = {
  base: (facilityId, facilityGroupId, include) => ({
    type: INSTALATION_GROUP_UNIVERSALS_GET.BASE,
    facilityId,
    facilityGroupId,
    include,
  }),
  request: () => ({ type: INSTALATION_GROUP_UNIVERSALS_GET.REQUEST }),
  success: (result) => ({ type: INSTALATION_GROUP_UNIVERSALS_GET.SUCCESS, result }),
  failure: (error) => ({ type: INSTALATION_GROUP_UNIVERSALS_GET.FAILURE, error }),
};
export const getSecondaryInstalationGroups = {
  base: () => ({
    type: INSTALATION_GROUPS_SECONDARY_GET.BASE,
  }),
  request: () => ({ type: INSTALATION_GROUPS_SECONDARY_GET.REQUEST }),
  success: (result) => ({ type: INSTALATION_GROUPS_SECONDARY_GET.SUCCESS, result }),
  failure: (error) => ({ type: INSTALATION_GROUPS_SECONDARY_GET.FAILURE, error }),
};

export const getInstalationGroupsFobsCount = {
  base: (instalationID, instalationGroupID) => ({
    type: INSTALATION_GROUPS_FOBS_COUNT.BASE,
    instalationID,
    instalationGroupID,
  }),
  request: () => ({ type: INSTALATION_GROUPS_FOBS_COUNT.REQUEST }),
  success: (result) => ({ type: INSTALATION_GROUPS_FOBS_COUNT.SUCCESS, result }),
  failure: (error) => ({ type: INSTALATION_GROUPS_FOBS_COUNT.FAILURE, error }),
};

export const putInstalationGroups = {
  base: (instalationGroup) => ({
    type: INSTALATION_GROUPS_PUT.BASE,
    instalationGroup,
  }),
  request: () => ({ type: INSTALATION_GROUPS_PUT.REQUEST }),
  success: (result, payload) => ({ type: INSTALATION_GROUPS_PUT.SUCCESS, result, payload }),
  failure: (error) => ({ type: INSTALATION_GROUPS_PUT.FAILURE, error }),
};

export const postInstalationGroups = {
  base: (instalationGroup) => ({
    type: INSTALATION_GROUPS_POST.BASE,
    instalationGroup,
  }),
  request: () => ({ type: INSTALATION_GROUPS_POST.REQUEST }),
  success: (result, payload) => ({ type: INSTALATION_GROUPS_POST.SUCCESS, result, payload }),
  failure: (error) => ({ type: INSTALATION_GROUPS_POST.FAILURE, error }),
};

export const deleteInstalationGroups = {
  base: (id) => ({
    type: INSTALATION_GROUPS_DELETE.BASE,
    id,
  }),
  request: () => ({ type: INSTALATION_GROUPS_DELETE.REQUEST }),
  success: (result, payload) => ({ type: INSTALATION_GROUPS_DELETE.SUCCESS, result, payload }),
  failure: (error) => ({ type: INSTALATION_GROUPS_DELETE.FAILURE, error }),
};

export const getInstalationGroupAssigned = {
  base: (facilityId, equipmentId) => ({
    type: INSTALATION_GROUPS_ASSIGNED_GET.BASE,
    facilityId,
    equipmentId,
  }),
  request: () => ({ type: INSTALATION_GROUPS_ASSIGNED_GET.REQUEST }),
  success: (result) => ({ type: INSTALATION_GROUPS_ASSIGNED_GET.SUCCESS, result }),
  failure: (error) => ({ type: INSTALATION_GROUPS_ASSIGNED_GET.FAILURE, error }),
};

export const getGroupFobHistory = {
  base: (facilityId, facilityGroupId, groupFobId) => ({
    type: GET_GROUP_FOB_HISTORY.BASE,
    facilityId,
    facilityGroupId,
    groupFobId,
  }),
  request: (facilityId, facilityGroupId, groupFobId) => ({
    type: GET_GROUP_FOB_HISTORY.REQUEST,
    facilityId,
    facilityGroupId,
    groupFobId,
  }),
  success: (result) => ({ type: GET_GROUP_FOB_HISTORY.SUCCESS, result }),
  failure: (error) => ({ type: GET_GROUP_FOB_HISTORY.FAILURE, error }),
};

export function* getInstalationsGroupsSaga({ instalationID }) {
  try {
    const url = apiPathFactory.facilityGroupsGet(instalationID);
    const response = yield call(() => fetchEntitySaga(getInstalationGroups, api.get, url));
    return response;
  } catch (error) {
    return error;
  }
}

export function* getInstalationGroupSaga({ facilityId, groupId }) {
  try {
    const url = apiPathFactory.facilityGroupGet(facilityId, groupId);
    const response = yield call(() => fetchEntitySaga(getInstalationGroup, api.get, url));
    return response;
  } catch (error) {
    return error;
  }
}

export function* getInstalationGroupAssignedSaga({ facilityId, equipmentId }) {
  try {
    const url = apiPathFactory.assignedFacilityGroupsGet(facilityId, equipmentId);
    const response = yield call(() => fetchEntitySaga(getInstalationGroupAssigned, api.get, url));
    return response;
  } catch (error) {
    return error;
  }
}

export function* getInstalationGroupsUniversalsSaga({ facilityId, facilityGroupId, include }) {
  try {
    const url = apiPathFactory.facilityGroupsGetUniversals({
      facilityId,
      facilityGroupId,
      include,
    });
    const response = yield call(() =>
      fetchEntitySaga(getInstalationGroupsUniversals, api.get, url)
    );
    return response;
  } catch (error) {
    return error;
  }
}

export function* getSecondaryInstalationGroupsSaga({ instalationID, groupId }) {
  try {
    const url = apiPathFactory.facilityGroupsGetSingleList(instalationID, groupId, true);
    const response = yield call(() => fetchEntitySaga(getSecondaryInstalationGroups, api.get, url));
    return response;
  } catch (error) {
    return error;
  }
}

export function* getInstalationGroupsFobsCountSaga({ instalationID, instalationGroupID }) {
  try {
    const url = apiPathFactory.facilityGroupsGetAvailableFobs(instalationID, instalationGroupID);
    const response = yield call(() => fetchEntitySaga(getInstalationGroupsFobsCount, api.get, url));
    return response;
  } catch (error) {
    return error;
  }
}

export function* putInstalationGroupsSaga({ instalationGroup }) {
  try {
    const url = apiPathFactory.facilityGroupsPut(instalationGroup.facilityId, instalationGroup.id);
    const response = yield call(() =>
      fetchEntitySaga(putInstalationGroups, api.put, url, { data: instalationGroup })
    );
    return response;
  } catch (error) {
    return error;
  }
}

export function* postInstalationGroupsSaga({ instalationGroup }) {
  try {
    const url = apiPathFactory.facilityGroupsPost(instalationGroup.facilityId);
    const response = yield call(() =>
      fetchEntitySaga(postInstalationGroups, api.post, url, { data: instalationGroup })
    );
    return response;
  } catch (error) {
    return error;
  }
}

export function* deleteInstalationGroupsSaga({ facilityGroup }) {
  try {
    const url = apiPathFactory.facilityGroupsDelete(facilityGroup.facilityId, facilityGroup.id);
    const response = yield call(() =>
      fetchEntitySaga(deleteInstalationGroups, api.del, url, { toastNotShow: false })
    );
    return response;
  } catch (error) {
    return error;
  }
}

export function* getGroupFobHistorySaga({ facilityId, facilityGroupId, groupFobId }) {
  try {
    const url = apiPathFactory.getGroupFobHistory(facilityId, facilityGroupId, groupFobId);
    const response = yield call(() => fetchEntitySaga(getGroupFobHistory, api.get, url));
    return response;
  } catch (error) {
    return error;
  }
}

function* watchGetInstalationGroups() {
  yield takeLatest(InstalationGroupsActions.GET_INSTALATION_GROUPS, getInstalationsGroupsSaga);
}

function* watchGetSecondaryInstalationGroups() {
  yield takeLatest(
    InstalationGroupsActions.GET_SECONDARY_INSTALATION_GROUPS,
    getSecondaryInstalationGroupsSaga
  );
}

function* watchPutInstalationGroups() {
  yield takeLatest(InstalationGroupsActions.PUT_INSTALATION_GROUPS, putInstalationGroupsSaga);
}

function* watchPostInstalationGroups() {
  yield takeLatest(InstalationGroupsActions.POST_INSTALATION_GROUPS, postInstalationGroupsSaga);
}

function* watchDeleteInstalationGroups() {
  yield takeLatest(InstalationGroupsActions.DEL_INSTALATION_GROUP, deleteInstalationGroupsSaga);
}

function* watchGetInstalationGroupsFobsCount() {
  yield takeLatest(
    InstalationGroupsActions.GET_INSTALATION_FOBS_COUNTS,
    getInstalationGroupsFobsCountSaga
  );
}

function* watchGetInstalationGroupsUniversal() {
  yield takeLatest(
    InstalationGroupsActions.GET_INSTALATION_GROUP_UNIVERSALS,
    getInstalationGroupsUniversalsSaga
  );
}

function* watchGetInstalationGroupById() {
  yield takeLatest(InstalationGroupsActions.GET_INSTALATION_GROUP, getInstalationGroupSaga);
}

function* watchGetInstalationGroupAssigned() {
  yield takeLatest(
    InstalationGroupsActions.GET_INSTALATION_GROUPS_ASSIGNED,
    getInstalationGroupAssignedSaga
  );
}

function* watchGetGroupFobHistory() {
  yield takeLatest(GET_GROUP_FOB_HISTORY.BASE, getGroupFobHistorySaga);
}

export default function* watchers() {
  yield fork(watchGetInstalationGroups);
  yield fork(watchGetSecondaryInstalationGroups);
  yield fork(watchPutInstalationGroups);
  yield fork(watchPostInstalationGroups);
  yield fork(watchDeleteInstalationGroups);
  yield fork(watchGetInstalationGroupsFobsCount);
  yield fork(watchGetInstalationGroupsUniversal);
  yield fork(watchGetInstalationGroupById);
  yield fork(watchGetInstalationGroupAssigned);
  yield fork(watchGetGroupFobHistory);
}
