import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import {
  convertStringDateToDatetime,
  getTodayFormattedStringDate,
} from '../../../core/helpers/dateParser';
import Popup from '../../Popup/Popup';
import ContentContainer from '../../ContentContainer/ContentContainer';
import {
  getEquipmentSim,
  resetEquipmentSim,
} from '../../../modules/old_to_refact/actions/equipments';
import ButtonsGroup from '../../ButtonsGroup/ButtonsGroup';
import { IconSpan } from '../../IconSpan/IconSpan';

import './GsmPopup.css';

class GsmPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      icon: undefined,
    };
  }

  componentDidUpdate(prevProps) {
    const { item, isOpen, getEquipmentSim, equipmentSim } = this.props;
    if (!prevProps.isOpen && isOpen && item) {
      getEquipmentSim(item.facilityId, item.id);
      this.setState({ icon: item.id });
    }
  }

  changeIconColor = (id) => {
    const { equipmentSim } = this.props;

    const element = document.getElementById(`connection-${this.state.icon}`);
    if (element != null) {
      document.getElementById(`connection-${this.state.icon}`).style.color =
        equipmentSim.facilityEquipmentConnected ? '#00e600' : 'red';
    }
  };

  onClose = () => {
    const { onClose, resetEquipmentSim } = this.props;
    resetEquipmentSim();
    onClose();
  };

  refreshState = () => {
    const { getEquipmentSim, item } = this.props;
    getEquipmentSim(item.facilityId, item.id);
  };

  getColumns = (value) => [
    {
      value,
    },
  ];

  getContract = () => {
    const { t, equipmentSim, dateTimeFormat } = this.props;
    const today = convertStringDateToDatetime(
      getTodayFormattedStringDate(`${dateTimeFormat?.toUpperCase()} HH:mm:ss`),
      `${dateTimeFormat?.toUpperCase()} HH:mm:ss`
    );
    if (equipmentSim && equipmentSim.contract !== null) {
      const toReturn = equipmentSim.contract.map((contr) => (
        <div className='firstList'>
          <div className='title'>{t('gsmPopUp.simContractInfo')}</div>
          <div className='divTableGsm'>
            <div className='divTableBodyGsm'>
              <div className='divTableRowGsm'>
                <div className='divTableCell'>
                  <IconSpan
                    id='signature'
                    tenants={this.props.tenants}
                    defaultColor='grey'
                    iconClassName='fas fa-file-signature'
                  />
                </div>
                <div className='divTableCell'>{t('form.name')}</div>
                <div className='divTableCell right'>{contr.name}</div>
              </div>
              <div className='divTableRowGsm'>
                <div className='divTableCell'>
                  <IconSpan
                    tenants={this.props.tenants}
                    defaultColor='grey'
                    iconClassName='fas fa-info-circle'
                  />
                </div>
                <div className='divTableCell'>{t('gsmPopUp.status')}</div>
                <div className='divTableCell right'>
                  {convertStringDateToDatetime(
                    contr.expiration,
                    `${dateTimeFormat?.toUpperCase()} HH:mm:ss`
                  ) > today && contr.expiration !== null
                    ? t('gsmPopUp.enabled')
                    : t('gsmPopUp.disabled')}
                </div>
              </div>
              <div className='divTableRowGsm'>
                <div className='divTableCell'>
                  <IconSpan
                    tenants={this.props.tenants}
                    defaultColor='grey'
                    iconClassName='far fa-calendar'
                  />
                </div>
                <div className='divTableCell'>{t('gsmPopUp.expiration')}</div>
                <div className='divTableCell right'>{contr.expiration}</div>
              </div>
            </div>
          </div>
        </div>
      ));
      return toReturn;
    }
    if (equipmentSim && equipmentSim.contract === null) {
      return (
        <div className='firstList'>
          <div className='title'>{t('gsmPopUp.simContractInfo')}</div>
          <div className='divTableInformation'>
            <div className='divTableBodyGsm'>
              <div className='divTableRowGsm'>
                <div className='divTableCell'>
                  <IconSpan
                    tenants={this.props.tenants}
                    defaultColor='grey'
                    iconClassName='fas fa-info-circle'
                  />
                </div>
                <div className='divTableCell'>{t('gsmPopUp.infoNotAvailable')}</div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return '';
  };

  getContractInfo = () => {
    const { t, equipmentSim, dateTimeFormat } = this.props;
    const volumenDataSetter = {
      1: 'paramsName.Configuration_Params_LOW',
      2: 'paramsName.Configuration_Params_HIGH',
    };
    const today = convertStringDateToDatetime(
      getTodayFormattedStringDate(`${dateTimeFormat?.toUpperCase()} HH:mm:ss`),
      `${dateTimeFormat?.toUpperCase()} HH:mm:ss`
    );

    if (equipmentSim) {
      return (
        <div className='divTableBodyGsm'>
          <div className='divTableRowGsm'>
            <div className='divTableCell center'>{t('gsmPopUp.contractStatus')}</div>
          </div>
          <div className='divTableRowGsm'>
            <div className='divTableCell center'>
              <IconSpan
                tenants={this.props.tenants}
                defaultColor={
                  equipmentSim.contract !== null
                    ? convertStringDateToDatetime(
                        equipmentSim.contract[0].expiration,
                        `${dateTimeFormat?.toUpperCase()} HH:mm:ss`
                      ) > today
                      ? 'green'
                      : 'red'
                    : 'red'
                }
                iconClassName='fas fa-file-signature icon'
              />
            </div>
          </div>
          <div className='divTableRowGsm'>
            <div className='divTableCell center'>
              {equipmentSim.contract
                ? convertStringDateToDatetime(
                    equipmentSim.contract[0].expiration,
                    `${dateTimeFormat?.toUpperCase()} HH:mm:ss`
                  ) > today
                  ? t('gsmPopUp.enabled')
                  : t('gsmPopUp.disabled')
                : t('gsmPopUp.notAvailable')}
            </div>
          </div>
        </div>
      );
    }
  };

  getSimInfo = () => {
    const { t, equipmentSim } = this.props;
    if (equipmentSim) {
      return (
        <div className='divTableBodyGsm'>
          <div className='divTableRowGsm'>
            <div className='divTableCell center'>{t('gsmPopUp.simStatus')}</div>
          </div>
          <div className='divTableRowGsm'>
            <div className='divTableCell center'>
              <IconSpan
                tenants={this.props.tenants}
                defaultColor={
                  equipmentSim.deviceInfo === null ||
                  equipmentSim.deviceInfo.status === null ||
                  equipmentSim.deviceInfo.status === 'disabled'
                    ? 'red'
                    : 'green'
                }
                iconClassName='fas fa-sim-card icon'
              />
            </div>
          </div>
          <div className='divTableRowGsm'>
            <div className='divTableCell center'>
              {equipmentSim.deviceInfo === null || equipmentSim.deviceInfo.status === null
                ? t('gsmPopUp.notAvailable')
                : equipmentSim.deviceInfo.status === 'disabled'
                ? t('gsmPopUp.disabled')
                : t('gsmPopUp.enabled')}
            </div>
          </div>
        </div>
      );
    }
  };

  getSignalInfo = () => {
    const { t, equipmentSim, item } = this.props;
    this.changeIconColor(item.id);
    if (equipmentSim) {
      return (
        <div className='divTableBodyGsm'>
          <div className='divTableRowGsm'>
            <div className='divTableCell center'>{t('gsmPopUp.deviceStatus')}</div>
          </div>
          <div className='divTableRowGsm'>
            <div className='divTableCell center'>
              <IconSpan
                tenants={this.props.tenants}
                defaultColor={equipmentSim.facilityEquipmentConnected ? 'green' : 'red'}
                iconClassName='fas fa-wifi icon'
                id='wifi-csl'
              />
            </div>
          </div>
          <div className='divTableRowGsm'>
            <div className='divTableCell center'>
              {equipmentSim.facilityEquipmentConnected
                ? t('gsmPopUp.connected')
                : t('gsmPopUp.disconnected')}
            </div>
          </div>
        </div>
      );
    }
  };

  getSim = () => {
    const { t, equipmentSim } = this.props;
    if (equipmentSim && equipmentSim.deviceInfo !== null && equipmentSim.deviceInfo.usage) {
      return (
        <div className='secondList'>
          <div className='title'>{t('gsmPopUp.simConnectionInfo')}</div>
          <div className='divTableGsm'>
            <div className='divTableBodyGsm'>
              <div className='divTableRowGsm'>
                <div className='divTableCell'>
                  <IconSpan
                    tenants={this.props.tenants}
                    defaultColor='grey'
                    iconClassName='fas fa-user-friends'
                  />
                </div>
                <div className='divTableCell'>{t('gsmPopUp.cardIdentifier')}</div>
                <div className='divTableCell right'>{equipmentSim.deviceInfo.iccid}</div>
              </div>
              <div className='divTableRowGsm'>
                <div className='divTableCell'>
                  <IconSpan
                    tenants={this.props.tenants}
                    defaultColor='grey'
                    iconClassName='fas fa-info-circle'
                  />
                </div>
                <div className='divTableCell'>{t('gsmPopUp.status')}</div>
                <div className='divTableCell right'>
                  {equipmentSim.deviceInfo.status === 'true'
                    ? t('gsmPopUp.enabled')
                    : t('gsmPopUp.disabled')}
                </div>
              </div>
              <div className='divTableRowGsm'>
                <div className='divTableCell'>
                  <IconSpan
                    tenants={this.props.tenants}
                    defaultColor='grey'
                    iconClassName='far fa-calendar'
                  />
                </div>
                <div className='divTableCell'>{t('gsmPopUp.activationDate')}</div>
                <div className='divTableCell right'>{equipmentSim.deviceInfo.activation_date}</div>
              </div>
              <div className='divTableRowGsm'>
                <div className='divTableCell'>
                  <IconSpan
                    tenants={this.props.tenants}
                    defaultColor='grey'
                    iconClassName='fas fa-comments'
                  />
                </div>
                <div className='divTableCell'>{t('gsmPopUp.lastMonthDataUpload')}</div>
                <div className='divTableCell right'>{equipmentSim.deviceInfo.usage.bytes_down}</div>
              </div>
              <div className='divTableRowGsm'>
                <div className='divTableCell'>
                  <IconSpan
                    tenants={this.props.tenants}
                    defaultColor='grey'
                    iconClassName='fas fa-comments'
                  />
                </div>
                <div className='divTableCell'>{t('gsmPopUp.lastMonthDataDownload')}</div>
                <div className='divTableCell right'>{equipmentSim.deviceInfo.usage.bytes_up}</div>
              </div>
              <div className='divTableRowGsm'>
                <div className='divTableCell'>
                  <IconSpan
                    tenants={this.props.tenants}
                    defaultColor='grey'
                    iconClassName='fas fa-comments'
                  />
                </div>
                <div className='divTableCell'>{t('gsmPopUp.lastSeen')}</div>
                <div className='divTableCell right'>{equipmentSim.deviceInfo.usage.last_seen}</div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    if (equipmentSim.deviceInfo === null) {
      return (
        <div className='secondList'>
          <div className='title'>{t('gsmPopUp.simConnectionInfo')}</div>
          <div className='divTableInformation'>
            <div className='divTableBodyGsm'>
              <div className='divTableRowGsm'>
                <div className='divTableCell'>
                  <IconSpan
                    tenants={this.props.tenants}
                    defaultColor='grey'
                    iconClassName='fas fa-info-circle'
                  />
                </div>
                <div className='divTableCell'>{t('gsmPopUp.infoNotAvailable')}</div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  firstBlockError = () => {
    const { t } = this.props;
    return <div className='not-available-block'>{t('gsmPopUp.deviceNotSimInfo')}</div>;
  };

  render() {
    const { isOpen, t, equipmentSim, equipmentSimIsCalled, equipmentSimError } = this.props;
    return (
      <Popup isOpen={isOpen} onClose={this.onClose} className='device-popup'>
        <ContentContainer title={t('gsmPopUp.title')}>
          <div className='gsm-container'>
            <div className='gsm-firstblock'>
              {equipmentSim && equipmentSimIsCalled
                ? equipmentSim.contract === null && equipmentSim.deviceInfo === null
                  ? ''
                  : this.getContract()
                : ''}
              {equipmentSim && equipmentSimIsCalled
                ? equipmentSim.contract === null && equipmentSim.deviceInfo === null
                  ? ''
                  : this.getSim()
                : ''}
              {equipmentSim === undefined && equipmentSimIsCalled ? (
                <div className='not-available-block'>{t('gsmPopUp.deviceNotSimInfo')}</div>
              ) : (
                ''
              )}
            </div>
            {equipmentSimIsCalled && equipmentSim ? (
              equipmentSim.contract === null && equipmentSim.deviceInfo === null ? (
                ''
              ) : (
                <div className='line' />
              )
            ) : (
              ''
            )}
            {/* <div className='line'></div> */}
            <div className='gsm-secondblock'>
              {equipmentSim ? (
                this.getContractInfo()
              ) : equipmentSimIsCalled ? (
                <div className='divTableBodyGsm'>
                  <div className='divTableRowGsm'>
                    <div className='divTableCell center'>{t('gsmPopUp.contractStatus')}</div>
                  </div>
                  <div className='divTableRowGsm'>
                    <div className='divTableCell center'>
                      <IconSpan
                        tenants={this.props.tenants}
                        defaultColor='red'
                        iconClassName='fas fa-file-signature icon'
                      />
                    </div>
                  </div>
                  <div className='divTableRowGsm'>
                    <div className='divTableCell center'>{t('gsmPopUp.notAvailable')}</div>
                  </div>
                </div>
              ) : (
                ''
              )}
              <div className='divTableBodyGsm line-column'>
                <div className='divTableRowGsm'>
                  <div className='divTableCell'>&nbsp;</div>
                </div>
                <div className='divTableRowGsm'>
                  <div className='divTableCell'>&nbsp;</div>
                </div>
                <div className='divTableRowGsm'>
                  {equipmentSimIsCalled ? <hr className='connection-line' /> : ''}
                </div>
              </div>
              {equipmentSim ? (
                this.getSimInfo()
              ) : equipmentSimIsCalled ? (
                <div className='divTableBodyGsm'>
                  <div className='divTableRowGsm'>
                    <div className='divTableCell center'>{t('gsmPopUp.simStatus')}</div>
                  </div>
                  <div className='divTableRowGsm'>
                    <div className='divTableCell center'>
                      <IconSpan
                        tenants={this.props.tenants}
                        defaultColor='red'
                        iconClassName='fas fa-sim-card icon'
                      />
                    </div>
                  </div>
                  <div className='divTableRowGsm'>
                    <div className='divTableCell center'>{t('gsmPopUp.notAvailable')}</div>
                  </div>
                </div>
              ) : (
                ''
              )}
              <div className='divTableBodyGsm line-column'>
                <div className='divTableRowGsm'>
                  <div className='divTableCell'>&nbsp;</div>
                </div>
                <div className='divTableRowGsm'>
                  <div className='divTableCell'>&nbsp;</div>
                </div>
                <div className='divTableRowGsm'>
                  {equipmentSimIsCalled ? <hr className='connection-line' /> : ''}
                </div>
              </div>
              {equipmentSim ? (
                this.getSignalInfo()
              ) : equipmentSimIsCalled ? (
                <div className='divTableBodyGsm'>
                  <div className='divTableRowGsm'>
                    <div className='divTableCell center'>{t('gsmPopUp.deviceStatus')}</div>
                  </div>
                  <div className='divTableRowGsm'>
                    <div className='divTableCell center'>
                      <IconSpan
                        tenants={this.props.tenants}
                        defaultColor='red'
                        iconClassName='fas fa-wifi icon'
                      />
                    </div>
                  </div>
                  <div className='divTableRowGsm'>
                    <div className='divTableCell center'>{t('gsmPopUp.disconnected')}</div>
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
          <div>
            <span className='refresh-button-container'>
              <ButtonsGroup
                buttons={[
                  {
                    id: 'refreshButton-remote-state',
                    className: 'refresh-button',
                    onClick: this.refreshState,
                    value: t('remoteState.refreshStatus'),
                  },
                ]}
              />
            </span>
          </div>
        </ContentContainer>
      </Popup>
    );
  }
}

const mapStateToProps = (state) => ({
  tenants: state.tenants,
  showLoading: state.loading.show,
  equipmentSim: state.equipments.equipmentSim,
  driverState: state.devices.driverState,
  equipmentSimIsCalled: state.equipments.equipmentSimIsCalled,
  equipmentSimError: state.equipments.equipmentSimError,
  dateTimeFormat: state.user.user?.dateTimeFormat,
});

const GsmPopupWithTranslation = withTranslation()(GsmPopup);
const GsmPopupWithHistory = withRouter(GsmPopupWithTranslation);

export default connect(mapStateToProps, { getEquipmentSim, resetEquipmentSim })(
  GsmPopupWithHistory
);
