export default {
  updateInstalationForm: {
    contact: 'Contact',
    telephone: 'Téléphone',
    address: 'Adresse',
    country: 'Pays',
    saveButton: 'Sauvegarder',
    maintenanceDate: "Prochaine date d'entretien",
    createFacility: 'Créer une installation',
    installerCode: 'Utiliser le code installateur',
    installerCodeIconTooltip: 'Installer code',
    comment: 'commentaires',
    createFacilityGroup: "Créer un groupe d'installation",
    uniqueIdentity: 'Identifiant unique',
    geolocalization: 'Ensemble de géolocalisation',
    exportUsersGroups: 'Exporter les utilisateurs',
    noneGeolocalization: 'Géolocalisation non définie',
    addUniversalGroup: 'Ajouter un groupe universel',
    needSetLocation: 'Vous devez ajouter un emplacement spécifique',
    exportUsersTitle: 'exporter_utilisateurs',
    creationDate: 'Date de creation',
    installerCodeFirstMessage: 'Assurez-vous de travailler avec le code d’installateur.',
    installerCodeSecondMessage: 'En cas de doute, veuillez contacter votre administrateur'
  },
};
