import { useSelector } from 'react-redux';
import { Flex } from '@jcm-technologies/uikit/dist/atoms/Layout';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Table from '../Table';
import { getECodeTranslated } from '../../../../core/old_common/utils/facilityService';
import { actionString, mapperNameLog, typeString } from '../config';
import { ExcelExportLogs } from '../Excel/Export';

const LogsBox = ({ lastSearchValue }) => {
  const { facilityLogs, facility } = useSelector((state) => state.instalations);
  const { t } = useTranslation();
  return (
    <Flex height='100%' flexDirection='column' justifyContent='space-between'>
      <Table
        lastSearchValue={lastSearchValue}
        data={
          facilityLogs?.map((log) => ({
            ...log,
            name: log.type === 'Fob' ? getECodeTranslated(log.name) : mapperNameLog(log.name) || '',
            action: t(actionString[log.action]),
            type: t(typeString[log.type]),
            info:
              (log?.info?.includes('Fob') && `${t('title.fobs')} - ${log?.info.split('-')[1]}`) ||
              log?.info,
          })) || []
        }
      />
      <Flex alignItems='center' justifyContent='space-between' marginY={2}>
        <ExcelExportLogs
          facilityName={facility?.name}
          data={
            facilityLogs?.map((log) => ({
              ...log,
              name:
                log.type === 'Fob' ? getECodeTranslated(log.name) : mapperNameLog(log.name) || '',
              action: t(actionString[log.action]),
              type: t(typeString[log.type]),
            })) || []
          }
        />
      </Flex>
    </Flex>
  );
};

export default LogsBox;

LogsBox.propTypes = {
  lastSearchValue: PropTypes.string,
};

LogsBox.defaultProps = {
  lastSearchValue: '',
};
