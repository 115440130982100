import createFetchTypes from '../../../core/typeActions/types';
import { GET_LIST_GROUP_FOB_HISTORY } from '../actions/groups';

export const GROUP_GET_LIST = createFetchTypes('GROUP_GET_LIST');

/* Redux state init */
const initialState = {
  groups: [],
  needCallGroups: true,
};

export default function groupsReducer(state = initialState, action) {
  if (action.type !== undefined) {
    switch (action.type) {
      case GROUP_GET_LIST.SUCCESS: {
        const { overallPages, overallRows, currentPage, pageItems } = action.result;
        return {
          ...state,
          overallPages,
          currentPage,
          overallRows,
          groups: pageItems,
          needCallGroups: true,
        };
      }
      case GET_LIST_GROUP_FOB_HISTORY.REQUEST:
      case GROUP_GET_LIST.REQUEST:
        return {
          ...state,
          groups: [],
          needCallGroups: false,
        };
      case GET_LIST_GROUP_FOB_HISTORY.SUCCESS: {
        const { overallPages, overallRows, currentPage, pageItems } = action.result;
        return {
          ...state,
          overallPages,
          currentPage,
          overallRows,
          groups: pageItems,
          needCallGroups: true,
        };
      }
      default:
        return state;
    }
  }
  return state;
}
