import errorsAction from '../actions/errors';

export const API_CALL_FAILURE = 'API_CALL_FAILURE';

/* Redux state init */
const initialState = {
  isError: false,
  errorStatus: '',
  errorMessage: '',
  toastNotShow: undefined,
};

export default function errorsReducer(state = initialState, action) {
  if (action.type !== undefined) {
    switch (action.type) {
      case API_CALL_FAILURE:
        return {
          ...state,
          isError: true,
          errorStatus: action.error.response.body.error.code,
          errorMessage: action.error.response.body.error.message,
          toastNotShow: action.toastNotShow ? action.toastNotShow : false,
          infoError: action.error.response.body.error.ExtendedInfo1,
          listError: action.error.response.body.error.ExtendedInfo2,
        };
      case errorsAction.RESET_ERRORS:
        return initialState;
      default:
        return state;
    }
  }
  return state;
}
