import { call, fork, takeLatest } from 'redux-saga/effects';
import fetchEntitySaga from '../old_to_refact/sagas/utils/fetchEntitySaga';
import apiPathFactory from '../../core/old_common/rooting/apiPathFactory';
import api from '../../core/helpers/api';
import {
  DEL_HOLIDAYS,
  GET_HOLIDAYS,
  GET_HOLIDAYS_FULL,
  SET_HOLIDAYS,
} from './constants';


export const getHolidays = {
  base: () => ({
    type: GET_HOLIDAYS.BASE,
  }),
  request: () => ({ type: GET_HOLIDAYS.REQUEST }),
  success: (result) => ({ type: GET_HOLIDAYS.SUCCESS, result }),
  failure: (error) => ({ type: GET_HOLIDAYS.FAILURE, error }),
};

export const getHolidaysFull = {
  base: () => ({
    type: GET_HOLIDAYS_FULL.BASE,
  }),
  request: () => ({ type: GET_HOLIDAYS_FULL.REQUEST }),
  success: (result) => ({ type: GET_HOLIDAYS_FULL.SUCCESS, result }),
  failure: (error) => ({ type: GET_HOLIDAYS_FULL.FAILURE, error }),
};

export const setHolidays = {
  base: (id, data) => ({
    type: SET_HOLIDAYS.BASE,
    id,
    data,
  }),
  request: () => ({ type: SET_HOLIDAYS.REQUEST }),
  success: (result) => ({ type: SET_HOLIDAYS.SUCCESS, result }),
  failure: (error) => ({ type: SET_HOLIDAYS.FAILURE, error }),
};

export const delHolidays = {
  base: (id, data) => ({
    type: DEL_HOLIDAYS.BASE,
    id,
    data,
  }),
  request: () => ({ type: DEL_HOLIDAYS.REQUEST }),
  success: (result) => ({ type: DEL_HOLIDAYS.SUCCESS, result }),
  failure: (error) => ({ type: DEL_HOLIDAYS.FAILURE, error }),
};

export function* getHolidaysSaga() {
  try {
    const url = apiPathFactory.getHolidays();
    const response = yield call(() => fetchEntitySaga(getHolidays, api.get, url));
    return response;
  } catch (error) {
    return error;
  }
}

export function* getHolidaysFullSaga() {
  try {
    const url = apiPathFactory.getHolidaysFull();
    const response = yield call(() => fetchEntitySaga(getHolidaysFull, api.get, url));
    return response;
  } catch (error) {
    return error;
  }
}

export function* setHolidaysSaga({ id, data }) {
  try {
    const url = apiPathFactory.setHolidays(id);
    const response = yield call(() =>
      fetchEntitySaga(setHolidays, api.put, url, {
        data,
      })
    );
    yield call(getHolidaysSaga, {});
    return response;
  } catch (error) {
    return error;
  }
}

export function* delHolidaysSaga({ id, data }) {
  try {
    const url = apiPathFactory.delHolidays(id, data);
    const response = yield call(() => fetchEntitySaga(delHolidays, api.del, url));
    return response;
  } catch (error) {
    return error;
  }
}

function* watchDashboard() {
  yield takeLatest(GET_HOLIDAYS_FULL.BASE, getHolidaysFullSaga);
  yield takeLatest(GET_HOLIDAYS.BASE, getHolidaysSaga);
  yield takeLatest(SET_HOLIDAYS.BASE, setHolidaysSaga);
  yield takeLatest(DEL_HOLIDAYS.BASE, delHolidaysSaga);
}

export default function* watchers() {
  yield fork(watchDashboard);
}
