import {
  SET_SEARCH_QUERY,
  SET_SELECTED_CITIES,
  SET_SELECTED_DESCENDANTS,
  SET_SELECTED_POSTALCODES,
} from './constants';

export const setSelectedDescendants = (descType, selectedDescendants) => ({
  type: SET_SELECTED_DESCENDANTS,
  descType,
  selectedDescendants,
});

export const setSelectedCities = (selectedCities) => ({
  type: SET_SELECTED_CITIES,
  selectedCities,
});

export const setSelectedPostalCodes = (selectedPostalCodes) => ({
  type: SET_SELECTED_POSTALCODES,
  selectedPostalCodes,
});

export const setSearchQuery = (searchQuery, viewMore) => ({
  type: SET_SEARCH_QUERY,
  searchQuery,
  viewMore,
});
