import { Box, Flex } from '@jcm-technologies/uikit/dist/atoms/Layout';
import { Icon } from '@jcm-technologies/uikit/dist/atoms/Icon';
import IconChevronDown from '@jcm-technologies/uikit/dist/atoms/Icons/IconChevronDown';
import IconChevronUp from '@jcm-technologies/uikit/dist/atoms/Icons/IconChevronUp';
import { Text } from '@jcm-technologies/uikit/dist/atoms/Typography';
import { Input } from '@jcm-technologies/uikit/dist/atoms/Input';
import { Checkbox } from '@jcm-technologies/uikit/dist/atoms/Checkbox';
import { Card } from '@jcm-technologies/uikit/dist/molecules/Card';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { IconTooltip } from '@jcm-technologies/uikit/dist/molecules/IconTooltip';
import PropTypes from 'prop-types';
import { getNewArray } from '../../../../../core/helpers/arrayService';
import CustomizationFamiliesProductItem from '../ProductItem';

const CustomizationFamiliesItem = ({
  family,
  familyIndex,
  families,
  onModifyFamilyState,
  onChangeNameFamily,
  onCheckProductItem,
  onCheckFamilyItem,
  onChangeNameProduct,
}) => {
  const { products } = family;
  const { t } = useTranslation();
  const [isOpened, setIsOpened] = useState(false);

  useEffect(() => {
    if (products) {
      onModifyFamilyState(families);
    }
    if (!products) {
      setIsOpened(false);
    }
  }, [products]);

  return (
    <Card marginBottom={1} padding={1} key={family.id} >
      <Flex alignItems='center' justifyContent='space-between'>
        <Flex
          width='20%'
          onClick={() => {
            setIsOpened(!isOpened);
            const newItemFamilyModified = getNewArray(
              families,
              familyIndex,
              'isProductsVisible',
              !isOpened
            );
            onModifyFamilyState(newItemFamilyModified);
          }}
          style={{
            cursor: 'pointer',
          }}
        >
          <IconTooltip
            color='red'
            sizeIcon='display16'
            toolTipContent={family?.isProductsVisible ? t('general.collapse') : t('general.expand')}
            display='flex'
          >
            <Icon sizeIcon='display24' marginRight={1}>
              {isOpened ? <IconChevronUp /> : <IconChevronDown />}
            </Icon>
            <Text>{family.masterName}</Text>
          </IconTooltip>
        </Flex>
        <Flex width='60%'>
          <Input
            width='100%'
            defaultValue={family.name}
            id={family.id}
            name={family.id}
            hasError={family.error}
            errorMessage={t('formValidation.isRequired')}
            onBlur={(name, value) => onChangeNameFamily(name, value, family, familyIndex)}
          />
        </Flex>
        <Flex>
          <IconTooltip
            color='red'
            sizeIcon='display16'
            toolTipContent={family.isEnabled ? t('form.disable') : t('form.enable')}
          >
            <Checkbox
              id={family.id}
              name={family.id}
              hasError={!family.isEnabled}
              defaultChecked={family.isEnabled}
              action={(name, value) => {
                onCheckFamilyItem(name, value, family, familyIndex);
                setIsOpened(true);
              }}
            />
          </IconTooltip>
        </Flex>
      </Flex>
      {isOpened && family?.products?.length && (
        <>
          <hr />
          <Box backgroundColor='whiteSmooth' borderRadius={2} padding={1}>
            <Flex
              alignItems='center'
              justifyContent='space-between'
              paddingX={4}
              backgroundColor='whiteSmooth'
              borderRadius={2}
            >
              <Flex width='20%'>
                <Text fontWeight='bold'>{t('form.standard')}</Text>
              </Flex>
              <Flex width='60%'>
                <Text fontWeight='bold'>{t('form.custom')}</Text>
              </Flex>
              <Flex />
            </Flex>
            {family?.products.map((product, productIndex) => (
              <CustomizationFamiliesProductItem
                key={product.id}
                family={family}
                familyIndex={familyIndex}
                productIndex={productIndex}
                onChangeNameProduct={onChangeNameProduct}
                onCheckProductItem={onCheckProductItem}
                product={product}
              />
            ))}
          </Box>
        </>
      )}
    </Card>
  );
};

export default CustomizationFamiliesItem;

CustomizationFamiliesItem.propTypes = {
  family: PropTypes.shape({
    id: PropTypes.string,
    isEnabled: PropTypes.bool,
    name: PropTypes.string,
    masterName: PropTypes.string,
    error: PropTypes.bool,
    isProductsVisible: PropTypes.bool,
    products: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      })
    ),
  }).isRequired,
  familyIndex: PropTypes.number.isRequired,
  families: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onModifyFamilyState: PropTypes.func.isRequired,
  onChangeNameFamily: PropTypes.func.isRequired,
  onCheckProductItem: PropTypes.func.isRequired,
  onCheckFamilyItem: PropTypes.func.isRequired,
  onChangeNameProduct: PropTypes.func.isRequired,
};
