import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { Loading } from '@jcm-technologies/uikit/dist/atoms/Loading';
import CustomizationLayout from '../../../../components/Administration/Customization/Layout';
import { getTenants } from '../../../../modules/tenants/actions';

export const Edit = () => {
  const dispatch = useDispatch();
  const { color1 } = useSelector((state) => state.tenants);

  useEffect(() => {
    if (!color1) {
      dispatch(getTenants());
    }
  }, [color1]);

  if (color1) return <CustomizationLayout />;
  return <Loading show />;
};
