const GET_5KSERVICES_REMOTE_COMUNICATIONS = 'GET_5KSERVICES_REMOTE_COMUNICATIONS';
const GET_5KSERVICES_ONLINE_DEVICES = 'GET_5KSERVICES_ONLINE_DEVICES';
const POST_SET_MEMORY = 'POST_SET_MEMORY';
const POST_READ_MEMORY = 'POST_READ_MEMORY';
const POST_READ_EVENTS = 'POST_READ_EVENTS';
const POST_FORMAT_EVENTS = 'POST_FORMAT_EVENTS';
const POST_GET_EVENTS = 'POST_GET_EVENTS';
const RESET_NEED_UPDATE_LIST = 'RESET_NEED_UPDATE_LIST';
const POST_5KSERVICES_COMPARE_FOBS = 'POST_5KSERVICES_COMPARE_FOBS';
const RESET_A5KSERVICES_EVENTS = 'RESET_A5KSERVICES_EVENTS';

export default {
  GET_5KSERVICES_REMOTE_COMUNICATIONS,
  POST_GET_EVENTS,
  GET_5KSERVICES_ONLINE_DEVICES,
  POST_SET_MEMORY,
  POST_READ_MEMORY,
  POST_READ_EVENTS,
  POST_FORMAT_EVENTS,
  RESET_NEED_UPDATE_LIST,
  POST_5KSERVICES_COMPARE_FOBS,
  RESET_A5KSERVICES_EVENTS,
};

export const getOnlineServices = (facilityId, equipmentId) => ({
  type: GET_5KSERVICES_ONLINE_DEVICES,
  facilityId,
  equipmentId,
});
export const getRemoteComunication = (facilityId, equipmentId) => ({
  type: GET_5KSERVICES_REMOTE_COMUNICATIONS,
  facilityId,
  equipmentId,
});
export const getEvents = (facilityId, equipmentId, file, index) => ({
  type: POST_GET_EVENTS,
  facilityId,
  equipmentId,
  file,
  index,
});
export const resetNeedUpdateList = () => ({ type: RESET_NEED_UPDATE_LIST });
export const postCompareFobs = (facilityId, id, cc, ic, file) => ({
  type: POST_5KSERVICES_COMPARE_FOBS,
  facilityId,
  id,
  ic,
  cc,
  file,
});
export const resetA5kEvents = () => ({ type: RESET_A5KSERVICES_EVENTS });

export const postReadMemory = (facilityId, equipmentId, ic, cc) => ({
  type: POST_READ_MEMORY,
  facilityId,
  equipmentId,
  ic,
  cc,
});
export const postSetMemory = (facilityId, equipmentId, ic, cc) => ({
  type: POST_SET_MEMORY,
  facilityId,
  equipmentId,
  ic,
  cc,
});
export const postReadEvents = (facilityId, equipmentId) => ({
  type: POST_READ_EVENTS,
  facilityId,
  equipmentId,
});
export const postFormatEvents = (facilityId, equipmentId) => ({
  type: POST_FORMAT_EVENTS,
  facilityId,
  equipmentId,
});
