export default {
  adjustTimePopUp: {
    manualDateTimeAdjust: 'Ajustar fecha hora y hora manualmente',
    actualHour: 'Hora actual:',
    minutesCompensation: 'Compensación de minutos a partir de ahora',
    insertSdMemory: 'Insertar la memoria SD y alimentar el dispositivo a la siguiente hora',
    inputTime: 'Indica el tiempo',
    save: 'Guardar',
  },
};
