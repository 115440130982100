export default {
  formValidation: {
    notSameValue: 'Is not the same value',
    notSamePassword: 'Passwords do not match',
    numericField: 'This field is numeric',
    minNumber: 'Minimum value is 1',
    passwordFormat:
      'Password needs to contain minimum eight characters, at least one capital letter, one number and one special character',
    isNotValidDate: 'Wrong date format, format: YYYY-MM-DDTHH:mm:ss',
    isRequired: 'Field required',
    outOfRange: 'Number out of range',
    maxLength: 'The maximum characters length is',
    regEx: 'Incorrect format. Use this characters ',
    deviceLimit: 'Device limit reached',
    onlySpaces: 'Only blank spaces are not allowed',
    isReservedWord: "it's a reserved word",
  },
};
