import React, { Component } from 'react';
import { connect } from 'react-redux';
import StyledSpan from './StyledSpan';
import StyledSelect from './StyledSelect';
import ErrorMessage from '../ErrorMessage/ErrorMessage';

import './ComboboxFlyer.css';

export class ComboboxFlyer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFocused: false,
    };
  }

  onFocus = () => {
    const { isFocused } = this.state;
    this.setState({ isFocused: !isFocused });
  };

  onBlur = (e) => {
    const { onBlur } = this.props;
    this.onFocus();
    if (onBlur) {
      onBlur(e);
    }
  };

  getSelect = () => {
    const { tenants, options, placeHolder, className, disabled } = this.props;
    return (
      <StyledSelect
        tenants={tenants}
        {...this.props}
        onBlur={this.onBlur}
        disabled={disabled}
        className={
          placeHolder
            ? 'combobox'
            : className === 'timezone-wrong'
            ? 'combobox-no-placeholder timezone-wrong'
            : 'combobox-no-placeholder'
        }
      >
        {options}
      </StyledSelect>
    );
  };

  render() {
    const { value, tenants, options, placeHolder, error, className, disabled } = this.props;
    const { isFocused } = this.state;
    return (
      <>
        {placeHolder ? (
          <label
            className={`jcm-label ${className || ''} ${disabled ? 'jcm-label-disabled' : ''}`}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
          >
            {this.getSelect()}
            <StyledSpan
              tenants={tenants}
              value={
                value ||
                (options[0] && Object.keys(options[0].props).length > 0
                  ? options[0].props.children
                  : '')
              }
              isFocused={isFocused}
            >
              {placeHolder}
            </StyledSpan>
            {error ? <ErrorMessage message={error.errorMessage} /> : null}
          </label>
        ) : (
          this.getSelect()
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  tenants: state.tenants,
});

export default connect(mapStateToProps, {})(ComboboxFlyer);
