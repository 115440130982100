import { Modal } from '@jcm-technologies/uikit/dist/molecules/Modal';
import { Flex } from '@jcm-technologies/uikit/dist/atoms/Layout';
import { Text } from '@jcm-technologies/uikit/dist/atoms/Typography';
import { Button } from '@jcm-technologies/uikit/dist/atoms/Button';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getContrastColor } from '../../../../../core/helpers/colorManager';

export const DeleteInvitationLicensesModal = ({
  isOpenedDeleteModal,
  setIsOpenedDeleteModal,
  setIdToDelete,
  deleteInvitationLicense,
}) => {
  const { t } = useTranslation();
  const { color1 } = useSelector((state) => state.tenants);
  return (
    <Modal
      isOpen={isOpenedDeleteModal}
      closeWithButton
      id='delete-invitation'
      title='delete invitation'
      onModalClose={() => {
        setIsOpenedDeleteModal(false);
        setIdToDelete(null);
      }}
    >
      <Modal.Content>
        <Flex>
          <Text>{t('general.deleteEntity.question', { entity: t('title.invitation') })}</Text>
        </Flex>
      </Modal.Content>
      <Modal.Actions>
        <Flex alignItems='center' justifyContent='flex-end'>
          <Button
            backgroundColor={color1}
            marginRight={2}
            color={getContrastColor(color1)}
            action={() => deleteInvitationLicense()}
          >
            {t('general.delete')}
          </Button>
          <Button
            sizeText='display14'
            backgroundColor='blackLight'
            id='cancel-button'
            action={() => {
              setIsOpenedDeleteModal(false);
              setIdToDelete(null);
            }}
          >
            {t('general.cancel')}
          </Button>
        </Flex>
      </Modal.Actions>
    </Modal>
  );
};
