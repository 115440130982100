import { call, fork, put, takeLatest } from 'redux-saga/effects';
import loginActions from '../actions/login';
import fetchEntitySaga from './utils/fetchEntitySaga';
import apiPathFactory from '../../../core/old_common/rooting/apiPathFactory';
import api from '../../../core/helpers/api';

import {
  CHANGE_PASSWORD_POST,
  LOGIN_VERSION_ACCOUNT,
  RE_LOGIN_POST,
  RESET_PASSWORD_POST,
} from '../reducers/login';

export const rePostLogin = {
  base: (refreshToken) => ({
    type: RE_LOGIN_POST.BASE,
    refreshToken,
  }),
  request: (params) => ({ type: RE_LOGIN_POST.REQUEST, params }),
  success: (result) => ({ type: RE_LOGIN_POST.SUCCESS, result }),
  failure: (error) => ({ type: RE_LOGIN_POST.FAILURE, error }),
};

export const postAccountVersion = {
  base: (version) => ({
    type: LOGIN_VERSION_ACCOUNT.BASE,
    version,
  }),
  request: () => ({ type: LOGIN_VERSION_ACCOUNT.REQUEST }),
  success: (result) => ({ type: LOGIN_VERSION_ACCOUNT.SUCCESS, result }),
  failure: (error) => ({ type: LOGIN_VERSION_ACCOUNT.FAILURE, error }),
};

export const postResetPassword = {
  base: (resetPassowordDatas) => ({
    type: RESET_PASSWORD_POST.BASE,
    resetPassowordDatas,
  }),
  request: () => ({ type: RESET_PASSWORD_POST.REQUEST }),
  success: (result) => ({ type: RESET_PASSWORD_POST.SUCCESS, result }),
  failure: (error) => ({ type: RESET_PASSWORD_POST.FAILURE, error }),
};

export function* rePostLoginSaga(params) {
  if (params?.payload?.refreshToken) {
    try {
      const url = apiPathFactory.accountsReLogin(params?.payload.refreshToken);
      const response = yield call(() => fetchEntitySaga(rePostLogin, api.get, url));
      return response;
    } catch (error) {
      return error;
    }
  }
  return false;
}

export function* postAccountVersionSaga({ version }) {
  try {
    const url = apiPathFactory.loginVersionAccountPost(version);
    const response = yield call(() => fetchEntitySaga(postAccountVersion, api.post, url));
    return response;
  } catch (error) {
    return error;
  }
}

export function* postResetPasswordSaga({ resetPassowordDatas }) {
  try {
    const url = apiPathFactory.accountsResetPassword();
    const data = JSON.stringify(resetPassowordDatas);
    const response = yield call(() => fetchEntitySaga(postResetPassword, api.post, url, { data }));
    return response;
  } catch (error) {
    return error;
  }
}

export function* postChangePasswordSaga({ changePassowordDatas }) {
  try {
    const url = apiPathFactory.accountsChangePassword();
    const data = JSON.stringify(changePassowordDatas);
    const response = yield call(() => fetchEntitySaga(postChangePassword, api.post, url, { data }));
    return response;
  } catch (error) {
    return error;
  }
}

function* watchReLoginSaga() {
  yield takeLatest(RE_LOGIN_POST.BASE, rePostLoginSaga);
}

// ----------------------------------------------------------------------------------------------

function* watchResetPasswordSaga() {
  yield takeLatest(RESET_PASSWORD_POST.BASE, postResetPasswordSaga);
}

function* dispatchResetPassword(loginAction) {
  yield put(postResetPassword.base(loginAction.payload));
}

function* watchResetPassword() {
  yield takeLatest(loginActions.POST_RESET_PASSWORD, dispatchResetPassword);
}

// ----------------------------------------------------------------------------------------------

function* watchChangePasswordSaga() {
  yield takeLatest(CHANGE_PASSWORD_POST.BASE, postChangePasswordSaga);
}

function* dispatchChangePassword(action) {
  yield put(postChangePassword.base(action.payload));
}

function* watchChangePassword() {
  yield takeLatest(loginActions.POST_CHANGE_PASSWORD, dispatchChangePassword);
}

function* watchAccountVersionSaga() {
  yield takeLatest(loginActions.POST_ACCOUNT_VERSION, postAccountVersionSaga);
}

export default function* badRuleWarningWatchers() {
  yield fork(watchReLoginSaga);
  yield fork(watchResetPassword);
  yield fork(watchResetPasswordSaga);
  yield fork(watchChangePassword);
  yield fork(watchChangePasswordSaga);
  yield fork(watchAccountVersionSaga);
}
