import React from 'react';
import { HuePicker } from 'react-color';
import './SliderColorPicker.css';
import Div from './Div';
import Button from '../GenericForm/Button';

class SliderColorPicker extends React.Component {
  render() {
    const {
      placeHolder,
      saveButtonText,
      customCSS,
      pickerColor,
      tenants,
      id,
      onChangeComplete,
      handleColorTextChange,
      onColorInputChange,
      onSaveClick,
      value,
    } = this.props;
    return (
      <div className={`${customCSS}`}>
        <div className='hueColorPicker'>
          {placeHolder}
          <Div backgroundColor={pickerColor}>
            <input
              type='text'
              onChange={onColorInputChange}
              onBlur={handleColorTextChange}
              className='pickerCode'
              value={value || ''}
            />
          </Div>
          <HuePicker
            onChangeComplete={onChangeComplete}
            color={pickerColor}
            className='picker'
            id={id}
          />
          <div className='colorPickerSaveButton'>
            <Button className='colorPickerSave' onClick={onSaveClick} tenants={tenants}>
              {saveButtonText}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
export default SliderColorPicker;
