export default {
  instalationGroups: {
    exportExcel: 'Descarregar Excel',
    importExcel: 'Importar Excel',
    exportPDF: 'Descarregar PDF',
    code: 'Codi',
    type: 'Tipus',
    name: 'Nom',
    surname: 'Cognom',
    identification: 'DNI',
    place: 'Plaça',
    enrollFOB: 'Alta de codi',
    config: 'Configurar',
    deleteConfirmation: 'Segur que voleu suprimir aquest grup?',
    description: 'Descripció',
    facilityName: 'Nom de la instal·lació',
    replace: 'Substituir',
    delete: 'Esborrar',
    deleteFobConfirmation: 'Segur que voleu suprimir aquest emissor?',
    alarms: 'Alarma',
    selectConfigFields: 'Selecciona els CAMPS D\'USUARI / EMISSORS a exportar',
    totalSelected: 'Total seleccionats'
  },
};