import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Pill } from '@jcm-technologies/uikit/dist/atoms/Pill';
import { Box, Flex } from '@jcm-technologies/uikit/dist/atoms/Layout';
import { Icon } from '@jcm-technologies/uikit/dist/atoms/Icon';
import IconClose from '@jcm-technologies/uikit/dist/atoms/Icons/IconClose';
import IconEllipsis from '@jcm-technologies/uikit/dist/atoms/Icons/IconEllipsis';
import { DropdownMenu } from '@jcm-technologies/uikit/dist/molecules/Dropdown';
import IconFile from '@jcm-technologies/uikit/dist/atoms/Icons/IconFile';
import { getVisibleDescendants } from '../../modules/old_to_refact/actions/universalGroups';
import {
  setSearchQuery,
  setSelectedCities,
  setSelectedDescendants,
  setSelectedPostalCodes,
} from '../../modules/state/visibleDescendants/actions';
import {
  getFacilitiesPDF,
  getInstalationFilters,
  getInstalations,
  resetInstalation,
} from '../../modules/old_to_refact/actions/instalations';
// import { getField } from '../../modules/old_to_refact/actions/dashboard';
import { getField } from '../../modules/dashboard/actions';
import { resetEquipments } from '../../modules/old_to_refact/actions/equipments';
import GenericList from '../GenericList';
import InstalationGrow from '../InstalationGrow';
import AddButton from '../AddButton';
import {
  CreationPath_Instalations,
  RootPath_Instalations,
} from '../../core/old_common/constats/genericListPaths';
import { resetInstalationGroups } from '../../modules/old_to_refact/actions/instalationGroups';
import { FACILITY_DESCENDANT } from '../../modules/state/visibleDescendants/constants';
import SelectableFilters from '../SelectableFilters';
import {
  deleteCookie_selectedItemCustomerId,
  getCookieAccessToken,
  getCookieFacilitiesSearch,
  getCookieUserRoles,
  saveCookies,
} from '../../core/old_common/utils/cookiesManager';
import { getContrastColor } from '../../core/helpers/colorManager';
import { FACILITY_LIST } from '../../modules/state/pagination/constants';

export class InstalationsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDescendantsChanged: false,
      haveFilter: false,
      filterSelected: null,
    };
  }

  componentDidMount = () => {
    const { facilityPostalCodes, fromDashboard, dashboardFilterId, dashboardFilterValue, isAuth } =
      this.props;
    const userToken = getCookieAccessToken();
    if (!userToken) {
      const tokenFromUrl = new URLSearchParams(window.location.search).get('token');
      saveCookies({ token: tokenFromUrl });
    }
    if (isAuth) {
      if (!fromDashboard) {
        this.props.getInstalations();
      } else {
        if (dashboardFilterId === 3) {
          this.handleCitiesFilterChange(dashboardFilterValue);
        }
        if (dashboardFilterId === 4) {
          this.handlePostalCodesFilterChange(dashboardFilterValue);
        }
        if (this.setPillFilter()) {
          this.setState({
            haveFilter: true,
            filterSelected: this.setPillFilter(),
          });
        }
      }
      if (!facilityPostalCodes) {
        this.props.getVisibleDescendants();
        this.props.getInstalationFilters();
      }

      this.props.resetEquipments();
      this.props.resetInstalationGroups();
      this.props.resetInstalation();
      deleteCookie_selectedItemCustomerId();
      if (this.props.searchQuery) {
        this.props.setSearchQuery(this.props.searchQuery, !!this.props.searchQuery);
      }
    }
  };

  componentDidUpdate(prevProps, prevState) {
    const {
      isFacilitiesListUpdated,
      isAuth,
      facilityPostalCodes,
      fromDashboard,
      dashboardFilterId,
      dashboardFilterValue,
    } = this.props;
    if (this.state.isDescendantsChanged || isFacilitiesListUpdated) {
      if (this.state.isDescendantsChanged) {
        this.setState({ isDescendantsChanged: false });
      }
      this.props.getInstalationFilters();
    }

    if (!prevProps.isAuth && isAuth) {
      if (!fromDashboard) {
        this.props.getInstalations();
      } else {
        if (dashboardFilterId === 3) {
          this.handleCitiesFilterChange(dashboardFilterValue);
        }
        if (dashboardFilterId === 4) {
          this.handlePostalCodesFilterChange(dashboardFilterValue);
        }
        if (this.setPillFilter()) {
          this.setState({
            haveFilter: true,
            filterSelected: this.setPillFilter(),
          });
        }
      }
      if (!facilityPostalCodes) {
        this.props.getVisibleDescendants();
        this.props.getInstalationFilters();
      }

      this.props.resetEquipments();
      this.props.resetInstalationGroups();
      this.props.resetInstalation();
      deleteCookie_selectedItemCustomerId();
      if (this.props.searchQuery) {
        this.props.setSearchQuery(this.props.searchQuery, !!this.props.searchQuery);
      }
    }
  }

  handlePageChange = () => {
    const { dashboardFilterId, getField, getInstalations } = this.props;
    const { haveFilter } = this.state;
    if (haveFilter) {
      getField(dashboardFilterId);
    } else {
      getInstalations();
    }
  };

  handleSelectionChange = (value) => {
    // filter type (schedules)
    const { setSelectedDescendants, getInstalations } = this.props;
    setSelectedDescendants(FACILITY_DESCENDANT, value);
    this.setState({ isDescendantsChanged: true });
    getInstalations();
  };

  handleCitiesFilterChange = (value) => {
    // filter type (schedules)
    const { setSelectedCities, getInstalations } = this.props;
    setSelectedCities(value);
    getInstalations();
  };

  handlePostalCodesFilterChange = (value) => {
    // filter type (schedules)
    const { setSelectedPostalCodes, getInstalations } = this.props;
    setSelectedPostalCodes(value);
    getInstalations();
  };

  handleSearchQueryChange = (e) => {
    // filter type (schedules)
    const { setSearchQuery } = this.props;
    setSearchQuery(
      e.currentTarget.value.replace(/%23/g, '#').replace(/%26/g, '&') || '',
      !!e.currentTarget.value
    );
  };

  getCustomStyles = () => {
    const { tenants } = this.props;
    const customStyles = {
      multiValue: (provided, state) => ({
        ...provided,
        background: tenants.color1,
        color: 'white',
        // flex: 'auto',
        flexGrow: 1,
      }),
      multiValueLabel: (provided, state) => ({
        ...provided,
        color: 'white',
        flex: 'auto',
        // flexGrow: 1,
      }),
      control: (provided, state) => ({
        ...provided,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }),
    };
    return customStyles;
  };

  getMask = () => {
    const url = window.location.href;
    const searchParams = new URLSearchParams(url);
    const nameRoleFromUrl = new URLSearchParams(window.location.search).get('userRolesName');
    const maskRoleFromUrl = new URLSearchParams(window.location.search).get('userRolesMask');
    const roles = { name: nameRoleFromUrl, mask: maskRoleFromUrl };
    saveCookies({ userRoles: roles });
    return new URLSearchParams(window.location.search).get('userRolesMask');
  };

  getVisibleDescendants = () => {
    const { t } = this.props;
    let visibleDesc = [];
    const role = getCookieUserRoles();
    const mask = role && role.mask ? role.mask : this.getMask();
    if (mask !== 8) {
      if (this.props.visibleDescendants) {
        visibleDesc = this.props.visibleDescendants.map((vDes) => ({
          label: vDes.name,
          value: vDes.id,
        }));
        visibleDesc.unshift({
          label: t('globalSearch.allOwners'),
          value: 'ffffffff-ffff-ffff-ffff-ffffffffffff',
        });
      }
    }
    return visibleDesc;
  };

  getCities = () => {
    const { facilityCities } = this.props;
    let cities = [];
    if (facilityCities) {
      cities = facilityCities.map((vDes) => ({ label: vDes.city, value: vDes.city }));
    }
    return cities;
  };

  getPostalCodes = () => {
    const { facilityPostalCodes } = this.props;
    let postalCodes = [];
    if (facilityPostalCodes) {
      postalCodes = facilityPostalCodes.map((vDes) => ({ label: vDes.zip, value: vDes.zip }));
    }
    return postalCodes;
  };

  setPillFilter = () => {
    const { dashboardFilterId, t } = this.props;
    const lastSearch = getCookieFacilitiesSearch();
    const filterSelected = {
      6: t('updateInstalationForm.uniqueIdentity'),
      7: t('form.activeAlarms'),
      8: t('form.connectedDevices'),
      9: t('form.disconnectedDevices'),
      10: t('form.missconfiguredDevices'),
      11: t('form.pendingMaintenance'),
      12: t('form.missconfiguredConnected'),
      18: t('form.missconfiguredNoConnectivity'),
    };
    return (
      (filterSelected[dashboardFilterId] &&
        `${filterSelected[dashboardFilterId]}${
          lastSearch?.value ? `: ${lastSearch?.value}` : ''
        }`) ||
      `${lastSearch?.label}: ${lastSearch?.value}`
    );
  };

  render() {
    const {
      instalations,
      t,
      selectedDescendants,
      selectedCities,
      selectedPostalCodes,
      searchQuery,
      getInstalations,
      dashboardFilterId,
      fromDashboard,
      tenants,
      getFacilitiesPDF,
    } = this.props;
    const { haveFilter, filterSelected } = this.state;
    const role = getCookieUserRoles();
    const mask = role && role.mask ? role.mask : this.getMask();
    return (
      <>
        <Flex justifyContent='space-between' alignItems='center'>
          <Flex alignSelf='baseline'>
            {haveFilter &&
            filterSelected &&
            fromDashboard &&
            dashboardFilterId !== '3' &&
            dashboardFilterId !== 3 &&
            dashboardFilterId !== '4' &&
            dashboardFilterId !== 4 ? (
              <Pill backgroundColor={tenants.color1} color={getContrastColor(tenants?.colo1)}>
                {filterSelected}
                <Icon
                  sizeIcon='display24'
                  onClick={() => {
                    this.setState({ haveFilter: false, filterSelected: null });
                    this.setState({ fromDashboard: false });
                    this.props.getInstalations();
                  }}
                >
                  <IconClose />
                </Icon>
              </Pill>
            ) : (
              <Box />
            )}
          </Flex>
          <Flex alignItems='baseline' flex={1}>
            <SelectableFilters
              isExpanded={fromDashboard && (dashboardFilterId === 3 || dashboardFilterId === 4)}
              descendatsOptions={this.getVisibleDescendants()}
              selectedDescendants={selectedDescendants}
              onChangeDescendants={(e) => this.handleSelectionChange(e)}
              renderDescendants={!(mask === 8 || mask === 16 || mask === 128 || mask === 256)}
              cities={this.getCities()}
              selectedCities={selectedCities}
              onChangeCities={(e) => this.handleCitiesFilterChange(e)}
              renderCityFilter
              postalCodes={this.getPostalCodes()}
              selectedPostalCodes={selectedPostalCodes}
              onChangePostalCodes={(e) => this.handlePostalCodesFilterChange(e)}
              renderPostalCodeFilter
              onChangeSearchQuery={(e) => this.handleSearchQueryChange(e)}
              searchQuery={searchQuery}
              onSubmit={this.props.getInstalations}
              renderSearch
              customStyles={this.getCustomStyles()}
            />
            <DropdownMenu
              mainComponent={
                <Icon marginTop={1} style={{ cursor: 'pointer' }}>
                  <IconEllipsis />
                </Icon>
              }
              menuItems={[
                {
                  label: t('general.downloadHtml'),
                  icon: <IconFile />,
                  action: () => {
                    getFacilitiesPDF({
                      typeFilter: haveFilter && Number(getCookieFacilitiesSearch()?.fieldId),
                      city:
                        (Array.isArray(selectedCities) &&
                          selectedCities.length &&
                          selectedCities.map((element) => element.value)) ||
                        (dashboardFilterId === '3' || dashboardFilterId === 3
                          ? getCookieFacilitiesSearch()?.value
                          : null),
                      zip:
                        (Array.isArray(selectedPostalCodes) &&
                          selectedPostalCodes.length &&
                          selectedPostalCodes.map((element) => element.value)) ||
                        (dashboardFilterId === '4' || dashboardFilterId === 4
                          ? getCookieFacilitiesSearch()?.value
                          : null),
                      customerIDs:
                        Array.isArray(selectedDescendants) &&
                        selectedDescendants.length &&
                        selectedDescendants.map((element) => element.value),
                      userId:
                        dashboardFilterId === 'caUsers'
                          ? getCookieFacilitiesSearch()?.valueId
                          : null,
                      queryText:
                        searchQuery ||
                        (dashboardFilterId !== '3' &&
                        dashboardFilterId !== 3 &&
                        dashboardFilterId !== '4' &&
                        dashboardFilterId !== 4 &&
                        dashboardFilterId !== 'caUsers'
                          ? getCookieFacilitiesSearch()?.value
                          : null),
                    });
                  },
                },
              ]}
              position='right'
            />
          </Flex>
        </Flex>
        <GenericList
          dataList={instalations}
          CustomGrow={(props) => (
            <InstalationGrow
              {...props}
              canClone
              needSecondPopup
              dashboardFilterId={dashboardFilterId}
            />
          )}
          handlePageChange={this.handlePageChange}
          baseURI={RootPath_Instalations}
          creationURI={CreationPath_Instalations}
          listType={FACILITY_LIST}
          getList={getInstalations}
          groupByProp='customerName'
        />
        <AddButton
          id='add'
          toolTip={t('instalations.add')}
          history={this.props.history}
          url={`${RootPath_Instalations}/${CreationPath_Instalations}`}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  tenants: state.tenants,
  isAuth: !!state.login.userId,
  instalations: state.instalations.instalations,
  fromDashboard: state.instalations.fromDashboard,
  dashboardFilterId: state.instalations.dashboardFilterId,
  dashboardFilterValue: state.instalations.dashboardFilterValue,
  facilityCities: state.instalations.facilityCities,
  facilityPostalCodes: state.instalations.facilityPostalCodes,
  selectedDescendants: state.visibleDescendants.selectedDescendants[FACILITY_DESCENDANT],
  selectedCities: state.visibleDescendants.selectedCities,
  selectedPostalCodes: state.visibleDescendants.selectedPostalCodes,
  searchQuery: state.visibleDescendants.searchQuery,
  searchQueryDashboard: state.instalations.searchQuery,
  visibleDescendants: state.universalGroups.visibleDescendants,
  schedulesCount: state.instalations.schedulesCount,
  isFacilitiesListUpdated: state.instalations.isFacilitiesListUpdated,
});

const InstalationsListPage = withTranslation()(InstalationsList);

export default connect(mapStateToProps, {
  resetInstalation,
  getInstalations,
  getFacilitiesPDF,
  getField,
  getVisibleDescendants,
  setSelectedDescendants,
  resetInstalationGroups,
  getInstalationFilters,
  setSelectedCities,
  setSelectedPostalCodes,
  setSearchQuery,
  resetEquipments,
})(InstalationsListPage);
