import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import Relays from '../../old_components/Relays/Relays';
import TabMenu from '../../old_components/TabMenu/TabMenu';
import FacilitySchedules from '../../old_components/FacilitySchedules/FacilitySchedules';
import {
  getFacility,
  getInstalationsRelays,
  putInstalationsRelay,
  resetInstalation,
  resetInstalationsRelays,
} from '../../modules/old_to_refact/actions/instalations';
import { resetUGRelays } from '../../modules/old_to_refact/actions/universalGroups';
import { getInstalationGroup } from '../../modules/old_to_refact/actions/instalationGroups';
import {
  getCookie_selectedFacilityCustomerId,
  saveCookies,
} from '../../core/old_common/utils/cookiesManager';

export class RelaysConfigurationInstalation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      warningShowed: false,
    };
  }

  componentDidMount = () => {
    const { facility } = this.props;
    if (facility && getCookie_selectedFacilityCustomerId() !== facility.customerId) {
      saveCookies({ selectedFacilityCustomerId: facility.customerId });
    }
  };

  componentDidUpdate = () => {
    const { instalationsGroupRelays, t } = this.props;
    const groupRelayWarning = [];
    let itemsInfo;
    let disabledItem;
    let corruptedItem;
    if (!this.state.warningShowed) {
      const equipment = instalationsGroupRelays?.forEach((groupRelay) => {
        groupRelay?.warnings?.forEach((warning) => {
          const splittedWarn = warning.message.split('::');
          groupRelayWarning.push({ code: splittedWarn[0], equipment: splittedWarn[2] });
        });
      });

      groupRelayWarning.map((item) => {
        if (item.code === '91069') {
          disabledItem = `${
            disabledItem === undefined
              ? `${item.equipment}`
              : disabledItem.includes(item.equipment)
              ? `${disabledItem}`
              : `${disabledItem}, ${item.equipment}`
          }`;
        }
        if (item.code === '91011') {
          corruptedItem = `${
            corruptedItem === undefined
              ? `${item.equipment}`
              : corruptedItem.includes(item.equipment)
              ? `${corruptedItem}`
              : `${corruptedItem}, ${item.equipment}`
          }`;
        }
      });

      if (itemsInfo !== undefined) {
        toast.warn(`${t('errors.91069')}: ${itemsInfo}.`);
        this.setState({ warningShowed: true });
      }

      if (disabledItem !== undefined) {
        toast.warn(`${t('errors.91069')}: ${disabledItem}.`);
        this.setState({ warningShowed: true });
      }

      if (corruptedItem !== undefined) {
        toast.warn(`${t('errors.91011')}: ${corruptedItem}.`);
        this.setState({ warningShowed: true });
      }
    }
  };

  componentWillUnmount = () => {
    this.props.resetUGRelays();
    this.props.resetInstalationsRelays();
  };

  tabs = () => {
    const {
      facilityGroup,
      facility,
      instalationsGroupRelays,
      getInstalationsRelays,
      resetInstalationsRelays,
      getFacility,
      getInstalationGroup,
      putInstalationsRelay,
      t,
    } = this.props;

    const tabstate = {
      secondaryItem: facilityGroup,
      principalItem: facility,
      relays: instalationsGroupRelays,
    };
    const tabList = [
      {
        title: t('relaysConfiguration.tabRelays'),
        active: true,
        bodyComponent: Relays,
        componentState: {
          ...tabstate,
          principalParameter: 'facilityId',
          secondaryParameter: 'groupId',
          putRelay: putInstalationsRelay,
          getRelays: getInstalationsRelays,
          resetRelays: resetInstalationsRelays,
          canShowBackButton: true,
          getPrincipalItem: () => getFacility(this.props.match.params.instalationId),
          getSecondaryItem: () =>
            getInstalationGroup(facility.id, this.props.match.params.instalationGroupId),
        },
      },
      {
        title: t('relaysConfiguration.tabSchedules'),
        active: false,
        bodyComponent: FacilitySchedules,
        componentState: {
          ...tabstate,
          getRelays: getInstalationsRelays,
          principalParameter: 'facilityId',
          secondaryParameter: 'groupId',
          putRelay: putInstalationsRelay,
          resetRelays: resetInstalationsRelays,
          canShowBackButton: true,
          getPrincipalItem: () => getFacility(this.props.match.params.instalationId),
          getSecondaryItem: () =>
            getInstalationGroup(facility.id, this.props.match.params.instalationGroupId),
        },
      },
    ];
    return tabList;
  };

  render() {
    return <TabMenu tabType='tabs' tabsData={this.tabs()} />;
  }
}

const relaysConfigurationInstalationPage = withTranslation()(RelaysConfigurationInstalation);

const mapStateToProps = (state) => ({
  facilityGroup: state.instalationGroups.facilityGroup,
  facility: state.instalations.facility,
  instalationsGroupRelays: state.instalations.instalationsGroupRelays,
});

const relaysConfigurationInstalation = withRouter(relaysConfigurationInstalationPage);

export default connect(mapStateToProps, {
  resetInstalationsRelays,
  getInstalationsRelays,
  getInstalationGroup,
  getFacility,
  putInstalationsRelay,
  resetInstalation,
  resetUGRelays,
})(relaysConfigurationInstalation);
