export default {
  breadcrumb: {
    dashboard: 'Dashboard',
    Facilities: 'Instalace',
    FacilityEquipments: 'Zařízení',
    Groups: 'Skupiny',
    Equipments: 'Zařízení',
    AddUniversalGroup: 'Vložit Univerzální skupinu',
    Configuration: 'Nastavení',
    Enroll: 'Přijmout',
    administration: 'Administrace',
    'user-config': 'Můj účet',
    UniversalGroups: 'Univerzální skupiny',
    Users: 'Uživatelé',
    Help: 'Pomoc',
    Parameters: 'Parametry',
    Relays: 'Relé',
    Fobs: 'Ovladače',
    Actions: 'Úpravy',
    Events: 'Události',
    'Remote comm': 'Vzdálená komunikace',
    Facility: 'Nová instalace',
    Equipment: 'Nové zařízení',
    FacilityGroup: 'Nová skupina',
    Neighbours: 'Seznam uživatelů',
    UniversalGroup: 'Nová Univerzální skupina',
    Schedule: 'Nový časový rozvrh',
    customization: 'Upravit web',
    Invitations: 'Pozvání',
    Companies: 'Společnosti',
    Preferences: 'Preference',
    'Update User': 'Můj účet',
    Holidays: 'Sváteční dny',
    Information: 'Informace',
    recycle: 'Odpadkový koš',
  },
};
