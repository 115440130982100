import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import IconMuvAlarm from '@jcm-technologies/uikit/dist/atoms/Icons/IconMuvAlarm';
import { CodesCounters } from '@jcm-technologies/uikit/dist/molecules/CodesCounters';
import GenericKendoGrid from '../GenericKendoGrid/GenericKendoGrid';
import {
  generateRows,
  getArrayWithAttributes,
} from '../../core/old_common/utils/createInputByTenant';
import {
  getEquipmentCounters,
  getEquipmentFOBS,
} from '../../modules/old_to_refact/actions/equipments';
import { getECodeTranslated } from '../../core/old_common/utils/facilityService';
import { generateAlarmSumary } from '../utils/mappers/alarms';
import { IconSpan } from '../IconSpan';
import './FobsGrid.css';

class FobsGrid extends Component {
  componentDidMount() {
    const { facilityId, equipmentId, getEquipmentFOBS, getEquipmentCounters } = this.props;
    getEquipmentFOBS(facilityId, equipmentId);
    getEquipmentCounters(facilityId, equipmentId);
  }

  getColumns = () => {
    const { t, originalTenants } = this.props;
    return [
      {
        key: ' ',
        name: '',
        width: '50px',
        hideOnPDFExport: true,
        cell: (props, childProps) => (
          <td className='grid-buttons'>
            {props.dataItem?.alarms?.length ? (
              <IconSpan
                cursor='pointer'
                icon={<IconMuvAlarm fill='red' />}
                toolTip={generateAlarmSumary(props.dataItem.alarms)}
                tenants={originalTenants}
                iconClassName='d-grid'
                cursos='auto'
                onClick={(e) => {
                  e?.preventDefault();
                  e?.stopPropagation();
                }}
                {...childProps}
              />
            ) : (
              ''
            )}
          </td>
        ),
        tooltip: t('form.type'),
      },
      {
        key: 'fobCode',
        name: t('form.code'),
        sortable: true,
        filterable: true,
        tooltip: t('form.code'),
      },
      {
        key: 'groupName',
        name: t('title.group'),
        sortable: true,
        filterable: true,
        tooltip: t('title.group'),
      },
      {
        key: 'productName',
        name: t('form.type'),
        sortable: true,
        filterable: true,
        placeholder: 'porfa',
        tooltip: t('form.type'),
      },
      {
        key: 'name',
        name: t('form.name'),
        sortable: true,
        filterable: true,
        tooltip: t('form.name'),
      },
      {
        key: 'surname',
        name: t('form.surname'),
        sortable: true,
        filterable: true,
        tooltip: t('form.surname'),
      },
      {
        key: 'nic',
        name: t('form.identityCard'),
        sortable: true,
        filterable: true,
        tooltip: t('form.identityCard'),
      },
      {
        key: 'plaza',
        name: t('fobs.place'),
        sortable: true,
        filterable: true,
        tooltip: t('fobs.place'),
      },
      ...generateRows(originalTenants, 5),
    ];
  };

  render() {
    const { fobs, originalTenants, fobsCount, t } = this.props;
    return (
      <div>
        <GenericKendoGrid
          getColumns={this.getColumns}
          value={getArrayWithAttributes(
            fobs
              ? fobs.map((fob) => ({
                  ...fob,
                  fobCode:
                    this.props.equipment.equipmentConfiguration.tabs[0].itemsList?.length > 0
                      ? getECodeTranslated(
                          fob.e_code,
                          this.props.equipment.equipmentConfiguration.tabs[0].itemsList[5].value,
                          this.props.equipment.productName
                        )
                      : '',
                }))
              : [],
            'facilityFobAttributes',
            originalTenants
          )}
          // dropdownFilters={this.state.data}
        />
        {fobsCount ? (
          <CodesCounters
            availableValue={fobsCount.free || 0}
            availablePlaceholder={t('codesCount.available')}
            reservationValue={fobsCount.reserved}
            reservationPlaceholder={t('enrollCode.notAsigned')}
            usedValue={fobsCount.busy}
            usedPlaceholder={t('codesCount.used')}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  tenants: state.tenants,
  originalTenants: state.tenants.originalTenants,
  fobs: state.equipments.fobs,
  fobsCount: state.equipments.fobsCount,
});

const FobsGridWithTranslation = withTranslation()(FobsGrid);

export default connect(mapStateToProps, { getEquipmentFOBS, getEquipmentCounters })(
  FobsGridWithTranslation
);
