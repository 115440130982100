import createFetchTypes from '../../../core/typeActions/types';
import equipmentsActions, { GET_EQUIPMENTS_WIEGAND_CONFIG } from '../actions/equipments';
import {
  deleteArrayNodeByPropName,
  getArrayNodeByPropName,
  updateArrayFromArrayByPropName,
  updateArrayNodeByPropName,
} from '../../../core/helpers/arrayService';
import { GET_EQUIPMENTS_SEARCH } from '../../dashboard/constants';

export const EQUIPMENTS_LIST_GET = createFetchTypes('EQUIPMENTS_LIST_GET');
export const EQUIPMENT_GET = createFetchTypes('EQUIPMENT_GET');
export const EQUIPMENTS_DEL = createFetchTypes('EQUIPMENTS_DEL');
export const EQUIPMENTS_POST = createFetchTypes('EQUIPMENTS_POST');
export const EQUIPMENTS_PUT = createFetchTypes('EQUIPMENTS_PUT');
export const EQUIPMENT_IS_UPDATED_PUT = createFetchTypes('EQUIPMENT_IS_UPDATED_PUT');
export const EQUIPMENTS_FOBS_GET = createFetchTypes('EQUIPMENTS_FOBS_GET');
export const EQUIPMENTS_RELAY_PUT = createFetchTypes('EQUIPMENTS_RELAY_PUT');
export const EQUIPMENTS_RELAYS_GET = createFetchTypes('EQUIPMENTS_RELAYS_GET');
export const EQUIPMENTS_ACTIONS_GET = createFetchTypes('EQUIPMENTS_ACTIONS_GET');
export const EQUIPMENTS_CONNECTIVITY_GET = createFetchTypes('EQUIPMENTS_CONNECTIVITY_GET');
export const EQUIPMENTS_VERTEX_CONNECTIVITY_GET = createFetchTypes(
  'EQUIPMENTS_VERTEX_CONNECTIVITY_GET'
);
export const EQUIPMENTS_COUNTERS_GET = createFetchTypes('EQUIPMENTS_COUNTERS_GET');
export const EQUIPMENTS_SIM_GET = createFetchTypes('EQUIPMENTS_SIM_GET');
export const EQUIPMENTS_EVENTS_GET = createFetchTypes('EQUIPMENTS_EVENTS_GET');
export const EQUIPMENTS_EVENTS_DELETE = createFetchTypes('EQUIPMENTS_EVENTS_DELETE');

/* Redux state init */
const initialState = {
  equipments: [],
  searchPagination: {},
  equipment: undefined,
  fobs: [],
  relays: [],
  actions: [],
  equipmentConnection: undefined,
  isEquipmentUpdated: false,
  fobsCount: undefined,
  needCallConnectivity: true,
  needCallEquipments: true,
  needCallEquipment: true,
  vertexConnectivity: undefined,
  isUpdated: undefined,
  updatedEquipment: undefined,
  configFieldsEquipments: undefined,
  putRelaysIsSucced: undefined,
  equipmentConnectionInfo: undefined,
  equipmentSim: undefined,
  equipmentSimIsCalled: undefined,
  equipmentSimError: undefined,
  parametersScroll: null,
  events: [],
  goBack: false,
};

const modifyEquipmentConnectivity = (equipment, connectionState) => {
  if (equipment && equipment.facilityEquipmentAttributes) {
    const connectionAttributes = equipment.facilityEquipmentAttributes.filter(
      (att) => att.type === 5
    );
    connectionAttributes.forEach((att) => {
      Object.entries(connectionState).forEach(([key, value]) => (att[key] = value));
    });
    equipment.facilityEquipmentAttributes = updateArrayFromArrayByPropName(
      equipment.facilityEquipmentAttributes,
      connectionAttributes,
      'id'
    );
    return equipment;
  }
};

export default function equipmentsReducer(state = initialState, action) {
  if (action.type !== undefined) {
    switch (action.type) {
      case GET_EQUIPMENTS_SEARCH.REQUEST:
      case EQUIPMENTS_LIST_GET.REQUEST:
        return {
          ...state,
          needCallEquipments: false,
        };

      case GET_EQUIPMENTS_SEARCH.SUCCESS:
        const { overallPages, overallRows, currentPage, pageItems } = action.result;
        return {
          ...state,
          equipments: pageItems || [],
          searchPagination: {
            overallPages,
            overallRows,
            currentPage,
          },
          needCallEquipments: true,
        };
      case EQUIPMENTS_LIST_GET.SUCCESS:
        return {
          ...state,
          equipments: [...action.result],
          needCallEquipments: true,
          configFieldsEquipments: action.result.MetaData,
        };
      case GET_EQUIPMENTS_SEARCH.FAILURE:
      case EQUIPMENTS_LIST_GET.FAILURE:
        return {
          ...state,
          needCallEquipments: true,
        };
      case EQUIPMENTS_DEL.SUCCESS:
        return {
          ...state,
          equipments: deleteArrayNodeByPropName(state.equipments, action.result, 'id'),
        };
      case EQUIPMENTS_RELAY_PUT.REQUEST:
        return { ...state, putRelaysIsSucced: false };
      case EQUIPMENTS_RELAY_PUT.SUCCESS:
        return {
          ...state,
          putRelaysIsSucced: true,
          relays: updateArrayNodeByPropName(state.relays, action.result, 'id'),
        };
      case EQUIPMENTS_RELAYS_GET.SUCCESS:
        return { ...state, relays: [...action.result] };
      case EQUIPMENTS_FOBS_GET.SUCCESS:
        return { ...state, fobs: [...action.result] };
      case EQUIPMENTS_ACTIONS_GET.SUCCESS:
        return { ...state, actions: [...action.result] };
      case EQUIPMENTS_CONNECTIVITY_GET.REQUEST:
        return { ...state, needCallConnectivity: false };
      case EQUIPMENTS_CONNECTIVITY_GET.SUCCESS:
        {
          const connectionStatus =
            action.result.connected !== 'unknown'
              ? {
                  value: `[${action.result.connEventTime}][${action.result.connected}]`,
                  tag: action.result.name,
                }
              : { value: '[][Unknow]', tag: 'DeviceNotFound' };
          let newEquipment = { ...state.equipment };
          let newEquipmentsList = state.equipments ? [...state.equipments] : undefined;
          if (newEquipment) {
            newEquipment = modifyEquipmentConnectivity(newEquipment, connectionStatus);
          }
          if (newEquipmentsList) {
            const selectedEquipmentId = action.payload[1].equipmentId;
            let selectedEquipment = getArrayNodeByPropName(
              state.equipments,
              'id',
              selectedEquipmentId
            );
            if (selectedEquipment) {
              selectedEquipment = modifyEquipmentConnectivity(selectedEquipment, connectionStatus);
              newEquipmentsList = updateArrayNodeByPropName(
                newEquipmentsList,
                selectedEquipment,
                'id'
              );
            }
          }
        }
        return {
          ...state,
          equipmentConnectionInfo: action.result,
          needCallConnectivity: true,
          equipments: newEquipmentsList,
          equipment: newEquipment,
        };
      case EQUIPMENTS_CONNECTIVITY_GET.FAILURE:
        return {
          ...state,
          equipmentConnection: action.error.response.body.error,
          needCallConnectivity: true,
        };
      case EQUIPMENTS_COUNTERS_GET.SUCCESS:
        return { ...state, fobsCount: action.result[0].counters[0] };
      case EQUIPMENTS_POST.SUCCESS:
        return {
          ...state,
          equipment: action.result,
          equipments: state.equipments ? [...state.equipments, action.result] : undefined,
          // isEquipmentUpdated: true,
        };

      case 'PUT_EQUIPMENT': {
        return {
          ...state,
          tabType: action?.tabType,
        };
      }
      case EQUIPMENTS_PUT.SUCCESS: {
        const fwUpdated = action?.result?.MetaData?.fwUpdated;
        const serialNumber = action?.result?.MetaData?.serialNumber;
        const newEquipment = action.payload[1].data
          ? { ...action.payload[1].data, fwUpdated: fwUpdated, serialNumber: serialNumber }
          : undefined;
        return {
          ...state,
          equipments: state.equipments
            ? updateArrayNodeByPropName(state.equipments, newEquipment, 'id')
            : undefined,
          equipment: newEquipment,
          isEquipmentUpdated: true,
          updatedEquipment: true,
        };
      }
      case equipmentsActions.RESET_EQUIPMENTS_SCROLL:
        return {
          ...state,
          parametersScroll: null,
        };
      case equipmentsActions.SET_EQUIPMENTS_SCROLL:
        return {
          ...state,
          parametersScroll: document.getElementById('scrollit')
            ? document.getElementById('scrollit').scrollTop
            : null,
        };
      case EQUIPMENT_IS_UPDATED_PUT.SUCCESS:
        return {
          ...state,
          isUpdated: action.result,
          relays: updateArrayNodeByPropName(state.relays, action.result, 'id'),
        };
      case EQUIPMENT_GET?.REQUEST:
        return {
          ...state,
          needCallEquipment: false,
          goBack: false,
          isEquipmentUpdated: false,
        };
      case EQUIPMENT_GET.SUCCESS:
        const newEquipment = {
          ...action.result.Data,
          fwUpdated: undefined,
          ...action.result.Options,
        };
        return {
          ...state,
          equipment: newEquipment,
          equipments: state.equipments
            ? updateArrayNodeByPropName(state.equipments, newEquipment, 'id')
            : undefined,
          needCallEquipment: true,
          configFieldsEquipments: action.result.MetaData,
        };
      case EQUIPMENT_GET.FAILURE:
        return {
          ...state,
          needCallEquipment: true,
          goBack: true,
        };
      case equipmentsActions.RESET_EQUIPMENT:
        return {
          ...state,
          equipment: undefined,
        };
      case equipmentsActions.RESET_EQUIPMENTS:
        return {
          ...state,
          equipments: undefined,
        };
      case EQUIPMENTS_VERTEX_CONNECTIVITY_GET.SUCCESS:
        return {
          ...state,
          vertexConnectivity: action.result,
        };
      case equipmentsActions.RESET_VERTEX_CONNECTIVITY:
        return {
          ...state,
          vertexConnectivity: undefined,
        };
      case equipmentsActions.RESET_EQUIPMENT_LIST:
        return {
          ...state,
          equipments: undefined,
        };
      case equipmentsActions.RESET_EQUIPMENT_CONNECTIVITY:
        return {
          ...state,
          equipmentConnection: undefined,
        };
      case EQUIPMENTS_SIM_GET.REQUEST:
        return {
          ...state,
          equipmentSimIsCalled: false,
        };
      case EQUIPMENTS_SIM_GET.FAILURE:
        return {
          ...state,
          equipmentSimIsCalled: true,
          equipmentSimError: action.error.response.body,
        };
      case EQUIPMENTS_SIM_GET.SUCCESS:
        return {
          ...state,
          equipmentSim: action.result,
          equipmentSimIsCalled: true,
        };
      case equipmentsActions.RESET_EQUIPMENT_SIM:
        return {
          ...state,
          equipmentSim: undefined,
          equipmentSimIsCalled: false,
        };

      case equipmentsActions.RESET_EQUIPMENT_RELAYS:
        return {
          ...state,
          relays: [],
        };
      case EQUIPMENTS_EVENTS_GET.SUCCESS:
        return { ...state, events: action.result };
      case EQUIPMENTS_EVENTS_DELETE.SUCCESS:
        return {
          ...state,
          events: [],
        };
      case equipmentsActions.RESET_EQUIPMENT_EVENTS: {
        return {
          ...state,
          events: [],
        };
      }
      case GET_EQUIPMENTS_WIEGAND_CONFIG.SUCCESS: {
        return {
          ...state,
          equipment: {
            ...state.equipment,
            wiegandConfigs: action.result,
          },
        };
      }
      default:
        return state;
    }
  }
  return state;
}
