import errors from './errors';
import forgotPassword from './forgotPassword';
import resetPassword from './resetPassword';
import topBar from './topBar';
import changePassword from './changePassword';
import genericForm from './genericForm';
import universalGroups from './universalGroups';
import popupconfirm from './popupconfirm';
import layout from './layout';
import notAvailable from './notAvailable';
import genericGrid from './genericGrid';
import commonFormValidation from './commonFormValidation';
import pagination from './pagination';
import genericGrow from './genericGrow';
import updateInstalationForm from './updateInstalationForm';
import apiToasters from './apiToasters';
import instalations from './instalations';
import instalationGroups from './instalationGroups';
import codesCount from './codesCount';
import enrollCode from './enrollCode';
import equipments from './equipments';
import fobsPopUps from './fobsPopUps';
import instalationsMap from './instalationsMap';
import relaysConfiguration from './relaysConfiguration';
import gdprPopup from './gdprPopup';
import facilitiesUserGroupsGrid from './facilitiesUserGroupsGrid';
import actionsPopUps from './actionsPopUps';
import fobs from './fobs';
import parametersPopUps from './parametersPopUps';
import remoteComunications from './remoteComunications';
import neighbourPopUp from './neighbourPopUp';
import versionPopUp from './versionPopUp';
import breadcrumb from './breadcrumb';
import signalRErrors from './signalRErrors';
import compareMemory from './compareMemory';
import parametersDescriptions from './parametersDescriptions';
import globalSearch from './globalSearch';
import equipmentErrors from './equipmentErrors';
import paramsName from './paramsName';
import remoteState from './remoteState';
import alarms from './alarms';
import gsmPopup from './gsmPopup';
import fobsSubstitution from './fobsSubstitution';
import instalationUserGroups from './instalationUserGroups';

export default {
  ...neighbourPopUp,
  ...parametersPopUps,
  ...actionsPopUps,
  ...instalationsMap,
  ...fobsPopUps,
  ...errors,
  ...forgotPassword,
  ...resetPassword,
  ...topBar,
  ...changePassword,
  ...genericForm,
  ...universalGroups,
  ...popupconfirm,
  ...layout,
  ...notAvailable,
  ...genericGrid,
  ...commonFormValidation,
  ...pagination,
  ...genericGrow,
  ...updateInstalationForm,
  ...apiToasters,
  ...instalations,
  ...instalationGroups,
  ...codesCount,
  ...enrollCode,
  ...equipments,
  ...relaysConfiguration,
  ...gdprPopup,
  ...facilitiesUserGroupsGrid,
  ...fobs,
  ...remoteComunications,
  ...versionPopUp,
  ...breadcrumb,
  ...signalRErrors,
  ...compareMemory,
  ...parametersDescriptions,
  ...globalSearch,
  ...equipmentErrors,
  ...paramsName,
  ...remoteState,
  ...alarms,
  ...gsmPopup,
  ...fobsSubstitution,
  ...instalationUserGroups,
};
