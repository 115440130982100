import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import IconMuvAlarm from '@jcm-technologies/uikit/dist/atoms/Icons/IconMuvAlarm';
import {
  deleteUniversalGroup,
  getUniversalGroups,
  getUniversalGroupsInfo,
  resetUniversalGroupInfo,
} from '../../modules/old_to_refact/actions/universalGroups';
import GenericMapButton from '../GenericMapButton/GenericMapButton';

import './UniversalGroupGrow.css';
import { withTranslation } from 'react-i18next';
import GenericGrow from '../GenericGrow/GenericGrow';
import UniversalGroupsMapPopUpContent from '../UniversalGroupMapPopUpContent/UniversalGroupsMapPopUpContent';
import PropsList from '../PropsList/PropsList';
import { generateAlarmSumary } from '../utils/mappers/alarms';
import { IconUserShield } from '@jcm-technologies/uikit/dist/atoms/Icons';
import { Icon } from '@jcm-technologies/uikit/dist/atoms/Icon';

export class UniversalGroupGrow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mapProps: {
        isOpen: false,
        universalGroupId: '',
      },
    };
  }

  getHeaderTitles = (isEditMode) => {
    const { customerName, name, match, t } = this.props;
    if (isEditMode) {
      return [
        {
          id: 'dateInvited',
          value: name,
          className: 'UG-portlet-header-item UG-header-title',
        },
        {
          id: 'dateInvited',
          value: isEditMode ? customerName : '',
          className: 'UG-portlet-header-item',
        },
      ];
    }
    return [
      {
        id: 'dateInvited',
        value: t('universalGroups.create'),
        className: 'UG-portlet-header-item UG-header-title',
      },
    ];
  };

  getRowCustomActions = (isCreationMode) => {
    const { item, t, hideActionButtons } = this.props;
    if (!hideActionButtons) {
      const actionButtons = [];
      // TODO: Fix parent and old components to style from parent comp
      if (item?.installerCode) {
        actionButtons.push({
          toolTip: t('updateInstalationForm.installerCodeIconTooltip'),
          //TODO: We neeed to refact the other icons in order to fix this styling
          icon: <IconUserShield className='generic-grow-action-button d-grid refactored-icon' />,
          isHidden: isCreationMode,
          onClick: (e) => {
            e?.preventDefault();
            e?.stopPropagation();
          },
        });
      }
      if (item?.alarms?.length) {
        actionButtons.unshift({
          toolTip: generateAlarmSumary(item.alarms),
          icon: <IconMuvAlarm className='generic-grow-action-button d-grid' fill='red' />,
          cursor: 'pointer',
          onClick: (e) => {
            e?.preventDefault();
            e?.stopPropagation();
          },
        });
      }
      return actionButtons;
    }
    return [];
  };

  popupDeleteChildren = (item) => {
    const { t } = this.props;
    return [
      {
        key: t('form.name'),
        value: item.name,
      },
      {
        key: t('universalGroups.description'),
        value: item.description,
      },
    ];
  };

  changePopUpState = (newState, id) => {
    this.setState({
      mapProps: {
        isOpen: newState,
        universalGroupId: id,
      },
    });
  };

  isMapOpen = (popUpState) => {
    this.props.resetUniversalGroupInfo();
    this.setState({
      mapProps: {
        isOpen: popUpState,
      },
    });
  };

  generateUniversalGroupMap = (id) => {
    const { mapProps } = this.state;
    if (mapProps.universalGroupId === id) {
      return (
        <UniversalGroupsMapPopUpContent
          isOpen={mapProps.isOpen}
          universalGroupId={id}
          onCloseForm={() => this.isMapOpen(false)}
          resetUniversalGroupInfo={this.props.resetUniversalGroupInfo}
          mapInfo={this.props.universalGroupInfo}
        />
      );
    }
    return '';
  };

  render() {
    const {
      children,
      customerId,
      id,
      baseURI,
      creationURI,
      getUniversalGroups,
      deleteUniversalGroup,
      getUniversalGroupsInfo,
      t,
      match,
      canClone,
      hideActionButtons,
      isMapButtonVisible,
      item,
    } = this.props;
    const { mapProps } = this.state;
    return (
      <GenericGrow
        isExpanded={children !== undefined}
        key={customerId}
        item={item}
        rootPath={baseURI}
        secondaryRootPath={
          this.props.match.path === '/Facilities/:instalationId/groups/AddUniversalGroup/:id'
        }
        creationURI={creationURI}
        isMapButtonVisible={isMapButtonVisible !== undefined ? isMapButtonVisible : true}
        isMapButtonDisabled
        customActionButtons={this.getRowCustomActions(decodeURI(match.params.id) === creationURI)}
        headerTitles={this.getHeaderTitles(decodeURI(match.params.id) !== creationURI)}
        handleDelete={(e) => {
          deleteUniversalGroup({ id, customerId });
          if (this.props.handleDelete) {
            this.props.handleDelete();
          }
        }}
        handleClone={this.handleClone}
        hideActionButtons={hideActionButtons}
        MapComponent={() => (
          <GenericMapButton
            isExpanded={children !== undefined}
            isMapButtonVisible={false}
            mapProps={mapProps}
            isMapButtonDisabled={false}
            resetMapProps={this.resetMapProps}
            changePopUpState={this.changePopUpState}
            universalGroupId={id}
            mapId={id}
            onClosePopUp={() => {
              this.changePopUpState(false, '');
              resetUniversalGroupInfo();
            }}
            onClick={(mapId) => {
              this.changePopUpState(true, mapId);
              getUniversalGroupsInfo(mapId);
            }}
          >
            {this.generateUniversalGroupMap(id)}
          </GenericMapButton>
        )}
        deletePopupChildren={<PropsList propsArray={this.popupDeleteChildren(item)} />}
        deletePopupTitle={`${t('universalGroups.deleteConfirmation')}`}
        canClone={canClone !== undefined ? canClone : true}
      >
        {children}
      </GenericGrow>
    );
  }
}

const mapStateToProps = (state) => ({
  universalGroupInfo: state.universalGroups.universalGroupsInfo,
});

const withRouterComponent = withRouter(UniversalGroupGrow);
const withTranslationComponent = withTranslation()(withRouterComponent);

export default connect(mapStateToProps, {
  deleteUniversalGroup,
  getUniversalGroupsInfo,
  resetUniversalGroupInfo,
  getUniversalGroups,
})(withTranslationComponent);
