import formsControlActions from '../actions/formsControl';
import { POST_INVITATION } from '../../invitations/constants';

/* Redux state init */
const initialState = {
  isSuccess: false,
};

export default function formsControl(state = initialState, action) {
  if (action.type !== undefined) {
    switch (action.type) {
      case POST_INVITATION.SUCCESS:
        return {
          ...state,
          isSuccess: true,
        };
      case formsControlActions.RESET_GROUP_FOBS:
        return {
          ...state,
          isSuccess: false,
        };

      default:
        return state;
    }
  }
  return state;
}
