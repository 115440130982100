import styled, { css } from 'styled-components';

export default styled.textarea`
  border: 1px solid ${(props) => (props.error ? 'red' : '#b3b3b3')};
  font-size: 14px;
  font-weight: 100;
  resize: none;
  outline: none !important;
  ${(props) => (props.icon ? 'text-indent: 30px;' : '')};

  ${(props) => (props.isFocused && css ? `border: 1px solid ${props.tenants.color1}` : ``)}

  &:hover {
    border: 1px solid ${(props) => props.tenants.color1};
  }
`;
