import { call, fork, put, select, takeLatest } from 'redux-saga/effects';
import moment from 'moment/moment';
import i18Next from 'i18next';
import InstalationsActions, {
  resetAllFobLists,
  resetInstalationFOBS,
} from '../actions/instalations';
import { setSelectedDescendants } from '../../state/visibleDescendants/actions';
import fetchEntitySaga from './utils/fetchEntitySaga';
import apiPathFactory from '../../../core/old_common/rooting/apiPathFactory';
import api from '../../../core/helpers/api';
import { getUniversalFobsSaga } from './universalGroupsFobs';
import { getInstalationGroupSaga, getInstalationGroupsFobsCountSaga } from './instalationGroups';
import { getUniversalGroupSaga } from './universalGroups';
import {
  CHECKER_RECYCLE_INSTALLATION_GET,
  EQUIPMENT_PARAMETERS_GET,
  EQUIPMENT_USER_GROUPS_GET,
  FACILITY_GET,
  FACILITY_LOGS_BY_USER_GET,
  FACILITY_LOGS_GET,
  GET_FACILITIES_PDF,
  GET_FACILITY_EVENTS,
  INSTALATION_CITIES_GET,
  INSTALATION_DELETE_ALARM,
  INSTALATION_INFO_GET,
  INSTALATION_POSTALCODES_GET,
  INSTALATION_TRANSFER_PUT,
  INSTALATIONS_DEL,
  INSTALATIONS_FOBS_GET,
  INSTALATIONS_GEOLOCATION_PUT,
  INSTALATIONS_GET,
  INSTALATIONS_POST,
  INSTALATIONS_PUT,
  INSTALATIONS_RELAY_PUT,
  INSTALATIONS_RELAYS_GET,
  INSTALATIONS_SINGLE_LIST_GET,
  RECYCLE_INSTALLATIONS_GET,
  RECYCLE_RESTORE_INSTALLATION_SET,
} from '../reducers/instalations';
import { FACILITY_DESCENDANT } from '../../state/visibleDescendants/constants';
import {
  getCookie_selectedFacilityCustomerId,
  getCookiePageSizeItems,
} from '../../../core/old_common/utils/cookiesManager';
import { resetInstalationGroup } from '../actions/instalationGroups';
import { convertDateTimeForBackend } from '../../../core/old_common/utils/dataService';
import { FACILITY_LIST } from '../../state/pagination/constants';
import { downloadFile, getFilesFromApi } from '../../../core/helpers/filesApi';

export const getInstalationsSingleList = {
  base: (id, except) => ({
    type: INSTALATIONS_SINGLE_LIST_GET.BASE,
    id,
    except,
  }),
  request: () => ({ type: INSTALATIONS_SINGLE_LIST_GET.REQUEST }),
  success: (result) => ({ type: INSTALATIONS_SINGLE_LIST_GET.SUCCESS, result }),
  failure: (error) => ({ type: INSTALATIONS_SINGLE_LIST_GET.FAILURE, error }),
};

export const getFacility = {
  base: (id, include) => ({
    type: FACILITY_GET.BASE,
    id,
    include,
  }),
  request: () => ({ type: FACILITY_GET.REQUEST }),
  success: (result) => ({ type: FACILITY_GET.SUCCESS, result }),
  failure: (error) => ({ type: FACILITY_GET.FAILURE, error }),
};

export const getFacilityLogs = {
  base: (facilityId) => ({
    type: FACILITY_LOGS_GET.BASE,
    facilityId,
  }),
  request: () => ({ type: FACILITY_LOGS_GET.REQUEST }),
  success: (result) => ({ type: FACILITY_LOGS_GET.SUCCESS, result }),
  failure: (error) => ({ type: FACILITY_LOGS_GET.FAILURE, error }),
};

export const getFacilitiesLogsByUser = {
  base: (userId) => ({
    type: FACILITY_LOGS_BY_USER_GET.BASE,
    userId,
  }),
  request: () => ({ type: FACILITY_LOGS_BY_USER_GET.REQUEST }),
  success: (result) => ({ type: FACILITY_LOGS_BY_USER_GET.SUCCESS, result }),
  failure: (error) => ({ type: FACILITY_LOGS_BY_USER_GET.FAILURE, error }),
};

export const getFacilityEvents = {
  base: (facilityId) => ({
    type: GET_FACILITY_EVENTS.BASE,
    facilityId,
  }),
  request: () => ({ type: GET_FACILITY_EVENTS.REQUEST }),
  success: (result) => ({ type: GET_FACILITY_EVENTS.SUCCESS, result }),
  failure: (error) => ({ type: GET_FACILITY_EVENTS.FAILURE, error }),
};

export const getEquipmentUserGroups = {
  base: (facilityId, equipmentId) => ({
    type: EQUIPMENT_USER_GROUPS_GET.BASE,
    facilityId,
    equipmentId,
  }),
  request: () => ({ type: EQUIPMENT_USER_GROUPS_GET.REQUEST }),
  success: (result) => ({ type: EQUIPMENT_USER_GROUPS_GET.SUCCESS, result }),
  failure: (error) => ({ type: EQUIPMENT_USER_GROUPS_GET.FAILURE, error }),
};

export const getEquipmentParameters = {
  base: (facilityId, equipmentId) => ({
    type: EQUIPMENT_PARAMETERS_GET.BASE,
    facilityId,
    equipmentId,
  }),
  request: () => ({ type: EQUIPMENT_PARAMETERS_GET.REQUEST }),
  success: (result) => ({ type: EQUIPMENT_PARAMETERS_GET.SUCCESS, result }),
  failure: (error) => ({ type: EQUIPMENT_PARAMETERS_GET.FAILURE, error }),
};

export const getFacilities = {
  base: () => ({
    type: INSTALATIONS_GET.BASE,
  }),
  request: () => ({ type: INSTALATIONS_GET.REQUEST }),
  success: (result) => ({ type: INSTALATIONS_GET.SUCCESS, result }),
  failure: (error) => ({ type: INSTALATIONS_GET.FAILURE, error }),
};

export const putInstalation = {
  base: () => ({
    type: INSTALATIONS_PUT.BASE,
  }),
  request: () => ({ type: INSTALATIONS_PUT.REQUEST }),
  success: (result, payload) => ({ type: INSTALATIONS_PUT.SUCCESS, result, payload }),
  failure: (error) => ({ type: INSTALATIONS_PUT.FAILURE, error }),
};

export const postInstalation = {
  base: () => ({
    type: INSTALATIONS_POST.BASE,
  }),
  request: () => ({ type: INSTALATIONS_POST.REQUEST }),
  success: (result) => ({ type: INSTALATIONS_POST.SUCCESS, result }),
  failure: (error) => ({ type: INSTALATIONS_POST.FAILURE, error }),
};

export const getInstalationGroupRelays = {
  base: () => ({
    type: INSTALATIONS_RELAYS_GET.BASE,
  }),
  request: () => ({ type: INSTALATIONS_RELAYS_GET.REQUEST }),
  success: (result) => ({ type: INSTALATIONS_RELAYS_GET.SUCCESS, result }),
  failure: (error) => ({ type: INSTALATIONS_RELAYS_GET.FAILURE, error }),
};

export const getInstalationFOBS = {
  base: () => ({
    type: INSTALATIONS_FOBS_GET.BASE,
  }),
  request: () => ({ type: INSTALATIONS_FOBS_GET.REQUEST }),
  success: (result) => ({ type: INSTALATIONS_FOBS_GET.SUCCESS, result }),
  failure: (error) => ({ type: INSTALATIONS_FOBS_GET.FAILURE, error }),
};

export const delInstalation = {
  base: () => ({
    type: INSTALATIONS_DEL.BASE,
  }),
  request: () => ({ type: INSTALATIONS_DEL.REQUEST }),
  success: (result) => ({ type: INSTALATIONS_DEL.SUCCESS, result }),
  failure: (error) => ({ type: INSTALATIONS_DEL.FAILURE, error }),
};

export const getInstalationInfo = {
  base: (id) => ({
    type: INSTALATION_INFO_GET.BASE,
    id,
  }),
  request: () => ({ type: INSTALATION_INFO_GET.REQUEST }),
  success: (result) => ({ type: INSTALATION_INFO_GET.SUCCESS, result }),
  failure: (error) => ({ type: INSTALATION_INFO_GET.FAILURE, error }),
};

export const putInstalationGroupRelays = {
  base: () => ({
    type: INSTALATIONS_RELAY_PUT.BASE,
  }),
  request: () => ({ type: INSTALATIONS_RELAY_PUT.REQUEST }),
  success: (result) => ({ type: INSTALATIONS_RELAY_PUT.SUCCESS, result }),
  failure: (error) => ({ type: INSTALATIONS_RELAY_PUT.FAILURE, error }),
};

export const putInstalationGeolocation = {
  base: () => ({
    type: INSTALATIONS_GEOLOCATION_PUT.BASE,
  }),
  request: () => ({ type: INSTALATIONS_GEOLOCATION_PUT.REQUEST }),
  success: (result) => ({ type: INSTALATIONS_GEOLOCATION_PUT.SUCCESS, result }),
  failure: (error) => ({ type: INSTALATIONS_GEOLOCATION_PUT.FAILURE, error }),
};

export const getFacilityCities = {
  base: () => ({
    type: INSTALATION_CITIES_GET.BASE,
  }),
  request: () => ({ type: INSTALATION_CITIES_GET.REQUEST }),
  success: (result) => ({ type: INSTALATION_CITIES_GET.SUCCESS, result }),
  failure: (error) => ({ type: INSTALATION_CITIES_GET.FAILURE, error }),
};

export const getFacilityPostalCodes = {
  base: () => ({
    type: INSTALATION_POSTALCODES_GET.BASE,
  }),
  request: () => ({ type: INSTALATION_POSTALCODES_GET.REQUEST }),
  success: (result) => ({ type: INSTALATION_POSTALCODES_GET.SUCCESS, result }),
  failure: (error) => ({ type: INSTALATION_POSTALCODES_GET.FAILURE, error }),
};

export const deleteAlarmFob = {
  base: (alarmId, isUniversal, groupId, facilityId, instalationsName) => ({
    type: INSTALATION_DELETE_ALARM.BASE,
    alarmId,
    isUniversal,
    facilityId,
    groupId,
    instalationsName,
  }),
  request: () => ({ type: INSTALATION_DELETE_ALARM.REQUEST }),
  success: (result) => ({ type: INSTALATION_DELETE_ALARM.SUCCESS, result }),
  failure: (error) => ({ type: INSTALATION_DELETE_ALARM.FAILURE, error }),
};

export const putTransferFacility = {
  base: (facilityId, newCustomerId) => ({
    type: INSTALATION_TRANSFER_PUT,
    facilityId,
    newCustomerId,
  }),
  request: () => ({ type: INSTALATION_TRANSFER_PUT.REQUEST }),
  success: (result) => ({ type: INSTALATION_TRANSFER_PUT.SUCCESS, result }),
  failure: (error) => ({ type: INSTALATION_TRANSFER_PUT.FAILURE, error }),
};

export const getRecycleChecker = {
  base: () => ({
    type: CHECKER_RECYCLE_INSTALLATION_GET,
  }),
  request: () => ({ type: CHECKER_RECYCLE_INSTALLATION_GET.REQUEST }),
  success: (result) => ({ type: CHECKER_RECYCLE_INSTALLATION_GET.SUCCESS, result }),
  failure: (error) => ({ type: CHECKER_RECYCLE_INSTALLATION_GET.FAILURE, error }),
};

export const getRecycleList = {
  base: () => ({
    type: RECYCLE_INSTALLATIONS_GET,
  }),
  request: () => ({ type: RECYCLE_INSTALLATIONS_GET.REQUEST }),
  success: (result) => ({ type: RECYCLE_INSTALLATIONS_GET.SUCCESS, result }),
  failure: (error) => ({ type: RECYCLE_INSTALLATIONS_GET.FAILURE, error }),
};

export const setRecycleRestore = {
  base: (facilityId) => ({
    type: RECYCLE_RESTORE_INSTALLATION_SET,
    facilityId,
  }),
  request: () => ({ type: RECYCLE_RESTORE_INSTALLATION_SET.REQUEST }),
  success: (result) => ({ type: RECYCLE_RESTORE_INSTALLATION_SET.SUCCESS, result }),
  failure: (error) => ({ type: RECYCLE_RESTORE_INSTALLATION_SET.FAILURE, error }),
};

export function* getInstalationsSingleListSaga({ facilityId, except }) {
  try {
    const url = apiPathFactory.facilitiesGetSingleList(facilityId, except);
    const response = yield call(() => fetchEntitySaga(getInstalationsSingleList, api.get, url));
    return response;
  } catch (error) {
    return error;
  }
}

export function* getFacilitySaga({ id, include, fromGlobalSearch }) {
  try {
    const url = apiPathFactory.facilitiesGet(
      id,
      include,
      undefined,
      fromGlobalSearch ? '' : getCookie_selectedFacilityCustomerId()
    );
    const response = yield call(() => fetchEntitySaga(getFacility, api.get, url));
    return response;
  } catch (error) {
    return error;
  }
}

export function* getFacilitiesHTMLSaga({ customerIDs, queryText, city, zip, typeFilter, userId }) {
  try {
    const url = apiPathFactory.facilitiesGetHTML({
      customerIDs,
      queryText,
      city,
      zip,
      typeFilter,
      userId,
    });
    const response = yield call(() =>
      getFilesFromApi(
        url,
        (isError, response) => {
          if (!isError) {
            const fileName = `${moment().format('YYYY-MM-DD')}_${i18Next.t(
              'title.facilities'
            )}.html`;
            downloadFile(response, fileName);
          }
        },
        'application/json',
        'binary'
      )
    );

    return response;
  } catch (error) {
    return error;
  }
}

export function* getFacilityLogsSaga({ facilityId }) {
  try {
    const url = apiPathFactory.facilityGetLogs(facilityId);
    const response = yield call(() => fetchEntitySaga(getFacilityLogs, api.get, url));
    return response;
  } catch (error) {
    return error;
  }
}

export function* getFacilitiesLogsByUserSaga({ userId }) {
  try {
    const url = apiPathFactory.facilityGetLogsByUser(userId);
    const response = yield call(() => fetchEntitySaga(getFacilitiesLogsByUser, api.get, url));
    return response;
  } catch (error) {
    return error;
  }
}

export function* getFacilityEventsSaga({ facilityId }) {
  try {
    const url = apiPathFactory.facilityGetEvents(facilityId);
    const response = yield call(() => fetchEntitySaga(getFacilityEvents, api.get, url));
    return response;
  } catch (error) {
    return error;
  }
}

export function* getEquipmentUserGroupsSaga({ facilityId, equipmentId }) {
  try {
    const url = apiPathFactory.facilitiesGetEquipmentUserGroups(facilityId, equipmentId);
    const response = yield call(() => fetchEntitySaga(getEquipmentUserGroups, api.get, url));
    return response;
  } catch (error) {
    return error;
  }
}

export function* getEquipmentParametersSaga({ facilityId, equipmentId }) {
  try {
    const url = apiPathFactory.facilitiesGetEquipmentParameters(facilityId, equipmentId);
    const response = yield call(() => fetchEntitySaga(getEquipmentParameters, api.get, url));
    return response;
  } catch (error) {
    return error;
  }
}

export function* getInstalationsSaga() {
  try {
    const state = yield select();
    const selectedDescendants = state.visibleDescendants.selectedDescendants[FACILITY_DESCENDANT];
    const { selectedCities } = state.visibleDescendants;
    const { selectedPostalCodes } = state.visibleDescendants;
    const { searchQuery } = state.visibleDescendants;
    const { backendParams } = state;
    const pageSize = getCookiePageSizeItems() || backendParams.pagesize;
    const url = apiPathFactory.facilitiesGetPage(
      pageSize,
      backendParams.pagenumber[FACILITY_LIST],
      { selectedDescendants, selectedCities, selectedPostalCodes, searchQuery }
    );
    const response = yield call(() => fetchEntitySaga(getFacilities, api.get, url));
    return response;
  } catch (error) {
    return error;
  }
}

export function* getInstalationsFOBS({ facilityId, groupId }) {
  try {
    const url = apiPathFactory.fobsGetList(facilityId, groupId);
    const response = yield call(() => fetchEntitySaga(getInstalationFOBS, api.get, url));
    return response;
  } catch (error) {
    return error;
  }
}

export function* deleteAlarmFobSaga({ alarmId, groupId, facilityId, isUniversal, groupName }) {
  try {
    const url = apiPathFactory.alarmsDelete(alarmId);
    const response = yield call(() => fetchEntitySaga(deleteAlarmFob, api.del, url));
    yield put(resetInstalationFOBS());
    yield put(resetAllFobLists());
    if (isUniversal) {
      yield call(getUniversalFobsSaga, { universalGroupId: groupId });
      yield call(getUniversalGroupSaga, { id: groupId });
    } else {
      yield call(getInstalationsFOBS, { facilityId, groupId });
      yield call(getInstalationGroupSaga, { facilityId, groupId, include: true });
      yield call(getInstalationGroupsFobsCountSaga, {
        instalationID: facilityId,
        instalationGroupID: groupId,
      });
    }
    yield call(getFacilitySaga, { id: facilityId, include: true });
    return response;
  } catch (error) {
    return error;
  }
}

export function* postInstalationSaga({ instalation, isClonation }) {
  try {
    const url = isClonation
      ? apiPathFactory.facilitiesPostClone(instalation.oldInstalationId)
      : apiPathFactory.facilitiesPost();
    const response = yield call(() =>
      fetchEntitySaga(postInstalation, api.post, url, { data: instalation })
    );
    return response;
  } catch (error) {
    return error;
  }
}

export function* getInstalationGroupRelaysSaga({ instalationId, groupId }) {
  try {
    const url = apiPathFactory.relaysGet(instalationId, groupId);
    const response = yield call(() => fetchEntitySaga(getInstalationGroupRelays, api.get, url));
    return response;
  } catch (error) {
    return error;
  }
}

export function* getInstalationCitiesSaga() {
  try {
    const state = yield select();
    const selectedDescendants = state.visibleDescendants.selectedDescendants[FACILITY_DESCENDANT];
    const url = apiPathFactory.facilitiesGetCities(selectedDescendants);
    const response = yield call(() => fetchEntitySaga(getFacilityCities, api.get, url));
    return response;
  } catch (error) {
    return error;
  }
}

export function* getInstalationPostalCodesSaga() {
  try {
    const state = yield select();
    const selectedDescendants = state.visibleDescendants.selectedDescendants[FACILITY_DESCENDANT];
    const url = apiPathFactory.facilitiesGetPostalCodes(selectedDescendants);
    const response = yield call(() => fetchEntitySaga(getFacilityPostalCodes, api.get, url));
    return response;
  } catch (error) {
    return error;
  }
}

export function* putInstalationSaga({ instalation }) {
  instalation.maintenanceDate = convertDateTimeForBackend(instalation.maintenanceDate);
  try {
    const url = apiPathFactory.facilitiesPut(instalation.id);
    const response = yield call(() =>
      fetchEntitySaga(putInstalation, api.put, url, { data: instalation })
    );
    return response;
  } catch (error) {
    return error;
  }
}

export function* delInstalationSaga({ id }) {
  try {
    const url = apiPathFactory.facilitiesDelete(id.id);
    const response = yield call(() =>
      fetchEntitySaga(delInstalation, api.del, url, { toastNotShow: false })
    );
    yield getInstalationsSaga();
    yield call(getRecycleCheckerSaga, {});
    return response;
  } catch (error) {
    return error;
  }
}

export function* putInstalationGroupRelaySaga({ facilityId, groupId, relay }) {
  try {
    const url = apiPathFactory.relaysPut({
      facilityId,
      groupId,
      equipmentId: relay.equipmentId,
      relayId: relay.relayId,
      assignationId: relay.relayAssignationId,
    });
    const response = yield call(() =>
      fetchEntitySaga(putInstalationGroupRelays, api.put, url, { data: relay })
    );
    yield put(resetInstalationGroup());
    if (groupId) {
      yield call(getInstalationGroupSaga, {
        facilityId,
        groupId,
        include: true,
      });
    }
    if (relay?.instalationId) {
      yield call(getFacilitySaga, {
        id: relay?.instalationId,
        include: true,
      });
    }

    return response;
  } catch (error) {
    return error;
  }
}

export function* getInstalationInfoSaga({ id }) {
  try {
    const url = apiPathFactory.facilitiesGetWithInclude(id, true);
    const response = yield call(() => fetchEntitySaga(getInstalationInfo, api.get, url));
    return response;
  } catch (error) {
    return error;
  }
}

export function* putInstalationGeolocationSaga({ facilityId, geolocationURL }) {
  try {
    const url = apiPathFactory.facilitiesPutGeolocation({
      facilityId,
    });
    const response = yield call(() =>
      fetchEntitySaga(putInstalationGeolocation, api.put, url, { data: geolocationURL })
    );
    return response;
  } catch (error) {
    return error;
  }
}

export function* putTransferFacilitySaga({ facilityId, newCustomerId, valueSelected }) {
  try {
    const url = apiPathFactory.facilitiesTransfer(facilityId, newCustomerId);
    const response = yield call(() =>
      fetchEntitySaga(putTransferFacility, api.put, url, { facilityId, newCustomerId })
    );
    yield put(setSelectedDescendants(FACILITY_DESCENDANT, [valueSelected]));
    yield call(getInstalationsSaga, {});
    return response;
  } catch (error) {
    return error;
  }
}

export function* getRecycleCheckerSaga() {
  try {
    const url = apiPathFactory.facilitiesRecycleChecker();
    const response = yield call(() => fetchEntitySaga(getRecycleChecker, api.get, url));
    return response;
  } catch (error) {
    return error;
  }
}

export function* getRecycleListSaga() {
  try {
    const url = apiPathFactory.facilitiesRecycleList();
    const response = yield call(() => fetchEntitySaga(getRecycleList, api.get, url));
    return response;
  } catch (error) {
    return error;
  }
}

export function* setRecycleRestoreSaga({ facilityId }) {
  try {
    const url = apiPathFactory.facilitiesRecycleRestore(facilityId);
    const response = yield call(() =>
      fetchEntitySaga(setRecycleRestore, api.patch, url, { data: [facilityId] })
    );
    yield call(getRecycleListSaga, {});
    yield call(getRecycleCheckerSaga, {});
    return response;
  } catch (error) {
    return error;
  }
}

function* watchGetInstalationsSingleList() {
  yield takeLatest(InstalationsActions.GET_INSTALATIONS_SINGLE_LIST, getInstalationsSingleListSaga);
}

function* watchGeFacilities() {
  yield takeLatest(InstalationsActions.GET_INSTALATIONS, getInstalationsSaga);
}

function* watchGetFacilitiesPDF() {
  yield takeLatest(GET_FACILITIES_PDF.BASE, getFacilitiesHTMLSaga);
}

function* watchGetInstalationInfo() {
  yield takeLatest(InstalationsActions.GET_INSTALATION_INFO, getInstalationInfoSaga);
}

function* watchPutSchedules() {
  yield takeLatest(InstalationsActions.PUT_INSTALATIONS, putInstalationSaga);
}

function* watchDelSchedules() {
  yield takeLatest(InstalationsActions.DEL_INSTALATIONS, delInstalationSaga);
}

function* watchPostSchedules() {
  yield takeLatest(InstalationsActions.POST_INSTALATIONS, postInstalationSaga);
}

function* watchGetInstalationsRelays() {
  yield takeLatest(InstalationsActions.GET_INSTALATIONS_RELAYS, getInstalationGroupRelaysSaga);
}

function* watchPutInstalationsRelay() {
  yield takeLatest(InstalationsActions.PUT_INSTALATIONS_RELAY, putInstalationGroupRelaySaga);
}

function* watchGetInstalationFOBS() {
  yield takeLatest(InstalationsActions.GET_INSTALATIONS_FOBS, getInstalationsFOBS);
}

function* watchGetEquipmentUserGroups() {
  yield takeLatest(InstalationsActions.GET_EQUIPMENT_USER_GROUPS, getEquipmentUserGroupsSaga);
}

function* watchGetEquipmentParameters() {
  yield takeLatest(InstalationsActions.GET_EQUIPMENT_PARAMETERS, getEquipmentParametersSaga);
}

function* watchPutInstalationsGeolocation() {
  yield takeLatest(InstalationsActions.PUT_INSTALATIONS_GEOLOCATION, putInstalationGeolocationSaga);
}

function* watchGetFacilityFilters() {
  yield takeLatest(InstalationsActions.GET_FACILITY_FILTERS, getInstalationCitiesSaga);
}

function* watchGetFacilityFilters2() {
  yield takeLatest(InstalationsActions.GET_FACILITY_FILTERS, getInstalationPostalCodesSaga);
}

function* watchGetFacilityById() {
  yield takeLatest(InstalationsActions.GET_FACILITY, getFacilitySaga);
}

function* watchGetFacilityLogs() {
  yield takeLatest(FACILITY_LOGS_GET.BASE, getFacilityLogsSaga);
}

function* watchGetFacilitiesLogsByUser() {
  yield takeLatest(FACILITY_LOGS_BY_USER_GET.BASE, getFacilitiesLogsByUserSaga);
}

function* watchGetFacilityEvents() {
  yield takeLatest(GET_FACILITY_EVENTS.BASE, getFacilityEventsSaga);
}

function* watchDeleteAlarmFOB() {
  yield takeLatest(InstalationsActions.DELETE_ALARMS_INSTALATION, deleteAlarmFobSaga);
}

function* watchTransferFacility() {
  yield takeLatest(InstalationsActions.PUT_TRANSFER_INSTALATION, putTransferFacilitySaga);
}

function* watchCheckerRecycle() {
  yield takeLatest(InstalationsActions.GET_RECYCLE_INSTALLATION_CHECKER, getRecycleCheckerSaga);
}

function* watchListRecycle() {
  yield takeLatest(InstalationsActions.GET_RECYCLE_INSTALLATIONS, getRecycleListSaga);
}

function* watchRestoreRecycle() {
  yield takeLatest(InstalationsActions.SET_RECYCLE_RESTORE_INSTALLATION, setRecycleRestoreSaga);
}

export default function* watchers() {
  yield fork(watchGeFacilities);
  yield fork(watchGetFacilitiesPDF);
  yield fork(watchPutSchedules);
  yield fork(watchDelSchedules);
  yield fork(watchPostSchedules);
  yield fork(watchGetInstalationsRelays);
  yield fork(watchPutInstalationsRelay);
  yield fork(watchGetInstalationInfo);
  yield fork(watchGetFacilityFilters);
  yield fork(watchGetFacilityFilters2);
  yield fork(watchGetInstalationFOBS);
  yield fork(watchGetInstalationsSingleList);
  yield fork(watchGetEquipmentUserGroups);
  yield fork(watchGetEquipmentParameters);
  yield fork(watchPutInstalationsGeolocation);
  yield fork(watchGetFacilityById);
  yield fork(watchGetFacilityLogs);
  yield fork(watchGetFacilitiesLogsByUser);
  yield fork(watchGetFacilityEvents);
  yield fork(watchDeleteAlarmFOB);
  yield fork(watchTransferFacility);
  yield fork(watchCheckerRecycle);
  yield fork(watchListRecycle);
  yield fork(watchRestoreRecycle);
}
