import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Data, Toolbar } from 'react-data-grid-addons';
import ReactDataGrid from 'react-data-grid';
import CustomHeader from './CutomHeader';
import './GenericGrid.css';
import { withTranslation } from 'react-i18next';

class GenericGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gridWidth: 0,
      list: [],
      filters: {},
    };
    this.myRef = React.createRef();
  }

  componentDidMount = () => {
    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions);
  };

  updateDimensions = () => {
    if (this.myRef.current && this.myRef.current.clientWidth) {
      this.setState({ width: this.myRef.current.clientWidth });
    }
  };

  sortRows = (sortColumn, sortDirection) => {
    const comparer = (a, b) => {
      if (sortDirection === 'ASC') {
        return a[sortColumn] > b[sortColumn] ? 1 : -1;
      }
      if (sortDirection === 'DESC') {
        return a[sortColumn] < b[sortColumn] ? 1 : -1;
      }
    };
    const newRows =
      sortDirection === 'NONE' ? this.props.list.slice(0) : [...this.props.list].sort(comparer);
    this.setState({ list: newRows });
  };

  handleFilterChange = (filter) => {
    const newFilters = { ...this.state.filters };
    if (filter.filterTerm) {
      newFilters[filter.column.key] = filter;
    } else {
      delete newFilters[filter.column.key];
    }

    this.setState({ filters: newFilters });
  };

  getValidFilterValues = (columnId) => {
    const values = this.props.list.map((r) => r[columnId]);
    return values.filter((item, i, a) => i === a.indexOf(item));
  };

  handleOnClearFilters = () => {
    this.setState({ filters: {} });
  };

  getRows = (rows, filters) => Data.Selectors.getRows({ rows, filters });

  getColumns = () =>
    this.props.getColumns().map((column) => ({ ...column, headerRenderer: <CustomHeader /> }));

  cellSelected = ({ rowIdx, idx }) => {
    this.grid.openCellEditor(rowIdx, idx);
  };

  render() {
    const { getCellActions, list, onGridRowsUpdated, enableCellSelect, t } = this.props;
    const rows = this.state.list.length ? this.state.list : list;
    const filteredRows = this.getRows(rows, this.state.filters);
    return (
      <div className='generic-grid' ref={(node) => this.myRef}>
        <ReactDataGrid
          ref={(node) => (this.grid = node)}
          columns={this.getColumns()}
          rowGetter={(i) => filteredRows[i]}
          toolbar={<Toolbar enableFilter filterRowsButtonText={t('genericGrid.filterButton')} />}
          rowsCount={filteredRows.length}
          getCellActions={(column, row) => getCellActions(column, row)}
          onGridSort={this.sortRows}
          onCellSelected={(e) => this.cellSelected(e)}
          onAddFilter={this.handleFilterChange}
          getValidFilterValues={this.getValidFilterValues}
          onClearFilters={this.handleOnClearFilters}
          onGridRowsUpdated={onGridRowsUpdated}
          enableCellSelect={enableCellSelect}
        />
      </div>
    );
  }
}

const gridWithTranslation = withTranslation()(GenericGrid);

const mapStateToProps = (state) => ({
  tenants: state.tenants,
});

export default connect(mapStateToProps, {})(gridWithTranslation);
