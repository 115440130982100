import React, { Component } from 'react';
import { connect } from 'react-redux';
import StyledInput from '../Input/StyledInput';
import ErrorMessage from '../ErrorMessage';
import StyledSpan from '../Input/StyledSpan';
import StyledIcon from '../Input/StyledIcon';
import './Password.css';

export class Password extends Component {
  render() {
    const {
      type,
      placeHolder,
      value,
      id,
      onChange,
      tenants,
      error,
      disable,
      required,
      icon,
      onFocus,
      onBlur,
      isFocused,
      min,
      maxlength,
      className,
      onIconClick,
      iconDisabled,
      max,
      componentRef,
      showPSW,
    } = this.props;
    return placeHolder ? (
      <label className='jcm-label PATATA'>
        <StyledInput
          tenants={tenants}
          icon={icon}
          error={error}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          isFocused={isFocused}
          value={value || ''}
          // defaultValue={value || ''}
          type={showPSW ? 'input' : 'password'}
          id={id}
          name={id}
          disabled={disable}
          required={required}
          autoComplete='off'
          min={min}
          // max={max}
          maxLength={maxlength}
          ref={componentRef}
        />
        {icon && (
          <StyledIcon
            tenants={tenants}
            disabled={iconDisabled}
            className={`input-icon ${icon}`}
            value={value}
            isFocused={isFocused}
            onClick={!iconDisabled ? onIconClick : null}
          />
        )}
        <StyledSpan tenants={tenants} icon={icon} value={value} isFocused={isFocused}>
          {placeHolder}
        </StyledSpan>
        {icon && (
          <StyledIcon
            tenants={tenants}
            disabled={iconDisabled}
            className={showPSW ? `input-icon-right fa fa-eye-slash` : 'input-icon-right fa fa-eye'}
            value={value}
            isFocused={isFocused}
            onClick={!iconDisabled ? onIconClick : null}
          />
        )}
        {error ? <ErrorMessage message={error.errorMessage} /> : null}
      </label>
    ) : (
      <StyledInput
        tenants={tenants}
        className={className}
        icon={icon}
        error={error}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        isFocused={isFocused}
        value={value || ''}
        // defaultValue={value || ''}
        type={type}
        id={id}
        name={id}
        disabled={disable}
        required={required}
        autoComplete='off'
        min={min}
        max={max}
        maxlength={maxlength}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  tenants: state.tenants,
});

export default connect(mapStateToProps, {})(Password);
