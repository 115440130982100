import { GET_SEARCH, RESET_GLOBAL_SEARCH_RESULT } from './constants';

/* Redux state init */
const initialState = {
  result: null,
  needCallGlobalSearch: true,
};

export default function globalSearchReducer(state = initialState, action) {
  if (action.type !== undefined) {
    switch (action.type) {
      case GET_SEARCH.REQUEST:
        return {
          ...state,
          needCallGlobalSearch: false,
        };
      case GET_SEARCH.SUCCESS:
        return {
          ...state,
          needCallGlobalSearch: true,
          result: { ...action.result },
        };
      case RESET_GLOBAL_SEARCH_RESULT:
        return {
          ...state,
          result: null,
        };
      default:
        return state;
    }
  }
  return state;
}
