import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { ContentContainer } from '../ContentContainer/ContentContainer';
import SwitchCheckbox from '../SwitchCheckbox';
import Button from '../GenericForm/Button';

import './PopUpBitMap.css';

class PopUpBitMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      relayArray: [],
      bitMapObject: props.data,
      showError: false,
    };
  }

  componentDidMount() {
    const { bitMapObject } = this.state;
    const newRelayArray = bitMapObject.value.substr(2).split('');
    this.setState({ relayArray: newRelayArray });
  }

  handleAccept = (e) => {
    const { closePopup, onSave } = this.props;
    onSave(this.getNewBitMap());
    closePopup();
  };

  getNewBitMap = () => {
    const { relayArray, bitMapObject } = this.state;
    const newValue = `0b${this.getBitString(relayArray)}`;
    return { ...bitMapObject, value: newValue };
  };

  getBitString = (array) => {
    const newValue = array.join('');
    return newValue;
  };

  handleChange = (e) => {
    const { relayArray, bitMapObject } = this.state;
    const position = this.getPositionFromId(e);
    const modifiedArray = [...relayArray];
    const newArray = modifiedArray.map((relay, i) => {
      if (i === position) {
        return e.target.checked ? '1' : '0';
      }
      return relay;
    });

    const newArrayInBinary = parseInt(this.getBitString(newArray), 2);
    const maxInBinary = parseInt(bitMapObject.max.replace('0b', ''), 2);

    if (newArrayInBinary <= maxInBinary) {
      this.setState({ relayArray: newArray });
      this.setState({ showError: false });
    } else {
      this.setState({ showError: true });
    }
  };

  getPositionFromId = (e) => {
    const { id } = e.target;
    return Number(id.slice(id.length - 1));
  };

  render() {
    const { tenants, t, closePopup } = this.props;
    const { relayArray, showError } = this.state;
    return (
      <div>
        <ContentContainer tenants={tenants} title={t('parameters.titleRelays')}>
          <span className='popup-bit-map-relays'>
            {relayArray.map((relay, i) => (
              <SwitchCheckbox
                key={`relay${i}`}
                id={`relay${i}`}
                value={relay === '1'}
                placeHolder={`Relay ${i + 1}`}
                onChange={this.handleChange}
              />
            ))}
          </span>
          <span className='popup-bit-map-relays popup-bit-map-error'>
            {showError ? `${t('parameters.errorRelaysPopUp')}` : ''}
          </span>
          <div className='genericFormControl genericFormControlCenter genericFormControlButtons'>
            <Button
              id='entry'
              disabled={false}
              tenants={tenants}
              onClick={this.handleAccept}
              hidden={false}
              type='button'
            >
              {t('genericForm.save')}
            </Button>
            <Button id='cancel' tenants={tenants} onClick={closePopup} hidden={false} type='button'>
              {t('genericForm.cancel')}
            </Button>
          </div>
        </ContentContainer>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  tenants: state.tenants,
});

const PopUpBitMapTranslation = withTranslation()(PopUpBitMap);

export default connect(mapStateToProps, {})(PopUpBitMapTranslation);
