export default {
  resetPassword: {
    email: 'E-mail',
    newPassword: 'Új jelszó',
    backToLogin: 'Vissza bejelentkezési oldalra',
    mailReseted: 'Az Ön e-mail címe helyreállt',
    passwordReset: 'Jelszó visszaállítása',
    send: 'Küldés',
  },
};
