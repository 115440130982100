import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/ca';
import 'moment/locale/cs';
import 'moment/locale/en-gb';
import 'moment/locale/fr';
import 'moment/locale/it';
import 'moment/locale/de';
import { useDispatch, useSelector } from 'react-redux';
import { Text } from '@jcm-technologies/uikit/dist/atoms/Typography';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { v1 as uuidv1 } from 'uuid';
import PropTypes from 'prop-types';
import { updateArrayNodeByPropName } from '../../../../core/helpers/arrayService';
import { ModalScheduleEditEvent } from '../../Modal/EditEvent';
import { EventSchedule } from '../Event';
import { showAlert } from '../../../../modules/state/alerts';

const localizer = momentLocalizer(moment);
const DragAndDropCalendar = withDragAndDrop(Calendar);

export function WeeklyCalendar({
  data,
  onChangeAction,
  id,
  isOpenedEditEvent,
  setIsOpenedEditEvent,
}) {
  const { color1 } = useSelector((state) => state.tenants);
  const { t } = useTranslation();
  const { roleMask, language } = useSelector((state) => state.user.user);
  const [eventsData, setEventsData] = useState(data || []);
  const [eventData, setEventData] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const isSelectable = roleMask === 256;
  const dispatch = useDispatch();

  useEffect(() => {
    setEventsData(data);
  }, [data]);

  const datePropToMoment = (date) => moment(date, 'YYYY/MM/DD HH:mm:ss.SSS');

  const mapToRBCFormat = (event) => {
    const { start, end } = event;
    return { ...event, start: moment(start).toDate(), end: moment(end).toDate() };
  };

  const getRealEvent = (id) => data?.find((event) => event.id === id);

  const getRealEventFormatted = (id) => {
    const realEvent = getRealEvent(id);
    if (realEvent) {
      return {
        start: moment(realEvent.start, 'YYYY/MM/DD HH:mm:ss.SSS'),
        end: moment(realEvent.end, 'YYYY/MM/DD HH:mm:ss.SSS'),
      };
    }
    return {};
  };

  const getFormattedStringDate = (date) =>
    `${`${date.getMonth() + 1}`.padStart(2, '0')}/${`${date.getDate()}`.padStart(
      2,
      '0'
    )}/${date.getFullYear()} ${`${date.getHours()}`.padStart(
      2,
      '0'
    )}:${`${date.getMinutes()}`.padStart(2, '0')}`;

  const getUTCDateString = (date) =>
    moment(new Date(`${getFormattedStringDate(date)} UTC`)).toISOString();

  const getMoveEventStartTime = (realEventStart, newStart) => {
    let minutesToAdd = realEventStart.minutes();
    if (realEventStart.minutes() % 30 !== 0) {
      if (realEventStart.minutes() > 30) {
        minutesToAdd -= 30;
      }
      newStart.setMinutes(newStart.getMinutes() + minutesToAdd);
    }
    return newStart;
  };

  const getNewDateRange = (event, start) => {
    const parsedOldSchedule = {
      start: datePropToMoment(event.start).valueOf(),
      end: datePropToMoment(event.end).valueOf(),
    };
    const ellapsedTime = parsedOldSchedule.end - parsedOldSchedule.start;
    return {
      ...event,
      start: getUTCDateString(
        getMoveEventStartTime(moment(event.start, 'YYYY/MM/DD HH:mm:ss.SSS'), start)
      ),
      end: getUTCDateString(
        new Date(moment(start, 'YYYY/MM/DD HH:mm:ss.SSS').valueOf() + ellapsedTime)
      ),
    };
  };

  const hasScheduleInSameRange = (newSchedule, ...excludeIds) => {
    if (data) {
      const parsedNewSchedule = {
        start: datePropToMoment(newSchedule.start).valueOf(),
        end: datePropToMoment(newSchedule.end).valueOf(),
      };
      const listToSee = excludeIds.length
        ? data.filter((val) => !excludeIds.includes(val.id))
        : data;
      return listToSee.some((schedule) => {
        const oldSchedule = {
          start: datePropToMoment(schedule.start?.split('+')[0]).valueOf(),
          end: datePropToMoment(schedule.end?.split('+')[0]).valueOf(),
        };
        const scheduleInRange =
          (oldSchedule.start < parsedNewSchedule.start &&
            oldSchedule.end > parsedNewSchedule.start) ||
          (oldSchedule.start >= parsedNewSchedule.start &&
            oldSchedule.start < parsedNewSchedule.end);
        return scheduleInRange;
      });
    }
    return false;
  };

  const formatEvents = (events) => {
    events.map((e) => {
      e.start = moment(e.start, 'YYYY-MM-DD HH:mm:ss.SSS').format();
      e.end = moment(e.end, 'YYYY-MM-DD HH:mm:ss.SSS').format();
      return e;
    });
  };

  const onChangeEvents = (nextEvents) => {
    formatEvents(nextEvents);
    setEventsData([...eventsData, nextEvents]);
    onChangeAction(id, nextEvents);
  };

  const moveEvent = ({ event, start }) => {
    const realEvent = { ...event, ...(getRealEventFormatted(event.id) || event) };
    const updatedEvent = getNewDateRange(realEvent, start);
    updatedEvent.from = updatedEvent.start;
    updatedEvent.to = updatedEvent.end;
    if (hasScheduleInSameRange(updatedEvent, event.id)) {
      dispatch(showAlert('red', 1, t('error.scheduleDateOnSameRange')));
    } else {
      const nextEvents = updateArrayNodeByPropName(data, updatedEvent, 'id');
      onChangeEvents(nextEvents);
    }
  };

  const newEvent = (event) => {
    const newId = uuidv1();
    const completeDateStart = getUTCDateString(event.start);
    const completeDateEnd = getUTCDateString(event.end);

    const hour = {
      id: newId,
      start: completeDateStart,
      end: completeDateEnd,
      scheduleId: id,
      from: completeDateStart,
      to: completeDateEnd,
    };

    if (hasScheduleInSameRange(hour)) {
      dispatch(showAlert('red', 1, t('error.scheduleDateOnSameRange')));
    } else {
      onChangeEvents(data?.concat([hour]));
    }
  };
  const onDelete = (idToDelete, newEvents) => {
    const nextEvents = newEvents.filter((existingEvent) => existingEvent.id !== idToDelete);
    onChangeEvents(nextEvents);
    setIsDeleting(false);
  };

  useEffect(() => {
    if (isDeleting) {
      onDelete(eventData?.id, eventsData);
    }
  }, [isDeleting]);

  const resizeEvent = ({ event, start, end, difference }) => {
    const updatedEvent = {
      ...event,
      start: getUTCDateString(start),
      end: getUTCDateString(end),
    };
    const ms = moment(end, 'DD/MM/YYYY HH:mm:ss').diff(moment(start, 'DD/MM/YYYY HH:mm:ss'));
    const d = moment.duration(ms);
    const differenceStatus = Math.floor(d.asHours()) + moment.utc(ms).format(':mm:ss');
    const signDifference = Math.sign(differenceStatus?.split(':')?.[0]);

    if (difference < '0' || differenceStatus === '0:00:00' || signDifference === -1) {
      dispatch(showAlert('red', 1, t('error.scheduleDateRangeInvalid')));
    } else if (hasScheduleInSameRange(updatedEvent, event.id)) {
      dispatch(showAlert('red', 1, t('error.scheduleDateOnSameRange')));
    } else {
      const nextEvents = eventsData.map((existingEvent) =>
        existingEvent.id === event.id
          ? { ...existingEvent, start, end, from: updatedEvent.start, to: updatedEvent.end }
          : existingEvent
      );
      onChangeEvents(nextEvents);
    }

    setEventData(null);
  };

  return (
    <>
      <Text id='weekly-title' color='black' marginBottom={2}>
        {t('general.weekly')}
      </Text>
      <DragAndDropCalendar
        allDayAccessor={(event) => {
          const start = moment(event.start);
          const end = moment(event.end);
          if (
            start.day === end.day &&
            start.hours() === 0 &&
            end.hours() === 0 &&
            start.minutes() === end.minutes()
          ) {
            return true;
          }
          return false;
        }}
        selectable={!isSelectable}
        localizer={localizer}
        culture={language?.replace('_', '-')}
        events={eventsData?.map((el) => mapToRBCFormat(el)) || []}
        onChange={onChangeAction}
        id={id}
        defaultDate={new Date(1970, 0, 5)}
        onEventDrop={moveEvent}
        onEventResize={resizeEvent}
        resizable
        onSelectSlot={newEvent}
        onSelectEvent={(event) => {
          setEventData(event);
        }}
        eventPropGetter={() => ({
          style: {
            minHeight: '22px',
            backgroundColor: color1,
            border: 0,
          },
        })}
        defaultView={Views.WEEK}
        components={{
          event: (props) => (
            <EventSchedule
              data={data}
              eventsData={eventsData}
              setIsOpenedEditEvent={setIsOpenedEditEvent}
              onDelete={() => setIsDeleting(true)}
              {...props}
            />
          ),
        }}
        toolbar={false}
        min={new Date(1970, 0, 5, 0, 0)}
        max={new Date(1970, 0, 11, 23, 59)}
        formats={{
          dayFormat: 'ddd',
          eventTimeRangeFormat: () => null,
        }}
        style={{ height: '75vh' }}
      />

      <ModalScheduleEditEvent
        data={eventData}
        isOpened={isOpenedEditEvent}
        setIsOpened={setIsOpenedEditEvent}
        action={(eventNew) => resizeEvent(eventNew)}
      />
    </>
  );
}

WeeklyCalendar.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  onChangeAction: PropTypes.func.isRequired,
  id: PropTypes.string,
  isOpenedEditEvent: PropTypes.bool.isRequired,
  setIsOpenedEditEvent: PropTypes.func.isRequired,
};
