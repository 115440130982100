import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from '@jcm-technologies/uikit/dist/molecules/Card';
import { Flex } from '@jcm-technologies/uikit/dist/atoms/Layout';
import { Text } from '@jcm-technologies/uikit/dist/atoms/Typography';
import { Button } from '@jcm-technologies/uikit/dist/atoms/Button';
import ToolTip from '@jcm-technologies/uikit/dist/atoms/Tooltip';
import { getContrastColor } from '../../../../core/helpers/colorManager';
import { useSelector } from 'react-redux';

const QuickActions = () => {
  const { t } = useTranslation();
  const tenants = useSelector((state) => state.tenants);
  return (
    <Card
      rounded='soft'
      width='100%'
      height='100%'
      maxWidth='100%'
      position='relative'
      marginBottom={2}
      display='flex'
      id='quickactions-card'
    >
      <Flex width='100%' flexDirection='column'>
        <Flex alignItems='center'>
          <Text id='quickactions-title'>{t('form.quickActions')}</Text>
        </Flex>
        <Flex
          justifyContent='center'
          alignItems='center'
          flex={1}
          flexDirection='column'
          marginTop={3}
        >
          <Flex
            data-tip={t('error.functionNotAvailable')}
            data-for='action1'
            flexDirection='column'
            alignItems='center'
          >
            <ToolTip toolTipId='action1' />
            <Text id='quickactions-checkAllSeeAlarms-title' sizeText='display14'>
              {t('dashboard.checkAllSeeAlarms')}
            </Text>
            <Button
              marginTop={1}
              disabled
              backgroundColor={tenants?.color1}
              action={() => {}}
              sizeButton='small'
              color={getContrastColor(tenants?.color1)}
              id='quickactions-checkAllSeeAlarms-button'
            >
              {t('dashboard.updateAlarms')}
            </Button>
          </Flex>
          <Flex
            data-tip={t('error.functionNotAvailable')}
            data-for='action2'
            flexDirection='column'
            alignItems='center'
            marginTop={3}
          >
            <ToolTip toolTipId='action2' />
            <Text id='quickactions-updatemissconfiguredConnected-title' sizeText='display14'>
              {t('dashboard.updatemissconfiguredConnected')}
            </Text>
            <Button
              id='quickactions-updatemissconfiguredConnected-button'
              marginTop={1}
              disabled
              backgroundColor={tenants?.color1}
              action={() => {}}
              sizeButton='small'
              color={getContrastColor(tenants?.color1)}
            >
              {t('dashboard.updateDevices')}
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Card>
  );
};

export default QuickActions;
