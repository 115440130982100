export default {
  paramsName: {
    A5KPROXY: 'A5K proxy konfiguráció',
    ACTIVATIONKEY: 'Aktiváló kulcs',
    ACTIVEELEMENTS: 'Aktív események',
    ACTIVEEVENTSTOSD: 'A5K események beállítása',
    ANTIPANICCONFIGRELE1: '1-es relé beállítása (impulzus vagy ON/OFF vagy pánik)',
    ANTIPANICCONFIGRELE2: '2-es relé beállítása (impulzus vagy ON/OFF vagy pánik)',
    ANTIPANICCONFIGRELE3: '3-as relé beállítása (impulzus vagy ON/OFF vagy pánik)',
    ANTIPANICCONFIGRELE4: '4-es relé beállítása (impulzus vagy ON/OFF vagy pánik)',
    APBKMODE: 'Anti-Passback mód (APB)',
    APBKTIMEBACK: 'Anti-Passback idő visszaállítása percekben en (Anti-timeback)',
    APNCONFIG: 'APN beállítás',
    BADGE_MAITRE01: '1. főkód',
    BADGE_MAITRE10: '10. főkód',
    BADGE_MAITRE2: '2. főkód',
    BADGE_MAITRE3: '3. főkód',
    BADGE_MAITRE4: '4. főkód',
    BADGE_MAITRE5: '5. főkód',
    BADGE_MAITRE6: '6. főkód',
    BADGE_MAITRE7: '7. főkód',
    BADGE_MAITRE8: '8. főkód',
    BADGE_MAITRE9: '9. főkód',
    CONFIGRELAYSNEW1: 'Aktív relék az 1. csatornán',
    CONFIGRELAYSNEW2: 'Aktív relék az 1. csatornán',
    CONFIGRELAYSNEW3: 'Aktív relék az 1. csatornán',
    CONFIGRELAYSNEW4: 'Aktív relék az 1. csatornán',
    CONFIGRELAYSPIN1: 'Relék PIN programozásra. Csatorna: 1',
    CONFIGRELAYSPIN2: 'Relék PIN programozásra. Csatorna: 2',
    CONFIGRELAYSPIN3: 'Relék PIN programozásra. Csatorna: 3',
    CONFIGRELAYSPIN4: 'Relék PIN programozásra. Csatorna: 4',
    CONFIGRELE1: '1. relé beállítása (impulzus vagy ON/OFF)',
    CONFIGRELE2: '2. relé beállítása (impulzus vagy ON/OFF)',
    CONFIGRELE3: 'Relé 3 beállítása (impulzus vagy ON/OFF)',
    CONFIGRELE4: 'Relé 3 beállítása (impulzus vagy ON/OFF)',
    CONFIGRELE_ANTIPANIC: 'Pánik',
    CONNECTIVITY_EXTENSION: 'Link kártya telepítve',
    Configuration_Params_BUTT_NC: 'Általában zárva gomb',
    Configuration_Params_BUTT_NO: 'Általában nyitva gomb',
    Configuration_Params_ConfigRele_Antipanic: 'Antipánik',
    Configuration_Params_ConfigRele_Biestable: 'Bistabil',
    Configuration_Params_ConfigRele_Impulsional: 'Impulzív',
    Configuration_Params_D2_HSCLS: '[Kapu 2] Záró végálláskapcsoló érzékelő',
    Configuration_Params_D2_HSOLS: '[Kapu 2] Nyitási végálláskapcsoló érzékelő',
    Configuration_Params_D2_NCCLS: '[Kapu 2] Normál esetben zárt záró végálláskapcsoló',
    Configuration_Params_D2_NCOLS: '[Kapu 2] Normál esetben zárt nyitási végálláskapcsoló',
    Configuration_Params_D2_NOCLS: '[Kapu 2] Normál esetben nyitott záró végálláskapcsoló',
    Configuration_Params_D2_NOOLS: '[Kapu 2] Normál esetben nyitott nyitó végálláskapcsoló',
    Configuration_Params_DEBUG: 'Hibaelhárítás',
    Configuration_Params_GO: 'GO',
    Configuration_Params_GOPRO: 'GO-PRO',
    Configuration_Params_HANDSFREEALLOWED: 'HONOA Lehetővé teszi a BlueTooth kihangosítást (BETA)',
    Configuration_Params_HFADVANCE: 'HF-ADVANCE',
    Configuration_Params_HIGH: 'Magas',
    Configuration_Params_HSCLS: '[Kapu 1] Záró végálláskapcsoló érzékelő',
    Configuration_Params_HSOLS: '[Kapu 1] Nyitási végálláskapcsoló érzékelő',
    Configuration_Params_LOW: 'Alacsony',
    Configuration_Params_MIN: 'Minimum',
    Configuration_Params_MODE1: 'Mód 1 (egycsatornás bemenet/kimenet, szabad kimenet)',
    Configuration_Params_MODE2: 'Mód 2 (egycsatornás bemenet / kimenet)',
    Configuration_Params_MODE3: 'Mód 3 (1. csatorna bemenet /2. csatorna kimenet)',
    Configuration_Params_MODE4: 'Mód 4 (1. és 3. csatorna bemenet / 2. és 4. csatorna kimenet)',
    Configuration_Params_NCCLS: '[Kapu 1] Normál esetben zárt záró végálláskapcsoló',
    Configuration_Params_NCOLS: '[Kapu 1] Normál esetben zárt nyitási végálláskapcsoló',
    Configuration_Params_NCSC: 'Normál esetben zárt biztonsági lánc',
    Configuration_Params_NOCLS: '[Kapu 1] Normál esetben nyitott záró végálláskapcsoló',
    Configuration_Params_NOOLS: '[Kapu 1] Normál esetben nyitott nyitó végálláskapcsoló',
    Configuration_Params_NOSC: 'Normál esetben nyitott biztonsági lánc',
    Configuration_Params_NSCLS: '[Kapu 1] Nyitási végálláskapcsoló érzékelő',
    Configuration_Params_NSOLS: '[Kapu 1] Záró végálláskapcsoló érzékelő',
    Configuration_Params_No: 'Nem',
    Configuration_Params_NoFixed: 'Nem rögzített',
    Configuration_Params_OPENREMOTE: 'HONOA Távoli nyitás engedélyezése',
    Configuration_Params_PIN: 'PIN',
    Configuration_Params_PINCADUCAT: 'A PIN kód lejárt',
    Configuration_Params_UNUSED: 'Nem használt',
    Configuration_Params_ValueTEVENTS: 'Igen',
    Configuration_Params_W26FORMAT0: 'Kód (16 bits) + helyszín kód (8 bits)',
    Configuration_Params_W26FORMAT1: 'Kód (24 bits)',
    Configuration_Params_Yes: 'Igen',
    Configuration_Params_entranceNC: 'APB bemenet normál esetben zárt',
    Configuration_Params_entranceNO: 'APB bemenet normál esetben nyitott',
    Configuration_Params_exitNC: 'APB bemenet normál esetben zárt',
    Configuration_Params_exitNO: 'APB kimenet normál esetben nyitott',
    DATAUSAGE: 'Adathasználat',
    DISABLEMANUALPROG: 'Kézi programozás érvénytelenítve',
    DISABLERADIOPROG: 'Rádió programozás érvénytelenítve',
    DOOROPENEDEVENTTIME: 'Kapunyitási esemény',
    EMMITTERNOTUSEDEVENTTIME: 'Ne használja a távirányító eseményidőpontját',
    FREEACTIVE: 'Aktív csoport az ingyenes rendszerben',
    FREEGROUP0LOCKED: '0-ás csoport az ingyenes rendszerben blokkolva',
    FREEGROUPNUM: 'Csoportok száma az ingyenes rendszerben',
    FREEGROUPRFPROGALLOWED: 'Rádióprogramozási üzemmód engedélyezett az ingyenes rendszerben',
    GO: 'GO',
    GOPRO: 'Go-Pro',
    HANDSFREEALLOWED: 'HONOA Lehetővé teszi a BlueTooth kihangosítást (BETA)',
    HFADVANCE: 'HF-ADVANCE',
    IMPBIRELE1: 'Az 1. relé bistabilként történő konfigurálása',
    INPUT10KEY: 'Rádióérzékelő 10 gomb',
    INPUT10MODE: '10-es bemeneti szenzor neve',
    INPUT10NAME: '10-es rádiószenzor neve',
    INPUT1KEY: 'Bemenet 1 billentyű',
    INPUT1MODE: '1-es bemeneti szenzor neve',
    INPUT1NAME: '1-es bemenet neve',
    INPUT2KEY: 'Bemenet 2 billentyű',
    INPUT2MODE: '2-es bemeneti szenzor neve',
    INPUT2NAME: '2-es bemenet neve',
    INPUT3KEY: 'Rádióérzékelő 3 gomb',
    INPUT3MODE: '3-as bemeneti szenzor neve',
    INPUT3NAME: '3-as rádiószenzor neve',
    INPUT4KEY: 'Rádióérzékelő 4 gomb',
    INPUT4MODE: '4-es bemeneti szenzor neve',
    INPUT4NAME: '4-es rádiószenzor neve',
    INPUT5KEY: 'Rádióérzékelő 5 gomb',
    INPUT5MODE: '5-ös bemeneti szenzor neve',
    INPUT5NAME: '5-ös rádiószenzor neve',
    INPUT6KEY: 'Rádióérzékelő 6 gomb',
    INPUT6MODE: '6-os bemeneti szenzor neve',
    INPUT6NAME: '6-os rádiószenzor neve',
    INPUT7KEY: 'Rádióérzékelő 7 gomb',
    INPUT7MODE: '7-es bemeneti szenzor neve',
    INPUT7NAME: '7-es rádiószenzor neve',
    INPUT8KEY: 'Rádióérzékelő 8 gomb',
    INPUT8MODE: '8-as bemeneti szenzor neve',
    INPUT8NAME: '8-as rádiószenzor neve',
    INPUT9KEY: 'Rádióérzékelő 9 gomb',
    INPUT9MODE: '9-es bemeneti szenzor neve',
    INPUT9NAME: '9-es rádiószenzor neve',
    INPUTS: 'Bemenetek',
    INSTAL: 'Telepítő',
    MINIDDEVICE: 'Minimális eszköz szám',
    MessageBox_ActivationRelay: 'Relé aktiválás',
    MessageBox_ConnectionError: 'Csatlakozási hiba',
    MessageBox_DeviceAccepted: 'Elfogadott eszköz',
    MessageBox_DeviceRecieved: 'Fogadott eszköz',
    MessageBox_FormatMemoryError: 'Rossz formátum a memóriában',
    MessageBox_InvalidDevice: 'Az eszköz nem érvényes',
    MessageBox_MemoryExtracted: 'Kivont memória',
    MessageBox_MemoryInserted: 'Beillesztett memória',
    MessageBox_Message: 'Aktív események konfigurálása',
    MessageBox_NotInMemory: 'Nem található a memóriában',
    MessageBox_OutOfSchedule: 'Időn kívül',
    MessageBox_PowerUp: 'ON',
    MessageBox_RelayState: 'A relé állapotának megváltoztatása',
    Name: 'Név',
    OPENREMOTE: 'HONOA Távoli nyitás engedélyezése',
    PASSWORD: 'Jelszó',
    PIN: 'PIN',
    PINCADUCAT: 'PIN lejárt',
    POWER: 'Tápegység',
    PRICE1: 'Az 1. relé kivonási értéke',
    PRICE2: 'Az 2. relé kivonási értéke',
    PRICE3: 'A 3. relé kivonási értéke',
    PRICE4: 'A 4. relé kivonási értéke',
    Product: 'Eszköz',
    RELAYPRICE1: 'Az 1. relé kivonási értéke',
    RELAYPRICE2: 'Az 2. relé kivonási értéke',
    RELAYPRICE3: 'Az 3. relé kivonási értéke',
    RELAYPRICE4: 'Az 4. relé kivonási értéke',
    RELAYS: 'Relék',
    RELE1IMP: '1-es relé beállítása (impulzus vagy ON/OFF)',
    RELE1TEMP: 'Időbeállító relé 1',
    RELE2IMP: '2-es relé beállítása (impulzus vagy ON/OFF)',
    RELE2TEMP: 'Időbeállító relé 2',
    RELE3IMP: '3-as relé beállítása (impulzus vagy ON/OFF)',
    RELE3TEMP: 'Időbeállító relé 3',
    RELE4IMP: '4-es relé beállítása (impulzus vagy ON/OFF)',
    RELE4TEMP: 'Időbeállító relé 4',
    Relay_Sound: 'Kapunyitás üzenet nyelve',
    SCHEDULESDATA: 'Ütemterv adatok',
    SENSORS: 'Érzékelők',
    SITECODE: 'Helyszín kódja',
    SerialNumber: 'Sorozatszám',
    Sip_Address: 'SIP cím',
    Sip_Status: 'SIP állapot',
    TEMPRELE1: '1-es relé időbeállítása',
    TEMPRELE2: '2-es relé időbeállítása',
    TEMPRELE3: '3-as relé időbeállítása',
    TEMPRELE4: '4-es relé időbeállítása',
    TEVENT: 'Eseménykártya',
    TIMEZONE: 'Időzóna azonosító',
    TIMEZONEID: 'Időzóna',
    TMEM: 'Memória kártya',
    UNIQUEKEY: 'Egyedi kulcs',
    UniqueIdentity: 'Egyedi azonosító',
    W26FORMAT: 'Wiegand 26 formátum',
    access_code: 'Hozzáférési kód',
    call_max_duration: 'A hívás maximális időtartama',
    call_outgoing_timeout: 'Kimenő hívás időkorlátja',
    cell_id: 'Hívóazonosító',
    connection_type: 'Csatlakozás típusa',
    correctVertexKey: 'A megadott kód érvényes',
    dbm: 'Decibel',
    door_english: 'Angol',
    door_french: 'Francia',
    door_spanish: 'Spanyol',
    estoSeraFalse: 'b',
    estoSeraTrue: 'a',
    fw_version: 'Firmware verzió',
    hw_version: 'Hardver verzió',
    iccid: 'ICCID szám',
    imei: 'IMEI szám',
    imsi: 'IMSI',
    incorrectVertexKey: 'A megadott kód nem érvényes',
    lac: 'LAC',
    level: 'Jelszint',
    mac_address: 'MAC cím',
    mcc: 'MCC',
    mic_volume: 'Mikrofon hangereje',
    mnc: 'MNC',
    none: 'Nincs',
    relay_dtmf_hang_call: 'Relé aktiválása hangjelzéssel',
    speaker_volume: 'Hangszóró hangereje',
    ACCESSCONTROL: 'Hozzáférés ellenőrzése',
    WIEGANDCONFIG: 'Wiegand konfiguráció',
    ALLOWNOTIFICATIONS: '“HONOA Mutassa az ajtó állapotát',
    LOCALEVENTMANAGEMENTCONFIG: 'Eredmény konfiguráció',
  },
};
