export default {
  neighbourPopUp: {
    title: 'Szomszéd hozzáadása',
    email: 'E-mail',
    phoneNumber: 'Telefonszám',
    floor: 'Emelet',
    door: 'Ajtó',
    titleEdit: 'Névjegy szerkesztése',
    titleDelete: 'Biztosan törölni szeretné ezt a névjegyet?',
  },
};
