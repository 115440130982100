export default {
  gdprPopup: {
    accept: 'Acceptar',
    cancel: 'Cancel·lar',
    conditionsAgreement: 'Acceptar els termes d\'ús',
    close: 'Tancar',
    firstPoliciAgreementText: 'CloudAssistant segueix evolucionant per a oferir-te millors serveis i donar cabuda a noves prestacions com el nou rol de Property Manager que et permet delegar la gestió dels emissors a aquells Administradors de Finques que vulguin donar un servei més proper a les seves comunitats.',
    firstPolicyAgreementSecondText: 'Hem modificat les polítiques per a donar cabuda a aquest nous serveis.',
    firstPolicyAgreementThirdText: 'Per a qualsevol dubte, pots contactar-nos a',
    privacyText: 'Política de privadesa',
    legalNoticeText: 'Avís legal',
    conditionsText: 'Condicions generals',
    cookiesText: 'Política de cookies',
    PolicyAgreementPopupTitle1: 'CloudAssistant s\'actualitza',
    PolicyAgreementPopupTitle2: 'nous serveis i polítiques',
    PolicyAgreementPopupAnd: 'i',
  },
};