import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { generatePath, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import moment from 'moment-timezone';
import IconPlus from '@jcm-technologies/uikit/dist/atoms/Icons/IconPlus';
import { CodesCounters } from '@jcm-technologies/uikit/dist/molecules/CodesCounters';
import { Button } from '@jcm-technologies/uikit/dist/atoms/Button';
import { Flex } from '@jcm-technologies/uikit/dist/atoms/Layout';
import { IconTooltip } from '@jcm-technologies/uikit/dist/molecules/IconTooltip';
import {
  getInstalationGroups,
  getInstalationGroupsFobsCounts,
  getSecondaryInstalationGroups,
  postInstalationGroup,
  putInstalationGroup,
} from '../../modules/old_to_refact/actions/instalationGroups';
import {
  deleteAlarms,
  getInstalationsFOBS,
  getInstalationsSingleList,
  needReturnToFacilities,
  resetAllFobLists,
  resetInstalationFOBS,
} from '../../modules/old_to_refact/actions/instalations';
import { resetEquipmentScroll } from '../../modules/old_to_refact/actions/equipments';
import {
  deleteFob,
  deleteFobList,
  fobsGetCheckCodes,
  importFobs,
  postCopyFobList,
  putFob,
  putMoveFobList,
  resetCheckedFobs,
} from '../../modules/group/fobs/actions';
import IndividualGrowGenericForm from '../IndividualGrowGenericForm/IndividualGrowGenericForm';
import KendoDropDownCell from '../KendoDropDownCell';
import { putUniversalGroupsFob } from '../../modules/old_to_refact/actions/universalGroupsFobs';
import {
  generateRows,
  getArrayWithAttributes,
  getItemWithAttributtes,
} from '../../core/old_common/utils/createInputByTenant';
import './IndividualInstalationGroupForm.css';
import getOnFlyValidations from '../../core/old_common/utils/validationsApi';
import { setOnFlyErrors } from '../../core/old_common/validations/service';
import apiPathFactory from '../../core/old_common/rooting/apiPathFactory';
import { IconSpan } from '../IconSpan';
import {
  invokeSendCICC,
  invokeSendFunction,
  setAssistantPanelCheckSuccessFunction,
  setMessageHubFunction,
} from '../../core/old_common/utils/signalRService';
import SignalRComponent from '../SignalRComponent/SignalRComponent';
import { getFamiliesSingleList } from '../../modules/productFamilies/action';
import { getFamilyListByType, getProduct } from '../../core/old_common/utils/familiesService';
import { resetManualGrowRedirectURL, setManualGrowRedirectURL, } from '../../modules/old_to_refact/actions/genericGrow';
import { getECodeTranslated } from '../../core/old_common/utils/facilityService';

import { createUniversalGroup, setUniversalGroup, } from '../../modules/old_to_refact/actions/universalGroups';
import { getCookie_groupIsUniversal } from '../../core/old_common/utils/cookiesManager';
import DeletePopup from '../DeletePopup/DeletePopup';
import PropsList from '../PropsList/PropsList';
import Popup from '../Popup/Popup';
import PopUpConfirm from '../PopUpConfirm';
import GenericKendoGrid from '../GenericKendoGrid/GenericKendoGrid';
import validationsDictionary from '../../core/old_common/validations';
import { resetSignalRPopup, setNeedPopup, setProductId, showPopUp, } from '../../modules/old_to_refact/actions/signalr';
import { replaceStringValue } from '../../core/old_common/utils/replaceStringService';
import PopUpErrorMessage from '../PopupErrorMessage/PopUpErrorMessage';
import PdfPopup from '../PdfPopup/PdfPopup';
import { createArrayFromObjectParam } from '../../core/helpers/arrayService';

class IndividualInstalationGroupForm extends SignalRComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this.createNewState(props),
      substitutionSignalRTrack: '',
      fobCodeSignalRTrack: '',
      isCheckingCodes: undefined,
      isInstalationSingleListCalled: false,
      isDeleteFobPopupOpen: false,
      deleteFobItem: {},
      alarmId: null,
      isErrorMessagePopupOpen: false,
      productIdWrite: undefined,
      popupOpenPDF: false,
      popup: {
        isPopupOpen: false,
        text: '',
        onAcceptFn: () => {
          this.onCleanAlarm();
        },
        onCancelFn: () => {
          this.onToggleCleanAlarm();
        },
      },
      needResetCounters: false,
      functionSended: false,
      codesSubstitutionDone: undefined,
      getFobs: false,
    };
    this.familyType = props.isUniversal || getCookie_groupIsUniversal() ? 2 : 1;
  }

  componentDidMount() {
    const {
      match,
      baseURI,
      creationURI,
      setManualGrowRedirectURL,
      redirectURL,
      resetManualGrowRedirectURL,
      getSecondaryInstalationGroups,
      facility,
      id,
    } = this.props;
    setMessageHubFunction(this.newMessageHubFunction);
    setAssistantPanelCheckSuccessFunction(this.codesSubstitution);
    if (match.params.id === creationURI) {
      setManualGrowRedirectURL(`${baseURI}/~~~name~~~/Configuration`);
    } else if (redirectURL) {
      resetManualGrowRedirectURL();
    }
    if (facility && id) {
      getSecondaryInstalationGroups(facility.id, id);
    }
  }

  componentDidUpdate = () => {
    const {
      match,
      codeExists,
      resetCheckedFobs,
      errorMessage,
      t,
      setNeedPopup,
      showSignalRPopup,
      errorPutFobsMessage,
      facilityId,
      id,
      getInstalationGroupsFobsCounts,
      getInstalationGroups,
      isFobSuccess,
      creationURI,
      instalationId,
      fobsCount,
    } = this.props;
    const {
      substitutionSignalRTrack,
      isCheckingCodes,
      needResetCounters,
      functionSended,
      getFobs,
    } = this.state;
    if (match.params.instalationId !== instalationId) {
      getInstalationGroups(match.params.instalationId);
    }
    if (codeExists && isCheckingCodes) {
      toast.error(t(`apitoasters.errors.${errorMessage}`));
      resetCheckedFobs();
      this.setState({ isCheckingCodes: undefined, isErrorMessagePopupOpen: true });
    } else if (codeExists === false && isCheckingCodes) {
      setNeedPopup();
      if ((showSignalRPopup === false || showSignalRPopup === undefined) && functionSended) {
        invokeSendFunction(substitutionSignalRTrack);
        this.setState({ functionSended: false });
      }
    }
    if (errorPutFobsMessage !== undefined && isCheckingCodes) {
      this.setState({ isCheckingCodes: undefined });
    }
    if (needResetCounters && isFobSuccess) {
      getInstalationGroupsFobsCounts(facilityId, id);
      this.setState({ needResetCounters: false });
    }
    if (!getFobs && fobsCount === undefined) {
      getInstalationGroupsFobsCounts(facilityId, id);
      this.setState({ getFobs: true });
    }
  };

  componentWillUnmount = () => {
    const { resetInstalationFOBS, resetAllFobLists } = this.props;
    resetInstalationFOBS();
    resetAllFobLists();
  };

  isRemovePopUpOpen = (isOpen) => this.setState({ isRemovePopUpOpen: isOpen });

  setPopUpData = (isOpen, action, title) => {
    this.setState({ isActionPopUpOpen: isOpen, savePopUpAction: action, title });
  };

  onToggleCleanAlarm = (alarmId, props) => {
    const { popup } = this.state;
    if (popup.isPopupOpen) {
      this.setState({
        popup: {
          ...popup,
          isPopupOpen: false,
        },
        alarmId: null,
      });
    } else {
      this.setState({
        popup: {
          ...popup,
          isPopupOpen: true,
        },
        alarmId,
      });
    }
  };

  onCleanAlarm = async () => {
    const { id, name, deleteAlarms, isUniversal, facility, match } = this.props;
    const { alarmId } = this.state;
    const setIsUniversal = isUniversal || getCookie_groupIsUniversal();
    await deleteAlarms(alarmId, id, facility?.id, setIsUniversal, name, match.params.instalationId);
    await this.onToggleCleanAlarm();
  };

  createNewState = (props) => {
    const { match, creationURI, familiesSingleList } = this.props;
    const isCreateMode = match.params.id === creationURI;
    const productFamily = getFamilyListByType(familiesSingleList, this.familyType);
    return {
      id: isCreateMode ? '' : props.id,
      name: props.name || '',
      description: props.description || '',
      facilityId: isCreateMode ? props.instalationId : props.facilityId,
      facilityFobs: getArrayWithAttributes(
        props.facilityFobs,
        'facilityFobAttributes',
        props.instalationConfigFields
      ).map((fob) => {
        const product = getProduct(productFamily, fob.productId);
        return {
          ...fob,
          fobCode: getECodeTranslated(fob.e_code),
          productName: product ? product.name : '',
        };
      }),
      // facilityFobs: getArrayWithAttributes(props.facilityFobs, 'facilityFobAttributes', props.originalTenants),
      formErrors: [],
      onFlyFormErrors: [],
      isUniversal: props.isUniversal,
    };
  };

  handleBlur = (e) => {
    const { instalationId } = this.props;
    if (this.props[e.target.id] !== e.target.defaultValue) {
      this.executeOnFlyValidations(
        e.target.id,
        apiPathFactory.facilitiesGroupsCheckContent(
          e.target.id,
          e.target.defaultValue,
          instalationId
        )
      );
    } else {
      this.setErrorsCallback({
        onFlyFormErrors: this.state.onFlyFormErrors,
        error: undefined,
        id: e.target.id,
      });
    }
  };

  handleChangeInstalation = (e) => {
    this.props.getSecondaryInstalationGroups(e, this.props.id);
  };

  getProducts = () => {
    const productsArray = [];
    const families = getFamilyListByType(this.props.familiesSingleList, this.familyType);
    if (families) {
      const products = families.flat();
      products.forEach((family, i) => {
        productsArray.push({
          key: family.section,
          id: family.section,
          value: family.section,
          disabled: true,
          className: 'enroll-code-combo-family',
          options: family.items.map((product) => ({
            key: product.productId,
            id: product.productId,
            value: product.name,
          })),
        });
      });
    }
    return productsArray;
  };

  getTypeValueName = (props) => {
    const products = this.getProducts();
    const { productId } = props.dataItem;
    let productName = '';
    products.forEach((family) => {
      family.options.forEach((product) => {
        if (product.id === productId) {
          productName = product.value;
        }
      });
    });
    return productName;
  };

  codesSubstitution = () => {
    const { fobCodeSignalRTrack } = this.state;
    invokeSendFunction(fobCodeSignalRTrack);
  };

  onClickSignalR = (props) => {
    const { t, facility, getInstalationsFOBS, id, setProductId } = this.props;
    const { dataItem } = props;
    const families = getFamilyListByType(this.props.familiesSingleList, this.familyType).flat();
    const products = families.map((family) => family.items).flat();
    const selectedProduct = products.find((product) => product.productId === dataItem.productId);

    if (selectedProduct) {
      invokeSendCICC();
      setProductId(selectedProduct.productIdWrite);
      getInstalationsFOBS(facility.id, id);
      setAssistantPanelCheckSuccessFunction(this.codesSubstitution);
      this.setState({
        fobCodeSignalRTrack: `${selectedProduct.name}::${selectedProduct.productIdWrite}::${selectedProduct.productIdRead}::null::${facility.installerCode}`,
        substitutionSignalRTrack: `${selectedProduct.name}::${selectedProduct.productIdWrite}::${selectedProduct.productIdRead}::${dataItem.e_code}::${facility.installerCode}`,
        isCheckingCodes: true,
        productIdWrite: selectedProduct.productIdWrite,
        functionSended: true,
        codesSubstitutionDone: false,
      });
    } else {
      toast.error(t('apitoasters.errors.selectTypeFirst'));
    }
  };

  newMessageHubFunction = (name, message) => {
    const {
      facilityId,
      id,
      fobsGetCheckCodes,
      codeExists,
      facilityFobs,
      putFob,
      resetCheckedFobs,
      resetSignalRPopup,
    } = this.props;
    const { isCheckingCodes, codesSubstitutionDone } = this.state;
    if (isCheckingCodes && codeExists === undefined && codesSubstitutionDone) {
      fobsGetCheckCodes(facilityId, id, message.split(':')[0], 1);
    }
    if (codeExists === false && isCheckingCodes) {
      const splittedResponse = message.split('::');
      const fobCodes = { newCode: splittedResponse[0], oldCode: splittedResponse[1] };
      const selectedFob = {
        ...facilityFobs.find((fob) => fob.e_code === parseInt(fobCodes.oldCode)),
      };
      selectedFob.e_code = fobCodes.newCode;
      putFob(selectedFob);
      resetCheckedFobs();
      resetSignalRPopup();
      this.setState({
        isCheckingCodes: undefined,
        productIdWrite: undefined,
        needResetCounters: true,
      });
    } else if (codesSubstitutionDone === false) {
      this.codesSubstitution();
      this.setState({ codesSubstitutionDone: true });
    }
  };

  onClickDeleteFob = (props) => {
    this.setState({ isDeleteFobPopupOpen: true, deleteFobItem: props.dataItem });
  };

  handleAcceptDeleteIndividualFob = () => {
    const { deleteFob } = this.props;
    deleteFob(this.state.deleteFobItem);
    this.setState({ isDeleteFobPopupOpen: false, deleteFobItem: {} });
  };

  onKeyDown = (e) => {
    if (e.keyCode === 9) {
      e?.preventDefault();
    }
  };

  getUniversalColumns = () => {
    const { t, universalConfigFields, tenants } = this.props;
    const columns = [
      {
        key: 'alarms',
        name: t('instalationGroups.alarms'),
        width: '85px',
        sortable: true,
        hideOnPDFExport: true,
        cell: (props, childProps) => (
          <td>
            {props?.dataItem?.alarms?.length ? (
              <div className='d-flex justify-content-center'>
                {props?.dataItem?.alarms.map((element, key) => {
                  if (element.alarmCode) {
                    const icon =
                      element.alarmCode === 44801 ? 'fa-skull-crossbones' : 'fa-battery-quarter';
                    return (
                      <IconSpan
                        key={element.id}
                        cursor='pointer'
                        defaultColor='#ff0000'
                        toolTip={t(`code.${element.alarmCode}`)}
                        tenants={tenants}
                        iconClassName={`fas ${icon}`}
                        cursos='pointer'
                        style={{
                          marginLeft: key > 0 && '4px',
                        }}
                        {...childProps}
                      />
                    );
                  }
                })}
              </div>
            ) : (
              ' '
            )}
          </td>
        ),
      },
      {
        key: 'fobCode',
        name: t('form.id'),
        sortable: true,
        filterable: true,
        tooltip: t('form.code'),
      },
      {
        key: 'productName',
        name: t('form.type'),
        sortable: true,
        filterable: true,
        editable: false,
        cell: (props, childProps) => (
          <KendoDropDownCell
            onChangeSave={this.onFobSave}
            layout={this.getTypeValueName(props)}
            datas={this.getProducts()}
            {...props}
            {...childProps}
            field='productId'
          />
        ),
        tooltip: t('form.type'),
      },
      {
        key: 'name',
        name: t('form.name'),
        sortable: true,
        filterable: true,
        editable: false,
        tooltip: t('form.type'),
      },
      {
        key: 'surname',
        name: t('form.surname'),
        sortable: true,
        filterable: true,
        editable: false,
        tooltip: t('form.type'),
      },
      {
        key: 'nic',
        name: t('form.identityCard'),
        sortable: true,
        editable: false,
        filterable: true,
        tooltip: t('form.type'),
      },
      {
        key: 'plaza',
        name: t('instalationGroups.place'),
        sortable: true,
        editable: false,
        filterable: true,
        editor: 'numeric',
        tooltip: t('form.type'),
      },
      ...generateRows(universalConfigFields, 5, false),
    ];
    return columns;
  };

  getColumns = () => {
    const { t, instalationConfigFields, tenants } = this.props;
    const columns = [
      {
        key: '',
        name: '',
        width: '50px',
        hideOnPDFExport: true,
        cell: (props, childProps) => (
          <td className='grid-buttons'>
            <>
              {props.dataItem.productId !== '71bf4341-36b9-4055-8cd8-34d46ee97b63' &&
              props.dataItem.productId !== '0b9c3bf1-34ac-42ba-9190-5464b2858725' &&
              props.dataItem.productId !== '486f7c87-10e4-4043-b90e-7af7c4b990e5' ? (
                <IconSpan
                  cursor='pointer'
                  toolTip={t('fobs.substitution')}
                  tenants={tenants}
                  iconClassName='fas fa-sync-alt icon-cursor'
                  cursos='pointer'
                  onClick={() => this.onClickSignalR(props, childProps)}
                  {...childProps}
                />
              ) : null}
            </>
          </td>
        ),
        tooltip: t('form.type'),
      },
      {
        key: 'alarms',
        name: t('instalationGroups.alarms'),
        width: '85px',
        sortable: true,
        hideOnPDFExport: true,
        cell: (props, childProps) => (
          <td>
            {props?.dataItem?.alarms?.length ? (
              <div className='d-flex justify-content-center'>
                {props?.dataItem?.alarms.map((element) => {
                  if (element.alarmCode) {
                    const icon =
                      element.alarmCode === 44801 ? 'fa-skull-crossbones' : 'fa-battery-quarter';
                    return (
                      <IconSpan
                        cursor='pointer'
                        defaultColor='#ff0000'
                        toolTip={t(`code.${element.alarmCode}`)}
                        tenants={tenants}
                        iconClassName={`fas ${icon}`}
                        cursos='pointer'
                        onClick={() => this.onToggleCleanAlarm(element.id, props)}
                        {...childProps}
                      />
                    );
                  }
                })}
              </div>
            ) : (
              ' '
            )}
          </td>
        ),
      },
      {
        key: 'fobCode',
        name: t('form.code'),
        sortable: true,
        filterable: true,
        tooltip: t('form.code'),
      },
      {
        key: 'productName',
        name: t('form.type'),
        sortable: true,
        filterable: true,
        editable: !this.props.isPropertyManagerLiteRole,
        cell: (props, childProps) => (
          <KendoDropDownCell
            onChangeSave={this.onFobSave}
            layout={this.getTypeValueName(props)}
            datas={this.getProducts()}
            isDisabled={this.props.isPropertyManagerLiteRole}
            {...props}
            {...childProps}
            field='productId'
          />
        ),
        tooltip: t('form.type'),
      },
      {
        key: 'name',
        name: t('form.name'),
        sortable: true,
        filterable: true,
        editable: !this.props.isPropertyManagerLiteRole,
        tooltip: t('form.type'),
      },
      {
        key: 'surname',
        name: t('form.surname'),
        sortable: true,
        filterable: true,
        editable: !this.props.isPropertyManagerLiteRole,
        tooltip: t('form.type'),
      },
      {
        key: 'nic',
        name: t('form.identityCard'),
        sortable: true,
        editable: !this.props.isPropertyManagerLiteRole,
        filterable: true,
        tooltip: t('form.type'),
      },
      {
        key: 'plaza',
        name: t('instalationGroups.place'),
        sortable: true,
        editable: !this.props.isPropertyManagerLiteRole,
        filterable: true,
        editor: 'numeric',
        tooltip: t('form.type'),
      },
      ...generateRows(instalationConfigFields, 5, true),
      {
        key: ' ',
        name: '',
        width: '50px',
        hideOnPDFExport: true,
        displayable: !(
          this.props.isBasicUserRole ||
          this.props.isBasicManagerRole ||
          this.props.isPropertyManagerLiteRole
        ),
        cell: (props, childProps) => (
          <td colSpan='1' className='grid-buttons'>
            <IconSpan
              cursor='pointer'
              toolTip={t('genericGrow.delete')}
              tenants={tenants}
              iconClassName='fas fa-trash'
              onClick={() => this.onClickDeleteFob(props, childProps)}
              {...childProps}
            />
          </td>
        ),
        tooltip: t('form.type'),
      },
    ];
    return columns;
  };

  putMoveFobList = (toFacilityId, toGroupId, fobList) => {
    const { facilityId, id, putMoveFobList, name } = this.props;
    putMoveFobList(
      facilityId,
      toFacilityId,
      id,
      toGroupId,
      createArrayFromObjectParam(fobList, 'id'),
      name
    );
  };

  postCopyFobList = (toFacilityId, toGroupId, fobList) => {
    const { facilityId, id } = this.props;
    this.props.postCopyFobList(
      facilityId,
      toFacilityId,
      id,
      toGroupId,
      createArrayFromObjectParam(fobList, 'id')
    );
  };

  deleteFobList = (e, fobList) => {
    const { facilityId, id } = this.props;
    this.props.deleteFobList(facilityId, id, createArrayFromObjectParam(fobList, 'id'));
  };

  onFobSave = (item) => {
    const { putFob, instalationConfigFields, facilityFobs } = this.props;
    const itemWithAttributes = {
      ...getItemWithAttributtes(
        item,
        'facilityFobAttributes',
        item.facilityFobAttributes,
        instalationConfigFields
      ),
    };

    const selectedFob = {
      ...facilityFobs.find((fob) => fob.e_code === parseInt(item.e_code)),
    };

    if (selectedFob) {
      item.facilityFobAttributes.map((newAttribute) => {
        if (newAttribute.id == '00000000-0000-0000-0000-000000000000') {
          const storeAttributeForTag = selectedFob.facilityFobAttributes.find(
            (z) => z.tag == newAttribute.tag
          );

          if (storeAttributeForTag) {
            newAttribute.id = storeAttributeForTag.id;
          }
        }
      });
    }

    putFob(itemWithAttributes);
  };

  executeOnFlyValidations = (inputId, endPoint) => {
    const { t } = this.props;
    getOnFlyValidations(endPoint, (isError, response) => {
      this.setErrorsCallback({
        onFlyFormErrors: this.state.onFlyFormErrors,
        error: isError,
        id: inputId,
        errorMessage: isError ? t(`errors.${response?.error?.code}`) : '',
      });
    });
  };

  setErrorsCallback = ({ onFlyFormErrors, error, id, errorMessage }) => {
    this.setState({
      onFlyFormErrors: [...setOnFlyErrors({ onFlyFormErrors, error, id, errorMessage })],
    });
  };

  putImportFobs = (e) => {
    const { id, facilityId, importFobs, resetInstalationFOBS } = this.props;
    if (e.target.files.length > 0) {
      importFobs(facilityId, id, e.target.files[0]);
      resetInstalationFOBS();
    }
  };

  getExportExcelColumns = () => {
    const { t, instalationConfigFields } = this.props;
    return [
      {
        field: 'id',
        title: 'Id',
        locked: true,
        cellOptions: { background: '#ccc', wrap: true },
        headerCellOptions: { textAlign: 'center', bold: true },
      },
      {
        field: 'fobCode',
        title: t('form.code'),
        locked: true,
        cellOptions: { background: '#ccc', wrap: true },
        headerCellOptions: { textAlign: 'center', bold: true },
      },
      {
        field: 'productName',
        title: t('form.type'),
        locked: true,
        cellOptions: { background: '#ccc', wrap: true },
        headerCellOptions: { textAlign: 'center', bold: true },
      },
      {
        field: 'name',
        title: t('form.name'),
        width: 150,
        cellOptions: { wrap: true },
        headerCellOptions: { textAlign: 'center', bold: true },
      },
      {
        field: 'surname',
        title: t('form.surname'),
        width: 150,
        cellOptions: { wrap: true },
        headerCellOptions: { textAlign: 'center', bold: true },
      },
      {
        field: 'nic',
        title: t('form.identityCard'),
        width: 150,
        cellOptions: { wrap: true },
        headerCellOptions: { textAlign: 'center', bold: true },
      },
      {
        field: 'plaza',
        title: t('instalationGroups.place'),
        width: 150,
        cellOptions: { wrap: true },
        headerCellOptions: { textAlign: 'center', bold: true },
      },
      ...generateRows(instalationConfigFields, 5, true).map((configField) => ({
        field: configField.key,
        title: configField.name,
        width: 150,
        cellOptions: { wrap: true },
        headerCellOptions: { textAlign: 'center', bold: true },
      })),
    ];
  };

  getFields = (newFields) => {
    const { t, baseURI, match } = this.props;
    const fields = [
      [
        {
          type: 'name',
          placeHolder: `${t('form.name')} *`,
          value: 'name',
          id: 'name',
          onBlur: this.handleBlur,
          validation: [
            () => validationsDictionary.isRequired(newFields.name),
            () => validationsDictionary.isInTextLength(newFields.name, 255),
          ],
        },
        {
          type: 'description',
          placeHolder: t('instalationGroups.description'),
          value: 'description',
          id: 'description',
          validation: [() => validationsDictionary.isInTextLength(newFields.description, 255)],
        },
      ],
    ];
    return fields;
  };

  popupDeleteChildren = () => {
    const { t } = this.props;
    const { deleteFobItem } = this.state;
    const families = getFamilyListByType(this.props.familiesSingleList, this.familyType).flat();
    const products = families.map((family) => family.items).flat();
    const selectedProduct = products.find(
      (product) => product.productId === deleteFobItem.productId
    );
    return [
      {
        key: t('form.code'),
        value:
          deleteFobItem.fobCode === t('enrollCode.reserved')
            ? deleteFobItem.fobCode
            : deleteFobItem.e_code,
      },
      {
        key: t('form.type'),
        value: selectedProduct ? selectedProduct.name : '',
      },
      {
        key: t('form.name'),
        value: deleteFobItem.name,
      },
      {
        key: t('form.surname'),
        value: deleteFobItem.surname,
      },
      {
        key: t('form.identityCard'),
        value: deleteFobItem.nic,
      },
      {
        key: t('instalationGroups.place'),
        value: deleteFobItem.plaza,
      },
    ];
  };

  parseConfigFieldsToGrid = (fob) => {
    const responseObject = {};
    if (fob.facilityFobAttributes) {
      fob.facilityFobAttributes.forEach(
        (attribute) => (responseObject[attribute.tag] = attribute.value)
      );
    }
    return responseObject;
  };

  getFobsCounts = () => {
    const { fobsCount } = this.props;
    if (fobsCount && fobsCount.length) {
      const countersObject = fobsCount.find((counter) => counter.schema === 'Fobs Availability');
      return {
        ...countersObject.counters[0],
      };
    }
    return {
      free: 0,
      busy: 0,
      reserved: 0,
    };
  };

  handleCancel = () => {
    const { history, baseURI, resetEquipmentScroll } = this.props;
    resetEquipmentScroll();
    history.push(baseURI.replace('%09', '%2509'));
  };

  render() {
    const {
      isEnrollIconVisible,
      isBasicUserRole,
      isBasicManagerRole,
      isPropertyManagerRole,
      isPropertyManagerLiteRole,
      match,
      facilityFobs,
      t,
      universalGroupFobs,
      postInstalationGroup,
      setUniversalGroup,
      createUniversalGroup,
      name,
      baseURI,
      putInstalationGroup,
      creationURI,
      successUrlRedirect,
      isUniversal,
      needCodesCount,
      facilityId,
      facilityGroup,
      id,
    } = this.props;
    const { formErrors, onFlyFormErrors } = this.state;
    const productFamily = getFamilyListByType(this.props.familiesSingleList, 1);
    const realCount = this.getFobsCounts();
    return (
      <div>
        <Popup isOpen={this.state.popup.isPopupOpen} onCloseIconClick={this.state.popup.onCancelFn}>
          <PopUpConfirm
            {...this.state.popup}
            text={t('alarms.deleteAlarmPopUp')}
            tenants={this.props.tenants}
          />
        </Popup>

        {this.props.match.params.id !== creationURI ? (
          <div className='fobsGrid'>
            {!needCodesCount ? (
              ''
            ) : (
              <CodesCounters
                availableValue={realCount.free}
                availablePlaceholder={t('codesCount.available')}
                reservationValue={realCount.reserved}
                reservationPlaceholder={t('enrollCode.notAsigned')}
                usedValue={realCount.busy}
                usedPlaceholder={t('codesCount.used')}
              />
            )}
            <GenericKendoGrid
              firstValues={
                universalGroupFobs
                  ? universalGroupFobs.map((fob) => {
                      const product = getProduct(productFamily, fob.productId);
                      return {
                        ...fob,
                        ...this.parseConfigFieldsToGrid(fob),
                        fobCode: getECodeTranslated(fob.e_code),
                        productName: product ? product.name : '',
                      };
                    })
                  : facilityFobs
                  ? facilityFobs.map((fob) => {
                      const product = getProduct(productFamily, fob.productId);
                      return {
                        ...fob,
                        ...this.parseConfigFieldsToGrid(fob),
                        fobCode: getECodeTranslated(fob.e_code),
                        productName: product ? product.name : '',
                      };
                    })
                  : []
              }
              value={
                universalGroupFobs
                  ? universalGroupFobs.map((fob) => {
                      const product = getProduct(productFamily, fob.productId);
                      return {
                        ...fob,
                        ...this.parseConfigFieldsToGrid(fob),
                        fobCode: getECodeTranslated(fob.e_code),
                        productName: product ? product.name : '',
                      };
                    })
                  : facilityFobs
                  ? facilityFobs.map((fob) => {
                      const product = getProduct(productFamily, fob.productId);
                      return {
                        ...fob,
                        ...this.parseConfigFieldsToGrid(fob),
                        fobCode: getECodeTranslated(fob.e_code),
                        productName: product ? product.name : '',
                      };
                    })
                  : []
              }
              // firstSortedColumn={'fobCode'}
              needMultiselection={
                !isBasicUserRole &&
                !isBasicManagerRole &&
                !isPropertyManagerLiteRole &&
                !getCookie_groupIsUniversal()
              }
              needUpdateView
              needExport={
                !(
                  (isBasicUserRole ||
                    isBasicManagerRole ||
                    isPropertyManagerRole ||
                    isPropertyManagerLiteRole) &&
                  getCookie_groupIsUniversal()
                )
              }
              notShow={this.props.match.params.id === creationURI}
              getColumns={getCookie_groupIsUniversal() ? this.getUniversalColumns : this.getColumns}
              excelExportColumns={this.getExportExcelColumns()}
              excelName={`${moment().format('YYYY-MM-DD')}_${replaceStringValue(
                match.params.instalationId.substring(0, 50),
                'Facility'
              )}_${replaceStringValue(name, 'Group')}`}
              pdfName={`${moment().format('YYYY-MM-DD')}_${replaceStringValue(
                match.params.instalationId.substring(0, 50),
                'Facility'
              )}_${replaceStringValue(name, 'Group')}`}
              importExcel={this.putImportFobs}
              gridCallbacks={{
                deleteItemsCallback: this.deleteFobList,
                moveItemsCallback: this.putMoveFobList,
                copyItemsCallback: this.postCopyFobList,
                onCellBlurSave: this.onFobSave,
              }}
              gridDatas={{
                isUniversal: false,
                moveToPrincipalList: this.props.instalationsSingleList,
                firstCallAction: 'instalationSingleList',
                idPrincipalCall: this.props.instalationId,
                getSecondaryList: this.handleChangeInstalation,
                moveToSecondaryList: this.props.facilityGroups,
              }}
              showItemsSelected
            />
            <span className='instalation-group-config instalation-group-config-icons-container'>
              {this.props.isIconVisible && isEnrollIconVisible ? (
                <>
                  {!isBasicUserRole && !isPropertyManagerLiteRole && (
                    <>
                      <IconTooltip
                        toolTipContent={t('instalationGroups.enrollFOB')}
                        sizeIcon='display36'
                        color='grey'
                        onClick={() => {
                          const decoded = generatePath(`/Facilities/:instalationId/groups/:id`, {
                            instalationId: match.params.instalationId,
                            id: match.params.id,
                          });
                          this.props.history.push(`${decoded}/Enroll`);
                        }}
                      >
                        <IconPlus />
                      </IconTooltip>
                    </>
                  )}
                </>
              ) : null}
            </span>
            <Flex
              id={
                isBasicUserRole ||
                isBasicManagerRole ||
                isPropertyManagerRole ||
                isPropertyManagerLiteRole
                  ? 'end-button-padded'
                  : 'end-button'
              }
              justifyContent='flex-end'
              paddingY={1}
            >
              <Button
                id='cancel'
                backgroundColor='blackLight'
                sizeButton='small'
                onClick={() => this.handleCancel()}
              >
                {t('general.back')}
              </Button>
            </Flex>
          </div>
        ) : (
          <IndividualGrowGenericForm
            id={this.props.match.params.id !== creationURI ? 'test' : ''}
            idDiv={this.props.match.params.id !== creationURI ? 'instalations-groups' : ''}
            item={this.createNewState(this.props)}
            updateItem={
              isUniversal || getCookie_groupIsUniversal() ? setUniversalGroup : putInstalationGroup
            }
            goToIdRedirect={`${facilityGroup?.id}/Configuration`}
            createItem={isUniversal ? createUniversalGroup : postInstalationGroup}
            successUrlRedirect={successUrlRedirect}
            baseURI={baseURI}
            creationURI={creationURI}
            fields={(newFields) => this.getFields(newFields)}
            onFlyformErrors={[...(formErrors || []), ...(onFlyFormErrors || [])]}
            submitText={t('general.save')}
            needHelper
          />
        )}

        <DeletePopup
          title={t('instalationGroups.deleteFobConfirmation')}
          isOpen={this.state.isDeleteFobPopupOpen}
          handleAccept={this.handleAcceptDeleteIndividualFob}
          handleCancel={() => this.setState({ isDeleteFobPopupOpen: false, deleteFobItem: {} })}
        >
          <PropsList propsArray={this.popupDeleteChildren()} />
        </DeletePopup>

        <PopUpErrorMessage
          isOpen={!!(this.props.showSignalRPopup || this.props.errorPutFobsMessage !== undefined)}
          onClose={() => this.setState({ isErrorMessagePopupOpen: false })}
          productId={this.state.productIdWrite ? this.state.productIdWrite : ''}
          needTagMessage={this.props.productId === 8}
        />
        <PdfPopup
          isOpen={this.state.popupOpenPDF}
          items={this.props.instalationConfigFields}
          facilityId={facilityId}
          groupId={id}
          name={`${moment().format('YYYY-MM-DD')}_${replaceStringValue(
            match.params.instalationId.substring(0, 50),
            'Facility'
          )}_${replaceStringValue(name, 'Group')}`}
          onClose={() => this.setState({ popupOpenPDF: false })}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  tenants: state.tenants,
  originalTenants: state.tenants.originalTenants,
  instalationsSingleList: state.instalations.instalationsSingleList,
  facilityGroups: state.instalationGroups.secondaryFacilityGroups,
  facilityGroup: state.instalationGroups.facilityGroup,
  familiesSingleList: state.families.familiesSingleList,
  fobsImported: state.fobs.fobsImported,
  redirectURL: state.genericGrow.redirectURL,
  facilityFobs: state.instalations.facilityFobs,
  universalGroupFobs: state.universalGroupsFobs.universalGroupFobs,
  instalationConfigFields: state.instalations.instalationConfigFields,
  universalConfigFields: state.universalGroupsFobs.universalConfigFields,
  codeExists: state.fobs.codeExists,
  errorMessage: state.fobs.errorMessage,
  showSignalRPopup: state.signalR.showSignalRPopup,
  message: state.signalR.message,
  errorPutFobsMessage: state.instalations.errorPutFobsMessage,
  productId: state.signalR.productId,
  fobsCount: state.instalationGroups.fobsCount,
  isSuccess: state.fobs.isSuccess,
  isFobSuccess: state.fobs.isFobSuccess,
});

const individualInstalationGroupFormWithTranslation = withTranslation()(
  IndividualInstalationGroupForm
);
const individualInstalationGroupFormWithRouter = withRouter(
  individualInstalationGroupFormWithTranslation
);

export default connect(mapStateToProps, {
  getSecondaryInstalationGroups,
  putInstalationGroup,
  postInstalationGroup,
  getInstalationsSingleList,
  putMoveFobList,
  postCopyFobList,
  deleteFobList,
  deleteFob,
  resetInstalationFOBS,
  resetAllFobLists,
  putFob,
  getFamiliesSingleList,
  importFobs,
  setManualGrowRedirectURL,
  resetManualGrowRedirectURL,
  setUniversalGroup,
  createUniversalGroup,
  putUniversalGroupsFob,
  getInstalationsFOBS,
  getFamilyListByType,
  needReturnToFacilities,
  deleteAlarms,
  fobsGetCheckCodes,
  resetCheckedFobs,
  setNeedPopup,
  showPopUp,
  resetSignalRPopup,
  setProductId,
  resetEquipmentScroll,
  getInstalationGroupsFobsCounts,
  getInstalationGroups,
})(individualInstalationGroupFormWithRouter);
