export default {
  enrollCode: {
    manualTab: 'Manuale',
    reservationsTab: 'Riserva',
    sequentialTab: 'Sequenziale',
    universalsTab: 'Universale',
    device: 'Dispositivo',
    initialCode: 'Codice iniziale',
    idCard: 'DNI',
    spot: 'Fessura',
    numberOfFobs: 'Numero di portatessere',
    endCode: 'Codice fine',
    cantEnroll:
      'Per registrare nuovi codici è necessario scegliere un dispositivo e sono necessarie posizioni disponibili',
    cancel: 'Annullare',
    accept: 'Accettare',
    ok: 'OK',
    forceCodeQuestion: 'Vuoi forzare la registrazione di questo codice?',
    codeAlreadyExist: 'Questo codice esiste già in un altro gruppo',
    notAsigned: 'Non assegnato',
    reserved: 'Riservato',
  },
};
