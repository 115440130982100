import styled from 'styled-components';
import { getContrastColor } from '../../core/helpers/colorManager';

export default styled.input`
  border: none;
  padding: 8px 32px;
  color: ${(props) => getContrastColor(props.tenants.color1)};
  background-color: ${(props) => props.tenants.color1};
  border-radius: 2em;
  display: flex;
  cursor: pointer;
`;
