export default {
  formValidation: {
    notSameValue: 'Ist nicht der gleiche Wert',
    notSamePassword: 'Passwörter stimmen nicht überein',
    numericField: 'Dieses Feld ist numerisch',
    minNumber: 'Mindestwert ist 1',
    passwordFormat:
      'Das Passwort muss aus mindestens acht Zeichen, mindestens einem Großbuchstaben, einem Kleinbuchstaben, einer Zahl und einem Sonderzeichen bestehen',
    isNotValidDate: 'Falsches Datumsformat, Format: JJJJ-MM-TTTHH: mm: ss',
    isRequired: 'Pflichtfeld',
    outOfRange: 'Nummer außerhalb des Bereichs',
    maxLength: 'Die maximale Zeichenlänge beträgt',
    regEx: 'Falsches Format. Verwenden Sie diese Zeichen',
    deviceLimit: 'Gerätelimit erreicht',
    onlySpaces: 'Nur Leerzeichen sind nicht erlaubt',
    isReservedWord: 'ist ein zurückhaltendes Wort',
  },
};
