import 'babel-polyfill';
import * as Sentry from '@sentry/react';
import { applyMiddleware, createStore } from 'redux';
import createSagaMiddleware, { END } from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from '../reducers';
import rootSaga from '../sagas';

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options listed below
});

const saga = createSagaMiddleware();

const middleware = [saga];

const createStoreWithMiddleware = composeWithDevTools(
  applyMiddleware(...middleware),
  sentryReduxEnhancer
)(createStore);

const configureStore = () => {
  const store = createStoreWithMiddleware(rootReducer, {});
  store.runSaga = saga.run;
  store.close = () => store.dispatch(END);
  return store;
};
const store = configureStore();
store.runSaga(rootSaga);

export default store;
