export default {
  parameters: {
    title: 'Nastavení parametrů Proxy Access 5K',
    pinCode: 'PIN kód',
    apn: 'APN',
    apnUser: 'Uživatel APN',
    apnPassword: 'Heslo APN',
    serverURL: 'URL server',
    editproxy: 'Upravit Acces 5K Proxy',
    titleRelays: 'Programování relé',
    errorRelaysPopUp: 'Chyba: nemůžete překročit maximálné počet relé',
    CONFIGRELAYSNEW1_ICON: 'Upravit relé 1',
    CONFIGRELAYSNEW2_ICON: 'Upravit relé 2',
    CONFIGRELAYSNEW3_ICON: 'Upravit relé 3',
    CONFIGRELAYSNEW4_ICON: 'Upravit relé 4',
    ACTIVEEVENTSTOSD_ICON: 'Upravit aktivní události',
    A5KPROXY_ICON: 'Upravit ACCESS 5K Proxy',
  },
};
