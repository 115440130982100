export const baseUnits = [
  { value: 'sum_r1', label: 'r1Activations' },
  { value: 'sum_r2', label: 'r2Activations' },
  { value: 'sum_fOk', label: 'fobsOk' },
  { value: 'sum_clon', label: 'suspiciusEmitters' },
  { value: 'sum_fBt', label: 'BLEactivations' },
  { value: 'sum_fKo', label: 'fobsKO' },
  { value: 'sum_rx', label: 'dataReceived' },
  { value: 'sum_tx', label: 'dataTransmitted' },
  { value: 'sumfBatt', label: 'emittersWithBatteryLow' },
  { value: 'sum_op', label: 'openedEvents' },
  { value: 'sum_cl', label: 'closedEvents' },
];
