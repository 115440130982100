import { Flex } from '@jcm-technologies/uikit/dist/atoms/Layout';
import { Modal } from '@jcm-technologies/uikit/dist/molecules/Modal';
import { Button } from '@jcm-technologies/uikit/dist/atoms/Button';
import { Text } from '@jcm-technologies/uikit/dist/atoms/Typography';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getContrastColor } from '../../../../../core/helpers/colorManager';
import PropTypes from 'prop-types';

const DeleteActionModal = ({removeAll, isOpenedDeletePopUp, setIsOpenedDeletePopUp})=>{
  const { t } = useTranslation();
  const { color1 } = useSelector((state) => state.tenants);
  return (
    <Modal
      maxWidth='320px'
      width='100%'
      isOpen={isOpenedDeletePopUp}
      id='delete-holidays'
      onModalClose={() => setIsOpenedDeletePopUp(false)}
    >
      <Modal.Content width='100%'>
        <Text sizeText='display16' textAlign='center'>
          {t('holidays.answerDeleteHolidays')}
        </Text>
      </Modal.Content>
      <Modal.Actions>
        <Flex alignItems='center' justifyContent='flex-end'>
          <Button
            id='acceptDeleteHolidays'
            sizeButton='small'
            backgroundColor={color1}
            action={() => {
              removeAll();
              setIsOpenedDeletePopUp(false);
            }}
            marginRight={2}
            color={getContrastColor(color1)}
          >
            {t('general.delete')}
          </Button>
          <Button backgroundColor='blackLight' id='cancel-button' sizeButton='small'>
            {t('general.cancel')}
          </Button>
        </Flex>
      </Modal.Actions>
    </Modal>
  )
}

export default DeleteActionModal;

DeleteActionModal.propTypes = {
  setIsOpenedDeletePopUp: PropTypes.func.isRequired,
  removeAll: PropTypes.func.isRequired,
  isOpenedDeletePopUp: PropTypes.bool.isRequired,
};
