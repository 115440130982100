import i18next from 'i18next';
import moment from 'moment/moment';
import { replaceStringValue } from '../../../../core/old_common/utils/replaceStringService';

export const actionGroup = ({
  setIsOpenModalDeleteMultiMifare,
  setIsOpenModalMoveMultiMifare,
  setIsOpenModalCopyMultiMifare,
}) => [
  {
    action: () => {
      setIsOpenModalDeleteMultiMifare(true);
    },
    children: i18next.t('general.delete'),
    id: 'delete-mifare',
  },
  {
    action: () => {
      setIsOpenModalMoveMultiMifare(true);
    },
    children: i18next.t('general.move'),
    id: 'move-mifare',
  },
  {
    action: () => {
      setIsOpenModalCopyMultiMifare(true);
    },
    children: i18next.t('general.copy'),
    id: 'copy-mifare',
  },
];

export const fileExcelName = ({ idFacility, idGroup }) =>
  `${moment().format('YYYY-MM-DD')}_${replaceStringValue(
    idFacility?.substring(0, 50),
    'Facility'
  )}_${replaceStringValue(idGroup, 'Group')}`;

export const PRODUCT_FAMILY_TYPE = 4;

export const CREATE_TAB_DEFAULT = 'manual';

export const CUSTOM_ATTRIBUTES_KEY = 'facilityFobMifareAttributes';
