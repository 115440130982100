import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import IndividualInstalationGroupForm from '../../old_components/IndividualInstalationGroupForm/IndividualInstalationGroupForm';
import IndividualInstalationInformationForm from '../../old_components/IndividualInstalationInformationForm/IndividualInstalationInformationForm';

import {
  getInstalationGroup,
  getInstalationGroups,
  getInstalationGroupsFobsCounts,
  resetInstalationGroup,
  resetInstalationGroups,
} from '../../modules/old_to_refact/actions/instalationGroups';
import { resetManualGrowRedirectURL } from '../../modules/old_to_refact/actions/genericGrow';
import { getFacility, getInstalationsFOBS } from '../../modules/old_to_refact/actions/instalations';
import { getFamiliesSingleList } from '../../modules/productFamilies/action';
import { resetIsSuccess } from '../../modules/group/fobs/actions';
import {
  CreationPath_Instalation_Group,
  RootPath_Instalations,
} from '../../core/old_common/constats/genericListPaths';
import GenericIndividualGrow from '../../old_components/GenericIndividualGrow/GenericIndividualGrow';
import InstalationGroupGrow from '../../old_components/InstalationGroupGrow/InstalationGroupGrow';
import { getDecodedUrl } from '../../core/old_common/utils/getUrlParams';

import './IndividualInstalationGroup.css';
import {
  createSignalRConnectionOnReducer,
  resetSignalRConnectionOnReducer,
} from '../../core/old_common/utils/signalRService';
import { getFamilyListByType } from '../../core/old_common/utils/familiesService';
import { getUniversalGroupsFobs } from '../../modules/old_to_refact/actions/universalGroupsFobs';
import {
  getCookie_groupIsUniversal,
  getCookieUserRoles,
  saveCookies,
} from '../../core/old_common/utils/cookiesManager';
import { getUniversalGroup } from '../../modules/old_to_refact/actions/universalGroups';
import FacilityGroupUserList from '../../views/Facility/Group/User/List';
import FacilityGroupMifareList from '../../views/Facility/Group/Mifare/List';

class IndividualInstalationGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFamiliyCalled: false,
      isFOBSCalled: false,
      isFacilityCalled: false,
      countFobs: 0,
    };
  }

  componentDidMount() {
    this.initFacilitiesParameters();
    createSignalRConnectionOnReducer();
  }

  initFacilitiesParameters = () => {
    const { familiesSingleList, getFamiliesSingleList, match, getFacility, needCallFamilies } =
      this.props;
    const facility = this.getSelectedInstalation();
    const selectedItem = this.getSelectedItem();
    if (
      selectedItem &&
      selectedItem.isUniversal !== undefined &&
      selectedItem.isUniversal !== getCookie_groupIsUniversal()
    ) {
      saveCookies({ groupIsUniversal: selectedItem.isUniversal });
    }
    if (
      (!facility || match.params.instalationId !== facility?.id) &&
      match.params.instalationId &&
      !this.state.isFacilityCalled
    ) {
      this.setState({ isFacilityCalled: true });
      getFacility(getDecodedUrl(match.params.instalationId));
    }
    this.getInstalationFOBS();
    const familyType =
      (selectedItem && selectedItem.isUniversal) || getCookie_groupIsUniversal() ? 2 : 1;
    if (
      !getFamilyListByType(familiesSingleList, familyType).length &&
      match.params.id !== CreationPath_Instalation_Group &&
      !this.state.isFamiliyCalled &&
      needCallFamilies
    ) {
      this.setState({ isFamiliyCalled: true });
      getFamiliesSingleList(familyType, true);
    }
  };

  componentWillUnmount() {
    if (!this.props.match.path.includes('mifare')) {
      resetSignalRConnectionOnReducer();
      this.props.resetInstalationGroups();
      this.props.resetInstalationGroup();
      this.props.resetManualGrowRedirectURL();
    }
  }

  getInstalationFOBS = () => {
    const {
      getInstalationsFOBS,
      facilityFobs,
      resetIsSuccess,
      isDeleteSuccess,
      isSuccess,
      match,
      fobsImported,
      getUniversalGroupsFobs,
      universalGroupFobs,
      needCallFobs,
      needCallUGFobs,
      isFobsCalled,
      getInstalationGroupsFobsCounts,
    } = this.props;
    const selectedItem = this.getSelectedItem();
    const facility = this.getSelectedInstalation();
    const isUniversalCondition =
      selectedItem &&
      selectedItem.isUniversal &&
      (!universalGroupFobs || !universalGroupFobs.length);
    const notUniversalCondition =
      facility && selectedItem && !selectedItem.isUniversal && !facilityFobs;
    if (
      ((selectedItem && (isUniversalCondition || notUniversalCondition) && !isFobsCalled) ||
        isDeleteSuccess ||
        isSuccess ||
        fobsImported) &&
      selectedItem
    ) {
      if (
        (selectedItem.isUniversal || getCookie_groupIsUniversal()) &&
        !universalGroupFobs &&
        needCallUGFobs
      ) {
        getUniversalGroupsFobs(selectedItem.id);
      } else if (needCallFobs && !facilityFobs && !isFobsCalled && !selectedItem.isUniversal) {
        if (facility && selectedItem) {
          getInstalationsFOBS(facility?.id, selectedItem.id || selectedItem?.groupId);
          if (!this.props.history.location.pathname.includes('Configuration')) {
            getInstalationGroupsFobsCounts(facility.id, selectedItem.id || selectedItem?.groupId);
          }
        }
      }
      resetIsSuccess();
    } else if (!selectedItem) {
      this.updateInstalationsList(getDecodedUrl(match.params.id));
    }
  };

  updateInstalationsList = (instalationGroupName) => {
    const { match, getInstalationGroup, needCallInstalationGroups, getFacility } = this.props;
    const facility = this.getSelectedInstalation();
    if (
      facility &&
      match.params.id !== CreationPath_Instalation_Group &&
      needCallInstalationGroups
    ) {
      getInstalationGroup(facility.id, instalationGroupName);
    }
  };

  componentDidUpdate = (prevProps) => {
    this.initFacilitiesParameters();
  };

  getSelectedItem = () => {
    const {
      match,
      facilityGroup,
      facilityGroups,
      groups,
      universalGroupFobs,
      facilityFobs,
      universalGroup,
    } = this.props;
    let selectedItem;

    if (facilityGroup) {
      selectedItem = facilityGroup;
    } else if (facilityGroups) {
      selectedItem = facilityGroups.find(
        (facilityGroup) => facilityGroup.id === getDecodedUrl(match.params.id)
      );
    } else if (groups) {
      selectedItem = groups.find(
        (facilityGroup) =>
          facilityGroup.id === getDecodedUrl(match.params.id) &&
          facilityGroup.description != undefined
      );
    }
    if (getCookie_groupIsUniversal() && universalGroup && universalGroup.id === match.params.id) {
      selectedItem = universalGroup;
    }

    if (selectedItem) {
      const selectedFacilityFobs =
        (selectedItem && selectedItem.isUniversal) || getCookie_groupIsUniversal()
          ? universalGroupFobs
          : facilityFobs;
      selectedItem.facilityFobs = selectedFacilityFobs;
    }
    return selectedItem;
  };

  getSelectedInstalation = () => {
    const { match, instalations, facility } = this.props;
    getFacility(match.params.instalationId, false);
    if (facility) {
      return facility;
    }
    if (instalations) {
      return instalations.find(
        (instalation) => instalation.id === getDecodedUrl(match.params.instalationId)
      );
    }
    return undefined;
  };

  generateTabs = () => {
    const { t, match, active, history, facilityGroup } = this.props;
    const baseURI = `${RootPath_Instalations}/${match.params.instalationId}/groups`;
    const selectedInstalation = this.getSelectedInstalation();
    const selectedItem = this.getSelectedItem();
    const isIconVisible =
      match.params.id !== CreationPath_Instalation_Group ||
      !getCookie_groupIsUniversal() ||
      selectedItem;
    const role = getCookieUserRoles();
    const roleMask = role ? role.mask : '';
    const isEnrollIconVisible =
      match.params.id !== CreationPath_Instalation_Group &&
      ((facilityGroup && !facilityGroup.isUniversal) ||
        !getCookie_groupIsUniversal() ||
        (selectedItem && selectedItem.isUniversal !== undefined && !selectedItem.isUniversal));
    const isBasicUserRole = roleMask === 16;
    const isBasicManagerRole = roleMask === 32;
    const isPropertyManagerRole = roleMask === 128;
    const isPropertyManagerLiteRole = roleMask === 256;
    const tabMifare = selectedItem?.mifareTab
      ? {
          title: t('title.mifare'),
          id: 'mifare',
          bodyComponent: FacilityGroupMifareList,
          onClick: () => {
            history.push(`${`${baseURI}/${encodeURIComponent(selectedItem.id)}/wiegand`}`);
          },
          active: active === 'mifare',
        }
      : false;
    const tabFobs = selectedItem?.fobsTab
      ? {
          title: t('instalationUserGroups.Fobs'),
          id: 'fobs',
          bodyComponent: IndividualInstalationGroupForm,
          onClick: () => {
            history.push(
              `${baseURI.replace(/\#/g, '%23').replace(/\&/g, '%26')}/${encodeURIComponent(
                selectedItem.id
              )}`
            );
          },
          componentState: {
            baseURI: `${baseURI.replace(/\#/g, '%23').replace(/\&/g, '%26')}`,
            instalationId: selectedInstalation ? selectedInstalation.id : '',
            facility: selectedInstalation,
            isUniversal: !!selectedItem?.isUniversal,
            instalationsGroupId: facilityGroup ? facilityGroup.id : '',
            isIconVisible,
            isEnrollIconVisible,
            isBasicUserRole,
            isBasicManagerRole,
            isPropertyManagerRole,
            isPropertyManagerLiteRole,
            fobs: selectedItem ? selectedItem.facilityFobs : [],
            fobAttributes:
              selectedItem && selectedItem.facilityFobs
                ? selectedItem.facilityFobs.map((x) => x.facilityFobAttributes).flat()
                : [],
            creationURI: CreationPath_Instalation_Group,
            needCodesCount: true,
          },
          active: !active,
        }
      : false;
    const tabUsers = selectedItem?.userGroupsTab
      ? {
          title: t('title.users'),
          id: 'users',
          bodyComponent: FacilityGroupUserList,
          active: active === 'users',
          onClick: () => {
            history.push(`${baseURI}/${encodeURIComponent(selectedItem.id)}/users`);
          },
        }
      : false;
    const tabs = [];
    if (tabFobs) {
      tabs.push(tabFobs);
    }
    if (tabMifare) {
      tabs.push(tabMifare);
    }
    if (tabUsers) {
      tabs.push(tabUsers);
    }
    tabs.push({
      title: t('instalationUserGroups.information'),
      id: 'info',
      bodyComponent: IndividualInstalationInformationForm,
      active: active === 'info',
      onClick: () =>
        history.push(
          `${baseURI.replace(/\#/g, '%23').replace(/\&/g, '%26')}/${encodeURIComponent(
            selectedItem.id
          )}/Information`
        ),
      componentState: {
        baseURI: `${baseURI.replace(/\#/g, '%23').replace(/\&/g, '%26')}`,
        instalationId: selectedItem ? selectedItem.id : '',
        facility: selectedInstalation,
        isUniversal: !!selectedItem?.isUniversal,
        instalationsGroupId: facilityGroup ? facilityGroup.id : '',
        isIconVisible,
        isBasicUserRole,
        isBasicManagerRole,
        isPropertyManagerRole,
        isPropertyManagerLiteRole,
        isEnrollIconVisible,
        creationURI: CreationPath_Instalation_Group,
        fobs: selectedItem ? selectedItem.facilityFobs : [],
        fobAttributes:
          selectedItem && selectedItem.facilityFobs
            ? selectedItem.facilityFobs.map((x) => x.facilityFobAttributes).flat()
            : [],
        successUrlRedirect: false,
      },
    });
    return tabs;
  };

  render() {
    const { t, match, tenants, facilityGroup } = this.props;
    const baseURI = `${RootPath_Instalations}/${match.params.instalationId}/groups`;
    const selectedInstalation = this.getSelectedInstalation();
    const selectedItem = this.getSelectedItem();
    const isUniversal = getCookie_groupIsUniversal();
    const isIconVisible =
      match.params.id !== CreationPath_Instalation_Group ||
      !getCookie_groupIsUniversal() ||
      selectedItem;
    const role = getCookieUserRoles();
    const roleMask = role ? role.mask : '';
    const isEnrollIconVisible =
      match.params.id !== CreationPath_Instalation_Group &&
      ((facilityGroup && !facilityGroup.isUniversal) ||
        !getCookie_groupIsUniversal() ||
        (selectedItem && selectedItem.isUniversal !== undefined && !selectedItem.isUniversal));
    const isBasicUserRole = roleMask === 16;
    const isBasicManagerRole = roleMask === 32;
    const isPropertyManagerRole = roleMask === 128;
    const isPropertyManagerLiteRole = roleMask === 256;

    return isUniversal ? (
      <GenericIndividualGrow
        Grow={InstalationGroupGrow}
        baseURI={baseURI}
        creationURI={CreationPath_Instalation_Group}
        selectedItem={selectedItem}
        getItem={this.updateInstalationsList}
        childGeneralProps={{
          instalationId: selectedInstalation ? selectedInstalation.id : '',
          facility: selectedInstalation,
          isUniversal: !!selectedItem?.isUniversal,
          instalationsGroupId: facilityGroup ? facilityGroup.id : '',
          isIconVisible,
          isEnrollIconVisible,
          isBasicUserRole,
          isBasicManagerRole,
          isPropertyManagerRole,
          isPropertyManagerLiteRole,
          fobs: selectedItem ? selectedItem.facilityFobs : [],
          fobAttributes:
            selectedItem && selectedItem.facilityFobs
              ? selectedItem.facilityFobs.map((x) => x.facilityFobAttributes).flat()
              : [],
        }}
      >
        <IndividualInstalationGroupForm
          baseURI={baseURI}
          creationURI={CreationPath_Instalation_Group}
          tenants={tenants}
          facility={selectedInstalation}
          needCodesCount={false}
        />
      </GenericIndividualGrow>
    ) : (
      <GenericIndividualGrow
        Grow={InstalationGroupGrow}
        baseURI={baseURI}
        creationURI={CreationPath_Instalation_Group}
        selectedItem={selectedItem}
        getItem={this.updateInstalationsList}
        tabsData={this.generateTabs()}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  facilityGroups: state.instalationGroups.facilityGroups,
  needCallInstalationGroups: state.instalationGroups.needCallInstalationGroups,
  facility: state.instalations.facility,
  facilityGroup: state.instalationGroups.facilityGroup,
  instalations: state.instalations.instalations,
  facilityFobs: state.instalations.facilityFobs,
  universalGroupFobs: state.universalGroupsFobs.universalGroupFobs,
  isDeleteSuccess: state.fobs.isDeleteSuccess,
  isSuccess: state.fobs.isSuccess,
  tenants: state.tenants,
  familiesSingleList: state.families.familiesSingleList,
  fobsImported: state.fobs.fobsImported,
  groups: state.groups.groups,
  needCallFobs: state.instalations.needCallFobs,
  needCallUGFobs: state.universalGroupsFobs.needCallUGFobs,
  needCallFamilies: state.families.needCallFamilies,
  isFobsCalled: state.fobs.isFobsCalled,
  fobsCount: state.instalationGroups.fobsCount,
});
const IndividualInstalationGroupWithTranslation = withTranslation()(IndividualInstalationGroup);
const IndividualInstalationGroupWithRouter = withRouter(IndividualInstalationGroupWithTranslation);

export default connect(mapStateToProps, {
  getFacility,
  getInstalationGroup,
  getInstalationsFOBS,
  getFamiliesSingleList,
  getInstalationGroups,
  resetIsSuccess,
  resetManualGrowRedirectURL,
  getUniversalGroupsFobs,
  getUniversalGroup,
  getInstalationGroupsFobsCounts,
  resetInstalationGroups,
  resetInstalationGroup,
})(IndividualInstalationGroupWithRouter);
