export default {
  descriptionParams: {
    ACCESSCONTROL: 'Es zeigt an, ob das Gerät über einen Eingang für Zugangskontrollgeräte verfügt',
    ACTIVEEVENTSTOSD: 'Es gibt die aktiven Ereignisse für Ihr Gerät an',
    APNCONFIG: 'Es konfiguriert den APN des Geräts',
    BADGE_MAITRE1: 'Seriennummer der Karte, die als Hauptschlüssel verwendet wird',
    BADGE_MAITRE10: 'Seriennummer der Karte,, der als Hauptschlüssel verwendet wird',
    BADGE_MAITRE2: 'Seriennummer der Karte, der als Hauptschlüssel verwendet wird',
    BADGE_MAITRE3: 'Seriennummer der Karte, der als Hauptschlüssel verwendet wird',
    BADGE_MAITRE4: 'Seriennummer der Karte, der als Hauptschlüssel verwendet wird',
    BADGE_MAITRE5: 'Seriennummer der Karte, der als Hauptschlüssel verwendet wird',
    BADGE_MAITRE6: 'Seriennummer der Karte, der als Hauptschlüssel verwendet wird',
    BADGE_MAITRE7: 'Seriennummer der Karte, der als Hauptschlüssel verwendet wird',
    BADGE_MAITRE8: 'Seriennummer der Karte, der als Hauptschlüssel verwendet wird',
    BADGE_MAITRE9: 'Seriennummer der Karte, der als Hauptschlüssel verwendet wird',
    CONFIGRELAYSNEW1:
      'Es zeigt die Relais an, die entsprechend dem vom Gerät gesendeten Kanal aktiviert werden',
    CONFIGRELAYSNEW2:
      'Es zeigt die Relais an, die entsprechend dem vom Gerät gesendeten Kanal aktiviert werden',
    CONFIGRELAYSNEW3:
      'Es zeigt die Relais an, die entsprechend dem vom Gerät gesendeten Kanal aktiviert werden',
    CONFIGRELAYSNEW4:
      'Es zeigt die Relais an, die entsprechend dem vom Gerät gesendeten Kanal aktiviert werden',
    CONFIGRELAYSPIN1:
      'Es zeigt die Relais an, die entsprechend dem vom Gerät gesendeten Kanal aktiviert werden',
    CONFIGRELAYSPIN2:
      'Es zeigt die Relais an, die entsprechend dem vom Gerät gesendeten Kanal aktiviert werden',
    CONFIGRELAYSPIN3:
      'Es zeigt die Relais an, die entsprechend dem vom Gerät gesendeten Kanal aktiviert werden',
    CONFIGRELAYSPIN4:
      'Es zeigt die Relais an, die entsprechend dem vom Gerät gesendeten Kanal aktiviert werden',
    DISABLEMANUALPROG: 'Sie können Geräte manuell registrieren',
    DISABLERADIOPROG:
      'Es verhindert die Registrierung von Sendern im Funkprogrammiermodus (Standardmodus)',
    FREEACTIVE: 'Es aktiviert / deaktiviert die Eigenschaften der FREE-Systemgruppe',
    FREEGROUP0LOCKED: 'Es sperrt / entsperrt Gruppe 0 des FREE Systems',
    FREEGROUPNUM: 'Hiermit wird die Gruppennummer des FREE Systems festgelegt',
    FREEGROUPRFPROGALLOWED:
      'Es aktiviert / deaktiviert den Funkprogrammiermodus für die FREE Systemgruppe',
    IMPBIRELE1: 'Es ändert den Betrieb von Relais 1 von Impuls auf EIN / AUS',
    INSTAL: 'Es wird angezeigt, ob Sie mit Installationscode arbeiten',
    MINIDDEVICE: 'Das Gerät akzeptiert Geräte wie die angegebenen oder höher',
    PASSWORD: '4-stelliges Passwort für den Zugriff auf das zentrale Menü',
    PIN: '6-stelliger numerischer Code entsprechend dem PIN-Code des Empfängers',
    PINCADUCAT:
      'Es wird angezeigt, ob der Empfänger Geräte mit dem abgelaufenen PIN-Code akzeptiert',
    POWER: 'Es zeigt die Art der Stromversorgung der Geräte',
    RELAYPRICE1: 'Vom Guthaben eines Prepaid-Geräts abzuziehender Wert 1',
    RELAYPRICE2: 'Wert, der vom Guthaben eines Prepaid-Geräts abgezogen werden soll 2',
    RELAYPRICE3: 'Vom Guthaben eines Prepaid-Geräts abzuziehender Wert 3',
    RELAYPRICE4: 'Vom Guthaben eines Prepaid-Geräts abzuziehender Wert 4',
    RELAYS: 'Anzahl der Relais im Empfänger',
    RELE1IMP: 'Es konfiguriert das Relais als Impuls, EIN / AUS oder Panik',
    RELE1TEMP:
      'Hiermit wird die Zeit eingestellt, zu der das Relais im Impulsmodus (1 bis 30 Sekunden) oder in Panik (1 bis 15 Minuten) aktiv bleibt.',
    RELE2IMP: 'Es setzt das Relais als Impuls, EIN / AUS oder Panik',
    RELE2TEMP:
      'Hiermit wird die Zeit eingestellt, zu der das Relais im Impulsmodus (1 bis 30 Sekunden) oder in Panik (1 bis 15 Minuten) aktiv bleibt.',
    RELE3IMP: 'Es setzt das Relais als Impuls, EIN / AUS oder Panik',
    RELE3TEMP:
      'Hiermit wird die Zeit eingestellt, zu der das Relais im Impulsmodus (1 bis 30 Sekunden) oder in Panik (1 bis 15 Minuten) aktiv bleibt.',
    RELE4IMP: 'Es setzt das Relais als Impuls, EIN / AUS oder Panik',
    RELE4TEMP:
      'Hiermit wird die Zeit eingestellt, zu der das Relais im Impulsmodus (1 bis 30 Sekunden) oder in Panik (1 bis 15 Minuten) aktiv bleibt.',
    SERIALNUMBER: 'Die Seriennummer ist für Remote-Aktionen obligatorisch',
    SITECODE:
      'Standortcode / Einrichtungscode / Installationscode, der im Wiegand 26-Protokoll mit Standortcode gesendet werden soll',
    TEVENT: 'Es zeigt an, ob das Bedienfeld eine Ereigniskarte haben kann',
    TIMEZONEID: 'Hiermit wird die Zeitzone festgelegt, in der das Gerät installiert ist',
    TMEM: 'Es zeigt an, ob das Gerät mit einer Speicherkarte funktioniert',
    UNIQUEKEY: 'Ein einziger Schlüssel ist für Fernaktionen',
    W26FORMAT: 'Hiermit wird das Übertragungsformat des Wiegand 26-Protokolls festgelegt',
    SETNEWACCESSPW: 'Ändere das Passwort',
    SERIALNUMBERLINK: 'Die Seriennummer ist für Remote-Aktionen obligatorisch',
    CONNECTIVITY_EXTENSION: 'Linkkarte installiert',
    ACTIVATIONKEY: 'Aktivierungsschlüssel',
    EMMITTERNOTUSEDEVENTTIME: 'Verwenden Sie nicht die Ereigniszeit des Senders',
    DOOROPENEDEVENTTIME: 'Ereignis der Türöffnung',
    SCHEDULESDATA: 'Plant Daten',
    A5KPROXY: 'Greifen Sie auf 5k Proxy zu',
    WIEGANDCONFIG: 'Personalisieren Sie die zu verwendende Wiegand-Konfiguration',
  },
};
