import PathEntity from './PathEntity';
import { getCookie_pageNumbers, getCookiePageSizeItems } from '../../utils/cookiesManager';
import { apiVersion, mainPath } from '../common';

class Facilities extends PathEntity {
  constructor() {
    super('facilities');
  }

  post = () => this.manufacturePath(this.entityName);

  clone = (facilityId) => this.manufacturePath(`${this.entityName}/${facilityId}/clone`);

  get = (id, include, initialEndpoint, customerId) => {
    let finalEndpoint = initialEndpoint || this.manufacturePath(`${this.entityName}/${id}`);
    finalEndpoint = this.addParameterToEndpoint(finalEndpoint, 'include', include);
    if (customerId) {
      finalEndpoint = this.addParameterToEndpoint(finalEndpoint, 'CustomerId', customerId);
    }
    return finalEndpoint;
  };

  getPDF = ({ customerIDs, queryText, city, zip, typeFilter, userId }) => {
    let endpoint = this.manufacturePath(`${this.entityName}/pdf`);
    if (queryText) endpoint = this.addParameterToEndpoint(endpoint, 'queryText', queryText);
    if (city) endpoint = this.addParameterToEndpoint(endpoint, 'city', city);
    if (zip) endpoint = this.addParameterToEndpoint(endpoint, 'zip', zip);
    if (customerIDs) endpoint = this.addParameterToEndpoint(endpoint, 'CustomerIds', customerIDs);
    if (typeFilter) endpoint = this.addParameterToEndpoint(endpoint, 'Type', typeFilter);
    if (userId) endpoint = this.addParameterToEndpoint(endpoint, 'UserId', userId);
    return endpoint;
  };

  getWithInclude = (id, include) =>
    this.addParameterToEndpoint(
      this.manufacturePath(`${this.entityName}/${id}`),
      'include',
      include
    );

  put = (id) => this.manufacturePath(`${this.entityName}/${id}`);

  putGeolocation = ({ facilityId }) =>
    this.manufacturePath(`${this.entityName}/${facilityId}/geolocation`);

  delete = (id) => this.manufacturePath(`${this.entityName}/${id}`);

  getSingleList = (id, exceptMe) =>
    this.manufacturePath(
      `${this.entityName}/${id}/singlelist${exceptMe !== undefined ? `?exceptme=${exceptMe}` : ''}`
    );

  facilityGroupCheckContent = (columnName, value, id) => {
    let finalEndpoint = this.manufacturePath(`${this.entityName}/${id}/groups/checkcontent`);
    finalEndpoint = this.addParameterToEndpoint(finalEndpoint, 'columnName', columnName);
    finalEndpoint = this.addParameterToEndpoint(finalEndpoint, 'value', value);
    finalEndpoint = this.addParameterToEndpoint(finalEndpoint, 'ForFacility', true);
    return finalEndpoint;
  };

  checkContent = (columnName, value, id) => {
    let finalEndpoint = this.manufacturePath(`${this.entityName}/checkcontent`);
    finalEndpoint = this.addParameterToEndpoint(finalEndpoint, 'columnName', columnName);
    finalEndpoint = this.addParameterToEndpoint(finalEndpoint, 'value', value);
    return finalEndpoint;
  };

  getEquipmentUserGroups = (facilityId, equipmentId) =>
    this.manufacturePath(`${this.entityName}/${facilityId}/equipments/${equipmentId}/groups`);

  getEquipmentParameters = (facilityId, equipmentId) =>
    this.manufacturePath(
      `${this.entityName}/${facilityId}/equipments/${equipmentId}/configuration`
    );

  getCities = (selectedDescendants) => {
    let finalEndpoint = this.manufacturePath(`facilities/cities`);
    finalEndpoint = this.getEndPointWithVisibleDescendants(
      finalEndpoint,
      selectedDescendants,
      'customerIds'
    );
    return finalEndpoint;
  };

  getPostalCodes = (selectedDescendants) => {
    let finalEndpoint = this.manufacturePath(`facilities/postalcodes`);
    finalEndpoint = this.getEndPointWithVisibleDescendants(
      finalEndpoint,
      selectedDescendants,
      'customerIds'
    );
    return finalEndpoint;
  };

  getExportusers = (groupsIds, includeReservations, includeConfigFields) => {
    let finalEndpoint = this.manufacturePath(`${this.entityName}/exportusers`);
    finalEndpoint = this.addParametersFromArray(finalEndpoint, 'GroupsIds', groupsIds);
    finalEndpoint = this.addParameterToEndpoint(
      finalEndpoint,
      'includeReservations',
      includeReservations
    );
    finalEndpoint = this.addParameterToEndpoint(
      finalEndpoint,
      'includeConfigFields',
      includeConfigFields
    );

    return finalEndpoint;
  };

  getField = (fieldId, value) => {
    const pageSizeItems = getCookiePageSizeItems() || 15;
    const pageNumber = getCookie_pageNumbers()?.FACILITY_LIST;
    const endpoint = this.manufacturePath(
      `facilities/field/${fieldId}?${
        value ? `queryText=${value}&` : ''
      }pagesize=${pageSizeItems}&pagenumber=${pageNumber}`
    );
    return endpoint;
  };

  getFacilitiesLogsByUser = (userId) => {
    const pageSizeItems = getCookiePageSizeItems() || 10;
    const pageNumber = getCookie_pageNumbers()?.FACILITY_LIST;
    this.mainPath = `${mainPath()}4.1`;
    const endpoint = this.manufacturePath(
      `facilities/userLogs/${userId}?pagesize=${pageSizeItems}&pagenumber=${pageNumber}`
    );
    this.mainPath = `${mainPath()}${apiVersion}`;
    return endpoint;
  };

  transferFacility = (facilityId, newCustomerId) =>
    this.manufacturePath(`${this.entityName}/${facilityId}/propertymanager/${newCustomerId}`);

  recycleChecker = () => this.manufacturePath(`${this.entityName}/deletedCheck`);

  recycleList = () => {
    const pageSize = getCookiePageSizeItems() || 15;
    const pageNumber = getCookie_pageNumbers()?.RECYCLE_LIST;
    let finalEndpoint = this.manufacturePath(`${this.entityName}/deletedList`);
    finalEndpoint = this.pagedEndPoint(finalEndpoint, pageSize, pageNumber);
    return finalEndpoint;
  };

  recycleRestoreItem = () => this.manufacturePath(`${this.entityName}/restore`);

  GetPdf = (facilityId) => this.manufacturePath(`${this.entityName}/${facilityId}/pdf`);

  getLogs = (facilityId) =>
    this.manufacturePath(`${this.entityName}/${facilityId}/logs?include=true`);

  getEvents = (facilityId) => {
    this.mainPath = `${mainPath()}4.1`;
    const endpoint = this.manufacturePath(`${this.entityName}/${facilityId}/facilityEvents`);
    this.mainPath = `${mainPath()}${apiVersion}`;
    return endpoint;
  };

  getPaths = () => ({
    facilitiesGetPage: this.getPaged,
    facilitiesGetCheckContent: this.checkContent,
    facilitiesGetWithInclude: this.getWithInclude,
    facilitiesGetHTML: this.getPDF,
    facilitiesPost: this.post,
    facilitiesPostClone: this.clone,
    facilitiesDelete: this.delete,
    facilitiesGet: this.get,
    facilitiesPut: this.put,
    facilitiesPutGeolocation: this.putGeolocation,
    facilitiesGetSingleList: this.getSingleList,
    facilitiesGroupsCheckContent: this.facilityGroupCheckContent,
    facilitiesGetEquipmentUserGroups: this.getEquipmentUserGroups,
    facilitiesGetEquipmentParameters: this.getEquipmentParameters,
    facilitiesGetExportUsers: this.getExportusers,
    facilitiesGetCities: this.getCities,
    facilitiesGetPostalCodes: this.getPostalCodes,
    facilitiesField: this.getField,
    facilitiesTransfer: this.transferFacility,
    facilitiesRecycleChecker: this.recycleChecker,
    facilitiesRecycleList: this.recycleList,
    facilitiesRecycleRestore: this.recycleRestoreItem,
    facilityGetPdf: this.GetPdf,
    facilityGetLogs: this.getLogs,
    facilityGetLogsByUser: this.getFacilitiesLogsByUser,
    facilityGetEvents: this.getEvents,
  });
}

export default new Facilities().getPaths();
