export default {
  formValidation: {
    notSameValue: 'Není stejné hodnoty',
    notSamePassword: 'Heslo nesouhlasí',
    numericField: 'Toto pole je numerické',
    minNumber: 'Minimální hodnota je 1',
    passwordFormat:
      'Heslo musí obsahovat minimálně 8 znaků, nejméně 1 velké písmeno, 1 číslo a 1 speciální symbol',
    isNotValidDate: 'Špatný formát času, formát musí být: rrrr-mm-dd-hh:mm:ss',
    isRequired: 'Toto pole je vyžadované',
    outOfRange: 'Počet mimo rozsah',
    maxLength: 'Maximální délka znaků je',
    regEx: 'Nesprávný formát. Použijte tyto znaky',
    deviceLimit: 'Dosažen limit zařízení',
    onlySpaces: 'Povolená jsou pouze prázdná pole',
    isReservedWord: 'je to vyhrazené slovo',
  },
};
