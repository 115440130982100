import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Flex } from '@jcm-technologies/uikit/dist/atoms/Layout';
import { useHistory, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';
import {
  AuthenticatedTemplate,
  MsalAuthenticationTemplate,
  UnauthenticatedTemplate,
  useAccount,
  useIsAuthenticated,
  useMsal,
} from '@azure/msal-react';
import {
  InteractionRequiredAuthError,
  InteractionStatus,
  InteractionType,
} from '@azure/msal-browser';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { Loading } from '@jcm-technologies/uikit/dist/atoms/Loading';
import ErrorMessage from '../../old_components/ErrorMessage';
import i18n, { initTranslations } from '../../core/i18n';
import {
  deleteCookieAll,
  getCookieCustomerId,
  getCookieEmail,
  getCookieShowVersionModal,
  getRefreshCheckToken,
  saveCookies,
} from '../../core/old_common/utils/cookiesManager';
import UnauthorizedView from '../../views/Unauthorized/View';
import { resetErrors } from '../../modules/old_to_refact/actions/errors';
import { getTenants } from '../../modules/tenants/actions';
import { resetAuthPopUp } from '../../modules/old_to_refact/actions/popUp';
import { toastError } from '../../core/services/toasters';
import { showAlert } from '../../modules/state/alerts';
import './Layout.css';
import GlobalStyle from '../../old_components/Layout/GlobalStyle';
import { createSignalRConnectionOnReducer } from '../../core/old_common/utils/signalRServiceFront';
import { appInsights, reactPlugin } from '../../core/old_common/utils/appInsights';

import { resetTimeouts, sendReLogin } from '../../modules/old_to_refact/actions/login';
import { checkFacilityRecycle } from '../../modules/old_to_refact/actions/instalations';
import { loginRequest } from '../../core/config/authConfig';
import { getUser } from '../../modules/user/actions';
import { getNotifications } from '../../modules/notifications/actions';
import { SidebarNav } from './Sidebar';
import { Main } from './Main';
import ErrorBoundaryView from '../../views/ErrorBoundary/View';
import { isSentryEnvLoaded } from '../../old_containers/Root';
import { stringToJson } from '../../core/old_common/utils/objectsService';

const Index = ({ children, pageViewTitle }) => {
  const history = useHistory();
  const { tab } = useParams();
  const dispatch = useDispatch();
  const isAuthenticated = useIsAuthenticated();
  const { t } = useTranslation();

  const { instance, accounts, inProgress } = useMsal();
  const [atsResponse, setAtsResponse] = useState(null);
  const [lastTracked, setLastTracked] = useState(undefined);
  const { percent, show } = useSelector((state) => state.loading);
  const tenants = useSelector((state) => state.tenants);
  const popup = useSelector((state) => state.popup);
  const { errorStatus, infoError, listError, isError, toastNotShow } = useSelector(
    (state) => state.errors
  );
  const { isSuccess, successMessage } = useSelector((state) => state.successToaster);
  const { userId, roles, language, newFeatures } = useSelector((state) => state.login);
  const {
    user: { email, language: userLang },
  } = useSelector((state) => state.user);
  const account = useAccount(accounts[0] || {});
  const { equipmentConnection } = useSelector((state) => state.equipments);
  const refreshTokenCheck = getRefreshCheckToken();
  const getUserDataAPI = useCallback(
    (token) => {
      dispatch(sendReLogin(token));
    },
    [dispatch]
  );
  const getTenantsAPI = useCallback(() => dispatch(getTenants()), [dispatch]);
  const userRoles = roles;
  const isRedirectPassword =
    history?.location?.hash.includes('access_denied') &&
    history?.location?.hash.includes('AADB2C90118');
  const setTrackPageView = () => {
    const title = history.location.state?.isCreating ? `${pageViewTitle} Create` : pageViewTitle;
    if (lastTracked !== title) {
      if (tab && pageViewTitle) {
        appInsights.trackPageView({ name: `${title}/${tab}` });
      } else if (history.location.state?.isCreating) {
        appInsights.trackPageView({ name: `${title}` });
      } else {
        appInsights.trackPageView({ name: title });
      }
      setLastTracked(title);
    }
  };

  //TODO: errorMessage and the itemsInfo is the same in 90102 error, pending refact of errors (+ info Jira 3761)
  const manageToastWithDescription = (errorMessage) => {
    let itemsInfo;
    if (errorStatus !== '90102') {
      if (errorStatus === '91527') {
        itemsInfo = t('apitoasters.errors.universalGroupsAssigned');
      } else {
        itemsInfo = t('apitoasters.errors.groupsAssigned');
      }
    } else {
      // itemsInfo = t(`apitoasters.errors.${errorStatus}`); This causes the repeat of the error on the toast
      itemsInfo = '';
    }
    const values = infoError && JSON.parse(infoError);
    values?.map((item) => {
      itemsInfo = `${itemsInfo} ${item},`;
      return itemsInfo;
    });
    toastError(`${errorMessage} ${itemsInfo}`);
  };

  const manageToast = () => {
    if (isError && !toastNotShow) {
      const list = listError && (stringToJson(listError) || listError);
      const listCodes = list?.listCodes || list;
      if (errorStatus) {
        if (errorStatus === '169' && equipmentConnection === undefined) {
          dispatch(showAlert('orange', 1, t('apitoasters.errors.redirectWarning')));
        } else if (errorStatus === '91201' || errorStatus === '91071' || errorStatus === '91201') {
          dispatch(showAlert('orange', 1, t(`apitoasters.errors.${errorStatus}`)));
        } else if (errorStatus !== '2146233088') {
          const translateCode = `apitoasters.errors.${errorStatus}`;
          const errorMessage = i18n.exists(translateCode)
            ? `${t(translateCode)}`
            : t('apitoasters.errors.unexpectedError');
          if (errorStatus === '91527' || errorStatus === '91528' || errorStatus === '90102') {
            manageToastWithDescription(errorMessage);
          } else if (errorStatus === '92030' || errorStatus === '92031') {
            toastError(errorMessage, t(`errors.title.honoa`));
          } else {
            dispatch(
              showAlert(
                'red',
                1,
                `${errorMessage}${listCodes ? ': ' : ''}${
                  listCodes
                    ? Array.isArray(listCodes)
                      ? listCodes?.map((el) => ` ${el}`)
                      : listCodes
                    : ''
                }`
              )
            );
          }
          if (errorStatus === '171') {
            const element = document.getElementById('unlock');
            if (element) {
              // TODO: Hacer esto en el elemento/comoponente que toque
              document.getElementById('unlock').classList.remove('fa-lock-open');
              document.getElementById('unlock').classList.add('fa-lock');
            }
          }
        }
        dispatch(resetErrors());
      }
    }
    if (isSuccess) {
      const translateCode = `apitoasters.success.${successMessage}`;
      const sucMessage = i18n.exists(translateCode) ? `${t(translateCode)}` : successMessage;
      dispatch(showAlert('green', 1, sucMessage));
    }
  };

  const handleLogout = () => {
    deleteCookieAll();
    Sentry.setUser(null);
    dispatch(resetTimeouts());
    instance.logoutRedirect();
  };

  const handleIfSessionExpired = () => {
    if (popup.isOpenAuth && !popup.onAcceptFn) {
      handleLogout();
    }
  };

  useEffect(async () => {
    const isRedirectCancelResetPassword =
      history?.location?.hash.includes('access_denied') &&
      history?.location?.hash.includes('AADB2C90091');
    if (isRedirectPassword) {
      history.replace('/reset-password');
    } else if (isRedirectCancelResetPassword && !isAuthenticated) {
      history.replace('/login');
    } else if (isRedirectCancelResetPassword && isAuthenticated) {
      history.replace('/user-config/preferences');
    } else {
      setTrackPageView();
    }
  }, [history, inProgress, isAuthenticated]);

  useEffect(() => {
    if (!isRedirectPassword) {
      if (
        (!atsResponse && account && inProgress === InteractionStatus.None) ||
        refreshTokenCheck === 'true'
      ) {
        const request = {
          ...loginRequest,
          // if user isn't loggin and is editing profile, for exemple, use te 1 position
          account:
            account?.idTokenClaims?.acr === 'b2c_1a_signup_signin_jcm' ? account : accounts[1],
          loginHint: getCookieEmail() || '',
        };
        instance
          .acquireTokenSilent(request)
          .then((response) => {
            setAtsResponse(response);
          })
          .catch((e) => {
            Sentry.captureException(e);
            if (e?.error === 'invalid_grant') {
              history.push('/login');
            }
            if (e instanceof InteractionRequiredAuthError) {
              instance.acquireTokenRedirect(request);
            }
          });
      }
    }

    if (atsResponse) {
      const authTokenReceived = atsResponse?.accessToken;
      const expiresOnReceived = atsResponse?.expiresOn;
      sessionStorage.setItem('access_token', authTokenReceived);
      sessionStorage.setItem('expires_token', expiresOnReceived);
      sessionStorage.setItem('refresh_check', false);

      if (!userId) {
        getUserDataAPI(authTokenReceived);
      }
    }
  }, [account, inProgress, instance, atsResponse, isRedirectPassword, userId, refreshTokenCheck]);

  useEffect(() => {
    if (userLang || language) {
      i18n.changeLanguage(userLang || language);
      initTranslations(userLang || language);
    }
  }, [userLang, language]);

  useEffect(() => {
    if (!isRedirectPassword) {
      if (userRoles && isAuthenticated) {
        const roleMask = userRoles ? userRoles.mask : '';
        const isPropertyManagerRole = roleMask === 128;
        const isPropertyManagerLiteRole = roleMask === 256;
        const isBasicUserRole = roleMask === 16;
        const isBasicManagerRole = roleMask === 32;
        if (
          !isPropertyManagerRole &&
          !isBasicUserRole &&
          !isBasicManagerRole &&
          !isPropertyManagerLiteRole
        ) {
          dispatch(checkFacilityRecycle());
          dispatch(getNotifications());
        }
        if (history.location.pathname === '/') {
          // CLAIM: This if, newFeatures is for Backend Flag to redirect user to main or beta
          if (newFeatures) {
            sessionStorage.clear();
            deleteCookieAll();
            window.location.replace(
              `${process?.env?.REACT_APP_LOGIN_NEW_FEATURES}${
                userRoles && userRoles.name === 'Owner' ? '/owner' : '/dashboard'
              }`
            );
          } else if (userRoles && userRoles.name === 'Owner') {
            history.replace('/owner');
          } else {
            history.replace('/dashboard');
          }
        }

        if (!tenants?.color1 || getCookieCustomerId()) {
          getTenantsAPI();
        }

        if (getCookieShowVersionModal() === undefined) {
          saveCookies({ showVersionModal: true });
        }

        createSignalRConnectionOnReducer();
      }
    }
  }, [userRoles, isAuthenticated, isRedirectPassword]);

  useEffect(() => {
    if (
      errorStatus === '169' &&
      (equipmentConnection === undefined || equipmentConnection.connected !== 'OffLine')
    ) {
      history.push('/dashboard');
    }
    if (errorStatus === '91852' || errorStatus === '-2146233079') {
      history.push('/unauthorized');
    }
    // Error 173 when a non-beta user tries to access the beta environment.
    if (errorStatus === '173') {
      if (window.location.origin.includes('-beta')) {
        //We need to clear the session storage and cookies in order to prevent that a user login with a beta account can log in without being redirected to production after a non beta user tried to login to beta.
        sessionStorage.clear();
        deleteCookieAll();
        window.location.href =
          window.location.origin.replace('-beta', '') +
          window.location.pathname +
          window.location.search;
      }
    }
  }, [errorStatus]);

  useEffect(() => {
    manageToast();
    handleIfSessionExpired();
  }, [isError, toastNotShow, equipmentConnection, errorStatus, isSuccess, popup]);

  useEffect(() => {
    loginRequest.loginHint = getCookieEmail();
    return () => dispatch(resetAuthPopUp());
  }, []);

  useEffect(() => {
    if (userId && !email) dispatch(getUser());
    if (userId && email && isSentryEnvLoaded) {
      Sentry.setUser({ email, id: userId, username: email });
    }
  }, [userId]);
  const rootPath = history?.location?.pathname === '/';
  const unauthorizedPath = history?.location?.pathname === '/unauthorized';

  return (
    <Sentry.ErrorBoundary fallback={() => <ErrorBoundaryView />}>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={loginRequest}
        errorComponent={({ error }) => {
          if (error.message.includes('userNotInvited')) {
            return <UnauthorizedView />;
          }
          if (error.message.includes('invitationExpired')) {
            return <UnauthorizedView message={t('error.invitationExpired')} />;
          }
          return <UnauthorizedView message={error?.message} />;
        }}
        loadingComponent={() => <Loading show themeColor={tenants?.color1} />}
      >
        <AuthenticatedTemplate>
          <Flex width='100%'>
            <GlobalStyle tenants={tenants} />
            {!rootPath && !unauthorizedPath ? (
              <>
                <SidebarNav />
                <Main>{children}</Main>
              </>
            ) : (
              ''
            )}
            {!rootPath && unauthorizedPath && children}
            <Loading percent={percent} show={show} themeColor={tenants?.color1} />
          </Flex>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <ErrorMessage message='You are not signed in! Please sign in.' />
        </UnauthenticatedTemplate>
      </MsalAuthenticationTemplate>
    </Sentry.ErrorBoundary>
  );
};

const Layout = withAITracking(reactPlugin, Index);

export default Layout;

Index.propTypes = {
  children: PropTypes.element,
  pageViewTitle: PropTypes.string,
};

Index.defaultProps = {
  pageViewTitle: '',
  children: <></>,
};
