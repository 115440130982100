import moment from "moment";

const formatNumber = (value) => {
  const num = `${value}`.padStart(2, '0');
  return num;
};
const formatDate = (date) => {
  const formatedDate = date
    ? `${formatNumber(date.getFullYear())}-${formatNumber(date.getMonth() + 1)}-${formatNumber(
        date.getDate()
      )}`
    : '';
  return formatedDate;
};
const formatTime = (date) =>
  date
    ? `${formatNumber(date.getHours())}:${formatNumber(date.getMinutes())}:${formatNumber(
        date.getSeconds()
      )}`
    : '';

const getFormatDateTime = (date) => {
  const formatDateTime = date ? `${formatDate(date)}T${formatTime(date)}` : '';
  return formatDateTime;
};

const convertDateTimeForBackend = (date) => {
  return moment(date).format("YYYY-MM-DD[T]HH:mm:ss");
}

export { getFormatDateTime, convertDateTimeForBackend };