import PathEntity from '../../core/old_common/rooting/apiPaths/PathEntity';

class Routes extends PathEntity {
  constructor() {
    super('search');
  }

  get = () => this.manufacturePath(`${this.entityName}`);

  getPaths = () => ({
    globalSearchGet: this.get,
  });
}

export default new Routes().getPaths();
