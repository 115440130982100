export const SCHEDULE_DESCENDANT = 'SCHEDULE_DESCENDANT';
export const FACILITY_DESCENDANT = 'FACILITY_DESCENDANT';
export const UG_DESCENDANT = 'UG_DESCENDANT';
export const GROUP_DESCENDANT = 'GROUP_DESCENDANT';
export const EQUIPMENT_DESCENDANT = 'GROUP_DESCENDANT';
export const SET_SELECTED_DESCENDANTS = 'SET_SELECTED_DESCENDANTS';
export const SET_SELECTED_CITIES = 'SET_SELECTED_CITIES';
export const SET_SELECTED_POSTALCODES = 'SET_SELECTED_POSTALCODES';
export const SET_SEARCH_QUERY = 'SET_SEARCH_QUERY';

export const getDescendantTypesDefaultState = () => {
  const state = {
    SCHEDULE_DESCENDANT: [],
    UG_DESCENDANT: [],
    FACILITY_DESCENDANT: [],
    GROUP_DESCENDANT: [],
    EQUIPMENT_DESCENDANT: [],
  };
  return state;
};
