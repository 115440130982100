import React, { Component } from 'react';
import $ from 'jquery';
import InstallMaps from '../../core/old_common/utils/InstallMaps';
import './custom.css';
import '../InstalationMapPupUpContent/custom.css';
import { exportComponentToPdf } from '../../core/helpers/getImagesBase64';
import moment from 'moment-timezone';

export class InstalationGroupMapPopUpContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      groups: '',
    };
  }

  componentDidMount = () => {
    this.setMap();
  };

  componentDidUpdate = () => {
    this.expandMap();
  };

  setMap = () => {
    const { mapInfo, facilityGroupId } = this.props;
    if (mapInfo) {
      this.setState({
        groups: InstallMaps.outputGroup(mapInfo),
      });
    }
    this.expandMap();
  };

  expandMap = () => {
    $('.toggle-branch').on('click', function () {
      const link = $(this);
      link
        .parent()
        .siblings('.branch')
        .toggle('linear', function () {
          if ($(this).is(':visible')) {
            link.children('i').removeClass('fa-plus').addClass('fa-minus');
          } else {
            link.children('i').removeClass('fa-minus').addClass('fa-plus');
          }
        });
    });
  };

  render() {
    const { mapInfo, onCloseForm } = this.props;
    return (
      <div className='modal-content'>
        <div className='modal-header'>
          <h4 className='modal-title' id='group-tree-title'>
            {mapInfo ? mapInfo.name : ''}
          </h4>
          <span>
            <span
              className='fas fa-file-pdf export-pdf-map'
              data-dismiss='modal'
              aria-label='Export to PDF'
              onClick={() =>
                exportComponentToPdf(
                  'facilityGroupMap',
                  mapInfo ? `${moment().format('YYYY-MM-DD')}_${mapInfo.name}` : undefined
                )
              }
            />
            <button
              type='button'
              className='close'
              data-dismiss='modal'
              aria-label='Close'
              onClick={onCloseForm}
            >
              <span aria-hidden='true'>&times;</span>
            </button>
          </span>
        </div>

        <div className='modal-body'>
          <div className='testFlex' id='facilityGroupMap'>
            <fieldset className='groups-col'>
              <div
                className='tree-container'
                dangerouslySetInnerHTML={{ __html: this.state.groups }}
                id='group-tree'
              />
            </fieldset>
          </div>
        </div>
      </div>
    );
  }
}

export default InstalationGroupMapPopUpContent;
