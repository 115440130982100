import PathEntity from '../../core/old_common/rooting/apiPaths/PathEntity';
import { apiVersion, mainPath } from '../../core/old_common/rooting/common';

class Routes extends PathEntity {
  constructor() {
    super('invitations');
  }

  postResendInvitations = (id) => this.manufacturePath(`${this.entityName}/resend/${id}`);

  postCreate = () => {
    this.mainPath = `${mainPath()}4.1`;
    const endpoint = this.manufacturePath(this.entityName);
    this.mainPath = `${mainPath()}${apiVersion}`;
    return endpoint;
  };

  deleteInvitation = (id) => {
    this.mainPath = `${mainPath()}4.1`;
    const endpoint = this.manufacturePath(`${this.entityName}/${id}`);
    this.mainPath = `${mainPath()}${apiVersion}`;
    return endpoint;
  };

  postReSendInvitationLicenceUser = (facilityId, groupId, userId) =>
    this.manufacturePath(
      `${this.entityName}/${facilityId}/groups/${groupId}/user/${userId}/resend`
    );

  getPaths = () => ({
    invitationsPost: this.postCreate,
    invitationsDelete: this.deleteInvitation,
    postResendInvitationLicence: this.postReSendInvitationLicenceUser,
    postResendInvitations: this.postResendInvitations,
  });
}

export default new Routes().getPaths();
