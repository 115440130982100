import React, { useEffect } from 'react';
import { Flex, Grid } from '@jcm-technologies/uikit/dist/atoms/Layout';
import { Field } from '@jcm-technologies/uikit/dist/atoms/Field';
import { SelectInput } from '@jcm-technologies/uikit/dist/atoms/Select';
import { Switch } from '@jcm-technologies/uikit/dist/atoms/Switch';
import { Button } from '@jcm-technologies/uikit/dist/atoms/Button';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { getLanguagesOptions } from '../../../../core/helpers/getLanguages';
import {
  getCustomerInstallerCode,
  updateCustomersInstallerCode,
} from '../../../../modules/customers/actions';
import { getContrastColor } from '../../../../core/helpers/colorManager';
import { getTimezones } from '../../../../modules/countries/actions';
import { initTranslations } from '../../../../core/i18n';
import { setUserNeedUpdateLngFalse } from '../../../../modules/user/actions';

const Form = ({ data, setData, handleSubmit }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { timezones } = useSelector((state) => state.countries);
  const { installerCode } = useSelector((state) => state.customers);
  const {
    needUpdateLng,
    user: { language },
  } = useSelector((state) => state.user);
  const tenants = useSelector((state) => state.tenants);
  useEffect(() => {
    dispatch(getCustomerInstallerCode());
    dispatch(getTimezones());
  }, []);

  useEffect(async () => {
    if (needUpdateLng || language) {
      await initTranslations(language);
      dispatch(setUserNeedUpdateLngFalse());
    }
  }, [needUpdateLng, language]);

  return (
    <Grid
      gridTemplateAreas={['"." "."', '". ." ". ."']}
      gridTemplateColumns={['1fr', '1fr 1fr']}
      gridColumnGap={3}
      padding={2}
    >
      <Field label={t('form.timeZone')} id='timeZone'>
        {data?.timeZone && timezones && (
          <SelectInput
            onChange={(name, value) => {
              setData((prev) => ({
                ...prev,
                timeZone: value.value,
              }));
            }}
            defaultValue={{
              label: timezones?.find((el) => el.id === data?.timeZone)?.code,
              value: data?.timeZone,
            }}
            options={timezones?.map((option) => ({
              value: option.id,
              label: option.code,
            }))}
            placeholderMessage={t('form.timeZone')}
            textAlign='center'
          />
        )}
      </Field>
      <Field label={t('form.dateTimeFormat')} id='dateTimeFormat'>
        <SelectInput
          name='dateTimeFormat'
          onChange={(name, value) => {
            setData((prev) => ({
              ...prev,
              dateTimeFormat: value.value,
            }));
          }}
          defaultValue={{
            label: data?.dateTimeFormat,
            value: data?.dateTimeFormat,
          }}
          options={[
            {
              value: 'dd-MM-yyyy',
              label: 'dd-MM-yyyy',
            },
            {
              value: 'MM-dd-yyyy',
              label: 'MM-dd-yyyy',
            },
            {
              value: 'yyyy-MM-dd',
              label: 'yyyy-MM-dd',
            },
            {
              value: 'yyyy-dd-MM',
              label: 'yyyy-dd-MM',
            },
          ]}
          placeholderMessage={t('form.dateTimeFormat')}
          textAlign='center'
        />
      </Field>
      <Field label={t('form.preferredLanguage')} id='language'>
        {data?.language && (
          <SelectInput
            name='language'
            onChange={(name, value) => {
              setData((prev) => ({
                ...prev,
                language: value.value,
              }));
            }}
            defaultValue={{
              label: getLanguagesOptions()?.find((el) => el.value === data?.language)?.label,
              value: data?.language,
            }}
            options={getLanguagesOptions()}
            placeholderMessage={t('form.preferredLanguage')}
            textAlign='center'
          />
        )}
      </Field>
      <Field label={t('form.pageSize')} id='pageItems'>
        {data?.pageItems && (
          <SelectInput
            name='pageItems'
            onChange={(name, value) => {
              setData((prev) => ({
                ...prev,
                pageItems: Number(value.value),
              }));
            }}
            defaultValue={{
              label: data?.pageItems,
              value: data?.pageItems,
            }}
            options={[
              {
                value: '5',
                label: '5',
              },
              {
                value: '10',
                label: '10',
              },
              {
                value: '25',
                label: '25',
              },
              {
                value: '50',
                label: '50',
              },
            ]}
            placeholderMessage={t('form.pageSize')}
            textAlign='center'
          />
        )}
      </Field>
      <Flex justifyContent='baseline' alignItems='center'>
        <Field
          label={t('account.useInstallerCode')}
          id='useInstallerCode'
          display='flex'
          style={{ alignItems: 'center', flexDirection: 'row-reverse' }}
        >
          <Switch
            sizeInput='small'
            defaultValue={installerCode?.useInstallerCode}
            themeColor={tenants?.color1}
            action={(isChecked) => {
              installerCode.useInstallerCode = isChecked;
              dispatch(updateCustomersInstallerCode(isChecked));
            }}
            marginRight={2}
            id='useInstallerCode'
          />
        </Field>
      </Flex>{' '}
      <Flex justifyContent='flex-end' alignItems='center' marginTop={3}>
        <Button
          id='save-preferences'
          sizeButton='small'
          backgroundColor={tenants?.color1}
          action={() => handleSubmit()}
          color={getContrastColor(tenants?.color1)}
        >
          {t('general.save')}
        </Button>
      </Flex>
    </Grid>
  );
};

export default Form;

Form.propTypes = {
  data: PropTypes.shape({
    language: PropTypes.string,
    timeZone: PropTypes.string,
    dateTimeFormat: PropTypes.string,
    assistantConfigId: PropTypes.string,
    pageItems: PropTypes.number,
  }).isRequired,
  setData: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};
