import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Box } from '@jcm-technologies/uikit/dist/atoms/Layout';
import { TabsMenu } from '@jcm-technologies/uikit/dist/molecules/TabsMenu';
import PropTypes from 'prop-types';
import AssistantPanel from './AssistantPanel/View';
import Preferences from './Preferences/Edit';
import { getContrastColor } from '../../core/helpers/colorManager';

const View = ({ active }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const tenants = useSelector((state) => state.tenants);
  return (
    <Box
      backgroundColor='white'
      borderRadius={3}
      height='100%'
      minHeight='calc(100vh - 156px)'
      paddingTop={2}
    >
      <TabsMenu
        id='userConfigTab'
        colorActive={getContrastColor(tenants?.color1)}
        themeColor={tenants?.color1}
        defaultSelected={active}
        components={{
          preferences: <Preferences />,
          assistantPanel: <AssistantPanel />,
        }}
        items={[
          {
            id: 'preferences',
            title: <>{t('title.preferences')}</>,
            onClick: () => history.push('/user-config/preferences'),
          },
          {
            id: 'assistantPanel',
            title: t('title.assistantPanel'),
            onClick: () => history.push('/user-config/assistant-panel'),
          },
        ]}
      />
    </Box>
  );
};

export default View;

View.propTypes = {
  active: PropTypes.oneOf(['preferences', 'assistantPanel']).isRequired,
};
