import createFetchTypes from '../../../core/typeActions/types';
import devicesAction from '../actions/devices';

export const DRIVERS_GET_STATE = createFetchTypes('DRIVERS_GET_STATE');
export const DRIVERS_SET_RELAY = createFetchTypes('DRIVERS_SET_RELAY');
export const DRIVERS_POST_WRITE_MEMORY_SINGLE = createFetchTypes(
  'DRIVERS_POST_WRITE_MEMORY_SINGLE'
);

/* Redux state init */
const initialState = {
  driverState: undefined,
  relayActivation: undefined,
  driverStateError: undefined,
  driverWriteDone: false,
  driverWriteError: undefined,
  driverNotResponseError: false,
};

export default function devicesReducer(state = initialState, action) {
  if (action.type !== undefined) {
    switch (action.type) {
      case DRIVERS_GET_STATE.SUCCESS:
        return { ...state, driverState: action.result.payload, driverStateError: undefined };
      case DRIVERS_GET_STATE.FAILURE:
        return { ...state, driverStateError: action.error.response.body, driverState: undefined };
      case DRIVERS_SET_RELAY.SUCCESS:
        return { ...state, relayActivation: action.result };
      case DRIVERS_SET_RELAY.FAILURE:
        return { ...state, relayActivation: action.error.response.body };
      case devicesAction.RESET_RELAY_ACTIVATION:
        return { ...state, relayActivation: undefined };
      case devicesAction.RESET_ALL_DEVICE_PROPS:
        return { ...initialState };
      case DRIVERS_POST_WRITE_MEMORY_SINGLE.SUCCESS:
        return {
          ...state,
          driverWriteDone: true,
        };
      case DRIVERS_POST_WRITE_MEMORY_SINGLE.FAILURE:
        if (action.error.response === undefined) {
          return {
            ...state,
            driverNotResponseError: true,
          };
        }
        return {
          ...state,
          driverWriteError: action.error.response.body,
        };

      case DRIVERS_POST_WRITE_MEMORY_SINGLE.REQUEST:
        return {
          ...state,
          driverWriteDone: false,
          driverWriteError: undefined,
          driverNotResponseError: false,
        };
      default:
        return state;
    }
  }
  return state;
}
