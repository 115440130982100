import {
  GET_CUSTOMERS_CUSTOMER_ROLES,
  GET_CUSTOMERS_HONOA,
  GET_CUSTOMERS_INSTALLER_CODE,
  GET_CUSTOMERS_LICENSES,
  GET_CUSTOMERS_PROPERTY_MANAGERS,
  PUT_CUSTOMERS_INSTALLER_CODE,
  RESET_CUSTOMERS_CUSTOMER_ROLES,
} from './constants';
/* Redux state init */
const initialState = {
  list: [],
  honoa: [],
  installerCode: {},
  propertyManagers: [],
  needCallManagers: true,
  licenses: { results: [], licenses: [] },
  customerEditableRoles: [],
};

export default function tenantsReducer(state = initialState, action) {
  if (action.type !== undefined) {
    switch (action.type) {
      case GET_CUSTOMERS_HONOA.SUCCESS:
        return { ...state, honoa: [...action.result] };
      case GET_CUSTOMERS_INSTALLER_CODE.SUCCESS:
        return { ...state, installerCode: { ...action.result } };
      case PUT_CUSTOMERS_INSTALLER_CODE.SUCCESS:
        return { ...state, installerCode: { ...action.payload[1].datas } };
      case PUT_CUSTOMERS_INSTALLER_CODE.FAILURE: {
        const oldInstallerCode = { ...action.payload[1].datas };
        oldInstallerCode.useInstallerCode = !oldInstallerCode.useInstallerCode;
        return { ...state, installerCode: { ...oldInstallerCode } };
      }
      case GET_CUSTOMERS_PROPERTY_MANAGERS.SUCCESS:
        return { ...state, propertyManagers: action?.result, needCallManagers: false };
      case GET_CUSTOMERS_PROPERTY_MANAGERS.REQUEST:
        return { ...state, needCallManagers: false };
      case GET_CUSTOMERS_PROPERTY_MANAGERS.FAILURE:
        return { ...state, needCallManagers: true };
      case GET_CUSTOMERS_LICENSES.SUCCESS:
        return { ...state, licenses: action.result };
      case GET_CUSTOMERS_CUSTOMER_ROLES.SUCCESS:
        return {
          ...state,
          customerEditableRoles: {
            ...state.customerEditableRoles,
            [action.result.curRoleMask]: action.result.newRolesAllowed,
          },
        };
      default:
        return state;
    }
  }
  return state;
}
