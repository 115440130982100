export default {
  resetPassword: {
    email: 'E-mail',
    newPassword: 'New password',
    backToLogin: 'Back to login page',
    mailReseted: 'Your e-mail has been restored',
    passwordReset: 'Reset password',
    send: 'Send',
  },
};
