import { useTranslation } from 'react-i18next';
import { GridColumn } from '@progress/kendo-react-grid';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@jcm-technologies/uikit/dist/atoms/Layout';
import { CustomFilter } from '../../../common/Table/renderers';
import Table from '../../../common/Table';
import { getAllGDPRLogs } from '../../../../modules/owner/action';

export function TableAcceptedGDPR() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { logs } = useSelector((state) => state.owners);
  const [dataLoaded, setDataLoaded] = useState(logs || []);

  useEffect(() => {
    dispatch(getAllGDPRLogs(5000, 1));
  }, []);

  return (
    <Box paddingTop={4} maxWidth={[225, 622, 800, '100%']}>
      <Table
        dataLoaded={dataLoaded}
        setDataLoaded={setDataLoaded}
        pageable
        filterable
        editable
        sortable
        data={logs}
      >
        <GridColumn
          field='userName'
          title={t('form.email')}
          filterCell={CustomFilter}
          filter='text'
          editable={false}
        />
        <GridColumn
          field='createdAt'
          title={t('form.createDateUTC')}
          filterCell={CustomFilter}
          filter='text'
          editable={false}
        />
        <GridColumn
          field='browserAgent'
          title={t('form.browserAgent')}
          filterCell={CustomFilter}
          filter='text'
          editable={false}
        />
        <GridColumn
          field='os'
          title='OS'
          filterCell={CustomFilter}
          filter='text'
          editable={false}
        />
        <GridColumn
          field='iPv4Source'
          title='IPv4'
          filterCell={CustomFilter}
          filter='text'
          editable={false}
        />
        <GridColumn
          field='refererSource'
          title={t('form.refereeOrigin')}
          filterCell={CustomFilter}
          filter='text'
          editable={false}
        />
        <GridColumn
          field='version'
          title={t('form.gdprVersion')}
          filterCell={CustomFilter}
          filter='text'
          editable={false}
        />
      </Table>
    </Box>
  );
}
