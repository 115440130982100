export default {
  instalationUserGroups: {
    manageFobsAndLicenses: 'Gestisci FOB e licenze',
    fobsLicensesGridTitle: 'FOBS / Licenze',
    email: 'E-mail',
    phone: 'Telefono',
    addUser: 'Aggiungi utente',
    deleteUserConfirmation: 'Sei sicuro di voler eliminare questo utente?',
    Fobs: 'Emettitori',
    Licenses: 'Licenza',
    addFobToUser: "Aggiungi FOB all'utente",
    expiration: 'Scadenza',
    licenseStatus: 'Stato',
    locked: 'Bloccato',
    unlocked: 'Sbloccato',
    0: 'Bluetooth',
    1: 'Apertura remota',
    users: 'Utentis',
    information: 'Informazione',
  },
};
