export default {
  enrollCode: {
    manualTab: 'Einzelne Seriennummern eingeben',
    reservationsTab: 'Speicherplätze reservieren',
    sequentialTab: 'Seriennummern fortlaufend eingeben',
    universalsTab: 'Universal',
    device: 'Sender Typ auswählen',
    initialCode: 'Erste Seriennummer',
    idCard: 'DNI',
    spot: 'Parkplatz',
    numberOfFobs: 'Anzahl der Sender',
    endCode: 'Letzte Seriennummer',
    cantEnroll:
      'Für die Registrierung neuer Codes muss ein Gerät ausgewählt werden und verfügbare Positionen werden benötigt',
    cancel: 'Stornieren',
    accept: 'Akzeptieren',
    ok: 'OK',
    forceCodeQuestion: 'Möchten Sie die Registrierung dieses Codes erzwingen?',
    codeAlreadyExist: 'Dieser Code existiert bereits in einer anderen Gruppe',
    notAsigned: 'Nicht zugeordnet',
    reserved: 'Reserviert',
  },
};
