import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { Box, Flex } from '@jcm-technologies/uikit/dist/atoms/Layout';
import CheckableInput from '../../CheckableInput/CheckableInput';
import { putEquipmentRelay } from '../../../modules/old_to_refact/actions/equipments';

import '../RelaysTabEquipment.css';
import Dropdown from '../../Dropdown/Dropdown';
import { Input } from '../../Input/Input';

class RelayCheckableInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props.relay,
      time: props?.relay?.time / 1000 || 1,
      delay: props?.relay?.delay / 1000 || 0,
      functions: props.relay?.functions?.map((func) => ({
        ...func,
        name: props.t(`equipment.${func.name}`),
      })),
    };
  }

  onCheckChanged = (e) => {
    const relay = { ...this.state, enabled: !this.state.enabled };
    this.setState({ enabled: relay.enabled });
    this.callToUpdateRelay(relay);
  };

  onBlur = () => {
    this.callToUpdateRelay(this.state);
  };

  callToUpdateRelay = (relay) => {
    const { putEquipmentRelay, equipmentId, facilityId, setParamsOnForm } = this.props;
    setParamsOnForm(relay);
  };

  onChangeNumericInput = (num, max, min) => {
    if (parseInt(num) > max) {
      num = max;
    } else if (parseInt(num) < min) {
      num = min;
    }
    return num;
  };

  onChange = (e) => {
    const { setParamsOnForm } = this.props;
    const relay = this.state;
    const relayValues = [relay];
    relayValues.map((value) => {
      if (e.currentTarget.id === 'customOpenTime') {
        const changedValue = (value[e.currentTarget.id] = this.onChangeNumericInput(
          e.currentTarget.value,
          86400,
          1
        ));
        const values = Number.parseFloat(value[e.currentTarget.id]);
        this.setState({ [e.target.id]: values.toFixed() });
        return changedValue;
      }
      if (e.currentTarget.id === 'time') {
        const changedValue = (value[e.currentTarget.id] = this.onChangeNumericInput(
          e.currentTarget.value,
          60,
          1
        ));

        const values = Number.parseFloat(value[e.currentTarget.id]);
        this.setState({ [e.target.id]: values.toFixed() });
        return changedValue;
      }
      if (e.currentTarget.id === 'delay') {
        const changedValue = (value[e.currentTarget.id] = this.onChangeNumericInput(
          e.currentTarget.value,
          10,
          0
        ));
        const values = Number.parseFloat(value[e.currentTarget.id]);
        this.setState({ [e.target.id]: values.toFixed() });
        return changedValue;
      }
      const changedValue = (value[e.currentTarget.id] = e.currentTarget.value);
      this.setState({ [e.target.id]: e.target.value });
      return changedValue;
    });
    setParamsOnForm(relay);
  };

  render() {
    const { equipmentName, i, t, schedules, tenants } = this.props;
    const { functions, function: functionSelected } = this.state;
    const haveBistableModeActive = functions.find((el) => el.id === functionSelected).code === 3;
    return (
      <Flex width='100%' id="relaycontent">
        <Box width='25%'>
          <CheckableInput
            id='name'
            key={this.state.id}
            value={this.state.name}
            placeHolder={`${t(`relaysConfiguration.Relay`)} ${i + 1}`}
            isFocused={false}
            isSelected={this.state.enabled}
            onCheckChanged={this.onCheckChanged}
            onBlur={this.onBlur}
            onChange={this.onChange}
          />
        </Box>
        {this.state.isAdvancedRelay && this.state.enabled ? (
          <Flex width='74%' paddingX={1}>
            <Flex flexDirection='column' width='20%'>
              <Box width='100%' paddingX={2}>
                <Dropdown
                  values={this.state.functions}
                  placeHolder={`${t('relaysConfiguration.type')} ${i + 1}`}
                  value={
                    this.state.function === null ? this.state.functions[0].id : this.state.function
                  }
                  id='function'
                  onChange={this.onChange}
                />
              </Box>
            </Flex>
            <Flex flexDirection='row' width='80%'>
              <Flex flexDirection='column' width='30%' paddingX={1}>
                <Input
                  type='number'
                  placeHolder={t('relaysConfiguration.time', { count: i + 1 })}
                  value={this.state.time === 0 ? 1 : this.state.time}
                  id='time'
                  min={1}
                  max={60}
                  title={t('relaysConfiguration.infoTime')}
                  onChange={this.onChange}
                  tenants={tenants}
                  color1={tenants.color1}
                  sufix='s'
                  disable={haveBistableModeActive}
                />
                <Input
                  type='number'
                  placeHolder={t('relaysConfiguration.delay', { count: i + 1 })}
                  value={this.state.delay === 0 ? '0' : this.state.delay}
                  min={0}
                  max={10}
                  title={t('relaysConfiguration.infoDelay')}
                  id='delay'
                  onChange={this.onChange}
                  tenants={tenants}
                  color1={tenants.color1}
                  sufix='s'
                  disable={haveBistableModeActive}
                />
              </Flex>
              <Box width='30%' paddingX={1}>
                <Dropdown
                  values={
                    schedules
                      ? [
                          { key: '', id: '', value: '--' },
                          ...schedules.map((schedule) => ({
                            key: schedule.scheduleId,
                            id: schedule.scheduleId,
                            value: schedule.name,
                          })),
                        ]
                      : []
                  }
                  placeHolder={t('relaysConfiguration.keepDoorOpenSchedule')}
                  value={this.state.scheduleKeepOpen}
                  id='scheduleKeepOpen'
                  onChange={this.onChange}
                  isDisabled={haveBistableModeActive}
                />
              </Box>
              <Box width='40%' paddingX={1}>
                <Dropdown
                  values={
                    schedules
                      ? [
                          { key: '', id: '', value: '--' },
                          ...schedules.map((schedule) => ({
                            key: schedule.scheduleId,
                            id: schedule.scheduleId,
                            value: schedule.name,
                          })),
                        ]
                      : []
                  }
                  placeHolder={t('relaysConfiguration.customOpenDoorTimeSchedule')}
                  value={this.state.scheduleCustomOpenTime}
                  id='scheduleCustomOpenTime'
                  onChange={this.onChange}
                  isDisabled={haveBistableModeActive}
                />
                <Input
                  type='number'
                  title={t('relaysConfiguration.infoCustomOpenDoorTime')}
                  placeHolder={t('relaysConfiguration.customOpenDoorTime')}
                  value={this.state.customOpenTime === 0 ? 1 : this.state.customOpenTime}
                  id='customOpenTime'
                  onChange={this.onChange}
                  tenants={tenants}
                  color1={tenants.color1}
                  sufix='s'
                  disable={haveBistableModeActive}
                />
              </Box>
            </Flex>
          </Flex>
        ) : (
          ''
        )}
      </Flex>
    );
  }
}

const mapStateToProps = (state) => ({
  tenants: state.tenants,
  singleSchedules: state.schedules.singleSchedules,
});
const RelayCheckableInputPage = withTranslation()(RelayCheckableInput);
const RelayCheckableInputWithRouter = withRouter(RelayCheckableInputPage);
export default connect(mapStateToProps, { putEquipmentRelay })(RelayCheckableInputWithRouter);
