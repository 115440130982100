import { Text } from '@jcm-technologies/uikit/dist/atoms/Typography';
import { UploaderIcon } from '@jcm-technologies/uikit/dist/molecules/Uploader';
import { Flex } from '@jcm-technologies/uikit/dist/atoms/Layout';
import { Card } from '@jcm-technologies/uikit/dist/molecules/Card';
import { useTranslation } from 'react-i18next';
import { useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { theme } from '@jcm-technologies/uikit/dist/theme';

const CustomizationLogoCollapsed = ({ imgLogo, onImagesLoad, handleImageDelete }) => {
  const { t } = useTranslation();
  const imgLogoRef = useRef();
  const tenants = useSelector((state) => state.tenants);
  const { color1 } = tenants;
  return (
    <Card
      rounded='solid'
      marginTop={3}
      marginBottom={1}
    >
      <Text color='blackLight' sizeText='display16' textAlign='left' marginBottom={1}>
        {t('form.collapsedLogo')}
      </Text>
      <Flex paddingBottom={4} paddingTop={5} flexDirection='column'>
        <UploaderIcon
          id='collapsedLogo'
          imgRef={imgLogoRef}
          defaultValue={imgLogo?.[0]?.base64}
          images={imgLogo}
          onImagesLoad={onImagesLoad}
          messageEdit={t('general.edit')}
          messageDelete={t('general.delete')}
          handleImageDelete={handleImageDelete}
          name='collapsedLogo'
          themeColor={color1}
          styleImg={{
            objectFit: 'contain',
          }}
          stylesAvatar={{
            maxWidth: '100px',
            width: '100%',
            maxHeight: '100px',
            borderRadius: theme.radii[2],
          }}
        />
      </Flex>
    </Card>
  );
};

export default CustomizationLogoCollapsed;

CustomizationLogoCollapsed.propTypes = {
  onImagesLoad: PropTypes.func.isRequired,
  imgLogo: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  handleImageDelete: PropTypes.func.isRequired,
};
