import { call, fork, takeLatest } from 'redux-saga/effects';
import fetchEntitySaga from '../../old_to_refact/sagas/utils/fetchEntitySaga';
import apiPathFactory from '../../../core/old_common/rooting/apiPathFactory';
import api from '../../../core/helpers/api';
import {
  GET_GROUP_USERS_FOBS_LIST,
  GET_GROUP_USERS_LIST_BY_USERID,
  GET_GROUP_USERS_LIST,
  GET_GROUP_USERS_LICENSES_LIST,
  POST_GROUP_USERS_COPY,
  DELETE_GROUP_USERS,
  DELETE_GROUP_USERS_LIST,
  DELETE_GROUP_USERS_FOB,
  PUT_GROUP_USERS_LICENSE_LOCK,
  PUT_GROUP_USERS_LICENSE_UNLOCK,
  PUT_GROUP_USERS_MOVE,
  POST_GROUP_USERS,
  POST_GROUP_USERS_FOBS,
  POST_GROUP_USERS_UPDATE,
  GET_GROUP_USERS_AVAILABLE_FACILITIES,
  GET_GROUP_USERS_AVAILABLE_GROUPS,
  GET_GROUP_USERS_COUNTER,
  POST_GROUP_USERS_RESEND_INVITATION_LICENCES,
  UPDATE_GROUP_USERS,
  PUT_GROUP_USERS_LOCK_USER_LICENSE,
  PUT_GROUP_USERS_UNLOCK_USER,
  PUT_GROUP_USERS_LOCK_USER,
  PUT_GROUP_USERS_EXCEL,
} from './constants.js';

export const getUserGroups = {
  base: ({ facilityId, groupId }) => ({
    facilityId,
    groupId,
    type: GET_GROUP_USERS_LIST.BASE,
  }),
  request: () => ({ type: GET_GROUP_USERS_LIST.REQUEST }),
  success: (result) => ({ type: GET_GROUP_USERS_LIST.SUCCESS, result }),
  failure: (error) => ({ type: GET_GROUP_USERS_LIST.FAILURE, error }),
};

export const getUserGroupsCounter = {
  base: ({ facilityId, groupId }) => ({
    facilityId,
    groupId,
    type: GET_GROUP_USERS_COUNTER.BASE,
  }),
  request: () => ({ type: GET_GROUP_USERS_COUNTER.REQUEST }),
  success: (result) => ({ type: GET_GROUP_USERS_COUNTER.SUCCESS, result: result[0].counters }),
  failure: (error) => ({ type: GET_GROUP_USERS_COUNTER.FAILURE, error }),
};

export const getUserGroupsGetAvailableGroups = {
  base: ({ facilityId, groupId, exceptme }) => ({
    facilityId,
    groupId,
    type: GET_GROUP_USERS_AVAILABLE_GROUPS.BASE,
    exceptme,
  }),
  request: () => ({ type: GET_GROUP_USERS_AVAILABLE_GROUPS.REQUEST }),
  success: (result) => ({ type: GET_GROUP_USERS_AVAILABLE_GROUPS.SUCCESS, result }),
  failure: (error) => ({ type: GET_GROUP_USERS_AVAILABLE_GROUPS.FAILURE, error }),
};

export const getUserGroupsGetAvailableFacilities = {
  base: ({ facilityId, groupId, exceptme }) => ({
    facilityId,
    groupId,
    type: GET_GROUP_USERS_AVAILABLE_FACILITIES.BASE,
    exceptme,
  }),
  request: () => ({ type: GET_GROUP_USERS_AVAILABLE_FACILITIES.REQUEST }),
  success: (result) => ({ type: GET_GROUP_USERS_AVAILABLE_FACILITIES.SUCCESS, result }),
  failure: (error) => ({ type: GET_GROUP_USERS_AVAILABLE_FACILITIES.FAILURE, error }),
};

export const getUserGroupsFobs = {
  base: ({ facilityId, groupId }) => ({
    facilityId,
    groupId,
    type: GET_GROUP_USERS_FOBS_LIST.BASE,
  }),
  request: () => ({ type: GET_GROUP_USERS_FOBS_LIST.REQUEST }),
  success: (result) => ({ type: GET_GROUP_USERS_FOBS_LIST.SUCCESS, result }),
  failure: (error) => ({ type: GET_GROUP_USERS_FOBS_LIST.FAILURE, error }),
};

export const getUserGroupsFobsByUserId = {
  base: ({ facilityId, groupId, userId }) => ({
    facilityId,
    groupId,
    userId,
    type: GET_GROUP_USERS_LIST_BY_USERID.BASE,
  }),
  request: () => ({ type: GET_GROUP_USERS_LIST_BY_USERID.REQUEST }),
  success: (result) => ({ type: GET_GROUP_USERS_LIST_BY_USERID.SUCCESS, result }),
  failure: (error) => ({ type: GET_GROUP_USERS_LIST_BY_USERID.FAILURE, error }),
};

export const getUserGroupsLicenses = {
  base: ({ facilityId, groupId, userId }) => ({
    facilityId,
    groupId,
    userId,
    type: GET_GROUP_USERS_LICENSES_LIST.BASE,
  }),
  request: () => ({ type: GET_GROUP_USERS_LICENSES_LIST.REQUEST }),
  success: (result) => ({ type: GET_GROUP_USERS_LICENSES_LIST.SUCCESS, result }),
  failure: (error) => ({ type: GET_GROUP_USERS_LICENSES_LIST.FAILURE, error }),
};

export const postUserGroups = {
  base: (facilityId, groupId, user) => ({
    type: POST_GROUP_USERS.BASE,
    facilityId,
    groupId,
    user,
  }),
  request: () => ({ type: POST_GROUP_USERS.REQUEST }),
  success: (result) => ({ type: POST_GROUP_USERS.SUCCESS, result }),
  failure: (error) => ({ type: POST_GROUP_USERS.FAILURE, error }),
};

export const postReSendInvitationLicenceUserGroups = {
  base: (facilityId, groupId, userId) => ({
    type: POST_GROUP_USERS_RESEND_INVITATION_LICENCES.BASE,
    facilityId,
    groupId,
    userId,
  }),
  request: () => ({ type: POST_GROUP_USERS_RESEND_INVITATION_LICENCES.REQUEST }),
  success: (result) => ({ type: POST_GROUP_USERS_RESEND_INVITATION_LICENCES.SUCCESS, result }),
  failure: (error) => ({ type: POST_GROUP_USERS_RESEND_INVITATION_LICENCES.FAILURE, error }),
};

export const deleteUserGroup = {
  base: (facilityId, groupId, userId) => ({
    type: DELETE_GROUP_USERS.BASE,
    facilityId,
    groupId,
    userId,
  }),
  request: () => ({ type: DELETE_GROUP_USERS.REQUEST }),
  success: (result) => ({ type: DELETE_GROUP_USERS.SUCCESS, result }),
  failure: (error) => ({ type: DELETE_GROUP_USERS.FAILURE, error }),
};

export const updateUserGroup = {
  base: (facilityId, groupId, userId, data) => ({
    type: POST_GROUP_USERS_UPDATE.BASE,
    facilityId,
    groupId,
    userId,
    data,
  }),
  request: () => ({ type: POST_GROUP_USERS_UPDATE.REQUEST }),
  success: (result) => ({ type: POST_GROUP_USERS_UPDATE.SUCCESS, result }),
  failure: (error) => ({ type: POST_GROUP_USERS_UPDATE.FAILURE, error }),
};

export const deleteUserGroupFob = {
  base: (facilityId, groupId, userId, fobId) => ({
    type: DELETE_GROUP_USERS_FOB.BASE,
    facilityId,
    groupId,
    userId,
    fobId,
  }),
  request: () => ({ type: DELETE_GROUP_USERS_FOB.REQUEST }),
  success: (result) => ({ type: DELETE_GROUP_USERS_FOB.SUCCESS, result }),
  failure: (error) => ({ type: DELETE_GROUP_USERS_FOB.FAILURE, error }),
};

export const deleteUserGroupList = {
  base: (facilityId, groupId, userList) => ({
    type: DELETE_GROUP_USERS_LIST.BASE,
    facilityId,
    groupId,
    userList,
  }),
  request: () => ({ type: DELETE_GROUP_USERS_LIST.REQUEST }),
  success: (result, payload) => ({ type: DELETE_GROUP_USERS_LIST.SUCCESS, result, payload }),
  failure: (error) => ({ type: DELETE_GROUP_USERS_LIST.FAILURE, error }),
};

export const postUserGroupsFobs = {
  base: (facilityId, groupId, userId, fobList) => ({
    type: POST_GROUP_USERS_FOBS.BASE,
    facilityId,
    groupId,
    userId,
    fobList,
  }),
  request: () => ({ type: POST_GROUP_USERS_FOBS.REQUEST }),
  success: (result, payload) => ({ type: POST_GROUP_USERS_FOBS.SUCCESS, result, payload }),
  failure: (error) => ({ type: POST_GROUP_USERS_FOBS.FAILURE, error }),
};

export const lockUserLicense = {
  base: (facilityId, groupId, userId, id) => ({
    type: PUT_GROUP_USERS_LICENSE_LOCK.BASE,
    facilityId,
    groupId,
    userId,
    id,
  }),
  request: () => ({ type: PUT_GROUP_USERS_LICENSE_LOCK.REQUEST }),
  success: (result, payload) => ({ type: PUT_GROUP_USERS_LICENSE_LOCK.SUCCESS, result, payload }),
  failure: (error) => ({ type: PUT_GROUP_USERS_LICENSE_LOCK.FAILURE, error }),
};

export const unlockUserLicense = {
  base: (facilityId, groupId, userId, id) => ({
    type: PUT_GROUP_USERS_LICENSE_UNLOCK.BASE,
    facilityId,
    groupId,
    userId,
    id,
  }),
  request: () => ({ type: PUT_GROUP_USERS_LICENSE_UNLOCK.REQUEST }),
  success: (result, payload) => ({ type: PUT_GROUP_USERS_LICENSE_UNLOCK.SUCCESS, result, payload }),
  failure: (error) => ({ type: PUT_GROUP_USERS_LICENSE_UNLOCK.FAILURE, error }),
};

export const unlockUser = {
  base: (facilityId, groupId, userId) => ({
    type: PUT_GROUP_USERS_UNLOCK_USER.BASE,
    facilityId,
    groupId,
    userId,
  }),
  request: () => ({ type: PUT_GROUP_USERS_UNLOCK_USER.REQUEST }),
  success: (result, payload) => ({ type: PUT_GROUP_USERS_UNLOCK_USER.SUCCESS, result, payload }),
  failure: (error) => ({ type: PUT_GROUP_USERS_UNLOCK_USER.FAILURE, error }),
};

export const lockUser = {
  base: (facilityId, groupId, userId) => ({
    type: PUT_GROUP_USERS_LOCK_USER.BASE,
    facilityId,
    groupId,
    userId,
  }),
  request: () => ({ type: PUT_GROUP_USERS_LOCK_USER.REQUEST }),
  success: (result, payload) => ({ type: PUT_GROUP_USERS_LOCK_USER.SUCCESS, result, payload }),
  failure: (error) => ({ type: PUT_GROUP_USERS_LOCK_USER.FAILURE, error }),
};

export const moveUserGroup = {
  base: (facilityId, groupId, toFacilityID, toGroupID, list) => ({
    type: PUT_GROUP_USERS_MOVE.BASE,
    facilityId,
    groupId,
    toFacilityID,
    toGroupID,
    list,
  }),
  request: () => ({ type: PUT_GROUP_USERS_MOVE.REQUEST }),
  success: (result, payload) => ({ type: PUT_GROUP_USERS_MOVE.SUCCESS, result, payload }),
  failure: (error) => ({ type: PUT_GROUP_USERS_MOVE.FAILURE, error }),
};

export const copyUserGroup = {
  base: (facilityId, groupId, toFacilityID, toGroupID, list) => ({
    type: POST_GROUP_USERS_COPY.BASE,
    facilityId,
    groupId,
    toFacilityID,
    toGroupID,
    list,
  }),
  request: () => ({ type: POST_GROUP_USERS_COPY.REQUEST }),
  success: (result, payload) => ({ type: POST_GROUP_USERS_COPY.SUCCESS, result, payload }),
  failure: (error) => ({ type: POST_GROUP_USERS_COPY.FAILURE, error }),
};

export const importGroupUsers = {
  base: ({ idFacility, idGroup, file }) => ({
    type: PUT_GROUP_USERS_EXCEL.BASE,
    idFacility,
    idGroup,
    file,
  }),
  request: () => ({ type: PUT_GROUP_USERS_EXCEL.REQUEST }),
  success: (result) => ({ type: PUT_GROUP_USERS_EXCEL.SUCCESS, result }),
  failure: (error) => ({ type: PUT_GROUP_USERS_EXCEL.FAILURE, error }),
};

// -----------------------------------------------------------------------------

export function* getGroupsUsersCounterSaga({ facilityId, groupId }) {
  try {
    const url = apiPathFactory.userGroupsGetUserCounter(facilityId, groupId);
    const response = yield call(() => fetchEntitySaga(getUserGroupsCounter, api.get, url));
    return response;
  } catch (error) {
    return error;
  }
}

export function* getGroupsUsersSaga({ facilityId, groupId }) {
  try {
    const url = apiPathFactory.userGroupsGetUsersList(facilityId, groupId);
    const response = yield call(() => fetchEntitySaga(getUserGroups, api.get, url));
    yield call(getGroupsUsersCounterSaga, { facilityId, groupId });
    return response;
  } catch (error) {
    return error;
  }
}

export function* getGroupsUsersAvailableFacilitiesSaga({ facilityId, groupId, exceptme }) {
  try {
    const url = apiPathFactory.userGroupsGetUserAvailableFacilities(facilityId, groupId, exceptme);
    const response = yield call(() =>
      fetchEntitySaga(getUserGroupsGetAvailableFacilities, api.get, url)
    );
    return response;
  } catch (error) {
    return error;
  }
}

export function* getGroupsUsersAvailableGroupsSaga({ facilityId, groupId, exceptme }) {
  try {
    const url = apiPathFactory.userGroupsGetUserAvailableGroups(facilityId, groupId, exceptme);
    const response = yield call(() =>
      fetchEntitySaga(getUserGroupsGetAvailableGroups, api.get, url)
    );
    return response;
  } catch (error) {
    return error;
  }
}

export function* getGroupsUsersFobsSaga({ facilityUser, groupUser }) {
  try {
    const url = apiPathFactory.userGroupsGetUsersFobs(facilityUser, groupUser);
    const response = yield call(() => fetchEntitySaga(getUserGroupsFobs, api.get, url));
    return response;
  } catch (error) {
    return error;
  }
}

export function* getGroupsUsersLicensesSaga({ facilityUser, groupUser, groupUserId }) {
  try {
    const url = apiPathFactory.userGroupsGetUsersLicenses(facilityUser, groupUser, groupUserId);
    const response = yield call(() => fetchEntitySaga(getUserGroupsLicenses, api.get, url));
    return response;
  } catch (error) {
    return error;
  }
}

export function* getGroupsUsersFobsByUserIDSaga({ facilityUser, groupUser, groupUserId }) {
  try {
    const url = apiPathFactory.userGroupsGetUsersFobsByUserId(facilityUser, groupUser, groupUserId);
    const response = yield call(() => fetchEntitySaga(getUserGroupsFobsByUserId, api.get, url));
    return response;
  } catch (error) {
    return error;
  }
}

export function* postGroupsUsersSaga({ facilityId, groupId, user }) {
  try {
    const url = apiPathFactory.userGroupsPostUsersList(facilityId, groupId);
    const response = yield call(() =>
      fetchEntitySaga(postUserGroups, api.post, url, { data: user })
    );
    yield call(getGroupsUsersCounterSaga, { facilityId, groupId });
    yield call(getGroupsUsersSaga, { facilityId, groupId });
    return response;
  } catch (error) {
    return error;
  }
}

export function* postGroupsUsersPostReSendInvitationLicenceSaga({ facilityId, groupId, userId }) {
  try {
    const url = apiPathFactory.postResendInvitationLicence(facilityId, groupId, userId);
    const response = yield call(() =>
      fetchEntitySaga(postReSendInvitationLicenceUserGroups, api.post, url, { data: {} })
    );

    yield call(getGroupsUsersSaga, { facilityId, groupId });
    return response;
  } catch (error) {
    return error;
  }
}

export function* postGroupsUsersFobsSaga({ facilityUser, groupUser, groupUserId, fobsIds }) {
  try {
    const url = apiPathFactory.userGroupsPostUsersFobs(facilityUser, groupUser, groupUserId);
    const response = yield call(() =>
      fetchEntitySaga(postUserGroupsFobs, api.post, url, { data: fobsIds })
    );
    return response;
  } catch (error) {
    return error;
  }
}

export function* deleteGroupsUserSaga({ facilityId, groupId, userId }) {
  try {
    const url = apiPathFactory.userGroupDelete(facilityId, groupId, userId);
    const response = yield call(() => fetchEntitySaga(deleteUserGroup, api.del, url));
    yield call(getGroupsUsersCounterSaga, { facilityId, groupId });
    yield call(getGroupsUsersSaga, { facilityId, groupId });
    return response;
  } catch (error) {
    return error;
  }
}

export function* updateGroupsUserSaga({ facilityId, groupId, userId, data }) {
  try {
    const url = apiPathFactory.userGroupUpdate(facilityId, groupId, userId);
    const response = yield call(() => fetchEntitySaga(updateUserGroup, api.put, url, { data }));
    return response;
  } catch (error) {
    return error;
  }
}

export function* deleteGroupsUserFobSaga({ facilityUser, groupUser, groupUserId, fobId }) {
  try {
    const url = apiPathFactory.userGroupFobDelete(facilityUser, groupUser, groupUserId, fobId);
    const response = yield call(() => fetchEntitySaga(deleteUserGroupFob, api.del, url));
    return response;
  } catch (error) {
    return error;
  }
}

export function* deleteGroupsUserListSaga({ facilityId, groupId, userList }) {
  try {
    const url = apiPathFactory.userGroupDeleteList(facilityId, groupId);
    const response = yield call(() =>
      fetchEntitySaga(deleteUserGroupList, api.del, url, { data: userList })
    );

    yield call(getGroupsUsersCounterSaga, { facilityId, groupId });
    yield call(getGroupsUsersSaga, { facilityId, groupId });
    return response;
  } catch (error) {
    return error;
  }
}

export function* putUserLicenseLockSaga({ facilityUser, groupUser, groupUserId, id }) {
  try {
    const url = apiPathFactory.userLicenseLock(facilityUser, groupUser, groupUserId, id);
    const response = yield call(() => fetchEntitySaga(lockUserLicense, api.put, url));
    return response;
  } catch (error) {
    return error;
  }
}

export function* putUserLicenseUnlockSaga({ facilityUser, groupUser, groupUserId, id }) {
  try {
    const url = apiPathFactory.userLicenseUnlock(facilityUser, groupUser, groupUserId, id);
    const response = yield call(() => fetchEntitySaga(unlockUserLicense, api.put, url));
    return response;
  } catch (error) {
    return error;
  }
}

export function* putUserUnlockSaga({ facilityId, groupId, userId }) {
  try {
    const url = apiPathFactory.userGroupUnlock(facilityId, groupId, userId);
    const response = yield call(() => fetchEntitySaga(unlockUser, api.put, url));
    yield call(getGroupsUsersSaga, { facilityId, groupId });
    return response;
  } catch (error) {
    return error;
  }
}

export function* putUserLockSaga({ facilityId, groupId, userId }) {
  try {
    const url = apiPathFactory.userGroupLock(facilityId, groupId, userId);
    const response = yield call(() => fetchEntitySaga(lockUser, api.put, url));
    yield call(getGroupsUsersSaga, { facilityId, groupId });
    return response;
  } catch (error) {
    return error;
  }
}

export function* putUserGroupMoveSaga({ facilityId, groupId, toFacilityID, toGroupID, list }) {
  try {
    const url = apiPathFactory.userMoveGroup(facilityId, groupId, toFacilityID, toGroupID);
    const response = yield call(() => fetchEntitySaga(moveUserGroup, api.put, url, { data: list }));
    yield call(getGroupsUsersCounterSaga, { facilityId, groupId });
    yield call(getGroupsUsersSaga, { facilityId, groupId });

    return response;
  } catch (error) {
    return error;
  }
}

export function* putUserGroupCopySaga({ facilityId, groupId, toFacilityID, toGroupID, list }) {
  try {
    const url = apiPathFactory.userCopyGroup(facilityId, groupId, toFacilityID, toGroupID);
    const response = yield call(() =>
      fetchEntitySaga(copyUserGroup, api.post, url, { data: list })
    );
    return response;
  } catch (error) {
    return error;
  }
}

export function* importGroupUsersSaga({ facilityId, groupId, file }) {
  try {
    const url = apiPathFactory.userImportGroupUsers(facilityId, groupId);
    const response = yield call(() =>
      fetchEntitySaga(importGroupUsers, api.put, url, {
        formData: [{ fieldName: 'file', file }],
      })
    );
    yield call(getGroupsUsersCounterSaga, { facilityId, groupId });
    yield call(getGroupsUsersSaga, { facilityId, groupId });

    return response;
  } catch (error) {
    return error;
  }
}

// -----------------------------------------------------------------------------

function* watchGetGroupUsers() {
  yield takeLatest(GET_GROUP_USERS_LIST.BASE, getGroupsUsersSaga);
}

function* watchGetGroupUsersCounter() {
  yield takeLatest(GET_GROUP_USERS_COUNTER.BASE, getGroupsUsersCounterSaga);
}

function* watchGetGroupUsersAvailableFacilities() {
  yield takeLatest(
    GET_GROUP_USERS_AVAILABLE_FACILITIES.BASE,
    getGroupsUsersAvailableFacilitiesSaga
  );
}

function* watchGetGroupUsersAvailableGroups() {
  yield takeLatest(GET_GROUP_USERS_AVAILABLE_GROUPS.BASE, getGroupsUsersAvailableGroupsSaga);
}

function* watchPostGroupUsers() {
  yield takeLatest(POST_GROUP_USERS.BASE, postGroupsUsersSaga);
}

function* watchDeleteGroupUser() {
  yield takeLatest(DELETE_GROUP_USERS.BASE, deleteGroupsUserSaga);
}

function* watchDeleteGroupUserList() {
  yield takeLatest(DELETE_GROUP_USERS_LIST.BASE, deleteGroupsUserListSaga);
}

function* watchUpdateGroupUser() {
  yield takeLatest(UPDATE_GROUP_USERS, updateGroupsUserSaga);
}

function* watchGetGroupUsersFobs() {
  yield takeLatest(GET_GROUP_USERS_FOBS_LIST.BASE, getGroupsUsersFobsSaga);
}

function* watchGetGroupUsersFobsByUserId() {
  yield takeLatest(GET_GROUP_USERS_LIST_BY_USERID.BASE, getGroupsUsersFobsByUserIDSaga);
}

function* watchGetGroupUsersLicenses() {
  yield takeLatest(GET_GROUP_USERS_LICENSES_LIST.BASE, getGroupsUsersLicensesSaga);
}

function* watchPostGroupUsersFobs() {
  yield takeLatest(POST_GROUP_USERS_FOBS.BASE, postGroupsUsersFobsSaga);
}

function* watchDeleteGroupUserFob() {
  yield takeLatest(DELETE_GROUP_USERS_FOB.BASE, deleteGroupsUserFobSaga);
}

function* watchPutUserLicenseLock() {
  yield takeLatest(PUT_GROUP_USERS_LOCK_USER_LICENSE.BASE, putUserLicenseLockSaga);
}

function* watchPutUserLicenseUnlock() {
  yield takeLatest(PUT_GROUP_USERS_LICENSE_UNLOCK.BASE, putUserLicenseUnlockSaga);
}

function* watchPutUserUnLock() {
  yield takeLatest(PUT_GROUP_USERS_UNLOCK_USER.BASE, putUserUnlockSaga);
}

function* watchPutUserLock() {
  yield takeLatest(PUT_GROUP_USERS_LOCK_USER.BASE, putUserLockSaga);
}

function* watchGetGroupUsersMove() {
  yield takeLatest(PUT_GROUP_USERS_MOVE.BASE, putUserGroupMoveSaga);
}

function* watchGetGroupUsersCopy() {
  yield takeLatest(POST_GROUP_USERS_COPY.BASE, putUserGroupCopySaga);
}

function* watchPostGroupUsersReSendInvitationLicence() {
  yield takeLatest(
    POST_GROUP_USERS_RESEND_INVITATION_LICENCES.BASE,
    postGroupsUsersPostReSendInvitationLicenceSaga
  );
}

function* watchImportGroupUsers() {
  yield takeLatest(PUT_GROUP_USERS_EXCEL.BASE, importGroupUsersSaga);
}

// -----------------------------------------------------------------------------

export default function* watchers() {
  yield fork(watchGetGroupUsers);
  yield fork(watchGetGroupUsersCounter);
  yield fork(watchGetGroupUsersAvailableFacilities);
  yield fork(watchGetGroupUsersAvailableGroups);
  yield fork(watchGetGroupUsersMove);
  yield fork(watchGetGroupUsersCopy);
  yield fork(watchPostGroupUsers);
  yield fork(watchDeleteGroupUser);
  yield fork(watchDeleteGroupUserList);
  yield fork(watchGetGroupUsersFobs);
  yield fork(watchGetGroupUsersFobsByUserId);
  yield fork(watchGetGroupUsersLicenses);
  yield fork(watchPostGroupUsersFobs);
  yield fork(watchDeleteGroupUserFob);
  yield fork(watchPutUserLicenseLock);
  yield fork(watchPutUserLicenseUnlock);
  yield fork(watchUpdateGroupUser);
  yield fork(watchPutUserUnLock);
  yield fork(watchPutUserLock);
  yield fork(watchPostGroupUsersReSendInvitationLicence);
  yield fork(watchImportGroupUsers);
}
