export default {
  remoteComunications: {
    index: 'Indice',
    content: 'Contenu',
    serialNumber: 'Numéro de série',
    uniqueKey: 'Clé unique',
    operationsCode: 'Opera. Code',
    finalCode: 'Code de fin',
    progress: 'Le progrès',
    status: 'Statut',
    refresh: 'Rafraîchir',
    saveConfig: 'Enregistrer la configuration',
    readConfig: 'Lire la configuration',
    readEvents: 'Lire les événements',
    removeEvents: 'Supprimer des événements',
    seeEvents: 'Voir les événements',
    export: 'Exportation',
    Completed: 'Terminé',
    Received: 'Reçu',
    Processing: 'En traitement',
  },
};
