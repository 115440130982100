import React, { useCallback, useEffect, useState } from 'react';
import { Flex } from '@jcm-technologies/uikit/dist/atoms/Layout';
import { Button } from '@jcm-technologies/uikit/dist/atoms/Button';
import { Prompt, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  getCustomersHonoa,
  updateCustomerAttributesHonoa,
} from '../../../modules/customers/actions';
import validationsDictionary from '../../../core/old_common/validations';
import { getErrors } from '../../../core/old_common/validations/service';
import { getCookieCustomerId } from '../../../core/old_common/utils/cookiesManager';
import { getContrastColor } from '../../../core/helpers/colorManager';
import { base64ToImage, imageFilesToBase64} from '../../../core/helpers/getImagesBase64';
import logo1 from '../../../static/jcmhonoalogo.png';
import HonoaPreview from '../../../components/Administration/Honoa/HonoaInfo';
import HonoaForm    from '../../../components/Administration/Honoa/Form'



export const View = ()=>{
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const { honoa } = useSelector((state) => state.customers);
  const tenants = useSelector((state) => state.tenants);
  const [dataForm, setDataForm] = useState([]);
  const [errorsForm, setErrosForm] = useState({});
  const [formChanged, setFormChanged] = useState(false);
  const customerId = getCookieCustomerId();
  const logoDefault = { base64:  logo1}
  const [dataHonoaLogo1, setDataHonoaLogo1] = useState(
    honoa.find((element) => element.tag === 'HonoaLogo1')? {base64: base64ToImage(honoa?.find((element) => element.tag === 'HonoaLogo1').value)} : logoDefault
  );
  const [dataHonoaLogo2, setDataHonoaLogo2] = useState(
    honoa.find((element) => element.tag === 'HonoaLogo2')? {base64: base64ToImage(honoa?.find((element) => element.tag === 'HonoaLogo2').value)} :  logoDefault
  );

  const setCustomersHonoaAPI = useCallback((id, data) => {
    dispatch(updateCustomerAttributesHonoa(data, id));
  }, []);

  const getCustomersHonoaAPI = useCallback((id) => {
    dispatch(getCustomersHonoa(id));
  }, []);

  useEffect(() => {
    getCustomersHonoaAPI(customerId);
  }, []);

  useEffect(() => {
    setDataForm(honoa);
    setDataHonoaLogo1(honoa?.find((element) => element.tag === 'HonoaLogo1')?.value?  {base64: base64ToImage(honoa?.find((element) => element.tag === 'HonoaLogo1').value)} : logoDefault);
    setDataHonoaLogo2(honoa?.find((element) => element.tag === 'HonoaLogo2')?.value?  {base64: base64ToImage(honoa?.find((element) => element.tag === 'HonoaLogo2').value)} : logoDefault );
  }, [honoa]);

  const handleRemoveLogo = {
    honoaLogo1: ()=>{
      setFormChanged(true);
      setDataHonoaLogo1(logoDefault);
    },
    honoaLogo2: ()=>{
      setFormChanged(true);
      setDataHonoaLogo2(logoDefault);
    }
  };

  const handleChangeLogo = {
    honoaLogo1: (files)=>{
      setFormChanged(true);
      if (files?.[0]?.file) {
        imageFilesToBase64([files?.[0]?.file], true).then((base64File) => {
          setDataHonoaLogo1({ base64:  base64ToImage(base64File[0]) });
        }).catch((errorMessage) => {
          toast.error(errorMessage);
        });
      }
    },
    honoaLogo2: (files)=>{
      setFormChanged(true);
      if (files?.[0]?.file) {
        imageFilesToBase64([files?.[0]?.file], true).then((base64File) => {
          setDataHonoaLogo2({ base64: base64ToImage(base64File[0]) });
        }).catch((errorMessage) => {
          toast.error(errorMessage);
        });
      }
    },
  }
  const handleChange = (name, value) => {
    const newData = [];
    setFormChanged(true);
    dataForm?.map((field) => {
      newData.push({
        type: field.type,
        id: field.id,
        visible: field.visible,
        value: field?.tag === name ? value : field.value,
        tag: field.tag,
        customerId: field.customerId,
      });
    });
    setDataForm(newData);
  };
  const handleSubmit = ()=>{
    setFormChanged(false);
    const dataToSend = dataForm || [];
    if (dataToSend?.find((element) => element.tag === 'HonoaLogo1')) {
      dataToSend.find((element) => element.tag === 'HonoaLogo1').value = dataHonoaLogo1.base64!=logo1? dataHonoaLogo1.base64 : null ;
    }
    if (dataToSend?.find((element) => element.tag === 'HonoaLogo2')) {
      dataToSend.find((element) => element.tag === 'HonoaLogo2').value =  dataHonoaLogo2.base64!=logo1? dataHonoaLogo2.base64 : null;
    }
    setCustomersHonoaAPI(customerId, dataToSend);
  };

  const handleBlur = (name) => {
    const validations = {
      HonoaCustomerMail: [
        validationsDictionary.isRequired(dataForm[2]?.value),
        validationsDictionary.isInTextLength(dataForm[2]?.value, 50),
      ],
      HonoaCustomerAddress: [
        validationsDictionary.isRequired(dataForm[0]?.value),
        validationsDictionary.isInTextLength(dataForm[0]?.value, 50),
      ],
      HonoaCustomerCity: [
        validationsDictionary.isRequired(dataForm[1]?.value),
        validationsDictionary.isInTextLength(dataForm[1]?.value, 50),
      ],
      HonoaCustomerPhone: [
        validationsDictionary.isRequired(dataForm[3]?.value),
        validationsDictionary.isInTextLength(dataForm[3]?.value, 25),
      ],
      HonoaCustomerWeb: [
        validationsDictionary.isRequired(dataForm[4]?.value),
        validationsDictionary.isInTextLength(dataForm[4]?.value, 50),
      ],
    };

    setErrosForm((prev) => ({
      ...prev,
      [name]: getErrors(validations[name]) || false,
    }));
  };

  return (
    <>
        <Flex padding={2} flexWrap='wrap'>
          <Prompt when={formChanged} message={() => `${t('general.mayNotSaved')}`} />
          <HonoaForm
            dataForm={dataForm}
            errorsForm={errorsForm}
            handleBlur={handleBlur}
            handleChange={handleChange}
            handleChangeLogo={handleChangeLogo}
            handleRemoveLogo={handleRemoveLogo}
            dataHonoaLogo1={dataHonoaLogo1}
            dataHonoaLogo2={dataHonoaLogo2}
            logo={logo1}
          />
          <HonoaPreview
            dataForm={dataForm}
            dataHonoaLogo={dataHonoaLogo1}
            logo={logo1} />
        </Flex>
        <Flex justifyContent='flex-end' padding={2}>
          <Button
            backgroundColor={tenants?.color1}
            sizeButton='small'
            id='entry'
            disabled={
              dataForm.filter((element) => {
                if (element.tag !== 'HonoaLogo1' && element.tag !== 'HonoaLogo2')
                  return element.value === '';
              }).length ||
              Object.entries(errorsForm).filter((el) => el[1].error).length ||
              false
            }
            type='submit'
            action={(e) => handleSubmit(e)}
            marginRight={1}
            color={getContrastColor(tenants?.color1)}
          >
            {t('general.save')}
          </Button>
          <Button
            backgroundColor='red'
            sizeButton='small'
            id='cancel'
            action={() => history.goBack()}
          >
            {t('general.back')}
          </Button>
        </Flex>

    </>
  )
}
