import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import Popup from '../Popup/Popup';
import ContentContainer from '../ContentContainer/ContentContainer';
import { resetSignalRPopup, resetProductId } from '../../modules/old_to_refact/actions/signalr';
import { resetCheckedFobs } from '../../modules/group/fobs/actions';
import { resetPutFobsError } from '../../modules/old_to_refact/actions/instalations';
import {
  resetPutUGFobsError,
  resetUGCheckedFobs,
} from '../../modules/old_to_refact/actions/universalGroupsFobs';
import './PopUpErrorMessage.css';

class PopUpErrorMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onClose = () => {
    const {
      onClose,
      resetSignalRPopup,
      resetCheckedFobs,
      resetPutFobsError,
      resetPutUGFobsError,
      resetUGCheckedFobs,
      resetProductId,
    } = this.props;
    resetSignalRPopup();
    resetPutFobsError();
    resetUGCheckedFobs();
    resetPutUGFobsError();
    resetCheckedFobs();
    resetProductId();
    onClose();
  };

  render() {
    const { isOpen, t, productId, needTagMessage, message, fromEnroll } = this.props;
    return (
      <Popup isOpen={isOpen} onClose={this.onClose} className=''>
        <ContentContainer
          title={t('fobsSubstitution.somethingWentWrong')}
          title={!fromEnroll ? t('fobsSubstitution.somethingWentWrong') : t('signalRError.11052')}
        >
          <div className='popup-error-container'>
            <div className='popup-left-container'>
              <div className='popup-icon-error'>
                <i id='iconExclamation' className='fas fa-exclamation-triangle' />
              </div>
            </div>
            <div className='popup-right-container'>
              <div className='error-description'>
                {message === 'signalRError.dontMoveTag'
                  ? t('signalRError.dontMoveTag')
                  : productId === 8
                  ? t(`fobsSubstitution.descriptionTAGError`)
                  : t(`fobsSubstitution.descriptionError`)}
              </div>
            </div>
          </div>
        </ContentContainer>
      </Popup>
    );
  }
}

const mapStateToProps = (state) => ({
  tenants: state.tenants,
  showLoading: state.loading.show,
  message: state.signalR.message,
});

const PopUpErrorMessageWithTranslation = withTranslation()(PopUpErrorMessage);
const PopUpErrorMessageWithHistory = withRouter(PopUpErrorMessageWithTranslation);

export default connect(mapStateToProps, {
  resetSignalRPopup,
  resetUGCheckedFobs,
  resetCheckedFobs,
  resetPutFobsError,
  resetPutUGFobsError,
  resetProductId,
})(PopUpErrorMessageWithHistory);
