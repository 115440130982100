export default {
  instalations: {
    groupsTab: 'Grups',
    equipmentsTab: 'Equips',
    addGroup: 'Afegir nou grup',
    deleteConfirmation: 'Segur que vols suprimir aquesta instal·lació?',
    add: 'Afegir instal·lació',
    config: 'Configurar instal·lació',
    notAllow: 'Els equips assignats no admeten horaris',
    allDevicesConnected: 'Tots connectats',
    allDevicesDisconnected: 'Darrer manteniment:',
    includeReservations: 'Incloure les reserves?',
    includeConfigFields: 'Incloure els camps configurables?',
    acceptExportPopUpButton: 'Exportar',
    exportPopUpTitle: 'Exportar grups',
    nameTableheader: 'Nom',
    allEquipmentsStatusOk: 'Tots els dispositius connectats',
    numberOfConnectedDevices: 'Dispositius trobats',
    filters: 'Filtres',
    criticalDeleteTitle: 'ACCIÓ CRÍTICA IRREVERSIBLE',
    firstAreYouSureCriticalDelete: 'Segur que vols eliminar',
    secondAreYouSureCriticalDelete: '',
    noGoBack: 'AQUESTA ACCIÓ ÉS IRREVERSIBLE',
    transfer: 'Transferir',
    transferPopUpLabel: 'Administrador que gestionarà la instal·lació',
    transferPopUpSelector: 'Selecciona usuari',
    restore: 'Restaurar',
    recycleBin: 'Paperera',
    creation: 'Creació',
    saveMemory: 'Desar memòria',
    substitution: 'Substitució',
    fob: 'Fob',
    equipment: 'Equip',
    deletion: 'Esborrar',
    moved: 'Mogut',
    locked: 'Bloquejat',
    unlocked: 'Desbloquejat',
  },
};
