import {
  GET_STATISTICS
} from './constants';

/**
 * Sets the form state
 * @param  {int} value                The value for show loading
 * @return {object}                   Formatted action for the reducer to handle
 */

export const getStatistics = () => ({ type: GET_STATISTICS.BASE });
