export default {
  instalations: {
    groupsTab: 'Gruppi',
    equipmentsTab: 'apparecchiature',
    addGroup: 'Aggiungi un nuovo gruppo',
    deleteConfirmation: 'Sei sicuro di voler rimuovere questa funzione?',
    add: 'Aggiungi installazione',
    config: "Configura l'installazione",
    notAllow: ' I dispositivi assegnati non consentono le pianificazioni',
    allDevicesConnected: 'Tutti collegati',
    allDevicesDisconnected: 'Ultima mantinenza:',
    includeReservations: 'Includere prenotazioni?',
    includeConfigFields: 'Includi campi di configurazione?',
    acceptExportPopUpButton: 'Esportare',
    exportPopUpTitle: 'Esporta gruppi',
    nameTableheader: 'Nome',
    allEquipmentsStatusOk: 'Tutti i dispositivi collegati',
    numberOfConnectedDevices: 'Dispositivi trovati',
    filters: 'Filtri',
    criticalDeleteTitle: 'AZIONE CRITICA IRREVERSIBILE',
    firstAreYouSureCriticalDelete: 'Sei sicuro di voler cancellare ',
    secondAreYouSureCriticalDelete: '',
    noGoBack: 'QUESTA AZIONE È IRREVERSIBILE ',
    transfer: 'Trasferisci',
    transferPopUpLabel: "Amministratore che gestirà l'installazione",
    transferPopUpSelector: 'Seleziona utente',
    restore: 'Ristabilire',
    recycleBin: 'Cestino',
    creation: 'Creazione',
    saveMemory: 'Salva memoria',
    substitution: 'Sostituzione',
    fob: 'Emettitore',
    equipment: 'Apparecchiature',
    deletion: 'Cancellazione',
    moved: 'Mosso',
    locked: 'Bloccato',
    unlocked: 'Sbloccato'
  },
};
