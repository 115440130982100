export default {
  equipments: {
    add: 'Afegir equip',
    createEquipment: 'Crear equip',
    deleteConfirmation: 'Segur que vols suprimir aquest equip?',
    facilityName: 'Instal·lació',
    productName: 'Producte',
    parameters: 'Paràmetres',
    relays: 'Relés',
    fobs: 'Emissors',
    groups: 'Grups',
    info: 'Informació',
    actions: 'Accions',
    events: 'Esdeveniments',
    'remote comm': 'Comunicacions remotes',
    changePasswordAction: 'Canviar contrasenya',
    adjustDateTimeAction: 'Ajustar data i hora',
    formatMemory: 'Formatar memòria',
    disconnectedKey: 'Desconnectat',
    connectedKey: 'Connectat',
    notFoundKey: 'Dispositiu no trobat',
    saveConfiguration: 'Desar configuració',
    compareMemory: 'Comparar memòria',
    savedMemory: 'Memòria guardada',
    sendMemoryAction: 'Actualitzar memòria localment',
    saveMemoryAction: 'Actualitzar memòria remotament',
    verifyConnectivity: 'Verificar connectivitat',
    getdoorstatus: "Obtenir l'estat de la porta",
    device: 'Dispositiu',
    deleteEventsConfirmation: 'Segur que vols esborrar els events?',
  },
};
