import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getFacilityLogs } from '../../../modules/old_to_refact/actions/instalations';
import LogsBox from './Box';
import { getCookieFacilitiesSearch } from '../../../core/old_common/utils/cookiesManager';

const LogsTab = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const getLogsAPI = useCallback(() => dispatch(getFacilityLogs(id)), []);

  useEffect(() => {
      getLogsAPI();
  }, []);

  const getLastSearchValue = () => {
    const lastSearch = getCookieFacilitiesSearch();

    return lastSearch?.value;
  };

  return <LogsBox lastSearchValue={getLastSearchValue()} />;
};

export default LogsTab;
