import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { generatePath, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import GenericList from '../GenericList';
import {
  getEquipmentList,
  resetEquipment,
  resetEquipments,
  resetEquipmentsEvents,
} from '../../modules/old_to_refact/actions/equipments';
import { resetFamilyProducts } from '../../modules/productFamilies/action';
import { AddButton } from '../AddButton/AddButton';
import { CreationPath_Equipments } from '../../core/old_common/constats/genericListPaths';
import EquipmentsGrow from '../EquipmentsGrow/EquipmentsGrow';

import './EquipmentsList.css';
import { createSignalRConnectionOnReducer } from '../../core/old_common/utils/signalRService';
import { EQUIPMENT_LIST } from '../../modules/state/pagination/constants';

export class EquipmentsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      needCallEquipmentsInclude: true,
      warningShowed: false,
    };
  }

  componentDidMount = () => {
    const { resetEquipment, resetEquipmentsEvents, equipments } = this.props;
    this.getEquipmentList();
    resetEquipment();
    resetEquipmentsEvents();
    createSignalRConnectionOnReducer();
  };

  componentDidUpdate = (prevoProps) => {
    const { needCallEquipments, equipments, t } = this.props;
    const equipmentWarning = [];
    let disabledItem;
    let corruptedItem;
    if (needCallEquipments && needCallEquipments === undefined && needCallEquipmentsInclude) {
      this.getEquipmentList();
      this.setState({ needCallEquipmentsInclude: false });
    }

    if (
      (prevoProps.equipments?.length === 0 || prevoProps.equipments === undefined) &&
      equipments &&
      !this.state.warningShowed
    ) {
      const equipment = equipments.forEach((equipment) => {
        equipment.equipmentConfiguration?.warnings?.forEach((warning) => {
          const splittedWarn = warning.split('::');
          equipmentWarning.push({ code: splittedWarn[0], equipment: splittedWarn[2] });
        });
      });
      equipmentWarning.map((item) => {
        if (item.code === '91069') {
          disabledItem = `${
            disabledItem === undefined
              ? `${item.equipment}`
              : disabledItem.includes(item.equipment)
              ? `${disabledItem}`
              : `${disabledItem}, ${item.equipment}`
          }`;
        }
        if (item.code === '91011') {
          corruptedItem = `${
            corruptedItem === undefined
              ? `${item.equipment}`
              : corruptedItem.includes(item.equipment)
              ? `${corruptedItem}`
              : `${corruptedItem}, ${item.equipment}`
          }`;
        }
      });
      if (disabledItem !== undefined) {
        toast.warn(`${t('errors.91069')}: ${disabledItem}.`);
      }
      if (corruptedItem !== undefined) {
        toast.warn(`${t('errors.91011')}: ${corruptedItem}.`);
      }
      this.setState({ warningShowed: true });
    }
  };

  getEquipmentList = () => {
    const { instalationId, equipments, getEquipmentList, isUpdatedEquipment } = this.props;
    if (instalationId || isUpdatedEquipment === 1) {
      getEquipmentList(instalationId);
    }
  };

  getBaseURI = (decodedId) => {
    const { match } = this.props;
    const encoded = generatePath(`${match.path}/${CreationPath_Equipments}`, {
      id: encodeURIComponent(decodedId),
    });

    return encoded;
  };

  getEquipmentsWithoutWarnings = () => {
    const { equipments } = this.props;
    if (equipments) {
      return equipments.filter((equipment) => !equipment.equipmentConfiguration?.warnings);
    }
  };

  render() {
    const { baseURI, equipments, t, tenants, history, match } = this.props;
    return (
      <span className='equipments-list'>
        <GenericList
          dataList={this.getEquipmentsWithoutWarnings()}
          CustomGrow={(props) => <EquipmentsGrow {...props} canClone isSaveConfigVisible />}
          baseURI={baseURI}
          creationURI={CreationPath_Equipments}
          listType={EQUIPMENT_LIST}
          isPaginable={false}
          getList={this.getEquipmentList}
        />
        <AddButton
          id='add'
          tenants={tenants}
          toolTip={t('equipments.add')}
          history={history}
          url={`${this.getBaseURI(decodeURIComponent(match.params.id))}`}
        />
      </span>
    );
  }
}

const mapStateToProps = (state) => ({
  tenants: state.tenants,
  equipments: state.equipments.equipments,
  needCallEquipments: state.equipments.needCallEquipments,
  isUpdatedEquipment: state.equipments.isUpdated,
});

const EquipmentsListPage = withTranslation()(EquipmentsList);
const EquipmentsListWithHistory = withRouter(EquipmentsListPage);

export default connect(mapStateToProps, {
  getEquipmentList,
  resetEquipment,
  resetFamilyProducts,
  resetEquipments,
  resetEquipmentsEvents,
})(EquipmentsListWithHistory);
