import {
  POST_GROUP_FOBS_FOB,
  POST_GROUP_FOBS_SEQUENTIAL,
  PUT_GROUP_FOBS_LIST_MOVE,
  POST_GROUP_FOBS_LIST_COPY,
  DELETE_GROUP_FOBS_LIST,
  RESET_GROUP_FOBS,
  PUT_GROUP_FOBS_GRID,
  DELETE_GROUP_FOBS_FOB,
  RESET_GROUP_FOBS_IS_POST_FOB,
  POST_GROUP_FOBS_LIST,
  PUT_GROUP_FOBS_IMPORT,
  DELETE_GROUP_FOBS_COMPARE_MEMORY_LIST,
  RESET_GROUP_FOBS_CHECKED_FOBS,
  RESET_GROUP_FOBS_TABLE_FOBS,
  POST_GROUP_FOBS_COMPARE_MEMORY_LIST,
  GET_GROUP_FOBS_PDF,
  GET_GROUP_FOBS_CHECK_CODES,
} from './constants';

export const postFOB = (fob) => ({ type: POST_GROUP_FOBS_FOB.BASE, fob });
export const putMoveFobList = (
  facilityId,
  toFacilityId,
  groupId,
  toGroupId,
  fobList,
  groupName
) => ({
  type: PUT_GROUP_FOBS_LIST_MOVE.BASE,
  facilityId,
  toFacilityId,
  groupId,
  toGroupId,
  fobList,
  groupName,
});
export const postCopyFobList = (facilityId, toFacilityId, groupId, toGroupId, fobList) => ({
  type: POST_GROUP_FOBS_LIST_COPY.BASE,
  facilityId,
  toFacilityId,
  groupId,
  toGroupId,
  fobList,
});
export const deleteFobList = (facilityId, groupId, fobList) => ({
  type: DELETE_GROUP_FOBS_LIST.BASE,
  facilityId,
  groupId,
  fobList,
});
export const deleteFob = (fob) => ({ type: DELETE_GROUP_FOBS_FOB.BASE, fob });
export const postSequentialFOB = (payload) => ({ type: POST_GROUP_FOBS_SEQUENTIAL.BASE, payload });
export const resetIsSuccess = () => ({ type: RESET_GROUP_FOBS });
export const putFob = (fob) => ({ type: PUT_GROUP_FOBS_GRID.BASE, fob });
export const resetIsPostFobSuccess = () => ({ type: RESET_GROUP_FOBS_IS_POST_FOB });
export const postFOBList = (facilityId, groupId, fobList) => ({
  type: POST_GROUP_FOBS_LIST.BASE,
  facilityId,
  groupId,
  fobList,
});
export const importFobs = (facilityId, id, files) => ({
  type: PUT_GROUP_FOBS_IMPORT.BASE,
  facilityId,
  id,
  files,
});
export const deleteCompareMemoryFobs = (facilityId, groupId, fobList) => ({
  type: DELETE_GROUP_FOBS_COMPARE_MEMORY_LIST.BASE,
  facilityId,
  groupId,
  fobList,
});
export const postCompareMemoryFOBList = (facilityId, groupId, fobList) => ({
  type: POST_GROUP_FOBS_COMPARE_MEMORY_LIST.BASE,
  facilityId,
  groupId,
  fobList,
});

export const fobsGetCheckCodes = (facilityId, equipmentId, initialCode, count) => ({
  type: GET_GROUP_FOBS_CHECK_CODES.BASE,
  facilityId,
  equipmentId,
  initialCode,
  count,
});

export const resetCheckedFobs = () => ({
  type: RESET_GROUP_FOBS_CHECKED_FOBS,
});

export const resetNeedUpdateTableFobs = () => ({
  type: RESET_GROUP_FOBS_TABLE_FOBS,
});

export const getPDFFobs = (facilityId, groupId, configFields) => ({
  type: GET_GROUP_FOBS_PDF.BASE,
  facilityId,
  groupId,
  configFields,
});
