import { Flex, Grid, GridItem } from '@jcm-technologies/uikit/dist/atoms/Layout';
import { Card } from '@jcm-technologies/uikit/dist/molecules/Card';
import { Text } from '@jcm-technologies/uikit/dist/atoms/Typography';
import { Button } from '@jcm-technologies/uikit/dist/atoms/Button';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getContrastColor } from '../../../../core/helpers/colorManager';

export const CardsLicenses = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { color1 } = useSelector((state) => state.tenants);
  const { licenses } = useSelector((state) => state.customers.licenses);

  return (
    <Grid
      gridTemplateColumns={['1fr', '1fr', '1fr 1fr 1fr']}
      gridTemplateAreas={['.', '.', '. . .']}
      gridTemplateRows='1fr'
      gridGap={[2, 2, 4]}
      padding={[1, 1, 0]}
    >
      {licenses?.map((element) => (
        <GridItem height='100%' paddingTop={[1, 1, 3]} paddingBottom={1} key={element.value}>
          <Card rounded='solid' height='100%'>
            <Flex flexDirection='column' height='100%'>
              <Text>{t(`title.${element.value}`)}</Text>
              <Grid
                gridTemplateColumns={['1fr', '1fr', '1fr', '1fr 1fr']}
                gridTemplateAreas={['.', '.', '.', '. .']}
                gridGap={1}
              >
                <Card rounded='solid' marginTop={[1, 1, 3]} marginBottom={1}>
                  <Text>{t(`licenses.availableLicenses`)}</Text>
                  <Text
                    color='green'
                    sizeText={['display24', 'display24', 'display24', 'display48']}
                  >
                    {element.availableLicenses}
                  </Text>
                </Card>
                <Card rounded='solid' marginTop={[1, 1, 3]} marginBottom={1}>
                  <Text>{t('licenses.totalLicenses')}</Text>
                  <Text sizeText={['display24', 'display24', 'display24', 'display48']}>
                    {element.totalLicenses}
                  </Text>
                </Card>
              </Grid>
              {element.value === 'cloudAssistant' && (
                <Flex
                  alignItems='center'
                  justifyContent='flex-end'
                  marginTop={2}
                  selfAlign='flex-end'
                  height='100%'
                >
                  <Button
                    backgroundColor={color1}
                    color={getContrastColor(color1)}
                    action={() => history.push('/administration/licenses/create')}
                  >
                    {t('general.createEntity', { entity: t('title.licenses') })}
                  </Button>
                </Flex>
              )}
            </Flex>
          </Card>
        </GridItem>
      ))}
    </Grid>
  );
};
