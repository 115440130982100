export default {
  paramsName: {
    ACCESSCONTROL: 'Control d\'accés',
    ACTIVEELEMENTS: 'Esdeveniments actius',
    CONFIGRELE1: 'Configuració Relé 1 (Impulsional / Biestable)',
    CONFIGRELE2: 'Configuració Relé 2 (Impulsional / Biestable)',
    INSTAL: 'Instal·lador',
    POWER: 'Alimentació',
    PRICE1: 'Valor de descomptatge del relé 1',
    PRICE2: 'Valor de descomptatge del relé 2',
    RELAYS: 'Relés',
    TEMPRELE1: 'Temporització Relé 1',
    TEMPRELE2: 'Temporització Relé 2',
    TEVENT: 'Targeta d\'esdeveniments',
    TIMEZONE: 'Identificador de zona horària',
    TMEM: 'Targeta de memòria',
    MessageBox_ActivationRelay: 'Activació de relé.',
    MessageBox_ConnectionError: 'Error de connexió.',
    MessageBox_DeviceAccepted: 'Dispositiu acceptat.',
    MessageBox_DeviceRecieved: 'Dispositiu rebut.',
    MessageBox_FormatMemoryError: 'Memòria amb format erroni.',
    MessageBox_InvalidDevice: 'Dispositiu no vàlid.',
    MessageBox_MemoryExtracted: 'Memòria extreta.',
    MessageBox_MemoryInserted: 'Memòria inserida.',
    MessageBox_NotInMemory: 'No present a memòria.',
    MessageBox_OutOfSchedule: 'Fora dhorari.',
    MessageBox_PowerUp: 'Encès.',
    MessageBox_RelayState: 'Canvi d´estat del relé.',
    MessageBox_Message: 'Configurar Esdeveniments actius:',
    CONFIGRELAYSPIN1: 'Relés en programació PIN. Canal:1',
    CONFIGRELAYSPIN2: 'Relés en programació PIN. Canal:2',
    CONFIGRELAYSPIN3: 'Relés en programació PIN. Canal:3',
    CONFIGRELAYSPIN4: 'Relés en programació PIN. Canal:4',
    DISABLEMANUALPROG: 'Prohibir la programació manual',
    DISABLERADIOPROG: 'Prohibir la programació per ràdio',
    GO: 'Go',
    GOPRO: 'Go-Pro',
    HFADVANCE: 'HF-Advance',
    IMPBIRELE1: 'Configurar el relé 1 com a biestable',
    MINIDDEVICE: 'Dispositiu mínim',
    PIN: 'PIN',
    PINCADUCAT: 'PIN caducat',
    CONFIGRELAYSNEW1: 'Relés actius a la central mitjançant canal 1',
    CONFIGRELAYSNEW2: 'Relés actius a la central mitjançant canal 2',
    CONFIGRELAYSNEW3: 'Relés actius a la central mitjançant canal 3',
    CONFIGRELAYSNEW4: 'Relés actius a la central mitjançant canal 4',
    CONFIGRELE3: 'Configuració Relé 3 (Impulsional / Biestable)',
    CONFIGRELE4: 'Configuració Relé 4 (Impulsional / Biestable)',
    TEMPRELE3: 'Temporització Relé 3',
    TEMPRELE4: 'Temporització Relé 4',
    PRICE3: 'Valor de descompte del relé 3',
    PRICE4: 'Valor de descompte del relé 4',
    FREEACTIVE: 'Grup del Sistema FREE actiu',
    FREEGROUP0LOCKED: 'Grup 0 del Sistema FREE bloquejat',
    FREEGROUPNUM: 'Número del grup del Sistema FREE',
    FREEGROUPRFPROGALLOWED: 'Programació via ràdio per a grup del Sistema FREE permès',
    BADGE_MAITRE01: 'Codi mestre 1',
    BADGE_MAITRE10: 'Codi mestre 10',
    BADGE_MAITRE2: 'Codi mestre 2',
    BADGE_MAITRE3: 'Codi mestre 3',
    BADGE_MAITRE4: 'Codi mestre 4',
    BADGE_MAITRE5: 'Codi mestre 5',
    BADGE_MAITRE6: 'Codi mestre 6',
    BADGE_MAITRE7: 'Codi mestre 7',
    BADGE_MAITRE8: 'Codi mestre 8',
    BADGE_MAITRE9: 'Codi mestre 9',
    SITECODE: 'Codi d\'instal·lació',
    W26FORMAT: 'Format Wiegand 26',
    Configuration_Params_W26FORMAT0: 'Codi (16 bits) + Codi d\'instal·lació (8 bits)',
    Configuration_Params_W26FORMAT1: 'Codi (24 bits)',
    CONFIGRELE_ANTIPANIC: 'Antipànic',
    ANTIPANICCONFIGRELE1: 'Configuració Relé 1 (Impulsional / Biestable / Antipànic)',
    ANTIPANICCONFIGRELE2: 'Configuració Relé 2 (Impulsional / Biestable / Antipànic)',
    ANTIPANICCONFIGRELE3: 'Configuració Relé 3 (Impulsional / Biestable / Antipànic)',
    ANTIPANICCONFIGRELE4: 'Configuració Relé 4 (Impulsional / Biestable / Antipànic)',
    RELE1IMP: 'Relé 1 configuració (Impuls/Biestable)',
    RELE1TEMP: 'Relé 1 temporització',
    RELE2IMP: 'Relé 2 configuració (Impuls/Biestable)',
    RELE2TEMP: 'Relé 2 Temporització',
    RELE3IMP: 'Relé 3 configuració (Impuls/Biestable)',
    RELE3TEMP: 'Relé 3 temporització',
    RELE4IMP: 'Relé 4 configuració (Impuls/Biestable)',
    RELE4TEMP: 'Relé 4 temporització',
    RELAYPRICE1: 'Valor de descompte del relé 1',
    RELAYPRICE2: 'Valor de descompte del relé 2',
    RELAYPRICE3: 'Valor de descompte del relé 3',
    RELAYPRICE4: 'Valor de descompte del relé 4',
    PASSWORD: 'Password',
    CONNECTIVITY_EXTENSION: 'Accessori LINK instal·lat',
    ACTIVATIONKEY: 'Clau d\'activació',
    UNIQUEKEY: 'Clau única',
    TIMEZONEID: 'Identificador de zona horària',
    EMMITTERNOTUSEDEVENTTIME: 'No utilitzeu l\'esdeveniment temps de l\'emissor',
    DOOROPENEDEVENTTIME: 'Esdeveniment d\'obertura de porta',
    ACTIVEEVENTSTOSD: 'Configura d\'esdeveniments del A5K',
    SCHEDULESDATA: 'Dades horàries',
    APNCONFIG: 'Configuració de l\'APN',
    A5KPROXY: 'Configureu proxy A5K',
    Configuration_Params_NoFixed: 'No fixat',
    Configuration_Params_GO: 'GO',
    Configuration_Params_GOPRO: 'GO-PRO',
    Configuration_Params_HFADVANCE: 'HF-Advance',
    Configuration_Params_ConfigRele_Impulsional: 'Impulsional',
    Configuration_Params_ConfigRele_Biestable: 'Biestable',
    Configuration_Params_ConfigRele_Antipanic: 'Antipànic',
    Configuration_Params_Yes: 'Sí',
    Configuration_Params_No: 'No',
    Configuration_Params_PIN: 'PIN',
    Configuration_Params_PINCADUCAT: 'PIN caducat',
    Configuration_Params_ValueTEVENTS: 'Sí',
    Configuration_Params_BUTT_NO: 'Botó normalment obert',
    Sip_Address: 'Adreça SIP',
    Sip_Status: 'Estat connexió SIP',
    fw_version: 'Versió de microprogramari',
    hw_version: 'Versió de maquinari',
    mac_address: 'Direcció MAC',
    imei: 'Numero IMEI',
    iccid: 'Número ICCID',
    connection_type: 'Tipus de connexió',
    level: 'Nivell de senyal',
    dbm: 'Decibels',
    mcc: 'MCC',
    mnc: 'MNC',
    lac: 'LAC',
    call_max_duration: 'Màxima durada de la trucada',
    call_outgoing_timeout: 'Temps excedit per a la trucada sortint',
    relay_dtmf_hang_call: 'Activació de relé per marcació de to',
    Relay_Sound: 'Idioma del missatge de porta oberta',
    mic_volume: 'Volum del micròfon',
    speaker_volume: 'Volum de l\'altaveu',
    access_code: 'Codi d\'accés',
    true: 'Cert',
    false: 'Fals',
    door_english: 'Anglès',
    door_spanish: 'Espanyol',
    door_french: 'Frances',
    none: 'Cap',
    imsi: 'IMSI',
    cell_id: 'Id de cel·la',
    Configuration_Params_LOW: 'Baix',
    Configuration_Params_HIGH: 'Alt',
    Configuration_Params_DEBUG: 'Depuració',
    Configuration_Params_UNUSED: 'No utilitzat',
    Configuration_Params_NCOLS: '[Porta 1] Final de carrera d\'obertura (Normalment Tancat)',
    Configuration_Params_NCCLS: '[Porta 1] Final de carrera de tancament (Normalment Tancat)',
    Configuration_Params_NOOLS: '[Porta 1] Final de carrera d\'obertura (Normalment Obert)',
    Configuration_Params_NOCLS: '[Porta 1] Final de carrera de tancament (Normalment Obert)',
    Configuration_Params_NCSC: 'Cadena de seguretat (Normalment Tancat)',
    Configuration_Params_NOSC: 'Cadena de seguretat (Normalment Obert)',
    Configuration_Params_entranceNC: 'APB entrada (Normalment Tancat)',
    Configuration_Params_exitNC: 'APB sortida (Normalment Tancat)',
    Configuration_Params_entranceNO: 'APB entrada (Normalment Obert)',
    Configuration_Params_exitNO: 'APB sortida (Normalment Obert)',
    Configuration_Params_D2_NCOLS: '[Porta 2] Final de carrera obertura (Normalment Tancat)',
    Configuration_Params_D2_NCCLS: '[Porta 2] Final de carrera tancament (Normalment Tancat)',
    Configuration_Params_D2_NOOLS: '[Porta 2] Final de carrera obertura (Normalment Obert)',
    Configuration_Params_D2_NOCLS: '[Porta 2] Final de carrera tancament (Normalment Obert)',
    Configuration_Params_NSCLS: '[Porta 1] Sensor de final de carrera d\'obertura',
    Configuration_Params_NSOLS: '[Porta 1] Sensor de final de carrera de tancament',
    Configuration_Params_D2_HSOLS: '[Porta 2] Sensor de final de carrera d\'obertura',
    Configuration_Params_D2_HSCLS: '[Porta 2] Sensor de final de carrera de tancament',
    Configuration_Params_OPENREMOTE: 'HONOA Permetre obertura remota',
    Configuration_Params_HANDSFREEALLOWED: 'HONOA Permetre mans lliures Bluetooth (BETA)',
    APBKMODE: 'Mode Anti-Passback (APB)',
    Configuration_Params_MODE1: 'Mode 1 (monocanal entrada/sortida, sortida lliure)',
    Configuration_Params_MODE2: 'Mode 2 (monocanal entrada / sortida)',
    Configuration_Params_MODE3: 'Mode 3 (canal 1 entrada /canal 2 sortida)',
    Configuration_Params_MODE4: 'Mode 4 (canal 1 i 3 entrada / canal 2 i 4 sortida)',
    APBKTIMEBACK: 'Temps de reset Anti-Passback en minuts (Anti-timeback)',
    Configuration_Params_HSCLS: '[Porta 1] Sensor de final de carrera de tancament',
    Configuration_Params_HSOLS: '[Porta 1] Sensor de final de carrera d\'obertura',
    Configuration_Params_MIN: 'Mínim',
    DATAUSAGE: 'Ús de dades',
    INPUTS: 'Entrades',
    INPUT1NAME: 'Nom entrada 1',
    INPUT2NAME: 'Nom entrada 2',
    INPUT3NAME: 'Nom sensor ràdio 3',
    INPUT4NAME: 'Nom sensor ràdio 4',
    INPUT5NAME: 'Nom sensor ràdio 5',
    INPUT6NAME: 'Nom sensor ràdio 6',
    INPUT7NAME: 'Nom sensor ràdio 7',
    INPUT8NAME: 'Nom sensor ràdio 8',
    INPUT9NAME: 'Nom sensor ràdio 9',
    INPUT10NAME: 'Nom sensor ràdio 10',
    INPUT1MODE: 'Mode entrada 1',
    INPUT2MODE: 'Mode entrada 2',
    INPUT3MODE: 'Mode sensor ràdio 3',
    INPUT4MODE: 'Mode sensor ràdio 4',
    INPUT5MODE: 'Mode sensor ràdio 5',
    INPUT6MODE: 'Mode sensor ràdio 6',
    INPUT7MODE: 'Mode sensor ràdio 7',
    INPUT8MODE: 'Mode sensor ràdio 8',
    INPUT9MODE: 'Mode sensor ràdio 9',
    INPUT10MODE: 'Mode sensor ràdio 10',
    INPUT1KEY: 'Clau entrada 1',
    INPUT2KEY: 'Clau entrada 2',
    INPUT3KEY: 'Clau sensor ràdio 3',
    INPUT4KEY: 'Clau sensor ràdio 4',
    INPUT5KEY: 'Clau sensor ràdio 5',
    INPUT6KEY: 'Clau sensor ràdio 6',
    INPUT7KEY: 'Clau sensor ràdio 7',
    INPUT8KEY: 'Clau sensor ràdio 8',
    INPUT9KEY: 'Clau sensor ràdio 9',
    INPUT10KEY: 'Clau sensor ràdio 10',
    SENSORS: 'Sensors',
    incorrectVertexKey: 'El codi introduït no és vàlid',
    correctVertexKey: 'El codi introduït és vàlid',
    OPENREMOTE: 'Permetre obertura remota per a HONOA',
    HANDSFREEALLOWED: 'Permetre mans lliures Bluetooth (BETA) per a HONOA',
    Name: 'Nom',
    UniqueIdentity: 'Identificador únic',
    Product: 'Dispositiu',
    SerialNumber: 'Número de sèrie',
    WIEGANDCONFIG: 'Configuració Wiegand',
  },
};
