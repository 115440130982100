export default {
  equipmentErrors: {
    2: 'ERR_CL_IN',
    3: 'ERR_INS_CL_0',
    4: 'ERR_INS_DEV',
    5: 'Chyba: prázdná paměť',
    6: 'Chyba dekódování',
    8: 'Chyba hardwaru',
    9: 'Chyba čtení',
    ERR_MD6: {
      7: 'ERR_MD5',
    },
  },
};
