export default {
  instalations: {
    groupsTab: 'Benutzergruppen',
    equipmentsTab: 'Geräte',
    addGroup: 'Neue Gruppe hinzufügen',
    deleteConfirmation: 'Möchten Sie diese Funktion wirklich entfernen?',
    add: 'Installation hinzufügen',
    config: 'Konfigurieren Sie die Installation',
    notAllow: ' Die zugewiesenen Geräte erlauben keine Zeitpläne',
    allDevicesConnected: 'Alle verbunden',
    allDevicesDisconnected: 'Letzte Mantinenz:',
    includeReservations: 'Reservierungen einschließen?',
    includeConfigFields: 'Konfigurationsfelder einschließen?',
    acceptExportPopUpButton: 'Export',
    exportPopUpTitle: 'Gruppen exportieren',
    nameTableheader: 'Name',
    allEquipmentsStatusOk: 'Alle Geräte angeschlossen',
    numberOfConnectedDevices: 'Geräte gefunden',
    filters: 'Filter',
    criticalDeleteTitle: 'IRREVERSIBLE KRITISCHE AKTION',
    firstAreYouSureCriticalDelete: 'Sicher, dass Sie ',
    secondAreYouSureCriticalDelete: ' löschen möchten',
    noGoBack: 'DIESE AKTION IST IRREVERSIBEL',
    transfer: 'Übertragen',
    transferPopUpLabel: 'Administrator, der die Anlage verwaltet',
    transferPopUpSelector: 'Wählen Sie einen Benutzer',
    restore: 'Wiederherstellen',
    recycleBin: 'Papierkorb',
    creation: 'Erstellung',
    saveMemory: 'Speicher speichern',
    substitution: 'Auswechslung',
    fob: 'Sender',
    equipment: 'Gerät',
    deletion: 'Streichung',
    moved: 'Gerührt',
    locked: 'Gesperrt',
    unlocked: 'Entsperrt'
  },
};
