export default {
  enrollCode: {
    manualTab: 'Ruční',
    reservationsTab: 'Rezervace',
    sequentialTab: 'Hromadně',
    universalsTab: 'Univerzální',
    device: 'Zařízení',
    initialCode: 'Počáteční kód',
    idCard: 'DNI',
    spot: 'Slot',
    numberOfFobs: 'Počet ovladačů',
    endCode: 'Konečný kód',
    cantEnroll: 'Pro zapsání nových kódů musí být vybráno zařízení a dostupné pozice',
    cancel: 'Zrušit',
    accept: 'Přijmout',
    ok: 'OK',
    forceCodeQuestion: 'Chceš si vynutit registraci tohoto kódu?',
    codeAlreadyExist: 'Tento kód již existuje v jiné skupině',
    notAsigned: 'Rezervováno',
    reserved: 'Rezervováno',
  },
};
