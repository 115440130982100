const POST_DRIVER_MEMORY_CONFIG = 'GET_DRIVER_MEMORY_CONFIG';
const RESET_DRIVER_MEMORY_CONFIG = 'RESET_DRIVER_MEMORY_CONFIG';
const POST_DRIVER_MEMORY_CODES = 'GET_DRIVER_MEMORY_CODES';
const POST_DRIVER_COMPARE = 'GET_DRIVER_COMPARE';
const POST_DRIVER_EVENTS = 'POST_DRIVER_EVENTS';
const RESET_DRIVER_COMPARE = 'RESET_DRIVER_COMPARE';
const POST_DRIVER_SEND_MEMORY_CODES = 'POST_DRIVER_SEND_MEMORY_CODES';
const GET_DRIVER_PING_DEVICE = 'GET_DRIVER_PING_DEVICE';
const RESET_DRIVER_EVENTS = 'RESET_DRIVER_EVENTS';
const RESET_DRIVER_ASYNC_RESPONSE = 'RESET_DRIVER_ASYNC_RESPONSE';

export default {
  POST_DRIVER_MEMORY_CONFIG,
  POST_DRIVER_MEMORY_CODES,
  POST_DRIVER_COMPARE,
  RESET_DRIVER_MEMORY_CONFIG,
  POST_DRIVER_EVENTS,
  RESET_DRIVER_COMPARE,
  POST_DRIVER_SEND_MEMORY_CODES,
  GET_DRIVER_PING_DEVICE,
  RESET_DRIVER_EVENTS,
  RESET_DRIVER_ASYNC_RESPONSE,
};

export const getMemoryConfig = (id, facilityId) => ({
  type: POST_DRIVER_MEMORY_CONFIG,
  id,
  facilityId,
});
export const getMemoryCodes = (id, facilityId) => ({
  type: POST_DRIVER_MEMORY_CODES,
  id,
  facilityId,
});
export const getMemoryCompare = (id, facilityId, binaryData) => ({
  type: POST_DRIVER_COMPARE,
  id,
  facilityId,
  binaryData,
});
export const resetMemoryConfig = () => ({ type: RESET_DRIVER_MEMORY_CONFIG });
export const getDriverEvents = (facilityId, id, file) => ({
  type: POST_DRIVER_EVENTS,
  facilityId,
  id,
  file,
});
export const resetCompareMemory = () => ({ type: RESET_DRIVER_COMPARE });
export const sendMemoryCodes = (facilityId, id, ic, cc, signalRConnId) => ({
  type: POST_DRIVER_SEND_MEMORY_CODES,
  facilityId,
  id,
  ic,
  cc,
  signalRConnId,
});
export const getDevicePing = (id, facilityId, connectionId, productType) => ({
  type: GET_DRIVER_PING_DEVICE,
  id,
  facilityId,
  connectionId,
  productType,
});
export const resetEvents = () => ({ type: RESET_DRIVER_EVENTS });
export const resetDriversAsyncResponse = () => ({ type: RESET_DRIVER_ASYNC_RESPONSE });
