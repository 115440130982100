import Version_DEFAULT from '../../../../static/dist/docs/VersionInfo/Features_default.json';
import Version_ES from '../../../../static/dist/docs/VersionInfo/Features_ES.json';
import Version_EN from '../../../../static/dist/docs/VersionInfo/Features_EN.json';
import Version_DE from '../../../../static/dist/docs/VersionInfo/Features_DE.json';
import Version_FR from '../../../../static/dist/docs/VersionInfo/Features_FR.json';
import Version_IT from '../../../../static/dist/docs/VersionInfo/Features_IT.json';
import Version_CS from '../../../../static/dist/docs/VersionInfo/Features_CS.json';
import Version_HU from '../../../../static/dist/docs/VersionInfo/Features_HU.json';

const VersionDictionary = [];
VersionDictionary.default = Version_DEFAULT;
VersionDictionary['es-ES'] = Version_ES;
VersionDictionary['ca-ES'] = Version_ES;
VersionDictionary['en-GB'] = Version_EN;
VersionDictionary['de-DE'] = Version_DE;
VersionDictionary['fr-FR'] = Version_FR;
VersionDictionary['it-IT'] = Version_IT;
VersionDictionary['cs-CZ'] = Version_CS;
VersionDictionary['hu-HU'] = Version_HU;

export default VersionDictionary;
