import PathEntity from '../../core/old_common/rooting/apiPaths/PathEntity';
import { apiVersion, mainPath } from '../../core/old_common/rooting/common';

class Routes extends PathEntity {
  constructor() {
    super('schedules');
  }

  getList = (
    pageSize,
    pageNumber,
    { selectedDescendants, selectedCities, selectedPostalCodes, searchQuery }
  ) => {
    this.mainPath = `${mainPath()}4.1`;
    let finalEndpoint = this.getEndPointWithVisibleDescendants(
      this.entityName,
      selectedDescendants,
      'CustomerIds'
    );
    finalEndpoint = this.getEndPointWithVisibleDescendants(finalEndpoint, selectedCities, 'City');
    finalEndpoint = this.getEndPointWithVisibleDescendants(
      finalEndpoint,
      selectedPostalCodes,
      'ZIP'
    );
    finalEndpoint = this.getEndPointWithVisibleDescendants(finalEndpoint, searchQuery, 'queryText');
    finalEndpoint = this.manufacturePath(finalEndpoint);
    this.mainPath = `${mainPath()}${apiVersion}`;
    return this.pagedEndPoint(finalEndpoint, pageSize, pageNumber);
  };

  get = (id) => this.manufacturePath(`${this.entityName}/${id}`);

  post = () => this.manufacturePath(this.entityName);

  put = (id) => this.manufacturePath(`${this.entityName}/${id}`);

  delete = (id) => this.manufacturePath(`${this.entityName}/${id}`);

  getSingleList = (facilityId) =>
    this.manufacturePath(`${this.entityName}/facilities/${facilityId}/singlelist`);

  getAssignedFacilities = (id) =>
    this.manufacturePath(`${this.entityName}/${id}/assignedfacilities`);

  checkContent = (columnName, value) => {
    let finalEndpoint = this.manufacturePath(`${this.entityName}/checkcontent`);
    finalEndpoint = this.addParameterToEndpoint(finalEndpoint, 'columnName', columnName);
    finalEndpoint = this.addParameterToEndpoint(finalEndpoint, 'value', value);
    return finalEndpoint;
  };

  getPaths = () => ({
    schedulesGetPage: this.getList,
    scheduleGet: this.get,
    schedulesPost: this.post,
    schedulesPut: this.put,
    schedulesDelete: this.delete,
    schedulesSingleListGetPage: this.getSingleList,
    schedulesGetAssignedFacilities: this.getAssignedFacilities,
    schedulesGetCheckContent: this.checkContent,
  });
}

export default new Routes().getPaths();
