import PathEntity from './PathEntity.js';

class Accounts extends PathEntity {
  constructor() {
    super('accounts');
  }

  login = () => this.manufacturePath(`${this.entityName}/login`);

  reLogin = (refreshToken) =>
    this.manufacturePath(`${this.entityName}/login?refreshToken=${refreshToken}`);

  resetPassword = () => this.manufacturePath(`${this.entityName}/password/reset`);

  changePassword = () => this.manufacturePath(`${this.entityName}/password/change`);

  versionAccountPost = (version) => {
    let endpoint = this.manufacturePath(`${this.entityName}/infoversion`);
    endpoint = this.addParameterToEndpoint(endpoint, 'hideinfoversion', version);
    return endpoint;
  };

  getPaths = () => ({
    accountsLogin: this.login,
    accountsReLogin: this.reLogin,
    accountsResetPassword: this.resetPassword,
    accountsChangePassword: this.changePassword,
    loginVersionAccountPost: this.versionAccountPost,
  });
}

export default new Accounts().getPaths();
