export default {
  gdprPopup: {
    accept: 'Ok',
    cancel: 'Abbrechen',
    conditionsAgreement: 'Nutzungsbedingungen akzeptieren',
    close: 'Schließen',
    firstPolicyAgreementText:
      'CloudAssistant wird ständig weiterentwickelt, um Ihnen bessere Dienste zu bieten und neue Funktionen aufzunehmen, wie z. B. die neue Rolle des Property Managers, die es Ihnen ermöglicht, die Verwaltung der Fernbedienungen an Hausverwalter abzugeben, die ihren Eigentümergemeinschaften einen kundennäheren Service bieten möchten.',
    firstPolicyAgreementSecondText:
      'Wir haben unsere Richtlinien geändert, um diese neuen Dienste aufnehmen zu können.',
    firstPolicyAgreementThirdText: 'Für alle Fragen können Sie sich gerne an uns wenden unter',
    privacyText: 'Datenschutzerklärung',
    legalNoticeText: 'Rechtlicher Hinweis',
    cookiesText: 'Cookie-Richtlinie',
    conditionsText: 'Allgemeine Bedingungen',
    PolicyAgreementPopupTitle1: 'CloudAssistant aktualisiert sich',
    PolicyAgreementPopupTitle2: 'neue Dienste und neue Richtlinien',
    PolicyAgreementPopupAnd: 'und',
  },
};
