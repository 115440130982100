export default {
  formValidation: {
    notSameValue: "N'est pas la même valeur",
    notSamePassword: 'Les mots de passe ne correspondent pas',
    numericField: 'Ce champ est numérique',
    minNumber: 'La valeur minimale est 1',
    passwordFormat:
      'Le mot de passe doit comprendre au moins huit caractères, au moins une lettre majuscule, une minuscule, un chiffre et un caractère spécial',
    isNotValidDate: 'Format de date incorrect, format: AAAA-MM-JJTHH: mm: ss',
    isRequired: 'Champ obligatoire',
    outOfRange: 'Nombre hors plage',
    maxLength: 'La longueur maximale des caractères est',
    regEx: 'Format incorrect. Utilisez ces caractères',
    deviceLimit: 'Limite de périphérique atteinte',
    onlySpaces: 'Non sono ammessi solo spazi vuoti',
    isReservedWord: "c'est un mot réservé",
  },
};
