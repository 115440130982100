import React, { Component } from 'react';
import Select from 'react-select';
import { withTranslation } from 'react-i18next';
import Search from './components/Search';
import Span from './components/Span';

import './SelectableFilters.css';
import { connect } from 'react-redux';

class SelectableFilters extends Component {
  constructor(props) {
    super(props);
    const { isExpanded } = this.props;
    this.state = {
      isExpanded: isExpanded || false,
      isSubmitCalled: false,
      isKeyPressed: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.searchQuery &&
      !this.props.searchQuery &&
      this.state.isSubmitCalled &&
      !this.state.isKeyPressed
    ) {
      this.setState({ isSubmitCalled: false });
      this.props.onSubmit();
    }
  }

  onKeyDown = () => {
    if (!this.state.isKeyPressed) {
      this.setState({ isKeyPressed: true });
    }
  };

  onKeyUp = () => {
    if (this.state.isKeyPressed) {
      this.setState({ isKeyPressed: false });
    }
  };

  onSubmit = () => {
    this.setState({ isSubmitCalled: true });
    this.props.onSubmit(this.props.searchQuery);
  };

  render() {
    const {
      renderDescendants,
      renderCityFilter,
      renderPostalCodeFilter,
      renderSearch,
      selectedDescendants,
      selectedCities,
      selectedPostalCodes,
      searchQuery,
      descendatsOptions,
      cities,
      postalCodes,
      onChangeDescendants,
      onChangeCities,
      onChangePostalCodes,
      onChangeSearchQuery,
      customStyles,
      tenants,
      t,
      onlySearch,
    } = this.props;
    if (onlySearch) {
      return (
        <Search
          onKeyDown={this.onKeyDown}
          onKeyUp={this.onKeyUp}
          placeholder={t('general.searchDotted')}
          value={searchQuery.replace(/%26/g, '&')}
          onChange={onChangeSearchQuery}
          onSubmit={this.onSubmit}
        />
      );
    }
    return (
      <div className='selectable-filters'>
        <div className='selectable-filters-search-container'>
          {renderDescendants || renderCityFilter || renderPostalCodeFilter || renderSearch ? (
            <Span
              id='filtersExpandButton'
              className='expand-selectable-filters-button'
              tenants={tenants}
              onClick={() => this.setState({ isExpanded: !this.state.isExpanded })}
            >
              {t('instalations.filters')}
              <span
                className={`${
                  this.state.isExpanded ? 'fas fa-angle-up' : 'fas fa-angle-down'
                } expand-selectable-filters-button-angle`}
              />
            </Span>
          ) : null}
        </div>
        {this.state.isExpanded ? (
          <div className='selectable-filters-span'>
            {renderSearch ? (
              <Search
                onKeyDown={this.onKeyDown}
                onKeyUp={this.onKeyUp}
                placeholder={t('general.searchDotted')}
                value={searchQuery?.replace(/%23/g, '#').replace(/%26/g, '&')}
                onChange={(e) => onChangeSearchQuery(e)}
                onSubmit={this.onSubmit}
              />
            ) : (
              ''
            )}
            {renderDescendants ? (
              <Select
                id='descendantsSelectFilter'
                isMulti
                styles={customStyles}
                placeholder={t('topBar.descendants')}
                value={selectedDescendants}
                onChange={(e) => onChangeDescendants(e)}
                options={descendatsOptions}
              />
            ) : (
              ''
            )}
            {renderCityFilter ? (
              <Select
                id='citySelectFilter'
                isMulti
                styles={customStyles}
                placeholder={t('topBar.cities')}
                value={selectedCities}
                onChange={(e) => onChangeCities(e)}
                options={cities}
              />
            ) : (
              ''
            )}
            {renderPostalCodeFilter ? (
              <Select
                id='postalCodeSelectFilter'
                isMulti
                styles={customStyles}
                placeholder={t('topBar.zip')}
                value={selectedPostalCodes}
                onChange={(e) => onChangePostalCodes(e)}
                options={postalCodes}
              />
            ) : (
              ''
            )}
          </div>
        ) : null}
      </div>
    );
  }
}

const SelectableFiltersWithTranslation = withTranslation()(SelectableFilters);

const mapStateToProps = (state) => ({
  tenants: state.tenants,
  searchQuery: state.visibleDescendants.searchQuery,
});

export default connect(mapStateToProps, {})(SelectableFiltersWithTranslation);
