import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Flex } from '@jcm-technologies/uikit/dist/atoms/Layout';
import { Text } from '@jcm-technologies/uikit/dist/atoms/Typography';
import { Icon } from '@jcm-technologies/uikit/dist/atoms/Icon';
import { IconExclamationTriangle } from '@jcm-technologies/uikit/dist/atoms/Icons';
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import { GridPDFExport } from '@progress/kendo-react-pdf';
import { filterBy, orderBy } from '@progress/kendo-data-query';
import { IntlProvider, loadMessages, LocalizationProvider } from '@progress/kendo-react-intl';
import MultiselectionGridPopUps from './components/MultiselectionGridPopUps';
import { Renderers } from './components/CellRenderers';
import IconSpan from '../IconSpan';
import { ColumnMenu } from './columnMenu.js';
import messagesDictionary from './translations/messagesDictionary';
import { loadFile } from '../../core/helpers/filesApi';
import { objectsAreEquals } from '../../core/old_common/utils/objectsService';
import { getECodeTranslated } from '../../core/old_common/utils/facilityService';
import { showDialog } from '../../modules/old_to_refact/actions/loading';
import { getCookieLanguage } from '../../core/old_common/utils/cookiesManager';
import { resetNeedUpdateTableFobs } from '../../modules/group/fobs/actions';
import '@progress/kendo-theme-default';
import './GenericGrid.css';
import { getTransparentColor } from '../../core/helpers/colorManager';
import IconDownload from '@jcm-technologies/uikit/dist/atoms/Icons/IconDownload';
import { IconTooltip } from '@jcm-technologies/uikit/dist/molecules/IconTooltip';
import IconFilePdf from '@jcm-technologies/uikit/dist/atoms/Icons/IconFilePdf';
import IconUpload from '@jcm-technologies/uikit/dist/atoms/Icons/IconUpload';
import { CustomFilter } from '../../components/common/Table/renderers';

class GenericKendoGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editField: undefined,
      changes: false,
      someSelected: false,
      everySelected: false,
      datas: props.value || [],
      filter: undefined,
      sort: [
        this.props.firstSortedColumn
          ? { field: this.props.firstSortedColumn, dir: 'asc' }
          : this.getSortableColumn(),
      ],
      skip: 0,
      take: this.props.pageSize || 15,
      nonDisplayableColums: [],
    };
    this.renderers = new Renderers(
      this.enterEdit,
      this.exitEdit,
      'inEdit',
      this.onBlurSave,
      this.onTabFocusNextItem,
      this.onTabFocusPrevItem
    );
  }

  componentDidUpdate = (prevProps, prevState) => {
    const {
      value,
      otherGridProps,
      needUpdateTableFobs,
      needUpdateTableUGFobs,
      needUpdateTableCompanies,
    } = this.props;
    if (
      (_.isEmpty(prevProps.value) && !_.isEmpty(value)) ||
      !_.isEqual(_.flatMap(prevProps.value), _.flatMap(value)) ||
      !objectsAreEquals(_.flatMap(prevProps.otherGridProps), _.flatMap(otherGridProps))
    ) {
      const changeValuesIfSubstitution =
        needUpdateTableFobs || needUpdateTableUGFobs
          ? this.compareArrays(value, prevState.datas)
          : needUpdateTableCompanies
          ? value
          : null;
      const gridDatas =
        prevState.datas.length > 0 &&
        value.length === prevState.datas.length &&
        needUpdateTableCompanies
          ? changeValuesIfSubstitution || prevState.datas
          : value;

      const updatingData =
        this.state.datas.length > 0 && this.state.datas.length === gridDatas.length
          ? this.state.datas
          : gridDatas;

      const valuesToMap = needUpdateTableCompanies ? gridDatas : updatingData;
      const mappedValues = [
        ...(valuesToMap.map((dataItem) => {
          let inEdit;
          if (valuesToMap) {
            const selectedValue = valuesToMap.find((val) => val.id === dataItem.id);
            if (selectedValue) {
              inEdit = selectedValue.inEdit;
            }
          }

          return { selected: false, inEdit, ...dataItem };
        }) || []),
      ];
      // this.getLanguageForGrid();
      this.setState((prevState) => ({
        ...prevState,
        datas: orderBy(mappedValues, this.state.sort),
        someSelected: false,
        everySelected: false,
      }));
    }
  };

  compareArrays = (value, datas) => {
    value.map((currElement, index) => {
      const indexz = datas.findIndex((data) => data.id === currElement.id);
      if (datas.length > 0) {
        if (datas[indexz]?.e_code !== currElement.e_code) {
          datas[indexz].e_code = currElement.e_code;
          datas[indexz].fobCode = getECodeTranslated(currElement.e_code);
        }
      }
    });
    this.props.resetNeedUpdateTableFobs();
    return datas;
  };

  // --------------------------------------------------------------------------------------------
  // -----------------------BASIC USAGE----------------------------------------------------------
  // --------------------------------------------------------------------------------------------

  getfiltrableColumn = () =>
    this.props
      .getColumns()
      .map((column) => ({ field: column.key, operator: 'contains', value: '' }));

  getSortableColumn = () =>
    this.props.getColumns().map((column) => ({ field: column.key, dir: 'asc' }));

  getColumns = (isPDFExport) => {
    const nonDisplayableArray = [];
    const temp = {
      filterable: false,
      editable: false,
      sortable: false,
      resizable: false,
      reorderable: false,
      groupable: false,
    };
    const columnsArray = isPDFExport
      ? this.props.getColumns().filter((column) => !column.hideOnPDFExport)
      : this.props.getColumns();
    const columns = columnsArray.map((column) => {
      if (column.displayable !== false) {
        return (
          <Column
            filter={column.filter || 'text'}
            resizable={column.resizable || false}
            minResizableWidth={(column.width && Number(column.width)) || 10}
            locked={column.locked || undefined}
            width={column.width || 'auto'}
            editable={column.editable || false}
            sortable={column.sortable || false}
            filterable={column.filterable || false}
            filterCell={CustomFilter}
            columnMenu={column.sortable === true ? ColumnMenu : null}
            key={column.key}
            field={column.key}
            title={column.name}
            editor={column.editor || ''}
            format={column.format || ''}
            headerClassName={column.headerClassName || ''}
            className={column.className || ''}
            cell={
              column.cell
                ? (props) =>
                    column.cell(props, {
                      onEdit: this.enterEdit,
                      onBlur: (e) => {
                        this.exitEdit();
                      },
                      onChange: (e) => this.itemChange(e),
                      editField: this.state.editField,
                    })
                : null
            }
          />
        );
      }

      return null;
    });

    return columns;
  };
  // --------------------------------------------------------------------------------------------
  // -----------------------MULTISELECCTION GESTION----------------------------------------------
  // --------------------------------------------------------------------------------------------

  selectionChange = (event) => {
    event.dataItem.selected = !event.dataItem.selected;
    const someSelected = this.state.datas.some((dataItem) => dataItem.selected);
    const everySelected = this.state.datas.every((dataItem) => dataItem.selected);
    this.setState({ someSelected, everySelected });
  };

  headerSelectionChange = (event) => {
    const { datas } = this.state;
    const gridDatas = filterBy(
      orderBy(
        datas || [],
        this.state.sort[0]?.field === 'fobCode'
          ? [{ field: 'e_code', dir: this.state.sort[0].dir }]
          : this.state.sort
      ),
      this.state.filter
    );
    const { checked } = event.syntheticEvent.target;
    gridDatas.forEach((item) => (item.selected = checked));
    const everySelected = gridDatas.every((dataItem) => dataItem.selected);
    const someSelected = gridDatas.some((dataItem) => dataItem.selected);
    this.setState({ gridDatas, everySelected, someSelected });
  };

  // --------------------------------------------------------------------------------------------
  // --------------------------IN CELL EDIT -----------------------------------------------------
  // --------------------------------------------------------------------------------------------
  enterEdit = (dataItem, field) => {
    if (dataItem.inEdit && field === this.state.editField) {
      return;
    }
    // this.exitEdit();
    dataItem.inEdit = field;
    this.setState({
      editField: field,
      datas: this.state.datas,
    });
  };

  exitEdit = () => {
    const { dontNeedBlur } = this.props;
    if (!dontNeedBlur) {
      this.state.datas.forEach((d) => {
        d.inEdit = undefined;
      });
      this.setState({
        datas: this.state.datas,
        editField: undefined,
      });
    }
  };

  onBlurSave = (e, item) => {
    if (this.props.gridCallbacks) {
      const { onCellBlurSave } = this.props.gridCallbacks;
      let newItem = item;
      // newItem[inEdit] = e.target.defaultValue;
      if (!e.shiftKey && e.keyCode === 9) {
        newItem = this.onTabFocusNextItem(e, item);
      } else if (e.shiftKey && e.keyCode === 9) {
        newItem = this.onTabFocusPrevItem(e, item);
      } else if (e.keyCode === 13) {
        newItem = this.onEnterFocusNextItem(e, item, e.shiftKey);
      } else {
        const datas = [...this.state.datas].map((dataItem) => ({
          ...dataItem,
          inEdit: dataItem.inEdit === true ? dataItem.inEdit : undefined,
        }));
        this.setState({
          datas,
          editField: undefined,
        });
      }
      if (onCellBlurSave) {
        const selectedValue = this.props.value.find((data) => data.id === item.id);
        const inEditAttribute = item.inEdit;
        if (selectedValue[inEditAttribute] !== item[inEditAttribute]) {
          onCellBlurSave(item);
        }
      }
    }
  };

  onEnterFocusNextItem = (e, item, isReversed) => {
    let newDataItem = item;
    const datas = [...this.state.datas].map((dataItem, i) => {
      if (item.inEdit && dataItem.id === item.id) {
        return { ...dataItem, inEdit: undefined };
      }
      if (!isReversed && i > 0 && item.inEdit && this.state.datas[i - 1].id === item.id) {
        newDataItem = { ...dataItem, inEdit: item.inEdit };
        return newDataItem;
      }
      if (isReversed && i + 1 < this.state.datas.length && this.state.datas[i + 1].id === item.id) {
        newDataItem = { ...dataItem, inEdit: item.inEdit };
        return newDataItem;
      }
      return { ...dataItem, inEdit: dataItem.inEdit || undefined };
    });
    this.setState({ datas });
    return newDataItem;
  };

  onTabFocusNextItem = (e, item) => {
    let nextElement = '';
    let newDataItem = item;
    const columns = [...this.props.getColumns()];
    const inEditColumn = columns.findIndex((column) => column.key === item.inEdit);
    if (inEditColumn > -1 && inEditColumn + 2 < columns.length) {
      nextElement = columns[inEditColumn + 1].key;
      const datas = [...this.state.datas].map((dataItem) => {
        if (nextElement && dataItem.id === item.id) {
          newDataItem = {
            ...dataItem,
            inEdit: nextElement,
          };
          return newDataItem;
        }
        return { ...dataItem, inEdit: dataItem.inEdit || undefined };
      });
      this.setState({
        datas,
        editField: nextElement,
      });
      return newDataItem;
    }
    const newItem = {
      ...item,
      inEdit: columns[columns.findIndex((column) => column.key === 'name')].key,
    };
    this.onEnterFocusNextItem(e, newItem, false);

    return item;
  };

  onTabFocusPrevItem = (e, item) => {
    let nextElement = '';
    let newDataItem = item;
    const columns = [...this.props.getColumns()];
    const inEditColumn = columns.findIndex((column) => column.key === item.inEdit);
    const firstEditable = columns.findIndex((column) => column.key === 'name');
    if (inEditColumn > firstEditable) {
      nextElement = columns[inEditColumn - 1].key;
      const datas = [...this.state.datas].map((dataItem) => {
        if (nextElement && dataItem.id === item.id) {
          newDataItem = {
            ...dataItem,
            inEdit: nextElement,
          };
          return newDataItem;
        }
        return { ...dataItem, inEdit: dataItem.inEdit || undefined };
      });
      this.setState({
        datas,
        editField: nextElement,
      });
      return newDataItem;
    }
    const newItem = {
      ...item,
      inEdit: columns[columns.length - 2].key,
    };
    this.onEnterFocusNextItem(e, newItem, true);

    return item;
  };

  itemChange = (event) => {
    if (!this.props.gridUploading) {
      const field = event.field || '';
      this.state.datas.forEach((item) => {
        if (item.id === event.dataItem.id) {
          item[event.field] = event.value;
        }
      });

      this.setState({ changes: true });
    }
  };

  // --------------------------------------------------------------------------------------------
  // --------------------------EXPORT EXCEL------------------------------------------------------
  // --------------------------------------------------------- -----------------------------------

  _exportExport;

  _grid;

  exportExcel = () => {
    const { datas } = this.state;
    let columns = [...this._grid.columns];
    columns = columns.filter((x) => x.field !== 'selected');
    columns.unshift(...this.state.nonDisplayableColums);
    this._exportExport.save();
    this.props.showDialog(-1);
  };

  // --------------------------------------------------------------------------------------------
  // --------------------------EXPORT PDF---------------------------------------------------------
  // ---------------------------------------------------------------------------------------------

  _gridPDFExport;

  exportPDF = () => {
    this._gridPDFExport.save(this.state.datas, () => this.props.showDialog(-1));
  };

  // --------------------------------------------------------------------------------------------
  // --------------------------Page--------------------------------------------------------------
  // --------------------------------------------------------------------------------------------

  pageChange = (event) => {
    document.getElementsByClassName('k-grid-content k-virtual-content').item(0).scrollTop = 0;
    this.setState({
      skip: event.page.skip,
      take: event.page.take,
    });
  };

  // --------------------------------------------------------------------------------------------
  // ---------------------------GRID UPDATE ICONS------------------------------------------------
  // --------------------------------------------------------------------------------------------

  getGridUpdateIcons = () => {
    const { gridUpdated, gridUploading, gridFailed, t } = this.props;
    if (gridUploading) {
      // return t('genericGrid.gridUploading');
      return <IconSpan toolTip={t('genericGrid.gridUploading')} iconClassName='fas fa-spinner' />;
    }

    if (gridFailed) {
      return '';
    }
  };
  // --------------------------------------------------------------------------------------------
  // --------------------------SORT BY COLUMN----------------------------------------------------
  // --------------------------------------------------------------------------------------------

  handleSortChange = (e) => {
    const { datas } = this.state;
    const { orderByReverse } = this.props;
    let gridOrderedBySort;
    if (orderByReverse && e.sort.length > 0 && e.sort[0].field === 'datetime') {
      gridOrderedBySort = this.props.value?.reverse();
    } else {
      gridOrderedBySort = orderBy(this.state.datas, e.sort);
    }
    this.setState({ datas: filterBy(gridOrderedBySort, e.filter), sort: e.sort });
  };

  // --------------------------------------------------------------------------------------------
  // --------------------------RENDER------------------------------------------------------------
  // --------------------------------------------------------------------------------------------

  getGridRender = (isPDFExport) => {
    const { needMultiselection, gridClassName, filterable, resizable, sort, onSortChange } =
      this.props;
    const { datas, everySelected } = this.state;
    const gridDatas = filterBy(datas, this.state.filter);
    return (
      <Grid
        // width="500"
        style={
          this.props.pageSize && gridDatas.length
            ? { width: '100%', height: '400px' }
            : { width: '100%' }
        }
        ref={(grid) => {
          this._grid = grid;
        }}
        className={gridClassName}
        data={gridDatas.slice(this.state.skip, this.state.take + this.state.skip)}
        onItemChange={this.itemChange}
        cellRender={this.renderers.cellRender}
        rowRender={this.renderers.rowRender}
        editField='inEdit'
        selectedField='selected'
        onSelectionChange={this.selectionChange}
        onHeaderSelectionChange={this.headerSelectionChange}
        resizable={resizable || undefined}
        sortable
        sort={sort || this.state.sort}
        onSortChange={this.handleSortChange}
        filterable={filterable !== undefined ? filterable : true}
        filter={this.state.filter}
        onFilterChange={(e) => {
          this.setState({
            ...this.state,
            filter: e.filter,
            skip: 0,
          });
        }}
        total={this.state.datas.length}
        skip={this.state.skip}
        take={this.state.take}
        pageable
        onPageChange={this.pageChange}
        filterOperators={{
          text: [{ text: 'grid.filterContainsOperator', operator: 'contains' }],
        }}
      >
        {needMultiselection && !isPDFExport ? (
          <Column
            field='selected'
            width='50px'
            filterable={false}
            editalbe={false}
            headerSelectionValue={everySelected}
          />
        ) : (
          ''
        )}
        {this.getColumns(isPDFExport)}
      </Grid>
    );
  };

  getGrid = (isPDFExport) => {
    const lang = getCookieLanguage();
    loadMessages(messagesDictionary[lang], lang);
    return !isPDFExport ? (
      <LocalizationProvider language={lang || ''}>
        <IntlProvider locale={`${lang?.slice(0, 2)}`}>{this.getGridRender(false)}</IntlProvider>
      </LocalizationProvider>
    ) : (
      this.getGridRender(true)
    );
  };

  getExcelColumns = (usersGrid) => {
    const { t } = this.props;
    if (usersGrid) {
      return (
        <div>
          <Column field='email' title={t('instalationUserGroups.email')} />
          <Column field='name' title={t('form.name')} />
          <Column field='surname' title={t('form.surname')} />
          <Column field='phone' title={t('instalationUserGroups.phone')} />
        </div>
      );
    }
    return (
      <div>
        <Column field='fobCode' title={t('form.code')} />
        <Column field='productName' title={t('form.type')} />
        <Column field='name' title={t('form.name')} />
        <Column field='surname' title={t('form.surname')} />
        <Column field='nic' title={t('form.identityCard')} />
        <Column field='plaza' title={t('instalationGroups.place')} />
      </div>
    );
  };

  render() {
    const {
      showDialog,
      needExport,
      needOnlyExport,
      needMultiselection,
      tenants,
      gridCallbacks,
      gridDatas,
      t,
      needUpdateView,
      importExcel,
      excelExportColumns,
      excelName,
      pdfName,
      gridUploading,
      onlyRemove,
      onlySave,
      usersGrid,
      dontShowImport,
      needPdfExport,
      showItemsSelected,
    } = this.props;
    const { datas, someSelected, editField } = this.state;
    const selectedItems = [...datas.filter((item) => item.selected)];
    return (
      <div>
        {selectedItems.length && showItemsSelected ? (
          <Flex marginBottom={2}>
            <Icon color='red' sizeIcon='display24'>
              <IconExclamationTriangle />
            </Icon>
            <Text sizeText='display16'>
              {t('instalationGroups.totalSelected')}: {selectedItems.length}
            </Text>
          </Flex>
        ) : null}
        <div className='generic-grid-kendo'>
          <style>
            {`
            * { outline: none }
            .k-grid .k-state-selected > td { background: ${getTransparentColor(
              tenants?.color1,
              0.25
            )} !important;}
            .k-checkbox:checked, .k-checkbox.k-checked { border-color: ${
              tenants?.color1
            }; background-color: ${tenants?.color1}; }
            .k-checkbox:checked:focus, .k-checkbox.k-checked.k-state-focus { box-shadow: none; }
           `}
          </style>
          <div className='export-kendo-grid'>
            <GridPDFExport
              fileName={pdfName}
              paperSize='A4'
              scale={0.45}
              margin='2cm'
              ref={(pdfExport) => (this._gridPDFExport = pdfExport)}
            >
              {this.getExcelColumns(usersGrid)}
              {this.getGrid(true)}
            </GridPDFExport>
          </div>
          {this.getGrid()}
          {needMultiselection && someSelected ? (
            <MultiselectionGridPopUps
              tenants={tenants}
              isHidden={!someSelected}
              gridCallbacks={gridCallbacks}
              gridDatas={gridDatas}
              selectedItems={selectedItems}
              onlyRemove={onlyRemove}
              onlySave={onlySave}
            />
          ) : (
            ''
          )}
          <Flex alignItems='center' justifyContent='space-between' marginY={1}>
            {needOnlyExport ? (
              <>
                <div className='grid-export-button-kendo'>
                  <IconTooltip
                    color='grey'
                    sizeIcon='display36'
                    toolTipContent={t('instalationGroups.exportExcel')}
                    onClick={() => {
                      if (showDialog) {
                        showDialog(1);
                      }
                      setTimeout(() => this.exportExcel(), 0);
                    }}
                  >
                    <IconDownload />
                  </IconTooltip>
                </div>
              </>
            ) : (
              ''
            )}
            {(needUpdateView && datas && datas.length) || needExport ? (
              <div className='grid-export-buttons'>
                {needExport ? (
                  <>
                    {needPdfExport ? (
                      <div className='grid-export-button-pdf'>
                        <IconTooltip
                          color='grey'
                          sizeIcon='display36'
                          toolTipContent={t('instalationGroups.exportPDF')}
                          onClick={() => {
                            if (showDialog) {
                              showDialog(1);
                            }
                            setTimeout(() => this.exportPDF(), 0);
                          }}
                          marginRight={2}
                        >
                          <IconFilePdf />
                        </IconTooltip>
                      </div>
                    ) : null}

                    <div className='grid-export-button-kendo'>
                      <IconTooltip
                        color='grey'
                        sizeIcon='display36'
                        toolTipContent={t('instalationGroups.exportExcel')}
                        onClick={() => {
                          if (showDialog) {
                            showDialog(1);
                          }
                          setTimeout(() => this.exportExcel(), 0);
                        }}
                        marginRight={2}
                      >
                        <IconDownload />
                      </IconTooltip>
                    </div>
                    <div className='grid-import-button-kendo'>
                      {!dontShowImport ? (
                        <IconTooltip
                          color='grey'
                          sizeIcon='display36'
                          toolTipContent={t('instalationGroups.importExcel')}
                          onClick={() =>
                            loadFile({
                              callBack: (e) => importExcel(e),
                            })
                          }
                        >
                          <IconUpload />
                        </IconTooltip>
                      ) : (
                        ''
                      )}
                    </div>
                  </>
                ) : (
                  ''
                )}
                {needUpdateView && datas && datas.length ? (
                  <span
                    className={`grid-updated-icons ${gridUploading ? 'grid-kendo-loading' : ''}`}
                  >
                    {this.getGridUpdateIcons()}
                  </span>
                ) : null}
              </div>
            ) : null}
            <ExcelExport
              fileName={excelName || 'jcm-tech'}
              data={datas}
              ref={(exporter) => (this._exportExport = exporter)}
            >
              {excelExportColumns
                ? excelExportColumns.map((column) => <ExcelExportColumn {...column} />)
                : null}
            </ExcelExport>
          </Flex>
        </div>
      </div>
    );
  }
}

const gridWithTranslation = withTranslation()(GenericKendoGrid);

const mapStateToProps = (state) => ({
  tenants: state.tenants,
  gridUpdated: state.gridControllers.gridUpdated,
  gridUploading: state.gridControllers.gridUploading,
  gridFailed: state.gridControllers.gridFailed,
  needUpdateTableFobs: state.fobs.needUpdateTableFobs,
  needUpdateTableUGFobs: state.universalGroupsFobs.needUpdateTableUGFobs,
});

export default connect(mapStateToProps, { showDialog, resetNeedUpdateTableFobs })(
  gridWithTranslation
);
