export default {
  remoteState: {
    noContent: 'No disponible',
    title: 'Estat remot',
    doorState0: 'Indefinida',
    doorState1: 'Oberta',
    doorState2: 'Tancada',
    doorState3: 'Obrint',
    doorState4: 'Tancant',
    doorState5: 'Parat obrint',
    doorState6: 'Parat tancant',
    doorState7: 'Esperant tancament automàtic',
    deviceType: 'Tipus de dispositiu',
    connectionSignal: 'Senyal de connexió',
    rssiValue: 'Valor RSSI',
    hwVersion: 'Versió Maquinari',
    swVersion: 'Versió Programari',
    doorStateTitle: 'Estat de la porta',
    inputStateTitle: 'Estat de l\'entrada',
    deviceInfoTitle: 'Informació del dispositiu',
    relayTitle: 'temps d\'activació en segons',
    relaysTitle: 'Relés',
    refreshStatus: 'Refrescar estat',
    activateRel1: 'Activar relé 1',
    activateRel2: 'Activar relé 2',
    doorState: 'Estat',
    activateRelError: 'El dispositiu no respon',
    activateRelSuccess: 'Acció completada amb èxit',
    connectionSignalUnknown: 'Fora de rang',
    connectionSignalExcellent: 'Excel·lent',
    connectionSignalGood: 'Molt bona',
    connectionSignalFair: 'Correcta',
    connectionSignalWeak: 'Marginal',
    connectionType: 'Tipus de connexió',
    deviceType0: 'eBase',
    deviceType1: 'eConnect',
    deviceType2: 'btConnect',
    deviceType3: 'Sesame',
    deviceType4: 'Blink',
    deviceType5: 'eAccess',
    deviceType6: 'Vdp',
    deviceType7: 'honoaDoor / ebaseDoor',
    deviceType8: 'honoaRelay',
    sensors: 'Sensors',
    open: 'Obert',
    battery: 'Bateria',
    deactivated: 'Desactivat',
    activated: 'Activat',
  },
};