import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getCountries } from '../../modules/countries/actions';
import './ContryRegion.css';
import { ComboboxFlyer } from '../ComboboxFlyer/ComboboxFlyer';

class ContryRegion extends Component {
  componentDidMount = () => this.props.getCountries();

  getCountriesOptions = () => {
    if (this.props.countries.length) {
      const options = this.props.countries.map((country) => (
        <option key={country.code} value={country.id}>
          {country.name}
        </option>
      ));
      return [...options];
    }
    return [];
  };

  render() {
    return (
      <ComboboxFlyer
        {...this.props}
        className={this.props.className}
        options={this.getCountriesOptions()}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  countries: state.countries.countries,
});
const contryRegion = connect(mapStateToProps, {
  getCountries,
})(ContryRegion);
export default contryRegion;
