export default {
  updateInstalationForm: {
    contact: 'Kontakt',
    telephone: 'Telefon',
    address: 'Adresse',
    country: 'Land',
    saveButton: 'Speichern',
    maintenanceDate: 'Nächster Wartungstermin',
    createFacility: 'Installation erstellen',
    installerCode: 'Installateurcode verwenden?',
    installerCodeIconTooltip: 'Code installieren',
    comment: 'Bemerkungen',
    createFacilityGroup: 'Benutzergruppen erstellen',
    uniqueIdentity: 'Projektnummer',
    geolocalization: 'Geolocation eingestellt',
    exportUsersGroups: 'Benutzer exportieren',
    noneGeolocalization: 'Standorterkennung ausgeschaltet',
    addUniversalGroup: 'Universelle Gruppe hinzufügen',
    needSetLocation: 'Sie müssen einen bestimmten Speicherort hinzufügen',
    exportUsersTitle: 'benutzer_exportieren',
    creationDate: 'Erstellungsdatum',
    installerCodeFirstMessage: 'Stellen Sie sicher, dass Sie mit Installateurcode arbeiten.',
    installerCodeSecondMessage: 'Kontaktieren Sie im Zweifelsfall Ihren Administrator'
  },
};
