import React, { Component } from 'react';
import { connect } from 'react-redux';
import { generatePath, withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { v1 as uuidv1 } from 'uuid';
import { Box, Flex } from '@jcm-technologies/uikit/dist/atoms/Layout';
import { Modal } from '@jcm-technologies/uikit/dist/molecules/Modal';
import { Text } from '@jcm-technologies/uikit/dist/atoms/Typography';
import { Button } from '@jcm-technologies/uikit/dist/atoms/Button';
import { SelectInput } from '@jcm-technologies/uikit/dist/atoms/Select';
import IconSpan from '../IconSpan/IconSpan';
import ToolTip from '../ToolTip/ToolTip';

import './GenericGrow.css';
import { setCloneGrow } from '../../modules/old_to_refact/actions/genericGrow';
import DeletePopup from '../DeletePopup/DeletePopup';
import { getDecodedUrl } from '../../core/old_common/utils/getUrlParams';
import { resetEquipmentScroll } from '../../modules/old_to_refact/actions/equipments';
import { getCustomersPropertyManagers } from '../../modules/customers/actions';
import { putTransferInstalation } from '../../modules/old_to_refact/actions/instalations';
import { getContrastColor } from '../../core/helpers/colorManager';

class GenericGrow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deletePopupIsOpen: false,
      secondPopupIsOpen: false,
      transferModalIsOpen: false,
      transferInfo: {
        facilityId: null,
        newCustomerId: null,
        value: null,
      },
    };
  }

  componentDidMount() {
    if (this.props.customers.propertyManagers.length === 0 && this.props.needCallManagers) {
      this.props.getCustomersPropertyManagers();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.state.transferModalIsOpen &&
      !prevState.transferModalIsOpen &&
      this.props.customers.propertyManagers.length === 0 &&
      this.props.needCallManagers
    ) {
      this.props.getCustomersPropertyManagers();
    }
  }

  getRootPath = () => this.props.rootPath || '/';

  handleExpand = () => {
    const { item, history, rootPath, resetEquipmentScroll, dashboardFilterId } = this.props;
    resetEquipmentScroll();
    if (
      this.props.match.path === '/Facilities/:instalationId/groups/AddUniversalGroup/:id' ||
      this.props.match.path === '/Facilities/:instalationId/groups/AddUniversalGroup'
    ) {
      console.log(1);
      const instalationId = encodeURIComponent(this.props.match.params.instalationId);
      const instalationReplace = instalationId.replace(/\&/g, '%26');
      history.push(`/Facilities/${instalationReplace}/groups/AddUniversalGroup`);
    }
    if (
      history.location.pathname !== decodeURIComponent(this.getRootPath()) &&
      !this.props.secondaryRootPath &&
      this.props.match.path !== '/Facilities/:instalationId/groups/AddUniversalGroup'
    ) {
      console.log(2);
      history.push(this.getRootPath().replace('%09', '%2509'));
    } else if (rootPath === '/groups') {
      console.log(3);
      const facilityId = encodeURIComponent(item.facilityId);
      const groupName = encodeURIComponent(item.id);
      const facilityReplace = facilityId.replace(/\&/g, '%26');
      const groupReplace = groupName.replace(/\&/g, '%26');
      this.props.history.push(`/Facilities/${facilityReplace}/groups/${groupReplace}/Fobs`);
    } else if (this.props.match.path === '/schedules') {
      console.log(4);
      const decoded = generatePath('/schedules/:id', {
        id: encodeURIComponent(item.id),
      });
      history.push(decoded);
    } else if (this.props.match.path === '/UniversalGroups') {
      console.log(9);
      const decoded = generatePath('/UniversalGroups/:id', {
        id: encodeURIComponent(item.id),
      });
      history.push(decoded);
    } else if (
      this.props.match.path !== '/Facilities/:instalationId/groups/AddUniversalGroup/:id' &&
      this.props.match.path !== '/Facilities'
    ) {
      console.log(5);
      let encoded = '';
      const decodedId = decodeURIComponent(
        this.props.match.params.id
          ? this.props.match.params.id
          : this.props.match.params.instalationId
      );
      if (this.props.match.path === '/Facilities/:id/groups') {
        console.log(10);
        encoded = generatePath(`${this.props.match.path}/:secondId`, {
          id: encodeURIComponent(decodedId),
          secondId: encodeURIComponent(item.id),
        });
      } else if (this.props.match.params.id) {
        console.log(6);
        // TODO: Esto es donde te manda a por el id de un grupo en una facility
        encoded = generatePath(`${this.props.match.path}/:secondId`, {
          id: encodeURIComponent(decodedId),
          secondId: encodeURIComponent(item.id),
        });
      } else if (this.props.match.path === '/Equipments') {
        encoded = generatePath(`/Facilities/:instalationId/Equipments/:equipmentId`, {
          instalationId: encodeURIComponent(item?.facilityId),
          equipmentId: encodeURIComponent(item.id),
        });
      } else {
        console.log(7);
        encoded = generatePath(`${this.props.match.path}/:secondId`, {
          instalationId: encodeURIComponent(decodedId),
          secondId: encodeURIComponent(item.id),
        });
      }
      history.push(`${encoded}`);
    } else if (this.props.match.path === '/Facilities') {
      console.log(8);
      let decoded;
      if (dashboardFilterId === 'caUsers') {
        decoded = generatePath('/Facilities/:id/logs', {
          id: encodeURIComponent(item.id),
        });
      } else {
        decoded = generatePath('/Facilities/:id', {
          id: encodeURIComponent(item.id),
        });
      }

      history.push(decoded);
    }
  };

  handleDelete = (e) => {
    const { history, handleDelete } = this.props;
    e?.stopPropagation();
    if (handleDelete) {
      setTimeout(() => {
        handleDelete();
      }, 500);
      history.push(this.getRootPath());
    }
  };

  handleClone = (e) => {
    const { item, creationURI, handleClone, history, setCloneGrow, manipulateCloneObject } =
      this.props;
    e?.stopPropagation();
    if (!handleClone) {
      let selectedItem = { ...item };
      if (manipulateCloneObject) {
        selectedItem = { ...manipulateCloneObject(selectedItem) };
      }
      selectedItem.name = ``;
      selectedItem.id = uuidv1();
      selectedItem.uniqueIdentity = null;
      setCloneGrow(true, selectedItem);
      history.push(`${this.getRootPath()}/${creationURI}`);
    } else {
      handleClone();
    }
  };

  handleTransfer = () => {
    const { putTransferInstalation } = this.props;
    putTransferInstalation(
      this.state.transferInfo.facilityId,
      this.state.transferInfo.newCustomerId,
      this.state.transferInfo.value
    );
    this.setState({
      transferModalIsOpen: false,
      transferInfo: {
        facilityId: null,
        newCustomerId: null,
        value: null,
      },
    });
  };

  render() {
    const {
      t,
      headerTitles,
      children,
      canClone,
      handleExpand,
      tenants,
      customerId,
      isExpanded,
      hideActionButtons,
      actionPanelClassName,
      creationURI,
      canExpand,
      customActionButtons,
      MapComponent,
      deletePopupChildren,
      deletePopupTitle,
      isMapButtonVisible,
      match,
      handleDelete,
      deleteButtonCustomId,
      secondDeletePopup,
      firstTextSecondPopup,
      secondTextSecondPopup,
      secondDeletePopupTitle,
      isAdminUserRole,
      isAdvancedManagerRole,
      handleRestore,
      item: { id, name },
      history,
    } = this.props;
    const isCreating = getDecodedUrl(match.params.id) === creationURI;
    const expandable = canExpand !== undefined ? canExpand : !isCreating;
    const isMapVisible = MapComponent && (isMapButtonVisible === undefined || isMapButtonVisible);

    return (
      <div key={customerId} className='genericGrow'>
        <span className='genericGrowHeaderContainer'>
          {MapComponent && isMapVisible && !isCreating ? <MapComponent /> : null}
          <Box
            padding={1}
            onClick={expandable ? handleExpand || this.handleExpand : null}
            className={`genericGrowHeader ${expandable ? 'clickable' : ''}`}
          >
            <span className='generic-grow-header-item generic-grow-header-titles'>
              {headerTitles.map((title) => {
                const toolTipID = uuidv1();
                return (
                  <span
                    key={toolTipID}
                    id={title.id}
                    className={`${title.className}`}
                    data-tip={title.toolTip}
                    data-for={toolTipID}
                  >
                    {title.value ? title.value.replace(/%26/g, '&') : ''}
                    {title?.toolTip && <ToolTip id={toolTipID} />}
                  </span>
                );
              })}
            </span>
            <span className={`generic-grow-action-panel ${actionPanelClassName || ''}`}>
              {customActionButtons && !isCreating
                ? customActionButtons.map((actionButton, i) => {
                    const Type = actionButton.actionType || IconSpan;
                    return <Type key={`${i} icon`} {...actionButton} />;
                  })
                : null}
              {!hideActionButtons &&
              (isAdminUserRole || isAdvancedManagerRole) &&
              !match?.url?.includes('Universal') &&
              !isCreating ? (
                <IconSpan
                  id='transfer'
                  secondaryId='cloneId'
                  toolTip={t('instalations.transfer')}
                  defaultColor='#a4aebb'
                  iconClassName='generic-grow-action-button fas fa-random'
                  cursor='pointer'
                  onClick={(e) => {
                    e?.stopPropagation();
                    this.setState({ transferModalIsOpen: true });
                  }}
                />
              ) : null}
              {!hideActionButtons && canClone && !isCreating ? (
                <IconSpan
                  id='clone'
                  secondaryId='cloneId'
                  toolTip={t('general.clone')}
                  defaultColor='#a4aebb'
                  iconClassName='generic-grow-action-button fas fa-clone'
                  cursor='pointer'
                  onClick={this.handleClone}
                />
              ) : null}
              {!hideActionButtons &&
              !isCreating &&
              handleDelete &&
              !match?.url?.includes('/Equipments/Facilities') ? (
                <IconSpan
                  id={deleteButtonCustomId || 'delete'}
                  toolTip={t('genericGrow.delete')}
                  defaultColor='#a4aebb'
                  iconClassName='generic-grow-action-button fas fa-trash'
                  cursor='pointer'
                  onClick={(e) => {
                    e?.stopPropagation();
                    this.setState({ deletePopupIsOpen: true });
                  }}
                />
              ) : null}
              {handleRestore && !hideActionButtons && (
                <IconSpan
                  id='restore'
                  toolTip={t('instalations.restore')}
                  defaultColor='#a4aebb'
                  iconClassName='generic-grow-action-button fas fa-trash-restore'
                  cursor='pointer'
                  onClick={() => {
                    handleRestore(id);
                  }}
                />
              )}
              {expandable ? (
                <IconSpan
                  toolTip={isExpanded ? t('general.collapse') : t('general.expand')}
                  defaultColor='#a4aebb'
                  cursor='pointer'
                  iconClassName={`generic-grow-action-collapse fas ${
                    isExpanded ? 'fa-angle-up' : 'fa-angle-down'
                  }`}
                />
              ) : null}
            </span>
          </Box>
        </span>
        {isExpanded && (
          <>
            <Box
              borderBottomWidth={2}
              borderBottomStyle='solid'
              borderBottomColor={tenants?.color1}
            />
            <Box padding={3}>{children || null}</Box>
          </>
        )}
        <DeletePopup
          title={deletePopupTitle}
          isOpen={this.state.deletePopupIsOpen}
          handleAccept={(e) => {
            if (secondDeletePopup) {
              this.setState({ deletePopupIsOpen: false, secondPopupIsOpen: true });
            } else {
              this.setState({ deletePopupIsOpen: false });
              this.handleDelete(e);
            }
          }}
          handleCancel={() => this.setState({ deletePopupIsOpen: false })}
        >
          {deletePopupChildren}
        </DeletePopup>
        <DeletePopup
          isSecondDeletePopup
          title={secondDeletePopupTitle}
          isOpen={this.state.secondPopupIsOpen}
          handleAccept={(e) => {
            this.setState({ secondPopupIsOpen: false });
            this.handleDelete(e);
          }}
          handleCancel={() => this.setState({ secondPopupIsOpen: false })}
        >
          <div className='container-secondDelete'>
            <div className='iconWarning'>
              <IconSpan defaultColor='red' iconClassName='fas fa-exclamation-triangle fa-5x' />{' '}
            </div>
            <div className='text'>
              {`${firstTextSecondPopup} ${this.props.item.name}${this.props.firstSecondTextSecondPopup}`}
              ?
            </div>
            <div className='secondText'>{secondTextSecondPopup}</div>
          </div>
        </DeletePopup>

        <Modal
          maxWidth='440px'
          width='100%'
          isOpen={this.state.transferModalIsOpen}
          id='transfer-instalation'
          onModalClose={() =>
            this.setState({
              transferModalIsOpen: false,
              transferInfo: {
                newCustomerId: null,
                facilityId: null,
                value: null,
              },
            })
          }
        >
          <Modal.Content width='100%'>
            <Text sizeText='display16' textAlign='center' marginBottom={2}>
              {t('instalations.transferPopUpLabel')}
            </Text>
            <SelectInput
              placeholderMessage={t('instalations.transferPopUpSelector')}
              options={this.props.customers.propertyManagers?.map((el) => ({
                label: el.name,
                value: el.id,
              }))}
              onChange={(name, value) => {
                this.setState({
                  transferInfo: {
                    newCustomerId: value.value,
                    facilityId: id,
                    value,
                  },
                });
              }}
            />
          </Modal.Content>
          <Modal.Actions>
            <Flex alignItems='center' justifyContent='center'>
              <Button
                id='acceptTransfer'
                sizeButton='small'
                disabled={!this.state.transferInfo.newCustomerId}
                backgroundColor={tenants?.color1}
                action={async () => {
                  await this.handleTransfer();
                  history.push(this.getRootPath().replace('%09', '%2509'));
                }}
                marginRight={2}
                color={getContrastColor(tenants?.color1)}
              >
                {t('popupConfirm.accept')}
              </Button>
              <Button backgroundColor='blackLight' id='cancel-button' sizeButton='small'>
                {t('popupConfirm.cancel')}
              </Button>
            </Flex>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  tenants: state.tenants,
  customers: state.customers,
  needCallManagers: state.customers.needCallManagers,
});

const GenericGrowWithTranslation = withTranslation()(GenericGrow);
const GenericGrowWithRouter = withRouter(GenericGrowWithTranslation);

export default connect(mapStateToProps, {
  setCloneGrow,
  resetEquipmentScroll,
  getCustomersPropertyManagers,
  putTransferInstalation,
})(GenericGrowWithRouter);
