import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Pill } from '@jcm-technologies/uikit/dist/atoms/Pill';
import { Icon } from '@jcm-technologies/uikit/dist/atoms/Icon';
import IconClose from '@jcm-technologies/uikit/dist/atoms/Icons/IconClose';
import GenericList from '../GenericList';
import {
  CreationPath_Equipments,
  RootPath_Equipments,
} from '../../core/old_common/constats/genericListPaths';
//import { getEquipments } from '../../modules/old_to_refact/actions/dashboard';
import { getEquipments } from '../../modules/dashboard/actions';
import { resetEquipments } from '../../modules/old_to_refact/actions/equipments';
import EquipmentsGrow from '../EquipmentsGrow';
import { getCookieEquipmentSearch, setCookie } from '../../core/old_common/utils/cookiesManager';
import { getContrastColor } from '../../core/helpers/colorManager';
import { EQUIPMENT_LIST } from '../../modules/state/pagination/constants';

export class EquipmentsListSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      haveFilter: false,
      filterSelected: null,
    };
  }

  componentDidMount = () => {
    const { needCallEquipments } = this.props;
    const lastSearch = getCookieEquipmentSearch();
    if (needCallEquipments && lastSearch?.value) {
      this.props.getEquipments(lastSearch?.fieldId, lastSearch?.value);
    }
    if (this.setPillFilter()) {
      this.setState({
        haveFilter: true,
        filterSelected: this.setPillFilter(),
      });
    }
  };

  handlePageChange = () => {
    const { needCallEquipments } = this.props;
    const lastSearch = getCookieEquipmentSearch();
    if (needCallEquipments && lastSearch?.value) {
      this.props.getEquipments(lastSearch?.fieldId, lastSearch?.value);
    }
  };

  setPillFilter = () => {
    const { t } = this.props;
    const lastSearch = getCookieEquipmentSearch();
    const filterSelected = {
      18: lastSearch.label,
      19: lastSearch.label,
    };

    return (
      (filterSelected[lastSearch.fieldId] &&
        `${filterSelected[lastSearch.fieldId]}${
          lastSearch.value ? `: ${lastSearch.value}` : ''
        }`) ||
      `${lastSearch.label}: ${lastSearch.value}`
    );
  };

  componentWillUnmount() {
    const { resetEquipments } = this.props;
    resetEquipments();
    setCookie('equipmentSearch', {});
  }

  render() {
    const { equipments, getEquipments, tenants, history } = this.props;
    const { haveFilter, filterSelected } = this.state;
    return (
      <>
        {haveFilter && filterSelected && (
          <Pill
            backgroundColor={tenants.color1}
            marginTop={1}
            color={getContrastColor(tenants?.colo1)}
          >
            {filterSelected}
            <Icon
              sizeIcon='display24'
              onClick={() => {
                this.setState({ haveFilter: false, filterSelected: null });
                history.push('/dashboard');
              }}
            >
              <IconClose />
            </Icon>
          </Pill>
        )}
        <GenericList
          dataList={equipments}
          CustomGrow={EquipmentsGrow}
          hideActionButtons
          handlePageChange={this.handlePageChange}
          baseURI={RootPath_Equipments}
          creationURI={CreationPath_Equipments}
          listType={EQUIPMENT_LIST}
          getList={getEquipments}
          groupByProp='customerName'
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  tenants: state.tenants,
  equipments: state.equipments.equipments,
  needCallEquipments: state.equipments.needCallEquipments,
});

const EquipmentsListSearchPage = withTranslation()(EquipmentsListSearch);
const EquipmentsListSearchPageHistory = withRouter(EquipmentsListSearchPage);

export default connect(mapStateToProps, {
  getEquipments,
  resetEquipments,
})(EquipmentsListSearchPageHistory);
