import PathEntity from '../../core/old_common/rooting/apiPaths/PathEntity';
import { apiVersion, mainPath } from '../../core/old_common/rooting/common';

class Routes extends PathEntity {
  constructor() {
    super('gdpr');
  }

  getLogsList = (pageSize, pageNumber) => {
    this.mainPath = `${mainPath()}4.1`;
    let endpoint = this.manufacturePath(`gdpr/logs/`);
    endpoint = this.addParameterToEndpoint(endpoint, 'pageSize', pageSize);
    endpoint = this.addParameterToEndpoint(endpoint, 'pageNumber', pageNumber);
    this.mainPath = `${mainPath()}${apiVersion}`;
    return endpoint;
  };

  getSingleList = (pageSize, pageNumber, string) => {
    let endpoint = this.manufacturePath(`${this.entityName}/logs/singlelist/all`);
    if (string) {
      endpoint = this.addParameterToEndpoint(endpoint, 'queryText', string);
    }
    endpoint = this.addParameterToEndpoint(endpoint, 'pageSize', pageSize);
    endpoint = this.addParameterToEndpoint(endpoint, 'pageNumber', pageNumber);
    return endpoint;
  };

  delete = (email) => this.manufacturePath(`${this.entityName}/logs/${email}`);

  post = (email) => this.manufacturePath(`${this.entityName}/logs/${email}`);

  getPending = (pageSize, pageNumber, string) => {
    let endpoint = this.manufacturePath(`${this.entityName}/logs/singlelist`);
    endpoint = this.addParameterToEndpoint(endpoint, 'accepted', false);
    if (string) {
      endpoint = this.addParameterToEndpoint(endpoint, 'queryText', string);
    }
    endpoint = this.addParameterToEndpoint(endpoint, 'pageSize', pageSize);
    endpoint = this.addParameterToEndpoint(endpoint, 'pageNumber', pageNumber);
    return endpoint;
  };

  getPaths = () => ({
    ownersGetSingleList: this.getSingleList,
    ownersDelete: this.delete,
    ownersGetPendingList: this.getPending,
    ownersPostGDPR: this.post,
    ownersGetLogsList: this.getLogsList,
  });
}

export default new Routes().getPaths();
