import validationsDictionary from '../../core/old_common/validations';

export const validationRules = (data) => ({
  name: [
    validationsDictionary.isRequired(data.name),
    validationsDictionary.isInTextLength(data.name, 50),
  ],
});

export const setWeekDropdownDatas = [
  {
    label: 'general.weekly.monday',
    value: 5,
  },
  {
    label: 'general.weekly.tuesday',
    value: 6,
  },
  {
    label: 'general.weekly.wednesday',
    value: 7,
  },
  {
    label: 'general.weekly.thursday',
    value: 8,
  },
  {
    label: 'general.weekly.friday',
    value: 9,
  },
  {
    label: 'general.weekly.saturday',
    value: 10,
  },
  {
    label: 'general.weekly.sunday',
    value: 11,
  },
];
