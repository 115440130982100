export default {
  equipmentErrors: {
    2: 'ERR_CL_IN',
    3: 'ERR_INS_CL_0',
    4: 'ERR_INS_DEV',
    5: 'ERR_INS_DEV_0_MEMORY_EMPTY',
    6: 'ERR_DECODING',
    7: 'ERR_MD5',
    8: 'ERR_HARDWARE',
    9: 'ERR_READING',
  },
};