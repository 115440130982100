export default {
  relaysConfiguration: {
    tabRelays: 'Relé',
    tabSchedules: 'Časové rozvrhy',
    tabInfo: 'Informace',
    time: 'Doba aktivace relé {{count}}',
    delay: 'Doba prodlevy relé {{count}}',
    infoTime: 'Hodnota musí být od 1 do 60 sekund',
    infoDelay: 'Hodnota musí být od 0 do 10 sekund',
    infoCustomOpenDoorTime: 'Hodnota musí být od 1 do 86400 sekund',
    relayNotassigned: 'Relé nepřiděleno',
    goBack: 'Zpět',
    type: 'Typ relé',
    keepDoorOpenSchedule: 'Rozvrh otevření dveří',
    customOpenDoorTimeSchedule: 'Uživatelský časový rozvrh otevření dveří',
    customOpenDoorTime: 'Čas otevření dveří',
    Relay: 'Relé',
  },
};
