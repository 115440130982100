export default {
  paramsName: {
    ACCESSCONTROL: 'Controllo di accesso',
    ACTIVEELEMENTS: 'Eventi attivi',
    CONFIGRELE1: 'Impostazione relè 1 (impulso o ON / OFF)',
    CONFIGRELE2: 'Impostazione relè 2 (impulso o ON / OFF)',
    INSTAL: 'Installler',
    POWER: 'Alimentazione elettrica',
    PRICE1: 'Valore di sottrazione per relè 1',
    PRICE2: 'Valore di sottrazione per relè 2',
    RELAYS: 'Relè',
    TEMPRELE1: 'Relè impostazione ora 1',
    TEMPRELE2: 'Relè impostazione ora 2',
    TEVENT: 'Scheda eventi',
    TIMEZONE: 'Identificatore del fuso orario',
    TMEM: 'Scheda di memoria',
    MessageBox_ActivationRelay: 'Attivazione relè',
    MessageBox_ConnectionError: 'Errore di connessione',
    MessageBox_DeviceAccepted: 'Dispositivo accettato',
    MessageBox_DeviceRecieved: 'Dispositivo ricevuto',
    MessageBox_FormatMemoryError: 'Formato errato in memoria',
    MessageBox_InvalidDevice: 'Dispositivo non valido',
    MessageBox_MemoryExtracted: 'Memoria estratta',
    MessageBox_MemoryInserted: 'Memoria inserita',
    MessageBox_NotInMemory: 'Non trovato in memoria',
    MessageBox_OutOfSchedule: 'Fuori programma',
    MessageBox_PowerUp: 'SOPRA',
    MessageBox_RelayState: 'Modifica dello stato del relè',
    MessageBox_Message: 'Configura eventi attivi',
    CONFIGRELAYSPIN1: 'Relè sulla programmazione PIN. Canale: 1',
    CONFIGRELAYSPIN2: 'Relè sulla programmazione PIN. Canale: 2',
    CONFIGRELAYSPIN3: 'Relè sulla programmazione PIN. Canale: 3',
    CONFIGRELAYSPIN4: 'Relè sulla programmazione PIN. Canale: 4',
    DISABLEMANUALPROG: 'Vietare la Programmazione Mannual',
    DISABLERADIOPROG: 'Vietare la programmazione radio',
    GO: 'Partire',
    GOPRO: 'Go-Pro',
    HFADVANCE: 'HF-ADVANCE',
    IMPBIRELE1: 'Configura il relè 1 come bistabile',
    MINIDDEVICE: 'Dispositivo minimo',
    PIN: 'PIN',
    PINCADUCAT: 'PIN scaduto',
    CONFIGRELAYSNEW1: 'Relè attivi sul canale 1',
    CONFIGRELAYSNEW2: 'Relè attivi sul canale 2',
    CONFIGRELAYSNEW3: 'Relè attivi sul canale 3',
    CONFIGRELAYSNEW4: 'Relè attivi sul canale 4',
    CONFIGRELE3: 'Impostazione relè 3 (impulso o ON / OFF)',
    CONFIGRELE4: 'Impostazione relè 4 (impulso o ON / OFF)',
    TEMPRELE3: 'Relè impostazione ora 3',
    TEMPRELE4: 'Relè impostazione ora 4',
    PRICE3: 'Valore di sottrazione per relè 3',
    PRICE4: 'Valore di sottrazione per relè 4',
    FREEACTIVE: 'Gruppo attivo su sistema FREE',
    FREEGROUP0LOCKED: 'Gruppo 0 su sistema FREE bloccato',
    FREEGROUPNUM: 'Numero di gruppo sul sistema FREE',
    FREEGROUPRFPROGALLOWED: 'Modalità di programmazione radio consentita su sistema FREE',
    BADGE_MAITRE01: 'Codice principale 1',
    BADGE_MAITRE10: 'Codice principale 10',
    BADGE_MAITRE2: 'Codice principale 2',
    BADGE_MAITRE3: 'Codice principale 3',
    BADGE_MAITRE4: 'Codice principale 4',
    BADGE_MAITRE5: 'Codice principale 5',
    BADGE_MAITRE6: 'Codice principale 6',
    BADGE_MAITRE7: 'Codice principale 7',
    BADGE_MAITRE8: 'Codice principale 8',
    BADGE_MAITRE9: 'Codice principale 9',
    SITECODE: 'Codice del sito',
    W26FORMAT: 'Formato Wiegand 26',
    Configuration_Params_W26FORMAT0: 'Codice (16 bit) + Codice sito (8 bit)',
    Configuration_Params_W26FORMAT1: 'Codice (24 bit)',
    Configuration_Params_BUTT_NO: 'Pulsante normalmente aperto',
    Configuration_Params_BUTT_NC: 'Pulsante normalmente chiuso',
    CONFIGRELE_ANTIPANIC: 'Panico',
    ANTIPANICCONFIGRELE1: 'Impostazione relè 1 (impulso o ON / OFF o panico)',
    ANTIPANICCONFIGRELE2: 'Impostazione relè 2 (impulso o ON / OFF o panico)',
    ANTIPANICCONFIGRELE3: 'Impostazione relè 3 (impulso o ON / OFF o panico)',
    ANTIPANICCONFIGRELE4: 'Impostazione relè 4 (impulso o ON / OFF o panico)',
    RELE1IMP: 'Impostazione relè 1 (impulso o ON / OFF)',
    RELE1TEMP: 'Impostazione del tempo dipende 1',
    RELE2IMP: 'Impostazione relè 2 (impulso o ON / OFF)',
    RELE2TEMP: "L'impostazione dell'ora dipende 2",
    RELE3IMP: 'Impostazione relè 3 (impulso o ON / OFF)',
    RELE3TEMP: 'Impostazione del tempo dipende 3',
    RELE4IMP: 'Impostazione relè 4 (impulso o ON / OFF)',
    RELE4TEMP: "L'impostazione del tempo dipende 4",
    RELAYPRICE1: 'Valore di sottrazione per relè 1',
    RELAYPRICE2: 'Valore di sottrazione per relè 2',
    RELAYPRICE3: 'Valore di sottrazione per relè 3',
    RELAYPRICE4: 'Valore di sottrazione per relè 4',
    PASSWORD: "Parola d'ordine",
    CONNECTIVITY_EXTENSION: 'Ling card installata',
    ACTIVATIONKEY: 'Chiave di attivazione',
    UNIQUEKEY: 'Chiave unica',
    TIMEZONEID: 'Fuso orario',
    EMMITTERNOTUSEDEVENTTIME: "Non utilizzare l'ora dell'evento del trasmettitore",
    DOOROPENEDEVENTTIME: 'Evento di apertura della porta',
    ACTIVEEVENTSTOSD: 'Impostazione degli eventi A5K',
    SCHEDULESDATA: 'Pianifica i dati',
    APNCONFIG: 'Impostazione APN',
    A5KPROXY: 'Configurare il proxy A5K',
    Configuration_Params_ConfigRele_Impulsional: 'Impulsional',
    Configuration_Params_ConfigRele_Biestable: 'Bistabile',
    Configuration_Params_ConfigRele_Antipanic: 'Antipanico',
    Configuration_Params_Yes: 'Se',
    Configuration_Params_No: 'No',
    Configuration_Params_PIN: 'PIN',
    Configuration_Params_PINCADUCAT: 'PIN scaduto',
    Configuration_Params_ValueTEVENTS: 'Se',
    SIP_ADDRESS: 'Indirizzo SIP',
    Sip_Status: 'Stato SIP',
    fw_version: 'Versione del firmware',
    hw_version: 'Versione hardware',
    mac_address: 'Indirizzo MAC',
    imei: 'Numero IMEI',
    iccid: 'Numero ICCID',
    connection_type: 'Tipo di connessione',
    level: 'Livello del segnale',
    dbm: 'decibel',
    mcc: 'MCC',
    mnc: 'MNC',
    lac: 'LAC',
    call_max_duration: 'Durata massima della chiamata',
    call_outgoing_timeout: 'Timeout chiamate in uscita',
    relay_dtmf_hang_call: 'Attivazione relè tramite tono',
    Relay_Sound: 'Lingua del messaggio porta aperta',
    mic_volume: 'Volume del microfono',
    speaker_volume: "Volume dell'altoparlante",
    access_code: "Codice d'accesso",
    true: 'Vero',
    false: "'Falso",
    door_english: 'Inglese',
    door_spanish: 'Spagnolo',
    door_french: 'Francese',
    none: 'Nessuna',
    imsi: 'IMSI',
    cell_id: 'CELL ID',
    Configuration_Params_LOW: 'Basso',
    Configuration_Params_HIGH: 'Alto',
    Configuration_Params_DEBUG: 'Debug',
    Configuration_Params_UNUSED: 'Inutilizzato',
    Configuration_Params_NCOLS: '[Porta 1] Finecorsa di apertura normalmente chiuso',
    Configuration_Params_NCCLS: '[Porta 1] Finecorsa di chiusura normalmente chiuso',
    Configuration_Params_NOOLS: '[Porta 1] Finecorsa di apertura normalmente aperto',
    Configuration_Params_NOCLS: '[Porta 1] Finecorsa di chiusura normalmente aperto',
    Configuration_Params_NCSC: 'Catena di sicurezza normalmente chiusa',
    Configuration_Params_NOSC: 'Catena di sicurezza normalmente aperta',
    Configuration_Params_entranceNC: 'APB accesso normalmente chiuso',
    Configuration_Params_exitNC: 'APB uscita normalmente chiuso',
    Configuration_Params_entranceNO: 'APB accesso normalmente aperto',
    Configuration_Params_exitNO: 'APB uscita normalmente aperto',
    Configuration_Params_D2_NCOLS: '[Porta 2] Finecorsa di apertura normalmente chiuso',
    Configuration_Params_D2_NCCLS: '[Porta 2] Finecorsa di chiusura normalmente chiuso',
    Configuration_Params_D2_NOOLS: '[Porta 2] Finecorsa di apertura normalmente aperto',
    Configuration_Params_D2_NOCLS: '[Porta 2] Finecorsa di chiusura normalmente aperto',
    Configuration_Params_NSCLS: '[Porta 1] Sensore finecorsa in apertura',
    Configuration_Params_NSOLS: '[Porta 1] Sensore finecorsa di chiusura',
    Configuration_Params_D2_HSOLS: '[Porta 2] Sensore finecorsa in apertura',
    Configuration_Params_D2_HSCLS: '[Porta 2] Sensore finecorsa di chiusura',
    Configuration_Params_OPENREMOTE: 'HONOA Consentire apertura da remoto',
    Configuration_Params_HANDSFREEALLOWED: 'HONOA Consentire vivavoce Bluetooth (BETA)',
    APBKMODE: 'Modalità anti-passback (APB)',
    Configuration_Params_MODE1: 'Modalità 1 (monocanale ingresso/uscita, uscita libera)',
    Configuration_Params_MODE2: 'Modalità 2 (monocanale entrata / uscita)',
    Configuration_Params_MODE3: 'Modalità 3 (canale 1 entrata / canale 2 uscita)',
    Configuration_Params_MODE4: 'Modalità 4 (canale 1 e 3 entrata / canale 2 e 4 uscita)',
    APBKTIMEBACK: 'Tempo di ripristino Anti-Passback in minuti (Anti-timeback)',
    Configuration_Params_HSCLS: '[Porta 1] Sensore finecorsa di chiusura',
    Configuration_Params_HSOLS: '[Porta 1] Sensore finecorsa in apertura',
    Configuration_Params_MIN: 'Minimo',
    DATAUSAGE: 'Utilizzo dei dati',
    INPUTS: 'Ingressi',
    INPUT1NAME: 'Immettere 1 nome',
    INPUT2NAME: 'Immettere 2 nome',
    INPUT3NAME: 'Sensore radio 3 nome',
    INPUT4NAME: 'Sensore radio 4 nome',
    INPUT5NAME: 'Sensore radio 5 nome',
    INPUT6NAME: 'Sensore radio 6 nome',
    INPUT7NAME: 'Sensore radio 7 nome',
    INPUT8NAME: 'Sensore radio 8 nome',
    INPUT9NAME: 'Sensore radio 9 nome',
    INPUT10NAME: 'Sensore radio 10 nome',
    INPUT1MODE: 'Modalità input 1',
    INPUT2MODE: 'Modalità input 2',
    INPUT3MODE: 'Modalità sensore radio 3',
    INPUT4MODE: 'Modalità sensore radio 4',
    INPUT5MODE: 'Modalità sensore radio 5',
    INPUT6MODE: 'Modalità sensore radio 6',
    INPUT7MODE: 'Modalità sensore radio 7',
    INPUT8MODE: 'Modalità sensore radio 8',
    INPUT9MODE: 'Modalità sensore radio 9',
    INPUT10MODE: 'Modalità sensore radio 10',
    INPUT1KEY: 'Tasto input 1',
    INPUT2KEY: 'Tasto input 2',
    INPUT3KEY: 'Tasto sensore radio 3',
    INPUT4KEY: 'Tasto sensore radio 4',
    INPUT5KEY: 'Tasto sensore radio 5',
    INPUT6KEY: 'Tasto sensore radio 6',
    INPUT7KEY: 'Tasto sensore radio 7',
    INPUT8KEY: 'Tasto sensore radio 8',
    INPUT9KEY: 'Tasto sensore radio 9',
    INPUT10KEY: 'Tasto sensore radio 10',
    SENSORS: 'Sensori',
    incorrectVertexKey: 'il codice inserito non è valido',
    correctVertexKey: 'Il codice inserito è valido',
    OPENREMOTE: 'HONOA Consentire apertura da remoto',
    HANDSFREEALLOWED: 'HONOA Consentire apertura hands-free Bluetooth (BETA)',
    Name: 'Nome',
    UniqueIdentity: 'Identificativo unico',
    Product: 'Disposotivo',
    SerialNumber: 'Numero di serie',
    WIEGANDCONFIG: 'Configurazione Wiegand',
    ALLOWNOTIFICATIONS: 'HONOA Mostra stato porta',
    LOCALEVENTMANAGEMENTCONFIG: 'Configurazione eventi',
  },
};
