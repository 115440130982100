import { RESET_PAGE_NUMBER, RESET_TOTAL_PAGE_NUMBER, SET_PAGE_NUMBER } from './constants';
import { GROUP_GET_LIST } from '../../old_to_refact/reducers/groups';

import {
  UNIVERSAL_GROUP_GET_LIST,
  UNIVERSAL_GROUPS_EQUIPMENT_RELAYS_GET,
  UNIVERSAL_GROUPS_FACILITIES,
} from '../../old_to_refact/reducers/universalGroups';
import {
  GET_FIELD,
  INSTALATIONS_GET,
  RECYCLE_INSTALLATIONS_GET,
} from '../../old_to_refact/reducers/instalations';
import { GET_SCHEDULES } from '../../schedule/contants';
import { getCookie_pageNumbers, saveCookies } from '../../../core/old_common/utils/cookiesManager';
import { OWNERS_PENDING_LIST_GET, OWNERS_SINGLELIST_GET } from '../../owner/constants';
import { GET_LIST_GROUP_FOB_HISTORY } from '../../old_to_refact/actions/groups';
/* Redux state init */
const initialState = {
  pagesize: 15,
  pagenumber: getCookie_pageNumbers(),
  totalPages: 1,
};

export default function backendParamsReducer(state = initialState, action) {
  if (action.type !== undefined) {
    switch (action.type) {
      case SET_PAGE_NUMBER: {
        const newPageNumber = { ...state.pagenumber };
        newPageNumber[action.listType] = action.newPage;
        saveCookies({ pagenumbers: newPageNumber });
        return {
          ...state,
          pagenumber: newPageNumber,
        };
      }
      case RESET_PAGE_NUMBER:
        return { ...state, pagenumber: 1 };
      case RESET_TOTAL_PAGE_NUMBER:
        return { ...state, totalPages: 1 };
      case GET_SCHEDULES.SUCCESS:
      case UNIVERSAL_GROUP_GET_LIST.SUCCESS:
      case UNIVERSAL_GROUPS_FACILITIES.SUCCESS:
      case OWNERS_PENDING_LIST_GET.SUCCESS:
      case OWNERS_SINGLELIST_GET.SUCCESS:
      case GROUP_GET_LIST.SUCCESS:
      case UNIVERSAL_GROUPS_EQUIPMENT_RELAYS_GET.SUCCESS:
      case RECYCLE_INSTALLATIONS_GET.SUCCESS:
      case GET_LIST_GROUP_FOB_HISTORY.SUCCESS:
        return { ...state, totalPages: action.result.overallPages };
      case INSTALATIONS_GET.SUCCESS:
      case GET_FIELD.SUCCESS:
        return { ...state, totalPages: action.result.Data.overallPages };
      default:
        return state;
    }
  }
  return state;
}
