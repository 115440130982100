export default {
  remoteComunications: {
    index: 'Mutató',
    content: 'Tartalom',
    serialNumber: 'Gyártási szám',
    uniqueKey: 'Egyedi kulcs',
    operationsCode: 'Operációs kód',
    finalCode: 'Vége kód',
    progress: 'Fejlődés',
    status: 'Állapot',
    refresh: 'Frissítés',
    saveConfig: 'Konfiguráció mentése',
    readConfig: 'Konfiguráció olvasása',
    readEvents: 'Események olvasása',
    removeEvents: 'Események törlése',
    seeEvents: 'Események megtekintése',
    export: 'Exportálás',
    Completed: 'Befejezett',
    Received: 'Beérkezett',
    Processing: 'Feldolgozás',
  },
};
