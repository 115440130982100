import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import BoxUsers from '../../../../components/Facility/Group/Users/Box';
import {
  formatDateValidationLicence,
  idGetter,
  SELECTED_FIELD,
} from '../../../../components/Facility/Group/Users/config';
import {
  validationRegexEmail,
  validationRegexPhone,
} from '../../../../core/helpers/regexValidation';
import {
  importGroupUsers,
  deleteUserGroup,
  deleteUsersList,
  getGroupUsers,
  lockUser,
  postGroupUser,
  postGroupUserInvitationLicence,
  putUserCopyGroup,
  putUserMoveGroup,
  resetGroupUsers,
  unLockUser,
  updateUserGroup,
} from '../../../../modules/group/users/actions';

const FacilityGroupUserList = () => {
  const { id: idGroup, instalationId: idFacility } = useParams();
  const { groupUsers, groupUsersCounter } = useSelector((state) => state.groupUsers);
  const [data, setData] = useState(groupUsers);
  const [changes, setChanges] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const [selectedState, setSelectedState] = useState({});
  const [selectedCountState, setSelectedCountState] = useState(0);
  const [isOpenModalDeleteMultiUsers, setIsOpenModalDeleteMultiUsers] = useState(false);
  const [isOpenModalMoveMultiUsers, setIsOpenModalMoveMultiUsers] = useState(false);
  const [isOpenModalCopyMultiUsers, setIsOpenModalCopyMultiUsers] = useState(false);
  const [isOpenModalAddUser, setIsOpenModalAddUser] = useState(false);
  const [newUser, setNewUser] = useState(null);
  const [newUserErrors, setNewUserErrors] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getGroupUsers(idFacility, idGroup));
    return () => dispatch(resetGroupUsers());
  }, []);

  useEffect(() => {
    if (groupUsers) {
      setData(
        groupUsers.map((dataItem) => ({
          selected: false,
          ...dataItem,
        }))
      );
    }
  }, [groupUsers]);

  const onBlurSaveTable = (dataUser) => {
    if (changes) {
      dispatch(
        updateUserGroup(idFacility, idGroup, dataUser.id, {
          ...dataUser,
          validFrom: moment(dataUser?.validFrom).format(formatDateValidationLicence),
          validTo: moment(dataUser?.validTo).format(formatDateValidationLicence),
        })
      );
    }
  };

  const reSendUserInvitationLicence = (idUser) => {
    dispatch(postGroupUserInvitationLicence(idFacility, idGroup, idUser));
  };

  const deleteUserLicence = (idUser) => {
    dispatch(deleteUserGroup(idFacility, idGroup, idUser));
    setUserToDelete(null);
  };

  const deleteMultipleUsersLicence = () => {
    const idUsersList = [];
    for (const key in selectedState) {
      if (selectedState[key]) {
        idUsersList.push(key);
      }
    }
    dispatch(deleteUsersList(idFacility, idGroup, idUsersList));
    setIsOpenModalDeleteMultiUsers(false);
    setSelectedCountState(0);
    setSelectedState({});
  };

  const moveMultipleUsersLicence = (toFacilityID, toGroupID) => {
    const userList = [];
    for (const key in selectedState) {
      if (selectedState[key]) {
        userList.push(data.find((item) => item.id === key));
      }
    }

    dispatch(putUserMoveGroup(idFacility, idGroup, toFacilityID, toGroupID, userList));
    setIsOpenModalMoveMultiUsers(false);
    setSelectedCountState(0);
    setSelectedState({});
  };

  const copyMultiUsersLicence = (toFacilityID, toGroupID) => {
    const userList = [];
    for (const key in selectedState) {
      if (selectedState[key]) {
        userList.push(data.find((item) => item.id === key));
      }
    }

    dispatch(putUserCopyGroup(idFacility, idGroup, toFacilityID, toGroupID, userList));
    setIsOpenModalCopyMultiUsers(false);
  };

  const createNewUser = () => {
    const setErrors = {
      email: !newUser?.email?.match(validationRegexEmail),
      phone: newUser?.phone && !newUser?.phone?.match(validationRegexPhone),
    };
    setNewUserErrors(setErrors);
    let errorExist = false;

    for (const errorsKey in setErrors) {
      if (setErrors[errorsKey]) {
        errorExist = true;
      }
    }
    if (!errorExist) {
      dispatch(postGroupUser(idFacility, idGroup, newUser));
      setIsOpenModalAddUser(false);
    }
  };

  const onToggleLockUserLicence = (status, userId) => {
    if (status) {
      dispatch(unLockUser(idFacility, idGroup, userId));
    } else {
      dispatch(lockUser(idFacility, idGroup, userId));
    }
  };

  const importExcelUsers = (file) => {
    dispatch(importGroupUsers(idFacility, idGroup, file));
  };

  return (
    <BoxUsers
      items={
        data?.map((item) => ({
          ...item,
          validFrom: new Date(item.validFrom),
          validTo: new Date(item.validTo),
          [SELECTED_FIELD]: selectedState[idGetter(item)],
        })) || []
      }
      counter={groupUsersCounter}
      setData={setData}
      setChanges={setChanges}
      onBlurSaveTable={onBlurSaveTable}
      onResendAction={reSendUserInvitationLicence}
      onToggleLockUserLicence={onToggleLockUserLicence}
      setUserToDelete={setUserToDelete}
      userToDelete={userToDelete}
      onDeleteUser={deleteUserLicence}
      onDeleteMultiUsers={deleteMultipleUsersLicence}
      onMoveMultiUsers={moveMultipleUsersLicence}
      onCopyMultiUsers={copyMultiUsersLicence}
      onSelectedState={setSelectedState}
      selectedState={selectedState}
      selectedCountState={selectedCountState}
      setSelectedCountState={setSelectedCountState}
      isOpenModalDeleteMultiUsers={isOpenModalDeleteMultiUsers}
      setIsOpenModalDeleteMultiUsers={setIsOpenModalDeleteMultiUsers}
      isOpenModalMoveMultiUsers={isOpenModalMoveMultiUsers}
      setIsOpenModalMoveMultiUsers={setIsOpenModalMoveMultiUsers}
      isOpenModalCopyMultiUsers={isOpenModalCopyMultiUsers}
      setIsOpenModalCopyMultiUsers={setIsOpenModalCopyMultiUsers}
      setIsOpenModalAddUser={setIsOpenModalAddUser}
      isOpenModalAddUser={isOpenModalAddUser}
      setNewUser={setNewUser}
      newUserErrors={newUserErrors}
      onCreateNewUser={createNewUser}
      onImportExcelUsers={importExcelUsers}
    />
  );
};

export default FacilityGroupUserList;
