const SET_SIGNALR_CONNECTION_DATA = 'SET_SIGNALR_CONNECTION_DATA';
const SET_SIGNALR_CONNECTION = 'SET_SIGNALR_CONNECTION';
const SET_NEW_MESSAGE_HUB_FUNCTION = 'SET_NEW_MESSAGE_HUB_FUNCTION';
const SET_ASSISTANT_CHECK_SUCCESS_FUNCTION = 'SET_ASSISTANT_CHECK_SUCCESS_FUNCTION';
const SET_SIGNALR_CONNECTION_STATUS = 'SET_SIGNALR_CONNECTION_STATUS';
const SET_SIGNALR_CALL_ERROR = 'SET_SIGNALR_CALL_ERROR';
const SET_SIGNALR_POPUP = 'SET_SIGNALR_POPUP';
const SET_SIGNALR_SHOW_POPUP = 'SET_SIGNALR_SHOW_POPUP';
const RESET_SIGNALR_POPUP = 'RESET_SIGNALR_POPUP';
const SET_SIGNALR_PRODUCT_ID = 'SET_SIGNALR_PRODUCT_ID';
const RESET_SIGNALR_PRODUCT_ID = 'RESET_SIGNALR_PRODUCT_ID';

export default {
  SET_SIGNALR_CONNECTION_DATA,
  SET_SIGNALR_CONNECTION,
  SET_NEW_MESSAGE_HUB_FUNCTION,
  SET_ASSISTANT_CHECK_SUCCESS_FUNCTION,
  SET_SIGNALR_CONNECTION_STATUS,
  SET_SIGNALR_CALL_ERROR,
  SET_SIGNALR_POPUP,
  SET_SIGNALR_SHOW_POPUP,
  RESET_SIGNALR_POPUP,
  SET_SIGNALR_PRODUCT_ID,
  RESET_SIGNALR_PRODUCT_ID,
};

export const setSignalRConnectionData = (connectionData) => ({
  type: SET_SIGNALR_CONNECTION_DATA,
  connectionData,
});

export const setSignalRConnection = (connection) => ({ type: SET_SIGNALR_CONNECTION, connection });

export const setNewMessageHubFunction = (hubFunction) => ({
  type: SET_NEW_MESSAGE_HUB_FUNCTION,
  hubFunction,
});

export const setAssistantCheckSuccessFunction = (onSuccessFunction) => ({
  type: SET_ASSISTANT_CHECK_SUCCESS_FUNCTION,
  onSuccessFunction,
});

export const setSignalRConnectionStatus = (isConnected) => ({
  type: SET_SIGNALR_CONNECTION_STATUS,
  isConnected,
});

export const setSignalRCallError = (errorFunction) => ({
  type: SET_SIGNALR_CALL_ERROR,
  errorFunction,
});

export const setNeedPopup = () => ({
  type: SET_SIGNALR_POPUP,
  needPopup: true,
});

export const showPopUp = (showPopup, message) => ({
  type: SET_SIGNALR_SHOW_POPUP,
  showPopup,
  message,
});

export const resetSignalRPopup = () => ({
  type: RESET_SIGNALR_POPUP,
});

export const setProductId = (productId) => ({
  type: SET_SIGNALR_PRODUCT_ID,
  productId,
});

export const resetProductId = () => ({
  type: RESET_SIGNALR_PRODUCT_ID,
});
