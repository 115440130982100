import { Field } from '@jcm-technologies/uikit/dist/atoms/Field';
import { Input } from '@jcm-technologies/uikit/dist/atoms/Input';
import { Box, Flex } from '@jcm-technologies/uikit/dist/atoms/Layout';
import { useTranslation } from 'react-i18next';
import { Switch } from '@jcm-technologies/uikit/dist/atoms/Switch';
import { Button } from '@jcm-technologies/uikit/dist/atoms/Button';
import { Icon } from '@jcm-technologies/uikit/dist/atoms/Icon';
import IconSettings from '@jcm-technologies/uikit/dist/atoms/Icons/IconSettings';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { getContrastColor } from '../../../core/helpers/colorManager';
import ToolTip from '../../../old_components/ToolTip';

export function ScheduleForm({
  data,
  onChangeAction,
  isActiveHolidays = false,
  handleGoToHolidays,
  setIsActiveHolidays,
  handleBlur,
  errors,
}) {
  const { color1 } = useSelector((state) => state.tenants);
  const { roleMask } = useSelector((state) => state.user.user);
  const { holidaysActive } = useSelector((state) => state.holidays);
  const { t } = useTranslation();

  return (
    <>
      <Box paddingX={4}>
        <Field label={t('form.name')} required>
          <Input
            name='name'
            id='schedule-name'
            placeholderMessage={t('form.name')}
            value={data?.name || ''}
            onChange={onChangeAction}
            onBlur={(name) => handleBlur(name)}
            hasError={errors?.name?.error}
            errorMessage={errors?.name?.errorMessage}
            maxLength={50}
          />
        </Field>
      </Box>
      <Flex alignItems='center' justifyContent='flex-end' marginY={1} paddingX={4}>
        <Flex flexDirection='column' alignItems='center'>
          <Field
            label={t('form.activateHolidays')}
            display='flex'
            marginTop={0}
            marginBottom={0}
            marginRight={1}
            style={{ alignItems: 'center' }}
          >
            <ToolTip id='holidays' />
            <Switch
              marginLeft={2}
              marginBottom={0}
              sizeInput='small'
              themeColor={color1}
              name='holidayEntityId'
              id='holidayEntityId'
              title={t('form.activateHolidays')}
              disabled={!holidaysActive[0]?.id}
              defaultValue={isActiveHolidays}
              action={(isSelected, name) => {
                setIsActiveHolidays(!isActiveHolidays);
                onChangeAction(name, isSelected ? holidaysActive[0]?.id : '');
              }}
            />
          </Field>
        </Flex>
        {roleMask !== 4 && roleMask !== 128 && roleMask !== 256 && roleMask !== 8 && (
          <Button
            sizeButton='small'
            backgroundColor={color1}
            color={getContrastColor(color1)}
            action={() => handleGoToHolidays()}
          >
            <Icon sizeIcon='display16' marginRight={1} color={getContrastColor(color1)}>
              <IconSettings />
            </Icon>
            {t('title.holidaysDays')}
          </Button>
        )}
      </Flex>
    </>
  );
}

ScheduleForm.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
    holidayEntityId: PropTypes.string,
  }).isRequired,
  onChangeAction: PropTypes.func.isRequired,
  isActiveHolidays: PropTypes.bool.isRequired,
  handleGoToHolidays: PropTypes.func.isRequired,
  setIsActiveHolidays: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  errors: PropTypes.shape({
    name: PropTypes.shape({
      error: PropTypes.bool,
      errorMessage: PropTypes.string,
    }),
  }).isRequired,
};
