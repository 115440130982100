export default {
  instalations: {
    groupsTab: 'Csoportok',
    equipmentsTab: 'Eszközök',
    addGroup: 'Csoport hozzáadása',
    deleteConfirmation: 'Biztosan el szeretné távolítani ezt a létesítményt?',
    add: 'Létesítmény hozáadása',
    config: 'Létesítmény szerkesztése',
    notAllow: 'A hozzárendelt eszközök nem teszik lehetővé az ütemezést',
    allDevicesConnected: 'Minden csatlakoztatva',
    allDevicesDisconnected: 'Utolsó karbantartás:',
    includeReservations: 'Foglalásokat is tartalmaz?',
    includeConfigFields: 'Konfigurációs mezők felvétele?',
    acceptExportPopUpButton: 'Exportálás',
    exportPopUpTitle: 'Csoportok exportálása',
    nameTableheader: 'Név',
    allEquipmentsStatusOk: 'Minden eszköz csatlakoztatva',
    numberOfConnectedDevices: 'Talált eszközök',
    filters: 'Szűrők',
    criticalDeleteTitle: 'KRITIKUS CSELEKVÉS, AMELYET NEM LEHET VISSZACSINÁLNI',
    firstAreYouSureCriticalDelete: 'Biztosan törölni szeretné a',
    secondAreYouSureCriticalDelete: '',
    noGoBack: 'EZT A LÉPÉST NEM LEHET MAJD VISSZAVONNI',
    transfer: 'Transzfer',
    transferPopUpLabel: 'A telepítést irányító rendszergazda',
    transferPopUpSelector: 'Felhasználó kiválasztása',
    restore: 'Visszaállítás',
    recycleBin: 'Kuka',
    creation: 'Alkotás',
    saveMemory: 'Tartalékoljon memóriát',
    substitution: 'Helyettesítés',
    fob: 'Távirányítók',
    equipment: 'Berendezések',
    deletion: 'Törlés',
    moved: 'Elköltözött',
    locked: 'Zárt',
    unlocked: 'Feloldva'
  },
};
