export default {
  fobsPopUps: {
    instalations: 'Instalace',
    groups: 'Skupiny',
    move: 'Přesunout',
    copy: 'Kopírovat',
    remove: 'Smazat',
    removeQuestion: 'Chceš smazat následující položky?',
    selectedItemsShow1: ' Chcete odstranit ',
    selectedItemsShow2: '  zvolených prvků?',
    warningDeleteFobs:
      'UPOZORNĚNÍ: Je možné, že je zvolených více prvků, než které vidíte na obrazovce.',
    selectedItemsMove1: 'Chcete přesunout ',
    selectedItemsCopy1: 'Chcete zkopírovat ',
    add: 'Přidat',
    selectedItemsAdd1: 'Přejete si přidat',
  },
};
