import _, { chunk } from 'lodash';

const filterByType = (values, type) => values.filter((element) => element.type === type);

const generateFields = (values = [], type, rows, parentLevel = '') => {
  const typeValues = filterByType(values, type);
  const visibleFields = typeValues.filter((element) => element.visible);
  const selectedFields = visibleFields.map((element) => ({
    type: 'input',
    placeHolder: element.value,
    value: element.tag,
    key: element.tag,
    parentLevel,
    id: element.tag,
  }));

  return chunk(selectedFields, rows);
};

const generateRows = (values = [], type, isEditable) => {
  const typeValues = filterByType(values, type);
  const visibleFields = typeValues.filter((element) => element.visible);
  const selectedFields = visibleFields.map((element) => ({
    key: element.tag,
    name: element.value,
    sortable: true,
    filterable: true,
    editable: isEditable,
    tooltip: element.value,
  }));

  return selectedFields;
};

// --------------------------------------------------------------------------------------------------------
// ------------------CREATE AND MANAGE ATTRIBUTES IN STATES OBJECTS AND ARRAYS-----------------------------
// --------------------------------------------------------------------------------------------------------

const getNewItem = (attributesArray, item, attr) => {
  if (attributesArray.length) {
    const foundAttr = attributesArray.find((itemAttr) => itemAttr.tag === attr.key);
    if (foundAttr) {
      item[attr.id] = foundAttr.value;
    } else {
      item[attr.id] = '';
    }
  }
  return item;
};

const getArrayWithAttributes = (array, attributesArrayName, originalTenants) => {
  const newArray = [...(array || [])];
  // eslint-disable-next-line no-unused-vars
  let attributes = generateFields(originalTenants, 5);
  attributes = _.flattenDeep(attributes).map((attr) => {
    const reasignedAttr = { [attr.id]: '' };
    newArray.map((item) => {
      const attributesArray = [...(item[attributesArrayName] || [])];
      return getNewItem(attributesArray, item, attr);
    });
    return reasignedAttr;
  });
  return newArray;
};

// --------------------------------------------------------------------------------------------------------
// ------------------CREATE AND MANAGE ATTRIBUTES IN STATES OBJECTS AND ARRAYS-----------------------------
// --------------------------------------------------------------------------------------------------------

const getArrayWithReasignedAttributes = (array, item, attr) => {
  array.map((fobAtt) => {
    if (fobAtt.tag === attr.key) {
      fobAtt.value = item[attr.key];
    }
    return fobAtt;
  });
  return array;
};

const getNewAttributeInToArray = (array, item, attr) => {
  array.push({
    id: '00000000-0000-0000-0000-000000000000',
    fobId: item.id,
    type: 5,
    tag: attr.key,
    value: item[attr.key],
  });
  return array;
};

const getItemWithAttributtes = (item, arrayName, array, originalTenants) => {
  const attributes = generateFields(originalTenants, 5);
  _.flattenDeep(attributes).forEach((attr) => {
    const foundAttr = item[attr.key];
    if (foundAttr !== undefined) {
      const foundAttInItem = array.find((x) => x.tag === attr.key);
      if (foundAttInItem) {
        item[arrayName] = getArrayWithReasignedAttributes([...item[arrayName]], item, attr);
      } else {
        item[arrayName] = getNewAttributeInToArray([...item[arrayName]], item, attr);
        delete item[attr.key];
      }
    }
  });
  return item;
};

export {
  generateFields,
  filterByType,
  generateRows,
  getItemWithAttributtes,
  getArrayWithAttributes,
};
