export default {
  paramsName: {
    A5KPROXY: 'Konfigurieren Sie den A5K-Proxy',
    ACCESSCONTROL: 'Zugangskontrolle',
    ACTIVATIONKEY: 'Aktivierungsschlüssel',
    ACTIVEELEMENTS: 'Aktive Ereignisse',
    ACTIVEEVENTSTOSD: 'Festlegen von A5K-Ereignissen',
    ANTIPANICCONFIGRELE1: 'Relais 1 einstellen (Impuls oder EIN / AUS oder Panik)',
    ANTIPANICCONFIGRELE2: 'Relais 2 einstellen (Impuls oder EIN / AUS oder Panik)',
    ANTIPANICCONFIGRELE3: 'Relais 3 einstellen (Impuls oder EIN / AUS oder Panik)',
    ANTIPANICCONFIGRELE4: 'Relais 4 einstellen (Impuls oder EIN / AUS oder Panik)',
    APBKMODE: 'AntiPassback-Modus (APB)',
    APBKTIMEBACK: 'Anti-Passback Rückstellzeit in Minuten (Anti-Timeback)',
    APNCONFIG: 'APN-Einstellung',
    BADGE_MAITRE01: 'Mastercode 1',
    BADGE_MAITRE10: 'Mastercode 10',
    BADGE_MAITRE2: 'Mastercode 2',
    BADGE_MAITRE3: 'Mastercode 3',
    BADGE_MAITRE4: 'Mastercode 4',
    BADGE_MAITRE5: 'Mastercode 5',
    BADGE_MAITRE6: 'Mastercode 6',
    BADGE_MAITRE7: 'Mastercode 7',
    BADGE_MAITRE8: 'Mastercode 8',
    BADGE_MAITRE9: 'Mastercode 9',
    CONFIGRELAYSNEW1: 'Aktive Relais auf Kanal 1',
    CONFIGRELAYSNEW2: 'Aktive Relais auf Kanal 2',
    CONFIGRELAYSNEW3: 'Aktive Relais auf Kanal 3',
    CONFIGRELAYSNEW4: 'Aktive Relais auf Kanal 4',
    CONFIGRELAYSPIN1: 'Relais zur PIN-Programmierung. Kanal 1',
    CONFIGRELAYSPIN2: 'Relais zur PIN-Programmierung. Kanal: 2',
    CONFIGRELAYSPIN3: 'Relais zur PIN-Programmierung. Kanal: 3',
    CONFIGRELAYSPIN4: 'Relais zur PIN-Programmierung. Kanal: 4',
    CONFIGRELE1: 'Relais 1 einstellen (Impuls oder EIN / AUS)',
    CONFIGRELE2: 'Relais 2 einstellen (Impuls oder EIN / AUS)',
    CONFIGRELE3: 'Einstellung von Relais 3 (Impuls oder EIN / AUS)',
    CONFIGRELE4: 'Einstellung von Relais 4 (Impuls oder EIN / AUS)',
    CONFIGRELE_ANTIPANIC: 'Panik',
    CONNECTIVITY_EXTENSION: 'Ling-Karte installiert',
    Configuration_Params_ConfigRele_Antipanic: 'Antipanic',
    Configuration_Params_ConfigRele_Biestable: 'Biestable',
    Configuration_Params_ConfigRele_Impulsional: 'Impulsional',
    Configuration_Params_D2_HSCLS: '[Tor 2] Endschaltersensor schließen',
    Configuration_Params_D2_HSOLS: '[Tor 2] Endschaltersensor öffnen',
    Configuration_Params_D2_NCCLS: '[Tor 2] Normalerweise geschlossener Endschalter schließen',
    Configuration_Params_D2_NCOLS: '[Tor 2] Normalerweise geschlossener Endschalter öffnen',
    Configuration_Params_D2_NOCLS: '[Tor 2] Normalerweise geöffneter Endschalter schließen',
    Configuration_Params_D2_NOOLS: '[Tor 2] Normalerweise geöffneter Endschalter öffnen',
    Configuration_Params_DEBUG: 'Debuggen',
    Configuration_Params_GO: 'GO',
    Configuration_Params_GOPRO: 'GO-PRO',
    Configuration_Params_HANDSFREEALLOWED: 'HONOA Bluetooth Hands-free gestatten (BETA)',
    Configuration_Params_HFADVANCE: 'HF-ADVANCE',
    Configuration_Params_HIGH: 'Hoch',
    Configuration_Params_HSCLS: '[Tor 1] Endschaltersensor schließen',
    Configuration_Params_HSOLS: '[Tor 1] Endschaltersensor öffnen',
    Configuration_Params_LOW: 'Bass',
    Configuration_Params_MIN: 'Minimum',
    Configuration_Params_MODE1: 'Modus 1 (Einkanal Eingang/Ausgang, freier Ausgang)',
    Configuration_Params_MODE2: 'Modus 2 (Einkanal Eingang/ Ausgang)',
    Configuration_Params_MODE3: 'Modus 3 (Kanal 1 Eingang/ Kanal 2 Ausgang)',
    Configuration_Params_MODE4: 'Modus 4 (Kanal 1 und 3 Eingang/ Kanal 2 und 4 Ausgang)',
    Configuration_Params_NCCLS: '[Tor 1] Normalerweise geschlossener schließender Endschalter',
    Configuration_Params_NCOLS: '[Tor 1] Normalerweise geschlossener Öffnungs-Endschalter',
    Configuration_Params_NCSC: 'Sicherheitskette normalerweise geschlossen',
    Configuration_Params_NOCLS: '[Tor 1] Normalerweise öffnen schließender Endschalter',
    Configuration_Params_NOOLS: '[Tor 1] Normalerweise öffnen Öffnung-Endschalter',
    Configuration_Params_NOSC: 'Sicherheitskette normalerweise offen',
    Configuration_Params_NSCLS: '[Tor 1] Endschaltersensor öffnen',
    Configuration_Params_NSOLS: '[Tor 1] Endschaltersensor schließen',
    Configuration_Params_No: 'Nein',
    Configuration_Params_NoFixed: 'Nicht behoben',
    Configuration_Params_OPENREMOTE: 'HONOA Fernöffnung gestatten',
    Configuration_Params_PIN: 'PIN',
    Configuration_Params_PINCADUCAT: 'PIN abgelaufen',
    Configuration_Params_UNUSED: 'Unbenutzt',
    Configuration_Params_ValueTEVENTS: 'Ja',
    Configuration_Params_W26FORMAT0: 'Code (16 Bit) + Standortcode (8 Bit)',
    Configuration_Params_W26FORMAT1: 'Code (24 Bit)',
    Configuration_Params_Yes: 'Ja',
    Configuration_Params_entranceNC: 'Normalerweise geschlossener APB-Eingang',
    Configuration_Params_entranceNO: 'Normalerweise geöffneter APB-Eingang',
    Configuration_Params_exitNC: 'Normalerweise geschlossener APB-Ausgang',
    Configuration_Params_exitNO: 'Normalerweise geöffneter APB-Ausgang',
    Configuration_Params_BUTT_NO: 'Taster Schließer',
    Configuration_Params_BUTT_NC: 'Taster Öffner',
    DATAUSAGE: 'Datenverbrauch',
    DISABLEMANUALPROG: 'Manuelle Programmierung verbieten',
    DISABLERADIOPROG: 'Programmiermodus per Funk öffnen verbieten',
    DOOROPENEDEVENTTIME: 'Eröffnungstürereignis',
    EMMITTERNOTUSEDEVENTTIME: 'Verwenden Sie nicht die Ereigniszeit des Senders',
    FREEACTIVE: 'Aktive Gruppe auf FREE System',
    FREEGROUP0LOCKED: 'Gruppe 0 auf FREE System blockiert',
    FREEGROUPNUM: 'Gruppennummer im FREE System',
    FREEGROUPRFPROGALLOWED: 'Radio-Programmiermodus auf FREE System zulässig',
    GO: 'Gehen',
    GOPRO: 'Go-Pro',
    HANDSFREEALLOWED: 'HONOA Bluetooth Hands-free gestatten (BETA)',
    HFADVANCE: 'HF-ADVANCE',
    IMPBIRELE1: 'Konfiguriert Relais 1 als bistabil',
    INPUT10KEY: 'Sensorradio die Taste 10 ein',
    INPUT10MODE: 'Sensorradio den 10-Modus ein',
    INPUT10NAME: 'Sensorradio 10 Namen ein',
    INPUT1KEY: 'Geben Sie die Taste 1 ein',
    INPUT1MODE: 'Geben Sie den 1-Modus ein',
    INPUT1NAME: 'Geben Sie 1 Namen ein',
    INPUT2KEY: 'Geben Sie die Taste 2 ein',
    INPUT2MODE: 'Geben Sie den 2-Modus ein',
    INPUT2NAME: 'Geben Sie 2 Namen ein',
    INPUT3KEY: 'Sensorradio die Taste 3 ein',
    INPUT3MODE: 'Sensorradio den 3-Modus ein',
    INPUT3NAME: 'Sensorradio 3 Namen ein',
    INPUT4KEY: 'Sensorradio die Taste 4 ein',
    INPUT4MODE: 'Sensorradio den 4-Modus ein',
    INPUT4NAME: 'Sensorradio 4 Namen ein',
    INPUT5KEY: 'Sensorradio die Taste 5 ein',
    INPUT5MODE: 'Sensorradio den 5-Modus ein',
    INPUT5NAME: 'Sensorradio 5 Namen ein',
    INPUT6KEY: 'Sensorradio die Taste 6 ein',
    INPUT6MODE: 'Sensorradio den 6-Modus ein',
    INPUT6NAME: 'Sensorradio 6 Namen ein',
    INPUT7KEY: 'Sensorradio die Taste 7 ein',
    INPUT7MODE: 'Sensorradio den 7-Modus ein',
    INPUT7NAME: 'Sensorradio 7 Namen ein',
    INPUT8KEY: 'Sensorradio die Taste 8 ein',
    INPUT8MODE: 'Sensorradio den 8-Modus ein',
    INPUT8NAME: 'Sensorradio 8 Namen ein',
    INPUT9KEY: 'Sensorradio die Taste 9 ein',
    INPUT9MODE: 'Sensorradio den 9-Modus ein',
    INPUT9NAME: 'Sensorradio 9 Namen ein',
    INPUTS: 'Eingänge',
    INSTAL: 'Installateur Code',
    MINIDDEVICE: 'Minimales Gerät',
    MessageBox_ActivationRelay: 'Relaisaktivierung',
    MessageBox_ConnectionError: 'Verbindungsfehler',
    MessageBox_DeviceAccepted: 'Akzeptiertes Gerät',
    MessageBox_DeviceRecieved: 'Empfangenes Gerät',
    MessageBox_FormatMemoryError: 'Falsches Format im Speicher',
    MessageBox_InvalidDevice: 'Gerät ungültig',
    MessageBox_MemoryExtracted: 'Speicher entfernt',
    MessageBox_MemoryInserted: 'Speicher eingesteckt ',
    MessageBox_Message: 'Konfigurieren Sie aktive Ereignisse',
    MessageBox_NotInMemory: 'Nicht im Speicher gefunden',
    MessageBox_OutOfSchedule: 'Außerhalb des Zeitplans',
    MessageBox_PowerUp: 'ON',
    MessageBox_RelayState: 'Änderung des Relaisstatus',
    Name: 'Nome',
    OPENREMOTE: 'HONOA Fernöffnung gestatten',
    PASSWORD: 'Passwort',
    PIN: 'STIFT',
    PINCADUCAT: 'PIN abgelaufen',
    POWER: 'Spannungsversorgung',
    PRICE1: 'Subtraktionswert für Relais 1',
    PRICE2: 'Subtraktionswert für Relais 2',
    PRICE3: 'Subtraktionswert für Relais 3',
    PRICE4: 'Subtraktionswert für Relais 4',
    Product: 'Gerät',
    RELAYPRICE1: 'Subtraktionswert für Relais 1',
    RELAYPRICE2: 'Subtraktionswert für Relais 2',
    RELAYPRICE3: 'Subtraktionswert für Relais 3',
    RELAYPRICE4: 'Subtraktionswert für Relais 4',
    RELAYS: 'Relais',
    RELE1IMP: 'Einstellung Relais 1 (Impuls oder EIN / AUS)',
    RELE1TEMP: 'Zeiteinstellung abhängig 1',
    RELE2IMP: 'Einstellung von Relais 2 (Impuls oder EIN / AUS)',
    RELE2TEMP: 'Zeiteinstellung abhängig 2',
    RELE3IMP: 'Einstellung von Relais 3 (Impuls oder EIN / AUS)',
    RELE3TEMP: 'Zeiteinstellung abhängig 3',
    RELE4IMP: 'Einstellung von Relais 4 (Impuls oder EIN / AUS)',
    RELE4TEMP: 'Zeiteinstellung abhängig 4',
    Relay_Sound: 'Tür offen Nachrichtensprache',
    SCHEDULESDATA: 'Daten planen',
    SENSORS: 'Sensoren',
    SITECODE: 'Site-Code',
    SerialNumber: 'Seriennummer',
    Sip_Address: 'SIP-Adresse',
    Sip_Status: 'SIP-Status',
    TEMPRELE1: 'Zeiteinstellrelais 1',
    TEMPRELE2: 'Zeiteinstellrelais 2',
    TEMPRELE3: 'Zeiteinstellrelais 3',
    TEMPRELE4: 'Zeiteinstellrelais 4',
    TEVENT: 'Ereignispeicherkarte',
    TIMEZONE: 'Zeitzonen-ID',
    TIMEZONEID: 'Zeitzone',
    TMEM: 'Speicherkarte',
    UNIQUEKEY: 'Einzigartiger Schlüssel',
    UniqueIdentity: 'Unique Identifier',
    W26FORMAT: 'Wiegand 26 format',
    access_code: 'Zugangscode',
    call_max_duration: 'Maximale Anrufdauer',
    call_outgoing_timeout: 'Zeitlimit für ausgehende Anrufe',
    cell_id: 'CELL ID',
    connection_type: 'Verbindungstyp',
    correctVertexKey: 'Der eingegebene Code ist gültig',
    dbm: 'Dezibel',
    door_english: 'Englisch',
    door_french: 'Französisch',
    door_spanish: 'Spanisch',
    false: 'Falsch',
    fw_version: 'Firmware Version',
    hw_version: 'Hardware Version',
    iccid: 'ICCID-Nummer',
    imei: 'IMEI-Nummer',
    imsi: 'IMSI',
    incorrectVertexKey: 'Der eingegebene Code ist ungültig',
    lac: 'LAC',
    level: 'Signalpegel',
    mac_address: 'MAC-Adresse',
    mcc: 'MCC',
    mic_volume: 'Mikrofonlautstärke',
    mnc: 'MNC',
    none: 'Keiner',
    relay_dtmf_hang_call: 'Relaisaktivierung durch Ton',
    speaker_volume: 'Lautstärke des Lautsprechers',
    true: 'Wahr',
    WIEGANDCONFIG: 'Wiegand-Konfiguration',
    ALLOWNOTIFICATIONS: 'HONOA Torzustand zeigen',
    LOCALEVENTMANAGEMENTCONFIG: 'Ereigniskonfiguration',
  },
};
