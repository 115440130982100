export default {
  equipments: {
    add: 'Berendezés hozzáadása',
    createEquipment: 'Berendezés létrehozása',
    deleteConfirmation: 'Biztosan törölni szeretné ezt a berendezést?',
    facilityName: 'Létesítmény neve',
    productName: 'Termék neve',
    parameters: 'Paraméterek',
    relays: 'Relék',
    fobs: 'Klónozott távirányítók',
    groups: 'Csoportok',
    actions: 'Tevékenységek',
    events: 'Események',
    info: 'Információk',
    'remote comm': 'Távoli kommunikáció',
    changePasswordAction: 'Jelszó módosítása',
    adjustDateTimeAction: 'Dátum és idő beállítása',
    formatMemory: 'Memóriakártya formázása',
    disconnectedKey: 'Kikapcsolt',
    connectedKey: 'Csatlakoztatott',
    notFoundKey: 'Eszköz nem található',
    saveConfiguration: 'Konfiguráció mentése',
    compareMemory: 'Memória összehasonlítása',
    savedMemory: 'Memória mentve',
    sendMemoryAction: 'A memória helyi frissítése',
    saveMemoryAction: 'A memória távoli frissítése',
    verifyConnectivity: 'Ellenőrizze a csatlakoztathatóságot',
    getdoorstatus: 'Az ajtó állapotának lekérdezése',
    device: 'Eszköz',
    deleteEventsConfirmation: 'Biztos benne, hogy törölni szeretné az események?',
  },
};
