import { GridColumn as Column } from '@progress/kendo-react-grid/dist/npm/GridColumn';
import { GridColumn } from '@progress/kendo-react-grid';
import { Flex } from '@jcm-technologies/uikit/dist/atoms/Layout';
import { IconTooltip } from '@jcm-technologies/uikit/dist/molecules/IconTooltip';
import IconTrash from '@jcm-technologies/uikit/dist/atoms/Icons/IconTrash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Table from '../../../../common/Table';
import { idGetter, SELECTED_FIELD } from '../../../../common/Table/config';
import { CustomFilter } from '../../../../common/Table/renderers';

export const TableMifare = ({
  data,
  customFields,
  setData,
  setChanges,
  onBlurSaveTable,
  onSelectedState,
  selectedState,
  setSelectedCountState,
  onFobToDelete,
}) => {
  const { t } = useTranslation();
  const {
    user: { roleMask },
  } = useSelector((state) => state.user);
  const [dataLoaded, setDataLoaded] = useState(data || []);
  const isPropertyManagerLiteRole = roleMask === 256;

  return (
    <Table
      data={data}
      customFields={customFields}
      setData={setData}
      setChanges={setChanges}
      onBlurSaveTable={onBlurSaveTable}
      onSelectedState={onSelectedState}
      selectedState={selectedState}
      setSelectedCountState={setSelectedCountState}
      setDataLoaded={setDataLoaded}
      dataLoaded={dataLoaded}
      editable
      pageable
      filterable
      sortable
      customFieldsParentName='facilityFobMifareAttributes'
    >
      {!isPropertyManagerLiteRole && (
        <Column
          field={SELECTED_FIELD}
          title=' '
          width={42}
          filterable={false}
          editalbe={false}
          headerSelectionValue={
            dataLoaded.findIndex((item) => !selectedState[idGetter(item)]) === -1
          }
        />
      )}
      <GridColumn
        editor='text'
        field='code'
        width='auto'
        title={t('form.code')}
        filterCell={CustomFilter}
        filter='text'
        editable={false}
      />
      <GridColumn
        editor='text'
        field='type'
        width='auto'
        title={t('form.type')}
        filter='text'
        editable={false}
        filterCell={CustomFilter}
      />
      <GridColumn
        editor='text'
        field='name'
        width='auto'
        title={t('form.name')}
        filter='text'
        filterCell={CustomFilter}
      />
      <GridColumn
        editor='text'
        field='surname'
        width='auto'
        title={t('form.surname')}
        filter='text'
        filterCell={CustomFilter}
      />
      <GridColumn
        editor='text'
        field='nic'
        width='auto'
        title={t('form.identityCard')}
        filter='text'
        filterCell={CustomFilter}
      />
      <GridColumn
        editor='numeric'
        field='plaza'
        width='auto'
        title={t('form.slot')}
        filter='text'
        filterCell={CustomFilter}
      />
      {customFields?.map((column) => (
        <GridColumn
          key={column.id}
          editor='text'
          field={column.tag}
          width='auto'
          title={column.value}
          filter='text'
          filterCell={CustomFilter}
        />
      ))}
      {!isPropertyManagerLiteRole && (
        <Column
          field=''
          title=' '
          width={50}
          filterable={false}
          editalbe={false}
          cell={(props) => {
            const { dataItem } = props;
            return (
              <td colSpan='1'>
                <Flex alignItems='center' justifyContent='center'>
                  <IconTooltip
                    sizeIcon='display16'
                    cursor='pointer'
                    color='blackLight'
                    toolTipContent={t('genericGrow.delete')}
                    tooltipProps={{
                      place: 'top',
                    }}
                    onClick={() => onFobToDelete(dataItem)}
                  >
                    <IconTrash />
                  </IconTooltip>
                </Flex>
              </td>
            );
          }}
        />
      )}
    </Table>
  );
};

TableMifare.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setData: PropTypes.func.isRequired,
  setChanges: PropTypes.func.isRequired,
  onBlurSaveTable: PropTypes.func.isRequired,
  onSelectedState: PropTypes.func.isRequired,
  selectedState: PropTypes.shape({}).isRequired,
  setSelectedCountState: PropTypes.func.isRequired,
  onFobToDelete: PropTypes.func.isRequired,
  customFields: PropTypes.arrayOf(PropTypes.shape({})),
};
TableMifare.defaultProps = {
  customFields: [],
};
