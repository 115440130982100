import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import ContentContainer from '../../ContentContainer/ContentContainer';
import { getInstalationGroups } from '../../../modules/old_to_refact/actions/instalationGroups';
import { getUniversalGroupSingleList } from '../../../modules/old_to_refact/actions/universalGroups';

import { getInstalationsSingleList } from '../../../modules/old_to_refact/actions/instalations';
import GenericForm from '../../GenericForm/GenericForm';
import PopUpConfirm from '../../PopUpConfirm';

export class MultiselectionPopUpsContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toInstallation: '',
      toGroup: '',
      haveAlarms: false,
      popupAlarms: {
        text: '',
        onAcceptFn: null,
        onCancelFn: null,
      },
    };
  }

  componentDidMount = () => {
    const {
      firstCallAction,
      getInstalationsSingleList,
      idPrincipalCall,
      getUniversalGroupSingleList,
    } = this.props;
    if (firstCallAction === 'instalationSingleList' && idPrincipalCall) {
      getInstalationsSingleList(idPrincipalCall, this.props?.action === 'copy' ? true : false);
    } else if (firstCallAction === 'ugSingleList') {
      getUniversalGroupSingleList(idPrincipalCall, true);
    }
  };

  handleChange = (name, value) => {
    this.setState({ [name]: value });
  };

  handleChangePrimaryList = (name, value) => {
    this.handleChange(name, value?.id);
    this.setState((prev) => ({
      ...prev,
      toGroup: { name: name, groupId: value?.id },
    }));
    if (this.props.getSecondaryList) {
      this.props.getSecondaryList(value?.id);
    }
  };

  handleSubmit = (e) => {
    const { selectedFobList, onAcceptFn, onCancelFn, action } = this.props;
    const { toInstallation, toGroup } = this.state;
    e?.preventDefault();
    e?.stopPropagation();

    const textContent = this.getTextAlarm();
    let setFlagAlarms = false;
    for (let index = 0; index < selectedFobList.length; index++) {
      const element = selectedFobList[index].alarms;
      if (element?.length > 0) {
        setFlagAlarms = true;
        this.setState({
          haveAlarms: true,
        });
      }
    }
    if (setFlagAlarms) {
      this.setState({
        popupAlarms: {
          text: textContent,
          onAcceptFn: () => {
            onAcceptFn(toInstallation, toGroup, selectedFobList);
            onCancelFn(false);
          },
          onCancelFn: action !== 'copy' && (() => onCancelFn(false)),
        },
      });
    } else {
      onAcceptFn(toInstallation, toGroup, selectedFobList);
      onCancelFn(false);
    }
  };

  handleCancel = (e) => {
    const { onCancelFn } = this.props;
    e?.preventDefault();
    e?.stopPropagation();
    this.setState({
      haveAlarms: false,
      text: '',
    });
    onCancelFn(false);
  };

  getPrimaryListDropdownValues = (list) => {
    let values = list;
    const { tenants, text, t, action, history } = this.props;
    if (values && action !== 'copy') {
      values = values.map((object, i) => ({
        value: object.name,
        label: object.name,
        id: object?.groupId ? object.groupId : object.facilityId ? object.facilityId : object.id,
      }));

      return values;
    }

    values = values.map((object, i) => ({
      id: object?.groupId ? object.groupId : object.facilityId ? object.facilityId : object.id,
      label: object.name,
      value: object.name,
    }));

    return values;
  };

  getSecondaryDropdownValues = (list) => {
    let values = list;
    if (values) {
      values = values.map((object, i) => ({
        id: object.id || object.groupId,
        label: object.name,
        value: object.name,
      }));

      return values;
    }
    return [];
  };

  getFields = () => {
    const { moveToPrincipalList, moveToSecondaryList, t, principalListPlaceHolder } = this.props;
    const fields = [];
    if (moveToPrincipalList) {
      fields.push([
        {
          type: 'dropdownSearch',
          options: this.getPrimaryListDropdownValues(moveToPrincipalList),
          placeholderMessage: principalListPlaceHolder || t('fobsPopUps.instalations'),
          id: 'toInstallation',
          name: 'toInstallation',
          value: this.state.toInstallation,
          onChange: (name, value) => this.handleChangePrimaryList(name, value),
          width: '100%',
        },
      ]);
    }

    if (moveToSecondaryList) {
      fields.push([
        {
          type: 'dropdownSearch',
          options: this.getSecondaryDropdownValues(moveToSecondaryList),
          isDisabled: this.state.toInstallation === '',
          placeholderMessage: t('fobsPopUps.groups'),
          id: 'toGroup',
          name: 'toGroup',
          value: this.state.toGroup,
          onChange: (name, value) => this.handleChange(name, value?.id),
          width: '100%',
        },
      ]);
    }
    return fields;
  };

  getTextAlarm = () => {
    const { t, action, isUniversal } = this.props;
    switch (action) {
      case 'copy':
        if (isUniversal) {
          return t('alarms.deleteAlarmPopUpWithCloneUniversal');
        }
        return t('alarms.deleteAlarmPopUpWithClone');

      case 'move':
        return t('alarms.deleteAlarmPopUpWithMove');
      default:
        return t('alarms.deleteAlarmPopUpWithMove');
    }
  };

  render() {
    const { formErrors, haveAlarms, popupAlarms, toInstallation, toGroup } = this.state;
    const { tenants, text, t, selectedFobList, moveToSecondaryList } = this.props;

    return (
      <ContentContainer tenants={tenants} title={text}>
        {haveAlarms ? (
          <PopUpConfirm {...popupAlarms} tenants={this.props.tenants} />
        ) : (
          <>
            <h6 className='delete-fobs-popup-text'>
              {this.props.action === 'move'
                ? `${t('fobsPopUps.selectedItemsMove1')}`
                : `${t('fobsPopUps.selectedItemsCopy1')}`}
              {selectedFobList.length} {t('fobsPopUps.selectedItemsShow2')}
            </h6>
            <p className='delete-fobs-popup-warning-text'>{`${t(
              'fobsPopUps.warningDeleteFobs'
            )}`}</p>
            <GenericForm
              tenants={this.props.tenants}
              fields={this.getFields()}
              handleSubmit={this.handleSubmit}
              handleCancel={this.handleCancel}
              submitText={t('popupConfirm.accept')}
              cancelText={t('popupConfirm.cancel')}
              isCancelVisible
              isSubmitDisabled={!toGroup}
              formErrors={formErrors}
            />
          </>
        )}
      </ContentContainer>
    );
  }
}
const mapStateToProps = (state) => ({
  tenants: state.tenants,
  instalationsSingleList: state.instalations.instalationsSingleList,
  universalGroupsSingleList: state.universalGroups.universalGroupsSingleList,
});

const MultiselectionPopUpsContentWithTranslation = withTranslation()(MultiselectionPopUpsContent);
const MultiselectionPopUpsContentWithRouter = withRouter(
  MultiselectionPopUpsContentWithTranslation
);

export default connect(mapStateToProps, {
  getInstalationGroups,
  getInstalationsSingleList,
  getUniversalGroupSingleList,
})(MultiselectionPopUpsContentWithRouter);
