export default {
  breadcrumb: {
    dashboard: 'Dashboard',
    Facilities: 'Facilities',
    FacilityEquipments: 'Equipments',
    Groups: 'Groups',
    Equipments: 'Equipments',
    AddUniversalGroup: 'Add Universal Group',
    Configuration: 'Configuration',
    Enroll: 'Enroll process',
    administration: 'Administration',
    Owner: 'Owner',
    'clear-gdpr': 'Clear GDPR',
    'pending-gdpr': 'Pending GDPR',
    'user-config': 'My account',
    UniversalGroups: 'Universal Groups',
    Users: 'Users',
    Help: 'Help',
    Parameters: 'Parameters',
    Relays: 'Relays',
    Fobs: 'Fobs',
    Actions: 'Actions',
    Events: 'Events',
    'Remote comm': 'Remote Communications',
    Facility: 'New Facility',
    Equipment: 'New Equipment',
    FacilityGroup: 'New Group',
    Neighbours: 'Neighbors list',
    UniversalGroup: 'New Universal Group',
    Schedule: 'New Schedule',
    customization: 'Customize web',
    Invitations: 'Invitations',
    Companies: 'Companies',
    UpdateUser: 'My account',
    preferences: 'Preferences',
    'assistant-panel': 'Assistant Panel',
    Holidays: 'Holidays',
    Information: 'Information',
    recycle: 'Recycle bin',
  },
};
