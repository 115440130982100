export default {
  paramsName: {
    ACCESSCONTROL: 'Kontrola přístupu',
    ACTIVEELEMENTS: 'Aktivní události',
    CONFIGRELE1: 'Nastavení relé 1 (impulsní nebo ON/OFF)',
    CONFIGRELE2: 'Nastavení relé 2 (impulsní nebo ON/OFF)',
    INSTAL: 'Technik',
    POWER: 'Napájení',
    PRICE1: 'Odečítání hodnoty pro relé 1',
    PRICE2: 'Odečítání hodnoty pro relé 2',
    RELAYS: 'Relé',
    TEMPRELE1: 'Nastavení času relé 1',
    TEMPRELE2: 'Nastavení času relé 2',
    TEVENT: 'Karta událostí',
    TIMEZONE: 'Identifikátor časového pásma',
    TMEM: 'Paměťová karta',
    MessageBox_ActivationRelay: 'Aktivace relé',
    MessageBox_ConnectionError: 'Chyba spojení',
    MessageBox_DeviceAccepted: 'Zařízení akceptováno',
    MessageBox_DeviceRecieved: 'Zařízení přijato',
    MessageBox_FormatMemoryError: 'Vadný formát paměti',
    MessageBox_InvalidDevice: 'Neplatné zařízení',
    MessageBox_MemoryExtracted: 'Paměť vyjmuta',
    MessageBox_MemoryInserted: 'Paměť vložena',
    MessageBox_NotInMemory: 'Žádný nález v paměti',
    MessageBox_OutOfSchedule: 'Mimo časový rámec',
    MessageBox_PowerUp: 'Zapnuto',
    MessageBox_RelayState: 'Změna významu relé',
    MessageBox_Message: 'Konfigurovat aktivní události',
    CONFIGRELAYSPIN1: 'Relé v PIN programování. Kanál 1',
    CONFIGRELAYSPIN2: 'Relé v PIN programování. Kanál 2',
    CONFIGRELAYSPIN3: 'Relé v PIN programování. Kanál 3',
    CONFIGRELAYSPIN4: 'Relé v PIN programování. Kanál 4',
    DISABLEMANUALPROG: 'Manuální programování zakázáno',
    DISABLERADIOPROG: 'Rádiové programování zakázáno',
    GO: 'Go',
    GOPRO: 'GO-Pro',
    HFADVANCE: 'HF-ADVENCE',
    IMPBIRELE1: 'Nastavuje relé 1 jako bistabilní',
    MINIDDEVICE: 'Nejnižší úroveň zařízení',
    PIN: 'PIN',
    PINCADUCAT: 'Vypršení platnosti PIN',
    CONFIGRELAYSNEW1: 'Aktivace relé na kanálu 1',
    CONFIGRELAYSNEW2: 'Aktivace relé na kanálu 2',
    CONFIGRELAYSNEW3: 'Aktivace relé na kanálu 3',
    CONFIGRELAYSNEW4: 'Aktivace relé na kanálu 4',
    CONFIGRELE3: 'Nastavení relé 3 (impulsní nebo ON/OFF)',
    CONFIGRELE4: 'Nastavení relé 4 (impulsní nebo ON/OFF)',
    TEMPRELE3: 'Nastavení času relé 3',
    TEMPRELE4: 'Nastavení času relé 4',
    PRICE3: 'Odečítání hodnoty pro relé 3',
    PRICE4: 'Odečítání hodnoty pro relé 4',
    FREEACTIVE: 'Aktivní skupina ve Free systému',
    FREEGROUP0LOCKED: 'Skupina 0 je ve Free systému blokována',
    FREEGROUPNUM: 'Číslo skupiny ve Free systému',
    FREEGROUPRFPROGALLOWED: 'Rádiové programování ve Free systému povoleno',
    BADGE_MAITRE01: 'Master kód 1',
    BADGE_MAITRE10: 'Master kód 10',
    BADGE_MAITRE2: 'Master kód 2',
    BADGE_MAITRE3: 'Master kód 3',
    BADGE_MAITRE4: 'Master kód 4',
    BADGE_MAITRE5: 'Master kód 5',
    BADGE_MAITRE6: 'Master kód 6',
    BADGE_MAITRE7: 'Master kód 7',
    BADGE_MAITRE8: 'Master kód 8',
    BADGE_MAITRE9: 'Master kód 9',
    SITECODE: 'Site Kód',
    W26FORMAT: 'Formát Wiegand 26',
    W26FORMAT0: 'Kód (16-ti bitový) + Site kód (8-mi bitový)',
    W26FORMAT1: 'Kód (24-ti bitový)',
    CONFIGRELE_ANTIPANIC: 'Panika',
    ANTIPANICCONFIGRELE1: 'Nastavení relé 1 (impulsní nebo ON/OFF nebo panika)',
    ANTIPANICCONFIGRELE2: 'Nastavení relé 2 (impulsní nebo ON/OFF nebo panika)',
    ANTIPANICCONFIGRELE3: 'Nastavení relé 3 (impulsní nebo ON/OFF nebo panika)',
    ANTIPANICCONFIGRELE4: 'Nastavení relé 4 (impulsní nebo ON/OFF nebo panika)',
    RELE1IMP: 'Nastavení relé 1 (impulsní nebo ON/OFF)',
    RELE1TEMP: 'Nastavení času relé 1',
    RELE2IMP: 'Nastavení relé 2 (impulsní nebo ON/OFF)',
    RELE2TEMP: 'Nastavení času relé 2',
    RELE3IMP: 'Nastavení relé 3 (impulsní nebo ON/OFF)',
    RELE3TEMP: 'Nastavení času relé 3',
    RELE4IMP: 'Nastavení relé 4 (impulsní nebo ON/OFF)',
    RELE4TEMP: 'Nastavení času relé 4',
    RELAYPRICE1: 'Odečítání hodnoty pro relé 1',
    RELAYPRICE2: 'Odečítání hodnoty pro relé 2',
    RELAYPRICE3: 'Odečítání hodnoty pro relé 3',
    RELAYPRICE4: 'Odečítání hodnoty pro relé 4',
    PASSWORD: 'Heslo',
    CONNECTIVITY_EXTENSION: 'Link karta instalována',
    ACTIVATIONKEY: 'Aktivační klíč',
    UNIQUEKEY: 'Unikátní klíč',
    TIMEZONEID: 'Časové pásmo',
    EMMITTERNOTUSEDEVENTTIME: 'Nepoužívat čas události vysílače',
    DOOROPENEDEVENTTIME: 'Událost otevření dveří',
    ACTIVEEVENTSTOSD: 'Nastavení událostí ACCESS 5K',
    SCHEDULESDATA: 'Data časových rozvrhů',
    APNCONFIG: 'Nastavení APN',
    A5KPROXY: 'Nastavení ACCESS 5K Proxy',
    Configuration_Params_NoFixed: 'Nedefinováno',
    Configuration_Params_GO: 'GO',
    Configuration_Params_GOPRO: 'GOPro',
    Configuration_Params_HFADVANCE: 'HF-ADVENCE',
    Configuration_Params_ConfigRele_Impulsional: 'Impulsní',
    Configuration_Params_ConfigRele_Biestable: 'Bistabilní',
    Configuration_Params_ConfigRele_Antipanic: 'Antipanika',
    Configuration_Params_Yes: 'Ano',
    Configuration_Params_No: 'Ne',
    Configuration_Params_PIN: 'PIN',
    Configuration_Params_PINCADUCAT: 'PIN vypršel',
    Configuration_Params_ValueTEVENTS: 'Ano',
    Configuration_Params_BUTT_NO: 'Normálně otevřené tlačítko',
    Configuration_Params_BUTT_NC: 'Normálně zavřené tlačítko',
    Sip_Address: 'Adresa SIP',
    Sip_Status: 'Stav SIP',
    fw_version: 'Verze firmware',
    hw_version: 'Verze hardware',
    mac_address: 'Adresa MAC',
    imei: 'Číslo IMEI',
    iccid: 'Číslo ICCID',
    connection_type: 'Typ spojení',
    level: 'Úroveň signálu',
    dbm: 'Decibely',
    mcc: 'MCC',
    mnc: 'MNC',
    lac: 'LAC',
    call_max_duration: 'Maximální délka volání',
    call_outgoing_timeout: 'Pauza odchozího volání',
    relay_dtmf_hang_call: 'Aktivace relé tónem',
    Relay_Sound: 'Jazyk hlášení otevření dveří',
    mic_volume: 'Hlasitost mikrofonu',
    speaker_volume: 'Hlasitost reproduktoru',
    access_code: 'Vstupní kód',
    true: 'skutečný',
    false: 'Lež',
    door_english: 'Anglicky',
    door_spanish: 'Španělsky',
    door_french: 'Francouzsky',
    none: 'Žádný',
    imsi: 'IMSI',
    cell_id: 'CELL ID',
    Configuration_Params_LOW: 'Low',
    Configuration_Params_HIGH: 'Vysoké',
    Configuration_Params_DEBUG: 'Debug',
    Configuration_Params_UNUSED: 'Nepoužité',
    Configuration_Params_NCOLS: '[Dveře 1] Běžně zavřený koncový spínač pro otevřeno',
    Configuration_Params_NCCLS: '[Dveře 1] Běžně zavřený koncový spínač zavřeno',
    Configuration_Params_NOOLS: '[Dveře 1] Běžně otevřený koncový spínač pro otevřeno',
    Configuration_Params_NOCLS: '[Dveře 1] Běžné otevřený koncový spínač pro zavřeno',
    Configuration_Params_NCSC: 'Běžně zavřený bezpečnostní řetěz',
    Configuration_Params_NOSC: 'Běžně otevřený bezpečnostní řetěz',
    Configuration_Params_entranceNC: 'Vstup APB normálně zavřen',
    Configuration_Params_exitNC: 'Výstup APB normálně zavřen',
    Configuration_Params_entranceNO: 'Vstup APB normálně otevřen',
    Configuration_Params_exitNO: 'Výstup APB normálně otevřen',
    Configuration_Params_D2_NCOLS: '[Dveře 2] Běžně zavřený koncový spínač pro otevřeno',
    Configuration_Params_D2_NCCLS: '[Dveře 2] Běžně zavřený koncový spínač zavřeno',
    Configuration_Params_D2_NOOLS: '[Dveře 2] Běžně otevřený koncový spínač pro otevřeno',
    Configuration_Params_D2_NOCLS: '[Dveře 2] Běžné otevřený koncový spínač pro zavřeno',
    Configuration_Params_NSCLS: '[Dveře 1] Snímač koncového spínače pro zavřeno',
    Configuration_Params_NSOLS: '[Dveře 1] Snímač koncového spínače pro otevřeno',
    Configuration_Params_D2_HSOLS: '[Dveře 2] Snímač koncového spínače pro zavřeno',
    Configuration_Params_D2_HSCLS: '[Dveře 2] Snímač koncového spínače pro otevřeno',
    Configuration_Params_OPENREMOTE: 'HONOA Povolit dálkové otevření',
    Configuration_Params_HANDSFREEALLOWED: 'HONOA Povolit handsfree Bluetooth (BETA)',
    APBKMODE: 'Režim Anti-Passback (APB)',
    Configuration_Params_MODE1: 'Režim 1 (monokanál vstup/výstup, volný výstup)',
    Configuration_Params_MODE2: 'Režim 2 (monokanál vstup / výstup)',
    Configuration_Params_MODE3: 'Režim 3 (kanál 1 vstup / kanál 2 výstup)',
    Configuration_Params_MODE4: 'Režim 4 (kanál 1 a 3 vstup / kanál 2 a 4 výstup)',
    APBKTIMEBACK: 'Čas resetu Anti-Passback v minutách (Anti-timeback)',
    Configuration_Params_HSCLS: '[Dveře 1] Snímač koncového spínače pro zavřeno',
    Configuration_Params_HSOLS: '[Dveře 1] Snímač koncového spínače pro otevřeno',
    Configuration_Params_MIN: 'Minimum',
    DATAUSAGE: 'Spotřeba dat',
    INPUTS: 'Vstupy',
    INPUT1NAME: 'Název vstupu 1',
    INPUT2NAME: 'Název vstupu 2',
    INPUT3NAME: 'Název rádiového snímače 3',
    INPUT4NAME: 'Název rádiového snímače 4',
    INPUT5NAME: 'Název rádiového snímače 5',
    INPUT6NAME: 'Název rádiového snímače 6',
    INPUT7NAME: 'Název rádiového snímače 7',
    INPUT8NAME: 'Název rádiového snímače 8',
    INPUT9NAME: 'Název rádiového snímače 9',
    INPUT10NAME: 'Název rádiového snímače 10',
    INPUT1MODE: 'Stav vstupního snímače 1',
    INPUT2MODE: 'Stav vstupního snímače 2',
    INPUT3MODE: 'Stav vstupního snímače 3',
    INPUT4MODE: 'Stav vstupního snímače 4',
    INPUT5MODE: 'Stav vstupního snímače 5',
    INPUT6MODE: 'Stav vstupního snímače 6',
    INPUT7MODE: 'Stav vstupního snímače 7',
    INPUT8MODE: 'Stav vstupního snímače 8',
    INPUT9MODE: 'Stav vstupního snímače 9',
    INPUT10MODE: 'Stav vstupního snímače 10',
    INPUT1KEY: 'Klíč vstupu 1',
    INPUT2KEY: 'Klíč vstupu 2',
    INPUT3KEY: 'Stav vstupního snímače 3',
    INPUT4KEY: 'Stav vstupního snímače 4',
    INPUT5KEY: 'Stav vstupního snímače 5',
    INPUT6KEY: 'Stav vstupního snímače 6',
    INPUT7KEY: 'Stav vstupního snímače 7',
    INPUT8KEY: 'Stav vstupního snímače 8',
    INPUT9KEY: 'Stav vstupního snímače 9',
    INPUT10KEY: 'Stav vstupního snímače 10',
    SENSORS: 'Snímače',
    incorrectVertexKey: 'Vložený kód není  platný',
    correctVertexKey: 'Vložený kód je platný',
    OPENREMOTE: 'HONOA Povolit dálkové otevření',
    HANDSFREEALLOWED: 'HONOA Povolit handsfree Bluetooth (BETA)',
    Name: 'Název',
    UniqueIdentity: 'Unikátní identifikátor',
    Product: 'Zařízení',
    SerialNumber: 'Sériové číslo',
    WIEGANDCONFIG: 'Konfigurace Wiegand',
    ALLOWNOTIFICATIONS: 'HONOA Zobrazit stav dveří',
    LOCALEVENTMANAGEMENTCONFIG: 'Konfigurace událostí',
  },
};
