import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from '@jcm-technologies/uikit/dist/molecules/Card';
import { Flex } from '@jcm-technologies/uikit/dist/atoms/Layout';
import { Text } from '@jcm-technologies/uikit/dist/atoms/Typography';
import { Icon } from '@jcm-technologies/uikit/dist/atoms/Icon';
import IconTools from '@jcm-technologies/uikit/dist/atoms/Icons/IconTools';
import IconArrowRight from '@jcm-technologies/uikit/dist/atoms/Icons/IconArrowRight';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

const FacilitiesMaintenance = ({ pendingMaintenance, handleRedirectTo }) => {
  const { t } = useTranslation();
  const tenants = useSelector((state) => state.tenants);

  return (
    <Card rounded='soft' width='100%' height='100%' marginBottom={2} display='flex'>
      <Flex width='100%' flexDirection='column'>
        <Flex id='pendingMaintenance-title' alignItems='center'>
          <Icon sizeIcon='display24' marginRight={2}>
            <IconTools id='pendingMaintenance-icon' />
          </Icon>
          <Text id='pendingMaintenance-text' style={{ flex: '1' }}>
            {t('form.pendingMaintenance')}
          </Text>
          <Icon
            sizeIcon='display24'
            style={{ cursor: 'pointer' }}
            onClick={() => handleRedirectTo(11, '', t('form.pendingMaintenance'))}
          >
            <IconArrowRight id='pendingMaintenance-icon-arrow' />
          </Icon>
        </Flex>
        <Flex justifyContent='center' alignItems='center' flex={1}>
          <Text id='pendingMaintenance' color={tenants?.color1 || 'orange'} sizeText='display48'>
            {pendingMaintenance}
          </Text>
        </Flex>
      </Flex>
    </Card>
  );
};

export default FacilitiesMaintenance;

FacilitiesMaintenance.propTypes = {
  handleRedirectTo: PropTypes.func.isRequired,
  pendingMaintenance: PropTypes.number,
};
