const privacyPolicyDictionary = [];
privacyPolicyDictionary.default = 'https://www.jcm-tech.com/cloudassistant-privacy-policy/';
privacyPolicyDictionary['es-ES'] =
  'https://www.jcm-tech.com/es/cloudassistant-politica-de-privacidad/';
privacyPolicyDictionary['en-GB'] = 'https://www.jcm-tech.com/cloudassistant-privacy-policy/';
privacyPolicyDictionary['de-DE'] =
  'https://www.jcm-tech.com/de/cloudassistant-datenschutzerklaerung/';
privacyPolicyDictionary['fr-FR'] =
  'https://www.jcm-tech.com/fr/cloudassistant-politique-de-confidentialite/';
privacyPolicyDictionary['it-IT'] =
  'https://www.jcm-tech.com/it/cloudassistant-politica-sulla-privacy/';

export default privacyPolicyDictionary;
