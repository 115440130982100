import { call, fork, takeLatest } from 'redux-saga/effects';
import fetchEntitySaga from '../old_to_refact/sagas/utils/fetchEntitySaga';
import apiPathFactory from '../../core/old_common/rooting/apiPathFactory';
import api from '../../core/helpers/api';
import {
  GET_INVITATION_USER,
  GET_OWN_USERS,
  GET_USER,
  GET_USER_ROLES,
  GET_USER_ROLES_EMPLOYEES,
  PATCH_PREFERENCES_USER,
  PUT_AVATAR_USER,
  PUT_PREFERENCES_USER,
  PUT_USER_PERMISSIONS,
} from './constants';
import { getCookieLanguage } from '../../core/old_common/utils/cookiesManager';

export const getUserRoles = {
  base: () => ({
    type: GET_USER_ROLES.BASE,
  }),
  request: () => ({ type: GET_USER_ROLES.REQUEST }),
  success: (result) => ({ type: GET_USER_ROLES.SUCCESS, result }),
  failure: (error) => ({ type: GET_USER_ROLES.FAILURE, error }),
};

export const getUserRolesEmployees = {
  base: () => ({
    type: GET_USER_ROLES_EMPLOYEES.BASE,
  }),
  request: () => ({ type: GET_USER_ROLES_EMPLOYEES.REQUEST }),
  success: (result) => ({ type: GET_USER_ROLES_EMPLOYEES.SUCCESS, result }),
  failure: (error) => ({ type: GET_USER_ROLES_EMPLOYEES.FAILURE, error }),
};

export const getUserInvitation = {
  base: (id) => ({
    type: GET_INVITATION_USER.BASE,
    id,
  }),
  request: () => ({ type: GET_INVITATION_USER.REQUEST }),
  success: (result) => ({ type: GET_INVITATION_USER.SUCCESS, result }),
  failure: (error) => ({ type: GET_INVITATION_USER.FAILURE, error }),
};

export const getUser = {
  base: () => ({
    type: GET_USER.BASE,
  }),
  request: () => ({ type: GET_USER.REQUEST }),
  success: (result) => ({ type: GET_USER.SUCCESS, result }),
  failure: (error) => ({ type: GET_USER.FAILURE, error }),
};

export const putPreferencesUser = {
  base: (id, data) => ({
    type: PUT_PREFERENCES_USER.BASE,
    id,
    data,
  }),
  request: (data) => ({ type: PUT_PREFERENCES_USER.REQUEST, payload: data }),
  success: (result, payload) => ({ type: PUT_PREFERENCES_USER.SUCCESS, result, payload }),
  failure: (error) => ({ type: PUT_PREFERENCES_USER.FAILURE, error }),
};

export const patchPreferencesUser = {
  base: (id, data) => ({
    type: PUT_PREFERENCES_USER.BASE,
    id,
    data,
  }),
  request: (data) => ({ type: PATCH_PREFERENCES_USER.REQUEST, payload: data }),
  success: (result, payload) => ({ type: PATCH_PREFERENCES_USER.SUCCESS, result, payload }),
  failure: (error) => ({ type: PATCH_PREFERENCES_USER.FAILURE, error }),
};

export const putAvatarUser = {
  base: (id, avatar) => ({
    type: PUT_AVATAR_USER.BASE,
    id,
    avatar,
  }),
  request: (data) => ({ type: PUT_AVATAR_USER.REQUEST, payload: data }),
  success: (result, payload) => ({ type: PUT_AVATAR_USER.SUCCESS, result, payload }),
  failure: (error) => ({ type: PUT_AVATAR_USER.FAILURE, error }),
};

export const getOwnUsers = {
  base: (userDatas) => ({
    type: GET_OWN_USERS.BASE,
    userDatas,
  }),
  request: () => ({ type: GET_OWN_USERS.REQUEST }),
  success: (result) => ({ type: GET_OWN_USERS.SUCCESS, result }),
  failure: (error) => ({ type: GET_OWN_USERS.FAILURE, error }),
};

export const putUserPermissions = {
  base: (userId, userDatas) => ({
    type: PUT_USER_PERMISSIONS.BASE,
    userId,
    userDatas,
  }),
  request: () => ({ type: PUT_USER_PERMISSIONS.REQUEST }),
  success: (result, payload) => ({ type: PUT_USER_PERMISSIONS.SUCCESS, result, payload }),
  failure: (error) => ({ type: PUT_USER_PERMISSIONS.FAILURE, error }),
};

//-----------------------------------------------------------------------------------

export function* getUserRolesSaga({ mask }) {
  try {
    const url = apiPathFactory.usersGetRoles(mask);
    const response = yield call(() => fetchEntitySaga(getUserRoles, api.get, url));
    return response;
  } catch (error) {
    return error;
  }
}

export function* getUserRolesEmployeesSaga() {
  try {
    const url = apiPathFactory.userGetRolesEmployees();
    const response = yield call(() => fetchEntitySaga(getUserRolesEmployees, api.get, url));
    return response;
  } catch (error) {
    return error;
  }
}

export function* getUserInvitationSaga({ id }) {
  try {
    const url = apiPathFactory.invitationsGet(id);
    const response = yield call(() => fetchEntitySaga(getUserInvitation, api.get, url));
    return response;
  } catch (error) {
    return error;
  }
}

export function* getUserSaga() {
  try {
    const url = apiPathFactory.usersGet();
    const response = yield call(() => fetchEntitySaga(getUser, api.get, url));
    return response;
  } catch (error) {
    return error;
  }
}

export function* putPreferencesUserSaga({ id, data }) {
  try {
    const actualLang = getCookieLanguage().replace('-', '_');
    const url = apiPathFactory.usersPutPreferences(id);
    const response = yield call(() => fetchEntitySaga(putPreferencesUser, api.put, url, { data }));

    if (actualLang !== data?.language) {
      // TODO: When we migrate all strings to PhraseApp clean this force
      window?.location?.reload();
    } else {
      yield call(getUserSaga);
    }

    return response;
  } catch (error) {
    return error;
  }
}

export function* patchPreferencesUserSaga({ id, data }) {
  try {
    const url = apiPathFactory.usersPatchPreferences(id);
    const response = yield call(() =>
      fetchEntitySaga(patchPreferencesUser, api.patch, url, { data })
    );
    yield call(getUserSaga);

    return response;
  } catch (error) {
    return error;
  }
}

export function* putAvatarUserSaga({ id, avatar }) {
  try {
    const url = apiPathFactory.usersPutAvatar(id);
    const response = yield call(() =>
      fetchEntitySaga(putAvatarUser, api.put, url, { data: { avatar } })
    );
    yield call(getUserSaga, { id });
    return response;
  } catch (error) {
    return error;
  }
}

export function* getOwnUsersSaga() {
  try {
    const url = apiPathFactory.usersGetOwn();
    const response = yield call(() => fetchEntitySaga(getOwnUsers, api.get, url));
    return response;
  } catch (error) {
    return error;
  }
}

export function* putUserPermissionsSaga({ userDatas }) {
  try {
    const url = apiPathFactory.usersPutPermissions(userDatas.id);
    const response = yield call(() =>
      fetchEntitySaga(putUserPermissions, api.put, url, { data: userDatas })
    );
    return response;
  } catch (error) {
    return error;
  }
}

//-----------------------------------------------------------------------------------

function* watchUserRoles() {
  yield takeLatest(GET_USER_ROLES.BASE, getUserRolesSaga);
}

//-----------------------------------------------------------------------------------

function* watchUserRolesEmployees() {
  yield takeLatest(GET_USER_ROLES_EMPLOYEES.BASE, getUserRolesEmployeesSaga);
}

//-----------------------------------------------------------------------------------

function* watchUserInvitationById() {
  yield takeLatest(GET_INVITATION_USER.BASE, getUserInvitationSaga);
}

//-----------------------------------------------------------------------------------

function* watchUserById() {
  yield takeLatest(GET_USER.BASE, getUserSaga);
}

//-----------------------------------------------------------------------------------
function* watchPatchPreferencesUser() {
  yield takeLatest(PATCH_PREFERENCES_USER.BASE, patchPreferencesUserSaga);
}

function* watchPutPreferencesUser() {
  yield takeLatest(PUT_PREFERENCES_USER.BASE, putPreferencesUserSaga);
}

function* watchPutAvatarUser() {
  yield takeLatest(PUT_AVATAR_USER.BASE, putAvatarUserSaga);
}

//-----------------------------------------------------------------------------------

function* watchGetOwnUsers() {
  yield takeLatest(GET_OWN_USERS.BASE, getOwnUsersSaga);
}

//-----------------------------------------------------------------------------------

function* watchPutUserPermissions() {
  yield takeLatest(PUT_USER_PERMISSIONS.BASE, putUserPermissionsSaga);
}

export default function* badRuleWarningWatchers() {
  yield fork(watchUserRoles);
  yield fork(watchUserRolesEmployees);
  yield fork(watchUserInvitationById);
  yield fork(watchUserById);
  yield fork(watchPatchPreferencesUser);
  yield fork(watchPutPreferencesUser);
  yield fork(watchPutAvatarUser);
  yield fork(watchGetOwnUsers);
  yield fork(watchPutUserPermissions);
}
