import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import moment from 'moment-timezone';
import { Flex } from '@jcm-technologies/uikit/dist/atoms/Layout';
import i18Next from 'i18next';
import GenericKendoGrid from '../GenericKendoGrid';
import { getEvents, resetA5kEvents } from '../../modules/old_to_refact/actions/a5Kservices';
import ButtonsGroup from '../ButtonsGroup';
import { downloadFile, getFilesFromApi, loadFile } from '../../core/helpers/filesApi';
import apiPathFactory from '../../core/old_common/rooting/apiPathFactory';
import {
  checkAssistantPanelConnection,
  invokeSendCICC,
  invokeSendFunction,
  setAssistantPanelCheckSuccessFunction,
  setMessageHubFunction,
} from '../../core/old_common/utils/signalRService';
import SignalRComponent from '../SignalRComponent';
import { getDriverEvents, resetEvents } from '../../modules/old_to_refact/actions/drivers';
import {
  deleteEvents,
  getEquipmentEvents,
  resetEquipmentsEvents,
} from '../../modules/old_to_refact/actions/equipments';
import './EquipmentEventsTab.css';
import { getCookieDateTimeFormat } from '../../core/old_common/utils/cookiesManager';
import { replaceStringValue } from '../../core/old_common/utils/replaceStringService';
import DeletePopup from '../DeletePopup/DeletePopup';
import { infoValueMapped, rgpdAlertLanguages } from '../../components/Facility/Events/config';
import { ModalRgpd } from '../../components/common/Modals/ModalRgpd';

class EquipmentEventsTab extends SignalRComponent {
  constructor(props) {
    super(props);
    this.state = {
      file: '',
      signalRAction: '',
      sort: [{ field: 'datetime', dir: 'desc' }],
      isDeleteEventsPopupOpen: false,
      isOpenModalRgpd: false,
    };
  }

  componentWillUnmount = () => {
    this.props.resetEvents();
    this.props.resetA5kEvents();
    this.props.resetEquipmentsEvents();
  };

  newMessageHubFunction = (name, message) => {
    const values = message.split('::');
    const codes = values[0].split('|');
    const { facilityId, equipmentId } = this.props;
    if (this.state.signalRAction === 'loadEvents') {
      this.loadEvents();
    } else if (this.props.product.code === 307) {
      getFilesFromApi(
        apiPathFactory.a5kGetEmptyEvents(facilityId, equipmentId, codes[0], codes[1]),
        (isError, response) => (!isError ? downloadFile(response, 'Access5KCodesM.JCMbin') : null)
      );
    } else {
      this.removeEvents();
    }
  };

  getColumns = () => {
    const { t } = this.props;
    return [
      {
        key: 'datetime',
        name: t('form.date'),
        sortable: true,
        filterable: true,
        tooltip: t('fobs.date'),
      },
      {
        key: 'event',
        name: t('title.event'),
        sortable: true,
        filterable: true,
        tooltip: t('title.event'),
      },
      {
        key: 'owner',
        name: t('title.user'),
        sortable: true,
        filterable: true,
        tooltip: t('title.user'),
      },
      {
        key: 'code',
        name: t('form.code'),
        sortable: true,
        filterable: true,
        tooltip: t('form.code'),
      },
      {
        key: 'group',
        name: t('title.group'),
        sortable: true,
        filterable: true,
        tooltip: t('title.group'),
      },
      {
        key: 'info',
        name: t('form.additionalInfo'),
        sortable: true,
        filterable: true,
        tooltip: t('form.additionalInfo'),
      },
    ];
  };

  loadEventsHandleNewMessage = (name, message) => {
    const { facilityId, equipmentId, getDriverEvents } = this.props;
    getDriverEvents(facilityId, equipmentId, message.split('::')[1]);
    this.setState({ readyForList: true });
  };

  loadEvents = () => {
    const { product } = this.props;
    setMessageHubFunction(this.loadEventsHandleNewMessage);
    invokeSendFunction(`${product.driverName}e_read::00::00::${product.productType}::00`, 90000);
  };

  removeEvents = () => {
    const { product } = this.props;
    setMessageHubFunction(this.onEventsRemoved);
    invokeSendFunction(`${product.driverName}e_format::00::00::${product.productType}::00`, 90000);
  };

  onEventsRemoved = () => {
    const { t } = this.props;
    toast.success(t('event.deletedSuccess'));
    this.props.resetEvents();
    this.props.resetA5kEvents();
    this.props.resetEquipmentsEvents();
  };

  onFileChange = (e) => {
    const { facilityId, equipmentId } = this.props;
    this.props.resetEvents();
    this.props.resetA5kEvents();
    this.props.resetEquipmentsEvents();
    this.props.getEvents(facilityId, equipmentId, e.target.files[0]);
  };

  callSignalRAction = (actionName) => {
    this.props.resetEvents();
    this.props.resetA5kEvents();
    this.props.resetEquipmentsEvents();
    setMessageHubFunction(this.newMessageHubFunction);
    setAssistantPanelCheckSuccessFunction(invokeSendCICC);
    this.setState({ signalRAction: actionName });
    checkAssistantPanelConnection();
  };

  getEventsTranslated = () => {
    const { events, equipmentEvents, t } = this.props;
    if (events.length > 0) {
      return events.map((eventTranslated) => {
        const translatedInfoLanguageCode =
          eventTranslated.infoLanguageCode !== 0
            ? t(`event.status.${eventTranslated.infoLanguageCode}`)
            : '';
        return {
          ...eventTranslated,
          event: t(`event.status.${eventTranslated?.actionLanguageCode}`),
          info: infoValueMapped(translatedInfoLanguageCode, eventTranslated.infoValue),
          owner: i18Next.exists(`event.code.${eventTranslated.owner}`)
            ? t(`event.code.${eventTranslated.owner}`)
            : eventTranslated.owner === 'Deleted User'
            ? t(`errors.deletedUser`)
            : eventTranslated.owner,
        };
      });
    }
    if (equipmentEvents.length > 0) {
      return equipmentEvents.map((eventTranslated) => {
        const translatedInfoLanguageCode =
          eventTranslated.infoLanguageCode !== 0
            ? t(`event.status.${eventTranslated.infoLanguageCode}`)
            : '';
        const translatedInfoUser = i18Next.exists(`event.code.${eventTranslated.owner}`)
          ? t(`event.code.${eventTranslated.owner}`)
          : eventTranslated.owner === 'Deleted User'
          ? t(`errors.deletedUser`)
          : eventTranslated.owner;
        return {
          ...eventTranslated,
          event: t(`event.status.${eventTranslated.actionLanguageCode}`),
          info:
            translatedInfoLanguageCode !== ''
              ? translatedInfoLanguageCode
              : eventTranslated.infoValue,
          owner: translatedInfoUser,
          // datetime: this.getDateTimeFromString(eventTranslated.datetime),
        };
      });
    }
    return [];
  };

  getFobsTranslated = () => {
    const { listFobs, t } = this.props;
    if (listFobs) {
      return listFobs.map((eventTranslated) => {
        const translatedInfoLanguageCode =
          eventTranslated.infoLanguageCode !== 0
            ? t(`event.status.${eventTranslated.infoLanguageCode}`)
            : '';
        const translatedInfoUser =
          eventTranslated.owner === 'Deleted User'
            ? t(`errors.deletedUser`)
            : eventTranslated.owner;
        return {
          ...eventTranslated,
          event: t(`event.status.${eventTranslated.actionLanguageCode}`),
          info: `${translatedInfoLanguageCode}${
            translatedInfoLanguageCode.includes(':') || !translatedInfoLanguageCode ? '' : ':'
          } ${eventTranslated.infoValue}`,
          owner: translatedInfoUser,
          // datetime: this.getDateTimeFromString(eventTranslated.datetime),
        };
      });
    }
    return [];
  };

  getExportExcelColumns = () => {
    const { t } = this.props;
    const formatDateTime = getCookieDateTimeFormat();
    return [
      {
        field: 'datetime',
        width: 150,
        title: t('form.date'),
        locked: true,
        cellOptions: {
          textAlign: 'left',
          background: '#ccc',
          wrap: true,
          format: `${formatDateTime} HH:mm`,
        },
        headerCellOptions: { textAlign: 'center', bold: true },
      },
      {
        field: 'event',
        title: t('title.event'),
        locked: true,
        cellOptions: { background: '#ccc', wrap: true },
        headerCellOptions: { textAlign: 'center', bold: true },
      },
      {
        field: 'owner',
        title: t('title.user'),
        locked: true,
        cellOptions: { background: '#ccc', wrap: true },
        headerCellOptions: { textAlign: 'center', bold: true },
      },
      {
        field: 'code',
        title: t('form.code'),
        locked: true,
        cellOptions: { background: '#ccc', wrap: true },
        headerCellOptions: { textAlign: 'center', bold: true },
      },
      {
        field: 'group',
        title: t('title.group'),
        locked: true,
        cellOptions: { background: '#ccc', wrap: true },
        headerCellOptions: { textAlign: 'center', bold: true },
      },
      {
        field: 'info',
        title: t('form.additionalInfo'),
        locked: true,
        cellOptions: { background: '#ccc', wrap: true },
        headerCellOptions: { textAlign: 'center', bold: true },
      },
    ];
  };

  getEvents = () => {
    const { getEquipmentEvents, equipmentId, facilityId } = this.props;
    this.props.resetEvents();
    this.props.resetA5kEvents();
    this.props.resetEquipmentsEvents();
    getEquipmentEvents(facilityId, equipmentId);
  };

  onClickDeleteEvents = () => {
    this.setState({ isDeleteEventsPopupOpen: true });
  };

  handleAcceptDeleteEvents = () => {
    const { deleteEvents, equipmentId, facilityId } = this.props;
    deleteEvents(facilityId, equipmentId);
    this.setState({ isDeleteEventsPopupOpen: false });
  };

  loadEventsProduct = () => {
    const { product } = this.props;
    if (
      product.code !== 307 &&
      product.code !== 612 &&
      product.code !== 613 &&
      product.code !== 614 &&
      product.code !== 615
    ) {
      this.callSignalRAction('loadEvents');
    } else if (
      product.code === 612 ||
      product.code === 613 ||
      product.code === 614 ||
      product.code === 615
    ) {
      this.getEvents();
    } else {
      loadFile({ callBack: this.onFileChange, accept: '.JCMbin' });
    }
  };

  showModalRgpd = () => {
    this.setState({ isOpenModalRgpd: true });
  };

  actionModalRgpd = () => {
    this.setState({ isOpenModalRgpd: false });
    this.loadEventsProduct();
  };

  render() {
    const {
      t,
      product,
      listFobs,
      facility,
      equipment,
      deleteEvents,
      facilityId,
      equipmentId,
      language,
      deleteEventsButton,
    } = this.props;
    const { readyForList } = this.state;

    return (
      <Flex height='100%' flexDirection='column' justifyContent='space-between'>
        <GenericKendoGrid
          pageSize={20}
          getColumns={this.getColumns}
          needOnlyExport
          excelExportColumns={this.getExportExcelColumns()}
          needDateTransformation
          excelName={`${moment().format('YYYY-MM-DD')}_${replaceStringValue(
            facility?.name?.substring(0, 50),
            'Facility'
          )}_${replaceStringValue(equipment.name, 'Equipment')}`}
          value={readyForList && listFobs ? this.getFobsTranslated() : this.getEventsTranslated()}
          orderByReverse
        />
        <ButtonsGroup
          className='equipment-events-tab-buttons-group'
          buttons={[
            {
              id: 'equipment-load-events',
              value: t('event.loadEvents'),
              onClick: () => {
                rgpdAlertLanguages.includes(language)
                  ? this.showModalRgpd()
                  : this.loadEventsProduct();
              },
            },
            {
              id: 'equipment-delete-events',
              value: t('event.deleteEvents'),
              disabled: !deleteEventsButton,
              onClick: () => {
                if (
                  product.code === 612 ||
                  product.code === 613 ||
                  product.code === 614 ||
                  product.code === 615
                ) {
                  this.onClickDeleteEvents();
                } else {
                  this.callSignalRAction('deleteEvents');
                }
              },
            },
          ]}
        />
        <DeletePopup
          title={t('equipments.deleteEventsConfirmation')}
          isOpen={this.state.isDeleteEventsPopupOpen}
          handleAccept={() => this.handleAcceptDeleteEvents()}
          handleCancel={() => this.setState({ isDeleteEventsPopupOpen: false })}
        />
        <ModalRgpd
          maxWidth='800px'
          action={this.actionModalRgpd}
          isOpen={this.state.isOpenModalRgpd}
          close={() => this.setState({ isOpenModalRgpd: false })}
        />
      </Flex>
    );
  }
}

const mapStateToProps = (state) => ({
  events: state.a5Kservices.events,
  assistantId: state.user.assistantPanelID,
  onAssistantCheckSuccess: state.signalR.onAssistantCheckSuccess,
  hubFunction: state.signalR.hubFunction,
  listFobs: state.drivers.listFobs,
  equipmentEvents: state.equipments.events,
  language: state.user.user.language,
  deleteEventsButton: state.equipments.equipment.deleteEventsButton,
});

const EquipmentEventsTabWithTranslation = withTranslation()(EquipmentEventsTab);

export default connect(mapStateToProps, {
  getEvents,
  getDriverEvents,
  resetA5kEvents,
  resetEvents,
  getEquipmentEvents,
  deleteEvents,
  resetEquipmentsEvents,
})(EquipmentEventsTabWithTranslation);
