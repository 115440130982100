import styled from 'styled-components';
import { getContrastColor, increaseBrightness } from '../../core/helpers/colorManager';

export default styled.button`
  padding: 8px 32px;
  border: 0px solid;
  border-radius: 2em;
  color: ${(props) =>
    props.disabled ? 'rgba(100,100,100,.8)' : getContrastColor(props.tenants.color1)};
  background-color: ${(props) => (props.disabled ? 'rgba(170,170,170,.8)' : props.tenants.color1)};
  ${(props) =>
    !props.disabled
      ? `&:focus{
        background-color: ${increaseBrightness(props.tenants.color1, 50)};
        color:${getContrastColor(increaseBrightness(props.tenants.color1, 50))};
        border-color: ${getContrastColor(props.tenants.color1)};
    }
    &:hover{
        background-color: ${increaseBrightness(props.tenants.color1, 30)};
        color:${getContrastColor(increaseBrightness(props.tenants.color1, 30))};
        border-color: ${getContrastColor(props.tenants.color1)};
    }`
      : ''}
`;
