import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { Calendar } from '@progress/kendo-react-dateinputs';
import { Box } from '@jcm-technologies/uikit/dist/atoms/Layout';
import { Text } from '@jcm-technologies/uikit/dist/atoms/Typography';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import PropTypes from 'prop-types';


const MonthCalendar =({ lang, holidays, setDataSelected, dataSelected, setHolidaysAPI })=> {
  const { t } = useTranslation();
  const defaultValue = '00000000-0000-0000-0000-000000000000';

  return (
    <Box padding={2}>
      <Text sizeText='display24' fontWeight='bold' color='black'>
        {t('holidays.addHolidays')}
      </Text>
      <Text sizeText='display13' marginY={2}>
        {t('holidays.selectHolidays')}
      </Text>
      <LocalizationProvider language={lang || ''}>
        <IntlProvider locale={`${lang?.slice(0, 2)}`}>
          <Calendar
            navigation={false}
            theme={(prevTheme) => ({
              ...prevTheme,
              primary: 'purple',
            })}
            max={new Date(new Date().getFullYear(), 11, 31)}
            min={new Date(new Date().getFullYear(), 0, 1)}
            onChange={(el) => {
              if (
                !dataSelected.find(
                  (data) => data.formatted === moment(el?.value).format('DD-MM-yy')
                )
              ) {
                setDataSelected((prev) => [
                  ...prev,
                  {
                    value: el?.value,
                    day: moment(el?.value).format('yy-MM-DD'),
                    formatted: moment(el?.value).format('DD-MM-yy'),
                    timestamp: moment(el?.value).unix(),
                    holidayEntityId: holidays[0]?.id || defaultValue,
                    id: defaultValue,
                  },
                ]);
                setHolidaysAPI(holidays[0]?.id || defaultValue, [
                  ...dataSelected,
                  {
                    value: el?.value,
                    day: moment(el?.value).format('yy-MM-DD'),
                    formatted: moment(el?.value).format('DD-MM-yy'),
                    timestamp: moment(el?.value).unix(),
                    holidayEntityId: holidays[0]?.id || defaultValue,
                    id: defaultValue,
                  },
                ]);
              }
            }}
          />
        </IntlProvider>
      </LocalizationProvider>
    </Box>
  )
}

export default MonthCalendar;


MonthCalendar.propTypes = {
  lang: PropTypes.string.isRequired,
  holidays: PropTypes.arrayOf(PropTypes.object).isRequired,
  setDataSelected: PropTypes.func.isRequired,
  dataSelected: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.instanceOf(Date),
      day: PropTypes.string,
      formatted: PropTypes.string,
      timestamp: PropTypes.number,
      holidayEntityId: PropTypes.string,
      id: PropTypes.string
    })
  ).isRequired,
  setHolidaysAPI: PropTypes.func.isRequired
};
