import React, { Component } from 'react';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { withTranslation } from 'react-i18next';

import './SelectableExportGroupsGrid.css';

export class SelectableExportGroupsGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: props.groupsList,
    };
  }

  areAllSelected = () => {
    const { groupsList } = this.props;
    const list = groupsList.every((dataItem) => dataItem.selected === true);
    return list;
  };

  headerSelectionChange = (event) => {
    const { data } = this.state;
    const { checked } = event.syntheticEvent.target;
    data.forEach((item) => (item.selected = checked));
    this.setState({ data });
  };

  render() {
    const { t, changeGroupSelection } = this.props;
    const { data } = this.state;
    return (
      <div className='selection-groups-table'>
        <Grid
          data={data}
          selectedField='selected'
          onSelectionChange={changeGroupSelection}
          onRowClick={changeGroupSelection}
          onHeaderSelectionChange={this.headerSelectionChange}
        >
          <Column field='selected' width='50px' headerSelectionValue={this.areAllSelected()} />
          <Column field='name' title={t('instalations.nameTableheader')} />
        </Grid>
      </div>
    );
  }
}

const SelectableKendoGridWithTranslation = withTranslation()(SelectableExportGroupsGrid);

export default SelectableKendoGridWithTranslation;
