import { resources } from '../i18n';
import { getCookieLanguage } from '../old_common/utils/cookiesManager';

export const getLanguagesOptions = () => {
  const userLang = navigator.language || navigator.userLanguage;
  const languages = Object.keys(resources).map((lng) => ({
    label: lng.replace('_', '-'),
    value: lng,
  }));
  languages.sort((x, y) => (x.key === userLang ? -1 : y.key === userLang ? 1 : 0));
  return languages;
};

export const getLanguages = () => {
  const userLang = navigator.language || navigator.userLanguage;
  const languages = Object.keys(resources).map((lng) => ({
    value: lng.replace('_', '-'),
    key: lng,
  }));
  languages.sort((x, y) => (x.key === userLang ? -1 : y.key === userLang ? 1 : 0));
  return languages;
};

const getSplitedLanguage = (lng) => {
  if (lng) {
    return lng;
  }
  return null;
};

export const getActualLanguage = () => {
  const actualLanguage =
    getCookieLanguage() ||
    getSplitedLanguage(navigator.language) ||
    getSplitedLanguage(navigator.userLanguage);
  return actualLanguage;
};
