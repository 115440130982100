export default {
  remoteComunications: {
    index: 'Index',
    content: 'Content',
    serialNumber: 'Serial Number',
    uniqueKey: 'Unique Key',
    operationsCode: 'Oper. Code',
    finalCode: 'End Code',
    progress: 'Progress',
    status: 'Status',
    refresh: 'Refresh',
    saveConfig: 'Save Configuration',
    readConfig: 'Read Configuration',
    readEvents: 'Read Events',
    removeEvents: 'Remove Events',
    seeEvents: 'See events',
    export: 'Export',
    Completed: 'Completed',
    Received: 'Received',
    Processing: 'Processing',
  },
};
