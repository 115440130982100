import bs58 from 'bs58';

const getVertexMotionType = (vertexId) => {
  let code = bs58.decode(vertexId.split('-').join(''));
  while (code.length > 11) {
    code = code.slice(1);
  }
  if (code.length == 11) {
    let check = 0;

    for (let i = 0; i < 11; i++) {
      check ^= code[i];
    }

    if (check == 0 && code[0] == (35 ^ code[2]) && code[1] == 1) {
      return code[5];
    }
  }

  return -1;
};

export { getVertexMotionType };
