import MESSAGES_DEFAULT from './en.json';
import MESSAGES_EN from './en.json';
import MESSAGES_ES from './es.json';
import MESSAGES_DE from './de.json';
import MESSAGES_FR from './fr.json';
import MESSAGES_IT from './it.json';
import MESSAGES_CS from './cs.json';

const messagesDictionary = [];
messagesDictionary.default = MESSAGES_DEFAULT;
messagesDictionary['es-ES'] = MESSAGES_ES;
messagesDictionary['ca-ES'] = MESSAGES_ES;
messagesDictionary['en-GB'] = MESSAGES_EN;
messagesDictionary['it-IT'] = MESSAGES_IT;
messagesDictionary['de-DE'] = MESSAGES_DE;
messagesDictionary['fr-FR'] = MESSAGES_FR;
messagesDictionary['cs-CZ'] = MESSAGES_CS;

export default messagesDictionary;
