import React, { Component } from 'react';
import {
  setAssistantPanelCheckSuccessFunction,
  setMessageHubFunction,
} from '../../core/old_common/utils/signalRService';

class SignalRComponent extends Component {
  componentWillUnmount() {
    setAssistantPanelCheckSuccessFunction(undefined);
    setMessageHubFunction(undefined);
  }

  render() {
    return <div />;
  }
}
export default SignalRComponent;
