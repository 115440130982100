import createFetchTypes from '../../../core/typeActions/types';
import instalationGroupsAction, { GET_GROUP_FOB_HISTORY } from '../actions/instalationGroups';
import {
  deleteArrayNodeByPropName,
  updateArrayNodeByPropName,
} from '../../../core/helpers/arrayService';
import { RESET_GROUP_FOB_HISTORY } from '../actions/groups';

export const INSTALATIONS_GROUPS_GET = createFetchTypes('INSTALATIONS_GROUPS_GET');
export const INSTALATION_GROUPS_GET = createFetchTypes('INSTALATION_GROUPS_GET');
export const INSTALATION_GROUPS_SECONDARY_GET = createFetchTypes(
  'INSTALATION_GROUPS_SECONDARY_GET'
);
export const INSTALATIONS_GROUP_GET = createFetchTypes('INSTALATIONS_GROUP_GET');
export const INSTALATION_GROUPS_PUT = createFetchTypes('INSTALATION_GROUPS_PUT');
export const INSTALATION_GROUPS_POST = createFetchTypes('INSTALATION_GROUPS_POST');
export const INSTALATION_GROUPS_DELETE = createFetchTypes('INSTALATION_GROUPS_DELETE');
export const INSTALATION_GROUPS_FOBS_COUNT = createFetchTypes('INSTALATION_GROUPS_FOBS_COUNT');
export const INSTALATION_GROUP_UNIVERSALS_GET = createFetchTypes(
  'INSTALATION_GROUP_UNIVERSALS_GET'
);
export const INSTALATION_GROUPS_ASSIGNED_GET = createFetchTypes('INSTALATION_GROUPS_ASSIGNED_GET');

/* Redux state init */
const initialState = {
  facilityGroups: undefined,
  facilityGroup: undefined,
  groupFobHistory: undefined,
  secondaryFacilityGroups: [],
  fobsCount: undefined,
  universals: [],
  needCallGroups: true,
  needCallInstalationGroups: true,
};

export default function facilityGroupsReducer(state = initialState, action) {
  if (action.type !== undefined) {
    switch (action.type) {
      case INSTALATIONS_GROUPS_GET.REQUEST:
        return { ...state, needCallGroups: false };
      case INSTALATIONS_GROUPS_GET.SUCCESS:
        return { ...state, facilityGroups: [...action.result], needCallGroups: true };
      case INSTALATION_GROUPS_SECONDARY_GET.SUCCESS:
        return { ...state, secondaryFacilityGroups: [...action.result] };
      case INSTALATION_GROUPS_DELETE.SUCCESS:
        return {
          ...state,
          facilityGroups: deleteArrayNodeByPropName(state.facilityGroups, action.result, 'id'),
        };
      case INSTALATION_GROUPS_FOBS_COUNT.SUCCESS:
        return { ...state, fobsCount: [...action.result] };
      case INSTALATION_GROUPS_FOBS_COUNT.FAILURE:
        return { ...state, fobsCount: [] };
      case instalationGroupsAction.RESET_INSTALATION_GROUPS:
        return { ...state, facilityGroups: undefined };
      case INSTALATION_GROUP_UNIVERSALS_GET.SUCCESS:
        return { ...state, universals: [...action.result] };
      case INSTALATIONS_GROUP_GET.SUCCESS:
        return {
          ...state,
          facilityGroups: state.facilityGroups
            ? updateArrayNodeByPropName(state.facilityGroups, action.result, 'id')
            : state.facilityGroups,
          facilityGroup: { ...state.facilityGroup, ...action.result },
        };
      case INSTALATION_GROUPS_PUT.SUCCESS:
        return {
          ...state,
          facilityGroups: state.facilityGroups
            ? updateArrayNodeByPropName(state.facilityGroups, action.result, 'id')
            : state.facilityGroups,
          facilityGroup: { ...state.facilityGroup, ...action.result },
        };
      case INSTALATION_GROUPS_POST.SUCCESS:
        return {
          ...state,
          facilityGroup: action.result,
          fobsCount: undefined,
          facilityGroups: state.facilityGroups
            ? [...state.facilityGroups, action.result]
            : undefined,
        };
      case instalationGroupsAction.RESET_INSTALATION_GROUP:
        return {
          ...state,
          facilityGroup: undefined,
        };
      case INSTALATION_GROUPS_GET.REQUEST:
        return {
          ...state,
          needCallInstalationGroups: false,
        };
      case INSTALATION_GROUPS_GET.SUCCESS:
        return {
          ...state,
          facilityGroups: action.result,
          needCallInstalationGroups: true,
        };
      case INSTALATION_GROUPS_ASSIGNED_GET.SUCCESS:
        return {
          ...state,
          facilityGroups: action.result,
        };
      case GET_GROUP_FOB_HISTORY.SUCCESS:
        return {
          ...state,
          groupFobHistory: action.result,
        };
      case RESET_GROUP_FOB_HISTORY:
        return {
          ...state,
          groupFobHistory: undefined,
        };
      default:
        return state;
    }
  }
  return state;
}
