import { GET_TENANTS, PUT_TENANTS } from './constants';
/* Redux state init */
const initialState = {
  originalTenants: [],
};

const getTenants = (attributes) => {
  const tenants = {};
  attributes.forEach((element) => {
    tenants[element.tag.toLowerCase()] = element.value;
  });
  return tenants;
};

export default function tenantsReducer(state = initialState, action) {
  if (action.type !== undefined) {
    switch (action.type) {
      case GET_TENANTS.SUCCESS: {
        const newTenants = getTenants(action.result);
        return { ...state, ...newTenants, originalTenants: action.result };
      }
      case PUT_TENANTS.SUCCESS: {
        const parsedTenants = action.payload[1].data;
        const newTenants = getTenants(parsedTenants);
        return { ...state, ...newTenants, originalTenants: state.originalTenants };
      }
      default:
        return state;
    }
  }
  return state;
}
