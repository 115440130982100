import { createAction } from 'redux-actions';

const POPUPSETTINGS_SET = 'POPUPSETTINGS_SET';
const RESET_AUTHPOPUP = 'RESETAUTHPOPUP';

export default {
  POPUPSETTINGS_SET,
  RESET_AUTHPOPUP,
};

export const setpopupSettings = createAction(
  POPUPSETTINGS_SET,
  (popupParameters) => popupParameters
);

export const resetAuthPopUp = createAction(RESET_AUTHPOPUP);
