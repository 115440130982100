import { useTranslation } from 'react-i18next';
import { Field } from '@jcm-technologies/uikit/dist/atoms/Field';
import { Input } from '@jcm-technologies/uikit/dist/atoms/Input';
import { Grid } from '@jcm-technologies/uikit/dist/atoms/Layout';
import PropTypes from 'prop-types';

const Form = ({ item }) => {
  const { t } = useTranslation();
  return (
    <Grid
      gridTemplateAreas={["'. . .'", "'. . .'", "'. . .'", "'. . . .'"]}
      gridTemplateColumns={['1fr 1fr 1fr', '1fr 1fr 1fr', '1fr 1fr 1fr', '1fr 1fr 1fr 1fr']}
      gridTemplateRows='1fr 1fr'
      gridColumnGap={2}
    >
      <Field label={t('form.name')}>
        <Input id='name' name='name' defaultValue={item.name || ''} readOnly />
      </Field>
      <Field label={t('form.surname')}>
        <Input id='surname' name='surname' defaultValue={item.surname || ''} readOnly />
      </Field>
      <Field label={t('form.identityCard')}>
        <Input
          id='identityCard'
          name='identityCard'
          defaultValue={item.identityCard || ''}
          readOnly
        />
      </Field>
      <Field label={t('form.slot')}>
        <Input id='slot' name='slot' defaultValue={item.slot || ''} readOnly />
      </Field>
      {item?.configFields?.map((field) => (
        <Field label={field.name} key={field.name}>
          <Input id='slot' name='slot' defaultValue={field.value || ''} readOnly />
        </Field>
      ))}
    </Grid>
  );
};

export default Form;

Form.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string,
    surname: PropTypes.string,
    slot: PropTypes.string,
    identityCard: PropTypes.string,
    configFields: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.string,
      })
    ),
  }).isRequired,
};
