import i18next from 'i18next';
import { toast } from 'react-toastify';
import createFetchTypes from '../../../core/typeActions/types';
import {
  DELETE_GROUP_FOBS_FOB,
  DELETE_GROUP_FOBS_LIST,
  PUT_GROUP_FOBS_IMPORT,
  PUT_GROUP_FOBS_LIST_MOVE,
  PUT_GROUP_FOBS_GRID,
} from '../../group/fobs/constants';
import {
  deleteArrayElementsByPropName,
  deleteArrayNodeByPropName,
  updateArrayNodeByPropName,
} from '../../../core/helpers/arrayService';
import instalationAction from '../actions/instalations';

// import errorsAction from '../actions/errors';

export const INSTALATIONS_GET = createFetchTypes('INSTALATIONS_GET');
export const INSTALATIONS_POST = createFetchTypes('INSTALATIONS_POST');
export const INSTALATIONS_PUT = createFetchTypes('INSTALATIONS_PUT');
export const INSTALATIONS_DEL = createFetchTypes('INSTALATIONS_DEL');
export const INSTALATIONS_RELAYS_GET = createFetchTypes('INSTALATIONS_RELAYS_GET');
export const INSTALATIONS_FOBS_GET = createFetchTypes('INSTALATIONS_FOBS_GET');
export const INSTALATIONS_RELAY_PUT = createFetchTypes('INSTALATIONS_RELAY_PUT');
export const INSTALATIONS_GEOLOCATION_PUT = createFetchTypes('INSTALATIONS_GEOLOCATION_PUT');
export const INSTALATION_INFO_GET = createFetchTypes('INSTALATION_INFO_GET');
export const INSTALATIONS_SINGLE_LIST_GET = createFetchTypes('INSTALATIONS_SINGLE_LIST_GET');
export const EQUIPMENT_USER_GROUPS_GET = createFetchTypes('EQUIPMENT_USER_GROUPS_GET');
export const EQUIPMENT_PARAMETERS_GET = createFetchTypes('EQUIPMENT_PARAMETERS_GET');
export const INSTALATION_CITIES_GET = createFetchTypes('INSTALATION_CITIES_GET');
export const INSTALATION_POSTALCODES_GET = createFetchTypes('INSTALATION_POSTALCODES_GET');
export const FACILITY_GET = createFetchTypes('FACILITY_GET');
export const INSTALATION_DELETE_ALARM = createFetchTypes('DELETE_ALARM_INSTALATION');
export const GET_FIELD = createFetchTypes('GET_FIELD');
export const INSTALATION_TRANSFER_PUT = createFetchTypes('INSTALATION_TRANSFER_PUT');
export const CHECKER_RECYCLE_INSTALLATION_GET = createFetchTypes(
  'CHECKER_RECYCLE_INSTALLATION_GET'
);
export const RECYCLE_INSTALLATIONS_GET = createFetchTypes('RECYCLE_INSTALLATIONS_GET');
export const RECYCLE_RESTORE_INSTALLATION_SET = createFetchTypes(
  'RECYCLE_RESTORE_INSTALLATION_SET'
);
export const FACILITY_LOGS_GET = createFetchTypes('FACILITY_LOGS_GET');
export const FACILITY_LOGS_BY_USER_GET = createFetchTypes('FACILITY_LOGS_BY_USER_GET');
export const GET_FACILITY_EVENTS = createFetchTypes('GET_FACILITY_EVENTS');
export const GET_FACILITIES_PDF = createFetchTypes('GET_FACILITIES_PDF');
/* Redux state init */
const initialState = {
  instalationsSingleList: undefined,
  instalations: undefined,
  instalationInfo: null,
  instalationsGroupRelays: undefined,
  facilityFobs: undefined,
  relaysWarnings: [],
  instalationId: '',
  groupId: '',
  overallPages: 0,
  overallRows: 0,
  currentPage: 0,
  equipmentUserGroups: [],
  facilityCities: undefined,
  facilityPostalCodes: undefined,
  facility: undefined,
  facilityLogs: undefined,
  isLoadingFacilityLogs: false,
  isLoadingFacilityEvents: false,
  needCallFacility: true,
  needCallFobs: true,
  isFacilitiesListUpdated: false,
  a: undefined,
  goFacilities: undefined,
  urlParam1: undefined,
  urlParam2: undefined,
  fromDashboard: false,
  dashboardFilterId: '',
  dashboardFilterValue: '',
  instalationConfigFields: [],
  recycleExist: false,
  recycleList: [],
  totalRecycleCount: 0,
  errorPutFobsMessage: undefined,
  showInstallerCodePopup: false,
};

const updateRelays = (relay, instalationsGroupRelays) => {
  const updatedRelays = instalationsGroupRelays.map((equipment) => {
    if (equipment.id === relay.equipmenId) {
      equipment.relays = equipment.relays.map((equipmentRelay) => {
        if (equipmentRelay.relayId === relay.relayAssignation.relayId) {
          equipmentRelay = relay.relayAssignation;
        }
        return equipmentRelay;
      });
    }
    return equipment;
  });
  return [...updatedRelays];
};

const updateFacilityGeolocation = (instalations, geolocationURL) => {
  const newInstalations = [...instalations];
  newInstalations.map((facility) => {
    if (facility.id === geolocationURL.facilityId) {
      facility.facilityAttributes.map((attr) => {
        attr.drop((x) => x.type === 6);
      });
      facility.facilityAttributes.push(geolocationURL);
      return facility;
    }
    return facility;
  });
  return [...newInstalations];
};

const deleteFromArray = (stateArray, newArray) => {
  const arrayCodes = newArray.map((item) => item.e_code);
  return stateArray.filter((item) => !arrayCodes.includes(item.e_code));
};

export default function instalationsReducer(state = initialState, action) {
  if (action.type !== undefined) {
    switch (action.type) {
      case DELETE_GROUP_FOBS_LIST.SUCCESS:
        return {
          ...state,
          facilityFobs: deleteFromArray(state.facilityFobs, action.payload[1].data),
        };
      case INSTALATIONS_SINGLE_LIST_GET.SUCCESS:
        return {
          ...state,
          instalationsSingleList: [...action.result],
        };
      case INSTALATION_INFO_GET.SUCCESS:
        return {
          ...state,
          instalationInfo: action.result?.Data,
        };
      case INSTALATIONS_RELAYS_GET.SUCCESS:
        return {
          ...state,
          instalationsGroupRelays: action.result,
          relaysWarnings: action.result.warnings || [],
        };
      case INSTALATIONS_GET.BASE: {
        return {
          ...state,
          dashboardFilterId: null,
        };
      }
      case INSTALATIONS_GET.SUCCESS: {
        const { overallPages, overallRows, currentPage, pageItems } = action.result.Data;
        return {
          ...state,
          overallPages,
          overallRows,
          currentPage,
          instalations: pageItems,
          fromDashboard: false,
          instalationConfigFields: [...action.result.MetaData],
        };
      }
      case INSTALATIONS_GET.FAILURE:
        return {
          ...state,
        };

      case FACILITY_LOGS_BY_USER_GET.BASE: {
        return {
          ...state,
          fromDashboard: true,
          dashboardFilterId: 'caUsers',
        };
      }

      case FACILITY_LOGS_BY_USER_GET.SUCCESS: {
        const { overallPages, overallRows, currentPage, pageItems } = action.result;
        return {
          ...state,
          overallPages,
          overallRows,
          currentPage,
          instalations: pageItems,
        };
      }
      case GET_FIELD.BASE: {
        const setOptions = {
          1: { searchQuery: action.value },
          2: { searchQuery: action.value },
          3: { dashboardFilterValue: [{ label: action.value, value: action.value }] },
          4: { dashboardFilterValue: [{ label: action.value, value: action.value }] },
          5: { searchQuery: action.value },
          6: { searchQuery: action.value },
        };
        return {
          ...state,
          dashboardFilterValue: null,
          searchQuery: null,
          fromDashboard: true,
          dashboardFilterId: action.fieldId,
          ...setOptions[action.fieldId],
        };
      }
      case GET_FIELD.SUCCESS: {
        const { overallPages, overallRows, currentPage, pageItems } = action.result.Data;
        return {
          ...state,
          overallPages,
          overallRows,
          currentPage,
          instalations: pageItems,
        };
      }

      case GET_FIELD.FAILURE:
        return {
          ...state,
          a:
            action.error.status === 404
              ? toast.error(i18next.t('globalSearch.filtersExceed'))
              : null,
        };
      case INSTALATIONS_DEL.SUCCESS:
        return {
          ...state,
          isFacilitiesListUpdated: true,
        };
      case INSTALATIONS_RELAY_PUT.SUCCESS:
        return {
          ...state,
          instalationsGroupRelays: updateRelays(action.result, state.instalationsGroupRelays),
        };
      case INSTALATIONS_FOBS_GET.REQUEST:
        return {
          ...state,
          needCallFobs: false,
        };
      case INSTALATIONS_FOBS_GET.SUCCESS:
        return {
          ...state,
          facilityFobs: [...action.result.Data],
          instalationConfigFields: [...action.result.MetaData],
          needCallFobs: true,
        };
      case PUT_GROUP_FOBS_GRID.SUCCESS:
        const updatedFacilityFobs = updateArrayNodeByPropName(
          state.facilityFobs,
          action.result,
          'id'
        );
        return {
          ...state,
          facilityFobs: updatedFacilityFobs,
        };
      case PUT_GROUP_FOBS_GRID.FAILURE:
        return {
          ...state,
          errorPutFobsMessage: action.error.response.body.error.code,
        };
      case DELETE_GROUP_FOBS_FOB.SUCCESS:
        const deletedFacilityFobs = deleteArrayNodeByPropName(
          state.facilityFobs,
          action.result,
          'id'
        );
        return {
          ...state,
          facilityFobs: deletedFacilityFobs,
        };
      case PUT_GROUP_FOBS_LIST_MOVE.SUCCESS:
        return {
          ...state,
          facilityFobs: deleteArrayElementsByPropName(
            state.facilityFobs,
            action.payload[1].data,
            'id'
          ),
        };
      case instalationAction.RESET_INSTALATIONS_RELAYS:
        return {
          ...state,
          instalationsGroupRelays: undefined,
        };
      case instalationAction.RESET_INTALATIONS_INFO:
        return {
          ...state,
          instalationInfo: null,
        };
      case instalationAction.RESET_INTALATIONS_FOBS:
      case PUT_GROUP_FOBS_IMPORT.SUCCESS:
        return {
          ...state,
          facilityFobs: undefined,
        };
      case EQUIPMENT_USER_GROUPS_GET.SUCCESS:
        return {
          ...state,
          equipmentUserGroups: action.result,
        };
      case EQUIPMENT_PARAMETERS_GET.SUCCESS:
        return {
          ...state,
          equipmentParameters: [...action.result],
        };
      case INSTALATIONS_PUT.SUCCESS:
        return {
          ...state,
          instalations: state.instalations
            ? updateArrayNodeByPropName(state.instalations, action.payload[1].data, 'id')
            : state.instalations,
          facility: { ...state.facility, ...action.payload[1].data },
          isFacilitiesListUpdated: true,
          showInstallerCodePopup: !!(
            state.facility.installerCode === false && action.payload[1].data.installerCode
          ),
        };
      case INSTALATIONS_GEOLOCATION_PUT.SUCCESS:
        return {
          ...state,
          instalations: updateFacilityGeolocation(state.instalations, action.result),
        };
      case INSTALATION_CITIES_GET.REQUEST:
      case INSTALATION_POSTALCODES_GET.REQUEST:
        return {
          ...state,
          isFacilitiesListUpdated: false,
        };
      case INSTALATION_CITIES_GET.SUCCESS:
        return {
          ...state,
          facilityCities: action.result,
        };
      case INSTALATION_POSTALCODES_GET.SUCCESS:
        return {
          ...state,
          facilityPostalCodes: action.result,
        };
      case INSTALATIONS_POST.SUCCESS:
        return {
          ...state,
          facility: action.result,
          isFacilitiesListUpdated: true,
          showInstallerCodePopup: action.result.installerCode,
        };
      case FACILITY_GET.REQUEST:
        return { ...state, needCallFacility: false };
      case FACILITY_GET.SUCCESS:
        return {
          ...state,
          facility: action.result?.Data || action.result,
          instalationConfigFields: action.result?.MetaData ? [...action.result.MetaData] : [],
          needCallFacility: true,
        };
      case FACILITY_LOGS_GET.REQUEST:
        return {
          ...state,
          isLoadingFacilityLogs: true,
        };
      case FACILITY_LOGS_GET.SUCCESS:
        return {
          ...state,
          isLoadingFacilityLogs: false,
          facilityLogs: action.result.pageItems,
        };
      case GET_FACILITY_EVENTS.REQUEST:
        return {
          ...state,
          isLoadingFacilityEvents: true,
        };
      case GET_FACILITY_EVENTS.SUCCESS:
        return {
          ...state,
          facility: {
            ...state.facility,
            events: action.result.events,
            dateTimeEventsGetter: action.result.date,
          },
          isLoadingFacilityEvents: false,
        };
      case instalationAction.RESET_FACILITY:
        return {
          ...state,
          facility: undefined,
          facilityLogs: undefined,
          instalationConfigFields: [],
        };
      case instalationAction.SET_FACILITY:
        return {
          ...state,
          facility: action.facility,
        };
      case instalationAction.RESET_FOBS_LIST:
        return {
          ...state,
          facilityFobs: undefined,
        };
      case instalationAction.SET_RETURN_TO_FACILITIES:
        return {
          ...state,
          goFacilities: true,
          urlParam1: action.urlParam1,
          urlParam2: action.urlParam2,
        };
      case instalationAction.RESET_PUT_FOBS_ERROR:
        return {
          ...state,
          errorPutFobsMessage: undefined,
        };
      case CHECKER_RECYCLE_INSTALLATION_GET.SUCCESS:
        return {
          ...state,
          recycleExist: action?.result,
        };
      case RECYCLE_INSTALLATIONS_GET.REQUEST:
        return {
          ...state,
          overallPages: 0,
          overallRows: 0,
          currentPage: 1,
        };
      case RECYCLE_INSTALLATIONS_GET.SUCCESS:
        return {
          ...state,
          recycleList: action?.result?.pageItems,
          totalRecycleCount: action?.result?.totalNumber,
        };
      case instalationAction.RESET_INSTALLERCODE_POPUP:
        return {
          ...state,
          showInstallerCodePopup: false,
        };
      default:
        return state;
    }
  }

  return state;
}
